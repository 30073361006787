import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AppUtil } from '@app/common/util/app-util';
import { LightboxBtnType } from '@app/dashboard/diagnose/diagnostics/lightbox-form-template/lightbox-model';
import { NetworkOnboardService } from '@app/v2/onboard/network/network.service';
import { Observable, of, Subject, zip } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { OrchestratorService } from '@app/common/components/drawer/orchestrator/orchestrator.service';
import {
  AttachPoint,
  CloudCredential,
  CloudNetwork,
  Cluster,
  ConnectorSettings,
  ConnectorSettingsDetails,
  Network,
  NetworkOnboardErrorResponseData,
  NetworkPublicCloud,
  SharedService,
  SharedServiceEndpointSubnets,
} from '@app/common/models';
import {
  IOrchestratorComponent,
  NetworkOnboardStep,
} from '@app/common/components/drawer/orchestrator/orchestrator.model';
import {
  APP_SETUP_STATUS,
  CLOUD_KEY_TYPES,
  CLOUD_TYPES,
  CONNECTIVITY_OPTIONS_KEYS,
  DIALOG_TOKEN,
  IMAGE_PATHS,
  LAST_UPDATED_TEXT,
  LOADER_CONFIG_TYPES,
  LOADER_MESSAGES,
  LoaderConfig,
  NETWORK_HOSTED_TYPE,
  NOTIFICATION_MESSAGES,
  SUBSCRIPTION_TYPES,
} from '@app/common/util/constants';
import { ModelFormGroup } from '@app/common/util/form-util';
import { NetworkVPC, VPCSettings } from './vpc.model';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CONNECTIIVITY_TYPE,
  CONNECTOR_TYPES,
  CONNECTOR_TYPE_VALUE,
  CSP_GWs,
  CSP_PC,
  DeleteAction,
  NetworkDeleteConfig,
  NetworkStatus,
  SERVICE_INSERTION_ENDPOINT_SUBNET_TYPES,
  SERVICE_INSERTION_ENDPOINT_TYPES,
  SERVICE_INSERTION_ENDPOINT_TYPES_LIST,
  connectorScalingPermittedCSPs,
  infraConnectorScalingPermittedCSPs,
} from '@app/v2/onboard/network/network.constants';
import {
  ICloudController,
  OwnCloudController,
  PrivateCloudController,
  PublicCloudController,
} from '../../../utils/controllers';
import { OnboardTemplateService } from '@app/common/components/drawer/templates/onboard-template/onboard-template.service';
import { NetworkUtil } from '@app/v2/onboard/network/network.util';
import { MatRadioChange } from '@angular/material/radio';
import { PDashLocalStoreUtil } from '@app/state/web-pdash.util';
import { MatSelectChange } from '@angular/material/select';
import {
  DeleteDialogComponent,
  LoaderService,
  SelectSearchConfig,
  ToastService,
} from '@prosimoio/components';
import { LoginUtil } from '@app/login/login-util';
import { CONSTANTS } from 'environments/environment';
import { networkQuery } from '@app/v2/onboard/network/state';
import { SubnetRouteMatch } from '@app/v2/onboard/network/network.model';

@Component({
  selector: 'app-vpc',
  templateUrl: './vpc.component.html',
  styleUrls: ['./vpc.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VpcComponent implements IOrchestratorComponent, OnInit, OnDestroy {
  stopSubs$: Subject<void>;
  step = NetworkOnboardStep.VPC;
  vpcForm: ModelFormGroup<NetworkVPC>;
  selectedVPCIndex: number = 0;
  selectedVPC: ModelFormGroup<VPCSettings>;
  networkBackup: Network;
  networkStatus: NetworkStatus;
  isStepValid = false;
  cloudController: ICloudController;
  loading = {
    connectorSettings: false,
    hubIds: false,
  };
  isUnmanagedAccount: boolean = false;
  svcCloudSubnetsMap: Map<string, Array<string>>;
  svcCloudSubnets: Array<string> = [];
  isEditable: boolean = true;
  selectHubIDConfig: SelectSearchConfig = {
    optionList: [],
    placeholder: 'Select TGW',
    uiThemeMode: PDashLocalStoreUtil.getUIThemeMode().toLowerCase() + '-theme',
    showSearch: true,
    isMultiSelection: false,
    isServerSideSearch: false,
    setKeyNameOnControl: true,
    keyName: 'attachPointID',
    addOption: false,
    panelWidthClass: 'select-width-none',
    displayName: 'attachPointDisplayName',
  };
  subnetRouteMatch: SubnetRouteMatch;
  vpcRouteState;
  overlappingConnectorSubnet: boolean = false;

  connectorSettingsMap: Map<string, ConnectorSettingsDetails>;
  edges: Array<Cluster> = [];
  clouds: Array<CloudCredential> = [];
  hubIDs: Array<AttachPoint> = [];
  subnets: Array<string> = [];
  cloudSubnetsMap: Map<string, Array<string>>;
  sharedServices: Array<SharedService>;

  connectorScalingPermittedCSPs = connectorScalingPermittedCSPs;
  infraConnectorScalingPermittedCSPs = infraConnectorScalingPermittedCSPs;
  COPY_CLOUD_TYPES = CLOUD_TYPES;
  COPY_CONNECTOR_TYPES = [];
  COPY_CONNECTOR_TYPE_VALUE = CONNECTOR_TYPE_VALUE;
  COPY_CONNECTIIVITY_TYPE = CONNECTIIVITY_TYPE;
  COPY_CONNECTIVITY_OPTIONS_KEYS = CONNECTIVITY_OPTIONS_KEYS;
  BANDWIDTH_RANGE = Array.from({ length: 100 }, (_, i) => i + 1);
  COPY_SERVICE_INSERTION_ENDPOINT_TYPES_LIST =
    SERVICE_INSERTION_ENDPOINT_TYPES_LIST;
  COPY_SERVICE_INSERTION_ENDPOINT_TYPES = SERVICE_INSERTION_ENDPOINT_TYPES;
  COPY_CSP_GWs = CSP_GWs;
  COPY_CSP_PC = CSP_PC;
  COPY_NetworkStatus = NetworkStatus;
  uiThemeMode: string = PDashLocalStoreUtil.getUIThemeMode().toLowerCase();
  tooltipThemeClass: string;
  defaultHint =
    'Networks bring subnets within a VPC/VNet behind the Prosimo fabric and routes the traffic via the fabric.';
  hint = '';
  errorIcon = IMAGE_PATHS.VISUAL_ONBOARD.ERROR;
  isPrivateConnectivity: boolean;
  summaryRoutesPath = CONSTANTS.MANAGEMENT.UI_PATH_SUMMARY_ROUTES;
  isSubnetsAvailable: boolean = true;
  COPY_LOADER_MESSAGES = LOADER_MESSAGES;
  subnetsLoader$: Observable<LoaderConfig>;

  @Input() data: Network;
  @Input() errorPayload: any;

  constructor(
    private networkOnboardService: NetworkOnboardService,
    private orchestratorService: OrchestratorService,
    private onboardTemplateService: OnboardTemplateService,
    private loaderService: LoaderService,
    private loginUtil: LoginUtil,
    private toastService: ToastService,
    private cdr: ChangeDetectorRef,
    private appUtil: AppUtil
  ) {
    this.stopSubs$ = new Subject();
    this.connectorSettingsMap = new Map();
    this.cloudSubnetsMap = new Map();
    this.svcCloudSubnetsMap = new Map();
  }

  ngOnInit(): void {
    this.appUtil
      .getDrawerResponse()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (response) => {
          if (response.code === LightboxBtnType.NEXT.code) {
            this.updateNetwork();
          } else {
            this.appUtil.setDrawerState(false);
            this.appUtil.setDynamicDrawerResponse({
              context: NetworkOnboardStep.VPC,
              action: response.code,
              payload: null,
            });
          }
        },
      });
    this.orchestratorService
      .getTabChangeRequest()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (step: NetworkOnboardStep) => {
          if (this.vpcForm) {
            const formData = <NetworkVPC>this.vpcForm.getRawValue();
            if (formData?.vpcSettings?.length) {
              this.cloudController.updateVPCs(this.data, formData);
            }
          }
          this.orchestratorService.setGotoStop(step);
        },
      });

    if (!this.data.publicCloud?.cloudNetworks?.length) {
      return;
    }
    this.getGlobalConnectivityType();
    this.networkBackup = networkQuery.getActiveNetwork();
    this.networkStatus = NetworkUtil.getNetworkState(this.data);
    let connectorTypes = CONNECTOR_TYPES(this.data.publicCloud.cloud);
    let subscriptionTypes = this.loginUtil.getSubscriptionType();

    if (!subscriptionTypes.includes(SUBSCRIPTION_TYPES.ZTNA)) {
      connectorTypes.pop();
    }
    this.COPY_CONNECTOR_TYPES = connectorTypes;
    this.selectHubIDConfig.placeholder = `Select ${
      CSP_GWs[this.data.publicCloud.cloud]
    }`;
    const cloudType = this.getCloudType();
    this.setCloudController(cloudType);
    this.initForm();
    if (
      this.errorPayload &&
      this.errorPayload.errorStep === NetworkOnboardStep.VPC
    ) {
      this.hint = this.errorPayload.errorMessage;
      this.selectedVPCIndex = this.errorPayload.index;
    } else {
      this.hint = this.defaultHint;
    }
    this.appUtil.updateDrawerButtonConfig({
      mainBtn: { ...LightboxBtnType.NEXT, disabled: true },
      cancelBtn: LightboxBtnType.CANCEL,
    });
    PDashLocalStoreUtil.getUIThemeModeAsObservable()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (uiThemeMode) => {
          this.uiThemeMode = uiThemeMode.toLowerCase();
          this.toastService.setUITheme(this.uiThemeMode + '-theme');
          this.selectHubIDConfig.uiThemeMode = this.uiThemeMode + '-theme';
          this.tooltipThemeClass =
            'cdk-component-container--' + this.uiThemeMode + '-theme-dark';
        },
      });
    this.onboardTemplateService
      .getNameInput()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (name) => {
          this.vpcForm.controls.name.patchValue(name);
        },
      });

    this.appUtil.setConditionHeading(
      `${CSP_PC[this.data.publicCloud.cloud]} Settings`
    );
    this.setConditionPanelConfig(null);
    this.appUtil
      .getConditionClickEvent()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (val) => {
          const match = this.data.publicCloud.cloudNetworks.findIndex(
            (cloudNetwork) => cloudNetwork.cloudNetworkID === val.label
          );
          if (match > -1) {
            this.selectedVPCIndex = match;
            this.selectedVPC = this.vpcForm.controls.vpcSettings.at(match);
            this.setEditable(match);
            this.vpcRouteState = this.getSummaryRouteState(
              this.data.publicCloud.cloudNetworks?.[
                this.selectedVPCIndex
              ].subnets.map((subnet) => subnet.virtualSubnet || subnet.subnet)
            );
            this.setConnectivityTypeControlState(
              this.selectedVPC.controls.connectorPlacement.value
            );
            if (
              this.selectedVPC.controls.serviceSubnets.controls.mode.value ===
              SERVICE_INSERTION_ENDPOINT_TYPES.MANUAL
            ) {
              this.fetchServiceSubnets();
            }
            this.fetchCloudSubnets();
            this.cdr.markForCheck();
          }
          this.getVPCActiveSettings();
        },
      });
    this.updateSubnetsLoaderConfig(true);
    this.setDrawerFooter();
    this.fetchStaticData();
    this.getNetworkConflicts();
    this.getVPCActiveSettings();
    this.validateStep();
  }

  getGlobalConnectivityType() {
    this.networkOnboardService.getGlobalConnectivityType().subscribe({
      next: (res) => {
        this.isPrivateConnectivity = res?.privateEdge ? true : false;
      },
    });
  }

  setDrawerFooter() {
    this.onboardTemplateService
      .getDeleteResponse()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (response) => {
          if (response.action === DeleteAction.DELETE) {
            this.deleteNetwork();
          } else if (response.action === DeleteAction.OFFBOARD) {
            this.offboardNetwork(response.confirmation);
          }
        },
      });

    if (this.data.createdTime) {
      this.onboardTemplateService.setDateInfo(
        `${LAST_UPDATED_TEXT}${this.appUtil.transformDateTime(
          this.data?.updatedTime
        )}`
      );
    }
    if (
      this.networkStatus !== NetworkStatus.NEW &&
      this.networkStatus !== NetworkStatus['IN-PROGRESS']
    ) {
      this.onboardTemplateService.setDeleteConfig({
        disabled: false,
        message: NetworkDeleteConfig[this.networkStatus].btn,
        tooltip: NetworkDeleteConfig[this.networkStatus].btn,
        panelConfig: {
          btnColorBlack: false,
          deleteMsg: NetworkDeleteConfig[this.networkStatus].panelMessage,
          deleteName: this.data.name,
          deleteButtonName:
            NetworkDeleteConfig[this.networkStatus].panelBtnName,
          action: NetworkDeleteConfig[this.networkStatus].action,
          confirmation: NetworkDeleteConfig[this.networkStatus].confirmation,
        },
      });
    }
  }

  setConditionPanelConfig(valid: Set<number>) {
    this.appUtil.setConditionConfig(
      this.data.publicCloud.cloudNetworks.map((cloudNetwork, i) => {
        return {
          displayLabel: this.getCloudNetworkID(
            cloudNetwork.cloudNetworkID,
            this.data.publicCloud.cloud
          ),
          label: cloudNetwork.cloudNetworkID,
          isActive: i === this.selectedVPCIndex,
          isValid: valid?.has(i),
        };
      })
    );
  }

  initForm() {
    this.vpcForm = new FormGroup({
      name: new FormControl(this.data.name || ''),
      vpcSettings: new FormArray(
        this.data.publicCloud.cloudNetworks.map((cloudNetwork) => {
          return new FormGroup({
            connectorPlacement: new FormControl(
              this.getConnectorPlacement(cloudNetwork)
            ),
            connectivityType: new FormControl(
              this.getConnectivityType(cloudNetwork)
            ),
            connectorSettings: new FormGroup({
              bandwidthRange: new FormGroup({
                min: new FormControl(
                  cloudNetwork.connectorSettings?.bandwidthRange?.min || 1
                ),
                max: new FormControl(
                  cloudNetwork.connectorSettings?.bandwidthRange?.max || 5
                ),
              }),
              subnets: new FormControl(
                cloudNetwork.connectorSettings?.subnets || [],
                [
                  Validators.maxLength(
                    this.data.publicCloud.cloud === CLOUD_TYPES.AZURE ? 1 : 2
                  ),
                ]
              ),
            }),
            hubID: new FormControl(cloudNetwork.hubID || ''),
            serviceSubnets: new FormGroup({
              mode: new FormControl(
                cloudNetwork.serviceSubnets?.mode ||
                  SERVICE_INSERTION_ENDPOINT_TYPES.AUTO
              ),
              svcID: new FormControl(cloudNetwork.serviceSubnets?.svcID || ''),
              svcName: new FormControl(
                cloudNetwork.serviceSubnets?.svcName || ''
              ),
              subnets: new FormArray([
                new FormGroup({
                  type: new FormControl(
                    SERVICE_INSERTION_ENDPOINT_SUBNET_TYPES.SERVICE_ENDPOINT
                  ),
                  ipAddrCidrs: new FormControl(
                    cloudNetwork.serviceSubnets?.subnets?.find(
                      (subnet) =>
                        subnet.type ===
                        SERVICE_INSERTION_ENDPOINT_SUBNET_TYPES.SERVICE_ENDPOINT
                    )?.ipAddrCidrs || []
                  ),
                }),
                new FormGroup({
                  type: new FormControl(
                    SERVICE_INSERTION_ENDPOINT_SUBNET_TYPES.CONNECTOR
                  ),
                  ipAddrCidrs: new FormControl(
                    cloudNetwork.serviceSubnets?.subnets?.find(
                      (subnet) =>
                        subnet.type ===
                        SERVICE_INSERTION_ENDPOINT_SUBNET_TYPES.CONNECTOR
                    )?.ipAddrCidrs || []
                  ),
                }),
              ]),
              endpointSubnet: new FormControl(
                cloudNetwork.serviceSubnets?.subnets?.find(
                  (subnet) =>
                    subnet.type ===
                    SERVICE_INSERTION_ENDPOINT_SUBNET_TYPES.SERVICE_ENDPOINT
                )?.ipAddrCidrs || []
              ),
              subnetInterface: new FormControl(
                cloudNetwork.serviceSubnets?.subnets?.find(
                  (subnet) =>
                    subnet.type ===
                    SERVICE_INSERTION_ENDPOINT_SUBNET_TYPES.CONNECTOR
                )?.ipAddrCidrs || []
              ),
            }),
          });
        })
      ),
    });
    this.setFormErrors();
    this.setEditable(0);
    this.selectedVPC = this.vpcForm.controls.vpcSettings.at(
      this.selectedVPCIndex
    );
    this.setConnectivityTypeControlState(
      this.selectedVPC.controls.connectorPlacement.value
    );
    this.vpcForm.valueChanges.pipe(takeUntil(this.stopSubs$)).subscribe({
      next: (val) => {
        this.validateStep();
      },
    });
  }

  getCloudNetworkID(cloudNetworkID: string, cloudType: string) {
    switch (cloudType) {
      case CLOUD_TYPES.AWS:
        return cloudNetworkID;
      case CLOUD_TYPES.GCP:
      case CLOUD_TYPES.AZURE:
        return cloudNetworkID.split('/').pop();
    }
  }

  getCloudType(): string {
    if (this.data.publicCloud?.cloudType) {
      return this.data.publicCloud?.cloudType;
    } else if (this.data.privateCloud?.cloudType) {
      return this.data.privateCloud?.cloudType;
    } else if (this.data.ownTransitCloud?.cloudType) {
      return this.data.ownTransitCloud?.cloudType;
    } else return NETWORK_HOSTED_TYPE.PUBLIC;
  }

  setCloudController(cloudType: string): void {
    if (cloudType === NETWORK_HOSTED_TYPE.PUBLIC) {
      this.cloudController = new PublicCloudController(
        this.orchestratorService
      );
    } else if (cloudType === NETWORK_HOSTED_TYPE.PRIVATE) {
      this.cloudController = new PrivateCloudController(
        this.orchestratorService
      );
    } else if (cloudType === NETWORK_HOSTED_TYPE.OWN) {
      this.cloudController = new OwnCloudController(this.orchestratorService);
    }
  }

  changeConnectorPlacement(event: MatRadioChange) {
    this.getVPCActiveSettings();
    if (event.value === CONNECTOR_TYPE_VALUE.INFRA) {
      this.updatePeeringOptions();
    }
    if (event.value === CONNECTOR_TYPE_VALUE.APP) {
      this.fetchCloudSubnets();
    }
    this.setConnectivityTypeControlState(event.value);
    this.cdr.markForCheck();
  }

  setConnectivityTypeControlState(connectorPlacement: CONNECTOR_TYPE_VALUE) {
    if (
      connectorPlacement === CONNECTOR_TYPE_VALUE.INFRA ||
      !this.selectedVPC.enabled
    ) {
      this.selectedVPC.controls.connectivityType.disable();
    } else {
      this.selectedVPC.controls.connectivityType.enable();
    }
    this.cdr.markForCheck();
  }

  getConnectorPlacement(cloudNetwork: CloudNetwork): CONNECTOR_TYPE_VALUE {
    const defaultPlacement = CONNECTOR_TYPE_VALUE.INFRA;
    return (
      (cloudNetwork.connectorPlacement as CONNECTOR_TYPE_VALUE) ||
      defaultPlacement
    );
  }

  getConnectivityType(cloudNetwork: CloudNetwork): string {
    if (cloudNetwork.connectivityType) {
      return cloudNetwork.connectivityType;
    }
    const placement = this.getConnectorPlacement(cloudNetwork);

    switch (this.data.publicCloud.cloud) {
      case CLOUD_TYPES.AWS:
        if (placement === CONNECTOR_TYPE_VALUE.INFRA) {
          return CONNECTIIVITY_TYPE[this.data.publicCloud.cloud][1].value;
        } else {
          return CONNECTIIVITY_TYPE[this.data.publicCloud.cloud][0].value;
        }
      case CLOUD_TYPES.GCP:
        return CONNECTIIVITY_TYPE[this.data.publicCloud.cloud][0].value;
      case CLOUD_TYPES.AZURE:
        if (placement === CONNECTOR_TYPE_VALUE.INFRA) {
          return CONNECTIIVITY_TYPE[this.data.publicCloud.cloud][0].value;
        } else {
          return CONNECTIIVITY_TYPE[this.data.publicCloud.cloud][1].value;
        }
      default:
        return CONNECTIIVITY_TYPE[this.data.publicCloud.cloud][0].value;
    }
  }

  setEditable(i: number) {
    if (
      this.networkStatus === NetworkStatus.DEPLOYED ||
      this.networkStatus === NetworkStatus['IN-PROGRESS']
    ) {
      this.isEditable = false;

      const newPCs = this.getNewVPCOrSubnet();

      this.vpcForm.controls.vpcSettings.controls.forEach((group) =>
        group.enable()
      );

      if (!newPCs.includes(i)) {
        this.vpcForm.controls.vpcSettings.at(i).disable();
        this.cdr.markForCheck();
      }
    }
  }

  getNewVPCOrSubnet(): Array<number> {
    const indices: Array<number> = [];
    this.data.publicCloud.cloudNetworks.forEach((PC, i) => {
      const existingPC = this.networkBackup.publicCloud.cloudNetworks.find(
        (selPC) => selPC.cloudNetworkID === PC.cloudNetworkID
      );
      if (!existingPC) {
        indices.push(i);
      } else {
        return; // TODO: Confirm if changing subnets also requires validating
      }
    });
    return indices || [];
  }

  fetchStaticData() {
    zip(
      this.networkOnboardService.getEdges(),
      this.networkOnboardService.getPublicClouds(),
      this.networkOnboardService.checkCustomRoutingNeeded(
        this.data.publicCloud?.cloud,
        this.data.publicCloud?.cloudRegion,
        this.data.publicCloud.cloudNetworks.reduce((p, cn) => {
          p = p.concat(
            cn.subnets.map((subnet) => subnet.virtualSubnet || subnet.subnet)
          );
          return p;
        }, [] as Array<string>)
      )
    ).subscribe({
      next: ([edges, clouds, subnetRouteMatch]: [
        Array<Cluster>,
        Array<CloudCredential>,
        SubnetRouteMatch
      ]) => {
        this.edges = edges;
        this.clouds = clouds;
        const account = clouds.find(
          (cloud) => cloud.id === this.data.publicCloud.cloudKeyID
        );
        this.isUnmanagedAccount = account.keyType === CLOUD_KEY_TYPES.UNMANAGED;
        this.subnetRouteMatch = subnetRouteMatch;
        this.vpcRouteState = this.getSummaryRouteState(
          this.data.publicCloud.cloudNetworks?.[
            this.selectedVPCIndex
          ].subnets.map((subnet) => subnet.virtualSubnet || subnet.subnet)
        );
        this.cdr.markForCheck();
      },
    });

    if (this.data.publicCloud.cloud !== CLOUD_TYPES.GCP) {
      this.getHubIDs();
    }
    if (
      this.selectedVPC.controls.connectorPlacement.value ===
      CONNECTOR_TYPE_VALUE.APP
    ) {
      this.fetchCloudSubnets();
    }
    if (this.data.publicCloud.cloud === CLOUD_TYPES.AWS) {
      this.fetchSharedServices();
    }
  }

  getVPCActiveSettings() {
    this.loading.connectorSettings = true;
    this.cdr.markForCheck();
    this.networkOnboardService
      .getConnectorActiveSettings(this.getActiveSettingsPayload())
      .subscribe({
        next: (data) => {
          this.loading.connectorSettings = false;
          if (data?.cloudNetworkID || data?.region) {
            if (
              this.selectedVPC.controls.connectorPlacement.value ===
              CONNECTOR_TYPE_VALUE.INFRA
            ) {
              this.connectorSettingsMap.set(
                this.data.publicCloud.cloudRegion,
                data
              );
            } else {
              this.connectorSettingsMap.set(
                this.data.publicCloud.cloudNetworks[this.selectedVPCIndex]
                  .cloudNetworkID,
                data
              );
            }
          }
          if (this.isValidForSetting()) {
            let connectorSettings: ConnectorSettings;
            if (data?.connectorSettings) {
              connectorSettings = {
                ...data?.connectorSettings,
                subnets:
                  this.selectedVPC.controls.connectorSettings.controls.subnets
                    .value || [],
              };
              connectorSettings.bandwidthRange =
                connectorSettings?.bandwidthRange
                  ? connectorSettings.bandwidthRange
                  : {
                      min: 1,
                      max: 1,
                    };
            } else {
              connectorSettings = {
                subnets:
                  this.selectedVPC.controls.connectorSettings.controls.subnets
                    .value || [],
                bandwidthRange: {
                  min: 1,
                  max: 1,
                },
              };
            }
            this.setConnectorSettings(connectorSettings);
          }
        },
        error: (error) => {
          console.warn('Error Loading Active Connector Settings');
          this.loading.connectorSettings = false;
          this.cdr.markForCheck();
        },
      });
  }

  getSummaryRouteState(subnets: Array<string>) {
    let state = {};
    subnets?.forEach((subnet) => {
      if (subnet in this.subnetRouteMatch?.rfcRoutesEnabled) {
        state['rfcMatch'] = true;
        return;
      }
      if (subnet in this.subnetRouteMatch?.customRouteAdded) {
        state['customMatch'] = true;
        return;
      }
      if (
        this.subnetRouteMatch?.customRouteNeeded.some(
          (sub) => sub === subnet
        ) ||
        subnet in this.subnetRouteMatch?.rfcRoutesDisabled
      ) {
        state['customMatchNeeded'] = true;
        return;
      }
    });
    return state;
  }

  setConnectorSettings(connectorSettings: ConnectorSettings) {
    if (
      this.selectedVPC.controls.connectorPlacement.value ===
      CONNECTOR_TYPE_VALUE.NONE
    ) {
      this.selectedVPC.controls.connectorSettings.patchValue({
        bandwidthRange: {
          min: 1,
          max: 1,
        },
      });
    }

    let activeSettings: ConnectorSettingsDetails;
    const storedSetting = this.connectorSettingsMap.get(
      this.selectedVPC.controls.connectorPlacement.value ===
        CONNECTOR_TYPE_VALUE.INFRA
        ? this.data.publicCloud.cloudRegion
        : this.data.publicCloud.cloudNetworks[this.selectedVPCIndex]
            .cloudNetworkID
    );
    if (this.isValidForSetting()) {
      activeSettings = storedSetting;
    }

    if (
      activeSettings?.networks?.some(
        (nw) => nw.deployed && nw.id !== this.data.id
      )
    ) {
      this.selectedVPC.controls.connectorSettings.patchValue({
        subnets: activeSettings.connectorSettings.subnets,
        bandwidthRange: activeSettings?.connectorSettings?.bandwidthRange,
      });
      this.selectedVPC.controls.connectorSettings.disable();
      this.cdr.markForCheck();
    } else {
      this.selectedVPC.controls.connectorSettings.patchValue({
        subnets: connectorSettings.subnets,
        bandwidthRange: connectorSettings?.bandwidthRange,
      });
      if (
        [NetworkStatus.NEW, NetworkStatus.SAVED].includes(this.networkStatus) ||
        this.getNewVPCOrSubnet().includes(this.selectedVPCIndex)
      ) {
        this.selectedVPC.controls.connectorSettings.enable();
      }
      this.cdr.markForCheck();
    }
  }

  getActiveSettingsPayload() {
    if (
      this.selectedVPC.controls.connectorPlacement.value ===
      CONNECTOR_TYPE_VALUE.INFRA
    ) {
      return {
        edgeRegion: this.data.publicCloud.cloudRegion,
      };
    } else {
      return {
        cloudNetworkID:
          this.data.publicCloud.cloudNetworks[this.selectedVPCIndex]
            .cloudNetworkID,
        edgeRegion: this.data.publicCloud.cloudRegion,
      };
    }
  }

  getNetworkConflicts() {
    if (this.networkStatus === NetworkStatus.NEW) {
      return;
    }
    // TODO: Phase 2
    // this.networkOnboardService.getConflicts(this.data).subscribe({
    //   next: (data) => {
    //     console.log(data);
    //   },
    // });
  }

  updatePeeringOptions() {
    const cloudType = this.data.publicCloud.cloud;
    this.selectedVPC.controls.connectivityType.patchValue(
      cloudType === CLOUD_TYPES.AZURE
        ? CONNECTIVITY_OPTIONS_KEYS.VNET_PEERING
        : cloudType === CLOUD_TYPES.AWS
        ? CONNECTIVITY_OPTIONS_KEYS.TRANSIT_GATEWAY
        : CONNECTIVITY_OPTIONS_KEYS.VPC_PEERING
    );
  }

  fetchCloudSubnets() {
    const payload = {
      id: this.data.publicCloud.cloudKeyID,
      region: this.data.publicCloud.cloudRegion,
      vpcID:
        this.data.publicCloud.cloudNetworks[this.selectedVPCIndex]
          .cloudNetworkID,
    };

    if (this.cloudSubnetsMap.has(payload.vpcID)) {
      this.subnets = this.filterSubnets(
        this.cloudSubnetsMap.get(payload.vpcID),
        this.data.publicCloud
      );
      this.cdr.markForCheck();
      return;
    }

    this.networkOnboardService.getCloudSubnets(payload).subscribe({
      next: (data) => {
        if (!data?.length) {
          this.subnets = [];
          this.cdr.markForCheck();
          return;
        } else {
          this.cloudSubnetsMap.set(payload.vpcID, data);
          this.subnets = this.filterSubnets(data, this.data.publicCloud);
        }

        this.cdr.markForCheck();
      },
    });
  }

  filterSubnets(subnets: string[], publicCloud: NetworkPublicCloud): string[] {
    this.updateSubnetsLoaderConfig(true, LOADER_MESSAGES.LOADING);
    subnets = Array.from(
      new Set(
        subnets
          .filter(
            (subnet) =>
              !publicCloud.cloudNetworks[this.selectedVPCIndex].subnets.some(
                (s) => s.subnet === subnet
              )
          )
          .concat(this.selectedVPC.getRawValue().connectorSettings.subnets)
      )
    );
    this.isSubnetsAvailable = subnets.length === 0 ? false : true;
    if (this.isSubnetsAvailable) {
      this.updateSubnetsLoaderConfig(false);
    } else {
      this.updateSubnetsLoaderConfig(
        false,
        'Connector cannot be deployed in subnets that are being onboarded as part of this network. Please update the subnet selections on the Define Network tab',
        'nosubnetserror'
      );
    }

    return subnets;
  }

  fetchSharedServices() {
    this.networkOnboardService
      .getSharedServices([APP_SETUP_STATUS.DEPLOYED])
      .subscribe({
        next: (sharedServices) => {
          this.sharedServices =
            sharedServices?.filter(
              (service) =>
                service.region.cloudRegion === this.data.publicCloud.cloudRegion
            ) || [];
          if (
            this.selectedVPC.controls.serviceSubnets.controls.mode.value ===
            SERVICE_INSERTION_ENDPOINT_TYPES.MANUAL
          ) {
            this.fetchServiceSubnets();
          }
        },
      });
  }

  updateServiceName(
    event: MatSelectChange,
    formGroup: ModelFormGroup<VPCSettings>
  ) {
    formGroup.controls.serviceSubnets.controls.svcName.setValue(
      this.sharedServices.find((service) => service.id === event.value).name,
      { emitEvent: false }
    );
    this.fetchServiceSubnets();
  }

  fetchServiceSubnets() {
    const selectedSvc = this.sharedServices.find(
      (svc) =>
        svc.id ===
        this.vpcForm.controls.vpcSettings.at(this.selectedVPCIndex).controls
          .serviceSubnets.controls.svcID.value
    );

    const payload: SharedServiceEndpointSubnets = {
      serviceEpName: selectedSvc?.region?.gwLoadBalancerID,
      sharedSvcCloudKeyID: selectedSvc?.region?.cloudKeyID,
      region: this.data.publicCloud?.cloudRegion,
      vpcID:
        this.data.publicCloud?.cloudNetworks?.[this.selectedVPCIndex]
          ?.cloudNetworkID,
      cloudKeyID: this.data.publicCloud?.cloudKeyID,
      subnets: [],
      sharedServiceName: selectedSvc?.name,
    };

    if (!this.svcCloudSubnetsMap.has(payload.serviceEpName)) {
      this.networkOnboardService
        .getSharedServiceEndpointSubnets(payload)
        .subscribe({
          next: (response) => {
            this.svcCloudSubnetsMap.set(
              payload.serviceEpName,
              response.subnets
            );
            this.svcCloudSubnets = response.subnets;
            this.cdr.markForCheck();
          },
        });
    } else {
      this.svcCloudSubnets = this.svcCloudSubnetsMap.get(payload.serviceEpName);
      this.cdr.markForCheck();
    }
  }

  getHubIDs() {
    if (this.isUnmanagedAccount) {
      const edge = this.edges.find(
        (edge) => edge.cloudRegion === this.data.publicCloud?.cloudRegion
      );
      this.loading.hubIds = true;
      this.cdr.markForCheck();

      if (!edge?.id) {
        return;
      }
      this.networkOnboardService.getEdgeConnectivityOptions(edge.id).subscribe({
        next: (connectivity) => {
          this.loading.hubIds = false;
          this.hubIDs = [];
          connectivity.forEach((hub) => {
            if (
              hub.connectivityType === CONNECTIVITY_OPTIONS_KEYS.TRANSIT_GATEWAY
            ) {
              this.hubIDs.push({
                attachPointID: hub.attachPoint,
                attachPointDisplayName: hub.attachPoint,
              });
            }
          });
          this.selectHubIDConfig.optionList = this.hubIDs;
          this.cdr.markForCheck();
        },
      });
    } else {
      if (
        !this.data.publicCloud?.cloudKeyID ||
        !this.data.publicCloud?.cloudRegion
      ) {
        return;
      }

      const cloudRegion = {
        id: this.data.publicCloud?.cloudKeyID,
        region: this.data.publicCloud?.cloudRegion,
      };
      this.loading.hubIds = true;
      this.cdr.markForCheck();
      this.networkOnboardService.getHubIDs(cloudRegion).subscribe({
        next: (data) => {
          if (this.cloudController.getCSP(this.data) === CLOUD_TYPES.AWS) {
            this.hubIDs = this.appUtil
              .sort(data || [], 'attachPointDisplayName')
              .map((hubID) => ({
                attachPointID: hubID.attachPointID,
                attachPointDisplayName: `${hubID.attachPointDisplayName} | ${hubID.attachPointID}`,
              }));
          } else {
            this.hubIDs = data || [];
          }
          this.selectHubIDConfig.optionList = this.hubIDs;
          this.loading.hubIds = false;
          const currentHubID = this.selectedVPC.controls.hubID.value;
          if (!data.some((hubID) => hubID.attachPointID === currentHubID)) {
            this.selectedVPC.controls.hubID.reset();
          }
          this.cdr.markForCheck();
        },
      });
    }
  }

  getCurrentVPC(): ModelFormGroup<VPCSettings> {
    return this.vpcForm.controls.vpcSettings.at(this.selectedVPCIndex);
  }

  isValidForSetting(): boolean {
    return (
      (this.selectedVPC.controls.connectorPlacement.value ===
        CONNECTOR_TYPE_VALUE.INFRA &&
        infraConnectorScalingPermittedCSPs.includes(
          this.data.publicCloud.cloud
        )) ||
      (this.selectedVPC.controls.connectorPlacement.value ===
        CONNECTOR_TYPE_VALUE.APP &&
        connectorScalingPermittedCSPs.includes(this.data.publicCloud.cloud))
    );
  }

  setFormErrors() {
    if (!this.errorPayload) {
      return;
    }
    let err: NetworkOnboardErrorResponseData = this.errorPayload.errorObject;
    setTimeout(() => {
      // console.log(this.errorPayload.index);
      // TODO: handle error
    });
  }

  validateStep() {
    const modifiedVPCs = this.detectFormChanges();
    let validSteps = [];
    const formData = <NetworkVPC>this.vpcForm.getRawValue();
    validSteps = this.cloudController.validateVPCs(
      this.data,
      formData.vpcSettings
    );
    if (this.networkStatus !== NetworkStatus.NEW && !modifiedVPCs.length) {
      this.isStepValid = true;
    } else if (!this.vpcForm.controls.name.value) {
      this.isStepValid = false;
    } else {
      this.isStepValid = validSteps.length === formData.vpcSettings.length;
    }
    if (this.isStepValid) {
      this.appUtil.updateDrawerButtonConfig({
        mainBtn: { ...LightboxBtnType.NEXT, disabled: false },
        cancelBtn: LightboxBtnType.CANCEL,
      });
    } else {
      this.appUtil.updateDrawerButtonConfig({
        mainBtn: { ...LightboxBtnType.NEXT, disabled: true },
        cancelBtn: LightboxBtnType.CANCEL,
      });
    }
    if (this.errorPayload) {
      validSteps = validSteps?.filter(
        (step) => step !== this.errorPayload.index
      );
    }
    this.setConditionPanelConfig(new Set(validSteps));
    this.overlappingConnectorSubnet =
      this.selectedVPC
        ?.getRawValue()
        ?.connectorSettings?.subnets?.some((subnet) =>
          this.data?.publicCloud?.cloudNetworks?.[
            this.selectedVPCIndex
          ]?.subnets?.some((selSub) => selSub?.subnet === subnet)
        ) || false;
    this.cdr.markForCheck();
  }

  detectFormChanges(): Array<number> {
    const formData = <NetworkVPC>this.vpcForm.getRawValue();
    return this.cloudController.detectVPCFormChanges(
      this.data,
      formData.vpcSettings
    );
  }

  updateNetwork() {
    this.validateStep();
    if (!this.isStepValid) {
      return;
    }

    if (!this.detectFormChanges()) {
      this.markStepComplete();
      return;
    }

    const formData = <NetworkVPC>this.vpcForm.getRawValue();
    this.cloudController.updateVPCs(this.data, formData);
    this.markStepComplete();
  }

  deleteNetwork() {
    if (!this.data.id) {
      return;
    }

    this.loaderService.setLoaderStatus(true);
    this.networkOnboardService.deleteNetwork(this.data.id).subscribe({
      next: (resp) => {
        this.loaderService.setLoaderStatus(false);
        this.toastService.success(
          NOTIFICATION_MESSAGES.DELETE.SUCCESS(this.data.name)
        );
        this.appUtil.setDynamicDrawerResponse({
          action: 'delete',
          context: NetworkOnboardStep.DEFINE,
          payload: null,
        });
        this.appUtil.setDrawerState(false);
      },
      error: (error) => {
        this.loaderService.setLoaderStatus(false);
        this.showErrorMessage(
          {
            errorMsg:
              error?.error?.message ||
              NOTIFICATION_MESSAGES.DELETE.FAIL(this.data.name),
          },
          () => {
            this.appUtil.setDrawerState(false);
          }
        );
      },
    });
  }

  offboardNetwork(forced: boolean) {
    if (!this.data.id) {
      return;
    }
    if (this.networkStatus !== NetworkStatus.DEPLOYED) {
      return;
    }

    this.loaderService.setLoaderStatus(true);
    this.networkOnboardService.offboardNetwork(this.data.id, forced).subscribe({
      next: (resp) => {
        this.loaderService.setLoaderStatus(false);
        this.toastService.success(
          NOTIFICATION_MESSAGES.OFFBOARD.SUCCESS(this.data.name)
        );
        this.appUtil.setDynamicDrawerResponse({
          action: 'offboard',
          context: NetworkOnboardStep.DEFINE,
          payload: null,
        });
        this.appUtil.setDrawerState(false);
      },
      error: (error) => {
        this.loaderService.setLoaderStatus(false);
        this.showErrorMessage(
          {
            errorMsg:
              error?.error?.message ||
              NOTIFICATION_MESSAGES.OFFBOARD.FAIL(this.data.name),
          },
          () => {
            this.appUtil.setDrawerState(false);
          }
        );
      },
    });
  }

  showErrorMessage(popupConfig, action: (...args: any[]) => void) {
    const dialogData = {
      ...popupConfig,
      isInnerHTML: true,
    };

    const injector = this.appUtil?.createInjector(
      DIALOG_TOKEN.INJECTOR_TOKEN_DATA.DIALOG_DATA_TOKEN.NAME,
      dialogData
    );

    const componentRef = this.appUtil?.initializeDeleteOverlay(
      DeleteDialogComponent,
      injector,
      this.uiThemeMode + '-theme',
      '340px'
    );
    (componentRef.instance as DeleteDialogComponent)?.closeDialogEvent
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          action(response);
        },
        error: (err) => {
          console.warn(err);
        },
        complete: () => {
          this.appUtil?.destroyOverlay();
        },
      });
  }

  updateSubnetsLoaderConfig(status = false, message = '', type = '') {
    this.subnetsLoader$ = of({
      status,
      message,
      type,
    } as LoaderConfig);
    this.cdr.markForCheck();
  }

  markStepComplete() {
    this.orchestratorService.setStepComplete(NetworkOnboardStep.VPC);
  }

  ngOnDestroy(): void {
    this.stopSubs$.next();
    this.stopSubs$.complete();
  }
}
