import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppMaterialModule } from './app-material.module';
import { CommonComponentsModule } from './component-common/common-components.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { HttpConfigInterceptor } from '@app/common/http/interceptor/httpconfig.interceptor';
import { PasswordFormStateResolver } from './password/password-form-state.resolver';
import { DrawerModule, InteractiveGuideModule } from '@prosimoio/components';
import { CommonUtil, WindowResizeService } from '@prosimoio/services';
import { UIThemePipeModule } from '@prosimoio/pipes';
import { DashboardCommonModule } from './common/dashboard-common.module';
import { I18nPluralPipe } from '@angular/common';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    AppRoutingModule,
    HttpClientModule,
    CommonComponentsModule,
    InteractiveGuideModule,
    DrawerModule,
    UIThemePipeModule,
    DashboardCommonModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    PasswordFormStateResolver,
    WindowResizeService,
    CommonUtil,
    I18nPluralPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
