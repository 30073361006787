<div id="generalTable">
  <div class="title col-0 print-hidden m-0 p-4" *ngIf="showTitle">
    <div class="row d-flex">
      <div class="col-2">
        <h2 class="mb-0">
          {{ tableCaption }}
          {{ tableCaptionValue ? ": " + tableCaptionValue : "" }}
        </h2>
      </div>
      <div class="d-flex flex-fill flex-column col-8">
        <div
          *ngIf="showAdvanceSearch"
          class="content-header d-flex justify-content-start align-items-center no-gutters"
          [ngClass]="{ 'expanded-header': displayTableFilter }"
        >
          <div class="w-100">
            <app-search
              [roundedCorners]="'LEFT'"
              (searchTerm)="searchTermChanged($event)"
            ></app-search>
          </div>
          <div
            class="text-left advance-search-display__control"
            (click)="toggleFilterRow($event)"
          >
            <div class="d-inline-flex align-items-center">
              Advanced
              <i class="material-icons toggle-icon align-middle">
                {{
                  displayTableFilter
                    ? "keyboard_arrow_up"
                    : "keyboard_arrow_down"
                }}
              </i>
            </div>
          </div>
        </div>
        <div class="position-relative w-100 h-100 w-75">
          <app-search-filter
            [class.hide]="!displayTableFilter"
            *ngIf="advanceSearchFields?.length"
            [filterRowColumns]="advanceSearchFields"
          ></app-search-filter>
        </div>
      </div>
    </div>
  </div>
  <div
    class="col-0 d-flex align-items-center justify-content-end"
    *ngIf="showPagination && _pageDetails"
  >
    <div class="d-flex align-items-center justify-content-end mb-1">
      <ng-container *ngIf="statusLegend?.length > 0">
        <div
          *ngFor="let legend of statusLegend"
          class="d-inline-flex align-items-center mr-2 status__container"
        >
          <div
            class="status__icon mr-1"
            [ngStyle]="{ 'background-color': legend?.fillColor }"
          ></div>
          <div class="status__label">= &nbsp; {{ legend?.label }}</div>
        </div>
      </ng-container>
    </div>
    <app-paginator
      #paginator
      [paginatorData]="paginatorData$ | async"
    ></app-paginator>
  </div>
  <table class="table table-striped table-fixed">
    <thead>
      <tr>
        <ng-container *ngFor="let header of tableDef.rowDef; let i = index">
          <th scope="col">
            <div
              class="row no-gutters"
              [ngClass]="header?.grids ? 'col-' + header.grids : 'col-12'"
            >
              <ng-container *ngIf="tableDef.showCheckboxes">
                <span>
                  <mat-checkbox
                    (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelectedRows()"
                    [interminate]="selection.hasValue() && !isAllSelectedRows()"
                    [aria-label]="checkboxLabel(header, i)"
                  ></mat-checkbox>
                </span>
              </ng-container>
              <ng-container *ngIf="!tableDef.showCheckboxes">
                <span class="d-inline-block"> {{ header?.displayName }}</span>
              </ng-container>
            </div>
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of data; let i = index">
        <ng-container *ngFor="let cellItem of tableDef?.rowDef">
          <ng-container>
            <td scope="row">
              <div
                [ngClass]="cellItem?.grids ? 'col-' + cellItem.grids : 'col-12'"
              >
                <ng-container *ngIf="tableDef.showCheckboxes">
                  <span>
                    <mat-checkbox
                      (click)="$event.stopPropagation()"
                      (change)="$event ? selectRow(item) : null"
                      [checked]="selection.isSelected()"
                      [aria-label]="checkboxLabel(item, i)"
                    >
                    </mat-checkbox>
                  </span>
                </ng-container>

                <ng-container *ngIf="!tableDef.showCheckboxes">
                  <!-- CSS Class Icons -->
                  <ng-container *ngIf="cellItem?.useCssClassIcons">
                    <div
                      [ngClass]="item?.cssClassIcons + ' ' + cellItem?.cssClass"
                    ></div>
                  </ng-container>

                  <!-- Image Icons -->
                  <ng-container *ngIf="cellItem?.imgIconsPath">
                    <img
                      [src]="cellItem?.imgIconsPath"
                      alt=""
                      [ngClass]="cellItem?.cssClass"
                    />
                  </ng-container>

                  <!-- Normal Data -->
                  <ng-container
                    *ngIf="
                      !cellItem?.useCssClassIcons && !cellItem?.imgIconsPath
                    "
                  >
                    <div
                      [ngClass]="cssClass"
                      class="d-flex align-items-center long-string"
                    >
                      <ng-container
                        *ngIf="cellItem?.dataType === COLUMN_DATA_TYPES.DATE"
                        >{{item?.[cellItem?.dataField]
                                                | date:'MM/dd/yy HH:mm:ss aa'}}</ng-container
                      >

                      <div
                        class="w-100"
                        *ngIf="cellItem?.dataType !== COLUMN_DATA_TYPES.DATE"
                      >
                        <!-- Data column for OS & Version -->
                        <ng-container
                          *ngIf="cellItem?.dataField === UTILITY_CONST.OS"
                        >
                          <ng-container
                            [ngTemplateOutlet]="osAndVersion"
                            [ngTemplateOutletContext]="{ item: item }"
                          >
                          </ng-container>
                        </ng-container>

                        <!-- Other columns -->
                        <ng-container
                          *ngIf="cellItem?.dataField !== UTILITY_CONST.OS"
                        >
                          {{item?.[cellItem?.dataField]}}
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </td>
          </ng-container>
        </ng-container>
      </tr>

      <tr scope="row" *ngIf="!data.length">
        <td [colSpan]="tableDef?.rowDef?.length" class="text-center bg-white">
          There doesn't seem to be any data available. Please adjust the filters
          and try again.
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #osAndVersion let-item="item">
  <ng-container *ngIf="item?.os?.toLowerCase() === UTILITY_CONST.WINDOWS">
    <span [attr.title]="item?.os | uppercase"
      >Windows {{ item.osDetails.major }}</span
    >
  </ng-container>
  <ng-container *ngIf="item?.os?.toLowerCase() === UTILITY_CONST.MAC">
    <img
      class="os-icon"
      [attr.src]="IMAGE_PATHS[item?.os | uppercase]"
      [attr.title]="item?.os | uppercase"
    />
  </ng-container>
  <div class="os_version" *ngIf="item?.osDetails">
    {{ item.osDetails.major }}.{{ item.osDetails.minor }}.{{
      item.osDetails.patch
    }}
  </div>
</ng-template>
