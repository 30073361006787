import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { HelpModel } from './help.model';
import { HttpClient } from '@angular/common/http';
import { ResourceService } from '@app/common/http/util/resource.service';
import { CONSTANTS } from 'environments/environment';
import { HelpModelSerializer } from './help.model.serializer';
@Injectable({
  providedIn: 'root',
})
export class HelpService extends ResourceService<any> {
  private helpContents$ = new Subject<HelpModel>();
  private refreshRequest$ = new Subject<boolean>();
  private searchTerm$ = new Subject<string>();
  private articleID$ = new Subject<string>();

  constructor(protected httpClient: HttpClient) {
    super(httpClient, CONSTANTS.DASHBOARD_HOST, '', new HelpModelSerializer());
  }

  setHelpConfig(helpConfig: HelpModel) {
    this.helpContents$.next(helpConfig);
  }

  getHelpConfig(): Observable<HelpModel> {
    return this.helpContents$;
  }

  setRefreshRequest(refreshRequest: boolean) {
    this.refreshRequest$.next(refreshRequest);
  }

  getRefreshRequest(): Observable<boolean> {
    return this.refreshRequest$;
  }

  // Sets the help article search term
  setSearchTerm(searchTerm: string) {
    this.searchTerm$.next(searchTerm);
  }

  // returns the help article search term
  getSearchTerm(): Observable<string> {
    return this.searchTerm$;
  }

  // Sets the help article id
  setArticleID(articleID: string) {
    this.articleID$.next(articleID);
  }

  // returns the article ID
  getArticleID(): Observable<string> {
    return this.articleID$;
  }

  getPageConfigs(): Observable<any> {
    this.path = CONSTANTS.PAGE_CONFIG;
    return this.read('');
  }
}
