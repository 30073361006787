import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import {
  SubmitTypes,
  RequestStatus,
  TicketSupportModel,
  TicketSubmitModel,
} from './feedback-form.model';
import { FeedbackFormService } from './feedback-form.service';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackFormComponent implements OnInit, OnDestroy {
  topics: Array<any>;
  displayForm: boolean = false;
  iconURL: string;
  selectedSubmitType: string;
  submitForm: UntypedFormGroup;
  requestStatus: string;
  responseMsg: string;
  SUBMIT_TYPES = SubmitTypes;
  FEEDBACK = 'feedback';
  STATUSES = RequestStatus;
  subscriptions = [];

  @Output() formSubmit = new EventEmitter();

  @Input('config') set config(data: TicketSupportModel) {
    this.onConfigChange(data);
  }

  constructor(
    private feedbackFormService: FeedbackFormService,
    private cdr: ChangeDetectorRef,
    private fb: UntypedFormBuilder
  ) {
    this.subscriptions.push(
      this.feedbackFormService.getFeedbackFormConfig().subscribe(
        (data: TicketSupportModel) => {
          this.onConfigChange(data);
          this.togglePopup();
        },
        (error) => {
          console.warn(
            'FeedbackFormComponent...getFeedbackFormConfig...',
            error
          );
        }
      )
    );
  }

  ngOnInit(): void {}

  /**
   * Initialized the frm
   */
  initPopup() {
    this.initSubmitForm();
    this.setDefaultSubmitType();
  }

  /**
   * Initialized the form
   */
  initSubmitForm() {
    this.submitForm = this.fb.group({
      comment: [''],
      topics: [[]],
    });
  }

  /**
   * Set default submit form type
   */
  setDefaultSubmitType() {
    const [{ value }] = this.SUBMIT_TYPES || [{}];
    this.selectedSubmitType = value;
  }

  /**
   * toggle popup form
   */
  togglePopup() {
    this.displayForm = !this.displayForm;
    this.initPopup();
    this.cdr.markForCheck();
  }

  /**
   * hide the form;
   */
  onCancel() {
    this.togglePopup();
    this.requestStatus = null;
    this.responseMsg = null;
    this.cdr.markForCheck();
  }

  /**
   * Select submit form type
   * @param event
   * @param type
   */
  onSelectSubmitType(event: any, type) {
    this.selectedSubmitType = type;
    this.cdr.markForCheck();
  }

  /**
   * Emit the data model
   */
  onSubmit() {
    const { topics = [], comment } = this.submitForm.getRawValue();
    const dataObj = {
      type: this.selectedSubmitType,
      subject: topics.toString(),
      tags:
        this.selectedSubmitType === this.FEEDBACK
          ? [...topics, this.FEEDBACK]
          : [...topics],
      comment: {
        body: comment,
      },
    };
    this.formSubmit.emit(dataObj as TicketSubmitModel);
  }

  onConfigChange({
    topics = [],
    displayForm = false,
    iconURL = '',
    requestStatus = '',
    responseMsg = '',
  }) {
    this.topics = topics;
    this.displayForm = displayForm;
    this.iconURL = iconURL;
    this.requestStatus = requestStatus;
    this.responseMsg = responseMsg;
  }

  ngOnDestroy() {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }
}
