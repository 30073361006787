import { LAST_UPDATED_TEXT } from '@app/common/util/constants';
import { Tab } from '../../../../../management/management.model';

// Constants for Tabs in the Create New/Edit Access Policy form.
export const TAB_CONSTANTS = {
  NAMESPACE: 'Namespace',
  SET_ACTIONS: 'Set Actions',
  MATCH_CONDITIONS: 'Match Conditions',
  SELECT_TARGETS: 'Select Targets',
};

// Constant for Tab config in the Create New/Edit Access Policy form.
export const ACCESS_POLICY_TABS = [
  {
    name: 'Namespace',
    isSelected: true,
    key: TAB_CONSTANTS.NAMESPACE,
    code: TAB_CONSTANTS.NAMESPACE,
    order: 1,
  } as Tab,
  {
    name: 'Set Actions',
    isSelected: false,
    key: TAB_CONSTANTS.SET_ACTIONS,
    code: TAB_CONSTANTS.SET_ACTIONS,
    order: 2,
  } as Tab,
  {
    name: 'Select Targets',
    isSelected: false,
    key: TAB_CONSTANTS.SELECT_TARGETS,
    code: TAB_CONSTANTS.SELECT_TARGETS,
    order: 4,
  } as Tab,
  {
    name: 'Match Conditions',
    isSelected: false,
    key: TAB_CONSTANTS.MATCH_CONDITIONS,
    code: TAB_CONSTANTS.MATCH_CONDITIONS,
    order: 3,
  } as Tab,
];

// Constant for Tab config in the Create New/Edit Access Policy form.
export const MCN_ACCESS_POLICY_TABS = [
  {
    name: 'Namespace',
    isSelected: false,
    key: TAB_CONSTANTS.NAMESPACE,
    code: TAB_CONSTANTS.NAMESPACE,
    order: 3,
  } as Tab,
  {
    name: 'Set Actions',
    isSelected: false,
    key: TAB_CONSTANTS.SET_ACTIONS,
    code: TAB_CONSTANTS.SET_ACTIONS,
    order: 1,
  } as Tab,
  {
    name: 'Select Targets',
    isSelected: false,
    key: TAB_CONSTANTS.SELECT_TARGETS,
    code: TAB_CONSTANTS.SELECT_TARGETS,
    order: 2,
  } as Tab,

  {
    name: 'Match Conditions',
    isSelected: false,
    key: TAB_CONSTANTS.MATCH_CONDITIONS,
    code: TAB_CONSTANTS.MATCH_CONDITIONS,
    order: 4,
  } as Tab,
];

// Constant for the Actions title displayed in the 'Set Actions' tab in the Create NEw/Edit Access Policy form.
export const SET_ACTIONS_CONSTANT_TITLE =
  'Set an action to apply to the policy';

// Constant for the Actions title displayed below the button in the 'Set Actions' tab in the Create NEw/Edit Access Policy form.
export const ACTIONS_TITLE = ' with the following actions';

// Constant for the Action buttons available in the 'Set Actions' tab in the Create NEw/Edit Access Policy form.
export const ACTION_BUTTONS_OPTIONS = {
  ALLOW: 'Allow',
  DENY: 'Deny',
};

// Constant for the Action buttons available in the 'Set Actions' tab in the Create NEw/Edit Access Policy form.
export const POLICY_ACTION_BUTTONS_OPTIONS = {
  ALLOW: 'allow',
  DENY: 'deny',
};

// Constant for Action buttons available in the 'Set Actions' tab in the Create NEw/Edit Access Policy form.
export const ACTION_BUTTONS = [
  ACTION_BUTTONS_OPTIONS.ALLOW,
  ACTION_BUTTONS_OPTIONS.DENY,
];

// Constants for Access Policy.
export const ACCESS_POLICY_CONSTANTS = {
  CREATE_FORM_TITLE: 'Create New Access Policy',
  ACCESS: 'access',
  TRANSIT: 'transit',
  LOCATION: 'Location',
};

// Constants used in the Create New/Edit Access Policy form.
export const ACCESS_POLICY_FORM_CONSTANTS = {
  CREATE_PAGE_TITLE: 'Create New Policy',
  ACCESS_POLICY_ACTION: 'accessPolicyAction',
  ACCESS_POLICY_OPTION: 'accessPolicyOptions',
  ACCESS_POLICY_CONDITIONS: 'accessPolicyConditions',
  PROPERTY: 'property',
  OPERATION: 'operation',
  VALUES: 'values',
  SOURCES: 'source',
  OPERATOR: 'operator',
  USERS: 'users',
  LOCATION: 'location',
  IDP: 'idp',
  DEVICES: 'devices',
  TIME: 'time',
  HTTP_PARAMETERS: 'httpparameters',
  DEVICE_POSTURE: 'deviceposture',
  DEVICE_POSTURE_PROFILE: 'devicepostureprofile',
  PAAS_SERVICES: 'paasservices',
  URL: 'url',
  FQDN: 'fqdn',
  ADVANCED: 'advanced',
  ACCESS: 'access',
  YES: 'Yes',
  NO: 'No',
  NO_CONDITIONS_SELECTED: 'No Conditions have been selected',
  AND: 'and',
  OR: 'or',
  TEXT: 'text',
  BETWEEN: 'between',
  FETCH_GROUP: 'fetchGroup',
  FETCH_OPTION: 'fetchOption',
  OPTION: 'option',
  MULTIPLE_TEXT: 'multipleText',
  DIALOG_TIME: '500ms',
  DIALOG_TEXT_FOR_ACCESS_POLICY_ALLOW_OPTION_FOR_BYPASS: `<div class="title">Bypass can’t be turned on if Enforce MFA is on. </div>  <div class="status-failed-clr mt-3">Turn off Enforce MFA.</div>`,
  DIALOG_TEXT_FOR_ACCESS_POLICY_ALLOW_OPTION_FOR_MFA: `<div class="title">Enforce MFA can’t be turned on if Bypass IDP is on. </div>  <div class="status-failed-clr mt-3">Turn off Bypass IDP.</div>`,
  DIALOG_WIDTH: '450px',
  DIALOG_TEXT_FOR_ACCESS_POLICY_ALLOW_OPTION_HEIGHT: '224px',
  DIALOG_TEXT_FOR_ACCESS_POLICY_DENY_OPTION: `This action setting will lock users from the fabric of all apps, once the condition is triggered. Users will remain locked out until they are unlocked by the Admin from the user settings page.`,
  DIALOG_TEXT_FOR_ACCESS_POLICY_DENY_OPTION_HEIGHT: '280px',
  AMAZON_S3: 'amazon_s3',
  NAME: 'name',
  IP_ADDRESS: 'IP Prefix/Address',
  FETCH: 'fetch',
  DELETE: 'Delete Access Policy',
  CREATED_MESSAGE: LAST_UPDATED_TEXT,
  AMAZONS3: 'Amazon S3',
  VALUES_FORM_FIELD_LABEL: 'Select Values',
  VALUES_FORM_FIELD_LABEL_FOR_TEXT: 'Enter Values',
  VALUES_FORM_FIELD_LABEL_FOR_FETCH_OPTION: 'Select Values',
  TRUSTED_DEVICE_CERTIFICATE: 'Trusted Device Certificate',
  OR_OPERATOR: 'OR',
  COUNTRY: 'Country',
  TYPE: 'type',
  RULELIST: 'ruleList',
  DEFAULT_CIDR: '0.0.0.0',
  DEFAULT_CIDR_MASK: '/0',
  SUB_NET_MASK: '/32',
  NOT: 'not',
  NA: 'N/A',
  ITEM: 'item',
  ATTRIBUTE: 'Attribute',
  DEFAULT: 'default',
  NETWORK: 'Network',
  ID: 'id',
  SELECTED_ITEMS: 'selectedItems',
  SELECTED_GROUPS: 'selectedGroups',
  OPTIONS: 'option',
  ACTION: 'action',
  API_NETWORK: 'network',
  API_NETWORK_GROUP: 'network-group',
  INDEX: 'index',
  POLICY_DELETE_ERROR_MSG:
    'Policies cannot be deleted when apps/networks are attached to it. Please remove the attached apps/networks and try again.',
  ERROR_TITLE: 'Failed!',
  TYPE_OPTION: 'options',
  CA: 'CA',
  INPUT_ITEMS: 'inputItems',
  MATCH_CONDITIONS_CONFLICT_MSG: (condition: string, matches: string[]) =>
    `To enable ${condition} you first have to remove  ${matches?.join(
      ' and '
    )} from match conditions.`,
};

// Constants for the Group Name used in the conditions panel in the 'Match Conditions' tab in the Create New/Edit Access Policy form.
export const GROUP_NAME = {
  URL: 'URL',
  PAAS_SERVICES: 'Paas Services',
  FQDN: 'FQDN',
  ADVANCED: 'Advanced',
  HTTP_PARAMETERS: 'Http Parameters',
};

// Constants for the Group used in the conditions panel in the 'Match Conditions' tab in the Create New/Edit Access Policy form.
export const GROUP = {
  URL: 'url',
  PAAS_SERVICES: 'passServices',
  FQDN: 'fqdn',
  ADVANCED: 'advanced',
  HTTP_PARAMETERS: 'httpParameters',
};

export const ACCESS_POLICY_CONDITIONS_TOOLTIP = {
  users:
    'Use email addresses or User Groups to allow or deny application access for users.',
  location:
    'Allow or deny access based on location using IP addresses, CIDR blocks, or geographic references to countries, states, or cities.',
  idp: 'Control access using SAML attributes received from the IDP configured in Prosimo, excluding reserved attributes: first name, last name, email, name, group, team, given name, and surname.',
  devices:
    'Manage access based on device attributes like OS, browser, and certificates. Retrieve information from the user-agent string using',
  time: 'Specify the time period during which access should either be allowed or denied.',
  httpparameters:
    'Grant or restrict access based on the Full Qualified Domain Name (FQDN) or Hostname, entire URL or any segment, and the HTTP Method from the supported headers list.',
  devicepostureprofile:
    'Grant or restrict access based on the risk level of the connecting device.',
  paasservices:
    'Authorize or restrict access to the source of the PaaS Service, such as a specific folder within an S3 bucket.',
  networkacl: `Allow or block session access based on IP (source and destination), port, and protocol. For IP, enter a specific address or choose 'any’. Ports can be 0, 65535, or 'any’.`,
  sourcenetworks:
    'Allow or deny access based on the originating source network for the session.',
};

// Constants for the conditions in the conditions panel in the 'Match Conditions' tab in the Create New/Edit Access Policy form.
export const ACCESS_POLICY_CONDITIONS = {
  USERS: 'Users',
  LOCATION: 'Location',
  IDP: 'IDP',
  DEVICES: 'Devices',
  TIME: 'Time',
  HTTP_PARAMETERS: 'Http Parameters',
  DEVICE_POSTURE: 'Device Posture Profile',
  DEVICE_POSTURES: 'Device Posture',
  PAAS_SERVICES: 'Paas Services',
  URL: 'URL',
  FQDN: 'FQDN',
  ADVANCED: 'Advanced',
  HTTP_METHOD: 'HTTP Method',
  NETWORK_ACL: 'Network ACL',
  SOURCES_NETWORK: 'Source Networks',
  NETWORKS_ACL: 'Networks ACL',
  NETWORKS: 'Networks',
  HTTP_PARAMETER: 'HTTP Parameters',
  PAAS_SERVICE: 'PaaS Services',
  EGRESS_FQDN: 'Egress FQDN',
  EGRESS_DOMAIN: 'Egress DOMAIN',
};

// Constants for the Selected Condition Payload object name.
export const SELECTED_CONDITON_PAYLOAD_DETAILS = {
  USERS: 'users',
  NETWORKS: 'networks',
  IDP: 'idp',
  DEVICES: 'devices',
  TIME: 'time',
  URL: 'url',
  DEVICE_POSTURE_PROFILES: 'devicePostureProfiles',
  FQDN: 'fqdn',
  ADVANCED: 'advanced',
  NETWORK_ACL: 'networkACL',
  SOURCES_NETWORK: 'prosimoNetworks',
  IN: 'In',
  SOURCE_IP: 'sourceIP',
  TARGET_IP: 'targetIP',
  PROTOCOL: 'protocol',
  SOURCE_PORT: 'sourcePort',
  TARGET_PORT: 'targetPort',
  KEY_VALUES: 'keyValues',
  SOURCES: 'sources',
  SOURCE_NETWORKS: 'sourcenetworks',
  LOCATION: 'geoip_country_code',
  EGRESS_FQDN: 'egressFqdns',
  EGRESS_FQDNS: 'egressFqdns',
  VALUES: 'values',
  PROPERTY: 'property',
  OPERATION: 'operation',
  FQDN_MATCH: 'fqdnMatch',
  FQDN_INVERSE_MATCH: 'fqdnInverseMatch',
};

// pAgent access types.
export const ACCESS_TYPES = {
  AGENT: 'agent',
  AGENTLESS: 'agentless',
  AGENT_CONSOLIDATED: 'consolidate-agent',
  STREAM: 'stream',
  AGENT_AND_STREAM: 'agentAndStream',
  ANY: 'any',
  CLIENT_APPLICATION_GROUPS: 'clientApplicationGroup',
};

// Constant for 'AND' Operator used in the 'Match Conditions' tab in the Create New/Edit Access Policy form.
export const ACCESS_POLICY_AND_OPERATOR = [
  'Is-not',
  'Does-not-contain',
  'Not-in',
];

// Constants for Operators used in the 'Match Conditions' tab in the Create New/Edit Access Policy form.
export const ACCESS_POLICY_OPERATOR = {
  AND: 'AND',
  OR: 'OR',
};

// Constants for the 'Allow' action in the 'Set Actions' tab in the Create New/Edit Access Policy form.
export const POLICY_ALLOW_ACTIONS = {
  ALLOW: 'allow',
  MFA: 'mfa',
  SKIP_WAF: 'skipWAF',
  BYPASS: 'bypass',
  ALERT: 'alert',
};

// Constants for the 'Allow' action config in the 'Set Actions' tab in the Create New/Edit Access Policy form.
export const POLICY_ACTIONS_ALLOW = [
  {
    displayName: 'Enforce MFA',
    name: POLICY_ALLOW_ACTIONS.MFA,
    options: [
      {
        name: 'No',
        isDefaultchecked: true,
      },
      {
        name: 'Yes',
        isDefaultchecked: false,
      },
    ],
  },
  {
    displayName: 'Skip WAF',
    name: POLICY_ALLOW_ACTIONS.SKIP_WAF,
    options: [
      {
        name: 'No',
        isDefaultchecked: true,
      },
      {
        name: 'Yes',
        isDefaultchecked: false,
      },
    ],
  },
  {
    displayName: 'Send Alert',
    name: POLICY_ALLOW_ACTIONS.ALERT,
    options: [
      {
        name: 'No',
        isDefaultchecked: true,
      },
      {
        name: 'Yes',
        isDefaultchecked: false,
      },
    ],
  },
  {
    displayName: 'Bypass IDP',
    name: POLICY_ALLOW_ACTIONS.BYPASS,
    options: [
      {
        name: 'No',
        isDefaultchecked: true,
      },
      {
        name: 'Yes',
        isDefaultchecked: false,
      },
    ],
  },
];

// Constants for the 'Deny' action in the 'Set Actions' tab in the Create New/Edit Access Policy form.
export const POLICY_DENY_ACTIONS = {
  LOCK: 'lockUser',
  ALERT: 'alert',
};

// Constants for the 'Deny' action config in the 'Set Actions' tab in the Create New/Edit Access Policy form.
export const POLICY_ACTIONS_DENY = [
  {
    displayName: 'Lock Users across all apps',
    name: POLICY_DENY_ACTIONS.LOCK,
    options: [
      {
        name: 'No',
        isDefaultchecked: true,
      },
      {
        name: 'Yes',
        isDefaultchecked: false,
      },
    ],
  },
  {
    displayName: 'Send Alert',
    name: POLICY_DENY_ACTIONS.ALERT,
    options: [
      {
        name: 'No',
        isDefaultchecked: true,
      },
      {
        name: 'Yes',
        isDefaultchecked: false,
      },
    ],
  },
];

// Constant for the help text displayed in the 'Match Conditions' tab, property form field in the Create New/Edit Access Policy form.
export const HELP_TEXT = ['Browser', 'Browser Version', 'Amazon S3', 'URL'];

// Constant for the help text displayed in the 'Match Conditions' tab in the Create New/Edit Access Policy form.
export const POLICY_MATCHES_HELP_TEXT = {
  browser: 'Policy is applicable only to Web, Jumpbox and VDI apps',
  amazons3: 'Policy is applicable only to S3 apps',
  url: 'Policy is applicable only to Web apps',
  browserversion: 'Policy is applicable only to Web, Jumpbox and VDI apps',
};

// Constants for the conditions form fields in the 'Match Conditions' tab in the Create New/Edit Access Policy form.
export const ACCESS_POLICY_FORM_FIELDS = [
  'property',
  'operation',
  'values',
  'operator',
];

// Constants for the form fields in the 'Match Conditions' tab in the Create New/Edit Access Policy form.
export const FORM_FIELDS = [
  'users',
  'location',
  'idp',
  'devices',
  'httpparameters',
  'deviceposture',
  'paasservices',
  'time',
];

// Constants for the form fields used in the 'Match Conditions' tab in the Create New/Edit Access Policy form.
export const FORM_FIELDS_DETAILS = {
  USERS: 'users',
  LOCATION: 'location',
  IDP: 'idp',
  DEVICES: 'devices',
  HTTP_PARAMETERS: 'httpparameters',
  DEVICE_POSTURE: 'deviceposture',
  PAAS_SERVICES: 'paasservices',
  TIME: 'time',
  NETWORKS_ACL: 'networkACL',
  NETWORKS: 'networks',
  EGRESS_FQDNS: 'egressFqdns',
  DOMAIN: 'domain',
};

// Constants for the buttons used in the Create New/Edit Access Policy form.
export const BUTTON_LABELS = {
  NEXT: 'Next',
  SAVE: 'Save',
};

// Constants for the Rule List Pane in the 'Match Conditions' tab in the Create New/Edit Access Policy form.
export const RULE_LIST_PANE_ACTION = {
  EDIT: 'edit',
  DELETE: 'delete',
};

// Constant for the Access Policy form buttons.
export const ACCESS_POLICY_FORM_BUTTON = {
  CANCEL_BTN_CONFIG: { code: 'CANCEL', name: 'Cancel', disabled: false },
  UPDATE_BTN_CONFIG: { code: 'UPDATE', name: 'Update', disabled: false },
  NEXT_BTN_CONFIG: { code: 'NEXT', name: 'Next', disabled: false },
  NEXT_BTN_CONFIG_DISABLE: { code: 'NEXT', name: 'Next', disabled: true },
  SAVE_BTN_CONFIG: { code: 'SAVE', name: 'Save', disabled: false },
  SAVE_BTN_DISABLE_CONFIG: { code: 'SAVE', name: 'Save', disabled: true },
  ASSIGN_BTN_CONFIG: { code: 'ASSIGN', name: 'Assign', disabled: false },
  ASSIGN_BTN_CONFIG_DISABLE: { code: 'ASSIGN', name: 'Assign', disabled: true },
  CREATE_BTN_CONFIG: { code: 'CREATE', name: 'Create', disabled: false },
  CREATE_BTN_CONFIG_DISABLE: { code: 'CREATE', name: 'Create', disabled: true },
};

// Constants for the Drawer component.
export const DRAWER_COMPONENT = {
  WIDTH: '618px',
  DEFAULT_PADDING: 'p-0',
  DELETE_DIALOG_WIDTH: '294px',
  DELETE_POLICY_DIALOG_MESSAGE: 'Are you sure you want to delete?',
  DELETE_POLICY_BTN_NAME: 'Delete',
  PANEL_CLASS: 'deleteDialog_',
};

// Constants for the 'Select Targets' tab in the Create New/Edit Access Policy form.
export const SELECT_TARGETS_TAB = {
  APP_TITLE: 'Apply Policy to these Apps',
  NETWORK_TITLE: 'Apply Policy to these Networks',
  NO_APPS_AND_NETWORKS_AVAILABLE_MSG: `No applications have been configured for on-boarding. Policies can be saved now in
  the library and attached to the applications later.`,
};

// Constants for the Validation error messages.
export const VALIDATION_ERRORS = {
  TIME_VALIDATION_BETWEEN_OPERATION: `Time must be 24 hour format. Eg
  00:01-23:20`,
  TIME_VALIDATION: 'Time must be 24 hour format. Eg 11.30',
  CIDR_VALIDATION: 'Enter valid CIDR. Eg 10.0.0.0/32',
  USER_NAME_VALIDATION: 'Invalid Value. Please enter comma separated values.',
};

// Constants for the Location condition in the 'Match Conditions' tab in the Create New/Edit Access Policy form field.
export const LOCATION = {
  COUNTRY: 'country',
  STATE: 'state',
  CITY: 'city',
};

// Constants for Overview dialog box.
export const OVERVIEW_DIALOG = {
  DIALOG_WIDTH: '698px',
  DIALOG_HEIGHT: '576px',
};

// Constants for Location details.
export const LOCATIONS_DATA = {
  STATE: 'state_name',
  CITY: 'city_name',
  COUNTRY: 'country_name',
  PAGE_SIZE: 1000,
};

// Constants for the default Policies.
export const DEFAULT_POLICIES = [
  'ALLOW-ALL-USERS',
  'DENY-ALL-USERS',
  'ALLOW-ALL-NETWORKS',
  'DENY-ALL-NETWORKS',
];

// Constant for Tab config in the Create New/Edit Access Policy form for the Default Policies.
export const DEFAULT_ACCESS_POLICY_TABS = [
  {
    name: 'Select Targets',
    isSelected: true,
    key: TAB_CONSTANTS.SELECT_TARGETS,
    code: TAB_CONSTANTS.SELECT_TARGETS,
    order: 1,
  } as Tab,
];

// Constant for Product Type.
export const PRODUCT_TYPE = {
  MCN: 'mcn',
  ZTNA: 'ztna',
};

// Constants for the Status of Rules.
export const RULE_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'InActive',
};

// Constants for the Pro-Transit form fields.
export const ACCESS_POLICY_FORM_PRO_TRANSIT_FIELDS = {
  NETWORKACL: 'networkacl',
  NETWORKS: 'sourcenetworks',
  PROTOCOL: 'Protocol',
  EGRESS_FQDN: 'egressFqdns',
};

// Constants for the conditions available in the Pro-Transit.
export const PRO_TRANSIT_ACCESS_POLICY_CONDITIONS = {
  TIME: 'Time',
  NETWORKS: 'Networks',
  NETWORKS_ACL: 'Network ACL',
  PAAS_SERVICES: 'Paas Services',
  SOURCES_NETWORK: 'Source Networks',
  HTTP_PARAMETERS: 'Http Parameters',
  FQDN: 'Fqdn',
  ADVANCED: 'Advanced',
  EGRESS_FQDN: 'Egress FQDN',
};

// Constants for the Payload details for the Pro-Transit.
export const PRO_TRANSIT_PAYLOAD_DETAILS = {
  TIME: 'time',
  NETWORKS: 'networks',
  NETWORKS_ACL: 'networkACL',
  SOURCES_NETWORK: 'prosimoNetworks',
  FQDN: 'fqdn',
  ADVANCED: 'advanced',
};

export const MATCH_CONDITIONS_KEY_VALUES = {
  [PRO_TRANSIT_PAYLOAD_DETAILS.TIME]: PRO_TRANSIT_ACCESS_POLICY_CONDITIONS.TIME,
  [PRO_TRANSIT_PAYLOAD_DETAILS.NETWORKS_ACL]:
    PRO_TRANSIT_ACCESS_POLICY_CONDITIONS.NETWORKS_ACL,
  [PRO_TRANSIT_PAYLOAD_DETAILS.NETWORKS]:
    PRO_TRANSIT_ACCESS_POLICY_CONDITIONS.NETWORKS,
  [ACCESS_POLICY_FORM_CONSTANTS.API_NETWORK]:
    PRO_TRANSIT_ACCESS_POLICY_CONDITIONS.NETWORKS,
  [PRO_TRANSIT_PAYLOAD_DETAILS.SOURCES_NETWORK]:
    PRO_TRANSIT_ACCESS_POLICY_CONDITIONS.SOURCES_NETWORK,
  [PRO_TRANSIT_PAYLOAD_DETAILS.FQDN]: ACCESS_POLICY_CONDITIONS.FQDN,
  [PRO_TRANSIT_PAYLOAD_DETAILS.ADVANCED]:
    PRO_TRANSIT_ACCESS_POLICY_CONDITIONS.ADVANCED,
  [FORM_FIELDS_DETAILS.USERS]: ACCESS_POLICY_CONDITIONS.USERS,
  [FORM_FIELDS_DETAILS.LOCATION]: ACCESS_POLICY_CONDITIONS.LOCATION,
  [FORM_FIELDS_DETAILS.IDP]: ACCESS_POLICY_CONDITIONS.IDP,
  [FORM_FIELDS_DETAILS.DEVICES]: ACCESS_POLICY_CONDITIONS.DEVICES,
  [FORM_FIELDS_DETAILS.HTTP_PARAMETERS]:
    ACCESS_POLICY_CONDITIONS.HTTP_PARAMETERS,
  [FORM_FIELDS_DETAILS.DEVICE_POSTURE]:
    ACCESS_POLICY_CONDITIONS.DEVICE_POSTURES,
  [FORM_FIELDS_DETAILS.PAAS_SERVICES]: ACCESS_POLICY_CONDITIONS.PAAS_SERVICES,
  method: ACCESS_POLICY_CONDITIONS.HTTP_METHOD,
  url: ACCESS_POLICY_CONDITIONS.URL,
  [ACCESS_POLICY_FORM_CONSTANTS.AMAZON_S3]:
    ACCESS_POLICY_FORM_CONSTANTS.AMAZONS3,
  [FORM_FIELDS_DETAILS.EGRESS_FQDNS]: ACCESS_POLICY_CONDITIONS.EGRESS_FQDN,
  [FORM_FIELDS_DETAILS.DOMAIN]: ACCESS_POLICY_CONDITIONS.EGRESS_DOMAIN,
};
// Constants for the Pro-Transit Network ACL condition.
export const NETWORK_ACL_PROPERTIES = [
  'Source IP',
  'Source Port',
  'Target IP',
  'Target Port',
  'Protocol',
];

// Constants for the Pro-Transit Egress FQDN  form fields.
export const EGRESS_FQDN_DETAILS = {
  SOURCE: 'Source',
  PORT: 'Port',
  PROTOCOL: 'Protocol',
  PROPERTY: 'Property',
  VALUES: 'Values',
  FQDN_MATCH: 'FQDN is',
  FQDN_INVERSE_MATCH: 'FQDN is not',

  // FQDN_MATCH: 'fqdnMatch',
  // FQDN_INVERSE_MATCH: 'fqdnInverseMatch',
};
// Constants for the Pro-Transit Egress FQDN condition.
export const EGRESS_FQDN_PROPERTIES = [
  EGRESS_FQDN_DETAILS.PROTOCOL,
  EGRESS_FQDN_DETAILS.PORT,
  EGRESS_FQDN_DETAILS.SOURCE,
  EGRESS_FQDN_DETAILS.PROPERTY,
  EGRESS_FQDN_DETAILS.FQDN_MATCH,
  EGRESS_FQDN_DETAILS.FQDN_INVERSE_MATCH,
];

export const EGRESS_FQDN_KEYS = {
  port: 'targetPort',
  protocol: 'protocol',
  source: 'sourceIP',
  property: 'property',
  values: 'values',
  operation: 'operation',
  FQDN_MATCH: 'fqdnMatch',
  FQDN_INVERSE_MATCH: 'fqdnInverseMatch',
};

// Constants for the Pro-Transit Network ACL form fields.
export const NETWORK_ACL_DETAILS = {
  SOURCE_IP: 'Source IP',
  TARGET_IP: 'Target IP',
  SOURCE_PORT: 'Source Port',
  TARGET_PORT: 'Target Port',
  PROTOCOL: 'Protocol',
};

export const DELETE_DIALOG_POSITION = {
  positionTop: '45vh',
  positionRight: '13vw',
};

// Constant for the Location condition properties.
export const LOCATION_CONDITION_PROPERTIES = {
  COUNTRY: 'Country',
  STATE: 'State',
  CITY: 'City',
};

// Constants for Warning dialog.
export const WARNING_DIALOG_BUTTONS = {
  OK: 'OK',
  YES_PROCEED: 'Yes, Proceed',
};

export const MAT_OPTION_CLASSNAME = 'mat-option-text';

// Constants for mat-select-search component.
export const MAT_SELECT_SEARCH_CONSTANTS = {
  SELECT_PROTOCOL: 'Select Protocol',
  ENTER_SOURCES: 'Enter Sources',
  KEY_NAME: 'name',
  ENTER_VALUES: 'Select Values',
  SELECT_ONE: 'Select One',
  SELECT_PROPERTY: 'Select Property',
  ADD_TO_LIST: 'Add To List',
  DELIMITER: ',',
};

export const TOOLTIP_POSITION = {
  AFTER: 'after',
};
export const CONNECTIVITY_TYPE = {
  PUBLIC: 'public',
  PRIVATE: 'private',
};

export const MCN_SELECT_TARGETS_TAB_NAMES = {
  APPLICATIONS: 'Applications',
  NETWORKS: 'Networks',
  INTERNET: 'Internet',
};

export const MCN_SELECT_TARGETS_TAB_LABELS = {
  APPLICATIONS: 'apps',
  NETWORKS: 'sourcenetworks',
  INTERNET: 'internet',
};

export const MCN_SELECT_TAB_INTERNET_TAB = {
  label: MCN_SELECT_TARGETS_TAB_LABELS.INTERNET,
  displayLabel: MCN_SELECT_TARGETS_TAB_NAMES.INTERNET,
  isActive: false,
  isValid: false,
  payloadKey: MCN_SELECT_TARGETS_TAB_LABELS.INTERNET,
  isDisabled: false,
  isError: false,
};

export const MCN_SELECT_TARGETS = [
  {
    label: MCN_SELECT_TARGETS_TAB_LABELS.APPLICATIONS,
    displayLabel: MCN_SELECT_TARGETS_TAB_NAMES.APPLICATIONS,
    isActive: true,
    isValid: false,
    payloadKey: MCN_SELECT_TARGETS_TAB_LABELS.APPLICATIONS,
    isDisabled: false,
    isError: false,
  },
  {
    label: MCN_SELECT_TARGETS_TAB_LABELS.NETWORKS,
    displayLabel: MCN_SELECT_TARGETS_TAB_NAMES.NETWORKS,
    isActive: false,
    isValid: false,
    payloadKey: MCN_SELECT_TARGETS_TAB_LABELS.NETWORKS,
    isDisabled: false,
    isError: false,
  },
];

export const MCN_ACCESS_CONDITIONS_APPLICATION_BASED_TABS = [
  PRO_TRANSIT_ACCESS_POLICY_CONDITIONS.SOURCES_NETWORK,
  PRO_TRANSIT_ACCESS_POLICY_CONDITIONS.NETWORKS_ACL,
  PRO_TRANSIT_ACCESS_POLICY_CONDITIONS.TIME,
  PRO_TRANSIT_ACCESS_POLICY_CONDITIONS.HTTP_PARAMETERS,
  PRO_TRANSIT_ACCESS_POLICY_CONDITIONS.PAAS_SERVICES,
];

export const MCN_ACCESS_CONDITIONS_NETWORKS_BASED_TABS = [
  PRO_TRANSIT_ACCESS_POLICY_CONDITIONS.SOURCES_NETWORK,
  PRO_TRANSIT_ACCESS_POLICY_CONDITIONS.NETWORKS_ACL,
  PRO_TRANSIT_ACCESS_POLICY_CONDITIONS.TIME,
];

export const MCN_ACCESS_CONDITIONS_INTERNET_BASED_TABS = [
  PRO_TRANSIT_ACCESS_POLICY_CONDITIONS.SOURCES_NETWORK,
  PRO_TRANSIT_ACCESS_POLICY_CONDITIONS.NETWORKS_ACL,
  // PRO_TRANSIT_ACCESS_POLICY_CONDITIONS.TIME,
  PRO_TRANSIT_ACCESS_POLICY_CONDITIONS.EGRESS_FQDN,
];

export const MCN_ACCESS_CONDITIONS_ALL_TABS = [
  ...MCN_ACCESS_CONDITIONS_APPLICATION_BASED_TABS,
  ...MCN_ACCESS_CONDITIONS_NETWORKS_BASED_TABS,
  PRO_TRANSIT_ACCESS_POLICY_CONDITIONS.FQDN,
  PRO_TRANSIT_ACCESS_POLICY_CONDITIONS.ADVANCED,
  PRO_TRANSIT_ACCESS_POLICY_CONDITIONS.EGRESS_FQDN,
];

export const EGRESS_FQDN_POLICY_VALUES = {
  PROPERTY: 'fqdn',
  OPERATION: 'is',
};

export const POLICY_COMAIN_REGEX = {
  BASIC_DOMAIN_REGEX:
    /^(?:\*(?:https?:\/\/)?|https?:\/\/|\*\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(?:,\s*(?:\*(?:https?:\/\/)?|https?:\/\/|\*\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*)*$/,
};
