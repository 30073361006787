import {
  LogMetaDataState,
  LogMetaDataStore,
  logMetaDataStore,
} from './log-meta-data.store';
import { ObjectUtil } from '@prosimoio/services';

export class LogMetaDataService {
  constructor(private logMetaDataStore: LogMetaDataStore) {}

  updateDashboardDisplayData(dashboard: any) {
    this.logMetaDataStore.update((state) => ({
      dashboard,
    }));
  }

  updateFieldsMappingData(fields: any) {
    this.logMetaDataStore.update((state) => ({
      fields,
    }));
  }

  updatePersistedLogColumns(dashboard: string, fields: Array<string>) {
    this.logMetaDataStore.update((state) => {
      const stateCopy = ObjectUtil.deepClone<LogMetaDataState>(state);
      if (stateCopy.dashboard[dashboard]) {
        stateCopy.dashboard[dashboard].defaultSelections = fields;
      }
      return stateCopy;
    });
  }
}

export const logMetaDataService = new LogMetaDataService(logMetaDataStore);
