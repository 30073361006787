import { Status } from './status.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  private status$ = new BehaviorSubject<Status>({
    img: {
      src: '',
      style: {
        width: '',
        height: '',
      },
    },
    statusMsg1: '',
    statusMsg2: '',
  } as Status);

  constructor() {}

  setStatus(statusDetails: Status) {
    this.status$.next(statusDetails);
  }

  getStatus(): Observable<Status> {
    return this.status$.asObservable();
  }
}
