import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonModelSerializer } from '@app/common/http/service/common.model.serializer';
import { ResourceService } from '@prosimoio/services';
import { CONSTANTS } from 'environments/environment';
import { NetworkService } from '@app/common/services/network.service';
import { CloudService } from '@app/common/services/cloud.service';
import { NamespaceService } from '@app/common/services/namespace.service';
import { Observable, Subject } from 'rxjs';
import {
  AttachPoint,
  BgpGroup,
  CloudCredential,
  CloudRegion,
  Cluster,
  ConnectorSettingsDetails,
  EdgeConnectivity,
  IpPrefixList,
  Namespace,
  NetEgressControl,
  Network,
  NetworkCreateAction,
  NetworkOnboardResponse,
  NetworkResponse,
  NetworkSmartSearchFilter,
  NetworkSubnetConflict,
  PrivateCloud,
  SharedService,
  SharedServiceEndpointSubnets,
  SmartSearchFilterValues,
  UnmanagedVPCs,
} from '@app/common/models';
import { take } from 'rxjs/operators';
import { VPCBulkResponse } from '../visual-onboard/visual-onboard.model';
import { PolicyService } from '@app/common/services/policy.service';
import { ClusterService } from '@app/common/services/cluster.service';
import { SharedServiceService } from '@app/common/services/shared-service.service';
import { Policy } from '@app/common/models';
import { NetEgressControlService } from '@app/common/services/net-egress-control.service';
import { GroupingService } from '@app/common/services/grouping.service';
import { SubnetRouteMatch } from './network.model';

@Injectable({
  providedIn: 'root',
})
export class NetworkOnboardService extends ResourceService<any> {
  private networkCount$: Subject<number>;
  constructor(
    protected httpClient: HttpClient,
    private cloudService: CloudService,
    private networkService: NetworkService,
    private namespaceService: NamespaceService,
    private policyService: PolicyService,
    private netEgressService: NetEgressControlService,
    private clusterService: ClusterService,
    private sharedServiceService: SharedServiceService,
    private groupingService: GroupingService
  ) {
    super(
      httpClient,
      CONSTANTS.DASHBOARD_HOST,
      '',
      new CommonModelSerializer()
    );
    this.networkCount$ = new Subject<number>();
  }

  getNetworks(): Observable<Array<Network>> {
    return this.networkService.getNetworks([]);
  }

  createNetwork(
    action: NetworkCreateAction,
    payload: Network
  ): Observable<NetworkOnboardResponse> {
    return this.networkService.createNetwork(action, payload);
  }

  deleteNetwork(id: string) {
    return this.networkService.deleteNetwork(id).pipe(take(1));
  }

  offboardNetwork(id, isForced: boolean = false) {
    return this.networkService.offboardNetwork(id, isForced).pipe(take(1));
  }

  createNetworkCloudConfig(payload: Network): Observable<any> {
    return this.networkService.createNetworkCloudConfig(payload.id, payload);
  }

  updateNetworkSecurity(payload: Network): Observable<any> {
    return this.networkService.updateNetworkSecurity(payload.id, payload);
  }

  getPublicClouds(): Observable<Array<CloudCredential>> {
    return this.cloudService.getPublicCloudAccounts();
  }

  getPrivateClouds(): Observable<Array<PrivateCloud>> {
    return this.cloudService.getPrivateClouds();
  }

  getCloudRegions(cloudKeyID): Observable<Array<CloudRegion>> {
    this.path = CONSTANTS.CLOUD.REGIONS.GET(cloudKeyID);
    return this.read('').pipe(take(1));
  }

  getNamespaces(): Observable<Array<Namespace>> {
    return this.namespaceService.getNamespaces([]);
  }

  getVPCs(payload): Observable<Array<VPCBulkResponse>> {
    this.path = CONSTANTS.CLIENT_APPLICATION_GROUP.GET_REGION_VPC;
    return this.create(payload).pipe(take(1));
  }

  getConnectorActiveSettings(payload): Observable<ConnectorSettingsDetails> {
    this.path = CONSTANTS.NETWORK_ONBOARD.CONNECTOR_CONFIG;
    return this.create(payload).pipe(take(1));
  }

  getPolicies(accessType: Array<string>): Observable<Array<Policy>> {
    return this.policyService.getPolicies(accessType);
  }

  getEgressControls(): Observable<Array<NetEgressControl>> {
    return this.netEgressService.getEgressControls().pipe(take(1));
  }

  getGlobalConnectivityType() {
    this.path = CONSTANTS.CLOUD_MANAGEMENT.GET_CONNECTIVITY;
    return this.read('');
  }

  getEdges(): Observable<Array<Cluster>> {
    return this.clusterService.getEdges().pipe(take(1));
  }

  fetchSearchFilterConfig(): Observable<Array<SmartSearchFilterValues>> {
    return this.networkService.fetchSearchFilterConfig().pipe(take(1));
  }

  getFilteredNetworks(
    payload: NetworkSmartSearchFilter
  ): Observable<NetworkResponse> {
    return this.networkService.searchNetworks(payload).pipe(take(1));
  }

  getHubIDs(cloudIdAndRegion: any): Observable<Array<AttachPoint>> {
    this.path = CONSTANTS.CLIENT_APPLICATION_GROUP.GET_REGION_TRANSIT_HUBS;
    return this.create(cloudIdAndRegion).pipe(take(1));
  }

  getCloudNetworksForUnmanaged(): Observable<Array<UnmanagedVPCs>> {
    this.path =
      CONSTANTS.CLIENT_APPLICATION_GROUP.GET_CLOUD_REGION_VPC_UNMANAGED;
    return this.read('').pipe(take(1));
  }

  getEdgeConnectivityOptions(
    edgeId: string
  ): Observable<Array<EdgeConnectivity>> {
    return this.clusterService.getEdgeConnectivityOptions(edgeId).pipe(take(1));
  }

  getCloudSubnets(payload: any): Observable<Array<string>> {
    this.path = CONSTANTS.CLIENT_APPLICATION_GROUP.GET_VPC_SUBNETS;
    return this.create(payload).pipe(take(1));
  }

  getSharedServices(status: Array<string>): Observable<Array<SharedService>> {
    return this.sharedServiceService.getSharedServices(status);
  }

  getSharedServiceEndpointSubnets(
    payload: SharedServiceEndpointSubnets
  ): Observable<SharedServiceEndpointSubnets> {
    return this.sharedServiceService.getSharedServiceEndpointSubnets(payload);
  }

  getOverlappingCidr(): Observable<Array<NetworkSubnetConflict>> {
    this.path = CONSTANTS.NETWORK_ONBOARD.GET_OVERLAPPING_CIDR;
    return this.read('').pipe(take(1));
  }

  getConflicts(network: Network): Observable<Array<Namespace>> {
    this.path = CONSTANTS.NETWORK_ONBOARD.GET_NETWORK_CONFLICTS_BY_PAYLOAD;
    return this.create(network).pipe(take(1));
  }

  getVirtualSubnets(): Observable<Array<Network>> {
    this.path = CONSTANTS.NETWORK_ONBOARD.GET_VIRTUAL_SUBNETS;
    return this.read('').pipe(take(1));
  }

  networksStatusCheck(networkIDs: Array<string>) {
    this.path = CONSTANTS.NETWORK_STATUS_CHECK;
    return this.create(networkIDs);
  }

  validateSubnet(payload: any) {
    this.path = CONSTANTS.SHARED_SERVICES.SUBNET_VALIDATE;
    return this.create(payload);
  }

  getAllIPPrefixGroupings(): Observable<Array<IpPrefixList>> {
    return this.groupingService.getAllIPPrefixGroupings().pipe(take(1));
  }

  getAllBgpCommunityGroupings(): Observable<Array<BgpGroup>> {
    return this.groupingService.getAllBGPCommunityGroupings().pipe(take(1));
  }

  getNetworksCount(): Observable<number> {
    return this.networkCount$?.asObservable();
  }

  setNetworksCount(count: number) {
    this.networkCount$?.next(count);
  }

  getVPCsByRegion(cloudCredsID: string, regionID: string): Observable<any> {
    this.path = CONSTANTS.DISCOVERED_APPS.VPCS_BY_REGION_ID(
      cloudCredsID,
      regionID
    );
    return this.read('').pipe(take(1));
  }

  checkCustomRoutingNeeded(
    cloudType: string,
    cloudRegion: string,
    subnets: Array<string>
  ): Observable<SubnetRouteMatch> {
    this.path = CONSTANTS.V2.ONBOARD.NETWORKS.SUMMARY_ROUTE_MATCH;
    return this.create({ cloudType, cloudRegion, subnets }).pipe(take(1));
  }
}
