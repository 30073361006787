import { IDPManagementServicesModel } from '@app/management/idp/idp.management.model';

export class SyncFromIDPConfigModel {
  title: string;
  hint: string;
  supportedIDPTypes: Array<IDPManagementServicesModel>;
  selectedIDPConfig?: any;
  importParameters?: Array<IDPImportParameterModel>;
  importInProgressConfig?: ImportInProgressModel;
  source: string;
}

export class IDPImportProgressModel {
  ids: Array<string>;
  parameters: IDPImportParameterModel;
}

export class IDPImportParameterModel {
  name: string;
  key: string;
  status: string;
}

export const SYNC_FROM_IDP_SOURCES = {
  POLICY_LIBRARY: 'POLICY_LIBRARY',
  APPS: 'APPS',
  IDP: 'IDP',
};

export class ImportInProgressModel {
  importStatus: boolean;
  importParameter: string;
}

export const IDP_IMPORT_PARAMETERS = [
  {
    name: 'Applications',
    key: 'application',
    status: 'active',
  } as IDPImportParameterModel,
  {
    name: 'User',
    key: 'user',
    status: 'active',
  } as IDPImportParameterModel,
  {
    name: 'User Groups',
    key: 'userGroup',
    status: 'active',
  } as IDPImportParameterModel,
  {
    name: 'Policy',
    key: 'policy',
    status: 'active',
  } as IDPImportParameterModel,
];

export const IDP_IMPORT_PARAMETER_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inActive',
};
