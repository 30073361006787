import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { NetworkSubnetConflict, SubnetConflict } from '@app/common/models';
import { PDashLocalStoreUtil } from '@app/state/web-pdash.util';
import { OVERVIEW_TABLE_MESSAGE } from '@app/v2/configure/policy/policy.constants';
import { Subject } from 'rxjs';
import { debounceTime, map, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-overlapping-cider-table',
  templateUrl: './overlapping-cider-table.component.html',
  styleUrls: ['./overlapping-cider-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverlappingCiderTableComponent implements OnDestroy {
  stopSubs$: Subject<void>;
  searchTerm$: Subject<string>;
  constructor(private cdr: ChangeDetectorRef) {
    this.stopSubs$ = new Subject();
    this.searchTerm$ = new Subject();
    this.searchTerm$
      .pipe(
        takeUntil(this.stopSubs$),
        debounceTime(300),
        map((searchTerm) => this.filterData(searchTerm)),
        tap((data) => {
          this._tableData = data;
          this.cdr.markForCheck();
        })
      )
      .subscribe();
  }

  @Input() set searchText(searchText: string) {
    this.searchTerm$.next(searchText);
  }

  uiThemeMode: string =
    PDashLocalStoreUtil?.getUIThemeMode()?.toLocaleLowerCase();

  @Input() set data(data: any) {
    if (data) {
      this._tableBackup = data;
    }
  }

  get data() {
    return this._tableData;
  }

  filterData(searchText: string = ''): Array<NetworkSubnetConflict> {
    try {
      if (!searchText) {
        return this._tableBackup;
      }

      searchText = searchText.toLowerCase();
      const tData: Array<NetworkSubnetConflict> = [];
      this._tableBackup.forEach((v) => {
        if (
          v.namespaceName.toLowerCase().includes(searchText) ||
          v.networkName.toLowerCase().includes(searchText)
        ) {
          tData.push(v);
        } else {
          let matched = false;
          const networkInfo: NetworkSubnetConflict = {
            namespaceID: v.namespaceID,
            namespaceName: v.namespaceName,
            networkID: v.networkID,
            networkName: v.networkName,
            vpc: v.vpc,
            subnetConflicts: [],
          };

          v.subnetConflicts.forEach((subnetInfo) => {
            if (subnetInfo.subnet.toLowerCase().includes(searchText)) {
              matched = true;
              networkInfo.subnetConflicts.push(subnetInfo);
            } else {
              const subnetConflict: SubnetConflict = {
                namespaceID: subnetInfo.namespaceID,
                namespaceName: subnetInfo.namespaceName,
                networkID: subnetInfo.networkID,
                networkName: subnetInfo.networkName,
                vpc: subnetInfo.vpc,
                subnet: subnetInfo.subnet,
                overlaps: [],
              };

              subnetInfo.overlaps.forEach((overlapInfo) => {
                if (
                  overlapInfo.networkName.toLowerCase().includes(searchText) ||
                  overlapInfo.vpc.toLowerCase().includes(searchText) ||
                  overlapInfo.subnet.toLowerCase().includes(searchText) ||
                  overlapInfo.namespaceName.toLowerCase().includes(searchText)
                ) {
                  matched = true;
                  subnetConflict.overlaps.push(overlapInfo);
                }
              });
              if (subnetConflict.overlaps.length > 0) {
                networkInfo.subnetConflicts.push(subnetConflict);
              }
            }
          });
          if (matched) {
            tData.push(networkInfo);
          }
        }
      });
      return tData;
    } catch (err) {
      return [];
    }
  }

  _tableData: Array<NetworkSubnetConflict>;
  _tableBackup: Array<NetworkSubnetConflict>;
  _searchText: string;
  OVERVIEW_TABLE_MESSAGE = OVERVIEW_TABLE_MESSAGE;

  ngOnDestroy(): void {
    this.stopSubs$.next();
    this.stopSubs$.complete();
  }
}
