import { Query } from '@datorama/akita';
import { AgentStore, AgentState, agentStore } from './agent.store';

export class AgentQuery extends Query<AgentState> {
  constructor(protected store: AgentStore) {
    super(store);
  }
}

export const agentQuery = new AgentQuery(agentStore);
