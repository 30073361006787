import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

export interface State {
  id?: string;
  name?: string;
  city_code?: string;
  region_code?: string;
  state_name?: string;
}

export interface City {
  id: string;
  name: string;
}

export interface SelectedLocations {
  country?: Array<any>;
  state?: Array<any>;
  city?: Array<any>;
}

export interface PdfDownload {
  currentPage: string;
  domain: string;
  isPrintPolling: BehaviorSubject<any>;
  module: any;
  pathURL: string;
}

export interface DevCredentialModel {
  name: string;
  host: string;
  token: string;
}

export interface ICloud {
  cloudType: string;
  cloudRegion: string;
}

export interface IDName {
  id: string;
  name?: string;
}
