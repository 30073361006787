import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Page } from './paginator.model';

@Injectable({
  providedIn: 'root',
})
export class PaginatorService {
  private paginatorData$ = new Subject<Page>();

  constructor() {}

  setPaginatorData(paginatorData: Page) {
    this.paginatorData$.next(paginatorData);
  }

  getPaginatorData(): Observable<Page> {
    return this.paginatorData$.asObservable();
  }
}
