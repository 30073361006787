import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceService } from '@prosimoio/services';
import { CommonModelSerializer } from '../http/service/common.model.serializer';
import { CONSTANTS } from 'environments/environment';
import { EMPTY, Observable } from 'rxjs';
import { Policy, PolicyResponse, PolicySearchFilter } from '../models';
import { PAGE_SIZE } from '../http/util/constants';
import { expand, reduce, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PolicyService extends ResourceService<any> {
  constructor(protected httpClient: HttpClient) {
    super(
      httpClient,
      CONSTANTS.DASHBOARD_HOST,
      '',
      new CommonModelSerializer()
    );
  }

  getPolicies(
    accessType: Array<string>,
    searchTerm: string = ''
  ): Observable<Array<Policy>> {
    let pageStart = 0;
    this.path = CONSTANTS.POLICY.POLICY_SEARCH;
    return this.create(
      this.getPolicyPayload(accessType, searchTerm, pageStart)
    ).pipe(
      expand((response: PolicyResponse) => {
        pageStart += PAGE_SIZE;
        if (pageStart < response.totalCount) {
          this.path = CONSTANTS.POLICY.POLICY_SEARCH;
          return this.create(
            this.getPolicyPayload(accessType, searchTerm, pageStart)
          );
        } else {
          return EMPTY;
        }
      }),
      reduce((acc: Array<Policy>, val: PolicyResponse) => {
        if (val?.records) {
          return acc.concat(val.records);
        }
      }, [] as Array<Policy>),
      take(1)
    );
  }

  getNetworksByPage(
    page: number,
    accessType: Array<string>,
    searchTerm: string
  ): Observable<PolicyResponse> {
    this.path = CONSTANTS.NETWORK_SEARCH;
    return this.create(
      this.getPolicyPayload(accessType, searchTerm, page * PAGE_SIZE)
    ).pipe(take(1));
  }

  getPolicyPayload(
    accessType: Array<string>,
    searchTerm: string,
    pageStart: number
  ): PolicySearchFilter {
    return {
      page: {
        size: PAGE_SIZE,
        start: pageStart,
      },
      sort: [
        {
          field: 'type',
          asc: true,
        },
      ],
      value: searchTerm,
      accessType: accessType,
    } as PolicySearchFilter;
  }
}
