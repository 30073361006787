import {
  Network,
  NetworkOwnCloud,
  PrefixInfo,
} from '@app/common/models';
import { ICloudController } from './cloud-controller';
import {
  NetworkDefinition,
  PCWithSubnets,
} from '../../components/drawer/definition/definition.model';
import { NetworkVPC, VPCSettings } from '../../components/drawer/vpc/vpc.model';
import { NetworkPolicy } from '../../components/drawer/policy/policy.model';
import { ModelFormGroup } from '@app/common/util/form-util';
import { OrchestratorService } from '@app/common/components/drawer/orchestrator/orchestrator.service';
import {
  NetworkOnboardPrivateWorkflow,
  NetworkOnboardTabs,
} from '../../../network.constants';
import {
  NetworkOnboardStep,
  OrchestratorStep,
  OrchestratorWorkflow,
} from '@app/common/components/drawer/orchestrator/orchestrator.model';
import { NetworkAutoDefinition } from '../../components/drawer/auto-definition/auto-definition.model';
import {
  NetworkRule,
  Rule,
} from '../../components/drawer/auto-rule/auto-rule.model';
import { ChangeSetList } from '../../../network.model';

export class OwnCloudController implements ICloudController {
  constructor(private orchestratorService: OrchestratorService) {}

  getCloudType(network: Network): string {
    return network.ownTransitCloud?.cloudType;
  }

  getCSP(network: Network) {
    return '';
  }

  getCloudKeyID(network: Network): string {
    return '';
  }

  getCloudRegion(network: Network) {
    return '';
  }

  getCloudNetworks(network: Network): Array<PCWithSubnets> {
    return [
      // {
      //   cloudNetworkID: '',
      //   subnets: network.ownTransitCloud.subnets,
      // },
    ];
  }

  getSubnets(network: NetworkDefinition) {
    return network.ownTransitCloud.subnets;
  }

  getCloudNetworksForm(network: NetworkDefinition): Array<PCWithSubnets> {
    return [
      // {
      //   cloudNetworkID: '',
      //   subnets: network.cloudNetworks?.[0].subnets || [],
      // },
    ];
  }

  validateDefinition(network: NetworkDefinition) {
    return network.ownTransitCloud.subnets.length > 0;
  }

  validateAutoDefinition: (networkForm: NetworkAutoDefinition) => boolean;

  detectDefinationFormChanges(
    network: Network,
    networkForm: NetworkDefinition
  ) {
    return networkForm.cloudType !== network.ownTransitCloud?.cloudType;
  }

  detectAutoDefinitionFormChanges: (
    network: Network,
    networkForm: NetworkAutoDefinition
  ) => boolean;

  updateDefinition(network: Network, networkForm: NetworkDefinition) {
    if (!network.ownTransitCloud) {
      network.ownTransitCloud = {} as NetworkOwnCloud;
      delete network.privateCloud;
      delete network.publicCloud;
    }
    network.ownTransitCloud.cloudType = networkForm.cloudType;
  }

  updateAutoDefinition: (
    network: Network,
    networkForm: NetworkAutoDefinition
  ) => void;

  detectAutoOnboardRulesFormChanges: (
    network: Network,
    rulesForm: Rule[]
  ) => number[];

  validateAutoOnboardRules: (
    network: Network,
    rulesForm: Rule[],
    prefixes: Map<string, PrefixInfo[]>,
    bgpCommunities: Map<string, string[]>,
    bgpNeighbors: Map<string, string[]>
  ) => number[];

  updateAutoOnboardRules: (
    network: Network,
    rulesForm: NetworkRule,
    prefixes: Map<string, PrefixInfo[]>,
    bgpCommunities: Map<string, string[]>,
    bgpNeighbors: Map<string, string[]>
  ) => void;

  getRulesChangeSet: (
    existingNetwork: Network,
    rulesForm: NetworkRule,
    prefixes: Map<string, PrefixInfo[]>,
    bgpCommunities: Map<string, string[]>,
    bgpNeighbors: Map<string, Array<string>>
  ) => Array<ChangeSetList>;

  detectVPCFormChanges(
    network: Network,
    vpcForm: Array<VPCSettings>
  ): Array<number> {
    return [];
  }

  validateVPCs(network: Network, vpcSettings: VPCSettings[]): Array<number> {
    return [];
  }

  updateVPCs: (network: Network, networkForm: NetworkVPC) => void;

  detectPolicyFormChanges: (
    network: Network,
    networkForm: NetworkPolicy
  ) => boolean;
  validatePolicy: (
    networkForm: NetworkPolicy,
    policies: Map<string, string>
  ) => boolean;
  updatePolicy: (network: Network, networkForm: NetworkPolicy) => void;

  addSubnetToDefinitionForm(
    subnet: string,
    networkForm: ModelFormGroup<NetworkDefinition>
  ) {
    networkForm.controls.ownTransitCloud.controls.subnets.patchValue([
      subnet,
      ...networkForm.controls.ownTransitCloud.controls.subnets.value,
    ]);
  }

  removeSubnetToDefinitionForm(
    subnet: string,
    networkForm: ModelFormGroup<NetworkDefinition>
  ) {
    networkForm.controls.ownTransitCloud.controls.subnets.patchValue(
      networkForm.controls.ownTransitCloud.controls.subnets.value.filter(
        (selSubnet) => selSubnet !== subnet
      )
    );
  }

  updateOrchestratorWorkflow(cloudType: string) {
    this.orchestratorService.setOrchWorkflow({
      tabs: NetworkOnboardTabs.filter((v) => v.code !== NetworkOnboardStep.VPC),
      workflowPayload: null,
      workflow:
        NetworkOnboardPrivateWorkflow as OrchestratorWorkflow<OrchestratorStep>,
    });
  }
}
