import { IMAGE_PATHS, SUBSCRIPTION_TYPES, URL_PATHS } from './constants';

export interface IProductTourConfig {
  manual: Array<any>;
  auto: Array<any>;
  label: string;
  url: string;
}
export const IG_INDIVIDUAL_STEPS = {
  SELECT_TARGET: {
    title: 'Select Target(s)',
    element: '.ig__target-filter',
    intro: 'Select the targets of interest',
  },
  BAD_UX: {
    title: 'Select Source(s)',
    element: '.ig__source-filter',
    intro: 'Select the sources that complain of bad user experience',
  },
  HIGH_USER_RISK: {
    title: 'Select Source(s)',
    element: '.ig__source-filter',
    intro: 'Select the sources under investigation for high risk score',
  },
  SELECT_DATE_RANGE: {
    title: 'Select Date Range',
    element: '.ig__date-filter',
    intro: 'Select the date range based on when they accessed the apps',
  },
};

export const INSIGHTS_BASIC_STEPS = [
  IG_INDIVIDUAL_STEPS.BAD_UX,
  IG_INDIVIDUAL_STEPS.SELECT_TARGET,
  IG_INDIVIDUAL_STEPS.SELECT_DATE_RANGE,
];

export const USER_RISK_BASIC_STEPS = [
  IG_INDIVIDUAL_STEPS.HIGH_USER_RISK,
  IG_INDIVIDUAL_STEPS.SELECT_TARGET,
  IG_INDIVIDUAL_STEPS.SELECT_DATE_RANGE,
];

export const PRODUCT_TOUR_KEYS = {
  DEBUG_LOW_UX: 'DEBUG_LOW_UX',
  INSIGHTS_USER_RISK: 'INSIGHTS_USER_RISK',
  GUIDE: 'GUIDE',
  DIAGNOSE_HEALTH_STATUS: 'DIAGNOSE_HEALTH_STATUS',
  INSIGHT_EVENTS: 'INSIGHT_EVENTS',
  INSIGHT_WAF: 'INSIGHT_WAF',
  INSIGHT_TROUBLESHOOT_USER_RISK: 'INSIGHT_TROUBLESHOOT_USER_RISK',
};

export const PRODUCT_TOUR_TYPES = {
  MANUAL: 'manual',
  AUTO: 'auto',
};

export const INTERACTIVE_GUIDE_CONFIGS = {
  [PRODUCT_TOUR_KEYS.DEBUG_LOW_UX]: <IProductTourConfig>{
    manual: [
      ...INSIGHTS_BASIC_STEPS,
      {
        title: 'Click on advanced search',
        element: '.ig__btn-advanced-search',
        intro: 'Advanced search helps to trim the results further',
      },
      {
        title: 'Click on add filter',
        element: '.ig__btn-add-filter',
        intro: 'Click to add a UX score filter',
        position: 'right',
      },
      {
        title: 'Select UX score',
        element: '.ig__input-field',
        intro: 'Select UX score under field dropdown',
        position: 'right',
      },
      {
        title: 'Select range operator',
        element: '.ig__input-operator',
        intro: 'Select the range operator under operator dropdown',
        position: 'right',
      },
      {
        title: 'Enter the UX score range values',
        element: '.ig__input-value',
        intro:
          'Enter the user experience score to a low range, between 1 and 39',
        position: 'right',
      },
      {
        title: 'Expand any session',
        element: '.ig__log-row',
        intro:
          'Expand any session of interest from the search results to view details',
        // actions: [
        //   {
        //     action: 'click',
        //     element: '.ig__btn-advanced-search',
        //     condition: {
        //       when: 'invisible',
        //       element: '.advance-search__container',
        //     },
        //   },
        //   {
        //     action: 'scroll',
        //     element: '.scrollable__content',
        //   },
        // ],
      },
      {
        title: 'View session details',
        element: '.ig__rocketship-view',
        intro: `
        <p>Check the detailed path between user and the app.The segment that is causing the low UX score is highlighted in red</p>
        <p class="pt-2">Hovering on warning sign <img src="${IMAGE_PATHS.COMMON.WARNING}" style="width:15px;height:15px;"/> of the highlighted segment(s) shows the root cause</p>
        `,
        actions: [
          {
            action: 'scroll',
            element: '.user-dashboard__contents',
            conditions: [
              {
                key: 'scrollTop',
                value: 100,
              },
            ],
          },
        ],
      },
      {
        title: 'Learn more in Diagnose',
        element: '.ig__view-session-details',
        intro:
          'Clicking on this button takes to Diagnose page with all transactions of this session automatically filtered. This gives a view to see if particular URL(s) could be an issue ',
      },
      {
        title: 'Download JSON for transaction details',
        element: '.ig__download-session-details',
        intro:
          'This download the JSON for all transactions of this session to dive deeper',
      },
    ],
    auto: [
      {
        title: 'Banner details',
        element: '.ig__banner_details',
        intro:
          'This banner will show the selected risk factor, user and date ranges',
      },
      {
        title: 'Verify the filtered result',
        element: '.ig__log-row',
        intro:
          'Each row will show the details corresponding to the selected filter as shown in banner',
      },
      {
        title: 'Expand row to view more',
        element: '.ig__log-row',
        intro: 'The details will be shown here',
      },
      {
        title: 'Verify the details',
        element: '.ig__trouble_shoot_details',
        intro: 'The details will be shown here',
      },
    ],
    label: 'Identifying Root cause for poor app Experience',
    url: `${URL_PATHS.DASHBOARD_INSIGHTS}/${SUBSCRIPTION_TYPES.ZTNA}/summary`,
  },
  [PRODUCT_TOUR_KEYS.INSIGHTS_USER_RISK]: <IProductTourConfig>{
    manual: [
      ...USER_RISK_BASIC_STEPS,
      {
        title: 'Click on any of the contributing factors',
        element: '.ig__donut-chart',
        intro:
          'Clicking on the risk factor either from the pie chart or the table will filter and show only the sessions impacted by the specific risk factor',
      },
      {
        title: 'Expand any session of interest',
        element: '.ig__log-row',
        intro:
          'Each session is an hourly bucket based on user and app, all factors contributed to the risk score are shown',
      },
      {
        title: 'Compare the current vs baseline',
        element: '.ig__analytics-info',
        intro: `<div>For each of the risk factors impacting the selected session, the horizontal scroll bar shows the baseline value along with marker showing where the current value  is falling. 
              The below legend explains the meaning of each of the color codes. This doesn't apply for sessions in learning mode. </div>
              <div class="mt-3 d-flex flex-row justify-content-around">
              <span class="d-flex justify-content-center user_risk_low">Low</span>
              <span class="d-flex justify-content-center user_risk_medium">Medium</span>
              <span class="d-flex justify-content-center user_risk_critical">Critical</span>
              <span class="d-flex justify-content-center user_risk_high">High</span>
              </div>`,
      },
      {
        title: 'Learn more',
        element: '.ig-learn-more-meta',
        intro:
          'Clicking on learn more takes to respective pages that shows more details about the risk factor events',
      },
    ],
    auto: [],
    label: 'Identifying Root cause for high risk score',
    url: `${URL_PATHS.DASHBOARD_INSIGHTS}/${SUBSCRIPTION_TYPES.ZTNA}/user-risk`,
  },
  [PRODUCT_TOUR_KEYS.INSIGHT_EVENTS]: {
    manual: [],
    auto: [
      {
        title: 'Verify the session context',
        element: '.ig__banner_details',
        intro:
          'Viewing details of the user session selected from the user risk page under insights',
      },
      {
        title: 'Summary of events',
        element: '.ig__log-row',
        intro:
          'Each row shows the summary of events that occurred during a specific transaction',
      },
      {
        title: 'Click on the transaction ID',
        element: '.ig__learn_more_transaction',
        intro:
          'Click on specific transaction ID that has the event of interest such as a revoke, MFA or a WAF enforce. This takes to the diagnose page to show the step by step details for this specific transaction',
      },
    ],
  },
  [PRODUCT_TOUR_KEYS.INSIGHT_WAF]: {
    manual: [],
    auto: [
      {
        title: 'Verify the session context',
        element: '.ig__banner_details',
        intro:
          'Viewing details of all WAF rule IDs that contributed to risk score for the selected session',
      },
      {
        title: 'Summary of WAF hits',
        element: '.ig__log-row',
        intro:
          'Each row shows the rule ID and its corresponding hits to understand which ruleID is contributing the most for the specific user session',
      },
      {
        title: 'Select the rule ID of interest',
        element: '.ig__learn_more_rule_id',
        intro:
          'Click on specific rule ID that has a lot of hits based on request count for the specific session. This takes to the events page to show the WAF related events for this rule ID',
      },
    ],
  },
  [PRODUCT_TOUR_KEYS.INSIGHT_TROUBLESHOOT_USER_RISK]: {
    manual: [],
    auto: [
      {
        title: 'Verify the transaction',
        element: '.ig__banner_details',
        intro:
          'Viewing details of specific transaction that was selected from the event page',
      },
      {
        title: 'Summary of the transaction',
        element: '.ig__log-row',
        intro:
          'The summary shows the key details of the transaction such as time, URL and if the transaction was blocked or incurred an error',
      },
      {
        title: 'Trace the transaction details',
        element: '.ig__trouble_shoot_details',
        intro:
          'For each transaction, you can expand and click though the tabs such as authentication, WAF and policy to understand different set of actions that were taken for this transaction',
      },
    ],
  },
  [PRODUCT_TOUR_KEYS.DIAGNOSE_HEALTH_STATUS]: <IProductTourConfig>{
    manual: [
      {
        title:
          'Analyze and troubleshoot health of applications, Edges and network segments',
        element: '.ig__health_status',
        intro:
          'This page is built to show the health status of  applications, Edges and network segments. In addition, this view highlights the recent issues seen along with the timestamps to understand the impact.',
      },
      {
        title: ' Click on Timeline to Review the list of issues',
        element: '.ig__timeline',
        intro:
          'This section shows the list of issues seen in chronological order',
      },
      {
        title: 'Analyze issue',
        element: '.ig__issuesByDay',
        intro:
          'Each entry shows a specific issue related to an application, Edge and network segment.   If the same issue is seen multiple times ( common example : health checks failing ), it shows the timestamps once you click on the issue. For a selected timestamp, the map and the log table on the right shows the status.',
      },
      {
        title: 'Compare with Live view',
        element: '.ig__btn-advanced-search ',
        intro:
          'This take it back to the live health to ensure the issue highlighted is fixed and compare the status of the issue timeline with the current view anytime.',
      },
      {
        title: 'Set a custom filter for health view',
        element: '.ig__filter',
        intro:
          'This section enables to set any custom filter based on specific time and select any of the  applications, Edges and network segments to check their heath status. This is useful when a complaint is received about an application or infrastructure from another source and the requirement is to get to that timeline specifically to check the health status. Live view could be checked at any any point.',
      },
    ],
    auto: [],
    label: 'Understand your cloud transit infrastructure',
    url: `${URL_PATHS.DASHBOARD}/diagnose/health-status`,
  },
  [PRODUCT_TOUR_KEYS.GUIDE]: <IProductTourConfig>{
    manual: [
      {
        title: 'Select any of the recommendations from the platform ',
        element: '.ig__guides',
        intro:
          'This shows the list of default recommendations generated by the platform. Each one provides a different combination for footprint of Prosimo Edges, based on cost and performance impact for applications.',
      },
      {
        title: 'Review the recommendation details',
        element: '.ig__guide_details',
        intro:
          'This shows the impact of cost and performance for the selected recommendation.',
      },
      {
        title: 'Analyze the  recommended edge foot print',
        element: '.ig__guide_legend',
        intro:
          'Review the cloud and the region for each of the action recommended, whether it is to create a new edge, spin down or keep existing edges. This is based on access patterns seen from users for the applications behind the fabric.',
      },
      {
        title: 'Review the top regions impacted',
        element: '.ig__region_legend',
        intro:
          'This show the the most improved, neutral or negatively impacted country if there are spin down recommendations. They are highlighted in the map as well.',
      },
      {
        title:
          'Drill down the impact of this recommendation for each application',
        element: '.ig__app_filter',
        intro:
          'Selecting an application shows the impact of recommendation specific to this application - performance improved per region will be unique per application based on the recommended edge footprint.',
        position: 'right',
      },
      {
        title: 'Analyze the impact of a different footprint',
        element: '.ig__customize_btn',
        intro:
          'Customize option enables you to choose a different footprint by making modifications to the recommended footprint and understand the performance / cost impact of these choices. Follow the signs in the map to add ( + sign ) or remove an edge ( X sign ).  The default recommendation screen is still shown the left to compare the impact. This edge footprint combination could be saved as a custom recommendation for future reference by providing a name.',
      },
    ],
    auto: [],
    label: 'Expand your cloud transit with ML recommendations',
    url: `${URL_PATHS.DASHBOARD}/guide`,
  },
};
