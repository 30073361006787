import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceService } from '@prosimoio/services';
import { CONSTANTS } from 'environments/environment';
import { CommonModelSerializer } from '../http/service/common.model.serializer';
import { CloudCredential, PrivateCloud } from '@app/common/models';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CloudService extends ResourceService<any> {
  constructor(protected httpClient: HttpClient) {
    super(
      httpClient,
      CONSTANTS.DASHBOARD_HOST,
      '',
      new CommonModelSerializer()
    );
  }

  getPublicCloudAccounts(): Observable<Array<CloudCredential>> {
    this.path = CONSTANTS.CLOUD_MANAGEMENT.GET_CLOUD;
    return this.read('').pipe(take(1));
  }

  getPublicCloudRegions(cloudId: string): Observable<Array<any>> {
    this.path = CONSTANTS.CLOUD.REGIONS.GET(cloudId);
    return this.read('').pipe(take(1));
  }

  getPrivateClouds(): Observable<Array<PrivateCloud>> {
    this.path = CONSTANTS.CLOUD_MANAGEMENT.PRIVATE_CLOUD;
    return this.read('').pipe(take(1));
  }

  getProsimoReservedIPs(cloudType: string): Observable<Array<any>> {
    this.path = CONSTANTS?.CLOUD.RESERVED_IP_RANGES(cloudType);
    return this.read('').pipe(take(1));
  }

  getVirtualCloudNetworks(payload: any) {
    this.path = CONSTANTS.CLIENT_APPLICATION_GROUP?.GET_CLOUD_REGION_EDGE_VPC;
    return this.create(payload).pipe(take(1));
  }

  validateCloudRequirements(payload: any) {
    this.path = CONSTANTS.EDGE_MANAGEMENT.EDGES.VALIDATE_EDGE;
    return this.create(payload).pipe(take(1));
  }
}
