import { SelectionObjectWithArray } from '../../../common/util/constants';
import { Store, StoreConfig } from '@datorama/akita';

export interface TasksState {
  selectedTasks?: Array<SelectionObjectWithArray>;
}

export function createInitialState(): TasksState {
  return {
    selectedTasks: null,
  };
}

@StoreConfig({ name: 'tasks', resettable: true })
export class TasksStore extends Store<TasksState> {
  constructor() {
    super(createInitialState());
  }
}

export const tasksStore = new TasksStore();
