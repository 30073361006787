import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ISwitchIconDef } from './switch.interface';
@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchComponent implements OnInit, OnChanges {
  @ViewChild('toggleBtn', { static: true }) toggleBtn;
  @Output() switchView = new EventEmitter<boolean>();
  @Input() left: string;
  @Input() right: string;
  @Input() simple = false;
  @Input() text = false;
  @Input() selectedView: string;

  @Input() leftIcons?: ISwitchIconDef;
  @Input() rightIcons?: ISwitchIconDef;
  @Input() selectedIcons?: ISwitchIconDef;
  @Input() customStyles?: string;
  @Input() id: string = 'customSwitches';
  selectedItem: string;
  selectedSwitchIcons: ISwitchIconDef;

  isLeft: boolean;
  isRight: boolean;
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.isIconSwitch) {
      this.updateIconSwitch(changes);
    } else {
      this.updateRegularSwitch(changes);
    }
  }

  ngOnInit() {}

  toggleChange(event: any) {
    if (this.isIconSwitch) {
      this.selectedSwitchIcons = event.target.checked
        ? this.leftIcons
        : this.rightIcons;
      this.isLeft = !event.target.checked;
      this.isRight = !this.isLeft;
      this.switchView.emit(
        this.selectedSwitchIcons.active === this.leftIcons.active ? false : true
      );
    } else {
      this.selectedItem = event.target.checked ? this.right : this.left;
      this.switchView.emit(this.selectedItem === this.left ? false : true);
    }
    this.cdr.markForCheck();
  }

  onClickIcon(item: any) {
    this.toggleBtn.nativeElement.checked =
      !this.toggleBtn.nativeElement.checked;
    if (this.isIconSwitch) {
      this.selectedSwitchIcons = item;
      this.isLeft = !this.toggleBtn.nativeElement.checked;
      this.isRight = !this.isLeft;
      this.switchView.emit(
        this.selectedSwitchIcons.active === this.leftIcons.active ? false : true
      );
    } else {
      this.selectedItem = item;
      this.switchView.emit(this.selectedItem === this.left ? false : true);
    }

    this.cdr.markForCheck();
  }

  /**
   * Verify if this switch is with only icons, svg
   */
  get isIconSwitch() {
    return this.leftIcons && this.rightIcons;
  }

  /**
   * Updates selected icon when land on this page or on change detection
   * @param changes
   */
  updateIconSwitch(changes: SimpleChanges) {
    if (changes.selectedIcons) {
      this.selectedSwitchIcons = this.selectedIcons || this.leftIcons;
      if (this.selectedIcons.active === this.rightIcons.active) {
        this.toggleBtn.nativeElement.checked = true;
      } else {
        this.toggleBtn.nativeElement.checked = false;
      }
    } else {
      this.selectedSwitchIcons = this.leftIcons;
    }

    this.isLeft = !this.toggleBtn.nativeElement.checked;
    this.isRight = !this.isLeft;
  }

  /**
   * Updates selected regular switch with text or mat icon when land on this page or on change detection
   * @param changes
   */
  updateRegularSwitch(changes: SimpleChanges) {
    if (changes.selectedView) {
      this.selectedItem = this.selectedView || this.left;
      if (this.selectedItem === this.right) {
        this.toggleBtn.nativeElement.checked = true;
      } else {
        this.toggleBtn.nativeElement.checked = false;
      }
    } else {
      this.selectedItem = this.left;
    }
  }
}
