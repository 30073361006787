import { Pipe, PipeTransform } from '@angular/core';
import { getWellKnownCommunityValue } from '@app/common/util/bgp-util';

@Pipe({
  name: 'wellKnownBgp',
})
export class WellKnownBgpPipe implements PipeTransform {
  transform(value: string): string {
    return getWellKnownCommunityValue(value, false) || value;
  }
}
