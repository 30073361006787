<ng-template #navMenuItem let-item="item">
  <ng-container
    *ngIf="
      !(isProd && MENU_NAMES_TO_HIDE_IN_PROD_ENV_COPY.includes(item.name)) &&
      !item?.isHidden
    "
  >
    <div
      [ngClass]="{
        'd-flex no-gutters flex-column justify-content-center align-items-center flex-nowrap navbar__menu--item': true,
        expanded: isExpanded,
        selected: item?.name === selectedMenuItem?.name,
        'sub-menu no-pointer': item?.subMenu?.length,
        'sub-menu-selected': item?.name === selectedMenuItem?.parentName,
        'trial-disable-btn': item?.isDisable
      }"
      (click)="routeTo(item)"
    >
      <div
        [ngClass]="{
          'col-sm-12 position-relative': true,
          'd-flex align-items-center justify-content-center logo-main-position':
            item?.isMain,
          'menu__item--img': !item?.isMain
        }"
      >
        <img
          [ngClass]="{
            'selected-img': true,
            'logo-main': item?.isMain,
            rocket: item.name === 'App on-boarding'
          }"
          [src]="item?.selectedImgUrl"
        />
        <img
          [ngClass]="{
            'unselected-img': true,
            'logo-main': item?.isMain,
            rocket: item.name === 'App on-boarding'
          }"
          [src]="item?.imgUrl"
        />
        <ng-container *ngIf="item?.isBetaRelease && !isTrialAccount">
          <img class="beta-img selected-img" [src]="BETA_IMAGES.selected" />
          <img class="beta-img unselected-img" [src]="BETA_IMAGES.unselected" />
        </ng-container>
        <div
          class="d-flex align-items-center justify-content-center"
          *ngIf="!item?.isMain"
        >
          <div
            class="menu__item--name align-middle"
            [ngClass]="{ 'trial-disable-btn': item?.isDisable }"
          >
            {{ item?.name }}
          </div>
        </div>
      </div>

      <!-- handle expanded view -->
      <ng-container *ngIf="isExpanded">
        <ng-container *ngFor="let subItem of item?.subMenu; first as isFirst">
          <div
            *ngIf="!subItem?.isHidden"
            class="col-sm-12 navbar__sub-menu--item"
            (click)="subMenuRouteTo($event, subItem)"
            [ngClass]="{
              'mt-2': isFirst,
              selected: subItem?.name === selectedMenuItem?.name,
              'trial-disable-btn': subItem?.isDisable
            }"
          >
            <div class="menu__item--img">
              <span
                class="sub-menu__item--name align-middle"
                [ngClass]="{ 'trial-disable-btn': subItem?.isDisable }"
              >
                {{ subItem?.name }}
              </span>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <!-- handle collapse view -->
      <div class="popup-menu" *ngIf="!isExpanded">
        <div
          class="d-flex flex-column justify-content-center popup-menu-item"
          [ngClass]="{ 'no-pointer': item?.subMenu?.length }"
        >
          <div
            class="menu-item d-flex align-items-center justify-content-center"
            [ngClass]="{
              selected: item?.name === selectedMenuItem?.name,
              'no-sub-items': !item?.subMenu?.length,
              'trial-disable-btn': item?.isDisable
            }"
          >
            <div class="menu-item-name">{{ item?.name }}</div>
            <div
              class="ml-3 px-2 popup-beta-release-wrapper"
              *ngIf="item?.isBetaRelease"
            >
              BETA
            </div>
          </div>
          <div
            class="sub-menu-item d-flex align-items-center justify-content-center"
            *ngFor="let subItem of item?.subMenu"
            (click)="subMenuRouteTo($event, subItem)"
            [ngClass]="{
              selected: subItem?.name === selectedMenuItem?.name,
              'trial-disable-btn': subItem?.isDisable
            }"
          >
            <div class="text-center">
              {{ subItem?.name }}
            </div>
            <!-- <div class="col-2 ml-3 px-2 popup-beta-release-wrapper" *ngIf="subItem?.isBetaRelease"> BETA </div> -->
          </div>
        </div>
      </div>
      <!-- end of pop up view -->
    </div>
  </ng-container>
</ng-template>

<!-- non Prosimo admins navbar -->
<ng-container
  *ngIf="(navbarState$ | async) && !(navbarConfig$ | async)?.prosimoAdmin"
>
  <div
    id="navbar__container"
    class="collapsed d-flex flex-column"
    *ngIf="navbarConfig$ | async as navbarConfig"
    [ngClass]="{
      expanded: isExpanded,
      'read-only': (navbarReadOnly$ | async)
    }"
  >
    <div
      class="flex-grow-1 d-block"
      [ngClass]="{ 'navbar__menu-wrapper': isExpanded }"
    >
      <div class="navbar__menu">
        <ng-container
          *ngFor="let item of navbarConfig?.menuItems"
          [ngTemplateOutlet]="navMenuItem"
          [ngTemplateOutletContext]="{ item: item }"
        >
        </ng-container>
      </div>
    </div>

    <div
      class="navbar__menu--item toggle__btn-wrapper d-flex align-items-center toggle-nav collapsed"
      [class.expanded]="isExpanded"
      (click)="toggleNavbar($event)"
    >
      <div
        class="toggle__btn-icon d-flex justify-content-center align-items-center"
      >
        <i
          class="material-icons expand__icon"
          matTooltip="{{ isExpanded ? 'Collapse' : 'Expand' }}"
          [ngClass]="{ collapsed__icon: isExpanded }"
          >double_arrow</i
        >
      </div>
      <div
        class="menu__item--name collapsed__txt flex-grow-1"
        [ngClass]="{ invisible: !isExpanded }"
      >
        Collapse
      </div>
    </div>
  </div>
</ng-container>

<!-- Prosimo admins nav bar -->
<ng-container
  *ngIf="(navbarState$ | async) && (navbarConfig$ | async)?.prosimoAdmin"
>
  <div
    id="navbar__container"
    class="collapsed no-event"
    *ngIf="navbarConfig$ | async"
  >
    <div class="navbar__menu">
      <div class="navbar__menu--item">
        <div class="menu__item--img">
          <img
            class="unselected-img"
            [src]="(navbarConfig$ | async)?.logo?.imgUrl"
          />
        </div>
      </div>
    </div>
  </div>
</ng-container>
