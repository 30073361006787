<div #noDataContainer id="no-data__container">
  <div *ngIf="noDataParams?.isDataAvailable">
    <ng-content></ng-content>
  </div>
  <div
    class="clr-grey7"
    [ngClass]="
      noDataParams?.type === MESSAGE_TYPES.INFO
        ? 'message info'
        : 'message error'
    "
    *ngIf="!noDataParams?.isDataAvailable"
  >
    {{ noDataParams?.message }}
  </div>
</div>
