export interface ILightboxFormModel {
  title: string;
  hint: string;
  buttonConfigs: Array<ILightboxBtnConfig>;
  cancelButtonType?: ILightboxBtnConfig;
  disableCancel?: boolean;
  hideCancelButton?: boolean;
  panelClass?: string;
  hideCloseIcon?: boolean;
  addPadding?: boolean;
}
export interface ILightboxBtnConfig {
  name: string;
  code: string;
  disabled?: boolean;
}

export interface LightBoxStatusMessage {
  message: string;
  type: LightBoxStatusType;
  stayOpened?: boolean;
  waitTime?: number;
}

export enum LightBoxStatusType {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export const LightboxBtnType: { [key: string]: ILightboxBtnConfig } = {
  SUBMIT: { code: 'SUBMIT', name: 'Submit' },
  DELETE_DISABLE: { code: 'DELETE', name: 'Yes, delete', disabled: true },
  DELETE_ENABLE: { code: 'DELETE', name: 'Yes, delete', disabled: false },
  CREATE: { code: 'CREATE', name: 'Create' },
  UPDATE: { code: 'UPDATE', name: 'Update' },
  CLOSE: { code: 'CLOSE', name: 'Close' },
  BACK: { code: 'BACK', name: 'Back' },
  CANCEL: { code: 'CANCEL', name: 'Cancel', disabled: false },
  CLEAR: { code: 'CLEAR', name: 'Clear' },
  NONE: { code: 'NONE', name: 'None' },
  ACKNOWLEDGE: { code: 'ACKNOWLEDGE', name: 'Acknowledge', disabled: false },
  ACKNOWLEDGE_ALL: {
    code: 'ACKNOWLEDGE ALL',
    name: 'Acknowledge All',
    disabled: false,
  },
  PROCEED: { code: 'PROCEED', name: 'Proceed' },
  NEXT: { code: 'NEXT', name: 'Next', disabled: false },
  SAVE: { code: 'SAVE', name: 'Save', disabled: false },
  ONBOARD: { code: 'ONBOARD', name: 'Onboard', disabled: false },
  SYNC: { code: 'SYNC', name: 'Sync', disabled: false },
};
