import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import {
  CloudTransitSharedFilterState,
  cloudTransitSharedFilterStore,
  CloudTransitSharedFilterStore,
} from './cloud-transit-filter.store';

export class CloudTransitSharedFilterQuery extends Query<CloudTransitSharedFilterState> {
  activeTab$ = this.select((state) => state.activeTab);

  constructor(protected store: CloudTransitSharedFilterStore) {
    super(store);
  }

  get activeTab(): Observable<string> {
    return this.activeTab$;
  }
}

export const cloudTransitSharedFilterQuery = new CloudTransitSharedFilterQuery(
  cloudTransitSharedFilterStore
);
