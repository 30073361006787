import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP_LOCALE } from '@app/common/util/constants';
import { CommonService } from '@app/common/http/service/common.service';
import { CONSTANTS } from 'environments/environment';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { retry, share, switchMap, takeUntil } from 'rxjs/operators';

import {
  HeaderDescription,
  L2HeaderModel,
  L2HeaderPageInfo,
  L2HeaderUserInfo,
} from './header.interface';
import { ESFilter } from '@prosimoio/services';

@Injectable({
  providedIn: 'root',
})
export class HeaderService extends CommonService {
  private isLoggedIn$ = new BehaviorSubject<boolean>(false);
  private userInfo$ = new BehaviorSubject<any>({});
  private showHeader$ = new BehaviorSubject<boolean>(false);
  private l2HeaderConfig$ = new BehaviorSubject<L2HeaderModel>(
    new L2HeaderModel().init()
  );
  private l2HeaderPageInfo$ = new Subject<L2HeaderPageInfo>();
  private actionBtnEvent$ = new Subject<any>();
  private l2HeaderUserConfig$ = new BehaviorSubject<L2HeaderUserInfo>(null);
  private headerDescription$ = new Subject();

  stopPolling: Subject<void>;
  constructor(protected http: HttpClient) {
    super(http);
  }

  setLoggedIn(loggedInStatus: boolean) {
    this.isLoggedIn$.next(loggedInStatus);
  }

  setUserInfo(userInfo: any) {
    this.userInfo$.next(userInfo);
  }

  setShowHeader(showHeader: boolean) {
    this.showHeader$.next(showHeader);
  }

  isLoggedIn() {
    return this.isLoggedIn$.asObservable();
  }

  getUserInfo() {
    return this.userInfo$.asObservable();
  }

  getShowHeader() {
    return this.showHeader$.asObservable();
  }

  setL2HeaderUserConfig(userConfig: L2HeaderUserInfo) {
    this.l2HeaderUserConfig$.next(userConfig);
  }

  getL2HeaderUserConfig() {
    return this.l2HeaderUserConfig$.asObservable();
  }

  setL2HeaderPageInfo(info: L2HeaderPageInfo) {
    this.l2HeaderPageInfo$.next(info);
  }

  getL2HeaderPageInfo() {
    return this.l2HeaderPageInfo$.asObservable();
  }

  setL2HeaderConfig(headerConfig: L2HeaderModel) {
    this.l2HeaderConfig$.next(headerConfig);
  }

  getL2HeaderConfig() {
    return this.l2HeaderConfig$.asObservable();
  }

  getL2HeaderActionEvent() {
    return this.actionBtnEvent$.asObservable();
  }

  triggerActionEvent(event: Event) {
    this.actionBtnEvent$.next(event);
  }

  /**
   * Method to get help contents
   */
  getArticleById(articleId: string): Observable<any> {
    this.path = CONSTANTS.HELP.GET_ARTICLE_BY_ID(APP_LOCALE.EN_US, articleId);
    return this.getData(this.path);
  }

  /**
   * Fetches all the articles matching the search term
   * @param searchTerm - string
   */
  getArticlesBySearchTerm(searchTerm: string): Observable<any> {
    this.path = CONSTANTS.HELP.GET_ARTICLES_BY_SEARCH_TERM;
    return this.create({ query: searchTerm });
  }

  logout() {
    this.path = CONSTANTS.USER_PROFILE.LOGOUT_USER;
    return this.delete();
  }

  searchAlertData() {
    const searchFormPayload: ESFilter = {
      page: {
        start: 0,
        size: 100,
      },
      exactFilters: [
        {
          field: 'isRead',
          values: ['false'],
        },
        {
          field: 'state',
          values: ['open'],
        },
      ],
      sort: [],
    };
    this.path = CONSTANTS.ALERTS.SEARCH;
    return this.create(searchFormPayload);
  }

  downloadPDF(path: string) {
    return this.getData(path);
  }

  getPDFStatus(path: string) {
    return this.getData(path);
  }

  getPDFDownloadProgress(path: string) {
    this.stopPolling = new Subject();
    const progress$ = timer(1, 2000).pipe(
      switchMap(() => this.getPDFStatus(path)),
      retry(3),
      share(),
      takeUntil(this.stopPolling)
    );
    return progress$;
  }

  setStopPolling() {
    if (this.stopPolling) {
      this.stopPolling.next();
      this.stopPolling.complete();
    }
  }

  setHeaderDescription(headerDescConfig: HeaderDescription) {
    this.headerDescription$.next(headerDescConfig);
  }

  getHeaderDescription() {
    return this.headerDescription$.asObservable();
  }
  fetchAvailableExternalReports() {
    this.path = CONSTANTS.PLAYGROUND.API.EXTERNAL_REPORTS;
    return this.read('');
  }
}
