<div id="carousel-container" [ngClass]="{ vertical: isVertical }">
  <ng-container *ngIf="!isDynamic">
    <div class="move previous" *ngIf="displayConfig?.count < cardCount">
      <i
        class="material-icons previous-icon"
        (click)="onSlide($event, 'previous')"
        [ngClass]="{ 'no-event': displayConfig?.start === 0 }"
        >chevron_left</i
      >
    </div>
    <div
      #cardsContainer
      class="cards-container row no-gutters flex-nowrap justify-content-center"
      (window:resize)="onResize($event)"
    >
      <ng-content select="[carousel-card]"></ng-content>
    </div>

    <div class="move next" *ngIf="displayConfig?.count < cardCount">
      <i
        class="material-icons next-icon"
        (click)="onSlide($event, 'next')"
        [ngClass]="{ 'no-event': displayConfig?.end >= cardCount }"
        >chevron_right</i
      >
    </div>
  </ng-container>
  <ng-container *ngIf="isDynamic && !isVertical">
    <div class="move previous" *ngIf="isStepper && selectedPageIndex > 0">
      <i
        class="material-icons previous-icon dynamic"
        (click)="onPreviousPage($event)"
        >chevron_left</i
      >
    </div>
    <div
      #cardsContainer
      class="cards-container d-flex flex-column justify-content-center"
      (window:resize)="onResize($event)"
    >
      <div class="d-flex justify-content-center" id="carousel-cards-container">
        <ng-content select="[hcard]"></ng-content>
      </div>
      <div
        class="d-flex align-items-center justify-content-center page-info"
        *ngIf="!isStepper && pagesInfo?.length > 1"
      >
        <div
          class="m-2 page"
          [ngClass]="{ selected: pageIndex === selectedPageIndex }"
          *ngFor="let page of pagesInfo; index as pageIndex"
          (click)="selectDisplayPage(page, pageIndex)"
        ></div>
      </div>
    </div>
    <div
      class="move next"
      *ngIf="isStepper && selectedPageIndex < pagesInfo?.length - 1"
    >
      <i class="material-icons next-icon dynamic" (click)="onNextPage($event)"
        >chevron_right</i
      >
    </div>
  </ng-container>
  <ng-container *ngIf="isDynamic && isVertical">
    <div class="d-flex flex-column">
      <div
        class="d-flex align-items-center py-1 justify-content-between more-tabs"
        *ngIf="selectedPageIndex > 0"
        (click)="onPreviousPage($event)"
      >
        <div class="more-tabs-txt">View More Tabs</div>
        <i class="material-icons more-tabs-icon chevron-down">expand_more</i>
      </div>
      <div
        #cardsContainer
        class="cards-container d-flex flex-column"
        (window:resize)="onResize($event)"
      >
        <div
          class="d-flex flex-column justify-content-center"
          id="carousel-cards-container"
        >
          <ng-content select="[vcard]"></ng-content>
        </div>
      </div>
      <div
        class="d-flex align-items-center py-1 justify-content-between more-tabs more-tabs-bottom"
        *ngIf="selectedPageIndex < pagesInfo?.length - 1"
        (click)="onNextPage($event)"
      >
        <div class="more-tabs-txt">View More Tabs</div>
        <i class="material-icons more-tabs-icon chevron-up">expand_less</i>
      </div>
    </div>
  </ng-container>
</div>
