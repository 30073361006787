import { LegendData } from '@app/component-common/legend/legend.model';
import {
  networksDashboardSharedStore,
  IActiveTabDetails,
  NetworksDashboardSharedStore,
} from './network-dashboard.store';

export class NetworksDashboardStoreService {
  constructor(private networkDashboardStore: NetworksDashboardSharedStore) {}

  updateCurrentNav(activeNavigation: any) {
    this.networkDashboardStore.update((state) => ({
      activeNavigation,
    }));
  }

  updateCurrentTabDetails(activeTabDetails: IActiveTabDetails) {
    this.networkDashboardStore.update((state) => ({ activeTabDetails }));
  }

  updateCurrentMCNTabDetails(mcnActiveTabDetails: IActiveTabDetails) {
    this.networkDashboardStore.update((state) => ({ mcnActiveTabDetails }));
  }

  updateActiveSearchTerm(activeSearchTerm: string) {
    this.networkDashboardStore.update((state) => ({ activeSearchTerm }));
  }

  updateActiveLegends(activelegends: Array<LegendData>) {
    this.networkDashboardStore.update((state) => ({ activelegends }));
  }

  resetNetworksDashboardStore() {
    networksDashboardSharedStore.reset();
  }
}

export const networksDashboardStoreService = new NetworksDashboardStoreService(
  networksDashboardSharedStore
);
