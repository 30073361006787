import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PrintFilterHeaderService {
  private filterData$ = new BehaviorSubject<any>(null);
  private repeatPrintHeader$ = new BehaviorSubject<boolean>(false);

  getFilterData(): Observable<any> {
    return this.filterData$.asObservable();
  }

  setFilterData(data: any) {
    this.filterData$.next(data);
  }

  setRepeatHeaderType(status: boolean) {
    this.repeatPrintHeader$.next(status);
  }

  isRepeatHeader(): Observable<boolean> {
    return this.repeatPrintHeader$.asObservable();
  }
}
