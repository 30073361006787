<div class="status__container">
  <img
    [attr.src]="(statusConfig$ | async)?.img?.src"
    [attr.width]="(statusConfig$ | async)?.img?.style?.width"
    [attr.height]="(statusConfig$ | async)?.img?.style?.height"
  />
  <p>{{ (statusConfig$ | async)?.statusMsg1 }}</p>
  <p>{{ (statusConfig$ | async)?.statusMsg2 }}</p>
  <div class="action">
    <button
      class="btn"
      *ngIf="(statusConfig$ | async)?.action?.type === 'LOGIN'"
      mat-raised-button
      color="primary"
    >
      <a href="" [routerLink]="[(statusConfig$ | async)?.action?.link]"
        >Log in</a
      >
    </button>
  </div>
</div>
