<div
  class="w-100 h-100 d-flex flex-column bg-light-shade overview-dialog-template position-relative"
>
  <div class="mat-dialog-content overlapping-cidr">
    <pcomp-overview-container
      [overviewContainerConfig]="overviewContainerConfig"
      (onDownloadValue)="onDownload($event)"
    >
      <div class="w-50 mt-3" *ngIf="data?.length > 0">
        <mat-form-field class="outline-input-field w-50" appearance="outline">
          <input
            [(ngModel)]="searchText"
            matInput
            placeholder="Smart Search"
            autocomplete="off"
          />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>

      <app-overlapping-cider-table
        [data]="data"
        [searchText]="searchText"
      ></app-overlapping-cider-table>
    </pcomp-overview-container>
  </div>
  <div
    mat-dialog-actions
    class="justify-content-center position-absolute bg-white"
  >
    <button
      class="button d-flex align-items-center bg-blue8"
      mat-raised-button
      color="primary"
      (click)="closeDialog()"
    >
      Okay
    </button>
  </div>
</div>
