<div
  class="d-flex justify-content-center align-items-center level-style p-1"
  [ngClass]="{
    'active-status-pill':
      (statusConfig?.key | lowercase) === (STATUS.ACTIVE | lowercase),
    'inactive-status-pill':
      (statusConfig?.key | lowercase) === (STATUS.INACTIVE | lowercase),
    'failed-status-pill':
      (statusConfig?.key | lowercase) === (STATUS.FAILED | lowercase)
  }"
>
  {{ statusConfig?.displayName }}
</div>
