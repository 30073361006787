import { Page, Sort } from '@prosimoio/services';

export type Application = {
  appName?: string;
  cloudService?: string;
  appOnboardType?: string;
  cloudHostedType?: string;
  cspType?: string;
  dcID?: string;
  accessType?: string;
  citrixIP?: Array<string>;
  appURLs?: Array<AppDomain>;
  certID?: string;
  cacheRuleID?: string;
  deployed?: boolean;
  dnsDiscovery?: boolean;
  dnsService?: DnsService;
  editableFields?: any;
  enableMultiCloud?: boolean;
  id?: string;
  idpID?: string;
  interactionType?: string;
  onboardType?: string;
  optOption?: string;
  optimizeAppExperience?: boolean;
  policyGroupID?: string;
  policyIDs?: Array<any>;
  policyUpdated?: string;
  samlRewrite?: any;
  source?: any;
  appType?: string;
  appAccessType?: string;
  status?: string;
  createdTime?: string;
  isChecked?: boolean;
  updatedTime?: string;
};

export type DnsService = {
  configured: boolean;
  id: string;
  type: string;
};

export type AppDomain = {
  id?: string;
  teamID?: string;
  appFqdn?: string;
  internalDomain?: string;
  exceptionDomains?: Array<string>;
  addressType?: string;
  domainType?: string;
  pappFqdn?: string;
  cloudKeyID?: string;
  certID?: string;
  cacheRuleID?: string;
  cacheRuleIDControl?: string;
  dnsService?: DNSService;
  urlRewriteRule?: string;
  protocols?: Array<AppProtoPort>;
  extProtocols?: Array<AppProtoPort>;
  subdomainIncluded?: boolean;
  connectionOption?: string;
  regions?: Array<AppDomainRegion>;
  wafHTTP?: any;
  healthCheckInfo?: AppHealthCheckInfo;
  deployed?: boolean;
  ip?: string;
  pappFqdnInternal?: string;
  cloudType?: string;
  transitPappFqdn?: string;
  pappFqdnDefault?: string;
  interactionType?: string;
  dcID?: string;
  dcAppIp?: string;
  dnsCustom?: DNSCustom;
  RegionsUpdate?: boolean;
  DnsCustomUpdate?: boolean;
  prosimoSubdomain?: string;
  prosimoDomain?: string;
  serviceIP?: string;
  serviceIPType?: string;
  namespaceID?: string;
};

export type AppDomainRegion = {
  id?: string;
  name?: string;
  connectionDetails?: any;
  inputEndpoints?: Array<string>;
  inputType?: string;
  selectedEndpoints?: Array<string>;
  networkInfo?: any;
  modifyTgwAppRouteTable?: string;
  edgeID?: string;
  networkApplianceIP?: string;
  endPoints?: Array<AppEndpoint>;
  attachPoints?: Array<AppAttachPoint>;
  connOption?: string;
  locationID?: string;
  buckets?: Array<string>;
  regionType?: string;
  dnsCustom?: DNSCustom;
  appName?: string;
  networkSubnet?: string;
};

export type AppEndpoint = {
  displayName?: string;
  appNetworkID?: string;
  attachPointID?: string;
  attachPointIP?: string;
  appIP?: string;
  appFqdn?: string;
  attachPointDisplayName?: string;
  networkSubnet?: string;
};

export type AppAttachPoint = {
  attachPointDisplayName: string;
  attachPointID: string;
};

export type DNSService = {
  id?: string;
  type?: string;
  regions?: Array<DNSRegion>;
  configured?: boolean;
};

export type DNSRegion = {
  id: string;
  name: string;
};

export type DNSCustom = {
  appId: string;
  appName: string;
  servers: Array<string>;
  isHealthCheckEnabled: boolean;
};

export type AppProtoPort = {
  protocol: string;
  port: number;
  webSocketEnabled: boolean;
  paths: Array<string>;
  portList: Array<string>;
};

export type AppHealthCheckInfo = {
  enabled: boolean;
  endpoint: string;
};

export type ApplicationSearchFilter = {
  value?: string;
  statusFilter?: Array<string>;
  appAccess?: Array<string>;
  page?: Page;
  sort?: Array<Sort>;
  onboardType?: Array<string>;
  deployed?: boolean;
  category?: string;
  interactionType?: Array<string>;
  networkService?: string;
};

export type ApplicationQuery = {
  category: string;
  records: Array<Application>;
  totalCount: number;
};
