import { Store, StoreConfig } from '@datorama/akita';
import { TimeRange } from '../../shared/calendar.component';
import {
  Network,
  NetworkGroup,
  NetworkMasterSelectConfig,
} from '@app/dashboard/shared/filter-list/network-list/network-list.model';
import { OnboardedApp } from '@app/dashboard/shared/filter-list/client-list/client-list.model';
import {
  Region,
} from '@app/dashboard/shared/filter-list/region-list/region-list.model';
import { OnboardedAppMasterSelectConfig } from '@app/dashboard/shared/filter-list/app-list/apps-list.model';
import {
  PrivateLink,
  PrivateLinkMasterSelectConfig,
} from '@app/dashboard/shared/filter-list/private-link-list/private-link-list.model';

export enum TimeRangeType {
  PAST_ONE_DAY = 'PAST_ONE_DAY',
  PAST_ONE_WEEK = 'PAST_ONE_WEEK',
  PAST_ONE_MONTH = 'PAST_ONE_MONTH',
  CUSTOM = 'CUSTOM',
}

export interface MCNInsightsSharedFilterState {
  selectedSourceNetworks?: Array<Network>;
  selectedSourcePrivateLinks?: Array<PrivateLink>;
  selectedSourceNetworkGroups?: Array<NetworkGroup>;
  selectedTargetNetworks?: Array<Network>;
  selectedTargetNetworkGroups?: Array<NetworkGroup>;
  selectedSourceRegions?: Array<Region>;
  selectedTargetRegions?: Array<Region>;
  selectedOnboardedApps?: Array<OnboardedApp>;
  timeRange?: TimeRange;
  selectedTimeRangeType?: TimeRangeType;
  insightTimeRange?: TimeRange;
  mainDashboardTimeRange?: TimeRange;
  costDashboardTimeRange?: TimeRange;
  activeTab?: string;
  activeSubMenu?: string;
  levelTwoActiveSubMenu?: string;
  source?: string;
  isDataNew?: boolean;
  totalSourceNetworks?: number;
  totalSourcePrivateLinks?: number;
  totalSourceNetworkGroups?: number;
  totalSourceRegions?: number;
  totalTargetNetworks?: number;
  totalTargetNetworkGroups?: number;
  totalTargetRegions?: number;
  totalOnboardedApps?: number;
}

export function createInitialState(): MCNInsightsSharedFilterState {
  return {
    selectedSourceNetworks: [NetworkMasterSelectConfig],
    selectedSourcePrivateLinks: [PrivateLinkMasterSelectConfig],
    selectedSourceNetworkGroups: [],
    selectedTargetNetworks: [NetworkMasterSelectConfig],
    selectedTargetNetworkGroups: [],
    selectedSourceRegions: [],
    selectedTargetRegions: [],
    selectedOnboardedApps: [OnboardedAppMasterSelectConfig],
    timeRange: null,
    selectedTimeRangeType: TimeRangeType.PAST_ONE_WEEK,
    insightTimeRange: null,
    mainDashboardTimeRange: null,
    costDashboardTimeRange: null,
    activeTab: '',
    levelTwoActiveSubMenu: '',
    activeSubMenu: '',
    source: 'FILTER_HISTORY',
    isDataNew: true,
    totalSourceNetworks: null,
    totalSourcePrivateLinks: null,
    totalSourceNetworkGroups: null,
    totalSourceRegions: null,
    totalTargetNetworks: null,
    totalTargetNetworkGroups: null,
    totalTargetRegions: null,
    totalOnboardedApps: null,
  };
}

@StoreConfig({ name: 'mcn-insights-filters', resettable: true })
export class MCNInsightsSharedFilterStore extends Store<MCNInsightsSharedFilterState> {
  constructor() {
    super(createInitialState());
  }
}

export const mcnInsightsSharedFilterStore = new MCNInsightsSharedFilterStore();
