import { TimeISO } from '@prosimoio/services';
import { SearchResponse } from './common';

export type IpPrefixList = {
  id?: string;
  name: string;
  teamID?: string;
  cidrs: CidrList;
  createdTime?: TimeISO;
  updatedTime?: TimeISO;
};

export type CidrList = {
  prefixes: Array<PrefixInfo>;
};

export type PrefixInfo = {
  prefix: string;
  min: number;
  max: number;
};

export type BgpGroup = {
  id: string;
  name: string;
  teamID: string;
  communities: Array<string>;
  createdTime: TimeISO;
  updatedTime: TimeISO;
};

export type GroupingResponse<T> = SearchResponse<T>;
