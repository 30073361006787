import { FONT_FAMILY } from '@app/common/util/constants';
import {
  ascending,
  descending,
  extent,
  mouse,
  scaleBand,
  scaleLinear,
  scaleLog,
  scaleOrdinal,
  scaleTime,
  timeFormat,
} from 'd3';

export const CHART_CONSTANTS = {
  SORT_ORDER: {
    ASCENDING: 'ASC',
    DESCENDING: 'DESC',
  },
  DAY_IN_MILLI_SECONDS: 24 * 60 * 60 * 1000,
  BAR_ORIENTATION: {
    HORIZONTAL: 'HORIZONTAL',
    VERTICAL: 'VERTICAL',
  },
};

export const PIXELS = {
  MARGIN: {
    BOTTOM: 20,
    TOP: 20,
    RIGHT: 50,
    LEFT: 50,
  },
  TRANSFORM: 100,
  HEIGHT: 200,
  TOOLTIP_ARROW: 30,
};

export const TICK_TYPES_BY = {
  HOURLY: 'HOURLY',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY',
};

export const DATE_TIME_FORMAT = {
  DAY_MONTH_DATE: '%a %m/%d',
  HOUR_AM_PM: '%I %p',
  FOR_TOOL_TIP: '%a %m/%d %I:%M %p',
};

export const TIME_FORMAT = {
  MILLI_SECONDS: timeFormat('.%L'),
  SECONDS: timeFormat(':%S'),
  MINUTE: timeFormat('%I:%M'),
  HOUR: timeFormat('%I %p'),
  DAY: timeFormat('%a %d'),
  WEEK: timeFormat('%b %d'),
  MONTH: timeFormat('%B'),
  YEAR: timeFormat('%Y'),
};

export const SCALE_TYPES = {
  DATE_TIME: 'DATE_TIME',
  LINEAR: 'LINEAR',
  SCALE_BAND: 'SCALE_BAND',
  LOG: 'LOG',
};

export const BAR_CHART_TYPES = {
  HORIZONTAL: 'HORIZONTAL',
  VERICAL: 'VERTICAL',
  GROUPED_VERTICAL: 'GROUPED_VERTICAL',
  GROUPED_HORIZONTAL: 'GROUPED_HORIZONTAL',
  STACKED_VERTICAL: 'STACKED_VERTICAL',
  STACKED_HORIZONTAL: 'STACKED_HORIZONTAL',
};

export const CHART_COLORS = [
  '#99affd',
  '#ACDAFF',
  '#7de260',
  '#e9b39e',
  '#bebada',
  '#fb8072',
  '#80b1d3',
  '#fdb462',
  '#b3de69',
];

// TODO: Replace CHART_COLORS with this object
export const NEW_CHART_COLORS = {
  ORANGE: '#ff883f',
  GREEN: '#44d347',
  GREEN_1: '#7de260',
  GREEN_2: '#b3de69',
  GREEN_3: '#149B3A',
  GREEN_4: '#b4cb6b',
  BLUE: '#acdaff',
  BLUE_2: '#80b1d3',
  BLUE_3: '#f0fbff',
  BLUE_4: '#daf6ff',
  BLUE_5: '#c6f2ff',
  BLUE_6: '#93e5ff',
  BLUE_7: '#5cd9ff',
  ORANGE_2: '#e9b39e',
  PURPLE: '#99affd',
  PURPLE_1: '#bc80bd',
  PURPLE_2: '#8783de',
  PINK: '#1DAEFF',
  LIGHT_GREYISH_BLUE: '#bebada',
  RED: '#ff3738',
  SOFT_RED: '#fb8072',
  ORANGE_3: '#fdb462',
  ORANGE_4: '#ffbe95',
  ORANGE_5: '#ee762e',
  GREY: '#D0D8E4',
};

export const DONUT_CHART_COLORS = [
  '#8680bd',
  '#fdbf99',
  NEW_CHART_COLORS.PURPLE_1,
  '#b3de69',
  '#7cdbd3',
  '#1DAEFF',
  '#D0D8E4',
];

export const TIME_INTERVALS = {
  HOUR: 'HOUR',
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
};

export const ATTRIBUTES = {
  STYLES: {
    VISIBILITY: {
      HIDDEN: 'hidden',
      VISIBLE: 'visible',
    },
  },
};

export enum VectorType {
  LINE = 'LINE',
  CIRCLE = 'CIRCLE',
}

export const CHART_TYPES = {
  LINE: 'LINE',
  BAR: 'BAR',
  CANDLE_STICK: 'CANDLE_STICK',
};

export class ChartUtils {
  static canvas;
  static MAX_TICK_LENGTH = 8;

  static sort(inData: any, sortKey: string, sortOrder: string): any {
    const data = [...inData];
    if (sortOrder === CHART_CONSTANTS.SORT_ORDER.ASCENDING) {
      data.sort((a, b) => {
        if (sortKey) {
          return ascending(a[sortKey], b[sortKey]);
        } else {
          return ascending(a, b);
        }
      });
    } else {
      data.sort((a, b) => {
        if (sortKey) {
          return descending(a[sortKey], b[sortKey]);
        } else {
          return descending(a, b);
        }
      });
    }
    return data;
  }

  static getAxisWidth(): number {
    return PIXELS.MARGIN.RIGHT + PIXELS.TRANSFORM;
  }

  static addXAxisPadding(
    data: Array<any>,
    timeInterval: string = TIME_INTERVALS.HOUR
  ): any {
    // filter zeroes
    const timestampExtent: any = extent(data, (d: any) => {
      if (d?.startTime > 0) {
        return new Date(d?.startTime);
      }
    });
    let startTimestamp;
    let endTimestamp;
    switch (timeInterval) {
      case TIME_INTERVALS.HOUR:
      case TIME_INTERVALS.DAY:
        startTimestamp =
          new Date(timestampExtent[0]).getTime() -
          CHART_CONSTANTS.DAY_IN_MILLI_SECONDS / 24;
        endTimestamp =
          new Date(timestampExtent[1]).getTime() +
          CHART_CONSTANTS.DAY_IN_MILLI_SECONDS / 24;
        break;
      case TIME_INTERVALS.WEEK:
        startTimestamp =
          new Date(timestampExtent[0]).getTime() -
          CHART_CONSTANTS.DAY_IN_MILLI_SECONDS / 2;
        endTimestamp =
          new Date(timestampExtent[1]).getTime() +
          CHART_CONSTANTS.DAY_IN_MILLI_SECONDS / 2;
        break;
      case TIME_INTERVALS.MONTH:
        startTimestamp =
          new Date(timestampExtent[0]).getTime() -
          CHART_CONSTANTS.DAY_IN_MILLI_SECONDS * 2;
        endTimestamp =
          new Date(timestampExtent[1]).getTime() +
          CHART_CONSTANTS.DAY_IN_MILLI_SECONDS * 2;
        break;
    }

    return {
      startTimestamp,
      endTimestamp,
    };
  }

  static clone(inData): any {
    return JSON.parse(JSON.stringify(inData));
  }

  static getXScale(
    scaleType: string,
    viewDims: Array<number>,
    data: any,
    timeInterval: string = TIME_INTERVALS.HOUR
  ) {
    switch (scaleType.toUpperCase()) {
      case SCALE_TYPES.LINEAR:
        /**
         * extract all the values from the object except the name
         * find the sum of values
         * find the max among the sum of values
         */
        const xDomainMax = Math.max(
          ...data.map((stat) =>
            Object.values(stat)
              .filter((val: any) => typeof val === 'number' && isFinite(val))
              .reduce((a: any, b: any) => a + b)
          )
        );
        return scaleLinear()
          .range([0, viewDims[0] - ChartUtils.getAxisWidth()])
          .domain([0, xDomainMax]);
      case SCALE_TYPES.DATE_TIME:
        const { startTimestamp, endTimestamp } = ChartUtils.addXAxisPadding(
          data,
          timeInterval
        );
        return scaleTime()
          .range([0, viewDims[0] - ChartUtils.getAxisWidth()])
          .domain([startTimestamp, endTimestamp]);
      case SCALE_TYPES.SCALE_BAND:
        return scaleBand()
          .domain(data.map((stat) => stat.name))
          .range([0, viewDims[0]]);
    }
  }

  static getYScale(
    scaleType: string,
    viewDims: Array<number>,
    data: any,
    tickVals: Array<any> = null,
    useDefaultDomainMinValue: boolean = false
  ) {
    let domainVals = null;
    if (tickVals) {
      domainVals = extent(tickVals);
    } else {
      domainVals = extent(
        data
          ?.filter((stat) => !stat?.ignorePlotting)
          ?.map((stat) =>
            typeof stat.count === 'number'
              ? stat.count
              : typeof stat.value === 'number'
              ? stat.value
              : stat || 0
          ) as Array<any>
      );
    }

    /**
     * When domainVals min and max values are same, y-axis prints only one label and the label is
     * centered along the y-axis line.
     *
     * Below logic handles setting a valid range by setting the min to zero when both min and max are
     * same.
     */

    if (
      domainVals &&
      domainVals.length === 2 &&
      domainVals[0] === domainVals[1]
    ) {
      domainVals = [0, domainVals[1]];
    }

    switch (scaleType.toUpperCase()) {
      case SCALE_TYPES.LINEAR:
        return scaleLinear()
          .range([viewDims[1], 0])
          .domain([useDefaultDomainMinValue ? 0 : domainVals[0], domainVals[1]])
          .nice(tickVals ? tickVals.length : 5);
      case SCALE_TYPES.SCALE_BAND:
        return scaleBand()
          .domain(data.map((stat) => stat.name))
          .range([viewDims[1], 0]);
      case SCALE_TYPES.LOG:
        return scaleLog()
          .base(2)
          .range([viewDims[1], 0])
          .domain([1, domainVals[1]])
          .nice();
    }
  }

  static getXPoint(scaleType: string, scale: any, data: any) {
    switch (scaleType.toUpperCase()) {
      case SCALE_TYPES.DATE_TIME:
        return scale(data.startTime) + 50;
      case SCALE_TYPES.SCALE_BAND:
        return (
          scale(data.name) +
          scale.bandwidth() / 2 +
          (PIXELS.TRANSFORM - PIXELS.TOOLTIP_ARROW)
        );
    }
  }

  /**
   * accepts a sentence/words separated by space, replaces the blank spaces with an underscore.
   * @param randomText
   */
  static replaceBlankWithUnderscore(randomText: string): string {
    if (randomText) {
      return randomText.split(/[\s\?\/\\\|\.]/g).join('_');
    }
  }

  static getXYAndViewPortDims(
    elemRef: any,
    miscellaneous = {
      isD3Element: true,
      coordinates: [0, 0],
      tooltipWidth: 200,
    }
  ) {
    if (!elemRef) {
      return {
        x: 0,
        y: 0,
        clientWidth: 0,
        clientHeight: 0,
      };
    }
    const tooltipParentContainerRef: any =
      document.querySelector('.basic-container');

    let mouseCoordinates = miscellaneous?.coordinates;

    if (miscellaneous?.isD3Element) {
      mouseCoordinates = mouse(tooltipParentContainerRef);
    }

    let tooltipXPosition = mouseCoordinates[0];

    if (
      tooltipXPosition + miscellaneous?.tooltipWidth >
      tooltipParentContainerRef.clientWidth
    ) {
      tooltipXPosition = mouseCoordinates[0] - miscellaneous?.tooltipWidth;
    }

    return {
      x: tooltipXPosition,
      y: tooltipParentContainerRef.clientHeight - mouseCoordinates[1] + 6,
      clientWidth: tooltipParentContainerRef.clientWidth,
      clientHeight: tooltipParentContainerRef.clientHeight,
    };
  }

  static removeDashes(value: string): string {
    return value ? value.toString().replace(/-/g, '') : '';
  }

  /**
   * Method to filter plottable data. chart data contains
   *  plottable - ignorePlotting set to false
   *  unplottable - ignorePlotting set to true
   *
   * @param data
   * @param chartType
   */

  static filterPlottableDataPoints(data: any, chartType: string): Array<any> {
    switch (chartType) {
      case CHART_TYPES.LINE:
        return data.map(({ name, values }) => {
          return {
            name,
            values: values.filter((val) => !val.ignorePlotting),
          };
        });
      case CHART_TYPES.BAR:
      case CHART_TYPES.CANDLE_STICK:
        return data.filter((val) => !val?.ignorePlotting);
    }
  }

  /**
   * Method to get canvas context.
   */
  static getCanvasContext(): any {
    if (!ChartUtils.canvas) {
      ChartUtils.canvas = document.createElement('canvas');
    }
    return ChartUtils.canvas.getContext('2d');
  }

  /**
   * Method to calculate the width of the given text
   * @param text
   * @param fontSize
   * @param fontFace
   * @returns number
   */
  static getTextWidth(text, fontSize = 12, fontFace = FONT_FAMILY): number {
    if (!text) {
      return;
    }
    let context = ChartUtils.getCanvasContext();
    context.font = fontSize + 'px ' + fontFace;
    return context.measureText(text).width;
  }

  static getChartColors(
    uniqueNames: Array<string>,
    colors: Array<string>,
    isSort: boolean = false
  ): any {
    return scaleOrdinal()
      .domain(isSort ? uniqueNames.sort(ascending) : uniqueNames)
      .range(colors);
  }

  /**
   *
   * @param ticks - all tick labels
   * @param trimTicks - if true, trims the label length and adds ellipsis
   * @param chartWidth - chart width
   * @returns
   */
  static getRotationAngle(
    ticks: any[],
    trimTicks: boolean = true,
    chartWidth: number
  ): number {
    let angle = 0;
    let maxTicksLength = 0;
    const maxAllowedLength = ChartUtils.MAX_TICK_LENGTH;
    for (let i = 0; i < ticks.length; i++) {
      let tickLength = this.getTextWidth(ticks[i].toString());
      if (trimTicks) {
        tickLength = this.getTextWidth(this.tickTrim(ticks[i]));
      }

      if (tickLength > maxTicksLength) {
        maxTicksLength = tickLength;
      }
    }

    const len = Math.min(maxTicksLength, maxAllowedLength);
    const charWidth = 8; // need to measure this
    const wordWidth = len * charWidth;

    let baseWidth = wordWidth;
    const maxBaseWidth = ticks?.length
      ? Math.floor(chartWidth / ticks?.length)
      : 0;

    // calculate optimal angle
    while (baseWidth >= maxBaseWidth && angle > -90) {
      angle -= 30;
      baseWidth = Math.cos(angle * (Math.PI / 180)) * wordWidth;
    }

    return angle;
  }

  static tickTrim(
    label: string,
    maxTickLength: number = ChartUtils.MAX_TICK_LENGTH
  ): string {
    return this.trimLabel(label, maxTickLength);
  }

  /**
   *
   * @param val
   * @param max
   * @returns
   */
  static trimLabel(val: any, max: number = ChartUtils.MAX_TICK_LENGTH): string {
    if (typeof val !== 'string') {
      if (typeof val === 'number') {
        return val + '';
      } else {
        return '';
      }
    }

    val = val.trim();
    if (val.length <= max) {
      return val;
    } else {
      return `${val.slice(0, max)}...`;
    }
  }
}
