import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OverviewContentService {
  private overviewConfig$: BehaviorSubject<any> = new BehaviorSubject({});

  setOverviewConfig(overviewConfig: any): void {
    this.overviewConfig$.next(overviewConfig);
  }

  getOverviewConfig(): Observable<any> {
    return this.overviewConfig$.asObservable();
  }
}
