import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NoDataModel } from './no-data.model';

@Injectable({
  providedIn: 'root',
})
export class NoDataService {
  private data$ = new Subject<NoDataModel>();

  constructor() {}

  setData(inData: NoDataModel) {
    this.data$.next(inData);
  }

  getData(): Observable<NoDataModel> {
    return this.data$.asObservable();
  }
}

export interface NoDataParams {
  isDataAvailable: boolean;
  message: string;
}
