<div class="table-container bg-blue8-op5 bdr-blue8-op30 w-100 clr-grey7">
  <table class="dynamic-table">
    <thead>
      <tr>
        <th
          *ngFor="let header of tabularViewConfig?.tableData[0]"
          [style.min-width]="tabularViewConfig?.minColWidth"
          [style.max-width]="tabularViewConfig?.maxColWidth"
        >
          <div class="ellipsis">{{ header }}</div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let row of tabularViewConfig?.tableData.slice(1); let i = index"
      >
        <td
          *ngFor="let cell of row; let i = index"
          [style.min-width]="tabularViewConfig?.minColWidth"
          [style.max-width]="tabularViewConfig?.maxColWidth"
          #cellRef
        >
          <div
            *ngIf="cell?.key !== 'action'"
            class="ellipsis"
            [matTooltip]="cell?.displayName"
            (mouseenter)="showTooltip(tooltip, matCell)"
            [matTooltipClass]="tooltipThemeClass"
            #tooltip="matTooltip"
            #matCell
          >
            {{ cell?.displayName }}
          </div>
          <div *ngIf="cell?.key === 'action'">
            <div class="d-flex align-items-center justify-content-end">
              <mat-icon
                matTooltip="Edit"
                [matTooltipClass]="tooltipThemeClass"
                class="clr-grey4 cursor-pointer"
                (click)="onEdit(row, i)"
                >create</mat-icon
              >
              <mat-icon
                matTooltip="Delete"
                [matTooltipClass]="tooltipThemeClass"
                class="clr-grey4 ml-1 cursor-pointer"
                (click)="onDelete(row, i)"
                >delete_outline</mat-icon
              >
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
