import { Query } from '@datorama/akita';
import { AdminState, AdminStore, adminStore } from './admin.store';
import { Observable } from 'rxjs';

export class AdminQuery extends Query<AdminState> {
  accountStatus$ = this.select((state) => state.accountStatus);

  constructor(protected store: AdminStore) {
    super(store);
  }
  get accountStatus(): Observable<string> {
    return this.accountStatus$;
  }
}

export const adminQuery = new AdminQuery(adminStore);
