import { TimeRange } from '@prosimoio/services';
import { Network } from '@app/dashboard/shared/filter-list/network-list/network-list.model';
import { OnboardedApp } from '@app/dashboard/shared/filter-list/client-list/client-list.model';
import { Region } from '@app/dashboard/shared/filter-list/region-list/region-list.model';
import {
  playgroundFilterStore,
  PlaygroundFilterStore,
  TimeRangeType,
} from './playground-filter.store';
import { Restrictions } from '@app/common/components/filter/playground-filter/playground-filter.model';

export class PlaygroundFilterService {
  constructor(private playgroundFilterStore: PlaygroundFilterStore) {}

  updateSelectedNetworks(selectedNetworks: Array<Network>) {
    this.playgroundFilterStore.update((state) => ({
      selectedNetworks,
    }));
  }

  updateSelectedPrivateLinks(selectedPrivateLinks: Array<any>) {
    this.playgroundFilterStore.update((state) => ({
      selectedPrivateLinks,
    }));
  }

  updateSelectedRegions(selectedRegions: Array<Region>) {
    this.playgroundFilterStore.update((state) => ({
      selectedRegions,
    }));
  }

  updateSelectedOnboardedApps(selectedOnboardedApps: Array<OnboardedApp>) {
    this.playgroundFilterStore.update((state) => ({
      selectedOnboardedApps,
    }));
  }

  updateTimeRange(timeRange: TimeRange) {
    this.playgroundFilterStore.update((state) => ({
      timeRange,
    }));
  }

  updateActiveTab(activeTab: string) {
    this.playgroundFilterStore.update((state) => ({
      activeTab,
    }));
  }

  updateActiveSubMenu(activeSubMenu: string) {
    this.playgroundFilterStore.update((state) => ({
      activeSubMenu,
    }));
  }

  updateLevelTwoActiveSubMenu(levelTwoActiveSubMenu: string) {
    this.playgroundFilterStore.update((state) => ({
      levelTwoActiveSubMenu,
    }));
  }

  updateSource(source: string) {
    this.playgroundFilterStore.update((state) => ({
      source,
    }));
  }

  updateSelectedTimeRangeType(selectedTimeRangeType: TimeRangeType) {
    this.playgroundFilterStore.update((state) => ({
      selectedTimeRangeType,
    }));
  }

  updateRestrictions(restrictions: Restrictions) {
    this.playgroundFilterStore.update(state => ({
      restrictions
    }))
  }
}

export const playgroundFilterService = new PlaygroundFilterService(
  playgroundFilterStore
);
