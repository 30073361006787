import { Store, StoreConfig } from '@datorama/akita';

export interface UserState {
  username: string;
  userId: string;
  firstname: string;
  lastname: string;
  token?: string;
  tokenName?: string;
  tokenExpiry?: number;
  generalToken?: string;
  generalTokenName?: string;
  generalTokenExpiry?: number;
  type: string;
  authType?: string;
  prosimoAppStatus: string;
  team: string;
  company: string;
  status: string;
  factors: Array<any>;
  companyName?: string;
  accountExpiry: number;
  iconPath?: string;
  loginQueryParam?: string;
  loginURL?: string;
  accType?: string;
  licenseStatus?: string;
  subscriptionType?: Array<string>;
  engLabsEnabled?: boolean;
}

export function createInitialState(): UserState {
  return {
    username: '',
    userId: '',
    firstname: '',
    lastname: '',
    type: '',
    authType: '',
    prosimoAppStatus: '',
    team: '',
    company: '',
    status: '',
    factors: null,
    companyName: '',
    accountExpiry: null,
    iconPath: '',
    loginQueryParam: null,
    loginURL: '',
    accType: '',
    licenseStatus: null,
    subscriptionType: null,
    engLabsEnabled: false,
  };
}

@StoreConfig({ name: 'user', resettable: true })
export class UserStore extends Store<UserState> {
  constructor() {
    super(createInitialState());
  }
}

export const userStore = new UserStore();
