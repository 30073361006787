<ng-template #formFields>
  <div class="data-fetch-form__container__title px-4 pt-4 pb-2">
    {{ formTitle }}
  </div>
  <div *ngIf="isRegionDataLoading; then noData; else dataAvailable"></div>
  <ng-template #noData>
    <div
      [ngClass]="{
        'lightbox_loader ml-4': !showInsideDrawer,
        'px-4 pt-4 pb-2': showInsideDrawer
      }"
    >
      <div>Loading...</div>
    </div>
  </ng-template>
  <ng-template #dataAvailable>
    <div class="data-fetch-form__container __content px-4">
      <div class="form__wrapper m-0 h-100" id="data-fetch-form">
        <form [formGroup]="manualDataFetchForm">
          <!-- SELECT CSP -->
          <div class="form__wrapper">
            <div class="d-flex align-items-baseline">
              <mat-label class="d-flex form__label pb-2 mt-4"
                >Select Cloud</mat-label
              >
              <div class="ml-2">
                <pcomp-mat-error-info-tooltip
                  [errorMessageConfig]="{
                    isShowTooltip: false,
                    errorCondition: csp?.invalid && csp.touched,
                    errorMessage:
                      errorMessagesFormat?.ERROR_MESSAGES?.CSP_REQUIRED
                  }"
                  [uiThemeMode]="uiThemeMode"
                ></pcomp-mat-error-info-tooltip>
              </div>
            </div>
            <div
              class="d-flex select__form__field"
              [ngClass]="{
                'invalid-range': csp?.invalid && csp.touched
              }"
            >
              <div>
                <pcomp-mat-select-search
                  [parentForm]="manualDataFetchForm"
                  [config]="selectSearchCSPConfig"
                  [formControlNameInput]="csp"
                  (emitSelections)="onCSPChange($event?.value)"
                  controlName="csp"
                  [customizedSelectTriggerTextConfig]="
                    customizedSelectTriggerCSPTextConfig
                  "
                >
                </pcomp-mat-select-search>
              </div>
            </div>
          </div>

          <!-- Select Account -->
          <div class="form__wrapper" *ngIf="csp?.value">
            <div class="d-flex">
              <mat-label class="d-flex form__label pb-2"
                >Select Cloud Account(s)</mat-label
              >
              <div class="ml-2">
                <pcomp-mat-error-info-tooltip
                  [errorMessageConfig]="{
                    isShowTooltip: false,
                    errorCondition: accounts?.invalid && accounts?.touched,
                    errorMessage:
                      errorMessagesFormat?.ERROR_MESSAGES
                        ?.CLOUD_ACCOUNT_REQUIRED
                  }"
                  [uiThemeMode]="uiThemeMode"
                ></pcomp-mat-error-info-tooltip>
              </div>
            </div>

            <div
              class="d-flex select__form__field"
              [ngClass]="{
                'invalid-range': accounts?.invalid && accounts?.touched
              }"
            >
              <div>
                <pcomp-mat-select-search
                  [parentForm]="manualDataFetchForm"
                  [config]="selectSearchAccountConfig"
                  [formControlNameInput]="accounts"
                  (emitSelections)="onAccountChange($event?.value)"
                  controlName="accounts"
                  [customizedSelectTriggerTextConfig]="
                    customizedSelectTriggerAccountTextConfig
                  "
                >
                </pcomp-mat-select-search>
              </div>
            </div>
          </div>

          <!-- Select region -->
          <div class="form__wrapper" *ngIf="accounts?.value">
            <div class="d-flex">
              <mat-label class="d-flex form__label pb-2"
                >Select Available Region(s)</mat-label
              >
              <div class="ml-2">
                <pcomp-mat-error-info-tooltip
                  [errorMessageConfig]="{
                    isShowTooltip: false,
                    errorCondition: regions?.invalid && regions?.touched,
                    errorMessage:
                      errorMessagesFormat?.ERROR_MESSAGES?.REGION_ERROR_MESSAGE
                  }"
                  [uiThemeMode]="uiThemeMode"
                ></pcomp-mat-error-info-tooltip>
              </div>
            </div>

            <div
              class="d-flex select__form__field"
              [ngClass]="{
                'invalid-range': regions?.invalid && regions?.touched
              }"
            >
              <div>
                <pcomp-mat-select-search
                  [parentForm]="manualDataFetchForm"
                  [config]="selectSearchRegionConfig"
                  [formControlNameInput]="regions"
                  (emitSelections)="onRegionChange($event?.value)"
                  controlName="regions"
                  [customizedSelectTriggerTextConfig]="
                    customizedSelectTriggerRegionTextConfig
                  "
                >
                </pcomp-mat-select-search>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
</ng-template>

<ng-container *ngIf="showInsideDrawer">
  <div
    class="data-fetch-form__container bg-light-shade h-100 d-flex flex-column m-n4 mr-0"
  >
    <ng-container [ngTemplateOutlet]="formFields"></ng-container>
  </div>
</ng-container>
<ng-container *ngIf="!showInsideDrawer">
  <div class="asset-refresh-popup position-absolute">
    <app-lightbox-form-template [data]="lbData">
      <ng-container [ngTemplateOutlet]="formFields"></ng-container>
      <div
        *ngIf="!isRegionDataLoading"
        class="d-flex align-items-center justify-content-center mb-4"
      >
        <pcomp-create-button
          [createBtn]="fetchButton"
          [isCreateBtnDisabled]="isFetchBtnDisabled"
          (createBtnClick)="onFetch()"
        ></pcomp-create-button>
        <div class="cancel_btn">
          <button
            class="button py-2 px-4 clr-grey3"
            (click)="onCancelBtnClick()"
          >
            Cancel
          </button>
        </div>
      </div>
    </app-lightbox-form-template>
  </div>
</ng-container>
