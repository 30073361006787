import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfettiService {
  private confettiState$ = new Subject<boolean>();

  constructor() {}

  setConfettiState(confettiState: boolean) {
    this.confettiState$.next(confettiState);
  }

  getConfettiState(): Observable<boolean> {
    return this.confettiState$;
  }
}
