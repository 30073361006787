import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keyToDisplayName',
})
export class KeyToDisplayNamePipe implements PipeTransform {
  transform(key: string, keyToDisplayNameList: any[] | any): string {
    if (!key) {
      return 'Empty Key';
    }

    const allKeys = Object.keys(keyToDisplayNameList);
    if (allKeys.length && allKeys.includes(key)) {
      return keyToDisplayNameList[key];
    }
    return key;
  }
}
