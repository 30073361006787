import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { IMAGE_PATHS } from '@app/common/util/constants';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormComponent implements OnInit {
  @Input() title = '';
  @Input() logo: string = IMAGE_PATHS.LOGO.GREY_BG;
  @Input() style: any = { width: '80px', height: '80px' };
  @Input() showLogo = true;

  constructor() {}

  ngOnInit() {}
}
