<ng-container *ngIf="progressBarType === progressBarTypes.CIRCULAR">
    <div class="circular-progress position-relative d-flex align-items-center justify-content-center"
    [style.width.px]="circleDiameter" [style.height.px]="circleDiameter"
    [style.background]="progress">
    <div class="inner-circle position-absolute d-flex align-items-center justify-content-center"
        [style.width.px]="circleDiameter - barSize" [style.height.px]="circleDiameter - barSize">
        <span *ngIf="showValue" [style.color]="data?.statusColor || defaultValues?.statusColor">{{percentage || 0}}%</span>
    </div>
</div>
</ng-container>

<ng-container *ngIf="progressBarType === progressBarTypes.HORIZONTAL">
    <div class="d-flex align-items-center">
        <div *ngIf="showValue" class="col-0 pr-2 onboard-status-percent" [style.color]="data?.statusColor || defaultValues?.statusColor">
            {{ percentage }}%
        </div>
        <div class="onboard-status-percent">
            <mat-progress-bar class="onboard-status-bar" [appProgressBarColor]="data?.statusColor || defaultValues?.statusColor" [value]="percentage || 0" mode="determinate">
            </mat-progress-bar>
        </div>
    </div>
</ng-container>