<ng-template #filterText let-data="data">
  <ng-container>
    <div class="d-flex">
      <div
        *ngIf="
          (data?.selectedList?.length === 1 &&
            data?.selectedList[0] === COPY_ALL) ||
          (data?.selectedList?.length === data?.total &&
            data?.selectedList?.length > 0 &&
            data?.selectedList[0] !== COPY_ALL)
        "
      >
        <div class="all-selected__label">
          {{ data?.allSelectedText }}
        </div>
      </div>

      <div
        *ngIf="
          data?.selectedList?.length !== data?.total &&
          data?.selectedList?.length > 0 &&
          !(
            data?.selectedList?.length === 1 &&
            data?.selectedList[0] === COPY_ALL
          )
        "
      >
        <div class="all-selected__label d-flex">
          <div>
            {{ data?.selectedList?.length }}
          </div>
          <div *ngIf="data?.selectedList?.length > 1" class="ml-1">
            {{ data?.partialSelectedTextPlural }}
          </div>
          <div *ngIf="data?.selectedList?.length === 1" class="ml-1">
            {{ data?.partialSelectedTextSingular }}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<!-- Template to show for filters & filter groups -->
<ng-template #filterContentDisplay let-data="data">
  <!--  Filter -->
  <ng-template
    [ngTemplateOutlet]="filterText"
    [ngTemplateOutletContext]="{ data: data }"
  ></ng-template>
  <!-- Filter Group -->
  <ng-template
    [ngTemplateOutlet]="filterText"
    [ngTemplateOutletContext]="{
      data: {
        selectedList: data?.group?.selectedGroupNames,
        total: data?.group?.availableGroupNamesCount,
        partialSelectedTextSingular: data?.group?.partialSelectedTextSingular,
        partialSelectedTextPlural: data?.group?.partialSelectedTextPlural
      }
    }"
  ></ng-template>
</ng-template>

<!-- Source filter -->
<div
  class="px-3 pt-3 mb-4 rounded filter__card ig__source-filter print-hidden bg-light-shade bdr-grey3"
>
  <div
    *ngIf="
      viewType === VIEW_TYPES.PLAYGROUND;
      then playgroundFilterBlock;
      else nonPlaygroundFilterBlock
    "
  ></div>
  <ng-template #playgroundFilterBlock>
    <div class="filter__avatar">
      <div>
        <img [attr.src]="SOURCE_IMAGE" />
      </div>
    </div>
    <ng-container *ngIf="sourceNetworksFilterData?.isEditable && !customFilter">
      <span
        class="icon__edit print-hidden"
        (click)="setActiveFilter(sourceNetworksFilterData?.type)"
      >
        <mat-icon class="material-icons-outlined" color="primary">
          edit
        </mat-icon>
      </span>
    </ng-container>
    <div class="section-label mb-2">Restrictions</div>
    <div>
      <div class="filters__selection-detail mt-1">
        <div
          *ngIf="
            customFilter;
            then recommendationsFilterBlock;
            else playgroundStandardFilters
          "
        ></div>
        <ng-template #recommendationsFilterBlock>
          <app-playground-filter
            (filterChange)="customFilterChange($event)"
          ></app-playground-filter>
        </ng-template>
        <ng-template #playgroundStandardFilters>
          <ng-container
            *ngIf="
              isPlaygroundFilterAvailable;
              then PlaygroundSelectedFilterDisplayBlock;
              else PlaygroundNoFilterSelectionBlock
            "
          ></ng-container>
        </ng-template>
        <ng-template #PlaygroundSelectedFilterDisplayBlock>
          <ng-container
            [ngTemplateOutlet]="filterContentDisplay"
            [ngTemplateOutletContext]="{
              data: {
                selectedList: sourceNetworksFilterData?.selectedFilterNames,
                total: sourceNetworksFilterData?.availableFiltersCount,
                allSelectedText: sourceNetworksFilterData?.allSelectedText,
                partialSelectedTextSingular:
                  sourceNetworksFilterData?.partialSelectedTextSingular,
                partialSelectedTextPlural:
                  sourceNetworksFilterData?.partialSelectedTextPlural,
                type: sourceNetworksFilterData?.type,
                group: sourceNetworksFilterData?.group,
                isEditable: sourceNetworksFilterData?.isEditable
              }
            }"
          >
          </ng-container>
          <ng-container
            [ngTemplateOutlet]="filterContentDisplay"
            [ngTemplateOutletContext]="{
              data: {
                iconURLPath: sourcePrivateLinksFilterData?.iconURLPath,
                selectedList: sourcePrivateLinksFilterData?.selectedFilterNames,
                total: sourcePrivateLinksFilterData?.availableFiltersCount,
                allSelectedText: sourcePrivateLinksFilterData?.allSelectedText,
                partialSelectedTextSingular:
                  sourcePrivateLinksFilterData?.partialSelectedTextSingular,
                partialSelectedTextPlural:
                  sourcePrivateLinksFilterData?.partialSelectedTextPlural,
                type: sourcePrivateLinksFilterData?.type,
                group: sourcePrivateLinksFilterData?.group,
                isEditable: sourcePrivateLinksFilterData?.isEditable
              }
            }"
          >
          </ng-container>
          <ng-container
            [ngTemplateOutlet]="filterContentDisplay"
            [ngTemplateOutletContext]="{
              data: {
                iconURLPath: sourceRegionsFilterData?.iconURLPath,
                selectedList: sourceRegionsFilterData?.selectedFilterNames,
                total: sourceRegionsFilterData?.availableFiltersCount,
                allSelectedText: sourceRegionsFilterData?.allSelectedText,
                partialSelectedTextSingular:
                  sourceRegionsFilterData?.partialSelectedTextSingular,
                partialSelectedTextPlural:
                  sourceRegionsFilterData?.partialSelectedTextPlural,
                type: sourceRegionsFilterData?.type,
                group: sourceRegionsFilterData?.group,
                isEditable: sourceRegionsFilterData?.isEditable
              }
            }"
          >
          </ng-container>
          <ng-container
            [ngTemplateOutlet]="filterContentDisplay"
            [ngTemplateOutletContext]="{
              data: {
                iconURLPath: appFilterData?.iconURLPath,
                selectedList: appFilterData?.selectedFilterNames,
                total: appFilterData?.availableFiltersCount,
                allSelectedText: appFilterData?.allSelectedText,
                partialSelectedTextSingular:
                  appFilterData?.partialSelectedTextSingular,
                partialSelectedTextPlural:
                  appFilterData?.partialSelectedTextPlural,
                type: appFilterData?.type,
                group: appFilterData?.group,
                isEditable: appFilterData?.isEditable
              }
            }"
          >
          </ng-container>
        </ng-template>
        <ng-template #PlaygroundNoFilterSelectionBlock>
          <div class="all-selected__label">{{ NO_FILTERS_MSG }}</div>
        </ng-template>
      </div>
    </div>
  </ng-template>
  <ng-template #nonPlaygroundFilterBlock>
    <div
      *ngIf="
        viewType === SUBSCRIPTION_TYPES_COPY.MCN;
        then mcnSourceFilterBlock;
        else ztnaSourceFilterBlock
      "
    ></div>
    <ng-template #mcnSourceFilterBlock>
      <div class="filter__avatar">
        <div>
          <img [attr.src]="SOURCE_IMAGE" class="bg-grey13" />
        </div>
      </div>
      <ng-container *ngIf="sourceNetworksFilterData?.isEditable">
        <span
          class="icon__edit print-hidden"
          (click)="setActiveFilter(sourceNetworksFilterData?.type)"
        >
          <mat-icon class="material-icons-outlined" color="primary">
            edit
          </mat-icon>
        </span>
      </ng-container>
      <div class="section-label">Source</div>
      <div>
        <div class="filters__selection-detail mt-1">
          <div
            *ngIf="
              isMCNSourceFilterAvailable;
              then MCNSourceFiltersAvailable;
              else noMCNSourceFiltersAvailable
            "
          ></div>
          <ng-template #MCNSourceFiltersAvailable>
            <ng-container
              [ngTemplateOutlet]="filterContentDisplay"
              [ngTemplateOutletContext]="{
                data: {
                  selectedList: sourceNetworksFilterData?.selectedFilterNames,
                  total: sourceNetworksFilterData?.availableFiltersCount,
                  allSelectedText: sourceNetworksFilterData?.allSelectedText,
                  partialSelectedTextSingular:
                    sourceNetworksFilterData?.partialSelectedTextSingular,
                  partialSelectedTextPlural:
                    sourceNetworksFilterData?.partialSelectedTextPlural,
                  type: sourceNetworksFilterData?.type,
                  group: sourceNetworksFilterData?.group,
                  isEditable: sourceNetworksFilterData?.isEditable
                }
              }"
            >
            </ng-container>
            <ng-container
              [ngTemplateOutlet]="filterContentDisplay"
              [ngTemplateOutletContext]="{
                data: {
                  selectedList:
                    sourcePrivateLinksFilterData?.selectedFilterNames,
                  total: sourcePrivateLinksFilterData?.availableFiltersCount,
                  allSelectedText:
                    sourcePrivateLinksFilterData?.allSelectedText,
                  partialSelectedTextSingular:
                    sourcePrivateLinksFilterData?.partialSelectedTextSingular,
                  partialSelectedTextPlural:
                    sourcePrivateLinksFilterData?.partialSelectedTextPlural,
                  type: sourcePrivateLinksFilterData?.type,
                  isEditable: sourcePrivateLinksFilterData?.isEditable
                }
              }"
            >
            </ng-container>
            <ng-container
              [ngTemplateOutlet]="filterContentDisplay"
              [ngTemplateOutletContext]="{
                data: {
                  selectedList: sourceRegionsFilterData?.selectedFilterNames,
                  total: sourceRegionsFilterData?.availableFiltersCount,
                  allSelectedText: sourceRegionsFilterData?.allSelectedText,
                  partialSelectedTextSingular:
                    sourceRegionsFilterData?.partialSelectedTextSingular,
                  partialSelectedTextPlural:
                    sourceRegionsFilterData?.partialSelectedTextPlural,
                  type: sourceRegionsFilterData?.type,
                  isEditable: sourceRegionsFilterData?.isEditable
                }
              }"
            >
            </ng-container>
          </ng-template>
          <ng-template #noMCNSourceFiltersAvailable>
            <div class="all-selected__label">{{ NO_SOURCE_FILTER_MSG }}</div>
          </ng-template>
        </div>
      </div>
    </ng-template>
    <ng-template #ztnaSourceFilterBlock>
      <div class="filter__avatar">
        <div>
          <img [attr.src]="SOURCE_IMAGE" class="bg-grey13" />
        </div>
      </div>
      <ng-container *ngIf="userFilterData?.isEditable">
        <span
          class="icon__edit print-hidden"
          (click)="setActiveFilter(userFilterData?.type)"
        >
          <mat-icon class="material-icons-outlined" color="primary">
            edit
          </mat-icon>
        </span>
      </ng-container>
      <div class="section-label">Source</div>

      <div class="filters__selection-detail mt-1">
        <div
          *ngIf="
            isZTNASourceFilterAvailable;
            then ZTNASourceFiltersAvailable;
            else noZTNASourceFiltersAvailable
          "
        ></div>
        <ng-template #ZTNASourceFiltersAvailable>
          <ng-container
            [ngTemplateOutlet]="filterContentDisplay"
            [ngTemplateOutletContext]="{
              data: {
                selectedList: userFilterData?.selectedFilterNames,
                total: userFilterData?.availableFiltersCount,
                allSelectedText: userFilterData?.allSelectedText,
                partialSelectedTextSingular:
                  userFilterData?.partialSelectedTextSingular,
                partialSelectedTextPlural:
                  userFilterData?.partialSelectedTextPlural,
                type: userFilterData?.type,
                group: userFilterData?.group,
                isEditable: userFilterData?.isEditable
              }
            }"
          >
          </ng-container>
        </ng-template>
        <ng-template #noZTNASourceFiltersAvailable>
          <div class="all-selected__label">{{ NO_SOURCE_FILTER_MSG }}</div>
        </ng-template>
      </div>
    </ng-template>
  </ng-template>
</div>

<!-- target filter -->
<div
  class="px-3 pt-3 mb-4 rounded filter__card ig__target-filter print-hidden bg-light-shade bdr-grey3"
  *ngIf="viewType !== VIEW_TYPES.PLAYGROUND"
>
  <div
    *ngIf="
      viewType === SUBSCRIPTION_TYPES_COPY.MCN;
      then mcnTargetFilterBlock;
      else ztnaTargetFilterBlock
    "
  ></div>
  <ng-template #mcnTargetFilterBlock>
    <div class="filter__avatar">
      <div>
        <img [attr.src]="TARGET_IMAGE" class="bg-grey13" />
      </div>
    </div>
    <ng-container *ngIf="targetNetworksFilterData?.isEditable">
      <span
        class="icon__edit print-hidden"
        (click)="setActiveFilter(targetNetworksFilterData?.type)"
      >
        <mat-icon class="material-icons-outlined" color="primary">
          edit
        </mat-icon>
      </span>
    </ng-container>
    <div class="section-label">Target</div>

    <div class="filters__selection-detail mt-1">
      <div
        *ngIf="
          isMCNTargetFilterAvailable;
          then MCNTargetFiltersAvailable;
          else noMCNTargetFiltersAvailable
        "
      ></div>
      <ng-template #MCNTargetFiltersAvailable>
        <ng-container
          [ngTemplateOutlet]="filterContentDisplay"
          [ngTemplateOutletContext]="{
            data: {
              selectedList: targetNetworksFilterData?.selectedFilterNames,
              total: targetNetworksFilterData?.availableFiltersCount,
              allSelectedText: targetNetworksFilterData?.allSelectedText,
              partialSelectedTextSingular:
                targetNetworksFilterData?.partialSelectedTextSingular,
              partialSelectedTextPlural:
                targetNetworksFilterData?.partialSelectedTextPlural,
              type: targetNetworksFilterData?.type,
              group: targetNetworksFilterData?.group,
              isEditable: targetNetworksFilterData?.isEditable
            }
          }"
        >
        </ng-container>
        <ng-container
          [ngTemplateOutlet]="filterContentDisplay"
          [ngTemplateOutletContext]="{
            data: {
              selectedList: appFilterData?.selectedFilterNames,
              total: appFilterData?.availableFiltersCount,
              allSelectedText: appFilterData?.allSelectedText,
              partialSelectedTextSingular:
                appFilterData?.partialSelectedTextSingular,
              partialSelectedTextPlural:
                appFilterData?.partialSelectedTextPlural,
              type: appFilterData?.type,
              group: appFilterData?.group,
              isEditable: appFilterData?.isEditable
            }
          }"
        >
        </ng-container>
        <ng-container
          [ngTemplateOutlet]="filterContentDisplay"
          [ngTemplateOutletContext]="{
            data: {
              selectedList: targetRegionsFilterData?.selectedFilterNames,
              total: targetRegionsFilterData?.availableFiltersCount,
              allSelectedText: targetRegionsFilterData?.allSelectedText,
              partialSelectedTextSingular:
                targetRegionsFilterData?.partialSelectedTextSingular,
              partialSelectedTextPlural:
                targetRegionsFilterData?.partialSelectedTextPlural,
              type: targetRegionsFilterData?.type,
              group: targetRegionsFilterData?.group,
              isEditable: targetRegionsFilterData?.isEditable
            }
          }"
        >
        </ng-container>
      </ng-template>
      <ng-template #noMCNTargetFiltersAvailable>
        <div class="all-selected__label">{{ NO_TARGET_FILTER_MSG }}</div>
      </ng-template>
    </div>
  </ng-template>
  <ng-template #ztnaTargetFilterBlock>
    <div class="filter__avatar">
      <div>
        <img [attr.src]="TARGET_IMAGE" class="bg-grey13" />
      </div>
    </div>
    <ng-container *ngIf="appFilterData?.isEditable">
      <span
        class="icon__edit print-hidden"
        (click)="setActiveFilter(appFilterData?.type)"
      >
        <mat-icon class="material-icons-outlined" color="primary">
          edit
        </mat-icon>
      </span>
    </ng-container>
    <div class="section-label">Target</div>
    <div class="filters__selection-detail mt-1">
      <div
        *ngIf="
          isZTNATargetFilterAvailable;
          then ZTNATargetFiltersAvailable;
          else noZTNATargetFiltersAvailable
        "
      ></div>
      <ng-template #ZTNATargetFiltersAvailable>
        <ng-container
          [ngTemplateOutlet]="filterContentDisplay"
          [ngTemplateOutletContext]="{
            data: {
              selectedList: appFilterData?.selectedFilterNames,
              total: appFilterData?.availableFiltersCount,
              allSelectedText: appFilterData?.allSelectedText,
              partialSelectedTextSingular:
                appFilterData?.partialSelectedTextSingular,
              partialSelectedTextPlural:
                appFilterData?.partialSelectedTextPlural,
              type: appFilterData?.type,
              group: appFilterData?.group,
              isEditable: appFilterData?.isEditable
            }
          }"
        >
        </ng-container>

        <ng-container
          [ngTemplateOutlet]="filterContentDisplay"
          [ngTemplateOutletContext]="{
            data: {
              selectedList: targetNetworksFilterData?.selectedFilterNames,
              total: targetNetworksFilterData?.availableFiltersCount,
              allSelectedText: targetNetworksFilterData?.allSelectedText,
              partialSelectedTextSingular:
                targetNetworksFilterData?.partialSelectedTextSingular,
              partialSelectedTextPlural:
                targetNetworksFilterData?.partialSelectedTextPlural,
              type: targetNetworksFilterData?.type,
              group: targetNetworksFilterData?.group,
              isEditable: targetNetworksFilterData?.isEditable
            }
          }"
        >
        </ng-container>
      </ng-template>
      <ng-template #noZTNATargetFiltersAvailable>
        <div class="all-selected__label">{{ NO_TARGET_FILTER_MSG }}</div>
      </ng-template>
    </div>
  </ng-template>
</div>

<!-- date filter -->
<div
  class="px-3 pt-3 pb-2 mb-4 rounded filter__card ig__date-filter print-hidden bg-light-shade bdr-grey3"
>
  <div class="filter__avatar print-hidden">
    <div>
      <img [attr.src]="CALENDAR_IMAGE" class="bg-grey13" />
    </div>
  </div>
  <span
    *ngIf="dateFilterData?.isEditable && isDateFilterEditable"
    class="icon__edit print-hidden"
    (click)="setActiveFilter('calendar')"
  >
    <mat-icon class="material-icons-outlined" color="primary"> edit </mat-icon>
  </span>
  <div class="section-label">
    Time Range
    <span *ngIf="viewType === VIEW_TYPES.PLAYGROUND" class="interval">
      ({{ DATE_RANGE_LABELS[dateFilterData?.selectedFilterNames?.interval] }})
    </span>
  </div>
  <div class="d-flex">
    <div class="d-flex flex-column align-items-center justify-content-center">
      <div class="circle bg-grey12"></div>
      <div class="line bg-grey12"></div>
      <div class="circle bg-grey12"></div>
    </div>
    <div class="filters__selection-detail ml-3">
      <div>
        <span class="day">
          {{ dateFilterData?.selectedFilterNames?.from | date : "EEE " }}
        </span>
        <span class="date">
          {{ dateFilterData?.selectedFilterNames?.from | date : "MMMM d, y" }}
        </span>
        <span class="time">
          {{ dateFilterData?.selectedFilterNames?.from | date : "h:mm:ss a" }}
        </span>
      </div>
      <div class="mt-2">
        <span class="day">
          {{ dateFilterData?.selectedFilterNames?.to | date : "EEE " }}
        </span>
        <span class="date">
          {{ dateFilterData?.selectedFilterNames?.to | date : "MMMM d, y" }}
        </span>
        <span class="time">
          {{ dateFilterData?.selectedFilterNames?.to | date : "h:mm:ss a" }}
        </span>
      </div>
    </div>
  </div>
</div>

<!-- Print header -->
<ng-container *ngIf="!(isRepeatPrintHeader$ | async)">
  <app-print-filter-header
    class="d-none print-display"
    [viewType]="viewType"
  ></app-print-filter-header>
</ng-container>
