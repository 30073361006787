import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { OrchestratorPayload, OrchestratorStep } from './orchestrator.model';
import { OnboardEntityType } from '../templates/onboard-template/onboard-template.model';

@Injectable({
  providedIn: 'root',
})
export class OrchestratorService {
  private stepComplete$: Subject<OrchestratorStep>;
  private gotoStep: Subject<OrchestratorStep>;
  private orchWorkflow$: Subject<
    OrchestratorPayload<OrchestratorStep, OnboardEntityType>
  >;
  private errorState$: Subject<any>;
  private tabChange$: Subject<OrchestratorStep>;
  constructor() {
    this.stepComplete$ = new Subject();
    this.orchWorkflow$ = new Subject();
    this.gotoStep = new Subject();
    this.errorState$ = new Subject();
    this.tabChange$ = new Subject();
  }

  setStepComplete(step: OrchestratorStep): void {
    this.stepComplete$.next(step);
  }

  getStepComplete(): Observable<OrchestratorStep> {
    return this.stepComplete$.asObservable();
  }

  setGotoStop(step: OrchestratorStep): void {
    this.gotoStep.next(step);
  }

  getGotoStop(): Observable<OrchestratorStep> {
    return this.gotoStep.asObservable();
  }

  setOrchWorkflow(
    workflow: OrchestratorPayload<OrchestratorStep, OnboardEntityType>
  ): void {
    this.orchWorkflow$.next(workflow);
  }

  getOrchWorkflow(): Observable<
    OrchestratorPayload<OrchestratorStep, OnboardEntityType>
  > {
    return this.orchWorkflow$.asObservable();
  }

  setErrorState(error: any): void {
    this.errorState$.next(error);
  }

  getErrorState(): Observable<any> {
    return this.errorState$.asObservable();
  }

  setTabChangeRequest(step: OrchestratorStep) {
    this.tabChange$.next(step);
  }

  getTabChangeRequest(): Observable<OrchestratorStep> {
    return this.tabChange$.asObservable();
  }
}
