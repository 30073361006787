import { Store, StoreConfig } from '@datorama/akita';

export interface AdminState {
  accountStatus: string;
}

export function createInitialState(): AdminState {
  return {
    accountStatus: 'PENDING',
  };
}

@StoreConfig({ name: 'admin', resettable: true })
export class AdminStore extends Store<AdminState> {
  constructor() {
    super(createInitialState());
  }
}

export const adminStore = new AdminStore();
