import { Query } from '@datorama/akita';
import {
  NetworksDashboardSharedState,
  NetworksDashboardSharedStore,
  networksDashboardSharedStore,
} from './network-dashboard.store';
import { Observable } from 'rxjs';

export class NetworksDashboardSharedQuery extends Query<NetworksDashboardSharedState> {
  activeNavigation$ = this.select((state) => state.activeNavigation);
  constructor(protected store: NetworksDashboardSharedStore) {
    super(store);
  }

  get selectedActiveNavigation(): Observable<string> {
    return this.activeNavigation$;
  }
}

export const networksDashboardSharedQuery = new NetworksDashboardSharedQuery(
  networksDashboardSharedStore
);
