import { Query } from '@datorama/akita';
import {
  LogSearchFilterStore,
  logSearchFilterStore,
  LogSearchFilterState,
} from './log-search-filter.store';
import { Observable } from 'rxjs';
import { TimeRange } from '../../shared/calendar.component';

export class LogSearchFilterQuery extends Query<LogSearchFilterState> {
  logSearchTimeRange$ = this.select((state) => state.logSearchTimeRange);

  constructor(protected store: LogSearchFilterStore) {
    super(store);
  }

  get logSearchTimeRange(): Observable<TimeRange> {
    return this.logSearchTimeRange$;
  }
}

export const logSearchFilterQuery = new LogSearchFilterQuery(
  logSearchFilterStore
);
