export interface OnboardedApp {
  id?: string;
  appName: string;
  appType?: string;
  onboardType?: string;
  appAccessType?: string;
}

export interface AppGroup {
  id: string;
  name: string;
}

export interface AppFilters {
  apps?: Array<OnboardedApp>;
  appGroups?: Array<AppGroup>;
  selectedApps?: Array<OnboardedApp>;
  selectedAppGroups?: Array<AppGroup>;
  type?: string;
  viewMode?: string;
}

export const OnboardedAppMasterSelectConfig: OnboardedApp = {
  id: 'all',
  appName: 'all'
}

export const AppGroupMasterSelectConfig: AppGroup = {
  id: 'all',
  name: 'all'
}
