import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { RulePaneTabularViewConfig } from './rule-pane-tabular-view.model';
@Component({
  selector: 'app-rule-pane-tabular-view',
  templateUrl: './rule-pane-tabular-view.component.html',
  styleUrls: ['./rule-pane-tabular-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RulePaneTabularViewComponent implements OnInit {
  private _tabularViewConfig: any;

  get tabularViewConfig() {
    return this._tabularViewConfig;
  }
  @Input() set rulePaneTabularViewConfig(data: RulePaneTabularViewConfig) {
    this._tabularViewConfig = data;
  }
  @Input() tooltipThemeClass: string = '';
  @Output() editRow = new EventEmitter();
  @Output() deleteRow = new EventEmitter();
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  showTooltip(tooltip, matCell) {
    if (matCell?.scrollWidth <= matCell?.clientWidth) {
      tooltip.disabled = true;
    } else {
      tooltip.disabled = false;
    }
    this.cdr?.markForCheck();
  }

  onEdit(row, i) {
    this.editRow.emit({ row, index: i });
  }

  onDelete(row, i) {
    this.deleteRow.emit({ row, index: i });
  }
}
