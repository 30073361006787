<div
  #helpContainer
  id="help-container"
  [ngClass]="{
    'position-absolute h-100 print-hidden': true,
    'open d-flex': isPanelOpen,
    'close d-none': !isPanelOpen
  }"
>
  <div class="flex-grow-0" (appResizable)="updateHelpPanelWidth($event)">
    <div
      [ngClass]="{
        'd-flex flex-column justify-content-center align-items-center h-100': true
      }"
    >
      <div
        [ngClass]="{
          'icon__drag-indicator d-flex flex-column justify-content-center align-items-center position-absolute d-block':
            displayStatus === 'OPEN' || displayStatus === 'FETCHING',
          'd-none': displayStatus === 'CLOSE'
        }"
      >
        <mat-icon>drag_indicator</mat-icon>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column h-100 flex-grow-1">
    <div id="help-container__header">
      <div class="d-flex">
        <div class="help-panel__button-close" (click)="hideHelpSection()">
          <mat-icon title="Close...">close</mat-icon>
        </div>
        <div class="flex-grow-1 text-center">
          <div class="search__container">
            <div class="search__icon--wrapper">
              <mat-icon color="primary">search</mat-icon>
            </div>
            <div class="search__input--wrapper">
              <input [formControl]="searchTerm" type="text" tabindex="-1" />
            </div>
          </div>
        </div>
        <div class="pr-1">
          <ng-container *ngFor="let font of fontSizes">
            <mat-icon
              (click)="updateFontSize(font)"
              [attr.style]="
                'font-size:' + font?.size + ' !important' | safe: 'style'
              "
              [ngClass]="{ 'selected-font': font?.isSelected }"
              >format_size</mat-icon
            >
          </ng-container>
        </div>
      </div>
    </div>
    <div
      id="help-container__title"
      class="flex-grow-0 text-center"
      *ngIf="displayTitle"
    >
      <h2>{{ displayTitle }}</h2>
    </div>
    <div
      #helpContents
      id="help-container__content"
      class="flex-grow-1 overflow-auto"
      [innerHTML]="displayContent | safe: 'html'"
    ></div>
    <ng-container *ngIf="isShowContactSupportBtn && isPanelOpen">
      <div id="help-container__footer" class="flex-grow-0 position-relative">
        <app-feedback-form
          [config]="feedbackFormConfig"
          (formSubmit)="onSubmit($event)"
        ></app-feedback-form>
      </div>
    </ng-container>
  </div>
</div>
