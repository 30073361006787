import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ILightboxBtnConfig, LightBoxStatusMessage } from './lightbox-model';

@Injectable({
  providedIn: 'root',
})
export class LightBoxTemplateService {
  private statusMessage$ = new Subject<LightBoxStatusMessage>();
  private response$ = new Subject<string>();
  private disbleButtons$ = new Subject<ILightboxBtnConfig[]>();
  private disbleCancelBtn$ = new Subject<boolean>();
  private lightBoxHeader$ = new Subject();
  constructor() {}

  setLightBoxStatusMessage(message: LightBoxStatusMessage) {
    this.statusMessage$.next(message);
  }

  getLightBoxStatusMessage(): Observable<LightBoxStatusMessage> {
    return this.statusMessage$;
  }

  setLightBoxResponse(response) {
    this.response$.next(response);
  }

  getLightBoxResponse(): Observable<string> {
    return this.response$;
  }

  setDisabledButtons(buttons: ILightboxBtnConfig[]) {
    this.disbleButtons$.next(buttons);
  }

  getDisabledButtons() {
    return this.disbleButtons$.asObservable();
  }

  setCancelBtnDisabled(disabled: boolean) {
    this.disbleCancelBtn$.next(disabled);
  }
  getCancelBtnDisabled() {
    return this.disbleCancelBtn$.asObservable();
  }
  setLightBoxHeader(lightBoxHeader) {
    this.lightBoxHeader$.next(lightBoxHeader);
  }
  getLightBoxHeader() {
    return this.lightBoxHeader$.asObservable();
  }
}
