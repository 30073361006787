export class AutoComplete {
  label: string;
  allowEntry: boolean;
  querySearch?: boolean; // backend search filter
  clearSearchValue?: boolean;
  selected: Array<any>;
  options: Array<any>;
  targetKey: string;
  uniqueKey: string;
  miscType?: any;
  constructor(
    label = 'Label',
    selected = [],
    options = [],
    allowEntry = true,
    uniqueKey = '',
    targetKey = '',
    querySearch = false,
    clearSearchValue = false,
    miscType = null,
  ) {
    this.label = label;
    this.selected = selected;
    this.options = options;
    this.allowEntry = allowEntry;
    this.targetKey = targetKey; // display Name
    this.uniqueKey = uniqueKey; // unique key
    this.querySearch = querySearch;
    this.clearSearchValue = clearSearchValue;
    this.miscType = miscType;
  }
}
