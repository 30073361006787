import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { PROSIMO_API_TOKEN_LABEL, URL_PATHS } from '@app/common/util/constants';
import { BannerService } from '@app/component-common/banner/banner.service';
import { AccessDeniedBannerConfig } from '@app/component-common/banner/banner.model';
import { AppUtil } from '@app/common/util/app-util';
import { CONSTANTS } from 'environments/environment';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  HTTP_STATUS_CODES = {
    SESSION_TIMEOUT: 401,
    ACCESS_DENIED: 403,
  };

  ERROR_STATUS_CODE = {
    ACCESS_EXPIRED: 'E101019',
  };

  constructor(private bannerService: BannerService, private appUtil: AppUtil) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.appUtil.isDev() && CONSTANTS.DASHBOARD_API_TOKEN) {
      request = request.clone({
        headers: request.headers.set(
          PROSIMO_API_TOKEN_LABEL,
          CONSTANTS.DASHBOARD_API_TOKEN
        ),
      });
    }

    // Ignore content type if there is one already and also for file upload.
    if (
      !request.headers.has('Content-Type') &&
      request.headers.get('prosimo-file-upload') !== 'upload'
    ) {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json'),
      });
    }

    request = request.clone({
      headers: request.headers.set('Accept', 'application/json'),
    });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.handleErrorStatus(error);
        return throwError(error);
      })
    );
  }

  /**
   * Handles 401 and 403
   * If status code is 401 - route the user to login page
   * If status code is 403 - display access denied error message
   * @param error - http error response
   */

  handleErrorStatus(error: HttpErrorResponse) {
    if (!error) {
      return;
    }
    const httpResponseCode = error.status;
    switch (httpResponseCode) {
      case this.HTTP_STATUS_CODES.SESSION_TIMEOUT:
        this.handleSessionTimeoutBasedOnStatusCode(error);
        break;
      case this.HTTP_STATUS_CODES.ACCESS_DENIED:
        this.bannerService.addBannerConfig(AccessDeniedBannerConfig);
        break;
    }
  }

  /**
   * Handle the routing based on the session timeout error status code
   * @param error
   */
  handleSessionTimeoutBasedOnStatusCode(error: any) {
    const currentPath = this.appUtil.getPath();
    if (this.routeToLogin(error) && currentPath !== URL_PATHS.LOGIN) {
      // Set local store state
      this.appUtil.destroyLocalStore();
      this.appUtil.reloadPage(URL_PATHS.LOGIN, true);
    }
  }

  /**
   * Method to check whether to route the user to login page
   * Will be routed to login if
   *   1. error url is not login request
   *   2. ui path does not include encode value in the path
   * @param error
   * @returns
   */
  routeToLogin(error: any): boolean {
    return (
      error.url &&
      error.url.indexOf(URL_PATHS.LOGIN) === -1 &&
      this.appUtil.getPath().indexOf(URL_PATHS.LOGIN) === -1
    );
  }
}
