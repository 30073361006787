<!-- Start : Dialog box to be displayed in the Create New/Edit Access Policy form. -->
<div [ngClass]="{
    'mat-dialog-container mat-panel-container overflow-auto d-block p-4 w-100 h-100':
      isOpenFromPanel
  }">
  <div [ngClass]="[uiThemeMode | uiTheme]">
    <div class="bg-light-shade">
      <div mat-dialog-content class="dialog__content text-center">
        <!-- Start : Display title along with the icon in the dialog box. -->
        <div class="d-flex flex-row justify-content-center align-items-center mt-2">
          <div>
            <img class="status-failed-clr warning-icon" [src]="warningIconURL" />
          </div>
          <div class="alert-text ml-2">{{ title }}</div>
        </div>
        <!-- End : Display title along with the icon in the dialog box. -->
        <!-- Start : Display warning message in the dialog box. -->
        <div class="clr-grey7 mx-3 mt-3" [innerHTML]="message"></div>
        <!-- End : Display warning message in the dialog box. -->
      </div>
      <div mat-dialog-actions class="justify-content-center pb-3" [ngClass]="{
          'mat-dialog-action d-flex align-items-center flex-wrap':
            isOpenFromPanel
        }">
        <!-- Start : Button displayed in the dialog box. -->
        <ng-template [ngIf]="isOpenFromPanel">
          <button *ngIf="isShowCancelButton" mat-button class="dialog-button clr-grey7 ml-2 p-2"
            (click)="onCancelEvent()">
            {{ cancelButtonTitle }}
          </button>
        </ng-template>
        <ng-template [ngIf]="!isOpenFromPanel">
          <button *ngIf="isShowCancelButton" mat-button mat-dialog-close class="dialog-button clr-grey7 ml-2 p-2"
            (click)="onCancelEvent()">
            {{ cancelButtonTitle }}
          </button>
        </ng-template>
        <ng-template [ngIf]="isOpenFromPanel">
          <button mat-button class="dialog-button blue-link p-2" (click)="onButtonClick()">
            {{ buttonTitle }}
          </button>
        </ng-template>
        <ng-template [ngIf]="!isOpenFromPanel">
          <button mat-button mat-dialog-close class="dialog-button blue-link p-2" (click)="onButtonClick()">
            {{ buttonTitle }}
          </button>
        </ng-template>

        <!-- End : Button displayed in the dialog box. -->
      </div>
    </div>
  </div>
</div>
<!-- End : Dialog box to be displayed in the Create New/Edit Access Policy form. -->