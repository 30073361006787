import { StatusService } from './status.service';
import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Status } from './status.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusComponent implements OnInit {
  @Input() statusConfig$: Observable<Status>;

  constructor(private statusService: StatusService) {}

  ngOnInit() {
    this.statusConfig$ = this.statusService.getStatus();
  }
}
