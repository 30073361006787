import { WellKnownCommunities } from '../models/bgp';

export const getWellKnownCommunityValue = (
  community: string,
  keyOrValue: boolean
): string => {
  return Object.entries(WellKnownCommunities).find(
    (comm) => comm[0] === community || comm[1] === community
  )?.[keyOrValue ? 0 : 1];
};
