import { advancedSettingService } from '@app/dashboard/state/advanced-setting/advanced-setting.service';
import { advancedSettingQuery } from '@app/dashboard/state/advanced-setting/advanced-setting.query';
import { AdvancedSettingState } from '@app/dashboard/state/advanced-setting/advanced-setting.store';
import { securityService } from '@app/dashboard/state/security/security.service';
import { securityQuery } from '@app/dashboard/state/security/security.query';
import { SecurityState } from '@app/dashboard/state/security/security.store';
import { LoginUtil } from '@app/login/login-util';
import { mergeMap, take, takeUntil, tap, debounceTime } from 'rxjs/operators';
import { DashboardMenuConfig } from '@app/common/components/dashboard-menu/dashboard-menu.config';
import { DecimalPipe } from '@angular/common';
import { NavigationBarModel } from '@app/component-common/navigation-bar/navigation-bar.config';
import { BannerService } from './../../component-common/banner/banner.service';
import {
  DIALOG_ACTION_TYPES,
  DATA_MULTIPLES,
  ALERT_TYPES,
  URL_PATHS,
  HEADER_CONFIGS,
  PageTypes,
  APP_SETUP_STATUS,
  DIALOG_BUTTON_TYPES,
  NUMERIC_ROUND_OFF,
  ACCESS_TYPES,
  IMAGE_PATHS,
  UI_PATHS_TO_SKIP,
  ONBOARD_TYPE,
  SIMPLE_PAGES,
  USER_TYPES,
  TEAM_ICONS,
  PROTOCOLS,
  CLOUD_TRANSIT_MENU_OPTIONS,
  SUBSCRIPTION_TYPES,
  PDF_PRINT,
  HTTP_RESPONSE_STATUS_CODES,
  PROSIMO_API_TOKEN_LABEL,
  FILTER_TYPES,
  MAT_OPTION_CLASSNAME,
  DIALOG_TOKEN,
  VIEW_TYPES,
  SelectionObjectWithArray,
} from '@app/common/util/constants';
import { NotificationService } from './../../component-common/notification/notification.service';
import { diagnoseSharedFilterQuery } from './../../dashboard/state/diagnose/diagnose-filter.query';
import { ComponentRef, ElementRef, Injectable, Injector } from '@angular/core';
import { FilterService } from '@app/common/components/filter/filter.service';
import { AlertModel } from '@app/component-common/alert/alert.model';
import { AlertService } from '@app/component-common/alert/alert.service';
import {
  ChartService,
  SelectedRange,
} from '@app/component-common/charts/common/services/chart.service';
import { LightboxService } from '@app/component-common/lightbox/lightbox.service';
import { LoaderService } from '@app/component-common/loader/loader.service';
import { PolicyService as CustomPolicyService } from '@app/v2/configure/policy/policy.service';
import { TimeRange } from '@app/dashboard/shared/calendar.component';
import { logMetaDataService } from '@app/dashboard/shared/log/state';
import {
  ztnaInsightsStoreFilterService,
  ztnaInsightsSharedFilterQuery,
  ZTNAInsightsSharedFilterState,
  ztnaInsightsSharedFilterStore,
  TimeRangeType,
} from '@app/dashboard/state/ztna-insights-filters';
import {
  MCNInsightsSharedFilterState,
  mcnInsightsSharedFilterStore,
  mcnInsightsSharedFilterQuery,
  mcnInsightsStoreFilterService,
} from '@app/dashboard/state/mcn-insights-filters';
import {
  PlaygroundFilterState,
  playgroundFilterStore,
  playgroundFilterQuery,
  playgroundFilterService,
} from '@app/dashboard/state/playground-filters';
import {
  agentQuery,
  agentService,
  AgentState,
} from '@app/dashboard/state/agent';
import { LoginService } from '@app/login/login.service';
import { BehaviorSubject, Observable, Subject, of, timer } from 'rxjs';
import { logMetaDataQuery } from './../../dashboard/shared/log/state/log-meta-data.query';
import {
  APP_TYPES,
  REGEX,
  INSIGHTS_MENU_OPTIONS,
  MANAGEMENT,
  MESSAGES,
  DIAGNOSE_MENU_OPTIONS,
  DOWNLOAD_TYPES,
} from './constants';
import {
  HeaderDescription,
  L2HeaderModel,
} from '@app/component-common/l2header/l2header.model';
import {
  ManagementState,
  managementStore,
} from '@app/dashboard/state/management/management.store';
import { managementQuery } from '@app/dashboard/state/management/management.query';
import { managementService } from '@app/dashboard/state/management/management.service';
import { diagnoseStoreFilterService } from '@app/dashboard/state/diagnose';
import { adminService } from '@app/admin/state/admin.service';
import { adminQuery } from '@app/admin/state/admin.query';
import {
  logSearchFilterQuery,
  logSearchFilterStoreService,
} from '@app/dashboard/state/log';
import { ObjectUtil } from '@prosimoio/services';
import { NavigationBarService } from '@app/component-common/navigation-bar/navigation-bar.service';
import { ZTNARecentSearch } from '@app/dashboard/state/ztna-recent-search/ztna-recent-search.model';
import { ztnaRecentSearchQuery } from '@app/dashboard/state/ztna-recent-search/ztna-recent-search.query';
import {
  mcnRecentSearchQuery,
  mcnRecentSearchService,
  MCNRecentSearchState,
} from '@app/dashboard/state/mcn-recent-search';
import {
  playgroundRecentSearchQuery,
  playgroundRecentSearchService,
  PlaygroundRecentSearchState,
} from '@app/dashboard/state/playground-recent-search';
import { ztnaRecentSearchService } from '@app/dashboard/state/ztna-recent-search/ztna-recent-search.service';
import { Router } from '@angular/router';
import { PDashLocalStoreUtil } from '@app/state/web-pdash.util';
import {
  TeamUser,
  UserGroup,
} from '@app/dashboard/shared/filter-list/client-list/client-list.model';
import {
  OnboardedApp,
  AppGroup,
} from '@app/dashboard/shared/filter-list/app-list/apps-list.model';
import {
  CONSTANTS,
  USER_BEHAVIOR_INSIGHTS_APP_ID,
  environment,
} from 'environments/environment';
import { TabService } from '@app/component-common/tab/tab.service';
import { TabConfig } from '@app/component-common/tab/tab.component';
import { DateUtil } from '@prosimoio/services';
import {
  APPS_INSIGHTS_MENU_CONFIGS,
  NETWORKS_INSIGHTS_MENU_CONFIGS,
} from '@app/dashboard/insights/insights.model';
import { AGENT_DASHBOARD } from '@app/dashboard/agent/agent.constants';
import {
  UserBehaviorInsightsService,
  UserBehaviorInsightsUserInfo,
  UserBehaviorInsightsCustomEventInfo,
  WindowResizeService,
} from '@prosimoio/services';
import {
  INTERACTIVE_GUIDE_CONFIGS,
  PRODUCT_TOUR_TYPES,
} from './interactive-guide-config.constant';
import {
  ConditionsPanelService,
  DrawerCompConfig,
  DrawerService,
  IBtnConfig,
  IDrawerTemplateConfig,
  InteractiveGuideService,
  MultiValueInputService,
  ValidationState,
  PageFooterService,
  FooterBtnCode,
  IFooterBtn,
  IPageFooterConfig,
  paginationConfig,
  PaginatorEventData,
  BreadcrumbService,
  ViewEditBtnConfig,
  TABLE_CONSTANTS,
  DrawerResponsePayload,
  ConfigTableViewService,
  LoaderService as NewLoaderService,
  DIALOG_DATA_TOKEN,
  DeleteDialogComponent,
} from '@prosimoio/components';
import * as objectHash from 'object-hash';
import { LightboxDynaCompConfig } from '@app/component-common/lightbox/lightbox.interface';
import { LightBoxTemplateService } from '@app/dashboard/diagnose/diagnostics/lightbox-form-template/lightbox-form-template.service';
import {
  appsDashboardStoreService,
  IActiveTabDetails,
} from '@app/dashboard/state/apps-dashboard';
import {
  cloudTransitSharedFilterQuery,
  cloudTransitStoreFilterService,
} from '@app/dashboard/state/cloud-transit';
import { LegendData } from '@app/component-common/legend/legend.model';
import { cloudTracerService } from '@app/dashboard/state/cloud-tracer/cloud-tracer.service';
import { tasksService } from '@app/dashboard/state/tasks/tasks.service';
import { alertsService } from '@app/dashboard/state/alerts/alerts.service';
import {
  Network,
  NetworkGroup,
} from '@app/dashboard/shared/filter-list/network-list/network-list.model';
import { MCNRecentSearch } from '@app/dashboard/state/mcn-recent-search/mcn-recent-search.model';
import { PdfDownload } from '../models/models';
import { BreadcrumbConfig } from '@prosimoio/components/lib/v2/breadcrumb/breadcrumb.model';
import { ConditionConfig } from '@prosimoio/components/lib/v2/conditions-panel/conditions-panel.model';
import {
  CloudTracer,
  CloudTracerComp,
  CloudTracerHistoryRecord,
} from '@app/v2/diagnose/diagnose.model';
import { OverviewContentService } from '@app/v2/configure/policy/service-insertion/overview-content/overview-content.service';
import { MatTableDataSource } from '@angular/material/table';
import { Region } from '@app/dashboard/shared/filter-list/region-list/region-list.model';
import { MatDrawer } from '@angular/material/sidenav';
import { ALL } from '@app/dashboard/dashboard.constants';
import { MatSelect } from '@angular/material/select';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { MatDialog } from '@angular/material/dialog';
import { loaderConfig } from '@prosimoio/components/lib/v2/loader/loader.model';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { PlaygroundRecentSearch } from '@app/dashboard/state/playground-recent-search/playground-recent-search.model';
import {
  IPollingConfiguration,
  IStatusConfiguration,
  PollingModel,
} from './models/polling-configuration.model';
import { TIME_INTERVALS } from '@app/dashboard/playground/playground.constants';
import { Restrictions } from '../components/filter/playground-filter/playground-filter.model';
import { pDashService } from '@app/state/web-pdash.service';
import { pDashQuery } from '@app/state/web-pdash.query';
import { Day0 } from '@app/state/web-pdash.store';
import { PDFService, PDF_STATUS } from './pdf.service';
import { networksDashboardStoreService } from '@app/dashboard/state/network-dashboard';
import { NoDataModel } from '@app/component-common/no-data/no-data.model';
import { CHART_CONSTANTS } from '@app/component-common/charts/common/util';
import { UserState } from '@app/login/state';
import { Policy } from '@app/v2/configure/policy/policy.model';
import { NETWORK_ONBOARD_MENU_OPTIONS } from '@app/v2/dashboard-constants';
import { networkQuery, networkService } from '@app/v2/onboard/network/state';
import { HeaderService } from '@app/component-common/header/header.service';
import { Location } from '@angular/common';
import { appQuery } from '@app/v2/onboard/app/state/app.query';
import { appService } from '@app/v2/onboard/app/state/app.service';
import { APP_ONBOARD } from '@app/v2/onboard/app/app.constants';
import { MATCH_CONDITIONS_KEY_VALUES } from '@app/v2/configure/policy/policy-library/policy-form/policy-form.constants';
import { Inject } from '@angular/core';
import { AccountService } from '../services/account.service';

// Constant for Delete dialog.
const DELETE_DIALOG = 'deleteDialog_';

@Injectable({
  providedIn: 'root',
})
export class AppUtil {
  DOWNLOAD_TYPE = DOWNLOAD_TYPES;
  DIALOG_BUTTON_TYPE = DIALOG_BUTTON_TYPES;
  ACCESS_TYPE = ACCESS_TYPES;
  PROTOCOLS = PROTOCOLS;

  // Hold the boolean for page footer.
  isDisplayFooter$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  // overlay config
  overlayRef: OverlayRef;
  detailViewContainer: HTMLElement;
  tableContainer: HTMLElement;
  parentContainer: HTMLElement;
  rootElement: ElementRef;
  latestPollingData$ = new BehaviorSubject(null);
  stopPolling$: Subject<boolean> = null;
  selectedUIThemeMode: string;
  private externalReports$ = new BehaviorSubject<any>([]);

  constructor(
    private loginUtil: LoginUtil,
    private alertService: AlertService,
    private filterService: FilterService,
    private headerService: HeaderService,
    private customPolicyService: CustomPolicyService,
    private lightboxService: LightboxService,
    private chartService: ChartService,
    private loaderService: LoaderService,
    private notificationService: NotificationService,
    private navigationService: NavigationBarService,
    private accountService: AccountService,
    private bannerService: BannerService,
    private loginService: LoginService,
    private tabService: TabService,
    private ubiService: UserBehaviorInsightsService,
    private decimalPipe: DecimalPipe,
    private router: Router,
    private interactiveGuideService: InteractiveGuideService,
    private lightboxTemplateService: LightBoxTemplateService,
    private pageFooterService: PageFooterService,
    private breadcrumbService: BreadcrumbService,
    private drawerService: DrawerService,
    private conditionsPanelService: ConditionsPanelService,
    private overviewService: OverviewContentService,
    private multiValueInputService: MultiValueInputService,
    private _ConfigTableViewService: ConfigTableViewService,
    private dialog: MatDialog,
    private windowResizeEventService: WindowResizeService,
    private overlay: Overlay,
    private newLoaderService: NewLoaderService,
    private pdfService: PDFService,
    private location: Location,
    @Inject('pdashStorage') private pdashStorage
  ) {
    this.windowResizeEventService?.onResize
      ?.pipe(debounceTime(300))
      ?.subscribe(() => {
        if (
          this.detailViewContainer &&
          this.tableContainer &&
          this.parentContainer
        ) {
          this.updateDetailSectionDimentions(
            null,
            this.detailViewContainer,
            this.tableContainer,
            this.parentContainer,
            { isReset: true }
          );
        }
      });
  }

  isTrialAccount(): boolean {
    return this.loginUtil.isTrialAdmin();
  }

  isProsimoAdmin(): boolean {
    return this.getUserAccountType() === USER_TYPES.PROSIMO_ADMIN;
  }

  getUserStateAsObservable(): Observable<any> {
    return this.loginUtil.getUserStateAsObservable();
  }
  /**
   * Return true if the user account type is customer
   * @returns
   */
  isValidTeamUser(): boolean {
    return this.getUserAccountType() === USER_TYPES.TEAM_USER;
  }

  isAccountExpired(): boolean {
    return this.loginUtil.isAccountExpired();
  }

  getUserAccountType(): string {
    return this.loginUtil.getUserAccountType();
  }

  /**
   *
   * @returns an team meta info object - has logo, name and account type
   */
  getTeamMetaInfo(): any {
    const { companyName, iconPath, type, team, loginURL, accType } =
      this.loginUtil.getUserState();
    return Object.assign(
      {},
      {
        abstractmarkLogo: iconPath
          ? `${CONSTANTS.GOOGLE_STORAGE_URL}/${CONSTANTS.IMAGES_BUCKET_NAME}/${CONSTANTS.LOGO_BUCKET_NAME}/${iconPath}/${TEAM_ICONS.ABSTRACTMARK}`
          : IMAGE_PATHS.NAVIGATION.PROSIMO,
        wordmarkLogo: iconPath
          ? `${CONSTANTS.GOOGLE_STORAGE_URL}/${CONSTANTS.IMAGES_BUCKET_NAME}/${CONSTANTS.LOGO_BUCKET_NAME}/${iconPath}/${TEAM_ICONS.WORDMARK}`
          : IMAGE_PATHS.NAVIGATION.PROSIMO,
        name: companyName ? companyName : 'Prosimo',
        type,
        favicon: iconPath
          ? `${CONSTANTS.GOOGLE_STORAGE_URL}/${CONSTANTS.IMAGES_BUCKET_NAME}/${CONSTANTS.LOGO_BUCKET_NAME}/${iconPath}/${TEAM_ICONS.FAVICON}`
          : IMAGE_PATHS.FAVICON,
        team,
        loginURL,
        accType,
      }
    );
  }

  getTeamsSupportedFeatures() {
    const { engLabsEnabled } =
      this.isDev() && CONSTANTS.DASHBOARD_API_TOKEN
        ? ({ engLabsEnabled: true } as UserState)
        : this.loginUtil.getUserState();
    return Object.assign({}, { engLabsEnabled });
  }

  /**  Alert component service wrapper */

  showAlert(config: AlertModel, routePath?: string) {
    this.alertService.setAlertConfig(config);
    if (config.type === ALERT_TYPES.SUCCESS) {
      setTimeout(() => {
        this.hideAlert();
        if (routePath) {
          this.router.navigateByUrl(routePath);
        }
      }, 3000);
    }
  }

  hideAlert() {
    this.alertService.resetAlertConfig();
  }

  // Filter Service

  getActiveFilter(): Observable<string> {
    return this.filterService.getSelectedFilter();
  }
  getDoneStatus(): Observable<boolean> {
    return this.filterService.getDoneStatus();
  }
  getCancelStatus(): Observable<boolean> {
    return this.filterService.getCancelStatus();
  }

  setFormErrorMsg(msg: string) {
    this.filterService.setErrorMessage(msg);
  }
  setCancelStatus(status: boolean) {
    this.filterService.setCancelStatus(status);
  }

  setFormLoaderStatus(showLoader: boolean) {
    this.filterService.setLoaderStatus(showLoader);
  }

  // Lightbox service

  setLightboxDisplayState(lightboxState: boolean) {
    this.lightboxService.setLightboxState(lightboxState);
  }

  getLightboxDisplayState(): Observable<boolean> {
    return this.lightboxService.getLightboxState();
  }

  setAddLightboxInTemplateState(isShowlightbox: boolean) {
    this.lightboxService.setAddLightboxInTemplateState(isShowlightbox);
  }

  getAddLightboxInTemplateState(): Observable<boolean> {
    return this.lightboxService.getAddLightboxInTemplateState();
  }

  setLightboxTemplate(template: string) {
    this.lightboxService.setLightboxTemplate(template);
  }

  getLightboxTemplate(): Observable<string> {
    return this.lightboxService.getLightboxTemplate();
  }

  setLightboxDynaCompConfig(dynaCompConfig: LightboxDynaCompConfig) {
    this.lightboxService.setDynaCompConfig(dynaCompConfig);
  }

  getLightboxDynaCompConfig(): Observable<LightboxDynaCompConfig> {
    return this.lightboxService.getDynaCompConfig();
  }

  setLightBoxResponse(response) {
    this.lightboxTemplateService.setLightBoxResponse(response);
  }

  getLightBoxResponse(): Observable<string> {
    return this.lightboxTemplateService.getLightBoxResponse();
  }

  getLightBoxHeader() {
    this.lightboxTemplateService.getLightBoxHeader();
  }

  setLightBoxHeader(header) {
    this.lightboxTemplateService.setLightBoxHeader(header);
  }

  // Loader/Spinner Service

  setLoaderState(loaderState: boolean) {
    this.loaderService.setShowLoader(loaderState);
  }

  // New loader service
  setLoaderConfig(loaderConfig: loaderConfig) {
    this.newLoaderService.setLoaderConfig(loaderConfig);
  }

  getLoaderConfig(): Observable<loaderConfig> {
    return this.newLoaderService.getLoaderConfig();
  }

  // no data service

  setNoDataParams(
    data: any,
    displayMessage = MESSAGES.NO_DATA_INSIGHTS
  ): Observable<NoDataModel> {
    if (!data || !ObjectUtil.hasKeys(data)) {
      return of<NoDataModel>(
        Object.assign({
          isDataAvailable: false,
          message: displayMessage ? displayMessage : MESSAGES.NO_DATA_INSIGHTS, // If message is null or '' empty string
          type: MESSAGES.TYPES.INFO,
        })
      );
    }
    return of<NoDataModel>(
      Object.assign({
        isDataAvailable: true,
        message: '',
        type: MESSAGES.TYPES.INFO,
      })
    );
  }

  getNoDataParamsErrorState(
    message: string = MESSAGES.ERROR
  ): Observable<NoDataModel> {
    return of(
      Object.assign({
        isDataAvailable: false,
        message,
        type: MESSAGES.TYPES.ERROR,
      })
    );
  }

  updateDay0Details(day0Details: Day0) {
    pDashService.updateDay0Details(day0Details);
  }

  getDay0Details(): Day0 {
    let day0Details;
    pDashQuery.day0Details.subscribe((data) => {
      day0Details = data;
    });
    return day0Details;
  }

  // local store -admin

  updateAdminAccountStatus(accountStatus: string) {
    adminService.updateAccountStatus(accountStatus);
  }

  getAdminAccountStatus(): string {
    const adminStoreData = adminQuery.getValue();
    return adminStoreData.accountStatus;
  }

  // local store - recent search - ztna

  updateRecentSearchStore(
    data: ZTNARecentSearch,
    ztnaRecentSearchData: Array<ZTNARecentSearch>
  ) {
    // need to maintain only 5 entries in recent search local store
    const recentStoreCount = ztnaRecentSearchQuery.getCount();
    if (ztnaRecentSearchData.length > 4) {
      this.removeRecentSearchEntry(
        ztnaRecentSearchData[recentStoreCount - 1].id
      );
    }
    ztnaRecentSearchService.updateRecentSearchStore(data);
  }

  updateRecentSearchStoreById(id: string, recentSearchData: ZTNARecentSearch) {
    ztnaRecentSearchService.updateRecentSearchStoreById(id, recentSearchData);
  }
  resetRecentSearchLocalStore() {
    ztnaRecentSearchService.resetStore();
  }

  removeRecentSearchEntry(id: number) {
    ztnaRecentSearchService.removeFilter(id);
  }

  getRecentSearchFilterHistoryStoreData() {
    return ztnaRecentSearchQuery.getAll();
  }

  // local store - recent search - MCN

  updateMCNRecentSearchStore(
    data: MCNRecentSearch,
    mcnRecentSearchData: Array<MCNRecentSearch>
  ) {
    // need to maintain only 5 entries in recent search local store
    const recentStoreCount = mcnRecentSearchQuery.getCount();

    if (mcnRecentSearchData.length > 4) {
      this.removeMCNRecentSearchEntry(
        mcnRecentSearchData[recentStoreCount - 1].id
      );
    }
    mcnRecentSearchService.updateRecentSearchStore(data);
  }

  updateMCNRecentSearchStoreById(id: any, recentSearchData: MCNRecentSearch) {
    mcnRecentSearchService.updateRecentSearchStoreById(id, recentSearchData);
  }
  resetMCNRecentSearchLocalStore() {
    mcnRecentSearchService.resetStore();
  }

  removeMCNRecentSearchEntry(id: number) {
    mcnRecentSearchService.removeFilter(id);
  }

  getMCNRecentSearchFilterHistoryStoreData(): Array<MCNRecentSearch> {
    return mcnRecentSearchQuery.getAll();
  }

  getMCNRecentSearchFilterDataAsObservable(): Observable<MCNRecentSearchState> {
    return mcnRecentSearchQuery.select();
  }

  getMCNRecentSearchFilterDataAsData(): MCNRecentSearchState {
    return mcnRecentSearchQuery.getAll();
  }

  // Recent search playground

  updatePlaygroundRecentSearchStore(
    data: PlaygroundRecentSearch,
    playgroundRecentSearchData: Array<PlaygroundRecentSearch>
  ) {
    // need to maintain only 5 entries in recent search local store
    const recentStoreCount = playgroundRecentSearchQuery.getCount();

    if (playgroundRecentSearchData.length > 4) {
      this.removeMCNRecentSearchEntry(
        playgroundRecentSearchData[recentStoreCount - 1].id
      );
    }
    playgroundRecentSearchService.updateRecentSearchStore(data);
  }

  updatePlaygroundRecentSearchStoreById(
    id: any,
    recentSearchData: PlaygroundRecentSearch
  ) {
    playgroundRecentSearchService.updateRecentSearchStoreById(
      id,
      recentSearchData
    );
  }
  resetPlaygroundRecentSearchLocalStore() {
    playgroundRecentSearchService.resetStore();
  }

  removePlaygroundRecentSearchEntry(id: number) {
    playgroundRecentSearchService.removeFilter(id);
  }

  getPlaygroundRecentSearchFilterHistoryStoreData(): Array<PlaygroundRecentSearch> {
    return playgroundRecentSearchQuery.getAll();
  }

  getPlaygroundRecentSearchFilterDataAsObservable(): Observable<PlaygroundRecentSearchState> {
    return playgroundRecentSearchQuery.select();
  }

  getPlaygroundRecentSearchFilterDataAsData(): PlaygroundRecentSearchState {
    return playgroundRecentSearchQuery.getAll();
  }

  // local store - ZTNA dashboard

  updateSource(source: string) {
    ztnaInsightsStoreFilterService.updateSource(source);
  }

  updateSelectedTeamUsers(selectedTeamUsers: Array<TeamUser>) {
    ztnaInsightsStoreFilterService.updateSelectedTeamUsers(selectedTeamUsers);
  }

  updateSelectedUserGroups(selectedUserGroups: Array<UserGroup>) {
    ztnaInsightsStoreFilterService.updateSelectedUserGroups(selectedUserGroups);
  }

  updateSelectedOnboardedApps(selectedOnboardedApps: Array<OnboardedApp>) {
    ztnaInsightsStoreFilterService.updateSelectedOnboardedApps(
      selectedOnboardedApps
    );
  }

  updateSelectedAppGroups(selectedAppGroups: Array<AppGroup>) {
    ztnaInsightsStoreFilterService.updateSelectedAppGroups(selectedAppGroups);
  }

  updateZTNASelectedTargetNetworks(selectedNetworks: Array<Network>) {
    ztnaInsightsStoreFilterService.updateSelectedNetworks(selectedNetworks);
  }

  updateZTNASelectedNetworkGroups(selectedNetworkGroups: Array<NetworkGroup>) {
    ztnaInsightsStoreFilterService.updateSelectedNetworkGroups(
      selectedNetworkGroups
    );
  }

  updatedSelectedDateRangeFilter(dateRange: TimeRange) {
    ztnaInsightsStoreFilterService.updateDateRange(
      Object.assign({}, dateRange)
    );
  }

  updatedLogSearchTimeRangeFilter(logSearchTimeRange: TimeRange) {
    logSearchFilterStoreService.updateLogSearchFilterTimeRange(
      Object.assign({}, logSearchTimeRange)
    );
  }

  getLogSearchTimeRangeFilter(): any {
    return logSearchFilterQuery.getValue();
  }

  updateInsightTimeRange(timeRange: TimeRange) {
    ztnaInsightsStoreFilterService.updateInsightDateRange(
      Object.assign({}, timeRange)
    );
  }

  updateSelectedTimeRangeType(selectedTimeRangeType: TimeRangeType) {
    ztnaInsightsStoreFilterService.updateSelectedTimeRangeType(
      selectedTimeRangeType
    );
  }

  updateMainDashboardTimeRange(timeRange: TimeRange) {
    ztnaInsightsStoreFilterService.updateMainDashboardDateRange(
      Object.assign({}, timeRange)
    );
  }

  mcnUpdateMainDashboardTimeRange(timeRange: TimeRange) {
    mcnInsightsStoreFilterService.updateMainDashboardDateRange(
      Object.assign({}, timeRange)
    );
  }

  mcnUpdateCostDashboardTimeRange(timeRange: TimeRange) {
    if (timeRange) {
      mcnInsightsStoreFilterService.updateCostDashboardDateRange(
        Object.assign({}, timeRange)
      );
    } else {
      mcnInsightsStoreFilterService.updateCostDashboardDateRange(null);
    }
  }

  updateActiveTabName(activeTab: string, tabType: string) {
    const { storeState, storeService } = this.getStoreStateAndService(tabType);
    const activeTabConfig = activeTab ? activeTab : storeState.activeTab;
    storeService.updateActiveTab(activeTabConfig);
  }

  updateActiveSubMenuName(activeSubMenu: string, subMenuType: string) {
    const { storeState, storeService } =
      this.getStoreStateAndService(subMenuType);
    const activeSubMenuName = activeSubMenu
      ? activeSubMenu
      : storeState.activeSubMenu;
    storeService.updateActiveSubMenu(activeSubMenuName);
  }

  updatePreviewImageUrl(previewImageUrl: string, subMenuType: string) {
    const { storeService } = this.getStoreStateAndService(subMenuType);
    const previewImageUrlValue = previewImageUrl;
    storeService.updatePreviewImageUrl(previewImageUrlValue);
  }

  updateLevelTwoActiveSubMenuName(activeSubMenu: string, subMenuType: string) {
    const { storeState, storeService } =
      this.getStoreStateAndService(subMenuType);
    const activeSubMenuName = activeSubMenu
      ? activeSubMenu
      : storeState.activeSubMenu;
    storeService.updateLevelTwoActiveSubMenu(activeSubMenuName);
  }

  getStoreStateAndService(type: string) {
    let config = { storeState: null, storeService: null };
    switch (type) {
      case INSIGHTS_MENU_OPTIONS.INSIGHTS.MCN:
        config = {
          storeState: mcnInsightsSharedFilterQuery.getValue(),
          storeService: mcnInsightsStoreFilterService,
        };
        break;
      case INSIGHTS_MENU_OPTIONS.INSIGHTS.ZTNA:
        config = {
          storeState: ztnaInsightsSharedFilterQuery.getValue(),
          storeService: ztnaInsightsStoreFilterService,
        };
        break;
      case DIAGNOSE_MENU_OPTIONS.DIAGNOSE:
        config = {
          storeState: diagnoseSharedFilterQuery.getValue(),
          storeService: diagnoseStoreFilterService,
        };
        break;
      case MANAGEMENT.MANAGEMENT_TEXT:
        config = {
          storeState: managementQuery.getValue(),
          storeService: managementService,
        };
        break;
      case AGENT_DASHBOARD:
        config = {
          storeState: this.getAgentStoreData(),
          storeService: agentService,
        };
        break;
      case 'advanced-setting':
        config = {
          storeState: this.getAdvancedSettingStoreData(),
          storeService: advancedSettingService,
        };
        break;
      case 'device-posture':
        config = {
          storeState: this.getSecurityStoreData(),
          storeService: securityService,
        };
        break;
      case CLOUD_TRANSIT_MENU_OPTIONS.CLOUD_TRANSIT:
        config = {
          storeState: cloudTransitSharedFilterQuery.getValue(),
          storeService: cloudTransitStoreFilterService,
        };
        break;
      case NETWORK_ONBOARD_MENU_OPTIONS.NETWORK_ONBOARD:
        config = {
          storeState: networkQuery.getValue(),
          storeService: networkService,
        };
        break;
      case APP_ONBOARD:
        config = {
          storeState: appQuery.getValue(),
          storeService: appService,
        };
        break;
      case VIEW_TYPES.PLAYGROUND:
        config = {
          storeState: this.getPlaygroundDashboardFilters(),
          storeService: playgroundFilterService,
        };
    }
    return config;
  }

  updateZTNAFiltersStore(
    ztnaInsightsSharedFilterState: ZTNAInsightsSharedFilterState
  ) {
    ztnaInsightsSharedFilterStore.update(ztnaInsightsSharedFilterState);
  }

  /** MCN Insights store - Start  */

  updateMCNDashboardFiltersStore(
    mcnInsightsFilterState: MCNInsightsSharedFilterState
  ) {
    mcnInsightsSharedFilterStore.update(mcnInsightsFilterState);
  }

  getMCNStoreValue(): MCNInsightsSharedFilterState {
    return mcnInsightsSharedFilterStore.getValue();
  }

  getMCNStoreAsObservable(): Observable<MCNInsightsSharedFilterState> {
    return mcnInsightsSharedFilterQuery.select();
  }

  updateMCNSelectedTimeRangeType(selectedTimeRangeType: TimeRangeType) {
    mcnInsightsStoreFilterService.updateSelectedTimeRangeType(
      selectedTimeRangeType
    );
  }

  updateMCNInsightTimeRange(timeRange: TimeRange) {
    mcnInsightsStoreFilterService.updateInsightDateRange(
      Object.assign({}, timeRange)
    );
  }

  updateMCNSelectedDateRangeFilter(dateRange: TimeRange) {
    mcnInsightsStoreFilterService.updateDateRange(Object.assign({}, dateRange));
  }

  getMCNDashboardFilters(): MCNInsightsSharedFilterState {
    const {
      selectedSourceNetworks,
      selectedSourcePrivateLinks,
      selectedSourceNetworkGroups,
      selectedSourceRegions,
      selectedTargetNetworks,
      selectedTargetNetworkGroups,
      selectedTargetRegions,
      selectedOnboardedApps,
      timeRange,
      selectedTimeRangeType,
      insightTimeRange,
      mainDashboardTimeRange,
      costDashboardTimeRange,
      activeTab,
      activeSubMenu,
      levelTwoActiveSubMenu,
      source,
      isDataNew,
      totalSourceNetworks,
      totalSourcePrivateLinks,
      totalSourceNetworkGroups,
      totalSourceRegions,
      totalTargetNetworks,
      totalTargetNetworkGroups,
      totalTargetRegions,
      totalOnboardedApps,
    } = this.getMCNStoreValue();
    return {
      selectedSourceNetworks,
      selectedSourcePrivateLinks,
      selectedSourceNetworkGroups,
      selectedSourceRegions,
      selectedTargetNetworks,
      selectedTargetNetworkGroups,
      selectedTargetRegions,
      selectedOnboardedApps,
      timeRange,
      selectedTimeRangeType,
      insightTimeRange,
      mainDashboardTimeRange,
      costDashboardTimeRange,
      activeTab,
      activeSubMenu,
      levelTwoActiveSubMenu,
      source,
      isDataNew,
      totalSourceNetworks,
      totalSourcePrivateLinks,
      totalSourceNetworkGroups,
      totalSourceRegions,
      totalTargetNetworks,
      totalTargetNetworkGroups,
      totalTargetRegions,
      totalOnboardedApps,
    } as MCNInsightsSharedFilterState;
  }

  updateSelectedSourceNetworks(selectedSourceNetworks: Array<Network>) {
    mcnInsightsStoreFilterService.updateSelectedSourceNetworks(
      selectedSourceNetworks
    );
  }

  updateSelectedSourcePrivateLinks(selectedSourcePrivateLinks: Array<Network>) {
    mcnInsightsStoreFilterService.updateSelectedSourcePrivateLinks(
      selectedSourcePrivateLinks
    );
  }

  updateSelectedSourceNetworkGroups(
    selectedSourceNetworkGroups: Array<NetworkGroup>
  ) {
    mcnInsightsStoreFilterService.updateSelectedSourceNetworkGroups(
      selectedSourceNetworkGroups
    );
  }

  updateSelectedSourceRegions(selectedSourceRegions: Array<Region>) {
    mcnInsightsStoreFilterService.updateSelectedSourceRegions(
      selectedSourceRegions
    );
  }

  updateSelectedTargetNetworks(selectedTargetNetworks: Array<Network>) {
    mcnInsightsStoreFilterService.updateSelectedTargetNetworks(
      selectedTargetNetworks
    );
  }

  updateSelectedTargetNetworkGroups(selectedTargetNetworkGroups: Array<any>) {
    mcnInsightsStoreFilterService.updateSelectedTargetNetworkGroups(
      selectedTargetNetworkGroups
    );
  }

  updateSelectedTargetRegions(selectedTargetRegions: Array<any>) {
    mcnInsightsStoreFilterService.updateSelectedTargetRegions(
      selectedTargetRegions
    );
  }

  updateMCNSelectedOnboardedApps(selectedOnboardedApps: Array<any>) {
    mcnInsightsStoreFilterService.updateSelectedOnboardedApps(
      selectedOnboardedApps
    );
  }

  updateMCNSearchFilterDateRange(dateRange: TimeRange) {
    mcnInsightsStoreFilterService.updateDateRange(Object.assign({}, dateRange));
  }

  updateMCNActiveSubMenuName(activeSubMenu: string, subMenuType: string) {
    const { storeState, storeService } =
      this.getStoreStateAndService(subMenuType);
    const activeSubMenuName = activeSubMenu
      ? activeSubMenu
      : storeState.activeSubMenu;
    storeService.updateActiveSubMenu(activeSubMenuName);
  }

  /** Networks Insights store - end  */

  /** Playground store data - start  */

  updatePlaygroundSelectedNetworks(selectedNetworks: Array<Network>) {
    playgroundFilterService.updateSelectedNetworks(selectedNetworks);
  }

  updatePlaygroundSelectedPrivateLinks(selectedPrivateLinks: Array<Network>) {
    playgroundFilterService.updateSelectedPrivateLinks(selectedPrivateLinks);
  }

  updatePlaygroundSelectedOnboardedApps(selectedOnboardedApps: Array<any>) {
    playgroundFilterService.updateSelectedOnboardedApps(selectedOnboardedApps);
  }

  updatePlaygroundSelectedRegions(selectedRegions: Array<Region>) {
    playgroundFilterService.updateSelectedRegions(selectedRegions);
  }

  updatePlaygroundTimeRange(timeRange: TimeRange) {
    playgroundFilterService.updateTimeRange(timeRange);
  }

  updatePlaygroundRestrictions(restrictions: Restrictions) {
    playgroundFilterService.updateRestrictions(restrictions);
  }

  updatePlaygroundTimeInterval(selectedTimeInterval: string): string {
    switch (selectedTimeInterval) {
      case TimeRangeType.PAST_ONE_DAY:
        return TIME_INTERVALS.DAY;
      case TimeRangeType.PAST_ONE_WEEK:
        return TIME_INTERVALS.WEEK;
      case TimeRangeType.PAST_ONE_MONTH:
        return TIME_INTERVALS.MONTH;
    }
  }

  updatePlaygroundSelectedTimeRangeType(selectedTimeRangeType: TimeRangeType) {
    playgroundFilterService.updateSelectedTimeRangeType(selectedTimeRangeType);
  }

  updatePlaygroundDashboardFiltersStore(
    playgroundFilterState: PlaygroundFilterState
  ) {
    playgroundFilterStore.update(playgroundFilterState);
  }

  updatePlaygroundActiveSubMenuName(
    activeSubMenu: string,
    subMenuType: string
  ) {
    const { storeState, storeService } =
      this.getStoreStateAndService(subMenuType);
    const activeSubMenuName = activeSubMenu
      ? activeSubMenu
      : storeState.activeSubMenu;
    storeService.updateActiveSubMenu(activeSubMenuName);
  }

  getPlaygroundStoreValue(): PlaygroundFilterState {
    return playgroundFilterStore.getValue();
  }

  getPlaygroundLocalStore(): Observable<PlaygroundFilterState> {
    return playgroundFilterQuery.select();
  }

  getPlaygroundDashboardFilters(): PlaygroundFilterState {
    const {
      selectedNetworks,
      selectedPrivateLinks,
      selectedRegions,
      selectedOnboardedApps,
      selectedTimeRangeType,
      timeRange,
      activeTab,
      activeSubMenu,
      levelTwoActiveSubMenu,
      source,
      totalNetworks,
      totalPrivateLinks,
      totalRegions,
      totalOnboardedApps,
      restrictions,
    } = this.getPlaygroundStoreValue();
    return {
      selectedNetworks,
      selectedPrivateLinks,
      selectedRegions,
      selectedOnboardedApps,
      selectedTimeRangeType,
      timeRange,
      activeTab,
      activeSubMenu,
      levelTwoActiveSubMenu,
      source,
      totalNetworks,
      totalPrivateLinks,
      totalRegions,
      totalOnboardedApps,
      restrictions,
    } as PlaygroundFilterState;
  }
  /** Playground store data - end  */

  updateManagementStore(managementState: ManagementState) {
    managementStore.update(managementState);
  }
  getInsightsDashboardFilters(): ZTNAInsightsSharedFilterState {
    const {
      selectedOnboardedApps,
      selectedAppGroups,
      selectedTeamUsers,
      selectedUserGroups,
      selectedNetworks,
      selectedNetworkGroups,
      timeRange,
      totalUsers,
      totalApps,
      totalUserGroups,
      totalAppGroups,
      totalNetworks,
      totalNetworkGroups,
      selectedTimeRangeType,
      activeTab,
      activeSubMenu,
    } = this.getZTNAInsightsLocalStoreAsValue();
    return {
      selectedOnboardedApps,
      selectedAppGroups,
      selectedTeamUsers,
      selectedUserGroups,
      selectedNetworks,
      selectedNetworkGroups,
      timeRange,
      totalUsers,
      totalApps,
      totalUserGroups,
      totalAppGroups,
      totalNetworks,
      totalNetworkGroups,
      selectedTimeRangeType,
      activeTab,
      activeSubMenu,
    } as ZTNAInsightsSharedFilterState;
  }

  getZTNAActiveTab(): string {
    const ztnaLocalStoreData = ztnaInsightsSharedFilterQuery.getValue();
    return ztnaLocalStoreData.activeTab;
  }

  getManagementActiveTab(): string {
    const managementLocalStoreData = managementQuery.getValue();
    return managementLocalStoreData.activeTab;
  }

  getManagementPreviewImageUrl(): string {
    const managementLocalStoreData = managementQuery.getValue();
    return managementLocalStoreData.previewImageUrl;
  }

  getZTNAInsightsLocalStoreAsValue(): ZTNAInsightsSharedFilterState {
    return ztnaInsightsSharedFilterQuery.getValue();
  }

  getZTNAInsightsLocalStore(): Observable<ZTNAInsightsSharedFilterState> {
    return ztnaInsightsSharedFilterQuery.select();
  }

  updateDashboardLogDisplayData(dasbhoardDisplayData: any) {
    logMetaDataService.updateDashboardDisplayData(dasbhoardDisplayData);
  }

  updateLogFieldsMappingData(fieldsMappingData: any) {
    logMetaDataService.updateFieldsMappingData(fieldsMappingData);
  }

  updatePersistedLogColumns(dashboard: string, fields: Array<string>) {
    logMetaDataService.updatePersistedLogColumns(dashboard, fields);
  }

  getDashboardLogDisplayData(): Observable<any> {
    return logMetaDataQuery.dashboardDisplayData;
  }

  getLogFieldsMappingData(): Observable<any> {
    return logMetaDataQuery.fieldsMappingData;
  }

  getDashboardLogMetaData() {
    return logMetaDataQuery.getValue();
  }

  /**
   * Function to check if only agent app is selected from the filters
   * If only agent app is selected then disable access to performance, app experience and waf tabs
   * If the user is already in that tab then route them to the summary tab
   * @param menuConfigs
   */

  updateDashboardMenuConfigs(menuConfigs: Array<DashboardMenuConfig>) {
    if (!menuConfigs) {
      return;
    }
    // Get insights dashboard local store values
    const { activeTab } = this.getInsightsDashboardFilters();
    const TABS_TO_DISABLE_FOR_AGENT_APP = [
      INSIGHTS_MENU_OPTIONS.PERFORMANCE,
      INSIGHTS_MENU_OPTIONS.APP_EXPERIENCE,
      INSIGHTS_MENU_OPTIONS.WAF,
    ];
    const TABS_TO_DISABLE_FOR_STREAM_APP = [
      INSIGHTS_MENU_OPTIONS.PERFORMANCE,
      INSIGHTS_MENU_OPTIONS.USER_RISK,
      INSIGHTS_MENU_OPTIONS.WAF,
    ];
    const TABS_TO_DISABLE_FOR_AGENT_STREAM_APP = Array.from(
      new Set([
        ...TABS_TO_DISABLE_FOR_AGENT_APP,
        ...TABS_TO_DISABLE_FOR_STREAM_APP,
      ])
    );
    const TABS_TO_DISABLE_FOR_CLIENT_APPLICATION_GROUP = [
      INSIGHTS_MENU_OPTIONS.HEATMAP,
    ];

    let updatedActiveTab = activeTab;
    let updatedMenuConfigs: Array<DashboardMenuConfig> = menuConfigs;
    const selectedAppAccessTypes = this.selectedAppTypesInFilter();
    const activeMenuConfig = updatedMenuConfigs.find(
      (menuConfig) => menuConfig.isSelected
    );
    switch (selectedAppAccessTypes) {
      case ACCESS_TYPES.AGENT:
        updatedMenuConfigs = this.updateMenuConfigs(
          menuConfigs,
          TABS_TO_DISABLE_FOR_AGENT_APP,
          'Not available for agent apps'
        );
        if (TABS_TO_DISABLE_FOR_AGENT_APP.includes(activeMenuConfig.code)) {
          updatedActiveTab = updatedMenuConfigs[0].code;
        }
        this.updateActiveTabName(
          updatedActiveTab,
          INSIGHTS_MENU_OPTIONS.INSIGHTS.ZTNA
        );
        break;
      case ACCESS_TYPES.STREAM:
        updatedMenuConfigs = this.updateMenuConfigs(
          menuConfigs,
          TABS_TO_DISABLE_FOR_STREAM_APP,
          'Not available for streaming apps'
        );
        if (TABS_TO_DISABLE_FOR_STREAM_APP.includes(activeMenuConfig.code)) {
          updatedActiveTab = updatedMenuConfigs[0].code;
        }
        this.updateActiveTabName(
          updatedActiveTab,
          INSIGHTS_MENU_OPTIONS.INSIGHTS.ZTNA
        );
        break;
      case ACCESS_TYPES.AGENT_AND_STREAM:
        updatedMenuConfigs = this.updateMenuConfigs(
          menuConfigs,
          TABS_TO_DISABLE_FOR_AGENT_STREAM_APP,
          'Not available for apps selected in the filter'
        );
        if (
          TABS_TO_DISABLE_FOR_AGENT_STREAM_APP.includes(activeMenuConfig.code)
        ) {
          updatedActiveTab = updatedMenuConfigs[0].code;
        }
        this.updateActiveTabName(
          updatedActiveTab,
          INSIGHTS_MENU_OPTIONS.INSIGHTS.ZTNA
        );
        break;
      case ACCESS_TYPES.CLIENT_APPLICATION_GROUPS:
        updatedMenuConfigs = this.updateMenuConfigs(
          menuConfigs,
          TABS_TO_DISABLE_FOR_CLIENT_APPLICATION_GROUP,
          'Not available for apps selected in the filter'
        );
        if (
          TABS_TO_DISABLE_FOR_AGENT_STREAM_APP.includes(activeMenuConfig.code)
        ) {
          updatedActiveTab = updatedMenuConfigs[0].code;
        }
        this.updateActiveTabName(
          updatedActiveTab,
          INSIGHTS_MENU_OPTIONS.INSIGHTS.ZTNA
        );
        break;
      case ACCESS_TYPES.ANY:
        updatedMenuConfigs = menuConfigs.map((menuConfig) =>
          Object.assign({}, menuConfig, { isDisabled: false, tooltip: '' })
        );
        break;
    }
    updatedMenuConfigs = updatedMenuConfigs.map((menuConfig) => {
      return updatedActiveTab === menuConfig.code
        ? Object.assign({}, menuConfig, { isSelected: true })
        : Object.assign({}, menuConfig, { isSelected: false });
    });
    return updatedMenuConfigs;
  }

  /**
   * Disable tabs based on app & access types
   * @param menuConfigs
   * @param tabsToDisable
   * @param tooltip
   */
  updateMenuConfigs(
    menuConfigs: Array<DashboardMenuConfig>,
    tabsToDisable: Array<string>,
    tooltip: string
  ) {
    return menuConfigs.map((menuConfig) => {
      return tabsToDisable.includes(menuConfig.code)
        ? Object.assign({}, menuConfig, { isDisabled: true, tooltip })
        : Object.assign({}, menuConfig, { isDisabled: false });
    });
  }

  /**
   * Function to check only agent app is selected from the insights filter.
   */

  selectedAppTypesInFilter(): string {
    // Get insights dashboard local store values
    const { selectedOnboardedApps } = this.getInsightsDashboardFilters();
    const selectedOnboardedAppsCount =
      selectedOnboardedApps &&
      !this.isMasterSelectAllRecords(selectedOnboardedApps, FILTER_TYPES.APP)
        ? selectedOnboardedApps.length
        : 0;
    if (!selectedOnboardedAppsCount) {
      return ACCESS_TYPES.ANY;
    }

    const agentApp: Array<OnboardedApp> = selectedOnboardedApps.filter(
      (selectedApp: OnboardedApp) =>
        selectedApp.appAccessType === ACCESS_TYPES.AGENT
    );
    const streamApp: Array<OnboardedApp> = selectedOnboardedApps.filter(
      (selectedApp: OnboardedApp) => selectedApp?.appType === 'tcp'
    );
    const accessingFabricApps: Array<OnboardedApp> =
      selectedOnboardedApps.filter(
        (selectedApp: OnboardedApp) =>
          selectedApp?.onboardType === ONBOARD_TYPE.ACCESSING_FABRIC
      );

    // If all the selected apps are stream apps
    if (streamApp.length === selectedOnboardedAppsCount) {
      return ACCESS_TYPES.STREAM;
    }
    // If all the selected apps are agent apps
    if (agentApp.length === selectedOnboardedAppsCount) {
      return ACCESS_TYPES.AGENT;
    }
    // If all the selected apps are accessing fabric apps or source application group
    if (accessingFabricApps.length === selectedOnboardedAppsCount) {
      return ACCESS_TYPES.CLIENT_APPLICATION_GROUPS;
    }
    return ACCESS_TYPES.ANY;
  }

  /**
   *
   * @param dashboardType
   */
  routeToDashboardDefaultPage(isZTNA: boolean) {
    const { activeTab } = isZTNA
      ? this.getInsightsDashboardFilters()
      : this.getMCNDashboardFilters();
    const menuConfigs = isZTNA
      ? APPS_INSIGHTS_MENU_CONFIGS
      : NETWORKS_INSIGHTS_MENU_CONFIGS;
    this.router.navigateByUrl(
      menuConfigs.find((menuConfig) => menuConfig.code === activeTab).fullPath
    );
  }

  setPageLayout(pageType: any) {
    const simplePageTypeSet = new Set(SIMPLE_PAGES);
    const {
      type,
      banner = true,
      navigation = true,
      header = null,
      navigationReadOnly = false,
    } = pageType || {};
    let displayNavBar = navigation;
    let showPageBanner = banner;
    let isProsimoAdmin = false;
    let isMSP = false;
    if (simplePageTypeSet.has(type)) {
      displayNavBar = false;
      showPageBanner = false;
    } else {
      if (type === PageTypes.PROSIMO_ADMIN) {
        isProsimoAdmin = true;
      } else if (type === PageTypes.MSP_ADMIN) {
        isMSP = true;
      }
    }
    this.setL2Header(header ? HEADER_CONFIGS[header] : null);
    this.setNavigationBar(
      isProsimoAdmin,
      isMSP,
      displayNavBar,
      navigationReadOnly
    );
    this.bannerService.hideBanner(!showPageBanner);
  }

  setNavigationBar(
    isProsimoAdmin: boolean = false,
    isMSP: boolean = false,
    displayNavBar: boolean = false,
    readOnly: boolean = false
  ) {
    this.selectedUIThemeMode = PDashLocalStoreUtil.getUIThemeMode();
    this.navigationService.setNavigationBarConfig(
      new NavigationBarModel(isProsimoAdmin, isMSP),
      this.selectedUIThemeMode
    );
    this.navigationService.displayNavigationBar(displayNavBar);
    this.navigationService.setNavigationBarReadOnlyState(readOnly);
  }

  /**
   * get the nav bar expanded state
   * @returns
   */
  getNavigationExpandState(): Observable<boolean> {
    return this.navigationService.getNavigationExpandState();
  }

  setL2Header(headerConfig: L2HeaderModel): void {
    this.headerService.setL2HeaderConfig(headerConfig);
  }

  // TODO: update mspLogo
  setMSPL2Header(
    headerConfig: L2HeaderModel,
    mspName: string = '',
    mspLogo: string = IMAGE_PATHS.NAVIGATION.PROSIMO
  ): void {
    const title = mspName
      ? `${headerConfig.title} ${mspName}`
      : headerConfig.title;
    const mspImageURL = mspLogo || '';
    const mspHeaderConfig = Object.assign({}, headerConfig, {
      title,
      mspImageURL,
    });
    this.headerService.setL2HeaderConfig(mspHeaderConfig);
  }

  getL2HeaderActionEvent(): Observable<Event> {
    return this.headerService.getL2HeaderActionEvent();
  }

  setL2headerActionEvent(state: any) {
    this.headerService.triggerActionEvent(state);
  }

  getCustomPolicies(): Observable<Policy> {
    return this.customPolicyService.getAvailablePolicies();
  }

  getAvailableApps() {
    return this.customPolicyService.getAvailableApps();
  }

  getPolicyFormDefinition() {
    return this.customPolicyService.getFormDefinitionAgentLess();
  }

  openNewWindow(url: string) {
    window.open(url, '_blank');
  }

  // advanced setting - start

  getAdvancedSettingStoreData(): AdvancedSettingState {
    return advancedSettingQuery.getValue();
  }

  // security - start

  getSecurityStoreData(): SecurityState {
    return securityQuery.getValue();
  }

  // agent dashboard - start

  getAgentStoreData(): AgentState {
    return agentQuery.getValue();
  }

  updateAgentDashboardActiveTab(tabName: string) {
    agentService.updateActiveTab(tabName);
  }

  // agent dashboard

  reloadSameWindowWithURL(url: string) {
    window.open(url, '_self');
  }

  // Chart service - Start

  getSelectedBrushedRegionDims(): Observable<any> {
    return this.chartService.getBrushExtent();
  }

  getSelectedDataPoints(): Observable<any> {
    return this.chartService.getSelectedDataPoint();
  }

  getSelectedRangeValues(): Observable<SelectedRange> {
    return this.chartService.getSelectedRange();
  }

  /**
   *
   * @param insightsLocalStoreData - Can be either MCNRecentSearchState or InsightsSharedFilterState
   * @returns
   */
  compileSelectedDateRangeInfo(insightsLocalStoreData: any): any {
    return {
      from: DateUtil.roundOffToNearestHour(
        insightsLocalStoreData?.timeRange?.from,
        DateUtil.ROUND_OFF_HOURLY_TYPE.LOWER
      ),
      to: DateUtil.roundOffToNearestHour(
        insightsLocalStoreData?.timeRange?.to,
        DateUtil.ROUND_OFF_HOURLY_TYPE.HIGHER
      ),
    };
  }

  /**
   *
   * @param queryParams - decoded object from URL path
   * @returns - object with two properties from and to datetime
   */
  compileSelectedDateRangeFromQueryParam(queryParams: any): any {
    return {
      from: queryParams?.fatime || 0,
      to: queryParams?.latime || 0,
    };
  }

  /**
   *
   * @param fromAndtoDateTimeInMs
   * @param isValueArray
   * @param customPropName
   */
  formatSelectedDateRangeForChartData(
    fromAndtoDateTimeInMs: any,
    value = null,
    customPropName: string = 'value'
  ) {
    return [
      {
        [customPropName]: value,
        startTime: fromAndtoDateTimeInMs.from,
        ignorePlotting: true,
      },
      {
        [customPropName]: value,
        startTime: fromAndtoDateTimeInMs.to,
        ignorePlotting: true,
      },
    ];
  }

  // Chart service - End

  // Notification Service
  setConfirmMessage(message: string = '', actionBtns?: Array<any>) {
    const actionType = DIALOG_ACTION_TYPES.CONFIRM;
    const buttons =
      actionBtns && actionBtns.length
        ? actionBtns
        : [
            {
              name: this.DIALOG_BUTTON_TYPE.CANCEL,
              state: 'inActive',
            },
            {
              name: this.DIALOG_BUTTON_TYPE.YES,
              state: 'Active',
            },
          ];
    this.notificationService.setMessageConfig({ actionType, message, buttons });
  }

  setNotificationMessage(message: any, type?: string, delay?: number) {
    const actionType = type ? type : DIALOG_ACTION_TYPES.SUCCESS;
    this.notificationService.setMessageConfig({ actionType, message });
    if (delay) {
      this.hideMessageDialog(delay);
    }
  }
  setCopyDataNotificationMessage(
    copyData: string,
    message: any,
    actionBtns?: Array<any>
  ) {
    const buttons =
      actionBtns && actionBtns.length
        ? actionBtns
        : [
            {
              name: this.DIALOG_BUTTON_TYPE.DONE,
              state: 'Active',
            },
          ];
    this.notificationService.setMessageConfig({
      copyDataConfig: { copyData, message },
      buttons,
    });
  }

  setNotificationLoaderStatus(showLoader: boolean) {
    this.notificationService.setLoderStatus(showLoader);
  }

  getConfirmAction() {
    return this.notificationService.getSelectedAction();
  }

  hideMessageDialog(delay?: number) {
    if (delay || delay === 0) {
      setTimeout(() => this.notificationService.setMessageConfig(null), delay);
    } else {
      this.notificationService.setMessageConfig(null);
    }
    this.setNotificationLoaderStatus(false);
  }

  // Notification Service - End

  /** Tab service - start  */
  getSelectedTabConfig(): Observable<TabConfig> {
    return this.tabService.getSelectedTab();
  }
  /** Tab service - end  */

  triggerWindowResize() {
    window.dispatchEvent(new Event('resize'));
  }

  /**
   * Method to get hash value of the input parameter
   * @param value
   * @returns
   */
  getHashId(value: any) {
    if (!value) {
      return;
    }
    return objectHash.sha1(value);
  }

  /** app-wide common component services wrapper - end  */

  /* helper functions - start*/
  clone(toClone: any): any {
    if (!toClone) {
      return;
    }
    return JSON.parse(JSON.stringify(toClone));
  }

  parseJSON(toParse: any): any {
    return JSON.parse(toParse);
  }
  replaceWhitespace(str: string): string {
    return str.replace(REGEX.WHITESPACE, '_');
  }

  sortRegions(data, keyType, dataType) {
    data.sort((a, b) => (a[keyType] > b[keyType] ? 1 : -1));
    return data;
  }

  sortIpAddresses(ipAddresses) {
    if (ipAddresses?.length === 1) {
      return ipAddresses;
    } else if (ipAddresses?.length > 0) {
      ipAddresses = [...ipAddresses].sort((a, b) => {
        const val1 = this.convertToNumber(this.getSubnetValue(a));
        const val2 = this.convertToNumber(this.getSubnetValue(b));
        return val1 - val2;
      });
      return ipAddresses;
    } else {
      return [];
    }
  }

  getSubnetValue(subnetVal: any): string {
    return typeof subnetVal === 'object' ? subnetVal?.subnet : subnetVal;
  }

  convertToNumber(value) {
    if (!(value && typeof value === 'string')) {
      return;
    }
    value = value.replace('/', '.');
    return Number(
      value
        .split('.')
        .map((num) => `000${num}`.slice(-3))
        .join('')
    );
  }

  sort(
    data,
    key: string,
    type: string = '',
    asc: boolean = true,
    ignoreCase: boolean = true
  ) {
    if (!data) {
      return;
    }

    if (type === 'number' && !key) {
      return data.sort();
    }
    // if value is not string, return as it is. Otherwise, convert to lowercase and return
    const getValue = (value) => {
      if (typeof value === 'string' && ignoreCase && type !== 'date') {
        return value.toLowerCase();
      }
      return value;
    };

    const strCompare = (A, B) => {
      return A.toString().localeCompare(B.toString(), undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    };

    const dateCompare = (A, B) => {
      const dateA = new Date(A);
      const dateB = new Date(B);
      return !dateA ? 1 : !dateB ? -1 : dateA < dateB ? -1 : 1;
    };

    const compareAsc = (a, b) => {
      const A = getValue(key ? a[key] : a);
      const B = getValue(key ? b[key] : b);

      if (type === 'date') {
        return dateCompare(A, B);
      } else {
        return !A ? 1 : !B ? -1 : isNaN(A) ? strCompare(A, B) : A < B ? -1 : 1;
      }
    };

    return asc ? data.sort(compareAsc) : data.sort(compareAsc).reverse();
  }
  /*
   *fileName -> represents name of the file to be downloaded
   * downloadType -> to decide if file needs to be downloaded or view
   */
  downloadData(
    data: any,
    type: string = '',
    fileName: string = 'file',
    downloadType: string = this.DOWNLOAD_TYPE.VIEW
  ) {
    const blob = new Blob([data], { type });
    if (downloadType === this.DOWNLOAD_TYPE.SAVE) {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = fileName;
      a.click();
    } else {
      const url = window.URL.createObjectURL(blob);
      const pwa = window.open(url);
      // TODO: add a custom alert instead of HTML alert
      if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
        alert('Please disable your Pop-up blocker and try again.');
      }
    }
  }

  /**
   * Method to calculate the byte range for a byte bucket
   * @param bucket - numeric value
   */
  getByteRange(bucket: number): string {
    if (!bucket) {
      return '-';
    }
    if (bucket < 4) {
      return `0 Byte - 1 KB`;
    }
    return `${this.dataSizeConverter(
      Math.pow(10, bucket - 1)
    )} - ${this.dataSizeConverter(Math.pow(10, bucket))}`;
  }

  dataSizeConverter(
    bytes: number,
    decimals = 3,
    unitMultiple = DATA_MULTIPLES.BASE_10
  ): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const unitNames = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const unitChanges = Math.floor(Math.log(bytes) / Math.log(unitMultiple));
    return `${parseFloat(
      (bytes / Math.pow(unitMultiple, unitChanges)).toFixed(decimals || 0)
    )} ${unitNames[unitChanges < 0 ? 0 : unitChanges]}`;
  }

  getDataMultiplesBasedOnUnit(unit: string) {
    switch (unit.toUpperCase()) {
      case 'KB':
        return DATA_MULTIPLES.BASE_10;
      case 'MB':
        return Math.pow(DATA_MULTIPLES.BASE_10, 2);
      case 'GB':
        return Math.pow(DATA_MULTIPLES.BASE_10, 3);
      case 'TB':
        return Math.pow(DATA_MULTIPLES.BASE_10, 4);
      case 'PB':
        return Math.pow(DATA_MULTIPLES.BASE_10, 5);
      case 'EB':
        return Math.pow(DATA_MULTIPLES.BASE_10, 6);
      case 'ZB':
        return Math.pow(DATA_MULTIPLES.BASE_10, 7);
      case 'YB':
        return Math.pow(DATA_MULTIPLES.BASE_10, 8);
      case 'BYTES':
        return 1;
    }
  }

  convertToSIPrefix(value: number): string {
    const units = [
      'p',
      'n',
      'u',
      'm',
      '',
      'K',
      'M',
      'G',
      'T',
      'P',
      'E',
      'Z',
      'Y',
    ];
    const unitChanges = Math.floor(
      (value === 0 ? 0 : Math.log(value)) / Math.log(DATA_MULTIPLES.BASE_10)
    );
    return `${parseFloat(
      (value / Math.pow(DATA_MULTIPLES.BASE_10, unitChanges)).toFixed(3)
    )} ${units[unitChanges + 4]}`;
  }

  removeCommaFromNumber(val: any) {
    if (!val) {
      return;
    }
    return val.replace(/,/g, '') * 1;
  }

  /**
   *
   * @param pageUrl - page to reload
   * @param clearLocalStore - flag to reset local store
   */
  reloadPage(
    pageUrl: string = URL_PATHS.LOGIN,
    clearLocalStore: boolean = false
  ) {
    if (clearLocalStore) {
      this.resetLocalStore();
    }
    location.href = pageUrl;
  }

  /**
   * 1. Reads the data from local store
   * 2. Destorys the local store
   * 3. Resets data with values read from localstore in step #1
   */

  resetLocalStore() {
    const { companyName, iconPath, accType, loginURL, subscriptionType } =
      this.loginUtil.getUserState();
    // Set local store state
    const currentProductViewType = PDashLocalStoreUtil.getProductViewType();
    const requestedURL = PDashLocalStoreUtil.getRequestedURL();

    this.destroyLocalStore();

    if (subscriptionType && subscriptionType?.length > 1) {
      PDashLocalStoreUtil.updateProductViewType(currentProductViewType);
      PDashLocalStoreUtil.updateRequestedURL(requestedURL);
    }
    // set iconpath, companyname and type info
    this.loginUtil.updateUserState(
      {
        username: '',
        userId: '',
        firstname: '',
        lastname: '',
        prosimoAppStatus: '',
        team: '',
        company: companyName,
        factors: [],
        type: '',
        accType,
        loginURL,
        iconPath,
        status: '',
        accountExpiry: '',
        authType: '',
        subscriptionType: [],
        licenseStatus: '',
        engLabsEnabled: '',
      } as any,
      false
    );
  }

  destroyLocalStore() {
    this.pdashStorage.clearStore();
    this.pdashStorage.destroy();
  }
  /**
   * @param: email
   */

  extractDomainFromEmail(email: string): string {
    if (!email || !REGEX.EMAIL.test(email)) {
      return '';
    }
    const atCharIndex = email.indexOf('@');
    const fullDomain = email.substring(atCharIndex + 1);
    return fullDomain;
  }

  isProd(): boolean {
    return ['PROD', 'DEPLOYMENT'].includes(environment.type) ? true : false;
  }

  isStaging(): boolean {
    return environment.type === 'STAGING' ? true : false;
  }

  isDev(): boolean {
    return environment.type === 'DEV' ? true : false;
  }

  /**
   * Method to convert a numerica value to percentage
   * @param value
   * @param precision
   */
  convertToPercentage(value: number = 0, precision: number = 2): number {
    if (value) {
      return +this.decimalPipe.transform(
        value * 100,
        NUMERIC_ROUND_OFF.TWO_DECIMALS
      );
    }
    return value;
  }

  /**
   * Method to extract the unit - Bytes, kB, MB,etc from the value string
   * @param dataWithUnit - contains the value & unit
   */
  getDataUsageUnit(dataWithUnit: string): string {
    const dataAndUnit = dataWithUnit.split(' ').filter(Boolean);
    return dataAndUnit && dataAndUnit.length > 1 ? dataAndUnit[1].trim() : '';
  }

  /**
   * Method to get the past one week date range starting from the current time.
   */
  getDefaultTimeRange(viewType: string = VIEW_TYPES.PLAYGROUND): TimeRange {
    let currentDateTime;
    let weekBeforeDateTime;
    if (viewType === VIEW_TYPES.PLAYGROUND) {
      currentDateTime = DateUtil.getCurrentUTCMidnightTimeInMills();
      weekBeforeDateTime = DateUtil.getPastNDays(currentDateTime, 7);
    } else {
      currentDateTime = new Date().getTime();
      weekBeforeDateTime = DateUtil.getPastNDays(currentDateTime, 7);
    }

    return {
      from: weekBeforeDateTime,
      to: currentDateTime.valueOf(),
      interval: 'HOUR',
    } as TimeRange;
  }

  getHost(): string {
    return location.hostname;
  }

  getProtocol(): string {
    return location.protocol;
  }

  getPath(): string {
    return location.pathname;
  }

  getQueryParams() {
    return location.search;
  }

  hasQueryParams(url: string): boolean {
    return url && url.indexOf('?') !== -1 ? true : false;
  }

  /**
   * Method to check whether the requested pages are apps related or not
   * @returns boolean
   */
  isZTNA(): boolean {
    const path = this.getPath();
    return path?.indexOf(SUBSCRIPTION_TYPES.ZTNA) !== -1;
  }

  /**
   * Check if force routing is required. If force routing is required, route the user to
   *
   * - login page if its a team host
   * - signin page if its a non-team host
   *
   */
  hostBasedRouting(inCurrentRequestPath: string = '') {
    const host = this.getHost();
    const isTeamHost = host && host.split('.').length === 4 ? true : false;
    const pathsToSkipForcedRouting = isTeamHost
      ? UI_PATHS_TO_SKIP.TEAM
      : UI_PATHS_TO_SKIP.NON_TEAM;
    const isForcedRouting = this.isForcedRoutingRequired(
      pathsToSkipForcedRouting,
      inCurrentRequestPath
    );
    if (isForcedRouting) {
      if (isTeamHost) {
        this.loginService.fetchTeamMetaInfo();
        PDashLocalStoreUtil.updateRequestedURL(this.getPath());
        /**
         * If its a team host then route the user to main dashboard,
         * which in-turn will validate user token
         */
        this.routeToHost();
      } else {
        this.routeToNonTeamHost();
      }
    }
  }

  /**
   * Route to non team host path based on path
   */
  routeToNonTeamHost() {
    const path = this.getPath();
    const queryParams = this.getQueryParams();
    let signInPath = CONSTANTS.UI_PATHS.SIGNIN.ROOT;
    if (path && path === CONSTANTS.UI_PATHS.SP_LOGIN && queryParams) {
      signInPath = `${signInPath}/${ObjectUtil.encodeObject({ queryParams })}`;
    } else if (path && path.indexOf(CONSTANTS.UI_PATHS.SIGNIN.ROOT) !== -1) {
      signInPath = path;
    }
    this.router.navigateByUrl(signInPath);
  }

  /**
   * Route to page based on the path
   */
  routeToHost() {
    if (
      this.isRoutingPathDependOnProsimoAppStatus() &&
      this.isValidTeamUser()
    ) {
      this.routeBasedOnProsimoAppStatus();
    } else {
      this.routeToPath();
    }
  }

  /**
   * Route based on prosimo app status
   */
  routeBasedOnProsimoAppStatus() {
    this.accountService
      .getCloudsAndIDPsConfig()
      .pipe(take(1))
      .subscribe(
        (configData) => {
          if (this.isProsimoApp(configData)) {
            // route to day zero
            this.routeToDayZero();
          } else {
            // route to normal path
            this.routeToPath();
          }
        },
        (error) => {
          console.warn('Failed to load prosimo app status', error);
        }
      );
  }

  /**
   * Return true if the path does not include in independent paths, else return false
   * @returns
   */
  isRoutingPathDependOnProsimoAppStatus(): boolean {
    const path = this.getPath();
    const independentPaths = [URL_PATHS.ACCOUNT_EXPIRED, URL_PATHS.LOGIN];
    return independentPaths.includes(path) ? false : true;
  }

  routeToDayZero() {
    this.router.navigate([CONSTANTS.MANAGEMENT.UI_PATH_CLOUD], {
      state: { appType: APP_TYPES.PROSIMO },
    });
    const day0LocalStore = {
      isStepOneDone: false,
      isStepTwoDone: false,
    } as Day0;
    this.updateDay0Details(day0LocalStore);
  }

  isProsimoApp(configData: any = {}): boolean {
    const notAllowedSet = new Set<string>([
      APP_SETUP_STATUS.UNAVAILABLE,
      APP_SETUP_STATUS.CONFIGURING,
    ]);
    const prosimoAppStatus = this.loginUtil.dayZeroConfigStatus(configData);
    return notAllowedSet.has(prosimoAppStatus);
  }

  /**
   * update prosimo app status using a loginUtil service's function
   * @param status
   */
  updateProsimoAppStatus(status: string) {
    if (status) {
      this.loginUtil.updateDayZeroStatus(status);
    }
  }

  /**
   * Route to url path or dashboard
   */
  routeToPath() {
    let path = this.getPath();
    const queryParams = this.getQueryParams();
    if (!!queryParams && queryParams?.trim() !== '') {
      path = path?.concat(queryParams);
    }
    if (!path || path === '/') {
      const url = this.loginUtil.isMSPAdmin()
        ? URL_PATHS.MSP.DASHBOARD
        : this.loginUtil.isProsimoAdmin()
        ? URL_PATHS.ADMIN.DASHBOARD
        : CONSTANTS.UI_PATHS.MAIN_DASHBOARD;
      this.router.navigateByUrl(url);
    } else {
      this.router.navigateByUrl(path);
    }
  }

  /**
   *
   * @param uiPathsToSkip
   * @param inCurrentRequestPath
   * @returns
   */

  isForcedRoutingRequired(
    uiPathsToSkip: Array<string>,
    inCurrentRequestPath: string = ''
  ): boolean {
    const currentRequestPath = inCurrentRequestPath
      ? inCurrentRequestPath.split('/')
      : this.getPath().split('/');
    const currentRequestPathMatchingPathsToSkipTeamValidation =
      currentRequestPath.find((path) => uiPathsToSkip.includes(path));

    return !currentRequestPathMatchingPathsToSkipTeamValidation ? true : false;
  }

  routeTo(routeURL: string = URL_PATHS.LOGIN) {
    this.router.navigateByUrl(routeURL);
  }

  /**
   * log out the user and redirect to login page
   */
  logout() {
    const { loginURL } = this.getTeamMetaInfo();
    this.headerService
      .logout()
      .pipe(take(1))
      .subscribe((data) => {
        this.loginUtil.deleteCookie(`${this.loginUtil.getUserTokenName()}=''`);
        if (this.loginUtil.isMSPAdmin()) {
          // delete general token if MSP user logout
          const domain = this.loginUtil.getGeneralDomain();
          this.loginUtil.deleteCookie(
            `${this.loginUtil.getUserGeneralTokenName()}=''`,
            domain
          );
        }
        // method to reset user identity
        this.resetIdentity();
        this.reloadPage(loginURL, true);
      });
  }

  getCookie(name: string): string {
    if (!name) {
      return;
    }
    return document.cookie
      .split('; ')
      .find((row) => row.startsWith(`${name}=`))
      ?.split('=')[1];
  }

  /**
   * toggle the button state
   * @param event: button event
   * @param state: true for disabled and false for enabled
   */
  toggleBtnDisabledState(event: any, state: boolean) {
    const eventBtn = event && event.target;
    if (eventBtn) {
      eventBtn.disabled = state;
    }
  }

  getTooltipParentContainerDims(): any {
    return document
      .querySelector('.dashboard__contents')
      ?.getBoundingClientRect();
  }

  /* helper functions - end*/

  /* phone number country dropdown - start*/

  // to format phone number (123)456-7890 => 1234567890
  replaceNonNumericCharFromString(phoneNumber: string) {
    return phoneNumber ? phoneNumber?.replace(/[^\d\+]/g, '') : phoneNumber;
  }

  // to format phone number 1234567890 => (123)456-7890
  formatPhoneNumber(phoneNumber: string) {
    if (!phoneNumber) {
      return;
    } else {
      let formatnumber = '';
      const input = phoneNumber.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
      const zip = phoneNumber.substring(0, 3);
      const middle = phoneNumber.substring(3, 6);
      const last = phoneNumber.substring(6, 10);
      if (input.length > 6) {
        formatnumber = `(${zip}) ${middle} - ${last}`;
      } else if (input.length > 3) {
        formatnumber = `(${zip}) ${middle}`;
      } else if (input.length > 0) {
        formatnumber = `(${zip}`;
      }
      return formatnumber;
    }
  }

  // to construct phone model to send server side
  constructPhoneModel(countryConfig: any, phoneNumber: string) {
    if (countryConfig) {
      const {
        country = null,
        isoCode = null,
        countryCode = null,
      } = countryConfig;
      return {
        country,
        isoCode,
        countryCode,
        number: this.replaceNonNumericCharFromString(phoneNumber),
      };
    } else {
      return null;
    }
  }

  /* phone number country dropdown - end*/

  getDecodedFilterConfig(routeParams: any) {
    if (!routeParams) {
      return false;
    }

    try {
      return ObjectUtil.decodeObject(routeParams);
    } catch (error) {
      return false;
    }
  }

  /**
   *  Update the isInteractiveGuide store
   * @param isActive
   */
  setIsInteractiveGuideActive(isActive: boolean) {
    PDashLocalStoreUtil.updateIsInteractiveTourActive(isActive);
  }
  /**
   * This function will return true/false if interactive guide  active or not
   * @returns isInteractiveTourActive
   */
  getIsInteractiveGuideActive(): boolean {
    return PDashLocalStoreUtil.getIsInteractiveTourActive();
  }

  /* ubiService - start  */

  setAllowedEnvListToTrackUserBehavior(envs: Array<string>) {
    this.ubiService.setAllowedEnv(envs);
  }

  getAllowedEnvList(): Array<string> {
    return this.ubiService.getAllowedEnv();
  }

  setSelectedEnv(env: string) {
    this.ubiService.setSelectedEnv(env);
  }

  getSelectedEnv(): string {
    return this.ubiService.getSelectedEnv();
  }

  isUserBehaviorTrackingAllowed(): boolean {
    return this.ubiService
      .getAllowedEnv()
      .includes(this.ubiService.getSelectedEnv());
  }

  initUserBehaviorInsights() {
    if (!this.isUserBehaviorTrackingAllowed()) {
      return;
    }
    const userBehaviorInsightsAppId = USER_BEHAVIOR_INSIGHTS_APP_ID;
    this.ubiService.appid = userBehaviorInsightsAppId;
    this.ubiService.load(userBehaviorInsightsAppId, {
      secureCookie: true,
    });
  }

  setUserInfo(userInfo: UserBehaviorInsightsUserInfo) {
    this.ubiService.addUserProperties(userInfo);
  }

  setUniqueIdentifier(uniqueUserId: string) {
    this.ubiService.identity = uniqueUserId;
    this.ubiService.identify(uniqueUserId);
  }

  setCustomEventInfo(customEvent: UserBehaviorInsightsCustomEventInfo) {
    this.ubiService.track(customEvent?.name, customEvent?.metaInfo);
  }

  resetIdentity() {
    this.ubiService.resetIdentity();
  }

  isPathNeedsToBeSkipped() {
    const path = this.getPath();
    const pathToCheck = path.split('/')?.[1] || '';
    const ignorePathsSet = new Set(UI_PATHS_TO_SKIP.TEAM);
    return ignorePathsSet.has(pathToCheck);
  }

  /* ubiService - end  */
  /**
   * Return the userAgent info of the browser navigator object
   * @returns
   */
  getNavigatorUserAgentInfo(): string {
    return navigator?.userAgent;
  }

  /**
   *
   * @param path URL where we wish to navigate
   * @param type type of menu
   * @param tab tab name
   * @param subMenu sub menu name
   * @param useDashboardTimeRange used to display time range filter on dashboard
   */
  routeToDashboardByPath(
    url: string,
    type: string,
    tab: string,
    subMenu: string,
    useDashboardTimeRange: boolean = false
  ) {
    if (type && tab) {
      this.updateActiveTabName(tab, type);
      if (subMenu) {
        this.updateActiveSubMenuName(subMenu, type);
      }
    }
    this.router.navigateByUrl(url, {
      state: { mainDashboardTimeRange: useDashboardTimeRange },
    });
  }

  getPDFTimeRange(store) {
    let selectedTimeRange: TimeRange = store?.['timeRange'];
    if (!selectedTimeRange || ObjectUtil.hasKeys(selectedTimeRange)) {
      selectedTimeRange = {
        from: DateUtil.getPastNDays(Date.now(), 3),
        to: Date.now(),
      };
    } else {
      const numberOfDays =
        (new Date(selectedTimeRange.to).getTime() -
          new Date(selectedTimeRange.from).getTime()) /
        CHART_CONSTANTS.DAY_IN_MILLI_SECONDS;
      if (numberOfDays > 3) {
        selectedTimeRange.from = DateUtil.getPastNDays(selectedTimeRange.to, 3);
      }
    }
    return selectedTimeRange;
  }

  /**
   * Pdf generation
   */
  generatePDF(
    pdfDownloadInfo: PdfDownload,
    showNotification: boolean = true,
    storeData?: any
  ) {
    let store;
    if (storeData) {
      store = storeData;
    } else {
      store = JSON.parse(localStorage.getItem('pdash-store'));
      store.pdash.isPDFPrintView = true;
      delete store.$cache;
    }

    let selectedTimeRange: TimeRange;
    if (PDashLocalStoreUtil.getProductViewType() === SUBSCRIPTION_TYPES.MCN) {
      selectedTimeRange = this.getPDFTimeRange(store['mcn-insights-filters']);
      if (store['mcn-insights-filters']?.['timeRange']) {
        store['mcn-insights-filters']['timeRange'] = selectedTimeRange;
      }
      if (store['mcn-insights-filters']?.['insightTimeRange']) {
        store['mcn-insights-filters']['insightTimeRange'] = selectedTimeRange;
      }
      if (store['mcn-insights-filters']) {
        store['mcn-insights-filters']['selectedTimeRangeType'] = 'CUSTOM';
      }
    } else {
      const selectedTimeRange: TimeRange = this.getPDFTimeRange(
        store['ztna-insights-filters']
      );
      if (store['ztna-insights-filters']?.['timeRange']) {
        store['ztna-insights-filters']['timeRange'] = selectedTimeRange;
      }
      if (store['ztna-insights-filters']?.['insightTimeRange']) {
        store['ztna-insights-filters']['insightTimeRange'] = selectedTimeRange;
      }
      if (store['ztna-insights-filters']) {
        store['ztna-insights-filters']['selectedTimeRangeType'] = 'CUSTOM';
      }
    }
    if (store['log-search-filter']?.['logSearchTimeRange']) {
      store['log-search-filter']['logSearchTimeRange'] = selectedTimeRange;
    }

    if (pdfDownloadInfo?.pathURL) {
      const data = {
        parameter: {
          storeName: 'pdash-store',
          storeKey: pdfDownloadInfo?.currentPage,
          storeValue: JSON.stringify(store),
          path: pdfDownloadInfo?.pathURL,
          tokenName: PROSIMO_API_TOKEN_LABEL,
          domain: pdfDownloadInfo?.domain,
        },
      };
      this.headerService
        .createData(CONSTANTS.GENERATE_REPORT, data)
        .pipe(take(1))
        .subscribe(
          (response) => {
            const { id } = response;
            this.startPollingForPDF(
              id,
              pdfDownloadInfo?.isPrintPolling,
              showNotification
            );
            this.pdfService.setPDFStatus(PDF_STATUS.IN_PROGRESS);
            if (showNotification) {
              this.setNotificationMessage(
                `<p class="px-2"> ${PDF_PRINT.PROGRESS} </p>`,
                DIALOG_ACTION_TYPES.SUCCESS,
                2000
              );
            }
          },
          (error) => {
            console.warn(PDF_PRINT.PRINT_FAILED, error);
            // PDashLocalStoreUtil.updateIsPDFPrintView(false);
            this.pdfService.setPDFStatus(PDF_STATUS.FAILED);
            if (showNotification) {
              this.setNotificationMessage(
                PDF_PRINT.GENERATION_FAILED,
                DIALOG_ACTION_TYPES.ERROR
              );
            }
          }
        );
    }
  }

  /**
   * Start the polling to download the pdf which is related taskID
   * @param reportIDreportID
   * @param isPrintPolling
   */
  startPollingForPDF(
    reportID: string,
    isPrintPolling,
    showNotification: boolean = true
  ) {
    if (reportID && !isPrintPolling.getValue()) {
      isPrintPolling.next(true);
      this.headerService
        .getPDFDownloadProgress(CONSTANTS.REPORT_STATUS(reportID))
        .subscribe(
          ({ statusCode }) => {
            if (statusCode !== HTTP_RESPONSE_STATUS_CODES.IN_PROGRESS) {
              // stop the polling when the report generating is not in progress
              this.headerService.setStopPolling();
              isPrintPolling.next(false);
              // PDashLocalStoreUtil.updateIsPDFPrintView(false);
              if (statusCode === HTTP_RESPONSE_STATUS_CODES.FAILED) {
                this.pdfService.setPDFStatus(PDF_STATUS.FAILED);
                if (showNotification) {
                  this.setNotificationMessage(
                    PDF_PRINT.GENERATION_FAILED,
                    DIALOG_ACTION_TYPES.ERROR
                  );
                }
              } else {
                this.pdfService.setPDFStatus(PDF_STATUS.SUCCESS);
                const path = CONSTANTS.DOWNLOAD_REPORT(reportID);
                const url = `${location.origin}/${path}`;
                this.openNewWindow(url);
              }
            }
          },
          (error) => {
            isPrintPolling.next(false);
            // PDashLocalStoreUtil.updateIsPDFPrintView(false);
            this.setNotificationMessage(
              PDF_PRINT.GENERATION_FAILED,
              DIALOG_ACTION_TYPES.ERROR
            );
          }
        );
    }
  }

  /**
   * predict the filter value based on the display columns
   * @param dataSource
   * @param displayedColumns
   */
  dataSourceFilterPredictFn(
    dataSource: MatTableDataSource<any>,
    displayedColumns: Array<string>
  ): void {
    if (!!dataSource && displayedColumns && !!displayedColumns?.length) {
      dataSource.filterPredicate = (data, filter) => {
        const currentData = this.clone(data);
        return displayedColumns?.some((column) => {
          if (
            typeof currentData[column] === 'object' &&
            column === TABLE_CONSTANTS.TOOLTIPCOLUMN
          ) {
            currentData[column].value = String(currentData[column]?.value);
            return (
              currentData?.[column]?.value
                ?.trim()
                ?.toLowerCase()
                ?.indexOf(filter) !== -1
            );
          } else {
            currentData[column] = String(currentData[column]);
            return (
              currentData?.[column]?.trim()?.toLowerCase()?.indexOf(filter) !==
              -1
            );
          }
        });
      };
    }
  }

  /**
   * @param date
   * @returns This method calculate the time diffenece between given date and current date
   *  and returns in minutes, hours or in days.
   */
  getTimeDifference(date: Date) {
    if (date) {
      const dateTo = new Date(date);
      const currentDate = new Date();
      let minutes = (dateTo?.getTime() - currentDate?.getTime()) / 1000;
      minutes = Math.abs(Math.round(minutes / 60));
      if (minutes <= 60) {
        return `${minutes} minutes ago`;
      } else {
        let hour = (dateTo?.getTime() - currentDate?.getTime()) / 1000;
        hour = Math.abs(Math.round(hour / (60 * 60)));
        if (hour <= 24) {
          return `${hour} hours ago`;
        } else {
          let days = (dateTo?.getTime() - currentDate?.getTime()) / 1000;
          days = Math.abs(Math.round(days / (60 * 60 * 60)));
          return `${days} days ago`;
        }
      }
    }
  }

  setIsActiveTab(isActive: boolean) {
    this.tabService.setActiveTab(isActive);
  }

  getIsActiveTab() {
    return this.tabService.getActiveTab();
  }

  /**
   * Start interactive guide manully
   */
  autoStartInteractiveGuide(
    feature: string,
    tourType: string = PRODUCT_TOUR_TYPES.AUTO
  ) {
    if (this.getIsInteractiveGuideActive()) {
      this.interactiveGuideService.setInteractiveGuideConfig({
        steps: INTERACTIVE_GUIDE_CONFIGS[feature]?.[tourType],
      });
    }
  }

  setHeaderDescription(description: HeaderDescription) {
    this.headerService.setHeaderDescription(description);
  }

  updateActiveAppsDashboardNav(activeNavigation: string) {
    appsDashboardStoreService.updateCurrentNav(activeNavigation);
  }

  updateDiscoveredViewActiveTabDetails(activeTabDetails: IActiveTabDetails) {
    appsDashboardStoreService.updateCurrentTabDetails(activeTabDetails);
  }

  updateMCNDiscoveredViewActiveTabDetails(
    mcnActiveTabDetails: IActiveTabDetails
  ) {
    appsDashboardStoreService.updateCurrentMCNTabDetails(mcnActiveTabDetails);
  }

  updateActiveSearchTerm(activeSearchTerm: string) {
    appsDashboardStoreService.updateActiveSearchTerm(activeSearchTerm);
  }

  updateActiveLegends(activelegends: Array<LegendData>) {
    appsDashboardStoreService.updateActiveLegends(activelegends);
  }

  //======

  updateNetworksDiscoveredViewActiveTabDetails(
    activeTabDetails: IActiveTabDetails
  ) {
    networksDashboardStoreService.updateCurrentTabDetails(activeTabDetails);
  }

  updateNetworksActiveLegends(activelegends: Array<LegendData>) {
    networksDashboardStoreService.updateActiveLegends(activelegends);
  }

  updateSelectedHop(selectedHop: CloudTracerComp) {
    cloudTracerService.updateSelectedHop(selectedHop);
  }

  updateSelectedCloudTraceRecord(
    selectedTraceRecord: CloudTracerHistoryRecord
  ) {
    cloudTracerService.updateSelectedCloudTraceRecord(selectedTraceRecord);
  }

  updateCloudTracerResults(cloudTracerResults: CloudTracer) {
    cloudTracerService.updateCloudTracerResults(cloudTracerResults);
  }

  updateConfigRefreshTime(configRefreshTime: string) {
    cloudTracerService.updateConfigRefreshTime(configRefreshTime);
  }

  updateSelectedTasksToStore(selectedTasks: Array<SelectionObjectWithArray>) {
    tasksService.updateSelectedTasks(selectedTasks);
  }

  updateSelectedAlertsToStore(selectedAlerts: Array<SelectionObjectWithArray>) {
    alertsService.updateSelectedAlerts(selectedAlerts);
  }

  // Page Footer
  updatePageFooterConfig(pageFooterConfig?: IPageFooterConfig) {
    this.pageFooterService.setPageFooterConfig(pageFooterConfig);
  }

  getPageFooterResponse(): Observable<FooterBtnCode> {
    return this.pageFooterService.getFooterResponse();
  }

  updateOverviewConfig(overviewConfig: any) {
    this.overviewService.setOverviewConfig(overviewConfig);
  }

  getOverviewResponse(): Observable<any> {
    return this.overviewService.getOverviewConfig();
  }

  setPageFooterBtnConfig(btnConfig: ViewEditBtnConfig) {
    this.pageFooterService.setFooterBtnConfig(btnConfig);
  }

  setPageFooterAdditionalBtnConfig(btnConfig: IFooterBtn) {
    this.pageFooterService.setFooterAdditionalBtnConfig(btnConfig);
  }

  setPageFooterPaginationConfig(paginationConfig: paginationConfig) {
    this.pageFooterService.setPaginationConfig(paginationConfig);
  }

  getPaginatorResponse(): Observable<PaginatorEventData> {
    return this.pageFooterService.getPaginatorResponse();
  }

  getDrawer(): MatDrawer {
    return this.drawerService?.getDrawer();
  }

  setDrawerState(state: boolean) {
    this.drawerService.setDrawerState(state);
  }

  getDrawerState(): Observable<boolean> {
    return this.drawerService.getDrawerState();
  }

  setDrawerFormData(formData: unknown) {
    this.drawerService.setDrawerFormData(formData);
  }

  setDrawerDynaCompConfig(config: DrawerCompConfig) {
    this.drawerService.setDynaCompConfig(config);
  }

  getDrawerFormData(): Observable<unknown> {
    return this.drawerService.getDrawerFormData();
  }

  getDrawerResponse(): Observable<IBtnConfig> {
    return this.drawerService.getDrawerResponse();
  }

  setDynamicDrawerResponse<T, U, V>(response?: DrawerResponsePayload<T, U, V>) {
    this.drawerService.setDynamicDrawerResponse(response);
  }

  getDynamicDrawerResponse<T, U, V>(): Observable<
    DrawerResponsePayload<T, U, V>
  > {
    return this.drawerService.getDynamicDrawerResponse();
  }

  updateDrawerButtonConfig(config: IDrawerTemplateConfig): void {
    this.drawerService.updateDrawerButtonConfig(config);
  }

  getBackdropEvent(): Observable<boolean> {
    return this.drawerService.getBackdropEvent();
  }

  updateBackdropEvent(action: boolean) {
    this.drawerService.updateBackdropEvent(action);
  }

  /**
   * reset the drawer style configuration
   * @returns
   */
  resetStyleConfiguration(): void {
    this.drawerService?.resetStyleConfiguration();
  }

  /** Set breadcrumb config.
   *   @Param breadcrumbConfig : Array of Breadcrumb config objects. Breadcrumb config includes breadcrumb label and router link details.
   */
  setBreadcrumbConfig(breadcrumbConfig: BreadcrumbConfig[]) {
    this.breadcrumbService?.setBreadcrumbConfig(breadcrumbConfig);
  }

  /** Get breadcrumb details after click.
   *   @Returns breadcrumbResponse : Includes breadcrumb label and router link details.
   */
  getBreadcrumbClickEvent(): Observable<BreadcrumbConfig> {
    return this.breadcrumbService?.getBreadcrumbClickEvent();
  }

  /** Set conditions config.
   *   @Param conditionConfig : Array of condition config objects. Condition config includes condition label and displayLabel details.
   */
  setConditionConfig(conditionConfig: ConditionConfig[]) {
    this.conditionsPanelService?.setConditionConfig(conditionConfig);
  }

  getConditionConfig(): Observable<ConditionConfig[]> {
    return this.conditionsPanelService?.getConditionConfig();
  }

  /**
   * set condition panel heading
   * @param heading
   */
  setConditionHeading(heading: string) {
    this.conditionsPanelService?.setConditionHeading(heading);
  }

  /** Get condition details after click.
   *   @Returns conditionResponse : Includes condition label and displayLabel details.
   */
  getConditionClickEvent(): Observable<ConditionConfig> {
    return this.conditionsPanelService?.getConditionClickEvent();
  }

  /** Get output values from multi-value-input component after button click.
   *   @Returns output values : Includes existing values and new values.
   */
  getValuesFromMultiInput(): Observable<string[]> {
    return this.multiValueInputService?.getNewValues();
  }

  /** Set validationErrors.
   *   @Param validationErrors : a flag that shows if its valid and if not then validation message.
   */
  setValidationsToMultiInput(validationErrors: ValidationState) {
    this.multiValueInputService?.setValidationState(validationErrors);
  }

  isMasterSelectAllRecords(records: Array<any>, filterType: string) {
    switch (filterType) {
      case FILTER_TYPES.SRC_REGIONS:
      case FILTER_TYPES.TGT_REGIONS:
        if (records?.length === 1) {
          return records[0]?.name === ALL;
        }
        return false;
      case FILTER_TYPES.APP:
      case FILTER_TYPES.APP_GROUP:
        if (records?.length === 1) {
          const config = records[0];
          return config?.id === ALL && config?.appName === ALL;
        }
        return false;
      default:
        if (records?.length === 1) {
          const config = records[0];
          return config?.id === ALL && config?.name === ALL;
        }
        return false;
    }
  }

  setDeleteWarningConfig(body) {
    this._ConfigTableViewService.setDeleteWarningConfig(body);
  }

  /** Display tooltip for mat-options if required(for content having ellipses).
   *  @Param tooltip : Includes the matTooltip details.
   *  @Param matCell : Includes the cell details.
   *  @Param isMatOption : specifies mat-option
   */
  showTooltipForMatOption(tooltip, matCell, isMatOption: boolean = false) {
    if (isMatOption) {
      const nativeElement = matCell?._element?.nativeElement;
      matCell = nativeElement?.getElementsByClassName(MAT_OPTION_CLASSNAME)[0];
    }

    if (matCell?.scrollWidth <= matCell?.clientWidth) {
      tooltip.disabled = true;
    } else {
      tooltip.disabled = false;
    }
  }

  /** Display tooltip in the if required for mat-select.
   *  @Param tooltip : Includes the matTooltip details.
   *  @Param sourceInput : Includes the input details.
   *  @Param matSelect(optional param) : Includes reference of mat-select.
   *  @Param isSelect : if true then refered as mat-select
   */
  showTooltipForInput(
    tooltip,
    sourceInput,
    matSelect?,
    isSelect: boolean = false
  ) {
    if (matSelect) {
      matSelect = (matSelect as MatSelect)?._elementRef?.nativeElement;
    }

    if (isSelect) {
      if (sourceInput?.offsetWidth <= matSelect?.clientWidth) {
        tooltip.disabled = true;
      } else {
        tooltip.disabled = false;
      }
    } else {
      if (sourceInput?.scrollWidth <= sourceInput?.clientWidth) {
        tooltip.disabled = true;
      } else {
        tooltip.disabled = false;
      }
    }
  }

  /**
   * update isDisplayFooter$ based on the input state
   * @param state
   */
  setPageFooterState(state: boolean): void {
    this.isDisplayFooter$?.next(state);
  }

  /**
   *
   * @returns isDisplayFooter$ as Observable
   */
  getPageFooterState(): Observable<boolean> {
    return this.isDisplayFooter$?.asObservable();
  }

  /**
   * set root element
   * @param ele
   */
  setRootElement(ele: ElementRef): void {
    this.rootElement = ele;
  }

  /**
   * return the set root element
   * @returns
   */
  getRootElement(): ElementRef {
    return this.rootElement;
  }

  /**
   * create a injector
   * @param dialogData
   * @returns
   */
  createInjector<T>(providerName: string, dialogData: T): Injector {
    let portalInjector: Injector = null;

    switch (providerName) {
      case DIALOG_TOKEN.INJECTOR_TOKEN_DATA.DIALOG_DATA_TOKEN.NAME:
        portalInjector = Injector.create({
          providers: [
            {
              provide:
                DIALOG_TOKEN.INJECTOR_TOKEN_DATA.DIALOG_DATA_TOKEN.INJECTOR,
              useValue: dialogData,
            },
          ],
        });
        break;

      case DIALOG_TOKEN.INJECTOR_TOKEN_DATA.WARNING_DIALOG_DATA_TOKEN.NAME:
        portalInjector = Injector.create({
          providers: [
            {
              provide:
                DIALOG_TOKEN.INJECTOR_TOKEN_DATA.WARNING_DIALOG_DATA_TOKEN
                  .INJECTOR,
              useValue: dialogData,
            },
          ],
        });
        break;

      default:
        portalInjector = Injector.create({
          providers: [
            {
              provide: DIALOG_DATA_TOKEN,
              useValue: dialogData,
            },
          ],
        });
        break;
    }

    return portalInjector;
  }

  /** design function for error message display using delete dialog
   * @param component
   * @param miscellaneous
   * @param data
   */
  deleteDialogFailedResult<T>(
    component: ComponentType<T>,
    miscellaneous: {
      panelClass?: string | string[];
      width?: string;
      backDropClass?: string | string[];
    },
    data: {
      errorTitle?: string;
      errorMsg?: string;
      deleteMsg?: string;
      deleteName?: string;
    }
  ) {
    const enterAnimationDuration = '500ms';
    const exitAnimationDuration = '500ms';

    this.dialog.open(component, {
      width: miscellaneous?.width,
      disableClose: true,
      enterAnimationDuration,
      exitAnimationDuration,
      backdropClass: 'backdrop-blur',
      minWidth: '234px',
      panelClass: miscellaneous?.panelClass,
      data,
    });
  }

  /**
   * update the detail section width
   * @param event
   * @param detailViewContainer
   * @param tableContainer
   */
  updateDetailSectionDimentions(
    event: { width: number; height: number },
    detailViewContainer: HTMLElement,
    tableContainer: HTMLElement,
    parentContainer: HTMLElement,
    miscellaneous?: {
      detailViewContainerWidth?: number;
      isReset?: boolean;
    }
  ): void {
    this.detailViewContainer = detailViewContainer;
    this.tableContainer = tableContainer;
    this.parentContainer = parentContainer;

    if (
      this.tableContainer &&
      this.detailViewContainer &&
      this.parentContainer
    ) {
      if (miscellaneous?.isReset) {
        (this.detailViewContainer as HTMLElement).style.width = `60%`;
        (this.detailViewContainer as HTMLElement).style.minWidth = 'unset';

        (this.tableContainer as HTMLElement).style.width = '40%';
        (this.tableContainer as HTMLElement).style.minWidth = 'unset';
      } else {
        const parentContainerWidth =
          this.parentContainer?.getClientRects()[0]?.width;

        const remainingWidth = parentContainerWidth - event?.width;

        if (
          remainingWidth > 400 &&
          event?.width > (miscellaneous?.detailViewContainerWidth || 698)
        ) {
          (this.detailViewContainer as HTMLElement).style.minWidth = `${
            event?.width - 30
          }px`;
          (
            this.tableContainer as HTMLElement
          ).style.minWidth = `${remainingWidth}px`;
        }
      }
    }
  }

  /**
   * Vertical split and resizing
   * @param event
   * @param resizeElement
   */
  verticalResizer(event, resizeElement: HTMLElement) {
    let dragX = event?.clientY;
    document.onmousemove = function onMouseMove(e) {
      // e.clientY will be the position of the mouse as it has moved a bit now
      // offsetHeight is the height of the resizeElement
      resizeElement.style.height =
        resizeElement?.offsetHeight + e?.clientY - dragX + 'px';
      // update variable - till this pos, mouse movement has been handled
      dragX = e?.clientY;
    };
    // remove mouse-move listener on mouse-up (drag is finished now)
    document.onmouseup = () =>
      (document.onmousemove = document.onmouseup = null);
  }

  /**
   * Open delete dialog positioned center w.r.t Drawer
   * initializing delete dialog with overlay
   * @param dialogData
   * @param uiThemeMode
   * @param width
   * @returns
   */
  initializeDeleteOverlay<T>(
    component: ComponentType<T>,
    portalInjector: Injector,
    uiThemeMode: string | string[],
    width: string,
    sourceComponent?: ElementRef
  ): ComponentRef<T> {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(
        sourceComponent || (this.getDrawer() as any)?._elementRef
      )
      ?.withPositions([
        {
          originX: 'center',
          originY: 'center',
          overlayX: 'center',
          overlayY: 'center',
        },
      ]);

    const panelClass = Array?.isArray(uiThemeMode)
      ? uiThemeMode.map((mode, index) => {
          if (!index) {
            return DELETE_DIALOG + `${mode}`;
          } else {
            return mode;
          }
        })
      : uiThemeMode?.includes(DELETE_DIALOG)
      ? uiThemeMode
      : DELETE_DIALOG + `${uiThemeMode}`;

    const overLayConfig = new OverlayConfig({
      panelClass,
      positionStrategy,
      hasBackdrop: true,
      width: width,
    });

    this.overlayRef = this.overlay?.create(overLayConfig);
    const deleteOverlayDialog = new ComponentPortal(
      component,
      null,
      portalInjector
    );

    return this.overlayRef?.attach(deleteOverlayDialog);
  }

  /**
   * reseting the html element
   */
  resetHTMLElement(): void {
    this.detailViewContainer = null;
    this.tableContainer = null;
    this.parentContainer = null;
  }

  /**
   * destroying the existing overlay
   */
  destroyOverlay(): void {
    this.overlayRef?.detach();
  }

  /**
   * open the delete dialog with failed result
   * @param miscellaneous
   * @param data
   */
  openDeleteDialogFailedResult(
    miscellaneous: {
      panelClass?: string | string[];
      width?: string;
      backDropClass?: string | string[];
    },
    data: {
      errorTitle?: string;
      errorMsg?: string;
      deleteMsg?: string;
      deleteName?: string;
      isHideDeleteButton?: boolean;
    }
  ): void {
    this.deleteDialogFailedResult(DeleteDialogComponent, miscellaneous, data);
  }

  /**
   * initialize the polling with the configuration
   * @param statusConfiguration
   * @param pollingConfiguration
   * @returns
   */
  initializePolling<T>(_config: PollingModel<T>): Observable<any> {
    this.setStopPolling();
    if (
      this.verifyAllStatusDeployed(_config?.statusConfiguration) &&
      !this.stopPolling$
    ) {
      return this.startPolling(_config?.pollingConfiguration)?.pipe(
        tap((data) => {
          _config?.updateLatestData(data?.records);
          if (
            !this.verifyAllStatusDeployed({
              data: data?.records,
              checkedValue: _config?.statusConfiguration?.checkedValue,
              keyName: _config?.statusConfiguration?.keyName,
            })
          ) {
            this.setStopPolling();
          }
        })
      );
    } else {
      return of();
    }
  }

  /**
   * this function checks whether all status policy is deployed or not
   * @param serviceData
   */
  verifyAllStatusDeployed<T>(
    statusConfiguration: IStatusConfiguration<T>
  ): boolean {
    if (!statusConfiguration?.data?.length) {
      return false;
    }

    return statusConfiguration?.data?.some((data) => {
      let length = statusConfiguration?.checkedValue
        ?.filter((cv) => data?.[statusConfiguration?.keyName] === cv)
        ?.filter((v) => Boolean(v))?.length;
      return !!length;
    });
  }

  /**
   * start the polling with configuration
   * @param configuration
   * @returns
   */
  startPolling(configuration: IPollingConfiguration): Observable<{
    data: {
      records: any;
      totalCount: number;
    };
    message: string;
    statusCode: string;
  }> {
    this.stopPolling$ = new Subject();

    return timer(1, configuration?.timer || 5000)?.pipe(
      mergeMap(() => configuration?.apiFunction()),
      takeUntil(this.stopPolling$)
    );
  }

  /**
   * stopPolling subject mark as complete
   */
  setStopPolling(): void {
    if (this.stopPolling$) {
      this.stopPolling$?.next(true);
      this.stopPolling$?.complete();
      this.stopPolling$ = null;
    }
  }

  overscrollBehaviorXNone(): void {
    document.body.style.overscrollBehaviorX = 'none';
    document.documentElement.style.overscrollBehaviorX = 'none';
  }

  overscrollBehaviorXAuto(): void {
    document.body.style.overscrollBehaviorX = 'auto';
    document.documentElement.style.overscrollBehaviorX = 'auto';
  }

  static isN2N(sourceType: string, targetType: string): boolean {
    return sourceType === 'network' && targetType === 'network';
  }

  static isLogN2N(log: any): boolean {
    return AppUtil.isN2N(
      log.sourceType || log['Source Type'],
      log.targetType || log['Target Type']
    );
  }

  /**
   * This is to trasnform AZURE to Azure
   * Transforms the given data object or array of objects by modifying specific string values based on the provided keys.
   * The keys parameter can be a string representing a single key, an array of strings representing multiple keys,
   * or an object where each key-value pair specifies the main key and its corresponding subkey(s) to modify.
   *
   * @param data - The data array of objects to be transformed.
   * @param keys - The key(s) to identify which string values to modify. It can be a string, an array of strings,
   *              or an object where each key-value pair represents the main key and its corresponding subkey(s).
   *
   * @returns The transformed data object or array with modified string values.
   */
  transformStringToAzureFormat(
    data: any,
    keys: string | string[] | { [key: string]: string | string[] } = ''
  ) {
    const startTime = performance.now();
    let result: any;
    if (Array.isArray(data)) {
      result = data.map((item) => {
        const updatedItem: { [key: string]: any } = { ...item };
        if (Array.isArray(keys)) {
          keys.forEach((key) => {
            if (item[key]) {
              updatedItem[key] = AppUtil.modifyString(item[key]);
            }
          });
        } else if (typeof keys === 'string' && item[keys]) {
          updatedItem[keys] = AppUtil.modifyString(item[keys]);
        } else if (typeof keys === 'object') {
          Object.entries(keys).forEach(([mainKey, subKey]) => {
            if (item[mainKey] && subKey === '') {
              updatedItem[mainKey] = AppUtil.modifyString(item[mainKey]);
            } else if (
              item[mainKey] &&
              subKey !== '' &&
              !Array.isArray(subKey)
            ) {
              if (Array.isArray(item[mainKey])) {
                item[mainKey].forEach((mk, i) => {
                  updatedItem[mainKey][i][subKey] = AppUtil.modifyString(
                    item[mainKey][i][subKey]
                  );
                });
              }
            }
          });
        }
        return updatedItem;
      });
    } else if (typeof data === 'string') {
      result = AppUtil.modifyString(data);
    } else {
      result = data;
    }
    const endTime = performance.now();
    return result;
  }

  static modifyString(value: any) {
    if (typeof value === 'string') {
      return value.replace(/AZURE/gi, 'Azure');
    }
    return value;
  }

  /**
   * This returns true/false based on current product view type
   * @returns
   */
  isMCN() {
    return (
      PDashLocalStoreUtil.getProductViewType()?.toLocaleUpperCase() ===
      SUBSCRIPTION_TYPES.MCN?.toLocaleUpperCase()
    );
  }

  setURLWithEncodedFilters(filters) {
    const currentUrl = this.getPath().split('?pql')[0];
    const encodedFilters = ObjectUtil.encodeObject({
      filters,
    });
    const path = `${currentUrl}?pql=${encodedFilters}`;
    this.location.replaceState(path);
  }

  // this function always transforms date in "dd MMM yyyy, h:mm:ss a" format
  public transformDateTime(
    dateTime: string,
    format: string = DateUtil.DATE_FORMATS.FORMAT_DD_MMM_YYYY_HH_MM_SS_A
  ): string {
    return DateUtil?.getFormattedLocaleDate(dateTime, format);
  }

  filterNestedObject(obj: any, filters) {
    const filteredObj = {};
    for (let key in obj) {
      // Check if the current key is present in the filters object
      if (filters.hasOwnProperty(key) && filters[key] === true) {
        filteredObj[key] = obj[key];
      }

      // Check if the current value is an object and if the corresponding filter is an object as well
      if (
        typeof obj[key] === 'object' &&
        obj[key] !== null &&
        filters[key] &&
        typeof filters[key] === 'object'
      ) {
        filteredObj[key] = this.filterNestedObject(obj[key], filters[key]);
      }

      // Check if the current value is an array and if the corresponding filter is an object
      if (
        Array.isArray(obj[key]) &&
        filters[key] &&
        typeof filters[key] === 'object'
      ) {
        // If so, iterate over each object in the array and apply filtering
        filteredObj[key] = obj[key].map((item) =>
          this.filterNestedObject(item, filters[key])
        );
      }
    }
    return filteredObj;
  }

  getMappedConflictTooltip(matchCondition: any) {
    const conditions = matchCondition?.map(
      (obj) =>
        MATCH_CONDITIONS_KEY_VALUES?.[obj?.property] ||
        obj?.property?.toUpperCase()
    );

    return [...new Set(conditions)];
  }

  // We will get match conditions from policy object like: policyObject?.details?.matches
  hasAppMatchConditionConflict(matchConditions: any) {
    const { egressFqdns } = matchConditions;
    return {
      hasConflict: !!egressFqdns?.length,
      conflictingConditions: this.getMappedConflictTooltip(egressFqdns),
    };
  }

  // We will get match conditions from policy object like: policyObject?.details?.matches
  hasNetworksMatchConditionConflict(matchConditions: any) {
    const { advanced, fqdn, url, egressFqdns } = matchConditions;
    return {
      hasConflict:
        !!advanced?.length ||
        !!fqdn?.length ||
        !!url?.length ||
        !!egressFqdns?.length,
      conflictingConditions: [
        ...this.getMappedConflictTooltip(fqdn),
        ...this.getMappedConflictTooltip(advanced),
        ...this.getMappedConflictTooltip(url),
        ...this.getMappedConflictTooltip(egressFqdns),
      ],
    };
  }

  /**
   * Sets the external report state
   * @param state The external report state
   */
  setExternalReports(state) {
    this.externalReports$.next(state);
  }

  /**
   * Listens to external report state change events
   * @returns An Observable that emits the external report state
   */
  getExternalReports(): Observable<any> {
    return this.externalReports$.asObservable();
  }

  /**
   * To remove null from an array or object and add empty string ('')
   * @param data accept array or object
   * @returns returns same data after removing null and adding ''
   */
  removeNullFromData(data) {
    for (const [key, formValue] of Object.entries(data)) {
      if (Array.isArray(formValue)) {
        for (let condition of formValue) {
          for (const [key, value] of Object.entries(condition)) {
            condition[key] = value ? value : '';
          }
        }
      }
    }
    return data;
  }

  openHelpURL(url: string) {
    this.openNewWindow(url);
    PDashLocalStoreUtil.updateRequestedURL(url);
  }

  getUserInfoFromStore() {
    return this.loginService.getUserInfoFromStore();
  }
}
