import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { PDashLocalStoreUtil } from '@app/state/web-pdash.util';
import { Subscription } from 'rxjs';
import { IRuleListPaneConfig } from './rule-list-read-only.model';
@Component({
  selector: 'app-rule-list-read-only',
  templateUrl: './rule-list-read-only.component.html',
  styleUrls: ['./rule-list-read-only.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RuleListReadOnlyComponent implements OnInit, OnDestroy {
  @Input() ruleListPaneDetails: IRuleListPaneConfig = null;

  subscription: Subscription;
  uiThemeMode: string = PDashLocalStoreUtil?.getUIThemeMode()?.toLowerCase();
  tooltipThemeClass: string;

  constructor(private cdr: ChangeDetectorRef) {
    this.subscription = new Subscription();

    this.subscription.add(
      PDashLocalStoreUtil?.getUIThemeModeAsObservable()?.subscribe((mode) => {
        this.uiThemeMode = mode?.toLowerCase() + '-theme';
        this.tooltipThemeClass =
          'cdk-component-container--' + this.uiThemeMode + '-dark';
        this.cdr?.markForCheck();
      })
    );
  }

  ngOnInit(): void {}

  /** Display tooltip if required(for content having ellipses).
   *  @Param tooltip : Includes the matTooltip details.
   *  @Param ruleTitleRef : Includes the cell details.
   */
  showTooltip(tooltip, ruleTitleRef) {
    if (ruleTitleRef?.scrollWidth <= ruleTitleRef?.clientWidth) {
      tooltip.disabled = true;
    } else {
      tooltip.disabled = false;
    }
    this.cdr?.markForCheck();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
