import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable, timer } from 'rxjs';
import {
  L2HeaderModel,
  L2HeaderUserInfo,
  L2HeaderPageInfo,
  HeaderDescription,
} from './l2header.model';
import { CommonService } from '@app/common/http/service/common.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CONSTANTS } from 'environments/environment';
import { APP_LOCALE } from '@app/common/util/constants';
import { switchMap, retry, share, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class L2HeaderService extends CommonService {
  private l2HeaderConfig$ = new BehaviorSubject<L2HeaderModel>(
    new L2HeaderModel().init()
  );
  private l2HeaderPageInfo$ = new Subject<L2HeaderPageInfo>();
  private actionBtnEvent$ = new Subject<any>();
  private l2HeaderUserConfig$ = new BehaviorSubject<L2HeaderUserInfo>(null);
  private headerDescription$ = new Subject();

  stopPolling: any;
  constructor(protected http: HttpClient, private router: Router) {
    super(http);
  }

  setL2HeaderUserConfig(userConfig: L2HeaderUserInfo) {
    this.l2HeaderUserConfig$.next(userConfig);
  }

  getL2HeaderUserConfig() {
    return this.l2HeaderUserConfig$.asObservable();
  }

  setL2HeaderPageInfo(info: L2HeaderPageInfo) {
    this.l2HeaderPageInfo$.next(info);
  }

  getL2HeaderPageInfo() {
    return this.l2HeaderPageInfo$.asObservable();
  }

  setL2HeaderConfig(headerConfig: L2HeaderModel) {
    this.l2HeaderConfig$.next(headerConfig);
  }

  getL2HeaderConfig() {
    return this.l2HeaderConfig$.asObservable();
  }

  getL2HeaderActionEvent() {
    return this.actionBtnEvent$.asObservable();
  }

  triggerActionEvent(event: Event) {
    this.actionBtnEvent$.next(event);
  }

  /**
   * Method to get help contents
   */
  getArticleById(articleId: string): Observable<any> {
    this.path = CONSTANTS.HELP.GET_ARTICLE_BY_ID(APP_LOCALE.EN_US, articleId);
    return this.getData(this.path);
  }

  /**
   * Fetches all the articles matching the search term
   * @param searchTerm - string
   */
  getArticlesBySearchTerm(searchTerm: string): Observable<any> {
    this.path = CONSTANTS.HELP.GET_ARTICLES_BY_SEARCH_TERM;
    return this.create({ query: searchTerm });
  }

  logout() {
    this.path = CONSTANTS.USER_PROFILE.LOGOUT_USER;
    return this.delete();
  }

  downloadPDF(path: string) {
    return this.getData(path);
  }

  getPDFStatus(path: string) {
    return this.getData(path);
  }

  getPDFDownloadProgress(path: string) {
    this.stopPolling = new Subject();
    const progress$ = timer(1, 2000).pipe(
      switchMap(() => this.getPDFStatus(path)),
      retry(3),
      share(),
      takeUntil(this.stopPolling)
    );
    return progress$;
  }

  setStopPolling() {
    if (this.stopPolling) {
      this.stopPolling.next();
      this.stopPolling.complete();
    }
  }

  setHeaderDescription(headerDescConfig: HeaderDescription) {
    this.headerDescription$.next(headerDescConfig);
  }

  getHeaderDescription() {
    return this.headerDescription$.asObservable();
  }
}
