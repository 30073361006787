<div
  class="rule-list-pane-read-only__container d-flex flex-column p-2 bg-blue8-op5 bdr-blue8-op30"
>
  <div class="d-flex">
    <!-- for tule title -->
    <div
      *ngIf="ruleListPaneDetails?.ruleTitle"
      class="d-flex flex-column rule-list-pane-read-only__container__title-wrapper rule-list-pane-read-only__container__row-gap mr-2"
    >
      <ng-container
        [ngTemplateOutlet]="ruleTitle"
        [ngTemplateOutletContext]="{
          showThinTitle: ruleListPaneDetails?.showThinTitle,
          ruleTitle: ruleListPaneDetails?.ruleTitle
        }"
      >
      </ng-container>
    </div>
    <!-- for rule icon -->
    <div
      class="d-flex align-items-center icon mr-1"
      *ngIf="ruleListPaneDetails?.ruleListIcon"
    >
      <mat-icon class="blue-link">{{
        ruleListPaneDetails?.ruleListIcon
      }}</mat-icon>
    </div>
    <!-- for rule data -->
    <div
      class="rule-list-pane-read-only__container__rule-list w-100 rule-list-pane-read-only__container__column-gap d-flex flex-row"
      [ngClass]="{ 'ml-5': ruleListPaneDetails?.ruleTitle }"
    >
      <!-- show data -->
      <ng-container *ngIf="ruleListPaneDetails?.ruleList?.length">
        <div
          class="d-flex flex-column rule-list-pane-read-only__container__row-gap w-100"
          *ngFor="let ruleDetails of ruleListPaneDetails?.ruleList"
        >
          <div *ngFor="let rule of ruleDetails" class="clr-grey7">
            <span [ngStyle]="rule?.keyStyle"> {{ rule?.keyData }} </span>
            <span [ngStyle]="rule?.valueStyle">
              {{ rule?.valueData }}
            </span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template
  #ruleTitle
  let-showThinTitle="showThinTitle"
  let-ruleTitle="ruleTitle"
>
  <div
    [ngClass]="
      showThinTitle
        ? 'rule-list-pane-read-only__container__title-wrapper__main-thin-title'
        : 'rule-list-pane-read-only__container__title-wrapper__main-title'
    "
    class="title-text-overflow"
    [matTooltip]="ruleTitle"
    [matTooltipClass]="tooltipThemeClass"
    (mouseenter)="showTooltip(tooltip, ruleTitleRef)"
    #tooltip="matTooltip"
    #ruleTitleRef
  >
    {{ ruleTitle }}
  </div>
</ng-template>
