import { StepperConfig } from './stepper.config';
import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import { StepperService } from './stepper.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperComponent implements OnInit, OnDestroy {
  stepperConfig$ = new BehaviorSubject([new StepperConfig().init()]);
  currentStep$ = new BehaviorSubject({});
  subscriptions = [];

  constructor(private stepperService: StepperService) {}

  ngOnInit() {
    /**
     * Subscribe to stepper config stream
     */
    this.subscriptions.push(
      this.stepperService.getStepperConfig().subscribe((stepperConfig) => {
        this.stepperConfig$.next(stepperConfig);
      })
    );
    this.subscriptions.push(
      this.stepperService.getCurrentStep().subscribe((currentStep) => {
        this.currentStep$.next(currentStep);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }

  setCurrentStep(
    stepperConfig: StepperConfig,
    isStepDisabled: boolean = false
  ) {
    if (isStepDisabled) {
      return;
    } else {
      this.stepperService.setCurrentStep(stepperConfig);
    }
  }

  nextStep(stepType: string) {
    this.stepperService.setNextStep(stepType);
  }
}

export enum KEY_CODE {
  UP_ARROW = 38,
  DOWN_ARROW = 40,
}
