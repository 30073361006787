import { LegendData } from './legend.model';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LegendService {
  private selectedLegend$ = new Subject<Array<LegendData>>();

  constructor() {}

  setSelectedLegends(legendData: Array<LegendData>) {
    this.selectedLegend$.next(legendData);
  }

  getSelectedLegends(): Observable<Array<LegendData>> {
    return this.selectedLegend$.asObservable();
  }
}
