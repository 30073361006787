import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { RULESET_TYPES } from '@app/common/util/constants';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'app-waf-rule-category',
  templateUrl: './waf-rule-category.component.html',
  styleUrls: ['./waf-rule-category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WafRuleCategoryComponent implements OnInit, OnChanges {
  @Input() wafRuleGroupList;
  @Input() formModel;
  newFormInst: UntypedFormGroup;
  @Input() set newForm(formGroup: UntypedFormGroup) {
    if (formGroup) {
      this.newFormInst = formGroup;
    } else {
      this.newFormInst = this.initwafRuleForm();
    }
  }
  CONSTANTS = RULESET_TYPES;
  selectedDefaultListItem = 0; // count to show selected default rulesets
  selectedModSecListItem = 0; // count to show selected owasp rulesets
  defaultListArray = []; // data for default rulesets dropdown
  modSecListArray = []; // data for owasp rulesets dropdown
  @ViewChild('allDefaultSelected') private allDefaultSelected: MatOption;
  @ViewChild('allModSecSelected') private allModSecSelected: MatOption;
  constructor(private cdr: ChangeDetectorRef, private fb: UntypedFormBuilder) {}

  ngOnChanges(changes: SimpleChanges) {
    this.initwafRuleForm();
  }
  ngOnInit() {
    this.defaultListArray = this.wafRuleGroupList.basic.rulegroups;
    this.modSecListArray =
      this.wafRuleGroupList[this.CONSTANTS.OWASP].rulegroups;
  }

  initwafRuleForm() {
    let wafRuleForm: UntypedFormGroup;
    const formModel = this.formModel;
    wafRuleForm = this.fb.group({
      isDefaultListChecked: [
        formModel && formModel.hasOwnProperty('isDefaultListChecked')
          ? formModel.isDefaultListChecked
          : false,
      ],
      isModSecListChecked: [
        formModel && formModel.hasOwnProperty('isModSecListChecked')
          ? formModel.isModSecListChecked
          : false,
      ],
      default: [formModel && formModel.basic ? formModel.basic.rulegroups : []],
      modSec: [
        formModel && formModel[this.CONSTANTS.OWASP]
          ? formModel[this.CONSTANTS.OWASP].rulegroups
          : [],
      ],
    });
    this.selectedDefaultListItem =
      formModel && formModel.hasOwnProperty(this.CONSTANTS.BASIC)
        ? formModel.basic.rulegroups.length
        : 0;
    this.selectedModSecListItem =
      formModel && formModel.hasOwnProperty(this.CONSTANTS.OWASP)
        ? formModel[this.CONSTANTS.OWASP].rulegroups.length
        : 0;
    return wafRuleForm;
  }
  // click on option
  selectAllDefaultItems() {
    // If all option are selected, deselect all options. Update count based on form data
    if (this.allDefaultSelected.selected) {
      this.allDefaultSelected.deselect();
      this.selectedDefaultListItem =
        this.newFormInst.getRawValue().default.length;
      return false;
    }
    // If form data has all values, update count and select all options
    if (
      this.newFormInst.controls.default.value.length ===
      this.defaultListArray.length
    ) {
      this.selectedDefaultListItem =
        this.newFormInst.getRawValue().default.length;
      this.allDefaultSelected.select();
      return false;
    }
    this.selectedDefaultListItem =
      this.newFormInst.getRawValue().default.length;
    this.cdr.detectChanges();
  }

  // click on select all option to toggle the selected option
  toggleAllDefaultSelection() {
    // if all options are selected, patch extra value to formcontrol
    if (this.allDefaultSelected.selected) {
      this.newFormInst.controls.default.patchValue([
        ...this.defaultListArray,
        0,
      ]);
      this.selectedDefaultListItem =
        this.newFormInst.getRawValue().default.length - 1;
    } else {
      // if no options are selcted, patch empty array to formcontrol
      this.newFormInst.controls.default.patchValue([]);
      this.selectedDefaultListItem =
        this.newFormInst.getRawValue().default.length;
    }
    this.cdr.detectChanges();
  }

  selectAllModSecItems() {
    if (this.allModSecSelected.selected) {
      this.allModSecSelected.deselect();
      this.selectedModSecListItem =
        this.newFormInst.getRawValue().modSec.length;
      return false;
    }
    if (
      this.newFormInst.controls.modSec.value.length ===
      this.modSecListArray.length
    ) {
      this.selectedModSecListItem =
        this.newFormInst.getRawValue().modSec.length;
      this.allModSecSelected.select();
      return false;
    }
    this.selectedModSecListItem = this.newFormInst.getRawValue().modSec.length;
    this.cdr.detectChanges();
  }

  toggleAllModSecSelection() {
    if (this.allModSecSelected.selected) {
      this.newFormInst.controls.modSec.patchValue([...this.modSecListArray, 0]);
      this.selectedModSecListItem =
        this.newFormInst.getRawValue().modSec.length - 1;
    } else {
      this.newFormInst.controls.modSec.patchValue([]);
      this.selectedModSecListItem =
        this.newFormInst.getRawValue().modSec.length;
    }
    this.cdr.detectChanges();
  }
}
