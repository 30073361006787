import {
  BreadcrumbConfig,
  ColumnHeaderConfig,
  TabConfig,
} from '@prosimoio/components';
import { CONSTANTS } from 'environments/environment';
import {
  AppOnboardStep,
  OrchestratorWorkflow,
} from '@app/common/components/drawer/orchestrator/orchestrator.model';
import { DefinitionComponent } from './shared/components/drawer/definition/definition.component';
import { SettingsComponent } from './shared/components/drawer/settings/settings.component';
import { PolicyComponent } from './shared/components/drawer/policy/policy.component';
import { IMAGE_PATHS } from '@app/common/util/constants';
import { ConnectivityTypeModel } from './app.model';

export const TABS_DETAILS = {
  TAB_MARGIN: 'mat-tab-label-ml-16',
};

export enum ViewMode {
  ONBOARDED = 'onboarded',
  DISCOVERED = 'discovered',
}

export const APP_ONBOARD_TABS: Array<TabConfig> = [
  {
    tabName: 'Onboarded',
    code: ViewMode.ONBOARDED,
    routeUrl: CONSTANTS.V2.ONBOARD.APP.UI_PATH.UI_ONBOARDED_PATH,
    totalRecords: 0,
  },
  {
    tabName: 'Discovered',
    code: ViewMode.DISCOVERED,
    routeUrl: CONSTANTS.V2.ONBOARD.APP.UI_PATH.UI_DISCOVERED_PATH,
    totalRecords: 0,
  },
];

export const BreadcrumbsConfig: Array<BreadcrumbConfig> = [
  {
    label: 'Onboarding',
    link: CONSTANTS.V2.ONBOARD.UI_BASE_PATH,
  },
  {
    label: 'Applications',
  },
];

export const AppOnboardWorkflow: OrchestratorWorkflow<any> = {
  [AppOnboardStep.DEFINE]: {
    component: DefinitionComponent,
    next: AppOnboardStep.APP_SETTINGS,
  },
  [AppOnboardStep.APP_SETTINGS]: {
    component: SettingsComponent,
    next: AppOnboardStep.POLICY,
  },
  [AppOnboardStep.POLICY]: {
    component: PolicyComponent,
    next: null,
  },
};

export const AppOnboardTabs: Array<TabConfig> = [
  {
    tabName: 'Define App',
    code: AppOnboardStep.DEFINE,
    totalRecords: 0,
  },
  {
    tabName: 'App Settings',
    code: AppOnboardStep.APP_SETTINGS,
    totalRecords: 0,
  },
  {
    tabName: 'Attach Policies',
    code: AppOnboardStep.POLICY,
    totalRecords: 0,
  },
];

export const APP_ONBOARD_TYPE_OPTIONS = [
  {
    displayName: 'TCP/UDP',
    key: 'fqdn',
  },
  {
    displayName: 'DNS',
    key: 'ip',
  },
  {
    displayName: 'Web',
    key: 'web',
  },
  {
    displayName: 'Citrix VDI Desktop',
    key: 'vdi',
  },
  {
    displayName: 'Cloud Platform Services',
    key: 'cloud-svc',
  },
  {
    displayName: 'Jumpbox',
    key: 'jumpbox',
  },
];

export const APP_ONBOARD_TYPE_OPTION_KEYS = {
  FQDN: 'fqdn',
  IP: 'ip',
  WEB: 'web',
  VDI: 'vdi',
  CLOUD_SVC: 'cloud-svc',
  JUMPBOX: 'jumpbox',
};

export const APP_ONBOARD_TYPE_OPTION_FOR_DROPDOWN = {
  fqdn: {
    displayName: 'TCP/UDP',
    key: 'fqdn',
  },
  ip: {
    displayName: 'DNS',
    key: 'ip',
  },
  web: {
    displayName: 'Web',
    key: 'web',
  },
  vdi: {
    displayName: 'Citrix VDI Desktop',
    key: 'vdi',
  },
  'cloud-svc': {
    displayName: 'Cloud Platform Services',
    key: 'cloud-svc',
  },
  jumpbox: {
    displayName: 'Jumpbox',
    key: 'jumpbox',
  },
};

export const CLOUD_SERVICE_OPTIONS = [
  {
    displayName: 'Amazon S3',
    value: 'amazon-s3',
  },
];

export const CLOUD_SERVICE_KEYS = {
  AMAZON_S3: 'amazon-s3',
};

export const APP_ONBOARD_TYPE_OPTION_DISPLAY_NAMES = {
  fqdn: 'TCP/UDP',
  ip: 'DNS',
  web: 'Web',
  vdi: 'Citrix VDI Desktop',
  'cloud-svc': 'Cloud Platform Services',
  service_ip: 'Service IP',
  jumpbox: 'Jumpbox',
};

export const TCP_UDP_ACCESS_METHOD_OPTION_DISPLAY_NAMES = {
  fqdn: 'FQDN',
  service_ip: 'Service IP',
};

export const TCP_UDP_ACCESS_METHOD_OPTIONS = [
  {
    displayName: 'FQDN',
    key: 'fqdn',
  },
  {
    displayName: 'Service IP',
    key: 'service_ip',
  },
];

export const TCP_UDP_ACCESS_METHOD_FOR_DROPDOWN = {
  fqdn: {
    displayName: 'FQDN',
    key: 'fqdn',
  },
  service_ip: {
    displayName: 'Service IP',
    key: 'service_ip',
  },
};

export const TCP_UDP_ACCESS_METHOD_OPTION_KEYS = {
  FQDN: 'fqdn',
  SERVICE_IP: 'service_ip',
};

export const SERVICE_IP_ADDRESS_INPUT_METHOD_OPTIONS = [
  {
    displayName: 'Auto Generate IP',
    class: 'p-0',
    value: 'auto',
  },
  {
    displayName: 'Enter IP',
    class: 'ml-2',
    value: 'manual',
  },
];

export const SERVICE_IP_ADDRESS_INPUT_METHOD_OPTION_KEYS = {
  AUTO: 'auto',
  MANUAL: 'manual',
};

export const DOMAIN_TYPE_OPTIONS = [
  {
    displayName: 'Custom',
    class: 'p-0',
    value: 'custom',
  },
  {
    displayName: 'Prosimo',
    class: 'ml-2',
    value: 'prosimo',
  },
];

export const DOMAIN_TYPE_KEYS = {
  CUSTOM: 'custom',
  PROSIMO: 'prosimo',
};

export const POLICY_OPTIONS = [
  {
    displayName: 'No Policy (Implicit Deny)',
    class: 'pl-0',
    value: 'noPolicy',
  },
  {
    displayName: 'Select Specific Policy',
    value: 'specificPolicy',
  },
];

export const DNS_OPTIONS = [
  {
    displayName: 'Manual',
    class: 'pl-0',
    value: 'manual',
  },
  {
    displayName: 'AWS Route 53',
    value: 'aws_route53',
  },
];

export const DNS_OPTION_KEYS = {
  MANUAL: 'manual',
  AWS_ROUTE53: 'aws_route53',
};

export const AUTH_OPTIONS = [
  {
    displayName: 'SAML',
    key: 'saml',
  },
  {
    displayName: 'OIDC',
    key: 'oidc',
  },
  {
    displayName: 'Other',
    key: 'other',
  },
];

export const CSP_TYPE_OPTIONS = [
  {
    displayName: 'AWS',
    key: 'AWS',
    imageURL: IMAGE_PATHS.CLOUD.CSP[`AWS_DARK`],
  },
  {
    displayName: 'Azure',
    key: 'AZURE',
    imageURL: IMAGE_PATHS.CLOUD.CSP[`AZURE_DARK`],
  },
  {
    displayName: 'GCP',
    key: 'GCP',
    imageURL: IMAGE_PATHS.CLOUD.CSP[`GCP_DARK`],
  },
];

export const CSP_TYPE_FOR_DROPDOWN = {
  AWS: CSP_TYPE_OPTIONS[0],
  AZURE: CSP_TYPE_OPTIONS[1],
  GCP: CSP_TYPE_OPTIONS[2],
};

export const AUTH_OPTION_FOR_DROPDOWN = {
  saml: {
    displayName: 'SAML',
    key: 'saml',
  },
  oidc: {
    displayName: 'OIDC',
    key: 'oidc',
  },
  other: {
    displayName: 'Other',
    key: 'other',
  },
};

export const AUTH_OPTION_KEYS = {
  SAML: 'saml',
  OIDC: 'oidc',
  OTHER: 'other',
};

export const METADATA_OPTIONS = [
  {
    displayName: 'MetaData URL (Cloned App)',
    class: 'pl-0',
    value: 'metadataURL',
  },
  {
    displayName: 'MetaData',
    value: 'metadata',
  },
];

export const METADATA_OPTIONS_KEYS = {
  METADATAURL: 'metadataURL',
  METADATA: 'metadata',
};

export const POLICY_OPTION_KEYS = {
  NO_POLICY: 'noPolicy',
  SPECIFIC_POLICY: 'specificPolicy',
};

export const OPTMIZATION_MODE_OPTIONS = [
  {
    displayName: 'Cost Savings',
    value: 'CostSaving',
  },
  {
    displayName: 'Performance Enhanced',
    value: 'PerformanceEnhanced',
  },
  {
    displayName: 'Fast Lane',
    value: 'FastLane',
  },
];

export const OPTMIZATION_MODE_DROPDOWN_OPTIONS = {
  CostSaving: {
    displayName: 'Cost Savings',
    value: 'CostSaving',
  },
  PerformanceEnhanced: {
    displayName: 'Performance Enhanced',
    value: 'PerformanceEnhanced',
  },
  FastLane: {
    displayName: 'Fast Lane',
    value: 'FastLane',
  },
};

export const OPTMIZATION_MODE_OPTION_KEYS = {
  CostSaving: 'CostSaving',
  PerformanceEnhanced: 'PerformanceEnhanced',
  FastLane: 'FastLane',
};

export const OPTMIZATION_MODE_DISPLAY_NAME = {
  CostSaving: 'Cost Savings',
  PerformanceEnhanced: 'Performance Enhanced',
  FastLane: 'Fast Lane',
};

export const TCP_UDP_PROTOCOL_OPTIONS = [
  {
    displayName: 'TCP',
    value: 'tcp',
  },
  {
    displayName: 'UDP',
    value: 'udp',
  },
];

export const DNS_PROTOCOL_OPTIONS = [
  {
    displayName: 'DNS',
    value: 'dns',
  },
];

export const WEB_VDI_PROTOCOL_OPTIONS = [
  {
    displayName: 'HTTP',
    value: 'http',
  },
  {
    displayName: 'HTTPS',
    value: 'https',
  },
  {
    displayName: 'SSH',
    value: 'ssh',
  },
  {
    displayName: 'RDP',
    value: 'rdp',
  },
  {
    displayName: 'VNC',
    value: 'vnc',
  },
];

export const PROTOCOLS = {
  HTTP: 'http',
  HTTPS: 'https',
  TCP: 'tcp',
  UDP: 'udp',
};

export const PROTOCOL_DISPLAY_NAME = {
  http: 'HTTP',
  https: 'HTTPS',
  tcp: 'TCP',
  udp: 'UDP',
  rdp: 'RDP',
  ssh: 'SSH',
  vnc: 'VNC',
  dns: 'DNS',
};

export const WEB_VDI_EXT_PROTOCOL_OPTIONS = [
  {
    displayName: 'HTTP',
    value: 'http',
  },
  {
    displayName: 'HTTPS',
    value: 'https',
  },
];

export const PROTOCOL_DEFAULT_PORT = {
  http: 80,
  https: 443,
  ssh: 22,
  rdp: 3389,
  vnc: 5901,
  dns: 53,
  tcp: '',
  udp: '',
};

export const CLOUD_TYPE_OPTIONS = [
  {
    displayName: 'Public',
    class: 'p-0',
    value: 'public',
  },
  {
    displayName: 'Private',
    class: 'ml-2',
    value: 'private',
  },
];

export const CLOUD_TYPE_OPTION_KEYS = {
  PUBLIC: 'public',
  PRIVATE: 'private',
};

export const AUTH_METHOD_DISPLAY_NAME = {
  oidc: 'OIDC',
  saml: 'SAML',
  other: 'Other',
};

export const CONN_TYPE_DISPLAY_NAME = {
  public: 'Public',
  private: 'Private',
  peering: 'Peering',
  awsPrivateLink: 'Private Link',
  azurePrivateLink: 'Private Link',
  transitGateway: 'Transit Gateway',
  awsVpnGateway: 'VPN Gateway',
  azureTransitVnet: 'Transit Vnet',
  vwanHub: 'VWAN Hub',
};

export const REGION_TYPE_DISPLAY_NAME = {
  active: 'Active',
  backup: 'Backup',
};

export const AWS_PEERING_OPTIONS = [
  {
    displayName: 'Peering',
    class: 'p-0',
    value: 'private',
  },
  {
    displayName: 'Private Link',
    class: 'p-0',
    value: 'awsPrivateLink',
  },
  {
    displayName: 'Transit Gateway',
    class: 'p-0',
    value: 'transitGateway',
  },
  {
    displayName: 'VPN Gateway',
    class: 'p-0',
    value: 'awsVpnGateway',
  },
];

export const AZURE_PEERING_OPTIONS = [
  {
    displayName: 'Peering',
    class: 'p-0',
    value: 'private',
  },
  {
    displayName: 'Private Link',
    class: 'p-0',
    value: 'azurePrivateLink',
  },
  {
    displayName: 'VWAN Hub',
    class: 'p-0',
    value: 'vwanHub',
  },
];

export const PEERING_OPTION_KEYS = {
  PUBLIC: 'public',
  PRIVATE: 'private',
  PEERING: 'peering',
  AWS_PRIVATE_LINK: 'awsPrivateLink',
  AZURE_PRIVATE_LINK: 'azurePrivateLink',
  TGW: 'transitGateway',
  AWS_VPN_GATEWAY: 'awsVpnGateway',
  AZURE_TRANSIT_VNET: 'azureTransitVnet',
  VWAN_HUB: 'vwanHub',
};

export const ENDPOINT_OPTIONS = [
  {
    displayName: 'Discover Endpoints',
    class: 'p-0',
    value: 'discovered',
  },
  {
    displayName: 'Enter Endpoints',
    class: 'ml-2 p-0',
    value: 'entry',
  },
];

export const REGION_TYPE_OPTIONS = [
  {
    displayName: 'Active',
    class: 'p-0',
    value: 'active',
  },
  {
    displayName: 'Backup',
    class: 'ml-2 p-0',
    value: 'backup',
  },
];

export const REGION_TYPE_OPTION_KEYS = {
  ACTIVE: 'active',
  BACKUP: 'backup',
};

export const ENDPOINT_OPTION_KEYS = {
  DISCOVERED: 'discovered',
  MANUAL: 'entry',
};

export const CERTIFICATE_OPTIONS = [
  {
    displayName: 'Upload a Certificate',
    key: 'uploadCert',
  },
  {
    displayName: 'Existing Certificates',
    key: 'existingCert',
  },
];

export const CERTIFICATE_OPTION_KEYS = {
  UPLOAD_CERT: 'uploadCert',
  EXISTING_CERT: 'existingCert',
};

export const TGW_ROUTE_TABLE_OPTIONS = {
  MAINTAIN: 'MAINTAIN',
  MODIFY: 'MODIFY',
};

export const TOOLTIP_MESSAGE = {
  SUDOMAIN_INCLUDED: `
    If multiple subdomains are present for the internal domain provided, they 
    can be included quickly & efficiently by enabling this option`,
  BUCKETS_INCLUDED: `
    This option onboards all buckets in order for users to access them using the same domains`,
  EDGE_TO_APP_PROTOCOL: `Protocol that Prosimo edge uses to connect to the app`,
  SOURCE_TO_EDGE_PROTOCOL: `
    Source could be user or another app`,
  HEALTH_CHECK: `Enable health check to ensure application domains being resolved 
    by the DNS servers are responsive`,
  IP_ALLOWLIST: `
    If users are behind dynamic NAT pool, enter the IP range for the corporate 
    NAT pool to allow access for sessions from any IP address from the range`,
  WEBSOCKET: `
 Prosimo edges can connect to application via Websockets if the application supports it`,
};

export const ERROR_MESSAGE = {
  REQUIRED: `Required`,
  INVALID: 'Invalid',
  CLOUD: `Cloud account is required`,
  REGION: `Region is Required`,
  BUCKET: `Bucket is required`,
  ENDPOINT: `Endpoint is required`,
  SERVICE_ENDPOINT: `Service Endpoint is required`,
  IP_ADDRESS: `IP Address is required`,
  PORT_RANGE: `Port must be between 1 - 65535`,
  NOT_UNIQUE: `Domain name not unique`,
};

export const OVERVIEW_TITLE = 'Overview: ';
export interface domainList {
  domain?: string;
  subdomainIncluded?: boolean;
}

export interface DetailsTabData {
  appType?: string;
  accessMethod?: string;
  domainList: Array<domainList>;
}

export interface PortSettings {
  tcpPort: string;
  udpPort: string;
}

export interface cloudSettings {
  hosted?: string;
  connection?: string;
  account?: string;
  region?: string;
  connectivityType?: string;
}

export interface endpoints {
  discovered?: string;
  vpc?: string;
  attachedPoint?: string;
}

export interface certificates {
  option?: string;
  certificate?: string;
  privateKey?: string;
}

export interface dnsSettings {
  ip?: string;
  dns?: string;
}
export interface AppSettingsTabData {
  domain?: string;
  portSettings?: PortSettings;
  cloudSettings?: cloudSettings;
  endpoints?: endpoints;
  certificates?: certificates;
  cacheRules?: string;
  dnsSettings?: dnsSettings;
}

export interface PoliciesTabData {}

export interface AdvanceSettingsTabData {}

export enum AppStatus {
  NEW = 'NEW',
  CONFIGURED = 'CONFIGURED',
  CONFIGURING = 'CONFIGURING',
  DECOMMISSIONING = 'DECOMMISSIONING',
  DEPLOYING = 'DEPLOYING',
  DEPLOYED = 'DEPLOYED',
  FAILED = 'FAILED',
  DISCOVERED = 'DISCOVERED',
}

export enum DeleteAction {
  DELETE = 'delete',
  OFFBOARD = 'offboard',
}

export const OFFBOARD_CONFIRMATION_TOOLTIP = `Selecting this will force offboard 
of this application when underlying resources haven't been cleaned up.`;

export const AppDeleteConfig: { [type in AppStatus]: any } = {
  [AppStatus.NEW]: null,
  [AppStatus.DEPLOYING]: null,
  [AppStatus.DECOMMISSIONING]: null,
  [AppStatus.CONFIGURING]: null,
  [AppStatus.DISCOVERED]: {
    action: DeleteAction.DELETE,
    btn: 'Delete App',
    panelMessage: 'Are you sure you want to delete this application?',
    panelBtnName: 'Delete',
    confirmation: null,
  },
  [AppStatus.CONFIGURED]: {
    action: DeleteAction.DELETE,
    btn: 'Delete App',
    panelMessage: 'Are you sure you want to delete this application?',
    panelBtnName: 'Delete',
    confirmation: null,
  },
  [AppStatus.FAILED]: {
    action: DeleteAction.OFFBOARD,
    btn: 'Offboard App',
    panelMessage: 'Are you sure you want to Offboard this application?',
    panelBtnName: 'Offboard',
    confirmation: {
      confirmationLabel: 'Force Offboard',
      confirmationTooltip: OFFBOARD_CONFIRMATION_TOOLTIP,
      isConfirmationReqd: false,
    },
  },
  [AppStatus.DEPLOYED]: {
    action: DeleteAction.OFFBOARD,
    btn: 'Offboard App',
    panelMessage: 'Are you sure you want to Offboard this application?',
    panelBtnName: 'Offboard',
    confirmation: {
      confirmationLabel: 'Force Offboard',
      confirmationTooltip: OFFBOARD_CONFIRMATION_TOOLTIP,
      isConfirmationReqd: false,
    },
  },
};

export const ONBOARDED_APPS_STATUS = [
  AppStatus.CONFIGURED,
  AppStatus.CONFIGURING,
  AppStatus.DECOMMISSIONING,
  AppStatus.DEPLOYED,
  AppStatus.DEPLOYING,
  AppStatus.FAILED,
];

export const DISCOVERED_APPS_STATUS = [AppStatus.DISCOVERED];

export const COLUMN_HEADER_CONFIG: Array<ColumnHeaderConfig> = [
  {
    name: 'Status',
  },
  {
    name: 'Name',
  },
  {
    name: 'App Type',
  },
  {
    name: 'Cloud',
  },
];

export const DISCOVERED_APP_COLUMN_HEADER_CONFIG: Array<ColumnHeaderConfig> = [
  {
    name: 'Name',
  },
  {
    name: 'App Type',
  },
  {
    name: 'Cloud',
  },
];

export const DISPLAY_COLUMNS: Array<string> = [
  'status',
  'appName',
  'appOnboardTypeToDisplay',
  'cloud',
];

export const DISCOVERED_APP_DISPLAY_COLUMNS: Array<string> = [
  'appName',
  'appOnboardTypeToDisplay',
  'cloud',
];

export const COLUMN_HEADERS: Array<string> = [
  'Status',
  'Name',
  'App Type',
  'Cloud',
];

export const DISCOVERED_APP_COLUMN_HEADERS: Array<string> = [
  'Name',
  'App Type',
  'Cloud',
];

export const POLLING_TASK_STATUS = [''];
export const SELECT_ROW_MESSAGE =
  'Click on a row to view data or click on create new to create new Application.';

export const FORM_FIELD_DISABLE_AS_PER_STATUS = {
  FAILED: [
    'appOnboardType',
    'accessType',
    'appFqdn',
    'subdomainIncluded',
    'addDomain',
    'nextButton',
  ],
  DEPLOYED: [],
};

export const APP_ONBOARD = 'APP_ONBOARD';

export const CONNECTIVITY_OPTIONS = {
  AWS: [
    {
      displayName: 'VPC Peering',
      value: 'vpc-peering',
      isDefault: true,
      isDisabled: false,
    } as ConnectivityTypeModel,
    {
      displayName: 'Transit Gateway',
      value: 'transit-gateway',
      isDefault: false,
      isDisabled: false,
    } as ConnectivityTypeModel,
  ],
  GCP: [
    {
      displayName: 'VPC Peering',
      value: 'vpc-peering',
      isDefault: true,
      isDisabled: true,
    } as ConnectivityTypeModel,
  ],
  AZURE: [
    {
      displayName: 'VNet Peering',
      value: 'vnet-peering',
      isDefault: true,
      isDisabled: false,
    } as ConnectivityTypeModel,
    {
      displayName: 'VWAN Hub',
      value: 'vwan-hub',
      isDefault: false,
      isDisabled: false,
    } as ConnectivityTypeModel,
  ],
};

export const KEYS_TO_COMPARE = {
  id: true,
  appName: true,
  appOnboardType: true,
  cloudHostedType: true,
  cspType: true,
  dcID: true,
  accessType: true,
  appURLs: {
    id: true,
    appFqdn: true,
    internalDomain: true,
    subdomainIncluded: true,
    cacheRuleID: true,
    certID: true,
    cloudKeyID: true,
    connectionOption: true,
    domainType: true,
    dcID: true,
    dnsService: {
      type: true,
      id: true,
      configured: true,
    },
    dcAppIp: true,
    namespaceID: true,
    extProtocols: {
      protocol: true,
      port: true,
      webSocketEnabled: true,
      paths: true,
      portList: true,
    },
    protocols: {
      protocol: true,
      port: true,
      webSocketEnabled: true,
      paths: true,
      portList: true,
    },
    healthCheckInfo: {
      enabled: true,
      endpoint: true,
    },
    regions: {
      id: true,
      connOption: true,
      endPoints: true,
      inputType: true,
      inputEndpoints: true,
      selectedEndpoints: true,
      modifyTgwAppRouteTable: true,
      name: true,
      regionType: true,
    },
    serviceIP: true,
    serviceIPType: true,
    pappFqdn: true,
    transitPappFqdn: true,
  },
  cloudService: true,
  dnsService: {
    type: true,
    id: true,
    configured: true,
  },
  status: true,
  createdTime: true,
  updatedTime: true,
  optimizeAppExperience: true,
  optOption: true,
  policyIDs: true,
  enableMultiCloud: true,
  samlRewrite: true,
};
