import { IMAGE_PATHS, URL_PATHS } from '@app/common/util/constants';

export class L2HeaderModel {
  title?: string;
  description?: string;
  displayHeader?: boolean;
  displayActionBtn?: boolean;
  selectedIcon?: string;
  actionBtnLabel?: string;
  profile?: HeaderProfile;
  isProsimoAdmin?: boolean;
  isMSPAdmin?: boolean;
  mspName?: string;
  mspImageURL?: string;
  actions?: Array<any>;
  routingLink?: string;
  routingLinkLabel?: string;
  isBetaRelease?: boolean;
  init() {
    return {
      title: '',
      description: '',
      selectedIcon: null,
      displayActionBtn: false,
      isProsimoAdmin: false,
      isMSPAdmin: false,
      actionBtnLabel: '',
      profile: null,
    } as L2HeaderModel;
  }
}

export interface HeaderProfile {
  company?: string;
  team?: string;
  actions?: Array<any>;
}

export interface L2HeaderUserInfo {
  firstname: string;
  lastname: string;
  username: string;
  team: string;
  disabledProfile: boolean;
}

export interface L2HeaderPageInfo {
  pageName: string;
}

export const HEADER_ICONS_CONFIG = {
  PRINT: {
    selected_image: IMAGE_PATHS.COMMON.SELECTED_PDF,
    unselected_image: IMAGE_PATHS.COMMON.PDF,
    displayName: 'PDF',
    name: 'PRINT',
    placeholder: 'PDF print view',
  },
  TASKS: {
    selected_image: IMAGE_PATHS.NAVIGATION.SELECTED_TASKS,
    unselected_image: IMAGE_PATHS.NAVIGATION.TASKS,
    url: URL_PATHS.DASHBOARD_TASKS,
    mspURL: URL_PATHS.MSP.TASKS,
    displayName: 'Tasks',
    name: 'TASKS',
    placeholder: 'View system and user tasks, status, and details ',
  },
  ALERTS: {
    selected_image: IMAGE_PATHS.NAVIGATION.SELECTED_ALERTS,
    unselected_image: IMAGE_PATHS.NAVIGATION.ALERTS,
    url: URL_PATHS.DASHBOARD_ALERTS,
    mspUrl: URL_PATHS.DASHBOARD_ALERTS,
    displayName: 'Alerts',
    name: 'ALERTS',
    placeholder: 'View critical alerts, warnings and notifications',
  },
  HELP: {
    selected_image: IMAGE_PATHS.NAVIGATION.HELP_SELECTED,
    unselected_image: IMAGE_PATHS.NAVIGATION.HELP_UNSELECTED,
    url: URL_PATHS.DASHBOARD_RELEASE_NOTES,
    mspURL: URL_PATHS.MSP.RELEASE_NOTES,
    displayName: 'Help',
    name: 'HELP',
    placeholder: 'View release notes and documentation',
  },
  USER_PROFILE: {
    selected_image: IMAGE_PATHS.NAVIGATION.SELECTED_USER_PROFILE,
    unselected_image: IMAGE_PATHS.NAVIGATION.USER_PROFILE,
    url: URL_PATHS.DASHBOARD_USER_PROFILE,
    mspURL: URL_PATHS.MSP.USER_PROFILE,
    displayName: 'Admin',
    name: 'USER_PROFILE',
  },
  TOURS: {
    selected_image: IMAGE_PATHS.NAVIGATION.SELECTED_PRODUCT_TOUR,
    unselected_image: IMAGE_PATHS.NAVIGATION.DESELECTED_PRODUCT_TOUR,
    url: '',
    mspUrl: '',
    displayName: 'Tours',
    name: 'TOURS',
    placeholder: 'View product tours',
  },
};

export const PRINTABLE_PAGES = new Set(['Discovery', 'Insights']);

export const LOCAL_STORE_DATA_REQUIRED_PAGES = new Set(['Insights']);

export const LOCAL_STORE_KEYS = {
  Insights: 'insights',
};

export interface HeaderDescription {
  htmlContent: string;
  tootltipContent?: string;
  tootltipHtmlContent?: string;
}
