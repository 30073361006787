import { TimeISO } from '@prosimoio/services';

import { EdgeConnectorGroup } from './cluster';

export type CloudCredential = {
  name: string;
  cloudType: string;
  id: string;
  keyType: string;
  accountID: string;
  accountName: string;
  isDefault: boolean;
  details: any;
  teamID: string;
  regionNames: Array<string>;
  extras: CloudCredExtras;
  createdTime: TimeISO;
  updatedTime: TimeISO;
  pappUsedCount: number;
  appUsedCount: number;
  edgeConnUsedCount: number;
  status: string;
  connectionType: string;
  autoUpdate: boolean;
  appNames: Array<string>;
};

export type PrivateCloud = {
  id: string;
  name: string;
  status: string;
  location: CloudLocation;
  publicConnection: boolean;
  privateConnection: boolean;
  privateConnectivityDetails: Array<CloudAttachment>;
  teamID: string;
  createdTime: TimeISO;
  updatedTime: TimeISO;
  edgeConnectorGroups: Array<EdgeConnectorGroup>;
  appsCount: number;
  unavailableEdges: any;
  cloudDomain: string;
  progress: number;
  appNames: Array<string>;
};

export type CloudCredExtras = {
  filename: string;
  appOnboarded: boolean;
  pappEligible: boolean;
};

export type CloudLocation = {
  cityCode: number;
  cityName: string;
  countryCodeIso2: string;
  regionCode: string;
  stateName: string;
  countryName: string;
  latitude: number;
  longitude: number;
};

export type CloudAttachment = {
  edgeID: string;
  cloudRegion: string;
  attachPoint: string;
  attachment: string;
  edgeConnectivityID: string;
  connectivityType: string;
  homeEdge: boolean;
  cloudType: string;
  privateCloudID: string;
  teamID: string;
  createdTime: TimeISO;
  updatedTime: TimeISO;
  cloudCredsID: string;
  connectorCIDR: string;
  routeTable: string;
  status: string;
};

export type CloudRegion = {
  regionName: string;
  appCount: number;
  locationID: string;
};

export type UnmanagedVPCs = {
  cloud: string;
  regions: Array<UnmanagedVPCandRegion>;
};

export type UnmanagedVPCandRegion = {
  region: string;
  vpcs: Array<string>;
};
