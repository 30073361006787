import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { TabConfig } from './tab.component';

@Injectable({
  providedIn: 'root',
})
export class TabService {
  private selectedTab$ = new BehaviorSubject<TabConfig>(null);
  private tabConfigs$ = new BehaviorSubject<Array<TabConfig>>(null);
  private activeTab$ = new Subject();

  constructor() {}

  setTabConfigs(tabConfigs: Array<TabConfig>) {
    this.tabConfigs$.next(tabConfigs);
  }

  getTabConfigs(): Observable<Array<TabConfig>> {
    return this.tabConfigs$.asObservable();
  }

  setSelectedTab(selectedTabConfig: TabConfig) {
    this.selectedTab$.next(selectedTabConfig);
  }

  getSelectedTab(): Observable<TabConfig> {
    return this.selectedTab$.asObservable();
  }

  setActiveTab(isActive: boolean) {
    this.activeTab$.next(isActive);
  }

  getActiveTab() {
    return this.activeTab$.asObservable();
  }
}
