import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { AppUtil } from '@app/common/util/app-util';
import {
  COLORS,
  URL_PATHS,
  FONT_FAMILY,
  MENU_NAMES,
  SUBSCRIPTION_TYPES,
  APP_LOCALE,
  UI_THEME_MODES,
  USER_TYPES,
} from '@app/common/util/constants';
import { LoginUtil } from '@app/login/login-util';
import { PDashLocalStoreUtil } from '@app/state/web-pdash.util';
import { BehaviorSubject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  take,
} from 'rxjs/operators';
import {
  ArticleSearchResult,
  DISPLAY_STATUS,
  HelpModel,
} from '../help/help.model';
import { HelpService } from '../help/help.service';
import { MESSAGES } from './l2header.constant';
import {
  HEADER_ICONS_CONFIG,
  L2HeaderModel,
  PRINTABLE_PAGES,
} from './l2header.model';
import { CONSTANTS } from 'environments/environment';
import {
  INTERACTIVE_GUIDE_CONFIGS,
  PRODUCT_TOUR_KEYS,
} from '@app/common/util/interactive-guide-config.constant';
import { InteractiveGuideService } from '@prosimoio/components';
import { UserBehaviorInsightsCustomEventInfo } from '@prosimoio/services';
import { MAIN_DASHBOARD_ROUTES } from '@app/dashboard/main/main.model';
import { PdfDownload } from '@app/common/models/models';
import { AppService } from '@app/app.service';
import { ObjectUtil } from '@prosimoio/services';
import { creds } from 'environments/creds';
import { DevCredentialModel } from '@app/common/models/models';
import { IMAGE_PATHS } from '@app/common/util/constants';
import { HeaderService } from '../header/header.service';

const TASKS = 'tasks';
const ALERTS = 'alerts';
const USER_PROFILE = 'user-profile';
const TOURS = 'tours';
@Component({
  selector: 'app-l2header',
  templateUrl: './l2header.component.html',
  styleUrls: ['./l2header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class L2headerComponent implements OnInit, OnDestroy {
  public headerConfig$ = new BehaviorSubject(new L2HeaderModel().init());
  public pageInfo$ = new BehaviorSubject(null);
  private subscriptions = [];
  tasksSelected = false;
  alertsSelected = false;
  tourSelected = false;
  userProfileSelected = false;
  helpSelected = false;
  pdfSelected = false;
  defaultPdfTooltip = true;
  LOGIN_PATH = URL_PATHS.LOGIN;
  tasksConfig = HEADER_ICONS_CONFIG.TASKS;
  printConfig = HEADER_ICONS_CONFIG.PRINT;
  alertsConfig = HEADER_ICONS_CONFIG.ALERTS;
  tourConfig = HEADER_ICONS_CONFIG.TOURS;
  userProfileConfig = HEADER_ICONS_CONFIG.USER_PROFILE;
  helpConfig = HEADER_ICONS_CONFIG.HELP;
  profileTooltip: any;
  BETA_ICON = IMAGE_PATHS.COMMON.BETA_ICON;
  helpTextConfig$ = new BehaviorSubject<any>(null);
  companyInfo = Object.assign(
    {},
    {
      name: '',
      team: '',
    }
  );
  displayPrintIcon$ = new BehaviorSubject<boolean>(false);
  isPrintPolling = new BehaviorSubject<boolean>(false);
  pdfPrintInprogressTxt = `PDF generation in progress, monitor the status in task page`;
  pdfTooltip = this.printConfig?.placeholder;
  productTours: Array<any> = [];
  MENU_NAMES = MENU_NAMES;
  headerDescription;
  TOOLTIP_POSITION = {
    BEFORE: 'before',
  };
  productViewTypes = [
    {
      value: SUBSCRIPTION_TYPES.ZTNA,
      displayName: 'ProAccess',
      isDisabled: false,
      tooltip:
        'Zero Trust Network Access (ZTNA) for users to securely access applications and networks across different cloud providers',
      tooltipPosition: this.TOOLTIP_POSITION.BEFORE,
    },
    {
      value: SUBSCRIPTION_TYPES.MCN,
      displayName: 'ProTransit',
      isDisabled: false,
      tooltip:
        'Multi-Cloud Networking (MCN) to connect, secure and manage networks and applications across various cloud providers',
      tooltipPosition: this.TOOLTIP_POSITION.BEFORE,
    },
  ];
  credentialConfig = creds;
  selectedDashboardHost: DevCredentialModel;
  selectedProductViewType = {
    displayName: '',
    tooltip: '',
    value: '',
  };
  showProductViewTypeInput: boolean = false;
  CONTACT_SALES = 'Contact Prosimo Sales to enable access';
  selectedUIThemeMode = '';
  UI_THEME_MODES_COPY = UI_THEME_MODES;
  isDev: boolean = false;

  constructor(
    private headerService: HeaderService,
    private loginUtil: LoginUtil,
    private router: Router,
    private helpService: HelpService,
    private appService: AppService,
    private interactiveGuideService: InteractiveGuideService,
    private cdr: ChangeDetectorRef,
    private appUtil: AppUtil
  ) {
    this.isDev = this.appUtil.isDev();
    this.selectedUIThemeMode = PDashLocalStoreUtil.getUIThemeMode();
    this.selectedDashboardHost = this.credentialConfig?.find(
      (credential) => credential.host === CONSTANTS.DASHBOARD_HOST
    );
    this.subscriptions.push(
      this.headerService
        .getL2HeaderConfig()
        .pipe()
        .subscribe((config) => {
          this.headerConfig$.next(config);
          if (config) {
            this.setTeamInfo();
            this.tasksSelected = config.selectedIcon === TASKS;
            this.alertsSelected = config.selectedIcon === ALERTS;
            this.userProfileSelected = config.selectedIcon === USER_PROFILE;
            this.tourSelected = config.selectedIcon === TOURS;
          }
        }),

      this.headerService.getL2HeaderPageInfo().subscribe((pageInfo) => {
        this.pageInfo$.next(pageInfo);
      }),

      this.headerService.getL2HeaderUserConfig().subscribe((config) => {
        this.profileTooltip = config;
        this.setProductViewTypesStatus();
      }),

      this.helpService.getHelpConfig().subscribe((helpConfig) => {
        if (helpConfig && helpConfig.displayStatus === DISPLAY_STATUS.CLOSE) {
          this.helpSelected = false;
          this.cdr.markForCheck();
        }
      }),

      this.helpService.getRefreshRequest().subscribe((refreshStatus) => {
        this.setHelpContent();
      }),

      PDashLocalStoreUtil.getPageInfo().subscribe((data) => {
        if (this.helpSelected) {
          this.hideHelpPanel();
        }
        const {
          pageInfo: {
            module = { parent: '', child: '' },
            helpId = '',
            interactiveGuides = null,
          },
        } = data;
        const config = Object.assign({}, { module, helpId, interactiveGuides });
        this.togglePrintIcon(module.parent);
        this.helpTextConfig$.next(config);
      }),

      this.helpService
        .getSearchTerm()
        .pipe(
          switchMap((searchTerm) => this.getArticlesBySearchTerm(searchTerm))
        )
        .subscribe(
          (data) => {
            let results = data ? data : [];
            results = results.map(({ id, title }) => ({ id, title }));
            this.compileSearchResultSection(results);
          },
          (error) => {
            console.log(`Unable to fetch articles for search term - ${error}`);
          }
        ),
      this.helpService
        .getArticleID()
        .pipe(
          distinctUntilChanged(),
          switchMap((articleID) => this.headerService.getArticleById(articleID))
        )
        .subscribe(
          (data: any) => {
            this.helpService.setHelpConfig({
              displayStatus: DISPLAY_STATUS.OPEN,
              displayContent: data && data.body ? data.body : '',
              displayTitle: data && data.title ? data.title : '',
            } as HelpModel);
          },
          (error) => {
            console.log('unable to fetch article by id...', error);
          }
        ),
      this.loginUtil
        .getTeamMetaInfoFromUserState()
        .pipe(debounceTime(100))
        .subscribe(
          (data) => {
            this.setTeamInfo();
          },
          (error) => {
            console.warn('Unable to fetch team meta info..');
          }
        ),
      this.interactiveGuideService
        .getIsInteractiveGuideOn()
        .subscribe((isInteractiveGuideOn) => {
          this.appUtil.setIsInteractiveGuideActive(isInteractiveGuideOn);
        }),
      this.headerService.getHeaderDescription().subscribe((description) => {
        this.headerDescription = Object.assign({}, description);
        this.cdr.markForCheck();
      }),
      this.loginUtil.getUserStatusAsObservable().subscribe(
        (status) => {
          if (status?.toLowerCase() === 'success') {
            if (!this.appUtil.isPathNeedsToBeSkipped()) {
              this.fetchHelpConfig();
            }
          }
        },
        (error) => {
          console.warn('unable to fetch user status from store');
        }
      )
    );

    this.subscriptions.push(
      PDashLocalStoreUtil.getProductViewTypeAsObservable().subscribe(
        (productType) => {
          if (!productType) {
            return;
          }
          const { displayName, tooltip, value } = this.productViewTypes?.find(
            (productViewType: any) =>
              productViewType?.value?.toLowerCase() === productType
          );
          this.selectedProductViewType = Object.assign(
            {},
            { displayName, tooltip, value }
          );
        }
      )
    );
  }

  ngOnInit() {
    this.productTours = this.appUtil.sort(
      this.getProductTourList(),
      'displayName'
    );
  }

  /**
   * Checks if help config is available in local store and
   * fetch them if its not available.
   *
   */
  fetchHelpConfig() {
    const userInfo = this.loginUtil.getUserState();
    const isAdmin =
      userInfo && userInfo?.type && userInfo?.type === USER_TYPES.PROSIMO_ADMIN
        ? true
        : false;

    // Skip fetching help config if the user is not logged in or type is an admin
    if (userInfo?.type && !isAdmin) {
      const helpConfig = PDashLocalStoreUtil.getHelpConfig();
      if (!helpConfig || !ObjectUtil.hasKeys(helpConfig)) {
        this.helpService
          .getPageConfigs()
          .pipe(take(1))
          .subscribe(
            (data) => {
              PDashLocalStoreUtil.updateHelpConfig(data);
            },
            (error) => {
              console.warn('Unable to fetch page config');
            }
          );
      }
    }
  }

  /**
   * Update the option access type based on the subscription types
   */
  setProductViewTypesStatus() {
    const subscriptionTypes = this.loginUtil.getSubscriptionType();

    this.showProductViewTypeInput =
      subscriptionTypes && subscriptionTypes?.length > 1 ? true : false;

    console.warn('step 1 - product view type dropdown setup - start ');
    const productViewTypeFromLocalStore =
      PDashLocalStoreUtil.getProductViewType();
    if (!productViewTypeFromLocalStore) {
      return;
    }
    console.warn(
      'step 2 - product view type from store',
      productViewTypeFromLocalStore
    );
    const { displayName, tooltip, value } = this.productViewTypes.find(
      (productViewType: any) =>
        productViewType?.value?.toLowerCase() ===
        productViewTypeFromLocalStore?.toLowerCase()
    );
    this.selectedProductViewType = Object.assign(
      {},
      { displayName, tooltip, value }
    );

    // Allow both subscription types for dev
    console.warn('step 3 - subscription types from store', subscriptionTypes);
    this.productViewTypes.forEach((productViewType) => {
      if (subscriptionTypes.includes(productViewType?.value)) {
        productViewType.isDisabled = false;
      } else {
        productViewType.isDisabled = true;
      }
    });
    this.productViewTypes = ObjectUtil.deepClone(this.productViewTypes);
    console.warn('step 4 - product view type dropdown setup - complete');
    this.cdr.markForCheck();
  }

  setTeamInfo() {
    const { team, name } = this.appUtil.getTeamMetaInfo();
    this.companyInfo = Object.assign(
      {},
      {
        name,
        team,
      }
    );
    this.cdr.markForCheck();
  }

  onRoute(config: any) {
    if (
      config?.name === HEADER_ICONS_CONFIG.USER_PROFILE.name &&
      this.profileTooltip?.disabledProfile
    ) {
      return;
    }
    this.appUtil.setCustomEventInfo({
      name: 'event_header_nav_link',
      metaInfo: {
        name: config?.name,
        path: config?.url,
      },
    } as UserBehaviorInsightsCustomEventInfo);
    this.router.navigateByUrl(this.getRouteURL(config));
  }

  onAction(event: Event) {
    this.headerService.triggerActionEvent(event);
  }

  logoutUser() {
    this.appUtil.logout();
  }

  getRouteURL(config: any): string {
    const path = config?.url;
    if (!path) {
      return;
    }

    let routeURL = typeof path === 'function' ? path() : path;
    if (this.headerConfig$ && this.headerConfig$.value.isMSPAdmin) {
      routeURL = config.mspURL;
    }
    return routeURL;
  }

  setHelpContent() {
    if (this.helpSelected) {
      // Set status as fetching
      this.helpService.setHelpConfig({
        displayStatus: DISPLAY_STATUS.FETCHING,
        displayContent: `<p style='text-align:center;margin:20px;'>Loading...</p>`,
      } as HelpModel);

      const pageConfig = PDashLocalStoreUtil.getPDashLocalStoreConfig();
      const helpId = pageConfig.helpId[this.selectedProductViewType.value];
      if (!helpId) {
        this.setNoArticleMsg();
        return;
      }
      const articleSub = this.headerService.getArticleById(helpId).subscribe(
        (data) => {
          if (data && !data.body) {
            this.setNoArticleMsg();
          } else {
            this.helpService.setHelpConfig({
              displayStatus: DISPLAY_STATUS.OPEN,
              displayContent: data && data.body ? data.body : '',
              displayTitle: data && data.title ? data.title : '',
            } as HelpModel);
          }
        },
        (error) => {
          this.helpService.setHelpConfig({
            displayStatus: DISPLAY_STATUS.OPEN,
            displayContent: `
            <p style='font:italic 12px ${FONT_FAMILY};text-align:center;margin:20px;color:${COLORS.RED}'>
              ${MESSAGES.ARTICLE_ACCESS_ERROR}
            </p>
            `,
          } as HelpModel);
        }
      );
      this.subscriptions.push(articleSub);
    } else {
      this.hideHelpPanel();
    }
  }

  /**
   * Checks the matched article list.
   * If its empty then sets the no article available message
   * If list has values, compile and sets the template to display
   * @param searchResults
   */

  compileSearchResultSection(searchResults: Array<ArticleSearchResult>) {
    if (!searchResults || !searchResults.length) {
      this.helpService.setHelpConfig({
        displayStatus: DISPLAY_STATUS.OPEN,
        displayContent: `
        <p style='font:italic 12px ${FONT_FAMILY};text-align:center;margin:20px;color:${COLORS.GREY1}'>
              ${MESSAGES.NO_ARTICLE_AVAILABLE}
        </p>
        `,
      } as HelpModel);
      return;
    }
    const searchResultTemplate = [];
    searchResultTemplate.push(`<ul id="search-results">`);
    searchResults.forEach((searchResult) => {
      searchResultTemplate.push(
        `<li data-id=${searchResult.id}> <mat-icon class="material-icons-outlined" title="Close...">article</mat-icon> <span>${searchResult.title}</span></li>`
      );
    });
    searchResultTemplate.push(`</ul>`);
    this.helpService.setHelpConfig({
      displayStatus: DISPLAY_STATUS.OPEN,
      displayContent: searchResultTemplate.join(''),
      displayTitle: '',
    } as HelpModel);
  }

  getArticlesBySearchTerm(searchTerm: string) {
    return this.headerService.getArticlesBySearchTerm(searchTerm);
  }

  hideHelpPanel() {
    this.helpService.setHelpConfig({
      displayStatus: DISPLAY_STATUS.CLOSE,
      displayContent: ``,
    } as HelpModel);
  }

  setNoArticleMsg() {
    this.helpService.setHelpConfig({
      displayStatus: DISPLAY_STATUS.OPEN,
      displayContent: `
      <p style='font:italic 12px ${FONT_FAMILY};text-align:center;margin:20px;color:${COLORS.GREY1}'>
        No help contents available for this page.</p>
      `,
    } as HelpModel);
  }

  redirectToAPIDoc() {
    this.appUtil.setCustomEventInfo({
      name: 'event_header_nav_link',
      metaInfo: {
        name: 'API_DOC_HELP',
      },
    } as UserBehaviorInsightsCustomEventInfo);
    const apiDocURL = this.loginUtil.isMSPAdmin()
      ? URL_PATHS.API_DOCS.MSP
      : URL_PATHS.API_DOCS.TEAM;
    this.appUtil.openNewWindow(apiDocURL);
  }

  redirectToHelpCenter() {
    const pageConfig = PDashLocalStoreUtil.getPDashLocalStoreConfig();
    this.appUtil.setCustomEventInfo({
      name: 'event_header_nav_link',
      metaInfo: {
        name: 'ZENDESK_HELP',
      },
    } as UserBehaviorInsightsCustomEventInfo);
    if (pageConfig?.rootConfig?.helpId) {
      const articleId =
        pageConfig?.rootConfig?.helpId[this.selectedProductViewType?.value];
      const articlePath = CONSTANTS.HELP.PATH_TO_ARTICLE(
        APP_LOCALE.EN_US,
        articleId
      );
      this.appUtil.openHelpURL(articlePath);
    } else {
      this.appUtil.openNewWindow(CONSTANTS.HELP.PATH_TO_HELP_CENTER);
    }
  }

  redirectToVideoTutorials() {
    this.appUtil.openNewWindow(CONSTANTS.HELP.PATH_TO_HELP_CENTER);
  }

  /**
   *
   * @param key - key to get interactive config
   *
   */
  startInteractiveGuide(key: string) {
    this.appUtil.setCustomEventInfo({
      name: 'event_header_nav_link',
      metaInfo: {
        name: 'INTERACTIVE_GUIDE',
        type: key,
      },
    } as UserBehaviorInsightsCustomEventInfo);

    this.interactiveGuideService.setInteractiveGuideConfig({
      steps: INTERACTIVE_GUIDE_CONFIGS[key]?.manual,
    });

    this.updateQueryParams(key);
  }

  updateQueryParams(key: string) {
    switch (key) {
      case 'INSIGHTS_USER_RISK':
        this.appUtil.setIsInteractiveGuideActive(true);
        break;

      default:
        this.appUtil.setIsInteractiveGuideActive(false);
        break;
    }
  }

  togglePrintIcon(selectedPage: string) {
    this.pdfSelected = false;
    let state = false;
    if (PRINTABLE_PAGES.has(selectedPage)) {
      state = true;
    }
    this.displayPrintIcon$.next(state);
    this.cdr.markForCheck();
  }

  /**
   * Export PDF of insights
   */
  generatePDF(): void {
    if (this.isPrintPolling.value) {
      return;
    }
    this.pdfTooltip = '';
    // PDashLocalStoreUtil.updateIsPDFPrintView(true);
    const { module } = this.helpTextConfig$.value || {};
    const currentPage = module?.parent;
    const domain = location.origin;
    const pathURL = location.href;
    const pdfDownloadInfo: PdfDownload = {
      module,
      currentPage,
      pathURL,
      domain,
      isPrintPolling: this.isPrintPolling,
    };
    if (PRINTABLE_PAGES.has(currentPage)) {
      this.appUtil.generatePDF(pdfDownloadInfo);
    }
  }

  /**
   * Method to handle product tour request from header menu
   */
  openProductTour(tour: any) {
    try {
      const url = `${tour?.url}`;
      const isSummary = tour?.key === PRODUCT_TOUR_KEYS.DEBUG_LOW_UX;
      const isUserRisk = tour?.key === PRODUCT_TOUR_KEYS.INSIGHTS_USER_RISK;

      const isSameUrl = this.router.url === url;
      /**
       * If user is already in the view/page, do nothing
       */
      if (isSameUrl && this.appUtil.getIsInteractiveGuideActive()) {
        this.appUtil.setIsInteractiveGuideActive(false);
        return;
      }

      /**
       * If user is already on same page and tour is disable and user wish to see the guide then just activate the tour, no need for routing
       */
      if (isSameUrl && !this.appUtil.getIsInteractiveGuideActive()) {
        this.startInteractiveGuide(tour?.key);
        return;
      }

      /**
       * if user is in a different page, activate interactive guide
       */
      this.appUtil.setIsInteractiveGuideActive(true);

      /**
       * Insights -> summary and user-risk needs set the active tab
       * For all others we just need to route the request to the corresponding URL
       */
      if (isSummary || isUserRisk) {
        const key = isSummary ? 'users' : isUserRisk ? 'risk' : '';
        const { type, tab, subMenu } = MAIN_DASHBOARD_ROUTES[key];
        this.appUtil.setIsActiveTab(true);
        this.appUtil.routeToDashboardByPath(url, type, tab, subMenu, false);
      } else {
        this.appUtil.routeTo(url);
      }
    } catch (err) {
      console.warn(`Tour not found or wrong url`);
    }
  }

  getProductTourList() {
    const productTourKeys = Object.keys(INTERACTIVE_GUIDE_CONFIGS);
    const tourList = [];

    productTourKeys?.forEach((key) => {
      const url = INTERACTIVE_GUIDE_CONFIGS[key]?.['url'];
      const displayName = INTERACTIVE_GUIDE_CONFIGS[key]?.['label'];
      if (url && displayName) {
        tourList.push({
          key,
          url,
          displayName,
        });
      }
    });

    return tourList;
  }

  /**
   * updates selected product view type and
   */
  updateProductViewType(viewType: string) {
    const { displayName, tooltip, value, isDisabled } =
      this.productViewTypes.find(
        (productViewType: any) =>
          productViewType?.value?.toLowerCase() === viewType?.toLowerCase()
      );
    if (isDisabled) {
      return;
    }

    this.selectedProductViewType = Object.assign(
      {},
      { displayName, tooltip, value }
    );
    PDashLocalStoreUtil.updateProductViewType(viewType);
    this.cdr.markForCheck();
  }

  setUIThemeMode() {
    this.appService.toggleDarkTheme();
    this.selectedUIThemeMode = PDashLocalStoreUtil.getUIThemeMode();
  }

  updateDashboardHost(credentials: DevCredentialModel) {
    if (
      !this.credentialConfig?.some(
        (credential) => credential.host === credentials.host
      )
    ) {
      return;
    }

    this.selectedDashboardHost = credentials;
    CONSTANTS.DASHBOARD_HOST = credentials.host;
    CONSTANTS.DASHBOARD_API_TOKEN = credentials.token;
    localStorage.setItem('host', credentials.host);
    this.appUtil.reloadPage(this.appUtil.getPath(), true);
  }

  ngOnDestroy(): void {
    this.headerService.setStopPolling();
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }
}
