import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { HeaderService } from './header.service';
import { BehaviorSubject, ReplaySubject, Subject, timer } from 'rxjs';
import {
  URL_PATHS,
  APP_LOCALE,
  UI_THEME_MODES,
  HELP_ARTICLE_ID,
  IMAGE_PATHS,
  MENU_NAMES,
  USER_TYPES,
} from '@app/common/util/constants';
import {
  CONTACT_SALES,
  HEADER_ICONS_CONFIG,
  PRODUCT_VIEW_TYPES,
  TOOLTIP_POSITION,
} from './header.constants';
import { HELP_ACTION, MenuConfig } from './header.interface';
import { L2HeaderModel } from '../l2header/l2header.model';
import { DevCredentialModel } from '@app/common/models';
import { PDashLocalStoreUtil } from '@app/state/web-pdash.util';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  Event,
} from '@angular/router';
import { AppService } from '@app/app.service';
import { LoginUtil } from '@app/login/login-util';
import { CONSTANTS } from 'environments/environment';
import { creds } from 'environments/creds';
import { AppUtil } from '@app/common/util/app-util';
import {
  ObjectUtil,
  UserBehaviorInsightsCustomEventInfo,
} from '@prosimoio/services';
import { NavigationBarService } from '../navigation-bar/navigation-bar.service';
import {
  NavigationBarModel,
  NavMenuItem,
} from '../navigation-bar/navigation-bar.config';
import { MainHeaderConfig } from '@prosimoio/components/lib/v2/main-header/main-header.model';
import { switchMap, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
  uiThemeMode: UI_THEME_MODES = PDashLocalStoreUtil?.getUIThemeMode();
  uiThemeClass: string = PDashLocalStoreUtil?.getUIThemeClass(this.uiThemeMode);
  UI_THEME_MODES_COPY = UI_THEME_MODES;
  TOOLTIP_POSITION = TOOLTIP_POSITION;
  IMAGE_PATHS = IMAGE_PATHS;
  BETA_ICON = IMAGE_PATHS.COMMON.BETA_ICON;
  BETA_IMAGES = {
    selected: IMAGE_PATHS.COMMON.BETA_SELECTED,
    unselected: IMAGE_PATHS.COMMON.BETA_DESELECTED,
  };
  CONTACT_SALES = CONTACT_SALES;
  tasksConfig = HEADER_ICONS_CONFIG.TASKS;
  alertsConfig = HEADER_ICONS_CONFIG.ALERTS;
  userProfileConfig = HEADER_ICONS_CONFIG.USER_PROFILE;
  helpConfig = HEADER_ICONS_CONFIG.HELP;
  productViewTypes = PRODUCT_VIEW_TYPES;
  credentialConfig = creds;
  public headerConfig$ = new BehaviorSubject(new L2HeaderModel().init());
  public navbarConfig$ = new ReplaySubject<NavigationBarModel>();
  public navbarState$ = new BehaviorSubject<boolean>(false);
  private subscriptions = [];
  profileTooltip;
  startedUrls;
  routesMap: Map<string, NavMenuItem>;
  selectedDashboardHost: DevCredentialModel;
  selectedProductViewType = {
    displayName: '',
    tooltip: '',
    value: '',
  };
  showProductViewTypeInput: boolean = false;
  isDev: boolean = false;
  isTrialAccount: boolean = false;
  selectedUIThemeMode = '';
  tooltipThemeClass: string;
  navMenuConfig;
  navMenuConfigCopy;
  selectedMenuItem;
  selectedActionItem;
  externalReports;
  mainHeaderConfig: MainHeaderConfig;
  constructor(
    private headerService: HeaderService,
    private loginUtil: LoginUtil,
    private router: Router,
    private appService: AppService,
    private cdr: ChangeDetectorRef,
    private appUtil: AppUtil,
    private navigationService: NavigationBarService
  ) {
    this.isDev = this.appUtil.isDev();
    this.selectedUIThemeMode = PDashLocalStoreUtil.getUIThemeMode();
    this.selectedDashboardHost = this.credentialConfig?.find(
      (credential) => credential.host === CONSTANTS.DASHBOARD_HOST
    );
    this.subscriptions.push(
      this.navigationService
        .getNavigationBarConfig()
        .subscribe((config: NavigationBarModel) => {
          this.navMenuConfigCopy = ObjectUtil.deepClone(config);
          if (this.loginUtil.getUserAccountType() === USER_TYPES.TEAM_USER) {
            if (!this.externalReports?.length) {
              this.fetchExternalReports(config);
            } else {
              this.onMenuConfig(config, this.externalReports);
            }
          } else {
            this.onMenuConfig(config, []);
          }
        }),

      this.navigationService
        .getNavigationDisplayState()
        .subscribe((state: boolean) => {
          // hide/show based on the state
          this.navbarState$.next(state);
        }),

      this.headerService
        .getL2HeaderConfig()
        .pipe()
        .subscribe((config) => {
          this.headerConfig$.next(config);
          this.updateMainHeaderConfig();
        }),

      this.router.events.subscribe((event: Event) => {
        // update selected menu item based on browser url
        if (event instanceof NavigationStart) {
          const eventUrl = event?.url;
          this.selectedMenuItem = null;
          const selectedItem = this.getSelectedMenuItem(
            eventUrl,
            this.routesMap
          );
          if (selectedItem) {
            this.startedUrls = selectedItem.routeUrls || [];
          }
        }
        if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel
        ) {
          const endedUrl = event.url;
          if (this.isValidRoute(this.startedUrls, endedUrl)) {
            this.selectedMenuItem = this.routesMap?.get(this.startedUrls?.[0]);
            this.updateMainHeaderConfig();
          }
        }

        if (event instanceof NavigationError) {
          // Present error to user and unselect the navigation menu item
          console.warn('Navigation Failed', event?.error);
          this.selectedMenuItem = null;
        }
        this.cdr.markForCheck();
      }),

      PDashLocalStoreUtil.getUIThemeModeAsObservable().subscribe((mode) => {
        this.uiThemeMode = mode;
        this.selectedUIThemeMode = mode;
        this.uiThemeClass = PDashLocalStoreUtil?.getUIThemeClass(
          this.uiThemeMode
        );

        this.tooltipThemeClass = `cdk-component-container--${this.uiThemeMode?.toLocaleLowerCase()}-theme-dark`;
        this.navigationService.setNavigationBarConfig(
          this.navMenuConfig,
          this.selectedUIThemeMode
        );
        this.updateMainHeaderConfig();
        this.cdr?.markForCheck();
      }),

      this.headerService.getL2HeaderUserConfig().subscribe((config) => {
        this.profileTooltip = config;
        this.setProductViewTypesStatus();
      }),
      PDashLocalStoreUtil.getProductViewTypeAsObservable().subscribe(
        (productType) => {
          if (!productType) {
            return;
          }
          const { displayName, tooltip, value } = this.productViewTypes?.find(
            (productViewType: any) =>
              productViewType?.value?.toLowerCase() === productType
          );
          this.selectedProductViewType = Object.assign(
            {},
            { displayName, tooltip, value }
          );
          this.updateMainHeaderConfig();
        }
      )
    );
  }

  ngOnInit() {
    if (this.appUtil.isValidTeamUser()) {
      const stopPolling$ = new Subject<void>();
      timer(0, 5000)
        .pipe(
          takeUntil(stopPolling$),
          switchMap(() => this.headerService.searchAlertData())
        )
        .subscribe({
          next: ({ total = 0, records = [] }) => {
            if (!records?.length) {
              stopPolling$.next();
            }
            this.updateBadgeCountInAlertConfig(total);
          },
        });
    }
  }

  updateBadgeCountInAlertConfig(total: number = 0) {
    const badgeText = total > 0 ? '.' : ``;
    if (badgeText !== this.alertsConfig.badgeText) {
      Object.assign(this.alertsConfig, { badgeText });
      this.updateMainHeaderConfig();
    }
  }

  fetchExternalReports(navMenuConfig) {
    this.headerService
      .fetchAvailableExternalReports()
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.externalReports = data;
          this.appUtil.setExternalReports(data);
          this.onMenuConfig(navMenuConfig, data);
        },
        (error) => {
          this.onMenuConfig(navMenuConfig, []);
          console.warn('unable to fetch external reports...');
        }
      );
  }

  onMenuConfig(config, externalReports) {
    if (config?.menuItems?.length) {
      const isProd = this.appUtil.isProd();
      const isExternalReports = externalReports?.length;
      const { engLabsEnabled } = this.loginUtil.getUserState();

      if (!engLabsEnabled || (isProd && !isExternalReports)) {
        config.menuItems = config?.menuItems?.filter(
          (menu) => menu.name !== MENU_NAMES.PLAYGROUND
        );
      } else {
        config = this.navMenuConfigCopy;
      }
    }

    this.navMenuConfig = config;
    this.navMenuConfigCopy = ObjectUtil.deepClone(config);
    this.isTrialAccount = this.appUtil.isTrialAccount();
    config.menuItems = this.updateMenuItemConfigs(config?.menuItems);
    // set up the navbar config
    this.navbarConfig$.next(this.navMenuConfig);
    this.selectedMenuItem = this.setSelectedMenuItem(config, config?.menuItems);
    this.selectedActionItem = this.setSelectedMenuItem(
      config,
      config?.actionItems
    );
    this.routesMap = this.generateRoutesMap(config?.menuItems);
    this.updateMainHeaderConfig();
    this.cdr.markForCheck();
  }

  updateMenuItemConfigs(menuItemConfigs: Array<NavMenuItem>) {
    const { wordmarkLogo, abstractmarkLogo, name } =
      this.appUtil.getTeamMetaInfo();
    if (name?.toUpperCase() !== 'PROSIMO') {
      this.navMenuConfig.logo.imgUrl = wordmarkLogo || abstractmarkLogo;
    }

    return menuItemConfigs.map((config) => {
      config.isDisable = config?.isDisableOnTrial && this.isTrialAccount;
      return config.isMain
        ? Object.assign({}, config, {
            imgUrl: wordmarkLogo,
            selectedImgUrl: wordmarkLogo,
            name: 'Home',
          })
        : config;
    });
  }

  generateRoutesMap(menuItems: Array<NavMenuItem>): Map<string, NavMenuItem> {
    const routesMap = new Map<string, NavMenuItem>();
    menuItems.forEach((item) => {
      if (item?.routeUrls?.length) {
        this.addMultipleRoutes(item.routeUrls, item, routesMap);
      }
    });
    return routesMap;
  }

  addMultipleRoutes(
    urls: Array<string>,
    item: NavMenuItem,
    routesMap: Map<string, NavMenuItem>
  ) {
    urls.forEach((url) => routesMap.set(url, item));
  }

  setSelectedMenuItem(config: NavigationBarModel, items) {
    const currentPath = this.appUtil.getPath();
    let matchedMenuConfig = null;
    const selectedMenu = items?.find((menu) => {
      if (menu?.routeUrls?.length) {
        if (menu?.routeUrls) {
          const routeURLs = menu?.routeUrls;
          return routeURLs.find((url) => {
            if (currentPath?.indexOf(url) !== -1) {
              matchedMenuConfig = menu;
              return true;
            }
            return false;
          });
        }
      }
      const subMenus = menu?.subMenu;
      if (subMenus?.length) {
        return subMenus.find((subMenu) => {
          const routeURLs = subMenu?.routeUrls;
          return routeURLs.find((url) => {
            if (currentPath?.indexOf(url) !== -1) {
              matchedMenuConfig = subMenu;
              return true;
            }
            return false;
          });
        });
      }
    });
    return matchedMenuConfig;
  }

  getSelectedMenuItem(url: string, routesMap: Map<string, NavMenuItem>) {
    let menuItem;
    routesMap?.forEach((value, key) => {
      if (url.includes(key)) {
        menuItem = routesMap.get(key);
      }
    });
    return menuItem;
  }

  isValidRoute(urls: string[] = [], endedUrl: string): boolean {
    return urls.some((url) => endedUrl.includes(url));
  }

  /**
   * Update the option access type based on the subscription types
   */
  setProductViewTypesStatus() {
    const subscriptionTypes = this.loginUtil.getSubscriptionType();
    this.showProductViewTypeInput = Boolean(subscriptionTypes?.length > 1);
    const productViewTypeFromLocalStore =
      PDashLocalStoreUtil.getProductViewType();
    if (!productViewTypeFromLocalStore) {
      return;
    }

    const { displayName, tooltip, value } = this.productViewTypes?.find(
      (productViewType: any) =>
        productViewType?.value?.toLowerCase() ===
        productViewTypeFromLocalStore?.toLowerCase()
    );
    this.selectedProductViewType = Object.assign(
      {},
      { displayName, tooltip, value }
    );

    this.productViewTypes?.forEach((productViewType) => {
      if (subscriptionTypes.includes(productViewType?.value)) {
        productViewType.isDisabled = false;
      } else {
        productViewType.isDisabled = true;
      }
    });
    this.productViewTypes = ObjectUtil.deepClone(this.productViewTypes);
    this.cdr.markForCheck();
  }

  onRoute(config: any) {
    this.appUtil.setCustomEventInfo({
      name: 'event_header_nav_link',
      metaInfo: {
        name: config?.name,
        path: config?.url,
      },
    } as UserBehaviorInsightsCustomEventInfo);
    const url = this.getRouteURL(config);
    this.selectedActionItem = config;
    this.router.navigateByUrl(url);
  }

  logoutUser() {
    this.appUtil.logout();
  }

  getRouteURL(config: any): string {
    const path = config?.url;
    if (!path) {
      return;
    }

    let routeURL = typeof path === 'function' ? path() : path;
    if (this.headerConfig$ && this.headerConfig$.value.isMSPAdmin) {
      routeURL = config.mspURL;
    }
    return routeURL;
  }

  redirectToAPIDoc() {
    this.appUtil.setCustomEventInfo({
      name: 'event_header_nav_link',
      metaInfo: {
        name: 'API_DOC_HELP',
      },
    } as UserBehaviorInsightsCustomEventInfo);
    const apiDocURL = this.loginUtil.isMSPAdmin()
      ? URL_PATHS.API_DOCS.MSP
      : URL_PATHS.API_DOCS.TEAM;
    this.appUtil.openNewWindow(apiDocURL);
  }

  redirectToVideoTutorials() {
    const articlePath = CONSTANTS.HELP.PATH_TO_ARTICLE(
      APP_LOCALE.EN_US,
      HELP_ARTICLE_ID.VIDEO_TUTORIAL
    );
    this.appUtil.openHelpURL(articlePath);
  }

  redirectToHelpNLearn() {
    this.appUtil.openNewWindow(CONSTANTS.HELP.PATH_TO_HELP_CENTER);
  }

  redirectToReleaseNotes() {
    // TODO relaese note
  }

  /**
   * updates selected product view type and
   */
  updateProductViewType(viewType: string) {
    const { displayName, tooltip, value, isDisabled } =
      this.productViewTypes?.find(
        (productViewType: any) =>
          productViewType?.value?.toLowerCase() === viewType?.toLowerCase()
      );
    if (isDisabled) {
      return;
    }

    this.selectedProductViewType = Object.assign(
      {},
      { displayName, tooltip, value }
    );
    PDashLocalStoreUtil.updateProductViewType(viewType);
    this.updateMainHeaderConfig();
    this.cdr.markForCheck();
  }

  setUIThemeMode(event: any) {
    this.appService.toggleDarkTheme();
    this.selectedUIThemeMode = PDashLocalStoreUtil.getUIThemeMode();
    this.navigationService.setNavigationBarConfig(
      this.navMenuConfig,
      this.selectedUIThemeMode
    );
    this.cdr.markForCheck();
  }

  updateDashboardHost(credentials: DevCredentialModel) {
    if (
      !this.credentialConfig?.some(
        (credential) => credential?.host === credentials?.host
      )
    ) {
      return;
    }

    this.selectedDashboardHost = credentials;
    CONSTANTS.DASHBOARD_HOST = credentials?.host;
    CONSTANTS.DASHBOARD_API_TOKEN = credentials?.token;
    localStorage.setItem('host', credentials?.host);
    this.appUtil.reloadPage(this.appUtil.getPath(), true);
    this.updateMainHeaderConfig();
  }

  onMenuAction(menu: MenuConfig): void {
    this.navMenuConfig.menuItems?.forEach((item) => {
      item.isSelected = item === menu;
    });
    this.cdr.markForCheck();
    this.selectedActionItem = null;
    this.router.navigateByUrl(menu.fullPath);
  }

  updateMainHeaderConfig(): void {
    this.setProductViewTypesStatus();
    const mainHeaderConfig = {
      navMenuConfig: this.navMenuConfig,
      selectedMenuItem: this.selectedMenuItem,
      selectedActionItem: this.selectedActionItem,
      homeLogo: IMAGE_PATHS.NAVIGATION.HOME_DESELECTED,
      homeLogoSelected: IMAGE_PATHS.NAVIGATION.HOME_SELECTED,

      showProductViewTypeInput: this.showProductViewTypeInput,
      selectedProductViewType: this.selectedProductViewType,
      productViewTypes: this.productViewTypes,
      contactSalesText: CONTACT_SALES,
      tooltipPosition: TOOLTIP_POSITION.BEFORE,

      credentialConfig: this.credentialConfig,
      selectedDashboardHost: this.selectedDashboardHost,
      isDev: this.isDev,
      uiThemeMode: this.UI_THEME_MODES_COPY,
      selectedUIThemeMode: this.selectedUIThemeMode,
      tooltipThemeClass: this.tooltipThemeClass,

      tasksConfig: this.tasksConfig,
      alertsConfig: this.alertsConfig,
      helpConfig: this.helpConfig,
      headerConfig: this.headerConfig$.value,
      uiThemeClass: this.uiThemeClass,
      userProfileConfig: this.userProfileConfig,
      profileTooltip: this.profileTooltip,
      themeImgUrl: IMAGE_PATHS.NAVIGATION.THEME,
      menuIconUrl: IMAGE_PATHS.NAVIGATION.MENU,
      menuIconCloseUrl: IMAGE_PATHS.NAVIGATION.MENU_CLOSE,
    };
    this.mainHeaderConfig = ObjectUtil.deepClone(mainHeaderConfig);
  }

  onHelpActions(type): void {
    switch (type) {
      case HELP_ACTION.HELP_AND_LEARN:
        this.redirectToHelpNLearn();
        break;
      case HELP_ACTION.VIDEOS:
        this.redirectToVideoTutorials();
        break;
      case HELP_ACTION.RELEASE_NOTES:
        this.redirectToReleaseNotes();
        break;
      case HELP_ACTION.API_DOC:
        this.redirectToAPIDoc();
        break;
      case HELP_ACTION.LOGOUT:
        this.logoutUser();
        break;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }
}
