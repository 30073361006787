<div
  class="d-flex justify-column-start align-items-center py-3 number-operator-container"
>
  <div class="col-0" *ngIf="operatorOptions?.length">
    <div class="ig__range-options">
      <select class="border-0 border-bottom1" [formControl]="operatorKey">
        <option *ngFor="let option of operatorOptions" [value]="option?.value">
          {{ option?.displayName }}
        </option>
      </select>
    </div>
  </div>
  <div class="col-0">
    <div class="ig__range-options">
      <select class="border-0" [formControl]="operatorControl">
        <option *ngFor="let option of options" [value]="option?.value">
          {{ option?.displayName }}
        </option>
      </select>
    </div>
  </div>
  <div class="col px-0 pr-2 ig__range-input" >
    <input
      class="border-top-0 border-left-0 border-right-0 pl-2" [class.w-75]="operatorControl?.value==='range'"
      type="number"
      [formControl]="fromControl"
      placeholder="{{ displayRange ? 'From' : '' }}"
    />
  </div>
  <div class="col px-0 pl-2" *ngIf="displayRange" >
    <input
      class="border-top-0 border-left-0 border-right-0 pl-2" [class.w-75]="operatorControl?.value==='range'"
      type="number"
      [formControl]="toControl"
      placeholder="{{ displayRange ? 'To' : '' }}"
    />
  </div>
</div>
