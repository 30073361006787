export interface TeamUser {
  username: string;
  email: string;
}

export interface UserGroup {
  id: string;
  name: string;
}

export interface OnboardedApp {
  id?: string;
  appName: string;
  isAgentApp?: boolean;
  appType?: string;
}

export interface ClientFilters {
  users?: Array<TeamUser>;
  userGroups?: Array<UserGroup>;
  selectedUsers?: Array<TeamUser>;
  selectedUserGroups?: Array<UserGroup>;
  type?: string;
  viewMode?: string;
  clientApps?: Array<OnboardedApp>;
}

export const TeamUserMasterSelectConfig: TeamUser = {
  username: 'all',
  email: 'all'
}


export const UserGroupMasterSelectConfig: UserGroup = {
  id: 'all',
  name: 'all'
}
