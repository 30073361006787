<pcomp-loader
  [paddingTop]="'25vh'"
  [absolutePosition]="true"
  [loaderStatus]="showLoader"
></pcomp-loader>
<div class="bg-light-shade h-100 d-flex flex-column mr-0 p-3 flex-fill">
  <div class="d-flex form-section-title clr-grey7">Sync From IDP</div>
  <div
    *ngIf="syncFromIDPConfig?.importInProgressConfig?.importStatus"
    class="error-message"
  >
    {{ syncFromIDPConfig?.importInProgressConfig?.importParameter }}
    import from IDP is in progress
  </div>
  <div class="h-100 mt-4 overflow-auto">
    <ng-container>
      <form
        *ngIf="
          importFromIDPForm &&
          !syncFromIDPConfig?.importInProgressConfig?.importStatus
        "
        [formGroup]="importFromIDPForm"
        class="mr-4"
      >
        <div>
          <!-- Integration Type field -->
          <div class="d-flex flex-column w-50">
            <div class="d-flex">
              <mat-label class="">Select IDP</mat-label>
              <div class="ml-2">
                <pcomp-mat-error-info-tooltip
                  [errorMessageConfig]="{
                    isShowTooltip: false,
                    errorCondition: idps?.invalid && idps.touched,
                    errorMessage: REQUIRED
                  }"
                  [uiThemeMode]="uiThemeMode"
                ></pcomp-mat-error-info-tooltip>
              </div>
            </div>

            <div
              class="d-flex flex-fill flex-column mt-2 w-100"
              [ngClass]="{
                'invalid-range': idps?.invalid && idps.touched
              }"
            >
              <pcomp-mat-select-search
                [config]="selectSearchConfigForIDPS"
                [parentForm]="importFromIDPForm"
                [disableStatus]="false"
                [formControlNameInput]="idps"
                controlName="idps"
                (emitSelections)="onChangeIDP($event)"
              ></pcomp-mat-select-search>
            </div>
          </div>
          <div *ngIf="importFromIDPForm.get('parameters')">
            <div class="d-flex align-items-baseline">
              <div class="form__field-label clr-grey7">Select Parameters</div>
              <div class="ml-2">
                <pcomp-mat-error-info-tooltip
                  [errorMessageConfig]="{
                    isShowTooltip: false,
                    errorCondition:
                      importFromIDPForm
                        .get('parameters')
                        .hasError('required') &&
                      importFromIDPForm.get('parameters').touched,
                    errorMessage:
                      errorMessagesFormat?.ERROR_MESSAGES
                        ?.SYNC_IDP_PARAMETER_ERROR_MESSAGE
                  }"
                  [uiThemeMode]="uiThemeMode"
                ></pcomp-mat-error-info-tooltip>
              </div>
            </div>
            <div class="error-message" *ngIf="inActiveParameters?.length">
              Sync is in progress for greyed out parameters
            </div>
            <div class="ml-n3">
              <mat-selection-list
                disableRipple
                formControlName="parameters"
                #parametersValue
              >
                <mat-list-option
                  class="clr-grey7"
                  *ngFor="let item of syncFromIDPConfig?.importParameters"
                  [value]="item?.key"
                  [checkboxPosition]="before"
                  [disabled]="
                    (item?.status | lowercase) ===
                    (IDP_IMPORT_PARAMETER_STATUS.ACTIVE | lowercase)
                      ? false
                      : true
                  "
                >
                  {{ item?.name }}
                </mat-list-option>
              </mat-selection-list>
              <div
                *ngIf="
                  isShowParameterValidationError &&
                  !parametersValue.selectedOptions.selected.length
                "
                class="error-message"
              >
                Select at least one parameter
              </div>
            </div>
          </div>
        </div>
      </form>
    </ng-container>
  </div>
</div>
