<div class="d-flex justify-content-center align-items-center vh-100">
  <div class="reserved_ip_ranges__container mt-4 align-center">
    <app-lightbox-form-template [data]="lbData">
      <div class="header p-4">Prosimo Reserved IP Ranges</div>
      <div class="pl-4 pr-4 pb-4">
        <!-- <table class="custom-table">
            <thead>
              <tr>
                <th class="custom-table__header">Profile</th>
                <th class="custom-table__header">Reserved Subnet</th>
                <th class="custom-table__header">PAS (Secure/Agent?)</th>
                <th class="custom-table__header">Transit</th>
                <th class="custom-table__header">Connector</th>
                <th class="custom-table__header">Service</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of reservedIPRanges">
                <td>{{ item?.profile }}</td>
                <td>{{ item?.reservedSubnet }}</td>
                <td>{{ item?.pas }}</td>
                <td>{{ item?.transit }}</td>
                <td>{{ item?.conector }}</td>
                <td>{{ item?.service }}</td>
              </tr>
            </tbody>
          </table> -->
        <table class="custom-table">
          <thead>
            <tr>
              <!-- <th class="custom-table__header">Profile</th> -->
              <th class="custom-table__header">Reserved Subnets</th>
              <!-- <th class="custom-table__header">PAS (Secure/Agent?)</th>
                <th class="custom-table__header">Transit</th>
                <th class="custom-table__header">Connector</th>
                <th class="custom-table__header">Service</th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of reservedIPRanges">
              <td>{{ item }}</td>
            </tr>
          </tbody>
        </table>
        <!-- <ng-container *ngIf="!isViewFullList">
          <table class="custom-table">
            <thead>
              <tr>
                <th class="custom-table__header">Profile</th>
                <th class="custom-table__header">Reserved Subnet</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of reservedIPRanges">
                <td>{{ item.profile }}</td>
                <td>{{ item.reservedSubnet }}</td>
              </tr>
            </tbody>
          </table>
        </ng-container> -->
      </div>
      <div class="action_btn d-flex justify-content-center pb-4">
        <button
          class="button py-2 px-4"
          mat-raised-button
          color="primary"
          (click)="hideLightBox()"
        >
          Okay
        </button>
      </div>
      <!-- <div class="d-flex justify-content-center pb-4" *ngIf="!isViewFullList">
        <span class="link-text" (click)="viewFullList()">View full list</span>
      </div> -->
    </app-lightbox-form-template>
  </div>
</div>
