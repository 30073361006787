import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { enableAkitaProdMode, persistState } from '@datorama/akita';
import { LOCAL_STORE } from '@app/common/util/constants';
import { PDashLocalStoreUtil } from '@app/state/web-pdash.util';

if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}

const localStoreInst = persistState({
  key: LOCAL_STORE.name,
  persistOnDestroy: false,
});
const providers = [{ provide: 'pdashStorage', useValue: localStoreInst }];
PDashLocalStoreUtil.setLocalStoreInst(localStoreInst);

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
