export class ResponseModel {
  code: number;
  message: string;
  data?: any;

  constructor(code: number, message: string, data: string = '') {
    this.code = code;
    this.message = message;
    this.data = data ? data : {};
  }
}
