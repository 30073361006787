import { Type } from '@angular/core';

export class LightboxDynaCompConfig {
  constructor(
    public type: string,
    public component?: Type<any>,
    public data?: any,
    public template?: string
  ) {}
}

export enum LightboxContentType {
  COMPONENT = 'COMPONENT',
  TEMPLATE = 'TEMPLATE',
}
