<ul>
  <li
    [ngClass]="(passwordStrength$ | async)[0]"
    class="shadow bg-white rounded"
  ></li>
  <li
    [ngClass]="(passwordStrength$ | async)[1]"
    class="shadow bg-white rounded"
  ></li>
  <li
    [ngClass]="(passwordStrength$ | async)[2]"
    class="shadow bg-white rounded"
  ></li>
  <li
    [ngClass]="(passwordStrength$ | async)[3]"
    class="shadow bg-white rounded"
  ></li>
</ul>
