<div class="mx-4 my-2">
  <p class="clr-grey5 page__hint">
    <img
      class="error-icon"
      *ngIf="errorPayload?.errorStep === step"
      [src]="errorIcon"
    />
    {{ hint }}
  </p>
  <mat-accordion
    [formGroup]="definitionForm"
    [multi]="true"
    class="bordered-panel"
  >
    <mat-expansion-panel
      [expanded]="true"
      [hideToggle]="true"
      class="form-panel"
    >
      <mat-expansion-panel-header class="form-panel__header pe-none arrow">
        <mat-panel-title>Network details</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="d-flex flex-column">
        <mat-label class="d-flex mb-2 form__label">
          Where is your network hosted?
        </mat-label>
        <mat-radio-group
          class="mb-2"
          [formControl]="definitionForm.controls.cloudType"
        >
          <mat-radio-button
            disabled
            color="primary"
            value="public"
            class="ml-n1 pl-0"
          >
            Public
          </mat-radio-button>
          <mat-radio-button color="primary" value="private" class="pl-2">
            Private
          </mat-radio-button>
        </mat-radio-group>

        <div
          *ngIf="
            definitionForm.controls.cloudType.value ===
            COPY_NETWORK_HOSTED_TYPE.PRIVATE
          "
          class="d-flex flex-row mb-3"
        >
          <div class="d-flex flex-column pr-2 mr-1 w-50">
            <div class="d-flex">
              <mat-label class="mb-2 form__label"> Geography </mat-label>
              <!-- <pcomp-mat-error-info-tooltip
              class="ml-2"
              [errorMessageConfig]="{
                isShowTooltip: false,
                errorCondition:
                  definitionForm.controls.cloudRegion?.errors?.cluster,
                errorMessage: 'Edge Not Deployed in this region'
              }"
              [uiThemeMode]="uiThemeMode"
            ></pcomp-mat-error-info-tooltip> -->
            </div>
            <pcomp-mat-select-search
              *ngIf="
                !loading.clouds &&
                this.privateClouds.length > 0 &&
                geographyControl
              "
              [config]="selectGeographyConfig"
              [disableStatus]="!isEditable"
              [formControlNameInput]="geographyControl"
              controlName="geographyControl"
              class="mat-form-field-wrapper-remove-padding"
            ></pcomp-mat-select-search>
            <div *ngIf="loading.clouds">Loading cloud accounts...</div>
            <mat-error
              *ngIf="!loading.clouds && this.privateClouds.length === 0"
            >
              No private clouds found
            </mat-error>
          </div>
          <div
            class="d-flex flex-column pl-2 ml-1 w-50"
            *ngIf="
              definitionForm.controls.cloudType.value ===
                COPY_NETWORK_HOSTED_TYPE.PRIVATE && geographyControl.value
            "
          >
            <div class="d-flex">
              <mat-label class="mb-2 form__label"> Private Cloud </mat-label>
              <!-- <pcomp-mat-error-info-tooltip
              class="ml-2"
              [errorMessageConfig]="{
                isShowTooltip: false,
                errorCondition:
                  definitionForm.controls.cloudRegion?.errors?.cluster,
                errorMessage: 'Edge Not Deployed in this region'
              }"
              [uiThemeMode]="uiThemeMode"
            ></pcomp-mat-error-info-tooltip> -->
            </div>
            <pcomp-mat-select-search
              *ngIf="!loading.clouds && this.privateClouds.length > 0"
              [parentForm]="definitionForm"
              [config]="selectDCConfig"
              [disableStatus]="!isEditable"
              [formControlNameInput]="definitionForm.controls.cloudKeyID"
              controlName="cloudKeyID"
              class="mat-form-field-wrapper-remove-padding"
            ></pcomp-mat-select-search>
            <div *ngIf="loading.clouds">Loading cloud accounts...</div>
            <mat-error
              *ngIf="!loading.clouds && this.privateClouds.length === 0"
            >
              No private clouds found
            </mat-error>
          </div>
        </div>
        <mat-label class="d-flex mb-2 form__label">
          Assign this Network to a Namespace
        </mat-label>
        <pcomp-mat-select-search
          *ngIf="!loading.namespaces"
          [parentForm]="definitionForm"
          [config]="selectNamespaceConfig"
          [disableStatus]="false"
          [formControlNameInput]="definitionForm.controls.namespace"
          controlName="namespace"
          class="w-50 pr-2 mat-form-field-wrapper-remove-padding"
        ></pcomp-mat-select-search>
        <div *ngIf="loading.namespaces">Loading Namespaces...</div>

        <div>
          <mat-checkbox
            class="mt-2"
            [formControl]="definitionForm.controls.exportable"
          >
            Mark network exportable in policy (Recommended)
          </mat-checkbox>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel
      [expanded]="true"
      [hideToggle]="true"
      class="form-panel"
    >
      <mat-expansion-panel-header class="form-panel__header pe-none arrow">
        <mat-panel-title>Auto onboard settings</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="d-flex flex-column">
        <mat-radio-group
          *ngIf="
            definitionForm.controls.cloudType.value ===
            COPY_NETWORK_HOSTED_TYPE.PRIVATE
          "
          [formControl]="
            definitionForm.controls.privateCloud?.controls.ruleBasedOnboarding
          "
        >
          <mat-radio-button color="primary" [value]="false" class="ml-n1 pl-0">
            Onboard all learned routes
          </mat-radio-button>
          <mat-radio-button color="primary" [value]="true" class="pl-2">
            Rule based onboarding
          </mat-radio-button>
        </mat-radio-group>
        <p class="page__hint">
          {{
            definitionForm.controls.privateCloud?.controls.ruleBasedOnboarding
              .value
              ? "Custom rules can be added under onboarding rules (second tab)."
              : "All advertised routes will be onboarded as part of this network and assigned to this Namespace."
          }}
        </p>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
