import { Query } from '@datorama/akita';
import { UserStore, UserState, userStore } from './user.store';
import { Observable } from 'rxjs';

export class UserQuery extends Query<UserState> {
  selectUsername$ = this.select((state) => state.username);
  selectStatus$ = this.select((state) => state.status);
  selectFactors$ = this.select((state) => state.factors);
  selectUserID$ = this.select((state) => state.userId);
  constructor(protected store: UserStore) {
    super(store);
  }

  get loggedInUser(): Observable<string> {
    return this.selectUsername$;
  }

  get loggedInStatus(): Observable<string> {
    return this.selectStatus$;
  }

  get authenticationFactors(): Observable<Array<any>> {
    return this.selectFactors$;
  }

  get userID(): Observable<string> {
    return this.selectUserID$;
  }
}

export const userQuery = new UserQuery(userStore);
