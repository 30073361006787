import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { IMAGE_PATHS } from '@app/common/util/constants';

@Component({
  selector: 'app-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ReleaseNotesComponent implements OnInit, AfterViewChecked {
  prosimoLogoURL = IMAGE_PATHS.RELEASES.PROSIMO;
  showDetails: any = [];
  matIconElements: any = [];
  expandedShowVersionElements: any = [];
  expandedHideVersionElements: any = [];
  displayContentPanelElements: any = [];
  displayContentConfigData: any = {
    displayContent: '',
    displayTitle: '',
  };
  isRenderFirstTime = true;
  @Input() set displayContentConfig(data: any) {
    if (data.displayTitle && data.displayContent) {
      this.displayContentConfigData = data;
    }
  }

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngAfterViewChecked(): void {
    /**
     * To set default behaviour of panel
     */
    if (this.isRenderFirstTime) {
      this.setMatIconToggleBehavior();
      this.setExpandedShowVersionElementsBehavior();
      this.setExpandedHideVersionElementsBehavior();
      this.setDisplayContentPanelElementsBehavior();
    }
  }

  toggleReleaseNotesContent(index) {
    this.isRenderFirstTime = false;
    this.showDetails[index] = !this.showDetails[index];
    /**
     * close other panels
     */
    this.showDetails.forEach((item, i) => {
      if (i !== index) {
        if (!this.showDetails[i]) {
          this.showDetails[i] = true;
        }
      }
    });
    this.expandedShowVersionElements =
      Array.from(document.getElementsByClassName('header_expanded_show')) || [];
    this.expandedHideVersionElements =
      Array.from(document.getElementsByClassName('header_expanded_hide')) || [];
    this.matIconElements =
      Array.from(document.getElementsByClassName('toggle-icon-wrapper')) || [];
    this.displayContentPanelElements =
      Array.from(document.getElementsByClassName('displayContent_panel')) || [];

    if (this.expandedShowVersionElements) {
      this.expandedShowVersionElements.forEach((element, i) => {
        this.showDetails[i]
          ? element.classList.add('hide')
          : element.classList.remove('hide');
      });
    }
    if (this.expandedHideVersionElements) {
      this.expandedHideVersionElements.forEach((element, i) => {
        this.showDetails[i]
          ? element.classList.remove('hide')
          : element.classList.add('hide');
      });
    }
    if (this.matIconElements) {
      this.matIconElements.forEach((element, i) => {
        this.showDetails[i]
          ? element.classList.remove('open')
          : element.classList.add('open');
      });
    }
    if (this.displayContentPanelElements) {
      this.displayContentPanelElements.forEach((element, i) => {
        this.showDetails[i]
          ? element.classList.add('hide')
          : element.classList.remove('hide');
      });
    }
    this.cdr.markForCheck();
  }

  setExpandedShowVersionElementsBehavior() {
    this.expandedShowVersionElements =
      Array.from(document.getElementsByClassName('header_expanded_show')) || [];
    if (this.expandedShowVersionElements) {
      this.expandedShowVersionElements.forEach((element, i) => {
        this.showDetails[i]
          ? element.classList.add('hide')
          : element.classList.remove('hide');
      });
    }
  }
  setExpandedHideVersionElementsBehavior() {
    this.expandedHideVersionElements =
      Array.from(document.getElementsByClassName('header_expanded_hide')) || [];
    if (this.expandedHideVersionElements) {
      this.expandedHideVersionElements.forEach((element, i) => {
        this.showDetails[i]
          ? element.classList.add('show')
          : element.classList.add('hide');
      });
    }
  }

  setDisplayContentPanelElementsBehavior() {
    this.displayContentPanelElements =
      Array.from(document.getElementsByClassName('displayContent_panel')) || [];
    if (this.displayContentPanelElements) {
      this.displayContentPanelElements.forEach((element, i) => {
        this.showDetails[i]
          ? element.classList.add('hide')
          : element.classList.add('show');
      });
    }
  }
  setMatIconToggleBehavior() {
    this.matIconElements =
      Array.from(document.getElementsByClassName('displayContent')) || [];
    if (this.matIconElements) {
      this.matIconElements.forEach((element, i) => {
        element.addEventListener(
          'click',
          this.toggleReleaseNotesContent.bind(this, i)
        );
        this.showDetails[i]
          ? element.classList.remove('open')
          : element.classList.add('open');
        /**
         * Cretae showDetails array based on input body data
         */
        if (i === 0) {
          this.showDetails.push(false);
        } else {
          this.showDetails.push(true);
        }
      });
    }
  }
}
