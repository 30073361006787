<div id="release-notes__container" class="position-relative">
  <div class="position-relative">
    <img class="prosimo__logo" [src]="prosimoLogoURL" />
  </div>
  <div class="release-notes__content d-flex flex-column h-100">
    <div
      class="displayTitle"
      [innerHTML]="displayContentConfigData?.displayTitle | safe : 'html'"
    ></div>
    <div
      class="displayContent_container d-flex flex-column"
      [innerHTML]="displayContentConfigData?.displayContent | safe : 'html'"
    ></div>
  </div>
</div>
