<div id="pillList__container">
  <div class="row no-gutters pillList__title" *ngIf="title && title !== ''">
    {{ title }}
  </div>
  <div class="row flex-grow-1 no-gutters align-items-center">
    <div
      class="d-flex flex-wrap flex-grow-1 align-items-center overflow-hidden"
    >
      <div
        class="row no-gutters flex-wrap pills__wrapper"
        *ngIf="!pill"
        [ngClass]="{
          'col-sm-9 px-0': isCardFormat
        }"
      >
        <div
          [matTooltip]="pill"
          matTooltipPosition="below"
          [ngStyle]="{ 'background-color': backgroundColor, width: pillWidth }"
          [ngClass]="{
            boldTxt: boldText,
            selectPill: selectPill,
            pill: !selectPill,
            hidePillBorder: hidePillBorder,
            'flex-fill': fillAvailableSpace
          }"
          *ngFor="let pill of _pillList | slice: 0:_count"
        >
          {{ pill }}
        </div>
        <div
          class="emptyText"
          [class.ml-2]="textIndent"
          *ngIf="!_pillList?.length"
        >
          {{ this.emptyText }}
        </div>
      </div>
      <ng-container *ngIf="pill">
        <app-pill
          *ngFor="let pill of _pillList | slice: 0:_count"
          [value]="pill"
          [closeable]="closeable"
          [closePositionAfter]="closePositionAfter"
          [borderColor]="borderColor"
          [textColor]="textColor"
          [fontSize]="fontSize"
          [backgroundColor]="backgroundColor"
          (close)="onClose($event)"
        ></app-pill>
      </ng-container>
      <div
        [ngClass]="{ 'col-sm-3 p-0': isCardFormat, 'col-0': !isCardFormat }"
        [matMenuTriggerFor]="moreMenu"
        (click)="toggleMoreInfo($event)"
      >
        <span class="more" *ngIf="_pillList.length > _count"
          >+ {{ _pillList.length - _count }} more</span
        >
      </div>
    </div>
    <!-- <div
      class="more"
      [ngClass]="{'col-sm-3': isCardFormat, 'col-0': !isCardFormat}"
      [matMenuTriggerFor]="moreMenu"
      *ngIf="_pillList.length > _count"
      (click)="toggleMoreInfo($event)">
      + {{ _pillList.length - _count }} more
    </div> -->
    <mat-menu #moreMenu="matMenu" class="pillList__menu">
      <div class="menu__wrapper">
        <div
          *ngFor="let pill of _pillList | slice: _count:_pillList.length"
          [matTooltip]="pill"
          matTooltipPosition="below"
        >
          <div class="d-flex justify-content-start align-items-center pill-row">
            <div *ngIf="closeMorePills" class="d-flex">
              <i
                class="material-icons cross-icon"
                matTooltip="Close"
                matTooltipPosition="above"
                (click)="onClose(pill)"
              >
                close
              </i>
            </div>
            <div class="menu__item">
              {{ pill }}
            </div>
          </div>
        </div>
      </div>
    </mat-menu>
  </div>
</div>
