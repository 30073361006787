<div
  class="master-select-all bg-light-shade d-flex flex-wrap align-items-center justify-content-center"
  *ngIf="
    masterSelectConfig?.isShow &&
    selection?.selected?.length === dataSource?.data?.length
  "
>
  <div
    *ngIf="
      masterSelectConfig?.isSelected;
      then selectedBlock;
      else notSelectedBlock
    "
  ></div>
  <ng-template #selectedBlock>
    <div>
      All
      <span class="count"
        ><em>{{ masterSelectConfig?.totalRecords }}</em></span
      >
      {{ masterSelectConfig?.context }} are selected.
    </div>
    <span
      class="master-select-msg ml-2 blue-link"
      (click)="masterSelectAllRecords()"
    >
      Clear Selection
    </span>
  </ng-template>
  <ng-template #notSelectedBlock>
    <div
      class="d-flex"
      *ngIf="
        masterSelectConfig?.totalRecords > masterSelectConfig?.pageSize &&
        masterSelectConfig?.pageRecordsTotal === masterSelectConfig?.pageSize
      "
    >
      <div>
        All
        <span class="count"
          ><em>{{ masterSelectConfig?.pageRecordsTotal }}</em></span
        >
        {{ masterSelectConfig?.context }} on this page are selected.
      </div>
      <div
        class="master-select-msg ml-2 blue-link"
        (click)="masterSelectAllRecords()"
      >
        Select all
        <span class="count">
          <em> {{ masterSelectConfig?.totalRecords }}</em>
        </span>
        {{ masterSelectConfig?.context }}
      </div>
    </div>
  </ng-template>
</div>
<table
  mat-table
  matSort
  id="grid-view"
  class="mat-elevation-z8 w-100"
  [dataSource]="dataSource"
  (matSortChange)="sortRows($event, sortKey)"
>
  <ng-container matColumnDef="select">
    <th class="text-center" mat-header-cell *matHeaderCellDef>
      <mat-checkbox
        color="primary"
        [checked]="selection.hasValue() && isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()"
        [aria-label]="checkboxLabel()"
        (change)="$event ? masterToggle() : null"
      >
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox
        [style.padding-bottom.rem]="rowPaddingBottom"
        color="primary"
        (change)="$event ? updateSelection(row) : null"
        [checked]="selection.isSelected(row)"
        [aria-label]="checkboxLabel(row)"
        (click)="$event.stopPropagation()"
      >
      </mat-checkbox>
    </td>
  </ng-container>
  <ng-container
    *ngFor="let column of columnDef"
    matColumnDef="{{ column?.key }}"
  >
    <ng-container *ngIf="!column?.disableSort; else noSort">
      <th
        class="test text-left pl-3 w-100"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
      >
        <div class="cell-content__margin clr-grey7">
          {{ column?.label }}
        </div>
      </th>
    </ng-container>
    <ng-template #noSort>
      <th class="test text-left pl-3" mat-header-cell *matHeaderCellDef>
        <div class="cell-content__margin clr-grey7">
          {{ column?.label }}
        </div>
      </th>
    </ng-template>

    <td
      mat-cell
      class="text-left pl-3"
      [style.padding-bottom.rem]="rowPaddingBottom"
      *matCellDef="let element"
    >
      <div
        [ngClass]="{
          'cell-content__margin': true,
          'text-center': column?.isDisplayColorCode
        }"
      >
        <span
          *ngIf="
            column?.isDisplayColorCode;
            then colorCodeBlock;
            else labelBlock
          "
        ></span>
        <ng-template #colorCodeBlock>
          <span
            class="onboard-type-color-code"
            [style.backgroundColor]="column?.colorCodes[element[column?.key]]"
          ></span>
        </ng-template>
        <ng-template #labelBlock>
          {{ element[column?.key] }}
        </ng-template>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    (click)="updateSelection(row)"
  ></tr>
</table>
