<div class="d-flex flex-fill overflow-hidden">
  <pcomp-conditions-panel
    [style.maxWidth.px]="240"
    [style.minWidth.px]="240"
    [conditionStyle]="{ 'min-width': '240px', 'max-width': '240px' }"
    [addConditionCategoryBtn]="'Add Another Rule'"
    [uiThemeMode]="uiThemeMode + '-theme'"
    [reorderIcon]="reorderIcon"
    [enableReorder]="true"
    [actionIcon]="binUrl"
    (actionClick)="deleteRule($event)"
  >
    <div [style.maxWidth.px]="240" [style.minWidth.px]="240"></div>
  </pcomp-conditions-panel>
  <div class="w-100 py-2 px-4 overflow-auto">
    <p class="page__hint">
      <img
        class="error-icon"
        *ngIf="errorPayload?.errorStep === step"
        [src]="errorIcon"
      />
      {{ hint }}
    </p>
    <!-- <mat-accordion [multi]="true" class="bordered-panel"> -->
    <p class="clr-grey7 mb-3 page__header">Conditions</p>
    <ng-container
      *ngIf="ruleForm"
      [ngTemplateOutlet]="rule"
      [ngTemplateOutletContext]="{
        formGroup: ruleForm.controls.rules.at(selectedRuleIndex)
      }"
    ></ng-container>
    <!-- </mat-accordion> -->
  </div>
</div>
<div
  class="summary__pane overflow-auto d-flex flex-column flex-fill w-100 bg-light-shade border-top-grey-thin px-3 pb-3 pt-2"
>
  <div class="d-flex justify-content-between">
    <span class="d-flex align-items-center">
      Rule {{ selectedRuleIndex + 1 }} Summary
    </span>
    <div>
      <div class="d-flex">
        <div class="d-flex align-items-center clr-grey7">View Changes</div>
        <div class="">
          <pcomp-toggle-switch
            [isToggleSwitchChecked]="isToggleSwitchChecked"
            [isToggleSwitchDisabled]="!isEditable"
            (isCheckboxCheckedEvent)="onViewChangesClick($event)"
          >
          </pcomp-toggle-switch>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="selectedRule"
    class="rule-list-pane__container d-flex flex-column p-2 bg-blue8-op5 bdr-blue8-op30 w-100 clr-grey7"
  >
    <div
      *ngIf="
        !prefixMap.get(selectedRule.value.id)?.length &&
        !selectedRule.value.prefixes?.groups?.length &&
        !bgpCommunityMap.get(selectedRule.value.id)?.length &&
        !selectedRule.value.bgpCommunities?.communityGroups?.length &&
        !bgpNeighborMap.get(selectedRule.value.id)?.length
      "
      class="d-flex w-100 h-100 justify-content-center align-items-center py-5 no-conditions clr-grey7"
    >
      No Conditions have been selected
    </div>
    <div
      *ngIf="prefixMap.get(selectedRule.value.id)?.length > 0"
      class="d-flex mb-3"
    >
      <div class="pt-1">Prefix:</div>
      <div class="flex-fill">
        <div class="d-flex justify-content-between">
          <div class="d-flex pt-1 ml-1">
            matches{{
              selectedRule.value.prefixes.matchCondition === "none"
                ? " neither"
                : ""
            }}
            <div class="ml-1 d-flex flex-row flex-wrap">
              <div
                *ngFor="
                  let prefix of prefixMap.get(selectedRule.value.id);
                  last as isLast
                "
                class="d-flex"
              >
                <div class="d-flex align-items-center rule__pill">
                  <div class="align-items-center cancel-icon">
                    <mat-icon
                      class="icon__close clr-grey4"
                      (click)="deleteCondition('prefix', prefix)"
                      >close</mat-icon
                    >
                  </div>
                  <span class="blue-link">{{ prefix.prefix }}</span>
                  <span class="ml-1">min</span>
                  <span class="ml-1 blue-link">{{ prefix.min }}</span>
                  <span class="ml-1">max</span>
                  <span class="ml-1 blue-link">{{ prefix.max }}</span>
                </div>
                <span class="mx-1" *ngIf="!isLast">{{
                  MatchConditionMap[selectedRule.value.prefixes.matchCondition]
                }}</span>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <div
      *ngIf="selectedRule.value.prefixes?.groups?.length"
      class="d-flex mb-3"
    >
      <div class="pt-1">Prefix List:</div>
      <div class="flex-fill">
        <div class="d-flex justify-content-between">
          <div class="d-flex pt-1 ml-1">
            matches{{
              selectedRule.value.prefixes.matchCondition === "none"
                ? " neither"
                : ""
            }}
            <div class="ml-1 d-flex flex-row flex-wrap">
              <div
                *ngFor="
                  let prefixGroup of selectedRule.value.prefixes.groups;
                  last as isLast
                "
                class="d-flex"
              >
                <div class="d-flex align-items-center rule__pill">
                  <div class="align-items-center cancel-icon">
                    <mat-icon
                      class="icon__close clr-grey4"
                      (click)="deleteCondition('prefixList', prefixGroup)"
                      >close</mat-icon
                    >
                  </div>
                  <span class="blue-link">{{
                    ipPrefixesLists.get(prefixGroup)
                  }}</span>
                </div>
                <span class="mx-1" *ngIf="!isLast">{{
                  MatchConditionMap[selectedRule.value.prefixes.matchCondition]
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="
        bgpCommunityMap.get(selectedRule.value.id)?.length > 0 ||
        selectedRule.value.bgpCommunities?.wellKnownCommunities?.length > 0
      "
      class="d-flex mb-3"
    >
      <div class="pt-1">BGP Community:</div>
      <div class="flex-fill">
        <div class="d-flex justify-content-between">
          <div class="d-flex pt-1 ml-1">
            matches{{
              selectedRule.value.bgpCommunities.matchCondition === "none"
                ? " neither"
                : ""
            }}
            <div class="ml-1 d-flex flex-row flex-wrap">
              <div
                *ngFor="
                  let bgpCommunity of (
                    bgpCommunityMap.get(selectedRule.value.id) || []
                  ).concat(
                    selectedRule.value.bgpCommunities?.wellKnownCommunities ||
                      []
                  );
                  last as isLast
                "
                class="d-flex"
              >
                <div class="d-flex align-items-center rule__pill">
                  <div class="align-items-center cancel-icon">
                    <mat-icon
                      class="icon__close clr-grey4"
                      (click)="deleteCondition('bgpCommunity', bgpCommunity)"
                      >close</mat-icon
                    >
                  </div>
                  <span class="blue-link">{{
                    bgpCommunity | wellKnownBgp
                  }}</span>
                </div>
                <span class="mx-1" *ngIf="!isLast">{{
                  MatchConditionMap[
                    selectedRule.value.bgpCommunities.matchCondition
                  ]
                }}</span>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <div
      *ngIf="selectedRule.value.bgpCommunities.communityGroups.length"
      class="d-flex mb-3"
    >
      <div class="pt-1">BGP Community Group:</div>
      <div class="flex-fill">
        <div class="d-flex justify-content-between">
          <div class="d-flex pt-1 ml-1">
            matches{{
              selectedRule.value.bgpCommunities.matchCondition === "none"
                ? " neither"
                : ""
            }}
            <div class="ml-1 d-flex flex-row flex-wrap">
              <div
                *ngFor="
                  let bgpCommunityGroup of selectedRule.value?.bgpCommunities
                    ?.communityGroups;
                  last as isLast
                "
                class="d-flex"
              >
                <div class="d-flex align-items-center rule__pill">
                  <div class="align-items-center cancel-icon">
                    <mat-icon
                      class="icon__close clr-grey4"
                      (click)="deleteCondition('bgpGroup', bgpCommunityGroup)"
                      >close</mat-icon
                    >
                  </div>
                  <span class="blue-link">{{
                    bgpGroups.get(bgpCommunityGroup)
                  }}</span>
                </div>
                <span class="mx-1" *ngIf="!isLast">{{
                  MatchConditionMap[
                    selectedRule.value.bgpCommunities.matchCondition
                  ]
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="bgpNeighborMap.get(selectedRule.value.id)?.length > 0"
      class="d-flex mb-3"
    >
      <div class="pt-1">BGP Neighbor:</div>
      <div class="flex-fill">
        <div class="d-flex justify-content-between">
          <div class="d-flex pt-1 ml-1">
            matches{{
              selectedRule.value.bgpNeighbours.matchCondition === "none"
                ? " neither"
                : ""
            }}
            <div class="ml-1 d-flex flex-row flex-wrap">
              <div
                *ngFor="
                  let bgpNeighbor of bgpNeighborMap.get(selectedRule.value.id);
                  last as isLast
                "
                class="d-flex"
              >
                <div class="d-flex align-items-center rule__pill">
                  <div class="align-items-center cancel-icon">
                    <mat-icon
                      class="icon__close clr-grey4"
                      (click)="deleteCondition('bgpNeighbor', bgpNeighbor)"
                      >close</mat-icon
                    >
                  </div>
                  <span class="blue-link">{{ bgpNeighbor }}</span>
                </div>
                <span class="mx-1" *ngIf="!isLast">{{
                  MatchConditionMap[
                    selectedRule.value.bgpNeighbours.matchCondition
                  ]
                }}</span>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="isToggleSwitchChecked"
    class="rule-list-pane__container mt-3 d-flex flex-column p-2 bg-blue8-op5 bdr-blue8-op30 w-100 clr-grey7"
  >
    <div
      *ngIf="!changeSet?.[selectedRuleIndex]?.length"
      class="d-flex w-100 h-100 justify-content-center align-items-center py-3 no-conditions clr-grey7"
    >
      No Changes
    </div>
    <div *ngFor="let set of changeSet?.[selectedRuleIndex]" class="d-flex mb-3">
      <div class="d-flex align-items-center pt-1">{{ set.key }}</div>
      <div class="flex-fill">
        <div class="d-flex pt-1 ml-1">
          <div class="ml-1 d-flex flex-row flex-wrap">
            <div
              *ngFor="
                let entity of set.values;
                first as isFirst;
                last as isLast
              "
              class="d-flex"
            >
              <div
                [ngClass]="{
                  'bg-success5-op10': entity.action === 'ADD',
                  'bg-danger5-op10': entity.action === 'DEL',
                  'bg-warn5-op10': entity.action === 'MOD'
                }"
                class="d-flex align-items-center rule__pill p-1 ineditable"
              >
                {{ isFirst ? "" : ", " }}{{ entity.value }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <pcomp-rule-list-pane
    *ngIf="rulePane"
    [ruleListPaneConfig]="rulePane"
    minVisibleCount="3"
    (removeRule)="removeRule($event)"
    (editRuleListLabel)="editRule($event)"
    (ruleListIconClick)="onRuleListIconClick($event)"
    [iconOnHoverEffect]="true"
    [tooltipThemeClass]="tooltipThemeClass"
  >
  </pcomp-rule-list-pane> -->
</div>

<ng-template #rule let-formGroup="formGroup">
  <ng-container [formGroup]="formGroup">
    <div class="d-flex flex-column">
      <mat-label class="d-flex mb-2 form__label">
        Set an action to apply to the rule
      </mat-label>
      <mat-radio-group class="mb-2" [formControl]="formGroup.controls.action">
        <mat-radio-button
          color="primary"
          [value]="RuleAction.ACCEPT"
          class="ml-n1 pl-0"
        >
          Accept
        </mat-radio-button>
        <mat-radio-button
          color="primary"
          [value]="RuleAction.REJECT"
          class="pl-2"
        >
          Block
        </mat-radio-button>
      </mat-radio-group>
      <div class="d-flex flex-column mb-3">
        <mat-label class="mb-2 form__label">Property</mat-label>
        <mat-form-field
          class="mat-form-field-wrapper-remove-padding mb-3"
          appearance="outline"
        >
          <mat-select
            [formControl]="propertyControl"
            panelClass="mat-panel__bg--{{
              uiThemeMode
            }}-theme-light select-width-lg"
            disableRipple
            disableOptionCentering
          >
            <mat-option value="prefix">Prefix</mat-option>
            <mat-option value="prefixList">Prefix List</mat-option>
            <mat-option value="bgpCommunity">BGP Community</mat-option>
            <mat-option value="bgpGroup">BGP Community Group</mat-option>
            <mat-option value="bgpNeighbor">BGP Neighbor</mat-option>
          </mat-select>
        </mat-form-field>

        <ng-container
          *ngIf="propertyControl.value === 'prefix'"
          [ngTemplateOutlet]="prefix"
          [ngTemplateOutletContext]="{
            formGroup: formGroup
          }"
        ></ng-container>
        <ng-container
          *ngIf="propertyControl.value === 'prefixList'"
          [ngTemplateOutlet]="prefixList"
          [ngTemplateOutletContext]="{
            formGroup: formGroup
          }"
        ></ng-container>
        <ng-container
          *ngIf="propertyControl.value === 'bgpCommunity'"
          [ngTemplateOutlet]="bgpCommunity"
          [ngTemplateOutletContext]="{
            formGroup: formGroup
          }"
        ></ng-container>
        <ng-container
          *ngIf="propertyControl.value === 'bgpGroup'"
          [ngTemplateOutlet]="bgpGroup"
          [ngTemplateOutletContext]="{
            formGroup: formGroup
          }"
        ></ng-container>
        <ng-container
          *ngIf="propertyControl.value === 'bgpNeighbor'"
          [ngTemplateOutlet]="bgpNeighbor"
          [ngTemplateOutletContext]="{
            formGroup: formGroup
          }"
        ></ng-container>
      </div>
    </div>
  </ng-container>
</ng-template>

<!-- Configure Prefixes manually -->
<ng-template #prefix let-formGroup="formGroup">
  <ng-container
    [ngTemplateOutlet]="condition"
    [ngTemplateOutletContext]="{
      type: 'prefix',
      formGroup: formGroup.controls.prefixes
    }"
  ></ng-container>

  <app-prefix-input
    [uiThemeMode]="uiThemeMode"
    [prefixForm]="formGroup.controls.prefixes.controls.values"
  ></app-prefix-input>

  <div class="d-block">
    <button
      mat-button
      class="p-0"
      color="primary"
      type="button"
      [disabled]="formGroup.controls.prefixes.controls.values.invalid"
      (click)="addPrefix()"
    >
      Add prefix
    </button>
  </div>
</ng-template>

<!-- Select from IP Prefix groupings configured in Management > Groupings -->
<ng-template #prefixList let-formGroup="formGroup">
  <ng-container
    [ngTemplateOutlet]="condition"
    [ngTemplateOutletContext]="{
      type: 'prefixList',
      formGroup: formGroup.controls.prefixes
    }"
  ></ng-container>

  <div class="d-flex flex-column">
    <mat-label class="mb-2 form__label">Prefix List</mat-label>
    <pcomp-mat-select-search
      *ngIf="!loading.prefixList && selectPrefixListConfig?.optionList.length"
      [parentForm]="formGroup"
      [config]="selectPrefixListConfig"
      [disableStatus]="false"
      [formControlNameInput]="formGroup.controls.prefixes.controls.groups"
      controlName="groups"
      class="mat-form-field-wrapper-remove-padding mb-3"
    ></pcomp-mat-select-search>
    <div
      *ngIf="
        !loading.prefixList && selectPrefixListConfig?.optionList?.length === 0
      "
      class="mb-3"
    >
      No Prefix Lists found
    </div>
    <div *ngIf="loading.prefixList" class="mb-3">Loading Prefix Lists...</div>
  </div>
</ng-template>

<!-- Configure BGP Communities manually -->
<ng-template #bgpCommunity let-formGroup="formGroup">
  <ng-container
    [ngTemplateOutlet]="condition"
    [ngTemplateOutletContext]="{
      type: 'bgpCommunity',
      formGroup: formGroup.controls.bgpCommunities
    }"
  ></ng-container>

  <div class="d-flex flex-column mb-2">
    <mat-label class="form__label">Well-Known BGP Communities</mat-label>
  </div>
  <div class="d-flex mb-3">
    <mat-form-field
      appearance="outline"
      class="mat-form-field-wrapper-remove-padding"
    >
      <mat-select
        [formControl]="
          formGroup.controls.bgpCommunities?.controls.wellKnownCommunities
        "
        placeholder="Select Well-Known Communities"
        disableOptionCentering
        disableRipple
        multiple
      >
        <mat-select-trigger>
          {{
            formGroup.controls.bgpCommunities?.controls?.wellKnownCommunities
              ?.value?.length > 1
              ? formGroup.controls.bgpCommunities?.controls
                  ?.wellKnownCommunities?.value.length + " Communities"
              : WellKnownCommunities[
                  formGroup.controls.bgpCommunities?.controls
                    ?.wellKnownCommunities?.value
                ]
          }}
        </mat-select-trigger>
        <mat-option
          [value]="community.key"
          *ngFor="let community of WellKnownCommunities | keyvalue"
          >{{ community.value }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <span class="form__label ml-3 d-flex align-items-center"> - AND/OR - </span>
  </div>

  <div class="d-flex mb-2">
    <mat-label class="form__label">BGP Community</mat-label>
    <pcomp-mat-error-info-tooltip
      class="ml-2"
      [errorMessageConfig]="{
        isShowTooltip: false,
        errorCondition:
          formGroup.controls.bgpCommunities?.controls.communities?.errors &&
          formGroup.controls.bgpCommunities?.controls.communities?.touched,
        errorMessage: formGroup.controls.bgpCommunities?.controls.communities
          ?.errors?.pattern
          ? 'Community is not valid'
          : formGroup.controls.bgpCommunities?.controls.communities?.errors
              ?.duplicate
          ? 'Community already exists'
          : 'Community is required'
      }"
      [uiThemeMode]="uiThemeMode"
    ></pcomp-mat-error-info-tooltip>
  </div>
  <div
    [ngClass]="{
      'invalid-range':
        formGroup.controls.bgpCommunities?.controls.communities?.errors &&
        formGroup.controls.bgpCommunities?.controls.communities?.touched
    }"
  >
    <mat-form-field
      appearance="outline"
      class="mat-form-field-wrapper-remove-padding mb-2 w-50"
    >
      <div class="d-flex">
        <input
          matInput
          placeholder="Enter Community [Enter]"
          [formControl]="
            formGroup.controls.bgpCommunities?.controls.communities
          "
          autocomplete="off"
          (keyup.enter)="addBGPCommunity()"
        />
      </div>
    </mat-form-field>

    <div class="d-block">
      <button
        mat-button
        class="p-0"
        color="primary"
        type="button"
        [disabled]="
          formGroup.controls.bgpCommunities?.controls.communities.invalid
        "
        (click)="addBGPCommunity()"
      >
        Add community
      </button>
    </div>
  </div>
</ng-template>

<!-- Select from BGP Community groupings configured in Management > Groupings -->
<ng-template #bgpGroup let-formGroup="formGroup">
  <ng-container
    [ngTemplateOutlet]="condition"
    [ngTemplateOutletContext]="{
      type: 'bgpGroup',
      formGroup: formGroup.controls.bgpCommunities
    }"
  ></ng-container>

  <div class="d-flex flex-column">
    <mat-label class="mb-2 form__label">BGP Community Groups</mat-label>
    <pcomp-mat-select-search
      *ngIf="!loading.prefixList && selectBgpGroupConfig?.optionList.length"
      [parentForm]="formGroup"
      [config]="selectBgpGroupConfig"
      [disableStatus]="false"
      [formControlNameInput]="
        formGroup.controls.bgpCommunities.controls.communityGroups
      "
      controlName="groups"
      class="mat-form-field-wrapper-remove-padding mb-3"
    ></pcomp-mat-select-search>
    <div
      *ngIf="
        !loading.prefixList && selectBgpGroupConfig?.optionList?.length === 0
      "
      class="mb-3"
    >
      No BGP Community Groups found
    </div>
    <div *ngIf="loading.prefixList" class="mb-3">
      Loading BGP Community Groups...
    </div>
  </div>
</ng-template>

<!-- Configure BGP Neighbors -->
<ng-template #bgpNeighbor let-formGroup="formGroup">
  <ng-container
    [ngTemplateOutlet]="condition"
    [ngTemplateOutletContext]="{
      type: 'bgpNeighbor',
      formGroup: formGroup.controls.bgpNeighbours
    }"
  ></ng-container>

  <div class="d-flex mb-2">
    <mat-label class="form__label">BGP Neighbor</mat-label>
    <pcomp-mat-error-info-tooltip
      class="ml-2"
      [errorMessageConfig]="{
        isShowTooltip: false,
        errorCondition:
          formGroup.controls.bgpNeighbours?.controls?.values?.errors &&
          formGroup.controls.bgpNeighbours?.controls?.values?.touched,
        errorMessage: formGroup.controls.bgpNeighbours?.controls?.values?.errors
          ?.pattern
          ? 'Neighbor IP is not valid'
          : 'Community is required'
      }"
      [uiThemeMode]="uiThemeMode"
    ></pcomp-mat-error-info-tooltip>
  </div>
  <div
    [ngClass]="{
      'invalid-range':
        formGroup.controls.bgpNeighbours?.controls?.values?.errors &&
        formGroup.controls.bgpNeighbours?.controls?.values?.touched
    }"
  >
    <mat-form-field
      appearance="outline"
      class="mat-form-field-wrapper-remove-padding mb-2 w-50"
    >
      <div class="d-flex">
        <input
          matInput
          placeholder="Enter Neighbor IP [Enter]"
          [formControl]="formGroup.controls.bgpNeighbours?.controls?.values"
          autocomplete="off"
          (keyup.enter)="addBGPNeighbor()"
        />
      </div>
    </mat-form-field>

    <div class="d-block">
      <button
        mat-button
        class="p-0"
        color="primary"
        type="button"
        [disabled]="formGroup.controls.bgpNeighbours?.controls?.values.invalid"
        (click)="addBGPNeighbor()"
      >
        Add neighbor
      </button>
    </div>
  </div>
</ng-template>

<ng-template #condition let-formGroup="formGroup" let-type="type">
  <div class="mb-2">
    <mat-label class="mb-2 form__label">Match Condition</mat-label>
  </div>
  <div class="mb-3">
    <mat-form-field
      class="mat-form-field-wrapper-remove-padding"
      appearance="outline"
    >
      <mat-select
        [formControl]="formGroup.controls.matchCondition"
        panelClass="mat-panel__bg--{{
          uiThemeMode
        }}-theme-light select-width-lg"
        disableRipple
        disableOptionCentering
      >
        <mat-option [value]="RuleMatchCondition.ANY">Matches any</mat-option>
        <mat-option
          *ngIf="type === 'bgpCommunity' || type === 'bgpGroup'"
          [value]="RuleMatchCondition.ALL"
          >Matches all</mat-option
        >
        <mat-option [value]="RuleMatchCondition.NONE">Matches none</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ng-template>
