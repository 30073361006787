import { Query } from '@datorama/akita';
import {
  MCNInsightsSharedFilterState,
  MCNInsightsSharedFilterStore,
  mcnInsightsSharedFilterStore,
} from './mcn-insights-filter.store';
import { Observable } from 'rxjs';
import { TimeRange } from '../../shared/calendar.component';
import {
  Network,
  NetworkGroup,
} from '@app/dashboard/shared/filter-list/network-list/network-list.model';
import { PrivateLink } from '@app/dashboard/shared/filter-list/private-link-list/private-link-list.model';

export class MCNInsightsSharedFilterQuery extends Query<MCNInsightsSharedFilterState> {
  selectedSourceNetworks$ = this.select(
    (state) => state.selectedSourceNetworks
  );
  selectedSourcePrivateLinks$ = this.select(
    (state) => state.selectedSourcePrivateLinks
  );
  selectedSourceNetworkGroups$ = this.select(
    (state) => state.selectedSourceNetworkGroups
  );
  selectedTargetNetworks$ = this.select(
    (state) => state.selectedTargetNetworks
  );
  selectedTargetNetworkGroups$ = this.select(
    (state) => state.selectedTargetNetworkGroups
  );
  timeRange$ = this.select((state) => state.timeRange);
  activeTab$ = this.select((state) => state.activeTab);
  source$ = this.select((state) => state.source);
  mainDashboardTimeRange$ = this.select(
    (state) => state.mainDashboardTimeRange
  );
  costDashboardTimeRange$ = this.select(
    (state) => state.mainDashboardTimeRange
  );
  insightTimeRange$ = this.select((state) => state.insightTimeRange);

  constructor(protected store: MCNInsightsSharedFilterStore) {
    super(store);
  }

  get selectedSourceNetworks(): Observable<Array<Network>> {
    return this.selectedSourceNetworks$;
  }

  get selectedSourcePrivateLinks(): Observable<Array<PrivateLink>> {
    return this.selectedSourcePrivateLinks$;
  }

  get selectedSourceNetworkGroups(): Observable<Array<NetworkGroup>> {
    return this.selectedSourceNetworkGroups$;
  }

  get selectedTargetNetworks(): Observable<Array<Network>> {
    return this.selectedTargetNetworks$;
  }

  get selectedTargetNetworkGroups(): Observable<Array<NetworkGroup>> {
    return this.selectedTargetNetworkGroups$;
  }

  get timeRange(): Observable<TimeRange> {
    return this.timeRange$;
  }

  get insightTimeRange(): Observable<TimeRange> {
    return this.insightTimeRange$;
  }

  get mainDashboardTimeRange(): Observable<TimeRange> {
    return this.mainDashboardTimeRange$;
  }

  get costDashboardTimeRange(): Observable<TimeRange> {
    return this.costDashboardTimeRange$;
  }

  get activeTab(): Observable<string> {
    return this.activeTab$;
  }

  get source(): Observable<string> {
    return this.source$;
  }
}

export const mcnInsightsSharedFilterQuery = new MCNInsightsSharedFilterQuery(
  mcnInsightsSharedFilterStore
);
