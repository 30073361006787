
export class PolicyFormModelSerializer {
  fromJson(data: any) {
    return data.data ? data.data : data;
  }

  toJson(data: any): any {
    return JSON.stringify(data);
  }

  toJsonArray(data: any): any {
    return data;
  }
}
