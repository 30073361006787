<div id="tabs__container" class="bg-dark-shade border-bottom-grey-thin">
  <ul class="d-flex flex-fill justify-content-center w-100">
    <li
      *ngFor="let config of configs$ | async"
      class="py-2"
      [ngClass]="{
        'selected bdr-btm-primary1': config?.isActive,
        'w-100': true
      }"
      (click)="setSelectedTab(config)"
    >
      <div
        class="d-flex justify-content-center align-items-center h-100 cursor-pointer"
      >
        <img
          *ngIf="config?.isShowIcon"
          [attr.src]="
            config?.isActive
              ? config?.selectedIconPath
              : config?.deselectedIconPath
          "
        />
        <span>{{ config?.label }}</span>
      </div>
    </li>
  </ul>
</div>
