import { Page, Sort, TimeISO } from '@prosimoio/services';
import { IDName } from './models';

export type Policy = {
  id: string;
  name: string;
  appAccessType: string;
  devicePostureConfigured: boolean;
  details: any;
  teamID: string;
  type: string;
  createdTime: TimeISO;
  updatedTime: TimeISO;
  displayName: string;
  namespaceID: string;
  namespaceName: string;
};

export type PolicyResponse = {
  totalCount: number;
  records: Array<Policy>;
};

export type SelectObj = {
  inputItems: Array<InItem>;
  selectedItems: Array<IDName>;
  selectedGroups: Array<IDName>;
};

export type InItem = {
  name: string;
  id: string;
  from: string;
  to: string;
  timeZone: string;
  keyValues: { [id: string]: string };
};

export type PolicySearchFilter = {
  value?: string;
  page?: Page;
  sort?: Array<Sort>;
  accessType?: Array<string>;
};
