import { MCNRecentSearchStore, mcnRecentSearchStore } from './mcn-recent-search.store';
import { MCNRecentSearch } from './mcn-recent-search.model';

export class MCNRecentSearchService {
  constructor(private recentSearchStore: MCNRecentSearchStore) {}

  updateRecentSearchStore(data: MCNRecentSearch) {
    this.recentSearchStore.add(data, { prepend: true });
  }

  updateRecentSearchStoreById(id: string, data: MCNRecentSearch) {
    this.recentSearchStore.update(id, data);
  }
  removeFilter(id: number) {
    this.recentSearchStore.remove(id);
  }
  resetStore() {
    this.recentSearchStore.reset();
  }
}

export const mcnRecentSearchService = new MCNRecentSearchService(mcnRecentSearchStore);
