import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Resource } from './resource';
import { Serializer } from './serializer';
import { QueryOptions } from './query-options';

export class ResourceService<T extends Resource> {
  constructor(
    protected httpClient: HttpClient,
    protected host: string,
    protected path: string,
    protected serializer: Serializer
  ) {}

  public create(
    item: T,
    headers: HttpHeaders = new HttpHeaders({})
  ): Observable<T> {
    return this.httpClient
      .post<T>(`${this.host}/${this.path}`, this.serializer.toJson(item), {
        headers,
      })
      .pipe(map((data) => this.serializer.fromJson(data) as T));
  }

  public update(
    item: T,
    noIdPostfix = false,
    headers: HttpHeaders = new HttpHeaders({})
  ): Observable<T> {
    let URL = `${this.host}/${this.path}`;
    if (item.id && !noIdPostfix) {
      URL = `${URL}/${item.id}`;
    }
    return this.httpClient
      .put<T>(`${URL}`, this.serializer.toJson(item))
      .pipe(map((data) => this.serializer.fromJson(data) as T));
  }

  public updateAll(items: T[]): Observable<T[]> {
    return this.httpClient.put<T>(`${this.host}/${this.path}`, items).pipe(
      map((data) => {
        const responseData = data && data.data ? data.data : [];
        return this.serializer.toJsonArray(responseData) as T[];
      })
    );
  }

  read(id: any, headers: HttpHeaders = new HttpHeaders({})): Observable<T> {
    let URL = `${this.host}/${this.path}`;
    if (id) {
      URL = `${URL}/${id}`;
    }
    return this.httpClient
      .get(`${URL}`, { headers })
      .pipe(map((data: any) => this.serializer.fromJson(data) as T));
  }

  list(queryOptions: QueryOptions): Observable<T[]> {
    return this.httpClient
      .get(`${this.host}/${this.path}`, {
        params: queryOptions.getQueryString(),
      })
      .pipe(map((data: any) => this.convertData(data)));
  }

  delete(id: number | string = '') {
    let URL = `${this.host}/${this.path}`;
    if (id) {
      URL = `${URL}/${id}`;
    }
    return this.httpClient.delete(`${URL}`);
  }

  patch(item: T, headers: HttpHeaders = new HttpHeaders({})): Observable<T> {
    let URL = `${this.host}/${this.path}`;
    return this.httpClient
      .patch<T>(`${URL}`, this.serializer.toJson(item), {
        headers,
      })
      .pipe(map((data) => this.serializer.fromJson(data) as T));
  }

  protected convertData(data: any): T[] {
    const responseData = data?.data;
    return responseData?.isArray
      ? responseData.map((item) => this.serializer.fromJson(item))
      : responseData;
  }
}
