import { AlertsStore, alertsStore } from './alerts.store';
import { SelectionObjectWithArray } from '../../../common/util/constants';

export class AlertsService {
  constructor(private alertsDataStore: AlertsStore) {}

  updateSelectedAlerts(selectedAlerts: Array<SelectionObjectWithArray>) {
    this.alertsDataStore.update((state) => ({
      selectedAlerts,
    }));
  }
}

export const alertsService = new AlertsService(alertsStore);
