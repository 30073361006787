import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Application } from '@app/common/models/app';
import { AppUtil } from '@app/common/util/app-util';
import {
  APP_SETUP_STATUS,
  LOADER_CONFIG_TYPES,
  NOTIFICATION_MESSAGES,
  POLICY_CONFLICT_MESSAGE,
  SUBSCRIPTION_TYPES,
} from '@app/common/util/constants';
import { POLICY_ACCESS_TYPES } from '@app/common/util/policy-constants';
import { LightboxBtnType } from '@app/dashboard/diagnose/diagnostics/lightbox-form-template/lightbox-model';
import { PDashLocalStoreUtil } from '@app/state/web-pdash.util';
import {
  DeleteAction,
  ERROR_MESSAGE,
  POLICY_OPTIONS,
  POLICY_OPTION_KEYS,
} from '@app/v2/onboard/app/app.constants';
import { AppCreateAction, LoaderConfig } from '@app/v2/onboard/app/app.model';
import { AppOnboardService } from '@app/v2/onboard/app/app.service';
import {
  IOrchestratorComponent,
  AppOnboardStep,
} from '@app/common/components/drawer/orchestrator/orchestrator.model';
import { OrchestratorService } from '@app/common/components/drawer/orchestrator/orchestrator.service';
import { OnboardTemplateService } from '@app/common/components/drawer/templates/onboard-template/onboard-template.service';
import {
  CustomizedSelectTriggerTextConfig,
  LoaderService,
  SelectSearchConfig,
  ToastService,
} from '@prosimoio/components';
import { ObjectUtil } from '@prosimoio/services';
import { Observable, Subject, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolicyComponent
  implements IOrchestratorComponent, OnInit, OnDestroy
{
  stopSubs$: Subject<void>;
  COPY_POLICY_OPTIONS = POLICY_OPTIONS;
  COPY_POLICY_OPTION_KEYS = POLICY_OPTION_KEYS;
  uiThemeMode: string;
  tooltipThemeClass: string;
  appStatus: string;
  policyForm: UntypedFormGroup;
  availablePolicies: Array<any> = [];
  productViewType: string;
  selectSearchConfigForPolicies: SelectSearchConfig;
  appData: Application;
  policyLoader$: Observable<LoaderConfig>;
  COPY_LOADER_CONFIG_TYPES = LOADER_CONFIG_TYPES;
  COPY_ERROR_MESSAGE = ERROR_MESSAGE;
  FORM_FIELDS_TO_DISABLE_VALUES = [];
  FORM_FIELDS_TO_DISABLE_KEYS: any = {};
  customizedSelectTriggerTextConfig = {
    isShow: true,
    all: 'All Policies',
    plural: 'Policies',
  } as CustomizedSelectTriggerTextConfig;
  constructor(
    private orchestratorService: OrchestratorService,
    private appUtil: AppUtil,
    private appOnboardService: AppOnboardService,
    private onboardTemplateService: OnboardTemplateService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.stopSubs$ = new Subject();
    this.productViewType = PDashLocalStoreUtil.getProductViewType();
    PDashLocalStoreUtil.getUIThemeModeAsObservable()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe((mode) => {
        this.uiThemeMode = mode?.toLowerCase() + '-theme';
        this.tooltipThemeClass =
          'cdk-component-container--' + this.uiThemeMode + '-dark';
        this.toastService.setUITheme(this.uiThemeMode);
        this.setSelectSearchConfigForPolicies();
        this.cdr.markForCheck();
      });
    PDashLocalStoreUtil.getProductViewTypeAsObservable()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe((productViewType) => {
        this.productViewType = productViewType;
        this.getAvailablePolicies();
      });
    this.onboardTemplateService
      .getNameInput()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (name) => {
          this.appOnboardService.updateApplicationConfig({ appName: name });
        },
      });
  }

  markStepComplete() {
    this.orchestratorService.setStepComplete(AppOnboardStep.POLICY);
  }

  ngOnInit(): void {
    this.appData = ObjectUtil.deepClone(
      this.appOnboardService.getApplicationConfig()
    );
    this.appStatus = this.appData?.status;
    const formFields =
      this.appOnboardService.getFormFieldsToDisableValuesBasedOnStatus(
        this.appStatus
      );
    if (formFields) {
      this.FORM_FIELDS_TO_DISABLE_KEYS = formFields;
      this.FORM_FIELDS_TO_DISABLE_VALUES = Object.values(formFields);
    }
    this.initPolicyForm();
    this.appUtil.updateDrawerButtonConfig({
      mainBtn: { ...LightboxBtnType.ONBOARD, disabled: false },
      secondaryBtn: { ...LightboxBtnType.SAVE, disabled: false },
      cancelBtn: LightboxBtnType.CANCEL,
    });
    this.appUtil
      .getDrawerResponse()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (response) => {
          if (
            [
              LightboxBtnType.SAVE.code,
              LightboxBtnType.ONBOARD.code,
              LightboxBtnType.UPDATE.code,
            ]?.includes(response.code)
          ) {
            this.updateApplication(response.code);
          }
        },
      });
    this.onboardTemplateService
      .getNameInput()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (name) => {
          this.appOnboardService.updateApplicationConfig({ appName: name });
          this.validateStep();
        },
      });
    this.setDrawerFooter();
    this.validateStep();
  }

  validateStep() {
    if (this.appStatus === APP_SETUP_STATUS.FAILED) {
      this.appUtil.updateDrawerButtonConfig({
        mainBtn: {
          ...LightboxBtnType.UPDATE,
          disabled: true,
        },
        cancelBtn: LightboxBtnType.CANCEL,
      });
      return;
    }
    if (this.appStatus === APP_SETUP_STATUS.DEPLOYED) {
      this.appUtil.updateDrawerButtonConfig({
        mainBtn: {
          ...LightboxBtnType.UPDATE,
          disabled: !this.appOnboardService.isApplicationConfigValid(),
        },
        cancelBtn: LightboxBtnType.CANCEL,
      });
    } else {
      this.appUtil.updateDrawerButtonConfig({
        mainBtn: {
          ...LightboxBtnType.ONBOARD,
          disabled: !this.appOnboardService.isApplicationConfigValid(),
        },
        secondaryBtn: {
          ...LightboxBtnType.SAVE,
          disabled: !this.appOnboardService.isApplicationConfigValid(),
        },
        cancelBtn: LightboxBtnType.CANCEL,
      });
    }
  }

  updateApplication(workflow: string = LightboxBtnType.SAVE.code) {
    let action: AppCreateAction;
    switch (workflow) {
      case LightboxBtnType.SAVE.code:
        action = AppCreateAction.SAVE;
        break;
      case LightboxBtnType.ONBOARD.code:
      case LightboxBtnType.UPDATE.code:
        action = AppCreateAction.DEPLOY;
        break;
    }
    const appDataPayload = this.getAppDataPayload();
    this.loaderService.setLoaderStatus(true);
    this.appOnboardService
      .createApp(action, appDataPayload)
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (resp) => {
          this.loaderService.setLoaderStatus(false);
          if (appDataPayload?.id) {
            this.toastService.success(
              NOTIFICATION_MESSAGES.UPDATE.SUCCESS(appDataPayload.appName)
            );
          } else {
            this.toastService.success(
              NOTIFICATION_MESSAGES.CREATE.SUCCESS(appDataPayload.appName)
            );
          }

          this.appUtil.setDynamicDrawerResponse();
          this.appUtil.setDrawerState(false);
          this.markStepComplete();
        },
        error: ({ error: { message } }) => {
          this.loaderService.setLoaderStatus(false);
          this.appOnboardService.showErrorMessage(
            this.uiThemeMode?.toLowerCase(),
            {
              errorMsg: message,
            },
            () => {}
          );
        },
      });
  }

  getAppDataPayload(): Application {
    const payload = ObjectUtil.deepClone(
      this.appOnboardService.getApplicationConfig()
    );
    delete payload['updatedTime'];
    delete payload['createdTime'];
    delete payload['dcID'];
    delete payload['cloudHostedType'];
    return payload;
  }

  initPolicyForm() {
    const { policyIDs = [] } = this.appData;
    let policyOption = POLICY_OPTION_KEYS.NO_POLICY;
    if (policyIDs?.length) {
      policyOption = POLICY_OPTION_KEYS.SPECIFIC_POLICY;
    }
    this.policyForm = this.fb.group({
      policyOption: [
        {
          value: '',
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.policy
          ),
        },
      ],
      policyIDs: [
        {
          value: '',
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.policy
          ),
        },
        Validators.required,
      ],
    });
    this.policyForm.setValue(
      {
        policyOption: policyOption,
        policyIDs: '',
      },
      { emitEvent: false }
    );
    this.appOnboardService.updateApplicationConfig({ policyIDs });
    this.policyForm.valueChanges.pipe(takeUntil(this.stopSubs$)).subscribe({
      next: (value) => {
        const { policyOption = '', policyIDs = '' } = value;
        let formData = { policyIDs: [] };
        if (
          policyOption === POLICY_OPTION_KEYS.SPECIFIC_POLICY &&
          policyIDs?.length
        ) {
          formData.policyIDs = policyIDs?.map((item) => item.id);
        }
        this.appOnboardService.updateApplicationConfig(formData);
      },
    });
  }

  getAvailablePolicies() {
    this.updateRegionLoaderConfig(true, 'Loading Policies...');
    const accessType =
      this.productViewType === SUBSCRIPTION_TYPES.MCN
        ? POLICY_ACCESS_TYPES.TRANSIT
        : POLICY_ACCESS_TYPES.ACCESS;
    const payload = {
      page: {
        start: 0,
        size: 100,
      },
      accessType: [accessType],
    };
    this.appOnboardService
      .getPolicies(payload)
      .pipe(takeUntil(this.stopSubs$))
      .subscribe(
        (data) => {
          this.updateRegionLoaderConfig(false);
          this.availablePolicies = this.appUtil.sort(data?.records, 'name');
          const { policyIDs = [] } =
            this.appOnboardService.getApplicationConfig();
          if (policyIDs?.length) {
            this.patchPolicyToForm(policyIDs);
          }
          this.setSelectSearchConfigForPolicies();
        },
        (error) => {
          this.updateRegionLoaderConfig(
            true,
            'Failed to load policies',
            LOADER_CONFIG_TYPES.ERROR
          );
        }
      );
  }

  patchPolicyToForm(policyIDs: Array<string>) {
    let policiesToPatch = [];
    this.availablePolicies.forEach((policy) => {
      if (policyIDs?.includes(policy.id)) {
        policiesToPatch.push(policy);
      }
    });
    this.customizedSelectTriggerTextConfig = ObjectUtil.deepClone({
      isShow: true,
      all: 'All Policies',
      plural: 'Policies',
    });
    this.policyIDs.patchValue(policiesToPatch, { emitEvent: false });
    this.cdr.markForCheck();
  }

  onChangePolicyOption(option: string) {
    if (option === POLICY_OPTION_KEYS.NO_POLICY) {
      this.policyIDs.patchValue([]);
      this.policyIDs.reset();
    }
  }

  removePolicy(policy: any) {
    const existingPolicies = this.policyIDs?.value;
    const modifiedPolicies = existingPolicies.filter(
      (item) => item.id !== policy.id
    );
    this.policyIDs.patchValue(modifiedPolicies);
  }

  updateRegionLoaderConfig(status = false, message = '', type = '') {
    this.policyLoader$ = of({
      status,
      message,
      type,
    } as LoaderConfig);
    this.cdr.markForCheck();
  }

  setSelectSearchConfigForPolicies() {
    // filter conflicting policies
    const updatedPolicies = this.availablePolicies.map((policy) => {
      const hasConflict = this.appUtil.hasAppMatchConditionConflict(
        policy?.details?.matches
      )?.hasConflict;
      return { ...policy, disabled: hasConflict };
    });
    const disabledPolicies = updatedPolicies.filter(
      (policy) => policy.disabled
    );
    const enabledPolicies = updatedPolicies.filter(
      (policy) => !policy.disabled
    );
    const config = {
      optionList: [...enabledPolicies, ...disabledPolicies],
      placeholder: 'Select Policy',
      uiThemeMode: this.uiThemeMode,
      showSearch: true,
      keyName: 'displayName',
      isMultiSelection: true,
    };
    if (disabledPolicies?.length) {
      Object.assign(config, {
        hintMessage: POLICY_CONFLICT_MESSAGE,
      });
    }
    this.selectSearchConfigForPolicies = ObjectUtil.deepClone(config);
    this.cdr.markForCheck();
  }

  setDrawerFooter() {
    this.onboardTemplateService
      .getDeleteResponse()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (response) => {
          if (response.action === DeleteAction.DELETE) {
            this.appOnboardService.deleteApplication(
              this.appData,
              this.uiThemeMode
            );
          } else if (response.action === DeleteAction.OFFBOARD) {
            this.appOnboardService.offboardApplication(
              response.confirmation,
              this.appData,
              this.uiThemeMode
            );
          }
        },
      });
    this.appOnboardService.setCreateTimeAndDeleteConfig(this.appData);
  }

  get policyOption() {
    return this.policyForm.get('policyOption');
  }
  get policyIDs() {
    return this.policyForm.get('policyIDs');
  }

  ngOnDestroy(): void {
    this.stopSubs$.next();
    this.stopSubs$.complete();
  }
}
