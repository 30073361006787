import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';

@Component({
  selector: 'app-accordion-header',
  templateUrl: './accordion-header.component.html',
  styleUrls: ['./accordion-header.component.scss'],
})
export class AccordionHeaderComponent implements OnInit, OnDestroy {
  @Input() btnLabel = 'New Item';
  @Input() legendConfig = null;
  @Input() dropdownConfig = null;
  @Input() progressConfig = null;
  @Input() isShowSearchBox = true;
  @Input() isShowCreateNewButton = true;
  @Input() isDisableCreateNewButton = false;
  @Input() filterableColumnMap;
  @Output() selectionChange = new EventEmitter();
  @Output() createNew = new EventEmitter();
  @Output() createNewImportIDPForm = new EventEmitter();
  @Output() searchTerm = new EventEmitter();
  @Output() selectedFilterMenuColumn = new EventEmitter();
  subscriptions = [];
  constructor() {}

  ngOnInit() {}

  onCreateNew(event) {
    this.createNew.emit();
  }

  onCreateImportPoliciesForm() {
    this.createNewImportIDPForm.emit();
  }
  onChangeDropdown(value: any) {
    this.selectionChange.emit(value);
  }

  searchTermListener(searchTerm: string) {
    this.searchTerm.emit(searchTerm);
  }

  selectedFilterMenuColumnListener(filteredColumnsSet: Set<string>) {
    this.selectedFilterMenuColumn.emit(filteredColumnsSet);
  }
  ngOnDestroy() {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }
}
