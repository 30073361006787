import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  MatPaginator,
  MatPaginatorSelectConfig,
  PageEvent,
} from '@angular/material/paginator';
import { Page } from './paginator.model';
import { PaginatorService } from './paginator.service';
import { PDashLocalStoreUtil } from '@app/state/web-pdash.util';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  _paginatorData;

  @Input() set paginatorData(paginatorData: Page) {
    this._paginatorData = paginatorData;
    if (paginatorData && !this.paginatorData?.start) {
      this.resetPaginator();
    }
  }

  get paginatorData() {
    return this._paginatorData;
  }

  @Input() pageSizeOptions: number[] = [100];
  @Input() isEventStream = true;
  @Output() data = new EventEmitter();

  pageEvent: PageEvent;

  selectConfig: MatPaginatorSelectConfig = {
    panelClass:
      'mat-panel__bg--' +
      PDashLocalStoreUtil.getUIThemeMode().toLowerCase() +
      '-theme-light',
  };

  constructor(private paginatorService: PaginatorService) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.paginator._intl.getRangeLabel = this.getRangeDisplayText;
  }

  /**
   *
   * @param page - Current page, starts at 0
   * @param pageSize - number of records to display in a page
   * @param length  - total number of records
   * @returns - string 'x of y' where x - current page and y - total available pages
   */
  getRangeDisplayText(page: number, pageSize: number, length: number) {
    if (length == 0 || pageSize == 0) {
      return `0 of ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} of ${length}`;
  }

  fetchMoreData(pageEvent: PageEvent) {
    const paginatorData = {
      size: pageEvent.pageSize,
      start: pageEvent.pageIndex * pageEvent.pageSize,
    } as Page;

    if (this.isEventStream) {
      this.paginatorService.setPaginatorData(paginatorData);
    } else {
      this.data.emit(paginatorData);
    }

    return pageEvent;
  }

  resetPaginator() {
    this.paginator.firstPage();
  }
}
