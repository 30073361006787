import { AppStore, appStore } from './app.store';

export class AppService {
  constructor(private appStore: AppStore) {}

  updateActiveTab(activeTab: string) {
    this.appStore.update((state) => ({
      activeTab,
    }));
  }
}

export const appService = new AppService(appStore);
