import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-number-operator',
  templateUrl: './number-operator.component.html',
  styleUrls: ['./number-operator.component.scss'],
})
export class NumberOperatorComponent implements OnInit, OnDestroy {
  @Input() set config(config: any) {
    this.operatorOptions = config?.values;
    this.operatorKey.setValue(config?.selectedKey);
  }
  @Output() changeSelection = new EventEmitter();
  subscriptions = [];
  operatorKey = new UntypedFormControl('');
  operatorControl = new UntypedFormControl('=');
  fromControl = new UntypedFormControl();
  toControl = new UntypedFormControl();
  displayRange = false;
  operatorOptions = [];
  options = [
    { displayName: '=', value: '=' },
    { displayName: '<', value: '<' },
    { displayName: '>', value: '>' },
    { displayName: 'Range', value: 'range' },
  ];

  constructor() {
    this.subscriptions.push(
      this.operatorControl.valueChanges
        .pipe(debounceTime(100), distinctUntilChanged())
        .subscribe((selectedOp) => {
          if (selectedOp === 'range') {
            this.displayRange = true;
          } else {
            this.displayRange = false;
          }
          this.emitChanges();
        }),

      this.fromControl.valueChanges
        .pipe(debounceTime(100), distinctUntilChanged())
        .subscribe((value) => {
          this.emitChanges();
        }),

      this.toControl.valueChanges
        .pipe(debounceTime(100), distinctUntilChanged())
        .subscribe((value) => {
          this.emitChanges();
        }),

        this.operatorKey.valueChanges
        .pipe(debounceTime(100), distinctUntilChanged())
        .subscribe((value) => {
          this.emitChanges();
        })
    );
  }

  ngOnInit() {}

  emitChanges() {
    const from = this.fromControl.value;
    const to = this.toControl.value;
    const operatorKey = this.operatorKey.value;
    this.changeSelection.emit({
      operator: this.operatorControl.value,
      values: this.displayRange
        ? from || to
          ? [from, to]
          : []
        : from
        ? [from]
        : [],
        selectedKey: operatorKey
    });
  }

  ngOnDestroy() {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }
}
