export class SelectPill {
  title?: string;
  dropDown: {
    label?: string;
    placeholder?: string;
    values: Array<object>;
  };
  uniqueKey?: string;
  targetKey?: string;
  selectedValues: Array<object>;

  constructor(
    title = 'Label',
    dropDown = null,
    targetKey = '',
    selectedValues = [],
    uniqueKey = 'id'
  ) {
    this.title = title;
    this.dropDown = dropDown;
    this.targetKey = targetKey;
    this.uniqueKey = uniqueKey;
    this.selectedValues = selectedValues;
  }
}
