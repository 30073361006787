import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export enum PDF_STATUS {
    IN_PROGRESS,
    DOWNLOADING,
    SUCCESS,
    FAILED,
    IDLE
}

@Injectable({
    providedIn: 'root'
})
export class PDFService {
    // ToDo: Move PDF download functionality here
    private pdfStatus$: Subject<PDF_STATUS> = new Subject();

    getPDFStatus(): Observable<PDF_STATUS> {
        return this.pdfStatus$.asObservable();
    }

    setPDFStatus(status: PDF_STATUS): void {
        this.pdfStatus$.next(status);
    }
}