<ng-container *ngIf="headerConfig$ | async as headerConfig">
  <header
    *ngIf="headerConfig && headerConfig.displayHeader"
    class="print-hidden"
    #component__header
    [ngClass]="[uiThemeMode | uiTheme]"
  >
    <pcomp-main-header
      [mainHeaderConfig]="mainHeaderConfig"
      (selectedProductViewType)="updateProductViewType($event)"
      (selectedMenuAction)="onMenuAction($event)"
      (selectedHost)="updateDashboardHost($event)"
      (setUIThemeMode)="setUIThemeMode(host)"
      (onRouteAction)="onRoute($event)"
      (onHelpAction)="onHelpActions($event)"
    ></pcomp-main-header>
  </header>
</ng-container>
