import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { NoDataService } from './no-data.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MESSAGES } from '@app/common/util/constants';

@Component({
  selector: 'app-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoDataComponent implements OnInit, OnDestroy {
  @Input() noDataParams;
  private unsubscribe$ = new Subject<void>();
  MESSAGE_TYPES = MESSAGES.TYPES;

  constructor(
    private noDataService: NoDataService,
    private cdr: ChangeDetectorRef
  ) {
    this.noDataService
      .getData()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.noDataParams = data;
        this.cdr.markForCheck();
      });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
