import { Resource } from '@app/common/http/util/resource';

export class AccountActivationModel extends Resource {
  token: string;

  constructor(token: string) {
    super();
    this.token = token;
  }
}
