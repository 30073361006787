<ng-template #quickStartGuide>
  <div>
    <button
      class="onboard-link-text color-primary-footer-back"
      type="button"
      (click)="openQuickStartGuide()"
    >
      Quick Start Guide
    </button>
  </div>
</ng-template>
<ng-template #gettingStartedLeftBlock>
  <div
    class="left-block d-flex flex-column justify-content-center align-items-center"
  >
    <div class="d-flex justify-content-center">
      <div>
        <img class="prosimo-logo" [src]="PROSIMO_LOGO" />
      </div>
    </div>
    <div class="header-text mt-5">Getting</div>
    <div class="header-text mt-1">Started</div>
  </div>
</ng-template>
<div class="d-flex justify-content-center align-items-center vh-100">
  <div class="getting-started-lightbox__container align-center">
    <app-lightbox-form-template
      [data]="lbData"
      #panel
      [closeable-panel]="panel"
    >
      <!-- FIRST STEP -->
      <div
        *ngIf="stepName === COPY_GETTING_STARTED_STEPS.FIRST_STEP"
        class="d-flex"
      >
        <ng-container [ngTemplateOutlet]="gettingStartedLeftBlock">
        </ng-container>
        <div
          class="letsGetStartedBlock bg-light-shade bdr-grey2"
          *ngIf="!clickOnLetsGetStarted"
        >
          <div class="onboard-msg-text">
            <div>Hi {{ userFirstName }},</div>
            <div class="mt-4 onboard-msg-text">
              Welcome to Prosimo! We&#39;re excited to have you here. Click on
              let&#39;s get started for next steps.
            </div>
          </div>
          <div class="getting-started-link-text d-flex align-items-center">
            <div class="d-flex">
              <button
                class="onboard-link-text color-primary-footer-back"
                type="button"
                (click)="letsGetStarted()"
              >
                Let&#39;s Get Started
                <div
                  class="mt-1 material-icons material-symbols-outlined d-flex align-items-center"
                >
                  arrow_right_alt
                </div>
              </button>
            </div>
          </div>
        </div>
        <div
          class="onboardBlock bg-light-shade bdr-grey2"
          *ngIf="clickOnLetsGetStarted"
        >
          <div class="onboard-header-text">Let&#39;s get started...</div>
          <div class="onboard-msg-text mt-5">1. Edge Connectivity Type</div>
          <div class="onboard-msg-text mt-3">
            2. Onboard Public Cloud Accounts
          </div>
          <div class="onboard-msg-text mt-3">3. Discover and Build Transit</div>
          <div class="d-flex align-items-center mt-5">
            <ng-container [ngTemplateOutlet]="quickStartGuide"> </ng-container>
            <div class="ml-5 d-flex align-items-center">
              <button
                class="onboard-link-text color-primary-footer-back"
                type="button"
                (click)="letsOnboard()"
              >
                Let&#39;s Onboard
                <div
                  class="mt-1 material-icons material-symbols-outlined d-flex align-items-center"
                >
                  arrow_right_alt
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- SECOND STEP -->
      <div
        *ngIf="stepName === COPY_GETTING_STARTED_STEPS.SECOND_STEP"
        class="d-flex"
      >
        <ng-container [ngTemplateOutlet]="gettingStartedLeftBlock">
        </ng-container>
        <div class="onboardBlock bg-light-shade bdr-grey2">
          <div class="onboard-header-text">Great Job!</div>
          <div class="d-flex onboard-msg-text mt-5">
            <div>1. Onboard Public Cloud Accounts</div>
            <div *ngIf="isStepOneDone" class="ml-2">
              <img src="{{ DAY0_CHECK_IMAGE }}" />
            </div>
          </div>
          <div class="onboard-msg-text mt-3">2. Discover and Build Transit</div>
          <div class="d-flex align-items-center mt-5">
            <ng-container [ngTemplateOutlet]="quickStartGuide"> </ng-container>

            <div class="d-flex align-items-center ml-5">
              <button
                class="onboard-link-text color-primary-footer-back"
                type="button"
                (click)="discoverTransit()"
              >
                Discover Transit
                <span class="material-icons material-symbols-outlined">
                  arrow_right_alt
                </span>
              </button>
              <div class="onboard-link-text d-flex align-items-center"></div>
            </div>
          </div>
        </div>
      </div>

      <!-- THIRD STEP-->
      <div
        *ngIf="stepName === COPY_GETTING_STARTED_STEPS.THIRD_STEP"
        class="d-flex"
      >
        <ng-container [ngTemplateOutlet]="gettingStartedLeftBlock">
        </ng-container>
        <div class="onboardBlock">
          <div class="onboard-header-text">Something is brewing</div>
          <div class="onboard-msg-text mt-5">
            We are busy setting up your transit. Check progress here. Once we
            are done setting up transit you&#39;ll be able to onboard networks
            and applications.
          </div>

          <div class="d-flex align-items-center mt-5">
            <ng-container [ngTemplateOutlet]="quickStartGuide"> </ng-container>
            <div class="d-flex ml-4">
              <button
                class="onboard-link-text color-primary-footer-back"
                type="button"
                (click)="onboardMoreAccounts()"
              >
                Onboard more accounts
                <div
                  class="mt-1 material-icons material-symbols-outlined d-flex align-items-center"
                >
                  arrow_right_alt
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </app-lightbox-form-template>
  </div>
</div>
