<ng-container *ngIf="tabs?.length">
  <div
    class="d-flex flex-fill tab-bar"
    [ngClass]="{
      'top-border': showTopBorder,
      'bottom-border': showBottomBorder,
      'bg-color': showBackgroundColor
    }"
  >
    <ng-container *ngFor="let tab of tabs; let $index = index">
      <div
        class="d-flex align-items-center justify-content-center"
        [ngClass]="{ 'ml-4': $index > 0 }"
      >
        <span
          (click)="onActionClick($index)"
          [ngStyle]="{color: selectedTabColor}"
          [class.selectedBottom]="tab.selected"
          class="tab py-2 cursor-pointer"
        >
          {{ tab?.label }}
        </span>
      </div>
    </ng-container>
  </div>
  <ng-content></ng-content>
</ng-container>
