import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import { LegendService } from './legend.service';
import { LegendData } from './legend.model';
import { AppUtil } from '@app/common/util/app-util';

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegendComponent implements OnInit, OnDestroy {
  @Input() legends;
  subscriptions = [];
  LEGEND_STATUS = {
    ON: 'on',
    OFF: 'off',
  };
  constructor(
    private legendservice: LegendService,
    private cdr: ChangeDetectorRef,
    private appUtil: AppUtil
  ) {}

  ngOnInit() {}

  setSelectedLegend(inLegend: LegendData) {
    this.legends.forEach((legend) => {
      if (legend.name === inLegend.name) {
        const hasCustomClasses = legend?.customStyles?.classes || false;
        const hasCustomStyles = legend?.customStyles?.styles || false;
        if (inLegend.status.toLowerCase() === this.LEGEND_STATUS.ON) {
          legend.status = this.LEGEND_STATUS.OFF;
          legend = this.getInactiveLegedsStyles(
            legend,
            hasCustomClasses,
            hasCustomStyles
          );
        } else {
          legend.status = this.LEGEND_STATUS.ON;
          legend = this.getActiveLegedsStyles(
            legend,
            hasCustomClasses,
            hasCustomStyles
          );
        }
      }
    });
    this.legendservice.setSelectedLegends(this.legends);
    this.appUtil.updateActiveLegends(this.legends);
    this.appUtil.updateNetworksActiveLegends(this.legends);
    this.cdr.markForCheck();
  }

  /**
   * This function will sets custom styles for Inactive legends
   * @param legend
   * @param hasCustomClasses
   * @param hasCustomStyles
   * @returns
   */
  getInactiveLegedsStyles(
    legend: any,
    hasCustomClasses: boolean,
    hasCustomStyles: boolean
  ) {
    if (hasCustomClasses) {
      legend.customStyles.classes.selected =
        legend?.customStyles?.classes?.inactive || '';
    }

    if (hasCustomStyles) {
      legend.customStyles.styles.selected =
        legend?.customStyles?.styles?.inactive || '';
    }

    if (!hasCustomClasses && !hasCustomStyles) {
      legend.color.fill = '#FFFFFF';
    }

    return legend;
  }

  /**
   * This function will sets custom styles for active legends
   * @param legend
   * @param hasCustomClasses
   * @param hasCustomStyles
   * @returns
   */
  getActiveLegedsStyles(
    legend: any,
    hasCustomClasses: boolean,
    hasCustomStyles: boolean
  ) {
    if (hasCustomClasses) {
      legend.customStyles.classes.selected =
        legend?.customStyles?.classes?.active || '';
    }

    if (hasCustomStyles) {
      legend.customStyles.styles.selected =
        legend?.customStyles?.styles?.active || '';
    }

    if (!hasCustomClasses && !hasCustomStyles) {
      legend.color.fill = legend?.color?.border || '';
    }

    return legend;
  }

  ngOnDestroy() {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }
}
