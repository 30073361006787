import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CarouselDisplayConfig } from './carousel.model';
const CONTAINER_PADDING = 80;
@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() cardCount = 0;
  @Input() isDynamic = false;
  @Input() isStepper = false;
  @Input('isVertical') set carouselDirection(state: boolean) {
    this.isVertical = state ? true : false;
    this.initCarousel();
  }
  @Input('selectedPage') set setSelectedPage(pageInfo: any) {
    if (pageInfo) {
      const { page, pos } = pageInfo;
      this.selectDisplayPage(page, pos);
    }
  }
  @Input() cardWidth: number; // the width of the card (content + padding + border + margin)
  @Input() singleRotate = true; // single rotation per click (can only use if isDyanmic is false)
  @Output() pagesConfigInfo = new EventEmitter();
  @ViewChild('cardsContainer') cardsContainer: ElementRef;
  @ContentChildren('hCard') contentHCards: QueryList<ElementRef>;
  @ContentChildren('vCard') contentCards: QueryList<ElementRef>;
  displayConfig = new CarouselDisplayConfig();
  pagesInfo: Array<any>;
  selectedPage: any;
  selectedPageIndex: any;
  cardsWidth: any;
  cardsHeight: any;
  isVertical = false;
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cardCount) {
      this.renderDisplayCard(this.getContainerWidth());
    }
  }

  ngAfterViewInit() {
    if (!this.isDynamic) {
      this.renderDisplayCard(this.getContainerWidth());
    } else {
      // listen the vertical content card changes and initiate the rendering logic
      this.contentCards?.changes?.subscribe((cards) => {
        this.initCarousel();
      });
      this.contentHCards?.changes?.subscribe((cards) => {
        this.initCarousel();
      });
    }
    this.initCarousel();
  }

  initCarousel() {
    const contentCards = this.isVertical
      ? this.contentCards
      : this.contentHCards;
    const cards = contentCards?.map((card) => card);
    if (!cards) {
      return;
    }
    this.resetCards();
    this.cardsWidth = this.getCardsWidthOrHeight(cards);
    this.cardsHeight = this.getCardsWidthOrHeight(cards, true);
    this.renderDynamicCards(
      this.getContainerWidth(),
      this.getContainerHeight()
    );
  }

  /**
   * Render content based on calculation
   * @param availableContainerWidth
   */
  renderDynamicCards(
    availableContainerWidth: number,
    availableContainerHeight: number
  ) {
    this.pagesInfo = this.getPagesInfo(
      availableContainerWidth,
      availableContainerHeight
    );
    this.selectDisplayPage(this.pagesInfo[0], 0);
    this.emitDisplayConfig(this.pagesInfo);
    this.cdr.markForCheck();
  }

  /**
   * Return the array of content cards width or height based on isVertical input
   */
  getCardsWidthOrHeight(cards, isHeight = false): Array<number> {
    if (!cards) {
      return [];
    }
    const measurements = [];
    cards.forEach((card) => {
      const el = card.nativeElement;
      const num = isHeight ? el?.offsetHeight : el?.offsetWidth;
      measurements.push(num);
    });
    return measurements;
  }

  /**
   * Return pages info which contain how many content card to show in one page
   * @param availableContainerWidth
   * @param availableContainerHeight
   *
   */
  getPagesInfo(
    availableContainerWidth: number,
    availableContainerHeight: number
  ) {
    let startIndex = 0;
    let totalSpace = 0;
    const availableSpace = this.isVertical
      ? availableContainerHeight
      : availableContainerWidth;
    const pages = [];
    const cardsDimension = this.isVertical ? this.cardsHeight : this.cardsWidth;
    if (!cardsDimension) {
      return [];
    }
    cardsDimension.forEach((card, pos) => {
      totalSpace += card;
      if (totalSpace > availableSpace) {
        pages.push({ start: startIndex, end: pos - 1 });
        startIndex = pos;
        totalSpace = card;
      }
      if (pos === cardsDimension.length - 1) {
        pages.push({ start: startIndex, end: pos });
      }
    });
    return pages;
  }

  /**
   * Display selected page content card
   * @param page
   * @param pos
   */
  selectDisplayPage(page: any, pos: number) {
    this.selectedPage = page;
    this.selectedPageIndex = pos;
    this.renderPageInfo();
    this.cdr.markForCheck();
  }

  /**
   * Based on selected page info, display content cards
   */
  renderPageInfo() {
    const { start = 0, end = 0 } = this.selectedPage || {};
    const contentCards = this.isVertical
      ? this.contentCards
      : this.contentHCards;
    contentCards.forEach((card, pos) => {
      const cardClassList = card.nativeElement.classList;
      if (pos >= start && pos <= end) {
        cardClassList.remove('hide-carousel-card');
      } else {
        cardClassList.add('hide-carousel-card');
      }
    });
  }

  /**
   * Display all content cards
   */
  resetCards() {
    const contentCards = this.isVertical
      ? this.contentCards
      : this.contentHCards;
    contentCards?.forEach((card) => {
      const cardClassList = card.nativeElement.classList;
      cardClassList.remove('hide-carousel-card');
    });
  }

  /**
   * Render next page content cards
   * @param event
   */
  onNextPage(event: any) {
    const lastIndex = this.pagesInfo.length - 1;
    if (this.selectedPageIndex < lastIndex) {
      this.selectedPageIndex += 1;
      this.selectedPage = this.pagesInfo[this.selectedPageIndex];
      this.renderPageInfo();
      this.cdr.markForCheck();
    }
  }

  /**
   * Render previous page content cards
   * @param event
   */
  onPreviousPage(event: any) {
    if (this.selectedPageIndex > 0) {
      this.selectedPageIndex -= 1;
      this.selectedPage = this.pagesInfo[this.selectedPageIndex];
      this.renderPageInfo();
      this.cdr.markForCheck();
    }
  }

  /**
   * Return the width of the container
   */
  getContainerWidth() {
    return this.cardsContainer
      ? this.cardsContainer?.nativeElement?.offsetWidth - CONTAINER_PADDING
      : 0;
  }

  getContainerHeight() {
    return this.cardsContainer
      ? this.cardsContainer?.nativeElement?.offsetHeight - CONTAINER_PADDING
      : 0;
  }

  /**
   * Listen window resize event
   * @param event
   */
  onResize(event: any) {
    const newContainerWidth = this.getContainerWidth();
    const newContainerHeight = this.getContainerHeight();
    if (!this.isDynamic) {
      this.renderDisplayCard(newContainerWidth);
    } else {
      this.renderDynamicCards(newContainerWidth, newContainerHeight);
    }
  }

  /**
   * Calculate how many cards to display based on window size
   * @param width
   */
  renderDisplayCard(width: number) {
    if (!width && !this.cardCount) {
      return;
    }
    const displayCardsCount = Math.floor(width / this.cardWidth);
    let startIndex = this.displayConfig.start || 0;

    // calculate end index
    const endIndex =
      startIndex + displayCardsCount > this.cardCount
        ? this.cardCount
        : startIndex + displayCardsCount;
    const range = endIndex - startIndex;

    // recalculate start index if the end index is equal to cardCount
    if (endIndex === this.cardCount && range < displayCardsCount) {
      const delta = displayCardsCount - range;
      startIndex = delta > startIndex ? 0 : startIndex - delta;
    }
    this.displayConfig.setRange(startIndex, endIndex, displayCardsCount);
  }

  /**
   * Rotate the cards based on the slide icon
   * @param event
   * @param type
   */
  onSlide(event: any, type: string) {
    if (!this.cardCount) {
      return;
    }
    event.stopPropagation();
    const { start, count } = this.displayConfig;
    const range = this.singleRotate ? 1 : count; // set how many card to rotate
    const startIndex =
      type === 'next' ? start + range : start === 0 ? 0 : start - range;
    const endIndex =
      startIndex + count > this.cardCount ? this.cardCount : startIndex + count;
    this.displayConfig.setRange(startIndex, endIndex, count);
  }

  /**
   * emit the available data
   * @param config
   */
  emitDisplayConfig(pageInfo: Array<any>) {
    this.pagesConfigInfo.emit(pageInfo);
  }
}
