export interface BannerModel {
  header?: string;
  type: string;
  closeable: boolean;
  isCloseOnRoute: boolean;
  data?: any;
}
export interface MaintenanceModel {
  from?: string | number;
  to?: string | number;
  message?: string;
  timezone?: string;
  path?: string;
}

export interface TrialModel {
  firstDigit?: string | number; // between (0-9)
  secondDigit?: string | number; // between (0-9)
  accountType?: string; // must be ('trial' or 'customer')
}

export const BannerType = {
  maintenance: 'maintenance',
  accessDenied: 'accessDenied',
  trial: 'trial',
};

export const MaintenanceBannerConfig = {
  type: BannerType.maintenance,
  isCloseOnRoute: false,
  closeable: true,
  data: {} as MaintenanceModel,
} as BannerModel;

export const AccessDeniedBannerConfig = {
  type: BannerType.accessDenied,
  isCloseOnRoute: true,
  closeable: true,
} as BannerModel;

export const TrialBannerConfig = {
  type: BannerType.trial,
  isCloseOnRoute: false,
  closeable: false,
  data: {} as TrialModel,
} as BannerModel;

export const TRIAL_ACCOUNT_MSG = {
  CONFIRM: `<p class="px-3"> Proceeding with onboard will remove all the read-only data provided
  to experience the Prosimo platform and will start the onboarding workflow for your dashboard account </p>`,
  LOADING: `<p class="px-3"> Setting up your dashboard account for onboarding </p>`,
};
