<div
  id="container__alert"
  class="bg__color-{{
    (alertConfig$ | async)?.type | lowercase
  }} default_container"
  role="alert"
  [ngClass]="{
    half_container: compact,
    'hide-alert': !displayAlert,
    'show-alert': displayAlert
  }"
>
  <span
    *ngIf="
      ((alertConfig$ | async)?.type | lowercase) !==
      (ALERT_TYPES_COPY.STATUS | lowercase)
    "
    class="close__sign white"
    (click)="resetAlertComponent()"
    matTooltip="Close..."
  >
    &times;
  </span>
  <div class="message">
    <p class="text-break">{{ (alertConfig$ | async)?.message1 }}</p>
    <div
      [ngClass]="{
        'hide-message': !(alertConfig$ | async)?.message2,
        'show-message': (alertConfig$ | async)?.message2
      }"
    >
      <div [innerHTML]="(alertConfig$ | async)?.message2 | safe : 'html'"></div>
    </div>
  </div>
</div>
