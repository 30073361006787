import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
} from '@angular/core';
import {
  Validators,
  UntypedFormGroup,
  UntypedFormBuilder,
} from '@angular/forms';
import { AppUtil } from '@app/common/util/app-util';
import { COLORS, IDP_TYPES, SYNC_FROM_IDP } from '@app/common/util/constants';
import {
  SyncFromIDPConfigModel,
  SYNC_FROM_IDP_SOURCES,
  IDP_IMPORT_PARAMETER_STATUS,
  IDPImportParameterModel,
} from './sync-from-idp.model';
import { IDPManagementServicesModel } from '@app/management/idp/idp.management.model';
import {
  LoaderService,
  SelectSearchConfig,
  ToastService,
} from '@prosimoio/components';
import { PDashLocalStoreUtil } from '@app/state/web-pdash.util';
import { IDP_CONSTANTS } from '@app/management/idp/idp.constants';
import { DROPDOWN_PANEL_CLASS } from '@app/management/management.constants';
import {
  ILightboxBtnConfig,
  LightboxBtnType,
} from '@app/dashboard/diagnose/diagnostics/lightbox-form-template/lightbox-model';
@Component({
  selector: 'app-sync-from-idp',
  templateUrl: './sync-from-idp.component.html',
  styleUrls: ['./sync-from-idp.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SyncFromIdpComponent implements OnInit, OnDestroy {
  private _syncFromIDPConfig: SyncFromIDPConfigModel;
  private _idpImportParameters: Array<IDPImportParameterModel>;
  isShowParameterValidationError = false;
  inActiveParameters = [];
  selectedParamters = [];
  importFromIDPForm: UntypedFormGroup;
  subscriptions = [];
  COLORS = COLORS;
  SYNC_FROM_IDP_SOURCE = SYNC_FROM_IDP_SOURCES;
  IDP_IMPORT_PARAMETER_STATUS = IDP_IMPORT_PARAMETER_STATUS;
  selectedIDP: IDPManagementServicesModel = null;
  allowMultipleIDPNameSelection: boolean = false;
  selectSearchConfigForIDPS: SelectSearchConfig;
  uiThemeMode: string = PDashLocalStoreUtil.getUIThemeMode()?.toLowerCase();
  tooltipThemeClass: string;
  REQUIRED = 'Required';
  showLoader: boolean = false;
  get syncFromIDPConfig(): SyncFromIDPConfigModel {
    return this._syncFromIDPConfig;
  }

  get idpImportParameters(): Array<IDPImportParameterModel> {
    return this._idpImportParameters;
  }
  @Input() set data(data: any) {
    if (data?.syncFromIDPConfig) {
      this._syncFromIDPConfig = data?.syncFromIDPConfig;
      const { source = '', importParameters = [] } = this.syncFromIDPConfig;
      this.allowMultipleIDPNameSelection =
        this.syncFromIDPConfig?.supportedIDPTypes?.length > 1 &&
        source !== this.SYNC_FROM_IDP_SOURCE.IDP
          ? true
          : false;

      this._idpImportParameters = importParameters;
      if (source === this.SYNC_FROM_IDP_SOURCE.IDP) {
        this.findInActiveParameters(importParameters);
      }
      this.initImportFromIDPForm();
      this.isShowParameterValidationError = false;
      this.filterImportParameters(
        this.syncFromIDPConfig?.selectedIDPConfig?.selectedIDP?.idpName
      );
      this.initImportFromIDPForm();
      this.updateSelectSearchIDPS();
    }
  }

  @Output() selectedIDPToImportParameters = new EventEmitter();
  constructor(
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private appUtil: AppUtil
  ) {
    // confirm action
    this.subscriptions.push(
      PDashLocalStoreUtil.getUIThemeModeAsObservable().subscribe((mode) => {
        this.uiThemeMode = mode?.toLowerCase() + '-theme';
        this.tooltipThemeClass =
          'cdk-component-container--' + this.uiThemeMode + '-dark';
        this.toastService.setUITheme(this.uiThemeMode);
        this.updateSelectSearchIDPS();
        this.cdr.markForCheck();
      }),
      this.appUtil
        .getDrawerResponse()
        .subscribe((response: ILightboxBtnConfig) => {
          this.drawerResponseAction(response);
        }),
      this.loaderService.getLoaderStatus().subscribe((status) => {
        this.showLoader = status;
        this.cdr.markForCheck();
      })
    );
  }

  ngOnInit(): void {}

  initImportFromIDPForm() {
    if (this.syncFromIDPConfig.source !== this.SYNC_FROM_IDP_SOURCE.IDP) {
      this.importFromIDPForm = this.fb.group({
        idps: [this.syncFromIDPConfig.selectedIDPConfig, [Validators.required]],
      });
    } else {
      this.importFromIDPForm = this.fb.group({
        idps: [
          this.syncFromIDPConfig.selectedIDPConfig.selectedIDP,
          [Validators.required],
        ],
        parameters: [
          this.syncFromIDPConfig.selectedIDPConfig.selectedParameters,
          [Validators.required],
        ],
      });
    }
  }

  // to find parameters for which import is in progress
  findInActiveParameters(parameters: any) {
    this.inActiveParameters = parameters.filter(
      (item) => item.status === this.IDP_IMPORT_PARAMETER_STATUS.INACTIVE
    );
  }

  /**
   * Remove selected idp from dropdown in import data from idp
   */
  removeSelectedIDPFromDropdown(idp) {
    const formControl = this.importFromIDPForm.get('idps');
    const currentValues = formControl.value ? [...formControl.value] : [];
    const newValues = currentValues.filter((value) => {
      return value !== idp;
    });
    formControl.patchValue(newValues);
    this.cdr.markForCheck();
  }

  onDone() {
    if (!this.importFromIDPForm) {
      return;
    }
    const formData = this.importFromIDPForm.getRawValue();
    let idps = formData ? formData.idps : [];
    if (this.syncFromIDPConfig.source !== this.SYNC_FROM_IDP_SOURCE.IDP) {
      if (formData && !Array.isArray(idps)) {
        idps = [idps];
      }
      if (this.importFromIDPForm.invalid) {
        this.importFromIDPForm.get('idps').markAsTouched();
      } else {
        this.afterDone(formData, idps);
      }
    } else {
      if (
        this.importFromIDPForm.invalid ||
        !this.importFromIDPForm.get('parameters').value.length
      ) {
        this.importFromIDPForm.get('idps').markAsTouched();
        this.isShowParameterValidationError = true;
      } else {
        this.afterDone(formData, idps);
      }
    }
  }

  afterDone(formData, idps) {
    const dataToSend = Object.assign({}, formData, { idps });
    this.appUtil.setDynamicDrawerResponse({
      payload: dataToSend,
      context: SYNC_FROM_IDP,
      action: LightboxBtnType.SYNC.code,
    });
  }

  // emit selected idp to parent component
  onChangeIDP(event) {
    const selectedIDP = event.value;
    this.appUtil.updateDrawerButtonConfig({
      mainBtn: { ...LightboxBtnType.SYNC, disabled: false },
      cancelBtn: LightboxBtnType.CANCEL,
    });
    this.filterImportParameters(selectedIDP.idpName);
  }

  updateSelectSearchIDPS() {
    this.selectSearchConfigForIDPS = {
      optionList: this.syncFromIDPConfig?.supportedIDPTypes,
      placeholder: IDP_CONSTANTS?.SYNC_IDP_DROPDOWN_PLACEHOLDER,
      uiThemeMode: this.uiThemeMode,
      keyName: IDP_CONSTANTS?.SYNC_IDP_KEYNAME,
      panelWidthClass: DROPDOWN_PANEL_CLASS?.LARGE,
    };
  }

  /**
   * filter import parameters application and policy for google idp
   * @param selectedIDP
   */
  filterImportParameters(selectedIDP: string) {
    if (selectedIDP === IDP_TYPES.GOOGLE) {
      const importParameters = this._idpImportParameters.slice();
      this._idpImportParameters = importParameters?.filter(
        (parameter) => !['application', 'policy'].includes(parameter?.key)
      );
      this.cdr.markForCheck();
    }
  }

  get idps() {
    return this.importFromIDPForm?.get('idps');
  }

  drawerResponseAction(response: any) {
    if (response.code === LightboxBtnType.SYNC.code) {
      this.onDone();
    }
  }

  ngOnDestroy() {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }
}
