<div class="mx-4 mt-2">
  <p class="page__hint">
    Application behind the Prosimo Fabric and route the user traffic via the
    Fabric.
  </p>
  <mat-accordion
    [formGroup]="appDefinitionForm"
    [multi]="true"
    class="bordered-panel"
  >
    <!--App Details Panel-->
    <mat-expansion-panel
      [expanded]="true"
      class="form-panel mat-expansion-panel-spacing"
    >
      <mat-expansion-panel-header class="form-panel__header arrow">
        <mat-panel-title>App Type</mat-panel-title>
      </mat-expansion-panel-header>
      <mat-label class="d-flex form__label pb-2">
        What kind of app is this?
      </mat-label>
      <div class="w-50">
        <pcomp-mat-select-search
          [parentForm]="appDefinitionForm"
          [config]="selectSearchConfigForAppOnboardType"
          [formControlNameInput]="appOnboardType"
          (emitSelections)="onAppOnboardTypeChange($event?.value)"
          controlName="appOnboardType"
        >
        </pcomp-mat-select-search>
      </div>
      <ng-container *ngIf="accessType">
        <mat-label class="d-flex form__label pb-2"> Access Method </mat-label>
        <div class="w-50">
          <pcomp-mat-select-search
            [parentForm]="appDefinitionForm"
            [config]="selectSearchConfigForAccessType"
            [formControlNameInput]="accessType"
            (emitSelections)="onAccessMethodChange($event?.value)"
            controlName="accessType"
          >
          </pcomp-mat-select-search>
        </div>
      </ng-container>
      <ng-container *ngIf="ipAllowListEnabled">
        <div class="d-flex flex-wrap align-items-center mb-3">
          <mat-checkbox
            class="form__label"
            color="primary"
            formControlName="ipAllowListEnabled"
            (change)="checkIPAllowlistRange($event?.checked)"
          >
            Enter IP allow-list range
          </mat-checkbox>
          <div class="mb-2">
            <pcomp-info-tooltip
              [matTooltipClass]="tooltipThemeClass"
              [tooltipTemplate]="COPY_TOOLTIP_MESSAGE?.IP_ALLOWLIST"
              tooltipTheme="cdk-component-container--{{ uiThemeMode }}-light"
            >
            </pcomp-info-tooltip>
          </div>
        </div>

        <ng-container *ngIf="citrixIPControl">
          <div class="d-flex align-items-center">
            <div class="d-flex flex-fill flex-column w-100">
              <mat-form-field appearance="outline">
                <div class="d-flex">
                  <input
                    matInput
                    placeholder="Enter IP address or IP range"
                    formControlName="citrixIPControl"
                    autocomplete="off"
                  />
                </div>
              </mat-form-field>
            </div>

            <div class="d-flex flex-fill flex-column w-100">
              <button
                (click)="addCitrixIP()"
                type="button"
                class="color-primary-footer-back addBtn position-relative mb-4 ml-4"
              >
                Add
              </button>
            </div>
          </div>
          <div *ngIf="citrixIP?.value?.length" class="mb-3 w-50">
            <pcomp-minimized-list
              [data]="citrixIP?.value"
              [isArray]="true"
              minVisibleCount="3"
              (elementRemoved)="removeCitrixIP($event)"
            ></pcomp-minimized-list>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="cloudService">
        <mat-label class="d-flex form__label pb-2">
          Cloud Platform Services
        </mat-label>
        <mat-form-field class="w-50" appearance="outline">
          <mat-select
            formControlName="cloudService"
            placeholder="Select Cloud Platform Service"
            panelClass="mat-panel__bg--{{
              uiThemeMode
            }}-theme-light select-width-lg"
            disableRipple
            disableOptionCentering
          >
            <mat-option
              *ngFor="let item of COPY_CLOUD_SERVICE_OPTIONS"
              [value]="item?.value"
            >
              {{ item?.displayName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
    </mat-expansion-panel>

    <!--Cloud Details Panel-->
    <mat-expansion-panel
      [expanded]="true"
      class="form-panel mat-expansion-panel-spacing"
    >
      <mat-expansion-panel-header class="form-panel__header">
        <mat-panel-title>Cloud Details</mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngIf="cloudHostedType">
        <mat-label class="d-flex form__label">
          Where is your domain hosted?
        </mat-label>
        <div class="mt-2">
          <mat-radio-group
            formControlName="cloudHostedType"
            (change)="onChangeCloudHostedType($event?.value)"
          >
            <span *ngFor="let option of COPY_CLOUD_TYPE_OPTIONS">
              <mat-radio-button
                class="{{ option?.class }}"
                [value]="option?.value"
                color="primary"
                >{{ option?.displayName }}</mat-radio-button
              >
            </span>
          </mat-radio-group>
        </div>
      </ng-container>
      <ng-container *ngIf="cspType">
        <mat-label class="d-flex form__label pb-2 mt-3">
          Select Cloud Service Provider
        </mat-label>
        <div class="w-50">
          <pcomp-mat-select-search
            [parentForm]="appDefinitionForm"
            [config]="selectSearchConfigForCSPType"
            [isShowOptionLogo]="isShowCSPOptionLogo"
            [formControlNameInput]="cspType"
            (emitSelections)="onCSPTypeChange($event?.value)"
            controlName="cspType"
          >
          </pcomp-mat-select-search>
        </div>
      </ng-container>
      <ng-container *ngIf="dcID">
        <ng-container *ngIf="(cloudLoader$ | async)?.status">
          <div
            class="my-3"
            [ngClass]="
              (cloudLoader$ | async)?.type === COPY_LOADER_CONFIG_TYPES.ERROR
                ? 'status-failed-clr'
                : 'clr-grey7'
            "
          >
            {{ (cloudLoader$ | async)?.message }}
          </div>
        </ng-container>
        <ng-container *ngIf="!(cloudLoader$ | async)?.status">
          <div
            *ngIf="
              selectSearchConfigForPrivateCloudAccounts?.optionList?.length;
              then cloudBlock;
              else noCloudBlock
            "
          ></div>
          <ng-template #cloudBlock>
            <div class="d-flex align-items-baseline mt-2">
              <mat-label class="d-flex form__label"
                >Select Private Cloud
              </mat-label>
              <div class="ml-2">
                <pcomp-mat-error-info-tooltip
                  [errorMessageConfig]="{
                    isShowTooltip: false,
                    errorCondition: dcID?.invalid && dcID?.touched,
                    errorMessage: COPY_ERROR_MESSAGE?.CLOUD
                  }"
                  [uiThemeMode]="uiThemeMode"
                ></pcomp-mat-error-info-tooltip>
              </div>
            </div>
            <div
              class="mt-2 mb-3 w-50"
              [ngClass]="{
                'invalid-range': dcID?.invalid && dcID?.touched
              }"
            >
              <pcomp-mat-select-search
                class="mat-form-field-wrapper-remove-padding"
                *ngIf="
                  selectSearchConfigForPrivateCloudAccounts?.optionList?.length
                "
                [parentForm]="appDefinitionForm"
                [config]="selectSearchConfigForPrivateCloudAccounts"
                [formControlNameInput]="dcID"
                (emitSelections)="onChangePrivateCloudAccount($event?.value)"
                controlName="dcID"
              >
              </pcomp-mat-select-search></div
          ></ng-template>
          <ng-template #noCloudBlock>
            <div class="my-2">No Private Cloud Available</div></ng-template
          >
        </ng-container>
      </ng-container>
    </mat-expansion-panel>

    <!--Domain Details Panel-->
    <mat-expansion-panel
      [expanded]="true"
      class="form-panel mat-expansion-panel-spacing"
    >
      <mat-expansion-panel-header class="form-panel__header arrow">
        <mat-panel-title>Domain Details</mat-panel-title>
      </mat-expansion-panel-header>
      <div formArrayName="appURLs">
        <div
          [ngClass]="{
            'app-domain-block-border':
              appURLsArray?.controls?.length > 1 &&
              domainIndex !== appURLsArray?.controls?.length - 1
          }"
          *ngFor="
            let appURLFormGroup of appURLsArray?.controls;
            let domainIndex = index
          "
        >
          <div
            [formGroup]="appURLFormGroup"
            [ngClass]="{
              'mt-2': domainIndex !== 0
            }"
            class="d-flex flex-fill w-100 justify-content-between"
          >
            <div class="d-flex flex-fill flex-column w-100">
              <!--Internal Domain-->
              <ng-container
                *ngIf="
                  appURLFormGroup?.get(
                    'internalDomain'
                  ) as internalDomainControl
                "
              >
                <div class="d-flex align-items-baseline">
                  <mat-label class="d-flex form__label pb-2">
                    {{ controlLabels?.internalDomain }}
                  </mat-label>
                  <div class="ml-2">
                    <pcomp-mat-error-info-tooltip
                      [errorMessageConfig]="{
                        isShowTooltip: false,
                        errorCondition:
                          internalDomainControl?.touched &&
                          (internalDomainControl?.hasError('required') ||
                            internalDomainControl?.hasError('pattern') ||
                            internalDomainControl?.hasError('notUnique')),
                        errorMessage:
                          internalDomainControl?.hasError('required') &&
                          internalDomainControl?.touched
                            ? COPY_ERROR_MESSAGE.REQUIRED
                            : internalDomainControl?.hasError('notUnique')
                            ? COPY_ERROR_MESSAGE.NOT_UNIQUE
                            : COPY_ERROR_MESSAGE.INVALID
                      }"
                      [uiThemeMode]="uiThemeMode"
                    ></pcomp-mat-error-info-tooltip>
                  </div>
                </div>
                <div
                  [ngClass]="{
                    'invalid-range':
                      internalDomainControl?.touched &&
                      (internalDomainControl?.hasError('required') ||
                        internalDomainControl?.hasError('pattern') ||
                        internalDomainControl?.hasError('notUnique'))
                  }"
                >
                  <mat-form-field class="w-100 mb-n3" appearance="outline">
                    <input
                      matInput
                      placeholder="Enter Internal Domain"
                      formControlName="internalDomain"
                      (blur)="onUpdateInternalDomain(domainIndex)"
                      autocomplete="off"
                      required
                    />
                  </mat-form-field>
                </div>
              </ng-container>
              <!--Include Sub-Domain check-->
              <ng-container
                *ngIf="
                  appURLFormGroup?.get('subdomainIncluded') &&
                  [
                    COPY_APP_ONBOARD_TYPE_OPTION_KEYS.WEB,
                    COPY_APP_ONBOARD_TYPE_OPTION_KEYS.VDI
                  ]?.includes(appOnboardType?.value?.key)
                "
              >
                <div class="d-flex flex-wrap align-items-center mt-2">
                  <mat-checkbox
                    class="form__label"
                    color="primary"
                    formControlName="subdomainIncluded"
                    (change)="
                      checkSubdomainIncluded($event?.checked, domainIndex)
                    "
                  >
                    <span class="clr-grey7">{{
                      controlLabels?.subdomainIncluded
                    }}</span>
                  </mat-checkbox>
                  <div class="mb-2">
                    <pcomp-info-tooltip
                      [matTooltipClass]="tooltipThemeClass"
                      [tooltipTemplate]="subdomainIncludedTooltip"
                      tooltipTheme="cdk-component-container--{{
                        uiThemeMode
                      }}-light"
                    >
                    </pcomp-info-tooltip>
                  </div>
                </div>
              </ng-container>
              <!--Domain Type/ External Domain-->
              <ng-container
                *ngIf="
                  appURLFormGroup?.get('domainType') &&
                  [
                    COPY_APP_ONBOARD_TYPE_OPTION_KEYS.WEB,
                    COPY_APP_ONBOARD_TYPE_OPTION_KEYS.VDI
                  ]?.includes(appOnboardType?.value?.key)
                "
              >
                <div
                  [ngClass]="{
                    'mt-3':
                      appURLFormGroup?.get('exceptionDomains')?.value?.length
                  }"
                >
                  <mat-label class="d-flex form__label mt-2"
                    >External Domain
                  </mat-label>
                </div>

                <div class="mt-3">
                  <mat-radio-group
                    formControlName="domainType"
                    (change)="onChangeDomainype($event?.value, domainIndex)"
                  >
                    <span *ngFor="let domainType of COPY_DOMAIN_TYPE_OPTIONS">
                      <mat-radio-button
                        class="{{ domainType?.class }}"
                        [value]="domainType?.value"
                        color="primary"
                        >{{ domainType?.displayName }}</mat-radio-button
                      >
                    </span>
                  </mat-radio-group>
                </div>
              </ng-container>
              <!--AppFQDN-->
              <ng-container
                *ngIf="appURLFormGroup?.get('appFqdn') as appFqdnControl"
              >
                <div class="d-flex align-items-baseline mt-2">
                  <mat-label class="d-flex form__label pb-2">
                    {{ controlLabels?.appFqdn }}
                  </mat-label>
                  <div class="ml-2">
                    <pcomp-mat-error-info-tooltip
                      [errorMessageConfig]="{
                        isShowTooltip: false,
                        errorCondition:
                          appFqdnControl?.touched &&
                          (appFqdnControl?.hasError('required') ||
                            appFqdnControl?.hasError('pattern') ||
                            appFqdnControl?.hasError('notUnique')),
                        errorMessage:
                          appFqdnControl?.hasError('required') &&
                          appFqdnControl?.touched
                            ? COPY_ERROR_MESSAGE.REQUIRED
                            : appFqdnControl?.hasError('notUnique')
                            ? COPY_ERROR_MESSAGE.NOT_UNIQUE
                            : COPY_ERROR_MESSAGE.INVALID
                      }"
                      [uiThemeMode]="uiThemeMode"
                    ></pcomp-mat-error-info-tooltip>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div
                    class="d-flex flex-column"
                    [ngClass]="{
                      'flex-fill w-100': [
                        COPY_APP_ONBOARD_TYPE_OPTION_KEYS.WEB,
                        COPY_APP_ONBOARD_TYPE_OPTION_KEYS.VDI
                      ]?.includes(appOnboardType?.value?.key),
                      'w-50': ![
                        COPY_APP_ONBOARD_TYPE_OPTION_KEYS.WEB,
                        COPY_APP_ONBOARD_TYPE_OPTION_KEYS.VDI
                      ]?.includes(appOnboardType?.value?.key),
                      'invalid-range':
                        appFqdnControl?.touched &&
                        (appFqdnControl?.hasError('required') ||
                          appFqdnControl?.hasError('pattern') ||
                          appFqdnControl?.hasError('notUnique'))
                    }"
                  >
                    <mat-form-field class="w-100 mb-n3" appearance="outline">
                      <input
                        matInput
                        [placeholder]="controlLabels?.appFqdnPlaceholder"
                        formControlName="appFqdn"
                        autocomplete="off"
                        required
                      />
                    </mat-form-field>
                  </div>
                  <div
                    class="d-flex flex-fill flex-column w-100"
                    *ngIf="
                      appURLFormGroup?.get('domainType')?.value ===
                      COPY_DOMAIN_TYPE_KEYS.PROSIMO
                    "
                  >
                    <div class="ml-2 mb-2">{{ prosimoDomain }}</div>
                  </div>
                </div>
              </ng-container>

              <ng-container
                *ngIf="
                  appURLFormGroup?.get('subdomainIncluded') &&
                  [
                    COPY_APP_ONBOARD_TYPE_OPTION_KEYS.FQDN,
                    COPY_APP_ONBOARD_TYPE_OPTION_KEYS.CLOUD_SVC
                  ]?.includes(appOnboardType?.value?.key)
                "
              >
                <div class="d-flex flex-wrap align-items-center mt-2">
                  <mat-checkbox
                    class="form__label"
                    color="primary"
                    formControlName="subdomainIncluded"
                  >
                    <span class="clr-grey7">{{
                      controlLabels?.subdomainIncluded
                    }}</span>
                  </mat-checkbox>
                  <div class="mb-2">
                    <pcomp-info-tooltip
                      [matTooltipClass]="tooltipThemeClass"
                      [tooltipTemplate]="subdomainIncludedTooltip"
                      tooltipTheme="cdk-component-container--{{
                        uiThemeMode
                      }}-light"
                    >
                    </pcomp-info-tooltip>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="appURLFormGroup?.get('serviceIPType')">
                <div class="mt-3">
                  <mat-label class="d-flex form__label">
                    {{ controlLabels?.serviceIPType }}
                  </mat-label>
                  <div class="mt-2">
                    <mat-radio-group
                      formControlName="serviceIPType"
                      (change)="
                        onChangeServiceIPType($event?.value, domainIndex)
                      "
                    >
                      <span
                        *ngFor="
                          let option of COPY_SERVICE_IP_ADDRESS_INPUT_METHOD_OPTIONS
                        "
                      >
                        <mat-radio-button
                          class="{{ option?.class }}"
                          [value]="option?.value"
                          color="primary"
                          >{{ option?.displayName }}</mat-radio-button
                        >
                      </span>
                    </mat-radio-group>
                  </div>
                </div>
              </ng-container>

              <ng-container
                *ngIf="appURLFormGroup?.get('serviceIP') as serviceIPControl"
              >
                <div class="d-flex align-items-baseline">
                  <mat-label class="d-flex form__label mt-3">
                    {{ controlLabels?.serviceIP }}
                  </mat-label>
                  <div class="ml-2">
                    <pcomp-mat-error-info-tooltip
                      [errorMessageConfig]="{
                        isShowTooltip: false,
                        errorCondition:
                          serviceIPControl?.touched &&
                          (serviceIPControl?.hasError('required') ||
                            serviceIPControl?.hasError('pattern')),
                        errorMessage: serviceIPControl?.hasError('required')
                          ? COPY_ERROR_MESSAGE.REQUIRED
                          : COPY_ERROR_MESSAGE.INVALID
                      }"
                      [uiThemeMode]="uiThemeMode"
                    ></pcomp-mat-error-info-tooltip>
                  </div>
                </div>

                <div
                  class="d-flex align-items-top mt-2 mb-3"
                  [ngClass]="{
                    'w-100':
                      appURLFormGroup?.get('serviceIPType')?.value ===
                      COPY_SERVICE_IP_ADDRESS_INPUT_METHOD_OPTION_KEYS.MANUAL,
                    'w-50':
                      appURLFormGroup?.get('serviceIPType')?.value !==
                      COPY_SERVICE_IP_ADDRESS_INPUT_METHOD_OPTION_KEYS.MANUAL,
                    'invalid-range':
                      serviceIPControl?.touched &&
                      (serviceIPControl?.hasError('required') ||
                        serviceIPControl?.hasError('pattern') ||
                        serviceIPControl?.hasError('customError'))
                  }"
                >
                  <div class="d-flex flex-column flex-fill w-100">
                    <mat-form-field
                      appearance="outline"
                      class="mat-form-field-wrapper-remove-padding"
                    >
                      <div class="d-flex">
                        <input
                          matInput
                          placeholder="Service IP"
                          (keyup)="updateServiceIP()"
                          formControlName="serviceIP"
                          autocomplete="off"
                        />
                      </div>
                    </mat-form-field>
                  </div>

                  <div
                    class="ml-3 d-flex flex-fill align-items-center w-100"
                    *ngIf="
                      appURLFormGroup?.get('serviceIPType')?.value ===
                      COPY_SERVICE_IP_ADDRESS_INPUT_METHOD_OPTION_KEYS.MANUAL
                    "
                  >
                    <ng-container
                      *ngIf="
                        (serviceIPValidateLoader$ | async)?.type ===
                        COPY_LOADER_CONFIG_TYPES.LOADER
                      "
                    >
                      <span class="clr-grey4"
                        ><img class="loader" [src]="loaderURL" alt="loader" />
                        {{ (serviceIPValidateLoader$ | async)?.message }}</span
                      >
                    </ng-container>

                    <ng-container
                      *ngIf="
                        (serviceIPValidateLoader$ | async)?.type ===
                        COPY_LOADER_CONFIG_TYPES.SUCCESS
                      "
                    >
                      <span class="status-active-clr">
                        <img class="verified-icon mr-1" [src]="verifiedURL" />
                        {{ (serviceIPValidateLoader$ | async)?.message }}
                      </span>
                    </ng-container>

                    <ng-container
                      *ngIf="
                        (serviceIPValidateLoader$ | async)?.type ===
                        COPY_LOADER_CONFIG_TYPES.ERROR
                      "
                    >
                      <span class="status-failed-clr">
                        <img class="verified-icon mr-1" [src]="failedURL" />
                        {{ (serviceIPValidateLoader$ | async)?.message }}
                      </span>
                    </ng-container>

                    <ng-container
                      *ngIf="!(serviceIPValidateLoader$ | async)?.status"
                    >
                      <button
                        [ngClass]="{
                          'clr-grey3': !serviceIPControl?.valid,
                          'color-primary-footer-back': serviceIPControl?.valid
                        }"
                        (click)="validateServiceIPAddress(domainIndex)"
                        [disabled]="!serviceIPControl?.valid"
                        type="button"
                        class="color-primary-footer-back addBtn position-relative cursor-pointer"
                      >
                        Validate
                      </button>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>

            <div
              *ngIf="
                appURLsArray?.controls?.length > 1 &&
                !FORM_FIELDS_TO_DISABLE_VALUES?.includes(
                  FORM_FIELDS_TO_DISABLE_KEYS.deleteDomain
                )
              "
            >
              <img
                class="delete-icon cursor-pointer my-auto"
                (click)="onDeleteDomain(domainIndex)"
                [src]="binUrl"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="my-4" *ngIf="multipleDomainsAllowed">
        <button
          (click)="onAddDomain()"
          type="button"
          class="color-primary-footer-back addBtn position-relative"
        >
          Add Domain
        </button>
      </div>
    </mat-expansion-panel>

    <!--SAML Rewrite Details-->
    <ng-container *ngIf="samlRewrite">
      <mat-expansion-panel
        [expanded]="true"
        class="form-panel mat-expansion-panel-spacing"
      >
        <mat-expansion-panel-header class="form-panel__header arrow">
          <mat-panel-title>Authentication</mat-panel-title>
        </mat-expansion-panel-header>
        <div [formGroup]="samlRewrite" class="mb-2">
          <mat-label class="d-flex form__label pb-2">
            Authentication Method
          </mat-label>
          <div class="w-50">
            <pcomp-mat-select-search
              [parentForm]="samlRewrite"
              [config]="selectSearchConfigForAuthType"
              [formControlNameInput]="selectedAuthType"
              (emitSelections)="onAuthMethodChange($event?.value)"
              controlName="selectedAuthType"
            >
            </pcomp-mat-select-search>
          </div>

          <div *ngIf="metadataType">
            <mat-radio-group
              formControlName="metadataType"
              (change)="onChangeMetadataType($event?.value)"
            >
              <span *ngFor="let option of COPY_METADATA_OPTIONS">
                <mat-radio-button
                  class="{{ option?.class }}"
                  [value]="option?.value"
                  color="primary"
                  >{{ option?.displayName }}</mat-radio-button
                >
              </span>
            </mat-radio-group>
            <div>
              <mat-label class="d-flex form__label mt-2"
                >{{ metadataControlLabel }}
              </mat-label>
              <mat-form-field appearance="outline" class="mt-3 w-100">
                <div
                  *ngIf="
                    metadataType?.value === COPY_METADATA_OPTIONS_KEYS.METADATA;
                    then metaDataBlock;
                    else metaDataURLBlock
                  "
                ></div>
                <ng-template #metaDataBlock>
                  <textarea
                    matInput
                    rows="8"
                    [matTooltipClass]="tooltipThemeClass"
                    [matTooltipPosition]="'above'"
                    formControlName="metadataControl"
                    placeholder="{{ metadataControlLabel }}"
                    autocomplete="off"
                  >
                  </textarea>
                </ng-template>
                <ng-template #metaDataURLBlock>
                  <div class="d-flex">
                    <input
                      matInput
                      placeholder="{{ metadataControlLabel }}"
                      formControlName="metadataControl"
                      autocomplete="off"
                    />
                  </div>
                </ng-template>
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</div>
