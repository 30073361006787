import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[positiveNumberOnly]',
})
export class PositiveNumberDirective {
  constructor() {}
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (
      !(
        (event.keyCode > 95 && event.keyCode < 106) ||
        (event.keyCode > 47 && event.keyCode < 58) ||
        event.keyCode === 8
      )
    ) {
      return false;
    }
  }
}
