import { CONSTANTS } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceService } from '../util/resource.service';
import { CommonModelSerializer } from './common.model.serializer';
import { EMPTY, Observable } from 'rxjs';
import { QueryModel } from '../util/models';
import { PAGE_SIZE } from '../util/constants';
import { expand, reduce } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CommonService extends ResourceService<any> {
  constructor(protected http: HttpClient) {
    super(http, CONSTANTS.DASHBOARD_HOST, '', new CommonModelSerializer());
  }

  getData(url: string) {
    this.path = url;
    return this.read('');
  }

  updateData(url: string, updatedItem: any) {
    this.path = url;
    return this.update(updatedItem);
  }

  createData(url: string, newItem: any) {
    this.path = url;
    return this.create(newItem);
  }

  deleteData(url: string, id: number | string) {
    this.path = url;
    return this.delete(id);
  }

  paginatedResponse<T>(path: string, payload: (startCount: number) => any): Observable<Array<T>> {
		this.path = path;
		let start = 0;
		return this.create(payload(start)).pipe(
		  expand((response: QueryModel<T>) => {
			start += PAGE_SIZE;
			if(start < response.total) {
			  return this.create(payload(start));
			} else {
			  return EMPTY;
			}
		  }),
		  reduce((acc: Array<T>, val: QueryModel<T>) => {
			if(val?.records?.length) {
			  return acc.concat(val.records);
			}
		  }, [] as Array<T>)
		)
	}
}
