import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { CLOUD_TYPES, IMAGE_PATHS } from '@app/common/util/constants';
import {
  DefaultSelectedMenuOption,
  NestedMenuOptions,
} from './nested-dropdown-menu.model';

@Component({
  selector: 'app-nested-dropdown-menu',
  templateUrl: './nested-dropdown-menu.component.html',
  styleUrls: ['./nested-dropdown-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NestedDropdownMenuComponent implements OnInit, OnChanges {
  @Input() contextMenuValues;
  @Input() defaultSelectedMenuOption: DefaultSelectedMenuOption;
  @Input() isShowSelectedvalueOnly = false;
  @Input() emitAfterInit = false;
  @Output() valueChange = new EventEmitter();
  @Input() isRequired: boolean = false;
  CLOUD_TYPES_CONST = CLOUD_TYPES;
  selectedOptionImageURL = '';
  imageClass = {
    AWS: 'cloud_aws',
    AZURE: 'cloud_azure',
    GCP: 'cloud_gcp',
    PRIVATE: 'cloud_private',
  };
  selectedMenuOptionImageClass = '';
  menuForm: UntypedFormGroup;
  itemImageURLMap = {
    AWS: IMAGE_PATHS.ICON.AWS,
    AZURE: IMAGE_PATHS.ICON.AZURE,
    GCP: IMAGE_PATHS.ICON.GCP,
    PRIVATE: IMAGE_PATHS.COMMON.CSP_PRIVATE,
  };

  constructor(protected fb: UntypedFormBuilder) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.initData();
    if (
      this.emitAfterInit &&
      changes.defaultSelectedMenuOption?.isFirstChange()
    ) {
      this.valueChange.emit(this.defaultSelectedMenuOption);
    }
    if (this.isRequired) {
      this.menuForm
        .get('selectedMenuOption')
        .setValidators(Validators.required);
    } else {
      this.menuForm
        .get('selectedMenuOption')
        .removeValidators(Validators.required);
    }
  }

  initData() {
    const cloudType = this.getCloudIcon();
    this.selectedOptionImageURL =
      this.itemImageURLMap[
        this.defaultSelectedMenuOption?.cloudType || cloudType
      ] || '';
    this.selectedMenuOptionImageClass = this.imageClass[cloudType];
    this.initmenuForm();
  }

  initmenuForm() {
    this.menuForm = this.fb.group({
      selectedMenuOption: this.defaultSelectedMenuOption.cloudName || '',
    });
  }
  getImageClass(item) {
    return {
      cloud_aws: item.name === this.CLOUD_TYPES_CONST.AWS,
      cloud_azure: item.name === this.CLOUD_TYPES_CONST.AZURE,
      cloud_gcp: item.name === this.CLOUD_TYPES_CONST.GCP,
    };
  }

  selectedMenuOption(item: NestedMenuOptions) {
    this.selectedOptionImageURL = item.imageURL;
    this.menuForm.get('selectedMenuOption').setValue(item.name);
    this.valueChange.emit(item);
  }

  getCloudIcon() {
    let clouds = [];
    this.contextMenuValues?.forEach(
      (menu) => (clouds = [...clouds, ...(menu?.options || [])])
    );
    const menu = clouds?.find(
      (cloud) => cloud.cloudId === this.defaultSelectedMenuOption?.cloudId
    );
    return menu?.cloudType;
  }

  get selectedMenuOptionValue() {
    return this.menuForm.get('selectedMenuOption')?.value;
  }
}
