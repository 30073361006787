<div
  *ngIf="!closed"
  id="pill__container"
  (mouseleave)="hideTooltip()"
  class="col pl-0 pr-2 my-1"
>
  <div
    class="row no-gutters align-items-center flex-nowrap pill"
    [ngStyle]="{
      'border-color': borderColor,
      'background-color': backgroundColor,
      width: pillWidth
    }"
    [matTooltip]="getTooltip"
    matTooltipClass="no-truncation-tooltip"
    matTooltipPosition="below"
  >
    <div
      *ngIf="closeable && !closePositionAfter"
      class="pill__close col-0 close-before d-flex align-items-center justify-content-center"
      (click)="onClose()"
    >
      <mat-icon class="icon__close">close</mat-icon>
    </div>
    <div
      (mouseover)="showTooltip($event)"
      class="pill__txt col"
      [ngStyle]="{ color: textColor, 'font-size': fontSize }"
    >
      {{ value }}
    </div>
    <div
      *ngIf="closeable && closePositionAfter"
      class="pill__close col-sm-2 close-after d-flex align-items-center justify-content-center"
      (click)="onClose()"
    >
      <mat-icon class="icon__close">close</mat-icon>
    </div>
  </div>
</div>
