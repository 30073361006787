import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CommonService } from '@app/common/http/service/common.service';
import { IMAGE_PATHS } from '@app/common/util/constants';
import {
  RequestStatus,
  TicketSubmitModel,
  TicketSupportModel,
} from '@app/component-common/feedback-form/feedback-form.model';
import { CONSTANTS } from 'environments/environment';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { DISPLAY_STATUS, HelpModel, HREF_TYPES, Topics } from './help.model';
import { HelpService } from './help.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class HelpComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('helpContainer', { static: true }) helpContainer: ElementRef;
  @ViewChild('helpContents', { static: true }) helpContents: ElementRef;
  displayContent: string;
  displayStatus: DISPLAY_STATUS = DISPLAY_STATUS.CLOSE;
  isPanelOpen: boolean = false;
  feedbackFormConfig;
  displayTitle: string;
  searchTerm = new UntypedFormControl('');
  liElements: any = [];
  anchorElements: any = [];
  fontSizes = [
    {
      size: '14px',
      isSelected: false,
    },
    {
      size: '14px',
      isSelected: false,
    },
  ];
  subscriptions = [];
  @Input() isShowContactSupportBtn: boolean = true;
  constructor(
    private helpService: HelpService,
    private commonService: CommonService,
    private cdr: ChangeDetectorRef
  ) {
    this.subscriptions.push(
      this.helpService.getHelpConfig().subscribe(
        (helpModel: HelpModel) => {
          this.displayStatus = helpModel
            ? helpModel.displayStatus
            : DISPLAY_STATUS.CLOSE;
          this.setPanelDisplayStatus();
          this.displayContent = helpModel ? helpModel.displayContent : '';
          this.displayTitle = helpModel ? helpModel.displayTitle : '';
          if (this.displayStatus === DISPLAY_STATUS.CLOSE) {
            this.removeHelpContainerStyleAttribute();
            this.closeZendeskForm();
          }
          this.cdr.markForCheck();
        },
        (error) => {
          console.log('error.....');
        }
      ),
      this.searchTerm.valueChanges
        .pipe(
          filter((searchTerm) => !!searchTerm),
          debounceTime(100),
          distinctUntilChanged()
        )
        .subscribe((data) => {
          this.helpService.setSearchTerm(data);
        })
    );
    this.initZendeskSupport();
  }

  hideHelpSection() {
    this.removeHelpContainerStyleAttribute();
    this.searchTerm.patchValue('');
    this.helpService.setHelpConfig({
      displayStatus: DISPLAY_STATUS.CLOSE,
      displayContent: '',
    } as HelpModel);
  }

  updateFontSize(selectedFont: any) {
    this.helpContents.nativeElement.style.fontSize =
      selectedFont && selectedFont.size ? selectedFont.size : '12px';
    this.fontSizes = [
      ...this.fontSizes.map((font) => {
        return font.size === selectedFont.size
          ? Object.assign({}, font, { isSelected: true })
          : Object.assign({}, font, { isSelected: false });
      }),
    ];
    this.cdr.markForCheck();
  }

  ngOnInit() {}

  /**
   * initialized the zendesk support ticket form
   */
  initZendeskSupport(displayStatus = false) {
    this.feedbackFormConfig = {
      topics: [...Topics],
      displayForm: displayStatus,
      iconURL: IMAGE_PATHS.COMMON.HELP_ADMIN,
    } as TicketSupportModel;
  }

  /**
   * Close the zendesk support ticket form
   */
  closeZendeskForm() {
    this.initZendeskSupport();
  }

  ngAfterViewChecked() {
    if (this.displayContent) {
      this.clearEventListeners();
      this.setAnchorBehavior();
      this.setListBehavior();
    }
  }

  /**
   * Checks for <a> elements. If there is a target and of type
   * self :
   *  - add the attributes
   *  - add an event listener to scroll to the intended section
   *  - disable default href behavior
   * none :
   *   - add type as blank to open the content in a new tab or window
   */
  setAnchorBehavior() {
    this.anchorElements = [];
    this.anchorElements = Array.from(document.getElementsByTagName('a')) || [];
    if (this.anchorElements && this.anchorElements.length) {
      this.anchorElements.map((element: any) => {
        if (element.target && element.target === HREF_TYPES.SELF) {
          const matchedArticle = element.href.match(/articles\/+\d+$/) || [];
          const splitArctileId =
            matchedArticle.length === 1 ? matchedArticle[0]?.split('/') : [];
          const articleId =
            splitArctileId.length === 2 ? splitArctileId[1] : '';

          if (articleId) {
            const parentNode = element?.parentNode;
            const newElem = document?.createElement('span');

            newElem?.setAttribute(
              'class',
              'd-inline-flex m-2 articles-link cursor-pointer'
            );
            parentNode?.setAttribute('data-id', articleId);
            newElem?.addEventListener(
              'click',
              this.onListElementClick.bind(this)
            );
            newElem.textContent = element?.textContent;
            parentNode?.appendChild(newElem); // Append new child to parent
            parentNode?.removeChild(element); // Remove inline anchor element as features added on span tag
          } else {
            const url = element.hash.replace('#', '');
            element.setAttribute('fragment', `${url}`);
            element.setAttribute('ng-reflect-fragment', `${url}`);
            element.setAttribute('ng-reflect-router-link', `./`);
            element.addEventListener(
              'click',
              this.onAnchorElementClick.bind(this)
            );
          }
        }
        if (!element.target) {
          element.setAttribute('target', HREF_TYPES.BLANK);
        }
      });
    }
  }

  /**
   * Checks for <li> elements
   * Filters only li elements with data-id
   * adds a click event
   */
  setListBehavior() {
    this.liElements = [];
    this.liElements = Array.from(document.getElementsByTagName('li')) || [];
    this.liElements = this.liElements.filter(
      (liElem: any) => liElem.dataset.id
    );
    if (this.liElements) {
      this.liElements.forEach((element) => {
        element.addEventListener('click', this.onListElementClick.bind(this));
      });
    }
  }

  /**
   * Using the router configuration didn't help. Going with the vanilla script to prevent the
   * default behavior of <a> element.
   *
   *   Router Config: { useHash: true,
   *     scrollPositionRestoration: 'enabled',
   *     anchorScrolling: 'enabled',}
   * @param event
   */

  onAnchorElementClick(event: any) {
    const elem = document.getElementById(
      event.srcElement.getAttribute('fragment')
    );
    if (elem) {
      elem.scrollIntoView();
      event.preventDefault();
    }
  }

  onListElementClick(event: any) {
    this.helpService.setArticleID(event.srcElement.parentElement.dataset.id);
  }

  /**
   * clears manually added event listeners
   */
  clearEventListeners() {
    if (this.liElements.length) {
      this.liElements.map((liElement: any) => {
        liElement.removeAllListeners('click');
      });
      this.liElements = [];
    }
    if (this.anchorElements.length) {
      this.anchorElements.map((anchorElement: any) => {
        anchorElement.removeAllListeners('click');
      });
      this.anchorElements = [];
    }
  }

  onSubmit(dataModel: TicketSubmitModel) {
    const sub = this.commonService
      .createData(CONSTANTS.HELP.POST_TICKET, dataModel)
      .subscribe(
        (data) => {
          this.updateZendeskFormMsg(
            RequestStatus.SUCCESS,
            `Successfully submitted!`
          );
        },
        (error) => {
          this.updateZendeskFormMsg(
            RequestStatus.ERROR,
            `Failed to submit the ticket!`
          );
        }
      );
    this.subscriptions.push(sub);
  }

  updateZendeskFormMsg(requestStatus, responseMsg, displayForm = true) {
    this.feedbackFormConfig = Object.assign({}, this.feedbackFormConfig, {
      requestStatus,
      responseMsg,
      displayForm,
    });
    this.cdr.markForCheck();
  }

  /**
   * resizable directive output listener.
   * sets the help container's width
   *
   * @param dimensions
   */
  updateHelpPanelWidth(dimensions: any) {
    if (dimensions?.width < 525 || dimensions?.width > 1000) {
      return;
    }
    this.helpContainer.nativeElement.style.width = `${dimensions?.width}px`;
  }

  removeHelpContainerStyleAttribute() {
    this.helpContainer.nativeElement.removeAttribute('style');
  }

  setPanelDisplayStatus() {
    if (this.displayStatus?.toUpperCase() === DISPLAY_STATUS.CLOSE) {
      this.isPanelOpen = false;
    } else {
      this.isPanelOpen = true;
    }
  }

  ngOnDestroy() {
    this.clearEventListeners();
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }
}
