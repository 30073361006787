<ng-container>
  <div class="d-flex justify-content-between align-items-center panel__footer">
    <div
      *ngIf="isAction"
      class="d-flex justify-content-end align-items-center col-7"
    >
    <div
      *ngIf="isRevoke"
      class="panel__actionBtn panel__actionBtn--delete"
      [ngClass]="{'trial-disable-txt': isReadOnly }"
      (click)="onClick(FOOTER_ACTIONS.REVOKE)"
    >
      REVOKE
    </div>
      <div
        *ngIf="isDeleteAll"
        class="panel__actionBtn panel__actionBtn--delete"
        [ngClass]="{ 'trial-disable-txt': isReadOnly }"
        (click)="onClick(FOOTER_ACTIONS.DELETE_ALL)"
      >
        DELETE ALL
      </div>
      <div
        *ngIf="!isUndo"
        class="panel__actionBtn"
        [ngClass]="{ 'trial-disable-txt': isReadOnly || cancelDisabled }"
        (click)="onClick(FOOTER_ACTIONS.CANCEL)"
      >
        CANCEL
      </div>
      <div
        *ngIf="!isUndo && isSave"
        class="panel__actionBtn panel__actionBtn--save"
        [ngClass]="{ 'trial-disable-txt': isReadOnly || saveDisabled }"
        (click)="onClick(FOOTER_ACTIONS.SAVE)"
      >
        SAVE
      </div>

      <div *ngIf="isUndo">
        <div
          class="panel__actionBtn"
          (click)="onClick(FOOTER_ACTIONS.UNDO)"
          [ngClass]="{ 'trial-disable-txt': isReadOnly || undoDisabled }"
        >
          UNDO
        </div>
      </div>
    </div>
    <div
      *ngIf="!isAction"
      class="d-flex justify-content-end align-items-center col-7"
    ></div>
    <div
      *ngIf="!isUndo && isShowCreatedDate"
      class="d-flex justify-content-end align-items-center px-0"
    >
      <div class="createdBy">
        {{ created?.label }}:
        {{
          created?.date
            | date
              : created?.dateFormat || DATE_FORMAT.FORMAT_MONTH_DOT_DAY_DOT_YEAR
        }}
      </div>
    </div>
  </div>
</ng-container>
