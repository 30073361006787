import { Page } from '@app/common/components/paginator/paginator.model';
import {
  ACCESS_BASED_POLICY_ACTIONS,
  COLORS,
  IMAGE_PATHS,
} from '@app/common/util/constants';
import { DateUtil } from '@prosimoio/services';
import { ObjectUtil } from '@prosimoio/services';
import { POLICY_TYPE } from '@app/common/util/policy-constants';
import { CONSTANTS } from 'environments/environment';
import { SunburstChartType } from './insights.model';
import { AppUtil } from '@app/common/util/app-util';

export const SUB_MENU_CODES = {
  TTFB: 'TTFB',
  DATA_USAGE: 'DATA_USAGE',
  LATENCY: 'LATENCY',
  TOTAL_RESPONSE_TIME: 'TERT',
  WAF_RULE: 'RULE',
  WAF_SOURCE: 'SOURCE',
  WAF_APP: 'APP',
  WAF_PATH: 'PATH',
  DEVICE: 'DEVICE',
  NETWORK: 'NETWORK',
  COUNTRY: 'COUNTRY',
  MAIN_DASHBOARD: 'MAIN_DASHBOARD',
  CACHE_OVERVIEW: 'CACHE_OVERVIEW',
  CACHE_ASSET_DETAILS: 'CACHE_ASSET_DETAILS',
  PACKET_LOSS: 'PACKET_LOSS',
  MCN_SUMMARY: 'MCN_SUMMARY',
  TRAFFIC_FLOW: 'TRAFFIC_FLOW',
  REGION: 'REGION',
  SESSIONS: 'SESSIONS',
};

export const STAT_METRIC_NAMES = {
  TTFB_HOURLY: 'TTFB_HOURLY',
  TOTAL_RESPONSE_TIME_HOURLY: 'TERT_HOURLY',
  SUMMARY: 'SUMMARY',
  CACHE: 'CACHE',
  DATA: 'DATA',
  LATENCY: 'LATENCY',
  WAF_RULE: 'WAF_RULE',
  WAF_SOURCE: 'WAF_SOURCE',
  WAF_APP: 'WAF_APP',
  WAF_RULE_LOG: 'WAF_RULE_LOG',
  WAF_SOURCE_LOG: 'WAF_SOURCE_LOG',
  WAF_APP_LOG: 'WAF_APP_LOG',
  DIAGNOSE_TROUBLESHOOT_LOG: 'TS_LOG',
  DIAGNOSE_DETAIL_TROUBLESHOOT_LOG: 'TS_EVENTS',
  DIAGNOSE_ACCESSIBILITY_LOG: 'HEALTH_LOGS',
  STATIC_RESOURCE_DIST: 'CACHE_CONTENT',
  UNCACHEABLE_REASONS_STAT: 'CACHE_MISS_STATS',
  EDGE_HEALTH_STATUS: 'FABRIC_STATS',
  EDGE_HEALTH_TIMELINE: 'HEALTH_TIMELINE',
  EDGE_HEALTH_SEGMENT_LOG: 'MIDMILE_LOGS',
  EVENT_SEVERITY: 'SEVERITY',
  EVENT_TYPE: 'TYPE',
  EVENT_AGGREGATE: 'AGGREGATE',
  PACKET_LOSS: 'LOSS',
  NW_PROTO_SUMMARY: 'NW_PROTO_SUMMARY',
  SRC_NETWORK_STATS: 'SRC_NETWORK_STATS',
  SRC_SENT_STATS: 'SRC_SENT_STATS',
  TGT_RCVD_STATS: 'TGT_RCVD_STATS',
  SRC_DATA_STATS: 'SRC_DATA_STATS',
  SRC_REG_SENT_STATS: 'SRC_REG_SENT_STATS',
  TGT_REG_RCVD_STATS: 'TGT_REG_RCVD_STATS',
  SRC_REG_DATA_STATS: 'SRC_REG_DATA_STATS',
  SRC_SESS_STATS: 'SRC_SESS_STATS',
};

export const INTRA_INTER_LEAP_TYPES = {
  WAF_RULE_TO_EVENTS: 'WAF_RULE_TO_EVENTS',
  EVENT_TO_TROUBLESHOOT_WITH_TRANSACTION_ID:
    'EVENT_TO_TROUBLESHOOT_WITH_TRANSACTION_ID',
};

export interface ClickableColumnConfig {
  name: string;
  eligibleValues?: string[];
  icon?: {
    path: string;
    tooltip: string;
    isMatIcon?: boolean;
    matIcontype: string;
  };
  route?: {
    path: string;
    isUpdatePath?: boolean;
    pathValueProps?: string[];
  };
}

export interface LogViewDetails {
  healthStatusViewType?: string;
  filterLogResults?: boolean;
  viewName: string;
  dataPath: string;
  metric: string;
  showDetails: boolean;
  showLatencyChart: boolean;
  showAnalyticsChart: boolean;
  analyticsChartType?: string;
  showMisc: boolean;
  showTroubleshoot?: boolean;
  showTableFilter?: boolean;
  showSegmentHealthStat?: boolean;
  selectedEdgeType?: string;
  detailMetric?: string;
  fetchDetail?: boolean;
  filter?: any;
  applyNoFilters?: boolean;
  listenForDateRangeFilterChange?: boolean;
  additionalFilters?: any;
  exactFilters?: any;
  ignoredLogTableColumnKeys?: Array<string>;
  clickableColumnDetails?: Array<ClickableColumnConfig>;
  clearFilters?: Array<string>;
  customPayload?: any;
  hidePaginator?: boolean;
}

export const LOG_VIEW_NAMES = {
  DEVICE_SUMMARY: 'deviceSummary',
  APPS_ACCESSED: 'appsAccessed',
  APP_EXPERIENCE: 'appExperienceMultiFactor',
  USER_RISK: 'userRisk',
  EVENTS: 'defaultEventLogView',
  DEFAULT_EVENT_LOG_VIEW: 'defaultEventLogView',
  DEFAULT_ACCESS_LOG_VIEW: 'defaultAccessLogView',
  WAF_APP_LOG_VIEW: 'wafApp',
  WAF_RULE_LOG_VIEW: 'wafRule',
  WAF_SOURCE_LOG_VIEW: 'wafSource',
  DIAGNOSE_TROUBLESHOOTING: 'troubleshootingView',
  DIAGNOSE_TROUBLESHOOTING_DETAILS: 'troubleshootingDetailsView',
  DIAGNOSE_ACCESSIBILITY: 'appHealth',
  DIAGNOSE_ACCESSIBILITY_SEGMENT: 'midmile',
  DIAGNOSE_TROUBLESHOOTING_TRANSACTION_ID:
    'troubleshootingTransactionIdBasedView',
  MCN_SUMMARY: 'mcnSummary',
  MCN_SUMMARY_SANKEY: 'mcnSummarySankey',
  MCN_EVENTS: 'mcnEventLog',
  MCN_TROUBLESHOOTING: 'mcnTroubleshooting',
};

export const INSIGHTS_CONSTANTS = {
  WAF_RULE_MODE: {
    DETECT: 'DETECT',
    ENFORCE: 'ENFORCE',
    BOTH: 'BOTH',
  },
};

const UX_SCORE_COLUMN_DYNAMIC_CONFIG = {
  dynamicColumns: ['uxscore', 'app'],
  getDynamicTemplate: (column: string, data: any, logViewConfig: any) => {
    switch (column.toUpperCase()) {
      case 'UXSCORE':
        return `
               <span>${data}</span>
               <span style="padding:0 0 0 8px;display:inline-block;">
                  <img style="width: 12px;  height: 12px; margin-top:-2px"
                        src=${IMAGE_PATHS.COMMON.WARNING}
                        title="UX score below threshold, expand to see more details"/>
               </span>
            `;
      case 'APP':
        if (data?.isAgent) {
          return `
                  <span style="padding:0 8px 0 0;display:inline-block;">
                     <img style="width: 16px;  height: 16px; margin-top:-2px"
                           src=${IMAGE_PATHS.ICON.AGENT_GREY}
                           title="Agent App"/>
                  </span>
                  <span>${data[column] || data}</span>
               `;
        } else {
          return `
                  <span>${data[column] || data}</span>
               `;
        }
    }
  },
};

const USER_RISK_SCORE_COLUMN_DYNAMIC_CONFIG = {
  dynamicColumns: ['riskscore'],
  getDynamicTemplate: (score: any, logViewConfig: any) => {
    return `
          <span>${score}</span>
          <span style="padding:0 0 0 8px;display:inline-block;">
             <img style="width: 12px;  height: 12px; margin-top:-2px"
                   src=${IMAGE_PATHS.COMMON.WARNING}
                   title="User risk score above threshold, expand to see more details"/>
          </span>
      `;
  },
};

export const ANALYTICS_LOG_STAT_TYPE = {
  RISK: 'RISK',
  LATENCY: 'LATENCY',
  ROOT_CAUSES: 'ROOT_CAUSES',
  FACTORS: 'FACTORS',
  BYTE_BUCKETS: 'BYTE_BUCKETS',
  UX_TREND_VIEW: 'TREND_VIEW',
  USER_RISK_TREND_VIEW: 'USER_RISK_TREND_VIEW',
  USER_RISK_FACTORS: 'USER_RISK_FACTORS',
  APP_EXPERIANCE_BYTE_BUCKET: 'APP_EXPERIANCE_BYTE_BUCKET',
  APP_EXPERIANCE_CONTRIBUTING_FACTORS: 'APP_EXPERIANCE_CONTRIBUTING_FACTORS',
};

export const EDGE_AND_APP_TYPES = {
  APP_ACTIVE: 'ACTIVE',
  APP_BACKUP: 'BACKUP',
  EDGE_PRIMARY: 'PRIMARY',
  EDGE_SECONDARY: 'SECONDARY',
};
export const LOG_VIEW_NAMES_CONFIG = {
  SUMMARY: Object.assign(
    {},
    {
      viewName: LOG_VIEW_NAMES.DEVICE_SUMMARY,
      metric: null,
      dataPath: CONSTANTS.INSIGHTS.GET_SUMMARY_LOGS,
      showLatencyChart: true,
      showAnalyticsChart: false,
      showDetails: true,
      showMisc: true,
      showTableFilter: true,
      listenForDateRangeFilterChange: true,
    },
    UX_SCORE_COLUMN_DYNAMIC_CONFIG
  ),
  SUMMARY_SANKEY: Object.assign(
    {},
    {
      viewName: LOG_VIEW_NAMES.MCN_SUMMARY_SANKEY,
      metric: STAT_METRIC_NAMES.SRC_NETWORK_STATS,
      dataPath: CONSTANTS.INSIGHTS.SUMMARY_STAT,
      showLatencyChart: false,
      showAnalyticsChart: false,
      showDetails: false,
      showMisc: false,
      showTableFilter: false,
      listenForDateRangeFilterChange: true,
      applyNoFilters: false,
      hidePaginator: true,
    },
    UX_SCORE_COLUMN_DYNAMIC_CONFIG
  ),
  APPS_ACCESSED: Object.assign(
    {},
    {
      viewName: LOG_VIEW_NAMES.APPS_ACCESSED,
      metric: null,
      dataPath: CONSTANTS.INSIGHTS.GET_SUMMARY_LOGS,
      showLatencyChart: true,
      showAnalyticsChart: false,
      showDetails: true,
      showMisc: true,
      showTableFilter: true,
      listenForDateRangeFilterChange: false,
    },
    UX_SCORE_COLUMN_DYNAMIC_CONFIG
  ),
  APP_EXPERIENCE: {
    viewName: LOG_VIEW_NAMES.APP_EXPERIENCE,
    metric: null,
    dataPath: CONSTANTS.INSIGHTS.GET_USER_EXPERIENCE_LOG,
    showLatencyChart: false,
    showAnalyticsChart: true,
    showDetails: true,
    showMisc: false,
    showTableFilter: true,
    listenForDateRangeFilterChange: false,
    analyticsChartType: ANALYTICS_LOG_STAT_TYPE.FACTORS,
  },
  USER_RISK: Object.assign(
    {},
    {
      viewName: LOG_VIEW_NAMES.USER_RISK,
      metric: null,
      dataPath: CONSTANTS.INSIGHTS.GET_USER_RISK_LOG,
      showLatencyChart: false,
      showAnalyticsChart: true,
      showDetails: true,
      showMisc: false,
      showTableFilter: true,
      listenForDateRangeFilterChange: false,
    },
    USER_RISK_SCORE_COLUMN_DYNAMIC_CONFIG
  ),
  EVENTS: {
    viewName: LOG_VIEW_NAMES.DEFAULT_EVENT_LOG_VIEW,
    metric: null,
    dataPath: CONSTANTS.INSIGHTS.GET_EVENT_LOGS,
    showLatencyChart: false,
    showAnalyticsChart: false,
    showDetails: false,
    showMisc: false,
    showTableFilter: true,
    listenForDateRangeFilterChange: false,
    clickableColumnDetails: [
      {
        name: 'tag',
        eligibleValues: ['ip-blocklist'],
        icon: {
          path: 'policy',
          tooltip: 'Click to allowlist the IP',
          isMatIcon: true,
          matIcontype: 'outlined',
        },
        route: {
          path: CONSTANTS.SECURITY.UI_PATH_IP_ALLOWLIST,
          isUpdatePath: true,
          pathValueProps: ['ip'],
        },
      } as ClickableColumnConfig,
    ],
    dynamicColumns: ['logMsg', 'tag'],
    getDynamicTemplate: (column: string, data: any, logViewConfig: any) => {
      const routeParams = logViewConfig?.queryParams?.routeParams;
      let config = logViewConfig?.queryParams;

      if (column === 'tag') {
        if (data[column] === POLICY_TYPE.ALLOW_ALL) {
          return ACCESS_BASED_POLICY_ACTIONS.ALLOW_ALL;
        } else {
          return data[column];
        }
      }
      if (routeParams) {
        config = Object.assign(routeParams, config);
        delete config['routeParams'];
      }

      const contentArr = data?.[column]?.split(':') || [];
      const transactionId = contentArr?.pop()?.trim() || '';
      const transactionText = contentArr.join(':');
      const logID = data['logID'] || '';
      /* 
          a minute is deducted from from-time to avoid no record show when user navigates
          from events to troubleshoot.

          the waf logs displayed in the events and troubleshoot have two different sources
          and the timestamp on the WAF logs differ by a fraction of seconds.
          */

      config = Object.assign({}, config, {
        bannerLabel: data[column],
        fatime: data?.time - 60000,
        latime: DateUtil.getUpdatedDateByHour(data?.time, 1),
        user: data?.user || '',
        hopType:
          INTRA_INTER_LEAP_TYPES.EVENT_TO_TROUBLESHOOT_WITH_TRANSACTION_ID,
      });
      const path = `${config?.routePath}/tid/${logID}/${ObjectUtil.encodeObject(
        config
      )}`;

      let template;

      if (data?.isInternal) {
        template = `<div>${transactionText}: ${transactionId}</div>`;
      } else {
        template = `<div>${transactionText}: <a href="${path}" target="_blank" style="color: #f26d4f" class="ig__learn_more_transaction"> ${transactionId}</a></div>`;
      }

      return template;
    },
  },
  EVENTS_AGGREGATE: {
    viewName: LOG_VIEW_NAMES.DEFAULT_EVENT_LOG_VIEW,
    metric: null,
    dataPath: CONSTANTS.INSIGHTS.GET_EVENT_LOGS,
    showLatencyChart: false,
    showAnalyticsChart: false,
    showDetails: false,
    showMisc: false,
    showTableFilter: true,
    listenForDateRangeFilterChange: true,
    clickableColumnDetails: [
      {
        name: 'tag',
        eligibleValues: ['ip-blocklist'],
        icon: {
          path: 'policy',
          tooltip: 'Click to allowlist the IP',
          isMatIcon: true,
          matIcontype: 'outlined',
        },
        route: {
          path: CONSTANTS.SECURITY.UI_PATH_IP_ALLOWLIST,
          isUpdatePath: true,
          pathValueProps: ['ip'],
        },
      } as ClickableColumnConfig,
    ],
  },
  WAF_RULE: {
    viewName: LOG_VIEW_NAMES.WAF_RULE_LOG_VIEW,
    metric: STAT_METRIC_NAMES.WAF_RULE_LOG,
    dataPath: CONSTANTS.INSIGHTS.WAF_STAT,
    showLatencyChart: false,
    showAnalyticsChart: false,
    showDetails: false,
    showMisc: false,
    showTableFilter: true,
    listenForDateRangeFilterChange: false,
    ignoredLogTableColumnKeys: ['wafMode'],
    dynamicColumns: ['wafRuleID'],
    getDynamicTemplate: (column: string, data: any, logViewConfig: any) => {
      const routeParams = logViewConfig?.queryParams?.routeParams;
      let config = logViewConfig?.queryParams;

      if (routeParams) {
        config = Object.assign(routeParams, config);
        delete config['routeParams'];
      }

      config = Object.assign({}, config, {
        wafRuleId: data[column],
        bannerLabel: data[column],
        hopType: INTRA_INTER_LEAP_TYPES.WAF_RULE_TO_EVENTS,
      });
      const path = `${config?.routePath}/${ObjectUtil.encodeObject(config)}`;
      return `<a href="${path}" target="_blank" style="color: #f26d4f" class="ig__learn_more_rule_id"> ${data[column]}</a>`;
    },
    clickableColumnDetails: [
      {
        name: 'wafRuleSet',
        icon: {
          path: 'policy',
          tooltip:
            'Click to allowlist the WAF ruleset for the selected apps in the filter',
          isMatIcon: true,
          matIcontype: 'outlined',
        },
        route: {
          path: CONSTANTS.SECURITY.UI_PATH_WAF_ALLOWLIST('allowlist'),
        },
      } as ClickableColumnConfig,
    ],
  } as LogViewDetails,
  WAF_APP: {
    viewName: LOG_VIEW_NAMES.WAF_APP_LOG_VIEW,
    metric: STAT_METRIC_NAMES.WAF_APP_LOG,
    dataPath: CONSTANTS.INSIGHTS.WAF_STAT,
    showLatencyChart: false,
    showAnalyticsChart: false,
    showDetails: false,
    showMisc: false,
    showTableFilter: true,
    listenForDateRangeFilterChange: false,
  },
  WAF_SOURCE: {
    viewName: LOG_VIEW_NAMES.WAF_SOURCE_LOG_VIEW,
    metric: STAT_METRIC_NAMES.WAF_SOURCE_LOG,
    dataPath: CONSTANTS.INSIGHTS.WAF_STAT,
    showLatencyChart: false,
    showAnalyticsChart: false,
    showDetails: false,
    showMisc: false,
    showTableFilter: true,
    listenForDateRangeFilterChange: false,
  },
  DEFAULT_ACCESS_LOG_VIEW: {
    viewName: LOG_VIEW_NAMES.DEFAULT_ACCESS_LOG_VIEW,
    metric: null,
    dataPath: CONSTANTS.INSIGHTS.GET_ACCESS_LOGS,
    showLatencyChart: true,
    showAnalyticsChart: false,
    showDetails: true,
    showMisc: true,
    showTableFilter: true,
    ignoredLogTableColumnKeys: ['edge'],
  },
  DEFAULT_EVENT_LOG_VIEW: {
    viewName: LOG_VIEW_NAMES.DEFAULT_EVENT_LOG_VIEW,
    metric: null,
    dataPath: CONSTANTS.INSIGHTS.GET_EVENT_LOGS,
    showLatencyChart: false,
    showAnalyticsChart: false,
    showDetails: true,
    showMisc: true,
    showTableFilter: true,
  },
  DIAGNOSE_TROUBLESHOOTING_VIEW: {
    viewName: LOG_VIEW_NAMES.DIAGNOSE_TROUBLESHOOTING,
    metric: STAT_METRIC_NAMES.DIAGNOSE_TROUBLESHOOT_LOG,
    dataPath: CONSTANTS.DIAGNOSE.GET_TROUBLESHOOT_LOG,
    showLatencyChart: false,
    showAnalyticChart: false,
    showDetails: true,
    showMisc: false,
    showTroubleshoot: true,
    showTableFilter: true,
    fetchDetail: true,
    detailMetric: STAT_METRIC_NAMES.DIAGNOSE_DETAIL_TROUBLESHOOT_LOG,
    listenForDateRangeFilterChange: true,
    hideTooltipColumns: ['blocked'], // hide tooltip
    dynamicColumns: ['status', 'blocked'],
    getDynamicTemplate: (column: string, data: any, logViewConfig: any) => {
      const DIAGNOSE_IMAGES_URL = IMAGE_PATHS.DASHBOARD.DIAGNOSE;
      const REDIRECT = 'redirect';
      const SUCCESS = 'success';
      const INIT = 'init';
      if (column === 'status') {
        const edgeStatus = data[column];
        const appStatus = data.upstreamStatus;
        const appStatusCode = data.upstreamStatusCode;
        let edgeImgURL = DIAGNOSE_IMAGES_URL.EDGE_UP;
        if (edgeStatus && [INIT].includes(edgeStatus)) {
          edgeImgURL = DIAGNOSE_IMAGES_URL.EDGE_BLACK;
        } else if (edgeStatus && [SUCCESS, REDIRECT].includes(edgeStatus)) {
          edgeImgURL = DIAGNOSE_IMAGES_URL.EDGE_UP;
        } else {
          edgeImgURL = DIAGNOSE_IMAGES_URL.EDGE_DOWN;
        }

        let appImgURL = DIAGNOSE_IMAGES_URL.APP_UP;
        appImgURL =
          appStatus === 'error' ? DIAGNOSE_IMAGES_URL.APP_DOWN : appImgURL;
        appImgURL =
          !appStatus && !appStatusCode
            ? DIAGNOSE_IMAGES_URL.APP_UNREACHABLE
            : appImgURL;
        return `
            <img style="width: 24px;  height: 24px; margin-right: 8px" src="${edgeImgURL}" alt="edge status" >
            <img style="width: 24px;  height: 24px;" src="${appImgURL}" alt="upstream status" >
          `;
      } else if (column === 'blocked') {
        const name = data.blockedName ? `(${data.blockedName})` : '';
        const value = data[column] ? `true ${name}` : 'false';
        return `<span>${value}<span>`;
      } else if (column === 'app') {
        if (data?.isAgent) {
          return `
                  <span style="padding:0 8px 0 0;display:inline-block;">
                     <img style="width: 16px;  height: 16px; margin-top:-2px"
                           src=${IMAGE_PATHS.ICON.AGENT_GREY}
                           title="Agent App"/>
                  </span>
                  <span>${data[column] || data}</span>
               `;
        } else {
          return `
                  <span>${data[column] || data}</span>
               `;
        }
      }
    },
    tooltip: {
      header: {
        content: (data) => {
          const isLogN2N = AppUtil.isLogN2N(data);
          return `Response Status${isLogN2N ? '' : ' Code'}`;
        },
        color: COLORS.BLUE,
      },
      getTemplate: (data, key) => {
        const isLogN2N = AppUtil.isLogN2N(data);
        return `<ul>
            <li><span style="color: #404245; font-weight: 400">Edge status${
              isLogN2N ? '' : ' code'
            }: <span> ${
          (isLogN2N ? data.status : data.edgeStatusCode) || 'NA'
        }</li>
            <li><span style="color: #404245; font-weight: 400">App status${
              isLogN2N ? '' : ' code'
            }: <span> ${
          (isLogN2N ? data.status : data.upstreamStatusCode) || 'NA'
        }</li>
            </ul>
          `;
      },
    },
  },
  DIAGNOSE_ACCESSIBILITY_VIEW: {
    viewName: LOG_VIEW_NAMES.DIAGNOSE_ACCESSIBILITY,
    metric: STAT_METRIC_NAMES.DIAGNOSE_ACCESSIBILITY_LOG,
    dataPath: CONSTANTS.DIAGNOSE.GET_ACCESSIBILITY_LOG,
    showLatencyChart: false,
    showAnalyticChart: false,
    showDetails: false,
    showMisc: false,
    showTroubleshoot: false,
    showTableFilter: false,
    fetchDetail: false,
    detailMetric: '',
    enableHealthCheck: true,
    showEdgeHealthDetails: false,
    applyNoFilters: false,
    filterLogResults: true,
    healthStatusViewType: 'APP',
    showSegmentHealthStat: false,
    hidePaginator: true,
    dynamicColumns: [
      'edgeHealthStatus',
      'appHealthStatus',
      'badSegment',
      'edgeConnectivityStatus',
      'app',
      'edgeRegion',
    ],
    getDynamicTemplate: (column: string, data: any, logViewConfig: any) => {
      if (column === 'badSegment') {
        const badSegment = data[column];
        const cssClass = `status-icon ${
          badSegment ? 'status-icon-error' : 'status-icon-success'
        }`;
        return badSegment === null
          ? `<div>NA</div>`
          : `<div class="${cssClass}"></di>`;
      } else if (column === 'app') {
        if (
          data &&
          data?.edgeMode?.toUpperCase() === EDGE_AND_APP_TYPES.EDGE_PRIMARY &&
          data?.appMode?.toUpperCase() === EDGE_AND_APP_TYPES.APP_ACTIVE
        ) {
          return `
            <div class='d-flex justify-content-start align-items-start'>
              <span><strong>Active app:</strong> ${data[column]}<span>
            </div>`;
        } else if (
          data &&
          data?.edgeMode?.toUpperCase() === EDGE_AND_APP_TYPES.EDGE_PRIMARY &&
          data?.appMode?.toUpperCase() === EDGE_AND_APP_TYPES.APP_BACKUP
        ) {
          return `
            <div class='d-flex justify-content-start align-items-start'>
              <span><strong>Backup app:</strong> ${data[column]}<span>
            </div>`;
        } else {
          return `${data[column]}`;
        }
      } else if (column === 'edgeRegion') {
        if (
          data &&
          data?.edgeMode?.toUpperCase() === EDGE_AND_APP_TYPES.EDGE_PRIMARY
        ) {
          return `
            <div class='d-flex justify-content-start align-items-start'>
              <span><strong>Primary Edge:</strong> ${data[column]}<span>
            </div>`;
        } else if (
          data &&
          data?.edgeMode?.toUpperCase() === EDGE_AND_APP_TYPES.EDGE_SECONDARY
        ) {
          return `
            <div class='d-flex justify-content-start align-items-start'>
              <span><strong>Secondary Edge:</strong> ${data[column]}<span>
            </div>`;
        } else {
          return `${data[column]}`;
        }
      } else {
        const status = data[column];
        let cssClass = `status-icon ${
          status === 'accessible'
            ? 'status-icon-success'
            : status === 'partial'
            ? 'status-icon-partial'
            : 'status-icon-error'
        }`;
        const affectedServices =
          data && data.affectedServices && data.affectedServices.length > 0
            ? data.affectedServices[0].name
            : '';
        if (column === 'appHealthStatus') {
          return logViewConfig.enableHealthCheck
            ? `<div class="${cssClass}" title="${affectedServices}"></di>`
            : `<div>NA</div>`;
        } else if (column === 'edgeHealthStatus') {
          return `<div class="${cssClass}" title="${
            status === 'partial'
              ? 'Partial Edge Failure impacting agent based access'
              : affectedServices
          }"></div>`;
        }
      }
    },
  },
};

export const IGNORED_OBJECT_KEY_LISTS = [
  'startTime',
  'count',
  'ignorePlotting',
];

export const UX_CONTRIBUTING_FACTORS_DATA_DISPLAY_ORDER = [
  'firstMile',
  'edge',
  'cloudBackbone',
  'app',
];

export const USER_RISK_SCORE_CONTRIBUTING_FACTORS_DATA_DISPLAY_ORDER = [
  'request_ct',
  'waf_hits',
  'policy_denied',
];

export const KEY_DISPLAY_NAME_MAPPING = {
  firstMile: 'First Mile',
  edge: 'Edge',
  cloudBackbone: 'Cloud Backbone',
  app: 'App',
  request_ct: 'Request Count',
  waf_hits: 'WAF',
  policy_denied: 'Policy',
  new_user: 'New User',
  new_location: 'Location',
  new_tod: 'Time of the day',
  geo_velocity: 'Unrealistic Geo-Velocity',
};

export const DEFAULT_PAGINATOR_DATA = {
  size: 100,
  start: 0,
} as Page;

export const InsightsDashboardTooltips = {
  summary: {
    devices:
      'Set up Sankey chart based on devices used by users to access apps',
    networks:
      'Set up Sankey chart based on networks used by users to access apps',
    countries:
      'Set up Sankey chart based on country level location of users to access apps',
  },
};

export enum EdgeHealthTypes {
  private = 'PRIVATE',
  public = 'PUBLIC',
}

export const InsightsEventsBannerDesc = 'Viewing details of all requests for ';

export const InsightsSummaryBannerDesc = 'Viewing details of all ';

export enum TRAFFIC_ENDPOINT_TYPE {
  SOURCE = 'source',
  TARGET = 'target',
  SOURCE_REGION = 'sourceRegion',
  TARGET_REGION = 'targetRegion',
}

export const TRAFFIC_ENDPOINT_NAME: { [type: string]: string } = {
  [TRAFFIC_ENDPOINT_TYPE.SOURCE]: 'Source',
  [TRAFFIC_ENDPOINT_TYPE.SOURCE_REGION]: 'Source',
  [TRAFFIC_ENDPOINT_TYPE.TARGET]: 'Target',
  [TRAFFIC_ENDPOINT_TYPE.TARGET_REGION]: 'Target',
};

export const SUNBURST_CHART_TYPES: { [id: string]: Array<SunburstChartType> } =
  {
    REGION: [
      {
        name: 'Top Sources',
        valueField: 'totalDataSent',
        valueName: 'Total Data Sent',
        level1: TRAFFIC_ENDPOINT_TYPE.SOURCE_REGION,
        level1Name: TRAFFIC_ENDPOINT_NAME[TRAFFIC_ENDPOINT_TYPE.SOURCE_REGION],
        level2: TRAFFIC_ENDPOINT_TYPE.TARGET_REGION,
        level2Name: TRAFFIC_ENDPOINT_NAME[TRAFFIC_ENDPOINT_TYPE.TARGET_REGION],
        showType: false,
        tooltip: 'Total data sent by Sources',
      },
      {
        name: 'Top Targets',
        valueField: 'totalDataRcvd',
        valueName: 'Total Data Received',
        level1: TRAFFIC_ENDPOINT_TYPE.TARGET_REGION,
        level1Name: TRAFFIC_ENDPOINT_NAME[TRAFFIC_ENDPOINT_TYPE.TARGET_REGION],
        level2: TRAFFIC_ENDPOINT_TYPE.SOURCE_REGION,
        level2Name: TRAFFIC_ENDPOINT_NAME[TRAFFIC_ENDPOINT_TYPE.SOURCE_REGION],
        showType: false,
        tooltip: 'Total data sent by Targets',
      },
      {
        name: 'Top Talkers',
        valueField: 'totalData',
        valueName: 'Total Data',
        level1: TRAFFIC_ENDPOINT_TYPE.SOURCE_REGION,
        level1Name: TRAFFIC_ENDPOINT_NAME[TRAFFIC_ENDPOINT_TYPE.SOURCE_REGION],
        level2: TRAFFIC_ENDPOINT_TYPE.TARGET_REGION,
        level2Name: TRAFFIC_ENDPOINT_NAME[TRAFFIC_ENDPOINT_TYPE.TARGET_REGION],
        showType: false,
        tooltip: 'Total data sent and received between sources and targets',
      },
    ],
    NETWORK: [
      {
        name: 'Top Sources',
        valueField: 'totalDataSent',
        valueName: 'Total Data Sent',
        level1: TRAFFIC_ENDPOINT_TYPE.SOURCE,
        level1Name: TRAFFIC_ENDPOINT_NAME[TRAFFIC_ENDPOINT_TYPE.SOURCE],
        level2: TRAFFIC_ENDPOINT_TYPE.TARGET,
        level2Name: TRAFFIC_ENDPOINT_NAME[TRAFFIC_ENDPOINT_TYPE.TARGET],
        showType: true,
        tooltip: 'Total data sent by Sources',
      },
      {
        name: 'Top Targets',
        valueField: 'totalDataRcvd',
        valueName: 'Total Data Received',
        level1: TRAFFIC_ENDPOINT_TYPE.TARGET,
        level1Name: TRAFFIC_ENDPOINT_NAME[TRAFFIC_ENDPOINT_TYPE.TARGET],
        level2: TRAFFIC_ENDPOINT_TYPE.SOURCE,
        level2Name: TRAFFIC_ENDPOINT_NAME[TRAFFIC_ENDPOINT_TYPE.SOURCE],
        showType: true,
        tooltip: 'Total data sent by Targets',
      },
      {
        name: 'Top Talkers',
        valueField: 'totalData',
        valueName: 'Total Data',
        level1: TRAFFIC_ENDPOINT_TYPE.SOURCE,
        level1Name: TRAFFIC_ENDPOINT_NAME[TRAFFIC_ENDPOINT_TYPE.SOURCE],
        level2: TRAFFIC_ENDPOINT_TYPE.TARGET,
        level2Name: TRAFFIC_ENDPOINT_NAME[TRAFFIC_ENDPOINT_TYPE.TARGET],
        showType: true,
        tooltip: 'Total data sent and received between sources and targets',
      },
    ],
    SESSIONS: [
      {
        name: 'Top Sessions',
        valueField: 'count',
        valueName: 'Total Sessions',
        level1: TRAFFIC_ENDPOINT_TYPE.SOURCE,
        level1Name: TRAFFIC_ENDPOINT_NAME[TRAFFIC_ENDPOINT_TYPE.SOURCE],
        level2: TRAFFIC_ENDPOINT_TYPE.TARGET,
        level2Name: TRAFFIC_ENDPOINT_NAME[TRAFFIC_ENDPOINT_TYPE.TARGET],
        showType: true,
        tooltip: 'Total number of unique sessions between sources and targets',
      },
    ],
  };

export const SORT_KEYS = {
  APP: 'appName',
  APP_GROUP: 'name',
  USER: 'email',
  USER_GROUP: 'name',
  SOURCE_NETWORKS: 'name',
  SOURCE_PRIVATE_LINK: 'name',
  SOURCE_REGIONS: 'name',
  TARGET_NETWORKS: 'name',
  TARGET_REGIONS: 'name',
  SRC_NETWORK_GROUPS: 'name',
  TGT_NETWORK_GROUPS: 'name',
};

export const ALL = 'all';

// Constants for breadcrumbs.
export const COST_BREADCRUMB_CONSTANTS = {
  INSIGHTS: 'Insights',
  OVERVIEW: 'Overview',
  DATA_TRANSFER: 'Data Transfer',
  CHARGEBACK: 'Changeback',
  SHARED_SERVICES: 'Shared Services',
  PROSIMO_INFRA: 'Prosimo Infra',
};

export const COST_PATHS_CONSTANTS = {
  DATA_TRANSFER_PATH: '/cost/overview',
  INSIGHT: '/dashboard/insights/mcn/summary',
  INSIGHT_MAIN: '/v2/insights/main',
};

export const ANALYTICS_360_BREADCRUMB_CONSTANTS = {
  ANALYTICS: 'Analytics 360',
  ANALYTICS_LINK: '',
};
