import { Store, StoreConfig } from '@datorama/akita';

export interface CloudTransitSharedFilterState {
  activeTab?: string;
}

export function createInitialState(): CloudTransitSharedFilterState {
  return {
    activeTab: '',
  };
}

@StoreConfig({ name: 'cloud-transit', resettable: true })
export class CloudTransitSharedFilterStore extends Store<CloudTransitSharedFilterState> {
  constructor() {
    super(createInitialState());
  }
}

export const cloudTransitSharedFilterStore =
  new CloudTransitSharedFilterStore();
