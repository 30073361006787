import { GroupSelectPill } from './group-select-pill.model';
import { ObjectUtil } from '@prosimoio/services';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
@Component({
  selector: 'app-group-select-pill',
  templateUrl: './group-select-pill.component.html',
  styleUrls: ['./group-select-pill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupSelectPillComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() config: GroupSelectPill;
  @Input() displayOneLine = false;
  @Input() hidePillList = false;
  @Input() multiSelect = true;
  @Input() displayTrigger = true;
  @Input() displayGroup = true;
  @Output() valuesChange = new EventEmitter();
  @Output() removedItem = new EventEmitter();
  selectedList: Array<any>;
  selectedItem = null;
  selectControl = new UntypedFormControl();
  compareFn: any;
  constructor(private cdr: ChangeDetectorRef) {
    // bind the SelectPillComponent context to get the config info in the  _compareFn function
    this.compareFn = this._compareFn.bind(this);
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.selectControl.valueChanges.subscribe((value) => {
      if (!value && this.config.targetKey) {
        return;
      }
      if (this.multiSelect) {
        this.selectedList = value
          .slice()
          .filter((item) => ObjectUtil.hasKeys(item));
      } else {
        this.selectedItem = value;
        if (this.selectedItem && !Object.keys(this.selectedItem).length) {
          this.selectedItem = null;
        }
      }
      this.emitChanges();
      this.cdr.markForCheck();
    });
  }

  ngOnChanges() {
    if (this.config) {
      if (this.multiSelect) {
        this.selectedList = this.config.selectedValues.slice();
      } else {
        this.selectedItem = this.config.selectedValues[0];
      }
      this.selectControl.patchValue(
        this.multiSelect ? this.selectedList : this.selectedItem
      );
      this.cdr.markForCheck();
    }
  }

  _compareFn(value, otherValue) {
    if (typeof value === 'string') {
      return value === otherValue;
    } else {
      if (this.config && this.config.twoUniqueKeys) {
        const key = this.config.uniqueKey;
        const key2 = this.config.secondaryUniqueKey;
        const modifiedValue = value ? `${value[key]}-${value[key2]}` : value;
        const modifiedOtherValue = otherValue
          ? `${otherValue[key]}-${otherValue[key2]}`
          : otherValue;
        return modifiedValue === modifiedOtherValue;
      } else {
        const key = this.config ? this.config.uniqueKey || 'id' : 'id';
        return value && otherValue
          ? value[key] === otherValue[key]
          : value === otherValue;
      }
    }
  }

  removeSelectedItem(item: any, pos: number) {
    this.selectedList.splice(pos, 1);
    this.selectControl.patchValue(this.selectedList);
    this.removedItem.emit(item);
    this.cdr.markForCheck();
  }

  emitChanges() {
    const changes = this.multiSelect
      ? this.selectedList.slice()
      : this.selectedItem;
    this.valuesChange.emit(changes);
  }
}
