import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  private selectedFilter$ = new Subject<string>();
  private done$ = new Subject<boolean>();
  private cancel$ = new Subject<boolean>();
  private errorMsg$ = new Subject<string>();
  private showLoader$ = new Subject<boolean>();

  constructor() {}

  setSelectedFilter(selectedFilter: string) {
    this.selectedFilter$.next(selectedFilter);
  }

  getSelectedFilter(): Observable<string> {
    return this.selectedFilter$.asObservable();
  }

  setDoneStatus(doneStatus: boolean) {
    this.done$.next(doneStatus);
  }

  getDoneStatus(): Observable<boolean> {
    return this.done$.asObservable();
  }

  setCancelStatus(cancelStatus: boolean) {
    this.cancel$.next(cancelStatus);
    this.errorMsg$.next('');
  }

  getCancelStatus(): Observable<boolean> {
    return this.cancel$.asObservable();
  }

  setErrorMessage(msg: string) {
    this.errorMsg$.next(msg);
  }

  getErrorMessage(): Observable<string> {
    return this.errorMsg$.asObservable();
  }

  setLoaderStatus(showLoader: boolean) {
    this.showLoader$.next(showLoader);
  }

  getLoaderStatus(): Observable<boolean> {
    return this.showLoader$.asObservable();
  }
}
