import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { PROGRESS_BAR_TYPES } from './progress-bar.constant';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent implements OnInit {
  @Input() progressBarType: string = PROGRESS_BAR_TYPES.HORIZONTAL;
  @Input() circleDiameter: number = 35;
  @Input() barSize: number = 10;
  @Input() showValue: boolean = true;
  @Input() set data(values: { actualValue: number, totalvalue: number, statusColor: string}) {
    this._data = values;
    this.calculateProgress(this._data);
  } 
  get data() {
    return this._data;
  }

  _data;
  defaultValues = {actualValue: 0, totalvalue: 100, statusColor: '#000'};
  percentage: number;
  progress: string
  progressBarTypes = PROGRESS_BAR_TYPES;
  constructor() { 
    this.calculateProgress(this.defaultValues);
  }

  ngOnInit(): void {
  }

  /**
   * Calculate no. of degree to show circle
   * @param data 
   */
  calculateProgress(data = this.defaultValues) {
    const percent = (data?.actualValue || 0) * 100 / (data?.totalvalue || this.defaultValues.totalvalue);
    const deg = percent * Math.PI * 360 / Math.PI / 100;

    this.percentage = percent;
    this.progress = `conic-gradient(${data?.statusColor || this.data?.statusColor} ${deg}deg, #ededed 0deg)`;
  }
}
