import { Tab } from '@app/management/management.model';

export const POLICY_LIBRARY_MESSAGES = {
  PAGE_TITLE: 'Access Policies',
  CREATE_BTN_LABEL: 'Create New',
  CREATE_BTN_TYPE: 'stroked',
  CREATE_FORM_TITLE: 'Create New Access Policy',
  CREATED_MSG: 'Access Policy Created ',
  EDIT_FORM_TITLE: 'Edit Access Policy',
  DELETE_BTN_LABEL: 'Delete Access Policy',
  DELETE_MSG: 'Are you sure you want to delete Access Policy?',
  SYNC_BTN_LABEL: 'Sync from IDP',
  PAGE_TITLE_NAMESPACE: 'Namespaces',
};

export const POLICY_CONSTANTS = {
  EDIT_BTN_LABEL: 'Edit',
};

// Constants for breadcrumbs.
export const BREADCRUMB_POLICY_CONSTANTS = {
  CONFIGURE: 'Configure',
  CONFIGURE_LINK: '/configure',
  SECUITY: 'Security',
  SECURITY_LINK: '/configure',
  ACCESS_POLICY: 'Access Policies',
  ACCESS_POLICY_LINK: '/configure/v2/policy/access-policy',
};

export const TAB_CONSTANTS_NAMESPACE = {
  CREATE: 'Create',
  ASSIGN: 'Assign',
  EXPORT: 'Export',
};

export const DEFAULT_NAMESPACE_TABS = [
  {
    name: 'Assign',
    isSelected: true,
    key: TAB_CONSTANTS_NAMESPACE.ASSIGN,
    code: TAB_CONSTANTS_NAMESPACE.ASSIGN,
    order: 1,
  } as Tab,
];

export const NAMESPACE_TABS = [
  {
    name: 'Assign',
    isSelected: true,
    key: TAB_CONSTANTS_NAMESPACE.ASSIGN,
    code: TAB_CONSTANTS_NAMESPACE.ASSIGN,
    order: 1,
  } as Tab,
  {
    name: 'Export',
    isSelected: false,
    key: TAB_CONSTANTS_NAMESPACE.EXPORT,
    code: TAB_CONSTANTS_NAMESPACE.EXPORT,
    order: 2,
  } as Tab,
];

export const OVERVIEW_CONTAINER_NAMESPACE_TABS = [
  {
    tabName: TAB_CONSTANTS_NAMESPACE.ASSIGN,
    totalRecords: 0,
  },
  {
    tabName: TAB_CONSTANTS_NAMESPACE.EXPORT,
    totalRecords: 0,
  },
];

export const NAMESPACE_MESSAGES = {
  CREATE: 'Namespace created successfully',
  UPDATE: 'Namespace name updated successfully',
};

export const SEARCH_TYPE = {
  EXPORT_NETWORK: 'export network',
  EXPORT_NAMESPACE: 'export namespace',
};
export const TAB_LABEL_CLASS = 'mat-tab-label-ml-28';

export const NAME_INPUT_WIDTH = '154px';

export const NAME_MAX_LENGTH = 64;

export const OVERVIEW_TABLE_MESSAGE = 'No data available';
