import { Store, StoreConfig } from '@datorama/akita';
import { ViewMode } from '../network.constants';
import { Network } from '@app/common/models';
export interface NetworkState {
  activeTab: string;
  activeNetwork: Network;
}

export function createInitialState(): NetworkState {
  return {
    activeTab: ViewMode.ONBOARDED,
    activeNetwork: null,
  };
}

@StoreConfig({
  name: 'networks',
  resettable: true,
})
export class NetworkStore extends Store<NetworkState> {
  constructor() {
    super(createInitialState());
  }
}

export const networkStore = new NetworkStore();
