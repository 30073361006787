import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import { IMAGE_PATHS } from '@app/common/util/constants';
import { DashboardSubMenuConfig } from './dashboard-sub-menu.config';
import { DashboardSubMenuService } from './dashboard-sub-menu.service';

@Component({
  selector: 'app-dashboard-sub-menu',
  templateUrl: './dashboard-sub-menu.component.html',
  styleUrls: ['./dashboard-sub-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardSubMenuComponent implements OnInit {
  @Input() configs: Array<DashboardSubMenuConfig>;
  @Input() tabsWithBottomBotder: boolean = false;
  @Output() selectedConfigs = new EventEmitter();
  selectedSubMenuConfig;
  BETA_ICON = IMAGE_PATHS.COMMON.BETA_ICON;

  constructor(
    private dashboardSubMenuService: DashboardSubMenuService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.selectedSubMenuConfig = this.configs.find(
      (subMenuConfig) => subMenuConfig.isSelected
    );
  }

  /**
   * Method to set the selected sub menu item
   * @param selectedSubMenuConfig - selected sub menu item configuration
   */
  setSelectedSubMenuItem(selectedSubMenuConfig: string) {
    this.configs.forEach((subMenuItem) => {
      if (
        subMenuItem.code.toLowerCase() === selectedSubMenuConfig.toLowerCase()
      ) {
        subMenuItem.isSelected = true;
        this.selectedSubMenuConfig = subMenuItem;
      } else {
        subMenuItem.isSelected = false;
      }
    });
    this.dashboardSubMenuService.setSelectedSubMenuItem(
      this.selectedSubMenuConfig
    );
    this.selectedConfigs.emit(this.selectedSubMenuConfig);
    this.cdr.markForCheck();
  }
}
