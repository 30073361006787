import { HelpService } from '@app/component-common/help/help.service';
import { Router } from '@angular/router';
import { BannerService } from '@app/component-common/banner/banner.service';
import { HeaderService } from '@app/component-common/header/header.service';
import { L2HeaderService } from '@app/component-common/l2header/l2header.service';
import { LoginUtil } from './login-util';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CONSTANTS } from './../../environments/environment';
import { ResourceService } from '@app/common/http/util/resource.service';
import { LoginModel, LOGIN_AUTH_TYPES } from '@app/login/login.model';
import { LoginModelSerializer } from './login.model.serializer';
import { UserState } from './state';
import {
  PROSIMO_TOKEN_LABEL,
  URL_PATHS,
  USER_STATUSES,
  USER_TYPES,
} from '@app/common/util/constants';
import { PDashLocalStoreUtil } from '@app/state/web-pdash.util';
import { ObjectUtil } from '@prosimoio/services';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoginService extends ResourceService<LoginModel> {
  queryParams: string;

  constructor(
    protected httpClient: HttpClient,
    private loginUtil: LoginUtil,
    private helpService: HelpService,
    private l2HeaderService: L2HeaderService,
    private headerService: HeaderService,
    private bannerService: BannerService,
    private router: Router
  ) {
    super(
      httpClient,
      CONSTANTS.DASHBOARD_HOST,
      CONSTANTS.AUTH_PATH,
      new LoginModelSerializer()
    );
  }

  validateGeneralToken(): Observable<LoginModel> {
    this.path = CONSTANTS.VALIDATE_ACCESS;
    return this.create({} as LoginModel);
  }

  authenticateUser(userCredentials: LoginModel): Observable<LoginModel> {
    this.path = CONSTANTS.AUTH_PATH;
    return this.create(userCredentials);
  }

  requestAuthCode() {
    this.path = CONSTANTS.AUTH_CODE(this.loginUtil.getUserID());
    return this.create(this.getAuthenticationRequestData());
  }

  verifyAuthenticationCode(data: any) {
    this.path = CONSTANTS.AUTH_CODE_VERIFY(this.loginUtil.getUserID());
    return this.create(this.getAuthenticationRequestData(data));
  }

  getAuthenticationRequestData(additional?: any) {
    const { type } = this.getAuthenticationFactorType();
    const data = Object.assign({}, { type }, additional) as LoginModel;
    return data;
  }

  getAuthenticationFactorType() {
    const factorType = this.getAuthenticationFactors().find((item) => {
      return item.details.target ? true : false;
    });
    const userName = this.loginUtil.getUserName();
    return factorType || { type: 'email', details: { target: userName } };
  }

  getAuthenticationFactors() {
    return this.loginUtil.getUserAuthenticationFactors();
  }

  getLogoDetails(): Observable<LoginModel> {
    this.path = CONSTANTS.TEAM_META_INFO;
    return this.read('');
  }

  getUserInfoFromStore(): UserState {
    return this.loginUtil.getUserState();
  }

  /**
   * Set the user info from route guard if the authType of userInfo is SSO('pdash-sso')
   * @param data
   */
  setUserInfoFromRouteGuard(data: any = {}) {
    const currentUserInfo = this.getUserInfoFromStore();
    if (
      data?.authType === LOGIN_AUTH_TYPES.SSO &&
      !ObjectUtil.compare(currentUserInfo, data)
    ) {
      const qp = currentUserInfo?.loginQueryParam;
      // to set the userInfo if current and incoming user info is different
      this.setUserInfo(data, qp, false);
      this.loginUtil.updateLoginQueryParam(null);
    }

    if (data?.licenseStatus !== currentUserInfo.licenseStatus) {
      const { type, accountExpiry, licenseStatus } = data;
      this.loginUtil.updateUserLicenseStatus(licenseStatus);
      this.bannerService.displayBannerInfo(accountExpiry, type, licenseStatus);
    }
  }

  /**
   * Return user state obj
   */
  getUserStateInfo(data): UserState {
    return data
      ? ({
          firstname: data.firstname,
          lastname: data.lastname,
          username: data.username,
          userId: data.userId || data.userID,
          prosimoAppStatus: data.prosimoAppStatus,
          team: data.team,
          token: data.token,
          tokenName: data.tokenName || PROSIMO_TOKEN_LABEL,
          tokenExpiry: data.tokenExpiry,
          generalToken: data.generalToken,
          generalTokenName: data.generalTokenName || '*',
          generalTokenExpiry: data.tokenExpiry,
          type: data.type,
          authType: data.authType,
          company: data.company,
          status: data.status,
          factors: data.factors,
          accountExpiry: data.accountExpiry,
          licenseStatus: data.licenseStatus,
          subscriptionType: this.loginUtil.getTransformedSubscriptionType(
            data?.subscriptionType
          ),
          engLabsEnabled: data?.engLabsEnabled,
        } as UserState)
      : ({
          username: '',
          userId: '',
          firstname: '',
          lastname: '',
          token: '',
          type: '',
          authType: '',
          prosimoAppStatus: '',
          team: '',
          company: '',
          licenseStatus: '',
          subscriptionType: null,
          engLabsEnabled: false,
        } as UserState);
  }

  getPageConfigData(accountType: string) {
    if (accountType === USER_TYPES.PROSIMO_ADMIN) {
      // do nothing if account type is prosimo admin
      return;
    }
    this.helpService
      .getPageConfigs()
      .pipe(take(1))
      .subscribe(
        (data) => {
          PDashLocalStoreUtil.updateHelpConfig(data);
        },
        (error) => {
          console.log('Unable to fetch page config');
        }
      );
  }

  /**
   * set the user info after login success
   */
  setUserInfo(data: any, qp: string = null, routingRequired: boolean = true) {
    this.queryParams = qp;
    // set user state in local store
    const userState = this.getUserStateInfo(data);
    // Store logged-in user state
    if (userState.status === USER_STATUSES.MFA_REQUIRED) {
      this.loginUtil.updateUserState(userState);
    } else {
      this.loginUtil.updateUserState(userState, true);
    }
    const { firstname, lastname, username, team, authType } = userState;
    this.l2HeaderService.setL2HeaderUserConfig({
      firstname,
      lastname,
      username,
      team,
      disabledProfile: userState?.authType === LOGIN_AUTH_TYPES.SSO,
    });
    // Update header component with the logged-in status and user-information
    this.headerService.setLoggedIn(true);
    this.headerService.setUserInfo(userState);

    // route to nex page based on mfa required or not
    if (userState.status === USER_STATUSES.MFA_REQUIRED) {
      this.requestAuthenticationCode();
    } else {
      this.postLoginSteps(this.queryParams, routingRequired);
    }
  }

  /**
   * Request authentication code before moving to the mfa page
   */
  requestAuthenticationCode() {
    this.requestAuthCode()
      .pipe(take(1))
      .subscribe(
        (data) => {
          console.log('authentication code success', data);
        },
        (error) => {
          console.warn('authentication request failed', error);
        }
      );

    let mfaUIPath = CONSTANTS.MFA.UI_PATH_AUTHENTICATION;
    mfaUIPath = this.queryParams
      ? `${mfaUIPath}/${this.queryParams}`
      : mfaUIPath;
    this.router.navigateByUrl(mfaUIPath);
  }

  postLoginSteps(qp: string = null, routingRequired: boolean = true) {
    const { type, accountExpiry, licenseStatus } =
      this.loginUtil.getUserState();
    if (this.loginUtil.isAccountExpired()) {
      this.router.navigateByUrl(URL_PATHS.ACCOUNT_EXPIRED);
    } else {
      this.queryParams = qp;
      // get page config from server side
      this.getPageConfigData(type);
      if (routingRequired) {
        this.loginUtil.startRouting(this.queryParams);
      }
      // this.fetchCompanyMetaInfo();
      //  validate to display the trial banner or not
      this.bannerService.displayBannerInfo(accountExpiry, type, licenseStatus);
    }
  }

  /**
   * Fetches company meta info like logo, name etc
   * response data = {
      "iconPath":"msp-1",
      "companyName":"verizon",
      "accountType": "msp"
      };
   * @param userState
   */
  fetchTeamMetaInfo() {
    this.getLogoDetails()
      .pipe(take(1))
      .subscribe(
        (data) => {
          let { iconPath, companyName, accountType, loginURL } =
            data as LoginModel;
          iconPath = iconPath ? `${iconPath}` : null;
          companyName = companyName ? companyName : 'Prosimo';
          loginURL = loginURL ? loginURL : this.compileLoginURL();
          this.loginUtil.updateIconPath(iconPath);
          this.loginUtil.updateCompanyName(companyName);
          this.loginUtil.updateAccountType(accountType);
          this.loginUtil.updateLoginURL(loginURL);
        },
        (error) => {
          // this.loginUtil.startRouting(this.queryParams);
          console.warn('Unable to fetch team meta info');
        }
      );
  }

  compileLoginURL() {
    return `${location.protocol}//${location.hostname}${URL_PATHS.LOGIN}`;
  }
}
