import {
  AdvancedSettingStore,
  advancedSettingStore,
} from './advanced-setting.store';

export class AdvancedSettingService {
  constructor(private advancedSettingStore: AdvancedSettingStore) { }

  updateActiveTab(activeTab: string) {
    this.advancedSettingStore.update((state) => ({
      activeTab,
    }));
  }
}

export const advancedSettingService = new AdvancedSettingService(
  advancedSettingStore
);
