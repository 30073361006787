import { Injectable } from '@angular/core';
import { ResourceService } from '@app/common/http/util/resource.service';
import { HttpClient } from '@angular/common/http';
import { CONSTANTS } from 'environments/environment';
import { PolicyFormModelSerializer } from './policy.model.serializer';
import {
  NamespaceInfo,
  OverlappedNamespace,
  Policy,
  PolicyBatchItem,
} from './policy.model';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { switchMap, retry, share, takeUntil } from 'rxjs/operators';
import { PaginatorSearchModel } from '@app/dashboard/dashboard.constants';
import { BREADCRUMB_POLICY_CONSTANTS } from './policy.constants';
import { ENV_CONSTANTS } from 'environments/env.constants';
import { Router } from '@angular/router';
import { QueryOptions } from '@app/common/http/util/query-options';
import { BreadcrumbService } from '@prosimoio/components';
import { BreadcrumbConfig } from '@prosimoio/components/lib/v2/breadcrumb/breadcrumb.model';
import { FILTER_TYPES } from '@app/common/util/constants';
import { Network } from '@app/common/models/network';

@Injectable({
  providedIn: 'root',
})
export class PolicyService extends ResourceService<any> {
  stopPolling: any;

  // Tab config.
  public tabName$: BehaviorSubject<any> = new BehaviorSubject<any>('');

  constructor(
    protected httpClient: HttpClient,
    private breadcrumbService: BreadcrumbService
  ) {
    super(
      httpClient,
      CONSTANTS.DASHBOARD_HOST,
      '',
      new PolicyFormModelSerializer()
    );
  }

  getFormDefinitionAgent() {
    this.path = CONSTANTS.POLICY.FORM_DEFINITION_AGENT;
    return this.read('');
  }

  getFormDefinitionAgentLess() {
    this.path = CONSTANTS.POLICY.FORM_DEFINITION_AGENT_LESS;
    return this.read('');
  }

  getTargetAppsAndSourceAppsMapping() {
    this.path = CONSTANTS.POLICY.TARGET_APP_AND_SOURCE_APPS_RELATION;
    return this.read('');
  }

  getAvailablePolicies() {
    this.path = CONSTANTS.POLICY.LIST;
    return this.read('');
  }

  getAvailableApps() {
    this.path = CONSTANTS.POLICY.APPS;
    return this.read('');
  }

  getAvailableNetworks(category: string): Observable<any> {
    const searchObj = {
      category,
    };
    this.path = CONSTANTS.NETWORK_SEARCH;
    return this.create(searchObj);
  }

  getNamespaceNetwork(payload: any, id: string): Observable<any> {
    this.path = CONSTANTS.NAMESPACE_NETWORKS(id);
    return this.create(payload);
  }

  getSvcNamespaceNetworks(params, id) {
    this.path = CONSTANTS.SVC_NAMESPACE_NETWORKS_POLICY(id);
    return this.list(new QueryOptions(params));
  }

  getSvcNamespaceNetworksGroupByNamespace(params) {
    this.path = CONSTANTS.GET_NETWORKS_BY_NAMESPACES;
    return this.create(params);
  }

  getAvailableAppGroups() {
    this.path = CONSTANTS.GROUPINGS.GET(FILTER_TYPES.APP);
    return this.read('');
  }

  createPolicy(policy: Policy): Observable<any> {
    this.path = CONSTANTS.POLICY.POST;
    return this.create(policy);
  }

  updatePolicy(policy: Policy): Observable<any> {
    this.path = CONSTANTS.POLICY.PUT;
    return this.update(policy);
  }

  updatePolicies(policies: Array<PolicyBatchItem>): Observable<any> {
    this.path = CONSTANTS.POLICY.BATCH_UPDATE;
    return this.updateAll(policies);
  }

  deletePolicy(id): Observable<any> {
    this.path = CONSTANTS.POLICY.DELETE;
    return this.delete(id);
  }

  isNameUnique(policyName: string): Observable<any> {
    this.path = CONSTANTS.POLICY.UNIQUE_NAME(policyName);
    return this.read('');
  }

  getPoliciesImportStatus() {
    const postData = { feature: 'Import', type: 'App Policy' };
    this.path = CONSTANTS.GUIDE.DEPLOY_STATUS;
    return this.create(postData);
  }

  getPoliciesImportProgress() {
    this.stopPolling = new Subject();
    const progress$ = timer(1, 2000).pipe(
      switchMap(() => this.getPoliciesImportStatus()),
      retry(3),
      share(),
      takeUntil(this.stopPolling)
    );
    return progress$;
  }

  setStopPolling() {
    if (this.stopPolling) {
      this.stopPolling.next();
      this.stopPolling.complete();
    }
  }

  searchPolicies(searchObj: PaginatorSearchModel): Observable<any> {
    this.path = CONSTANTS.POLICY.SEARCH;
    return this.create(searchObj);
  }

  /**
   * Return the apps only exist in the available apps
   * @param selectedApps
   * @param availableApps
   * @returns
   */
  getAvailableSelectedApps(selectedApps = [], availableApps = []) {
    const availableAppsIDSet = new Set(availableApps.map((app) => app.id));
    return selectedApps.filter((app) => availableAppsIDSet.has(app.id));
  }

  getFormDefinitionAccess() {
    this.path = CONSTANTS.POLICY.FORM_DEFINITION_ACCESS;
    return this.read('');
  }

  getFormDefinitionTransit() {
    this.path = CONSTANTS.POLICY.FORM_DEFINITION_TRANSIT;
    return this.read('');
  }

  getPolicies(payload) {
    this.path = CONSTANTS.POLICY.POLICY_SEARCH;
    return this.create(payload);
  }

  /** Prepare breadcrumb config. */
  prepareBreadcrumbConfig(label: string, link: string) {
    let breadcrumbConfig: { label: string; link: string }[] = [];
    breadcrumbConfig?.push({
      label: BREADCRUMB_POLICY_CONSTANTS.CONFIGURE,
      link: BREADCRUMB_POLICY_CONSTANTS.CONFIGURE_LINK,
    });
    breadcrumbConfig?.push({
      label: BREADCRUMB_POLICY_CONSTANTS.SECUITY,
      link: BREADCRUMB_POLICY_CONSTANTS.SECURITY_LINK,
    });
    breadcrumbConfig?.push({ label, link });
    this.setBreadcrumbConfig(breadcrumbConfig);
  }

  setBreadcrumbConfig(breadcrumbConfig: BreadcrumbConfig[]) {
    this.breadcrumbService?.setBreadcrumbConfig(breadcrumbConfig);
  }

  /** Reset breadcrumb config. */
  resetBreadcrumbs() {
    this.setBreadcrumbConfig([]);
  }

  /** Set tab name from the Access Policy Create New/Edit form.
   *  @Param tabName : Name of selected tab.
   */
  setTabNameInPolicyForm(tabName: string): void {
    this.tabName$?.next(tabName);
  }

  /** Get tab name selected from the Access Policy Create New/Edit form.
   *  Return tabName : Name of selected tab.
   */
  getTabNameFromPolicyForm() {
    return this.tabName$?.asObservable();
  }

  /** Fetch the location details (Country, State, City) from the backend.
   *  @Param payload : Includes fields - type and searchTerm.
   */
  getGeoLocations(payload): Observable<any> {
    this.path = ENV_CONSTANTS.SECURITY.DYNAMIC_RISK.LOCATION;
    return this.create(payload);
  }

  addNamespace(payload: any): Observable<any> {
    this.path = CONSTANTS.NETWORK_ONBOARD.ADD_NAMESPACE;
    return this.create(payload);
  }

  editNamespace(name: string, id: string): Observable<any> {
    this.path = CONSTANTS.NETWORK_ONBOARD.EDIT_NAMESPACE(id);
    return this.update({ name });
  }

  getNamespaceList(payload?: any): Observable<NamespaceInfo> {
    this.path = CONSTANTS.NETWORK_ONBOARD.GET_NAMESPACES_LIST;
    return this.create(payload);
  }

  getOverlappedNamespaces(id: string): Observable<Array<OverlappedNamespace>> {
    this.path = CONSTANTS.NETWORK_ONBOARD.OVERLAPPED_NAMESPACES(id);
    return this.read('');
  }

  getNamespacesForExport(networkID: string): Observable<NamespaceInfo> {
    this.path = CONSTANTS.GET_NAMESPACE_TO_EXPORTS(networkID);
    return this.read('');
  }

  getAllNamespaceList(): Observable<NamespaceInfo> {
    this.path = CONSTANTS.NETWORK_ONBOARD.GET_NAMESPACES_LIST;
    return this.create({});
  }

  deleteNamespace(id: string): Observable<any> {
    this.path = CONSTANTS.NETWORK_ONBOARD.DELETE_NAMESPACE;
    return this.delete(id);
  }

  assignNetworkToNamespace(data: any, id: string): Observable<any> {
    this.path = CONSTANTS.NETWORK_ONBOARD.ASSIGN_NETWORK_TO_NAMESPACE(id);
    return this.update(data);
  }

  exportNetworkToNamespace(data: any, id: string): Observable<any> {
    this.path = CONSTANTS.NETWORK_ONBOARD.EXPORT_NETWORK(id);
    return this.update(data);
  }

  getOverlappingCidrByNetworks(payload: Array<string>): Observable<Array<any>> {
    this.path = CONSTANTS.NETWORK_ONBOARD.GET_OVERLAPPING_CIDR;
    return this.create(payload);
  }

  getNamespaceMemberOverview(id: string): Observable<any> {
    this.path = CONSTANTS.NAMESPACE_MEMBER_OVERVIEW(id);
    return this.create({});
  }
  getNamespaceExportOverview(id: string): Observable<any> {
    this.path = CONSTANTS.NAMESPACE_EXPORT_OVERVIEW(id);
    return this.create({});
  }
  getNamespaceImportOverview(id: string): Observable<any> {
    this.path = CONSTANTS.NAMESPACE_IMPORT_OVERVIEW(id);
    return this.create({});
  }
  getAssignedNetworks(payload?: any): Observable<Array<Network>> {
    this.path = CONSTANTS.NETWORK_WITH_ASSIGNED_NAMESPACE;
    return this.create(payload);
  }

  withdrawNetwork(data: any, id: string): Observable<any> {
    this.path = CONSTANTS.NETWORK_ONBOARD.WITHDRAW_NETWORK(id);
    return this.create(data);
  }

  getNamespaceToWithdraw(id: string): Observable<Array<Network>> {
    this.path = CONSTANTS.NAMESPACE_TO_WITHDRAW(id);
    return this.read('');
  }

  navigateToPolicyLandingPage(router: Router, landingPageURL) {
    router?.navigateByUrl(landingPageURL);
  }
}
