export const SELECT_ROW_MESSAGE =
  'Click on a row to view data or click on create new to start building a new policy.';

export const OVERVIEW_TITLE = 'Overview:';

export const TABS = {
  NAMESPACE: 'Namespace',
  CONDITIONS: 'Conditions',
  SELECT_TARGETS: 'Select Targets',
  ACTION_DETAILS: 'Action Details',
};

export const OVERVIEW_CONTAINER_TABS = [
  {
    tabName: TABS.NAMESPACE,
    totalRecords: 0,
  },
  {
    tabName: TABS.ACTION_DETAILS,
    totalRecords: 0,
  },
  {
    tabName: TABS.SELECT_TARGETS,
    totalRecords: 0,
  },

  {
    tabName: TABS.CONDITIONS,
    totalRecords: 0,
  },
];

export const ACCESS_ACTIONS = {
  MFA: 'Enforce MFA',
  SKIPWAF: 'Skip WAF',
  ALERT: 'Send Alert',
  BYPASS: 'Bypass IDP',
  LOCKUSER: 'Lock Users accross all apps',
};

export const ACCESS_ACTIONS_ALLOW = {
  MFA: 'Enforce MFA',
  SKIPWAF: 'Skip WAF',
  ALERT: 'Send Alert',
  BYPASS: 'Bypass IDP',
};

export const ACCESS_ACTIONS_DENY = {
  ALERT: 'Send Alert',
  LOCKUSER: 'Lock Users accross all apps',
};

export const ACTION_DETAILS = {
  ALLOW: 'Allow',
  DENY: 'Deny',
  YES: 'Yes',
  NO: 'No',
};

export const TABS_NAMESPACES = {
  NETWORK_MEMBERS: 'Network Members',
  EXPORTED_NETWORKS: 'Exported Networks',
  IMPORTED_NETWORKS: 'Imported Networks',
};

export const TABS_NAMESPACES_TOOLTIPS = {
  NETWORK_MEMBERS:
    'List of networks within this Namespace, which can communicate with each other by default',
  EXPORTED_NETWORKS:
    'Networks exported to other namespaces. Exported networks cannot originate traffic to members of that namespace.',
  IMPORTED_NETWORKS:
    'Networks imported to this namespace from other namespaces. All imported networks can be accessed by all the members of this namespace.',
  DESTINATION_NAMESPACES:
    'Destination Namespace(s) networks can send traffic to source networks, but not the other way.',
};

export const OVERVIEW_CONTAINER_TABS_NAMESPACE = [
  {
    tabName: TABS_NAMESPACES.NETWORK_MEMBERS,
    totalRecords: 0,
    tooltip: TABS_NAMESPACES_TOOLTIPS.NETWORK_MEMBERS,
  },
  {
    tabName: TABS_NAMESPACES.EXPORTED_NETWORKS,
    totalRecords: 0,
    tooltip: TABS_NAMESPACES_TOOLTIPS.EXPORTED_NETWORKS,
  },
  {
    tabName: TABS_NAMESPACES.IMPORTED_NETWORKS,
    totalRecords: 0,
    tooltip: TABS_NAMESPACES_TOOLTIPS.IMPORTED_NETWORKS,
  },
];

export const NAMESAPCE_TABLE_CONFIG = {
  TABLE_COLUMN_CONFIG: {
    OPERATION_STATUS: {
      columnStyle: {
        'min-width': '120px',
      },
    },
    NAMESPACE_NAME: {
      columnStyle: {
        'max-width': '120px',
      },
    },
    NETWORK_MEMBERS: {
      columnStyle: {
        'max-width': '120px',
      },
    },
    IMPORTED_NETWORKS: {
      columnStyle: {
        'max-width': '120px',
      },
    },
  },
};

export const DISPLAY_COLUMNS_NAMESPACE = [
  'status',
  'name',
  'networkMembers',
  'importedMembers',
];

export const COLUMN_NAME_NAMESPACE = [
  'Operational Status',
  'Namespace Name',
  'Network Members',
  'Imported Members',
];

export const SELECT_ROW_MESSAGE_NAMESPACE =
  'Click on a row to view data or click on create new to start creating a new Namespace.';

export const NO_RECORDS = 'There doesnt seem to be any data available.';

export const NAMESPACE_ACTION = {
  EXPORTED_TO: 'EXPORTED TO',
  IMPORTED_TO: 'IMPORTED FROM',
};

export const ALLOW_ACCESS_ACTIONS = [
  {
    action: 'mfa',
    value: ACTION_DETAILS.NO,
  },
  {
    action: 'skipWAF',
    value: ACTION_DETAILS.NO,
  },
  {
    action: 'alert',
    value: ACTION_DETAILS.NO,
  },
  {
    action: 'bypass',
    value: ACTION_DETAILS.NO,
  },
];

export const DENY_ACCESS_ACTIONS = [
  {
    action: 'alert',
    value: ACTION_DETAILS.NO,
  },
  {
    action: 'lockUser',
    value: ACTION_DETAILS.NO,
  },
];

export const PRO_TRANSIT_ACCESS_ACTIONS = [
  {
    action: 'alert',
    value: ACTION_DETAILS.NO,
  },
];

export const LOCATION_TYPE = {
  COUNTRY: 'country',
  STATE: 'state',
  CITY: 'city',
};

// Constants for the Access Policy conditions.
export const POLICY_CONDITIONS = {
  ADVANCED: 'advanced',
  URL: 'url',
  FQDN: 'fqdn',
  HTTP_PARAMETER: 'HTTP Parameters',
  PAAS_SERVICE: 'PAAS Services',
  AMAZONS3: 'Amazon S3',
  IDP: 'idp',
  ATTRIBUTE: 'Attribute',
  ADVANCED_CONDITION: 'Advanced',
  URL_CONDITION: 'URL',
  FQDN_CONDITION: 'FQDN',
};

// Constants for the Drawer component.
export const DRAWER_COMPONENT = {
  WIDTH: '618px',
  DEFAULT_PADDING: 'p-0',
};

// Constant for the Access Policy form buttons.
export const ACCESS_POLICY_FORM_BUTTON = {
  CANCEL_BTN_CONFIG: { code: 'CANCEL', name: 'Cancel', disabled: false },
  NEXT_BTN_CONFIG: { code: 'NEXT', name: 'Next', disabled: false },
  SAVE_BTN_CONFIG: { code: 'SAVE', name: 'Save', disabled: false },
};

// Constant for Location condition.
export const LOCATION_CONDITION = {
  COUNTRY: 'Country',
  STATE: 'State',
  CITY: 'City',
};

// Constant for Network ACL condition.
export const NETWORK_ACL_CONDITION = {
  NETWORK_ACL: 'networkACL',
  SOURCE_PORT: 'Source Port',
  SOURCEPORT: 'sourcePort',
  TARGET_PORT: 'Target Port',
  TARGETPORT: 'targetPort',
  SOURCE_IP: 'Source IP',
  SOURCEIP: 'sourceIP',
  TARGET_IP: 'Target IP',
  TARGETIP: 'targetIP',
  PROTOCOLS: 'Protocol',
  PROTOCOL: 'protocol',
  EGRESS_FQDN: 'egressFqdns',
};

export const EGRESS_FQDN_PROPERTIES = [
  'protocol',
  'targetPort',
  'sourceIP',
  'fqdnMatch',
  'fqdnInverseMatch',
];

// Constant for Egress FQDN condition.
export const EGRESS_FQDN_CONDITION = {
  PORT: 'Port',
  SOURCE: 'Source',
  PROTOCOLS: 'Protocol',
  VALUES: 'Values',
  NAME: 'name',
  PROTOCOL: 'protocol',
  SOURCE_IP: 'sourceIP',
  TARGET_PORT: 'targetPort',
  PROPERTY: 'property',
  PROPERTIES: 'Property',
  OPERATION: 'operation',
  FQDN_MATCHS: 'FQDN is',
  FQDN_INVERSE_MATCHS: 'FQDN is not',
  FQDN_MATCH: 'fqdnMatch',
  FQDN_INVERSE_MATCH: 'fqdnInverseMatch',
};
// Constants for Operator.
export const OPERATOR = {
  AND: 'AND',
  OR: 'OR',
  NOT: 'not',
};

// Constants for the Pro-Transit Network ACL condition.
export const NETWORK_ACL_PROPERTIES = [
  'Source IP',
  'Source Port',
  'Target IP',
  'Target Port',
  'Protocol',
];

// Constants for Access Policy.
export const ACCESS_POLICY_CONSTANTS = {
  ACCESS: 'access',
  TRANSIT: 'transit',
  LOCATION: 'Location',
  NETWORKS: 'networks',
  APPS: 'apps',
  GEOIP_COUNTRY_CODE: 'geoip_country_code',
  NONE: 'None',
  ID: 'id',
};

/* To maintain sequence in overview panel
   id key is required to maintain sequence of overview on policy library page
   and condition key is required to maintain sequence of overview popup on policy form page
*/
export const PRO_ACCESS_RULE_SEQUENCE = [
  {
    index: 1,
    id: 'users',
    condition: 'Users',
  },
  {
    index: 2,
    id: 'networks',
    condition: 'Location',
  },
  {
    index: 3,
    id: 'idp',
    condition: 'IDP',
  },
  {
    index: 4,
    id: 'devices',
    condition: 'Devices',
  },
  {
    index: 5,
    id: 'time',
    condition: 'Time',
  },
  {
    index: 6,
    id: 'HTTP Parameters',
    condition: 'HTTP Parameters',
  },
  {
    index: 7,
    id: 'devicePostureProfiles',
    condition: 'Device Posture Profile',
  },
  {
    index: 8,
    id: 'PAAS Services',
    condition: 'PAAS Services',
  },
];

/* To maintain sequence in overview panel
    id key is required to maintain sequence of overview on policy library page
    and condition key is required to maintain sequence of overview popup on policy form page
*/
export const PRO_TRANSIT_RULE_SEQUENCE = [
  {
    index: 1,
    id: 'time',
    condition: 'Time',
  },
  {
    index: 2,
    id: 'networkACL',
    condition: 'Network ACL',
  },
  {
    index: 3,
    id: 'prosimoNetworks',
    condition: 'Source Networks',
  },
  {
    index: 4,
    id: 'HTTP Parameters',
    condition: 'HTTP Parameters',
  },
  {
    index: 5,
    id: 'PAAS Services',
    condition: 'PAAS Services',
  },
  {
    index: 6,
    id: 'egressFqdns',
    condition: 'Egress FQDN',
  },
];

export const TABLE_CONFIG = {
  ROW_HEIGHT: '48px',
  HEADER_HEIGHT: '32px',
  TABLE_PADDING: '0px 12px 0px 16px',
  TABLE_COLUMN_CONFIG: {
    ACTION: {
      columnStyle: {
        'max-width': '110px',
      },
    },
    POLICY_NAME: {
      columnStyle: {
        'max-width': '200px',
      },
    },
    APPS: {
      columnStyle: {
        'max-width': '80px',
      },
    },
    NETWORKS: {
      columnStyle: {
        'max-width': '100px',
      },
    },
    APPS_NETWORKS_COUNT: {
      columnStyle: {
        'max-width': '180px',
      },
    },
  },
};

// Constant for 'name' key required in the dropdown list.
export const NAME = 'name';

// Constants for Access Policy form payload.
export const ACCESS_POLICY_FORM_PAYLOAD = {
  TYPE: 'type',
  FETCH: 'fetch',
  NAME: 'name',
  AMAZON_S3: 'amazon_s3',
};

// Constant for error message displayed in the dialog box.
export const ERROR_TITLE = 'Failed!';
