import { MSPAuthGuard } from './common/util/guards/auth/msp-auth.guard';
import { MFAGuard } from './common/util/guards/auth/mfa.guard';
import { PreloadAllModules } from '@angular/router';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { AppMaterialModule } from '@app/app-material.module';
import { AccountActivationModule } from './account-activation/account-activation.module';
import { PasswordFormStateResolver } from './password/password-form-state.resolver';
import { AuthGuard } from './common/util/guards/auth/auth.guard';
import { AdminAuthGuard } from './common/util/guards/auth/admin-auth.guard';
import { SignupGuard } from './common/util/guards/auth/signup.guard';
import { ErrorPagePreviewComponent } from './management/error-page/error-page-preview/error-page-preview.component';
const routes: Routes = [
  {
    path: 'validate-token',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'login/:queryParams',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'signin/:queryParams',
    loadChildren: () =>
      import('./signin/signin.module').then((m) => m.SigninModule),
  },
  {
    path: 'signin',
    loadChildren: () =>
      import('./signin/signin.module').then((m) => m.SigninModule),
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./signup/signup.module').then((m) => m.SignupModule),
    canActivate: [SignupGuard],
  },
  {
    path: 'password',
    loadChildren: () =>
      import('./password/password.module').then((m) => m.PasswordModule),
  },
  {
    path: 'password/:mode',
    loadChildren: () =>
      import('./password/password.module').then((m) => m.PasswordModule),
  },
  {
    path: 'password/:mode/:email/:userid/:token',
    loadChildren: () =>
      import('./password/password.module').then((m) => m.PasswordModule),
    resolve: {
      passwordFormState: PasswordFormStateResolver,
    },
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AdminAuthGuard],
  },
  {
    path: 'sp',
    loadChildren: () =>
      import('./managed-service-provider/managed-service-provider.module').then(
        (m) => m.ManagedServiceProviderModule
      ),
    canActivate: [MSPAuthGuard],
  },
  {
    path: 'activation/:email/:token',
    loadChildren: () =>
      import('./account-activation/account-activation.module').then(
        (m) => m.AccountActivationModule
      ),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'v2',
    loadChildren: () =>
      import('./v2/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'maintenance',
    loadChildren: () =>
      import('./under-maintenance/under-maintenance.module').then(
        (m) => m.UnderMaintenanceModule
      ),
  },
  {
    path: 'account/expired',
    loadChildren: () =>
      import('./account-expired/account-expired.module').then(
        (m) => m.AccountExpiredModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'authentication/:queryParams',
    loadChildren: () =>
      import(
        './multi-factor-authentication/multi-factor-authentication.module'
      ).then((m) => m.MultiFactorAuthenticationModule),
    canActivate: [MFAGuard],
  },
  {
    path: 'authentication',
    loadChildren: () =>
      import(
        './multi-factor-authentication/multi-factor-authentication.module'
      ).then((m) => m.MultiFactorAuthenticationModule),
    canActivate: [MFAGuard],
  },
  {
    path: 'error-pages/preview/:errorPageContent',
    component: ErrorPagePreviewComponent,
  },
  {
    path: 'api-doc',
    loadChildren: () =>
      import('./api-doc/api-doc.module').then((m) => m.ApiDocModule),
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'discovery',
  //   loadChildren: () =>
  //     import('./discovery/discovery.module').then((m) => m.DiscoveryModule),
  // },
  {
    path: 'cost',
    loadChildren: () => import('./cost/cost.module').then((m) => m.CostModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'spl',
    loadChildren: () =>
      import('./service-provider-login/service-provider-login.module').then(
        (m) => m.ServiceProviderLoginModule
      ),
  },
  {
    path: 'theme',
    loadChildren: () =>
      import('./theme-test/theme-test.module').then((m) => m.ThemeTestModule),
  },
  {
    path: 'management',
    loadChildren: () =>
      import('../app/management/management.module').then(
        (m) => m.ManagementModule
      ),
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '**',
    loadChildren: () =>
      import('./page-not-found/page-not-found.module').then(
        (m) => m.PageNotFoundModule
      ),
  },
];

const ROUTER_OPTIONS: ExtraOptions = {
  enableTracing: false,
  preloadingStrategy: PreloadAllModules,
  relativeLinkResolution: 'legacy',
};

@NgModule({
  imports: [
    RouterModule.forRoot(routes, ROUTER_OPTIONS),
    AppMaterialModule,
    AccountActivationModule,
  ],
  exports: [RouterModule, AppMaterialModule],
})
export class AppRoutingModule {}
