import {
  CloudTracer,
  CloudTracerComp,
  CloudTracerHistoryRecord,
} from '@app/v2/diagnose/diagnose.model';
import { cloudTracerStore, CloudTracerStore } from './cloud-tracer.store';

export class CloudTracerService {
  constructor(private cloudTracerDataStore: CloudTracerStore) {}

  updateSelectedHop(selectedHop: CloudTracerComp) {
    this.cloudTracerDataStore.update((state) => ({
      selectedHop,
    }));
  }

  updateSelectedCloudTraceRecord(
    selectedTraceRecord: CloudTracerHistoryRecord
  ) {
    this.cloudTracerDataStore.update((state) => ({
      selectedTraceRecord,
    }));
  }

  updateCloudTracerResults(cloudTracerResults: CloudTracer) {
    this.cloudTracerDataStore.update((state) => ({
      cloudTracerResults,
    }));
  }

  updateConfigRefreshTime(configRefreshTime: string) {
    this.cloudTracerDataStore.update((state) => ({
      configRefreshTime,
    }));
  }
}

export const cloudTracerService = new CloudTracerService(cloudTracerStore);
