<div id="image-upload-preview__container">
  <div>
    <ng-container>
      <div class="upload-container">
        <p class="section__title">Drag and drop file here</p>
        <p class="option">OR</p>
        <div class="btn__container">
          <button type="file" class="btn" mat-raised-button color="accent">
            <span (click)="uploadFile()">Upload File</span>
          </button>
        </div>
        <span class="section__hint" *ngIf="imageName$ | async"
          >Selected Image : {{ imageName$ | async }}</span
        >
        <img class="image-preview" [src]="imgurl" /> <br />
      </div>
    </ng-container>
    <div class="d-none">
      <input
        type="file"
        #fileInput
        class="invisible"
        (change)="onFileUpload($event)"
      />
    </div>
  </div>
</div>
