<div id="header__container" [ngClass]="{ 'margin-y': !dropdownConfig }">
  <div class="filter-wrapper">
    <app-search
      *ngIf="isShowSearchBox"
      [filterableColumnMap]="filterableColumnMap"
      (selectedFilterMenuColumn)="selectedFilterMenuColumnListener($event)"
      (searchTerm)="searchTermListener($event)"
    ></app-search>
  </div>
  <div
    class="d-flex flex-row flex-nowrap align-items-center justify-content-end legend-wrapper"
  >
    <app-legend [legends]="legendConfig"></app-legend>
  </div>
  <div class="col-0 d-flex px-3 dropdown-container" *ngIf="dropdownConfig">
    <div
      class="import-text"
      [ngClass]="{ 'd-none': (hideSyncBtn | async) }"
      (click)="onCreateImportPoliciesForm($event)"
    >
      SYNC FROM IDP
    </div>
  </div>
  <div
    class="col-0 d-flex flex-column px-3 progress-container"
    *ngIf="progressConfig"
  >
    <div class="mb-1 progress-txt">{{ progressConfig.label }}</div>
    <mat-progress-bar
      class="progress-bar complete"
      [mode]="progressConfig.progressType"
      [value]="progressConfig.value"
    >
    </mat-progress-bar>
  </div>
  <div class="actionBtn__container">
    <div
      *ngIf="isShowCreateNewButton"
      class="action__btn"
      [ngClass]="{
        'action__btn-enable': !isDisableCreateNewButton,
        'action__btn-disable': isDisableCreateNewButton
      }"
      (click)="onCreateNew($event)"
    >
      {{ btnLabel }}
      <i class="material-icons btn__icon">add</i>
    </div>
  </div>
</div>
