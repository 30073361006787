export class MathUtils {
  /**
   * Calculate Factorial
   * @param value The Number for which the factorial is calculated
   * @returns Factorial of `value`
   */
  static factorial(value: number) {
    if (value <= 1) return 1;

    return value * MathUtils.factorial(value - 1);
  }

  /**
   * Calculate number of permutations in `totalValue` elements with `selections` elements selected
   * @param totalValue Total number of elements
   * @param selections Number of elements selected
   * @returns Total number of permutations
   */
  static nPr(totalValue: number, selections: number) {
    return Math.floor(
      MathUtils.factorial(totalValue) /
        MathUtils.factorial(totalValue - selections)
    );
  }

  static wholeNumber(number: number, decimal = 2) {
    let isNumber = Number.isInteger(number);
    if (isNumber) {
      return Number(number?.toFixed());
    }

    isNumber = Number.isInteger(Number(number?.toFixed(decimal)));

    if (isNumber) {
      return Number(number?.toFixed());
    }

    return Number(number?.toFixed(decimal));
  }

  /**
   * Calculate the median of an array of numbers
   * @param values Array of `numbers`
   * @param defaultValue Default value if median is undefined
   * @returns A number denoting the median of the array
   */
  static median(values: Array<number>, defaultValue: number = 0): number {
    values.sort();
    return Math.ceil(
      values[
        values.length % 2 === 0
          ? values.length / 2
          : Math.floor(values.length / 2)
      ] || defaultValue
    );
  }

  /**
   * Calculate the mean of an array of numbers
   * @param values Array of `numbers`
   * @param defaultValue Default value if mean is undefined
   * @returns A number denoting the mean of the array
   */
  static mean(values: Array<number>, defaultValue: number = 0): number {
    if (!values || !values.length) {
      return defaultValue;
    }

    return values.reduce((p, v) => p + (isNaN(v) ? 0 : v), 0) / values.length;
  }
}
