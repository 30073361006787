import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Inject,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  Optional,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IMAGE_PATHS, UI_THEME_MODES } from '@app/common/util/constants';
import { PDashLocalStoreUtil } from '@app/state/web-pdash.util';
import {
  WarningDialogData,
  WARNING_DIALOG_DATA_TOKEN,
} from './warning-dialog.model';
import { BTN_LABELS, WARNING_DIALOG } from './warning-dialog.constants';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-warning-dialog',
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['./warning-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class WarningDialogComponent implements OnInit {
  @Input() message: string = '';
  @Input() buttonTitle: string = '';
  @Input() title: string = WARNING_DIALOG.TITLE;
  @Input() icon: string = WARNING_DIALOG.ICON;
  @Input() isShowCancelButton: boolean = false;
  @Input() cancelButtonTitle: string = '';
  @Input() dialogPosition: {
    top: string;
    right: string;
  } = null;
  isOpenFromPanel: boolean = false;

  @Output() closeDialogEvent = new EventEmitter();

  subscriptions: Subscription[] = [];
  uiThemeMode: UI_THEME_MODES = PDashLocalStoreUtil?.getUIThemeMode();
  warningIconURL = IMAGE_PATHS.CONFIGURE.WARNING;

  constructor(
    @Optional() public dialogref: MatDialogRef<WarningDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: WarningDialogData,
    @Optional()
    @Inject(WARNING_DIALOG_DATA_TOKEN)
    public panelData: WarningDialogData,
    private cdr: ChangeDetectorRef
  ) {
    if (panelData) {
      this.isOpenFromPanel = true;
      this.initVariablesData(this.panelData);
    } else {
      this.initVariablesData(this.data);
    }

    this.subscriptions?.push(
      // Check Theme changes.
      PDashLocalStoreUtil?.getUIThemeModeAsObservable()?.subscribe((mode) => {
        this.uiThemeMode = mode;
        this.cdr?.markForCheck();
      })
    );
  }

  ngOnInit(): void {
    if (!this.isOpenFromPanel) {
      // Set position of the dialog box.
      this.dialogref?.updatePosition({
        top: this.dialogPosition?.top || '46vh',
        right: this.dialogPosition?.right || '6vw',
      });
    }
  }

  initVariablesData(dialogData: WarningDialogData): void {
    // Message to be displayed in the dialog box.
    this.message = dialogData?.message;
    // Button label displayed in the dialog box.
    this.buttonTitle = dialogData?.buttonTitle
      ? dialogData?.buttonTitle
      : BTN_LABELS.MAIN_BUTTON;
    this.isShowCancelButton = dialogData?.isShowCancelButton || false;
    this.cancelButtonTitle =
      dialogData?.cancelButtonTitle || BTN_LABELS.CANCEL_BUTTON;
    this.dialogPosition = dialogData?.dialogPosition;
  }

  /** Event called when clicked on 'Okay' button. */
  onButtonClick() {
    let data = {
      action: this.buttonTitle,
    };
    // Close the dialog box.
    if (this.isOpenFromPanel) {
      this.closeDialogEvent?.emit(data);
    } else {
      this.dialogref?.close(data);
    }
  }

  onCancelEvent() {
    if (this.isOpenFromPanel) {
      this.closeDialogEvent?.emit(null);
    } else {
      this.dialogref?.close(null);
    }
  }

  ngOnDestroy() {
    this.subscriptions?.forEach((subscription) => subscription?.unsubscribe());
  }
}
