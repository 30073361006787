<ng-container *ngIf="headerConfig$ | async as headerConfig">
  <div
    id="l2header__container"
    class="d-flex flex-column justify-content-center px-3 bg-light-shade"
    *ngIf="headerConfig?.displayHeader"
  >
    <div class="row no-gutters align-items-center justify-content-between">
      <div
        class="w-100 l2header-profile row no-gutters d-md-none d-lg-block"
        *ngIf="headerConfig?.profile"
      >
        <div class="profile-action w-100 pl-2 d-flex justify-content-between">
          <div
            class="header-section"
            *ngFor="let section of headerConfig?.profile?.actions"
          >
            <div
              class="section-wrapper d-flex align-items-center"
              (click)="onRoute(section?.redirectTo)"
            >
              <img class="section-img" [src]="section?.imgURL" alt="" />
              <div class="info-count px-2">{{ section?.count }}</div>
              <div class="info-txt">
                {{ section?.displayName || section?.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end of profile header -->
    </div>
  </div>
</ng-container>
