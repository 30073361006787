import {
  LogSearchFilterStore,
  logSearchFilterStore,
} from './log-search-filter.store';
import { TimeRange } from '../../shared/calendar.component';

export class LogSearchFilterStoreService {
  constructor(private store: LogSearchFilterStore) {}

  updateLogSearchFilterTimeRange(logSearchTimeRange: TimeRange) {
    this.store.update((state) => ({
      logSearchTimeRange,
    }));
  }
}

export const logSearchFilterStoreService = new LogSearchFilterStoreService(
  logSearchFilterStore
);
