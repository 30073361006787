<div id="form__container">
  <div *ngIf="showLogo" class="container__logo">
    <img
      *ngIf="!logo"
      src="{{ logo }}"
      width="80px"
      height="80px"
      class="logo mr-md-auto"
    />
    <img
      *ngIf="logo"
      src="{{ logo }}"
      [attr.width]="style.width"
      [attr.height]="style.height"
      class="logo mr-md-auto"
    />
  </div>
  <p *ngIf="title" class="mt-3 form__title" [innerHTML]="title | safe: 'html'"></p>
  <ng-content></ng-content>
</div>
