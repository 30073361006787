import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from '@app/common/http/service/common.service';
import { CONSTANTS } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends CommonService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  /**
   * Method to get auth token status
   */
  getAuthTokenStatus(): Observable<any> {
    this.path = CONSTANTS.PASSWORD.VALIDATE_TOKEN;
    return this.getData(this.path);
  }
}
