import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AppUtil } from '@app/common/util/app-util';
import { ObjectUtil } from '@prosimoio/services';
import { ILightboxBtnConfig } from '@app/dashboard/diagnose/diagnostics/lightbox-form-template/lightbox-model';
import {
  DRAWER_BTN_CONSTANT,
  ERROR_MESSAGE_AND_FORMAT,
} from '@app/management/management.constants';
import { PDashLocalStoreUtil } from '@app/state/web-pdash.util';
import {
  CreateButton,
  CustomizedSelectTriggerTextConfig,
  SelectSearchConfig,
  ToastService,
} from '@prosimoio/components';
import { DataFetchFormService } from './data-fetch-form.service';
import { Scope } from '@app/management/data-fetch/data-fetch.model';
import { CLOUD_KEY_TYPES, CLOUD_TYPES } from '@app/common/util/constants';
import {
  NavConfirmationService,
  NavData,
} from '@app/common/util/nav-confirmation.service';
@Component({
  selector: 'app-data-fetch-form',
  templateUrl: './data-fetch-form.component.html',
  styleUrls: ['./data-fetch-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataFetchFormComponent implements OnInit, OnDestroy {
  formTitle: string;
  subscriptions = [];
  uiThemeMode: string = PDashLocalStoreUtil?.getUIThemeMode()?.toLowerCase();
  manualDataFetchForm: UntypedFormGroup;
  selectSearchCSPConfig: SelectSearchConfig;
  selectSearchRegionConfig: SelectSearchConfig;
  selectSearchAccountConfig: SelectSearchConfig;
  tooltipThemeClass: string;
  regionList: Array<any> = [];
  accountList: Array<any> = [];
  cloudAccounts: Array<any> = [];
  cspList: Array<string> = [];
  errorMessagesFormat = ERROR_MESSAGE_AND_FORMAT;
  dataFetchButtonConfig = DRAWER_BTN_CONSTANT.FETCH_BTN_CONFIG;
  cancelButtonConfig = DRAWER_BTN_CONSTANT.CANCEL_BTN_CONFIG;
  accountRegionsMap = {};
  cspAccountsMap = {};
  showInsideDrawer: boolean;
  lbData: any;
  isFetchBtnDisabled = true;
  manualDataFetchFormInitValues;
  fetchButton: CreateButton = {
    label: 'Refresh',
  };
  isRegionDataLoading = true;
  customizedSelectTriggerCSPTextConfig = {
    isShow: true,
    text: '',
    plural: `CSP's`,
  } as CustomizedSelectTriggerTextConfig;
  customizedSelectTriggerAccountTextConfig = {
    isShow: true,
    text: '',
    plural: 'Accounts',
  } as CustomizedSelectTriggerTextConfig;
  customizedSelectTriggerRegionTextConfig = {
    isShow: true,
    text: '',
    plural: 'Regions',
  } as CustomizedSelectTriggerTextConfig;
  constructor(
    private cdr: ChangeDetectorRef,
    private appUtil: AppUtil,
    private fb: UntypedFormBuilder,
    private toastService: ToastService,
    private dataFetchFormService: DataFetchFormService,
    private navConfirmationService: NavConfirmationService
  ) {
    this.subscriptions.push(
      PDashLocalStoreUtil.getUIThemeModeAsObservable().subscribe((mode) => {
        this.uiThemeMode = mode?.toLowerCase() + '-theme';
        this.tooltipThemeClass =
          'cdk-component-container--' + this.uiThemeMode + '-dark';
        this.toastService?.setUITheme(this.uiThemeMode);
        this.setSelectSearchConfig();
        this.cdr.markForCheck();
      }),
      this.appUtil
        .getDrawerResponse()
        .subscribe((response: ILightboxBtnConfig) => {
          this.drawerResponseAction(response);
        }),
      this.appUtil.getBackdropEvent()?.subscribe({
        next: (action: boolean) => {
          if (action) {
            const navData: NavData = {
              check: true,
              elem: null,
            };
            this.isFormChanged()
              ? this.navConfirmationService.navConfirmationPopup(navData)
              : this.appUtil.setDrawerState(false);
          }
        },
      })
    );
  }
  @Input() set data(data) {
    const {
      formTitle = '',
      cloudAccountAndRegionData = [],
      showInsideDrawer = false,
      lbData = {},
    } = data;
    if (cloudAccountAndRegionData?.length > 0) {
      this.isRegionDataLoading = false;
    }
    this.lbData = lbData;
    this.showInsideDrawer = showInsideDrawer;
    const modifiedCloudAccountAndRegionData = this.modifyRegionsNames(
      cloudAccountAndRegionData
    );
    this.getCloudAccountAndRegionData(modifiedCloudAccountAndRegionData);
    this.formTitle = formTitle;
    this.initManualDataFetchForm();
    this.setSelectSearchConfig();
  }

  modifyRegionsNames(cloudAccountAndRegionData = []) {
    return cloudAccountAndRegionData
      .filter(
        (item) =>
          item?.keyType !== CLOUD_KEY_TYPES.UNMANAGED &&
          item?.regionNames &&
          item?.regionNames?.length > 0
      )
      .map((item) => ({
        ...item,
        regionNames: item?.regionNames?.map((region) => ({
          displayName: `${item.cloudType}/${region}`,
          value: region,
        })),
      }));
  }

  getCloudAccountAndRegionData(cloudAccountAndRegionData = []) {
    const cloudTypesSet = new Set(
      this.appUtil
        .transformStringToAzureFormat(cloudAccountAndRegionData, 'cloudType')
        .map((item) => item.cloudType)
    ) as any;
    this.cspList = Array.from(cloudTypesSet);
    this.cspAccountsMap = {};
    cloudAccountAndRegionData.forEach((item) => {
      const accountName = item.accountName || item.accountID;
      const { cloudType, accountID } = item;
      if (cloudType && accountID) {
        if (!this.cspAccountsMap[cloudType]) {
          this.cspAccountsMap[cloudType] = [];
        }
        this.cspAccountsMap[cloudType].push({
          cloudType: item.cloudType,
          displayName: `${accountName}/${item.name}`,
          value: item?.accountID,
        });
      }
    });

    // Create a map for accountID and their regions
    this.accountRegionsMap = {};
    this.accountRegionsMap = cloudAccountAndRegionData
      .filter((item) => item.accountID && item.regionNames)
      .reduce((acc, item) => {
        acc[item.accountID] = item.regionNames;
        return acc;
      }, {});
  }

  setSelectSearchConfig() {
    this.setSelectSearchCSPConfig();
    this.setSelectSearchAccountConfig();
    this.setSelectSearchRegionConfig();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.manualDataFetchForm?.valueChanges?.subscribe((val) => {
        const navData: NavData = {
          check: this.isFormChanged(),
          elem: null,
        };
        this.navConfirmationService.updateNavData(navData);

        var buttonConfig;
        if (this.manualDataFetchForm.valid) {
          buttonConfig = this.getButtonConfig(false);
          this.isFetchBtnDisabled = false;
        } else if (this.manualDataFetchForm.invalid) {
          buttonConfig = this.getButtonConfig(true);
          this.isFetchBtnDisabled = true;
        }
        this.appUtil.updateDrawerButtonConfig({
          mainBtn: buttonConfig,
          cancelBtn: this.cancelButtonConfig,
        });
      })
    );
  }

  getButtonConfig(isDisabled: boolean) {
    return Object.assign(ObjectUtil.deepClone(this.dataFetchButtonConfig), {
      disabled: isDisabled,
    });
  }

  drawerResponseAction(response: any) {
    if (response.code === this.dataFetchButtonConfig.code) {
      this.onDataFetch(response);
    } else {
      const navData: NavData = {
        check: true,
        elem: null,
      };
      this.isFormChanged()
        ? this.navConfirmationService.navConfirmationPopup(navData)
        : this.appUtil.setDrawerState(false);
    }
  }

  initManualDataFetchForm() {
    this.manualDataFetchForm = this.fb.group({
      csp: ['', Validators.required],
      accounts: ['', Validators.required],
      regions: ['', Validators.required],
    });
    this.manualDataFetchFormInitValues = this.manualDataFetchForm.getRawValue();
  }

  setSelectSearchCSPConfig() {
    this.selectSearchCSPConfig = {
      isMultiSelection: true,
      optionList: this.cspList,
      placeholder: 'Cloud Service Provider',
      uiThemeMode: this.uiThemeMode,
      showSearch: false,
      addOption: false,
      panelWidthClass: 'select-width-lg',
    };
  }
  setSelectSearchAccountConfig() {
    this.selectSearchAccountConfig = {
      isMultiSelection: true,
      optionList: this.accountList,
      keyName: 'displayName',
      placeholder: 'Cloud Account',
      uiThemeMode: this.uiThemeMode,
      showSearch: true,
      addOption: false,
      panelWidthClass: 'select-width-lg',
    };
  }

  setSelectSearchRegionConfig() {
    this.selectSearchRegionConfig = {
      isMultiSelection: true,
      optionList: this.regionList,
      keyName: 'displayName',
      placeholder: 'Cloud Region',
      uiThemeMode: this.uiThemeMode,
      showSearch: true,
      addOption: false,
      panelWidthClass: 'select-width-lg',
    };
  }

  onDataFetch(response: any) {
    this.appUtil.setDynamicDrawerResponse({
      payload: this.getPayloadForDataFetch(),
      action: response,
      context: this.dataFetchButtonConfig.code,
    });
  }

  getPayloadForDataFetch(): Array<Scope> {
    const {
      csp = [],
      accounts = [],
      regions = [],
    } = this.manualDataFetchForm.getRawValue();
    let payload = [];
    csp.forEach((cspItem) => {
      const cspAccounts = this.getSelectedCSPAccounts(
        accounts,
        cspItem?.toUpperCase()
      );
      const selectedRegions = this.getSelectedCSPRegions(cspAccounts, regions);
      if (cspAccounts?.length && selectedRegions?.length) {
        payload.push({
          csp: cspItem?.toUpperCase(),
          accounts: cspAccounts,
          regions: selectedRegions,
          triggerCause: 'manual',
          triggerReason: 'manual',
        });
      }
    });
    return payload;
  }

  getSelectedCSPAccounts(accounts: Array<any>, csp: string) {
    const cspAccounts = (this.cspAccountsMap[csp] || []).map(
      (account) => account?.value
    );
    const filteredAccounts = accounts.filter((account) =>
      cspAccounts.includes(account?.value)
    );
    return filteredAccounts.map((account) => account?.value);
  }

  getSelectedCSPRegions(accounts: Array<any>, regions: Array<any>) {
    let accountsRegionList = [];
    accounts.forEach((account) => {
      const accountRegions = this.accountRegionsMap[account];
      accountsRegionList = [...accountsRegionList, ...accountRegions];
    });
    const filteredRegions = regions.filter((region) =>
      accountsRegionList.some(
        (accountRegion) => accountRegion.value === region.value
      )
    );
    return filteredRegions.map((region) => region?.value);
  }

  onCSPChange(selectedCSPs) {
    this.accountList = [];
    selectedCSPs.forEach((csp) => {
      this.accountList = [
        ...this.accountList,
        ...this.cspAccountsMap[csp.toUpperCase()],
      ];
    });
    this.updateSelectedAccounts();
    this.setSelectSearchAccountConfig();
  }

  onAccountChange(selectedCloudAccounts) {
    this.regionList = [];
    // to store all the regions for each cloud type
    const cloudAccountRegions = {
      [CLOUD_TYPES.AWS]: [],
      [CLOUD_TYPES.AZURE]: [],
      [CLOUD_TYPES.GCP]: [],
    };
    // The regions are derived by finding the intersection of regions from corresponding
    // accounts within each cloud service provider (CSP) and then combining these
    // intersections across all CSPs
    // regions = (AWS Acct 1 Regions ∩ AWS Acct 2 Regions) U
    // (AZURE Acct 1 Regions ∩ AZURE Acct 2 Regions) U
    // (GCP Acct 1 Regions ∩ GCP Acct 2 Regions)
    selectedCloudAccounts?.forEach((cloudAccount) => {
      const regions = this.accountRegionsMap[cloudAccount.value];
      const cloudType = cloudAccount.cloudType;
      if (regions && regions.length > 0) {
        if (cloudAccountRegions[cloudType].length === 0) {
          cloudAccountRegions[cloudType] = [...regions];
        } else {
          cloudAccountRegions[cloudType] = cloudAccountRegions[
            cloudType
          ].filter((region) => regions.some((r) => r.value === region.value));
        }
      }
    });
    const regionList = [
      ...cloudAccountRegions[CLOUD_TYPES.AWS],
      ...cloudAccountRegions[CLOUD_TYPES.AZURE],
      ...cloudAccountRegions[CLOUD_TYPES.GCP],
    ];
    this.regionList = this.appUtil.transformStringToAzureFormat(
      Object.values(regionList),
      'displayName'
    );
    this.regionList = this.appUtil.sort(
      this.regionList,
      'displayName',
      null,
      true
    );
    this.updateSelectedRegions();
    this.setSelectSearchRegionConfig();
  }

  updateSelectedAccounts() {
    let selectedAccountList = this.accounts.getRawValue();
    if (selectedAccountList?.length) {
      selectedAccountList = selectedAccountList.filter((selectedAccount) => {
        return this.accountList.some(
          (account) => account.value === selectedAccount.value
        );
      });
      this.accounts.patchValue(selectedAccountList);
      this.onAccountChange(selectedAccountList);
    }
  }

  updateSelectedRegions() {
    let selectedRegionsList = this.regions.getRawValue();
    if (selectedRegionsList?.length) {
      selectedRegionsList = selectedRegionsList.filter((selectedRegion) => {
        return this.regionList.some(
          (region) => region.displayName === selectedRegion.displayName
        );
      });
      this.regions.patchValue(selectedRegionsList);
    }
  }

  onRegionChange(event) {}

  get csp() {
    return this.manualDataFetchForm.get('csp') as FormControl;
  }

  get accounts() {
    return this.manualDataFetchForm.get('accounts') as FormControl;
  }

  get regions() {
    return this.manualDataFetchForm.get('regions') as FormControl;
  }

  onFetch() {
    const payload = this.getPayloadForDataFetch();
    this.dataFetchFormService.setSelectedActionButtonResponse({
      payload,
      action: 'FETCH',
    });
  }

  onCancelBtnClick() {
    this.appUtil.setLightboxDisplayState(false);
  }

  isFormChanged() {
    const rowFormValue = this.manualDataFetchForm?.getRawValue();
    const isFormChanged = !ObjectUtil.compare(
      this.manualDataFetchFormInitValues,
      rowFormValue
    );
    return isFormChanged;
  }

  ngOnDestroy(): void {
    const navData: NavData = {
      check: false,
      elem: null,
    };
    this.navConfirmationService.updateNavData(navData);
    this.subscriptions?.forEach((subscription) => subscription?.unsubscribe());
  }
}
