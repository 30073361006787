import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Application } from '@app/common/models/app';
import { AppUtil } from '@app/common/util/app-util';
import {
  CLOUD_TYPES,
  DIALOG_TOKEN,
  DOMAIN_TYPES,
  IMAGE_PATHS,
  LOADER_CONFIG_TYPES,
  LoaderConfig,
  REGEX,
  SUBSCRIPTION_TYPES,
} from '@app/common/util/constants';
import { NavData } from '@app/common/util/nav-confirmation.service';
import { LightboxBtnType } from '@app/dashboard/diagnose/diagnostics/lightbox-form-template/lightbox-model';
import { PDashLocalStoreUtil } from '@app/state/web-pdash.util';
import {
  APP_ONBOARD_TYPE_OPTIONS,
  APP_ONBOARD_TYPE_OPTION_FOR_DROPDOWN,
  APP_ONBOARD_TYPE_OPTION_KEYS,
  AUTH_OPTIONS,
  AUTH_OPTION_KEYS,
  AUTH_OPTION_FOR_DROPDOWN,
  CLOUD_SERVICE_KEYS,
  CLOUD_SERVICE_OPTIONS,
  DOMAIN_TYPE_KEYS,
  DOMAIN_TYPE_OPTIONS,
  ERROR_MESSAGE,
  METADATA_OPTIONS,
  METADATA_OPTIONS_KEYS,
  SERVICE_IP_ADDRESS_INPUT_METHOD_OPTIONS,
  SERVICE_IP_ADDRESS_INPUT_METHOD_OPTION_KEYS,
  TCP_UDP_ACCESS_METHOD_FOR_DROPDOWN,
  TCP_UDP_ACCESS_METHOD_OPTIONS,
  TCP_UDP_ACCESS_METHOD_OPTION_KEYS,
  TOOLTIP_MESSAGE,
  CLOUD_TYPE_OPTIONS,
  CSP_TYPE_OPTIONS,
  CLOUD_TYPE_OPTION_KEYS,
  CSP_TYPE_FOR_DROPDOWN,
} from '@app/v2/onboard/app/app.constants';
import { AppOnboardService } from '@app/v2/onboard/app/app.service';
import { DeleteAction } from '@app/v2/onboard/network/network.constants';
import {
  AppOnboardStep,
  IOrchestratorComponent,
} from '@app/common/components/drawer/orchestrator/orchestrator.model';
import { OrchestratorService } from '@app/common/components/drawer/orchestrator/orchestrator.service';
import { OnboardTemplateService } from '@app/common/components/drawer/templates/onboard-template/onboard-template.service';
import {
  DeleteDialogComponent,
  SelectSearchConfig,
  ToastService,
} from '@prosimoio/components';
import { ObjectUtil } from '@prosimoio/services';
import { Observable, Subject, of } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-definition',
  templateUrl: './definition.component.html',
  styleUrls: ['./definition.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefinitionComponent
  implements IOrchestratorComponent, OnInit, OnDestroy
{
  stopSubs$: Subject<void>;
  appDefinitionForm: UntypedFormGroup;
  COPY_APP_ONBOARD_TYPES = APP_ONBOARD_TYPE_OPTIONS;
  COPY_APP_ONBOARD_TYPE_OPTION_KEYS = APP_ONBOARD_TYPE_OPTION_KEYS;
  COPY_TCP_UDP_ACCESS_METHOD_OPTIONS = TCP_UDP_ACCESS_METHOD_OPTIONS;
  COPY_TCP_UDP_ACCESS_METHOD_OPTION_KEYS = TCP_UDP_ACCESS_METHOD_OPTION_KEYS;
  COPY_CLOUD_SERVICE_OPTIONS = CLOUD_SERVICE_OPTIONS;
  COPY_SERVICE_IP_ADDRESS_INPUT_METHOD_OPTIONS =
    SERVICE_IP_ADDRESS_INPUT_METHOD_OPTIONS;
  COPY_SERVICE_IP_ADDRESS_INPUT_METHOD_OPTION_KEYS =
    SERVICE_IP_ADDRESS_INPUT_METHOD_OPTION_KEYS;
  COPY_TOOLTIP_MESSAGE = TOOLTIP_MESSAGE;
  COPY_DOMAIN_TYPE_OPTIONS = DOMAIN_TYPE_OPTIONS;
  COPY_DOMAIN_TYPE_KEYS = DOMAIN_TYPE_KEYS;
  COPY_AUTH_OPTIONS = AUTH_OPTIONS;
  COPY_METADATA_OPTIONS = METADATA_OPTIONS;
  COPY_ERROR_MESSAGE = ERROR_MESSAGE;
  COPY_METADATA_OPTIONS_KEYS = METADATA_OPTIONS_KEYS;
  COPY_LOADER_CONFIG_TYPES = LOADER_CONFIG_TYPES;
  COPY_CLOUD_TYPE_OPTIONS = CLOUD_TYPE_OPTIONS;
  appOnboardTypeOptions: Array<any> = APP_ONBOARD_TYPE_OPTIONS;
  binUrl = IMAGE_PATHS.CONFIGURE.BIN;
  FORM_FIELDS_TO_DISABLE_VALUES = [];
  FORM_FIELDS_TO_DISABLE_KEYS: any = {};
  loaderURL: string = IMAGE_PATHS.DASHBOARD.DIAGNOSE.CLOUD_TRACER.LOADER_NEW;
  verifiedURL: string = IMAGE_PATHS.VISUAL_ONBOARD.REGION_COMPLETE;
  failedURL: string = IMAGE_PATHS.VISUAL_ONBOARD.ERROR;
  selectSearchConfigForAppOnboardType: SelectSearchConfig;
  selectSearchConfigForAccessType: SelectSearchConfig;
  selectSearchConfigForAuthType: SelectSearchConfig;
  selectSearchConfigForCSPType: SelectSearchConfig;
  isShowCSPOptionLogo: boolean = true;
  selectSearchConfigForPrivateCloudAccounts: SelectSearchConfig;
  serviceIPValidateLoader$: Observable<LoaderConfig>;
  cloudLoader$: Observable<LoaderConfig>;
  uiThemeMode: string;
  tooltipThemeClass: string;
  subdomainIncludedTooltip: string;
  prosimoDomain: string;
  metadataControlLabel: string;
  controlLabels = {};
  appBackup: Application;
  appData: Application;
  isNewApp: boolean;
  multipleDomainsAllowed: boolean;
  appStatus: string;
  CONTROL_LABEL = {
    INTERNAL_DOMAIN: 'internalDomain',
    APP_FQDN: 'appFqdn',
  };
  @Input() data: Application;
  productViewType: string;
  navData: NavData = {
    check: false,
    elem: null,
  };
  constructor(
    private orchestratorService: OrchestratorService,
    private appUtil: AppUtil,
    private fb: UntypedFormBuilder,
    private onboardTemplateService: OnboardTemplateService,
    private appOnboardService: AppOnboardService,
    private toastService: ToastService,
    private cdr: ChangeDetectorRef
  ) {
    this.stopSubs$ = new Subject();
    this.productViewType = PDashLocalStoreUtil.getProductViewType();
    if (this.productViewType === SUBSCRIPTION_TYPES.MCN) {
      this.appOnboardTypeOptions = APP_ONBOARD_TYPE_OPTIONS.filter(
        (option) => option?.key !== APP_ONBOARD_TYPE_OPTION_KEYS.IP
      );
    }
    PDashLocalStoreUtil.getUIThemeModeAsObservable()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe((mode) => {
        this.uiThemeMode = mode?.toLowerCase() + '-theme';
        this.toastService.setUITheme(this.uiThemeMode);
        this.tooltipThemeClass =
          'cdk-component-container--' + this.uiThemeMode + '-dark';
        this.setSelectSearchConfigForAppOnboardType();
        this.setSelectSearchConfigForAccessType();
        this.setSelectSearchConfigForAuthType();
        this.setSelectSearchConfigForCSPType();
        this.cdr.markForCheck();
      });
    PDashLocalStoreUtil.getProductViewTypeAsObservable()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe((productViewType) => {
        this.productViewType = productViewType;
      });
    this.onboardTemplateService
      .getNameInput()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (appName) => {
          this.appDefinitionForm?.controls?.appName.patchValue(appName);
          this.appOnboardService.updateApplicationConfig({ appName });
          this.appData = this.appOnboardService.getApplicationConfig();
        },
      });
    this.appUtil
      .getDrawerResponse()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (response) => {
          if (response.code === LightboxBtnType.NEXT.code) {
            this.markStepComplete();
          }
        },
      });
  }

  markStepComplete() {
    this.orchestratorService.setStepComplete(AppOnboardStep.DEFINE);
  }

  ngOnInit(): void {
    this.appBackup = ObjectUtil.deepClone(
      this.appOnboardService.getApplicationConfig()
    );
    this.appData = ObjectUtil.deepClone(
      this.appOnboardService.getApplicationConfig()
    );
    this.isNewApp = this.appOnboardService.isNewApp();
    this.appStatus = this.appOnboardService.getAppStatus();
    this.onboardTemplateService.setNameState({
      disabled: !this.isNewApp,
    });
    const { appOnboardType = '', status = '' } = this.appData;
    const formFields =
      this.appOnboardService.getFormFieldsToDisableValuesBasedOnStatus(status);
    if (formFields) {
      this.FORM_FIELDS_TO_DISABLE_KEYS = formFields;
      this.FORM_FIELDS_TO_DISABLE_VALUES = Object.values(formFields);
    }
    const appOnboardTypeForDropdown =
      APP_ONBOARD_TYPE_OPTION_FOR_DROPDOWN[appOnboardType];
    this.onAppOnboardTypeChange(appOnboardTypeForDropdown, true);
    this.setSelectSearchConfigForAppOnboardType();
    this.setSelectSearchConfigForAccessType();
    this.setSelectSearchConfigForAuthType();
    this.setSelectSearchConfigForCSPType();
    this.setDrawerFooter();
    this.appUtil.updateDrawerButtonConfig({
      mainBtn: LightboxBtnType.NEXT,
      cancelBtn: LightboxBtnType.CANCEL,
    });
  }

  getProsimoDomain() {
    this.appOnboardService
      .getProsimoDomain()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe((data) => {
        this.prosimoDomain = data?.domain;
        this.cdr.markForCheck();
      });
  }

  setSelectSearchConfigForAppOnboardType() {
    const config = {
      optionList: this.appOnboardTypeOptions,
      placeholder: 'Select App Type',
      uiThemeMode: this.uiThemeMode,
      showSearch: false,
      keyName: 'displayName',
      isMultiSelection: false,
      addOption: false,
      panelWidthClass:
        'ml-2 remove-mat-select-search-option-extra-space overflow-hidden',
    };
    this.selectSearchConfigForAppOnboardType = ObjectUtil.deepClone(config);
  }

  setSelectSearchConfigForAccessType() {
    const config = {
      optionList: TCP_UDP_ACCESS_METHOD_OPTIONS,
      placeholder: 'Select Access Type',
      uiThemeMode: this.uiThemeMode,
      showSearch: false,
      keyName: 'displayName',
      isMultiSelection: false,
      addOption: false,
      panelWidthClass: 'ml-2 remove-mat-select-search-option-extra-space',
    };
    this.selectSearchConfigForAccessType = ObjectUtil.deepClone(config);
  }

  setSelectSearchConfigForAuthType() {
    const config = {
      optionList: AUTH_OPTIONS,
      placeholder: 'Select Access Type',
      uiThemeMode: this.uiThemeMode,
      showSearch: false,
      keyName: 'displayName',
      isMultiSelection: false,
    };
    this.selectSearchConfigForAuthType = ObjectUtil.deepClone(config);
  }

  setSelectSearchConfigForCSPType() {
    const config = {
      optionList: CSP_TYPE_OPTIONS,
      placeholder: 'Select CSP',
      uiThemeMode: this.uiThemeMode,
      showSearch: false,
      keyName: 'displayName',
      isMultiSelection: false,
    };
    this.selectSearchConfigForCSPType = ObjectUtil.deepClone(config);
  }

  setSelectSearchConfigForPrivateCloudAccounts(cloudAccounts: Array<any>) {
    if (!cloudAccounts) {
      return;
    }
    const config = {
      optionList: cloudAccounts,
      placeholder: 'Select Cloud',
      uiThemeMode: this.uiThemeMode,
      showSearch: true,
      keyName: 'displayName',
      isMultiSelection: false,
    };
    this.selectSearchConfigForPrivateCloudAccounts =
      ObjectUtil.deepClone(config);
  }

  onAppOnboardTypeChange(appOnboardType: any, patchDataToForm = false) {
    if (!patchDataToForm) {
      this.appOnboardService.updateAppCloudHostedType(
        CLOUD_TYPE_OPTION_KEYS.PUBLIC
      );
      this.appOnboardService.updateAppCSPType('');
      this.appOnboardService.updateAppDCAppCloudID('');
    }
    switch (appOnboardType?.key) {
      case APP_ONBOARD_TYPE_OPTION_KEYS.FQDN:
        this.initFqdnAppDefinitionForm(patchDataToForm);
        break;
      case APP_ONBOARD_TYPE_OPTION_KEYS.IP:
        this.initIpAppDefinitionForm(patchDataToForm);
        break;
      case APP_ONBOARD_TYPE_OPTION_KEYS.WEB:
        this.initWebAppDefinitionForm(patchDataToForm);
        break;
      case APP_ONBOARD_TYPE_OPTION_KEYS.VDI:
        this.initVdiAppDefinitionForm(patchDataToForm);
        break;
      case APP_ONBOARD_TYPE_OPTION_KEYS.CLOUD_SVC:
        this.initCloudSvcAppDefinitionForm(patchDataToForm);
        break;
      case APP_ONBOARD_TYPE_OPTION_KEYS.JUMPBOX:
        this.initJumpboxAppDefinitionForm(patchDataToForm);
        break;
    }
    this.onAppDefinitionFormValueChanges();
  }

  onAccessMethodChange(accessMethod: any) {
    switch (accessMethod?.key) {
      case TCP_UDP_ACCESS_METHOD_OPTION_KEYS.FQDN:
        this.initFqdnAppDefinitionForm();
        break;
      case TCP_UDP_ACCESS_METHOD_OPTION_KEYS.SERVICE_IP:
        this.initAccessMethodIPForm();
    }
    this.onAppDefinitionFormValueChanges();
  }

  checkIPAllowlistRange(checked: boolean) {
    if (checked) {
      this.appDefinitionForm.addControl(
        'citrixIPControl',
        this.fb?.control('')
      );
      this.appDefinitionForm.addControl('citrixIP', this.fb?.control(''));
    } else {
      this.appDefinitionForm.removeControl('citrixIPControl');
      this.appDefinitionForm.removeControl('citrixIP');
    }
  }

  addCitrixIP() {
    const newIP = this.citrixIPControl?.value;
    this.citrixIPControl.reset();
    const existingIPs = this.citrixIP?.value;
    this.citrixIP.patchValue([newIP, ...existingIPs]);
  }

  removeCitrixIP(ip: string) {
    const existingIPs = this.citrixIP?.value;
    const indexToRemove = existingIPs.indexOf(ip);
    if (indexToRemove !== -1) {
      existingIPs.splice(indexToRemove, 1);
      this.citrixIP.patchValue(existingIPs);
    }
  }

  onChangeServiceIPType(value, domainIndex: number) {
    this.updateServiceIPValidateLoaderConfig(false);
    const serviceIPControl =
      this.getdomainFormGroupAtIndex(domainIndex).get('serviceIP');
    serviceIPControl.enable();
    serviceIPControl.reset();
    if (value === SERVICE_IP_ADDRESS_INPUT_METHOD_OPTION_KEYS.AUTO) {
      this.autoGenerateServiceIPAddress(domainIndex);
    }
  }

  initFqdnAppDefinitionForm(patchDataToForm = false) {
    const {
      appName = '',
      appURLs = [],
      cloudHostedType = '',
      cspType = '',
    } = this.appData;
    this.appDefinitionForm = this.fb.group({
      appName: [appName, Validators.required],
      appOnboardType: [
        {
          value: APP_ONBOARD_TYPE_OPTION_FOR_DROPDOWN.fqdn,
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.appOnboardType
          ),
        },
        Validators.required,
      ],
      accessType: [
        {
          value: TCP_UDP_ACCESS_METHOD_FOR_DROPDOWN.fqdn,
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.accessType
          ),
        },
        Validators.required,
      ],
      cloudHostedType: [
        {
          value: cloudHostedType,
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.cloudType
          ),
        },
      ],
      appURLs: new UntypedFormArray([]),
    });
    if (cloudHostedType === CLOUD_TYPE_OPTION_KEYS.PUBLIC) {
      this.appDefinitionForm.addControl(
        'cspType',
        this.fb.control({
          value: '',
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.cloudType
          ),
        })
      );
    } else {
      this.appDefinitionForm.addControl(
        'dcID',
        this.fb.control({
          value: '',
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.dcID
          ),
        })
      );
      this.getPrivateClouds(patchDataToForm);
    }
    if (patchDataToForm && appURLs?.length) {
      if (cspType) {
        this.cspType.patchValue(CSP_TYPE_FOR_DROPDOWN[cspType]);
      }
      appURLs?.forEach((appURL) => {
        const { appFqdn = '' } = appURL;
        const domain = {
          appFqdn: [
            {
              value: appFqdn,
              disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
                this.FORM_FIELDS_TO_DISABLE_KEYS.appFqdn
              ),
            },
            [
              Validators.required,
              Validators.pattern(REGEX.DOMAIN_NAME),
              this.uniqueURLValidator(this.CONTROL_LABEL.APP_FQDN),
            ],
          ],
        };
        this.appURLsArray.push(this.fb.group(domain));
      });
    } else {
      this.appOnboardService.updateAppURLs([]);
      const domain = {
        appFqdn: [
          '',
          [
            Validators.required,
            Validators.pattern(REGEX.DOMAIN_NAME),
            this.uniqueURLValidator(this.CONTROL_LABEL.APP_FQDN),
          ],
        ],
        domainType: [DOMAIN_TYPE_KEYS.CUSTOM],
      };
      this.appURLsArray.push(this.fb.group(domain));
      this.appOnboardService.addAppURL(this.fb.group(domain).getRawValue());
    }
    this.setControlLabels(APP_ONBOARD_TYPE_OPTION_KEYS.FQDN);
    this.multipleDomainsAllowed = this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
      this.FORM_FIELDS_TO_DISABLE_KEYS.addDomain
    )
      ? false
      : true;
    this.appOnboardService.updateApplicationConfig({
      accessType: this.accessType?.value?.key,
    });
  }

  initIpAppDefinitionForm(patchDataToForm = false) {
    const {
      appName = '',
      appURLs = [],
      cloudHostedType = '',
      cspType = '',
      dcID = '',
      accessType = '',
    } = this.appData;
    let appOnboardTypeToBePatched =
      patchDataToForm &&
      accessType === TCP_UDP_ACCESS_METHOD_OPTION_KEYS.SERVICE_IP
        ? APP_ONBOARD_TYPE_OPTION_FOR_DROPDOWN.fqdn
        : APP_ONBOARD_TYPE_OPTION_FOR_DROPDOWN.ip;

    this.appDefinitionForm = this.fb.group({
      appName: [appName, Validators.required],
      appOnboardType: [appOnboardTypeToBePatched, Validators.required],
      cloudHostedType: [
        {
          value: cloudHostedType,
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.cloudType
          ),
        },
      ],
      appURLs: new UntypedFormArray([]),
    });
    if (cloudHostedType === CLOUD_TYPE_OPTION_KEYS.PUBLIC) {
      this.appDefinitionForm.addControl(
        'cspType',
        this.fb.control({
          value: '',
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.cloudType
          ),
        })
      );
    } else {
      this.appDefinitionForm.addControl(
        'dcID',
        this.fb.control({
          value: '',
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.dcID
          ),
        })
      );
    }
    if (
      patchDataToForm &&
      accessType === TCP_UDP_ACCESS_METHOD_OPTION_KEYS.SERVICE_IP
    ) {
      this.appDefinitionForm.addControl(
        'accessType',
        this.fb?.control({
          value: TCP_UDP_ACCESS_METHOD_FOR_DROPDOWN[accessType],
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.accessType
          ),
        })
      );
    }
    if (patchDataToForm && appURLs?.length) {
      if (cspType) {
        this.cspType.patchValue(CSP_TYPE_FOR_DROPDOWN[cspType]);
      }
      if (dcID) {
        this.getPrivateClouds(true);
      }
      appURLs?.forEach((appURL) => {
        const {
          appFqdn = '',
          serviceIPType = SERVICE_IP_ADDRESS_INPUT_METHOD_OPTION_KEYS.AUTO,
          serviceIP = '',
        } = appURL;
        const domain = {
          appFqdn: [
            {
              value: appFqdn,
              disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
                this.FORM_FIELDS_TO_DISABLE_KEYS.appFqdn
              ),
            },
            [
              Validators.required,
              Validators.pattern(REGEX.AGENT_IP_ADDRESS),
              this.uniqueURLValidator(this.CONTROL_LABEL.APP_FQDN),
            ],
          ],
          serviceIPType: {
            value: serviceIPType,
            disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
              this.FORM_FIELDS_TO_DISABLE_KEYS.serviceIPType
            ),
          },
          serviceIP: [
            {
              value: serviceIP,
              disabled:
                serviceIPType ===
                  SERVICE_IP_ADDRESS_INPUT_METHOD_OPTION_KEYS.AUTO ||
                this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
                  this.FORM_FIELDS_TO_DISABLE_KEYS.serviceIP
                ),
            },
            [Validators.required, Validators.pattern(REGEX.IP_ADDRESS_PATTERN)],
          ],
          domainType: [
            {
              value: DOMAIN_TYPE_KEYS.CUSTOM,
              disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
                this.FORM_FIELDS_TO_DISABLE_KEYS.domainType
              ),
            },
          ],
        };
        this.appURLsArray.push(this.fb.group(domain));
      });
    } else {
      this.appOnboardService.updateAppURLs([]);
      const domain = {
        appFqdn: [
          '',
          [
            Validators.required,
            Validators.pattern(REGEX.AGENT_IP_ADDRESS),
            this.uniqueURLValidator(this.CONTROL_LABEL.APP_FQDN),
          ],
        ],
        serviceIPType: SERVICE_IP_ADDRESS_INPUT_METHOD_OPTION_KEYS.AUTO,
        serviceIP: [
          '',
          [Validators.required, Validators.pattern(REGEX.IP_ADDRESS_PATTERN)],
        ],
      };
      this.appURLsArray.push(this.fb.group(domain));
      this.appOnboardService.addAppURL(this.fb.group(domain).getRawValue());
      if (this.isNewApp) {
        this.autoGenerateServiceIPAddress(0);
      }
    }
    this.setControlLabels(APP_ONBOARD_TYPE_OPTION_KEYS.IP);
    this.multipleDomainsAllowed = false;
  }

  initAccessMethodIPForm() {
    const {
      appName = '',
      cloudHostedType = '',
      cspType = '',
      dcID = '',
    } = this.appData;
    this.appDefinitionForm = this.fb.group({
      appName: [appName, Validators.required],
      appOnboardType: [
        {
          value: APP_ONBOARD_TYPE_OPTION_FOR_DROPDOWN.fqdn,
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.appOnboardType
          ),
        },
        Validators.required,
      ],
      cloudHostedType: [
        {
          value: cloudHostedType,
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.cloudType
          ),
        },
      ],
      accessType: [
        {
          value: TCP_UDP_ACCESS_METHOD_FOR_DROPDOWN.service_ip,
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.accessType
          ),
        },
        Validators.required,
      ],
      appURLs: new UntypedFormArray([]),
    });
    if (cloudHostedType === CLOUD_TYPE_OPTION_KEYS.PUBLIC) {
      this.appDefinitionForm.addControl(
        'cspType',
        this.fb.control({
          value: '',
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.cloudType
          ),
        })
      );
    } else {
      this.appDefinitionForm.addControl(
        'dcID',
        this.fb.control({
          value: '',
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.dcID
          ),
        })
      );
    }
    if (cspType) {
      this.cspType.patchValue(CSP_TYPE_FOR_DROPDOWN[cspType]);
    }
    if (dcID) {
      this.getPrivateClouds(true);
    }
    this.appOnboardService.updateAppURLs([]);
    const domain = {
      appFqdn: [
        '',
        [Validators.required, Validators.pattern(REGEX.AGENT_IP_ADDRESS)],
      ],
      serviceIPType: SERVICE_IP_ADDRESS_INPUT_METHOD_OPTION_KEYS.AUTO,
      serviceIP: [
        '',
        [Validators.required, Validators.pattern(REGEX.IP_ADDRESS_PATTERN)],
      ],
    };
    this.appURLsArray.push(this.fb.group(domain));
    this.appOnboardService.addAppURL(this.fb.group(domain).getRawValue());
    this.setControlLabels(APP_ONBOARD_TYPE_OPTION_KEYS.IP);
    this.autoGenerateServiceIPAddress(0);
    this.multipleDomainsAllowed = false;
  }

  initWebAppDefinitionForm(patchDataToForm = false) {
    const {
      appName = '',
      cloudHostedType = '',
      cspType = '',
      dcID = '',
      appURLs = [],
      samlRewrite = { selectedAuthType: AUTH_OPTION_FOR_DROPDOWN.other.key },
    } = this.appData;
    this.appDefinitionForm = this.fb.group({
      appName: [appName, Validators.required],
      appOnboardType: [
        {
          value: APP_ONBOARD_TYPE_OPTION_FOR_DROPDOWN.web,
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.appOnboardType
          ),
        },
        Validators.required,
      ],
      cloudHostedType: [
        {
          value: cloudHostedType,
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.cloudType
          ),
        },
      ],
      appURLs: new UntypedFormArray([]),
    });
    if (cloudHostedType === CLOUD_TYPE_OPTION_KEYS.PUBLIC) {
      this.appDefinitionForm.addControl(
        'cspType',
        this.fb.control({
          value: '',
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.cloudType
          ),
        })
      );
    } else {
      this.appDefinitionForm.addControl(
        'dcID',
        this.fb.control({
          value: '',
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.dcID
          ),
        })
      );
    }
    if (patchDataToForm && appURLs?.length) {
      if (cspType) {
        this.cspType.patchValue(CSP_TYPE_FOR_DROPDOWN[cspType]);
      }
      if (dcID) {
        this.getPrivateClouds(true);
      }
      appURLs?.forEach((appURL) => {
        let { internalDomain = '', appFqdn = '', domainType = '' } = appURL;
        const domain = {
          internalDomain: [
            {
              value: internalDomain,
              disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
                this.FORM_FIELDS_TO_DISABLE_KEYS.internalDomain
              ),
            },
            [
              Validators.required,
              Validators.pattern(
                new RegExp(
                  `(${REGEX.DOMAIN_NAME.source})|(${REGEX.IP_ADDRESS_PATTERN.source})`
                )
              ),
              this.uniqueURLValidator(this.CONTROL_LABEL.INTERNAL_DOMAIN),
            ],
          ],
          appFqdn: [
            {
              value: appFqdn,
              disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
                this.FORM_FIELDS_TO_DISABLE_KEYS.appFqdn
              ),
            },
            [
              Validators.required,
              Validators.pattern(REGEX.DOMAIN_NAME),
              this.uniqueURLValidator(this.CONTROL_LABEL.APP_FQDN),
            ],
          ],
          domainType: [
            {
              value: domainType,
              disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
                this.FORM_FIELDS_TO_DISABLE_KEYS.domainType
              ),
            },
          ],
        };
        if (domainType === DOMAIN_TYPE_KEYS.PROSIMO) {
          this.appOnboardService
            .getProsimoDomain()
            .pipe(takeUntil(this.stopSubs$))
            .subscribe((data) => {
              this.prosimoDomain = data?.domain;
              Object.assign(domain, {
                appFqdn: [
                  {
                    value: appFqdn
                      ? appFqdn.split(this.prosimoDomain)[0]
                      : appFqdn,
                    disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
                      this.FORM_FIELDS_TO_DISABLE_KEYS.appFqdn
                    ),
                  },
                ],
              });
              this.appURLsArray.push(this.fb.group(domain));
              if (this.isProsimoDomainTypeExist()) {
                this.appDefinitionForm.addControl(
                  'samlRewrite',
                  this.fb.group({
                    selectedAuthType: [
                      {
                        value:
                          AUTH_OPTION_FOR_DROPDOWN[
                            samlRewrite?.selectedAuthType
                          ],
                        disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
                          this.FORM_FIELDS_TO_DISABLE_KEYS.samlRewrite
                        ),
                      },
                      Validators.required,
                    ],
                  })
                );
                if (samlRewrite?.selectedAuthType === AUTH_OPTION_KEYS.SAML) {
                  if (
                    this.appData.samlRewrite[METADATA_OPTIONS_KEYS.METADATA]
                  ) {
                    this.samlRewrite.addControl(
                      'metadataType',
                      this.fb.control('metadata')
                    );
                    this.metadataControlLabel = `Enter Metadata`;
                    this.samlRewrite.addControl(
                      'metadataControl',
                      this.fb.control({
                        value:
                          this.appData.samlRewrite[
                            METADATA_OPTIONS_KEYS.METADATA
                          ],
                        disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
                          this.FORM_FIELDS_TO_DISABLE_KEYS.samlRewrite
                        ),
                      })
                    );
                  }
                  if (
                    this.appData.samlRewrite[METADATA_OPTIONS_KEYS.METADATAURL]
                  ) {
                    this.samlRewrite.addControl(
                      'metadataType',
                      this.fb.control('metadataURL')
                    );
                    this.metadataControlLabel = `Enter Metadata URL`;
                    this.samlRewrite.addControl(
                      'metadataControl',
                      this.fb.control({
                        value:
                          this.appData.samlRewrite[
                            METADATA_OPTIONS_KEYS.METADATAURL
                          ],
                        disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
                          this.FORM_FIELDS_TO_DISABLE_KEYS.samlRewrite
                        ),
                      })
                    );
                  }
                }
              }
              this.cdr.markForCheck();
            });
        } else {
          this.appURLsArray.push(this.fb.group(domain));
          if (this.isProsimoDomainTypeExist()) {
            this.appDefinitionForm.addControl(
              'samlRewrite',
              this.fb.group({
                selectedAuthType: [
                  AUTH_OPTION_FOR_DROPDOWN[samlRewrite?.selectedAuthType],
                  Validators.required,
                ],
              })
            );
          }
        }
      });
    } else {
      this.appOnboardService.updateAppURLs([]);
      const domain = {
        internalDomain: [
          '',
          [
            Validators.required,
            Validators.pattern(
              new RegExp(
                `(${REGEX.DOMAIN_NAME.source})|(${REGEX.IP_ADDRESS_PATTERN.source})`
              )
            ),
            this.uniqueURLValidator(this.CONTROL_LABEL.INTERNAL_DOMAIN),
          ],
        ],
        appFqdn: [
          '',
          [
            Validators.required,
            Validators.pattern(REGEX.DOMAIN_NAME),
            this.uniqueURLValidator(this.CONTROL_LABEL.APP_FQDN),
          ],
        ],
        domainType: [DOMAIN_TYPE_KEYS.CUSTOM],
      };
      this.appURLsArray.push(this.fb.group(domain));
      this.appOnboardService.addAppURL(this.fb.group(domain).getRawValue());
    }

    this.setControlLabels(APP_ONBOARD_TYPE_OPTION_KEYS.WEB);
    this.multipleDomainsAllowed = this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
      this.FORM_FIELDS_TO_DISABLE_KEYS.addDomain
    )
      ? false
      : true;
  }

  initVdiAppDefinitionForm(patchDataToForm = false) {
    const {
      appName = '',
      cloudHostedType = '',
      cspType = '',
      dcID = '',
      appURLs = [],
      citrixIP = [],
      samlRewrite = { selectedAuthType: 'other' },
    } = this.appData;
    this.appDefinitionForm = this.fb.group({
      appName: [appName, Validators.required],
      appOnboardType: [
        APP_ONBOARD_TYPE_OPTION_FOR_DROPDOWN.vdi,
        Validators.required,
      ],
      cloudHostedType: [
        {
          value: cloudHostedType,
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.cloudType
          ),
        },
      ],
      ipAllowListEnabled: false,
      appURLs: new UntypedFormArray([]),
    });
    if (citrixIP?.length) {
      this.ipAllowListEnabled.patchValue(true);
      this.appDefinitionForm.addControl(
        'citrixIPControl',
        this.fb?.control([])
      );
      this.appDefinitionForm.addControl('citrixIP', this.fb?.control(citrixIP));
    }
    if (cloudHostedType === CLOUD_TYPE_OPTION_KEYS.PUBLIC) {
      this.appDefinitionForm.addControl(
        'cspType',
        this.fb.control({
          value: '',
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.cloudType
          ),
        })
      );
    } else {
      this.appDefinitionForm.addControl(
        'dcID',
        this.fb.control({
          value: '',
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.dcID
          ),
        })
      );
    }
    if (patchDataToForm && appURLs?.length) {
      if (cspType) {
        this.cspType.patchValue(CSP_TYPE_FOR_DROPDOWN[cspType]);
      }
      if (dcID) {
        this.getPrivateClouds(true);
      }
      appURLs?.forEach((appURL) => {
        const {
          internalDomain = '',
          exceptionDomains = [],
          appFqdn = '',
          domainType = '',
        } = appURL;
        const domain = {
          internalDomain: [
            {
              value: internalDomain,
              disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
                this.FORM_FIELDS_TO_DISABLE_KEYS.internalDomain
              ),
            },
            [
              Validators.required,
              Validators.pattern(REGEX.DOMAIN_NAME),
              this.uniqueURLValidator(this.CONTROL_LABEL.INTERNAL_DOMAIN),
            ],
          ],
          appFqdn: [
            {
              value: appFqdn,
              disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
                this.FORM_FIELDS_TO_DISABLE_KEYS.appFqdn
              ),
            },
            [
              Validators.required,
              this.uniqueURLValidator(this.CONTROL_LABEL.APP_FQDN),
            ],
          ],
          domainType: [
            {
              value: domainType,
              disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
                this.FORM_FIELDS_TO_DISABLE_KEYS.domainType
              ),
            },
          ],
        };
        if (domainType === DOMAIN_TYPE_KEYS.PROSIMO) {
          this.appOnboardService
            .getProsimoDomain()
            .pipe(takeUntil(this.stopSubs$))
            .subscribe((data) => {
              this.prosimoDomain = data?.domain;
              Object.assign(domain, {
                appFqdn: appFqdn.split(this.prosimoDomain)[0],
              });
              this.appURLsArray.push(this.fb.group(domain));
              if (this.isProsimoDomainTypeExist()) {
                this.appDefinitionForm.addControl(
                  'samlRewrite',
                  this.fb.group({
                    selectedAuthType: {
                      value:
                        AUTH_OPTION_FOR_DROPDOWN[samlRewrite?.selectedAuthType],
                      disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
                        this.FORM_FIELDS_TO_DISABLE_KEYS.samlRewrite
                      ),
                    },
                  })
                );
                if (samlRewrite?.selectedAuthType === AUTH_OPTION_KEYS.SAML) {
                  if (
                    this.appData.samlRewrite[METADATA_OPTIONS_KEYS.METADATA]
                  ) {
                    this.samlRewrite.addControl(
                      'metadataType',
                      this.fb.control('metadata')
                    );
                    this.metadataControlLabel = `Enter Metadata`;
                    this.samlRewrite.addControl(
                      'metadataControl',
                      this.fb.control({
                        value:
                          this.appData.samlRewrite[
                            METADATA_OPTIONS_KEYS.METADATA
                          ],
                        disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
                          this.FORM_FIELDS_TO_DISABLE_KEYS.samlRewrite
                        ),
                      })
                    );
                  }
                  if (
                    this.appData.samlRewrite[METADATA_OPTIONS_KEYS.METADATAURL]
                  ) {
                    this.samlRewrite.addControl(
                      'metadataType',
                      this.fb.control('metadataURL')
                    );
                    this.metadataControlLabel = `Enter Metadata URL`;
                    this.samlRewrite.addControl(
                      'metadataControl',
                      this.fb.control(
                        this.appData.samlRewrite[
                          METADATA_OPTIONS_KEYS.METADATAURL
                        ]
                      )
                    );
                  }
                }
              }
              this.cdr.markForCheck();
            });
        } else {
          this.appURLsArray.push(this.fb.group(domain));
          if (this.isProsimoDomainTypeExist()) {
            this.appDefinitionForm.addControl(
              'samlRewrite',
              this.fb.group({
                selectedAuthType: [
                  samlRewrite?.selectedAuthType,
                  Validators.required,
                ],
              })
            );
          }
        }
      });
    } else {
      this.appOnboardService.updateAppURLs([]);
      const domain = {
        internalDomain: [
          '',
          [
            Validators.required,
            Validators.pattern(REGEX.DOMAIN_NAME),
            this.uniqueURLValidator(this.CONTROL_LABEL.INTERNAL_DOMAIN),
          ],
        ],
        appFqdn: [
          '',
          [
            Validators.required,
            this.uniqueURLValidator(this.CONTROL_LABEL.APP_FQDN),
          ],
        ],
        domainType: [DOMAIN_TYPE_KEYS.CUSTOM],
      };
      this.appURLsArray.push(this.fb.group(domain));
      this.appOnboardService.addAppURL(this.fb.group(domain).getRawValue());
    }
    this.setControlLabels(APP_ONBOARD_TYPE_OPTION_KEYS.VDI);
    this.multipleDomainsAllowed = this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
      this.FORM_FIELDS_TO_DISABLE_KEYS.addDomain
    )
      ? false
      : true;
  }

  initCloudSvcAppDefinitionForm(patchDataToForm = false) {
    const { appName = '', appURLs = [] } = this.appData;
    this.appDefinitionForm = this.fb.group({
      appName: [appName, Validators.required],
      appOnboardType: [
        {
          value: APP_ONBOARD_TYPE_OPTION_FOR_DROPDOWN['cloud-svc'],
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.appOnboardType
          ),
        },

        Validators.required,
      ],
      cloudService: [
        { value: CLOUD_SERVICE_KEYS.AMAZON_S3, disabled: true },
        Validators.required,
      ],
      cspType: [{ value: CSP_TYPE_OPTIONS[0], disabled: true }],
      appURLs: new UntypedFormArray([]),
    });
    this.appOnboardService.updateAppDCAppCloudID('');
    this.appOnboardService.updateAppCSPType(CLOUD_TYPES.AWS);
    if (patchDataToForm && appURLs?.length) {
      appURLs?.forEach((appURL) => {
        const { appFqdn = '', subdomainIncluded = false } = appURL;
        const domain = {
          appFqdn: [
            {
              value: appFqdn,
              disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
                this.FORM_FIELDS_TO_DISABLE_KEYS.appFqdn
              ),
            },
            [
              Validators.required,
              Validators.pattern(REGEX.DOMAIN_NAME),
              this.uniqueURLValidator(this.CONTROL_LABEL.APP_FQDN),
            ],
          ],
          subdomainIncluded: [
            {
              value: subdomainIncluded,
              disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
                this.FORM_FIELDS_TO_DISABLE_KEYS.subdomainIncluded
              ),
            },
          ],
          domainType: [
            {
              value: DOMAIN_TYPE_KEYS.CUSTOM,
              disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
                this.FORM_FIELDS_TO_DISABLE_KEYS.domainType
              ),
            },
          ],
        };
        this.appURLsArray.push(this.fb.group(domain));
      });
    } else {
      this.appOnboardService.updateAppURLs([]);
      const domain = {
        appFqdn: [
          '',
          [
            Validators.required,
            Validators.pattern(REGEX.DOMAIN_NAME),
            this.uniqueURLValidator(this.CONTROL_LABEL.APP_FQDN),
          ],
          ,
        ],
        subdomainIncluded: false,
        domainType: [DOMAIN_TYPE_KEYS.CUSTOM],
      };
      this.appURLsArray.push(this.fb.group(domain));
      this.appOnboardService.addAppURL(this.fb.group(domain).getRawValue());
    }
    this.setControlLabels(APP_ONBOARD_TYPE_OPTION_KEYS.CLOUD_SVC);
    this.multipleDomainsAllowed = this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
      this.FORM_FIELDS_TO_DISABLE_KEYS.addDomain
    )
      ? false
      : true;
    this.subdomainIncludedTooltip = TOOLTIP_MESSAGE.BUCKETS_INCLUDED;
  }

  initJumpboxAppDefinitionForm(patchDataToForm = false) {
    const {
      appName = '',
      cloudHostedType = '',
      cspType = '',
      dcID = '',
      appURLs = [],
    } = this.appData;
    this.appDefinitionForm = this.fb.group({
      appName: [appName, Validators.required],
      appOnboardType: [
        APP_ONBOARD_TYPE_OPTION_FOR_DROPDOWN.jumpbox,
        Validators.required,
      ],
      cloudHostedType: [
        {
          value: cloudHostedType,
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.cloudType
          ),
        },
      ],
      appURLs: new UntypedFormArray([]),
    });
    if (cloudHostedType === CLOUD_TYPE_OPTION_KEYS.PUBLIC) {
      this.appDefinitionForm.addControl(
        'cspType',
        this.fb.control({
          value: '',
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.cloudType
          ),
        })
      );
    } else {
      this.appDefinitionForm.addControl(
        'dcID',
        this.fb.control({
          value: '',
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.dcID
          ),
        })
      );
    }
    if (patchDataToForm && appURLs?.length) {
      if (cspType) {
        this.cspType.patchValue(CSP_TYPE_FOR_DROPDOWN[cspType]);
      }
      if (dcID) {
        this.getPrivateClouds(true);
      }
      appURLs?.forEach((appURL) => {
        const { appFqdn = '' } = appURL;
        const domain = {
          appFqdn: [
            {
              value: appFqdn,
              disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
                this.FORM_FIELDS_TO_DISABLE_KEYS.appFqdn
              ),
            },
            [Validators.required, Validators.pattern(REGEX.DOMAIN_NAME)],
          ],
          domainType: [
            {
              value: DOMAIN_TYPE_KEYS.CUSTOM,
              disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
                this.FORM_FIELDS_TO_DISABLE_KEYS.domainType
              ),
            },
          ],
        };
        this.appURLsArray.push(this.fb.group(domain));
      });
    } else {
      this.appOnboardService.updateAppURLs([]);
      const domain = {
        appFqdn: [
          '',
          [Validators.required, Validators.pattern(REGEX.DOMAIN_NAME)],
        ],
        domainType: [DOMAIN_TYPE_KEYS.CUSTOM],
      };
      this.appURLsArray.push(this.fb.group(domain));
      this.appOnboardService.addAppURL(this.fb.group(domain).getRawValue());
    }
    this.setControlLabels(APP_ONBOARD_TYPE_OPTION_KEYS.JUMPBOX);
    this.multipleDomainsAllowed = false;
  }

  patchPrivateCloudData(cloudHostedType: string) {
    if (cloudHostedType === CLOUD_TYPE_OPTION_KEYS.PRIVATE) {
      this.onChangeCloudHostedType(CLOUD_TYPE_OPTION_KEYS.PRIVATE, true);
    }
  }

  uniqueURLValidator(controlName: string = ''): ValidatorFn | null {
    return (control: AbstractControl): ValidationErrors | null => {
      const allDomains = [];
      const controlValue = control.value;
      const appURLs = this.appDefinitionForm.getRawValue().appURLs;
      const controlIndex = this.findControlIndexInFormArray(
        controlName,
        controlValue
      );
      appURLs?.forEach((appURL, i) => {
        if (controlName === this.CONTROL_LABEL.APP_FQDN) {
          if (appURL?.appFqdn) {
            allDomains.push(appURL?.appFqdn);
          }
          if (i !== controlIndex) {
            if (appURL?.internalDomain) {
              allDomains.push(appURL?.internalDomain);
            }
          }
        }
        if (controlName === this.CONTROL_LABEL.INTERNAL_DOMAIN) {
          if (i !== controlIndex) {
            if (appURL?.appFqdn) {
              allDomains.push(appURL?.appFqdn);
            }
          }
          if (appURL?.internalDomain) {
            allDomains.push(appURL?.internalDomain);
          }
        }
      });
      const isDuplicate =
        allDomains?.filter((item) => item === controlValue).length > 1;
      if (isDuplicate) {
        return { notUnique: true };
      }
      return null;
    };
  }

  findControlIndexInFormArray(controlName: string, controlValue: string) {
    const appURLs = this.appDefinitionForm.getRawValue().appURLs;
    const index = appURLs.findIndex(
      (appUrl) => appUrl[controlName] === controlValue
    );
    return index;
  }

  onUpdateInternalDomain(domainIndex: number) {
    const internalDomain = this.getdomainFormGroupAtIndex(domainIndex).get(
      this.CONTROL_LABEL.INTERNAL_DOMAIN
    );
    if (internalDomain.valid) {
      this.getdomainFormGroupAtIndex(domainIndex)
        .get('appFqdn')
        .patchValue(internalDomain?.value);
    }
  }

  setControlLabels(appOnboardType: string) {
    switch (appOnboardType) {
      case APP_ONBOARD_TYPE_OPTION_KEYS.FQDN:
        this.controlLabels = {
          appFqdn: 'Enter Domain Name',
          appFqdnPlaceholder: 'Enter Domain',
          exceptionDomains: 'Enter Excluded Subdomains',
        };
        break;
      case APP_ONBOARD_TYPE_OPTION_KEYS.IP:
        this.controlLabels = {
          appFqdn: 'Enter Internal IP Address',
          appFqdnPlaceholder: 'Enter IP',
          serviceIPType: 'Select Service IP',
          serviceIP: 'Enter Service IP Address',
        };
        break;
      case APP_ONBOARD_TYPE_OPTION_KEYS.WEB:
      case APP_ONBOARD_TYPE_OPTION_KEYS.VDI:
        this.controlLabels = {
          internalDomain: 'Enter Internal Domain',
          appFqdnPlaceholder: 'Enter Domain',
          exceptionDomains: 'Enter Excluded Subdomains',
          appFqdn: 'Enter Custom Domain',
        };
        break;
      case APP_ONBOARD_TYPE_OPTION_KEYS.CLOUD_SVC:
        this.controlLabels = {
          appFqdn: 'Enter Domain for S3 access',
          appFqdnPlaceholder: 'Enter Domain',
          subdomainIncluded: 'Include All Buckets',
        };
        break;
      case APP_ONBOARD_TYPE_OPTION_KEYS.JUMPBOX:
        this.controlLabels = {
          appFqdn: 'Enter Domain Name',
          appFqdnPlaceholder: 'Enter Domain',
        };
        break;
    }
  }

  onAddDomain() {
    let domain = {};
    switch (this.appOnboardType?.value?.key) {
      case APP_ONBOARD_TYPE_OPTION_KEYS.FQDN:
        if (
          this.accessType?.value?.key === TCP_UDP_ACCESS_METHOD_OPTION_KEYS.FQDN
        ) {
          domain = {
            appFqdn: [
              '',
              [
                Validators.required,
                Validators.pattern(REGEX.DOMAIN_NAME),
                this.uniqueURLValidator(this.CONTROL_LABEL.APP_FQDN),
              ],
            ],
            domainType: [DOMAIN_TYPES.CUSTOM],
          };
        }
        break;
      case APP_ONBOARD_TYPE_OPTION_KEYS.IP:
        break;
      case APP_ONBOARD_TYPE_OPTION_KEYS.WEB:
        domain = {
          internalDomain: [
            '',
            [
              Validators.required,
              Validators.pattern(REGEX.DOMAIN_NAME),
              this.uniqueURLValidator(this.CONTROL_LABEL.INTERNAL_DOMAIN),
            ],
          ],
          appFqdn: ['', [Validators.required]],
          domainType: [DOMAIN_TYPES.CUSTOM],
        };
        break;
      case APP_ONBOARD_TYPE_OPTION_KEYS.VDI:
        domain = {
          internalDomain: [
            '',
            [
              Validators.required,
              Validators.pattern(REGEX.DOMAIN_NAME),
              this.uniqueURLValidator(this.CONTROL_LABEL.INTERNAL_DOMAIN),
            ],
          ],
          appFqdn: ['', [Validators.required]],
          domainType: [DOMAIN_TYPES.CUSTOM],
        };
        break;
      case APP_ONBOARD_TYPE_OPTION_KEYS.CLOUD_SVC:
        domain = {
          appFqdn: [
            '',
            [
              Validators.required,
              Validators.pattern(REGEX.DOMAIN_NAME),
              this.uniqueURLValidator(this.CONTROL_LABEL.APP_FQDN),
            ],
          ],
          subdomainIncluded: false,
          domainType: [DOMAIN_TYPES.CUSTOM],
        };
        break;
      case APP_ONBOARD_TYPE_OPTION_KEYS.JUMPBOX:
        break;
    }
    this.appURLsArray.push(this.fb.group(domain));
    this.appOnboardService.addAppURL(this.fb.group(domain).getRawValue());
    this.onUpdateDomain();
  }

  onChangeDomainype(domainType: string, domainIndex: number) {
    this.getdomainFormGroupAtIndex(domainIndex).get('appFqdn').reset();
    if (domainType === DOMAIN_TYPE_KEYS.CUSTOM) {
      if (!this.isProsimoDomainTypeExist()) {
        this.appDefinitionForm.removeControl('samlRewrite');
      }
      const appFqdnControl =
        this.getdomainFormGroupAtIndex(domainIndex).get('appFqdn');
      appFqdnControl.clearValidators();
      appFqdnControl.addValidators([
        Validators.required,
        Validators.pattern(REGEX.DOMAIN_NAME),
      ]);
    } else {
      const appFqdnControl =
        this.getdomainFormGroupAtIndex(domainIndex).get('appFqdn');
      appFqdnControl.clearValidators();
      appFqdnControl.addValidators(Validators.required);
      this.getProsimoDomain();
      if (!this.appDefinitionForm.get('samlRewrite')) {
        this.appDefinitionForm.addControl(
          'samlRewrite',
          this.fb.group({
            selectedAuthType: [
              AUTH_OPTION_FOR_DROPDOWN.other,
              Validators.required,
            ],
          })
        );
      }
    }
    this.cdr.markForCheck();
  }

  isProsimoDomainTypeExist() {
    const isProsimoDomainTypeExist = this.appURLsArray.controls.some(
      (domainDetailsGroup) => {
        const domainTypeControl = domainDetailsGroup.get('domainType');
        return (
          domainTypeControl &&
          domainTypeControl.value === DOMAIN_TYPE_KEYS.PROSIMO
        );
      }
    );
    return isProsimoDomainTypeExist;
  }

  onAuthMethodChange(authMethod: any) {
    if (authMethod?.key === AUTH_OPTION_KEYS.SAML) {
      this.samlRewrite.addControl('metadataType', this.fb.control('metadata'));
      this.metadataControlLabel = `Enter Metadata`;
      this.samlRewrite.addControl('metadataControl', this.fb.control(''));
    }
  }

  onChangeMetadataType(metadataType: string) {
    this.metadataControl?.reset();
    if (metadataType === METADATA_OPTIONS_KEYS.METADATAURL) {
      this.metadataControlLabel = `Enter Metadata URL`;
    } else {
      this.metadataControlLabel = `Enter Metadata`;
    }
    this.cdr.markForCheck();
  }

  onDeleteDomain(index: number) {
    this.appURLsArray.removeAt(index);
    this.appOnboardService.removeAppURL(index);
  }

  onAppDefinitionFormValueChanges() {
    this.appDefinitionForm.valueChanges
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (value) => {
          let {
            appName = '',
            appOnboardType = '',
            accessType = '',
            cloudService = '',
            citrixIP = [],
            samlRewrite = {},
          } = this.appDefinitionForm.getRawValue();
          if (samlRewrite?.selectedAuthType?.key === AUTH_OPTION_KEYS.SAML) {
            if (samlRewrite.metadataType === METADATA_OPTIONS_KEYS.METADATA) {
              delete samlRewrite.metadataType;
              Object.assign(samlRewrite, {
                metadata: samlRewrite?.metadataControl,
              });
            } else {
              delete samlRewrite.metadataType;
              Object.assign(samlRewrite, {
                metadataURL: samlRewrite?.metadataControl,
              });
            }
            delete samlRewrite.metadataControl;
          }
          const appSettingsData = {
            appName,
            appOnboardType:
              accessType?.key === TCP_UDP_ACCESS_METHOD_OPTION_KEYS.SERVICE_IP
                ? APP_ONBOARD_TYPE_OPTION_KEYS.IP
                : appOnboardType?.key,
          };
          if (accessType?.key) {
            Object.assign(appSettingsData, { accessType: accessType?.key });
          }
          if (samlRewrite.selectedAuthType) {
            Object.assign(samlRewrite, {
              selectedAuthType: samlRewrite?.selectedAuthType?.key,
            });
            Object.assign(appSettingsData, { samlRewrite });
          }

          if (citrixIP?.length) {
            Object.assign(appSettingsData, { citrixIP });
          }

          if (cloudService) {
            Object.assign(appSettingsData, { cloudService });
          }
          this.appOnboardService.updateApplicationConfig(appSettingsData);
          this.onUpdateDomain();
        },
      });
  }

  onUpdateDomain() {
    const secondStepAppURLs = this.appOnboardService.getAppDomains();
    if (!this.appDefinitionForm.valid) {
      return;
    }
    const {
      appURLs = [],
      appOnboardType = '',
      accessType = '',
    } = this.appDefinitionForm.getRawValue();
    appURLs.forEach((url) => {
      if (url.domainType === DOMAIN_TYPE_KEYS.PROSIMO && url?.appFqdn) {
        url.appFqdn = `${url.appFqdn}${this.prosimoDomain}`;
      }
      if (
        accessType?.key === TCP_UDP_ACCESS_METHOD_OPTION_KEYS.SERVICE_IP ||
        appOnboardType?.key === APP_ONBOARD_TYPE_OPTION_KEYS.IP
      ) {
        url.appFqdn = REGEX.IP_ADDRESS_PATTERN.test(url.appFqdn)
          ? `${url.appFqdn}/32`
          : url.appFqdn;
      }
    });
    if (
      secondStepAppURLs?.length === appURLs?.length &&
      secondStepAppURLs?.length !== 0 &&
      appURLs?.length !== 0
    ) {
      secondStepAppURLs.forEach((item, i) => {
        Object.assign(item, appURLs[i]);
      });
      this.appOnboardService.updateApplicationConfig({
        appURLs: secondStepAppURLs,
      });
    }
  }

  autoGenerateServiceIPAddress(domainIndex: number) {
    this.appOnboardService
      .autoGenerateServiceIP()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe((data) => {
        const serviceIPControl =
          this.getdomainFormGroupAtIndex(domainIndex).get('serviceIP');
        serviceIPControl.patchValue(data);
        serviceIPControl.disable();
      });
  }

  updateServiceIPValidateLoaderConfig(status = false, message = '', type = '') {
    this.serviceIPValidateLoader$ = of({
      status,
      message,
      type,
    } as LoaderConfig);
    this.cdr.markForCheck();
  }

  updateServiceIP() {
    this.updateServiceIPValidateLoaderConfig(false);
  }

  validateServiceIPAddress(domainIndex: number) {
    const serviceIP =
      this.getdomainFormGroupAtIndex(domainIndex).get('serviceIP');
    const paylaod = { serviceIP: serviceIP?.value, id: '' };
    this.updateServiceIPValidateLoaderConfig(
      true,
      'Validating IP Address...',
      LOADER_CONFIG_TYPES.LOADER
    );
    this.appOnboardService
      .validateServiceIP(paylaod)
      .pipe(takeUntil(this.stopSubs$))
      .subscribe((data) => {
        this.updateServiceIPValidateLoaderConfig(false);
        const { isValid = true, validationMessage = '' } = data;
        if (!isValid) {
          serviceIP.setErrors({ customError: validationMessage });
          this.updateServiceIPValidateLoaderConfig(
            true,
            validationMessage,
            LOADER_CONFIG_TYPES.ERROR
          );
          this.cdr.markForCheck();
        } else {
          this.updateServiceIPValidateLoaderConfig(
            true,
            'Validated',
            LOADER_CONFIG_TYPES.SUCCESS
          );
        }
      });
  }

  onChangeCloudHostedType(cloudHostedType: string, patchDataToForm = false) {
    this.appOnboardService.updateAppCloudHostedType(cloudHostedType);
    if (cloudHostedType === CLOUD_TYPE_OPTION_KEYS.PRIVATE) {
      this.appDefinitionForm.removeControl('cspType');
      this.appDefinitionForm.addControl('dcID', this.fb.control(''));
      this.getPrivateClouds(patchDataToForm);
    } else {
      this.appDefinitionForm.removeControl('dcID');
      this.appDefinitionForm.addControl('cspType', this.fb.control(''));
      this.appOnboardService.updateAppDCAppCloudID('');
    }
  }

  onCSPTypeChange(cspType: any) {
    this.appOnboardService.updateAppCSPType(cspType?.key);
  }

  onChangePrivateCloudAccount(cloudAccount: any, patchData = false) {
    const { id = '' } = cloudAccount;
    this.appOnboardService.updateAppDCAppCloudID(id);
    this.appOnboardService.updateAppCSPType('');
  }

  getPrivateClouds(patchDataToForm = false) {
    this.updateCloudLoaderConfig(true, 'Loading Private Cloud Accounts...');
    this.appOnboardService
      .getPrivateClouds()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe(
        (privateCloudAccounts) => {
          this.updateCloudLoaderConfig(false);
          const modifiedAccounts = privateCloudAccounts
            .map((cloud) => {
              return Object.assign(cloud, {
                displayName: cloud?.name,
              });
            })
            .sort((a, b) => a.displayName.localeCompare(b.displayName));
          this.setSelectSearchConfigForPrivateCloudAccounts(modifiedAccounts);
          if (patchDataToForm) {
            const selectedPrivateCloud = modifiedAccounts.find(
              (cloud) => cloud.id === this.appData.dcID
            );
            this.dcID?.patchValue(selectedPrivateCloud);
          }
        },
        (error) => {
          this.updateCloudLoaderConfig(
            true,
            'Unable to fetch cloud accounts...',
            LOADER_CONFIG_TYPES.ERROR
          );
        }
      );
  }

  updateCloudLoaderConfig(status = false, message = '', type = '') {
    this.cloudLoader$ = of({
      status,
      message,
      type,
    } as LoaderConfig);
    this.cdr.markForCheck();
  }

  get appOnboardType() {
    return this.appDefinitionForm.get('appOnboardType');
  }

  get accessType() {
    return this.appDefinitionForm.get('accessType');
  }

  get cloudHostedType() {
    return this.appDefinitionForm.get('cloudHostedType');
  }

  get cspType() {
    return this.appDefinitionForm.get('cspType');
  }

  get dcID() {
    return this.appDefinitionForm.get('dcID');
  }

  get cloudService() {
    return this.appDefinitionForm.get('cloudService');
  }

  get citrixIPControl() {
    return this.appDefinitionForm.get('citrixIPControl');
  }

  get citrixIP() {
    return this.appDefinitionForm.get('citrixIP');
  }

  get ipAllowListEnabled() {
    return this.appDefinitionForm.get('ipAllowListEnabled');
  }

  get metadataType() {
    return this.samlRewrite?.get('metadataType');
  }

  get metadataControl() {
    return this.samlRewrite?.get('metadataControl');
  }

  get samlRewrite() {
    return this.appDefinitionForm.get('samlRewrite') as UntypedFormGroup;
  }

  get selectedAuthType() {
    return this.samlRewrite.get('selectedAuthType');
  }

  get appURLsArray(): UntypedFormArray {
    return this.appDefinitionForm.get('appURLs') as UntypedFormArray;
  }

  getdomainFormGroupAtIndex(domainIndex: number) {
    return this.appURLsArray.at(domainIndex) as UntypedFormGroup;
  }

  showErrorMessage(popupConfig, action: (...args: any[]) => void) {
    const dialogData = {
      ...popupConfig,
      isInnerHTML: true,
    };

    const injector = this.appUtil?.createInjector(
      DIALOG_TOKEN.INJECTOR_TOKEN_DATA.DIALOG_DATA_TOKEN.NAME,
      dialogData
    );

    const componentRef = this.appUtil?.initializeDeleteOverlay(
      DeleteDialogComponent,
      injector,
      this.uiThemeMode,
      '340px'
    );
    (componentRef.instance as DeleteDialogComponent)?.closeDialogEvent
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          action(response);
        },
        error: (err) => {
          console.warn(err);
        },
        complete: () => {
          this.appUtil?.destroyOverlay();
        },
      });
  }

  setDrawerFooter() {
    this.onboardTemplateService
      .getDeleteResponse()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (response) => {
          if (response.action === DeleteAction.DELETE) {
            this.appOnboardService.deleteApplication(
              this.appData,
              this.uiThemeMode
            );
          } else if (response.action === DeleteAction.OFFBOARD) {
            this.appOnboardService.offboardApplication(
              response.confirmation,
              this.appData,
              this.uiThemeMode
            );
          }
        },
      });
    this.appOnboardService.setCreateTimeAndDeleteConfig(this.appData);
  }

  ngOnDestroy(): void {
    this.stopSubs$.next();
    this.stopSubs$.complete();
  }
}
