import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AlertModel } from './alert.model';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private alertConfig$ = new Subject<AlertModel>();

  constructor() {}

  setAlertConfig(alertConfig: AlertModel) {
    this.alertConfig$.next(alertConfig);
  }

  getAlertConfig(): Observable<AlertModel> {
    return this.alertConfig$.asObservable();
  }

  resetAlertConfig() {
    this.setAlertConfig(null);
  }
}
