import { Component, OnInit, OnDestroy } from '@angular/core';

import { PasswordMeterService } from './password-meter.service';

import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-password-meter',
  templateUrl: './password-meter.component.html',
  styleUrls: ['./password-meter.component.scss'],
})
export class PasswordMeterComponent implements OnInit, OnDestroy {
  readonly PASSWORD_STRENGTH = ['bad', 'moderate', 'good', 'strong'];
  passwordStrength$ = new BehaviorSubject<string[]>([
    this.PASSWORD_STRENGTH[0],
  ]);
  subscriptions = [];

  constructor(private passwordMeterService: PasswordMeterService) {}

  ngOnInit() {
    /**
     * Subscribe password input changes. Set the criteria' to true if it passes regex test.
     */
    const passwordTextSub = this.passwordMeterService
      .getPasswordText()
      .pipe(debounceTime(100), distinctUntilChanged())
      .subscribe((passwordText) => {
        this.passwordStrength$.next(this.setPasswordStrength(passwordText));
      });
    this.subscriptions.push(passwordTextSub);
  }

  /**
   * @description
   *         - Validates password text against regex and sets the property to true if the regex test pass
   * @param passwordText
   *         - The password input text
   * @returns string[]
   *         - either PASSWORD_STRENGTH or a subset of PASSWORD_STRENGTH.
   *         - this dependends on number of criteria that matched
   */
  setPasswordStrength(passwordText: string): string[] {
    if (!passwordText.length) {
      return [this.PASSWORD_STRENGTH[0]];
    }
    const passwordRulesStatus = {
      digits: /\d/.test(passwordText),
      lower: /[a-z]/.test(passwordText),
      upper: /[A-Z]/.test(passwordText),
    };

    const statisfiedStatusCount = Object.keys(passwordRulesStatus).filter(
      (key) => passwordRulesStatus[key]
    ).length;
    return this.PASSWORD_STRENGTH.slice(0, statisfiedStatusCount + 1);
  }

  ngOnDestroy() {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }
}
