<div id="groupSelectPill__container">
  <div
    class="row no-gutters select__container"
    *ngIf="config && config?.groups"
  >
    <div class="dropDown__container">
      <div
        class="selectPill__label"
        [ngClass]="{ displayOneLine: displayOneLine }"
      >
        {{ config?.label }}
      </div>
      <mat-form-field
        class="selectPill__content"
        [ngClass]="{
          'no-form-field-underline no-dropdown-arrow':
            selectedList?.length >= 1 || !!selectedItem
        }"
      >
        <mat-select
          class="selectPill__select"
          [formControl]="selectControl"
          [multiple]="multiSelect"
          disableRipple
          [compareWith]="compareFn"
        >
          <mat-select-trigger *ngIf="displayTrigger">
            <span class="selected__txt" *ngIf="!displayOneLine">
              {{ selectedList?.length }} {{ config.title }} selected
            </span>
            <div
              *ngIf="displayOneLine && selectedList?.length >= 1"
              class="d-flex align-items-center justify-content-between"
            >
              <div
                *ngIf="config?.targetKey"
                class="first-select-trigger-text selectPill selectPill--modified"
                [title]="selectedList[0][config.targetKey]"
                matTooltipPosition="below"
              >
                {{ selectedList[0][config.targetKey] }}
              </div>
              <div
                *ngIf="!config?.targetKey"
                class="first-select-trigger-text selectPill selectPill--modified"
                [title]="selectedList[0]"
                matTooltipPosition="below"
              >
                {{ selectedList[0] }}
              </div>
              <div
                class="mr-1 additional-select-trigger-text more"
                *ngIf="selectedList?.length > 1"
              >
                + {{ selectedList?.length - 1 }} more
              </div>

              <div class="ml-1 update__txt">Update</div>
            </div>
            <div
              *ngIf="selectedItem"
              class="d-flex align-items-center justify-content-between"
            >
              <div
                *ngIf="config?.targetKey"
                class="first-select-trigger-text selectPill selectPill--modified"
                [title]="selectedItem[config.targetKey]"
                matTooltipPosition="below"
              >
                {{ selectedItem[config.targetKey] }}
              </div>
              <div
                *ngIf="!config?.targetKey"
                class="first-select-trigger-text selectPill selectPill--modified"
                [title]="selectedItem"
                matTooltipPosition="below"
              >
                {{ selectedItem }}
              </div>
              <div class="ml-1 update__txt">Update</div>
            </div>
          </mat-select-trigger>
          <ng-container *ngIf="displayGroup">
            <mat-optgroup *ngFor="let group of config?.groups">
              <div class="option-group d-flex align-items-center">
                <img
                  *ngIf="group.groupIconURL"
                  class="option-group-logo pr-2"
                  [src]="group?.groupIconURL"
                />
                <div class="flex-grow-1 option-group-label">
                  {{ group[config?.targetKey] }}
                </div>
              </div>
              <ng-container *ngFor="let val of group.options">
                <mat-option
                  *ngIf="config?.targetKey && val"
                  [value]="val"
                  [title]="val[config?.targetKey]"
                >
                  {{ val[config?.targetKey] }}
                </mat-option>
                <mat-option
                  *ngIf="!config?.targetKey"
                  [value]="val"
                  [title]="val"
                >
                  {{ val }}
                </mat-option>
              </ng-container>
            </mat-optgroup>
          </ng-container>

          <ng-container *ngIf="!displayGroup">
            <mat-option *ngFor="let group of config?.groups" [value]="group">
              <div class="d-flex justify-content-between align-items-center">
                <div class="flex-grow-1">{{ group[config?.targetKey] }}</div>
                <i
                  class="material-icons info"
                  [matMenuTriggerFor]="menu"
                  (click)="$event.stopPropagation()"
                  >info</i
                >
                <mat-menu #menu="matMenu">
                  <div
                    mat-menu-item
                    *ngFor="let option of group.additionalInfo"
                  >
                    {{ option[config?.targetKey] }}
                  </div>
                </mat-menu>
              </div>
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="pills__container" *ngIf="!displayOneLine && !hidePillList">
    <div class="select__pill" *ngFor="let item of selectedList; index as i">
      <div class="pill__action" (click)="removeSelectedItem(item, i)">
        <i class="material-icons close__icon"> close </i>
      </div>
      <div
        class="pill__label"
        [matTooltip]="item[config?.targetKey]"
        matTooltipPosition="below"
      >
        {{ item[config?.targetKey] }}
      </div>
    </div>
  </div>
</div>
