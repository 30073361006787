import { SelectionObjectWithArray } from '../../../common/util/constants';
import { Store, StoreConfig } from '@datorama/akita';

export interface AlertsState {
  selectedAlerts?: Array<SelectionObjectWithArray>;
}

export function createInitialState(): AlertsState {
  return {
    selectedAlerts: null,
  };
}

@StoreConfig({ name: 'alerts', resettable: true })
export class AlertsStore extends Store<AlertsState> {
  constructor() {
    super(createInitialState());
  }
}

export const alertsStore = new AlertsStore();
