export type RouteOperState = {
  afi?: number;
  safi?: number;
  oPrefix?: string;
  isAuto: boolean;
  nwID?: string;
};

export type Route = {
  prefix?: IPNet;
  nexthop?: string;
  nsID: number;
  exportedNS?: number;
  oPrefix?: string;
};

export type IPNet = {
  IP: string;
  mask: string;
};

export const WellKnownCommunities = {
  '65535:65281': 'NO_EXPORT',
  '65535:65282': 'NO_ADVERTISE',
  '65535:65283': 'NO_EXPORT_SUBCONFED',
  '65535:65284': 'NO_PEER',
  '.+': 'ANY',
};
