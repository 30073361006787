import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  ILightboxFormModel,
  LightboxBtnType,
  LightBoxStatusMessage,
  LightBoxStatusType,
} from '@app/dashboard/diagnose/diagnostics/lightbox-form-template/lightbox-model';
import { LightBoxTemplateService } from '@app/dashboard/diagnose/diagnostics/lightbox-form-template/lightbox-form-template.service';
import { AppUtil } from '@app/common/util/app-util';

@Component({
  selector: 'app-lightbox-form-template',
  templateUrl: './lightbox-form-template.component.html',
  styleUrls: ['./lightbox-form-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LightboxFormTemplateComponent implements OnDestroy {
  CANCEL_BUTTON_TYPE = LightboxBtnType.CANCEL;
  dataConfig: ILightboxFormModel;
  header: string;
  addPadding = true;
  @Input() set data(data: ILightboxFormModel) {
    const { cancelButtonType = LightboxBtnType.CANCEL, addPadding = true } =
      data;
    if (data) {
      this.dataConfig = data;
      this.CANCEL_BUTTON_TYPE = cancelButtonType;
      this.addPadding = addPadding;
    }
  }
  @Input() headerClass?: string;
  @Input() subHeaderClass?: string;
  @Output() emitBtnAction = new EventEmitter();
  @ViewChild('panel') closeablePanel: ElementRef<any>;
  @HostListener('document:keydown.escape', ['$event'])
  onKeyEscapePress() {
    this.appUtil.setLightboxDisplayState(false);
  }
  isOpen = true;
  statusMessage$: BehaviorSubject<LightBoxStatusMessage | null> =
    new BehaviorSubject(null);
  subscriptions = [];
  COPY_LIGHTBOX_STATUS_TYPES = LightBoxStatusType;
  COPY_LIGHTBOX_BTN_TYPES = LightboxBtnType;
  cancelDisabled = false;
  constructor(
    private lightboxTemplateService: LightBoxTemplateService,
    private appUtil: AppUtil,
    private cdr: ChangeDetectorRef
  ) {
    this.header = '';
    this.subscriptions.push(
      this.lightboxTemplateService
        .getLightBoxStatusMessage()
        .subscribe((message) => {
          this.statusMessage$.next(message);
          if (message.type === LightBoxStatusType.ERROR) {
            if (!message?.stayOpened) {
              setTimeout(
                () => this.dismissErrorMessage(),
                message?.waitTime ? message.waitTime : 2000
              );
            }
          } else {
            if (!message?.stayOpened) {
              setTimeout(
                () =>
                  this.emitActionBtnDetails(
                    null,
                    this.COPY_LIGHTBOX_BTN_TYPES.CLOSE.code
                  ),
                message?.waitTime ? message.waitTime : 2000
              );
            } else {
              setTimeout(
                () => this.dismissErrorMessage(),
                message?.waitTime ? message.waitTime : 2000
              );
            }
          }
        }),
      this.lightboxTemplateService.getDisabledButtons().subscribe((buttons) => {
        this.dataConfig.buttonConfigs = buttons;
        this.cdr.markForCheck();
      }),
      this.lightboxTemplateService
        .getCancelBtnDisabled()
        .subscribe((disabled) => {
          this.cancelDisabled = disabled;
          this.cdr.markForCheck();
        }),
      this.lightboxTemplateService
        .getLightBoxHeader()
        .subscribe((header: string) => {
          this.header = header || '';
          this.cdr.markForCheck();
        })
    );
  }

  closePanel() {
    this.appUtil.setLightboxDisplayState(false);
  }

  get panel() {
    return this.closeablePanel;
  }

  emitActionBtnDetails(event: any, btnConfig: any) {
    this.emitBtnAction.emit(btnConfig);
    if (
      btnConfig === this.COPY_LIGHTBOX_BTN_TYPES.CANCEL.code ||
      btnConfig === this.COPY_LIGHTBOX_BTN_TYPES.CLOSE.code
    ) {
      this.appUtil.setLightboxDisplayState(false);
    }
    this.appUtil.setLightBoxResponse(btnConfig);
  }

  dismissErrorMessage() {
    this.statusMessage$.next(null);
  }

  ngOnDestroy() {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }
}
