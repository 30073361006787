import { AppUtil } from '@app/common/util/app-util';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import {
  MENU_NAMES_TO_BE_REMOVED_FOR_MCN,
  MENU_NAMES_TO_BE_REMOVED_FOR_ZTNA,
  MENU_NAMES_TO_HIDE_IN_PROD_ENV,
  NavigationBarModel,
  NavMenuItem,
} from './navigation-bar.config';
import {
  Router,
  NavigationEnd,
  NavigationStart,
  NavigationError,
  Event,
  NavigationCancel,
} from '@angular/router';
import { NavigationBarService } from './navigation-bar.service';
import {
  IMAGE_PATHS,
  MENU_NAMES,
  SUBSCRIPTION_TYPES,
} from '@app/common/util/constants';
import { UserBehaviorInsightsCustomEventInfo } from '@prosimoio/services';
import { PDashLocalStoreUtil } from '@app/state/web-pdash.util';
@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationBarComponent implements OnInit, OnDestroy {
  selectedMenuItem: NavMenuItem;
  menuItems: Array<NavMenuItem>;
  routesMap: Map<string, NavMenuItem>;
  private subscriptions = [];
  public navbarConfig$ = new ReplaySubject<NavigationBarModel>();
  public navbarState$ = new BehaviorSubject<boolean>(false);
  public navbarReadOnly$ = new BehaviorSubject<boolean>(false);
  pinImageUrl = IMAGE_PATHS.NAVIGATION.PIN;
  selectedPinImageUrl = IMAGE_PATHS.NAVIGATION.SELECTED_PIN;
  isExpanded = true;
  startedUrls;
  isProd = false;
  MENU_NAMES_TO_HIDE_IN_PROD_ENV_COPY = MENU_NAMES_TO_HIDE_IN_PROD_ENV;
  BETA_IMAGES = {
    selected: IMAGE_PATHS.COMMON.BETA_SELECTED,
    unselected: IMAGE_PATHS.COMMON.BETA_DESELECTED,
  };
  isTrialAccount = false;
  navMenuConfig = null;
  selectedProductType: string;
  userStatus = '';
  isValidTeamUser: boolean = false;
  constructor(
    private navigationService: NavigationBarService,
    private appUtil: AppUtil,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.subscriptions.push(
      this.navigationService
        .getNavigationBarConfig()
        .subscribe((config: NavigationBarModel) => {
          this.navMenuConfig = config;
          this.isTrialAccount = this.appUtil.isTrialAccount();
          config.menuItems = this.updateMenuItemConfigs(config.menuItems);
          // set up the navbar config
          this.navbarConfig$.next(this.navMenuConfig);
          this.selectedMenuItem = this.setSelectedMenuItem(config);
          this.disableMenuBasedProductType(
            PDashLocalStoreUtil.getProductViewType()
          );
          this.routesMap = this.generateRoutesMap(config.menuItems);
          this.cdr.markForCheck();
        }),
      this.navigationService
        .getNavigationDisplayState()
        .subscribe((state: boolean) => {
          // hide/show based on the state
          this.navbarState$.next(state);
        }),
      this.navigationService
        .getNavigationBarReadOnlyState()
        .subscribe((readOnly: boolean) => {
          // set readOnly or not
          this.navbarReadOnly$.next(readOnly);
        }),
      this.router.events.subscribe((event: Event) => {
        // update selected menu item based on browser url
        if (event instanceof NavigationStart) {
          const eventUrl = event.url;
          this.selectedMenuItem = null;
          const selectedItem = this.getSelectedMenuItem(
            eventUrl,
            this.routesMap
          );
          if (selectedItem) {
            this.startedUrls = selectedItem.routeUrls || [];
          }
        }
        if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel
        ) {
          const endedUrl = event.url;
          if (this.isValidRoute(this.startedUrls, endedUrl)) {
            this.selectedMenuItem = this.routesMap.get(this.startedUrls[0]);
          }
        }

        if (event instanceof NavigationError) {
          // Present error to user and unselect the navigation menu item
          console.warn('Navigation Failed', event.error);
          this.selectedMenuItem = null;
        }
        this.cdr.markForCheck();
      }),
      PDashLocalStoreUtil.getProductViewTypeAsObservable().subscribe(
        (productType) => this.disableMenuBasedProductType(productType)
      )
    );
  }

  ngOnInit() {
    this.isProd = this.appUtil.isProd();
  }

  isValidRoute(urls: Array<string> = [], endedUrl: string) {
    const validRoute = urls.filter((url) => endedUrl.includes(url));
    return validRoute.length ? true : false;
  }

  getSelectedMenuItem(url: string, routesMap: Map<string, NavMenuItem>) {
    let menuItem;
    routesMap.forEach((value, key) => {
      if (url.includes(key)) {
        menuItem = routesMap.get(key);
      }
    });
    return menuItem;
  }

  subMenuRouteTo(event: any, navItem: NavMenuItem) {
    event.stopPropagation();
    this.appUtil.setCustomEventInfo({
      name: 'event_main_nav_link',
      metaInfo: {
        name: navItem?.name,
        path: navItem?.routeUrls,
        parentName: navItem?.parentName,
      },
    } as UserBehaviorInsightsCustomEventInfo);
    this.routeTo(navItem);
  }

  routeTo(navItem: NavMenuItem) {
    if (navItem.subMenu || this.navbarReadOnly$.value) {
      // to handle nav menu item with sub menu; it has to wait for click on the sub menu item
      return;
    }
    // set the selected nav item isSelected to true, set false to other nav items
    this.navMenuConfig.menuItems?.forEach((item) => {
      item.isSelected = item === navItem;
    });
    this.router.navigateByUrl(navItem.routeUrls[0]);
  }

  generateRoutesMap(menuItems: Array<NavMenuItem>): Map<string, NavMenuItem> {
    const routesMap = new Map<string, NavMenuItem>();
    menuItems.forEach((item) => {
      if (item.subMenu && item.subMenu.length) {
        this.addSubMenuMultipleRoutes(item.subMenu, item, routesMap);
      } else if (item.routeUrls.length) {
        this.addMultipleRoutes(item.routeUrls, item, routesMap);
      }
    });
    return routesMap;
  }

  addSubMenuMultipleRoutes(
    subMenuItems: Array<NavMenuItem>,
    item: NavMenuItem,
    routesMap: Map<string, NavMenuItem>
  ) {
    subMenuItems.forEach((subItem) => {
      this.addMultipleRoutes(subItem.routeUrls, subItem, routesMap);
    });
  }

  addMultipleRoutes(
    urls: Array<string>,
    item: NavMenuItem,
    routesMap: Map<string, NavMenuItem>
  ) {
    urls.forEach((url) => routesMap.set(url, item));
  }

  toggleNavbar(event: any) {
    event.stopPropagation();
    this.isExpanded = !this.isExpanded;
    this.navigationService.setNavigationExpandState(this.isExpanded);
  }

  /**
   *
   * @param menuItemConfigs
   */

  updateMenuItemConfigs(menuItemConfigs: Array<NavMenuItem>) {
    const { wordmarkLogo, name, accType } = this.appUtil.getTeamMetaInfo();
    return menuItemConfigs.map((config) => {
      config.isDisable = config.isDisableOnTrial && this.isTrialAccount;
      const subMenu = Array.isArray(config.subMenu)
        ? this.getModifiedSubMenus(config.subMenu)
        : null;
      config.subMenu = subMenu;
      return config.isMain
        ? Object.assign({}, config, {
            imgUrl: wordmarkLogo,
            selectedImgUrl: wordmarkLogo,
            name,
          })
        : config;
    });
  }

  /**
   * Modify the sub menus isDisable property
   * @param subMenus
   * @returns
   */
  getModifiedSubMenus(subMenus: Array<any> = []): Array<any> {
    return subMenus.map((menu) => {
      const isDisable = menu.isDisableOnTrial && this.isTrialAccount;
      return Object.assign({}, menu, { isDisable });
    });
  }

  setSelectedMenuItem(config: NavigationBarModel) {
    const currentPath = this.appUtil.getPath();
    let matchedMenuConfig = null;

    const selectedMenu = config?.menuItems.find((menu) => {
      if (menu?.routeUrls?.length) {
        if (menu?.routeUrls) {
          const routeURLs = menu?.routeUrls;
          return routeURLs.find((url) => {
            if (currentPath?.indexOf(url) !== -1) {
              matchedMenuConfig = menu;
              return true;
            }
            return false;
          });
        }
      }
      const subMenus = menu?.subMenu;
      if (subMenus?.length) {
        return subMenus.find((subMenu) => {
          const routeURLs = subMenu?.routeUrls;
          return routeURLs.find((url) => {
            if (currentPath?.indexOf(url) !== -1) {
              matchedMenuConfig = subMenu;
              return true;
            }
            return false;
          });
        });
      }
    });
    return matchedMenuConfig;
  }

  /**
   * Disable menus based on product type
   * @param productType
   */
  disableMenuBasedProductType(productType: string) {
    const config = this.navMenuConfig;
    const isProTransit = productType?.toLowerCase() === SUBSCRIPTION_TYPES.MCN;
    const MENU_ITEMS_TO_BE_REMOVED = isProTransit
      ? this.getMenuItemsToBeRemovedForMCN()
      : MENU_NAMES_TO_BE_REMOVED_FOR_ZTNA;

    config?.menuItems?.forEach((parentMenu) => {
      const isHidden = MENU_ITEMS_TO_BE_REMOVED?.includes(parentMenu?.name);
      parentMenu = Object.assign(parentMenu, { isHidden });

      parentMenu?.subMenu?.forEach((subMenu) => {
        const isHidden = MENU_ITEMS_TO_BE_REMOVED?.includes(subMenu?.name);
        subMenu = Object.assign(subMenu, { isHidden: isHidden });
      });
    });
    this.navbarConfig$.next(config);
    this.cdr.markForCheck();
  }

  /**
   * Removes playground from navigation menu if its not applicable to a team
   * @returns
   */
  getMenuItemsToBeRemovedForMCN() {
    const { engLabsEnabled }: any = this.appUtil.getTeamsSupportedFeatures();
    const menuNames = MENU_NAMES_TO_BE_REMOVED_FOR_MCN;
    return engLabsEnabled ? menuNames : [...menuNames, MENU_NAMES.PLAYGROUND];
  }

  ngOnDestroy(): void {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }
}
