import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '@app/common/services/app.service';
import {
  CommonModelSerializer,
  FileUploadModelSerializer,
} from '@app/common/http/service/common.model.serializer';
import { ResourceService } from '@prosimoio/services';
import { CONSTANTS } from 'environments/environment';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import {
  AppDomain,
  AppDomainRegion,
  AppEndpoint,
  AppHealthCheckInfo,
  AppProtoPort,
  Application,
  ApplicationQuery,
  DNSService,
} from '@app/common/models/app';
import { AppCreateAction, GenSSLCertModel, SSLModel } from './app.model';
import {
  DIALOG_TOKEN,
  FILE_UPLOAD_HEADER_CONSTANT,
  LAST_UPDATED_TEXT,
  NOTIFICATION_MESSAGES,
} from '@app/common/util/constants';
import {
  APP_ONBOARD_TYPE_OPTION_KEYS,
  AppDeleteConfig,
  AppStatus,
  DOMAIN_TYPE_KEYS,
  KEYS_TO_COMPARE,
  PROTOCOLS,
  TGW_ROUTE_TABLE_OPTIONS,
} from './app.constants';
import { AppUtil } from '@app/common/util/app-util';
import { OnboardTemplateService } from '@app/common/components/drawer/templates/onboard-template/onboard-template.service';
import { map, retry, share, switchMap, take, takeUntil } from 'rxjs/operators';
import {
  DeleteDialogComponent,
  LoaderService,
  ToastService,
} from '@prosimoio/components';
import { ConditionConfig } from '@prosimoio/components/lib/v2/conditions-panel/conditions-panel.model';
import { Cluster } from '@app/common/models';

@Injectable({
  providedIn: 'root',
})
export class AppOnboardFileUploadService extends ResourceService<any> {
  FILE_UPLOAD_HEADER = FILE_UPLOAD_HEADER_CONSTANT;
  constructor(protected httpClient: HttpClient) {
    super(
      httpClient,
      CONSTANTS.DASHBOARD_HOST,
      '',
      new FileUploadModelSerializer()
    );
  }
  saveDomainCertificate(sslModel: SSLModel) {
    this.path = CONSTANTS.APP_ONBOARD.SSL.DOMAIN_CERTIFICATE;
    return this.create(sslModel, new HttpHeaders(FILE_UPLOAD_HEADER_CONSTANT));
  }
}
@Injectable({
  providedIn: 'root',
})
export class AppOnboardService extends ResourceService<any> {
  applicationConfig: Application;
  backupApplicationConfig: Application;
  private panelStatusConfig$: BehaviorSubject<any> = new BehaviorSubject({});
  onboardAppCount$ = new Subject();
  discoverAppCount$ = new Subject();
  stopPolling: any;
  constructor(
    protected httpClient: HttpClient,
    private appService: AppService,
    private onboardTemplateService: OnboardTemplateService,
    private appUtil: AppUtil,
    private toastService: ToastService,
    private loaderService: LoaderService
  ) {
    super(
      httpClient,
      CONSTANTS.DASHBOARD_HOST,
      '',
      new CommonModelSerializer()
    );
  }

  getApplicationConfig(): Application {
    return this.applicationConfig;
  }

  setBackupApplicationConfig(config: Application) {
    this.backupApplicationConfig = config;
  }

  getBackupApplicationConfig(): Application {
    return this.backupApplicationConfig;
  }

  //appOnboardType
  getAppOnboardType(): string {
    return this.applicationConfig.appOnboardType;
  }

  //accessType
  getAppAccessType(): string {
    return this.applicationConfig.accessType;
  }

  addAppURL(appURL: AppDomain) {
    this.applicationConfig.appURLs.push(appURL);
  }

  removeAppURL(index: number) {
    this.applicationConfig.appURLs.splice(index, 1);
  }

  updateAppURLs(appURLs: Array<AppDomain>) {
    this.applicationConfig.appURLs = appURLs;
  }

  updateAppCloudHostedType(cloudHostedType: string) {
    this.applicationConfig.cloudHostedType = cloudHostedType;
  }

  updateAppCSPType(cspType: string) {
    this.applicationConfig.cspType = cspType;
  }

  updateAppDCAppCloudID(dcID: string) {
    this.applicationConfig.dcID = dcID;
  }

  //cloudService
  getAppCloudService(): string {
    return this.applicationConfig.cloudService;
  }

  getAppStatus(): string {
    return this.applicationConfig.status;
  }

  getAppCloudHostedType(): string {
    return this.applicationConfig.cloudHostedType;
  }

  getAppDCAppCloudID(): string {
    return this.applicationConfig.dcID;
  }

  getAppCSPType(): string {
    return this.applicationConfig.cspType;
  }

  getAppDomains(): Array<AppDomain> {
    return this.applicationConfig.appURLs;
  }

  getAppDomainAtDomainIndex(domainIndex: number): AppDomain {
    return this.getAppDomains()[domainIndex];
  }

  getApplicationDomainAppFqdn(domainIndex: number): string {
    return this.getAppDomains()[domainIndex].appFqdn;
  }

  getApplicationDomainInternalDomain(domainIndex: number): string {
    return this.getAppDomains()[domainIndex].internalDomain;
  }

  getApplicationProtocols(domainIndex: number): Array<AppProtoPort> {
    return this.getAppDomains()[domainIndex]?.protocols || [];
  }

  getApplicationExtProtocols(domainIndex: number): Array<AppProtoPort> {
    return this.getAppDomains()[domainIndex]?.extProtocols || [];
  }

  getSubdomainIncluded(domainIndex: number): boolean {
    return this.getAppDomains()[domainIndex]?.subdomainIncluded;
  }

  getAppDomainAtIndex(domainIndex: number) {
    return this.getAppDomains()[domainIndex];
  }

  getRegionAtRegionAndAppDomainIndex(domainIndex: number, regionIndex: number) {
    return this.getAppDomains()[domainIndex].regions[regionIndex];
  }

  getCacheRuleID(domainIndex: number): string {
    return this.getAppDomainAtIndex(domainIndex)?.cacheRuleID;
  }

  updateAppDomainCloudType(domainIndex: number, cloudType: string) {
    this.getAppDomains()[domainIndex].cloudType = cloudType;
  }

  updateAppDomainCloudKeyID(domainIndex: number, cloudKeyID: string) {
    this.getAppDomains()[domainIndex].cloudKeyID = cloudKeyID;
    this.updateSettingsDomainValidity(domainIndex);
  }

  updateAppDomainDCAppCloudID(domainIndex: number, dcID: string) {
    this.getAppDomains()[domainIndex].dcID = dcID;
    this.updateSettingsDomainValidity(domainIndex);
  }

  updateAppDomainDCAppIP(domainIndex: number, dcAppIp: string) {
    this.getAppDomains()[domainIndex].dcAppIp = dcAppIp;
    this.updateSettingsDomainValidity(domainIndex);
  }

  updateAppDomainNamespaceID(domainIndex: number, namespaceID: string) {
    this.getAppDomains()[domainIndex].namespaceID = namespaceID;
    this.updateSettingsDomainValidity(domainIndex);
  }

  updateAppDomainDNSService(domainIndex: number, dnsService: DNSService) {
    this.getAppDomains()[domainIndex].dnsService = dnsService;
    this.updateSettingsDomainValidity(domainIndex);
  }

  updateAppDomainHealthCheckInfo(
    domainIndex: number,
    healthCheckInfo: AppHealthCheckInfo
  ) {
    this.getAppDomains()[domainIndex].healthCheckInfo = healthCheckInfo;
    this.updateSettingsDomainValidity(domainIndex);
  }

  updateAppDomainConnectionOption(
    domainIndex: number,
    connectionOption: string
  ) {
    this.getAppDomains()[domainIndex].connectionOption = connectionOption;
  }

  updateAppDomainRegions(domainIndex: number, regions: Array<AppDomainRegion>) {
    this.getAppDomains()[domainIndex].regions = regions;
    this.updateSettingsDomainValidity(domainIndex);
  }

  updateBucketsAtRegionIndex(
    domainIndex: number,
    regionIndex: number,
    buckets: Array<string>
  ) {
    this.getRegionAtRegionAndAppDomainIndex(domainIndex, regionIndex).buckets =
      buckets;
    this.updateSettingsDomainValidity(domainIndex);
  }

  updateAppDomainRegionEndpoints(
    domainIndex: number,
    regionIndex: number,
    endpoints: Array<AppEndpoint>
  ) {
    this.getRegionAtRegionAndAppDomainIndex(
      domainIndex,
      regionIndex
    ).endPoints = endpoints;
    this.updateSettingsDomainValidity(domainIndex);
  }

  updateAppDomainRegionID(
    domainIndex: number,
    regionIndex: number,
    id: string
  ) {
    this.getRegionAtRegionAndAppDomainIndex(domainIndex, regionIndex).id = id;
  }

  updateAppDomainRegionName(
    domainIndex: number,
    regionIndex: number,
    name: string
  ) {
    this.getRegionAtRegionAndAppDomainIndex(domainIndex, regionIndex).name =
      name;
    this.updateSettingsDomainValidity(domainIndex);
  }

  updateAppDomainRegionRegionType(
    domainIndex: number,
    regionIndex: number,
    regionType: string
  ) {
    this.getRegionAtRegionAndAppDomainIndex(
      domainIndex,
      regionIndex
    ).regionType = regionType;
  }

  removeAppDomainRegion(domainIndex: number, regionIndex: number) {
    const existingRegions = this.getAppDomainAtIndex(domainIndex).regions;
    existingRegions.splice(regionIndex, 1);
    this.updateSettingsDomainValidity(domainIndex);
  }

  addAppDomainRegion(domainIndex: number, regionConfig: AppDomainRegion) {
    this.getAppDomainAtIndex(domainIndex).regions.push(regionConfig);
    this.updateSettingsDomainValidity(domainIndex);
  }

  updateAppDomainRegionEndpointInputType(
    domainIndex: number,
    regionIndex: number,
    inputType: string
  ) {
    this.getRegionAtRegionAndAppDomainIndex(
      domainIndex,
      regionIndex
    ).inputType = inputType;
    this.updateSettingsDomainValidity(domainIndex);
  }

  updateAppDomainRegionConnOption(
    domainIndex: number,
    regionIndex: number,
    connOption: string
  ) {
    this.getRegionAtRegionAndAppDomainIndex(
      domainIndex,
      regionIndex
    ).connOption = connOption;
    this.updateSettingsDomainValidity(domainIndex);
  }

  updateAppDomainRegionTGWRouteTable(
    domainIndex: number,
    regionIndex: number,
    modifyTgwAppRouteTable: boolean
  ) {
    this.getRegionAtRegionAndAppDomainIndex(
      domainIndex,
      regionIndex
    ).modifyTgwAppRouteTable = modifyTgwAppRouteTable
      ? TGW_ROUTE_TABLE_OPTIONS.MODIFY
      : TGW_ROUTE_TABLE_OPTIONS.MAINTAIN;
  }

  updateCacheRuleID(domainIndex: number, cacheRuleID: string) {
    this.getAppDomainAtIndex(domainIndex).cacheRuleID = cacheRuleID;
  }

  updateAppDomainCertID(domainIndex: number, certID: string) {
    this.getAppDomainAtIndex(domainIndex).certID = certID;
    this.updateSettingsDomainValidity(domainIndex);
  }

  updateApplicationProtocolsAtDomainIndex(
    domainIndex: number,
    protocols: Array<AppProtoPort>
  ) {
    this.getAppDomainAtIndex(domainIndex).protocols = protocols;
    this.updateSettingsDomainValidity(domainIndex);
  }

  updateApplicationExtProtocolsAtDomainIndex(
    domainIndex: number,
    extProtocols: Array<AppProtoPort>
  ) {
    this.getAppDomains()[domainIndex].extProtocols = extProtocols;
    this.updateSettingsDomainValidity(domainIndex);
  }

  setApplicationConfig(config: Application) {
    this.applicationConfig = config;
  }

  updateApplicationConfig(config: Application) {
    this.applicationConfig = this.applicationConfig
      ? Object.assign(this.applicationConfig, config)
      : Object.assign({}, this.applicationConfig, config);
  }

  updateBackupApplicationConfig(config: Application) {
    this.backupApplicationConfig = this.backupApplicationConfig
      ? Object.assign(this.backupApplicationConfig, config)
      : Object.assign({}, this.backupApplicationConfig, config);
  }

  updateBackupAppDomainCloudType(domainIndex: number, cloudType: string) {
    this.backupApplicationConfig.appURLs[domainIndex].cloudType = cloudType;
  }

  updateAppDomainConfig(domainindex: number, config: AppDomain) {
    this.applicationConfig.appURLs[domainindex] = this.applicationConfig
      .appURLs[domainindex]
      ? Object.assign(this.applicationConfig.appURLs[domainindex], config)
      : Object.assign({}, this.applicationConfig.appURLs[domainindex], config);
  }

  isNewApp(): boolean {
    return (
      this.getApplicationConfig().id === '' &&
      this.getApplicationConfig().status === AppStatus.NEW
    );
  }

  isTcpOrUDPApp(appConfig: Application): boolean {
    if (
      appConfig?.appURLs?.length &&
      appConfig?.appURLs[0]?.protocols?.length
    ) {
      return [PROTOCOLS.TCP, PROTOCOLS.UDP].includes(
        appConfig?.appURLs[0]?.protocols[0]?.protocol
      );
    }
    return false;
  }

  isHideSettingsData(appConfig): boolean {
    if (
      appConfig?.appURLs?.length &&
      appConfig?.appURLs[0]?.appFqdn &&
      appConfig?.cloudHostedType &&
      (appConfig?.cspType || appConfig?.dcID)
    ) {
      return false;
    }
    return true;
  }

  getAppInitialConfig(): Application {
    return {
      id: '',
      appName: '',
      appOnboardType: 'fqdn',
      cloudHostedType: 'public',
      cspType: '',
      accessType: 'fqdn',
      appURLs: [
        {
          appFqdn: '',
          subdomainIncluded: false,
          domainType: 'custom',
          healthCheckInfo: {
            enabled: true,
            endpoint: '/',
          },
        },
      ],
      optOption: '',
      enableMultiCloud: false,
      deployed: false,
      dnsDiscovery: false,
      policyIDs: [],
      status: 'NEW',
    } as Application;
  }

  setPanelStatusConfig(panelStatusConfig: any): void {
    this.panelStatusConfig$?.next(panelStatusConfig);
  }

  getPanelStatusConfig(): Observable<any> {
    return this.panelStatusConfig$?.asObservable();
  }

  setOnboardAppCount(count: number): void {
    this.onboardAppCount$?.next(count);
  }

  getOnboardAppCount(): Observable<any> {
    return this.onboardAppCount$?.asObservable();
  }

  setDiscoverAppCount(count: number): void {
    this.discoverAppCount$?.next(count);
  }

  getDiscoverAppCount(): Observable<any> {
    return this.discoverAppCount$?.asObservable();
  }

  getSettingsStepPanelStatus(domainIndex: number) {
    const appURLConfig = this.getAppDomainAtIndex(domainIndex);
    const config = {
      isProtocolStepCompleted: this.getProtocolPortPanelStatus(appURLConfig),
      isCloudStepCompleted: this.getCloudSettingsPanelStatus(appURLConfig),
      isHealthCheckStepCompleted:
        this.getAppHealthCheckPanelStatus(appURLConfig),
      isCertStepCompleted: this.getCertificatePanelStatus(appURLConfig),
      isDNSStepCompleted: this.getDNSSettingsPanelStatus(appURLConfig),
      isCacheRuleStepCompleted: this.getCacheRulePanelStatus(appURLConfig),
    } as any;
    return config;
  }

  getProtocolPortPanelStatus(appURLConfig: AppDomain) {
    if (appURLConfig?.protocols?.length) {
      return appURLConfig?.protocols.some((obj) => {
        const hasProtocol = obj?.protocol;
        const hasPortList =
          Array.isArray(obj?.portList) && obj?.portList.length > 0;
        const hasPort = obj?.port;
        return hasProtocol && (hasPortList || hasPort);
      });
    }
    return false;
  }

  getAppHealthCheckPanelStatus(appURLConfig: AppDomain) {
    return true;
  }

  getCloudSettingsPanelStatus(appURLConfig: AppDomain) {
    const regions = !!this.isDomainConditionRegionValid(appURLConfig?.regions);
    const cloudKeyID = !!appURLConfig?.cloudKeyID;
    const dcAppIp = !!appURLConfig?.dcAppIp;
    const namespaceID = !!appURLConfig?.namespaceID;
    const dcID = appURLConfig?.dcID || '';
    if (dcID) {
      return dcAppIp && namespaceID;
    } else {
      return cloudKeyID && regions;
    }
  }

  getCertificatePanelStatus(appURLConfig: AppDomain) {
    if (appURLConfig?.domainType === DOMAIN_TYPE_KEYS.PROSIMO) {
      return true;
    }
    const certID = !!appURLConfig?.certID;
    return certID;
  }

  getDNSSettingsPanelStatus(appURLConfig: AppDomain) {
    return true;
  }

  getCacheRulePanelStatus(appURLConfig: AppDomain) {
    return true;
  }

  updateSettingsDomainValidity(domainIndex: number) {
    this.setPanelStatusConfig(this.getSettingsStepPanelStatus(domainIndex));
    this.appUtil
      .getConditionConfig()
      .pipe(
        take(1),
        map((existingConditions) => {
          const updatedConditions = [...existingConditions];
          const selectedDomain = updatedConditions[domainIndex];
          const updatedDomain: ConditionConfig = {
            ...selectedDomain,
            isValid: this.isDomainConditionValid(domainIndex),
          };
          updatedConditions[domainIndex] = updatedDomain;
          return updatedConditions;
        })
      )
      .subscribe((updatedConditions: ConditionConfig[]) => {
        this.appUtil.setConditionConfig(updatedConditions);
      });
  }

  isApplicationConfigValid(): boolean {
    const appConfig = this.getApplicationConfig();
    return !!(
      appConfig?.appName &&
      appConfig?.appOnboardType &&
      this.isApplicationConfigDomainsValid()
    );
  }

  isApplicationConfigDomainsValid(): boolean {
    const appURLs = this.getAppDomains();
    return appURLs.every((appURL, i) => {
      return this.isDomainConditionValid(i);
    });
  }

  isDomainConditionValid(domainIndex: number): boolean {
    const domainData: AppDomain = this.getAppDomainAtDomainIndex(domainIndex);
    const dcID = domainData?.dcID || '';
    const appFqdn = !!domainData?.appFqdn;
    const protocolsLength = !!(
      domainData?.protocols?.length && domainData?.protocols[0]?.protocol
    );
    const regionValid = !!this.isDomainConditionRegionValid(
      domainData?.regions
    );
    const cloudKeyID = !!domainData?.cloudKeyID;
    const certIDOrProsimo = !!(
      domainData?.certID || domainData?.domainType === DOMAIN_TYPE_KEYS.PROSIMO
    );
    const dcAppIp = !!domainData?.dcAppIp;
    const namespaceID = !!domainData?.namespaceID;
    const certID = !!domainData?.certID;

    switch (this.getAppOnboardType()) {
      case APP_ONBOARD_TYPE_OPTION_KEYS.FQDN:
      case APP_ONBOARD_TYPE_OPTION_KEYS.IP:
        if (dcID) {
          return appFqdn && protocolsLength && dcAppIp && namespaceID;
        } else {
          return cloudKeyID && appFqdn && protocolsLength && regionValid;
        }

      case APP_ONBOARD_TYPE_OPTION_KEYS.WEB:
      case APP_ONBOARD_TYPE_OPTION_KEYS.VDI:
        if (dcID) {
          return (
            appFqdn &&
            protocolsLength &&
            dcAppIp &&
            namespaceID &&
            certIDOrProsimo
          );
        } else {
          return (
            cloudKeyID &&
            appFqdn &&
            protocolsLength &&
            certIDOrProsimo &&
            regionValid
          );
        }

      case APP_ONBOARD_TYPE_OPTION_KEYS.JUMPBOX:
        if (dcID) {
          return appFqdn && certID && dcAppIp && namespaceID;
        } else {
          return cloudKeyID && appFqdn && certID && regionValid;
        }

      case APP_ONBOARD_TYPE_OPTION_KEYS.CLOUD_SVC:
        return appFqdn && cloudKeyID && certID && regionValid;

      default:
        return false;
    }
  }

  isDomainConditionRegionValid(regions: Array<AppDomainRegion>): boolean {
    switch (this.getAppOnboardType()) {
      case APP_ONBOARD_TYPE_OPTION_KEYS.FQDN:
      case APP_ONBOARD_TYPE_OPTION_KEYS.IP:
      case APP_ONBOARD_TYPE_OPTION_KEYS.WEB:
      case APP_ONBOARD_TYPE_OPTION_KEYS.VDI:
      case APP_ONBOARD_TYPE_OPTION_KEYS.JUMPBOX:
        return (
          regions?.length > 0 &&
          regions.every((region) => region?.name && region?.endPoints?.length)
        );
      case APP_ONBOARD_TYPE_OPTION_KEYS.CLOUD_SVC:
        return (
          regions?.length > 0 &&
          regions.every((region) => region?.name && region?.buckets?.length)
        );
    }
  }

  getKeysToCompareAsPerAppOnboardType(appOnboardType: string) {
    switch (appOnboardType) {
      case APP_ONBOARD_TYPE_OPTION_KEYS.FQDN:
      case APP_ONBOARD_TYPE_OPTION_KEYS.IP:
      case APP_ONBOARD_TYPE_OPTION_KEYS.WEB:
      case APP_ONBOARD_TYPE_OPTION_KEYS.VDI:
      case APP_ONBOARD_TYPE_OPTION_KEYS.JUMPBOX:
        return {
          ...KEYS_TO_COMPARE,
        };
      case APP_ONBOARD_TYPE_OPTION_KEYS.CLOUD_SVC:
        return {
          ...KEYS_TO_COMPARE,
          appURLs: {
            ...KEYS_TO_COMPARE.appURLs,
            regions: {
              name: true,
              regionType: true,
              buckets: true,
            },
          },
        };
    }
  }

  getFormFieldsToDisableValuesBasedOnStatus(status: string) {
    switch (status) {
      case AppStatus.FAILED:
      case AppStatus.DEPLOYING:
      case AppStatus.DECOMMISSIONING:
      case AppStatus.CONFIGURING:
        return {
          appOnboardType: 'appOnboardType',
          accessType: 'accessType',
          appFqdn: 'appFqdn',
          internalDomain: 'internalDomain',
          subdomainIncluded: 'subdomainIncluded',
          domainType: 'domainType',
          cloudType: 'cloudType',
          cloudKeyID: 'cloudKeyID',
          connectionOption: 'connectionOption',
          dcID: 'dcID',
          endpoint: 'endpoint',
          namespaceID: 'namespaceID',
          region: 'region',
          serviceIPType: 'serviceIPType',
          serviceIP: 'serviceIP',
          samlRewrite: 'samlRewrite',
          addDomain: 'addDomain',
          deleteDomain: 'deleteDomain',
          protocol: 'protocol',
          dnsServiceType: 'dnsServiceType',
          dnsCloudAccount: 'dnsCloudAccount',
          cacheRuleID: 'cacheRuleID',
          selectCertOption: 'selectCertOption',
          healthCheckEnabled: 'healthCheckEnabled',
          healthCheckEndpointPath: 'healthCheckEndpointPath',
          policy: 'policy',
        };
      default: {
        return {};
      }
    }
  }

  getApplications(): Observable<Array<Application>> {
    return this.appService.getApplications([]);
  }

  getEdges(): Observable<Array<Cluster>> {
    this.path = CONSTANTS.EDGE_MANAGEMENT.EDGES.DEFAULT_PATH;
    return this.read('');
  }

  getProsimoDomain(): Observable<any> {
    this.path = CONSTANTS.APP_ONBOARD.SETTINGS.PROSIMO_DOMAIN;
    return this.read('');
  }

  getConfiguredClouds(): Observable<any> {
    this.path = CONSTANTS.CLOUD.CONFIGURED_LIST;
    return this.read('');
  }

  getCloudRegions(id: string): Observable<any> {
    this.path = CONSTANTS.CLOUD.REGIONS.GET(id);
    return this.read('');
  }

  getCloudServices(): Observable<any> {
    this.path = CONSTANTS.CLOUD.SERVICES.GET;
    return this.read('');
  }

  getPrivateClouds(): Observable<any> {
    this.path = CONSTANTS.CLOUD_MANAGEMENT.PRIVATE_CLOUD;
    return this.read('');
  }

  getAutoDiscoveredEndpoint(payload: any): Observable<any> {
    this.path = CONSTANTS.APP_ONBOARD.CLOUD.ENDPOINTS_BY_REGIONS_WITH_NO_APP_ID;
    return this.create(payload);
  }

  getDiscoveredBuckets(bucketPayload: any): Observable<any> {
    this.path = CONSTANTS.CLOUD.STORAGE.S3;
    return this.create(bucketPayload);
  }

  getNamespaces() {
    this.path = CONSTANTS.NETWORK_ONBOARD.ADD_NAMESPACE;
    return this.read('');
  }

  getPolicies(payload) {
    this.path = CONSTANTS.POLICY.POLICY_SEARCH;
    return this.create(payload);
  }

  getCacheRules(): Observable<any> {
    this.path = CONSTANTS.OPTIMIZE.CACHE_RULE;
    return this.read('');
  }

  getDomainCertificates(): Observable<any> {
    this.path = CONSTANTS.APP_ONBOARD.SSL.DOMAIN_CERTIFICATE;
    return this.read('');
  }

  getInternalCertificates(): Observable<any> {
    this.path = CONSTANTS.APP_ONBOARD.SSL.INTERNAL_CERTIFICATE;
    return this.read('');
  }

  generateCertificate(genCertModel: GenSSLCertModel): Observable<SSLModel> {
    this.path = CONSTANTS.APP_ONBOARD.SSL.DOMAIN_CERTIFICATE;
    return this.create(genCertModel);
  }

  autoGenerateServiceIP() {
    this.path = CONSTANTS.APP_ONBOARD.SETTINGS.AUTO_GENERATE_SERVICE_IP;
    return this.read('');
  }

  validateServiceIP(serviceIPPayload: any) {
    this.path = CONSTANTS.APP_ONBOARD.SETTINGS.VALIDATE_SERVICE_IP;
    return this.create(serviceIPPayload);
  }

  createApp(action: AppCreateAction, payload: Application): Observable<any> {
    this.path = CONSTANTS.V2.ONBOARD.APP.CREATE(action);
    return this.create(payload);
  }

  deleteApp(id) {
    this.path = CONSTANTS.APP_DELETE;
    return this.delete(id).pipe(take(1));
  }

  offboardApp(id, isForced: boolean = false) {
    this.path = `${CONSTANTS.APP_OFFBOARD}/${id}${
      isForced ? '?force=true' : ''
    }`;
    return this.delete().pipe(take(1));
  }

  searchApps(searchObj: any): Observable<any> {
    this.path = CONSTANTS.APPS_SEARCH;
    return this.create(searchObj);
  }

  getIdps(): Observable<any> {
    this.path = CONSTANTS.IDP_MANAGEMENT.GET_IDP;
    return this.read('');
  }

  idpImportParameters(importIdpPayload: any) {
    this.path = CONSTANTS.IDP_MANAGEMENT.IMPORT_PARAMETERS;
    return this.update(importIdpPayload, true);
  }

  getAppsImportStatus() {
    const postData = { feature: 'Import', type: 'App' };
    this.path = CONSTANTS.GUIDE.DEPLOY_STATUS;
    return this.create(postData);
  }

  getAppsImportProgress() {
    this.stopPolling = new Subject();
    const progress$ = timer(1, 2000).pipe(
      switchMap(() => this.getAppsImportStatus()),
      retry(3),
      share(),
      takeUntil(this.stopPolling)
    );
    return progress$;
  }

  setStopPolling() {
    if (this.stopPolling) {
      this.stopPolling.next();
      this.stopPolling.complete();
    }
  }

  getAppsByPage(
    page: number,
    searchTerm: string = ''
  ): Observable<ApplicationQuery> {
    return this.appService.getAppsByPage(page, searchTerm);
  }

  getAppsCount(): Observable<any> {
    this.path = CONSTANTS.V2.ONBOARD.APP.COUNT;
    return this.read('');
  }

  sortByLastModified(data: any) {
    if (!data) {
      return [];
    }
    return this.appUtil.sort(data, 'updatedTime', 'date', false);
  }

  setCreateTimeAndDeleteConfig(appData: Application) {
    const status = appData.status as AppStatus;
    if (appData?.updatedTime) {
      this.onboardTemplateService.setDateInfo(
        `${LAST_UPDATED_TEXT}${this.appUtil.transformDateTime(
          appData?.updatedTime
        )}`
      );
    }
    if (
      ![
        AppStatus.NEW,
        AppStatus.CONFIGURING,
        AppStatus.DECOMMISSIONING,
        AppStatus.DEPLOYING,
      ]?.includes(status)
    ) {
      this.onboardTemplateService.setDeleteConfig({
        disabled: false,
        message: AppDeleteConfig[status].btn,
        tooltip: AppDeleteConfig[status].btn,
        panelConfig: {
          btnColorBlack: false,
          deleteMsg: AppDeleteConfig[status].panelMessage,
          deleteName: appData.appName,
          deleteButtonName: AppDeleteConfig[status].panelBtnName,
          action: AppDeleteConfig[status].action,
          confirmation: AppDeleteConfig[status].confirmation,
        },
      });
    }
  }
  deleteApplication(appData: Application, uiThemeMode: string) {
    if (!appData.id) {
      return;
    }
    this.loaderService.setLoaderStatus(true);
    this.deleteApp(appData.id).subscribe({
      next: (resp) => {
        this.loaderService.setLoaderStatus(false);
        this.toastService.success(
          NOTIFICATION_MESSAGES.DELETE.SUCCESS(appData.appName)
        );
        this.appUtil.setDynamicDrawerResponse();
        this.appUtil.setDrawerState(false);
      },
      error: ({ error: { message } }) => {
        this.loaderService.setLoaderStatus(false);
        this.showErrorMessage(
          uiThemeMode?.toLowerCase(),
          {
            errorMsg:
              message || NOTIFICATION_MESSAGES.DELETE.FAIL(appData.appName),
          },
          () => {
            this.appUtil.setDrawerState(false);
          }
        );
      },
    });
  }

  offboardApplication(
    forced: boolean,
    appData: Application,
    uiThemeMode: string
  ) {
    if (!appData.id) {
      return;
    }
    if (
      ![AppStatus.DEPLOYED, AppStatus.FAILED]?.includes(
        appData.status as AppStatus
      )
    ) {
      return;
    }

    this.loaderService.setLoaderStatus(true);
    this.offboardApp(appData.id, forced).subscribe({
      next: (resp) => {
        this.loaderService.setLoaderStatus(false);
        this.toastService.success(
          NOTIFICATION_MESSAGES.OFFBOARD.SUCCESS(appData.appName)
        );
        this.appUtil.setDynamicDrawerResponse();
        this.appUtil.setDrawerState(false);
      },
      error: ({ error: { message } }) => {
        this.loaderService.setLoaderStatus(false);
        this.showErrorMessage(
          uiThemeMode?.toLowerCase(),
          {
            errorMsg:
              message || NOTIFICATION_MESSAGES.OFFBOARD.FAIL(appData.appName),
          },
          () => {
            this.appUtil.setDrawerState(false);
          }
        );
      },
    });
  }

  showErrorMessage(uiThemeMode, popupConfig, action: (...args: any[]) => void) {
    const dialogData = {
      ...popupConfig,
      isInnerHTML: true,
    };

    const injector = this.appUtil?.createInjector(
      DIALOG_TOKEN.INJECTOR_TOKEN_DATA.DIALOG_DATA_TOKEN.NAME,
      dialogData
    );

    const componentRef = this.appUtil?.initializeDeleteOverlay(
      DeleteDialogComponent,
      injector,
      uiThemeMode,
      '340px'
    );
    (componentRef.instance as DeleteDialogComponent)?.closeDialogEvent
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.appUtil?.destroyOverlay();
        },
        error: (err) => {
          console.warn(err);
        },
        complete: () => {
          this.appUtil?.destroyOverlay();
        },
      });
  }
}
