import { Store, StoreConfig } from '@datorama/akita';
import { ViewMode } from '../app.constants';
export interface AppState {
  activeTab: string;
}

export function createInitialState(): AppState {
  return {
    activeTab: ViewMode.ONBOARDED,
  };
}

@StoreConfig({
  name: 'apps',
  resettable: true,
})
export class AppStore extends Store<AppState> {
  constructor() {
    super(createInitialState());
  }
}

export const appStore = new AppStore();
