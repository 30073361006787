import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceService } from '@prosimoio/services';
import { CONSTANTS } from 'environments/environment';
import { CommonModelSerializer } from '../http/service/common.model.serializer';
import { Observable } from 'rxjs';
import { BgpGroup, IpPrefixList } from '../models';

@Injectable({
  providedIn: 'root',
})
export class GroupingService extends ResourceService<any> {
  constructor(protected httpClient: HttpClient) {
    super(
      httpClient,
      CONSTANTS.DASHBOARD_HOST,
      '',
      new CommonModelSerializer()
    );
  }

  getAllIPPrefixGroupings(): Observable<Array<IpPrefixList>> {
    this.path = CONSTANTS.MANAGEMENT.IP_PREFIX_GROUPING.BASE_PATH;
    return this.read('');
  }

  getAllBGPCommunityGroupings(): Observable<Array<BgpGroup>> {
    this.path = CONSTANTS.MANAGEMENT.BGP_COMMUNITY_GROUPING.BASE_PATH;
    return this.read('');
  }
}
