import { Query } from '@datorama/akita';
import {
  AppsDashboardSharedStore,
  AppsDashboardSharedState,
  appsDashboardSharedStore,
} from './apps-dashboard.store';
import { Observable } from 'rxjs';

export class AppsDashboardSharedQuery extends Query<AppsDashboardSharedState> {
  activeNavigation$ = this.select((state) => state.activeNavigation);
  constructor(protected store: AppsDashboardSharedStore) {
    super(store);
  }

  get selectedActiveNavigation(): Observable<string> {
    return this.activeNavigation$;
  }
}

export const appsDashboardSharedQuery = new AppsDashboardSharedQuery(
  appsDashboardSharedStore
);
