import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'transalateNumber' })
export class TransalateNumberPipe implements PipeTransform {
  transform(value: number): string {
    if (value <= 99) {
      return value.toString();
    } else if (value > 99 && value <= 999) {
      return '99+';
    } else if (value > 999 && value <= 9999) {
      return '999+';
    } else {
      return '9999+';
    }
  }
}
