import { Query } from '@datorama/akita';
import {
  LogMetaDataStore,
  LogMetaDataState,
  logMetaDataStore,
} from './log-meta-data.store';
import { Observable } from 'rxjs';

export class LogMetaDataQuery extends Query<LogMetaDataState> {
  dashboardMetaData$ = this.select((state) => state.dashboard);
  fieldsMappingData$ = this.select((state) => state.fields);

  constructor(protected store: LogMetaDataStore) {
    super(store);
  }

  get dashboardDisplayData(): Observable<any> {
    return this.dashboardMetaData$;
  }

  get fieldsMappingData(): Observable<any> {
    return this.fieldsMappingData$;
  }
}

export const logMetaDataQuery = new LogMetaDataQuery(logMetaDataStore);
