import { QueryEntity } from '@datorama/akita';
import { ZTNARecentSearch } from './ztna-recent-search.model';
import {
  ZTNARecentSearchState,
  ZTNARecentSearchStore,
  ztnaRecentSearchStore,
} from './ztna-recent-search.store';

export class ZTNARecentSearchQuery extends QueryEntity<
  ZTNARecentSearchState,
  ZTNARecentSearch
> {
  constructor(protected store: ZTNARecentSearchStore) {
    super(store);
  }
}

export const ztnaRecentSearchQuery = new ZTNARecentSearchQuery(ztnaRecentSearchStore);
