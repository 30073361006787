import { TimeISO } from '@prosimoio/services';
import { DNSServer, User } from './common';

export type DCConnectorSettings = {
  id: string;
  name: string;
  hostname: string;
  sshKeys: string;
  defaultGateway: string;
  wanIntfSettings: Array<WanIntfSettings>;
  lanIntfSettings: Array<LanIntfSettings>;
  loopbackIntfSettings: LoopbackIntfSetting;
  dnsServers: DNSServer;
  ntpServer: string;
  status: string;
  edgeConnectorGroupID: string;
  createdTime: TimeISO;
  updatedTime: TimeISO;
  users: Array<User>;
  registrationDetails: Array<User>;
};

export type WanIntfSettings = {
  addressSchema: string;
  intfName: string;
  ipAddress: string;
  subnetMask: string;
  gateway: string;
  defaultGatewayUsed: boolean;
  connectivityType: string;
  edgeCIDRs: Array<string>;
};

export type LanIntfSettings = {
  addressSchema: string;
  intfName: string;
  ipAddress: string;
  subnetMask: string;
  gateway: string;
  defaultGatewayUsed: boolean;
  mtu: string;
  vlanIntfSettings: Array<VlanIntfSettings>;
};

export type VlanIntfSettings = {
  ipAddress: string;
  subnetMask: string;
  namespaceID: string;
  namespace: string;
  vlanID: string;
};

export type LoopbackIntfSetting = {
  intfName: string;
  ipAddress: string;
  useAsBGPRouterID: boolean;
};

export type FabricConnectorInfo = {
  id: string;
  routingID: string;
  teamID: string;
  cloudType: string;
  name: string;
  hasPublic: boolean;
  attachments: Array<EdgeAttachment>;
};

export type EdgeAttachment = {
  id: string;
  connType: string;
  pappFqdn: string;
  attachType: string;
  weight: number;
};
