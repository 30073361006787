<ng-container *ngIf="displayStatus">
  <div
    id="loader__container"
    [ngClass]="alignCenter ? 'align-center-lineheight' : ''"
  >
    <button class="btn btn-primary" type="button" disabled>
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      {{ message }}
    </button>
  </div>
</ng-container>
