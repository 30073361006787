import { Store, StoreConfig } from '@datorama/akita';

export interface AdvancedSettingState {
  activeTab: string;
}

export function createInitialState(): AdvancedSettingState {
  return {
    activeTab: '',
  };
}

@StoreConfig({ name: 'advanced-setting' })
export class AdvancedSettingStore extends Store<AdvancedSettingState> {
  constructor() {
    super(createInitialState());
  }
}

export const advancedSettingStore = new AdvancedSettingStore();
