import { IMAGE_PATHS } from '@app/common/util/constants';
import { APP_HEALTH_STATUS } from '@app/v2/transit-360/transit-360.constants';

export const DASHBOARD_TYPES = {
  USER: 'user',
  APP: 'app',
  INSIGHTS: 'INSIGHTS',
};

export class PaginatorSearchModel {
  value: string;
  page: {
    start: number;
    size: number;
  };

  static getInitSearchModel(size: number = 0, start = 0, value = '') {
    return {
      value,
      page: {
        start,
        size,
      },
    } as PaginatorSearchModel;
  }
}

export const APP_HEALTH_UX_SCRORE_RANGE_SPECIFIC = {
  [APP_HEALTH_STATUS.POOR]: {
    from: 0,
    to: 39,
  },
  [APP_HEALTH_STATUS.GOOD]: {
    from: 40,
    to: 79,
  },
  [APP_HEALTH_STATUS.EXCELLENT]: {
    from: 80,
    to: 100,
  },
};

export const CARD_CONTENT_ICONS = {
  CSP: IMAGE_PATHS.APP_ONBOARD.DISCOVERY.CLOUD,
  REGION: IMAGE_PATHS.APP_ONBOARD.DISCOVERY.REGION,
  SUBNET: IMAGE_PATHS.APP_ONBOARD.DISCOVERY.SUBNET_DARK,
  VPC: IMAGE_PATHS.APP_ONBOARD.DISCOVERY.VPC_DARK,
};

/**
 * Constants common to insights and playground feature
 */
export const ALL = 'all';

export const PLAYGROUND_METRICS = {
  STATS: 'STATS',
  RECOMMENDATIONS: 'RECOMMENDATIONS',
};

export const SORT_KEYS = {
  APP: 'appName',
  APP_GROUP: 'name',
  USER: 'email',
  USER_GROUP: 'name',
  SOURCE_NETWORKS: 'name',
  SOURCE_PRIVATE_LINK: 'name',
  SOURCE_REGIONS: 'name',
  TARGET_NETWORKS: 'name',
  TARGET_REGIONS: 'name',
  SRC_NETWORK_GROUPS: 'name',
  TGT_NETWORK_GROUPS: 'name',
};
