<p *ngIf="isHideSettingsData" class="py-2 mx-4 page__hint">
  Please Configure Cloud & Domain Details in the previous step to proceed
</p>
<div
  *ngIf="!isHideSettingsData"
  class="d-flex h-100 overflow-hidden"
  [formGroup]="appSettingsForm?.controls[selectedDomainIndex]"
>
  <pcomp-conditions-panel
    [style.maxWidth.px]="240"
    [style.minWidth.px]="240"
    [conditionStyle]="{ 'min-width': '240px', 'max-width': '240px' }"
  >
    <div [style.maxWidth.px]="240" [style.minWidth.px]="240"></div>
  </pcomp-conditions-panel>
  <div class="m-2 w-100 overflow-hidden d-flex flex-column">
    <p class="page__hint">
      Application behind the Prosimo Fabric and route the user traffic via the
      Fabric.
    </p>
    <mat-accordion [multi]="true" class="bordered-panel overflow-auto">
      <!--Port/Protocol Settings-->
      <ng-container *ngIf="isShowProtocolPortStep">
        <mat-expansion-panel class="form-panel mat-expansion-panel-spacing">
          <mat-expansion-panel-header class="form-panel__header arrow">
            <mat-panel-title>
              <div class="d-flex align-items-center">
                <span>Protocol & Port Settings</span>
                <span class="ml-2">
                  <app-badge
                    [badgeConfig]="
                      panelStatusConfig?.isProtocolStepCompleted
                        ? panelBadgeConfig?.COMPLETE
                        : panelBadgeConfig?.INCOMPLETE
                    "
                  ></app-badge
                ></span>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-protocol
            [selectedDomainIndex]="selectedDomainIndex"
          ></app-protocol>
        </mat-expansion-panel>
      </ng-container>

      <!--App Health Checks-->
      <ng-container
        *ngIf="
          appSettingsForm?.controls[selectedDomainIndex]?.get('healthCheckInfo')
        "
      >
        <div
          [formGroup]="
            appSettingsForm?.controls[selectedDomainIndex].get(
              'healthCheckInfo'
            )
          "
        >
          <mat-expansion-panel class="form-panel mat-expansion-panel-spacing">
            <mat-expansion-panel-header class="form-panel__header arrow">
              <mat-panel-title>
                <div class="d-flex align-items-center">
                  <span>App Health Checks</span>
                  <span class="ml-2">
                    <app-badge
                      [badgeConfig]="
                        panelStatusConfig?.isHealthCheckStepCompleted
                          ? panelBadgeConfig?.COMPLETE
                          : panelBadgeConfig?.INCOMPLETE
                      "
                    ></app-badge
                  ></span>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="d-flex flex-wrap align-items-center">
              <pcomp-info-tooltip
                [matTooltipClass]="tooltipThemeClass"
                [tooltipTemplate]="COPY_TOOLTIP_MESSAGE?.HEALTH_CHECK"
                tooltipTheme="cdk-component-container--{{ uiThemeMode }}-light"
              >
                <mat-label class="d-flex form__label">Health Check</mat-label>
              </pcomp-info-tooltip>

              <pcomp-page-header-extra-buttons
                (isCheckboxCheckedEvent)="onToggleHealthCheck($event)"
                [isToggleSwitchChecked]="
                  appSettingsForm?.controls[selectedDomainIndex]
                    ?.get('healthCheckInfo')
                    ?.get('enabled')?.value
                "
                [isToggleSwitchDisabled]="
                  appSettingsForm?.controls[selectedDomainIndex]
                    ?.get('healthCheckInfo')
                    ?.get('enabled')?.disabled
                "
              >
              </pcomp-page-header-extra-buttons>
            </div>

            <div
              *ngIf="
                appSettingsForm?.controls[selectedDomainIndex]
                  ?.get('healthCheckInfo')
                  ?.get('endpoint')
              "
            >
              <mat-label class="d-flex form__label mt-2">Enter Path</mat-label>
              <mat-form-field appearance="outline" class="w-50 mt-2">
                <div class="d-flex">
                  <input
                    matInput
                    placeholder="Enter Path"
                    formControlName="endpoint"
                    autocomplete="off"
                  />
                </div>
              </mat-form-field>
            </div>
          </mat-expansion-panel>
        </div>
      </ng-container>

      <!--Cloud Settings-->
      <ng-container *ngIf="isShowCloudStep">
        <mat-expansion-panel class="form-panel mat-expansion-panel-spacing">
          <mat-expansion-panel-header class="form-panel__header arrow">
            <mat-panel-title>
              <div class="d-flex align-items-center">
                <span>Cloud Settings</span>
                <span class="ml-2">
                  <app-badge
                    [badgeConfig]="
                      panelStatusConfig?.isCloudStepCompleted
                        ? panelBadgeConfig?.COMPLETE
                        : panelBadgeConfig?.INCOMPLETE
                    "
                  ></app-badge
                ></span></div
            ></mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container *ngIf="(cloudLoader$ | async)?.status">
            <div
              class="mt-3"
              [ngClass]="
                (cloudLoader$ | async)?.type === COPY_LOADER_CONFIG_TYPES.ERROR
                  ? 'status-failed-clr'
                  : 'clr-grey7'
              "
            >
              {{ (cloudLoader$ | async)?.message }}
            </div>
          </ng-container>
          <ng-container *ngIf="!(cloudLoader$ | async)?.status">
            <app-cloud
              [cloudData]="{
                cloudAccounts: cloudAccounts,
                deployedEdgeRegions: deployedEdgeRegions,
                selectedDomainIndex: selectedDomainIndex
              }"
            >
            </app-cloud>
          </ng-container>
        </mat-expansion-panel>
      </ng-container>

      <!--Certificates-->
      <ng-container *ngIf="isShowCertStep">
        <mat-expansion-panel class="form-panel mat-expansion-panel-spacing">
          <mat-expansion-panel-header class="form-panel__header arrow">
            <mat-panel-title>
              <div class="d-flex align-items-center">
                <span>Certificates</span>
                <span class="ml-2">
                  <app-badge
                    [badgeConfig]="
                      panelStatusConfig?.isCertStepCompleted
                        ? panelBadgeConfig?.COMPLETE
                        : panelBadgeConfig?.INCOMPLETE
                    "
                  ></app-badge
                ></span></div
            ></mat-panel-title>
          </mat-expansion-panel-header>
          <app-certificate
            [certData]="{
              selectedDomainIndex: selectedDomainIndex
            }"
          ></app-certificate>
        </mat-expansion-panel>
      </ng-container>

      <!--DNS Settings-->
      <ng-container *ngIf="isShowDNSStep">
        <mat-expansion-panel class="form-panel mat-expansion-panel-spacing">
          <mat-expansion-panel-header class="form-panel__header arrow">
            <mat-panel-title>
              <div class="d-flex align-items-center">
                <span>DNS Settings</span>
                <span class="ml-2">
                  <app-badge
                    [badgeConfig]="
                      panelStatusConfig?.isDNSStepCompleted
                        ? panelBadgeConfig?.COMPLETE
                        : panelBadgeConfig?.INCOMPLETE
                    "
                  ></app-badge
                ></span></div
            ></mat-panel-title>
          </mat-expansion-panel-header>
          <!-- <ng-container
            *ngIf="
             domainTypeValue === COPY_DOMAIN_TYPE_KEYS.PROSIMO
            "
          >
            <div>View URL Rewrite Advanced Setting</div>
          </ng-container> -->
          <ng-container>
            <div
              [formGroup]="
                appSettingsForm?.controls[selectedDomainIndex]?.get(
                  'dnsService'
                )
              "
            >
              <ng-container *ngIf="isShowDNSType">
                <ng-container
                  *ngIf="
                    appSettingsForm?.controls[selectedDomainIndex]
                      ?.get('dnsService')
                      ?.get('type')?.value === COPY_DNS_OPTION_KEYS.MANUAL
                  "
                >
                  <ng-container [ngTemplateOutlet]="dns"> </ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!isShowDNSType">
                <ng-container [ngTemplateOutlet]="dns"> </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </mat-expansion-panel>
      </ng-container>

      <!--Cache Rules-->
      <ng-container
        *ngIf="
          appSettingsForm?.controls[selectedDomainIndex]?.get(
            'cacheRuleIDControl'
          ) as cacheRuleIDControl
        "
      >
        <mat-expansion-panel class="form-panel mat-expansion-panel-spacing">
          <mat-expansion-panel-header class="form-panel__header arrow">
            <mat-panel-title>
              <div class="d-flex align-items-center">
                <span>Cache Rules (Optional)</span>
                <span class="ml-2">
                  <app-badge
                    [badgeConfig]="
                      panelStatusConfig?.isCacheRuleStepCompleted
                        ? panelBadgeConfig?.COMPLETE
                        : panelBadgeConfig?.INCOMPLETE
                    "
                  ></app-badge
                ></span></div
            ></mat-panel-title>
          </mat-expansion-panel-header>
          <div
            *ngIf="
              selectSearchConfigForCacheRules?.optionList?.length;
              then ruleBlock;
              else noRuleBlock
            "
          ></div>
          <ng-template #ruleBlock>
            <div class="mb-2">
              <mat-label class="d-flex form__label mt-3">
                Select Cache Rule
              </mat-label>
              <div class="mt-2 w-50">
                <pcomp-mat-select-search
                  class="mat-form-field-wrapper-remove-padding"
                  [parentForm]="appSettingsForm?.controls[selectedDomainIndex]"
                  [config]="selectSearchConfigForCacheRules"
                  [formControlNameInput]="cacheRuleIDControl"
                  (emitSelections)="onChangeCacheRule($event?.value)"
                  controlName="cacheRuleIDControl"
                >
                </pcomp-mat-select-search>
              </div></div
          ></ng-template>
          <ng-template #noRuleBlock>
            <div class="my-2">No Cache rule available</div>
          </ng-template>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </div>
</div>
<ng-template #dns>
  <div *ngIf="!cnameValue" class="my-2">
    CNAME details will be available after creating an app
  </div>
  <div class="mt-3" *ngIf="cnameValue">
    <p class="page__hint">
      Create a CNAME record in the private hosted zone associated to the VPCs
      that will need to access this application. Copy CNAME record below.
    </p>
    <div class="cname__text" *ngIf="appURLData?.serviceIP">
      <div>
        IP: <strong>{{ appURLData?.serviceIP }}</strong>
      </div>
      <div>This IP is auto generated by Prosimo</div>
    </div>
    <ng-container *ngIf="cnameValue">
      <div class="cname__text mt-3">
        <div>CNAME Record</div>
        <div class="mt-1 d-flex">
          <span>Name:</span>
          <span class="ml-2">
            <strong>{{ appURLData?.appFqdn }}</strong>
          </span>
        </div>
        <div *ngIf="cnameValue" class="mt-1 mb-4 d-flex align-items-center">
          <div>Points to:</div>
          <div class="text-break ml-2">
            <strong>{{ cnameValue }}</strong>
          </div>
          <div
            class="cursor-pointer d-flex align-items-center ml-2"
            [cdkCopyToClipboard]="cnameValue"
            (click)="onCopyCNAME()"
          >
            <img [src]="COPY_ICON" width="15" alt="copy" />
            <i *ngIf="isCopy" class="status-active-clr ml-2 f-12">Copied!</i>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
