import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { PrefixInfo } from '@app/common/models';
import { ModelFormGroup } from '@app/common/util/form-util';
import { PDashLocalStoreUtil } from '@app/state/web-pdash.util';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-prefix-input',
  templateUrl: './prefix-input.component.html',
  styleUrls: ['./prefix-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrefixInputComponent implements OnInit, OnDestroy {
  stopSubs$: Subject<void>;

  @Input() uiThemeMode: string =
    PDashLocalStoreUtil.getUIThemeMode().toLowerCase();

  @Input() prefixForm: ModelFormGroup<PrefixInfo>;

  constructor(private cdr: ChangeDetectorRef) {
    this.stopSubs$ = new Subject();
  }

  ngOnInit(): void {
    this.prefixForm.valueChanges.pipe(takeUntil(this.stopSubs$)).subscribe({
      next: (data: PrefixInfo) => {
        this.validatePrefix(data);
      },
    });
  }

  validatePrefix(data: PrefixInfo) {
    if (this.prefixForm.controls.prefix.invalid) {
      delete this.prefixForm.controls.max.errors?.mask;
      delete this.prefixForm.controls.min.errors?.mask;
    }

    const mask = data.prefix?.split('/')?.[1];
    if (!mask || isNaN(parseInt(mask))) {
      delete this.prefixForm.controls.min.errors?.mask;
      delete this.prefixForm.controls.max.errors?.mask;
    }

    if (data.min < parseInt(mask)) {
      this.prefixForm.controls.min.setErrors({
        ...this.prefixForm.controls.min.errors,
        mask: 'Min mask should be greater than or equal to IP prefix mask',
      });
    } else {
      delete this.prefixForm.controls.min.errors?.mask;
    }

    if (data.max < parseInt(mask)) {
      this.prefixForm.controls.max.setErrors({
        ...this.prefixForm.controls.max.errors,
        mask: 'Max mask should be greater than or equal to IP prefix mask',
      });
    } else {
      delete this.prefixForm.controls.max.errors?.mask;
    }

    if (data.max < data.min && !this.prefixForm.controls.max.errors?.mask) {
      this.prefixForm.controls.max.setErrors({
        ...this.prefixForm.controls.max.errors,
        mask: 'Max mask should be greater than or equal to Min mask',
      });
    } else if (data.max >= data.min) {
      delete this.prefixForm.controls.max.errors?.mask;
    }

    if (Object.keys(this.prefixForm.controls.min.errors || {}).length === 0) {
      this.prefixForm.controls.min.setErrors(null);
    }

    if (Object.keys(this.prefixForm.controls.max.errors || {}).length === 0) {
      this.prefixForm.controls.max.setErrors(null);
    }
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.stopSubs$.next();
    this.stopSubs$.complete();
  }
}
