import {
  Component,
  OnInit,
  ElementRef,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { ObjectUtil } from '@prosimoio/services';
import {
  map,
  Control,
  tileLayer,
  marker,
  icon,
  geoJSON,
  polyline,
  Point,
} from 'leaflet';
import 'leaflet.markercluster';
import { GEO_MAP_CONSTANTS, HEATMAP_CONFIG } from './geo-map.constants';
import {
  GeoCircleMarkers,
  GeoHeatmapData,
  GeoMapConfig,
  GeoMapData,
} from './geo-map.model';
import * as L from 'leaflet';
import { CurvePathData } from '@elfalem/leaflet-curve';
import '@elfalem/leaflet-curve';
import 'leaflet.heat';
import { ShortNumberPipe } from '@prosimoio/pipes';
import { WindowResizeService } from '@prosimoio/services';
import { debounceTime } from 'rxjs/operators';
import {
  COLORS,
  IMAGE_PATHS,
  UI_THEME_MODES,
} from '@app/common/util/constants';
import { MathUtils } from '@app/common/util/math-util';

@Component({
  selector: 'app-geo-map',
  templateUrl: './geo-map.component.html',
  styleUrls: ['./geo-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeoMapComponent implements OnInit, OnChanges, OnDestroy {
  @Input() geoMapData: Array<GeoMapData> = [];
  @Input() geoLayerColorMap;
  @Input() geoJSONData: any;
  @Input() geoMapIcons: any;
  @Input() geoCoordinates: any;
  @Input() showTooltip = true;
  @Input() showHoverTooltip = false;
  @Input() set geoMapConfig(data: GeoMapConfig) {
    this._geoMapConfig = data || {
      zoomLevel: 2,
      view: '',
      viewInitGeo: [20, 0],
      clusterConfig: {
        useClusters: false,
        clusterTooltip: () => '',
      },
    };
    this.configureGeoMapView();
  }
  @Input() multiSelect = false;
  @Input() showGeoHeatmap = false;
  @Input() geoHeatmapData: Array<GeoHeatmapData> = [];
  @Output() changeMapData = new EventEmitter();
  @Output() selectPolyline = new EventEmitter();
  @Output() selectCircleMarker = new EventEmitter();
  @Input() animateCurveLine: boolean = false;
  @Input() polylineDetails: Array<any> = [];
  @Input() circleMarkerDetails: Array<any> = [];
  @Input() isDrawPolyline: boolean = false;
  @Input() set uiThemeMode(mode: UI_THEME_MODES) {
    this._uiThemeMode = mode;
    if (this.tileLayer) {
      this.tileLayer.setUrl(this.getTemplateUrl());
    }
  }

  geoMapInst: L.Map;
  geoJSONInst: L.GeoJSON;
  markerLayersMap = new Map<string, any>();
  polylineLayersMap = new Map<string, any>();
  heatmapLayer;
  geoLayersMap: Map<string, any>;
  polyLinesGroup;
  circleMarkerGroup;
  circleMarkerLayersMap = new Map<string, any>();
  _geoMapConfig: GeoMapConfig;
  markerCluster: L.MarkerClusterGroup;
  subscriptions = [];
  isSpiderifying: boolean = false;
  spiderfiedClustersMap: Set<string> = new Set();
  _uiThemeMode: UI_THEME_MODES;
  tileLayer: L.TileLayer;
  center: L.LatLng;
  GEOMAP_BG_COLOR = COLORS.WHITE;
  tooltipClickElement: HTMLElement = null;
  tooltipClickElements: HTMLCollectionOf<Element>;

  constructor(
    private elemRef: ElementRef,
    private shortNumber: ShortNumberPipe,
    private windowResizeEventService: WindowResizeService
  ) {
    this.subscriptions.push(
      this.windowResizeEventService.onResize
        .pipe(debounceTime(500))
        .subscribe((newWindowInst) => {
          this.geoMapInst.invalidateSize();
          this.setGeomapMinZoom();
        })
    );
  }

  ngOnInit() {
    this.createGeoMapInst();
    this.addTileLayer();
    this.addGeoMapControls();
    this.configureGeoMapView();
    this.stopPropagationFromOverlayElements();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.createGeoMapInst();
    if (this.geoMapData) {
      this.geoMapData.forEach((geoData, i) => {
        geoData.layerKey = `${geoData.name}_${geoData.lat}_${geoData.lon}_${i}`;
        geoData.displayRegions = false;
      });
    }

    this.geoLayersMap = new Map();
    this.clearGeoJSONLayers();
    this.plotMarkers();

    if (this.polylineDetails) {
      this.polylineDetails.forEach((polyline, i) => {
        polyline.layerKey = `${polyline.name}_${polyline.lat}_${polyline.lon}_${i}`;
        polyline.displayRegions = false;
      });
    }

    if (this.isDrawPolyline) {
      this.drawPolylines();
    }

    if (this.circleMarkerDetails) {
      this.circleMarkerDetails.forEach((polyline, i) => {
        polyline.layerKey = `${polyline.name}_${polyline.lat}_${polyline.lon}_${i}`;
      });
      this.drawCircleMarker();
    }

    /**
     * If geoJSONData is available then shade the countries / states
     * available in the supported geo list
     */
    if (
      this.geoJSONData &&
      this.geoJSONData.supportedGeo &&
      this.geoJSONData.supportedGeo.length
    ) {
      this.addGeoJSONLayer();
    }

    if (this.showGeoHeatmap) {
      this.renderHeatmapLayer();
    } else {
      if (this.heatmapLayer) {
        this.geoMapInst.removeLayer(this.heatmapLayer);
        this.heatmapLayer = null;
      }
    }
  }

  resizeMap() {
    if (this._geoMapConfig.zoomToUsedRegion) {
      const { bounds, medianLatitude } = this.getZoomedBounds();
      this.geoMapInst.setView(
        { lat: medianLatitude, lng: bounds.getCenter().lng },
        this.geoMapInst.getBoundsZoom(bounds, false)
      );
    } else {
      const bounds = L.latLngBounds([
        [95, 185],
        [-95, -185],
      ]);
      this.geoMapInst.setView(
        this.center,
        this.geoMapInst.getBoundsZoom(bounds, true)
      );
    }
  }

  getZoomedBounds(): { bounds: L.LatLngBounds; medianLatitude: number } {
    let latitudes = this.geoMapData?.map((loc) => loc.lat) || [];
    const medianLatitude =
      latitudes.length === 2
        ? MathUtils.mean(latitudes)
        : MathUtils.median(latitudes, 38);
    const bounds = L.latLngBounds(
      this.geoMapData.map((data) => [data.lat, data.lon])
    ).pad(0.2);
    return { bounds, medianLatitude };
  }
  /**
   * Create a Geo-map instance
   */
  createGeoMapInst() {
    if (!this.geoMapInst) {
      this.geoMapInst = map(this.elemRef.nativeElement.parentElement, {
        zoomControl: false,
        zoomSnap: 0.05,
      });
    }
  }

  /**
   * Adds a tile layer to the leaflet geomap.
   */

  addTileLayer() {
    this.tileLayer = tileLayer(this.getTemplateUrl(), {
      attribution: GEO_MAP_CONSTANTS.ATTRIBUTION,
      maxZoom: 18,
      accessToken: GEO_MAP_CONSTANTS.ACCESS_TOKEN,
      noWrap: true,
      detectRetina: true,
      bounds: [
        [-90, -180],
        [90, 180],
      ],
    });
    this.tileLayer.addTo(this.geoMapInst);
  }

  /**
   * Get Mapbox URL for leaflet tiles based on the theme
   * @returns Mapbox URL for leaflet tiles
   */
  getTemplateUrl() {
    return `https://api.mapbox.com/styles/v1/prosimo/${
      GEO_MAP_CONSTANTS[
        `${this._uiThemeMode || UI_THEME_MODES.LIGHT}_MAP_STYLE_ID`
      ]
    }/tiles/256/{z}/{x}/{y}@2x?access_token={accessToken}`;
  }

  /**
   * Handle geoJSONInst creation and layers rendering logics
   * @param data
   */
  addGeoJSONLayer(data?: any) {
    if (!this.geoJSONInst) {
      this.geoJSONInst = geoJSON().addTo(this.geoMapInst);
    }
    this.renderGeoJSONLayer(data || this.geoJSONData);
  }

  /**
   * Handle regions and markers rendering logic
   * @param data
   */
  renderGeoJSONLayer(data: any = []) {
    if (this.multiSelect) {
      this.renderMultipleSelectLayers(data);
    } else {
      this.renderSingleSelectLayers(data);
    }
    this.geoJSONInst.setStyle(this.setStyle.bind(this));
  }

  /**
   * Handle single icon selection rendering logic
   * @param data
   */
  renderSingleSelectLayers(data: GeoMapData) {
    this.geoJSONInst.clearLayers();
    if (data.displayRegions) {
      data.displayRegions = false;
    } else {
      const mapData = this.geoMapData || [];
      mapData.forEach((geoData) => {
        geoData.displayRegions = false;
        this.toggleIconState(geoData, false);
      });
      data.displayRegions = true;
      const geoJson = this.getGeoJson(data);
      this.geoJSONInst.addData(geoJson);
    }
    this.toggleIconState(data, data.displayRegions);
  }

  /**
   * Handle multi icons selection rendering logic
   * @param data
   */
  renderMultipleSelectLayers(data: GeoMapData) {
    const geoDataLayer = this.geoLayersMap.get(data.layerKey);
    if (geoDataLayer) {
      // if the data is already selected, remove the dataLayer entry from the map and clear the map
      // after that redraw the region
      this.geoLayersMap.delete(data.layerKey);
      data.displayRegions = false;
      geoDataLayer.clearLayers();
      this.geoMapData
        .filter((geoData) => this.geoLayersMap.has(geoData.layerKey))
        .forEach((geoData) => {
          const geoJson = this.getGeoJson(geoData);
          this.geoJSONInst.addData(geoJson);
        });
    } else {
      const geoJson = this.getGeoJson(data);
      const geoLayer = this.geoJSONInst.addData(geoJson);
      this.geoLayersMap.set(data.layerKey, geoLayer);
      data.displayRegions = true;
    }
    this.toggleIconState(data, data.displayRegions);
  }

  /**
   * Return the geo data of the supported regions
   * @param data
   */
  getGeoJson(data: any = []) {
    const supportedGeos = data && data.supportedGeo ? data.supportedGeo : [];
    const geoJson: any = supportedGeos
      .filter((geo) => this.geoCoordinates[geo])
      .map((geo) => {
        const { type = '', coordinates = [] } = this.geoCoordinates[geo] || {};
        return {
          type,
          coordinates,
          status: data.layerStatus || data.status,
          geoKey: geo,
        };
      });
    return geoJson;
  }

  /**
   * Return the marker instance based on geoMapData layerKey property
   * @param param0
   */
  getMarkerInstance({ layerKey: key = '' }) {
    return this.markerLayersMap.get(key);
  }

  getPolylineInstance({ layerKey: key = '' }) {
    return this.polylineLayersMap.get(key);
  }

  getCircleMarkerInstance({ layerKey: key = '' }) {
    this.circleMarkerLayersMap.get(key);
  }

  clearGeoJSONLayers() {
    if (this.geoJSONInst) {
      this.geoJSONInst.clearLayers();
    }
  }

  getColor(status: string) {
    return status === 'up'
      ? GEO_MAP_CONSTANTS.HEATMAP_COLORS.GREEN
      : status === 'down'
      ? GEO_MAP_CONSTANTS.HEATMAP_COLORS.RED
      : GEO_MAP_CONSTANTS.HEATMAP_COLORS.UNKNOWN;
  }

  setStyle(data) {
    return {
      fillColor: this.geoLayerColorMap
        ? this.geoLayerColorMap.get(data.geometry.geoKey)
        : this.getColor(data.geometry.layerStatus || data.geometry.status),
      color: 'white',
      weight: 1,
      opacity: 0.5,
      dashArray: '1',
      fillOpacity: 0.3,
    };
  }

  /**
   * Adds the controls to geo-map.
   * Supported controls by geo-map component are
   * a) Zoom
   * b) Search - WIP
   */

  addGeoMapControls() {
    new Control.Zoom({ position: 'topright' }).addTo(this.geoMapInst);

    var control = new L.Control({ position: 'topright' });
    control.onAdd = (map) => {
      var azoom = L.DomUtil.create('img', 'reset-zoom-btn');
      azoom.src = IMAGE_PATHS.COMMON.RESET_MAP;
      azoom.width = 29;
      azoom.height = 29;
      azoom.title = 'Reset Zoom';
      azoom.classList.add('svg-filter-grey');
      L.DomEvent.disableClickPropagation(azoom).addListener(
        azoom,
        'click',
        () => {
          this.resizeMap();
        },
        azoom
      );
      return azoom;
    };
    control.addTo(this.geoMapInst);
  }

  /**
   * Sets the default geomap's center point and zoom level
   */

  configureGeoMapView() {
    /**
     * set the extent of geomap
     */
    if (this.geoMapInst) {
      const { viewInitGeo = [20, 0], zoomLevel = 1 } = this._geoMapConfig || {};
      if (this._geoMapConfig.zoomToUsedRegion && this.geoMapData?.length) {
        const { bounds, medianLatitude } = this.getZoomedBounds();

        this.geoMapInst.setView(
          { lat: medianLatitude, lng: bounds.getCenter().lng },
          this.geoMapInst.getBoundsZoom(bounds, false)
        );
      } else {
        this.geoMapInst.setView(viewInitGeo, zoomLevel);
      }
      this.center = this.geoMapInst.getCenter();
      this.setGeomapMinZoom();
    }
  }

  /**
   * Adds pins based on the lat,lng co-ordinates
   */

  plotMarkers() {
    if (!this.geoMapData) {
      return;
    }
    this.clearMarkers();
    this.clearPolylines();
    this.markerLayersMap = new Map();
    this.polylineLayersMap = new Map();
    if (this._geoMapConfig?.clusterConfig?.useClusters) {
      this.markerCluster = new L.MarkerClusterGroup({
        zoomToBoundsOnClick: false,
        showCoverageOnHover: false,
        maxClusterRadius: 150,
        animate: false,
        iconCreateFunction: this.clusterIconCreateFunction.bind(this),
        spiderfyShapePositions: function (count, centerPt) {
          var distanceFromCenter = 50,
            markerDistance = 100,
            lineLength = markerDistance * (count - 1),
            lineStart = centerPt.y - lineLength / 2,
            res = [],
            i;

          res.length = count;

          for (i = count - 1; i >= 0; i--) {
            res[i] = new Point(
              centerPt.x + distanceFromCenter,
              lineStart + markerDistance * i
            );
          }

          return res;
        },
      });

      this.markerCluster.on('clusterclick', (marker: any) => {
        var bounds = marker.layer.getBounds().pad(0.5);
        this.geoMapInst.fitBounds(bounds);
      });
    }
    this.geoMapData.forEach((data) => {
      this.markerLayersMap.set(data.layerKey, this.getMarker(data));
      this.polylineLayersMap.set(data.layerKey, this.getPolyline(data));
      this.circleMarkerLayersMap.set(data.layerKey, this.getPolyline(data));
      const {
        polylineData = [],
        polylineConfig = { color: 'black', dashArray: '10', weight: 1 },
      } = data || {};
      const {
        curvelineData = [],
        curvelineConfig = { color: 'black', dashArray: '0', weight: 1 },
      } = data || {};

      if (polylineData.length) {
        if (!this.polyLinesGroup) {
          this.polyLinesGroup = L.layerGroup();
        }

        const polyline = L.polyline(polylineData, polylineConfig);
        polyline.on('click', () => this.selectPolyline.emit(data));
        this.polyLinesGroup.addLayer(polyline);
        this.polyLinesGroup.addTo(this.geoMapInst);
      }

      if (curvelineData.length) {
        if (!this.polyLinesGroup) {
          this.polyLinesGroup = L.layerGroup();
        }
        this.addCurveLine(curvelineData, curvelineConfig);
      }
    });
    if (this._geoMapConfig?.clusterConfig?.useClusters) {
      this.generateClusters();
    }
  }

  generateClusters() {
    this.markerCluster.addTo(this.geoMapInst);

    this.markerCluster.on('animationend', (event) => {
      this.spiderfiedClustersMap.clear();
    });

    this.markerCluster.on(
      'spiderfied',
      (event: L.MarkerClusterSpiderfyEvent) => {
        this.spiderfiedClustersMap.add(event.cluster['id']);
      }
    );

    if (this._geoMapConfig?.clusterConfig?.clusterTooltip) {
      this.markerCluster.on('clustermouseover', (event: L.LeafletEvent) => {
        event.propagatedFrom
          .bindPopup(this.generateClusterTooltip(event.propagatedFrom), {
            maxWidth: 500,
          })
          .openPopup();
      });
    }
  }

  generateClusterTooltip(cluster: L.MarkerCluster) {
    return this._geoMapConfig?.clusterConfig?.clusterTooltip(
      cluster
        .getAllChildMarkers()
        .map((marker) => marker.options.icon.options['clusterData'])
    );
  }

  clusterIconCreateFunction(cluster: L.MarkerCluster) {
    var childCount = cluster.getChildCount();
    cluster['id'] = cluster
      .getAllChildMarkers()
      .map((icon) => icon.options.icon.options['clusterData'])
      .sort()
      .join('-');
    const hasSelectedMarker = cluster
      .getAllChildMarkers()
      .some((marker) => marker.options.icon.options['isSelected']);

    var c = ' marker-cluster-large bg-light-shade';
    if (hasSelectedMarker) {
      c += ' selected';
    }
    if (this.spiderfiedClustersMap.has(cluster['id'])) {
      cluster.spiderfy();
    }

    return new L.DivIcon({
      html: '<div><span>' + childCount + '</span></div>',
      className: 'marker-cluster' + c,
      iconSize: new L.Point(40, 40),
    });
  }

  drawCircleMarker() {
    if (!this.circleMarkerDetails.length) {
      this.clearCircleMarkers();
      return;
    }

    this.clearCircleMarkers();
    this.circleMarkerDetails?.forEach((markerObj) => {
      this.circleMarkerLayersMap.set(
        markerObj.layerKey,
        this.getCircleMarker(markerObj)
      );
      const { lat, lon } = markerObj || {};

      if (lat && lon) {
        if (!this.circleMarkerGroup) {
          this.circleMarkerGroup = L.layerGroup();
        }

        let circleDivSelectors =
          'map-marker-count-circle d-flex align-items-center justify-content-center';

        if (markerObj?.className) {
          circleDivSelectors = `${circleDivSelectors} ${markerObj?.className}`;
        }

        const circleMarker = L.divIcon({
          iconSize: null,
          html: `<div class="${circleDivSelectors}">
                 <span>${this.shortNumber.transform(
                   markerObj?.count || 0
                 )}</span>
                </div>`,
          popupAnchor: markerObj?.popupAnchor,
        });

        const marker = L.marker({ lat, lng: lon }, { icon: circleMarker });

        const tooltip = markerObj?.tooltipTemplate;
        if (tooltip) {
          marker.on('mouseover', () => {
            marker
              .bindPopup(tooltip, { maxWidth: 500, closeButton: false })
              .openPopup();
          });
        }

        this.circleMarkerGroup.addLayer(marker);
        this.circleMarkerGroup.addTo(this.geoMapInst);
      }
    });
  }

  drawPolylines() {
    if (!this.polylineDetails.length) {
      this.clearPolylines();
      return;
    }

    this.clearPolylines();
    this.polylineDetails?.forEach((polyData) => {
      this.polylineLayersMap.set(polyData.layerKey, this.getPolyline(polyData));
      const {
        polylineData = [],
        polylineConfig = { color: 'black', dashArray: '10', weight: 1 },
      } = polyData || {};
      const {
        curvelineData = [],
        curvelineConfig = { color: 'black', dashArray: '0', weight: 1 },
      } = polyData || {};

      if (polylineData.length) {
        if (!this.polyLinesGroup) {
          this.polyLinesGroup = L.layerGroup();
        }

        const polyline = L.polyline(polylineData, polylineConfig);
        polyline.on('click', () => this.selectCircleMarker.emit(polyData));
        this.polyLinesGroup.addLayer(polyline);
        this.polyLinesGroup.addTo(this.geoMapInst);
      }

      if (curvelineData.length) {
        if (!this.polyLinesGroup) {
          this.polyLinesGroup = L.layerGroup();
        }
        this.addCurveLine(curvelineData, curvelineConfig);
      }
    });
  }
  /**
   * clear poly line
   */
  clearPolylines() {
    if (this.polyLinesGroup) {
      this.polyLinesGroup.clearLayers();

      this.polyLinesGroup?.eachLayer((layer) => {
        this.geoMapInst?.removeLayer(layer);
      });
    }
  }

  clearCircleMarkers() {
    if (this.circleMarkerGroup) {
      this.circleMarkerGroup.clearLayers();

      this.circleMarkerGroup?.eachLayer((layer) => {
        this.geoMapInst?.removeLayer(layer);
      });
    }
  }

  /**
   * Return the marker instance
   * @param data
   */
  getMarker(data: GeoMapData) {
    const markerInst = marker([data.lat, data.lon], {
      icon: this.getMarkerPin(data),
    });

    if (data.supportedGeo && data.supportedGeo.length) {
      markerInst.on('click', () => {
        this.markerClickHandler(data);
      });
    }

    if (data.emitDataOnClick) {
      markerInst.on('click', () => {
        this.emitData(data);
      });
    }

    if (data.emitSelectedMarker) {
      markerInst.on('click', () => {
        this.markerClickHandler(data);
        this.emitData(data);
      });
    }

    if (this.showHoverTooltip) {
      const tooltip = this.getTooltipTemplate(data);
      if (tooltip) {
        markerInst.on('mouseover', () => {
          markerInst
            .bindPopup(this.getTooltipTemplate(data), {
              maxWidth: 500,
              closeButton: false,
            })
            .addEventListener('popupopen', () => {
              setTimeout(() => {
                if (data?.tooltipClickInfo) {
                  this.tooltipClickElement?.removeAllListeners();
                  this.tooltipClickElement = null;
                  for (let i = 0; i < this.tooltipClickElements?.length; i++) {
                    this.tooltipClickElements.item(i).removeAllListeners();
                  }
                  this.tooltipClickElements = null;

                  const clickInfo = data?.tooltipClickInfo;

                  if (clickInfo?.elementId) {
                    this.tooltipClickElement = document.getElementById(
                      clickInfo?.elementId
                    );

                    this.tooltipClickElement.addEventListener(
                      'click',
                      (event) => {
                        return clickInfo.tooltipClickFn?.call(this, {
                          target: event.target['id'],
                        });
                      }
                    );
                  }

                  if (clickInfo.elementClass) {
                    this.tooltipClickElements = document.getElementsByClassName(
                      clickInfo.elementClass
                    );
                    for (let i = 0; i < this.tooltipClickElements.length; i++) {
                      this.tooltipClickElements
                        .item(i)
                        .addEventListener('click', (event) => {
                          return clickInfo.tooltipClickFn.call(this, {
                            target: event.target['id'],
                          });
                        });
                    }
                  }
                }
              }, 200);
              this.updateTooltipClass();
            })
            .openPopup();
        });
      }
    }

    if (this.showTooltip) {
      markerInst
        .bindPopup(this.getTooltipTemplate(data), { closeButton: false })
        .openTooltip();
    }
    if (this._geoMapConfig?.clusterConfig?.useClusters) {
      markerInst.addTo(this.markerCluster);
    } else {
      markerInst.addTo(this.geoMapInst);
    }

    return markerInst;
  }

  markerClickHandler(data: any) {
    this.addGeoJSONLayer(data);
  }

  getPolyline(data: GeoMapData) {
    if (!data?.polylineData) {
      return;
    }
    const polylineInst = polyline(data.polylineData);
    return polylineInst;
  }

  getCircleMarker(data: GeoCircleMarkers) {
    if (!data?.lat && !data?.lon) {
      return;
    }
    const circleInst = marker({ lat: data?.lat, lng: data?.lon });

    return circleInst;
  }

  emitData(data) {
    this.changeMapData.emit(data);
  }

  /**
   * Clears any pins on the geo-map
   */

  clearMarkers() {
    if (this.markerCluster) {
      this.markerCluster.clearLayers();
    }
    if (this.markerLayersMap && this.markerLayersMap.size > 0) {
      this.markerLayersMap.forEach((tooltipMarker: any) => {
        this.geoMapInst.removeLayer(tooltipMarker);
      });
    }

    if (this.geoJSONInst) {
      this.geoJSONInst.clearLayers();
    }
  }

  /**
   * Toggle the icon state (selected and unselected)
   * @param data
   * @param state
   */
  toggleIconState(data: GeoMapData, displayState: boolean = false) {
    data = Object.assign(data, { isSelected: false });
    const dataIcon = this.getMarkerPin(data, displayState);
    const dataMarker = this.getMarkerInstance(data);
    if (dataMarker) {
      dataMarker.setIcon(dataIcon);
    }
  }

  /**
   * Return the marker pin
   * @param data
   * @param selected
   */
  getMarkerPin(data: any, selected?: boolean) {
    let iconUrl;
    if (!selected && data?.isSelected) {
      selected = data.isSelected;
    }
    const prefixTxt = selected ? 'selected_' : '';
    if (ObjectUtil.hasKeys(this.geoMapIcons)) {
      if (data.name && data.status) {
        const iconUrlPropName =
          `${prefixTxt}${data.name}_${data.status}`.toUpperCase();
        iconUrl = this.geoMapIcons[iconUrlPropName];
      } else if (data.iconURL) {
        iconUrl = data.iconURL;
      } else {
        iconUrl = this.geoMapIcons.STANDARD;
      }
    }

    let iconObj = this.getIcon(iconUrl, selected);

    // Icon specific classes
    if (data?.className) {
      iconObj = Object.assign(iconObj, { className: data?.className });
    }

    // Icon specific size
    if (data?.iconSize) {
      iconObj = Object.assign(iconObj, { iconSize: data?.iconSize });
    }

    // Icon specific popup anchor
    if (data?.popupAnchor) {
      iconObj = Object.assign(iconObj, { popupAnchor: data?.popupAnchor });
    }
    return data?.markerIcon
      ? this.getDivIcon(iconUrl, selected, data)
      : this.getIcon(iconUrl, selected, data);
  }

  /**
   * Return the icon instance
   * @param iconUrl
   */
  getIcon(iconUrl: string = '', selected: boolean = false, data: any = null) {
    const customGeoMapIconDims = this._geoMapConfig?.geoMapIconDimension
      ? this._geoMapConfig?.geoMapIconDimension
      : null;

    let iconObj: any = {
      iconUrl,
      iconSize: customGeoMapIconDims
        ? [customGeoMapIconDims?.width, customGeoMapIconDims?.height]
        : selected
        ? [50, 50]
        : [40, 40], // size of the icon
      iconAnchor: selected ? [25, 50] : [20, 40], // point of the icon which will correspond to marker's location
    };

    // Icon specific classes
    if (data?.className) {
      iconObj = Object.assign(iconObj, { className: data?.className });
    }

    // Icon specific anchor
    if (data?.iconAnchor) {
      iconObj = Object.assign(iconObj, { iconAnchor: data?.iconAnchor });
    }

    // Icon specific size
    if (data?.iconSize) {
      iconObj = Object.assign(iconObj, { iconSize: data?.iconSize });
    }

    // Icon specific popup anchor
    if (data?.popupAnchor) {
      iconObj = Object.assign(iconObj, { popupAnchor: data?.popupAnchor });
    }

    if (data?.clusterData) {
      iconObj = Object.assign(iconObj, { clusterData: data?.clusterData });
    }
    return icon(iconObj);
  }

  getDivIcon(
    iconUrl: string = '',
    selected: boolean = false,
    data: any = null
  ) {
    const customGeoMapIconDims = this._geoMapConfig?.geoMapIconDimension
      ? this._geoMapConfig?.geoMapIconDimension
      : null;

    let iconObj: any = {
      iconSize: customGeoMapIconDims
        ? [customGeoMapIconDims?.width, customGeoMapIconDims?.height]
        : selected
        ? [50, 50]
        : [40, 40], // size of the icon
      iconAnchor: selected ? [25, 50] : [20, 40], // point of the icon which will correspond to marker's location,
      isSelected: data?.isSelected || false,
    };
    // Icon specific classes
    if (data?.className) {
      iconObj = Object.assign(iconObj, { className: data?.className });
    }

    // Icon specific anchor
    if (data?.iconAnchor) {
      iconObj = Object.assign(iconObj, { iconAnchor: data?.iconAnchor });
    }

    // Icon specific size
    if (data?.iconSize) {
      iconObj = Object.assign(iconObj, { iconSize: data?.iconSize });
    }

    // Icon specific popup anchor
    if (data?.popupAnchor) {
      iconObj = Object.assign(iconObj, { popupAnchor: data?.popupAnchor });
    }

    if (data?.markerIcon) {
      iconObj = Object.assign(iconObj, { html: data?.markerIcon(iconUrl) });
    }

    if (data?.clusterData) {
      iconObj = Object.assign(iconObj, { clusterData: data?.clusterData });
    }

    if (data?.isSelected) {
      const className = iconObj?.className || '';
      iconObj = Object.assign(iconObj, {
        className: `${className} selected-cluster-child`,
      });
    }

    return new L.DivIcon(iconObj);
  }

  getTooltipTemplate(data: any) {
    const { view } = this._geoMapConfig || {};
    if (!view) {
      return `<div>
              <div> <span class="geo-map-tooltip-city-text"> ${
                data.city || data?.country || 'NA'
              },</span> ${data.countryIsoCode}</div>
              <p> Unique Users : ${data.count}</p>
            </div>`;
    } else if (data.tooltipTemplate) {
      return data.tooltipTemplate;
    }
  }

  renderHeatmapLayer() {
    let heatmapData: any =
      this.geoHeatmapData && this.geoHeatmapData.length
        ? this.geoHeatmapData
        : this.getGeoHeatmapData();

    if (heatmapData && !heatmapData.length) {
      return;
    }

    if (!this.heatmapLayer) {
      heatmapData = heatmapData.map(({ lat, lon, count }) => [lat, lon, count]);
      this.heatmapLayer = L.heatLayer(heatmapData, HEATMAP_CONFIG);
      this.heatmapLayer.addTo(this.geoMapInst);
    }
  }

  getGeoHeatmapData() {
    return this.geoMapData
      ? this.geoMapData.map((data) => {
          return {
            lat: data.lat,
            lon: data.lon,
            count: data.count || 0,
          };
        })
      : [];
  }

  /**
   *
   * Reference @elfalem/leaflet-curve
   * @param curvelineData
   * @param curvelineConfig
   */
  addCurveLine(curvelineData: Array<any>, curvelineConfig: any) {
    const latlng1: number[] = curvelineData[0],
      latlng2: number[] = curvelineData[1];

    const midpointLatLng = this.calculateCurveLineMidpoint(curvelineData);

    const curveConfig: CurvePathData = [
      'M',
      [latlng1[0], latlng1[1]],
      'Q',
      [midpointLatLng[0], midpointLatLng[1]],
      [latlng2[0], latlng2[1]],
    ];

    let options = {
      color: curvelineConfig?.color,
      dashArray: curvelineConfig?.dashArray,
      weight: curvelineConfig?.weight,
    };
    if (this.animateCurveLine) {
      options = Object.assign(options, { animate: 500 });
    }
    const curvedPath = L.curve(curveConfig, options);
    this.polyLinesGroup.addLayer(curvedPath);
    this.polyLinesGroup.addTo(this.geoMapInst);
  }

  /**
   * Calculate midpoint x and y to make line bend
   * Reference @elfalem/leaflet-curve
   * @param curvelineData
   * @returns
   */
  calculateCurveLineMidpoint(curvelineData: Array<any>) {
    const latlng1: number[] = curvelineData[0],
      latlng2: number[] = curvelineData[1];

    const offsetX = latlng2[1] - latlng1[1],
      offsetY = latlng2[0] - latlng1[0];

    const r = Math.sqrt(Math.pow(offsetX, 2) + Math.pow(offsetY, 2)),
      theta = Math.atan2(offsetY, offsetX);

    const thetaOffset = 3.14 / (Math.abs(offsetX) < 200 ? 11 : 15);

    const r2 = r / 2 / Math.cos(thetaOffset),
      theta2 = theta + thetaOffset;

    const midpointX = r2 * Math.cos(theta2) + latlng1[1],
      midpointY = r2 * Math.sin(theta2) + latlng1[0];

    return [midpointY, midpointX];
  }

  setGeomapMinZoom() {
    if (!this.geoMapInst) {
      return;
    }
    if (this.geoMapData?.length > 0 && this._geoMapConfig?.zoomToUsedRegion) {
      this.geoMapInst.setZoom(this.geoMapInst.getZoom(), { animate: false });
    } else {
      this.geoMapInst.setZoom(
        this.geoMapInst.getBoundsZoom(
          [
            [95, 185],
            [-95, -185],
          ],
          true
        ),
        {
          animate: false,
        }
      );
    }
  }

  stopPropagationFromOverlayElements() {
    const divs = document.getElementsByClassName('stop_event_propagation');
    for (let i = 0; i < divs.length; i++) {
      const div = divs.item(i) as HTMLElement;
      L.DomEvent.on(div, 'mouseenter', this.disableDrag.bind(this));
      L.DomEvent.on(div, 'mouseleave', this.enableDrag.bind(this));
      L.DomEvent.on(div, 'dblclick', L.DomEvent.stopPropagation);
    }
  }

  removeListeners() {
    const divs = document.getElementsByClassName('stop_event_propagation');
    for (let i = 0; i < divs.length; i++) {
      const div = divs.item(i) as HTMLElement;
      L.DomEvent.removeListener(div, 'mouseenter', this.disableDrag.bind(this));
      L.DomEvent.removeListener(div, 'mouseleave', this.enableDrag.bind(this));
      L.DomEvent.on(div, 'dblclick', L.DomEvent.stopPropagation);
    }
  }

  disableDrag() {
    return this.geoMapInst.dragging.disable();
  }

  enableDrag() {
    return this.geoMapInst.dragging.enable();
  }

  /**
   * add a class when popup is display
   */
  updateTooltipClass() {
    const wrapper = document.getElementsByClassName(
      GEO_MAP_CONSTANTS.MAP_CLASS.POPUP_WRAPPER
    );
    const popupTip = document.getElementsByClassName(
      GEO_MAP_CONSTANTS.MAP_CLASS.POPUP_TIP
    );
    const leafPopup = document.getElementsByClassName(
      GEO_MAP_CONSTANTS.MAP_CLASS.LEAF_POPUP
    );

    Array.prototype.forEach.call(wrapper, (item: HTMLElement) =>
      item.classList.add(
        GEO_MAP_CONSTANTS.MAP_CLASS.LIGHT_SHADE,
        GEO_MAP_CONSTANTS.MAP_CLASS.BORDER_RADIUS_NONE
      )
    );
    Array.prototype.forEach.call(popupTip, (item: HTMLElement) =>
      item.classList.add(GEO_MAP_CONSTANTS.MAP_CLASS.LIGHT_SHADE)
    );
    Array.prototype.forEach.call(leafPopup, (item: HTMLElement) =>
      item.classList.add(GEO_MAP_CONSTANTS.MAP_CLASS.BORDER_SECONDARY_THIN)
    );
  }

  ngOnDestroy() {
    this.removeListeners();
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }
}
