import {
  cloudTransitSharedFilterStore,
  CloudTransitSharedFilterStore,
} from './cloud-transit-filter.store';

export class CloudTransitStoreFilterService {
  constructor(private cloudTransitFilterStore: CloudTransitSharedFilterStore) {}

  updateActiveTab(activeTab: string) {
    this.cloudTransitFilterStore.update((state) => ({
      activeTab,
    }));
  }
}

export const cloudTransitStoreFilterService =
  new CloudTransitStoreFilterService(cloudTransitSharedFilterStore);
