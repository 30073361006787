import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { getWellKnownCommunityValue } from '@app/common/util/bgp-util';

export const BGPCommunityValidator: ValidatorFn = (
  control: AbstractControl<string>
): ValidationErrors | null => {
  if (!control.value || control.value.length === 0) {
    delete control.errors?.pattern;
    return control.errors;
  }

  if (control.hasError('required')) {
    delete control.errors?.required;
  }

  if (getWellKnownCommunityValue(control.value, true)) {
    delete control.errors?.pattern;
    return control.errors;
  }

  const parts: Array<number> = control.value
    .split(':')
    .map((part) => parseInt(part));
  if (!parts || parts.length !== 2 || isNaN(parts[0]) || isNaN(parts[1])) {
    return {
      ...control.errors,
      pattern: true,
    };
  }

  if (!parts.every((part) => part >= 1 && part <= 65536)) {
    return {
      ...control.errors,
      pattern: true,
    };
  }

  delete control.errors?.pattern;
  return control.errors;
};
