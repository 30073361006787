import {
  PlaygroundRecentSearchStore,
  playgroundRecentSearchStore,
} from './playground-recent-search.store';
import { PlaygroundRecentSearch } from './playground-recent-search.model';

export class PlaygroundRecentSearchService {
  constructor(private recentSearchStore: PlaygroundRecentSearchStore) {}

  updateRecentSearchStore(data: PlaygroundRecentSearch) {
    this.recentSearchStore.add(data, { prepend: true });
  }

  updateRecentSearchStoreById(id: string, data: PlaygroundRecentSearch) {
    this.recentSearchStore.update(id, data);
  }
  removeFilter(id: number) {
    this.recentSearchStore.remove(id);
  }
  resetStore() {
    this.recentSearchStore.reset();
  }
}

export const playgroundRecentSearchService = new PlaygroundRecentSearchService(
  playgroundRecentSearchStore
);
