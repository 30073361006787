export const GEO_MAP_CONSTANTS = {
  ACCESS_TOKEN:
    'pk.eyJ1IjoicHJvc2ltbyIsImEiOiJjazUyMjl1MTEwZnFwM2xwODNzbGN3N3BsIn0.ZpqFgXAR2jjeMlG6XZVgXQ',
  LIGHT_MAP_STYLE_ID: 'clfc3f0sr000a01qcbpt9qmkw',
  DARK_MAP_STYLE_ID: 'clfc3msuf000101qubxeugg5m',
  ATTRIBUTION: `
            &#xA9; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &#xA9;
            <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>
            <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>`,

  HEATMAP_COLORS: {
    RED: '#ff3838',
    YELLOW: '#ffb34b',
    GREEN: '#02962c',
    UNKNOWN: '#bc80bd',
  },
  MAP_CLASS: {
    POPUP_WRAPPER: 'leaflet-popup-content-wrapper',
    POPUP_TIP: 'leaflet-popup-tip',
    LEAF_POPUP: 'leaflet-popup',
    BOTTOM_CONTROL: 'leaflet-control-attribution',
    LIGHT_SHADE: 'bg-light-shade',
    BORDER_SECONDARY_THIN: 'border-secondary-thin',
    BORDER_RADIUS_NONE: 'border-radius-none',
  },
};

export const HEATMAP_CONFIG = {
  radius: 30,
  minOpacity: 0.5,
  gradient: {
    0.5: GEO_MAP_CONSTANTS.HEATMAP_COLORS.GREEN,
    0.7: GEO_MAP_CONSTANTS.HEATMAP_COLORS.YELLOW,
    0.95: GEO_MAP_CONSTANTS.HEATMAP_COLORS.RED,
  },
};
