import { Store, StoreConfig } from '@datorama/akita';
import { ArrayUtil } from '@prosimoio/services';
import { LegendData } from '../../../component-common/legend/legend.model';
import {
  APPS_DASHBORD_TAB_VALUES,
  APP_DASHBOARD_URLS,
  APP_ONBOARDING_LEGENDS,
  NETWORK_DASHBOARD_NAV,
} from '@app/common/util/constants';

export interface IActiveTabDetails {
  currentTab: string; // Set current tab name in discovered view
  viewMode?: string; // Set active mode Tree mode or Apps mode
  subMenuViewMode?: string; //  Set sub menu mode
  activeURL?: string;
  appViewMode?: string;
  appDashboardViewMode?: string;
  networkDashboardViewMode?: string;
}

export interface NetworksDashboardSharedState {
  activeNavigation: string;
  activeTabDetails?: IActiveTabDetails;
  mcnActiveTabDetails?: IActiveTabDetails;
  activeSearchTerm?: string;
  activelegends?: Array<LegendData>;
}

export function createInitialState(): NetworksDashboardSharedState {
  return {
    activeNavigation: '',
    activeTabDetails: {
      currentTab: APPS_DASHBORD_TAB_VALUES.ONNBOARDED,
      activeURL: APP_DASHBOARD_URLS.ONBOARDED,
      viewMode: '',
      subMenuViewMode: '',
      appViewMode: '',
      appDashboardViewMode: '',
    },
    mcnActiveTabDetails: {
      currentTab: APPS_DASHBORD_TAB_VALUES.ONNBOARDED,
      activeURL: NETWORK_DASHBOARD_NAV.ONNBOARDED,
      viewMode: '',
      subMenuViewMode: '',
      appViewMode: '',
      networkDashboardViewMode: '',
    },
    activeSearchTerm: '',
    activelegends: ArrayUtil.deepClone(APP_ONBOARDING_LEGENDS),
  };
}

@StoreConfig({ name: 'networksDashboard', resettable: true })
export class NetworksDashboardSharedStore extends Store<NetworksDashboardSharedState> {
  constructor() {
    super(createInitialState());
  }
}

export const networksDashboardSharedStore = new NetworksDashboardSharedStore();
