<div class="row">
  <!--Filter Menu-->
  <div *ngIf="filterableColumnMap" class="col-sm-2">
    <div class="filter" [matMenuTriggerFor]="filterMenu">
      <i class="material-icons filter-icon">filter_list</i>
      <mat-menu #filterMenu="matMenu" class="filterMenu">
        <div class="tasks-filter-menu-header" mat-menu-item disabled>
          Filter
        </div>
        <div
          mat-menu-item
          class="d-flex align-items-center border-bottom"
          (click)="toggleAllColumnsFilter($event)"
        >
          <i class="material-icons checkbox">
            {{
              selectAllFilterColumns ? "check_box" : "check_box_outline_blank"
            }}
          </i>
          <span class="td-txt-regular pl-2">ALL</span>
        </div>
        <div
          mat-menu-item
          class="d-flex align-items-center"
          *ngFor="let column of filterableColumnMap | keyvalue"
          (click)="toggleColumnsFilter($event, column?.key)"
        >
          <i class="material-icons checkbox">
            {{
              filteredColumnsSet?.has(column?.key)
                ? "check_box"
                : "check_box_outline_blank"
            }}
          </i>
          <span class="td-txt-regular pl-2">
            {{ column?.value }}
          </span>
        </div>
      </mat-menu>
    </div>
  </div>
  <!--Search Input-->
  <div [ngClass]="filterableColumnMap ? 'col-sm-10' : 'col-sm-12'">
    <div
      id="search__container"
      [ngClass]="getSearchContainerStyles()"
      class="bdr-grey3 bg-light-shade"
    >
      <div class="d-flex align-items-center">
        <pcomp-button-dropdown
          *ngIf="searchTypeSelectionDropdown"
          [dropdownDef]="searchTypeSelectionDropdown"
          (selectionChanged)="onSelectionChange($event)"
        >
        </pcomp-button-dropdown>
        <div class="search__icon--wrapper">
          <i class="material-icons search__icon">search</i>
        </div>
      </div>
      <div class="search__input--wrapper">
        <input
          class="search__input clr-grey7"
          type="text"
          [placeholder]="placeholder"
          [formControl]="filterValue"
          (paste)="onSearchTermPaste($event)"
        />
      </div>
    </div>
  </div>
</div>
