import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResourceService } from '@app/common/http/util/resource.service';
import { CONSTANTS } from 'environments/environment';
import { AccountActivationModel } from './account-activation.model';
import { AccountActivationModelSerializer } from './account-activation.model.serializer';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountActivationService extends ResourceService<AccountActivationModel> {
  constructor(protected httpClient: HttpClient) {
    super(
      httpClient,
      CONSTANTS.DASHBOARD_HOST,
      CONSTANTS.ACCOUNT_ACTIVATION,
      new AccountActivationModelSerializer()
    );
  }

  activateAccount(
    activationToken: string,
    reCaptchaToken: string
  ): Observable<AccountActivationModel> {
    return this.read(
      activationToken,
      new HttpHeaders({
        'p-recaptcha-token': reCaptchaToken,
      })
    );
  }

  requestNewValidationToken(
    accountActivationModel: AccountActivationModel
  ): Observable<AccountActivationModel> {
    this.path = CONSTANTS.RESEND_VERIFICATION_EMAIL;
    return this.create(accountActivationModel);
  }
}
