import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AppDomain, Application } from '@app/common/models/app';
import { IsViewTypeProTransitPipe } from '@app/common/pipe/is-view-type-pro-transit/is-view-type-pro-transit.pipe';
import { AppUtil } from '@app/common/util/app-util';
import {
  APP_SETUP_STATUS,
  CLOUD_KEY_TYPES,
  CLOUD_TYPES,
  IMAGE_PATHS,
  LOADER_CONFIG_TYPES,
} from '@app/common/util/constants';
import { LightboxBtnType } from '@app/dashboard/diagnose/diagnostics/lightbox-form-template/lightbox-model';
import { PDashLocalStoreUtil } from '@app/state/web-pdash.util';
import {
  APP_ONBOARD_TYPE_OPTION_KEYS,
  CLOUD_SERVICE_KEYS,
  DNS_OPTIONS,
  DNS_OPTION_KEYS,
  DOMAIN_TYPE_KEYS,
  DeleteAction,
  ERROR_MESSAGE,
  TOOLTIP_MESSAGE,
} from '@app/v2/onboard/app/app.constants';
import { LoaderConfig } from '@app/v2/onboard/app/app.model';
import { AppOnboardService } from '@app/v2/onboard/app/app.service';
import {
  IOrchestratorComponent,
  AppOnboardStep,
} from '@app/common/components/drawer/orchestrator/orchestrator.model';
import { OrchestratorService } from '@app/common/components/drawer/orchestrator/orchestrator.service';
import { OnboardTemplateService } from '@app/common/components/drawer/templates/onboard-template/onboard-template.service';
import {
  STATUS_CONSTANTS,
  SelectSearchConfig,
  ToastService,
} from '@prosimoio/components';
import { ObjectUtil } from '@prosimoio/services';
import { Observable, Subject, of, zip } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsComponent
  implements IOrchestratorComponent, OnInit, OnDestroy
{
  badgeConfig = {
    status: 'FAILED',
  };
  stopSubs$: Subject<void>;
  appSettingsForm: UntypedFormArray;
  uiThemeMode: string;
  tooltipThemeClass: string;
  COPY_TOOLTIP_MESSAGE = TOOLTIP_MESSAGE;
  COPY_DNS_OPTIONS = DNS_OPTIONS;
  COPY_DNS_OPTION_KEYS = DNS_OPTION_KEYS;
  COPY_ERROR_MESSAGE = ERROR_MESSAGE;
  COPY_DOMAIN_TYPE_KEYS = DOMAIN_TYPE_KEYS;
  COPY_LOADER_CONFIG_TYPES = LOADER_CONFIG_TYPES;
  COPY_ICON = IMAGE_PATHS.COMMON.COPY;
  appOnboardType: string = '';
  cloudService: string = '';
  publicCloudAccounts: Array<any>;
  privateCloudAccounts: Array<any>;
  selectSearchConfigForDNSCloudAccounts: SelectSearchConfig;
  selectSearchConfigForCacheRules: SelectSearchConfig;
  cloudLoader$: Observable<LoaderConfig>;
  dnsCloudAccounts: Array<any>;
  cacheRules: Array<any>;
  selectedDomainIndex: number = 0;
  productViewType: string;
  isHideSettingsData = true;
  isShowCloudStep: boolean = false;
  isCopy: boolean = false;
  isShowProtocolPortStep: boolean = false;
  isShowCertStep: boolean = false;
  isShowDNSStep: boolean = false;
  isShowDNSType: boolean = false;
  domainTypeValue: string = DOMAIN_TYPE_KEYS.CUSTOM;
  cnameValue: string;
  appData: Application;
  cloudAccounts = {};
  appURLData: AppDomain;
  deployedEdgeRegions: Array<string> = [];
  appStatus = '';
  FORM_FIELDS_TO_DISABLE_VALUES = [];
  FORM_FIELDS_TO_DISABLE_KEYS: any = {};
  panelBadgeConfig = {
    COMPLETE: {
      status: STATUS_CONSTANTS.DEPLOYED,
    },
    INCOMPLETE: {
      status: STATUS_CONSTANTS.INCOMPLETE,
    },
  };
  panelStatusConfig: any = {};
  constructor(
    private orchestratorService: OrchestratorService,
    private onboardTemplateService: OnboardTemplateService,
    private appOnboardService: AppOnboardService,
    private isViewTypeProTransitPipe: IsViewTypeProTransitPipe,
    private toastService: ToastService,
    private cdr: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private appUtil: AppUtil
  ) {
    this.stopSubs$ = new Subject();
    this.productViewType = PDashLocalStoreUtil.getProductViewType();
    PDashLocalStoreUtil.getUIThemeModeAsObservable()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe((mode) => {
        this.uiThemeMode = mode?.toLowerCase() + '-theme';
        this.toastService.setUITheme(this.uiThemeMode);
        this.tooltipThemeClass =
          'cdk-component-container--' + this.uiThemeMode + '-dark';
        this.cdr.markForCheck();
      });
    PDashLocalStoreUtil.getProductViewTypeAsObservable()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe((productViewType) => {
        this.productViewType = productViewType;
      });
    this.appUtil
      .getDrawerResponse()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (response) => {
          if (response.code === LightboxBtnType.NEXT.code) {
            this.markStepComplete();
          }
        },
      });
    this.onboardTemplateService
      .getNameInput()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (name) => {
          this.appOnboardService.updateApplicationConfig({ appName: name });
        },
      });
    this.appUtil
      .getConditionClickEvent()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (val: any) => {
          this.selectedDomainIndex = val?.index;
          this.onAppSettingsFormValueChange();
          this.appURLData = this.appOnboardService.getAppDomainAtDomainIndex(
            this.selectedDomainIndex
          );
          this.setAppDomainPropertiesToShowInUI();
          this.cdr.markForCheck();
        },
      });
    this.appOnboardService
      .getPanelStatusConfig()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (val: any) => {
          this.panelStatusConfig = val;
        },
      });
    this.appUtil.updateDrawerButtonConfig({
      mainBtn: LightboxBtnType.NEXT,
      cancelBtn: LightboxBtnType.CANCEL,
    });
    this.getInitialData();
  }

  markStepComplete() {
    this.orchestratorService.setStepComplete(AppOnboardStep.APP_SETTINGS);
  }

  setAppDomainPropertiesToShowInUI() {
    const {
      domainType = DOMAIN_TYPE_KEYS.CUSTOM,
      pappFqdn = '',
      transitPappFqdn = '',
    } = this.appURLData;
    this.domainTypeValue = domainType;
    this.cnameValue = this.isViewTypeProTransitPipe.transform(
      this.productViewType
    )
      ? transitPappFqdn
      : pappFqdn;
  }

  ngOnInit(): void {
    this.toastService.setUITheme(this.uiThemeMode);
    this.appData = ObjectUtil.deepClone(
      this.appOnboardService.getApplicationConfig()
    );
    this.isHideSettingsData = this.appOnboardService.isHideSettingsData(
      this.appOnboardService.getApplicationConfig()
    );
    if (!this.isHideSettingsData) {
      this.appUtil.setConditionHeading('App Settings');
      this.appOnboardType = this.appOnboardService.getAppOnboardType();
      this.cloudService = this.appOnboardService.getAppCloudService();
      this.appURLData = this.appOnboardService.getAppDomainAtDomainIndex(
        this.selectedDomainIndex
      );
      this.panelStatusConfig =
        this.appOnboardService.getSettingsStepPanelStatus(
          this.selectedDomainIndex
        );
      this.setAppDomainPropertiesToShowInUI();
      this.appStatus = this.appOnboardService.getAppStatus();

      const formFields =
        this.appOnboardService.getFormFieldsToDisableValuesBasedOnStatus(
          this.appStatus
        );
      if (formFields) {
        this.FORM_FIELDS_TO_DISABLE_KEYS = formFields;
        this.FORM_FIELDS_TO_DISABLE_VALUES = Object.values(formFields);
      }

      this.initAppSettingsForm();
      const appURLConfig = [];
      const existingDomains = this.appData?.appURLs;
      existingDomains?.forEach((item, i) => {
        appURLConfig.push({
          label: item?.appFqdn,
          displayLabel: item?.appFqdn,
          isValid: false,
          isActive: i === 0,
          index: i,
        });
      });
      this.appUtil.setConditionConfig(appURLConfig);
      existingDomains.forEach((domain, i) => {
        this.appOnboardService.updateSettingsDomainValidity(i);
      });
    }
    this.setDrawerFooter();
  }

  initAppSettingsForm() {
    this.appSettingsForm = new UntypedFormArray([]);
    const initFormMethods = {
      [APP_ONBOARD_TYPE_OPTION_KEYS.FQDN]:
        this.initFqdnAppSettingsForm.bind(this),
      [APP_ONBOARD_TYPE_OPTION_KEYS.IP]: this.initIpAppSettingsForm.bind(this),
      [APP_ONBOARD_TYPE_OPTION_KEYS.WEB]:
        this.initWebAppSettingsForm.bind(this),
      [APP_ONBOARD_TYPE_OPTION_KEYS.VDI]:
        this.initVdiAppSettingsForm.bind(this),
      [APP_ONBOARD_TYPE_OPTION_KEYS.CLOUD_SVC]:
        this.initCloudSvcAppSettingsorm.bind(this),
      [APP_ONBOARD_TYPE_OPTION_KEYS.JUMPBOX]:
        this.initJumpboxAppSettingsForm.bind(this),
    };
    this.appData?.appURLs.forEach((appURL, i) => {
      const initFormMethod = initFormMethods[this.appOnboardType];
      if (initFormMethod) {
        initFormMethod(appURL, i);
      }
    });
    this.onAppSettingsFormValueChange();
    this.cdr.markForCheck();
  }

  onAppSettingsFormValueChange() {
    this.appSettingsForm?.valueChanges
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (domainData) => {
          this.appData = ObjectUtil.deepClone(
            this.appOnboardService.getApplicationConfig()
          );
          const existingDomains = this.appData?.appURLs;
          existingDomains.forEach((appURL: AppDomain, i) => {
            const {
              cacheRuleID = '',
              dnsService = {},
              healthCheckInfo,
            } = domainData[i];
            delete dnsService.dnsCloudControl;
            Object.assign(appURL, {
              cacheRuleID,
              dnsService,
              healthCheckInfo,
            });
          });
          this.appOnboardService.updateApplicationConfig({
            appURLs: existingDomains,
          });
        },
      });
  }
  patchDNSAccount(appURL: AppDomain, index: number) {
    const dnsID = appURL?.dnsService?.id;
    if (dnsID) {
      const selectedDNSAccount = this.dnsCloudAccounts.find(
        (account) => account.id === dnsID
      );
      const dnsCloudControl = this.getdomainFormGroupAtIndex(index)
        ?.get('dnsService')
        ?.get('dnsCloudControl');
      dnsCloudControl.patchValue(selectedDNSAccount);
      if (
        this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
          this.FORM_FIELDS_TO_DISABLE_KEYS.dnsCloudAccount
        )
      ) {
        dnsCloudControl.disable();
      }
    }
  }

  patchCacheRuleID(appURL: AppDomain, index: number) {
    const cacheRuleID = appURL?.cacheRuleID;
    if (cacheRuleID) {
      const selectedCacheRule = this.cacheRules.find(
        (rule) => rule.id === cacheRuleID
      );
      const cacheRuleIDControl =
        this.getdomainFormGroupAtIndex(index)?.get('cacheRuleIDControl');
      cacheRuleIDControl?.patchValue(selectedCacheRule);
      if (
        this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
          this.FORM_FIELDS_TO_DISABLE_KEYS.cacheRuleID
        )
      ) {
        cacheRuleIDControl?.disable();
      }
      this.cdr.markForCheck();
    }
  }

  initFqdnAppSettingsForm(appURL: AppDomain, domainIndex: number) {
    this.isShowProtocolPortStep = true;
    this.isShowCloudStep = true;
    this.isShowDNSStep = true;
    this.isShowDNSType = false;
    const { dnsService } = appURL;
    const dnsConfigured = dnsService?.configured || false;
    const dnsServiceType = dnsService?.type || DNS_OPTION_KEYS.MANUAL;
    const dnsID = dnsService?.id || '';
    const dnsServiceControl = this.fb.group({
      configured: dnsConfigured,
      type: [
        {
          value: dnsServiceType,
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.dnsServiceType
          ),
        },
        Validators.required,
      ],
      id: [dnsID],
    });
    const appDomainFormGroup = this.fb.group({
      dnsService: dnsServiceControl,
    });
    this.appSettingsForm.push(appDomainFormGroup);
    this.appOnboardService.updateAppDomainConfig(
      domainIndex,
      appDomainFormGroup.getRawValue()
    );
  }

  initIpAppSettingsForm(appURL: AppDomain, domainIndex: number) {
    this.isShowProtocolPortStep = true;
    this.isShowCloudStep = true;
    this.isShowDNSStep = true;
    this.isShowDNSType = false;
    const { dnsService } = appURL;
    const dnsConfigured = dnsService?.configured || false;
    const dnsServiceType = dnsService?.type || DNS_OPTION_KEYS.MANUAL;
    const dnsID = dnsService?.id || '';
    const dnsServiceControl = this.fb.group({
      configured: dnsConfigured,
      type: [
        {
          value: dnsServiceType,
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.dnsServiceType
          ),
        },
        Validators.required,
      ],
      id: [dnsID],
    });
    const appDomainFormGroup = this.fb.group({
      dnsService: dnsServiceControl,
    });
    this.appSettingsForm.push(appDomainFormGroup);
    this.appOnboardService.updateAppDomainConfig(
      domainIndex,
      appDomainFormGroup.getRawValue()
    );
  }

  initWebAppSettingsForm(appURL: AppDomain, domainIndex: number) {
    this.isShowProtocolPortStep = true;
    this.isShowCloudStep = true;
    this.isShowCertStep = true;
    this.isShowDNSStep = true;
    this.isShowDNSType = true;
    const { dnsService, healthCheckInfo, cacheRuleID = '' } = appURL;
    const dnsConfigured = dnsService?.configured || false;
    const dnsServiceType = dnsService?.type || DNS_OPTION_KEYS.MANUAL;
    const dnsID = dnsService?.id || '';
    const dnsServiceControl = this.fb.group({
      configured: dnsConfigured,
      type: [
        {
          value: dnsServiceType,
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.dnsServiceType
          ),
        },
        Validators.required,
      ],
      dnsCloudControl: ['', Validators.required],
      id: [dnsID],
    });
    const appDomainFormGroup = this.fb.group({
      dnsService: dnsServiceControl,
      cacheRuleIDControl: [''],
      cacheRuleID: [cacheRuleID],
    });
    const healthCheckFormGroup = this.fb.group({
      enabled: [
        {
          value: healthCheckInfo?.enabled ?? true,
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.healthCheckEnabled
          ),
        },
      ],
      endpoint: [
        {
          value: healthCheckInfo?.endpoint ?? '/',
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.healthCheckEndpointPath
          ),
        },
      ],
    });
    appDomainFormGroup.addControl('healthCheckInfo', healthCheckFormGroup);
    this.appSettingsForm.push(appDomainFormGroup);
    const appDomainData = appDomainFormGroup.getRawValue();
    delete appDomainData.cacheRuleIDControl;
    delete appDomainData.dnsService.dnsCloudControl;
    this.appOnboardService.updateAppDomainConfig(domainIndex, appDomainData);
  }

  initVdiAppSettingsForm(appURL: AppDomain, domainIndex: number) {
    this.isShowProtocolPortStep = true;
    this.isShowCloudStep = true;
    this.isShowCertStep = true;
    this.isShowDNSStep = true;
    this.isShowDNSType = true;
    const { dnsService, healthCheckInfo, cacheRuleID = '' } = appURL;
    const dnsConfigured = dnsService?.configured || false;
    const dnsServiceType = dnsService?.type || DNS_OPTION_KEYS.MANUAL;
    const dnsID = dnsService?.id || '';
    const dnsServiceControl = this.fb.group({
      configured: dnsConfigured,
      type: [
        {
          value: dnsServiceType,
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.dnsServiceType
          ),
        },
        Validators.required,
      ],
      dnsCloudControl: ['', Validators.required],
      id: [dnsID],
    });
    const appDomainFormGroup = this.fb.group({
      dnsService: dnsServiceControl,
      cacheRuleIDControl: [''],
      cacheRuleID: [cacheRuleID],
    });
    const healthCheckFormGroup = this.fb.group({
      enabled: [
        {
          value: healthCheckInfo?.enabled ?? true,
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.healthCheckEnabled
          ),
        },
      ],
      endpoint: [
        {
          value: healthCheckInfo?.endpoint ?? '/',
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.healthCheckEndpointPath
          ),
        },
      ],
    });
    appDomainFormGroup.addControl('healthCheckInfo', healthCheckFormGroup);
    this.appSettingsForm.push(appDomainFormGroup);
    const appDomainData = appDomainFormGroup.getRawValue();
    delete appDomainData.cacheRuleIDControl;
    delete appDomainData.dnsService.dnsCloudControl;
    this.appOnboardService.updateAppDomainConfig(domainIndex, appDomainData);
  }

  initJumpboxAppSettingsForm(appURL: AppDomain, domainIndex: number) {
    this.isShowCloudStep = true;
    this.isShowCertStep = true;
    this.isShowDNSStep = true;
    this.isShowDNSType = true;
    const { dnsService, healthCheckInfo } = appURL || {
      dnsService: { configured: false, type: DNS_OPTION_KEYS.MANUAL, id: '' },
    };
    const dnsConfigured = dnsService?.configured || false;
    const dnsServiceType = dnsService?.type || DNS_OPTION_KEYS.MANUAL;
    const dnsID = dnsService?.id || '';
    const dnsServiceControl = this.fb.group({
      configured: dnsConfigured,
      type: [
        {
          value: dnsServiceType,
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.dnsServiceType
          ),
        },
        Validators.required,
      ],
      dnsCloudControl: ['', Validators.required],
      id: [dnsID],
    });
    const appDomainFormGroup = this.fb.group({
      dnsService: dnsServiceControl,
    });
    const healthCheckFormGroup = this.fb.group({
      enabled: [
        {
          value: healthCheckInfo?.enabled ?? true,
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.healthCheckEnabled
          ),
        },
      ],
      endpoint: [
        {
          value: healthCheckInfo?.endpoint ?? '/',
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.healthCheckEndpointPath
          ),
        },
      ],
    });
    appDomainFormGroup.addControl('healthCheckInfo', healthCheckFormGroup);
    this.appSettingsForm.push(appDomainFormGroup);
    const appDomainData = appDomainFormGroup.getRawValue();
    delete appDomainData.cacheRuleIDControl;
    delete appDomainData.dnsService.dnsCloudControl;
    this.appOnboardService.updateAppDomainConfig(domainIndex, appDomainData);
  }

  initCloudSvcAppSettingsorm(appURL: AppDomain, domainIndex: number) {
    this.isShowCloudStep = true;
    this.isShowCertStep = true;
    this.isShowDNSStep = true;
    this.isShowDNSType = true;
    const { dnsService, cacheRuleID = '' } = appURL;
    const dnsConfigured = dnsService?.configured || false;
    const dnsServiceType = dnsService?.type || DNS_OPTION_KEYS.MANUAL;
    const dnsID = dnsService?.id || '';
    const dnsServiceControl = this.fb.group({
      configured: dnsConfigured,
      type: [
        {
          value: dnsServiceType,
          disabled: this.FORM_FIELDS_TO_DISABLE_VALUES.includes(
            this.FORM_FIELDS_TO_DISABLE_KEYS.dnsServiceType
          ),
        },
        Validators.required,
      ],
      dnsCloudControl: ['', Validators.required],
      id: [dnsID],
    });
    const appDomainFormGroup = this.fb.group({
      dnsService: dnsServiceControl,
      cacheRuleIDControl: [''],
      cacheRuleID: [cacheRuleID],
    });
    this.appSettingsForm.push(appDomainFormGroup);
    const appDomainData = appDomainFormGroup.getRawValue();
    delete appDomainData.cacheRuleIDControl;
    delete appDomainData.dnsService.dnsCloudControl;
    this.appOnboardService.updateAppDomainConfig(domainIndex, appDomainData);
  }

  onToggleHealthCheck(checked: boolean) {
    const healthCheckInfoFormGroup = this.getdomainFormGroupAtIndex(
      this.selectedDomainIndex
    )?.get('healthCheckInfo') as UntypedFormGroup;
    this.getdomainFormGroupAtIndex(this.selectedDomainIndex)
      .get('healthCheckInfo')
      .get('enabled')
      .patchValue(checked);
    if (checked) {
      healthCheckInfoFormGroup.addControl('endpoint', this.fb?.control('/'));
    } else {
      healthCheckInfoFormGroup.removeControl('endpoint');
    }
  }

  updateCloudLoaderConfig(status = false, message = '', type = '') {
    this.cloudLoader$ = of({
      status,
      message,
      type,
    } as LoaderConfig);
    this.cdr.markForCheck();
  }

  getInitialData() {
    const dataRequest$ = zip(
      this.appOnboardService.getConfiguredClouds(),
      this.appOnboardService.getPrivateClouds(),
      this.appOnboardService.getEdges(),
      this.appOnboardService.getCacheRules()
    );
    this.updateCloudLoaderConfig(true, 'Loading Cloud Accounts...');
    dataRequest$.pipe(takeUntil(this.stopSubs$)).subscribe(
      ([publicCloudAccounts, privateCloudAccounts, edges, cacheRules]) => {
        // public cloud accounts
        const appURLs = this.appData?.appURLs;
        this.updateCloudLoaderConfig(false);
        const cspType = this.appOnboardService.getAppCSPType();
        this.publicCloudAccounts = publicCloudAccounts
          .map((cloud) => {
            return Object.assign(cloud, {
              displayName: cloud?.accountName
                ? `${cloud?.accountName} / ${cloud?.name}`
                : cloud?.name,
            });
          })
          .sort((a, b) => a.displayName.localeCompare(b.displayName));

        this.dnsCloudAccounts = this.publicCloudAccounts.filter(
          (cloud) => cloud.cloudType === CLOUD_TYPES.AWS
        );

        this.publicCloudAccounts = this.publicCloudAccounts.filter(
          (cloud) =>
            cloud?.keyType !== CLOUD_KEY_TYPES.UNMANAGED &&
            cloud?.cloudType === cspType
        );
        this.setSelectSearchConfigForDNSCloudAccounts(this.dnsCloudAccounts);
        appURLs?.forEach((item, i) => {
          this.patchDNSAccount(item, i);
        });
        if (this.cloudService === CLOUD_SERVICE_KEYS.AMAZON_S3) {
          this.publicCloudAccounts = ObjectUtil.deepClone(
            this.dnsCloudAccounts
          );
        }
        this.cloudAccounts = {
          publicCloudAccounts: this.publicCloudAccounts,
          privateCloudAccounts,
        };
        // edges
        this.deployedEdgeRegions =
          edges
            ?.filter((edge) => edge?.status === APP_SETUP_STATUS.DEPLOYED)
            ?.map((edge) => edge?.cloudRegion) || [];

        // cache rules
        this.cacheRules = cacheRules;
        this.setSelectSearchConfigForCacheRules(cacheRules);
        appURLs?.forEach((item, index) => {
          this.patchCacheRuleID(item, index);
        });
      },
      (error) => {
        this.updateCloudLoaderConfig(
          true,
          'Loading Cloud Accounts...',
          LOADER_CONFIG_TYPES.ERROR
        );
      }
    );
  }

  setSelectSearchConfigForDNSCloudAccounts(accounts: Array<any>) {
    if (!accounts) {
      return;
    }
    const config = {
      optionList: accounts,
      placeholder: 'Select Accounts',
      uiThemeMode: this.uiThemeMode,
      showSearch: true,
      keyName: 'displayName',
      isMultiSelection: false,
    };
    this.selectSearchConfigForDNSCloudAccounts = ObjectUtil.deepClone(config);
    this.cdr.markForCheck();
  }

  setSelectSearchConfigForCacheRules(cacheRules: Array<any>) {
    if (!cacheRules) {
      return;
    }
    const config = {
      optionList: cacheRules,
      placeholder: 'Select Cache Rule',
      uiThemeMode: this.uiThemeMode,
      showSearch: true,
      keyName: 'name',
      isMultiSelection: false,
    };
    this.selectSearchConfigForCacheRules = ObjectUtil.deepClone(config);
    this.cdr.markForCheck();
  }

  onChangeCacheRule(cacheRule: any) {
    this.getdomainFormGroupAtIndex(this.selectedDomainIndex)
      ?.get('cacheRuleID')
      .patchValue(cacheRule?.id);
    this.appOnboardService.updateCacheRuleID(
      this.selectedDomainIndex,
      cacheRule?.id
    );
  }

  onCopyCNAME() {
    this.isCopy = true;
    setTimeout(() => {
      this.isCopy = false;
      this.cdr.markForCheck();
    }, 2000);
  }

  getdomainFormGroupAtIndex(domainIndex: number) {
    return this.appSettingsForm?.at(domainIndex) as UntypedFormGroup;
  }

  setDrawerFooter() {
    this.onboardTemplateService
      .getDeleteResponse()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (response) => {
          if (response.action === DeleteAction.DELETE) {
            this.appOnboardService.deleteApplication(
              this.appData,
              this.uiThemeMode
            );
          } else if (response.action === DeleteAction.OFFBOARD) {
            this.appOnboardService.offboardApplication(
              response.confirmation,
              this.appData,
              this.uiThemeMode
            );
          }
        },
      });
    this.appOnboardService.setCreateTimeAndDeleteConfig(this.appData);
  }

  ngOnDestroy(): void {
    this.stopSubs$.next();
    this.stopSubs$.complete();
  }
}
