import { AgentStore, agentStore } from './agent.store';

export class AgentService {
  constructor(private agentStore: AgentStore) {}

  updateActiveTab(activeTab: string) {
    this.agentStore.update((state) => ({
      activeTab,
    }));
  }
}

export const agentService = new AgentService(agentStore);
