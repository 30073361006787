import { LoginUtil } from './../../../../login/login-util';
import { USER_STATUSES, URL_PATHS } from './../../constants';
import {
  CanActivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MFAGuard implements CanActivate {
  constructor(private router: Router, private loginUtil: LoginUtil) {}

  canActivate(
    ext: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (this.loginUtil.getUserStatus() === USER_STATUSES.MFA_REQUIRED) {
      return true;
    } else {
      this.router.navigateByUrl(URL_PATHS.LOGIN);
      return false;
    }
  }
}
