import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private showLoader$ = new Subject<boolean>();

  constructor() {}

  setShowLoader(loaderDisplayStatus: boolean) {
    this.showLoader$.next(loaderDisplayStatus);
  }

  isShowLoader(): Observable<boolean> {
    return this.showLoader$.asObservable();
  }
}
