import { Injectable } from '@angular/core';
import { UI_THEME_MODES } from './common/util/constants';
import { PDashLocalStoreUtil } from './state/web-pdash.util';

@Injectable({ providedIn: 'root' })
export class AppService {
  toggleDarkTheme(isLight?: boolean) {
    if (isLight) {
      document.body.classList.remove('dark-theme');
      PDashLocalStoreUtil.updateUIThemeMode(UI_THEME_MODES.LIGHT);
    } else {
      document.body.classList.add('dark-theme');
      PDashLocalStoreUtil.updateUIThemeMode(UI_THEME_MODES.DARK);
    }
  }
}
