<ng-container *ngIf="!isV2View">
  <div
    class="p-1 cursor-pointer d-flex align-items-center justify-content-center p-1 filter-div-icon-v2 ig__btn-advanced-search"
    [matBadge]="getSelectedFilterCount(filterForm.invalid)"
    [matBadgeHidden]="getSelectedFilterCount(filterForm.invalid) === 0"
    [matMenuTriggerFor]="fieldsMenu"
    matTooltip="Advanced Search Filters"
  >
    <img
      width="16"
      height="16"
      class="v1-svg-filter-grey"
      [src]="logFilterImgUrl"
    />
  </div>

  <mat-menu
    [class]="
      tooltipThemeClass +
      ' ' +
      (uiThemeMode.split('-')[0] | uppercase | uiTheme)
    "
    #fieldsMenu="matMenu"
    backdropClass="advance-search-filter-panel"
    (close)="closeAllMatMenus()"
  >
    <div
      class="d-flex flex-column fields-menu-panel overflow-hidden px-2 pt-2 bg-light-shade"
    >
      <div class="fields-panel overflow-auto d-flex flex-column" #scrollMe>
        <div
          class="d-flex align-items-center justify-content-center p-3 add-filter-btn"
          *ngIf="!newSelections?.length"
        >
          No Filters Selected
        </div>
        <form #filterForm="ngForm">
          <div *ngFor="let data of newSelections; let i = index">
            <div class="d-flex flex-row">
              <div class="d-flex align-items-center px-3 pt-2">
                <mat-icon
                  class="cursor-pointer remove-icon"
                  (click)="removeSingleFilter('', data, i)"
                  >clear</mat-icon
                >
              </div>
              <div class="d-flex">
                <div class="d-flex flex-column col-4">
                  <div *ngIf="i === 0" class="mb-1">Field</div>
                  <mat-form-field
                    appearance="outline"
                    class="filter-input-text ig__input-field"
                  >
                    <mat-select
                      placeholder="Select Field"
                      panelClass="mat-panel__bg--{{
                        uiThemeMode.slice(0, 1)
                      }}-light"
                      #fieldsDropdowns
                      matNativeControl
                      [(value)]="data.field"
                      (selectionChange)="
                        onColumnChange($event, data);
                        data.value = '';
                        data.operator = FILTER_OPERATORS.CONTAINS;
                        data.showSelected = false;
                        data.selectedInputValues = []
                      "
                    >
                      <mat-option
                        *ngFor="let column of filterFields(i)"
                        [value]="column?.key"
                        >{{ column?.name }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="d-flex col-4" *ngIf="isRangeConfigMenu(data)">
                  <div
                    class="nested-menu-div d-flex pb-1 justify-content-between align-items-end"
                    [matMenuTriggerFor]="nestedOperators"
                    (click)="$event.stopPropagation()"
                  >
                    <span
                      class="filter-nested-text text-truncate"
                      matTooltip="{{ data?.displayValue }}"
                      [matTooltipDisabled]="!data?.displayValue"
                    >
                      {{
                        data?.displayValue
                          ? data?.displayValue
                          : OPERATORS_DISPLAY_NAMES.OPERATOR
                      }}
                    </span>
                    <mat-icon class="material-icons-outlined"
                      >arrow_drop_down</mat-icon
                    >
                  </div>

                  <mat-menu
                    [class]="
                      tooltipThemeClass +
                      ' ' +
                      (uiThemeMode.split('-')[0] | uppercase | uiTheme)
                    "
                    #nestedOperators="matMenu"
                    overlayPanelClass="advance-search-filter-panel-nested"
                  >
                    <ng-container
                      *ngFor="let operator of getOperatorsDropdown(data)"
                    >
                      <button
                        mat-menu-item
                        [matMenuTriggerFor]="operators"
                        [matMenuTriggerData]="{
                          operator: operator,
                          data: data
                        }"
                      >
                        {{ operator?.displayName }}
                      </button>
                    </ng-container>
                  </mat-menu>

                  <mat-menu
                    [class]="
                      tooltipThemeClass +
                      ' ' +
                      (uiThemeMode.split('-')[0] | uppercase | uiTheme)
                    "
                    #operators="matMenu"
                  >
                    <ng-template
                      matMenuContent
                      let-operator="operator"
                      let-data="data"
                    >
                      <button
                        mat-menu-item
                        *ngFor="let item of operator?.operators"
                        (click)="updateNestedOperatorData(data, operator, item)"
                      >
                        {{
                          data?.column?.type === SEARCH_FILTER.TYPES.RANGE &&
                          item.value === FILTER_OPERATORS.CONTAINS
                            ? OPERATORS_DISPLAY_NAMES.EQUALS
                            : item.displayName
                        }}
                      </button>
                    </ng-template>
                  </mat-menu>
                </div>

                <div
                  class="d-flex flex-column col-4"
                  *ngIf="!isRangeConfigMenu(data)"
                >
                  <div *ngIf="i === 0" class="mb-1">Operator</div>
                  <mat-form-field
                    appearance="outline"
                    class="filter-input-text ig__input-operator"
                  >
                    <mat-select
                      placeholder="Select Operator"
                      matNativeControl
                      [(value)]="data.operator"
                      (selectionChange)="
                        updateSelectedFilters(data?.value, data)
                      "
                      [disabled]="
                        data?.column?.type ===
                          SEARCH_FILTER.TYPES.SELECT_PILL ||
                        data?.column?.type ===
                          SEARCH_FILTER.TYPES.AUTOCOMPLETE ||
                        !data?.field
                      "
                    >
                      <mat-option
                        *ngFor="let operator of operators"
                        [value]="operator.value"
                        >{{
                          data?.column?.type === SEARCH_FILTER.TYPES.RANGE &&
                          operator.value === FILTER_OPERATORS.CONTAINS
                            ? OPERATORS_DISPLAY_NAMES.EQUALS
                            : operator?.displayName
                        }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="d-flex col-4">
                  <div
                    *ngIf="
                      data?.column?.type !== SEARCH_FILTER.TYPES.SELECT_PILL &&
                      data?.column?.type !== SEARCH_FILTER.TYPES.CHECKBOX_GROUP
                    "
                    class="d-flex flex-column"
                  >
                    <div *ngIf="i === 0" class="mb-1">Values</div>
                    <mat-form-field class="filter-input-text ig__input-value">
                      <input
                        type="text"
                        matInput
                        placeholder="Enter Value"
                        [value]="data?.selectedValuesCountText"
                        *ngIf="
                          data?.showSelected &&
                          data?.column?.type !== SEARCH_FILTER.TYPES.RANGE
                        "
                        (focus)="data.showSelected = false; data.value = ''"
                      />

                      <input
                        placeholder="Enter Value"
                        id="value-{{ i }}"
                        *ngIf="!data?.showSelected"
                        matInput
                        [ngModel]="data.value"
                        name="value-{{ i }}"
                        [pattern]="getPatternForValues(data)"
                        (input)="
                          updateSelectedFilters($event.target.value, data)
                        "
                        (focus)="
                          data.showSelected = false;
                          data?.column?.type !== SEARCH_FILTER.TYPES.RANGE
                            ? (data.value = '')
                            : false
                        "
                        [disabled]="!data?.field"
                        (mouseenter)="
                          focusManullyWhenDynamicInputs('value-' + i)
                        "
                        (blur)="updateValueCount(data)"
                      />
                      <mat-error
                        class="error-text text-trunlcate"
                        *ngIf="filterForm.form.controls?.['value-'+i]?.errors?.pattern"
                        >{{ data?.errMessage }}</mat-error
                      >
                    </mat-form-field>
                  </div>

                  <div
                    *ngIf="
                      data?.column?.type === SEARCH_FILTER.TYPES.SELECT_PILL
                    "
                    class="d-flex flex-column"
                  >
                    <div *ngIf="i === 0" class="mb-1">Values</div>
                    <mat-form-field class="filter-input-text ig__input-value">
                      <mat-select
                        placeholder="Select Value(s)"
                        [(value)]="data.value"
                        multiple
                        (selectionChange)="
                          updateSelectedFilters(data?.value, data)
                        "
                        [disabled]="!data?.field"
                      >
                        <mat-select-trigger>
                          <span>
                            {{ data?.value?.length || 0 }}
                            {{ data?.column?.name }} Selected
                          </span>
                        </mat-select-trigger>

                        <mat-option
                          *ngFor="
                            let obj of selectPillMap.get(data?.column?.key)
                              ?.dropDown.values
                          "
                          [value]="obj"
                          >{{ obj?.label }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div
                    *ngIf="
                      data?.column?.type === SEARCH_FILTER.TYPES.CHECKBOX_GROUP
                    "
                    class="d-flex flex-column"
                  >
                    <div *ngIf="i === 0" class="mb-1">Values</div>
                    <mat-form-field class="filter-input-text ig__input-value">
                      <mat-select
                        placeholder="Select Value(s)"
                        [(value)]="data.value"
                        multiple
                        (selectionChange)="
                          updateSelectedFilters(data?.value, data)
                        "
                        [disabled]="!data?.field"
                      >
                        <mat-option
                          *ngFor="let obj of checkboxGroups[data?.column?.key]"
                          [value]="obj?.value"
                          >{{ obj?.displayName }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div
                class="filter-input-text d-flex align-items-center pb-2"
                *ngIf="
                  data?.selectedInputValues?.length &&
                  data?.column?.type !== SEARCH_FILTER.TYPES.RANGE
                "
              >
                <span
                  [matMenuTriggerFor]="moreInputs"
                  (click)="$event.stopPropagation()"
                  class="more-text d-flex align-items-center justify-content-center cursor-pointer"
                  >{{
                    data?.selectedInputValues?.length > 9
                      ? "9+"
                      : data?.selectedInputValues?.length
                  }}</span
                >

                <mat-menu
                  [class]="
                    tooltipThemeClass +
                    ' ' +
                    (uiThemeMode.split('-')[0] | uppercase | uiTheme)
                  "
                  #moreInputs="matMenu"
                >
                  <ng-container
                    *ngFor="
                      let value of data?.selectedInputValues;
                      let valIndex = index
                    "
                  >
                    <div class="bg-dark-shade" mat-menu-item>
                      <div class="d-flex align-items-center">
                        <mat-icon
                          class="material-icons-outlined cursor-pointer remove-icon clr-grey4"
                          (click)="
                            $event.stopPropagation();
                            updateSelectedValueCount(data, valIndex)
                          "
                          >clear</mat-icon
                        >
                        <span class=""> {{ value }}</span>
                      </div>
                    </div>
                  </ng-container>
                </mat-menu>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="d-flex align-items-center pl-2 justify-content-between">
        <button
          mat-raised-button
          color="primary"
          (click)="addNewFilter()"
          class="d-flex cursor-pointer align-items-center pl-2 ig__btn-add-filter"
          [disabled]="
            newSelections?.length >= filterRowColumns?.length ||
            isNewFilterAllowed() ||
            filterForm.invalid
          "
        >
          <mat-icon
            [ngClass]="{
              'add-buton-disable-text':
                newSelections?.length >= filterRowColumns?.length ||
                isNewFilterAllowed()
            }"
            >add</mat-icon
          >
          <span class="ml-2 add-filter-btn">ADD FILTER</span>
        </button>
        <button
          mat-raised-button
          color="primary"
          class="d-flex cursor-pointer pr-2 add-filter-btn p-2"
          (click)="clearAllFilters()"
        >
          CLEAR FILTERS
        </button>
      </div>
    </div>
  </mat-menu>
</ng-container>

<ng-container *ngIf="isV2View">
  <div class="d-flex align-items-center justify-content-center">
    <span
      *ngIf="showCount && newSelections?.length"
      [matMenuTriggerFor]="selectedFilterMenu"
      [ngClass]="{
        'selected-mat-menu-disabled':
          getSelectedFilterCount(filterForm.invalid) === 0
      }"
      class="mr-2 d-flex align-items-center cursor-pointer justify-content-center filter-selected-count p1 bg-dark-shade"
      >{{ getSelectedFilterCount(filterForm.invalid) }}</span
    >
    <div
      *ngIf="!isBadgeView"
      class="p-1 cursor-pointer d-flex align-items-center justify-content-center p-1 filter-div-icon-v2 ig__btn-advanced-search"
      [matMenuTriggerFor]="fieldsMenu"
      matTooltip="Advanced Search Filters"
    >
      <mat-icon
        *ngIf="!newSelections?.length"
        class="material-icons-outlined icon d-flex align-items-center justify-content-center"
        >tune</mat-icon
      >
      <mat-icon
        *ngIf="newSelections?.length"
        class="icon d-flex align-items-center justify-content-center"
        >tune</mat-icon
      >
    </div>

    <div *ngIf="isV2View && isBadgeView" id="dropdown-selector">
      <div
        [matMenuTriggerFor]="fieldsMenu"
        (menuClosed)="onMenuClosed()"
        (menuOpened)="onMenuOpened()"
        [matBadge]="getSelectedFilterCount(filterForm.invalid)"
        [matBadgeHidden]="getSelectedFilterCount(filterForm.invalid) === 0"
        class="dropdown-trigger bg-light-shade text-color p-2 text-truncate cursor-pointer align-items-center justify-content-center"
        [ngClass]="{
          'border-blue-1 selected-filter-header ': isMenuOpened,
          'border-grey-1': !isMenuOpened,
          'img-width d-flex': filterImgUrl
        }"
      >
        <div>
          <img class="img-logo" [src]="filterImgUrl" />
        </div>
      </div>
    </div>

    <mat-menu
      #fieldsMenu="matMenu"
      xPosition="before"
      yPosition="below"
      (close)="closeAllMatMenus()"
      backdropClass="advance-search-filter-panel"
    >
      <div
        class="d-flex flex-column fields-menu-panel overflow-hidden px-2 pt-2"
      >
        <div class="fields-panel overflow-auto d-flex flex-column" #scrollMe>
          <div
            class="d-flex align-items-center justify-content-center p-3 add-filter-btn"
            *ngIf="!newSelections?.length"
          >
            No Filters Selected
          </div>
          <form #filterForm="ngForm">
            <div *ngFor="let data of newSelections; let i = index">
              <div class="d-flex flex-row">
                <div class="d-flex align-items-center px-3 pb-2">
                  <mat-icon
                    class="cursor-pointer remove-icon"
                    (click)="removeSingleFilter('', data, i)"
                    >clear</mat-icon
                  >
                </div>
                <div class="d-flex">
                  <div class="d-flex col-4">
                    <div class="form-wrapper__control__select">
                      <mat-form-field
                        class="ig__input-field"
                        appearance="outline"
                        class="w-100"
                      >
                        <mat-select
                          #fieldsDropdowns
                          [placeholder]="'Field'"
                          matNativeControl
                          [(value)]="data.field"
                          (selectionChange)="
                            onColumnChange($event, data);
                            data.value = '';
                            data.operator = FILTER_OPERATORS.CONTAINS;
                            data.showSelected = false;
                            data.selectedInputValues = []
                          "
                          panelClass="mat-panel__bg--{{
                            uiThemeMode
                          }}-light select-width-lg"
                          disableRipple
                          disableOptionCentering
                        >
                          <mat-option
                            *ngFor="let column of filterFields(i)"
                            [value]="column?.key"
                            >{{ column?.name }}</mat-option
                          >
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="d-flex col-4" *ngIf="isRangeConfigMenu(data)">
                    <div
                      class="d-flex form-wrapper__control__select pb-1 justify-content-between align-items-end position-relative"
                      [matMenuTriggerFor]="nestedOperators"
                      (click)="$event.stopPropagation()"
                    >
                      <mat-form-field
                        appearance="outline"
                        class="readonly-wrapper"
                        [matMenuTriggerFor]="menu"
                      >
                        <input
                          matTooltip="{{ data?.displayValue }}"
                          [value]="
                            data?.displayValue
                              ? data?.displayValue
                              : OPERATORS_DISPLAY_NAMES.OPERATOR
                          "
                          id="name"
                          readonly
                          [matTooltipDisabled]="!data?.displayValue"
                          placeholder="''"
                          matInput
                        />
                      </mat-form-field>

                      <mat-icon class="position-absolute operator-selector-icon"
                        >keyboard_arrow_down</mat-icon
                      >
                    </div>

                    <mat-menu
                      #nestedOperators="matMenu"
                      overlayPanelClass="mt-n3"
                      class="mt-n3"
                    >
                      <ng-container
                        *ngFor="let operator of getOperatorsDropdown(data)"
                      >
                        <button
                          mat-menu-item
                          [matMenuTriggerFor]="operators"
                          [matMenuTriggerData]="{
                            operator: operator,
                            data: data
                          }"
                        >
                          {{ operator?.displayName }}
                        </button>
                      </ng-container>
                    </mat-menu>

                    <mat-menu #operators="matMenu">
                      <ng-template
                        matMenuContent
                        let-operator="operator"
                        let-data="data"
                      >
                        <button
                          mat-menu-item
                          *ngFor="let item of operator?.operators"
                          (click)="
                            updateNestedOperatorData(data, operator, item)
                          "
                        >
                          {{
                            data?.column?.type === SEARCH_FILTER.TYPES.RANGE &&
                            item.value === FILTER_OPERATORS.CONTAINS
                              ? OPERATORS_DISPLAY_NAMES.EQUALS
                              : item.displayName
                          }}
                        </button>
                      </ng-template>
                    </mat-menu>
                  </div>

                  <div class="d-flex col-4" *ngIf="!isRangeConfigMenu(data)">
                    <div class="form-wrapper__control__select">
                      <mat-form-field
                        class="ig__input-operator"
                        appearance="outline"
                      >
                        <!-- <mat-label *ngIf="!data?.operator">Operator</mat-label> -->
                        <mat-select
                          [placeholder]="'Operator'"
                          matNativeControl
                          [(value)]="data.operator"
                          (selectionChange)="
                            updateSelectedFilters(data?.value, data)
                          "
                          [disabled]="
                            data?.column?.type ===
                              SEARCH_FILTER.TYPES.SELECT_PILL ||
                            data?.column?.type ===
                              SEARCH_FILTER.TYPES.AUTOCOMPLETE ||
                            !data?.field
                          "
                        >
                          <mat-option
                            *ngFor="let operator of operators"
                            [value]="operator.value"
                            >{{
                              data?.column?.type ===
                                SEARCH_FILTER.TYPES.RANGE &&
                              operator.value === FILTER_OPERATORS.CONTAINS
                                ? OPERATORS_DISPLAY_NAMES.EQUALS
                                : operator?.displayName
                            }}</mat-option
                          >
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="d-flex col-4">
                    <div class="class form-wrapper__control__select">
                      <mat-form-field
                        appearance="outline"
                        class="ig__input-value"
                        *ngIf="
                          data?.column?.type !==
                            SEARCH_FILTER.TYPES.SELECT_PILL &&
                          data?.column?.type !==
                            SEARCH_FILTER.TYPES.CHECKBOX_GROUP
                        "
                      >
                        <!-- <mat-label *ngIf="
                        !data?.value &&
                        !data?.showSelected &&
                        !data?.selectedInputValues?.length
                      ">Values</mat-label> -->

                        <input
                          placeholder="{{
                            !data?.value &&
                            !data?.showSelected &&
                            !data?.selectedInputValues?.length
                              ? 'Values'
                              : ''
                          }}"
                          type="text"
                          matInput
                          [value]="data?.selectedValuesCountText"
                          *ngIf="
                            data?.showSelected &&
                            data?.column?.type !== SEARCH_FILTER.TYPES.RANGE
                          "
                          (focus)="data.showSelected = false; data.value = ''"
                        />

                        <input
                          id="value-{{ i }}"
                          placeholder="Values"
                          *ngIf="!data?.showSelected"
                          matInput
                          [ngModel]="data.value"
                          name="value-{{ i }}"
                          [pattern]="getPatternForValues(data)"
                          (input)="
                            updateSelectedFilters($event.target.value, data)
                          "
                          (focus)="
                            data.showSelected = false;
                            data?.column?.type !== SEARCH_FILTER.TYPES.RANGE
                              ? (data.value = '')
                              : false
                          "
                          [disabled]="!data?.field"
                          (mouseenter)="
                            focusManullyWhenDynamicInputs('value-' + i)
                          "
                          (blur)="updateValueCount(data)"
                        />
                        <mat-error
                          class="error-text text-trunlcate"
                          *ngIf="filterForm.form.controls?.['value-'+i]?.errors?.pattern"
                          >{{ data?.errMessage }}</mat-error
                        >
                      </mat-form-field>

                      <mat-form-field
                        appearance="outline"
                        class="ig__input-value"
                        *ngIf="
                          data?.column?.type === SEARCH_FILTER.TYPES.SELECT_PILL
                        "
                      >
                        <!-- <mat-label *ngIf="">Values</mat-label> -->
                        <mat-select
                          [placeholder]="!data?.value?.length ? 'Values' : ''"
                          [(value)]="data.value"
                          multiple
                          (selectionChange)="
                            updateSelectedFilters(data?.value, data)
                          "
                          [disabled]="!data?.field"
                        >
                          <mat-select-trigger>
                            <span>
                              {{ data?.value?.length || 0 }}
                              {{ data?.column?.name }} Selected
                            </span>
                          </mat-select-trigger>

                          <mat-option
                            *ngFor="
                              let obj of selectPillMap.get(data?.column?.key)
                                ?.dropDown.values
                            "
                            [value]="obj"
                            >{{ obj?.label }}</mat-option
                          >
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field
                        appearance="outline"
                        class="ig__input-value"
                        *ngIf="
                          data?.column?.type ===
                          SEARCH_FILTER.TYPES.CHECKBOX_GROUP
                        "
                      >
                        <!-- <mat-label *ngIf="!data?.value?.length">Values</mat-label> -->
                        <mat-select
                          [placeholder]="!data?.value?.length ? 'values' : ''"
                          [(value)]="data.value"
                          multiple
                          (selectionChange)="
                            updateSelectedFilters(data?.value, data)
                          "
                          [disabled]="!data?.field"
                        >
                          <mat-option
                            *ngFor="
                              let obj of checkboxGroups[data?.column?.key]
                            "
                            [value]="obj?.value"
                            >{{ obj?.displayName }}</mat-option
                          >
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div
                  class="filter-input-text d-flex align-items-center pb-2"
                  *ngIf="
                    data?.selectedInputValues?.length &&
                    data?.column?.type !== SEARCH_FILTER.TYPES.RANGE
                  "
                >
                  <span
                    [matMenuTriggerFor]="moreInputs"
                    (click)="$event.stopPropagation()"
                    class="more-text d-flex align-items-center justify-content-center cursor-pointer"
                    >{{
                      data?.selectedInputValues?.length > 9
                        ? "9+"
                        : data?.selectedInputValues?.length
                    }}</span
                  >

                  <mat-menu #moreInputs="matMenu">
                    <ng-container
                      *ngFor="
                        let value of data?.selectedInputValues;
                        let valIndex = index
                      "
                    >
                      <div mat-menu-item>
                        <div class="d-flex align-items-center">
                          <mat-icon
                            class="cursor-pointer remove-icon"
                            (click)="
                              $event.stopPropagation();
                              updateSelectedValueCount(data, valIndex)
                            "
                            >clear</mat-icon
                          >
                          <span class=""> {{ value }}</span>
                        </div>
                      </div>
                    </ng-container>
                  </mat-menu>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="d-flex align-items-center pl-2 justify-content-center">
          <button
            mat-button
            color="primary"
            class="d-flex cursor-pointer add-filter-btn"
            (click)="clearAllFilters()"
          >
            CLEAR FILTERS
          </button>
          <button
            type="button"
            mat-stroked-button
            color="primary"
            (click)="addNewFilter()"
            class="d-flex cursor-pointer align-items-center ml-2 ig__btn-add-filter"
            [disabled]="
              newSelections?.length >= filterRowColumns?.length ||
              isNewFilterAllowed() ||
              filterForm.invalid
            "
          >
            <span class="ml-2 add-filter-btn">ADD FILTER</span>
          </button>
        </div>
      </div>
    </mat-menu>

    <mat-menu
      #selectedFilterMenu="matMenu"
      xPosition="before"
      yPosition="below"
      backdropClass="advance-search-filter-panel2"
    >
      <div class="d-flex flex-column overflow-hidden px-2 pt-2">
        <div class="fields-panel overflow-auto d-flex flex-column" #scrollMe2>
          <div *ngFor="let item of newSelections">
            <span class="selected-label">{{ item?.column?.name }}: </span>
            <span class="selected-value">{{
              item?.selectedInputValues?.length
                ? item?.selectedInputValues?.join(", ")
                : "-"
            }}</span>
          </div>
        </div>
      </div>
    </mat-menu>
  </div>
</ng-container>

<div>
  <!-- <mat-menu #dropdown="matMenu" [class]="
      'cdk-component-container--' +
      themeShade +
      ' v-two-dot-o-' +
      themeShade.slice(0, 1) +
      't'
    ">

  </mat-menu> -->
</div>

<!-- <div class="content-wrapper position-absolute d-flex flex-column w-100 h-100">
  <div class="content-body col">
    <div class="row no-gutters justifiy-content-start align-items-start">
      <div class="col-sm-3 py-2 px-2" *ngFor="let column of filterRowColumns">
        <div class="filter-column-label">{{ column?.name }}</div>
        <ng-container *ngIf="checkboxGroups[column?.key]">
          <app-checkbox-group [groupConfig]="checkboxGroups[column?.key]"
            (changeSelection)="onChangeSelection($event, column)"></app-checkbox-group>
        </ng-container>
        <ng-container *ngIf="autoCompleteMap.has(column?.key)">
          <app-auto-complete [config]="autoCompleteMap.get(column?.key)" [readOnly]="column?.readOnly"
            [removable]="!column?.readOnly" (changeSelection)="onChangeSelection($event, column)"></app-auto-complete>
        </ng-container>
        <ng-container *ngIf="numberOperatorMap.has(column?.key)">
          <app-number-operator [config]="numberOperatorMap.get(column?.key)"
            (changeSelection)="onChangeSelection($event, column)"></app-number-operator>
        </ng-container>
        <ng-container *ngIf="selectPillMap.has(column?.key)">
          <app-select-pill [config]="selectPillMap.get(column?.key)" [multiSelect]="true" [displayUnderline]="true"
            [displayLabel]="false" (valuesChange)="onChangeSelection($event, column)"></app-select-pill>
        </ng-container>
      </div>
    </div>
  </div>
</div> -->
