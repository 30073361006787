<input
  type="checkbox"
  id="toggle-button-checkbox"
  (change)="toggleChange($event)"
  [checked]="!isCheck"
  #toggleBtn
/>
<label
  class="toggle-button-switch bdr-grey3"
  [ngClass]="isDisabled ? 'pe-none bg-grey10' : 'cursor-pointer bg-blue8'"
  [ngStyle]="{
    width: toggleButtonWidth
  }"
>
  <span class="switch__text p-4" *ngIf="showToggleText">{{
    selectedText
  }}</span>
</label>
<div
  class="toggle-button bdr-grey3"
  [ngStyle]="{ width: switchWidth }"
  [ngClass]="isDisabled ? 'pe-none' : 'cursor-pointer'"
>
  <div class="toggle-button-first" (click)="onClickIcon(left)">
    <span *ngIf="showToggleText">{{ left }}</span>
  </div>
  <div class="toggle-button-second" (click)="onClickIcon(right)">
    <span *ngIf="showToggleText">{{ right }}</span>
  </div>
</div>
