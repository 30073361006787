import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  DeleteAction,
  NetworkAutoOnboardAllWorkflow,
  NetworkAutoOnboardRuleWorkflow,
  NetworkAutoOnboardTabs,
  NetworkDeleteConfig,
  NetworkStatus,
} from '@app/v2/onboard/network/network.constants';
import {
  IOrchestratorComponent,
  NetworkAutoOnboardStep,
  OrchestratorStep,
  OrchestratorWorkflow,
} from '@app/common/components/drawer/orchestrator/orchestrator.model';
import { OrchestratorService } from '@app/common/components/drawer/orchestrator/orchestrator.service';
import { Subject, Subscription } from 'rxjs';
import {
  ICloudController,
  PrivateCloudController,
  PublicCloudController,
} from '../../../utils/controllers';
import { ObjectUtil } from '@prosimoio/services';
import { NetworkUtil } from '@app/v2/onboard/network/network.util';
import { ModelFormGroup } from '@app/common/util/form-util';
import {
  CloudCredential,
  Namespace,
  Network,
  PrivateCloud,
} from '@app/common/models';
import { PDashLocalStoreUtil } from '@app/state/web-pdash.util';
import {
  APP_SETUP_STATUS,
  DIALOG_TOKEN,
  IMAGE_PATHS,
  LAST_UPDATED_TEXT,
  NETWORK_HOSTED_TYPE,
  NOTIFICATION_MESSAGES,
} from '@app/common/util/constants';
import { NetworkOnboardService } from '@app/v2/onboard/network/network.service';
import { OnboardTemplateService } from '@app/common/components/drawer/templates/onboard-template/onboard-template.service';
import {
  DeleteDialogComponent,
  LoaderService,
  SelectSearchConfig,
  ToastService,
} from '@prosimoio/components';
import { AppUtil } from '@app/common/util/app-util';
import { take, takeUntil } from 'rxjs/operators';
import { LightboxBtnType } from '@app/dashboard/diagnose/diagnostics/lightbox-form-template/lightbox-model';
import { NetworkAutoDefinition } from './auto-definition.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavConfirmationService } from '@app/common/util/nav-confirmation.service';

@Component({
  selector: 'app-auto-definition',
  templateUrl: './auto-definition.component.html',
  styleUrls: ['./auto-definition.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoDefinitionComponent
  implements IOrchestratorComponent, OnInit, OnDestroy
{
  stopSubs$: Subject<void>;
  definitionForm: ModelFormGroup<NetworkAutoDefinition>;
  geographyControl: FormControl<string>;
  autoOnboardTypeSub: Subscription;
  loading = {
    namespaces: true,
    clouds: true,
  };
  step = NetworkAutoOnboardStep.DEFINE;
  networkStatus: NetworkStatus;
  isStepValid = false;
  cloudController: ICloudController;
  defaultHint =
    'Networks bring subnets within a VPC/VNet behind the Prosimo fabric and routes the traffic via the fabric.';
  hint = '';
  isEditable: boolean = true;
  selectNamespaceConfig: SelectSearchConfig = {
    optionList: [],
    placeholder: 'Select Namespace',
    uiThemeMode: PDashLocalStoreUtil.getUIThemeMode().toLowerCase() + '-theme',
    showSearch: true,
    isMultiSelection: false,
    isServerSideSearch: false,
    setKeyNameOnControl: true,
    keyName: 'id',
    addOption: false,
    panelWidthClass: 'select-width-none',
    displayName: 'name',
  };
  selectGeographyConfig: SelectSearchConfig = {
    optionList: [],
    placeholder: 'Select Location',
    uiThemeMode: PDashLocalStoreUtil.getUIThemeMode().toLowerCase() + '-theme',
    showSearch: true,
    isMultiSelection: false,
    isServerSideSearch: false,
    setKeyNameOnControl: false,
    addOption: false,
    panelWidthClass: 'select-width-none',
  };
  selectDCConfig: SelectSearchConfig = {
    optionList: [],
    placeholder: 'Select Private Cloud',
    uiThemeMode: PDashLocalStoreUtil.getUIThemeMode().toLowerCase() + '-theme',
    showSearch: true,
    isMultiSelection: false,
    isServerSideSearch: false,
    setKeyNameOnControl: true,
    keyName: 'id',
    addOption: false,
    panelWidthClass: 'select-width-none',
    displayName: 'name',
  };

  networkBackup: Network;
  namespaces: Array<Namespace> = [];
  publicClouds: Array<CloudCredential> = [];
  privateClouds: Array<PrivateCloud> = [];

  uiThemeMode: string = PDashLocalStoreUtil.getUIThemeMode().toLowerCase();
  tooltipThemeClass: string;

  COPY_NETWORK_HOSTED_TYPE = NETWORK_HOSTED_TYPE;
  errorIcon = IMAGE_PATHS.VISUAL_ONBOARD.ERROR;

  @Input() data: Network;
  @Input() errorPayload: any;

  constructor(
    private networkOnboardService: NetworkOnboardService,
    private orchestratorService: OrchestratorService,
    private onboardTemplateService: OnboardTemplateService,
    private loaderService: LoaderService,
    private navConfirmationService: NavConfirmationService,
    private appUtil: AppUtil,
    private toastService: ToastService,
    private cdr: ChangeDetectorRef
  ) {
    this.stopSubs$ = new Subject();
  }

  ngOnInit(): void {
    this.networkBackup = ObjectUtil.deepClone(this.data);
    this.networkStatus = NetworkUtil.getNetworkState(this.data);

    this.toastService.setUITheme(this.uiThemeMode + '-theme');
    this.initForm();

    if (
      this.errorPayload &&
      this.errorPayload.errorStep === NetworkAutoOnboardStep.DEFINE
    ) {
      this.hint = this.errorPayload.errorMessage;
    } else {
      this.hint = this.defaultHint;
    }

    this.onboardTemplateService.setNameState({
      disabled: ![NetworkStatus.NEW].includes(this.networkStatus),
    });

    PDashLocalStoreUtil.getUIThemeModeAsObservable()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (uiThemeMode) => {
          this.uiThemeMode = uiThemeMode.toLowerCase();
          this.selectNamespaceConfig.uiThemeMode = this.uiThemeMode + '-theme';
          this.toastService.setUITheme(this.uiThemeMode + '-theme');
          this.tooltipThemeClass =
            'cdk-component-container--' + this.uiThemeMode + '-theme-dark';
        },
      });
    this.appUtil.updateDrawerButtonConfig({
      mainBtn: { ...LightboxBtnType.NEXT, disabled: true },
      cancelBtn: LightboxBtnType.CANCEL,
    });
    this.onboardTemplateService
      .getNameInput()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (name) => {
          this.definitionForm.controls.name.patchValue(name);
        },
      });
    this.appUtil
      .getDrawerResponse()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (response) => {
          if (response.code === LightboxBtnType.NEXT.code) {
            this.updateNetwork();
          } else {
            if (this.detectFormChanges()) {
              this.navConfirmationService.navConfirmationPopup(null);
              return;
            }
            this.appUtil.setDrawerState(false);
            this.appUtil.setDynamicDrawerResponse({
              context: NetworkAutoOnboardStep.DEFINE,
              action: response.code,
              payload: null,
            });
          }
        },
      });
    this.orchestratorService
      .getTabChangeRequest()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (step: NetworkAutoOnboardStep) => {
          const formData = <NetworkAutoDefinition>(
            this.definitionForm.getRawValue()
          );
          this.cloudController.updateAutoDefinition(this.data, formData);
          this.orchestratorService.setGotoStop(step);
        },
      });

    this.setDrawerFooter();
    this.fetchStaticData();
  }

  setDrawerFooter() {
    this.onboardTemplateService
      .getDeleteResponse()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (response) => {
          if (response.action === DeleteAction.DELETE) {
            this.deleteNetwork();
          } else if (response.action === DeleteAction.OFFBOARD) {
            this.offboardNetwork(response.confirmation);
          }
        },
      });

    if (this.data.createdTime) {
      this.onboardTemplateService.setDateInfo(
        `${LAST_UPDATED_TEXT}${this.appUtil.transformDateTime(
          this.data?.updatedTime
        )}`
      );
    }
    if (
      this.networkStatus !== NetworkStatus.NEW &&
      this.networkStatus !== NetworkStatus['IN-PROGRESS']
    ) {
      this.onboardTemplateService.setDeleteConfig({
        disabled: false,
        message: NetworkDeleteConfig[this.networkStatus].btn,
        tooltip: NetworkDeleteConfig[this.networkStatus].btn,
        panelConfig: {
          btnColorBlack: false,
          deleteMsg: NetworkDeleteConfig[this.networkStatus].panelMessage,
          deleteName: this.data.name,
          deleteButtonName:
            NetworkDeleteConfig[this.networkStatus].panelBtnName,
          action: NetworkDeleteConfig[this.networkStatus].action,
          confirmation: NetworkDeleteConfig[this.networkStatus].confirmation,
        },
      });
    }
  }

  setEditable() {
    if (
      this.networkStatus === NetworkStatus.DEPLOYED ||
      this.networkStatus === NetworkStatus['IN-PROGRESS']
    ) {
      this.isEditable = false;
      this.definitionForm.disable();
      this.geographyControl.disable();
      if (this.data.status === APP_SETUP_STATUS.DEPLOYED) {
        this.definitionForm.controls.exportable.enable();
        this.definitionForm.controls.namespace.enable();
        this.definitionForm.controls.privateCloud.controls.ruleBasedOnboarding.enable();

        // TODO: Add when Public cloud is supported
        // this.definitionForm.controls.publicCloud?.controls.cloudNetworks?.controls.forEach(
        //   (control) => {
        //     control.controls.subnets.enable();
        //     control.controls.cloudNetworkID.enable();
        //   }
        // );
      }
    }
  }

  fetchStaticData() {
    this.networkOnboardService.getNamespaces().subscribe({
      next: (namespaces) => {
        this.namespaces = namespaces;
        this.selectNamespaceConfig.optionList = namespaces;
        this.loading.namespaces = false;
        if (this.networkStatus === NetworkStatus.NEW) {
          this.definitionForm.controls.namespace.patchValue(
            this.getDefaultNamespaceId(),
            { emitEvent: false }
          );
        }
        this.cdr.markForCheck();
      },
      error: (error) => {
        this.loading.namespaces = false;
        this.cdr.markForCheck();
      },
    });
    this.fetchClouds();
  }

  fetchClouds() {
    // TODO: Add when Public cloud is supported
    // if (
    //   this.definitionForm.controls.cloudType.value ===
    //     NETWORK_HOSTED_TYPE.PUBLIC &&
    //   !this.publicClouds.length
    // ) {
    //   this.loading.clouds = true;
    //   this.cdr.markForCheck();
    //   this.networkOnboardService.getPublicClouds().subscribe({
    //     next: (clouds) => {
    //       this.publicClouds = clouds;
    //       this.loading.clouds = false;

    //       if (
    //         this.publicClouds.find(
    //           (cloud) =>
    //             cloud.id === this.definitionForm.controls.cloudKeyID.value
    //         )?.keyType === CLOUD_KEY_TYPES.UNMANAGED
    //       ) {
    //         this.definitionForm.controls.isUnmanaged.patchValue(true, {
    //           emitEvent: false,
    //         });
    //         this.isUnmanaged = true;
    //       }
    //       this.setCloudDropdown();

    //       this.fetchSubnets();

    //       this.cdr.markForCheck();
    //     },
    //     error: (error) => {
    //       this.loading.clouds = false;
    //       this.cdr.markForCheck();
    //     },
    //   });
    //   return;
    // }

    if (
      this.definitionForm.controls.cloudType.value ===
        NETWORK_HOSTED_TYPE.PRIVATE &&
      !this.privateClouds.length
    ) {
      this.loading.clouds = true;
      this.cdr.markForCheck();
      this.networkOnboardService.getPrivateClouds().subscribe({
        next: (clouds) => {
          this.privateClouds = clouds;
          this.loading.clouds = false;

          this.selectGeographyConfig.optionList = Array.from(
            new Set(
              this.privateClouds.map((cloud) => cloud.location.countryName)
            )
          ).sort((a, b) => (a < b ? -1 : 1));
          if (this.definitionForm.controls.cloudKeyID.value) {
            this.geographyControl.patchValue(
              this.privateClouds.find(
                (cloud) =>
                  cloud.id === this.definitionForm.controls.cloudKeyID.value
              ).location.countryName
            );
          }
          this.cdr.markForCheck();
        },
        error: (error) => {
          this.loading.clouds = false;
          this.cdr.markForCheck();
        },
      });
      return;
    }
  }

  initForm() {
    const cloudType = this.getCloudType();
    this.setCloudController(cloudType);
    // TODO: Cleanup
    this.definitionForm = new FormGroup({
      name: new FormControl(this.data?.name || '', [Validators.required]),
      namespace: new FormControl(this.data?.namespaceID || ''),
      cloudType: new FormControl(cloudType),
      exportable: new FormControl(!!this.data?.exportable),
      cloudKeyID: new FormControl(
        this.cloudController.getCloudKeyID(this.data)
      ),
    });
    this.geographyControl = new FormControl('');
    this.setCloudController(this.definitionForm.controls.cloudType.value);
    this.setCloudType(cloudType, true);

    this.setEditable();
    this.definitionForm.controls.cloudType.valueChanges
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (cloudType: string) => {
          this.setCloudController(cloudType);
          this.setCloudType(cloudType, false);
          this.cdr.markForCheck();
          // this.definitionForm.controls.cloudKeyID.reset('', {
          //   emitEvent: false,
          // });
          // this.definitionForm.controls.cloudRegion.reset('');
          this.fetchClouds();
        },
      });
    this.geographyControl.valueChanges
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (location) => {
          if (!location) {
            return;
          }

          this.selectDCConfig.optionList = this.privateClouds.filter(
            (cloud) => cloud.location.countryName === location
          );

          if (
            this.privateClouds.find(
              (cloud) =>
                cloud.id === this.definitionForm.controls.cloudKeyID.value
            )?.location.countryName !== location
          ) {
            this.definitionForm.controls.cloudKeyID.reset('');
          }
        },
      });
    this.definitionForm.valueChanges.pipe(takeUntil(this.stopSubs$)).subscribe({
      next: (_) => {
        this.validateStep();
      },
    });
  }

  getDefaultNamespaceId() {
    const defaultNamespace = this.namespaces?.find(
      (namespace) => namespace?.name === 'default'
    );
    return defaultNamespace.id ? defaultNamespace.id : '';
  }

  setCloudController(cloudType: string): void {
    if (cloudType === NETWORK_HOSTED_TYPE.PUBLIC) {
      this.cloudController = new PublicCloudController(
        this.orchestratorService
      );
    } else if (cloudType === NETWORK_HOSTED_TYPE.PRIVATE) {
      this.cloudController = new PrivateCloudController(
        this.orchestratorService
      );
    }
  }

  getCloudType(): string {
    if (this.data.publicCloud?.cloudType) {
      return this.data.publicCloud?.cloudType;
    } else if (this.data.privateCloud?.cloudType) {
      return this.data.privateCloud?.cloudType;
    } else if (this.data.ownTransitCloud?.cloudType) {
      return this.data.ownTransitCloud?.cloudType;
    } else return NETWORK_HOSTED_TYPE.PRIVATE;
  }

  setCloudType(cloudType: string, isInit: boolean) {
    if (cloudType !== NETWORK_HOSTED_TYPE.PUBLIC) {
      if (!isInit) {
        this.definitionForm.controls.exportable.patchValue(false, {
          emitEvent: false,
        });
      }
      // TODO: Add when Public cloud is supported
      // this.multiSelectConfig = null;
      // this.isUnmanaged = false;
    } else {
      if (!isInit) {
        this.definitionForm.controls.exportable.patchValue(true, {
          emitEvent: false,
        });
      }
    }

    switch (cloudType) {
      case NETWORK_HOSTED_TYPE.PUBLIC:
        // TODO: Add when Public cloud is supported
        // this.definitionForm.removeControl('privateCloud', { emitEvent: false });
        // this.definitionForm.removeControl('ownTransitCloud', {
        //   emitEvent: false,
        // });
        // this.definitionForm.addControl(
        //   'publicCloud',
        //   new FormGroup({
        //   })
        // );
        this.geographyControl.reset('', { emitEvent: false });
        break;
      case NETWORK_HOSTED_TYPE.PRIVATE:
        // TODO: Add when Public cloud is supported
        // this.definitionForm.removeControl('publicCloud', { emitEvent: false });
        this.definitionForm.addControl(
          'privateCloud',
          new FormGroup({
            ruleBasedOnboarding: new FormControl(
              !!this.data.privateCloud?.ruleBasedOnboarding
            ),
          })
        );
        if (!this.autoOnboardTypeSub) {
          this.autoOnboardTypeSub =
            this.definitionForm.controls.privateCloud?.controls.ruleBasedOnboarding.valueChanges
              .pipe(takeUntil(this.stopSubs$))
              .subscribe({
                next: (value) => {
                  this.orchestratorService.setOrchWorkflow({
                    tabs: this.definitionForm.controls.privateCloud.controls
                      .ruleBasedOnboarding.value
                      ? NetworkAutoOnboardTabs
                      : NetworkAutoOnboardTabs.filter(
                          (tab) => tab.code !== NetworkAutoOnboardStep.RULE
                        ),
                    workflowPayload: null,
                    workflow: (this.definitionForm.controls.privateCloud
                      .controls.ruleBasedOnboarding.value
                      ? NetworkAutoOnboardRuleWorkflow
                      : NetworkAutoOnboardAllWorkflow) as OrchestratorWorkflow<OrchestratorStep>,
                  });
                },
              });
        }
        break;
    }
  }

  validateStep() {
    if (this.networkStatus !== NetworkStatus.NEW && !this.detectFormChanges()) {
      this.isStepValid = true;
    } else if (!this.definitionForm.controls.name.value) {
      this.isStepValid = false;
    } else if (!this.definitionForm.valid) {
      this.isStepValid = false;
    } else {
      const formData = <NetworkAutoDefinition>this.definitionForm.getRawValue();
      this.isStepValid = this.cloudController.validateAutoDefinition(formData);
    }
    if (this.isStepValid) {
      this.appUtil.updateDrawerButtonConfig({
        mainBtn: { ...LightboxBtnType.NEXT, disabled: false },
        cancelBtn: LightboxBtnType.CANCEL,
      });
    } else {
      this.appUtil.updateDrawerButtonConfig({
        mainBtn: { ...LightboxBtnType.NEXT, disabled: true },
        cancelBtn: LightboxBtnType.CANCEL,
      });
    }
    this.cdr.markForCheck();
  }

  detectFormChanges(): boolean {
    const formData = <NetworkAutoDefinition>this.definitionForm.getRawValue();
    const changes = this.cloudController.detectAutoDefinitionFormChanges(
      this.data,
      formData
    );
    this.navConfirmationService.updateNavData({
      check: changes,
      elem: null,
    });
    return changes;
  }

  updateNetwork() {
    this.validateStep();
    if (!this.isStepValid) {
      return;
    }

    if (!this.detectFormChanges()) {
      this.markStepComplete();
      return;
    }

    const formData = <NetworkAutoDefinition>this.definitionForm.getRawValue();
    this.cloudController.updateAutoDefinition(this.data, formData);
    this.markStepComplete();
  }

  deleteNetwork() {
    if (!this.data.id) {
      return;
    }

    this.loaderService.setLoaderStatus(true);
    this.networkOnboardService.deleteNetwork(this.data.id).subscribe({
      next: (resp) => {
        this.loaderService.setLoaderStatus(false);
        this.toastService.success(
          NOTIFICATION_MESSAGES.DELETE.SUCCESS(this.data.name)
        );
        this.appUtil.setDynamicDrawerResponse({
          action: 'delete',
          context: NetworkAutoOnboardStep.DEFINE,
          payload: null,
        });
        this.appUtil.setDrawerState(false);
      },
      error: (error) => {
        this.loaderService.setLoaderStatus(false);
        this.showErrorMessage(
          {
            errorMsg:
              error?.error?.message ||
              NOTIFICATION_MESSAGES.DELETE.FAIL(this.data.name),
          },
          () => {
            this.appUtil.setDrawerState(false);
          }
        );
      },
    });
  }

  offboardNetwork(forced: boolean) {
    if (!this.data.id) {
      return;
    }
    if (this.networkStatus !== NetworkStatus.DEPLOYED) {
      return;
    }

    this.loaderService.setLoaderStatus(true);
    this.networkOnboardService.offboardNetwork(this.data.id, forced).subscribe({
      next: (resp) => {
        this.loaderService.setLoaderStatus(false);
        this.toastService.success(
          NOTIFICATION_MESSAGES.OFFBOARD.SUCCESS(this.data.name)
        );
        this.appUtil.setDynamicDrawerResponse({
          action: 'offboard',
          context: NetworkAutoOnboardStep.DEFINE,
          payload: null,
        });
        this.appUtil.setDrawerState(false);
      },
      error: (error) => {
        this.loaderService.setLoaderStatus(false);
        this.showErrorMessage(
          {
            errorMsg:
              error?.error?.message ||
              NOTIFICATION_MESSAGES.OFFBOARD.FAIL(this.data.name),
          },
          () => {
            this.appUtil.setDrawerState(false);
          }
        );
      },
    });
  }

  showErrorMessage(popupConfig, action: (...args: any[]) => void) {
    const dialogData = {
      ...popupConfig,
      isInnerHTML: true,
    };

    const injector = this.appUtil?.createInjector(
      DIALOG_TOKEN.INJECTOR_TOKEN_DATA.DIALOG_DATA_TOKEN.NAME,
      dialogData
    );

    const componentRef = this.appUtil?.initializeDeleteOverlay(
      DeleteDialogComponent,
      injector,
      this.uiThemeMode + '-theme',
      '340px'
    );
    (componentRef.instance as DeleteDialogComponent)?.closeDialogEvent
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          action(response);
        },
        error: (err) => {
          console.warn(err);
        },
        complete: () => {
          this.appUtil?.destroyOverlay();
        },
      });
  }

  markStepComplete() {
    this.orchestratorService.setStepComplete(NetworkAutoOnboardStep.DEFINE);
  }

  ngOnDestroy(): void {
    this.stopSubs$.next();
    this.stopSubs$.complete();
    this.autoOnboardTypeSub.unsubscribe();
    this.navConfirmationService.updateNavData(null);
  }
}
