import { Type } from '@angular/core';
import { TabConfig } from '@prosimoio/components';
import {
  OnboardEntity,
  OnboardEntityType,
} from '../templates/onboard-template/onboard-template.model';
import { EdgeDrawerStep } from '../../edge-drawer/edge-drawer.constants';

export interface IOrchestratorComponent {
  markStepComplete: () => void;
}

export type NextStep<T> = {
  component: Type<IOrchestratorComponent>;
  next: T;
};

export enum AppOnboardStep {
  DEFINE = 'DEFINE',
  APP_SETTINGS = 'APP_SETTINGS',
  POLICY = 'POLICY',
  ADVANCED = 'ADVANCED',
}

// export type OrchestratorStep = AppOnboardStep;
export enum NetworkOnboardStep {
  DEFINE = 'DEFINE',
  VPC = 'VPC',
  POLICY = 'POLICY',
} // Replace with actual Steps

export enum NetworkAutoOnboardStep {
  DEFINE = 'DEFINE',
  RULE = 'RULE',
  POLICY = 'POLICY',
}

export enum ManagedFirewallOnboardSteps {
  SETTINGS = 'SETTINGS',
  ADVANCED_SETTINGS = 'ADVANCED SETTINGS',
}

export type OrchestratorStep =
  | NetworkOnboardStep
  | NetworkAutoOnboardStep
  | ManagedFirewallOnboardSteps
  | AppOnboardStep
  | EdgeDrawerStep;

export type OrchestratorWorkflow<T extends OrchestratorStep> = {
  [step in T]: NextStep<T>;
};

export interface OrchestratorPayload<
  T extends OrchestratorStep,
  U extends OnboardEntityType
> {
  tabs: Array<TabConfig>;
  isHideNameInput?: boolean;
  workflowPayload: OnboardEntity<U>;
  errorPayload?: any;
  workflow: OrchestratorWorkflow<T>;
  requestTabChange?: boolean;
  miscellaneousData?: unknown;
}
