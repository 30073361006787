import { Network } from '@app/common/models';
import {
  OnboardEntity,
  OnboardEntityType,
} from '@app/common/components/drawer/templates/onboard-template/onboard-template.model';
import {
  NetworkAutoOnboardStep,
  NetworkOnboardStep,
} from '@app/common/components/drawer/orchestrator/orchestrator.model';

import { NetworkErrorFields } from './network.constants';

export type NetworkOnboardPayload = OnboardEntity<OnboardEntityType.NETWORK> &
  Network;

export const NetworkErrorStepMap: {
  [field in NetworkErrorFields]: NetworkOnboardStep | NetworkAutoOnboardStep;
} = {
  name: NetworkOnboardStep.DEFINE,
  exportable: NetworkOnboardStep.DEFINE,
  namespaceID: NetworkOnboardStep.DEFINE,
  'publicCloud.cloud': NetworkOnboardStep.DEFINE,
  'publicCloud.cloudKeyID': NetworkOnboardStep.DEFINE,
  'publicCloud.cloudRegion': NetworkOnboardStep.DEFINE,
  'publicCloud.cloudType': NetworkOnboardStep.DEFINE,
  'publicCloud.connectionOption': NetworkOnboardStep.VPC,
  'publicCloud.cloudNetworks.cloudNetworkID': NetworkOnboardStep.DEFINE,
  'publicCloud.cloudNetworks.subnet': NetworkOnboardStep.DEFINE,
  'publicCloud.cloudNetworks.virtualSubnet': NetworkOnboardStep.DEFINE,
  'publicCloud.cloudNetworks.hubID': NetworkOnboardStep.VPC,
  'publicCloud.cloudNetworks.connectivityType': NetworkOnboardStep.VPC,
  'publicCloud.cloudNetworks.connectorPlacement': NetworkOnboardStep.VPC,
  'publicCloud.cloudNetworks.connectorSettings.subnets': NetworkOnboardStep.VPC,
  'publicCloud.cloudNetworks.connectorSettings.bandwidthRange.min':
    NetworkOnboardStep.VPC,
  'publicCloud.cloudNetworks.connectorSettings.bandwidthRange.max':
    NetworkOnboardStep.VPC,
  'publicCloud.cloudNetworks.serviceSubnets.svcID': NetworkOnboardStep.VPC,
  'publicCloud.cloudNetworks.serviceSubnets.svcName': NetworkOnboardStep.VPC,
  'publicCloud.cloudNetworks.serviceSubnets.mode': NetworkOnboardStep.VPC,
  'publicCloud.cloudNetworks.serviceSubnets.subnets.type':
    NetworkOnboardStep.VPC,
  'publicCloud.cloudNetworks.serviceSubnets.subnets.ipAddrCidrs':
    NetworkOnboardStep.VPC,
  'privateCloud.cloud': NetworkOnboardStep.DEFINE,
  'privateCloud.cloudRegion': NetworkOnboardStep.DEFINE,
  'privateCloud.cloudType': NetworkOnboardStep.DEFINE,
  'privateCloud.connectionOption': NetworkOnboardStep.DEFINE,
  'privateCloud.connectorGroupID': NetworkOnboardStep.DEFINE,
  'privateCloud.privateCloudID': NetworkOnboardStep.DEFINE,
  'privateCloud.subnets': NetworkOnboardStep.DEFINE,
  'privateCloud.ruleBasedOnboarding': NetworkAutoOnboardStep.DEFINE,
  'privateCloud.autoOnboardRules.rules.action': NetworkAutoOnboardStep.RULE,
  'privateCloud.autoOnboardRules.rules.prefixes.matchCondition':
    NetworkAutoOnboardStep.RULE,
  'privateCloud.autoOnboardRules.rules.prefixes.groups.id':
    NetworkAutoOnboardStep.RULE,
  'privateCloud.autoOnboardRules.rules.prefixes.values.max':
    NetworkAutoOnboardStep.RULE,
  'privateCloud.autoOnboardRules.rules.prefixes.values.min':
    NetworkAutoOnboardStep.RULE,
  'privateCloud.autoOnboardRules.rules.prefixes.values.prefix':
    NetworkAutoOnboardStep.RULE,
  'privateCloud.autoOnboardRules.rules.bgpCommunities.matchCondition':
    NetworkAutoOnboardStep.RULE,
  'privateCloud.autoOnboardRules.rules.bgpCommunities.communityGroups.id':
    NetworkAutoOnboardStep.RULE,
  'privateCloud.autoOnboardRules.rules.bgpCommunities.communities':
    NetworkAutoOnboardStep.RULE,
  'privateCloud.autoOnboardRules.rules.bgpNeighbours.values':
    NetworkAutoOnboardStep.RULE,
  'privateCloud.autoOnboardRules.rules.bgpNeighbours.matchCondition':
    NetworkAutoOnboardStep.RULE,
  'security.policies.id': NetworkOnboardStep.POLICY,
};

export type ErrorObject = {
  field: NetworkErrorFields;
  index?: number;
  message: string;
};

export type ChangeSetList = Array<ChangeSet>;

export type ChangeSet = {
  key: string;
  values: Array<{
    value: string;
    action: string;
  }>;
};

export type SubnetRouteMatch = {
  rfcRoutesDisabled: { [subnet: string]: string };
  rfcRoutesEnabled: { [subnet: string]: string };
  customRouteAdded: { [subnet: string]: string };
  customRouteNeeded: Array<string>;
};

export type SubnetRouteMatchArr = {
  rfcRoutesDisabled: Array<string>;
  rfcRoutesEnabled: Array<string>;
  customRouteAdded: Array<string>;
  customRouteNeeded: Array<string>;
};

export interface SortValues {
  field: string;
  asc: boolean;
}
