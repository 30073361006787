<div id="selectPill__container" *ngIf="config">
  <div class="row no-gutters select__container" *ngIf="config.dropDown">
    <div class="dropDown__container">
      <mat-form-field
        class="selectPill__content"
        [ngClass]="{
          'no-form-field-underline no-dropdown-arrow':
            (!displayUnderline && selectedList?.length >= 1) || !!selectedItem,
          'no-top-border': '!displayLabel'
        }"
      >
        <div
          *ngIf="displayLabel"
          class="selectPill__label pb-2"
          [ngClass]="{ displayOneLine: displayOneLine }"
        >
          {{ config?.dropDown?.label }}
        </div>
        <mat-select
          class="selectPill__select"
          [formControl]="selectControl"
          [multiple]="multiSelect"
          disableRipple
          [compareWith]="compareFn"
          [placeholder]="config?.dropDown?.placeholder"
          (selectionChange)="selectionChange($event)"
        >
          <mat-select-trigger *ngIf="displayTrigger">
            <span
              class="selected__txt"
              *ngIf="!displayOneLine && !displayUnderline && displayLabel"
            >
              {{ selectedList?.length }} {{ config.title }} selected
            </span>
            <span
              class="selected__txt"
              *ngIf="!displayOneLine && displayUnderline && displayLabel"
            >
              {{ selectedList?.length }} of
              {{ config?.dropDown?.values?.length }} {{ config.title }} selected
            </span>
            <div
              *ngIf="displayOneLine && selectedList?.length >= 1"
              class="d-flex align-items-center justify-content-between"
            >
              <div
                *ngIf="config?.targetKey"
                class="first-select-trigger-text selectPill selectPill--modified"
                [title]="selectedList[0][config.targetKey]"
                matTooltipPosition="below"
              >
                {{ selectedList[0][config.targetKey] }}
              </div>
              <div
                *ngIf="!config?.targetKey"
                class="first-select-trigger-text selectPill selectPill--modified"
                [title]="selectedList[0]"
                matTooltipPosition="below"
              >
                {{ selectedList[0] }}
              </div>
              <div
                class="mr-1 additional-select-trigger-text more"
                *ngIf="selectedList?.length > 1"
              >
                + {{ selectedList?.length - 1 }} more
              </div>

              <div class="ml-1 update__txt">Update</div>
            </div>
            <div
              *ngIf="selectedItem"
              class="d-flex align-items-center justify-content-between"
            >
              <div
                *ngIf="config?.targetKey"
                class="first-select-trigger-text selectPill selectPill--modified"
                [title]="selectedItem[config.targetKey]"
                matTooltipPosition="below"
              >
                {{ selectedItem[config.targetKey] }}
              </div>
              <div
                *ngIf="!config?.targetKey"
                class="first-select-trigger-text selectPill selectPill--modified"
                [title]="selectedItem"
                matTooltipPosition="below"
              >
                {{ selectedItem }}
              </div>
              <div class="ml-1 update__txt">Update</div>
            </div>
          </mat-select-trigger>
          <ng-container *ngFor="let val of config.dropDown.values">
            <mat-option
              *ngIf="config?.targetKey && val"
              [value]="val"
              [title]="val[config?.targetKey]"
            >
              {{ val[config?.targetKey] }}
            </mat-option>
            <mat-option *ngIf="!config?.targetKey" [value]="val" [title]="val">
              {{ val }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="pills__container" *ngIf="!displayOneLine && !hidePillList">
    <div class="select__pill" *ngFor="let item of selectedList; index as i">
      <div class="pill__action" (click)="removeSelectedItem(item, i)">
        <i class="material-icons close__icon"> close </i>
      </div>
      <div
        class="pill__label"
        [matTooltip]="item[config?.targetKey]"
        matTooltipPosition="below"
      >
        {{ item[config?.targetKey] }}
      </div>
    </div>
  </div>
</div>
