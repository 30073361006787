import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mask',
})
export class MaskPipe implements PipeTransform {
  transform(value: any, noOfCharsToDisplay: any = 0, maskChar: any = '*'): any {
    let result;
    if (value) {
      const len = value.length;
      const strToMask = value.substring(0, len - noOfCharsToDisplay);
      const strToShow = value.substring(len - noOfCharsToDisplay);
      result = strToMask.replace(/./g, maskChar) + strToShow;
    }
    return result;
  }
}
