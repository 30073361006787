import { Pipe, PipeTransform } from '@angular/core';
import { ACCESS_BASED_POLICY_ACTIONS, POLICY_TYPE_ACCESS_NAMES } from '@app/common/util/constants';

@Pipe({
  name: 'policyTypes',
})
export class PolicyTypesPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case POLICY_TYPE_ACCESS_NAMES['ALLOW-ALL']:
        return ACCESS_BASED_POLICY_ACTIONS.ALLOW_ALL;
      case POLICY_TYPE_ACCESS_NAMES['DENY-ALL']:
        return ACCESS_BASED_POLICY_ACTIONS.DENY_ALL;
      case POLICY_TYPE_ACCESS_NAMES['DEFAULT-MCN-POLICY']:
        return ACCESS_BASED_POLICY_ACTIONS.DEFAULT_MCN_POLICY;
      case POLICY_TYPE_ACCESS_NAMES['DENY-ALL-NETWORKS']:
        return ACCESS_BASED_POLICY_ACTIONS.DENY_ALL_NETWORKS;

      default:
        return value;
    }
  }
}
