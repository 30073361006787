import { LoginModel } from './login.model';

export class LoginModelSerializer {
  /**
   * @description : customized response data
   * @param json - response from the server
   */

  fromJson(json: any): LoginModel {
    const data = json.data;
    const login = new LoginModel();
    if (data) {
      login.token = data.token;
      login.tokenName = data.tokenName;
      login.generalToken = data.generalToken;
      login.generalTokenName = data.generalTokenName;
      login.firstname = data.firstname;
      login.lastname = data.lastname;
      login.username = data.username;
      login.type = data.type;
      login.userId = data.userID;
      login.prosimoAppStatus = data.prosimoAppStatus; // APP_SETUP_STATUS.DEPLOYED; //
      login.team = data.team;
      login.company = data.company;
      login.status = data.status;
      login.factors = data.factors;
      login.logo = data.logo || '';
      login.logoTitle = data.logoTitle || '';
      login.accountExpiry = data.accountExpiry;
      login.iconPath = data?.iconPath;
      login.accountType = data?.accountType;
      login.companyName = data?.companyName;
      login.loginURL = data?.loginURL;
      login.licenseStatus = data?.licenseStatus;
      login.subscriptionType = data?.subscriptionType || [];
      login.engLabsEnabled = data?.engLabsEnabled || false;
    }

    return login;
  }

  /**
   * @description : payload to authenticate a user
   * @param loginModel
   */
  toJson(loginModel: LoginModel): any {
    return loginModel;
  }

  toJsonArray(loginModel: LoginModel[]): any {
    return [];
  }
}
