import { TimePickerModel } from './time-picker.model';
import { NUMERIC_ROUND_OFF } from './../../common/util/constants';
import { DecimalPipe } from '@angular/common';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimePickerComponent implements OnInit, AfterViewInit {
  @Input() label = '';
  @Input() set hour(inHour: number) {
    this._hour = inHour;
  }
  @Input() set minute(inMinute: number) {
    this._minute = inMinute;
  }
  @Input() set seconds(inSeconds: number) {
    this._seconds = inSeconds;
  }
  @Output() selectionChange = new EventEmitter<TimePickerModel>();

  TWO_DIGITS = NUMERIC_ROUND_OFF.TWO_DIGITS;
  hours = [];
  minutes = [];
  _hour: any;
  _minute: any;
  _seconds: any;

  constructor(
    private cdr: ChangeDetectorRef,
    private decimalPipe: DecimalPipe
  ) {
    this.hours = this.generateNumberArray(24);
    this.minutes = this.generateNumberArray(60);
    this.initTime();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.emitChange();
  }

  initTime() {
    this._hour = this.transformValue(new Date().getHours());
    this._minute = this.transformValue(new Date().getMinutes());
    this._seconds = this.transformValue(new Date().getSeconds());
  }

  generateNumberArray(max: number): Array<number> {
    const arr = [];
    for (let i = 0; i < max; i++) {
      arr.push(i);
    }
    return arr;
  }

  onSelect(event: any, type: string) {
    const value = parseFloat(event.srcElement.value);
    const val = this.transformValue(value);
    if (type === 'hour') {
      this._hour = val;
    } else if (type === 'minute') {
      this._minute = val;
    } else {
      this._seconds = val;
    }
    this.emitChange();
    this.cdr.markForCheck();
  }

  transformValue(value) {
    return this.decimalPipe.transform(value, this.TWO_DIGITS);
  }

  emitChange() {
    let hour = parseFloat(this._hour);
    let minutes = parseFloat(this._minute);
    let seconds = this._seconds || 0;
    if (isNaN(hour)) {
      hour = new Date().getHours();
      seconds = new Date().getSeconds();
    }

    if (isNaN(minutes)) {
      minutes = new Date().getMinutes();
      seconds = new Date().getSeconds();
    }

    this.selectionChange.emit({ hour, minutes, seconds });
  }
}
