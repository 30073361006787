import { Query } from '@datorama/akita';
import {
  AdvancedSettingStore,
  AdvancedSettingState,
  advancedSettingStore,
} from './advanced-setting.store';

export class AdvancedSettingQuery extends Query<AdvancedSettingState> {
  constructor(protected store: AdvancedSettingStore) {
    super(store);
  }
}

export const advancedSettingQuery = new AdvancedSettingQuery(
  advancedSettingStore
);
