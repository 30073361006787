import { Network } from './network';

export type ConnectorSettings = {
  enabled?: boolean;
  subnets: Array<string>;
  bandwidth?: string;
  bandwidthName?: string;
  cloudNetworkId?: string;
  instanceType?: string;
  teamId?: string;
  updateStatus?: string;
  bandwidthRange: BandwidthRange;
};

export type BandwidthRange = {
  min: number;
  max: number;
};

export type User = {
  name: string;
  password: string;
};

export type DNSServer = {
  primaryDNS: string;
  secondaryDNS: string;
};

export type ConnectorSettingsDetails = {
  cloudNetworkID: string;
  connectorPlacement: string;
  connectorSettings: ConnectorSettings;
  networks: Array<Network>;
  region: string;
  subnets: Array<string>;
  updateStatus: string;
};

export type NodeSizeSettings = {
  bandwidth: string;
  bandwidthName: string;
  instanceType: string;
  teamId: string;
  updateStatus: string;
  bandwidthRange: BandwidthRange;
};

export type AttachPoint = {
  attachPointDisplayName: string;
  attachPointID: string;
};

export type SearchResponse<T> = {
  records: Array<T>;
  totalCount: number;
};

// Standardize smart search payload. Need to work with pDash team

export type SmartSearchFilterValues = {
  displayName: string;
  key: string;
  operators: Array<FilterOperator>;
  values: Array<string>;
  sortable: boolean;
};

export type FilterOperator = {
  displayName: string;
  key: string;
};

export type ColumnFilter = {
  values: Array<ColumnValue>;
  field: string;
};

export type ColumnValue = {
  value: string;
  operator: string;
};
