import { BreadcrumbConfig, TabConfig } from '@prosimoio/components';
import { CONSTANTS } from 'environments/environment';
import { VpcComponent } from './shared/components/drawer/vpc/vpc.component';
import { PolicyComponent } from './shared/components/drawer/policy/policy.component';
import { DefinitionComponent } from './shared/components/drawer/definition/definition.component';
import {
  NetworkAutoOnboardStep,
  NetworkOnboardStep,
  OrchestratorWorkflow,
} from '@app/common/components/drawer/orchestrator/orchestrator.model';
import {
  CLOUD_TYPES,
  CONNECTIVITY_OPTIONS_KEYS,
} from '@app/common/util/constants';
import { DIALOG_BUTTON_TYPES, IMAGE_PATHS } from '@app/common/util/constants';
import { AutoDefinitionComponent } from './shared/components/drawer/auto-definition/auto-definition.component';
import { AutoRuleComponent } from './shared/components/drawer/auto-rule/auto-rule.component';
import { SubnetRouteMatchArr } from './network.model';

export const DISCOVERED_NETWORK_FILTERED_VIEW_ICONS = {
  APP_BLUE: IMAGE_PATHS.APP_ONBOARD.DISCOVERY.APP_BLUE,
  APP_DARK: IMAGE_PATHS.APP_ONBOARD.DISCOVERY.APP_DARK,
  APP_GRAY: IMAGE_PATHS.APP_ONBOARD.DISCOVERY.APP_GRAY,
  CLOUD: IMAGE_PATHS.APP_ONBOARD.DISCOVERY.CLOUD,
  REGION: IMAGE_PATHS.APP_ONBOARD.DISCOVERY.REGION,
  SPACESHIP: IMAGE_PATHS.APP_ONBOARD.DISCOVERY.SPACESHIP,
  SUBNET_BLUE: IMAGE_PATHS.APP_ONBOARD.DISCOVERY.SUBNET_BLUE,
  SUBNET_DARK: IMAGE_PATHS.APP_ONBOARD.DISCOVERY.SUBNET_DARK,
  SUBNET_GRAY: IMAGE_PATHS.APP_ONBOARD.DISCOVERY.SUBNET_GRAY,
  TABLE_DARK: IMAGE_PATHS.APP_ONBOARD.DISCOVERY.TABLE_DARK,
  TABLE_GRAY: IMAGE_PATHS.APP_ONBOARD.DISCOVERY.TABLE_GRAY,
  TREE_DARK: IMAGE_PATHS.APP_ONBOARD.DISCOVERY.TREE_DARK,
  TREE_GRAY: IMAGE_PATHS.APP_ONBOARD.DISCOVERY.TREE_GRAY,
  VPC_DARK: IMAGE_PATHS.APP_ONBOARD.DISCOVERY.VPC_DARK,
  AWS_CSP: IMAGE_PATHS.APP_ONBOARD.DISCOVERY.AWS_CSP,
  AZURE_CSP: IMAGE_PATHS.APP_ONBOARD.DISCOVERY.AZURE_CSP,
  GCP_CSP: IMAGE_PATHS.APP_ONBOARD.DISCOVERY.GCP_CSP,
  BLUE_CHECK_MARK: IMAGE_PATHS.APP_ONBOARD.DISCOVERY.BLUE_CHECK_MARK,
  APP_DESELECTED: IMAGE_PATHS.APP_ONBOARD.APP_SWITCH.APP_DESELECTED,
};

export enum ViewMode {
  ONBOARDED = 'onboarded',
  DISCOVERED = 'discovered',
}

export const TABS_DETAILS = {
  TAB_MARGIN: 'mat-tab-label-ml-16',
};

export const BreadcrumbsConfig: {
  [page in ViewMode]: Array<BreadcrumbConfig>;
} = {
  [ViewMode.DISCOVERED]: [
    {
      label: 'Onboarding',
      link: CONSTANTS.V2.ONBOARD.UI_BASE_PATH,
    },
    {
      label: 'Networks',
    },
  ],
  [ViewMode.ONBOARDED]: [
    {
      label: 'Onboarding',
      link: CONSTANTS.V2.ONBOARD.UI_BASE_PATH,
    },
    {
      label: 'Networks',
    },
  ],
};

export const TIME_FILTER = {
  PAST_HOUR: 'Past Hour',
  PAST_DAY: 'Past Day',
  PAST_WEEK: 'Past Week',
  PAST_MONTH: 'Past Month',
};

export const TIME_OPERATORS = [
  {
    displayName: 'Is',
    key: 'is',
    type: 'filterOperator',
    values: [
      {
        displayName: TIME_FILTER.PAST_HOUR,
        key: 'lastHr',
        type: 'filterValue',
      },
      {
        displayName: TIME_FILTER.PAST_DAY,
        key: 'last24Hr',
        type: 'filterValue',
      },
      {
        displayName: TIME_FILTER.PAST_WEEK,
        key: 'lastWeek',
        type: 'filterValue',
      },
      {
        displayName: TIME_FILTER.PAST_MONTH,
        key: 'lastMonth',
        type: 'filterValue',
      },
    ],
  },
  {
    displayName: 'Between',
    key: 'between',
    type: 'filterOperator',
    isHide: true,
    values: [],
  },
];

export const TIME_FILTER_DAYS = {
  [TIME_FILTER.PAST_MONTH]: 30,
  [TIME_FILTER.PAST_WEEK]: 7,
  [TIME_FILTER.PAST_DAY]: 1,
};

export const SEARCH_FILTER_KEYS = {
  UPDATED_TIME: 'updated_time',
  CREATED_TIME: 'created_time',
  STATUS: 'display_status',
};

export const NETWORK_ONBOARD_TABS: Array<TabConfig> = [
  {
    tabName: 'Onboarded',
    code: ViewMode.ONBOARDED,
    routeUrl: CONSTANTS.V2.ONBOARD.NETWORKS.UI_PATH.UI_ONBOARDED_PATH,
    totalRecords: 0,
  },
  {
    tabName: 'Discovered',
    code: ViewMode.DISCOVERED,
    routeUrl: CONSTANTS.V2.ONBOARD.NETWORKS.UI_PATH.UI_DISCOVERED_PATH,
    totalRecords: null,
  },
];

export const NetworkOnboardWorkflow: OrchestratorWorkflow<NetworkOnboardStep> =
  {
    [NetworkOnboardStep.DEFINE]: {
      component: DefinitionComponent,
      next: NetworkOnboardStep.VPC,
    },
    [NetworkOnboardStep.VPC]: {
      component: VpcComponent,
      next: NetworkOnboardStep.POLICY,
    },
    [NetworkOnboardStep.POLICY]: {
      component: PolicyComponent,
      next: null,
    },
  };

export const NetworkOnboardPrivateWorkflow: OrchestratorWorkflow<NetworkOnboardStep> =
  {
    [NetworkOnboardStep.DEFINE]: {
      component: DefinitionComponent,
      next: NetworkOnboardStep.POLICY,
    },
    [NetworkOnboardStep.VPC]: null,
    [NetworkOnboardStep.POLICY]: {
      component: PolicyComponent,
      next: null,
    },
  };

export const NetworkAutoOnboardRuleWorkflow: OrchestratorWorkflow<NetworkAutoOnboardStep> =
  {
    [NetworkAutoOnboardStep.DEFINE]: {
      component: AutoDefinitionComponent,
      next: NetworkAutoOnboardStep.RULE,
    },
    [NetworkAutoOnboardStep.RULE]: {
      component: AutoRuleComponent,
      next: NetworkAutoOnboardStep.POLICY,
    },
    [NetworkAutoOnboardStep.POLICY]: {
      component: PolicyComponent,
      next: null,
    },
  };

export const NetworkAutoOnboardAllWorkflow: OrchestratorWorkflow<NetworkAutoOnboardStep> =
  {
    [NetworkAutoOnboardStep.DEFINE]: {
      component: AutoDefinitionComponent,
      next: NetworkAutoOnboardStep.POLICY,
    },
    [NetworkAutoOnboardStep.RULE]: null,
    [NetworkAutoOnboardStep.POLICY]: {
      component: PolicyComponent,
      next: null,
    },
  };

export enum NetworkStatus {
  NEW = 'NEW',
  SAVED = 'SAVED',
  'IN-PROGRESS' = 'IN-PROGRESS',
  DEPLOYED = 'DEPLOYED',
}

export const OVERVIEW_TITLE = 'Overview: ';

export const NetworkOnboardTabs: Array<TabConfig> = [
  {
    tabName: 'Define Network',
    code: NetworkOnboardStep.DEFINE,
    totalRecords: 0,
  },
  {
    tabName: 'VPC Settings',
    code: NetworkOnboardStep.VPC,
    totalRecords: 0,
  },
  {
    tabName: 'Attach Policy',
    code: NetworkOnboardStep.POLICY,
    totalRecords: 0,
  },
];

export enum autoOnboardSteps {
  DETAILS = 'DETAILS',
  RULE = 'RULE',
  POLICY = 'POLICY',
  SUBNETS = 'SUBNETS',
}

export const RULE_PREFIX_CONDITIONS = {
  NONE: 'none',
  NEITHER: 'neither',
};

export const autoOnboardOverviewTabs: Array<TabConfig> = [
  {
    tabName: 'Details',
    code: autoOnboardSteps.DETAILS,
    totalRecords: 0,
  },
  {
    tabName: 'Onboarding Rules',
    code: autoOnboardSteps.RULE,
    totalRecords: 0,
  },
  {
    tabName: 'Attach Policy',
    code: autoOnboardSteps.POLICY,
    totalRecords: 0,
  },
  {
    tabName: 'Subnets',
    code: autoOnboardSteps.SUBNETS,
    totalRecords: 0,
  },
];

export const NetworkAutoOnboardTabs: Array<TabConfig> = [
  {
    tabName: 'Define Network',
    code: NetworkAutoOnboardStep.DEFINE,
    totalRecords: 0,
  },
  {
    tabName: 'Onboarding Rules',
    code: NetworkAutoOnboardStep.RULE,
    totalRecords: 0,
  },
  {
    tabName: 'Attach Policy',
    code: NetworkAutoOnboardStep.POLICY,
    totalRecords: 0,
  },
];

export interface VpcDetails {
  placement?: string;
  bandwidthProfile?: string;
  connectivityType?: string;
  connectivityNameID?: string;
  subnetSelection?: string;
  serviceName?: string;
  endpointSubnet?: string;
  connectorSubnet?: string;
  vpcID?: string;
  id?: string;
  connectorSubnets?: string;
  subnetRouteMatch?: SubnetRouteMatchArr;
}

export interface defineTabData {
  cloudTypeRegion?: string;
  account?: string;
  namespaceName?: string;
  exportable?: string;
  subnets?: Array<string>;
  hasVirtualSubnets: boolean;
}

export interface detailsTabData {
  geography?: string;
  privateCloud?: string;
  namespaceName?: string;
  exportable?: string;
}

export const connectivityType = {
  TGW: 'transit-gateway',
  VPC_PEERING: 'vpc-peering',
  VNET_PEERING: 'vnet-peering',
  VWAN_HUB: 'vwan-hub',
  PUBLIC: 'public',
};

export const setConnectivityType = {
  TGW: 'TGW',
  VPC_PEERING: 'VPC Peering',
  VNET_PEERING: 'VNet Peering',
  VWAN_HUB: 'VWAN Hub',
  PUBLIC: 'Public',
};

export interface AccessPolicy {
  access: string;
  name: string;
}

export const connectorScalingPermittedCSPs = [
  CLOUD_TYPES.AWS,
  CLOUD_TYPES.AZURE,
];
export const infraConnectorScalingPermittedCSPs = [
  CLOUD_TYPES.AWS,
  CLOUD_TYPES.AZURE,
];

export enum CONNECTOR_TYPE_VALUE {
  INFRA = 'infra',
  APP = 'app',
  NONE = 'none',
}

export const CSP_GWs = {
  [CLOUD_TYPES.AWS]: 'TGW',
  [CLOUD_TYPES.AZURE]: 'VHUB',
};

export const CSP_PC = {
  [CLOUD_TYPES.AWS]: 'VPC',
  [CLOUD_TYPES.AZURE]: 'VNet',
  [CLOUD_TYPES.GCP]: 'VPC',
};

export const CONNECTOR_TYPES = (cloud: string) => [
  {
    displayName: `Infra ${CSP_PC[cloud]}`,
    value: CONNECTOR_TYPE_VALUE.INFRA,
    disabled: false,
  },
  {
    displayName: `Workload ${CSP_PC[cloud]}`,
    value: CONNECTOR_TYPE_VALUE.APP,
    disabled: false,
  },
  {
    displayName: `None`,
    value: CONNECTOR_TYPE_VALUE.NONE,
    disabled: false,
  },
];

export const connectorPlacementField = {
  infra: 'cloudRegion',
  app: 'cloudNetworkID',
};

export const DISCOVERY_VIEW_TYPES = {
  TREE: 'TREE',
  APP: 'APP',
};

export const CSP_WANING_STATES = {
  ACTIVE: 'Active',
  IN_ACTIVE: 'inActive',
};

export const REFRESH_DISCOVERY_BTN = {
  REFRESH: 'Refresh Discovery',
  REFRESHING: 'Refreshing Discovery',
  VIEW: 'View New Discoveries',
  NO_DISCOVERY: 'No New Discoveries',
};

export const REFRESH_DISCOVERY_STATUS = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILURE',
  PROGRESS: 'IN-PROGRESS',
  SCHEDULE: 'SCHEDULE',
};

export const DEFAULT_TEXT_UPDATE_TIME = 10000;

export const APP_VIEW_MODES = {
  CARD: 'CARD_VIEW',
  TABULAR: 'TABULAR_VIEW',
  NETWORK: 'NETWORK',
};

export const TREE_NODE_TYPES = {
  CSP: 'csp',
  REGION: 'region',
  VPC: 'vpc',
  SUBNET: 'subnet',
  ENDPOINT: 'endpoint',
};

const CANCEL_WARNING = {
  name: DIALOG_BUTTON_TYPES.CANCEL,
  state: 'inActive',
};

export const CSP_CHANGE_WARNING_BUTTON = [
  CANCEL_WARNING,
  {
    name: DIALOG_BUTTON_TYPES.YES,
    state: 'Active',
    type: TREE_NODE_TYPES.CSP,
  },
];

export const REGION_CHANGE_WARNING_BUTTON = [
  CANCEL_WARNING,
  {
    name: DIALOG_BUTTON_TYPES.YES,
    state: 'Active',
    type: TREE_NODE_TYPES.REGION,
  },
];

export const REFRESH_WARNING_BUTTON = [
  CANCEL_WARNING,
  {
    name: DIALOG_BUTTON_TYPES.YES,
    state: 'Active',
    type: 'REFRESH',
  },
];

export const CSP_TYPES = {
  AWS: 'AWS',
  GCP: 'GCP',
  AZURE: 'AZURE',
  PRIVATE: 'PRIVATE',
};
export const CSP_ICONS_BY_TYPE = {
  [CSP_TYPES.AWS]: DISCOVERED_NETWORK_FILTERED_VIEW_ICONS.AWS_CSP,
  [CSP_TYPES.GCP]: DISCOVERED_NETWORK_FILTERED_VIEW_ICONS.GCP_CSP,
  [CSP_TYPES.AZURE]: DISCOVERED_NETWORK_FILTERED_VIEW_ICONS.AZURE_CSP,
};

export const TREE_NODE_CHILD_KEYS = {
  VPCS: 'vpcs',
  SUBNETS: 'subnets',
  ENDPOINT: 'endpoint',
  REGIONS: 'regions',
  CSP: 'csp',
};

export const CONNECTIIVITY_TYPE = {
  AWS: [
    {
      displayName: 'VPC Peering',
      value: CONNECTIVITY_OPTIONS_KEYS.VPC_PEERING,
      isDisabled: false,
    },
    {
      displayName: 'TGW',
      value: CONNECTIVITY_OPTIONS_KEYS.TRANSIT_GATEWAY,
      isDisabled: false,
    },
    {
      displayName: 'Public Connectivity',
      value: CONNECTIVITY_OPTIONS_KEYS.PUBLIC,
      isDisabled: false,
    },
  ],
  AZURE: [
    {
      displayName: 'VNet Peering',
      value: CONNECTIVITY_OPTIONS_KEYS.VNET_PEERING,
      isDisabled: false,
    },
    {
      displayName: 'VWAN Hub',
      value: CONNECTIVITY_OPTIONS_KEYS.VWAN_HUB,
      isDisabled: false,
    },
    {
      displayName: 'Public Connectivity',
      value: CONNECTIVITY_OPTIONS_KEYS.PUBLIC,
      isDisabled: false,
    },
  ],
  GCP: [
    {
      displayName: 'VPC Peering',
      value: CONNECTIVITY_OPTIONS_KEYS.VPC_PEERING,
      isDisabled: false,
    },
    {
      displayName: 'Public Connectivity',
      value: CONNECTIVITY_OPTIONS_KEYS.PUBLIC,
      isDisabled: false,
    },
  ],
};

export enum POLICY_TYPE {
  CUSTOMIZE = 'customize',
  ALLOW_ALL_NETWORKS = 'ALLOW-ALL-NETWORKS',
  DENY_ALL_NETWROKS = 'DENY-ALL-NETWORKS',
  ALLOW_ALL_USERS = 'ALLOW-ALL-USERS',
  DENY_ALL_USERS = 'DENY-ALL-USERS',
}

export const OFFBOARD_CONFIRMATION_TOOLTIP = `Selecting this will force decommission of this network when underlying resources haven't been cleaned up.`;

export enum DeleteAction {
  DELETE = 'delete',
  OFFBOARD = 'offboard',
}

export const NetworkDeleteConfig: { [type in NetworkStatus]: any } = {
  [NetworkStatus.NEW]: null,
  [NetworkStatus.SAVED]: {
    action: DeleteAction.DELETE,
    btn: 'Delete Network',
    panelMessage: 'Are you sure you want to delete this network?',
    panelBtnName: 'Delete',
    confirmation: null,
  },
  [NetworkStatus['IN-PROGRESS']]: null,
  [NetworkStatus.DEPLOYED]: {
    action: DeleteAction.OFFBOARD,
    btn: 'Offboard Network',
    panelMessage: 'Are you sure you want to Offboard this network?',
    panelBtnName: 'Offboard',
    confirmation: {
      confirmationLabel: 'Force Decommission',
      confirmationTooltip: OFFBOARD_CONFIRMATION_TOOLTIP,
      isConfirmationReqd: false,
    },
  },
};

export const NETWORK_STATUS = {
  DEPLOYING: 'DEPLOYING',
  DECOMMISSIONING: 'DECOMMISSIONING',
  CONFIGURING: 'CONFIGURING',
  CONFIGURED: 'CONFIGURED',
  FAILED: 'FAILED',
  OFFBOARDING: 'OFFBOARDING',
  ONBOARDING: 'ONBOARDING',
};

export enum SERVICE_INSERTION_ENDPOINT_TYPES {
  AUTO = 'auto',
  MANUAL = 'manual',
  NONE = 'none',
}

export const SERVICE_INSERTION_ENDPOINT_TYPES_LIST = {
  [SERVICE_INSERTION_ENDPOINT_TYPES.AUTO]: 'Auto',
  [SERVICE_INSERTION_ENDPOINT_TYPES.MANUAL]: 'Manual',
  [SERVICE_INSERTION_ENDPOINT_TYPES.NONE]: 'None',
};

export const SERVICE_INSERTION_ENDPOINT_SUBNET_TYPES = {
  CONNECTOR: 'Connector',
  SERVICE_ENDPOINT: 'ServiceEndpoint',
};

export const virtualCloudPluralMapping = {
  vpc: {
    '=1': 'VPC',
    other: 'VPCs',
  },
  vnet: {
    '=1': 'VNet',
    other: 'VNets',
  },
};

export const DISCOVERY_CHANGE_TYPES = {
  NAVIGATION: 'NAVIGATION',
  TAB: 'TAB',
};
export const CONFIRM_CHANGE = 'do you want to proceed?';
export const CSP_CHANGE_WARNING_MESSAGE = `Switching CSP will remove all existing configuration, <br/> <br/> ${CONFIRM_CHANGE}`;
export const REGION_CHANGE_WARNING_MESSAGE = `Switching regions will remove all existing configuration, <br/> <br/> ${CONFIRM_CHANGE}`;
export const REFRESH_DISCOVERY_WARNING_MESSSAGE = `Refreshing will remove all existing configuration, <br/> <br/> ${CONFIRM_CHANGE}`;

export type NetworkErrorFields =
  | 'name'
  | 'exportable'
  | 'namespaceID'
  | 'publicCloud.cloud'
  | 'publicCloud.cloudType'
  | 'publicCloud.connectionOption'
  | 'publicCloud.cloudKeyID'
  | 'publicCloud.cloudRegion'
  | 'publicCloud.cloudNetworks.cloudNetworkID'
  | 'publicCloud.cloudNetworks.subnet'
  | 'publicCloud.cloudNetworks.virtualSubnet'
  | 'publicCloud.cloudNetworks.hubID'
  | 'publicCloud.cloudNetworks.connectivityType'
  | 'publicCloud.cloudNetworks.connectorPlacement'
  | 'publicCloud.cloudNetworks.connectorSettings.subnets'
  | 'publicCloud.cloudNetworks.connectorSettings.bandwidthRange.min'
  | 'publicCloud.cloudNetworks.connectorSettings.bandwidthRange.max'
  | 'publicCloud.cloudNetworks.serviceSubnets.svcID'
  | 'publicCloud.cloudNetworks.serviceSubnets.svcName'
  | 'publicCloud.cloudNetworks.serviceSubnets.mode'
  | 'publicCloud.cloudNetworks.serviceSubnets.subnets.type'
  | 'publicCloud.cloudNetworks.serviceSubnets.subnets.ipAddrCidrs'
  | 'privateCloud.cloud'
  | 'privateCloud.cloudRegion'
  | 'privateCloud.cloudType'
  | 'privateCloud.connectionOption'
  | 'privateCloud.connectorGroupID'
  | 'privateCloud.privateCloudID'
  | 'privateCloud.subnets'
  | 'privateCloud.ruleBasedOnboarding'
  | 'privateCloud.autoOnboardRules.rules.action'
  | 'privateCloud.autoOnboardRules.rules.prefixes.matchCondition'
  | 'privateCloud.autoOnboardRules.rules.prefixes.groups.id'
  | 'privateCloud.autoOnboardRules.rules.prefixes.values.prefix'
  | 'privateCloud.autoOnboardRules.rules.prefixes.values.min'
  | 'privateCloud.autoOnboardRules.rules.prefixes.values.max'
  | 'privateCloud.autoOnboardRules.rules.bgpCommunities.matchCondition'
  | 'privateCloud.autoOnboardRules.rules.bgpCommunities.communityGroups.id'
  | 'privateCloud.autoOnboardRules.rules.bgpCommunities.communities'
  | 'privateCloud.autoOnboardRules.rules.bgpNeighbours.matchCondition'
  | 'privateCloud.autoOnboardRules.rules.bgpNeighbours.values'
  | 'security.policies.id';
