import { Query } from '@datorama/akita';
import { AppState, AppStore, appStore } from './app.store';

export class AppQuery extends Query<AppState> {
  constructor(protected store: AppStore) {
    super(store);
  }
}

export const appQuery = new AppQuery(appStore);
