<app-pill-list
  [pillList]="{
    targetKey: '',
    list: data?.riskProfiles || data?.list
  }"
  [displayCount]="1"
  [selectPill]="false"
  [isCardFormat]="true"
  [fillAvailableSpace]="true"
></app-pill-list>
