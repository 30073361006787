import { USER_TYPES } from '@app/common/util/constants';
import { LICENSE_STATUS } from '@app/login/login.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isTrialBannerAccountPipe',
})
export class IsTrialBannerAccountPipe implements PipeTransform {
  transform(type: string, licenseStatus: string): boolean {
    const licenseStatusTxt = licenseStatus?.toLowerCase();
    return (
      licenseStatusTxt !== LICENSE_STATUS.VALID &&
      (type === USER_TYPES.SANDBOX || type === USER_TYPES.TEAM_USER)
    );
  }
}
