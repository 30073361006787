import { NotificationService } from './notification.service';
import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent implements OnInit, OnDestroy {
  subscriptions = [];
  config$ = new BehaviorSubject<any>(null);
  loaderConfig$ = new BehaviorSubject<boolean>(null);
  constructor(private notificationService: NotificationService) {
    const msgSub = this.notificationService.getMessageConfig().subscribe(
      (config) => {
        this.config$.next(config);
      },
      (error) => {
        console.warn('Notification message configuration error');
      }
    );
    const loaderSub = this.notificationService.getLoaderStatus().subscribe(
      (showLoader) => {
        this.loaderConfig$.next(showLoader);
      },
      (error) => {
        console.warn('Loader status configuration error');
      }
    );
    this.subscriptions.push(msgSub, loaderSub);
  }

  ngOnInit() {}

  onClickAction(actionType: any) {
    if (actionType.isBanner) {
      // for trial to normal account change confirmation
      this.notificationService.setSelectedBannerAction(actionType);
    } else {
      this.notificationService.setSelectedAction(actionType);
    }
  }

  ngOnDestroy() {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }
}
