import {
  IMAGE_PATHS,
  SUBSCRIPTION_TYPES,
  URL_PATHS,
} from '@app/common/util/constants';
import { ActionConfig, MenuConfig } from './header.interface';

export const MENU_CONFIGS = [
  {
    name: 'Home',
    path: 'home',
    isSelected: true,
    fullPath: URL_PATHS.HOME,
  } as MenuConfig,
  {
    name: 'Insights',
    path: 'insights',
    isSelected: false,
    fullPath: URL_PATHS.INSIGHTS,
  } as MenuConfig,
  {
    name: 'Onboard',
    path: 'onboard',
    isSelected: false,
    fullPath: URL_PATHS.ONBOARD,
  } as MenuConfig,
  {
    name: 'Policy',
    path: 'policy',
    isSelected: false,
    fullPath: URL_PATHS.POLICY,
  } as MenuConfig,
  {
    name: 'Diagnose',
    path: 'diagnose',
    isSelected: true,
    fullPath: URL_PATHS.DIAGNOSE,
  } as MenuConfig,
  {
    name: 'Management',
    path: 'management',
    isSelected: false,
    fullPath: URL_PATHS.MANAGEMENT,
  } as MenuConfig,
  {
    name: 'Eng Labs',
    path: 'eng-labs',
    isSelected: false,
    fullPath: URL_PATHS.ENG_LABS,
  } as MenuConfig,
];

export const ACTION_CONFIGS = {
  search: {
    name: 'Search',
    imgUrl: IMAGE_PATHS.NAVIGATION.SEARCH,
    tooltip: '',
  } as ActionConfig,
  tasks: {
    name: 'Tasks',
    isDirectionAction: true,
    imgUrl: IMAGE_PATHS.NAVIGATION.TASK,
    tooltip: 'View system and user tasks, status, and details',
    fullPath: URL_PATHS.TASKS,
  } as ActionConfig,
  alerts: {
    name: 'Alerts',
    isDirectionAction: true,
    imgUrl: IMAGE_PATHS.NAVIGATION.ALERTS_NEW,
    tooltip: 'View critical alerts, warnings and notifications',
    fullPath: URL_PATHS.ALERT,
  } as ActionConfig,
  help: {
    name: 'Help',
    isDirectionAction: false,
    imgUrl: IMAGE_PATHS.NAVIGATION.HELP,
    tooltip: 'Help',
  } as ActionConfig,
  user: {
    name: 'User',
    isDirectionAction: false,
    imgUrl: IMAGE_PATHS.NAVIGATION.USER,
    tooltip: 'Admin Info',
  } as ActionConfig,
};

export const MESSAGES = {
  NO_ARTICLE_AVAILABLE:
    'No article(s) available matching your search term. Please try again.',
  ARTICLE_ACCESS_ERROR: 'Unable to access help content. Please try again',
};

export const HEADER_ICONS_CONFIG = {
  PRINT: {
    selected_image: IMAGE_PATHS.COMMON.SELECTED_PDF,
    unselected_image: IMAGE_PATHS.COMMON.PDF,
    displayName: 'PDF',
    name: 'PRINT',
    placeholder: 'PDF print view',
  },
  TASKS: {
    selected_image: IMAGE_PATHS.NAVIGATION.SELECTED_TASKS,
    unselected_image: IMAGE_PATHS.NAVIGATION.TASKS,
    image: IMAGE_PATHS.NAVIGATION.TASK,
    url: URL_PATHS.DASHBOARD_TASKS,
    mspURL: URL_PATHS.MSP.TASKS,
    displayName: 'Tasks',
    name: 'TASKS',
    placeholder: 'View system and user tasks, status, and details ',
  },
  ALERTS: {
    selected_image: IMAGE_PATHS.NAVIGATION.SELECTED_ALERTS,
    unselected_image: IMAGE_PATHS.NAVIGATION.ALERTS,
    image: IMAGE_PATHS.NAVIGATION.NOTIFICATION,
    url: URL_PATHS.DASHBOARD_ALERTS,
    mspURL: URL_PATHS.DASHBOARD_ALERTS,
    badgeText: '',
    displayName: 'Alerts',
    name: 'ALERTS',
    placeholder: 'View critical alerts, warnings and notifications',
  },
  HELP: {
    selected_image: IMAGE_PATHS.NAVIGATION.HELP_SELECTED,
    unselected_image: IMAGE_PATHS.NAVIGATION.HELP_UNSELECTED,
    image: IMAGE_PATHS.NAVIGATION.HELP,
    url: URL_PATHS.DASHBOARD_RELEASE_NOTES,
    mspURL: URL_PATHS.MSP.RELEASE_NOTES,
    displayName: 'Help',
    name: 'HELP',
    placeholder: 'Help',
  },
  USER_PROFILE: {
    selected_image: IMAGE_PATHS.NAVIGATION.SELECTED_USER_PROFILE,
    unselected_image: IMAGE_PATHS.NAVIGATION.USER_PROFILE,
    image: IMAGE_PATHS.NAVIGATION.USER,
    url: URL_PATHS.DASHBOARD_USER_PROFILE,
    mspURL: URL_PATHS.MSP.USER_PROFILE,
    displayName: 'Admin',
    name: 'USER_PROFILE',
  },
  TOURS: {
    selected_image: IMAGE_PATHS.NAVIGATION.SELECTED_PRODUCT_TOUR,
    unselected_image: IMAGE_PATHS.NAVIGATION.DESELECTED_PRODUCT_TOUR,
    url: '',
    mspUrl: '',
    displayName: 'Tours',
    name: 'TOURS',
    placeholder: 'View product tours',
  },
};

export const PRINTABLE_PAGES = new Set(['Discovery', 'Insights']);

export const LOCAL_STORE_DATA_REQUIRED_PAGES = new Set(['Insights']);

export const LOCAL_STORE_KEYS = {
  Insights: 'insights',
};

export const TOOLTIP_POSITION = {
  BEFORE: 'before',
};

export const PRODUCT_VIEW_TYPES = [
  {
    value: SUBSCRIPTION_TYPES.ZTNA,
    displayName: 'ProAccess',
    isDisabled: false,
    tooltip:
      'Zero Trust Network Access (ZTNA) for users to securely access applications and networks across different cloud providers',
    tooltipPosition: TOOLTIP_POSITION.BEFORE,
  },
  {
    value: SUBSCRIPTION_TYPES.MCN,
    displayName: 'ProTransit',
    isDisabled: false,
    tooltip:
      'Multi-Cloud Networking (MCN) to connect, secure and manage networks and applications across various cloud providers',
    tooltipPosition: TOOLTIP_POSITION.BEFORE,
  },
];

export const CONTACT_SALES = 'Contact Prosimo Sales to enable access';
