<app-confetti></app-confetti>
<app-interactive-guide></app-interactive-guide>
<div
  class="container-fluid justify-content-start expand-container"
  id="app__container"
  [ngClass]="[uiThemeMode | uiTheme]"
>
  <div class="w-100 expand-container">
    <div *ngIf="isShowHeader" class="w-100 position- header">
      <app-header></app-header>
    </div>

    <div *ngIf="!isHideBanner" class="app-banner print-hidden">
      <app-banner></app-banner>
    </div>
    <div *ngIf="isDashboard">
      <app-l2header></app-l2header>
    </div>
    <div
      class="app-content expand-container"
      [ngClass]="{
        banner: bannerConfig?.length && !isHideBanner,
        'banner-md': isDashboard && bannerConfig?.length,
        'banner-lg-main': isDashboard && isBannerLarge && bannerConfig?.length,
        'banner-lg': !isDashboard && isBannerLarge && bannerConfig?.length
      }"
    >
      <app-help [isShowContactSupportBtn]="isShowContactSupportBtn"></app-help>
      <router-outlet></router-outlet>
    </div>
    <app-notification></app-notification>
  </div>
</div>
