import { SwitchModule } from './components/switch/switch.module';
import { AccordionHeaderComponent } from './components/accordion-header/accordion-header.component';
import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { AppMaterialModule } from '@app/app-material.module';
import { Routes, RouterModule } from '@angular/router';
import { FormComponent } from './components/form/form.component';
import { StatusComponent } from '@app/common/components/status/status.component';
import { MaskPipe } from '@app/common/pipe/mask/mask.pipe';
import { TruncatePipe } from '@app/common/pipe/truncate/truncate.pipe';
import { FilterComponent } from '@app/common/components/filter/filter.component';
import { FilterActionFormWrapperComponent } from '@app/common/components/filter/filter-action-form-wrapper.component';
import { PillComponent } from './components/pill/pill.component';
import { PillListComponent } from './components/pill-list/pill-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SingleSelectBoxComponent } from './components/single-select-box/single-select-box.component';
import { SyncFromIdpComponent } from './components/sync-from-idp/sync-from-idp.component';
import { SearchComponent } from './components/search/search.component';
import { SelectPillComponent } from './components/select-pill/select-pill.component';
import { PanelFooterComponent } from './components/panel-footer/panel-footer.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { AutoCompleteComponent } from './components/auto-complete/auto-complete.component';
import { DashboardMenuComponent } from './components/dashboard-menu/dashboard-menu.component';
import { DashboardSubMenuComponent } from './components/dashboard-sub-menu/dashboard-sub-menu.component';
import { LegendModule } from '@app/component-common/legend/legend.module';
import { SwitchTextComponent } from './components/switch-text/switch-text.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import {
  SafePipeModule,
  ShortNumberPipeModule,
  FunctionCallerPipeModule,
  UIThemePipeModule,
} from '@prosimoio/pipes';
import { BlockCopyPasteDirective } from '@app/common/directives/block-copy-paste.directive';
import { PositiveNumberDirective } from '@app/common/directives/positive-number.directive';
import { WafRuleCategoryComponent } from './components/waf-rule-category/waf-rule-category.component';
import { KeyToDisplayNamePipe } from './pipe/key-to-display-name/key-to-display-name.pipe';
import { NumberValidatorPipe } from './pipe/number-validator/number-validator.pipe';
import { SegmentHealthPipe } from './pipe/segment-health/segment-list.pipe';
import { IsAgentPipe } from './pipe/is-agent/is-agent.pipe';
import { IsViewTypeProTransitPipe } from './pipe/is-view-type-pro-transit/is-view-type-pro-transit.pipe';
import { IsInteractionTypeAppToAppPipe } from './pipe/is-interactionType-appToApp/is-interactionType-appToApp.pipe';
import { ImageUploadPreviewComponent } from './components/image-upload-preview/image-upload-preview.component';
import { GroupSelectPillComponent } from './components/group-select-pill/group-select-pill.component';
import { DropZoneModule } from './directives/drop-zone/drop-zone.module';
import {
  InfoTooltipModule,
  TooltipLiteModule,
  ButtonDropdownModule,
  LocationModule,
  LocationService,
  PillContainerModule,
  MultiValueInputModule,
  MinimizedListModule,
  RightPanelModule,
  PageHeaderModule,
  PageFooterModule,
  ConfigTableViewModule,
  SearchModule,
  ToastModule,
  // ToastService,
  UploadFileModule,
  AutoCompleteModule,
  NoDataAvailableModule,
  ConditionsPanelModule,
  ToolTipModule,
  RuleListPaneModule,
  OverviewContainerModule,
  TabViewModule,
  MatErrorInfoTooltipModule,
  MatSelectSearchModule,
  CreateButtonModule,
  SearchFilterPillModule,
  LoaderModule,
  MultiLevelSelectComponent,
  PageHeaderExtraButtonsModule,
} from '@prosimoio/components';
import {
  CloseablePanelModule,
  ResizableModule,
  TableColumnResizerModule,
} from '@prosimoio/directives';
import { IsTrialBannerAccountPipe } from './pipe/is-trial-banner-account-pipe/is-trial-banner-account.pipe';
import { PrintFilterHeaderComponent } from './components/print-filter-header/print-filter-header.component';
import { InsightPrintFilterValuePipe } from './pipe/insight-print-filter-value.pipe';
import { TimeFilterComponent } from './components/time-filter/time-filter.component';
import { CommonComponentsModule } from '@app/component-common/common-components.module';
import { GeneralTableComponent } from './components/general-table/general-table.component';
import { SearchFilterComponent } from '@app/dashboard/shared/search-filter/search-filter.component';
import { CapsuleRendererComponent } from './components/general-table/cellRenderers/capsule-renderer/capsule-renderer.component';
import { SimpleTabsComponent } from './components/simple-tabs/simple-tabs.component';
import { LightboxFormTemplateComponent } from '@app/dashboard/diagnose/diagnostics/lightbox-form-template/lightbox-form-template.component';
import { CONSTANTS } from 'environments/environment';
import { TransalateNumberPipe } from './pipe/transalate-number/transalate-number.pipe';
import { CSPIconPipe } from './pipe/csp-icon/network-cards-csp-icon.pipe';
import { PolicyTypesPipe } from './pipe/policy-types/policy-types.pipe';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ProgressBarHorizontalColor } from './components/progress-bar/progress-bar-horizontal-color.directive';
import { AutofocusDirective } from './components/autofocus/autofocus.directive';
import { DataSizePipe } from './pipe/data-size/data-size.pipe';
import { PlaygroundFilterComponent } from './components/filter/playground-filter/playground-filter.component';
import { GettingStartedComponent } from './components/getting-started/getting-started.component';
import { DataFetchFormComponent } from './components/data-fetch-form/data-fetch-form.component';
import { RulePaneTabularViewComponent } from './components/rule-pane-tabular-view/rule-pane-tabular-view.component';
import { MatBadgeModule } from '@angular/material/badge';
import { ReservedIpRangesComponent } from './components/reserved-ip-ranges/reserved-ip-ranges.component';
import { BadgeComponent } from './components/badge/badge.component';
import { DashboardSubMenuPipe } from './components/dashboard-sub-menu/dashboard-sub-menu.pipe';
import { IsN2nPipe } from './pipe/is-n2n/is-n2n.pipe';
import { WellKnownBgpPipe } from './pipe/well-known-bgp/well-known-bgp.pipe';
import { StatusPillModule } from './components/status-pill/switch-pill.module';
const routes: Routes = [];

export const commonComponents = [
  FormComponent,
  StatusComponent,
  FilterComponent,
  FilterActionFormWrapperComponent,
  PillComponent,
  PillListComponent,
  SingleSelectBoxComponent,
  FilterComponent,
  SwitchTextComponent,
  SearchComponent,
  SelectPillComponent,
  PanelFooterComponent,
  AccordionHeaderComponent,
  PaginatorComponent,
  AutoCompleteComponent,
  DashboardMenuComponent,
  DashboardSubMenuComponent,
  UserProfileComponent,
  WafRuleCategoryComponent,
  BlockCopyPasteDirective,
  PositiveNumberDirective,
  SyncFromIdpComponent,
  ImageUploadPreviewComponent,
  GroupSelectPillComponent,
  PrintFilterHeaderComponent,
  TimeFilterComponent,
  SearchFilterComponent,
  GeneralTableComponent,
  CapsuleRendererComponent,
  SimpleTabsComponent,
  LightboxFormTemplateComponent,
  ProgressBarComponent,
  ProgressBarHorizontalColor,
  AutofocusDirective,
  PlaygroundFilterComponent,
  GettingStartedComponent,
  DataFetchFormComponent,
  ReservedIpRangesComponent,
  BadgeComponent,
  RulePaneTabularViewComponent,
];

export const commonPipes = [
  MaskPipe,
  TruncatePipe,
  KeyToDisplayNamePipe,
  NumberValidatorPipe,
  IsTrialBannerAccountPipe,
  InsightPrintFilterValuePipe,
  IsAgentPipe,
  IsInteractionTypeAppToAppPipe,
  SegmentHealthPipe,
  TransalateNumberPipe,
  CSPIconPipe,
  PolicyTypesPipe,
  IsViewTypeProTransitPipe,
  DataSizePipe,
  DashboardSubMenuPipe,
  IsN2nPipe,
  WellKnownBgpPipe,
];

@NgModule({
  declarations: [...commonComponents, ...commonPipes],
  imports: [
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    CommonModule,
    AppMaterialModule,
    LegendModule,
    SwitchModule,
    StatusPillModule,
    SafePipeModule,
    ShortNumberPipeModule,
    FunctionCallerPipeModule,
    ResizableModule,
    TableColumnResizerModule,
    DropZoneModule,
    TooltipLiteModule,
    InfoTooltipModule,
    ToolTipModule,
    ButtonDropdownModule,
    CommonComponentsModule,
    LocationModule,
    PillContainerModule,
    RuleListPaneModule,
    OverviewContainerModule,
    MultiValueInputModule,
    NoDataAvailableModule,
    MinimizedListModule,
    RightPanelModule,
    SearchModule,
    PageHeaderModule,
    PageFooterModule,
    ConfigTableViewModule,
    ToastModule,
    UploadFileModule,
    AutoCompleteModule,
    ConditionsPanelModule,
    FormsModule,
    MatBadgeModule,
    CloseablePanelModule,
    MatErrorInfoTooltipModule,
    MatSelectSearchModule,
    CreateButtonModule,
    SearchFilterPillModule,
    LoaderModule,
    MultiLevelSelectComponent,
    PageHeaderExtraButtonsModule,
    UIThemePipeModule,
    // EdgeDrawerModule,
  ],
  exports: [
    ...commonComponents,
    ...commonPipes,
    SwitchModule,
    SafePipeModule,
    ShortNumberPipeModule,
    FunctionCallerPipeModule,
    ResizableModule,
    TableColumnResizerModule,
    DropZoneModule,
    TooltipLiteModule,
    LocationModule,
    InfoTooltipModule,
    ToolTipModule,
    ButtonDropdownModule,
    PillContainerModule,
    RuleListPaneModule,
    OverviewContainerModule,
    MultiValueInputModule,
    NoDataAvailableModule,
    MinimizedListModule,
    RightPanelModule,
    SearchModule,
    PageHeaderModule,
    PageFooterModule,
    ConfigTableViewModule,
    ToastModule,
    UploadFileModule,
    AutoCompleteModule,
    ConditionsPanelModule,
    RuleListPaneModule,
    TabViewModule,
  ],
  providers: [
    ...commonPipes,
    DecimalPipe,
    { useValue: CONSTANTS.DASHBOARD_HOST, provide: 'host' },
    LocationService,
  ],
})
export class DashboardCommonModule {}
