import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-pill-list',
  templateUrl: './pill-list.component.html',
  styleUrls: ['./pill-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PillListComponent implements OnInit {
  @Input() title: string;
  @Input() emptyText = '';
  @Input() isCardFormat = false;
  @Input() boldText = false;
  @Input() selectPill = false;
  @Input() closeable = false;
  @Input() closeMorePills = false;
  @Input() closePositionAfter = false;
  @Input() pill = false;
  @Input() hidePillBorder = false;
  @Input() borderColor;
  @Input() textColor;
  @Input() backgroundColor;
  @Input() fontSize = '12px';
  @Input() pillWidth = null;
  @Input() textIndent: boolean = false;
  @Output() close = new EventEmitter();
  @Input() fillAvailableSpace: boolean = false;

  @Input() set displayCount(count: string | number) {
    if (typeof count === 'string') {
      this._count = Math.floor(parseFloat(count));
    } else {
      this._count = count;
    }
  }

  @Input() set pillList(data: any) {
    // convert the data into the string[]
    if (data && data.list) {
      if (data.targetKey === 'key') {
        // to convert the properties of object to string[]
        this._pillList = Object.keys(data.list);
      } else if (data.targetKey) {
        // extract the property's value from an array of object and turn into string[]
        this._pillList = data.list.map((item) => item[data.targetKey]);
      } else {
        this._pillList = data.list;
      }
    } else {
      this._pillList = [];
    }
  }

  _pillList: Array<string>;
  _count: number;
  constructor() {}

  ngOnInit() {}

  onClose(event) {
    this.close.emit(event);
  }

  toggleMoreInfo(event) {
    event.stopPropagation();
  }
}
