import {
  CloudNetwork,
  ConnectorSettings,
  Namespace,
  Network,
  NetworkPublicCloud,
  PrefixInfo,
  SubnetInfo,
} from '@app/common/models';
import { ICloudController } from './cloud-controller';
import {
  NetworkDefinition,
  PCWithSubnets,
} from '../../components/drawer/definition/definition.model';
import { NetworkPolicy } from '../../components/drawer/policy/policy.model';
import { NetworkVPC, VPCSettings } from '../../components/drawer/vpc/vpc.model';
import { ArrayUtil } from '@prosimoio/services';
import {
  CONNECTOR_TYPE_VALUE,
  NetworkOnboardWorkflow,
  SERVICE_INSERTION_ENDPOINT_SUBNET_TYPES,
  connectorScalingPermittedCSPs,
  infraConnectorScalingPermittedCSPs,
} from '../../../network.constants';
import {
  CLOUD_TYPES,
  CONNECTIVITY_OPTIONS_KEYS,
} from '@app/common/util/constants';
import { ModelFormGroup } from '@app/common/util/form-util';
import { OrchestratorService } from '@app/common/components/drawer/orchestrator/orchestrator.service';
import { NetworkUtil } from '../../../network.util';
import { NetworkAutoDefinition } from '../../components/drawer/auto-definition/auto-definition.model';
import {
  NetworkRule,
  Rule,
} from '../../components/drawer/auto-rule/auto-rule.model';
import { ChangeSetList } from '../../../network.model';
import {
  OrchestratorStep,
  OrchestratorWorkflow,
} from '@app/common/components/drawer/orchestrator/orchestrator.model';

export class PublicCloudController implements ICloudController {
  constructor(private orchestratorService: OrchestratorService) {}

  getCloudType(network: Network): string {
    return network.publicCloud?.cloudType || '';
  }

  getCSP(network: Network) {
    return network.publicCloud?.cloud || '';
  }

  getCloudKeyID(network: Network): string {
    return network.publicCloud?.cloudKeyID || '';
  }

  getCloudRegion(network: Network) {
    return network.publicCloud?.cloudRegion || '';
  }

  getCloudNetworks(network: Network): Array<PCWithSubnets> {
    return (
      network.publicCloud?.cloudNetworks.map((cloudNetwork) => {
        return {
          cloudNetworkID: cloudNetwork.cloudNetworkID,
          subnets: cloudNetwork.subnets.map((s) => ({
            subnet: s.subnet,
            virtualSubnet: s.virtualSubnet,
          })),
        } as PCWithSubnets;
      }) || []
    );
  }

  getSubnets: (network: NetworkDefinition) => string[];

  getCloudNetworksForm(network: NetworkDefinition): Array<PCWithSubnets> {
    return (
      network.publicCloud.cloudNetworks?.map((cloudNetwork) => {
        return {
          cloudNetworkID: cloudNetwork.cloudNetworkID,
          subnets: cloudNetwork.subnets,
        } as PCWithSubnets;
      }) || []
    );
  }

  validateDefinition(network: NetworkDefinition): boolean {
    return (
      network.cloudKeyID.length > 0 &&
      network.cloudRegion.length > 0 &&
      network.cloudType.length > 0 &&
      network.namespace.length > 0 &&
      network.publicCloud.cloudNetworks?.length > 0 &&
      (network.isUnmanaged ||
        network.publicCloud.cloudNetworks?.every(
          (pc) => pc.cloudNetworkID && pc.subnets?.length > 0
        ))
    );
  }

  validateAutoDefinition(networkForm: NetworkAutoDefinition): boolean {
    return true;
  }

  detectDefinationFormChanges(
    network: Network,
    networkForm: NetworkDefinition
  ) {
    const allSubnets =
      network?.publicCloud?.cloudNetworks?.reduce((p, pc) => {
        return p.concat(
          pc.subnets.map((s) => ({
            subnet: s.subnet,
            virtualSubnet: s.virtualSubnet,
          }))
        );
      }, [] as Array<SubnetInfo>) || [];
    const allSubnetsForm = networkForm.publicCloud.cloudNetworks.reduce(
      (p, pc) => {
        return p.concat(pc.subnets);
      },
      [] as Array<SubnetInfo>
    );
    return (
      networkForm.name !== network.name ||
      networkForm.cloudType !== network.publicCloud?.cloudType ||
      networkForm.cloudKeyID !== network.publicCloud?.cloudKeyID ||
      networkForm.cloudRegion !== network.publicCloud?.cloudRegion ||
      networkForm.csp !== network.publicCloud?.cloud ||
      networkForm.exportable !== network.exportable ||
      networkForm.namespace !== network.namespaceID ||
      networkForm.publicCloud.cloudNetworks?.length !==
        network.publicCloud?.cloudNetworks?.length ||
      ArrayUtil.findCommonValues(
        network.publicCloud.cloudNetworks.map((pc) => pc.cloudNetworkID),
        networkForm.publicCloud.cloudNetworks.map((pc) => pc.cloudNetworkID)
      ).length !== network.publicCloud.cloudNetworks.length ||
      allSubnets.length !== allSubnetsForm.length ||
      ArrayUtil.findCommonValues(
        allSubnets.map((s) => s.subnet),
        allSubnetsForm.map((s) => s.subnet)
      ).length !== allSubnets.length ||
      ArrayUtil.findCommonValues(
        allSubnets.map((s) => s.virtualSubnet),
        allSubnetsForm.map((s) => s.virtualSubnet)
      ).length !== allSubnets.length
    );
  }

  detectAutoDefinitionFormChanges: (
    network: Network,
    networkForm: NetworkAutoDefinition
  ) => boolean;

  updateDefinition(
    network: Network,
    networkForm: NetworkDefinition,
    namespaces: Array<Namespace>
  ) {
    const namespaceName = namespaces?.find(
      (namespace) => namespace?.id === networkForm.namespace
    );
    network.name = networkForm.name;
    if (!network.publicCloud) {
      network.publicCloud = {} as NetworkPublicCloud;
      delete network.privateCloud;
      delete network.ownTransitCloud;
    }
    network.namespaceID = networkForm.namespace;
    network.namespaceName = namespaceName.name;
    network.publicCloud.cloud = networkForm.csp;
    network.publicCloud.cloudKeyID = networkForm.cloudKeyID;
    network.publicCloud.cloudType = networkForm.cloudType;
    network.publicCloud.cloudRegion = networkForm.cloudRegion;
    network.publicCloud.connectionOption = 'private';
    network.publicCloud.cloudNetworks =
      networkForm.publicCloud.cloudNetworks.map((pc) => {
        const {
          connectorPlacement = '',
          hubID = '',
          connectorSettings = {} as ConnectorSettings,
          connectivityType = '',
          connectorGroupID = '',
          edgeConnectivityID = '',
          id = '',
          serviceSubnets,
        } = network.publicCloud?.cloudNetworks?.find(
          (c) => c.cloudNetworkID === pc.cloudNetworkID
        ) || ({} as CloudNetwork);
        const {
          bandwidth = '',
          bandwidthName = '',
          cloudNetworkId = '',
          instanceType = '',
          teamId = '',
          updateStatus = '',
          bandwidthRange = {
            min: 1,
            max: 5,
          },
          subnets = [],
        } = connectorSettings || ({} as ConnectorSettings);
        return {
          cloudNetworkID: pc.cloudNetworkID,
          subnets: pc.subnets,
          connectorPlacement:
            connectorPlacement ||
            (networkForm.csp === CLOUD_TYPES.GCP
              ? CONNECTOR_TYPE_VALUE.APP
              : ''),
          hubID: hubID || '',
          connectivityType: connectivityType || '',
          connectorGroupID: connectorGroupID || '',
          edgeConnectivityID: edgeConnectivityID || '',
          id: id || '',
          connectorSettings: {
            bandwidth: bandwidth || '',
            bandwidthName: bandwidthName || '',
            cloudNetworkId: cloudNetworkId || '',
            instanceType: instanceType || '',
            teamId: teamId || '',
            updateStatus: updateStatus || '',
            bandwidthRange: this.isValidForSetting(
              network,
              connectorPlacement ||
                (networkForm.csp === CLOUD_TYPES.GCP
                  ? CONNECTOR_TYPE_VALUE.APP
                  : '')
            )
              ? {
                  max: bandwidthRange?.max || 5,
                  min: bandwidthRange?.min || 1,
                }
              : null,
            subnets: subnets || [],
          },
          serviceSubnets,
        } as CloudNetwork;
      });
    network.exportable = networkForm.exportable;
  }

  updateAutoDefinition: (
    network: Network,
    networkForm: NetworkAutoDefinition
  ) => void;

  detectAutoOnboardRulesFormChanges: (
    network: Network,
    rulesForm: Rule[]
  ) => number[];

  validateAutoOnboardRules: (
    network: Network,
    rulesForm: Rule[],
    prefixes: Map<string, PrefixInfo[]>,
    bgpCommunities: Map<string, string[]>,
    bgpNeighbors: Map<string, string[]>
  ) => number[];

  updateAutoOnboardRules: (
    network: Network,
    rulesForm: NetworkRule,
    prefixes: Map<string, PrefixInfo[]>,
    bgpCommunities: Map<string, string[]>,
    bgpNeighbors: Map<string, string[]>
  ) => void;

  getRulesChangeSet: (
    existingNetwork: Network,
    rulesForm: NetworkRule,
    prefixes: Map<string, PrefixInfo[]>,
    bgpCommunities: Map<string, string[]>,
    bgpNeighbors: Map<string, Array<string>>
  ) => Array<ChangeSetList>;

  detectVPCFormChanges(
    network: Network,
    vpcForm: Array<VPCSettings>
  ): Array<number> {
    const modifiedNetworks = network.publicCloud?.cloudNetworks?.reduce(
      (p, cloudNetwork, i) => {
        if (
          vpcForm[i].connectorPlacement !== cloudNetwork.connectorPlacement ||
          vpcForm[i].connectorSettings?.bandwidthRange?.max !==
            cloudNetwork.connectorSettings?.bandwidthRange?.max ||
          vpcForm[i].connectorSettings?.bandwidthRange?.min !==
            cloudNetwork.connectorSettings?.bandwidthRange?.min ||
          !(
            ArrayUtil.findCommonValues(
              vpcForm[i].connectorSettings?.subnets || [],
              cloudNetwork.connectorSettings?.subnets || []
            ).length === vpcForm[i].connectorSettings?.subnets?.length &&
            vpcForm[i].connectorSettings?.subnets?.length ===
              cloudNetwork.connectorSettings?.subnets?.length
          )
        ) {
          p.push(i);
        }

        return p;
      },
      [] as Array<number>
    );
    return modifiedNetworks;
  }

  validateVPCs(network: Network, vpcSettings: VPCSettings[]): Array<number> {
    if (!network.publicCloud?.cloudNetworks?.length) {
      return [];
    }

    return vpcSettings
      .map((vpc, i) => {
        let valid = true;
        if (this.isValidForSetting(network, vpc.connectorPlacement)) {
          if (
            vpc.connectorSettings.bandwidthRange.min < 1 ||
            vpc.connectorSettings.bandwidthRange.max > 100 ||
            vpc.connectorSettings.bandwidthRange.max <
              vpc.connectorSettings.bandwidthRange.min
          ) {
            valid = false;
          }
        }

        if (
          vpc.connectorPlacement === CONNECTOR_TYPE_VALUE.APP &&
          !vpc.connectorSettings.subnets?.length
        ) {
          valid = false;
        }

        if (
          network.publicCloud.cloud === CLOUD_TYPES.AZURE &&
          vpc.connectorSettings.subnets?.length > 1
        ) {
          valid = false;
        }

        if (
          [CLOUD_TYPES.AWS, CLOUD_TYPES.GCP].includes(
            network.publicCloud.cloud
          ) &&
          vpc.connectorSettings.subnets?.length > 2
        ) {
          valid = false;
        }

        if (!vpc.connectivityType) {
          valid = false;
        }

        if (
          [
            CONNECTIVITY_OPTIONS_KEYS.TRANSIT_GATEWAY,
            CONNECTIVITY_OPTIONS_KEYS.VWAN_HUB,
          ].includes(vpc.connectivityType) &&
          !vpc.hubID
        ) {
          valid = false;
        }
        return valid ? i : -1;
      })
      .filter((i) => i >= 0);
  }

  updateVPCs(network: Network, networkForm: NetworkVPC): void {
    network.name = networkForm.name;
    networkForm.vpcSettings.forEach((vpc, i) => {
      network.publicCloud.cloudNetworks[i].connectorPlacement =
        vpc.connectorPlacement;
      network.publicCloud.cloudNetworks[i].connectorSettings = {
        ...network.publicCloud.cloudNetworks[i].connectorSettings,
        subnets: vpc.connectorSettings.subnets,
      } as ConnectorSettings;
      if (this.isValidForSetting(network, vpc.connectorPlacement)) {
        network.publicCloud.cloudNetworks[i].connectorSettings = {
          ...network.publicCloud.cloudNetworks[i].connectorSettings,
          bandwidthRange: {
            max: vpc.connectorSettings.bandwidthRange.max,
            min: vpc.connectorSettings.bandwidthRange.min,
          },
        } as ConnectorSettings;
      } else {
        delete network.publicCloud.cloudNetworks[i].connectorSettings
          ?.bandwidthRange;
      }

      if (vpc.connectorPlacement !== CONNECTOR_TYPE_VALUE.APP) {
        delete network.publicCloud.cloudNetworks[i].connectorSettings?.subnets;
      }

      network.publicCloud.cloudNetworks[i].connectivityType =
        vpc.connectivityType;
      if (
        [
          CONNECTIVITY_OPTIONS_KEYS.TRANSIT_GATEWAY,
          CONNECTIVITY_OPTIONS_KEYS.VWAN_HUB,
        ].includes(vpc.connectivityType)
      ) {
        network.publicCloud.cloudNetworks[i].hubID = vpc.hubID;
      } else {
        network.publicCloud.cloudNetworks[i].hubID = '';
      }
      if (
        vpc.connectorPlacement === CONNECTOR_TYPE_VALUE.APP &&
        network.publicCloud.cloud === CLOUD_TYPES.AWS
      ) {
        vpc.serviceSubnets.subnets.forEach((subnet) => {
          if (
            subnet.type ===
            SERVICE_INSERTION_ENDPOINT_SUBNET_TYPES.SERVICE_ENDPOINT
          ) {
            subnet.ipAddrCidrs = vpc.serviceSubnets.endpointSubnet || [];
          } else if (
            subnet.type === SERVICE_INSERTION_ENDPOINT_SUBNET_TYPES.CONNECTOR
          ) {
            subnet.ipAddrCidrs = vpc.serviceSubnets.subnetInterface || [];
          }
        });
        network.publicCloud.cloudNetworks[i].serviceSubnets = {
          mode: vpc.serviceSubnets.mode,
          svcID: vpc.serviceSubnets.svcID,
          svcName: vpc.serviceSubnets.svcName,
          subnets: vpc.serviceSubnets.subnets,
        };
      } else {
        network.publicCloud.cloudNetworks[i].serviceSubnets = {
          mode: 'none',
          svcID: '',
          svcName: '',
          subnets: null,
        };
        delete network.publicCloud.cloudNetworks[i].serviceSubnets.subnets;
      }
    });
    network.publicCloud.connectionOption = 'private';
  }

  detectPolicyFormChanges(network: Network, networkForm: NetworkPolicy) {
    return !ArrayUtil.compareArrays(
      network.security.policies?.map((policy) => policy.id) || [],
      networkForm.policies,
      ''
    );
  }

  validatePolicy(
    networkForm: NetworkPolicy,
    defaultPolicies: Map<string, string>
  ) {
    if (networkForm.policies.length === 0) {
      return true;
    }
    // TODO: This needs to be added back when Policy section has restriction on DENY-ALL-x and other policies co-existing on the same entity.

    // if (
    //   [POLICY_TYPE.DENY_ALL_NETWROKS, POLICY_TYPE.DENY_ALL_USERS].some(
    //     (policy) => networkForm.policies.includes(defaultPolicies.get(policy))
    //   ) &&
    //   networkForm.policies.length > 1
    // ) {
    //   return false;
    // }
    return true;
  }

  updatePolicy(network: Network, networkForm: NetworkPolicy) {
    network.name = networkForm.name;
    network.security.policies = networkForm.policies.map((policy) => ({
      id: policy,
    }));
  }

  isValidForSetting(
    network: Network,
    connectorPlacement: CONNECTOR_TYPE_VALUE | string
  ): boolean {
    return (
      (connectorPlacement === CONNECTOR_TYPE_VALUE.APP &&
        connectorScalingPermittedCSPs.includes(network.publicCloud.cloud)) ||
      (connectorPlacement === CONNECTOR_TYPE_VALUE.INFRA &&
        infraConnectorScalingPermittedCSPs.includes(network.publicCloud.cloud))
    );
  }

  addSubnetToDefinitionForm: (
    subnet: string,
    networkForm: ModelFormGroup<NetworkDefinition>
  ) => void;

  removeSubnetToDefinitionForm: (
    subnet: string,
    networkForm: ModelFormGroup<NetworkDefinition>
  ) => void;

  updateOrchestratorWorkflow(cloudType: string) {
    this.orchestratorService.setOrchWorkflow({
      tabs: NetworkUtil.getNetworkOnboardTabsFromCloudType(cloudType),
      workflowPayload: null,
      workflow:
        NetworkOnboardWorkflow as OrchestratorWorkflow<OrchestratorStep>,
    });
  }
}
