import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { IMAGE_PATHS, DIALOG_ACTION_TYPES } from '@app/common/util/constants';

@Component({
  selector: 'app-status-dialog',
  templateUrl: './status-dialog.component.html',
  styleUrls: ['./status-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusDialogComponent implements OnInit, OnChanges {
  @Input() statusAction: string; // refers to the confirm/success action of dialog
  @Input() statusMessage: string; // refers to the message displayed on dialog
  @Input() statusActionButtons; // refers to array of buttons(Cancel and Yes) to display on Dialog
  @Input() copyDataConfig: any = null;
  @Input() showLoader = false;
  @Output() clickedActionButton = new EventEmitter<string>();

  CLOSE = { name: 'Cancel', state: null };
  DIALOG_ACTION_TYPE = DIALOG_ACTION_TYPES;
  prosimoLogoURL = IMAGE_PATHS.COMMON.PROSIMO;
  inActiveButton: any;
  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {}

  dialogButtonStyle(actionButton: any) {
    return actionButton.state === 'inActive'
      ? 'btn btn-cancel'
      : 'btn btn-primary';
  }

  onDialogButtonAction(actionButton: any, isClose?: boolean) {
    this.getInActiveStateButton();
    if (isClose) {
      this.clickedActionButton.emit(this.inActiveButton ? this.inActiveButton : actionButton);
    } else {
      this.clickedActionButton.emit(actionButton);
    }
  }

  getInActiveStateButton() {
    if(!this.statusActionButtons){
      return;
    }
    this.inActiveButton = this.statusActionButtons.find(({state}) => state === 'inActive')
  }
}
