import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchFilterService {
  private searchFilterTerms = new Subject();
  private resetSearchFilter = new Subject();
  constructor() {}

  setsearchFilterTerms(data: any) {
    this.searchFilterTerms.next(data);
  }

  getsearchFilterTerms(): Observable<any> {
    return this.searchFilterTerms.asObservable();
  }

  resetAdvanceSearchFilter(disableCalls?: boolean) {
    this.resetSearchFilter.next(disableCalls);
  }

  getResetAdvanceSearchFilter(): any {
    return this.resetSearchFilter.asObservable();
  }
}
