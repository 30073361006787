import { Query } from '@datorama/akita';
import {
  PlaygroundFilterState,
  playgroundFilterStore,
} from './playground-filter.store';

export class PlaygroundFilterQuery extends Query<PlaygroundFilterState> {}

export const playgroundFilterQuery = new PlaygroundFilterQuery(
  playgroundFilterStore
);
