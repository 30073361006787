import { Component, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PasswordRuleService } from './password-rule.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-password-rule',
  templateUrl: './password-rule.component.html',
  styleUrls: ['./password-rule.component.scss'],
})
export class PasswordRuleComponent implements OnInit, OnDestroy {
  /** Password rule status */
  passwordRules$ = new BehaviorSubject({
    digit: false,
    lower: false,
    upper: false,
  });

  subscriptions = [];

  constructor(private passwordRuleService: PasswordRuleService) {}

  ngOnInit() {
    /*
     * Subscribe password rule status changes and update the message in UI
     */
    const passwordRuleStatusSub = this.passwordRuleService
      .getPasswordRulesStatus()
      .pipe(debounceTime(100), distinctUntilChanged())
      .subscribe((passwordRulesStatus) => {
        this.passwordRules$.next(passwordRulesStatus);
      });
    this.subscriptions.push(passwordRuleStatusSub);
  }

  ngOnDestroy(): void {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }
}
