import { TasksStore, tasksStore } from './tasks.store';
import { SelectionObjectWithArray } from '../../../common/util/constants';

export class TasksService {
  constructor(private tasksDataStore: TasksStore) {}

  updateSelectedTasks(selectedTasks: Array<SelectionObjectWithArray>) {
    this.tasksDataStore.update((state) => ({
      selectedTasks,
    }));
  }
}

export const tasksService = new TasksService(tasksStore);
