import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { OverlappingCidrComponent } from './overlapping-cidr.component';
import {
  AppMaterialModule,
  NoDataAvailableModule,
  OverviewContainerModule,
  ToolTipModule,
} from '@prosimoio/components';
import { SearchFilterPipeModule } from '@prosimoio/pipes';
import { OverlappingCiderTableComponent } from './overlapping-cider-table/overlapping-cider-table.component';
import { PCNamePipe } from '@app/common/pipe/pc-name/pc-name.pipe';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    OverviewContainerModule,
    NoDataAvailableModule,
    AppMaterialModule,
    SearchFilterPipeModule,
    ToolTipModule,
    PCNamePipe,
  ],
  declarations: [OverlappingCidrComponent, OverlappingCiderTableComponent],
  exports: [OverlappingCidrComponent, OverlappingCiderTableComponent],
})
export class OverlappingCidrModule {}
