import {
  ACCESS_BASED_POLICY_ACTIONS,
  POLICY_TYPE_ACCESS_NAMES,
} from './constants';

export const POLICY_TYPE = {
  IMPLICIT_DENY: 'IMPLICIT-DENY',
  ALLOW_ALL: 'ALLOW-ALL',
  DENY_ALL: 'DENY-ALL',
  BYPASS_ALL: 'DEFAULT-CONNECT-POLICY',
  BYPASS: 'customize',
  DEFAULT: 'default',
  DEFAULT_MCN_POLICY: 'DEFAULT-MCN-POLICY',
  ALLOW_ALL_NETWORKS: 'ALLOW-ALL-NETWORKS',
  DENY_ALL_NETWROKS: 'DENY-ALL-NETWORKS',
};

export const POLICY_CARD_ACTIONS = {
  CONFIGURE: 'CONFIGURE',
  DELETE: 'DELETE',
  VIEW: 'VIEW',
  DUPLICATE: 'DUPLICATE',
};

export const POLICY_TYPE_NAMES = {
  'ALLOW-ALL': ACCESS_BASED_POLICY_ACTIONS.ALLOW_ALL,
  'DENY-ALL': ACCESS_BASED_POLICY_ACTIONS.DENY_ALL,
  'DEFAULT-MCN-POLICY': ACCESS_BASED_POLICY_ACTIONS.DEFAULT_MCN_POLICY,
  'DENY-ALL-NETWORKS': ACCESS_BASED_POLICY_ACTIONS.DENY_ALL_NETWORKS,
};

/**
 * Every action is mapped with oppsit action because only one action should be selected and other should be removed from selection
 * Ex: When user select Allow All User then Deny All user should be deselected
 */
export const MUTUALLY_EXCLUSIVE_POLICIES = {
  'ALLOW-ALL-USERS': 'DENY-ALL-USERS',
  'DENY-ALL-USERS': 'ALLOW-ALL-USERS',
  'ALLOW-ALL-NETWORKS': 'DENY-ALL-NETWORKS',
  'DENY-ALL-NETWORKS': 'ALLOW-ALL-NETWORKS',
};

export const ALL_DEFAULT_POLICY_LIST = [
  POLICY_TYPE_ACCESS_NAMES['ALLOW-ALL'],
  POLICY_TYPE_ACCESS_NAMES['DENY-ALL'],
  POLICY_TYPE_ACCESS_NAMES['DEFAULT-MCN-POLICY'],
  POLICY_TYPE_ACCESS_NAMES['DENY-ALL-NETWORKS'],
];

export const POLICY_ACCESS_TYPES = {
  ACCESS: 'access',
  TRANSIT: 'transit',
};
