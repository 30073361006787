import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { COLORS } from '@app/common/util/constants';

@Component({
  selector: 'app-switch-text',
  templateUrl: './switch-text.component.html',
  styleUrls: ['./switch-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchTextComponent implements OnInit, OnChanges {
  @ViewChild('toggleBtn', { static: true }) toggleBtn;
  private _selectedText: string;
  @Output() switchView = new EventEmitter<boolean>();
  @Output() activeStateText = new EventEmitter<string>();
  @Input() left: string;
  @Input() right: string;
  @Input() set selectedText(value: string) {
    if (value) {
      this._selectedText = value;
      this.isCheck = value === this.left ? true : false;
    }
  }
  get selectedText(): string {
    return this._selectedText;
  }
  @Input() switchWidth = '130px';
  @Input() toggleButtonWidth = '65px';
  @Input() toggleButtonColor = COLORS.ORANGE2;
  @Input() showToggleText: boolean = true;
  @Input() isDisabled: boolean = false;
  disabledButtonColor = COLORS.GREY3;
  isCheck: boolean;

  constructor(private cdr: ChangeDetectorRef) { }
  ngOnChanges(changes: SimpleChanges) { }

  ngOnInit() { }

  toggleChange(event: any) {
    if (this.isDisabled) {
      return;
    }
    this._selectedText = event.target.checked ? this.right : this.left;
    this.switchView.emit(this._selectedText === this.left ? false : true);
    this.activeStateText.emit(this._selectedText);
    this.cdr.markForCheck();
  }

  onClickIcon(text: string, isEmittable = true) {
    if (this.isDisabled) {
      return;
    }
    this.toggleBtn.nativeElement.checked =
      !this.toggleBtn.nativeElement.checked;
    this._selectedText = text;
    if (isEmittable) {
      this.switchView.emit(this._selectedText === this.left ? false : true);
      this.activeStateText.emit(this._selectedText);
    }
    this.cdr.markForCheck();
  }
}
