<div
  id="error-page-preview__container"
  class="w-100 d-flex justify-content-center h-100 position-absolute"
>
  <pcomp-error-page
    [errorPageConfig]="{
      backgroundImageURL: backgroundImageURL,
      logo: previewImageUrl,
      page: errorPageContent?.errorType,
      type: errorPageContent?.errorCode,
      message: errorPageContent?.message,
      messageTitle: errorPageContent?.messageTitle,
      contact: errorPageContent?.contact
    }"
  ></pcomp-error-page>
</div>
