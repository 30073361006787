import { TabConfig } from '@prosimoio/components';
import { StatusLegend } from './transit-360.models';
import { COLORS, IMAGE_PATHS } from '@app/common/util/constants';

export const TRANSIT_360_PATH = {
  INSIGHTS: '/v2/insights/main',
  TRANSIT_360_PATH: '/v2/transit-360',
  INFRA: '/v2/transit-360/infra',
  TARGET: '/v2/transit-360/target',
};

export const TRANSIT_360_BREADCRUMB_CONSTANTS = {
  INSIGHTS: 'Insights',
  INSIGHTS_LINK: TRANSIT_360_PATH.INSIGHTS,
  TRANSIT_360: 'Transit 360',
  TRANSIT_360_LINK: TRANSIT_360_PATH.TRANSIT_360_PATH,
  INFRA: 'Infra View',
  INFRA_LINK: TRANSIT_360_PATH.INFRA,
  EDGE: 'Edge',
  TARGET: 'Target',
  TARGET_LINK: TRANSIT_360_PATH.TARGET,
};

// Please write all your constant in DIAGNOSE_CONSTANT and import it.
export const TRANSIT_360_CONSTANT = {
  TOASTER_MESSAGES: {},
  THEME_STUFF: {
    THEME: 'theme',
    CDK_COMPONENT_CONTAINER: 'cdk-component-container',
    DARK: 'dark',
    LIGHT: 'light',
  },
  HEALTH_METRICS: {
    HEALTH_GEO: 'HEALTH_GEO',
    REGION_EDGE_TRANSIT: 'REGION_EDGE_TRANSIT',
  },
  EDGE_HEALTH_STATUS: {
    HEALTHY: 'Healthy',
    UNHEALTHY: 'Unhealthy',
    FAILED: 'Failed',
  },
  SOURCE_TYPES: {
    NETWORK: 'network',
    PRIVATE_LINK: 'private_link',
  },
  PRIVATE: 'PRIVATE',
  RADIAL_TREE_EDGES: {
    TEXT_CLASS: 'svg-bg-fill-grey5 radial-tree-text',
    NODE_CLASS: 'svg-stroke-grey2',
    ERROR: {
      COLOR: COLORS.RADIAL_TREE_RED,
      STROKE_COLOR: COLORS.RADIAL_TREE_STROKE_RED,
      STROKE_WIDTH: '3px',
      DASH_ARRAY: '0',
      WEIGHT: 1,
    },
    SUCCESS: {
      COLOR: COLORS.RADIAL_TREE_GREEN,
      STROKE_COLOR: COLORS.RADIAL_TREE_STROKE_GREEN,
      STROKE_WIDTH: '3px',
      DASH_ARRAY: '0',
      WEIGHT: 1,
    },
    PARTAIL: {
      COLOR: COLORS.RADIAL_TREE_YELLOW,
      STROKE_COLOR: COLORS.RADIAL_TREE_STROKE_YELLOW,
      STROKE_WIDTH: '3px',
      DASH_ARRAY: '0',
      WEIGHT: 1,
    },
    NOT_ACTIVE: {
      COLOR: COLORS.RADIAL_TREE_GREY,
      STROKE_COLOR: COLORS.RADIAL_TREE_STROKE_GREY,
      STROKE_WIDTH: '1px',
      DASH_ARRAY: '0',
      WEIGHT: 1,
    },
    TOOLTIP_CLASS: {
      NOT_EXIST: 'transit-status-icon-not-exist',
      ERROR: 'transit-status-icon-error',
      SUCCESS: 'transit-status-icon-success',
      PARTIAL: 'transit-status-icon-partial',
    },
  },
  POLYLINE_SEGMENT: {
    ERROR: {
      color: COLORS.RED,
      dashArray: '10',
      weight: 1,
    },
    SUCCESS: {
      color: COLORS.GREEN,
      dashArray: '10',
      weight: 1,
    },
    UNAVAILABLE: {
      color: COLORS.RADIAL_TREE_STROKE_GREY,
      dashArray: '10 10',
      weight: 1,
    },
    UNKNOWN: {
      color: COLORS.WHITE,
      dashArray: '10',
      weight: 0,
    },
  },
  USER_POLYLINE_SEGMENT: {
    ERROR: {
      color: COLORS.RED,
      dashArray: '0',
      weight: 1,
    },
    SUCCESS: {
      color: COLORS.GREEN,
      dashArray: '0',
      weight: 1,
    },
    PARTAIL: {
      color: COLORS.ORANGE2,
      dashArray: '0',
      weight: 1,
    },
  },
  TIME_FILTER_RANGE: 7,
  EDGE_STATUSES: {
    INACCESSIBLE: 'inaccessible',
    ACCESSIBLE: 'accessible',
    PARTIAL: 'partial',
    UP: 'up',
    DOWN: 'down',
  },
  LOG_TIME_INTERVAL: 5,
  STAT_METRIC_NAMES: {
    EDGE_HEALTH_SEGMENT_LOG: 'MIDMILE_LOGS',
  },
  POLYLINE_CONNECTION_TYPES: {
    EDGE: 'EDGE',
    USER: 'USER',
    APP: 'APPS',
  },
  CLOUD_TRANSIT_MENU_OPTIONS: {
    CLOUD_TRANSIT: 'CLOUD_TRANSIT',
    TRANSIT: 'EDGE_TRANSIT',
    ZTNA: 'ZTNA',
    MCN: 'MCN',
    TARGET: 'TARGET',
  },
  NAVIGATION_URL: {
    EDGE_DETAIL: 'v2/transit-360/infra/',
  },
  ELEMENT_ID: {
    VIEW_EDGE_CONNECTION_LINK: 'view-edge-connection-link',
  },
};

export const TRANSIT_360_TAB_VIEW_CONFIG: Array<TabConfig> = [
  {
    tabName: 'Infra View',
    totalRecords: 0,
  },
  {
    tabName: 'Target View',
    totalRecords: 0,
  },
];

export enum EDGE_HEALTH_STATUS {
  HEALTHY = 'Healthy',
  UNHEALTHY = 'Unhealthy',
  FAILED = 'Failed',
}

export enum APP_HEALTH_STATUS {
  EXCELLENT = 'excellent',
  GOOD = 'good',
  POOR = 'poor',
}

export enum STATUS_LEGEND_KEYS {
  UP = 'up',
  PARTIAL = 'partial',
  DOWN = 'down',
}

export const FilterTypes = {
  edgeTransit: 'EDGE_TRANSIT',
  targetTransit: 'TARGET',
};

export enum HopTypes {
  EDGE = 'pedge',
  CONNECTOR = 'pconnector',
}

export enum ConnectorPlacement {
  INFRA = 'infra',
  WORKLOAD = 'workload',
}

export const INACCESSIBLE = 'inaccessible';
export type Timelines = 'EDGE_TIMELINE' | 'MIDMILE_TIMELINE' | 'APP_TIMELINE';

export const EdgeSelectionTypes = {
  ALL: 'All Edges',
  UNHEALTHY: 'Unhealthy Edges',
};

export enum SegmentSelectionTypeKeys {
  ALL = 'all',
  UNHEALTHY = 'unhealthy',
}

export const SegmentSelectionTypes = {
  ALL: 'All Segments',
  UNHEALTHY: 'Unhealthy Segments',
};

export const STATUS_LEGENDS: Array<StatusLegend> = [
  {
    key: STATUS_LEGEND_KEYS.UP,
    label: 'Healthy',
    imgUrl: `${IMAGE_PATHS.CLOUD_TRANSIT.ACTIVE_EDGE}`,
    fillColor: COLORS.GREEN,
  },
  {
    key: STATUS_LEGEND_KEYS.PARTIAL,
    label: 'Unhealthy',
    imgUrl: `${IMAGE_PATHS.CLOUD_TRANSIT.PARTIAL_FAILED_EDGE}`,
    fillColor: COLORS.ORANGE2,
  },
  {
    key: STATUS_LEGEND_KEYS.DOWN,
    label: 'Failed',
    imgUrl: `${IMAGE_PATHS.CLOUD_TRANSIT.FAILED_EDGE}`,
    fillColor: COLORS.RED,
  },
];

export enum TransitTargetType {
  APP = 'Apps',
  NETWORK = 'Networks',
}

export const AppAccessTypes = {
  ZTNA: 'ztna',
  MCN: 'mcn',
};

export const HEALTH_METRICS = {
  HEALTH_GEO: 'HEALTH_GEO',
  REGION_EDGE_TRANSIT: 'REGION_EDGE_TRANSIT',
};

export const USER_POLYLINE_SEGMENT = {
  ERROR: {
    color: COLORS.RED,
    dashArray: '0',
    weight: 1,
  },
  SUCCESS: {
    color: COLORS.GREEN,
    dashArray: '0',
    weight: 1,
  },
  PARTAIL: {
    color: COLORS.ORANGE2,
    dashArray: '0',
    weight: 1,
  },
};

export const PAGE_HEADER_CONST = {
  LABEL: 'View Live Data',
  TYPE: 'stroked',
};

// Constants for Tabs.
export const TABS_DETAILS = {
  TAB_MARGIN: 'mat-tab-label-ml-16',
};

export const SEGEMENTS_EDGE_ICONS = {
  AWS: IMAGE_PATHS.ICON.AWS,
  GCP: IMAGE_PATHS.ICON.GCP_CLOUD_LOGO_ONLY,
  AZURE: IMAGE_PATHS.ICON.AZURE_CLOUD_LOGO_ONLY,
  PRIVATE: IMAGE_PATHS.COMMON.PRIVATE_NEUTRAL,
};

export enum ConnectorGroupStatus {
  HEALTHY = 'Healthy',
  UNHEALTHY = 'Unhealthy',
  FAILED = 'Failed',
  TOTAL = 'Total',
}

export const LatencyHourlyThreshold = 10;

export const entityPluralMapping = {
  connector: {
    '=1': 'Workload Connector',
    other: 'Workload Connectors',
  },
  netGw: {
    '=1': 'Network Gateway',
    other: 'Network Gateways',
  },
  app: {
    '=1': 'App',
    other: 'Apps',
  },
  network: {
    '=1': 'Network',
    other: 'Networks',
  },
};
