import { ElementRef, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AppUtil } from './app-util';
import { DIALOG_TOKEN } from './constants';
import { DeleteDialogComponent } from '@prosimoio/components';
import { PDashLocalStoreUtil } from '../../state/web-pdash.util';

export interface NavData {
  check: boolean;
  elem: ElementRef;
}
@Injectable({
  providedIn: 'root',
})
export class NavConfirmationService {
  selectedAction$ = new Subject<boolean>();
  lightboxOverlayAction$ = new Subject<boolean>();
  navData: NavData;
  constructor(private appUtil: AppUtil) {}

  navConfirmationPopup(navData: NavData) {
    const uiThemeMode: string =
      PDashLocalStoreUtil?.getUIThemeMode()?.toLowerCase() + '-theme';
    const dialogData = {
      deleteMsg: '',
      deleteName: 'You have unsaved configurations. Do you want to proceed?',
      deleteButtonName: 'Proceed',
    };
    const injector = this.appUtil.createInjector(
      DIALOG_TOKEN.INJECTOR_TOKEN_DATA.DIALOG_DATA_TOKEN.NAME,
      dialogData
    );
    const componentRef = this.appUtil?.initializeDeleteOverlay(
      DeleteDialogComponent,
      injector,
      uiThemeMode,
      '294px',
      navData?.elem
    );
    (
      componentRef?.instance as DeleteDialogComponent
    )?.closeDialogEvent?.subscribe((result) => {
      this.appUtil?.destroyOverlay();
      this.setSelectedAction(result?.confirmation);
      if (result?.confirmation) {
        this.appUtil.setLightboxDisplayState(false);
        this.appUtil.setDrawerState(false);
        this.updateNavData({ check: false, elem: navData?.elem });
      }
    });
  }
  updateNavData(data: NavData) {
    this.navData = data;
  }
  getNavData() {
    return this.navData;
  }
  setSelectedAction(selectedAction: boolean) {
    this.selectedAction$.next(selectedAction);
  }
  getSelectedAction() {
    return this.selectedAction$.asObservable();
  }
  setLightboxOverlayAction(selectedAction: boolean) {
    this.lightboxOverlayAction$.next(selectedAction);
  }
  getLightboxOverlayAction() {
    return this.lightboxOverlayAction$.asObservable();
  }
}
