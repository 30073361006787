<input type="checkbox" id="{{id}}" (change)="toggleChange($event)" #toggleBtn />
<label class="toggle-button-app-switch" for="{{id}}" *ngIf="(!simple && !text) || !isIconSwitch" [style]="customStyles">
  <i class="material-icons switch__icon">{{ selectedItem }}</i>
</label>

<label *ngIf="isIconSwitch" class="toggle-button-app-switch" [style]="customStyles" for="toggle-button-app-switch">
  <img class="svg-icon-size" [src]="selectedSwitchIcons?.active" alt="" />
</label>

<label class="toggle-button-app-switch" for="{{id}}" *ngIf="text">
  {{ selectedItem }}
</label>
<div class="toggle-button" [ngClass]="{ 'toggle-button-txt': text }">
  <div class="toggle-button-first" (click)="onClickIcon(!isIconSwitch ? left : leftIcons)">
    <ng-container *ngIf="!leftIcons">
      <i class="material-icons switch__icon" *ngIf="!simple && !text">{{
        left
        }}</i>
      <span class="switch-txt" *ngIf="text">{{ left }}</span>
    </ng-container>
    <ng-container *ngIf="leftIcons">
      <img class="svg-icon-size" *ngIf="isLeft" [src]="leftIcons?.active" alt="" />
      <img class="svg-icon-size" *ngIf="!isLeft" [src]="leftIcons?.inactive" alt="" />
    </ng-container>
  </div>

  <div class="toggle-button-second" (click)="onClickIcon(!isIconSwitch ? right : rightIcons)">
    <ng-container *ngIf="!rightIcons">
      <i class="material-icons switch__icon" *ngIf="!simple && !text">{{
        right
        }}</i>
      <span class="switch-txt" *ngIf="text">{{ right }}</span>
    </ng-container>
    <ng-container *ngIf="rightIcons">
      <img class="svg-icon-size" *ngIf="isRight" [src]="rightIcons?.active" alt="" />
      <img class="svg-icon-size" *ngIf="!isRight" [src]="rightIcons?.inactive" alt="" />
    </ng-container>
  </div>
</div>