import { Injectable } from '@angular/core';
import { ResourceService } from '@prosimoio/services';
import {
  Network,
  NetworkCreateAction,
  NetworkOnboardResponse,
  NetworkResponse,
  NetworkSearchFilter,
  NetworkSmartSearchFilter,
  SmartSearchFilterValues,
} from '@app/common/models';
import { HttpClient } from '@angular/common/http';
import { CONSTANTS } from 'environments/environment';
import { CommonModelSerializer } from '../http/service/common.model.serializer';
import { EMPTY, Observable } from 'rxjs';
import { expand, reduce, take } from 'rxjs/operators';
import { PAGE_SIZE_V2 } from '../http/util/constants';

@Injectable({
  providedIn: 'root',
})
export class NetworkService extends ResourceService<any> {
  constructor(protected httpClient: HttpClient) {
    super(
      httpClient,
      CONSTANTS.DASHBOARD_HOST,
      '',
      new CommonModelSerializer()
    );
  }

  getNetworks(
    status: Array<string>,
    searchTerm: string = ''
  ): Observable<Array<Network>> {
    let pageStart = 0;
    this.path = CONSTANTS.NETWORK_SEARCH;
    return this.create(
      this.getNetworkPayload(status, searchTerm, pageStart)
    ).pipe(
      expand((response: NetworkResponse) => {
        pageStart += PAGE_SIZE_V2;
        if (pageStart < response.totalCount) {
          this.path = CONSTANTS.NETWORK_SEARCH;
          return this.create(
            this.getNetworkPayload(status, searchTerm, pageStart)
          );
        } else {
          return EMPTY;
        }
      }),
      reduce((acc: Array<Network>, val: NetworkResponse) => {
        if (val?.records) {
          return acc.concat(val.records);
        }
      }, [] as Array<Network>),
      take(1)
    );
  }

  searchNetworks(
    payload: NetworkSmartSearchFilter
  ): Observable<NetworkResponse> {
    this.path = CONSTANTS.V2.ONBOARD.NETWORKS.SPECIFIC_SEARCH;
    return this.create(payload);
  }

  fetchSearchFilterConfig(): Observable<Array<SmartSearchFilterValues>> {
    this.path = CONSTANTS.V2.ONBOARD.NETWORKS.SEARCH_FILTER_VALUES;
    return this.read('');
  }

  createNetwork(
    action: NetworkCreateAction,
    payload: Network
  ): Observable<NetworkOnboardResponse> {
    this.path = CONSTANTS.V2.ONBOARD.NETWORKS.CREATE(action);
    return this.create(payload).pipe(take(1));
  }

  deleteNetwork(id: string) {
    this.path = CONSTANTS.NETWORK_ONBOARD.DELETE;
    return this.delete(id);
  }

  offboardNetwork(id, isForced: boolean = false) {
    this.path = `${CONSTANTS.NETWORK_ONBOARD.OFFBOARD}/${id}${
      isForced ? '?force=true' : ''
    }`;
    return this.delete();
  }

  createNetworkCloudConfig(networkId: string, payload: Network) {
    this.path = CONSTANTS.NETWORK_ONBOARD.CLOUD_CONFIG.PUT(networkId);
    return this.update(payload, true).pipe(take(1));
  }

  updateNetworkSecurity(id, data) {
    this.path = CONSTANTS.NETWORK_ONBOARD.SECURITY.PUT(id);
    return this.update(data, true).pipe(take(1));
  }

  getNetworkPayload(
    status: Array<string>,
    searchTerm: string,
    pageStart: number
  ): NetworkSearchFilter {
    return {
      page: {
        size: PAGE_SIZE_V2,
        start: pageStart,
      },
      value: searchTerm,
      statusFilter: status,
    } as NetworkSearchFilter;
  }
}
