import { SecurityStore, securityStore } from './security.store';

export class SecurityService {
  constructor(private securityStore: SecurityStore) {}

  updateActiveTab(activeTab: string) {
    this.securityStore.update((state) => ({
      activeTab,
    }));
  }
}

export const securityService = new SecurityService(securityStore);
