import { Page, Sort, TimeISO } from '@prosimoio/services';
import { IDName } from './models';
import { ColumnFilter, ConnectorSettings } from './common';
import { IDNameNs } from './namespace';
import { Route } from './bgp';
import { PrefixInfo } from './grouping';

export type Network = {
  id?: string;
  name?: string;
  teamID?: string;
  pamCname?: string;
  policyUpdated?: boolean;
  deployed?: boolean;
  status?: string;
  displayStatus?: string;
  progress?: number;
  createdTime?: TimeISO;
  updatedTime?: string;
  publicCloud?: NetworkPublicCloud;
  privateCloud?: NetworkPrivateCloud;
  ownTransitCloud?: NetworkOwnCloud;
  security?: NetworkSecurity;
  autoOnboard?: boolean;
  discoveredRoutes?: DiscoveredRoutes;
  namespaceID?: string;
  namespacesExportedTo?: NamespaceList;
  namespaceImportedInto?: string;
  namespaceImportedFrom?: string;
  namespaceName?: string;
  namespaceNID?: number;
  exportable?: boolean;
  namespaces?: Array<string>;
  cloudTypeRegion?: string;
};

export type NetworkSelection = Network & {
  isChecked?: boolean;
};

export type NetworkPublicCloud = {
  id: string;
  cloud: string;
  cloudType: string;
  connectionOption: string;
  cloudKeyID: string;
  cloudRegion: string;
  cloudNetworks: Array<CloudNetwork>;
};

export type NetworkPrivateCloud = {
  id: string;
  cloud: string;
  cloudRegion: string;
  cloudType: string;
  connectionOption: string;
  connectorGroupID: string;
  privateCloudID: string;
  subnets: Array<string>;
  ruleBasedOnboarding?: boolean;
  autoOnboardRules?: PrivateCloudRules;
};

export type NetworkOwnCloud = {
  id: string;
  cloudType: string;
  subnets: Array<string>;
};

export type CloudNetwork = {
  id?: string;
  cloudNetworkID?: string;
  connectorGroupID?: string;
  edgeConnectivityID?: string;
  hubID?: string;
  connectivityType?: string;
  subnets?: Array<SubnetInfo>;
  connectorPlacement?: string;
  connectorSettings?: ConnectorSettings;
  serviceSubnets?: ServiceInsertionSubnet;
};

export type SubnetInfo = {
  subnet: string;
  virtualSubnet: string;
};

export type ServiceInsertionSubnet = {
  svcID: string;
  svcName: string;
  mode: string;
  subnets: Array<SharedServiceSubnet>;
};

export type SharedServiceSubnet = {
  type: string;
  ipAddrCidrs: Array<string>;
};

export type NetworkSecurity = {
  policies: Array<IDName>;
};

export type NamespaceList = {
  namespaces: Array<NetworkNamespace>;
};

export type NetworkNamespace = {
  namespaceID?: string;
  namespaceName?: string;
};

export type DiscoveredRoutes = {
  routes: Array<Route>;
};

export type PrivateCloudRules = {
  rules: Array<PrivateCloudRule>;
};

export type PrivateCloudRule = {
  bgpCommunities?: BgpCommunityRule;
  prefixes?: PrefixRule;
  bgpNeighbours?: BgpNeighborRule;
  action: RuleAction;
};

export enum RuleAction {
  ACCEPT = 'accept',
  REJECT = 'reject',
}

export enum RuleMatchCondition {
  ANY = 'any',
  ALL = 'all',
  NONE = 'none',
}

export type RuleClause = {
  matchCondition: RuleMatchCondition;
};

export type BgpCommunityRule = RuleClause & {
  communities?: Array<string>;
  communityGroups?: Array<IDName>;
};

export type PrefixRule = RuleClause & {
  values?: Array<PrefixInfo>;
  groups?: Array<IDName>;
};

export type BgpNeighborRule = RuleClause & {
  values: Array<string>;
};

export type NetworkSearchFilter = {
  value?: string;
  statusFilter?: Array<string>;
  page?: Page;
  sort?: Array<Sort>;
  deployed?: boolean;
  category?: string;
  connPlacement?: string;
};

export type NetworkSmartSearchFilter = {
  globalSearchValues: Array<string>;
  columnFilters: Array<ColumnFilter>;
  page?: Page;
  sortValues: Array<Sort>;
};

export type NetworkResponse = {
  category: string;
  records: Array<Network>;
  totalCount: number;
};

/**
 * Network Onboard Models
 */

export type NetworkOnboardResponse =
  | NetworkOnboardSuccessResponse
  | NetworkOnboardErrorResponse;

export type NetworkOnboardSuccessResponse = {
  id: string;
  taskID: string;
  auditID: string;
};

export type NetworkOnboardErrorResponse = {
  networkErrors: ModelResponseData<Network>;
};

export type ModelResponseData<T> = {
  [K in keyof T]: T[K] extends String | Number | Boolean
    ? ResponseField
    : ModelResponseData<T[K]>;
};

export type NetworkOnboardErrorResponseData = {
  name: ResponseField;
  exportable: ResponseField;
  publicCloud: PublicCloudResponse;
  privateCloud: PrivateCloudResponse;
  ownTransitCloud: OwnTransitCloudResponse;
  security: NetworkSecurityResponse;
  namespaceID: ResponseField;
};

export type PublicCloudResponse = {
  cloud: ResponseField;
  cloudType: ResponseField;
  connectionOption: ResponseField;
  cloudKeyID: ResponseField;
  cloudRegion: ResponseField;
  cloudNetworks: Array<CloudNetworksResponse>;
};

export type PrivateCloudResponse = {
  cloud: ResponseField;
  cloudRegion: ResponseField;
  cloudType: ResponseField;
  connectionOption: ResponseField;
  connectorGroupID: ResponseField;
  privateCloudID: ResponseField;
  subnets: Array<ResponseField>;
};

export type OwnTransitCloudResponse = {
  cloudType: ResponseField;
  subnets: Array<ResponseField>;
};

export type NetworkSecurityResponse = {
  policies: Array<IDNameResponse>;
};

export type CloudNetworksResponse = {
  cloudNetworkID: ResponseField;
  subnets: Array<SubnetInfoResponse>;
  hubID: ResponseField;
  connectivityType: ResponseField;
  connectorPlacement: ResponseField;
  connectorSettings: ConnectorSettingsResponse;
  serviceSubnets: ServiceInsertionResponse;
};

export type ConnectorSettingsResponse = {
  subnets: Array<ResponseField>;
  bandwidthName: ResponseField;
  cloudNetworkId: ResponseField;
  bandwidthRange: BandwidthRangeResponse;
};

export type BandwidthRangeResponse = {
  min: ResponseField;
  max: ResponseField;
};

export type SubnetInfoResponse = {
  subnet: ResponseField;
  virtualSubnet: ResponseField;
};

export type ServiceInsertionResponse = {
  svcID: ResponseField;
  svcName: ResponseField;
  mode: ResponseField;
  subnets: Array<SharedServiceSubnetResponse>;
};

export type SharedServiceSubnetResponse = {
  type: ResponseField;
  ipAddrCidrs: Array<ResponseField>;
};

export type IDNameResponse = {
  id: ResponseField;
  name: ResponseField;
};

export type NetworkGroupNs = {
  id: string;
  name: string;
  namespaceName: string;
  networks: Array<IDNameNs>;
};

export type ResponseField = {
  value: string;
  valid: boolean;
  errorMessage: string;
};

export enum NetworkCreateAction {
  VALIDATE = 'validate',
  SAVE = 'save',
  DEPLOY = 'deploy',
  REDEPLOY = 'redeploy',
}
