import { Query } from '@datorama/akita';
import { SecurityState, SecurityStore, securityStore } from './security.store';

export class SecurityQuery extends Query<SecurityState> {
  constructor(protected store: SecurityStore) {
    super(store);
  }
}

export const securityQuery = new SecurityQuery(securityStore);
