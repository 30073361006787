<div
  id="notification__container"
  *ngIf="config$ | async"
  class="position-absolute h-100 w-100 d-flex justify-content-center"
>
  <app-status-dialog
    [statusAction]="(config$ | async)?.actionType"
    [statusMessage]="(config$ | async)?.message"
    [copyDataConfig]="(config$ | async)?.copyDataConfig"
    [statusActionButtons]="(config$ | async)?.buttons"
    [showLoader]="loaderConfig$ | async"
    (clickedActionButton)="onClickAction($event)"
  ></app-status-dialog>
</div>
