export interface MenuConfig {
  name: string;
  path: string;
  isSelected: boolean;
  isDisabled?: boolean;
  fullPath?: string;
}

export interface ActionConfig {
  name: string;
  imgUrl: string;
  isDirectionAction: boolean;
  tooltip: string;
  fullPath?: string;
}

export class L2HeaderModel {
  title?: string;
  description?: string;
  displayHeader?: boolean;
  displayActionBtn?: boolean;
  selectedIcon?: string;
  actionBtnLabel?: string;
  profile?: HeaderProfile;
  isProsimoAdmin?: boolean;
  isMSPAdmin?: boolean;
  mspName?: string;
  mspImageURL?: string;
  actions?: Array<any>;
  routingLink?: string;
  routingLinkLabel?: string;
  isBetaRelease?: boolean;
  init() {
    return {
      title: '',
      description: '',
      selectedIcon: null,
      displayActionBtn: false,
      isProsimoAdmin: false,
      isMSPAdmin: false,
      actionBtnLabel: '',
      profile: null,
    } as L2HeaderModel;
  }
}

export interface HeaderProfile {
  company?: string;
  team?: string;
  actions?: Array<any>;
}

export interface L2HeaderUserInfo {
  firstname: string;
  lastname: string;
  username: string;
  team: string;
  disabledProfile: boolean;
}

export interface L2HeaderPageInfo {
  pageName: string;
}

export interface HeaderDescription {
  htmlContent: string;
  tootltipContent?: string;
  tootltipHtmlContent?: string;
}

export const HELP_ACTION = {
  HELP_AND_LEARN: 'help and learn',
  VIDEOS: 'videos',
  RELEASE_NOTES: 'relese notes',
  API_DOC: 'api documentation',
  CONTACT_SUPPORT: 'contact support',
  LOGOUT: 'logout',
};
