export enum DISPLAY_STATUS {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
  FETCHING = 'FETCHING',
}
export interface HelpModel {
  displayStatus: DISPLAY_STATUS;
  displayContent: string;
  displayTitle: string;
}

export enum HREF_TYPES {
  SELF = '_self',
  BLANK = '_blank',
}

export interface ArticleSearchResult {
  id: string;
  title: string;
}

export const Topics = [
  'Sign-up',
  'Main Dashboard',
  'Insights - Summary',
  'Insights - Performance',
  'Insights - App Experience',
  'Insights - User Risk',
  'Insights - Heatmap',
  'Insights - WAF',
  'Insights - Events',
  'App onboarding',
  'Protect - Policy',
  'Protect - WAF',
  'Protect - IP Reputation',
  'Optimize - Cache Rules',
  'Guide',
  'Management',
  'Diagnose - Infrastructure',
  'Diagnose - Health Status',
  'Diagnose - Troubleshoot',
  'Discovery',
];
