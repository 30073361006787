import {
  PrefixInfo,
  RuleAction,
  RuleMatchCondition,
} from '@app/common/models';

export type NetworkRule = {
  name: string;
  rules: Array<Rule>;
};

export type Rule = {
  id: string;
  bgpCommunities: BgpCommunityRule;
  prefixes: PrefixRule;
  bgpNeighbours: BgpNeighborRule;
  action: RuleAction;
};

export type RuleClause = {
  matchCondition: RuleMatchCondition;
};

export type BgpCommunityRule = RuleClause & {
  communities: string;
  wellKnownCommunities: Array<string>;
  communityGroups: Array<string>;
};

export type PrefixRule = RuleClause & {
  values: PrefixInfo;
  groups: Array<string>;
};

export type BgpNeighborRule = RuleClause & {
  values: string;
};

export const MatchConditionMap = {
  [RuleMatchCondition.ANY]: 'or',
  [RuleMatchCondition.ALL]: 'and',
  [RuleMatchCondition.NONE]: 'nor',
};
