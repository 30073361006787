export class HelpModelSerializer {
  fromJson(data: any): any {
    return data ? data.data : null;
  }

  toJson(data: any): any {
    return JSON.stringify(data);
  }

  toJsonArray(data: any): any {
    return data;
  }
}
