import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Pipe({
  name: 'subnetError',
})
export class SubnetErrorPipe implements PipeTransform {
  transform(subnetErrors: ValidationErrors): string {
    if (!subnetErrors || Object.keys(subnetErrors).length === 0) {
      return '';
    }

    if (subnetErrors.required) {
      return 'Virtual Subnet is required';
    }

    if (subnetErrors.server) {
      return subnetErrors.server;
    }

    if (subnetErrors.pattern) {
      return 'Invalid Subnet';
    }
  }
}
