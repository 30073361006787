import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { AppUtil } from '@app/common/util/app-util';
import { ReservedIPRange } from '@app/v2/onboard/visual-onboard/visual-onboard.model';

@Component({
  selector: 'app-reserved-ip-ranges',
  templateUrl: './reserved-ip-ranges.component.html',
  styleUrls: ['./reserved-ip-ranges.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReservedIpRangesComponent implements OnInit {
  lbData: any;
  reservedIPRanges: Array<ReservedIPRange>;
  isViewFullList: boolean = false;
  @Input() set data(data) {
    this.lbData = data?.lbData;
    this.reservedIPRanges = data?.reservedIPRanges;
  }
  constructor(private cdr: ChangeDetectorRef, private appUtil: AppUtil) {}

  ngOnInit(): void {}

  hideLightBox() {
    this.appUtil.setLightboxDisplayState(false);
  }

  viewFullList() {
    this.isViewFullList = !this.isViewFullList;
  }
}
