import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ISimpleTab } from './simple-tabs.interface';
@Component({
  selector: 'app-simple-tabs',
  templateUrl: './simple-tabs.component.html',
  styleUrls: ['./simple-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleTabsComponent implements OnInit {
  @Input() tabs: ISimpleTab[];
  @Input() showTopBorder: boolean = true;
  @Input() showBottomBorder: boolean = true;
  @Input() highlightSelectedTab: boolean = true;
  @Input() showBackgroundColor: boolean = true;
  @Input() selectedTabColor: string = '#2c374d';
  @Output() tabSelectionChange: EventEmitter<ISimpleTab> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    this.updateDefaultSelectedTab();
  }

  updateDefaultSelectedTab() {
    const selectedTabs = this.tabs.filter((tabs) => tabs.selected);

    if (!selectedTabs?.length) {
      this.tabs[0].selected = true;
    }

    this.tabSelectionChange.emit(this.tabs.filter((tab) => tab.selected)[0]);
  }

  onActionClick(index) {
    this.tabs.forEach((tab, i) => {
      tab.selected = i === index;
    });

    if (index > -1 && index < this.tabs.length) {
      this.tabSelectionChange.emit(this.tabs[index]);
    }
  }
}
