import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '@app/app-material.module';
import { UIThemePipeModule } from '@prosimoio/pipes';
import { WarningDialogComponent } from './warning-dialog.component';

@NgModule({
  declarations: [WarningDialogComponent],
  imports: [CommonModule, AppMaterialModule, UIThemePipeModule],
  exports: [WarningDialogComponent],
})
export class WarningDialogModule {}
