import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { MCNRecentSearch } from './mcn-recent-search.model';
import { Injectable } from '@angular/core';

export interface MCNRecentSearchState extends EntityState<MCNRecentSearch> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'mcn-recent-search', resettable: true })
export class MCNRecentSearchStore extends EntityStore<
  MCNRecentSearchState,
  MCNRecentSearch
> {
  constructor() {
    super();
  }
}

export const mcnRecentSearchStore = new MCNRecentSearchStore();
