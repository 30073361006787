import {
  CloudTracer,
  CloudTracerComp,
  CloudTracerHistoryRecord,
} from '@app/v2/diagnose/diagnose.model';
import { Store, StoreConfig } from '@datorama/akita';

export interface CloudTracerState {
  selectedHop?: CloudTracerComp;
  selectedTraceRecord?: CloudTracerHistoryRecord;
  cloudTracerResults?: CloudTracer;
  configRefreshTime?: string;
}

export function createInitialState(): CloudTracerState {
  return {
    selectedHop: undefined,
    selectedTraceRecord: undefined,
    cloudTracerResults: undefined,
    configRefreshTime: undefined,
  };
}

@StoreConfig({ name: 'cloud-tracer', resettable: true })
export class CloudTracerStore extends Store<CloudTracerState> {
  constructor() {
    super(createInitialState());
  }
}

export const cloudTracerStore = new CloudTracerStore();
