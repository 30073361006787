import { Query } from '@datorama/akita';
import {
  ManagementState,
  ManagementStore,
  managementStore,
} from './management.store';
import { Observable } from 'rxjs';

export class ManagementQuery extends Query<ManagementState> {
  activeTab$ = this.select((state) => state.activeTab);

  constructor(protected store: ManagementStore) {
    super(store);
  }
  get activeTab(): Observable<string> {
    return this.activeTab$;
  }
}

export const managementQuery = new ManagementQuery(managementStore);
