import { PrintFilterHeaderService } from '@app/common/components/print-filter-header/print-filter-header.service';
import { BehaviorSubject } from 'rxjs';
import {
  FILTER_TYPES,
  VIEW_TYPES,
  IMAGE_PATHS,
  SUBSCRIPTION_TYPES,
} from './../../util/constants';
import { FilterDisplayDetailsModel } from './filter-display-details.model';
import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { FilterService } from './filter.service';
import { DashboardMenuService } from '../dashboard-menu/dashboard-menu.service';
import { INSIGHTS_MENU_OPTIONS } from '@app/common/util/constants';
import { AppUtil } from '@app/common/util/app-util';
import { ALL } from '@app/dashboard/dashboard.constants';
import { PLAYGROUND_MENU_OPTIONS } from '@app/dashboard/playground/playground.constants';
import { Restrictions } from './playground-filter/playground-filter.model';
import { DateUtil } from '@prosimoio/services';
import { ENV_CONSTANTS } from 'environments/env.constants';
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterComponent implements OnInit, OnDestroy {
  appFilterData;
  userFilterData;
  dateFilterData;
  clientAppFilterData;
  sourceNetworksFilterData;
  sourcePrivateLinksFilterData;
  targetNetworksFilterData;
  targetNetworkGroupsFilterData;
  sourceRegionsFilterData;
  targetRegionsFilterData;

  @Input() set filterDisplayData(
    displayData: Array<FilterDisplayDetailsModel>
  ) {
    if (!displayData) {
      return;
    }
    this.setFilterData(displayData);
  }
  @Input() selectedMenuCode;
  @Input() viewType;
  @Output() customFilters = new EventEmitter<Restrictions>();

  subscriptions = [];
  INSIGHTS_MENU_OPTION_CONFIGS = INSIGHTS_MENU_OPTIONS;
  FILTER_TYPE_APP = FILTER_TYPES.APP;
  SUBSCRIPTION_TYPES_COPY = SUBSCRIPTION_TYPES;
  isRepeatPrintHeader$ = new BehaviorSubject(false);
  COPY_ALL = ALL;
  VIEW_TYPES = VIEW_TYPES;

  SOURCE_IMAGE = IMAGE_PATHS.DASHBOARD.INSIGHTS.MCN.SRC_FILTER;
  TARGET_IMAGE = IMAGE_PATHS.DASHBOARD.INSIGHTS.MCN.TGT_FILTER;
  CALENDAR_IMAGE = IMAGE_PATHS.DASHBOARD.INSIGHTS.MCN.CALENDAR_FILTER;
  NO_SOURCE_FILTER_MSG = `No sources selected`;
  NO_TARGET_FILTER_MSG = `No targets selected`;
  NO_FILTERS_MSG = 'No Filters selected';
  isMCNSourceFilterAvailable: boolean;
  isMCNTargetFilterAvailable: boolean;
  isZTNASourceFilterAvailable: boolean;
  isZTNATargetFilterAvailable: boolean;
  isPlaygroundFilterAvailable: boolean;
  isDateFilterEditable: boolean = true;
  customFilter: boolean = false;

  DATE_RANGE_LABELS = DateUtil.DATE_RANGE_LABELS;

  constructor(
    private filterService: FilterService,
    private dashboardMenuService: DashboardMenuService,
    private printFilterHeaderService: PrintFilterHeaderService,
    private appUtil: AppUtil,
    private cdr: ChangeDetectorRef
  ) {
    this.selectedMenuCode = this.appUtil.getZTNAActiveTab();
    this.subscriptions.push(
      this.dashboardMenuService
        .getSelectedMenuItem()
        .subscribe((selectedMenuConfig) => {
          if (selectedMenuConfig && selectedMenuConfig.code) {
            this.selectedMenuCode = selectedMenuConfig.code;
            if (
              this.selectedMenuCode === PLAYGROUND_MENU_OPTIONS.RECOMMENDATIONS
            ) {
              this.isDateFilterEditable = false;
              this.customFilter = true;
            } else {
              this.isDateFilterEditable = true;
              this.customFilter = false;
            }
          }
        }),
      this.printFilterHeaderService
        .isRepeatHeader()
        .subscribe((state: boolean) => {
          this.isRepeatPrintHeader$.next(state);
        }),

      this.appUtil.getPlaygroundLocalStore().subscribe(
        (data) => {
          if (
            !this.appUtil
              .getPath()
              .startsWith(ENV_CONSTANTS.PLAYGROUND.UI_PATH.UI_BASE_PATH)
          ) {
            return;
          }
          if (
            data &&
            data?.activeTab === PLAYGROUND_MENU_OPTIONS.RECOMMENDATIONS
          ) {
            this.isDateFilterEditable = false;
            this.customFilter = true;
          } else {
            this.isDateFilterEditable = true;
            this.customFilter = false;
          }
          this.cdr.markForCheck();
        },
        (error) => {
          console.warn(error);
        }
      )
    );
  }

  ngOnInit() {}

  setFilterData(displayData: Array<FilterDisplayDetailsModel>) {
    switch (this.viewType) {
      case VIEW_TYPES.PLAYGROUND:
        this.sourceNetworksFilterData = displayData.find(
          (filterData) =>
            filterData.type.toUpperCase() === FILTER_TYPES.SRC_NETWORKS
        );
        this.sourcePrivateLinksFilterData = displayData.find(
          (filterData) =>
            filterData.type.toUpperCase() === FILTER_TYPES.SRC_PRIVATE_LINKS
        );
        this.appFilterData = displayData.find(
          (filterData) => filterData.type.toUpperCase() === FILTER_TYPES.APP
        );
        this.sourceRegionsFilterData = displayData.find(
          (filterData) =>
            filterData.type.toUpperCase() === FILTER_TYPES.SRC_REGIONS
        );
        this.dateFilterData = displayData.find(
          (filterData) => filterData.type.toUpperCase() === 'DATE'
        );
        this.isPlaygroundFilterAvailable =
          !this.checkForPlaygroundFiltersAvailability();
        break;
      case SUBSCRIPTION_TYPES.MCN:
        this.sourceNetworksFilterData = displayData.find(
          (filterData) =>
            filterData.type.toUpperCase() === FILTER_TYPES.SRC_NETWORKS
        );
        this.sourcePrivateLinksFilterData = displayData.find(
          (filterData) =>
            filterData.type.toUpperCase() === FILTER_TYPES.SRC_PRIVATE_LINKS
        );
        this.targetNetworksFilterData = displayData.find(
          (filterData) =>
            filterData.type.toUpperCase() === FILTER_TYPES.TGT_NETWORKS
        );
        this.targetNetworkGroupsFilterData = displayData.find(
          (filterData) =>
            filterData.type.toUpperCase() === FILTER_TYPES.TGT_NETWORK_GROUPS
        );
        this.appFilterData = displayData.find(
          (filterData) => filterData.type.toUpperCase() === FILTER_TYPES.APP
        );
        this.sourceRegionsFilterData = displayData.find(
          (filterData) =>
            filterData.type.toUpperCase() === FILTER_TYPES.SRC_REGIONS
        );
        this.targetRegionsFilterData = displayData.find(
          (filterData) =>
            filterData.type.toUpperCase() === FILTER_TYPES.TGT_REGIONS
        );
        this.dateFilterData = displayData.find(
          (filterData) => filterData.type.toUpperCase() === 'DATE'
        );
        this.isMCNSourceFilterAvailable =
          !this.checkForMCNSourceFilterAvailability();
        this.isMCNTargetFilterAvailable =
          !this.checkForMCNTargetFilterAvailability();
        this.cdr.markForCheck();
        break;
      case SUBSCRIPTION_TYPES.ZTNA:
        this.userFilterData = displayData.find(
          (filterData) => filterData.type.toUpperCase() === FILTER_TYPES.USER
        );
        this.appFilterData = displayData.find(
          (filterData) => filterData.type.toUpperCase() === FILTER_TYPES.APP
        );
        this.targetNetworksFilterData = displayData.find(
          (filterData) =>
            filterData.type.toUpperCase() === FILTER_TYPES.TGT_NETWORKS
        );
        this.dateFilterData = displayData.find(
          (filterData) => filterData.type.toUpperCase() === 'DATE'
        );
        this.isZTNASourceFilterAvailable =
          !this.checkForZTNASourceFilterAvailability();
        this.isZTNATargetFilterAvailable =
          !this.checkForZTNATargetFilterAvailability();
        this.cdr.markForCheck();
        this.updateFilterConfig();
        break;
    }
  }

  checkForPlaygroundFiltersAvailability() {
    return (
      !this.sourceNetworksFilterData?.selectedFilterNames?.length &&
      !this.appFilterData?.selectedFilterNames?.length &&
      !this.sourcePrivateLinksFilterData?.selectedFilterNames?.length &&
      !this.sourceRegionsFilterData?.selectedFilterNames?.length
    );
  }

  checkForMCNSourceFilterAvailability() {
    return (
      !this.sourceNetworksFilterData?.selectedFilterNames?.length &&
      !this.sourceNetworksFilterData?.group?.selectedGroupNames?.length &&
      !this.sourcePrivateLinksFilterData?.selectedFilterNames?.length &&
      !this.sourceRegionsFilterData?.selectedFilterNames?.length
    );
  }

  checkForMCNTargetFilterAvailability() {
    return (
      !this.targetNetworksFilterData?.selectedFilterNames?.length &&
      !this.targetNetworksFilterData?.group?.selectedGroupNames?.length &&
      !this.appFilterData?.selectedFilterNames?.length &&
      !this.targetRegionsFilterData?.selectedFilterNames?.length
    );
  }

  checkForZTNASourceFilterAvailability() {
    return (
      !this.userFilterData?.selectedFilterNames?.length &&
      !this.userFilterData?.group?.selectedGroupNames?.length
    );
  }

  checkForZTNATargetFilterAvailability() {
    return (
      !this.appFilterData?.selectedFilterNames?.length &&
      !this.appFilterData?.group?.selectedGroupNames?.length &&
      !this.targetNetworksFilterData?.selectedFilterNames?.length &&
      !this.targetNetworksFilterData?.group?.selectedGroupNames?.length
    );
  }

  setActiveFilter(activeFilter: string) {
    this.filterService.setSelectedFilter(activeFilter);
  }

  formatName(inputText: string) {
    return inputText && inputText.length > 15
      ? `${inputText.substring(0, 15)} ...`
      : inputText;
  }

  updateFilterConfig(): void {
    switch (this.selectedMenuCode) {
      case INSIGHTS_MENU_OPTIONS.USER_RISK:
        this.appFilterData = Object.assign({}, this.appFilterData, {
          isEditable: false,
        });
        break;
      default:
        this.appFilterData = Object.assign({}, this.appFilterData, {
          isEditable: true,
        });
        break;
    }
    this.cdr.markForCheck();
  }

  customFilterChange(filter: Restrictions) {
    this.customFilters.emit(filter);
  }

  ngOnDestroy() {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }
}
