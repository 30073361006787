import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AppUtil } from '@app/common/util/app-util';
import { LightboxBtnType } from '@app/dashboard/diagnose/diagnostics/lightbox-form-template/lightbox-model';
import { NetworkOnboardService } from '@app/v2/onboard/network/network.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { OrchestratorService } from '@app/common/components/drawer/orchestrator/orchestrator.service';
import { Network, NetworkCreateAction, Policy } from '@app/common/models';
import {
  IOrchestratorComponent,
  NetworkAutoOnboardStep,
  NetworkOnboardStep,
} from '@app/common/components/drawer/orchestrator/orchestrator.model';
import { ModelFormGroup } from '@app/common/util/form-util';
import { ActionMessage, NetworkPolicy } from './policy.model';
import { PolicyControlState } from './policy.constants';
import {
  CustomizedSelectTriggerTextConfig,
  DeleteDialogComponent,
  LoaderService,
  SelectSearchConfig,
  ToastService,
} from '@prosimoio/components';
import { PDashLocalStoreUtil } from '@app/state/web-pdash.util';
import {
  APP_SETUP_STATUS,
  DIALOG_TOKEN,
  IMAGE_PATHS,
  LAST_UPDATED_TEXT,
  LOADER_CONFIG_TYPES,
  LoaderConfig,
  NETWORK_HOSTED_TYPE,
  NOTIFICATION_MESSAGES,
  POLICY_CONFLICT_MESSAGE,
  SUBSCRIPTION_TYPES,
} from '@app/common/util/constants';
import { ACCESS_POLICY_CONSTANTS } from '@app/v2/configure/policy/policy-library/policy-library.contants';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  DeleteAction,
  NetworkDeleteConfig,
  NetworkStatus,
} from '@app/v2/onboard/network/network.constants';
import { NetworkUtil } from '@app/v2/onboard/network/network.util';
import { OnboardTemplateService } from '@app/common/components/drawer/templates/onboard-template/onboard-template.service';
import {
  ICloudController,
  PrivateCloudController,
  PublicCloudController,
  OwnCloudController,
} from '@app/v2/onboard/network/shared/utils/controllers';
import { NetworkErrorStepMap } from '../../../../network.model';
import { ObjectUtil } from '@prosimoio/services';
import { LoginUtil } from '@app/login/login-util';
import { networkQuery } from '@app/v2/onboard/network/state';
import { NavConfirmationService } from '@app/common/util/nav-confirmation.service';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolicyComponent
  implements IOrchestratorComponent, OnInit, OnDestroy
{
  stopSubs$: Subject<void>;
  networkStatus: NetworkStatus;
  networkBackup: Network;
  previousStepsChanged: boolean = false;
  step = NetworkOnboardStep.POLICY;
  isStepValid = false;
  policyForm: ModelFormGroup<NetworkPolicy>;
  loading = {
    policies: this.getLoaderConfig(true, 'Loading Access Policies...'),
  };
  policyState: FormControl<PolicyControlState> = new FormControl(
    PolicyControlState.NONE
  );
  customizedSelectTriggerTextConfig = {
    isShow: true,
    all: 'All Policies',
    plural: 'Policies',
  } as CustomizedSelectTriggerTextConfig;
  styleConfig = {
    listHeight: '140px',
    dropdownWidth: '236px',
    panelWidth: '400px',
  };
  cloudController: ICloudController;
  selectedPoliciesListData = [];
  POLICY_COLUMN_DATA = ['access', 'name'];
  columnWidth = '150px';

  policies: Array<Policy> = [];
  defaultPolicies: Map<string, string>;
  selectSearchConfigForAccessPolicies: SelectSearchConfig;
  uiThemeMode: string = PDashLocalStoreUtil.getUIThemeMode().toLowerCase();
  tooltipThemeClass: string;
  errorIcon = IMAGE_PATHS.VISUAL_ONBOARD.ERROR;
  defaultHint =
    'If no policy is selected then access will be implicitly denied';
  hint = '';
  PolicyControlState = PolicyControlState;
  COPY_LOADER_CONFIG_TYPES = LOADER_CONFIG_TYPES;

  @Input() data: Network;
  @Input() errorPayload: any;

  constructor(
    private networkOnboardService: NetworkOnboardService,
    private orchestratorService: OrchestratorService,
    private onboardTemplateService: OnboardTemplateService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private navConfirmationService: NavConfirmationService,
    private appUtil: AppUtil,
    private loginUtil: LoginUtil,
    private cdr: ChangeDetectorRef
  ) {
    this.stopSubs$ = new Subject();
    this.defaultPolicies = new Map();
  }

  ngOnInit(): void {
    this.toastService.setUITheme(this.uiThemeMode + '-theme');
    this.networkBackup = networkQuery.getActiveNetwork();
    this.previousStepsChanged =
      this.networkBackup && !ObjectUtil.compare(this.data, this.networkBackup);
    this.networkStatus = NetworkUtil.getNetworkState(this.data);
    this.initForm();
    if (
      this.errorPayload &&
      this.errorPayload.errorStep === NetworkOnboardStep.POLICY
    ) {
      this.hint = this.errorPayload.errorMessage;
    } else {
      this.hint = this.defaultHint;
    }
    PDashLocalStoreUtil.getUIThemeModeAsObservable()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (uiThemeMode) => {
          this.uiThemeMode = uiThemeMode.toLowerCase();
          this.toastService.setUITheme(this.uiThemeMode + '-theme');
          this.tooltipThemeClass =
            'cdk-component-container--' + this.uiThemeMode + '-theme-dark';
        },
      });
    this.appUtil.updateDrawerButtonConfig({
      mainBtn: { ...LightboxBtnType.ONBOARD, disabled: true },
      secondaryBtn: { ...LightboxBtnType.SAVE, disabled: true },
      cancelBtn: LightboxBtnType.CANCEL,
    });
    this.appUtil
      .getDrawerResponse()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (response) => {
          if (
            response.code === LightboxBtnType.SAVE.code ||
            response.code === LightboxBtnType.ONBOARD.code ||
            response.code === LightboxBtnType.UPDATE.code
          ) {
            this.loaderService.setLoaderStatus(true);
            this.updateNetwork(response.code);
          } else {
            if (this.detectFormChanges()) {
              this.navConfirmationService.navConfirmationPopup(null);
              return;
            }
            this.appUtil.setDrawerState(false);
          }
        },
      });
    this.onboardTemplateService
      .getNameInput()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (name) => {
          this.policyForm.controls.name.patchValue(name);
        },
      });

    this.orchestratorService
      .getTabChangeRequest()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (step: NetworkAutoOnboardStep) => {
          const formData = <NetworkPolicy>this.policyForm.getRawValue();
          this.cloudController.updatePolicy(this.data, formData);
          this.orchestratorService.setGotoStop(step);
        },
      });
    this.setDrawerFooter();
    this.fetchPolicies();
    this.validateStep();
  }

  initForm() {
    const cloudType = this.getCloudType();
    this.setCloudController(cloudType);
    this.policyForm = new FormGroup({
      name: new FormControl(this.data.name, [Validators.required]),
      policies: new FormControl(
        this.data.security?.policies?.map((policy) => policy.id) || []
      ),
    });
    this.policyForm.valueChanges.pipe(takeUntil(this.stopSubs$)).subscribe({
      next: (value) => {
        this.validateStep();
      },
    });
  }

  fetchPolicies() {
    let policyTypes = [];
    let subscriptionTypes = this.loginUtil.getSubscriptionType();
    if (subscriptionTypes.includes(SUBSCRIPTION_TYPES.MCN)) {
      policyTypes.push(ACCESS_POLICY_CONSTANTS.TRANSIT);
    }
    if (subscriptionTypes.includes(SUBSCRIPTION_TYPES.ZTNA)) {
      policyTypes.push(ACCESS_POLICY_CONSTANTS.ACCESS);
    }
    this.fetchAccessPolicies(policyTypes);
  }

  // Access Policies

  fetchAccessPolicies(policyTypes: Array<string>) {
    this.networkOnboardService.getPolicies(policyTypes).subscribe({
      next: (policies) => {
        this.policies = policies;
        this.loading.policies = this.getLoaderConfig(
          this.policies.length === 0,
          !this.policies.length ? 'No Access Policies found' : '',
          !this.policies.length ? LOADER_CONFIG_TYPES.ERROR : ''
        );
        this.policies
          .filter((policy) => policy.type === 'default')
          .forEach((policy) => {
            this.defaultPolicies.set(policy.name, policy.id);
          });
        const currentPolicies = this.policyForm?.controls?.policies?.value;
        if (currentPolicies?.length > 0) {
          this.setPolicyList(currentPolicies);
          this.policyState?.setValue(PolicyControlState.CUSTOM);
        }
        this.setSelectSearchConfigForAccessPolicies();
        this.cdr.markForCheck();
      },
      error: (error) => {
        console.warn('Error Fetching Policies');
        this.policies = [];
        this.loading.policies = this.getLoaderConfig(
          true,
          'Error loading Access Policies',
          LOADER_CONFIG_TYPES.ERROR
        );
        this.cdr.markForCheck();
      },
    });
  }

  setSelectSearchConfigForAccessPolicies() {
    // filter conflicting policies
    const updatedPolicies = this.policies.map((policy) => {
      const hasConflict = this.appUtil.hasNetworksMatchConditionConflict(
        policy?.details?.matches
      )?.hasConflict;
      return {
        id: policy?.id,
        name: policy?.displayName,
        disabled: hasConflict,
      };
    });
    const disabledPolicies = updatedPolicies?.filter(
      (policy) => policy?.disabled
    );
    const enabledPolicies = updatedPolicies?.filter(
      (policy) => !policy?.disabled
    );
    const config: SelectSearchConfig = {
      optionList: [...enabledPolicies, ...disabledPolicies],
      placeholder: 'Select Policy',
      uiThemeMode: this.uiThemeMode + '-theme',
      showSearch: true,
      keyName: 'id',
      displayName: 'name',
      setKeyNameOnControl: true,
      isMultiSelection: true,
    };
    if (disabledPolicies?.length) {
      Object.assign(config, { hintMessage: POLICY_CONFLICT_MESSAGE });
    }
    this.selectSearchConfigForAccessPolicies = ObjectUtil.deepClone(config);
    this.cdr.markForCheck();
  }

  setPolicyList(currentPolicies: Array<string>) {
    this.selectedPoliciesListData = [];
    for (let policy of this.policies) {
      if (!currentPolicies?.includes(policy.id)) {
        continue;
      }
      this.selectedPoliciesListData.push({
        name: policy?.displayName,
        id: policy?.id,
        access: policy?.details?.actions.includes('allow') ? 'Allow' : 'Deny',
      });
    }
  }

  changePolicyState() {
    if (this.policyState?.value === PolicyControlState.NONE) {
      this.policyForm?.controls?.policies?.reset([]);
      this.setPolicyList([]);
    }
    this.cdr.markForCheck();
  }

  selectPolicy(policies: Array<any>) {
    this.setPolicyList(policies);
    this.cdr.markForCheck();
  }

  deletePolicy(policy: any) {
    const policies = this.policyForm?.value?.policies?.filter(
      (item) => item !== policy?.id
    );
    this.policyForm?.controls?.policies?.setValue(policies);
    this.setPolicyList(policies);
    this.cdr.markForCheck();
  }

  setDrawerFooter() {
    this.onboardTemplateService
      .getDeleteResponse()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (response) => {
          if (response.action === DeleteAction.DELETE) {
            this.deleteNetwork();
          } else if (response.action === DeleteAction.OFFBOARD) {
            this.offboardNetwork(response.confirmation);
          }
        },
      });

    if (this.data.createdTime) {
      this.onboardTemplateService.setDateInfo(
        `${LAST_UPDATED_TEXT}${this.appUtil.transformDateTime(
          this.data?.updatedTime
        )}`
      );
    }
    if (
      this.networkStatus !== NetworkStatus.NEW &&
      this.networkStatus !== NetworkStatus['IN-PROGRESS']
    ) {
      this.onboardTemplateService.setDeleteConfig({
        disabled: false,
        message: NetworkDeleteConfig[this.networkStatus].btn,
        tooltip: NetworkDeleteConfig[this.networkStatus].btn,
        panelConfig: {
          btnColorBlack: false,
          deleteMsg: NetworkDeleteConfig[this.networkStatus].panelMessage,
          deleteName: this.data.name,
          deleteButtonName:
            NetworkDeleteConfig[this.networkStatus].panelBtnName,
          action: NetworkDeleteConfig[this.networkStatus].action,
          confirmation: NetworkDeleteConfig[this.networkStatus].confirmation,
        },
      });
    }
  }

  setCloudController(cloudType: string): void {
    if (cloudType === NETWORK_HOSTED_TYPE.PUBLIC) {
      this.cloudController = new PublicCloudController(
        this.orchestratorService
      );
    } else if (cloudType === NETWORK_HOSTED_TYPE.PRIVATE) {
      this.cloudController = new PrivateCloudController(
        this.orchestratorService
      );
    } else if (cloudType === NETWORK_HOSTED_TYPE.OWN) {
      this.cloudController = new OwnCloudController(this.orchestratorService);
    }
  }

  getCloudType(): string {
    if (this.data.publicCloud?.cloudType) {
      return this.data.publicCloud?.cloudType;
    } else if (this.data.privateCloud?.cloudType) {
      return this.data.privateCloud?.cloudType;
    } else if (this.data.ownTransitCloud?.cloudType) {
      return this.data.ownTransitCloud?.cloudType;
    } else return NETWORK_HOSTED_TYPE.PUBLIC;
  }

  getLoaderConfig(status = false, message = '', type = ''): LoaderConfig {
    return {
      message,
      status,
      type,
    };
  }

  validateStep() {
    if (!this.policyForm.controls.name.value) {
      this.isStepValid = false;
    } else {
      const formData = <NetworkPolicy>this.policyForm.getRawValue();
      this.isStepValid = this.cloudController.validatePolicy(
        formData,
        this.defaultPolicies
      );
    }

    this.updateFooter();
    this.cdr.markForCheck();
  }

  /**
   * Update Drawer footer config
   * @param forceDisable Force buttons to be disabled regardless of form status
   */
  updateFooter(forceDisable: boolean = false) {
    if (
      this.networkStatus === NetworkStatus.NEW ||
      this.networkStatus === NetworkStatus.SAVED
    ) {
      this.appUtil.updateDrawerButtonConfig({
        mainBtn: {
          ...LightboxBtnType.ONBOARD,
          disabled: forceDisable || !this.isStepValid,
        },
        secondaryBtn: {
          ...LightboxBtnType.SAVE,
          disabled: forceDisable || !this.isStepValid,
        },
        cancelBtn: LightboxBtnType.CANCEL,
      });
    } else {
      this.appUtil.updateDrawerButtonConfig({
        mainBtn: {
          ...LightboxBtnType.UPDATE,
          disabled:
            forceDisable ||
            !(this.detectFormChanges() || this.previousStepsChanged) ||
            !this.isStepValid ||
            this.data.status !== APP_SETUP_STATUS.DEPLOYED,
        },
        cancelBtn: LightboxBtnType.CANCEL,
      });
    }
    this.cdr.markForCheck();
  }

  updateNetwork(workflow: string): void {
    this.validateStep();
    if (!this.isStepValid) {
      return;
    }

    this.updateFooter(true);

    const formData = <NetworkPolicy>this.policyForm.getRawValue();
    this.cloudController.updatePolicy(this.data, formData);

    let action: NetworkCreateAction;
    switch (workflow) {
      case LightboxBtnType.SAVE.code:
        action = NetworkCreateAction.SAVE;
        break;
      case LightboxBtnType.UPDATE.code:
        action = NetworkCreateAction.REDEPLOY;
        break;
      case LightboxBtnType.ONBOARD.code:
        action = NetworkCreateAction.DEPLOY;
        break;
      default:
        action = NetworkCreateAction.SAVE;
        break;
    }
    this.deployNetwork(action);
  }

  deployNetwork(action: NetworkCreateAction) {
    this.networkOnboardService
      .createNetwork(action, NetworkUtil.getNetworkCreatePayload(this.data))
      .subscribe({
        next: (resp: any) => {
          this.loaderService.setLoaderStatus(false);
          this.toastService.success(
            `Network ${
              !this.data.id && action === NetworkCreateAction.SAVE
                ? 'created'
                : ActionMessage[action]
            } successfully`
          );
          this.data.id = resp.id;
          this.markStepComplete();
        },
        error: (error) => {
          this.loaderService.setLoaderStatus(false);
          if (error?.error?.data && 'networkErrors' in error?.error?.data) {
            const networkErrors = error?.error?.data?.networkErrors;
            let errorState = NetworkUtil.getErrorField(networkErrors);
            let errorStep = NetworkErrorStepMap[errorState?.field];
            this.orchestratorService.setErrorState({
              errorStep,
              errorObject: networkErrors,
              errorMessage: errorState?.message,
              index: errorState?.index || 0,
            });
            this.orchestratorService.setGotoStop(errorStep);
            // TODO: error state gets reset after routing to step which has error
            if (!networkErrors?.name?.valid) {
              setTimeout(() => {
                this.onboardTemplateService.setNameState({
                  error: errorState?.message,
                });
              }, 0);
            }
            this.cdr.markForCheck();
          } else {
            this.showErrorMessage(
              {
                errorTitle: 'Failed',
                errorMsg:
                  error?.error?.message ||
                  'Network creation failed. Please try again.',
              },
              () => {}
            );
          }
        },
      });
  }

  deleteNetwork() {
    if (!this.data.id) {
      return;
    }

    this.networkOnboardService.deleteNetwork(this.data.id).subscribe({
      next: (resp) => {
        this.toastService.success(
          NOTIFICATION_MESSAGES.DELETE.SUCCESS(this.data.name)
        );
        this.appUtil.setDynamicDrawerResponse({
          action: 'delete',
          context: NetworkOnboardStep.DEFINE,
          payload: null,
        });
        this.appUtil.setDrawerState(false);
      },
      error: (error) => {
        this.showErrorMessage(
          {
            errorMsg:
              error?.error?.message ||
              NOTIFICATION_MESSAGES.DELETE.FAIL(this.data.name),
          },
          () => {
            this.appUtil.setDrawerState(false);
          }
        );
      },
    });
  }

  offboardNetwork(forced: boolean) {
    if (!this.data.id) {
      return;
    }
    if (this.networkStatus !== NetworkStatus.DEPLOYED) {
      return;
    }

    this.networkOnboardService.offboardNetwork(this.data.id, forced).subscribe({
      next: (resp) => {
        this.toastService.success(
          NOTIFICATION_MESSAGES.OFFBOARD.SUCCESS(this.data.name)
        );
        this.appUtil.setDynamicDrawerResponse({
          action: 'offboard',
          context: NetworkOnboardStep.DEFINE,
          payload: null,
        });
        this.appUtil.setDrawerState(false);
      },
      error: (error) => {
        this.showErrorMessage(
          {
            errorMsg:
              error?.error?.message ||
              NOTIFICATION_MESSAGES.OFFBOARD.FAIL(this.data.name),
          },
          () => {
            this.appUtil.setDrawerState(false);
          }
        );
      },
    });
  }

  detectFormChanges(): boolean {
    const formData = <NetworkPolicy>this.policyForm.getRawValue();
    const changes = this.cloudController.detectPolicyFormChanges(
      this.data,
      formData
    );
    this.navConfirmationService.updateNavData({
      check: changes,
      elem: null,
    });
    return changes;
  }

  markStepComplete() {
    this.orchestratorService.setStepComplete(NetworkOnboardStep.POLICY);
  }

  showErrorMessage(popupConfig, action: (...args: any[]) => void) {
    const dialogData = {
      ...popupConfig,
      isInnerHTML: true,
    };

    const injector = this.appUtil?.createInjector(
      DIALOG_TOKEN.INJECTOR_TOKEN_DATA.DIALOG_DATA_TOKEN.NAME,
      dialogData
    );

    const componentRef = this.appUtil?.initializeDeleteOverlay(
      DeleteDialogComponent,
      injector,
      this.uiThemeMode + '-theme',
      '340px'
    );
    (componentRef.instance as DeleteDialogComponent)?.closeDialogEvent
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          action(response);
        },
        error: (err) => {
          console.warn(err);
        },
        complete: () => {
          this.appUtil?.destroyOverlay();
        },
      });
  }

  get policiesControl() {
    return this.policyForm.get('policies');
  }

  ngOnDestroy(): void {
    this.stopSubs$.next();
    this.stopSubs$.complete();
    this.navConfirmationService.updateNavData(null);
  }
}
