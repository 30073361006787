import { Injectable } from '@angular/core';
import { IMAGE_PATHS, UI_THEME_MODES } from '@app/common/util/constants';
import { PDashLocalStoreUtil } from '@app/state/web-pdash.util';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { NavigationBarModel } from './navigation-bar.config';

@Injectable({
  providedIn: 'root',
})
export class NavigationBarService {
  private navigationConfig$ = new BehaviorSubject<NavigationBarModel>(
    new NavigationBarModel()
  );
  private navigationBarReadOnlyState$ = new BehaviorSubject<boolean>(false);
  private navigationDisplayState$ = new BehaviorSubject<boolean>(false);
  private navigationExpandState$ = new Subject<boolean>();
  selectedUIThemeMode = '';
  constructor() {
    this.selectedUIThemeMode = PDashLocalStoreUtil.getUIThemeMode();
  }

  setNavigationBarConfig(config: NavigationBarModel, uiThemeMode?: string) {
    if (uiThemeMode && config) {
      if (
        uiThemeMode === UI_THEME_MODES.DARK &&
        config?.logo?.imgUrl.includes('prosimo-main')
      ) {
        config.logo.imgUrl = IMAGE_PATHS.LOGO.MAIN_WHITE;
      } else {
        config.logo.imgUrl = IMAGE_PATHS.LOGO.MAIN;
      }
      this.navigationConfig$.next(config);
    }
  }

  setNavigationBarReadOnlyState(readOnly: boolean = false) {
    this.navigationBarReadOnlyState$.next(readOnly);
  }

  displayNavigationBar(state: boolean) {
    this.navigationDisplayState$.next(state);
  }

  getNavigationBarConfig() {
    return this.navigationConfig$.asObservable();
  }

  getNavigationDisplayState() {
    return this.navigationDisplayState$.asObservable();
  }

  getNavigationBarReadOnlyState() {
    return this.navigationBarReadOnlyState$.asObservable();
  }

  setNavigationExpandState(state: boolean) {
    this.navigationExpandState$?.next(state);
  }

  getNavigationExpandState(): Observable<boolean> {
    return this.navigationExpandState$.asObservable();
  }
}
