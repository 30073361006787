// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { creds } from './creds';
import { ENV_CONSTANTS } from './env.constants';

export const environment = {
  production: false,
  name: 'default',
  type: 'DEV',
};

if (creds?.length) {
  // Check for host in Local Storage
  const storedCred = localStorage.getItem('host');

  // Look for stored host in credential list. If not found, select first entry in credential list
  const configCred = creds.find((cred) => cred.host === storedCred) || creds[0];

  // If no host is stored or stored host is not in credential list,
  // update Local Storage with first entry in credential list
  if (!storedCred || configCred.host !== storedCred) {
    localStorage.setItem('host', configCred.host);
  }

  // Set host and token in Environment variables.
  ENV_CONSTANTS.DASHBOARD_HOST = configCred?.host;
  ENV_CONSTANTS.DASHBOARD_API_TOKEN = configCred?.token;
}

export const CONSTANTS = ENV_CONSTANTS;
export const USER_BEHAVIOR_INSIGHTS_APP_ID = '2104289635';

export const GENERATE_INFRA_AWS_KEYS = `https://console.aws.amazon.com/cloudformation/home?#/stacks/new?stackName=prosimoRoleStack&templateURL=https://customer-cloudformation-template.s3-us-west-2.amazonaws.com/customerCloudFormationDev.json`;
export const GENERATE_APP_AWS_KEYS = `https://console.aws.amazon.com/cloudformation/home?#/stacks/new?stackName=prosimoAppRoleStack&templateURL=https://customer-cloudformation-template.s3-us-west-2.amazonaws.com/customerAppCloudFormationDev.json`;
export const AWS_CUSTOMER_INFORMATION = `https://customer-cloudformation-template.s3-us-west-2.amazonaws.com/customerAppCloudFormationDev.json`;
export const AWS_CUSTOMER_LEARN_MORE = `https://docs.aws.amazon.com/AWSCloudFormation/latest/UserGuide/stacksets-concepts.html?icmpid=docs_cfn_console#stacksets-concepts-stackset`;
export const INFRA_TAG_LINK = `https://help.prosimo.io/hc/en-us/articles/27210092087949-Prosimo-Infra-Tags#h_01HZ95E2ZXSYR79ETC9KT8DXG2`;

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
