import { Store, StoreConfig } from '@datorama/akita';
import { TimeRange } from '../../shared/calendar.component';
import { TeamUser } from '@app/dashboard/shared/filter-list/client-list/client-list.model';
import { OnboardedApp } from '@app/dashboard/shared/filter-list/app-list/apps-list.model';
import { DEBUGGING_TOOLS } from '@app/dashboard/diagnose/diagnose.model';

export interface AccessibilityDetails {
  selectedEntity: any;
  selectedApp: any;
  selectedEdge: any;
  selectedSegment: any;
  selectedAppFQDN: string;
  selectedCountry: Array<any>;
  selectedStates: Array<any>;
  viewType: string;
}

export type DiagnosticTestDetails = {
  [key in keyof typeof DEBUGGING_TOOLS]: {
    testID: string;
    parameters: any;
  };
};

export interface DiagnoseSharedFilterState {
  selectedTeamUsers?: Array<TeamUser>;
  selectedOnboardedApps?: Array<OnboardedApp>;
  selectedNetworks: Array<any>;
  timeRange?: TimeRange;
  diagnoseTimeRange?: TimeRange;
  activeTab?: string;
  activeSubMenu?: string;
  isDataNew?: boolean;
  totalUsers: number;
  totalApps: number;
  accessibilityDetails?: AccessibilityDetails;
  diagnosticTestDetails?: DiagnosticTestDetails;
}

export function createInitialState(): DiagnoseSharedFilterState {
  return {
    selectedTeamUsers: [],
    selectedOnboardedApps: [],
    selectedNetworks: [],
    timeRange: null,
    activeTab: '',
    activeSubMenu: '',
    isDataNew: true,
    totalUsers: null,
    totalApps: null,
    accessibilityDetails: {
      selectedEntity: null,
      selectedApp: null,
      selectedEdge: null,
      selectedSegment: null,
      selectedAppFQDN: null,
      selectedCountry: null,
      selectedStates: null,
      viewType: null,
    },
    diagnosticTestDetails: {
      PING: {
        testID: '',
        parameters: {},
      },
      DIG: {
        testID: '',
        parameters: {},
      },
      TRACEROUTE: {
        testID: '',
        parameters: {},
      },
      NETCAT: {
        testID: '',
        parameters: {},
      },
      CURL: {
        testID: '',
        parameters: {},
      },
      TCPDUMP: {
        testID: '',
        parameters: {},
      },
      HEALTHCHECK: {
        testID: '',
        parameters: {},
      },
      CLOUDTRACER: {
        testID: '',
        parameters: {},
      },
      ROUTETABLES: {
        testID: '',
        parameters: {},
      },
    },
  };
}

@StoreConfig({ name: 'diagnose', resettable: true })
export class DiagnoseSharedFilterStore extends Store<DiagnoseSharedFilterState> {
  constructor() {
    super(createInitialState());
  }
}

export const diagnoseSharedFilterStore = new DiagnoseSharedFilterStore();
