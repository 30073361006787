<div id="feedback-form__container">
  <div
    class="d-flex align-items-center justify-content-around p-2 mx-auto contact-admin-btn"
    (click)="togglePopup()"
    [ngClass]="{ invisible: displayForm }"
  >
    <div class="col-0" [ngClass]="{ 'd-none': !iconURL }">
      <img class="contact-admin-btn-img" [src]="iconURL" alt="admin-icon" />
    </div>
    <div class="col-0 contact-admin-btn-txt">Contact Prosimo</div>
  </div>
  <!-- end of popup button -->
  <ng-container *ngIf="displayForm">
    <div class="position-absolute popup-container d-flex flex-column">
      <div class="col-0 p-3 text-center position-relative pop-up-header">
        <span class="pop-up-header-title"
          >Hi What can we help you with today?</span
        >
        <i class="material-icons popup-close-btn" (click)="onCancel()">close</i>
      </div>
      <div class="col p-3 popup-content d-flex flex-column align-items-center">
        <div
          class="d-flex align-items-center justify-content-start mb-2 submit-type-container"
        >
          <ng-container *ngFor="let type of SUBMIT_TYPES">
            <div
              class="btn mr-1 btn-type"
              (click)="onSelectSubmitType($event, type.value)"
              [ngClass]="{ selected: selectedSubmitType === type.value }"
            >
              {{ type.displayName }}
            </div>
          </ng-container>
        </div>
        <form
          class="submit-form"
          *ngIf="submitForm"
          [formGroup]="submitForm"
          autocomplete="off"
        >
          <div class="mb-2">
            <mat-form-field class="contain w-100">
              <div class="form__field-label mb-2">Topic</div>
              <mat-select formControlName="topics" multiple disableRipple>
                <mat-select-trigger>
                  <span class="selected__txt">
                    {{ submitForm?.value?.topics?.length }}
                    {{
                      submitForm?.value?.topics?.length > 1 ? "Topics" : "Topic"
                    }}
                    selected
                  </span>
                </mat-select-trigger>
                <mat-option
                  class="checkbox-option"
                  *ngFor="let option of topics"
                  [value]="option"
                >
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div>{{ option }}</div>
                  </div>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="contain w-100">
              <div class="form__field-label mb-2">Description</div>
              <input matInput formControlName="comment" autocomplete="off" />
            </mat-form-field>
          </div>
        </form>
        <div
          class="response-msg"
          [ngClass]="{ success: requestStatus === STATUSES.SUCCESS }"
        >
          {{ responseMsg }}
        </div>
      </div>
      <div
        class="col-0 pr-3 pl-3 pb-3 mb-2 d-flex align-items-center justify-content-center popup-footer"
      >
        <div class="btn mr-2 popup-btn cancel" (click)="onCancel()">Cancel</div>
        <div class="btn ml-2 popup-btn submit" (click)="onSubmit()">Submit</div>
      </div>
    </div>
  </ng-container>
  <!-- end of popup submit form -->
</div>
