import { Day0, PDashStore, pDashStore } from './web-pdash.store';
import { PageConfig, UI_THEME_MODES } from '@app/common/util/constants';

export class PDashService {
  constructor(private pdashStore: PDashStore) {}

  updatePageInfo(pageInfo: PageConfig) {
    this.pdashStore.update((state) => ({
      pageInfo,
    }));
  }

  updateHelpConfig(helpConfig: any) {
    this.pdashStore.update((state) => ({
      helpConfig,
    }));
  }

  updateRegionConfig(regionConfig: Array<any>) {
    this.pdashStore.update((state) => ({
      regionConfig,
    }));
  }

  updateRequestedUrl(requestedUrl: string) {
    this.pdashStore.update((state) => ({
      requestedUrl,
    }));
  }

  updateIsInteractiveGuideOn(isInteractiveGuideOn: boolean) {
    this.pdashStore.update((state) => ({
      isInteractiveGuideOn,
    }));
  }

  updateIsPDFPrintView(isPDFPrintView: boolean) {
    this.pdashStore.update((state) => ({
      isPDFPrintView,
    }));
  }

  updateProductViewType(productViewType: string) {
    this.pdashStore.update((state) => ({
      productViewType,
    }));
  }

  updateUIThemeMode(uiThemeMode: UI_THEME_MODES) {
    this.pdashStore.update((state) => ({
      uiThemeMode,
    }));
  }

  updateDay0Details(day0Details: Day0) {
    this.pdashStore.update((state) => ({
      day0: day0Details,
    }));
  }
}

export const pDashService = new PDashService(pDashStore);
