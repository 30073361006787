import { Pipe, PipeTransform } from '@angular/core';
import { AppUtil } from '@app/common/util/app-util';
import { DATA_MULTIPLES } from '@app/common/util/constants';

/**
 * This pipe used to convert data size to it bytes format
 * This conversion uses DATA_MULTIPLES.BASE_10 - (1000)
 * All supported units are 'Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'.
 */
@Pipe({
  name: 'dataSize',
})
export class DataSizePipe implements PipeTransform {
  constructor(private appUtil: AppUtil) {}

  transform(
    value: number = 0,
    decimals: number = 3,
    split: boolean = false,
    unitMultiple = DATA_MULTIPLES.BASE_10
  ): Array<string> | string {
    const transformedData = this.appUtil.dataSizeConverter(
      value,
      decimals,
      unitMultiple
    );
    if (split) {
      return transformedData.split(' ');
    }

    return transformedData;
  }
}
