import { USER_TYPES } from '@app/common/util/constants';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivate,
} from '@angular/router';
import { AuthService } from './auth.service';
import { map, take, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { URL_PATHS } from '../../constants';

@Injectable({
  providedIn: 'root',
})
export class AdminAuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.authService.getAuthTokenStatus().pipe(
      take(1),
      catchError(() => {
        this.routeTo(URL_PATHS.LOGIN);
        return of(false);
      }),
      map((data = {}) => {
        if (data.type === USER_TYPES.PROSIMO_ADMIN) {
          return true;
        } else {
          this.routeTo(URL_PATHS.PAGE_NOT_FOUND);
          return false;
        }
      })
    );
  }

  routeTo(routeURL: string = URL_PATHS.LOGIN) {
    this.router.navigate([routeURL]);
  }
}
