<div id="status-dialog__container" class="bg-light-shade">
  <!-- Confirmation Dialog Box -->
  <div
    class="shadow-sm rounded confirm__card"
    *ngIf="statusAction === DIALOG_ACTION_TYPE.CONFIRM"
  >
    <div>
      <img class="img__logo" [src]="prosimoLogoURL" />
    </div>
    <div class="status__message">
      <p class="p-3 clr-grey7" [innerHTML]="statusMessage | safe : 'html'"></p>
    </div>
    <div *ngIf="showLoader" class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div *ngIf="statusActionButtons?.length">
      <span
        class="close__sign grey"
        matTooltip="Close..."
        (click)="onDialogButtonAction(CLOSE, true)"
        >&times;</span
      >
    </div>
    <div class="action__controls">
      <div class="my-auto mx-auto">
        <button
          *ngFor="let button of statusActionButtons"
          [ngClass]="dialogButtonStyle(button)"
          type="button"
          (click)="onDialogButtonAction(button)"
        >
          {{ button.name }}
        </button>
      </div>
    </div>
  </div>
  <!-- Success Dialog Box -->
  <div
    class="shadow-sm rounded success__card"
    *ngIf="
      statusAction === DIALOG_ACTION_TYPE.SUCCESS ||
      statusAction === DIALOG_ACTION_TYPE.ERROR
    "
  >
    <div class="icon">
      <mat-icon
        *ngIf="statusAction === DIALOG_ACTION_TYPE.SUCCESS"
        class="mat-icon-green"
        >check_circle</mat-icon
      >
      <mat-icon
        *ngIf="statusAction === DIALOG_ACTION_TYPE.ERROR"
        class="mat-icon-red"
        >error</mat-icon
      >
    </div>
    <div
      class="success__text px-3"
      [ngClass]="{ error__text: statusAction === DIALOG_ACTION_TYPE.ERROR }"
    >
      <p [innerHTML]="statusMessage | safe : 'html'"></p>
    </div>
    <div class="close__sign">
      <span
        class="grey"
        matTooltip="Close..."
        (click)="onDialogButtonAction(CLOSE)"
        >&times;</span
      >
    </div>
  </div>

  <!--Token Dialog Box-->
  <div class="shadow-sm rounded copy-data__card" *ngIf="copyDataConfig">
    <div class="copy-data-title mt-3">
      {{ copyDataConfig?.message?.copyStatusHeader }}
    </div>
    <div class="copy-data-message mt-4">
      <div>{{ copyDataConfig?.message?.copyStatusMsg1 }}</div>
      <div>{{ copyDataConfig?.message?.copyStatusMsg2 }}</div>
    </div>
    <div class="copy-data-box d-flex mr-3 ml-3 mt-3">
      <div
        class="copy-data d-flex align-items-center justify-content-center h-100"
      >
        <span class="p-2">
          {{ copyDataConfig?.copyData }}
        </span>
      </div>
      <div
        class="copy-icon d-flex align-items-center justify-content-center h-100"
      >
        <span>
          <i
            class="material-icons"
            [cdkCopyToClipboard]="copyDataConfig?.copyData"
            matTooltip="Copy"
            matTooltipPosition="above"
          >
            content_copy
          </i>
        </span>
      </div>
    </div>
    <div>
      <span
        class="close__sign grey"
        matTooltip="Close..."
        (click)="onDialogButtonAction(CLOSE)"
        >&times;</span
      >
    </div>
    <div class="action__controls mt-2">
      <div class="my-auto mx-auto">
        <button
          *ngFor="let button of statusActionButtons"
          [ngClass]="dialogButtonStyle(button)"
          type="button"
          (click)="onDialogButtonAction(button)"
        >
          {{ button.name }}
        </button>
      </div>
    </div>
  </div>
</div>
