import { Store, StoreConfig } from '@datorama/akita';

export interface ManagementState {
  activeTab: string;
  activeSubMenu: string;
  previewImageUrl: string;
  activeFilter: string;
}

export function createInitialState(): ManagementState {
  return {
    activeTab: '',
    activeSubMenu: '',
    previewImageUrl: '',
    activeFilter: '',
  };
}

@StoreConfig({ name: 'management', resettable: true })
export class ManagementStore extends Store<ManagementState> {
  constructor() {
    super(createInitialState());
  }
}

export const managementStore = new ManagementStore();
