import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ModelFormGroup } from '@app/common/util/form-util';

import { Restrictions } from './playground-filter.model';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { playgroundFilterQuery } from '@app/dashboard/state/playground-filters';

@Component({
  selector: 'app-playground-filter',
  templateUrl: './playground-filter.component.html',
  styleUrls: ['./playground-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlaygroundFilterComponent implements OnInit, OnDestroy {
  subscriptions: Array<Subscription> = [];

  restrictions: ModelFormGroup<Restrictions>;

  @Output() filterChange = new EventEmitter<Restrictions>();

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    const localstoreData = playgroundFilterQuery.getValue().restrictions;
    this.restrictions = this.fb.group({
      rContinent: this.fb.control(localstoreData.rContinent),
      rCountry: this.fb.control(localstoreData.rCountry),
      rCSP: this.fb.control(localstoreData.rCSP),
      rRegion: this.fb.control(localstoreData.rRegion),
    });
    this.subscriptions.push(
      this.restrictions.valueChanges.subscribe({
        next: (restrictions: Restrictions) => {
          this.filterChange.emit(restrictions);
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
