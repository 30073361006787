import { Pipe, PipeTransform } from '@angular/core';
import { CLOUD_TYPES } from '@app/common/util/constants';

@Pipe({
  name: 'pcName',
  standalone: true,
})
export class PCNamePipe implements PipeTransform {
  transform(value: string, csp: string): string {
    switch (csp) {
      case CLOUD_TYPES.AZURE:
      case CLOUD_TYPES.GCP:
        return value.split('/').pop();
      case CLOUD_TYPES.AWS:
      default:
        return value;
    }
  }
}
