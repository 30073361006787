import { APP_INTERACTION_TYPES_KEY } from '@app/common/util/constants';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isInteractionTypeAppToApp',
})
export class IsInteractionTypeAppToAppPipe implements PipeTransform {
  transform(data: any): boolean {
    if (data && data === APP_INTERACTION_TYPES_KEY.APP_TO_APP) {
      return true;
    } else {
      return false;
    }
  }
}
