import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PasswordRulesStatus } from './password-rule.model';

@Injectable({
  providedIn: 'root',
})
export class PasswordRuleService {
  private passwordRulesStatus$ = new BehaviorSubject<PasswordRulesStatus>({
    digit: false,
    lower: false,
    upper: false,
  } as PasswordRulesStatus);

  constructor() {}

  setPasswordRulesStatus(passwordText: string) {
    this.passwordRulesStatus$.next({
      digit: /\d/.test(passwordText),
      lower: /[a-z]/.test(passwordText),
      upper: /[A-Z]/.test(passwordText),
    });
  }

  getPasswordRulesStatus(): Observable<PasswordRulesStatus> {
    return this.passwordRulesStatus$.asObservable();
  }
}
