import { Store, StoreConfig } from '@datorama/akita';

export interface AgentState {
  activeTab: string;
}

export function createInitialState(): AgentState {
  return {
    activeTab: '',
  };
}

@StoreConfig({ name: 'agent' })
export class AgentStore extends Store<AgentState> {
  constructor() {
    super(createInitialState());
  }
}

export const agentStore = new AgentStore();
