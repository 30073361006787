import { Pipe, PipeTransform } from '@angular/core';
import { MidmileLogs } from '@app/v2/transit-360/transit-360.models';

@Pipe({
  name: 'segmentHealth',
})
export class SegmentHealthPipe implements PipeTransform {
  transform(segment: MidmileLogs) {
    if (segment && segment?.badLink) {
      return 'Segment Down';
    } else if (segment && segment?.badLoss && segment?.badRTT) {
      return 'Packet Loss and High Latency';
    } else if (segment && segment?.badLoss) {
      return 'Packet Loss';
    } else if (segment && segment?.badRTT) {
      return 'High Latency';
    } else if (!segment.badLink && !segment.badLoss && !segment.badRTT) {
      return 'Healthy Segment';
    }
  }
}
