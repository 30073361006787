import { CONSTANTS } from '../../environments/environment';
import {
  IMAGE_PATHS,
  LAST_UPDATED_TEXT,
  MANAGEMENT_MENU_OPTIONS,
} from '../common/util/constants';
import { ManagementMenuItem } from './management.model';

/**
 * Edge Management header config
 */

export const VALIDATION_ERROR_AND_MESSAGE = {
  GROUPINGS: {
    USER_GROUP: {
      emailExists: 'Email already exists.',
      pattern: 'Email is invalid.',
    },

    NETWORK_GROUP: {
      networks: [{ type: 'required', message: 'Networks is required.' }],
    },
    NAMESPACE: {
      networks: [{ type: 'required', message: 'Namespace is required.' }],
    },
  },
};

/**
 * Certificate Management header config
 */
export const CERT_HEADER_CONFIG = [
  {
    name: '',
    rowSize: '1',
  },
  {
    name: 'Status',
    rowSize: '1',
  },
  {
    name: 'Common Name',
    rowSize: '2',
  },
  {
    name: 'Certificate Authority',
    rowSize: '2',
  },
  {
    name: 'Certificate Type',
    rowSize: '1',
  },
  {
    name: 'Generated By',
    rowSize: '1',
  },
  {
    name: 'Expiration Time',
    rowSize: '2',
  },
  {
    name: 'Details',
    rowSize: '1',
  },
  {
    name: '',
    rowSize: '1',
  },
];

/**
 * Alerts Management header config
 */
export const ALERTS_HEADER_CONFIG = [
  {
    name: '',
    rowSize: '1',
  },
  {
    name: 'Status',
    rowSize: '1',
  },
  {
    name: 'Category',
    rowSize: '2',
  },
  {
    name: 'Type',
    rowSize: '2',
  },
  {
    name: 'Notification Channels',
    rowSize: '3',
  },
  {
    name: 'Description',
    rowSize: '3',
  },
];

/**
 * Notification Channel Management header config
 */
export const NOTIFICATION_CHANNEL_HEADER_CONFIG = [
  {
    name: '',
    rowSize: '1',
  },
  {
    name: 'Name',
    rowSize: '3',
  },
  {
    name: 'Type',
    rowSize: '3',
  },
  {
    name: 'Configuration',
    rowSize: '5',
  },
];

/**
 * EDR Integration header config
 */
export const EDR_INTEGRATION_HEADER_CONFIG = [
  {
    name: '',
    rowSize: '1',
  },
  {
    name: 'Status',
    rowSize: '1',
  },
  {
    name: 'EDR Service',
    rowSize: '2',
  },
  {
    name: 'Nickname',
    rowSize: '2',
  },
  {
    name: 'Credentials',
    rowSize: '5',
  },
];

/** Start: Management Landing Page Cards. */
export const MANAGEMENT_CARD_CLOUDS = {
  name: 'Clouds',
  isSelected: false,
  code: MANAGEMENT_MENU_OPTIONS.CLOUD,
  order: 1,
  imgUrl: IMAGE_PATHS.MANAGEMENT.CLOUD,
  selectedImgUrl: IMAGE_PATHS.MANAGEMENT.CLOUD_SELECTED,
  routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_CLOUD,
  contents: [],
  description: 'Create and manage public and private cloud accounts',
  isEnabled: true,
} as ManagementMenuItem;

export const MANAGEMENT_CARD_EDGES = {
  name: 'Edges',
  isSelected: false,
  selectedImgUrl: IMAGE_PATHS.MANAGEMENT.EDGE_SELECTED,
  code: MANAGEMENT_MENU_OPTIONS.EDGE,
  order: 2,
  imgUrl: IMAGE_PATHS.MANAGEMENT.EDGE,
  routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_EDGE,
  contents: [],
  description:
    'Create and manage edges, and set up connectivity for public cloud regions',
  isEnabled: true,
} as ManagementMenuItem;

export const MANAGEMENT_CARD_ADMIN = {
  name: 'Admins',
  isSelected: false,
  code: MANAGEMENT_MENU_OPTIONS.ADMIN,
  order: 3,
  imgUrl: IMAGE_PATHS.MANAGEMENT.ADMIN,
  selectedImgUrl: IMAGE_PATHS.MANAGEMENT.ADMIN_SELECTED,
  routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_ADMIN,
  contents: ['users', 'usergroups', 'roles'],
  description: 'Create and manage users, groups, roles and access privileges',
  isEnabled: true,
} as ManagementMenuItem;

export const MANAGEMENT_CARD_CONNECTOR_GROUPS = {
  name: 'Connector Groups',
  isSelected: false,
  selectedImgUrl: IMAGE_PATHS.MANAGEMENT.CONNECTOR_GROUPS_SELECTED,
  code: MANAGEMENT_MENU_OPTIONS.CONNECTOR_GROUPS,
  order: 4,
  imgUrl: IMAGE_PATHS.MANAGEMENT.CONNECTOR_GROUPS,
  routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_CONNECTOR_GROUPS,
  contents: [],
  description:
    'Create and manage public and private cloud connector groups for MCN connectivity',
  isEnabled: true,
} as ManagementMenuItem;

export const MANAGEMENT_CARD_ALERTS = {
  name: 'Alerts',
  selectedImgUrl: IMAGE_PATHS.MANAGEMENT.ALERTS_SELECTED,
  isSelected: false,
  code: MANAGEMENT_MENU_OPTIONS.ALERT,
  order: 5,
  imgUrl: IMAGE_PATHS.MANAGEMENT.ALERTS,
  routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_ALERT,
  contents: [],
  description: 'Manage notification channels for various system alerts',
  isEnabled: true,
} as ManagementMenuItem;

export const MANAGEMENT_CARD_IDP = {
  name: 'IDP',
  isSelected: false,
  selectedImgUrl: IMAGE_PATHS.MANAGEMENT.IDP_SELECTED,
  code: MANAGEMENT_MENU_OPTIONS.IDP,
  order: 6,
  imgUrl: IMAGE_PATHS.MANAGEMENT.IDP,
  routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_IDP,
  contents: [],
  description: 'Manage the IDPs for authentication',
  isEnabled: true,
} as ManagementMenuItem;

export const MANAGEMENT_CARD_CERTIFICATES = {
  name: 'Certificates',
  selectedImgUrl: IMAGE_PATHS.MANAGEMENT.CERTIFICATES_SELECTED,
  isSelected: false,
  code: MANAGEMENT_MENU_OPTIONS.CERTIFICATES,
  order: 7,
  imgUrl: IMAGE_PATHS.MANAGEMENT.CERTIFICATES,
  routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_CERTIFICATES,
  contents: [],
  description: 'Create and manage certificates',
  isEnabled: true,
} as ManagementMenuItem;

export const MANAGEMENT_CARD_API_TOKENS = {
  name: 'API Tokens',
  selectedImgUrl: IMAGE_PATHS.MANAGEMENT.API_TOKENS_SELECTED,
  isSelected: false,
  code: MANAGEMENT_MENU_OPTIONS.API_TOKENS,
  order: 8,
  imgUrl: IMAGE_PATHS.MANAGEMENT.API_TOKENS,
  routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_API_TOKENS,
  contents: [],
  description:
    'Generate and manage role-based API auth tokens for northbound integrations',
  isEnabled: true,
} as ManagementMenuItem;

export const MANAGEMENT_CARD_GROUPINGS = {
  name: 'Groupings',
  isSelected: false,
  selectedImgUrl: IMAGE_PATHS.MANAGEMENT.GROUPINGS_SELECTED,
  code: MANAGEMENT_MENU_OPTIONS.GROUPINGS,
  order: 9,
  imgUrl: IMAGE_PATHS.MANAGEMENT.GROUPINGS,
  routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_GROUPINGS,
  contents: ['groups'],
  description: 'Manage various groupings to use in policy definition',
  isEnabled: true,
} as ManagementMenuItem;

export const MANAGEMENT_CARD_AGENT_INTEGRATION = {
  name: 'Agent',
  selectedImgUrl: IMAGE_PATHS.MANAGEMENT.LOGS_SELECTED,
  isSelected: false,
  code: MANAGEMENT_MENU_OPTIONS.AGENT_INTEGRATION,
  order: 11,
  imgUrl: IMAGE_PATHS.MANAGEMENT.LOGS,
  routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_AGENT_INTEGRATION,
  contents: [],
  description: 'Manage support settings for Prosimo Device agent',
  isEnabled: true,
} as ManagementMenuItem;

export const MANAGEMENT_CARD_LOG_AND_RECEIVER = {
  name: 'Log & Receiver',
  selectedImgUrl: IMAGE_PATHS.MANAGEMENT.LOGS_SELECTED,
  isSelected: false,
  code: MANAGEMENT_MENU_OPTIONS.LOG_RECEIVER,
  order: 12,
  imgUrl: IMAGE_PATHS.MANAGEMENT.LOGS,
  routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_LOGS,
  contents: [],
  description:
    'Manage and create receiver connections and tokens to receive logs',
  isEnabled: true,
} as ManagementMenuItem;

export const MANAGEMENT_CARD_ERROR_PAGES = {
  name: 'Error Pages',
  selectedImgUrl: IMAGE_PATHS.MANAGEMENT.ERROR_PAGE_SELECTED,
  isSelected: false,
  code: MANAGEMENT_MENU_OPTIONS.ERROR_PAGES,
  order: 13,
  imgUrl: IMAGE_PATHS.MANAGEMENT.ERROR_PAGE,
  routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_ERROR_PAGES,
  contents: [],
  description: 'Customize error notification pages',
  isEnabled: true,
} as ManagementMenuItem;

export const MANAGEMENT_CARD_DOWNLOADS = {
  name: 'Downloads',
  selectedImgUrl: IMAGE_PATHS.MANAGEMENT.DOWNLOADS_SELECTED,
  isSelected: false,
  code: MANAGEMENT_MENU_OPTIONS.DOWNLOADS,
  order: 14,
  imgUrl: IMAGE_PATHS.MANAGEMENT.DOWNLOADS,
  routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_DOWNLOADS,
  contents: [],
  description: 'Download connector, agent and terraform binaries',
  isEnabled: true,
} as ManagementMenuItem;

export const MANAGEMENT_CARD_EDR_INTEGRATION = {
  name: 'EDR Integration',
  selectedImgUrl: IMAGE_PATHS.MANAGEMENT.EDR_INTEGRATION_SELECTED,
  isSelected: false,
  code: MANAGEMENT_MENU_OPTIONS.EDR_INTEGRATION,
  order: 15,
  imgUrl: IMAGE_PATHS.MANAGEMENT.EDR_INTEGRATION,
  routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_EDR_INTEGRATION,
  contents: [], // Will remove at the time of API integration
  description: 'Manage EDR integrations for device posture notifications',
  isEnabled: true,
} as ManagementMenuItem;

export const MANAGEMENT_CARD_DATA_FETCH = {
  name: 'Cloud Asset Refresh',
  selectedImgUrl: IMAGE_PATHS.MANAGEMENT.DATA_FETCH,
  isSelected: false,
  code: MANAGEMENT_MENU_OPTIONS.DOWNLOADS,
  order: 14,
  imgUrl: IMAGE_PATHS.MANAGEMENT.DATA_FETCH,
  routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_DATA_FETCH,
  contents: [],
  description:
    'View and schedule automatic or manual refresh of your cloud assets in Prosimo',
  isEnabled: true,
} as ManagementMenuItem;

export const MANAGEMENT_CARD_SUMMARY_ROUTES = {
  name: 'Summary Routes',
  selectedImgUrl: IMAGE_PATHS.MANAGEMENT.SUMMARY_ROUTES_SELECTED,
  isSelected: false,
  code: MANAGEMENT_MENU_OPTIONS.SUMMARY_ROUTES,
  order: 16,
  imgUrl: IMAGE_PATHS.MANAGEMENT.SUMMARY_ROUTES,
  routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_SUMMARY_ROUTES,
  contents: [],
  description:
    'Summary routes to be added to VPC and VNET to attract traffic to Prosimo fabric',
  isEnabled: true,
} as ManagementMenuItem;

export const MANAGEMENT_FIREWALL_CONTROL_CENTER = {
  name: 'Firewall Control Center',
  selectedImgUrl: IMAGE_PATHS.MANAGEMENT.SUMMARY_ROUTES_SELECTED,
  isSelected: false,
  code: MANAGEMENT_MENU_OPTIONS.SUMMARY_ROUTES,
  order: 17,
  imgUrl: IMAGE_PATHS.SHARED_SERVICE.FIREWALL,
  routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_FIREWALL_CONTROL_CENTER,
  contents: [],
  description:
    'Orchestrate firewall devices and manage integrations with firewall managers',
  isEnabled: true,
} as ManagementMenuItem;

export const MANAGEMENT_CARD_PROSIMO_INFRA_TAGS = {
  name: 'Prosimo Infra Tags',
  selectedImgUrl: IMAGE_PATHS.MANAGEMENT.INFRA_TAGS_SELECTED,
  isSelected: false,
  code: MANAGEMENT_MENU_OPTIONS.INFRA_TAGS,
  order: 17,
  imgUrl: IMAGE_PATHS.MANAGEMENT.INFRA_TAGS,
  routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_PROSIMO_INFRA_TAGS,
  contents: [],
  description:
    'Specify tags that will be automatically applied to Prosimo resources',
  isEnabled: true,
} as ManagementMenuItem;

export const MANAGEMENT_MENU_ITEMS_TO_HIDE_IN_PRO_TRANSIT = [
  MANAGEMENT_MENU_OPTIONS.EDR_INTEGRATION,
];
export const MANAGEMENT_MENU_ITEMS_TO_HIDE_IN_PRO_ACCESS = [
  MANAGEMENT_MENU_OPTIONS.CONNECTOR_GROUPS,
];
export const GROUPINGS_MENU_ITEMS_TO_HIDE_IN_PRO_TRANSIT = ['USER', 'DEVICE'];
export const ALERT_TYPES_TO_HIDE_IN_PRO_TRANSIT = ['Risk Score', 'UX Score'];
export const ADMIN_ROLE_DEFINITION_TO_HIDE_IN_PRO_TRANSIT = [
  'guide',
  'agent',
  'discovery',
  'dynamic-risk',
  'user-setting',
  'device-posture',
  'edr-profile',
  'edr',
];
export const ADMIN_ROLE_DEFINITION_TO_HIDE_IN_PRO_ACCESS = [
  'connector-group-mgmt',
];

/** End: Management Landing Page Cards. */

/** Cards to display on Management Landing Page Active Filter Infra option click. */
export const INFRA_TABS = [
  MANAGEMENT_CARD_CLOUDS,
  MANAGEMENT_CARD_EDGES,
  MANAGEMENT_CARD_CONNECTOR_GROUPS,
  MANAGEMENT_CARD_IDP,
];

/** Cards to display on Management Landing Page Active Filter Integrations option click. */
export const INTEGRATIONS_TABS = [
  MANAGEMENT_FIREWALL_CONTROL_CENTER,
  MANAGEMENT_CARD_ALERTS,
  MANAGEMENT_CARD_LOG_AND_RECEIVER,
  MANAGEMENT_CARD_EDR_INTEGRATION,
  MANAGEMENT_CARD_API_TOKENS,
];

/** Cards to display on Management Landing Page Active Filter Notifications option click. */
export const NOTIFICATIONS_TABS = [MANAGEMENT_CARD_ALERTS];

/** Cards to display on Management Landing Page Active Filter Security option click. */
export const SECURITY_TABS = [MANAGEMENT_CARD_CERTIFICATES];

/** Cards to display on Management Landing Page. */
export const MANAGEMENT_TABS = [
  MANAGEMENT_CARD_CLOUDS,
  MANAGEMENT_CARD_EDGES,
  MANAGEMENT_CARD_ADMIN,
  MANAGEMENT_CARD_CONNECTOR_GROUPS,
  MANAGEMENT_CARD_ALERTS,
  MANAGEMENT_CARD_IDP,
  MANAGEMENT_CARD_CERTIFICATES,
  MANAGEMENT_CARD_API_TOKENS,
  MANAGEMENT_CARD_GROUPINGS,
  MANAGEMENT_CARD_LOG_AND_RECEIVER,
  MANAGEMENT_CARD_ERROR_PAGES,
  MANAGEMENT_CARD_DOWNLOADS,
  MANAGEMENT_CARD_EDR_INTEGRATION,
  MANAGEMENT_CARD_DATA_FETCH,
  MANAGEMENT_CARD_SUMMARY_ROUTES,
  MANAGEMENT_FIREWALL_CONTROL_CENTER,
  MANAGEMENT_CARD_PROSIMO_INFRA_TAGS,
];

/** Constants for Active Filter Options available on Management Landing Page. */
export const MANAGEMENT_CARD_FILTER_TYPE = {
  VIEW_ALL: 'View All',
  INFRA: 'Infra',
  INTEGRATIONS: 'Integrations',
  NOTIFICATIONS: 'Notifications',
  SECURITY: 'Security',
};

/** Management Landing Page Active Filter Options. */
export const MANAGEMENT_ACTIVE_FILTERS = [
  {
    label: MANAGEMENT_CARD_FILTER_TYPE.VIEW_ALL,
    value: MANAGEMENT_CARD_FILTER_TYPE.VIEW_ALL,
    isDefault: true,
  },
  {
    label: MANAGEMENT_CARD_FILTER_TYPE.INFRA,
    value: MANAGEMENT_CARD_FILTER_TYPE.INFRA,
    isDefault: false,
  },
  {
    label: MANAGEMENT_CARD_FILTER_TYPE.INTEGRATIONS,
    value: MANAGEMENT_CARD_FILTER_TYPE.INTEGRATIONS,
    isDefault: false,
  },
  {
    label: MANAGEMENT_CARD_FILTER_TYPE.NOTIFICATIONS,
    value: MANAGEMENT_CARD_FILTER_TYPE.NOTIFICATIONS,
    isDefault: false,
  },
  {
    label: MANAGEMENT_CARD_FILTER_TYPE.SECURITY,
    value: MANAGEMENT_CARD_FILTER_TYPE.SECURITY,
    isDefault: false,
  },
];

/* Button label constants */

export const BUTTON_LABELS = {
  CREATE: 'Create',
  UPDATE: 'Update',
  DEPLOY: 'Deploy',
};

export const CERTIFICATE_MESSAGES = {
  PAGE_TITLE: 'Certificates',
  CREATE_BTN_LABEL: 'Create New',
  CREATE_BTN_TYPE: 'stroked',
  CREATE_FORM_TITLE: 'Create New Certificate',
  CREATED_MSG: LAST_UPDATED_TEXT,
  EDIT_FORM_TITLE: 'Edit Certificate',
  DELETE_BTN_LABEL: 'Delete Certificate',
  DELETE_MSG: 'Are you sure you want to delete certificate?',
  DOWNLOAD_DIALOG_MSG: 'Select from following options',
};

export const EDR_INTEGRATION_MESSAGES = {
  PAGE_TITLE: 'EDR Integration',
  CREATE_BTN_LABEL: 'Create New',
  CREATE_BTN_TYPE: 'stroked',
  CREATE_FORM_TITLE: 'Create New EDR Integration',
  CREATED_MSG: LAST_UPDATED_TEXT,
  EDIT_FORM_TITLE: 'Edit EDR Integration',
  DELETE_BTN_LABEL: 'Delete EDR Integration',
  DELETE_MSG: 'Are you sure you want to delete EDR integration?',
};

export const USER_GROUPINGS_MESSAGES = {
  PAGE_TITLE: 'User Group',
  EDIT_FORM_TITLE: 'Edit User Group',
  CREATE_FORM_TITLE: 'Create New User Group',
  DELETE_BTN_LABEL: 'Delete User Group',
  DELETE_MSG: 'Are you sure you want to delete User Group?',
};

export const APP_GROUPINGS_MESSAGES = {
  PAGE_TITLE: 'App Group',
  EDIT_FORM_TITLE: 'Edit App Group',
  CREATE_FORM_TITLE: 'Create New App Group',
  DELETE_BTN_LABEL: 'Delete App Group',
  DELETE_MSG: 'Are you sure you want to delete App Group?',
};
export const ALERTS_MESSAGES = {
  PAGE_TITLE: 'Alerts',
  CREATED_MSG: LAST_UPDATED_TEXT,
  EDIT_FORM_TITLE: 'Edit Alert',
  MUTE_BTN_LABEL: 'Mute Alert',
  UNMUTE_BTN_LABEL: 'Unmute Alert',
  MUTE_MSG: 'Are you sure you want to mute?',
  UNMUTE_MSG: 'Are you sure you want to unmute?',
  CREATE_NEW_ALERT: 'Create new alert',
};

export const NOTIFICATION_CHANNEL_MESSAGES = {
  PAGE_TITLE: 'Notification Channel',
  CREATE_BTN_LABEL: 'Create New',
  CREATE_BTN_TYPE: 'stroked',
  CREATE_FORM_TITLE: 'Create New Notification Channel',
  CREATED_MSG: LAST_UPDATED_TEXT,
  EDIT_FORM_TITLE: 'Edit Notification Channel',
  DELETE_BTN_LABEL: 'Delete Channel',
  DELETE_MSG: 'Are you sure you want to delete Notification Channel?',
};

/** Management Landing Page Constants. */
export const MANAGEMENT_CONSTANTS = {
  MANAGEMENT: 'Management',
  MAIN: 'main',
  EDIT_BTN_LABEL: 'Edit',
};

export const DIALOG_TIMOUT = '500ms';

// Constants for breadcrumbs.
export const BREADCRUMB_CONSTANTS = {
  MANAGEMENT: 'Management',
  MANAGEMENT_LINK: '/management',
  CERTIFICATE: 'Certificates',
  CERTIFICATE_LINK: '/management/certificate',
  CLOUD: 'Clouds',
  CLOUD_LINK: '/management/cloud',
  ADMINS: 'Admins',
  ADMINS_LINK: '/management/admin',
  ERROR_PAGES: 'Error Pages',
  ERROR_LINK: '/management/error-pages',
  IP_ADDRESS: 'IP Addresses',
  IP_ADDRESS_LINK: '/management/ip-addresses',
  EDR_INTEGRATION: 'EDR Integration',
  EDR_INTEGRATION_LINK: '/management/edr-integration',
  IDP: 'IDP',
  IDP_LINK: '/management/idp',
  ALERTS: 'Alerts',
  ALERTS_LINK: '/management/alert',
  LOG_AND_RECEIVER: 'Log & Receiver',
  LOG_AND_RECEIVER_LINK: '/management/log-receiver',
  API_TOKENS: 'API Tokens',
  API_TOKENS_LINK: '/management/api-tokens',
  EDGES: 'Edges',
  EDGES_LINK: '/management/edge',
  CONNECTOR_GROUPS: 'Connector Groups',
  CONNECTOR_GROUPS_LINK: '/management/connector-groups',
  GROUPINGS: 'Groupings',
  GROUPINGS_LINK: '/management/groupings',
  DOWNLOADS: 'Downloads',
  DOWNLOADS_LINK: '/management/downloads',
  CONNECTIONS: 'Connections',
  CONNECTIONS_LINK: '/management/edge/connections',
  DATA_FETCH: 'Cloud Asset Refresh',
  DATA_FETCH_LINK: '/management/data-fetch',
  SUMMARY_ROUTES: 'Summary Routes',
  SUMMARY_ROUTES_LINK: '/management/summary-routes',
  FIREWALL_CONTROL_CENTER: 'Firewall Control Center',
  FIREWALL_CONTROL_CENTER_LINK: '/management/firewall-control-center',
  PROSIMO_INFRA_TAGS: 'Prosimo Infra Tags',
  PROSIMO_INFRA_TAGS_LINK: '/management/infra-tags',
};

export const NETWORK_GROUPINGS_MESSAGES = {
  PAGE_TITLE: 'Network Group',
  EDIT_FORM_TITLE: 'Edit Network Group',
  CREATE_FORM_TITLE: 'Create New Network Group',
  DELETE_BTN_LABEL: 'Delete Network Group',
  DELETE_MSG: 'Are you sure you want to delete Network Group?',
};

export const ADMINS = 'Admins';

export const LANDING_PAGE_CARD_SORT_KEY = 'name';

export const LOADER_CONFIG_TYPES = {
  LOADER: 'LOADER',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};
export interface LoaderConfig {
  status: boolean;
  message: string;
  type: string;
}

export const CLOUD_CONNECTION_OPTION = {
  AWS: {
    'vpc-peering': 'VPC',
    'transit-gateway': 'Transit Gateway',
  },
  AZURE: {
    'vnet-peering': 'VNet',
    'vwan-hub': 'VWan Hub',
  },
  GCP: {
    'vpc-peering': 'VPC',
  },
};

export const DISABLE_REGION_CONNECTIVITY_TYPES = [
  'transit-gateway',
  'vwan-hub',
];

export const TOOLTIP_POSITIONS = {
  RIGHT: 'right',
  LEFT: 'left',
  ABOVE: 'above',
  BELOW: 'below',
};

export const DIALOG_CONSTANT = {
  ERROR_TITLE: 'Failed!',
};

export const ERROR_MESSAGE_AND_FORMAT = {
  ERROR_FORMAT: {
    EMAIL_FORMAT: `Format needs to be <br> admin@email.com <br> admin@gmail <br> admin-test@gmail.com <br> admin.test@gmail.com`,
    NAME_FORMAT: ` Name must be between 3-64 characters`,
    NAME_FORMAT_WITH_PATTERN: ` Name must be between 3-64 characters and <br> must start with
    a capital letter`,
    CIDR_FORMAT: 'Format needs to be 10.248.8.0/21',
    IP_ADDRESS_FORMAT: 'Format needs to be <br> 0.0.0.0 - 255.255.255.255',
    TCP_PORT_FORMAT: `Format needs to be <br> 0 - 65535 or use "any"`,
    DATE_RANGE_FORMAT:
      'Format needs to be <br> From date should be less than to date <br><br> 4-25-2023 to 4-26-2023',
    URL_FORMAT: 'Format needs to be <br> https://login.microsoftonline.com',
    COUNT_FORMAT: 'Count should be greater than zero',
    ASN_RANGE_FORMAT:
      'Format needs to be <br> 64512-65535 OR <br> 4200000000-4294967295',
    ROUTER_ASN_FORMAT: 'Format needs to be <br> 1-4294967295',
    FORMAT:
      'Format needs to be <br> Name must be between 3-64 characters <br><br> Only alphanumeric and dash allowed <br><br> Name should be unique',
    CLOUD_NAME_FORMAT:
      'Format needs to be <br> Name must not be more than 25 characters <br><br>Name must be alphanumeric and must not have spaces',
    IDP_CUSTOMER_ID_FORMAT: 'Format needs to be <br> admin@email.com',
    IDP_DOMAIN_FORMAT: 'Format needs to be <br> https://accounts.google.com',
    COMMA_SEPARATED_EMAIL_FORMAT: `Enter the email addresses you'd like to include,  <br> separating them with commas.  <br> For example:  <br> john@example.com, <br>jane@example.com, <br>smith@example.com`,
  },
  ERROR_MESSAGES: {
    LOCATION: 'City is required.',
    NAME_ERROR_MESSAGE: 'Name is required',
    EMAIL_EXISTS_ERROR_MESSAGE: 'Email already exists',
    USER_REQUIRED_ERROR_MESSAGE: 'Users are required',
    NAME_INVALID_MESSAGE: 'Name is invalid',
    TCP_PORT_ERROR_MESSAGE: 'TCP Port is required',
    TCP_PORT_INVALID_MESSAGE: 'Invalid TCP Port',
    DESCRIPTION_ERROR_MESSAGE: 'Description is required',
    AUTH_ERROR_MESSAGE: 'Authentication Token is required',
    IP_ADDRESS_ERROR_MESSAGE: 'IP Address is required',
    IP_ADDRESS_INVALID_MESSAGE: 'IP Address is invalid',
    CLOUD_TYPE_ERROR_MESSAGE: 'Cloud Type is required',
    CIDR_ERROR_MESSAGE: 'CIDR Block is required',
    CIDR_INVALID_MESSAGE: 'CIDR is invalid',
    APP_ERROR_MESSAGE: 'App is required',
    NETWORK_ERROR_MESSAGE: 'Network is required',
    NAMESPACE_ERROR_MESSAGE: 'Namespace is required',
    CIDR_INVALID_ERROR_MESSAGE: 'Invalid IP Address Range',
    EMAIL_INVALID_ERROR_MESSAGE: 'Email is invalid',
    IP_RANGE_INVALID_ERROR: 'Invalid IP Range',
    IP_RANGE_DUPLICATE_ERROR: 'IP Range is already added',
    TIME_ZONE_ERROR_MESSAGE: `Time Zone is required`,
    DATE_RANGE_ERROR_MESSAGE: 'Invalid Date Range',
    ACCOUNT_URL_ERROR_MESSAGE: 'Account URL is required',
    ACCOUNT_URL_INVALID_ERROR_MESSAGE: 'Account URL is invalid',
    EDR_INTEGRATION_ERROR_MESSAGE: 'EDR Integration is required',
    DUPLICATE_EDR_ERROR_MESSAGE:
      'Configurations are already available for the selected vendor',
    NICKNAME_ERROR_MESSAGE: 'Nickname is required',
    BASE_URL_ERROR_MESSAGE: 'Base URL is required',
    CLIENT_ID_ERROR_MESSAGE: 'Client ID is required',
    CLIENT_SECRETE_ERROR_MESSAGE: 'Client Secret is required',
    CUSTOMER_ID_ERROR_MESSAGE: 'Customer ID is required',
    CLOUD_ERROR_MESSAGE: 'Cloud is required',
    REGION_ERROR_MESSAGE: 'Region is required',
    NO_VPC_ERROR_MESSAGE: 'No VPC(s) available',
    NO_VNET_ERROR_MESSAGE: 'No VNet(s) available',
    VPC_ERROR_MESSAGE: 'VPC is required',
    VNET_ERROR_MESSAGE: 'VNet is required',
    CLOUD_REGION_ERROR_MESSAGE: 'Cloud/Region is required',
    ACCOUNT_ERROR_MESSAGE: 'Account is required',
    CLOUD_ACCOUNT_ERROR_MESSAGE: 'Cloud account is required',
    CLOUD_REGIONS_ERROR_MESSAGE: 'Cloud Region is required',
    COUNT_ERROR_MESSAGE: 'Count is required',
    PRIVATE_CLOUD_ERROR_MESSAGE: 'Private Cloud is required',
    CONNECTOR_PLACEMENT_ERROR_MESSAGE: 'Placement is required',
    CONNECTOR_ERROR_MESSAGE: 'Connector is required',
    DNS_ERROR_MESSAGE: 'DNS is required',
    BGP_PEER_ERROR_MESSAGE: 'BGP Peer is required',
    BGP_PEER_INVALID_ERROR_MESSAGE: 'BGP Peer is invalid',
    PEER_ASN_ERROR_MESSAGE: 'Peer ASN is requieed',
    ROUTER_ASN_ERROR_MESSAGE: 'Router ASN is required',
    ERROR_MESSAGE: 'This field is required',
    CHANNEL_NAME_ERROR_MESSAGE: 'Channel Name is required',
    WEBHOOK_URL_ERROR_MESSAGE: 'Webhook URL is required',
    CHANNEL_TYPE_ERROR_MESSAGE: 'Channel Type is required',
    INSTANCE_NAME_ERROR_MESSAGE: 'Instance Name is required',
    USERNAME_ERROR_MESSAGE: 'Username is required',
    PASSWORD_ERROR_MESSAGE: 'Password is required',
    URL_ERROR_MESSAGE: 'URL is required',
    TOKEN_ERROR_MESSAGE: 'Token is required',
    EMAIL_ERROR_MESSAGE: ' Email is required',
    COUNTRY_ERROR_MESSAGE: 'Country is required',
    STATE_ERROR_MESSAGE: 'State is required',
    CITY_ERROR_MESSAGE: 'City is required',
    CLOUD_CONNECTION_ERROR_MESSAGE: 'Connection is required',
    CSP_ERROR_MESSAGE: 'Cloud is required',
    AUTHORIZATION_ERROR_MESSAGE: 'Authorization is required',
    ROLE_ARN_ERROR_MESSAGE: 'Role ARN is required',
    EXTERNAL_ID_ERROR_MESSAGE: 'External ID is required',
    ACCESS_KEY_ERROR_MESSAGE: 'Access Key ID is required',
    SECRET_KEY_ERROR_MESSAGE: 'Secret Key ID is required',
    SUBSCRIPTION_ID_ERROR_MESSAGE: 'Subscription ID is required',
    SECRET_VALUE_ERROR_MESSAGE: 'Secret value is required',
    TENANT_ID_ERROR_MESSAGE: 'Tenant ID is required',
    FIRST_NAME_ERROR_MESSAGE: 'First Name is required',
    LAST_NAME_ERROR_MESSAGE: 'Last Name is required',
    ROLE_ERROR_MESSAGE: 'Role is required',
    IDP_ACCOUNT_ERROR_MESSAGE: 'IDP Account is required',
    META_URL_ERROR_MESSAGE: 'MetaURL is required',
    METADATA_ERROR_MESSAGE: 'Metadata is required',
    API_TOKEN_ROLES_ERROR_MESSAGE: 'Role is required',
    API_TOKEN_ERROR_MESSAGE: 'API Token is required',
    DOMAIN_ERROR_MESSAGE: 'Domain is required',
    ADMIN_EMAIL_ERROR_MESSAGE: 'Admin Email is required',
    DOMAIN_NAME_ERROR_MESSAGE: 'Domain name is required',
    DOMAIN_NAME_INVALID_ERROR_MESSAGE: 'Domain name is not valid',
    ATTACH_POINT_ERROR_MESSAGE: 'Attach Point is required',
    SYNC_IDP_ERROR_MESSAGE: 'IDP is required',
    SYNC_IDP_PARAMETER_ERROR_MESSAGE: 'Select at least one parameter',
    REQUIRED_ERROR_MESSAGE: '(Required)',
    INVALID_PARAMETER_ERROR_MESSAGE: '(Invalid)',
    NEW_ROLE_ERROR_MESSAGE: 'New Role is required',
    APP_GROUP_NAME_ERROR_MESSAGE: 'App Group Name is required',
    NETWORK_GROUP_NAME_ERROR_MESSAGE: 'Network Group Name is required',
    DEVICE_GROUP_NAME_ERROR_MESSAGE: 'Device Group Name is required',
    TIME_GROUP_NAME_ERROR_MESSAGE: 'Time Group Name is required',
    GEO_GROUP_NAME_ERROR_MESSAGE: 'Geo Group Name is required',
    USER_GROUP_NAME_ERROR_MESSAGE: 'User Group Name is required',
    IP_RANGE_GROUP_NAME_ERROR_MESSAGE: 'IP Range Group Name is required',
    CONNECTOR_GROUP_NAME_ERROR_MESSAGE: 'Connector Group Name is required',
    APP_GROUP_NAME_INVALID_MESSAGE: 'App Group Name is invalid',
    NETWORK_GROUP_NAME_INVALID_MESSAGE: 'Network Group Name is invalid',
    DEVICE_GROUP_NAME_INVALID_MESSAGE: 'Device Group Name is invalid',
    TIME_GROUP_NAME_INVALID_MESSAGE: 'Time Group Name is invalid',
    USER_GROUP_NAME_INVALID_MESSAGE: 'User Group Name is invalid',
    GEO_GROUP_NAME_INVALID_MESSAGE: 'Geo Group Name is invalid',
    IP_RANGE_GROUP_NAME_INVALID_MESSAGE: 'IP Range Group Name is invalid',
    TOKEN_NAME_ERROR_MESSAGE: 'Token Name is required',
    ATTACHMENT_ERROR_MESSAGE: 'Attachment is required',
    ROUTE_TABLE_ERROR_MESSAGE: 'Route Table is required',
    CONNECTOR_NAME_ERROR_MESSAGE: 'Connector name is required',
    HOST_NAME_ERROR_MESSAGE: 'Host name is required',
    HOST_NAME_INVAID_MESSAGE: 'Host name is invalid',
    DEFAULT_ERROR_MESSAGE: 'Required',
    INVALID_ERROR_MESSAGE: 'Invalid',
    CSP_REQUIRED: 'Cloud is required',
    CLOUD_ACCOUNT_REQUIRED: 'Account is Required',
    CLOUD_REGION_REQUIRED: 'Region is Required',
    VPC_REQUIRED: 'VPC is Required',
    ROUTE_TABLE_REQUIRED: 'Route Table is Required',
    CIDR_DUPLICATE: 'CIDR is already added',
    CIDR_INVALID: 'Invalid CIDR',
    TIME_REQUIRED: 'Time is Required',
    PASSWORD_PATTERN:
      'Password must contain at least one uppercase letter, one lowercase letter and one number',
    INVALID_ACCOUNT_IDS: 'IDs must be 12 digit numeric value',
  },
};

export const DROPDOWN_PANEL_CLASS = {
  LARGE: 'select-width-lg',
  MEDIUM: 'select-width-md',
  SMALL: 'select-width-sm',
};

export const DRAWER_BTN_CONSTANT = {
  CANCEL_BTN_CONFIG: { code: 'CANCEL', name: 'Cancel', disabled: false },
  CREATE_BTN_CONFIG: { code: 'CREATE', name: 'Create', disabled: true },
  DEPLOY_BTN_CONFIG: { code: 'DEPLOY', name: 'Deploy', disabled: true },
  FETCH_BTN_CONFIG: { code: 'FETCH', name: 'Refresh', disabled: true },
  DRAWER_SUCCESS_BTN_CONFIG: {
    code: 'CREATE',
    name: 'Save',
    disabled: false,
  },
  UPDATE_BTN_CONFIG: { code: 'UPDATE', name: 'Update', disabled: true },
  NEXT_BTN_CONFIG: { code: 'NEXT', name: 'Next', disabled: true },
};

// Constants for the Drawer component.
export const DRAWER_COMPONENT = {
  WIDTH: '618px',
  DEFAULT_PADDING: 'p-0',
  DELETE_DIALOG_WIDTH: '294px',
  DELETE_DIALOG_MESSAGE: 'Are you sure you want to delete?',
  DELETE_BTN_NAME: 'Delete',
  PANEL_CLASS: 'deleteDialog_',
};
