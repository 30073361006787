import { USER_TYPES } from '@app/common/util/constants';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivate,
} from '@angular/router';
import { AuthService } from './auth.service';
import { map, take, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { URL_PATHS } from '../../constants';
import { PDashLocalStoreUtil } from '@app/state/web-pdash.util';
import { LoginUtil } from '@app/login/login-util';
import { UserState } from '@app/login/state';
import { L2HeaderService } from '@app/component-common/l2header/l2header.service';
import { LOGIN_AUTH_TYPES } from '@app/login/login.model';
import { BannerService } from '@app/component-common/banner/banner.service';
import { AppUtil } from '../../app-util';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private l2HeaderService: L2HeaderService,
    private loginUtil: LoginUtil,
    private bannerService: BannerService,
    private appUtil: AppUtil
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.authService.getAuthTokenStatus().pipe(
      take(1),
      catchError(() => {
        PDashLocalStoreUtil.updateRequestedURL(state.url);
        this.routeTo(URL_PATHS.LOGIN);
        return of(false);
      }),
      map((data: any = {}) => {
        // Below IF Statement is added to update banner state. If license status modified via admin view, it will add/remove banner on navigation or refresh.
        if (this.isAllowedToFetchBannerInfo(data?.type)) {
          this.bannerService.displayBannerInfo(
            data?.accountExpiry,
            data?.type,
            data?.licenseStatus
          );
        }

        // The below steps are required when user login via SSO or use API token (local development)
        const productViewType = PDashLocalStoreUtil.getProductViewType();
        const subscriptionTypesFromStore = this.loginUtil.getSubscriptionType();
        /*
         Set User state first followed by product view type as 
         the listeners of product view type has logic that depends on the subscription type set 
         in the user state.
         */
        const transformedSubscriptionTypes =
          this.loginUtil.getTransformedSubscriptionType(data?.subscriptionType);
        if (!(productViewType && subscriptionTypesFromStore.length > 0)) {
          this.loginUtil.updateUserState(
            {
              username: '',
              userId: '',
              firstname: '',
              lastname: '',
              prosimoAppStatus: '',
              team: '',
              company: '',
              factors: [],
              type: data?.type,
              status: data?.status || '',
              accountExpiry: data?.accountExpiry,
              authType: data?.authType,
              subscriptionType: transformedSubscriptionTypes,
              licenseStatus: data?.licenseStatus,
              engLabsEnabled: data?.engLabsEnabled || false,
            } as UserState,
            true
          );
          PDashLocalStoreUtil.updateProductViewType(
            transformedSubscriptionTypes?.length
              ? transformedSubscriptionTypes[0]
              : ''
          );
          const { firstname, lastname, username, team, authType } = data;
          this.l2HeaderService.setL2HeaderUserConfig({
            firstname,
            lastname,
            username,
            team,
            disabledProfile: authType === LOGIN_AUTH_TYPES.SSO,
          });
        }
        if (
          data?.type !== USER_TYPES.PROSIMO_ADMIN &&
          data?.type !== USER_TYPES.MSP_ADMIN
        ) {
          return true;
        } else {
          if (data?.type === USER_TYPES.PROSIMO_ADMIN) {
            this.routeTo(URL_PATHS.PAGE_NOT_FOUND);
          } else if (data?.type === USER_TYPES.MSP_ADMIN) {
            this.routeTo(URL_PATHS.MSP.DASHBOARD);
          }
          return false;
        }
      })
    );
  }

  routeTo(routeURL: string = URL_PATHS.LOGIN) {
    this.router.navigate([routeURL]);
  }

  isAllowedToFetchBannerInfo(type: string) {
    const path = this.appUtil.getPath();
    const ignorePathsSet = new Set([
      URL_PATHS.LOGIN,
      URL_PATHS.ACCOUNT_EXPIRED,
    ]);
    return type && !ignorePathsSet.has(path);
  }
}
