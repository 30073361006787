<!-- Active Step -->
<ng-template #activeStep let-config="config">
  <div
    class="component__stepper-step-active d-flex align-items-center"
    title="{{ config.info }}"
  >
    <div class="outer-circle d-flex align-items-center">
      <div class="inner-circle"></div>
    </div>
    <div class="step-label" [ngClass]="{ completed_step: config.isComplete }">
      {{ config.label }}
    </div>
  </div>
  <ng-container
    *ngIf="config.showStepConnector"
    [ngTemplateOutlet]="connector"
    [ngTemplateOutletContext]="{ status: 'active' }"
  ></ng-container>
</ng-template>

<!-- In-Active Step -->
<ng-template #inActiveStep let-config="config">
  <div
    class="component__stepper-step-inactive d-flex align-items-center"
    title="{{ config.info }}"
  >
    <div
      *ngIf="!config.isComplete"
      class="outer-circle d-flex align-items-center"
    >
      <div class="inner-circle"></div>
    </div>
    <div
      *ngIf="config.isComplete"
      class="outer-circle d-flex align-items-center"
    >
      <div class="inner-circle-complete"></div>
    </div>
    <div class="step-label" [ngClass]="{ completed_step: config.isComplete }">
      {{ config.label }}
    </div>
  </div>
  <ng-container
    *ngIf="config.showStepConnector"
    [ngTemplateOutlet]="connector"
    [ngTemplateOutletContext]="{ status: 'inactive' }"
  ></ng-container>
</ng-template>

<!-- Connector -->
<ng-template #connector let-status="status">
  <div class="component__stepper-step-connector"></div>
</ng-template>
<div>
  <div class="current-step__details">
    Step {{ (currentStep$ | async)?.order }}:
    {{ (currentStep$ | async)?.label }}
  </div>
  <ul class="component__stepper mt-4">
    <ng-container *ngFor="let stepperConfig of stepperConfig$ | async">
      <li
        *ngIf="!stepperConfig.isHide"
        (click)="setCurrentStep(stepperConfig, stepperConfig?.isDisabled)"
        [matTooltip]="stepperConfig?.isDisabled ? 'This step is disabled' : ''"
        matTooltipPosition="above"
      >
        <div>
          <ng-container
            [ngTemplateOutlet]="
              stepperConfig.order === (currentStep$ | async)?.order
                ? activeStep
                : inActiveStep
            "
            [ngTemplateOutletContext]="{ config: stepperConfig }"
          ></ng-container>
        </div>
      </li>
    </ng-container>
  </ul>
</div>
