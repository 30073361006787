import {
  ACCOUNT_TYPES,
  INDEX_PAGE_ELEMENT_CLASSES,
  UI_THEME_MODES,
  URL_PATHS,
  USER_STATUSES,
} from '@app/common/util/constants';
import { LoginUtil } from './login/login-util';
import { BannerService } from './component-common/banner/banner.service';
import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  OnDestroy,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CONSTANTS, environment } from 'environments/environment';
import { AppUtil } from './common/util/app-util';
import { Router, Event, NavigationStart } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { LOGIN_AUTH_TYPES } from './login/login.model';
import { AppService } from './app.service';
import { ENV_CONSTANTS } from 'environments/env.constants';
import {
  BannerModel,
  BannerType,
} from './component-common/banner/banner.model';
import { PDashLocalStoreUtil } from './state/web-pdash.util';
import { HeaderService } from './component-common/header/header.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnDestroy {
  uiThemeMode: UI_THEME_MODES = PDashLocalStoreUtil?.getUIThemeMode();
  companyMetaInfo;
  isShowContactSupportBtn;
  subscriptions = [];
  bannerConfig: Array<BannerModel> = [];
  isDashboard: boolean = false;
  isHideBanner: boolean = false;
  isBannerLarge: boolean = false;
  isShowHeader: boolean = false;

  constructor(
    private appService: AppService,
    private headerService: HeaderService,
    private bannerService: BannerService,
    private loginUtil: LoginUtil,
    private appUtil: AppUtil,
    private router: Router,
    private element: ElementRef,
    private cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: any
  ) {
    this.getUIThemeMode();
    const configSub = this.bannerService
      .getBannerConfig()
      .subscribe((config) => {
        this.bannerConfig = config;
        this.isBannerLarge = !(config?.[0]?.type == BannerType.trial);
      });
    this.subscriptions.push(configSub);
    this.appUtil.setAllowedEnvListToTrackUserBehavior(
      ENV_CONSTANTS.ALLOWED_ENV_FOR_USER_BEHAVIOR_ANALYTICS
    );
    this.appUtil.setSelectedEnv(environment.type?.toUpperCase());
    this.appUtil.initUserBehaviorInsights();

    this.subscribeToRouterEvents();

    this.appUtil?.setRootElement(this.element);

    // to override "https://pdash.prosimo.io" and set dashboard host dynamically
    // in production build

    if (CONSTANTS.ENABLE_DYNAMIC_HOST) {
      const location = this.document.location;
      const protocol = 'https://';
      CONSTANTS.DASHBOARD_HOST = `${protocol}${location.host}`;
    }

    console.log('initial app after', CONSTANTS.DASHBOARD_HOST);

    this.appUtil.hostBasedRouting();
    // this.setPrivateLinkSourceState();
    this.setBrowserTabInfo();

    // Getting User Info from localstore
    const {
      firstname,
      lastname,
      username,
      team,
      type,
      accType,
      accountExpiry,
      authType,
      licenseStatus,
    } = this.loginUtil.getUserState();
    // Hide contact support button for MSP tenants
    this.isShowContactSupportBtn =
      accType && accType.toLowerCase() === ACCOUNT_TYPES.TENANT ? false : true;
    this.headerService.setL2HeaderUserConfig({
      firstname,
      lastname,
      username,
      team,
      disabledProfile: authType === LOGIN_AUTH_TYPES.SSO,
    });

    if (accountExpiry && this.appUtil.isAccountExpired()) {
      // reroute to login page to update the local store with new values
      this.appUtil.logout();
    } else {
      if (this.isAllowedToFetchBannerInfo(type)) {
        // display banner info
        this.bannerService.displayBannerInfo(
          accountExpiry,
          type,
          licenseStatus
        );
      }
    }

    this.subscriptions?.push(
      this.loginUtil.getUserStatusAsObservable().subscribe((info) => {
        this.isShowHeader = info === USER_STATUSES.SUCCESS;
      })
    );
  }

  getUIThemeMode(): void {
    this.subscriptions?.push(
      // Theme change.
      PDashLocalStoreUtil?.getUIThemeModeAsObservable()?.subscribe((mode) => {
        this.uiThemeMode = mode;
        this.cdr?.markForCheck();
      })
    );
  }

  /**
   * Sets the favicon and the page title
   */
  setBrowserTabInfo() {
    this.subscriptions.push(
      this.loginUtil
        .getTeamMetaInfoFromUserState()
        .pipe(debounceTime(100))
        .subscribe(
          (data) => {
            const { favicon, name } = this.appUtil.getTeamMetaInfo();
            let elemFavicon: any = document.querySelector(
              `.${INDEX_PAGE_ELEMENT_CLASSES.FAVICON}`
            );
            let elemPageTitle: any = document.querySelector(
              `.${INDEX_PAGE_ELEMENT_CLASSES.TITLE}`
            );
            elemFavicon.href = favicon;
            elemPageTitle.innerText = name;
          },
          (error) => {
            console.warn('Unable to fetch team meta info..');
          }
        )
    );
  }

  isAllowedToFetchBannerInfo(type: string) {
    const path = this.appUtil.getPath();
    const ignorePathsSet = new Set([
      URL_PATHS.LOGIN,
      URL_PATHS.ACCOUNT_EXPIRED,
    ]);
    return type && !ignorePathsSet.has(path);
  }

  /**
   * function to
   * - listen for route changes and  clear any active banner messages
   * - add loader indicators - not used currently
   */
  subscribeToRouterEvents(): void {
    this.subscriptions.push(
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationStart) {
          this.isDashboard =
            event.url === '/' ||
            event.url === CONSTANTS.UI_PATHS.MAIN_DASHBOARD;
          this.isHideBanner = [
            CONSTANTS.UI_PATHS.LOGIN,
            CONSTANTS.USER_PROFILE.MAIN,
          ].includes(event.url);
          this.appUtil.hideMessageDialog();
          this.bannerService.removeIsCloseOnRouteBanners();
        }
        // if (event instanceof NavigationEnd) {
        // }
        // if (event instanceof NavigationError) {
        //   console.warn(event.error);
        // }
      })
    );
  }

  /**
   * set the theme
   */
  setTheme(): void {
    this.appService.toggleDarkTheme();
  }

  ngOnDestroy() {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }
}
