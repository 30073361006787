import { GeneralTableDef } from '@app/common/components/general-table/general-table.interface';
import { GENERAL_TABLE_COLUMN_DATA_TYPES } from '@app/common/components/general-table/general-table.constant';
import { COLORS } from '@app/common/util/constants';
import { SEARCH_FILTER } from '../shared/search-filter/search-filter.constants';
import { SelectPill } from '@app/common/components/select-pill/select-pill.model';

export const AGENT_DASHBOARD = 'AGENT_DASHBOARD';

export const UI_PATHS = {
  OVERVIEW: `dashboard/agent/overview`,
  DEVICES: `dashboard/agent/devices`,
  SETTINGS: `dashboard/agent/settings`,
  DOWNLOAD: `dashboard/agent/download`,
};

export const AGENT_MENU_OPTIONS = {
  OVERVIEW: 'OVERVIEW',
  DEVICES: 'DEVICES',
  SETTINGS: 'SETTINGS',
  DOWNLOAD: 'DOWNLOAD',
};

export const AGENT_OVERVIEW_CHART_COLORS = [
  '#b3de69',
  '#ffb34b',
  '#7B2C7C',
  '#14848B',
  '#0C27B0',
  '#1DAEFF',
  '#D0D8E4',
];

export const AGENT_OVERVIEW_CHART_TITLE = {
  DEVICE_MODEL: 'Device Model',
  AGENT_VERSIONS: 'Agent Versions',
  AGENT_USAGE_BY_PLATFORM: 'Agent usage by platform',
  TOTAL_AGENT_USAGE: 'Total Agent Usage',
  DEVICE_OS: 'Device Operating Systems',
};

export const ERROR_MESSAGES = {
  NO_OVERVIEW_DATA_AVAILABLE: `There doesn't seem to be any data available.`,
};

/**
 * Agent header config
 */

export const AGENT_DEVICES_HEADER_CONFIG = [
  {
    name: 'Status',
    rowSize: '1',
  },
  {
    name: 'User ID',
    rowSize: '2',
  },
  {
    name: 'OS & Version',
    rowSize: '1',
  },
  {
    name: 'Current Risk Level',
    rowSize: '3',
  },
  {
    name: 'Prior Risk Level',
    rowSize: '1',
  },
  {
    name: 'Device Model',
    rowSize: '1',
  },
  {
    name: 'Agent Version',
    rowSize: '1',
  },
  {
    name: 'Last Seen Time',
    rowSize: '2',
  },
];

export const AGENT_DOWNLOADS_HEADER_CONFIG = [
  {
    name: 'Application Version',
    rowSize: '3',
  },
  {
    name: 'Release Notes',
    rowSize: '6',
  },
];

export const AGENT_DOWNLOADS_HEADER_CONFIG_WINDOWS = [
  {
    name: 'Download ZIP',
    rowSize: '3',
  },
];

export const AGENT_DOWNLOADS_HEADER_CONFIG_MAC = [
  {
    name: 'Download ZIP',
    rowSize: '3',
  },
];

export const AGENT_DEVICES_LEGEND_CONFIG = [
  {
    name: 'Active',
    status: 'on',
    searchTerm: {
      key: 'usage',
      value: 'Used',
    },
    color: {
      border: COLORS.GREEN,
      fill: COLORS.GREEN,
    },
  },
  {
    name: 'Inactive',
    status: 'on',
    searchTerm: {
      key: 'usage',
      value: 'Subscribed',
    },
    color: {
      border: COLORS.ORANGE2,
      fill: COLORS.ORANGE2,
    },
  },
];

export const UNKNOWN_TYPES = {
  UNKNOWN: 'unknown',
  UNAVAILABLE: 'unavailable',
};

export const OS_OPTIONS = {
  WINDOWS: 'windows',
  MAC: 'mac',
};

export const OS = [
  {
    label:'Windows',
    value:'windows'
  },
  {
    label:'MacOS',
    value:'mac'
  }
];
export const STATUS = [
  {
    label: 'Used',
    value: 'Used',
  },
  {
    label: 'Subscribed',
    value: 'Subscribed',
  },
];

export const RISK_LEVEL = [
  {
    label: 'Low',
    value: 'low',
  },
  {
    label: 'Medium',
    value: 'medium',
  },
  {
    label: 'High',
    value: 'high',
  },
  {
    label: 'Others',
    value: 'unknown',
  },
];

export const AGENT_DEVICE_DETAILS_TABLE_DEF: GeneralTableDef = {
  isExpandableRows: false,
  showCheckboxes: false,
  rowDef: [
    {
      dataField: 'usage',
      displayName: 'Status',
      grids: 12,
      useCssClassIcons: true,
      advanceSearch: true,
      advanceSearchType: SEARCH_FILTER.TYPES.SELECT_PILL,
      configs: {
        dropDown: { label: '', values: STATUS },
        targetKey: 'label',
      } as SelectPill,
      isColumnFilter: true,
    },
    {
      dataField: 'userEmail',
      displayName: 'User Id',
      grids: 12,
      advanceSearch: true,
      advanceSearchType: SEARCH_FILTER.TYPES.AUTOCOMPLETE,
      isColumnFilter: true,
    },
    {
      dataField: 'os',
      displayName: 'OS',
      grids: 12,
      advanceSearch: true,
      advanceSearchType: SEARCH_FILTER.TYPES.SELECT_PILL,
      configs: {
        dropDown: { label: '', values: OS },
        targetKey: 'label',
      } as SelectPill,
      isColumnFilter: true,
    },
    {
      dataField: 'riskLevel',
      displayName: 'Current Risk Level',
      grids: 12,
      advanceSearch: true,
      advanceSearchType: SEARCH_FILTER.TYPES.SELECT_PILL,
      configs: {
        dropDown: { label: '', values: RISK_LEVEL },
        targetKey: 'label',
      } as SelectPill,
      isColumnFilter: true,
    },
    {
      dataField: 'riskProfiles',
      displayName: 'Risk Profiles',
      grids: 12,
      advanceSearch: true,
      advanceSearchType: SEARCH_FILTER.TYPES.AUTOCOMPLETE,
      isColumnFilter: true,
      // cellRenderer: {
      //    component: CapsuleRendererComponent // @TODO: Will address later, commenting now bcz observed performance related issues when we user multiple cell renderer
      // }
    },
    {
      dataField: 'priorRiskLevel',
      displayName: 'Prior Risk Level',
      advanceSearch: false,
      advanceSearchType: SEARCH_FILTER.TYPES.SELECT_PILL,
      configs: {
        dropDown: { label: '', values: RISK_LEVEL },
        targetKey: 'label',
      } as SelectPill,
      grids: 12,
      isColumnFilter: false,
    },
    {
      dataField: 'deviceModel',
      displayName: 'Device Model',
      grids: 12,
      advanceSearch: true,
      advanceSearchType: SEARCH_FILTER.TYPES.AUTOCOMPLETE,
      isColumnFilter: true,
    },
    {
      dataField: 'agentVersion',
      displayName: 'Agent Version',
      grids: 12,
      advanceSearch: true,
      advanceSearchType: SEARCH_FILTER.TYPES.AUTOCOMPLETE,
      isColumnFilter: true,
    },
    {
      dataField: 'lastSeenTime',
      displayName: 'Last Seen Time',
      grids: 12,
      dataType: GENERAL_TABLE_COLUMN_DATA_TYPES.DATE,
      advanceSearchType: SEARCH_FILTER.TYPES.AUTOCOMPLETE,
    },
  ],
};

export const AGEND_DEVICES_CHART_UIDS = {
  USAGE: 'USAGE',
  VERSION: 'VERSION',
  PLATFORM: 'PLATFORM',
  MODEL: 'MODEL',
  OS: 'OS',
  RISK_LEVEL: 'RISK_LEVEL',
};

export const AGENT_DEVICES_FILTER_KEYS = {
  [AGEND_DEVICES_CHART_UIDS.USAGE]: 'usage',
  [AGEND_DEVICES_CHART_UIDS.VERSION]: 'agentVersion',
  [AGEND_DEVICES_CHART_UIDS.PLATFORM]: 'platform',
  [AGEND_DEVICES_CHART_UIDS.MODEL]: 'deviceModel',
  [AGEND_DEVICES_CHART_UIDS.OS]: 'os',
  [AGEND_DEVICES_CHART_UIDS.RISK_LEVEL]: 'riskLevel',
};
