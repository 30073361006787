import { TimeISO } from '@prosimoio/services';
import { Network } from './network';

export type Namespace = {
  id: string;
  nid: number;
  name: string;
  createdTime: TimeISO;
  updatedTime: TimeISO;
  teamID: string;
  assignedNetworks: NetworkList;
  exportedNetworks: NetworkList;
  importedNetworks: NetworkList;
  status: string;
};

export type NetworkList = {
  networks: Array<Network>;
};

export type NamespaceResponse = {
  totalCount: number;
  namespaces: Array<Namespace>;
};

export type NetworkSubnetConflict = {
  namespaceID: string;
  namespaceName: string;
  networkID: string;
  networkName: string;
  vpc: string;
  subnetConflicts: Array<SubnetConflict>;
};

export type SubnetConflict = {
  subnet: string;
  namespaceID: string;
  namespaceName: string;
  networkID: string;
  networkName: string;
  vpc: string;
  overlaps: Array<OverlapInfo>;
};

export type OverlapInfo = {
  namespaceID: string;
  namespaceName: string;
  networkID: string;
  networkName: string;
  vpc: string;
  subnet: string;
  exportedNamespaces: Array<Namespace>;
  accountInfo: NamespaceAccountInfo;
};

export type NamespaceAccountInfo = {
  id: string;
  networkID: string;
  region: string;
  cidr: string;
  cspType: string;
  name: string;
  accountID: string;
  type: string;
  teamID: string;
  createdTime: TimeISO;
};

export type IDNameNs = {
  id: string;
  name: string;
  namespaceName: string;
  status: string;
  deployed: boolean;
};
