<div>
  <mat-paginator
    #paginator
    [length]="paginatorData?.total"
    [pageSize]="paginatorData?.size"
    [pageSizeOptions]="pageSizeOptions"
    (page)="pageEvent = fetchMoreData($event)"
    [selectConfig]="selectConfig"
  >
  </mat-paginator>
</div>
