import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  filteringString$ = new Subject<string>();
  resetFiltering$ = new Subject<boolean>();
  constructor() {}

  setFilteringString(str: string) {
    this.filteringString$.next(str);
  }

  getFilteringString(): Observable<any> {
    return this.filteringString$.asObservable();
  }

  getResetFiltering() {
    return this.resetFiltering$.asObservable();
  }

  resetFiltering() {
    this.resetFiltering$.next(true);
  }
}
