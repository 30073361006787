import { IsTrialBannerAccountPipe } from '../../common/pipe/is-trial-banner-account-pipe/is-trial-banner-account.pipe';
import { CHART_CONSTANTS } from '@app/component-common/charts/common/util';
import { DateUtil } from '@prosimoio/services';
import { CommonService } from './../../common/http/service/common.service';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  BannerModel,
  MaintenanceBannerConfig,
  TrialBannerConfig,
  TrialModel,
} from './banner.model';
import { CONSTANTS } from 'environments/environment';
import { LICENSE_STATUS } from '@app/login/login.model';

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  private bannerConfig$ = new BehaviorSubject<Array<BannerModel>>([]);
  private hideBanner$ = new Subject();
  constructor(
    private commonService: CommonService,
    private isTrialBannerAccountPipe: IsTrialBannerAccountPipe
  ) {}

  setBannerConfigs(configs: Array<BannerModel>) {
    this.bannerConfig$.next(configs);
  }

  hideBanner(status: boolean = true) {
    this.hideBanner$.next(status);
  }

  getBannerConfig() {
    return this.bannerConfig$.asObservable();
  }

  getBannerDisplayStatus() {
    return this.hideBanner$.asObservable();
  }

  /**
   * add the config to the list
   * @param config
   */
  addBannerConfig(config: BannerModel) {
    if (!config) {
      return;
    }
    const currentConfigs = this.getCurrentConfigs();
    const hasExistingType = currentConfigs.filter(
      (item) => item.type === config.type
    );
    if (!hasExistingType.length) {
      const newConfigs = [...currentConfigs, config];
      this.setBannerConfigs(newConfigs);
    }
  }

  /**
   * remove the config from the list
   * @param removedConfig
   */
  removeBannerConfig(removedConfig: BannerModel) {
    if (!removedConfig) {
      return;
    }
    const currentConfigs = this.getCurrentConfigs();
    const newConfigs = currentConfigs.filter(
      (config) => config.type !== removedConfig.type
    );
    this.setBannerConfigs(newConfigs);
  }

  /**
   * remove all banners which have isCloseOnRoute equal true
   */
  removeIsCloseOnRouteBanners() {
    const currentConfigs = this.getCurrentConfigs();
    const newConfigs = currentConfigs.filter(
      (config) => !config.isCloseOnRoute
    );
    this.setBannerConfigs(newConfigs);
  }

  /**
   * Return the current banner configs
   */
  getCurrentConfigs() {
    return this.bannerConfig$.getValue() || [];
  }

  getMaintenanceBannerInfo() {
    this.commonService.getData(CONSTANTS.MAINTENANCE_INFO).subscribe(
      (dataArray = []) => {
        if (dataArray.length) {
          const data = dataArray.pop();
          const { message = '', from = '', to = '' } = data || {};
          const maintenanceConfig = Object.assign({}, MaintenanceBannerConfig, {
            data: {
              message,
              from,
              to,
              timezone: DateUtil.getTimeZoneName(),
            },
          }) as BannerModel;
          this.addBannerConfig(maintenanceConfig);
        }
      },
      (error) => {
        console.warn('banner info failed to load', error);
      }
    );
  }

  displayBannerInfo(
    accountExpiry: number,
    type: string,
    licenseStatus: string
  ) {
    // validate account type is trial or customer with license or not. if trial or customer with no license,
    // display trial banner with different txt based on type
    this.getTrialBannerInfo(accountExpiry, type, licenseStatus);
    // get maintenance info and display in banner (This will only work after login. Otherwise, it will return empty data)
    this.getMaintenanceBannerInfo();
  }

  getTrialBannerInfo(
    accountExpiry: number,
    type: string,
    licenseStatus: string
  ) {
    // TODO: need to add license validation logic
    const licenseStatusTxt = licenseStatus?.toLowerCase();
    const data: TrialModel = this.getTrialBannerData(accountExpiry, type);
    const trialBannerConfig = Object.assign({}, TrialBannerConfig, { data });
    if (this.isTrialBannerAccountPipe.transform(type, licenseStatus)) {
      this.addBannerConfig(trialBannerConfig);
    } else if (licenseStatusTxt === LICENSE_STATUS.VALID) {
      this.removeBannerConfig(trialBannerConfig);
    }
  }

  getTrialBannerData(accountExpiry: number, accountType: string) {
    if (!accountExpiry) {
      return null;
    }
    const deltaTime = accountExpiry - new Date().getTime();
    const days = Math.floor(deltaTime / CHART_CONSTANTS.DAY_IN_MILLI_SECONDS);
    const fd = Math.floor(days / 10);
    const sd = Math.ceil(days % 10);
    const firstDigit = fd < 0 ? 0 : fd;
    const secondDigit = sd < 0 ? 0 : sd;
    return { firstDigit, secondDigit, accountType };
  }

  /**
   * Update the Trial account to normal account
   */
  changeTrialAccountToNormal(): Observable<any> {
    return this.commonService.updateData(CONSTANTS.UPDATE_TRIAL_ACCOUNT, {});
  }
}
