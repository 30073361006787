import { Store, StoreConfig } from '@datorama/akita';
import { PageConfig, UI_THEME_MODES } from '@app/common/util/constants';

export interface PDashState {
  requestedUrl: string;
  pageInfo: PageConfig;
  helpConfig: any;
  regionConfig: any;
  isInteractiveGuideOn: boolean;
  productViewType: string;
  uiThemeMode: UI_THEME_MODES;
  isPDFPrintView: boolean;
  day0: Day0;
}

export interface Day0 {
  isStepOneDone: boolean;
  isStepTwoDone: boolean;
}

export function createInitialState(): PDashState {
  return {
    requestedUrl: '',
    pageInfo: {
      name: '',
      module: {
        parent: '',
        child: '',
      },
      helpId: '',
      desc: '',
    } as PageConfig,
    helpConfig: {},
    regionConfig: { data: [] },
    isInteractiveGuideOn: false,
    isPDFPrintView: false,
    productViewType: '',
    uiThemeMode: UI_THEME_MODES.LIGHT,
    day0: {
      isStepOneDone: false,
      isStepTwoDone: false,
    },
  };
}

@StoreConfig({ name: 'pdash', resettable: true })
export class PDashStore extends Store<PDashState> {
  constructor() {
    super(createInitialState());
  }
}

export const pDashStore = new PDashStore();
