import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { DateUtil } from '@prosimoio/services';

@Component({
  selector: 'app-panel-footer',
  templateUrl: './panel-footer.component.html',
  styleUrls: ['./panel-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PanelFooterComponent implements OnInit {
  @Input() created: CreatedDate;
  @Input() isUndo = false;
  @Input() isRevoke = false;
  @Input() isDeleteAll = false;
  @Input() isSave = true;
  @Input() isAction = true; // flag to decide whether buttons should be visible on footer or not
  @Input() isReadOnly = false;
  @Input() isShowCreatedDate = true;
  @Input() saveDisabled: boolean = false;
  @Input() cancelDisabled: boolean = false;
  @Input() undoDisabled: boolean = false;
  @Output() undo = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() deleteAll = new EventEmitter();
  @Output() revoke = new EventEmitter();

  DATE_FORMAT = DateUtil.DATE_FORMATS;
  FOOTER_ACTIONS = {
    CANCEL: 'CANCEL',
    SAVE: 'SAVE',
    UNDO: 'UNDO',
    DELETE_ALL: 'DELETE_ALL',
    REVOKE: 'REVOKE',
  };
  constructor() {}

  ngOnInit() {}

  onClick(type: string) {
    switch (type) {
      case this.FOOTER_ACTIONS.CANCEL:
        this.cancel.emit();
        break;
      case this.FOOTER_ACTIONS.SAVE:
        this.save.emit();
        break;
      case this.FOOTER_ACTIONS.UNDO:
        this.undo.emit();
        break;
      case this.FOOTER_ACTIONS.DELETE_ALL:
        this.deleteAll.emit();
        break;
      case this.FOOTER_ACTIONS.REVOKE:
        this.revoke.emit();
        break;
    }
  }
}

export interface CreatedDate {
  label: string;
  date: Date | number | string;
}
