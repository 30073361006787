import { AlertModel } from '@app/component-common/alert/alert.model';
import { Subject } from 'rxjs';
import { AlertService } from './alert.service';
import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import { ALERT_TYPES } from '@app/common/util/constants';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent implements OnInit, OnDestroy {
  @Input() compact = false;
  alertConfig$: Subject<AlertModel> = new Subject();
  displayAlert = false;
  ALERT_TYPES_COPY = ALERT_TYPES;
  subscriptions = [];

  constructor(private alertService: AlertService) {
    this.subscriptions.push(
      this.alertService.getAlertConfig().subscribe((config) => {
        this.alertConfig$.next(config);
        this.displayAlert = config ? true : false;
      })
    );
  }

  ngOnInit() {}

  resetAlertComponent() {
    this.displayAlert = false;
    this.alertService.resetAlertConfig();
  }

  ngOnDestroy(): void {
    // reset alert config to remove the component from UI. Required to clean up the alert component
    // after it is initiated by other component.
    // this.resetAlertComponent();
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }
}
