export class CarouselDisplayConfig {
  start: number;
  end: number;
  count: number;
  constructor() {}

  setRange(startPos: number, endPos: number, displayCount: number) {
    this.start = startPos;
    this.end = endPos;
    this.count = displayCount;
  }
}
