import { Store, StoreConfig } from '@datorama/akita';

export interface LogMetaDataState {
  dashboard: any;
  fields: any;
}

export function createInitialState(): LogMetaDataState {
  return {
    dashboard: null,
    fields: null,
  };
}

@StoreConfig({ name: 'log-meta-data' })
export class LogMetaDataStore extends Store<LogMetaDataState> {
  constructor() {
    super(createInitialState());
  }
}

export const logMetaDataStore = new LogMetaDataStore();
