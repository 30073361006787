import { Query } from '@datorama/akita';
import {
  ZTNAInsightsSharedFilterState,
  ZTNAInsightsSharedFilterStore,
  ztnaInsightsSharedFilterStore,
} from './ztna-insights-filter.store';
import { Observable } from 'rxjs';
import { TimeRange } from '../../shared/calendar.component';
import { TeamUser } from '@app/dashboard/shared/filter-list/client-list/client-list.model';
import { OnboardedApp } from '@app/dashboard/shared/filter-list/app-list/apps-list.model';

export class ZTNAInsightsSharedFilterQuery extends Query<ZTNAInsightsSharedFilterState> {
  selectedTeamUsers$ = this.select((state) => state.selectedTeamUsers);
  selectedOnboardedApps$ = this.select((state) => state.selectedOnboardedApps);
  timeRange$ = this.select((state) => state.timeRange);
  activeTab$ = this.select((state) => state.activeTab);
  source$ = this.select((state) => state.source);
  mainDashboardTimeRange$ = this.select(
    (state) => state.mainDashboardTimeRange
  );
  insightTimeRange$ = this.select((state) => state.insightTimeRange);

  constructor(protected store: ZTNAInsightsSharedFilterStore) {
    super(store);
  }

  get selectedTeamUsers(): Observable<Array<TeamUser>> {
    return this.selectedTeamUsers$;
  }

  get selectedOnboardedApps(): Observable<Array<OnboardedApp>> {
    return this.selectedOnboardedApps$;
  }

  get timeRange(): Observable<TimeRange> {
    return this.timeRange$;
  }

  get insightTimeRange(): Observable<TimeRange> {
    return this.insightTimeRange$;
  }

  get mainDashboardTimeRange(): Observable<TimeRange> {
    return this.mainDashboardTimeRange$;
  }

  get activeTab(): Observable<string> {
    return this.activeTab$;
  }

  get source(): Observable<string> {
    return this.source$;
  }
}

export const ztnaInsightsSharedFilterQuery = new ZTNAInsightsSharedFilterQuery(
  ztnaInsightsSharedFilterStore
);
