<p
  *ngIf="!data.publicCloud?.cloudNetworks?.length"
  class="py-2 mx-4 page__hint clr-grey5"
>
  Please Select VPCs/VNets in the previous step to proceed
</p>
<div
  *ngIf="data.publicCloud?.cloudNetworks?.length"
  class="d-flex h-100 overflow-hidden"
>
  <pcomp-conditions-panel
    [style.maxWidth.px]="240"
    [style.minWidth.px]="240"
    [conditionStyle]="{ 'min-width': '240px', 'max-width': '240px' }"
  >
    <div [style.maxWidth.px]="240" [style.minWidth.px]="240"></div>
  </pcomp-conditions-panel>
  <div class="p-2 overflow-auto">
    <p class="page__hint clr-grey5">
      <img
        class="error-icon"
        *ngIf="errorPayload?.errorStep === step"
        [src]="errorIcon"
      />
      {{ hint }}
    </p>
    <mat-accordion [multi]="true" class="bordered-panel">
      <ng-container
        [ngTemplateOutlet]="vpcSettings"
        [ngTemplateOutletContext]="{
          formGroup: vpcForm.controls.vpcSettings.at(selectedVPCIndex)
        }"
      ></ng-container>
    </mat-accordion>
  </div>
</div>

<ng-template #vpcSettings let-formGroup="formGroup">
  <ng-container [formGroup]="formGroup">
    <mat-expansion-panel
      [expanded]="true"
      [hideToggle]="true"
      class="form-panel my-3"
    >
      <mat-expansion-panel-header class="form-panel__header pe-none arrow">
        <mat-panel-title class="title clr-grey5">
          Connector settings
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-label class="d-flex mb-2 form__label"> Placement </mat-label>
      <mat-radio-group
        formControlName="connectorPlacement"
        (change)="changeConnectorPlacement($event, formGroup)"
      >
        <mat-radio-button
          *ngFor="let connectorType of COPY_CONNECTOR_TYPES; first as isFirst"
          color="primary"
          [value]="connectorType.value"
          [ngClass]="isFirst ? 'ml-n1 pl-0' : 'pl-2'"
        >
          {{ connectorType.displayName }}
        </mat-radio-button>
      </mat-radio-group>
      <div formGroupName="connectorSettings">
        <div
          class="d-flex flex-column mt-2"
          [ngClass]="{
            'invalid-range':
              formGroup.controls.connectorSettings.controls.subnets?.errors &&
              formGroup.controls.connectorSettings.controls.subnets?.touched
          }"
          *ngIf="
            formGroup.controls.connectorPlacement.value ===
            COPY_CONNECTOR_TYPE_VALUE.APP
          "
        >
          <div class="d-flex mb-2">
            <mat-label class="form__label">Connector Group Subnet</mat-label>
            <pcomp-mat-error-info-tooltip
              class="ml-2"
              [errorMessageConfig]="{
                isShowTooltip: false,
                errorCondition:
                  formGroup.controls.connectorSettings.controls.subnets
                    ?.errors &&
                  formGroup.controls.connectorSettings.controls.subnets
                    ?.touched,
                errorMessage: formGroup.controls.connectorSettings.controls
                  .subnets?.errors?.maxlength
                  ? data.publicCloud.cloud +
                    ' only allows ' +
                    formGroup.controls.connectorSettings.controls.subnets
                      ?.errors?.maxlength.requiredLength +
                    ' connector group subnet(s)'
                  : ''
              }"
              [uiThemeMode]="uiThemeMode"
            ></pcomp-mat-error-info-tooltip>
          </div>
          <ng-container *ngIf="(subnetsLoader$ | async).status">
            {{ COPY_LOADER_MESSAGES.LOADING }}
          </ng-container>
          <ng-container *ngIf="!(subnetsLoader$ | async).status">
            <mat-form-field
              class="mat-form-field-wrapper-remove-padding mb-2"
              appearance="outline"
              *ngIf="isSubnetsAvailable"
            >
              <mat-select
                formControlName="subnets"
                [matTooltip]="
                  formGroup.controls.connectorSettings.disabled
                    ? 'Connector already deployed for a deployed network. Subnets cannot be modified.'
                    : 'Ensure that the Connector Subnet differs from the onboarding network subnets and utilizes a separate route table'
                "
                [matTooltipClass]="tooltipThemeClass"
                placeholder="Subnet"
                panelClass="mat-panel__bg--{{
                  uiThemeMode
                }}-theme-light select-width-lg"
                disableRipple
                multiple
                disableOptionCentering
              >
                <mat-select-trigger>
                  {{
                    !(
                      formGroup.controls.connectorSettings.controls.subnets
                        .value || []
                    ).length
                      ? ""
                      : (
                          formGroup.controls.connectorSettings.controls.subnets
                            .value || []
                        ).length > 1
                      ? formGroup.controls.connectorSettings.controls.subnets
                          .value.length + " Subnets"
                      : formGroup.controls.connectorSettings.controls.subnets
                          .value
                  }}
                </mat-select-trigger>
                <mat-option *ngFor="let subnet of subnets" [value]="subnet">
                  {{ subnet }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-error
              *ngIf="(subnetsLoader$ | async).type === 'nosubnetserror'"
            >
              {{ (subnetsLoader$ | async).message }}
            </mat-error>
          </ng-container>
          <p *ngIf="overlappingConnectorSubnet" class="page__hint">
            <img class="error-icon mr-1" [src]="errorIcon" />The Connector
            Subnet overlaps with network subnets and/or shares a route table.
          </p>
        </div>
        <div
          *ngIf="
            (formGroup?.controls?.connectorPlacement.value ===
              COPY_CONNECTOR_TYPE_VALUE.APP &&
              connectorScalingPermittedCSPs.includes(
                data.publicCloud?.cloud
              )) ||
            (formGroup?.controls?.connectorPlacement.value ===
              COPY_CONNECTOR_TYPE_VALUE.INFRA &&
              infraConnectorScalingPermittedCSPs.includes(
                data.publicCloud?.cloud
              ))
          "
        >
          <mat-label class="d-flex mt-3 mb-2 form-panel__section-header">
            Bandwidth profile (Gbps)
          </mat-label>

          <div
            formGroupName="bandwidthRange"
            [matTooltip]="
              formGroup.controls.connectorSettings.disabled
                ? 'Connector already deployed for a deployed network. Bandwidth cannot be modified.'
                : ''
            "
            [matTooltipClass]="tooltipThemeClass"
            class="d-flex flex-row my-2"
          >
            <div class="d-flex flex-column pr-2 mr-1 w-50">
              <mat-label class="mb-2 form__label">Minimum (1-100)</mat-label>
              <mat-form-field
                class="mat-form-field-wrapper-remove-padding"
                appearance="outline"
              >
                <mat-select
                  formControlName="min"
                  placeholder="Account"
                  panelClass="mat-panel__bg--{{
                    uiThemeMode
                  }}-theme-light select-width-lg"
                  disableRipple
                  disableOptionCentering
                >
                  <mat-select-trigger>
                    {{
                      formGroup.controls.connectorSettings.value.bandwidthRange
                        .min
                    }}
                    {{
                      formGroup.controls.connectorSettings.value.bandwidthRange
                        .min === 1
                        ? " (Default)"
                        : ""
                    }}
                  </mat-select-trigger>
                  <mat-option
                    *ngFor="let bandwidth of BANDWIDTH_RANGE"
                    [value]="bandwidth"
                  >
                    {{ bandwidth }}{{ bandwidth === 1 ? " (Default)" : "" }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="d-flex flex-column pl-2 ml-1 w-50">
              <mat-label class="mb-2 form__label">Maximum (1-100)</mat-label>
              <mat-form-field
                class="mat-form-field-wrapper-remove-padding"
                appearance="outline"
              >
                <mat-select
                  formControlName="max"
                  placeholder="Account"
                  panelClass="mat-panel__bg--{{
                    uiThemeMode
                  }}-theme-light select-width-lg"
                  disableRipple
                  disableOptionCentering
                >
                  <mat-select-trigger>
                    {{
                      formGroup.controls.connectorSettings.value.bandwidthRange
                        .max
                    }}
                    {{
                      formGroup.controls.connectorSettings.value.bandwidthRange
                        .max === 1
                        ? " (Default)"
                        : ""
                    }}
                  </mat-select-trigger>
                  <mat-option
                    *ngFor="let bandwidth of BANDWIDTH_RANGE"
                    [value]="bandwidth"
                  >
                    {{ bandwidth }}{{ bandwidth === 1 ? " (Default)" : "" }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <p class="page__hint">
            Connectors will auto scale up and down based on min and max values.
            If both values are the same, connectors will be set up at static
            capacity.
          </p>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      [expanded]="true"
      [hideToggle]="true"
      class="form-panel my-3"
    >
      <mat-expansion-panel-header class="form-panel__header pe-none arrow">
        <mat-panel-title class="title clr-grey5">
          Connectivity Details
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="mb-2">
        <pcomp-info-tooltip
          [contentTooltip]="isPrivateConnectivity ? true : false"
        >
          <span class="d-flex form__label" labelBefore>
            Connectivity Type
          </span>
          <span *ngIf="isPrivateConnectivity" content class="tooltip-text">
            Public connection is not available <br />
            as the edge connectivity type <br />is set to private only.
          </span>
        </pcomp-info-tooltip>
      </div>
      <mat-radio-group
        formControlName="connectivityType"
        [matTooltip]="
          formGroup.controls.connectivityType.disabled
            ? 'Connectivity Type cannot be modified.'
            : ''
        "
        [matTooltipClass]="tooltipThemeClass"
      >
        <ng-container
          *ngFor="
            let connectorType of COPY_CONNECTIIVITY_TYPE[
              data.publicCloud?.cloud
            ];
            first as isFirst
          "
        >
          <mat-radio-button
            color="primary"
            [value]="connectorType?.value"
            [ngClass]="isFirst ? 'ml-n1 pl-0' : 'pl-2'"
            *ngIf="
              (formGroup?.controls?.connectorPlacement?.value ===
                COPY_CONNECTOR_TYPE_VALUE.INFRA &&
                connectorType?.value !==
                  COPY_CONNECTIVITY_OPTIONS_KEYS.PUBLIC) ||
              (isPrivateConnectivity &&
                connectorType?.value !==
                  COPY_CONNECTIVITY_OPTIONS_KEYS.PUBLIC) ||
              (formGroup?.controls?.connectorPlacement?.value ===
                COPY_CONNECTOR_TYPE_VALUE.APP &&
                !isPrivateConnectivity)
            "
          >
            {{ connectorType.displayName }}
          </mat-radio-button>
        </ng-container>
      </mat-radio-group>
      <div
        class="d-flex flex-column"
        *ngIf="
          [
            COPY_CONNECTIVITY_OPTIONS_KEYS.TRANSIT_GATEWAY,
            COPY_CONNECTIVITY_OPTIONS_KEYS.VWAN_HUB
          ].includes(formGroup.controls.connectivityType.value)
        "
      >
        <mat-label class="mb-2 form__label"
          >Available {{ COPY_CSP_GWs[data.publicCloud.cloud] }}s</mat-label
        >
        <pcomp-mat-select-search
          *ngIf="!loading.hubIds && hubIDs.length"
          [parentForm]="formGroup"
          [config]="selectHubIDConfig"
          [disableStatus]="!selectedVPC.enabled"
          [formControlNameInput]="formGroup.controls.hubID"
          controlName="hubID"
          class="mat-form-field-wrapper-remove-padding mb-3"
        ></pcomp-mat-select-search>
        <div *ngIf="!loading.hubIds && hubIDs.length === 0" class="mb-3">
          No {{ COPY_CSP_GWs[data.publicCloud.cloud] }} found
        </div>
        <div *ngIf="loading.hubIds" class="mb-3">
          Loading {{ COPY_CSP_GWs[data.publicCloud.cloud] }}...
        </div>
      </div>
      <p
        *ngIf="
          ![COPY_NetworkStatus.NEW, COPY_NetworkStatus.SAVED].includes(
            networkStatus
          ) && !!formGroup.controls.hubID.value
        "
        class="page__hint mb-3 mt-n2"
      >
        To change this
        {{ COPY_CSP_GWs[data.publicCloud.cloud | uppercase] }} you first have to
        offboard this network.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel
      *ngIf="
        [COPY_CLOUD_TYPES.AWS].includes(data.publicCloud.cloud) &&
        formGroup.controls.connectorPlacement.value ===
          COPY_CONNECTOR_TYPE_VALUE.APP
      "
      [expanded]="false"
      [hideToggle]="false"
      class="form-panel my-3"
    >
      <mat-expansion-panel-header class="form-panel__header arrow">
        <mat-panel-title>
          <span class="title clr-grey5">Service Insertion Endpoint</span>
          <span class="subtitle clr-grey4 ml-1">(Optional)</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container formGroupName="serviceSubnets">
        <mat-label class="d-flex mb-2 form__label">
          Subnet Selection
        </mat-label>
        <mat-radio-group formControlName="mode">
          <mat-radio-button
            *ngFor="
              let endpointType of COPY_SERVICE_INSERTION_ENDPOINT_TYPES_LIST
                | keyvalue;
              first as isFirst
            "
            color="primary"
            [value]="endpointType.key"
            [ngClass]="isFirst ? 'ml-n1 pl-0' : 'pl-2'"
          >
            {{ endpointType.value }}
          </mat-radio-button>
        </mat-radio-group>
        <div
          *ngIf="
            formGroup.controls.serviceSubnets.controls.mode.value ===
            COPY_SERVICE_INSERTION_ENDPOINT_TYPES.MANUAL
          "
          class="d-flex flex-column"
        >
          <mat-label class="mb-2 form__label">Select Service</mat-label>
          <mat-form-field
            *ngIf="sharedServices?.length"
            class="mb-3 mat-form-field-wrapper-remove-padding"
            appearance="outline"
          >
            <mat-select
              formControlName="svcID"
              placeholder="Service"
              panelClass="mat-panel__bg--{{
                uiThemeMode
              }}-theme-light select-width-lg"
              disableRipple
              disableOptionCentering
              (selectionChange)="updateServiceName($event, formGroup)"
            >
              <mat-option
                *ngFor="let service of sharedServices"
                [value]="service.id"
              >
                {{ service.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error class="mt-1 mb-2" *ngIf="!sharedServices?.length">
            No Service Found
          </mat-error>
          <div
            class="d-flex mb-3"
            *ngIf="
              formGroup.controls.serviceSubnets.controls.mode.value ===
                COPY_SERVICE_INSERTION_ENDPOINT_TYPES.MANUAL &&
              svcCloudSubnets?.length
            "
          >
            <div class="d-flex flex-column pr-2 mr-1 w-50">
              <mat-label class="mb-2 form__label">Endpoint Subnet</mat-label>
              <mat-form-field
                class="mat-form-field-wrapper-remove-padding"
                appearance="outline"
              >
                <mat-select
                  formControlName="endpointSubnet"
                  placeholder="Endpoint Subnet"
                  panelClass="mat-panel__bg--{{
                    uiThemeMode
                  }}-theme-light select-width-lg"
                  disableRipple
                  multiple
                  disableOptionCentering
                >
                  <mat-option
                    *ngFor="let subnet of svcCloudSubnets"
                    [value]="subnet"
                  >
                    {{ subnet }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div
              *ngIf="subnets?.length"
              class="d-flex flex-column pl-2 ml-1 w-50"
            >
              <mat-label class="mb-2 form__label">Connector Subnet</mat-label>
              <mat-form-field
                class="mat-form-field-wrapper-remove-padding"
                appearance="outline"
              >
                <mat-select
                  formControlName="subnetInterface"
                  placeholder="Connector Subnet"
                  panelClass="mat-panel__bg--{{
                    uiThemeMode
                  }}-theme-light select-width-lg"
                  disableRipple
                  multiple
                  disableOptionCentering
                >
                  <mat-option *ngFor="let subnet of subnets" [value]="subnet">
                    {{ subnet }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </ng-container>
    </mat-expansion-panel>
    <mat-expansion-panel
      *ngIf="
        vpcRouteState?.customMatchNeeded ||
        vpcRouteState?.rfcMatch ||
        vpcRouteState?.customMatch
      "
      [expanded]="true"
      [hideToggle]="true"
      class="form-panel my-3"
    >
      <mat-expansion-panel-header class="form-panel__header pe-none arrow">
        <mat-panel-title class="title clr-grey5">
          Routing Info
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="mb-2">
        <ng-container *ngIf="vpcRouteState?.customMatchNeeded">
          <mat-label class="d-flex mb-2 form__label">
            <img class="route-error-icon mr-2" [src]="errorIcon" />Custom routes
            need to be added under management.
          </mat-label>
          <p class="mb-3 page__hint">
            Some subnets do not fall in the RFC1918 address space. You will need
            to add custom routes for these subnets. Visit management page and
            click on Summary Routes, or click
            <a [href]="summaryRoutesPath" target="_blank">here</a>
          </p>
        </ng-container>
        <ng-container *ngIf="vpcRouteState?.rfcMatch">
          <mat-label class="d-flex mb-2 form__label">
            RFC1918 summary routes will be added.
          </mat-label>
          <p class="page__hint">
            Summary routes will be automatically added for subnets belonging to
            the RFC1918 address space. To change this default setting visit
            management page and click on Summary Routes.
          </p>
        </ng-container>
        <ng-container *ngIf="vpcRouteState?.customMatch">
          <mat-label class="d-flex mb-2 form__label">
            Custom summary routes will be added.
          </mat-label>
          <p class="page__hint">
            Summary routes will be automatically added for subnets belonging to
            the custom address space. To change this setting visit management
            page and click on Summary Routes.
          </p>
        </ng-container>
      </div>
    </mat-expansion-panel>
  </ng-container>
</ng-template>
