import { Network } from '@app/common/models';
import { NetworkStore, networkStore } from './network.store';

export class NetworkService {
  constructor(private networkStore: NetworkStore) {}

  updateActiveTab(activeTab: string) {
    this.networkStore.update((state) => ({
      activeTab,
    }));
  }

  setActiveNetwork(network: Network) {
    this.networkStore.update({
      activeNetwork: network,
    });
  }
}

export const networkService = new NetworkService(networkStore);
