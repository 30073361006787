import { BehaviorSubject } from 'rxjs';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FilterService } from './filter.service';

@Component({
  selector: 'app-filter-action-form-wrapper',
  templateUrl: './filter-action-form-wrapper.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterActionFormWrapperComponent implements OnInit, OnDestroy {
  @Input() selectedFilter;
  @Input() logo;
  @Input() title;
  @Input() hint;
  @Input() doneAlias = null;
  @Input() infoTooltip = '';
  @Input() disableDoneButton = false;
  @Input() leftMargin = '';
  subscriptions = [];
  errorMessage$: BehaviorSubject<string> = new BehaviorSubject('');
  loader$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(private filterService: FilterService) {
    this.subscriptions.push(
      this.filterService
        .getErrorMessage()
        .subscribe((msg) => this.errorMessage$.next(msg)),
      this.filterService
        .getLoaderStatus()
        .subscribe((showLoader) => this.loader$.next(showLoader))
    );
  }

  ngOnInit() {}

  setActiveFilter(activeFilter: string) {
    this.filterService.setSelectedFilter(activeFilter);
  }

  onDone() {
    this.filterService.setDoneStatus(true);
  }

  onCancel() {
    this.filterService.setCancelStatus(true);
  }

  dismissErrorMessage() {
    this.errorMessage$.next('');
  }

  ngOnDestroy() {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }
}
