import { TimeISO } from '@prosimoio/services';
import { ConnectorSettings, DNSServer, NodeSizeSettings, User } from './common';
import { DCConnectorSettings, FabricConnectorInfo } from './connector-group';
import { NetEgressSvc } from './net-egress-svc';

export type Cluster = {
  id?: string;
  byoResourceDetails?: BYOResourceDetails;
  city?: string;
  cloudKeyID: string;
  cloudRegion: string;
  cloudType: string;
  clusterName: string;
  clusterType: string;
  accountID: string;
  country: string;
  flavor: string;
  idpType: string;
  locationID: string;
  networkInfo: any;
  nickName: string;
  pappFqdn: string;
  privateIP: string;
  prosimoPorts: Array<IPPort>;
  publicIP: string;
  rancherToken: string;
  state: string;
  status: string;
  subnet: string;
  tcpPorts: Array<string>;
  teamID: string;
  token: string;
  tokenActivated: boolean;
  udpPorts: Array<string>;
  createdTime: TimeISO;
  updatedTime: TimeISO;
  gateways?: Array<any>;
  wgExternalEndpoint: string;
  wgInternalEndpoint: string;
  fabricConnectInfo: FabricConnectorInfo;
  connectorSettings: ConnectorSettings;
  nodeSizeSettings: NodeSizeSettings;
  appNames: Array<string>;
  appUsedCount: number;
  networkNames: Array<string>;
  networkUsedCount: number;
  edgeConnectivityCount: number;
  privateLinkSourceNames: Array<string>;
  privateLinkUsedCount: number;
  sharedServiceNames: Array<string>;
  sharedServiceCount: number;
  netEgressSvc: NetEgressSvc;
};

export type EdgeConnectorGroup = {
  id: string;
  name: string;
  status: string;
  initFileDownloaded: boolean;
  privateCloudID: string;
  privateCloudName: string;
  teamID: string;
  ipAddressPool: string;
  createdTime: TimeISO;
  updatedTime: TimeISO;
  totalCount: number;
  vnetID: string;
  internallyManaged: boolean;
  isPublic: boolean;
  edgeID: string;
  cloudType: string;
  region: string;
  publicKey: string;
  privateKey: string;
  routerCfg: RouterConfig;
  instanceUpdatable: boolean;
  connectorSettings: ConnectorSettings;
  connectorPlacement: string;
  systemCfg: SystemConfig;
  dcConnectorSettings: Array<DCConnectorSettings>;
};

export type EdgeConnectivity = {
  account: string;
  attachPoint: string;
  cloudCredsID: string;
  cloudType: string;
  cloudRegion: string;
  connectivityType: string;
  edgeID: string;
  id: string;
  internallyManaged: boolean;
  name: string;
  network: string;
  region: string;
  status: string;
  teamID: string;
  attachment: string;
  createdTime: TimeISO;
  updatedTime: TimeISO;
};

export type RouterConfig = {
  local_asn: string;
  router_id: string;
  peers: Array<BGPNeighbor>;
  sshKeys: string;
  advancedBGPCfg: string;
  dnsServers: DNSServer;
  ntpServer: string;
  staticRouteCfg: string;
  customCloudInitCfg: string;
};

export type SystemConfig = {
  sshKeys: string;
  dnsServers: DNSServer;
  ntpServer: string;
  staticRouteCfg: string;
  customCloudInitCfg: string;
  users: Array<User>;
};

export type BGPNeighbor = {
  ip_addr: string;
  as_num: string;
  enable: boolean;
  namespaceID: string;
};

export type BYOResourceDetails = {
  vpcId: string;
};

export type IPPort = {
  ip: string;
  port: string;
};

export interface EdgeCreatePayload {
  cloudRegion: string;
  cloudKeyID: string;
  byoResourceDetails?: BYOResourceDetails;
  nodeSizeSettings?: ConnectorSettings;
  subnet?: string;
}
