import {
  MCNInsightsSharedFilterStore,
  mcnInsightsSharedFilterStore,
  TimeRangeType,
} from './mcn-insights-filter.store';
import { TimeRange } from '../../shared/calendar.component';
import {
  Network,
  NetworkGroup,
} from '@app/dashboard/shared/filter-list/network-list/network-list.model';
import { OnboardedApp } from '@app/dashboard/shared/filter-list/client-list/client-list.model';
import { Region } from '@app/dashboard/shared/filter-list/region-list/region-list.model';

export class MCNInsightsStoreFilterService {
  constructor(private mcnInsightsFilterStore: MCNInsightsSharedFilterStore) {}

  updateSelectedSourceNetworks(selectedSourceNetworks: Array<Network>) {
    this.mcnInsightsFilterStore.update((state) => ({
      selectedSourceNetworks,
    }));
  }

  updateSelectedSourcePrivateLinks(selectedSourcePrivateLinks: Array<any>) {
    this.mcnInsightsFilterStore.update((state) => ({
      selectedSourcePrivateLinks,
    }));
  }

  updateSelectedSourceNetworkGroups(
    selectedSourceNetworkGroups: Array<NetworkGroup>
  ) {
    this.mcnInsightsFilterStore.update((state) => ({
      selectedSourceNetworkGroups,
    }));
  }

  updateSelectedSourceRegions(selectedSourceRegions: Array<Region>) {
    this.mcnInsightsFilterStore.update((state) => ({
      selectedSourceRegions,
    }));
  }

  updateSelectedTargetNetworks(selectedTargetNetworks: Array<Network>) {
    this.mcnInsightsFilterStore.update((state) => ({
      selectedTargetNetworks,
    }));
  }

  updateSelectedTargetNetworkGroups(
    selectedTargetNetworkGroups: Array<NetworkGroup>
  ) {
    this.mcnInsightsFilterStore.update((state) => ({
      selectedTargetNetworkGroups,
    }));
  }

  updateSelectedOnboardedApps(selectedOnboardedApps: Array<OnboardedApp>) {
    this.mcnInsightsFilterStore.update((state) => ({
      selectedOnboardedApps,
    }));
  }

  updateSelectedTargetRegions(selectedTargetRegions: Array<Region>) {
    this.mcnInsightsFilterStore.update((state) => ({
      selectedTargetRegions,
    }));
  }

  updateDateRange(timeRange: TimeRange) {
    this.mcnInsightsFilterStore.update((state) => ({
      timeRange,
    }));
  }

  updateInsightDateRange(insightTimeRange: TimeRange) {
    this.mcnInsightsFilterStore.update((state) => ({
      insightTimeRange,
    }));
  }

  updateMainDashboardDateRange(mainDashboardTimeRange: TimeRange) {
    this.mcnInsightsFilterStore.update((state) => ({
      mainDashboardTimeRange,
    }));
  }

  updateCostDashboardDateRange(costDashboardTimeRange: TimeRange) {
    this.mcnInsightsFilterStore.update((state) => ({
      costDashboardTimeRange,
    }));
  }

  updateActiveTab(activeTab: string) {
    this.mcnInsightsFilterStore.update((state) => ({
      activeTab,
    }));
  }

  updateActiveSubMenu(activeSubMenu: string) {
    this.mcnInsightsFilterStore.update((state) => ({
      activeSubMenu,
    }));
  }

  updateLevelTwoActiveSubMenu(levelTwoActiveSubMenu: string) {
    this.mcnInsightsFilterStore.update((state) => ({
      levelTwoActiveSubMenu,
    }));
  }

  updateSource(source: string) {
    this.mcnInsightsFilterStore.update((state) => ({
      source,
    }));
  }

  updateSelectedTimeRangeType(selectedTimeRangeType: TimeRangeType) {
    this.mcnInsightsFilterStore.update((state) => ({
      selectedTimeRangeType,
    }));
  }
}

export const mcnInsightsStoreFilterService = new MCNInsightsStoreFilterService(
  mcnInsightsSharedFilterStore
);
