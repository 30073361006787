<ng-template #insightFilterItem let-item="item" let-data="data">
  <div class="d-flex py-1">
    <span class="pl-2 values-txt" *ngIf="!item?.isTimeRange"
      >{{ data | insightPrintFilterValue : item }}
    </span>
    <span class="pl-2 values-txt" *ngIf="item?.isTimeRange"
      >{{ data | insightPrintFilterValue : item }}
    </span>
  </div>
</ng-template>

<ng-template #insightFilter>
  <ng-container *ngIf="filterData$ | async as filterData">
    <div
      class="print-filter-header d-flex"
      *ngFor="let row of ROWS; let i = index"
      [ngClass]="row?.leftHeader ? 'mt-3' : ''"
    >
      <div class="col-2 section-txt py-1">{{ row.leftHeader || "" }}</div>
      <div class="left-panel" [ngClass]="row?.right ? 'col-4' : 'col-10'">
        <ng-container *ngIf="row?.left">
          <ng-container
            [ngTemplateOutlet]="insightFilterItem"
            [ngTemplateOutletContext]="{
              item: FILTERS_INFO[row?.left],
              data: filterData
            }"
          ></ng-container>
        </ng-container>
      </div>
      <ng-container *ngIf="row?.right">
        <div class="col-2 right-panel section-txt py-1">
          {{ row.rightHeader || "" }}
        </div>
        <div class="col-4">
          <ng-container>
            <ng-container
              [ngTemplateOutlet]="insightFilterItem"
              [ngTemplateOutletContext]="{
                item: FILTERS_INFO[row?.right],
                data: filterData
              }"
            ></ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<div id="print-filter-header__container">
  <ng-container [ngTemplateOutlet]="insightFilter"></ng-container>
</div>
