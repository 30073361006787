import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { IMAGE_PATHS } from '@app/common/util/constants';
import { SearchFilterModel } from '@app/dashboard/shared/search-filter/search-filter.model';
import { SearchFilterService } from '@app/dashboard/shared/search-filter/search-filter.service';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Page } from '../paginator/paginator.model';
import { PaginatorService } from '../paginator/paginator.service';
import { GENERAL_TABLE_COLUMN_DATA_TYPES } from './general-table.constant';
import { GeneralTableDef } from './general-table.interface';
@Component({
  selector: 'app-general-table',
  templateUrl: './general-table.component.html',
  styleUrls: ['./general-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralTableComponent implements OnInit, OnDestroy {
  @Input() set tableDef(tableDef: GeneralTableDef) {
    this._tableDef = tableDef;
    this.cdr.markForCheck();
    this.buidAdvanceSearchFields();
  }

  get tableDef() {
    return this._tableDef;
  }
  @Input() set data(list: Array<any>) {
    this._data = list || [];
  }

  get data() {
    return this._data;
  }

  @Input() tableCaption: string;
  @Input() tableCaptionValue: string;
  @Input() showTitle: boolean = true;
  @Input() showPagination: boolean = true;
  @Input() showAdvanceSearch: boolean = false;
  @Input() set paginationDetails(page: Page) {
    if (page && !page?.start) {
      this._pageDetails = page;
      this.paginatorData$.next({
        total: page?.total || this.data?.length || 0,
        size: page?.size || this.PAGE_SIZE,
        start: 0,
      } as Page);
    }
  }
  @Input() statusLegend;
  @Output() rowSelectionChange = new EventEmitter();
  @Output() paginationChange = new EventEmitter<Page>();
  @Output() searchTermChange = new EventEmitter();
  @Output() advanceSearchTermChange = new EventEmitter();

  columnHeaders: string[] = [];

  _tableDef;
  _data: Array<any> = [];
  selection = new SelectionModel<any>(true, []);
  PAGE_SIZE = 25;
  _pageDetails;
  paginatorData$: any = new BehaviorSubject([]);
  subscriptions = [];
  COLUMN_DATA_TYPES = GENERAL_TABLE_COLUMN_DATA_TYPES;
  IMAGE_PATHS = {
    WINDOWS: IMAGE_PATHS.AGENT.WINDOWS,
    MAC: IMAGE_PATHS.AGENT.MAC,
    UNAVAILABLE: IMAGE_PATHS.ICON.EMPTY_IMAGE,
  };
  UTILITY_CONST = {
    OS: 'os',
    WINDOWS: 'windows',
    MAC: 'mac',
  };
  displayTableFilter: boolean = false;
  advanceSearchFields: SearchFilterModel[] = [];
  constructor(
    private cdr: ChangeDetectorRef,
    private paginatorService: PaginatorService,
    private searchFilterService: SearchFilterService
  ) {
    this.initializeTable();
    this.paginatorListener();
    if (this.tableDef?.showCheckboxes) {
      this.columnHeaders?.unshift('select');
    }

    this.tableDef?.rowDef?.forEach((column) =>
      this.columnHeaders.push(column?.displayName)
    );

    // @TODO: Expand feature not yet implemented
    if (this.tableDef?.isExpandableRows) {
      this.columnHeaders?.unshift('expand');
    }
  }

  ngOnInit(): void {
    const advancedSearchSub = this.searchFilterService
      .getsearchFilterTerms()
      .pipe(debounceTime(100), distinctUntilChanged())
      .subscribe((searchTerms) => {
        this.advanceSearchTermChange.emit(searchTerms);
      });
    this.subscriptions.push(advancedSearchSub);
  }

  /**
   * Initialize table definition
   */
  initializeTable() {
    if (this.tableDef) {
      this.tableDef = Object.assign(this.tableDef, {
        rowDef: this.tableDef?.rowDef ? this.tableDef?.rowDef : [],
        isExpandableRows: this.tableDef?.isExpandableRows
          ? this.tableDef?.isExpandableRows
          : false,
        showCheckboxes: this.tableDef?.showCheckboxes
          ? this.tableDef?.showCheckboxes
          : false,
      });
      this.buidAdvanceSearchFields();
    }
  }

  /**
   * Check if all rows are selected
   * @returns true / false
   */
  isAllSelectedRows() {
    const numRows = this.data.length;
    return numRows === numRows;
  }

  /**
   * Toggle master checkbox
   */
  masterToggle() {
    if (this.isAllSelectedRows()) {
      this.selection.clear();
    }

    this.selection.select(...this.data);
    this.rowSelectionChange.emit(this.selection.selected);
  }

  /**
   * Update checkbox label
   * @param row
   * @param index
   * @returns
   */
  checkboxLabel(row: any, index: number): string {
    if (!row) {
      return `${this.isAllSelectedRows() ? 'deselect' : 'select'} all`;
    }

    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      index + 1
    }`;
  }

  // @TODO:  Yet to implement is HTML
  fireCallback(rowData: any, columnDef: any) {
    if (rowData?.callback instanceof Function) {
      rowData.callback(Object.assign(rowData, columnDef));
    }
  }

  /**
   * Function to subscribe to pagination
   */
  paginatorListener() {
    // Listen for the pagination change
    const paginatorChangeSub = this.paginatorService
      .getPaginatorData()
      .subscribe((paginatorData: Page) =>
        this.paginationChange.emit(paginatorData)
      );
    this.subscriptions.push(paginatorChangeSub);
  }

  /**
   * Toggle filter row
   * @param event
   */
  toggleFilterRow(event: any) {
    this.displayTableFilter = !this.displayTableFilter;
  }

  /**
   * Build Advance search control based on inputs
   */
  buidAdvanceSearchFields() {
    this.tableDef?.rowDef?.forEach((row) => {
      if (row?.advanceSearch) {
        const searchField = {
          name: row?.displayName,
          key: row?.dataField,
          type: row.advanceSearchType,
          isColumnFilter: row?.isColumnFilter,
          configs: row?.configs,
        } as SearchFilterModel;
        this.advanceSearchFields.push(searchField);
      }
    });
    this.cdr.markForCheck();
  }

  /**
   * Fire event when the search box has changed
   * @param searchTerm
   */
  searchTermChanged(searchTerm: string) {
    this.searchTermChange.emit(searchTerm);
  }

  ngOnDestroy() {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }
}
