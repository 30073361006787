<div class="badge__container w-100 d-flex align-item-center">
  <div class="badge__container__badge d-flex align-items-center">
    <span
      class="badge_icon status"
      [ngClass]="{
        'status-active-bg': activeCSS?.includes(
          badgeConfig?.status | lowercase
        ),
        'bg-grey2': incompleteCSS?.includes(badgeConfig?.status | lowercase),
        'status-failed-bg': errorCSS?.includes(badgeConfig?.status | lowercase),
        'status-warn-bg': notUsedCSS?.includes(badgeConfig?.status | lowercase),
        'status-configured-bg': configuredCSS?.includes(
          badgeConfig?.status | lowercase
        )
      }"
    >
    </span>
  </div>
  <div class="badge__container__text">
    {{ badgeConfig?.text }}
  </div>
</div>
