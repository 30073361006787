<div id="nested-dropdown-details">
  <form [formGroup]="menuForm">
    <div class="d-flex flex-wrap align-items-center no-gutters">
      <div *ngIf="selectedOptionImageURL">
        <img
          [ngClass]="selectedMenuOptionImageClass"
          [attr.src]="selectedOptionImageURL"
        />
      </div>
      <div class="ml-2">
        <div
          *ngIf="isShowSelectedvalueOnly; then valueOnly; else showDropdown"
        ></div>
        <ng-template #valueOnly>
          <span class="selected-value">
            {{ selectedMenuOptionValue }}
          </span>
        </ng-template>
        <ng-template #showDropdown>
          <mat-form-field class="ml-2">
            <div class="mat-input" [matMenuTriggerFor]="menu.itemMenu">
              <input
                matInput
                class="cursor-pointer"
                type="text"
                readonly="true"
                formControlName="selectedMenuOption"
                placeholder="Choose account"
                disabled="true"
              />
              <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
            </div>
            <mat-error
              *ngIf="menuForm?.controls?.selectedMenuOption.errors?.required"
              >Cloud Account is required.
            </mat-error>
            <div>
              <div class="menu-item">
                <app-menu-item
                  #menu
                  [contextMenuValues]="contextMenuValues"
                  (valueChange)="selectedMenuOption($event)"
                >
                </app-menu-item>
              </div>
            </div>
          </mat-form-field>
        </ng-template>
      </div>
    </div>
  </form>
</div>
