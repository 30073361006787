import { DashboardMenuConfig } from '@app/common/components/dashboard-menu/dashboard-menu.config';
import { CONSTANTS } from 'environments/environment';
import { PLAYGROUND_METRICS } from '../dashboard.constants';
import { SEARCH_FILTER } from '../shared/search-filter/search-filter.constants';
import { SearchFilterModel } from '../shared/search-filter/search-filter.model';

export const PLAYGROUND_MENU_OPTIONS = {
  CLUSTER: 'CLUSTER',
  GRAPH: 'GRAPH',
  RECOMMENDATIONS: 'RECOMMENDATIONS',
  EXTERNAL: 'EXTERNAL',
};

export const PLAYGROUND_MENU_CONFIGS = [
  {
    name: 'Cluster',
    path: 'cluster',
    isSelected: true,
    isDisabled: false,
    code: PLAYGROUND_MENU_OPTIONS.CLUSTER,
    fullPath: CONSTANTS.PLAYGROUND.UI_PATH.UI_PATH_CLUSTER,
    order: 1,
  } as DashboardMenuConfig,
  {
    name: 'Graph',
    path: 'graph',
    isSelected: false,
    isDisabled: false,
    code: PLAYGROUND_MENU_OPTIONS.GRAPH,
    fullPath: CONSTANTS.PLAYGROUND.UI_PATH.UI_PATH_GRAPH,
    order: 2,
  } as DashboardMenuConfig,
  {
    name: 'Recommendations',
    path: 'recommendations',
    isSelected: false,
    isDisabled: false,
    code: PLAYGROUND_MENU_OPTIONS.RECOMMENDATIONS,
    fullPath: CONSTANTS.PLAYGROUND.UI_PATH.UI_PATH_RECOMMENDATIONS,
    order: 3,
  } as DashboardMenuConfig,
];

export const LOG_VIEW_NAMES = {
  PLAYGROUND_CLUSTER: 'pgCluster',
};

export const TIME_INTERVALS = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
};

export const LOG_VIEW_NAMES_CONFIG = {
  PLAYGROUND_CLUSTER: Object.assign(
    {},
    {
      viewName: LOG_VIEW_NAMES.PLAYGROUND_CLUSTER,
      metric: PLAYGROUND_METRICS.STATS,
      dataPath: CONSTANTS.PLAYGROUND.API.BASE_PATH,
      showLatencyChart: false,
      showAnalyticsChart: false,
      showDetails: false,
      showMisc: false,
      showTableFilter: true,
      listenForDateRangeFilterChange: true,
      applyNoFilters: false,
    }
  ),
};

export const PLAYGROUND_CLUSTER_ADVANCED_FILTER_CONFIG = [
  {
    name: 'Source Name',
    key: 'source',
    type: SEARCH_FILTER.TYPES.AUTOCOMPLETE,
    isColumnFilter: true,
  } as SearchFilterModel,
  {
    name: 'Source Region',
    key: 'srcRegion',
    type: SEARCH_FILTER.TYPES.AUTOCOMPLETE,
    isColumnFilter: true,
  } as SearchFilterModel,
  {
    name: 'Source IP',
    key: 'sourceIP',
    type: SEARCH_FILTER.TYPES.AUTOCOMPLETE,
    isColumnFilter: true,
  } as SearchFilterModel,
  {
    name: 'Target Name',
    key: 'target',
    type: SEARCH_FILTER.TYPES.AUTOCOMPLETE,
    isColumnFilter: true,
  } as SearchFilterModel,
  {
    name: 'Target Region',
    key: 'targetRegion',
    type: SEARCH_FILTER.TYPES.AUTOCOMPLETE,
    isColumnFilter: true,
  } as SearchFilterModel,
  {
    name: 'Target IP',
    key: 'targetIP',
    type: SEARCH_FILTER.TYPES.AUTOCOMPLETE,
    isColumnFilter: true,
  } as SearchFilterModel,
  {
    name: 'Total Data (MB)',
    key: 'totalData',
    type: SEARCH_FILTER.TYPES.RANGE,
    advanceConfigs: {
      isDataField: true,
      unit: 'MB',
    },
  } as SearchFilterModel,
  {
    name: 'Data Sent (MB)',
    key: 'dataSent',
    type: SEARCH_FILTER.TYPES.RANGE,
    advanceConfigs: {
      isDataField: true,
      unit: 'MB',
    },
  } as SearchFilterModel,
  {
    name: 'Data Received (MB)',
    key: 'dataRcvd',
    type: SEARCH_FILTER.TYPES.RANGE,
    advanceConfigs: {
      isDataField: true,
      unit: 'MB',
    },
  } as SearchFilterModel,
  {
    name: `Total Latency`,
    key: 'totalLatency',
    type: SEARCH_FILTER.TYPES.RANGE,
  } as SearchFilterModel,
  {
    name: `Request Count`,
    key: 'requestCount',
    type: SEARCH_FILTER.TYPES.RANGE,
  } as SearchFilterModel,
  {
    name: `Blocked Policy Count`,
    key: 'policyBlockedCount',
    type: SEARCH_FILTER.TYPES.RANGE,
  } as SearchFilterModel,
  {
    name: `WAF Blocked Count`,
    key: 'wafBlockedCount',
    type: SEARCH_FILTER.TYPES.RANGE,
  } as SearchFilterModel,
  {
    name: `WAF Composite Count`,
    key: 'wafCompositeCount',
    type: SEARCH_FILTER.TYPES.RANGE,
  } as SearchFilterModel,
  {
    name: `WAF Detected Count`,
    key: 'wafDetectedCount',
    type: SEARCH_FILTER.TYPES.RANGE,
  } as SearchFilterModel,
];

export const CHART_COLORS = {
  GREEN: '#b3de69',
  ORANGE: '#fdbf99',
  AQUA_BLUE: '#7cdbd3',
  PURPLE: '#8680bd',
  GREY: '#f4f6f9',
};
