import { SUBSCRIPTION_TYPES } from '@app/common/util/constants';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'IsViewTypeProTransitPipe',
})
export class IsViewTypeProTransitPipe implements PipeTransform {
  transform(data: any): boolean {
    if (data && data === SUBSCRIPTION_TYPES.MCN) {
      return true;
    } else {
      return false;
    }
  }
}
