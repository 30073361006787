import { DashboardSubMenuConfig } from './dashboard-sub-menu.config';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DashboardSubMenuService {
  private selectedSubMenuItem$ = new Subject<DashboardSubMenuConfig>();

  constructor() {}

  setSelectedSubMenuItem(selectedSubMenuItemConfig: DashboardSubMenuConfig) {
    this.selectedSubMenuItem$.next(selectedSubMenuItemConfig);
  }

  getSelectedSubMenuItem(): Observable<DashboardSubMenuConfig> {
    return this.selectedSubMenuItem$.asObservable();
  }
}
