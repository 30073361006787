import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { ALL } from '@app/dashboard/insights/insights.constants';

@Pipe({
  name: 'insightPrintFilterValue',
})
export class InsightPrintFilterValuePipe implements PipeTransform {
  transform(data: any, ...args: any[]): string {
    const [params = {}, isMore = false] = args || [];
    const {
      selectedKey = '',
      totalKey = '',
      isTimeRange,
      targetKey = '',
      selectedAllText = '',
    } = params || {};
    const selected = data[selectedKey];
    const total = data[totalKey];
    const selectedLength = selected?.length || 0;
    if (isMore && !isTimeRange) {
      return selectedLength > 4 ? `+ ${selectedLength - 4} more` : '';
    } else if (!isMore && isTimeRange) {
      return `${this.getDateStr(selected?.from)} - ${this.getDateStr(
        selected?.to
      )}`;
    } else if (!isMore && !isTimeRange) {
      return (
        (selectedLength === total ||
        (selectedLength === 1 && selected[0][targetKey] === ALL) ||
        selectedLength === 0
          ? 'All '
          : `${selectedLength} `) + selectedAllText
      );
    }
  }

  getDateStr(date: any): string {
    const datePipe = new DatePipe('en-US');
    return `${this.getDay(datePipe, date)} ${this.getDate(
      datePipe,
      date
    )} ${this.getTime(datePipe, date)}`;
  }

  getDay(datePipe: any, data: any): string {
    return `${datePipe.transform(data, 'EEE ')}`;
  }

  getDate(datePipe: any, data: any): string {
    return `${datePipe.transform(data, 'MMMM d, y')}`;
  }

  getTime(datePipe: any, data: any): string {
    return `${datePipe.transform(data, 'h:mm:ss a')}`;
  }
}
