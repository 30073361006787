import { L2HeaderService } from '@app/component-common/l2header/l2header.service';
import { DashboardMenuConfig } from './dashboard-menu.config';
import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { DashboardMenuService } from './dashboard-menu.service';
import { AppUtil } from '@app/common/util/app-util';

@Component({
  selector: 'app-dashboard-menu',
  templateUrl: './dashboard-menu.component.html',
  styleUrls: ['./dashboard-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardMenuComponent implements OnInit {
  configs: Array<DashboardMenuConfig>;
  @Input('configs') set setConfigs(data: Array<DashboardMenuConfig>) {
    this.configs = this.addIgClassNameToConfigs(data);
  }

  constructor(
    private dashboardMenuService: DashboardMenuService,
    private l2HeaderService: L2HeaderService,
    private appUtil: AppUtil,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  /**
   * method to add interactive guide class name to menu config
   * @param configs
   */
  addIgClassNameToConfigs(
    configs: Array<DashboardMenuConfig>
  ): Array<DashboardMenuConfig> {
    const modifiedConfigs = this.appUtil.clone(configs);
    if (modifiedConfigs && modifiedConfigs.length) {
      modifiedConfigs.forEach((config) => {
        config.igClassName = `ig__${config.name
          .split(' ')
          .join('_')
          .toLowerCase()}`;
      });
    }
    return modifiedConfigs;
  }

  /**
   * Method to set the selected menu item
   * @param selectedConfig - selected menu item configuration
   */
  setSelectedMenuItem(selectedConfig: DashboardMenuConfig) {
    if (selectedConfig && selectedConfig?.isDisabled) {
      return;
    }
    this.configs.forEach((menuItem) => {
      if (menuItem.name.toLowerCase() === selectedConfig.name.toLowerCase()) {
        menuItem.isSelected = true;
      } else {
        menuItem.isSelected = false;
      }
    });
    this.l2HeaderService.setL2HeaderPageInfo({
      pageName: selectedConfig?.name,
    });
    this.dashboardMenuService.setSelectedMenuItem(selectedConfig);
    this.cdr.markForCheck();
  }
}
