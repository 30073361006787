import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  ViewEncapsulation,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Router } from '@angular/router';
import { CLOUD_TYPES } from '@app/common/util/constants';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MenuItemComponent implements OnInit {
  contextMenuValueData: any;
  @Input()  set contextMenuValues(data) {
   this.contextMenuValueData = this.addStyleClassToValues(data);
  };
  @Output() valueChange = new EventEmitter();
  @ViewChild('itemMenu', { static: true }) public itemMenu;

  constructor(public router: Router) {}

  ngOnInit() {}

  selectedMenuOption(item) {
    this.valueChange.emit(item);
  }

  addStyleClassToValues(data: any) {
    return data.map(item => {
      let className = ''
      switch(item?.name) {
        case CLOUD_TYPES.AWS: 
          className = 'cloud_aws';
          break;
        case CLOUD_TYPES.AZURE:
          className = 'cloud_azure';
          break;
        case CLOUD_TYPES.GCP:
          className = 'cloud_gcp';
      }
      Object.assign(item, {className})
      return item;
    })
  }
}
