<!-- party.js way -->
<!-- <div
  [ngClass]="{
    'd-none': !confettiState,
    'd-inline-block': confettiState
  }"
>
  <div id="confetti-container" class="confetti-container"></div>
</div> -->

<!-- without party.js way -->
<div
  class="box"
  id="box"
  [ngClass]="{
    'd-none': !confettiState,
    'd-inline-block': confettiState
  }"
></div>
