import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { APP_LOCALE } from '@app/common/util/constants';
import { DateUtil } from '@prosimoio/services';
import { TimePickerModel } from '@app/component-common/time-picker/time-picker.model';

@Component({
  selector: 'app-time-filter',
  templateUrl: './time-filter.component.html',
  styleUrls: ['./time-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeFilterComponent implements OnInit {
  @Input() showPanelHeader: boolean = true;
  @Input() includeAdditionalDateFilters: boolean = false;

  @Input() set customDateTime(date) {
    if (date && !isNaN(date)) {
      const recievedDate = new Date(date);
      this.selectedDate = recievedDate.getTime();
      this.selectedTime = {
        hour: recievedDate.getHours(),
        minutes: recievedDate.getMinutes(),
        seconds: recievedDate.getSeconds(),
      };

      if (this.includeAdditionalDateFilters) {
        this.selectedDate = '';
        this.selectedCustomDate = 'LIVE';
      }
    }
    this.dates = this.getDates(date);
  }

  @Input() defaultSelectedDate = new Date().getTime();

  @Output() onTimeChange = new EventEmitter();
  @Output() onDateChange = new EventEmitter();
  @Output() onDateAndTimeChange = new EventEmitter();
  @Output() onFromToDatesChange = new EventEmitter();
  dates = [];
  timePickerLabel = `Select a time (hh : mm : ss)`;
  selectedTime: TimePickerModel;
  selectedDate: any;
  selectedDateTime: any;
  private datePipe: DatePipe = new DatePipe(APP_LOCALE.EN_US);

  LIVE_DATA = {
    LIVE: -1,
    DATE: new Date().getTime(),
    TIME: {
      hour: new Date().getHours(),
      minutes: new Date().getMinutes(),
      seconds: new Date().getSeconds(),
    },
  };

  intervalMessage = {
    LAST_72_HOURS: '72 hours',
    LAST_5_MINUTES: '5 minutes',
  };

  additionalTimeFilters: Array<any> = [
    { displaName: 'Live Data', value: 'LIVE' },
    { displaName: 'Last 72 Hours', value: 'LAST_72_HOUR' },
  ];
  selectedCustomDate: string = '';
  dateFormats = DateUtil.DATE_FORMATS;

  constructor(private cdr: ChangeDetectorRef) {
    if (!this.customDateTime || isNaN(this.customDateTime)) {
      this.selectedDate = this.defaultSelectedDate
        ? this.defaultSelectedDate
        : this.LIVE_DATA.DATE;
      this.selectedTime = this.LIVE_DATA.TIME;
      this.dates = this.getDates();
    }
  }

  ngOnInit(): void {
    const matchedDate = this.dates.find(
      (date) => date === this.defaultSelectedDate
    );
    const isDateExist = this.defaultSelectedDate && matchedDate;

    this.selectedDate = isDateExist
      ? this.defaultSelectedDate
      : this.selectedDate;

    if (this.includeAdditionalDateFilters) {
      this.selectedCustomDate = this.additionalTimeFilters[0].value;
      this.selectedDate = '';
      this.onCustomDateChangeDate(this.additionalTimeFilters[0]);
    }
  }

  /**
   * Return the transform current date time
   */
  getCurrentDateTime() {
    return `Live ${this.datePipe.transform(
      new Date().getTime(),
      DateUtil.DATE_FORMATS.FORMAT_DAY_MON_DATE_YR
    )}`;
  }

  /**
   * Return dates of today and last two days
   */
  getDates(date?: any) {
    const today = date ? new Date(date) : new Date();
    const yesterday = date ? new Date(date) : new Date();
    const dayBeforeYesterday = date ? new Date(date) : new Date();

    today.setDate(today.getDate());
    yesterday.setDate(today.getDate() - 1);
    dayBeforeYesterday.setDate(today.getDate() - 2);

    const dates = [today, yesterday, dayBeforeYesterday].map((item) =>
      item.getTime()
    );
    return dates;
  }

  /**
   * Set the time
   * @param selectedTime
   */
  onChangeTime(selectedTime: TimePickerModel = this.LIVE_DATA.TIME) {
    this.selectedTime = selectedTime;
    this.updateDateTime();
    this.onTimeChange.emit(selectedTime);
    this.cdr.markForCheck();
  }

  /**
   * Set the date
   * @param selectedDate
   */
  onChangeDate(selectedDate: any = new Date()) {
    this.updateCurrentTime();
    this.selectedCustomDate = '';
    this.selectedDate = selectedDate;
    this.updateDateTime();
    this.onDateChange.emit(selectedDate);
    this.cdr.markForCheck();
  }

  onCustomDateChangeDate(selectedDate) {
    this.updateCurrentTime();
    this.selectedDate = '';
    this.selectedCustomDate = selectedDate?.value;

    const time = new Date().getTime();
    let finalTime;
    if (this.selectedCustomDate === this.additionalTimeFilters[1]?.value) {
      const from = DateUtil.getPastNDays(time, 3);
      const to = time + 1000;
      finalTime = { from, to, interval: this.intervalMessage.LAST_72_HOURS };
    } else {
      finalTime = {
        from: DateUtil.getPastNMinutes(time, 5),
        to: time,
        interval: this.intervalMessage.LAST_5_MINUTES,
      };
    }
    this.onFromToDatesChange.emit(finalTime);
    this.cdr.markForCheck();
  }

  /**
   * Update the date object
   */
  updateDateTime() {
    if (this.selectedDate) {
      const time = this.getUpdatedDateTime();
      this.onDateAndTimeChange.emit(time);
      this.onFromToDatesChange.emit({
        from: DateUtil.getPastNMinutes(time, 5),
        to: time,
        interval: this.intervalMessage.LAST_5_MINUTES,
      });
      this.cdr.markForCheck();
    }
  }

  getUpdatedDateTime() {
    const currentDate = new Date(this.selectedDate);

    const {
      hour = 0,
      minutes = 0,
      seconds = 0,
    } = this.selectedTime || {
      hour: currentDate.getHours(),
      minutes: currentDate.getMinutes(),
      seconds: currentDate.getSeconds(),
    };
    this.selectedDateTime = currentDate;

    if (hour || hour === 0) {
      this.selectedDateTime.setHours(hour);
    }
    if (minutes || minutes === 0) {
      this.selectedDateTime.setMinutes(minutes);
    }
    if (seconds || seconds === 0) {
      this.selectedDateTime.setSeconds(seconds);
    }

    return this.selectedDateTime.getTime();
  }

  updateCurrentTime() {
    this.selectedTime = {
      hour: new Date().getHours(),
      minutes: new Date().getMinutes(),
      seconds: new Date().getSeconds(),
    };
  }

  getTimeTooltip(time, datePipe, dateFormat) {
    const currentTime = new Date().getTime();
    let from = DateUtil.getPastNMinutes(time, 5),
      to = time;

    if (time === 'LIVE') {
      from = DateUtil.getPastNMinutes(currentTime, 5);
      to = currentTime;
    }

    if (time === 'LAST_72_HOUR') {
      from = DateUtil.getPastNDays(currentTime, 3);
      to = currentTime + 1000;
    }

    return `
        <div class='d-flex flex-column'>
          <div class="d-flex">
            <span class="font-weight-bold pr-1" style="width:40px;">From:</span> 
            <span class="font-weight-normal text-left">
              ${datePipe.transform(from, dateFormat)}
            </span>
          </div>
          <div class="d-flex mt-2">
            <span class="font-weight-bold pr-1" style="width:40px;">To:</span> 
            <span class="font-weight-normal text-left">
              ${datePipe.transform(to, dateFormat)}
            </span>
          </div>
        </div>`;
  }
}
