<div class="mx-4 my-2">
  <p class="clr-grey5 page__hint">
    <img
      class="error-icon"
      *ngIf="errorPayload?.errorStep === step"
      [src]="errorIcon"
    />
    {{ hint }}
  </p>
  <mat-accordion
    [formGroup]="definitionForm"
    [multi]="true"
    class="bordered-panel"
  >
    <mat-expansion-panel
      [expanded]="true"
      [hideToggle]="true"
      class="form-panel"
    >
      <mat-expansion-panel-header class="form-panel__header pe-none arrow">
        <mat-panel-title>Namespace settings</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="d-flex flex-column">
        <mat-label class="d-flex mb-2 form__label">
          Assign this Network to a Namespace
        </mat-label>
        <pcomp-mat-select-search
          *ngIf="!loading.namespaces"
          [parentForm]="definitionForm"
          [config]="selectNamespaceConfig"
          [disableStatus]="false"
          [formControlNameInput]="definitionForm.controls.namespace"
          controlName="namespace"
          class="w-50 pr-2 mat-form-field-wrapper-remove-padding"
        ></pcomp-mat-select-search>
        <div *ngIf="loading.namespaces">Loading Namespaces...</div>

        <div>
          <mat-checkbox
            class="mt-2"
            [formControl]="definitionForm.controls.exportable"
          >
            Mark network exportable in policy (Recommended)
          </mat-checkbox>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel
      [expanded]="true"
      [hideToggle]="true"
      class="form-panel"
    >
      <mat-expansion-panel-header class="form-panel__header pe-none arrow">
        <mat-panel-title>Network details</mat-panel-title>
      </mat-expansion-panel-header>
      <mat-label class="d-flex mb-2 form__label">
        Where is your network hosted?
      </mat-label>
      <mat-radio-group [formControl]="definitionForm.controls.cloudType">
        <mat-radio-button color="primary" value="public" class="ml-n1 pl-0">
          Public
        </mat-radio-button>
        <mat-radio-button color="primary" value="private" class="pl-2">
          Private
        </mat-radio-button>
      </mat-radio-group>
      <div class="d-flex flex-row mt-2 mb-3">
        <div class="d-flex flex-column pr-2 mr-1 w-50">
          <pcomp-multi-level-select
            *ngIf="multiSelectConfig"
            [config]="multiSelectConfig"
            (onAction)="onAction($event)"
          ></pcomp-multi-level-select>
          <mat-form-field
            *ngIf="
              !loading.clouds &&
              definitionForm.controls.cloudType.value ===
                COPY_NETWORK_HOSTED_TYPE.PRIVATE &&
              privateClouds.length > 0 &&
              definitionForm.controls.privateCloud
            "
            class="mat-form-field-wrapper-remove-padding"
            appearance="outline"
          >
            <mat-select
              [formControl]="
                definitionForm.controls.privateCloud.controls.privateCloudID
              "
              placeholder="Account"
              panelClass="mat-panel__bg--{{
                uiThemeMode
              }}-theme-light select-width-lg"
              disableRipple
              disableOptionCentering
            >
              <mat-option
                *ngFor="let cloud of privateClouds"
                [value]="cloud.id"
              >
                {{ cloud?.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div
            *ngIf="
              !loading.clouds &&
              definitionForm.controls.cloudType.value ===
                COPY_NETWORK_HOSTED_TYPE.PRIVATE &&
              privateClouds.length === 0
            "
          >
            No cloud accounts found
          </div>
          <div *ngIf="loading.clouds">Loading Cloud Accounts...</div>
        </div>
        <div
          class="d-flex flex-column pl-2 ml-1 w-50"
          *ngIf="
            definitionForm.controls.cloudKeyID.value &&
            definitionForm.controls.cloudType.value ===
              COPY_NETWORK_HOSTED_TYPE.PUBLIC
          "
        >
          <div class="d-flex">
            <mat-label class="mb-2 form__label"> Region </mat-label>
            <pcomp-mat-error-info-tooltip
              class="ml-2"
              [errorMessageConfig]="{
                isShowTooltip: false,
                errorCondition:
                  definitionForm.controls.cloudRegion?.errors?.cluster,
                errorMessage: 'Edge Not Deployed in this region'
              }"
              [uiThemeMode]="uiThemeMode"
            ></pcomp-mat-error-info-tooltip>
          </div>
          <pcomp-mat-select-search
            *ngIf="
              !loading.regions && this.selectRegionConfig.optionList.length > 0
            "
            [parentForm]="definitionForm"
            [config]="selectRegionConfig"
            [disableStatus]="!isEditable"
            [formControlNameInput]="definitionForm.controls.cloudRegion"
            controlName="cloudRegion"
            class="mat-form-field-wrapper-remove-padding"
          ></pcomp-mat-select-search>
          <div *ngIf="loading.regions">Loading Regions...</div>
          <mat-error *ngIf="!loading.regions && this.regions.length === 0">
            No regions with an active edge
          </mat-error>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      [expanded]="true"
      [hideToggle]="true"
      class="form-panel"
      *ngIf="
        (definitionForm.controls.cloudType.value ===
          COPY_NETWORK_HOSTED_TYPE.PUBLIC &&
          definitionForm.controls.cloudRegion.value) ||
        (definitionForm.controls.privateCloud?.controls.privateCloudID.value &&
          definitionForm.controls.cloudType.value ===
            COPY_NETWORK_HOSTED_TYPE.PRIVATE) ||
        definitionForm.controls.cloudType.value === COPY_NETWORK_HOSTED_TYPE.OWN
      "
    >
      <mat-expansion-panel-header class="form-panel__header pe-none arrow">
        <mat-panel-title>Subnet Selection</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="d-flex flex-column">
        <mat-label class="d-flex mb-2 form__label">
          Select subnets to add to this network
        </mat-label>
        <ng-container
          *ngIf="
            definitionForm.controls.cloudType.value ===
              COPY_NETWORK_HOSTED_TYPE.PUBLIC;
            then publicVPCs;
            else privatevpcs
          "
        ></ng-container>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<ng-template #publicVPCs>
  <div *ngIf="loading.vpcs" class="my-3">Loading VPCs...</div>
  <ng-container *ngIf="!loading.vpcs">
    <pcomp-multi-select-dropdown
      *ngIf="vpcDropdownDetails.length"
      class="w-50 my-3 pr-2 d-block"
      [ngClass]="{
        'pe-none': false
      }"
      [treeData]="vpcDropdownDetails"
      [styleConfig]="styleConfig"
      [themeShade]="uiThemeMode + '-theme-light bg-dark-shade'"
      [filterTriggerText]="dropdownPlaceholder"
      [isShowSelectAll]="false"
      [highlightSelectedItem]="true"
      [highlightChild]="false"
      [showChildNodeCheckbox]="true"
      [tooltipThemeClass]="tooltipThemeClass"
      [isHideTooltip]="false"
      [matTooltipPosition]="'right'"
      [isTextSelected]="
        definitionForm.controls.publicCloud.controls.cloudNetworks.value
          ?.length > 0
      "
      [searchOnKey]="true"
      (selectedItems)="selectVPC($event)"
    >
    </pcomp-multi-select-dropdown>
    <mat-error *ngIf="!vpcDropdownDetails.length" class="my-3">
      No {{ COPY_CSP_PC[definitionForm.controls.csp.value] }}s found
    </mat-error>
    <div
      *ngIf="
        vpcDropdownDetails.length &&
        definitionForm.controls.publicCloud.controls.cloudNetworks.value
          ?.length > 0
      "
    >
      <mat-checkbox
        class="mt-2"
        [checked]="useVirtualSubnets"
        [disabled]="!isEditable"
        (change)="setUseVirtualSubnets($event)"
      >
        Virtual Subnets
      </mat-checkbox>
    </div>
    <ng-container *ngIf="useVirtualSubnets">
      <div class="d-flex flex-column">
        <ng-container
          *ngFor="let subnetGroup of virtualSubnets.controls; let index = index"
        >
          <div class="d-flex flex-row mt-2" [formGroup]="subnetGroup">
            <div class="d-flex flex-column pr-2 mr-1">
              <mat-label class="mb-2 form__label"> Selected Subnet </mat-label>
              <mat-form-field
                class="mat-form-field-wrapper-remove-padding"
                appearance="outline"
              >
                <mat-select
                  formControlName="subnet"
                  placeholder="Subnet"
                  panelClass="mat-panel__bg--{{
                    uiThemeMode
                  }}-theme-light select-width-lg"
                  disableRipple
                  disableOptionCentering
                >
                  <ng-container *ngFor="let selSubnet of allSubnets">
                    <mat-option
                      *ngIf="
                        !selSubnet.virtualSubnet ||
                        subnetGroup.controls.subnet.value === selSubnet.subnet
                      "
                      [value]="selSubnet.subnet"
                    >
                      {{ selSubnet.subnet }}
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div>
            <div
              class="d-flex flex-column pl-2 ml-1"
              [ngClass]="{
                'invalid-range':
                  subnetGroup.controls.virtualSubnet?.errors &&
                  subnetGroup.controls.virtualSubnet.touched
              }"
            >
              <div class="d-flex">
                <mat-label class="d-flex flex-shrink-0 mb-2 form__label">
                  Virtual Subnet
                </mat-label>
                <pcomp-mat-error-info-tooltip
                  class="ml-2"
                  [errorMessageConfig]="{
                    isShowTooltip: false,
                    errorCondition:
                      subnetGroup.controls.virtualSubnet?.errors &&
                      subnetGroup.controls.virtualSubnet.touched,
                    errorMessage:
                      subnetGroup.controls.virtualSubnet?.errors | subnetError
                  }"
                  [uiThemeMode]="uiThemeMode"
                ></pcomp-mat-error-info-tooltip>
              </div>
              <mat-form-field
                appearance="outline"
                class="mat-form-field-wrapper-remove-padding"
              >
                <input
                  matInput
                  placeholder="Enter Subnet"
                  formControlName="virtualSubnet"
                  autocomplete="off"
                />
              </mat-form-field>
            </div>
            <div class="d-flex flex-column pl-2 ml-1">
              <mat-label class="mb-2 form__label">&nbsp;</mat-label>
              <img
                class="icon image cursor-pointer my-auto"
                [ngClass]="{
                  'pe-none': !isEditable
                }"
                (click)="removeVirtualSubnet(index)"
                [src]="binUrl"
              />
            </div>
          </div>
        </ng-container>
        <div class="add_btn my-3">
          <span
            [ngClass]="[
              virtualSubnets.controls.length === allSubnets.length ||
              virtualSubnets.invalid
                ? 'pe-none clr-grey4'
                : 'blue-link cursor-pointer'
            ]"
            (click)="addVirtualSubnet()"
            >Add Virtual Subnet</span
          >
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #privatevpcs>
  <div class="d-flex pb-2">
    <mat-label class="form__label">Enter Subnets</mat-label>
    <pcomp-mat-error-info-tooltip
      class="ml-2"
      [errorMessageConfig]="{
        isShowTooltip: false,
        errorCondition: subnetControl?.errors && subnetControl.touched,
        errorMessage: subnetControl?.errors?.pattern
          ? 'IP Address is not valid'
          : 'IP Address is required'
      }"
      [uiThemeMode]="uiThemeMode"
    ></pcomp-mat-error-info-tooltip>
  </div>
  <div
    [ngClass]="{
      'invalid-range': subnetControl?.errors && subnetControl?.touched
    }"
  >
    <div class="d-flex">
      <mat-form-field appearance="outline" class="w-50">
        <div class="d-flex">
          <input
            matInput
            placeholder="Enter Subnet"
            [formControl]="subnetControl"
            autocomplete="off"
            (keyup.enter)="addSubnet()"
          />
        </div>
      </mat-form-field>
      <div class="add_btn ml-3 mt-3">
        <span
          [ngClass]="[
            subnetControl?.errors
              ? 'pe-none clr-grey4'
              : 'blue-link cursor-pointer'
          ]"
          (click)="addSubnet()"
          >Add</span
        >
      </div>
    </div>
  </div>

  <pcomp-minimized-list
    *ngIf="selectedSubnetListData?.length"
    class="mb-3"
    [data]="selectedSubnetListData"
    [isArray]="true"
    minVisibleCount="3"
    (elementRemoved)="removeSubnetFromList($event)"
  ></pcomp-minimized-list>
</ng-template>
