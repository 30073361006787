import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-status-pill',
  templateUrl: './status-pill.component.html',
  styleUrls: ['./status-pill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusPillComponent implements OnInit {
  STATUS = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    FAILED: 'FAILED',
  };
  @Input() statusConfig: any;
  textColor = 'clr-grey4';
  backgroundColor = 'bg-grey0';
  borderColor = 'bdr-grey3';
  constructor() {}

  ngOnInit() {}
}
