import { Pipe, PipeTransform } from '@angular/core';
import { IMAGE_PATHS } from '@app/common/util/constants';

export const NETWORK_CSP_ICONS = {
  AWS: IMAGE_PATHS.COMMON.CSP_AWS,
  GCP: IMAGE_PATHS.COMMON.CSP_GOOGLE,
  AZURE: IMAGE_PATHS.COMMON.CSP_AZURE,
  PRIVATE: IMAGE_PATHS.COMMON.CSP_PRIVATE,
  CSP: IMAGE_PATHS.APP_ONBOARD.DISCOVERY.CLOUD,
};

@Pipe({
  name: 'cspIcon',
})
export class CSPIconPipe implements PipeTransform {
  transform(value: any): string {
    return NETWORK_CSP_ICONS[value] || NETWORK_CSP_ICONS.CSP;
  }
}
