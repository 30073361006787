import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  OnChanges,
  ViewChild,
  AfterViewInit,
  EventEmitter,
  ChangeDetectorRef,
  Inject,
  ViewChildren,
} from '@angular/core';
import { AutoComplete } from '@app/common/components/auto-complete/auto-complete.model';
import { ObjectUtil } from '@prosimoio/services';
import {
  OPERATORS_DISPLAY_NAMES,
  SEARCH_FILTER,
} from './search-filter.constants';
import { SearchFilterService } from './search-filter.service';
import { SelectPill } from '@app/common/components/select-pill/select-pill.model';
import {
  ADVANCE_SEARCH_FILTER_OPERATORS,
  ADVANCE_SEARCH_REGEX,
  FILTER_OPERATORS,
  SearchFilterModel,
} from './search-filter.model';
import { ArrayUtil } from '@prosimoio/services';
import { MatMenu } from '@angular/material/menu';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import {
  DATA_MULTIPLES,
  IMAGE_PATHS,
} from '@app/common/util/constants';
import { AppUtil } from '@app/common/util/app-util';
import { PDashLocalStoreUtil } from '@app/state/web-pdash.util';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFilterComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChildren('fieldsDropdowns') fieldsDropdowns;
  @ViewChild('fieldsMenu') fieldsMenu!: MatMenu | any;
  @ViewChild('scrollMe') private scrollMe: ElementRef;
  @Input() filterRowColumns;
  @Input() isV2View: boolean;
  @Input() showCount: boolean;
  @Input() isBadgeView: boolean;
  autoCompleteMap;
  selectPillMap;
  numberOperatorMap;
  checkboxGroups = {};
  autoCompleteSet;
  columnFilterSet;
  exactMatchFilterSet;
  filterValues = null;
  allFilters = null;
  SEARCH_FILTER = SEARCH_FILTER;
  FILTER_OPERATORS = FILTER_OPERATORS;
  selectedColumnList = [];
  newSelections = [];
  operators = ADVANCE_SEARCH_FILTER_OPERATORS;
  ADVANCE_SEARCH_REGEX = ADVANCE_SEARCH_REGEX;
  OPERATORS_DISPLAY_NAMES = OPERATORS_DISPLAY_NAMES;

  subscriptions = [];

  // theming stuff
  uiThemeMode: string = PDashLocalStoreUtil?.getUIThemeMode()?.toLowerCase();
  themeShade = 'light-theme-light';
  tooltipThemeClass: string = '';
  filterImgUrl = IMAGE_PATHS.COMMON.FILTER_ICON;
  isMenuOpened = false;
  logFilterImgUrl = IMAGE_PATHS.COMMON.LOG_FILTER_ICON;

  constructor(
    private searchFilterService: SearchFilterService,
    private cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: any,
    private appUtil: AppUtil
  ) {
    PDashLocalStoreUtil?.getUIThemeModeAsObservable()?.subscribe((mode) => {
      this.uiThemeMode = mode?.toLowerCase() + '-theme';
      this.themeShade = `${this.uiThemeMode}-light`;
      this.tooltipThemeClass =
        'cdk-component-container--' + this.uiThemeMode + '-dark';
      this.subscriptions.push(
        this.searchFilterService
          .getResetAdvanceSearchFilter()
          .subscribe((disableCalls) => {
            this.clearAllFilters(disableCalls);
          })
      );

      this.cdr?.markForCheck();
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {
    // Inject our custom logic of menu close
    if (this.fieldsMenu) {
      (this.fieldsMenu as any).closed = this.fieldsMenu.closed =
        this.configureMenuClose(this.fieldsMenu?.close);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.filterRowColumns) {
      this.filterRowColumns = this.appUtil.sort(this.filterRowColumns, 'name');
      const autocompleteFields = this.filterRowColumns
        .filter((field) => field?.type === SEARCH_FILTER.TYPES.AUTOCOMPLETE)
        .map((config) => config);
      const rangeFields = this.filterRowColumns
        .filter((field) => field?.type === SEARCH_FILTER.TYPES.RANGE)
        .map(({ key, configs, selectedKey }) => ({
          key,
          configs,
          selectedKey,
        }));
      const checkboxGroupFields = this.filterRowColumns
        .filter((field) => field?.type === SEARCH_FILTER.TYPES.CHECKBOX_GROUP)
        .map(({ key, configs }) => ({ key, configs }));
      const selectPillFields = this.filterRowColumns
        .filter((field) => field?.type === SEARCH_FILTER.TYPES.SELECT_PILL)
        .map(({ key, configs }) => ({ key, configs }));
      this.setAutocompleteData(autocompleteFields);
      this.setRangeData(rangeFields);
      this.setCheckboxGroupFields(checkboxGroupFields);
      this.setSelectPillFields(selectPillFields);
      this.setSearchTypeFilterKeys();
    }
  }

  setAutocompleteData(autoCompleteFields: Array<any>) {
    const autoCompleteConfig = [];
    autoCompleteFields.forEach((field) => {
      const autoCompleteInst = new AutoComplete('', field?.selected);
      autoCompleteConfig?.push([field?.key, autoCompleteInst]);
    });
    this.autoCompleteMap = new Map(autoCompleteConfig);
  }

  setRangeData(rangeFields: Array<any>) {
    const numberOperatorConfigs = [];
    rangeFields.forEach((field) => {
      numberOperatorConfigs.push([
        field?.key,
        { values: field?.configs, selectedKey: field?.selectedKey },
      ]);
    });
    this.numberOperatorMap = new Map(numberOperatorConfigs);
  }

  setCheckboxGroupFields(checkboxGroupFields: Array<any>) {
    checkboxGroupFields.forEach(({ key, configs }) => {
      this.checkboxGroups[key] = configs;
    });
  }

  setSelectPillFields(selectPillFields: Array<any>) {
    const selectPillConfigs = [];
    selectPillFields.forEach((field) => {
      const selectPillInst = new SelectPill(
        '',
        field?.configs?.dropDown,
        field?.configs?.targetKey
      );
      selectPillConfigs.push([field?.key, selectPillInst]);
    });
    this.selectPillMap = new Map(selectPillConfigs);
  }

  setSearchTypeFilterKeys() {
    // get the config items from the fields which hasSecondary equal true
    const flatItems = this.filterRowColumns
      .filter((field) => field?.hasSecondary)
      .reduce((items, field) => items.concat(field?.configs), [])
      .filter((item) => item?.isSecondary);

    const combinedColumns = this.filterRowColumns.concat(flatItems);

    // column filters
    const columnFilters = combinedColumns
      .filter((field: SearchFilterModel) => field?.isColumnFilter)
      .map(({ key }) => key);
    this.columnFilterSet = ArrayUtil.getUniqueArray(columnFilters);

    // exact match filters
    const exactFilters = combinedColumns
      .filter((field: SearchFilterModel) => field?.isExactFilter)
      .map(({ key }) => key);
    this.exactMatchFilterSet = ArrayUtil.getUniqueArray(exactFilters);
  }

  /**
   * Get the filtered data
   */
  refreshData(disableCalls?: boolean) {
    if (
      ObjectUtil.hasKeys(this.allFilters) ||
      ObjectUtil.hasKeys(this.filterValues)
    ) {
      this.allFilters = Object.assign({}, this.filterValues);
    }
    const columnFilters = {};
    const exactMatchFilters = {};
    const transformedExactMatchFilters = [];

    if (this.allFilters) {
      const filterKeys = Object.keys(this.allFilters);
      // column filters
      this.columnFilterSet.forEach((columnFilterKey) => {
        if (filterKeys.includes(columnFilterKey)) {
          columnFilters[columnFilterKey] = this.allFilters[columnFilterKey];
          delete this.allFilters[columnFilterKey];
        }
      });
      // exact match filters
      this.exactMatchFilterSet.forEach((exactMatchFilterKey) => {
        if (filterKeys.includes(exactMatchFilterKey)) {
          exactMatchFilters[exactMatchFilterKey] =
            this.allFilters[exactMatchFilterKey];
          delete this.allFilters[exactMatchFilterKey];
        }
      });

      const exactMatchFiltersKeys = Object.keys(exactMatchFilters);
      exactMatchFiltersKeys.forEach((key) => {
        transformedExactMatchFilters.push({
          field: key,
          values: exactMatchFilters[key],
        });
      });
    } else {
      if (disableCalls) {
        this.searchFilterService.setsearchFilterTerms({ disableCalls });
        return;
      }
      this.searchFilterService.setsearchFilterTerms({
        columnFilters: [],
        nonColumnFilters: [],
        exactFilters: [],
      });
      return;
    }
    this.filterSelectedNonColumnFilters();

    const data = {
      columnFilters: this.filterSelectedColumnFilters(columnFilters),
      nonColumnFilters: this.allFilters,
      exactFilters: this.filterSelectedExactFilters(
        transformedExactMatchFilters
      ),
    };

    if (disableCalls) {
      this.searchFilterService.setsearchFilterTerms({ disableCalls });
      return;
    } else {
      this.searchFilterService.setsearchFilterTerms(data);
    }
  }

  /**
   * Listen the values changes
   * @param selection
   * @param param1
   */
  onChangeSelection(selection: any, field) {
    if (!this.filterValues) {
      this.filterValues = {};
    }
    if (field.hasSecondary) {
      this.setMultiColumnsFilterValues(selection, field);
    } else {
      this.setSingleColumnFilterValues(selection, field);
    }
    this.refreshData();
  }

  /**
   * Set the filter values for single column
   * @param selection
   * @param param1
   */
  setSingleColumnFilterValues(selection, field) {
    if (this.numberOperatorMap?.has(field?.key)) {
      this.formatValues(selection, field);
    } else if (this.selectPillMap.has(field?.key)) {
      this.filterValues[field.key] =
        this.formatSelectPillInputValues(selection);
    } else if (!selection.length) {
      delete this.filterValues[field?.key];
    } else {
      this.filterValues[field?.key] = selection;
    }
  }

  /**
   * Set the filter values for multi columns
   * @param selection
   * @param field
   */
  setMultiColumnsFilterValues(selection, field) {
    if (selection.length) {
      const keyValueMap = new Map();
      const selectionSet = new Set(selection);
      field.configs.forEach(({ key, value, primaryValue }) => {
        const itemKey = key || field.key;
        const itemValue = key ? primaryValue : value;
        if (selectionSet.has(value)) {
          const item = keyValueMap.get(itemKey);
          if (item) {
            item.push(itemValue);
          } else {
            keyValueMap.set(itemKey, [itemValue]);
          }
        } else {
          // remove the secondary key from filterValues
          delete this.filterValues[key];
        }
      });
      keyValueMap.forEach((value, key) => {
        this.filterValues[key] = value;
      });
    } else {
      delete this.filterValues[field.key];
      const secondaryKeys = field.configs
        .filter((item) => item.isSecondary)
        .map(({ key }) => key);
      secondaryKeys.forEach((key) => {
        delete this.filterValues[key];
      });
    }
  }

  /**
   * Method to extract only the value from the selected list emitted by
   * the select-pill component.
   * @param selectedValues
   */
  formatSelectPillInputValues(selectedValues: any = []) {
    return selectedValues.map(({ value }) => value);
  }

  /**
   * Format the value to filterable format
   * @param param0
   * @param key
   */
  formatValues(selection, field) {
    const key = selection?.selectedKey ? selection?.selectedKey : field.key;
    const operator = selection?.operator;
    let values = selection?.values;
    const existingKeys = field?.configs?.map((config) => config?.value);
    if (this.filterValues?.ranges?.length && existingKeys?.length) {
      this.filterValues.ranges = this.filterValues.ranges.filter(
        (range) => !existingKeys.includes(range?.field)
      );
    }
    if (!this.filterValues.ranges && values?.length) {
      this.filterValues.ranges = [];
    }
    const ranges = this.filterValues.ranges;
    if (key === 'ttfb') {
      values = values.map((item) => item * 1000);
    }
    let from: any, to: any;
    switch (operator) {
      case '<':
        from = null;
        to = values[0];
        break;
      case '>':
        from = values[0];
        to = null;
        break;
      case '=':
        from = values[0];
        to = values[0];
        break;
      case 'range':
        from = values[0];
        to = values[1];
    }
    this.removeOldValue(key, ranges);
    if (values.length) {
      ranges.push({ field: key, from, to });
    } else if (ranges && !ranges.length) {
      delete this.filterValues.ranges;
    }
  }

  /**
   * Remove value from the array
   * @param key
   * @param data
   */
  removeOldValue(key, data) {
    if (!data) {
      return;
    }
    const pos = data.findIndex((item) => item?.field === key);
    if (pos >= 0) {
      data.splice(pos, 1);
    }
  }

  /**
   * Disable auto close mat-menu on click
   * @param old
   * @returns
   */
  private configureMenuClose(old: MatMenu['close']): MatMenu['close'] {
    const upd = new EventEmitter();
    this.feed(
      upd.pipe(
        filter((event) => {
          if (event === 'click') {
            // Ignore clicks inside the menu
            return false;
          }
          return true;
        })
      ),
      old
    );
    return upd;
  }

  /**
   * Add new filter with basic fields
   */
  addNewFilter() {
    this.newSelections.push({
      field: '',
      operator: '',
      value: '',
      column: null,
      selectedKey: '',
    });
    setTimeout(() => this.scrollToBottom(), 0);
    this.cdr.markForCheck();
  }

  /**
   * Remove single filter
   * @param index
   */
  removeSingleFilter(input: any, data: any, index: number) {
    this.newSelections.splice(index, 1);
    if (data?.column?.type === SEARCH_FILTER.TYPES.RANGE) {
      this.filterValues.ranges = this.filterValues?.ranges?.filter(
        (value) => value?.field !== data?.field
      );
    } else if (this.filterValues?.[data?.field]) {
      delete this.filterValues[data?.field];
    }
    this.refreshData();
    if (!this.newSelections?.length) {
      this.clearAllFilters();
    }
    this.cdr.markForCheck();
  }

  /**
   * Clear all filters
   */
  clearAllFilters(disableCalls?: boolean) {
    this.newSelections = [];
    this.filterValues = null;
    this.allFilters = null;
    this.refreshData(disableCalls);
    this.cdr.markForCheck();
  }

  /**
   * Filter event filter and mark as complete
   * @param from
   * @param to
   * @returns
   */
  feed<T>(from: Observable<T>, to: Subject<T>): Subscription {
    return from.subscribe(
      (data) => to.next(data),
      (err) => to.error(err),
      () => to.complete()
    );
  }

  /**
   * Scroll filter container when no. of filter increases beyond visible height
   */
  scrollToBottom(): void {
    try {
      this.scrollMe.nativeElement.scrollTop =
        this.scrollMe.nativeElement.scrollHeight -
        this.scrollMe.nativeElement.offsetHeight;
    } catch (err) {}
  }

  /**
   * Filter all columns and avoid duplicates entries, remove each column once it is already selected
   * @param i
   * @returns
   */
  filterFields(i) {
    const currentColumn = this.newSelections[i];
    const filtered = this.newSelections.filter(
      (dd) => dd?.field !== currentColumn?.field
    );
    const mapped = filtered.map((column) => column?.field);
    return this.filterRowColumns?.filter((col) => !mapped?.includes(col?.key));
  }

  /**
   * Filter columns when selection changes
   * @param param
   * @param data
   */
  onColumnChange({ value }, data) {
    data.column = this.filterRowColumns.find((column) => column?.key === value);
    this.cdr.markForCheck();
  }

  /**
   * Update selected filters and fire event with value changes to fetch new data
   * @param inputs
   * @param field
   * @param isValueRemoved
   * @returns
   */
  updateSelectedFilters(inputs, field, isValueRemoved?) {
    let data = inputs;
    field.value = inputs;
    const { isValid, errMessage } = this.isInputValid(inputs, field);

    if (!isValid && !isValueRemoved) {
      field = Object.assign(field, { errMessage });
      // return;
    } else {
      field = Object.assign(field, { errMessage: '' });
    }

    of(data)
      .pipe(distinctUntilChanged(), debounceTime(1000))
      .subscribe((response) => {
        if (field?.column?.type === SEARCH_FILTER.TYPES.RANGE) {
          const mapped = inputs
            ?.split('-')
            ?.map((input) => (input ? Number(input?.trim()) : null));
          data = {
            operator: field?.operator,
            values:
              field?.operator === FILTER_OPERATORS.RANGE
                ? mapped
                : [mapped?.[0]] || [Number(inputs?.trim())],
            selectedKey: field?.selectedKey,
          };
        }

        if (field?.column?.type === SEARCH_FILTER.TYPES.AUTOCOMPLETE) {
          data = inputs
            ?.split(',')
            ?.map((input) => input?.trim())
            ?.filter((value) => value);

          if (field?.selectedInputValues?.length) {
            data = [...field?.selectedInputValues, ...data];
          }
        }

        if (field?.column?.advanceConfigs?.isDataField) {
          this.dataConvertion(data, field?.column);
        } else {
          this.onChangeSelection(data, field?.column);
        }
      });
  }

  /**
   * Validate entered value is valid with given format
   * @param inputs
   * @param field
   * @returns
   */
  isInputValid(inputs, field) {
    let errMessage = '';
    let isValid;
    switch (field?.operator) {
      case FILTER_OPERATORS.CONTAINS:
        if (
          this.isRangeConfigMenu(field) ||
          field?.column?.type === SEARCH_FILTER.TYPES.SELECT_PILL ||
          field?.column?.type === SEARCH_FILTER.TYPES.CHECKBOX_GROUP
        ) {
          isValid = inputs?.length;
        }

        if (field?.column?.type === SEARCH_FILTER.TYPES.AUTOCOMPLETE) {
          isValid = ADVANCE_SEARCH_REGEX.MULTIPLE_WORDS.test(inputs);
          errMessage = !isValid
            ? 'Invalid input. Inputs can be comma separated.'
            : '';
        } else if (field?.column?.type === SEARCH_FILTER.TYPES.RANGE) {
          isValid = ADVANCE_SEARCH_REGEX.ONLY_NUMBERS.test(inputs);
          errMessage = !isValid
            ? 'Invalid input. Inputs should be a number or a decimal.'
            : '';
        } else {
          isValid = true;
        }
        break;
      case FILTER_OPERATORS.LESS_THAN:
      case FILTER_OPERATORS.GREATER_THAN:
        isValid = ADVANCE_SEARCH_REGEX.ONLY_NUMBERS.test(inputs);
        errMessage = !isValid
          ? 'Invalid input. Inputs should be a number or a decimal.'
          : '';
        break;
      case FILTER_OPERATORS.RANGE:
        isValid = ADVANCE_SEARCH_REGEX.NUMBER_RANGE.test(inputs);
        errMessage = !isValid
          ? 'Invalid input range. Inputs should be a range in number or a decimal separated by a dash'
          : '';
        break;
    }
    return { isValid, errMessage };
  }

  /**
   * Check if all values are entered and new entry is allowed
   * @returns
   */
  isNewFilterAllowed() {
    if (!this.newSelections?.length) {
      return false;
    }

    return !!this.newSelections.filter((column) => {
      const isNotCheckAndPill =
        column?.column?.type !== SEARCH_FILTER.TYPES.SELECT_PILL &&
        column?.column?.type !== SEARCH_FILTER.TYPES.CHECKBOX_GROUP;
      const isNotRange =
        column?.selectedInputValues?.length &&
        column?.column?.type !== SEARCH_FILTER.TYPES.RANGE;

      if (isNotCheckAndPill && isNotRange) {
        return (
          !column?.value &&
          !column?.value?.length &&
          !column?.selectedInputValues?.length
        );
      }
      return !column?.value || !column?.value?.length;
    })?.length;
  }

  /**
   * Check if column has custom Config menu
   * @param data
   * @returns
   */
  isRangeConfigMenu(data) {
    return (
      data?.column?.type === SEARCH_FILTER.TYPES.RANGE &&
      data?.column?.configs?.length
    );
  }

  /**
   * Get operators list based on selection of column
   * @param data
   * @returns
   */
  getOperatorsDropdown(data) {
    if (
      data?.column?.type === SEARCH_FILTER.TYPES.RANGE &&
      data?.column?.configs?.length
    ) {
      let operators = data?.column?.configs;
      operators?.forEach((config) => {
        config = Object.assign(config, { operators: this.operators });
      });
      return operators;
    } else {
      return this.operators;
    }
  }

  /**
   * Update value display text when multiple values are entered along with count
   * @param data
   */
  updateValueCount(data) {
    if (data?.column?.type !== SEARCH_FILTER.TYPES.RANGE) {
      const values = data?.value
        ?.split(',')
        ?.map((value) => value?.trim())
        ?.filter((value) => value);

      if (data?.selectedInputValues) {
        data.selectedInputValues = [...data?.selectedInputValues, ...values];
      } else {
        data = Object.assign(data, {
          selectedInputValues: values,
        });
      }

      data.selectedValuesCountText = `${
        data?.selectedInputValues?.length || 0
      }  ${data?.column?.name} Selected`;

      data = Object.assign(data, {
        showSelected: true,
        value: '',
      });
    }
  }

  /**
   * Updated nested operator selected text
   * @param data
   * @param operator
   * @param item
   */
  updateNestedOperatorData(data, operator, item) {
    data.operator = item?.value;
    data.selectedKey = operator?.value;
    data = Object.assign(data, {
      displayValue: `${operator?.displayName} - ${
        data?.column?.type === SEARCH_FILTER.TYPES.RANGE &&
        item.value === FILTER_OPERATORS.CONTAINS
          ? 'Equals'
          : item?.displayName
      }`,
    });

    if (data?.value) {
      data.value = '';
    }
  }

  /**
   * Update count and emit event when values get changes
   * @param data
   * @param index
   */
  updateSelectedValueCount(data, index) {
    data?.selectedInputValues.splice(index, 1);
    data.selectedValuesCountText = `${
      data?.selectedInputValues?.length || 0
    }  ${data?.column?.name} Selected`;

    this.updateSelectedFilters(data?.value, data, true);

    this.cdr.markForCheck();
  }

  /**
   * Focus on input values manually
   * @param id
   */
  focusManullyWhenDynamicInputs(id) {
    this.document.getElementById(id).focus();
  }

  /**
   * Return acurate pattern to validate input
   * @param field
   * @returns
   */
  getPatternForValues(field) {
    switch (field?.operator) {
      case FILTER_OPERATORS.CONTAINS:
        if (field?.column?.type === SEARCH_FILTER.TYPES.AUTOCOMPLETE) {
          return ADVANCE_SEARCH_REGEX.MULTIPLE_WORDS;
        }

        if (field?.column?.type === SEARCH_FILTER.TYPES.RANGE) {
          return ADVANCE_SEARCH_REGEX.ONLY_NUMBERS;
        }
        return null;
      case FILTER_OPERATORS.LESS_THAN:
      case FILTER_OPERATORS.GREATER_THAN:
        return ADVANCE_SEARCH_REGEX.ONLY_NUMBERS;
      case FILTER_OPERATORS.RANGE:
        return ADVANCE_SEARCH_REGEX.NUMBER_RANGE;
    }
    return null;
  }

  filterSelectedNonColumnFilters() {
    if (!this.allFilters) {
      return;
    }

    let rangeFilters = this.newSelections
      ?.filter(({ column }) => column?.type === SEARCH_FILTER.TYPES.RANGE)
      ?.map((column) =>
        column?.column?.selectedKey
          ? column?.column?.selectedKey
          : column?.field
      );

    this.newSelections?.forEach(({ column }) => {
      if (
        column?.type === SEARCH_FILTER.TYPES.RANGE &&
        column?.listKeys?.length
      ) {
        rangeFilters = [...rangeFilters, ...column?.listKeys];
      }
    });
    if (this.allFilters?.ranges?.length) {
      this.allFilters.ranges = this.allFilters?.ranges?.filter(
        (column) =>
          rangeFilters.includes(column?.field) &&
          (column?.from !== null || column?.to !== null)
      );
    }

    let allFields = this.newSelections?.map((column) => column?.field);

    if (this.newSelections?.length) {
      this.newSelections?.forEach(({ column }) => {
        if (column?.listKeys?.length) {
          allFields = [...allFields, ...column?.listKeys];
        }
      });
    }
    const ignoreKeys = [];
    Object.keys(this.allFilters)?.forEach((key) => {
      if (key !== 'ranges') {
        if (!allFields?.includes(key)) {
          ignoreKeys?.push(key);
        }
      }
    });

    ignoreKeys?.forEach((key) => delete this.allFilters?.[key]);
  }

  filterSelectedColumnFilters(columnFilters) {
    if (!columnFilters) {
      return;
    }
    const ignoreKeys = [];
    const columnFilterKeys = this.newSelections?.map((data) => {
      if (data?.column?.isColumnFilter) {
        return data?.field;
      }
    });

    Object.keys(columnFilters)?.forEach((key) => {
      if (!columnFilterKeys?.includes(key)) {
        ignoreKeys?.push(key);
      }
    });

    ignoreKeys?.forEach((key) => delete columnFilters?.[key]);

    return columnFilters;
  }

  filterSelectedExactFilters(exactFilters) {
    if (!exactFilters?.length) {
      return [];
    }
    const exactFilterKeys = this.newSelections?.map((data) => {
      if (data?.column?.isExactFilter) {
        return data?.field;
      }
    });

    return exactFilters?.filter((column) =>
      exactFilterKeys?.includes(column?.field)
    );
  }

  dataConvertion(data, column) {
    switch (column?.advanceConfigs?.unit) {
      case 'MB':
        data.values = data?.values?.map((value) => {
          if (value) {
            value = value * DATA_MULTIPLES.BASE_10 * DATA_MULTIPLES.BASE_10;
          }
          return value;
        });
        break;

      case 'GB':
        data.values = data?.values?.map((value) => {
          if (value) {
            value =
              value *
              DATA_MULTIPLES.BASE_10 *
              DATA_MULTIPLES.BASE_10 *
              DATA_MULTIPLES.BASE_10;
          }
          return value;
        });
        break;
    }

    this.onChangeSelection(data, column);
  }

  getSelectedFilterCount(invalid) {
    return this.newSelections?.length >= this.filterRowColumns?.length ||
      this.isNewFilterAllowed() ||
      invalid
      ? this.newSelections?.length - 1
      : this.newSelections?.length;
  }

  onMenuClosed() {
    this.isMenuOpened = false;
  }

  onMenuOpened() {
    this.isMenuOpened = true;
  }

  closeAllMatMenus() {
    this.isMenuOpened = true;
    this.fieldsDropdowns?.toArray()?.forEach((field) => {
      field?.close();
    });
  }
}
