import { Namespace, Network, PrefixInfo } from '@app/common/models';
import {
  NetworkDefinition,
  PCWithSubnets,
} from '../../components/drawer/definition/definition.model';
import { NetworkVPC, VPCSettings } from '../../components/drawer/vpc/vpc.model';
import { NetworkPolicy } from '../../components/drawer/policy/policy.model';
import { ModelFormGroup } from '@app/common/util/form-util';
import { NetworkAutoDefinition } from '../../components/drawer/auto-definition/auto-definition.model';
import {
  NetworkRule,
  Rule,
} from '../../components/drawer/auto-rule/auto-rule.model';
import { ChangeSetList } from '../../../network.model';

export interface ICloudController {
  getCloudType: (network: Network) => string;
  getCSP: (network: Network) => string;
  getCloudKeyID: (network: Network) => string;
  getCloudRegion: (network: Network) => string;
  getCloudNetworks: (network: Network) => Array<PCWithSubnets>;
  getSubnets: (network: NetworkDefinition) => Array<string>;
  getCloudNetworksForm: (network: NetworkDefinition) => Array<PCWithSubnets>;
  detectDefinationFormChanges: (
    network: Network,
    networkForm: NetworkDefinition
  ) => boolean;
  detectAutoDefinitionFormChanges: (
    network: Network,
    networkForm: NetworkAutoDefinition
  ) => boolean;
  validateDefinition: (networkForm: NetworkDefinition) => boolean;
  validateAutoDefinition: (networkForm: NetworkAutoDefinition) => boolean;
  updateDefinition: (
    network: Network,
    networkForm: NetworkDefinition,
    namespacesList: Array<Namespace>
  ) => void;
  updateAutoDefinition: (
    network: Network,
    networkForm: NetworkAutoDefinition
  ) => void;
  detectAutoOnboardRulesFormChanges: (
    network: Network,
    rulesForm: Array<Rule>,
    prefixes: Map<string, Array<PrefixInfo>>,
    bgpCommunities: Map<string, Array<string>>,
    bgpNeighbors: Map<string, Array<string>>
  ) => Array<number>;
  validateAutoOnboardRules: (
    network: Network,
    rulesForm: Array<Rule>,
    prefixes: Map<string, Array<PrefixInfo>>,
    bgpCommunities: Map<string, Array<string>>,
    bgpNeighbors: Map<string, Array<string>>
  ) => Array<number>;
  updateAutoOnboardRules: (
    network: Network,
    rulesForm: NetworkRule,
    prefixes: Map<string, Array<PrefixInfo>>,
    bgpCommunities: Map<string, Array<string>>,
    bgpNeighbors: Map<string, Array<string>>
  ) => void;
  getRulesChangeSet: (
    existingNetwork: Network,
    rulesForm: NetworkRule,
    prefixes: Map<string, Array<PrefixInfo>>,
    bgpCommunities: Map<string, Array<string>>,
    bgpNeighbors: Map<string, Array<string>>,
    ...misc: any
  ) => Array<ChangeSetList>;
  detectVPCFormChanges: (
    network: Network,
    vpcForm: Array<VPCSettings>
  ) => Array<number>;
  validateVPCs: (
    network: Network,
    vpcSettings: Array<VPCSettings>
  ) => Array<number>;
  updateVPCs: (network: Network, networkForm: NetworkVPC) => void;
  detectPolicyFormChanges: (
    network: Network,
    networkForm: NetworkPolicy
  ) => boolean;
  validatePolicy: (
    networkForm: NetworkPolicy,
    defaultPolicies: Map<string, string>
  ) => boolean;
  updatePolicy: (network: Network, networkForm: NetworkPolicy) => void;
  addSubnetToDefinitionForm: (
    subnet: string,
    networkForm: ModelFormGroup<NetworkDefinition>
  ) => void;
  removeSubnetToDefinitionForm: (
    subnet: string,
    networkForm: ModelFormGroup<NetworkDefinition>
  ) => void;

  updateOrchestratorWorkflow: (cloudType: string) => void;
}
