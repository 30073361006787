import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceService } from '@prosimoio/services';
import { CONSTANTS } from 'environments/environment';
import { Observable } from 'rxjs';
import { CommonModelSerializer } from '../http/service/common.model.serializer';
import { Cluster, EdgeConnectivity, EdgeCreatePayload } from '../models';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ClusterService extends ResourceService<any> {
  constructor(protected httpClient: HttpClient) {
    super(
      httpClient,
      CONSTANTS.DASHBOARD_HOST,
      '',
      new CommonModelSerializer()
    );
  }

  getEdges(): Observable<Array<Cluster>> {
    this.path = CONSTANTS.EDGE_MANAGEMENT.EDGES.DEFAULT_PATH;
    return this.read('');
  }

  validateEdgeIPRange(payload: any) {
    this.path = CONSTANTS.CLOUD.VALIDATE_SUBNET;
    return this.create(payload).pipe(take(1));
  }

  createEdge(payload: Cluster) {
    this.path = CONSTANTS.EDGE_MANAGEMENT.EDGES.DEFAULT_PATH;
    return this.create(payload);
  }

  launchEdge(edgeId: string) {
    this.path = CONSTANTS.EDGE_MANAGEMENT.EDGES.LAUNCH(edgeId);
    return this.update({}, false);
  }

  getEdgeConnectivityOptions(
    edgeId: string
  ): Observable<Array<EdgeConnectivity>> {
    this.path = CONSTANTS.EDGE_MANAGEMENT.EDGES.PEERING_OPTIONS(edgeId);
    return this.read('');
  }

  updateEdge(id: string, payload: Cluster) {
    this.path = CONSTANTS.EDGE_MANAGEMENT.EDGES.EDGE_EDIT(id);
    return this.update(payload, true).pipe(take(1));
  }

  reDeployEdge(edgeId: string, payload: Cluster) {
    this.path = CONSTANTS.EDGE_MANAGEMENT.EDGES.EDGE_EDIT(edgeId);
    return this.patch(payload).pipe(take(1));
  }

  deleteEdge(id: string) {
    this.path = CONSTANTS?.EDGE_MANAGEMENT?.EDGES?.DEFAULT_PATH;
    return this.delete(id).pipe(take(1));
  }

  updateEdgeIPRange(id: string, payload: Cluster) {
    this.path = CONSTANTS.EDGE_MANAGEMENT.EDGES.UPDATE_EDGE_IP_RANGE(id);
    return this.patch(payload).pipe(take(1));
  }

  decommissionEdge(id: string, isForced: boolean = false) {
    this.path = `${CONSTANTS.EDGE_MANAGEMENT.EDGES.RECEIVE}/${id}${
      isForced ? '?force=true' : ''
    }`;
    return this.delete().pipe(take(1));
  }

  fetchAttachPoints(payload: any) {
    this.path = CONSTANTS.CLOUD_MANAGEMENT.PUBLIC_CONNECTIVITY_OPTION;
    return this.create(payload);
  }

  getAWSCloudAttachments(payload: any): Observable<any> {
    this.path = CONSTANTS.CLOUD_MANAGEMENT.AWS_TGW_ATTACHMENTS;
    return this.create(payload);
  }

  decommissionEgressNATService(id: string, isForced: boolean = false) {
    this.path = `${CONSTANTS.EDGE_MANAGEMENT.EDGES.DECOMMISSION_EGRESS_NAT(
      id
    )}${isForced ? '?force=true' : ''}`;
    return this.delete().pipe(take(1));
  }

  getConnectivityConfig() {
    this.path = CONSTANTS.CLOUD_MANAGEMENT.GET_CONNECTIVITY_CONFIG;
    return this.read('');
  }
}
