import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-image-upload-preview',
  templateUrl: './image-upload-preview.component.html',
  styleUrls: ['./image-upload-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageUploadPreviewComponent implements OnInit {
  @ViewChild('fileInput') fileInput;
  imgurl: any = '';
  imageName$ = new BehaviorSubject<string>('');
  @Input() set imageUrl(data) {
    this.imgurl = data;
  }
  @Output() updatedImageUrl = new EventEmitter();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  onFileUpload(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      let imageFile = event.target.files[0];
      reader.readAsDataURL(imageFile);
      reader.onload = (event) => {
        this.imgurl = event.target.result;
        this.updatedImageUrl.emit({ imageURL: this.imgurl, imageFile });
        this.cdr.markForCheck();
      };
      this.imageName$.next(event.target.files[0].name);
    }
  }

  uploadFile() {
    this.fileInput.nativeElement.click();
  }
}
