import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PasswordMeterService {
  private passwordText$ = new BehaviorSubject<any>({});

  constructor() {}

  setPasswordText(passwordText: string) {
    this.passwordText$.next(passwordText);
  }

  getPasswordText(): Observable<string> {
    return this.passwordText$.asObservable();
  }
}
