import { Resource } from '@app/common/http/util/resource';

export class LoginModel extends Resource {
  accountExpiry: number;
  username: string;
  password: string;
  token: string;
  tokenName: string;
  tokenExpiry: number;
  generalToken: string;
  generalTokenName: string;
  generalTokenExpiry: number;
  firstname: string;
  lastname: string;
  type: string;
  authType?: string;
  userId: string;
  prosimoAppStatus: string;
  team: string;
  company: string;
  status: string;
  factors: Array<any>;
  iconPath: string;
  accountType: string;
  companyName: string;
  loginQueryParam?: string;
  loginURL: string;
  licenseStatus: string;
  subscriptionType?: Array<string>;
  engLabsEnabled?: boolean;
}

export const LOGIN_AUTH_TYPES = {
  SSO: 'pdash-sso',
  LOCAL: 'pdash-local',
};

export const LICENSE_STATUS = {
  VALID: 'valid',
  NONE: 'none',
};
