<div id="dashboard-menu__container">
  <div class="row h-100 no-gutters bg-dark-shade">
    <div
      class="d-flex align-items-center justify-content-center ml-4"
      *ngFor="let config of configs"
    >
      <div
        [ngClass]="config?.igClassName"
        class="metric__container h-100"
        (click)="setSelectedMenuItem(config)"
        [routerLink]="!config?.isDisabled ? config?.path : null"
      >
        <div
          class="px-3 h-100 d-flex justify-content-center align-items-center"
          [matTooltip]="config?.tooltip"
          [ngClass]="{
            'selected__menu bdr-btm-primary1 selected': config?.isSelected
          }"
        >
          <span
            class="clr-grey7"
            [ngClass]="{
              'metric__name my-auto align-middle': true,
              disabled: config?.isDisabled,
              enabled: !config?.isDisabled
            }"
            >{{ config?.name }}</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
