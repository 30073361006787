import { TimeRange } from '../../shared/calendar.component';
import {
  DiagnoseSharedFilterStore,
  diagnoseSharedFilterStore,
  AccessibilityDetails,
} from './diagnose-filter.store';
import { TeamUser } from '@app/dashboard/shared/filter-list/client-list/client-list.model';
import { OnboardedApp } from '@app/dashboard/shared/filter-list/app-list/apps-list.model';
import { DiagnosticTestDetails } from '.';

export class DiagnoseStoreFilterService {
  constructor(private diagnoseFilterStore: DiagnoseSharedFilterStore) {}

  updateSelectedTeamUsers(selectedTeamUsers: Array<TeamUser>) {
    this.diagnoseFilterStore.update((state) => ({
      selectedTeamUsers,
    }));
  }

  updateSelectedOnboardedApps(selectedOnboardedApps: Array<OnboardedApp>) {
    this.diagnoseFilterStore.update((state) => ({
      selectedOnboardedApps,
    }));
  }

  updateSelectedNetworks(selectedNetworks: Array<string>) {
    this.diagnoseFilterStore.update((state) => ({
      selectedNetworks,
    }));
  }

  updateDateRange(timeRange: TimeRange) {
    this.diagnoseFilterStore.update((state) => ({
      timeRange,
    }));
  }

  updateDiagnoseDateRange(diagnoseTimeRange: TimeRange) {
    this.diagnoseFilterStore.update((state) => ({
      diagnoseTimeRange,
    }));
  }

  updateActiveTab(activeTab: string) {
    this.diagnoseFilterStore.update((state) => ({
      activeTab,
    }));
  }

  updateActiveSubMenu(activeSubMenu: string) {
    this.diagnoseFilterStore.update((state) => ({
      activeSubMenu,
    }));
  }

  updateAccessibilityDetails(accessibilityDetails: AccessibilityDetails) {
    this.diagnoseFilterStore.update((state) => ({
      accessibilityDetails,
    }));
  }

  updateDiagnosticTestDetails(diagnosticTestDetails: DiagnosticTestDetails) {
    this.diagnoseFilterStore.update((state) => ({
      diagnosticTestDetails,
    }));
  }
}

export const diagnoseStoreFilterService = new DiagnoseStoreFilterService(
  diagnoseSharedFilterStore
);
