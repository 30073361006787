import { Resource } from '@app/common/http/util/resource';
import { ResponseModel } from '@app/common/util/models/response.model';

export class PasswordModel extends Resource {
  team = '';
  username: string;
  password: string;
  dashboardUrl = '';
  teamURL?: string;
  response: ResponseModel;
  userID?: string;
}
