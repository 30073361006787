import { LoginService } from '@app/login/login.service';
import { USER_TYPES } from '@app/common/util/constants';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivate,
} from '@angular/router';
import { AuthService } from './auth.service';
import { map, take, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { URL_PATHS } from '../../constants';
import { LoginUtil } from '@app/login/login-util';

@Injectable({
  providedIn: 'root',
})
export class MSPAuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private loginService: LoginService,
    private loginUtil: LoginUtil
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.authService.getAuthTokenStatus().pipe(
      take(1),
      catchError(() => {
        this.routeTo(URL_PATHS.LOGIN);
        return of(false);
      }),
      map((data = {}) => {
        this.loginService.setUserInfoFromRouteGuard(data);
        if (
          data.type === USER_TYPES.MSP_ADMIN &&
          !this.loginUtil.isProsimoSupportTeam(data)
        ) {
          return true;
        } else {
          this.routeTo(URL_PATHS.PAGE_NOT_FOUND);
          return false;
        }
      })
    );
  }

  routeTo(routeURL: string = URL_PATHS.LOGIN) {
    this.router.navigateByUrl(routeURL);
  }
}
