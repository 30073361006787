import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GettingStartedService {
  private actionButton$: Subject<string> = new Subject<string>();
  constructor() {}

  setSelectedActionButton(actionButton: string) {
    this.actionButton$.next(actionButton);
  }

  getSelectedActionButton(): Observable<string> {
    return this.actionButton$.asObservable();
  }
}
