import { inject } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Cluster, Network, SubnetInfo } from '@app/common/models';
import { NetworkOnboardService } from '../../network.service';
import { catchError, map, take } from 'rxjs/operators';
import { ModelFormGroup } from '@app/common/util/form-util';

export const RegionValidator = (edges: Array<Cluster>): ValidatorFn => {
  return (control: AbstractControl<string>) => {
    if (
      !control.value ||
      edges.some((edge) => {
        if (control.value === edge.cloudRegion) {
          return true;
        }
      })
    ) {
      delete control.errors?.cluster;
      return control.errors;
    } else {
      return {
        ...control?.errors,
        cluster: true,
      };
    }
  };
};

export const VirtualSubnetValidator = () => {
  const networkOnboardService = inject(NetworkOnboardService);
  return (network: Network) => (control: ModelFormGroup<SubnetInfo>) => {
    return networkOnboardService.validateSubnet([control.value]).pipe(
      take(1),
      map((data) => {
        // If virtual subnet is valid, or is already on this network for the selected subnet
        if (
          data?.[0].valid ||
          network.publicCloud?.cloudNetworks.some((pc) =>
            pc.subnets.some(
              (s) =>
                s.subnet === control.controls.subnet.value &&
                s.virtualSubnet === control.controls.virtualSubnet.value
            )
          )
        ) {
          const { server, ...rest } =
            control.controls.virtualSubnet.errors || {};
          control.controls.virtualSubnet.setErrors(
            Object.keys(rest).length > 0 ? rest : null
          );
          return control?.errors;
        } else {
          control.controls.virtualSubnet.setErrors({
            ...control.controls.virtualSubnet.errors,
            server: data?.[0].message,
          });
          return control?.errors;
        }
      }),
      catchError(async (err) => {
        console.warn(control.value, err);
        control.controls.virtualSubnet.setErrors({
          ...control.controls.virtualSubnet.errors,
          server: err.error.message,
        });
        return control?.errors;
      })
    );
  };
};
