import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { AppMaterialModule } from '@app/app-material.module';
import { NgxCaptchaModule } from 'ngx-captcha';
import { DashboardCommonModule } from '@app/common/dashboard-common.module';
import { CommonComponentsModule } from '@app/component-common/common-components.module';

import { AccountActivationComponent } from '@app/account-activation/account-activation.component';

const routes: Routes = [
  { path: 'activation/:email/:token', component: AccountActivationComponent },
];

@NgModule({
  declarations: [AccountActivationComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    AppMaterialModule,
    NgxCaptchaModule,
    DashboardCommonModule,
    CommonComponentsModule,
  ],
})
export class AccountActivationModule {}
