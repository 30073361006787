<div
  id="lb-form-container"
  class="shadow-sm mb-5 bg-white rounded px-4 d-flex flex-column position-relative"
  [ngClass]="{
    'pb-3': (statusMessage$ | async),
    'bg-light-shade': dataConfig.panelClass,
    'bg-white clr-grey': !dataConfig.panelClass,
    'px-4': addPadding
  }"
>
  <div
    *ngIf="header || dataConfig?.title"
    class="filter__header border-bottom-grey-thin"
  >
    <div *ngIf="header" [innerHTML]="header | safe : 'html'"></div>
    <h2 *ngIf="dataConfig?.title">{{ dataConfig?.title }}</h2>
    <h6>{{ dataConfig?.hint }}</h6>
  </div>
  <div *ngIf="!dataConfig.hideCloseIcon">
    <span
      class="close__sign grey"
      matTooltip="Close..."
      (click)="emitActionBtnDetails($event, COPY_LIGHTBOX_BTN_TYPES.CLOSE.code)"
    >
      &times;
    </span>
  </div>
  <div
    #panel
    class="lightbox__content d-flex flex-column overflow-auto position-relative"
  >
    <ng-content></ng-content>
  </div>
  <div
    class="p-4 action__controls d-flex justify-content-center"
    [ngClass]="[dataConfig?.panelClass ? '' : 'bg-white']"
    *ngIf="!(CANCEL_BUTTON_TYPE.code === COPY_LIGHTBOX_BTN_TYPES.NONE.code)"
  >
    <div class="my-auto mx-auto d-flex">
      <button
        *ngIf="!dataConfig?.hideCancelButton"
        mat-button
        class="btn-cancel mx-2"
        color="primary"
        [disabled]="cancelDisabled"
        (click)="emitActionBtnDetails($event, CANCEL_BUTTON_TYPE.code)"
      >
        {{ CANCEL_BUTTON_TYPE.name }}
      </button>
      <ng-container
        *ngIf="dataConfig?.panelClass; then v2Template; else v1dot5Template"
      ></ng-container>
      <ng-template #v1dot5Template>
        <button
          *ngFor="let btnConfig of dataConfig?.buttonConfigs"
          mat-raised-button
          class="mx-2"
          color="primary"
          [disabled]="btnConfig.disabled"
          (click)="emitActionBtnDetails($event, btnConfig?.code)"
        >
          {{ btnConfig?.name }}
        </button>
      </ng-template>
      <ng-template #v2Template>
        <button
          *ngFor="let btnConfig of dataConfig?.buttonConfigs"
          mat-stroked-button
          class="mx-2"
          color="primary"
          [disabled]="btnConfig.disabled"
          (click)="emitActionBtnDetails($event, btnConfig?.code)"
        >
          {{ btnConfig?.name }}
        </button>
      </ng-template>
    </div>
  </div>
  <ng-container *ngIf="statusMessage$ | async">
    <div class="d-flex align-item-center statusMessage text-center">
      <div
        class="col message"
        [ngClass]="{
          successMessage:
            (statusMessage$ | async).type ===
            COPY_LIGHTBOX_STATUS_TYPES.SUCCESS,
          errorMessage:
            (statusMessage$ | async).type === COPY_LIGHTBOX_STATUS_TYPES.ERROR
        }"
      >
        {{ (statusMessage$ | async).message }}
      </div>
    </div>
  </ng-container>
</div>
