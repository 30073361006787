import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DeleteConfig } from './onboard-template.model';

@Injectable({
  providedIn: 'root',
})
export class OnboardTemplateService {
  private nameInput$: Subject<string>;
  private nameState$: Subject<any>;
  private dateInfo$: Subject<string>;
  private deleteConfig$: Subject<DeleteConfig>;
  private deleteResponse$: Subject<any>;

  constructor() {
    this.nameInput$ = new Subject<string>();
    this.nameState$ = new Subject<any>();
    this.dateInfo$ = new Subject<string>();
    this.deleteConfig$ = new Subject<DeleteConfig>();
    this.deleteResponse$ = new Subject<any>();
  }

  setNameInput(name: string) {
    this.nameInput$.next(name);
  }

  getNameInput(): Observable<string> {
    return this.nameInput$.asObservable();
  }

  setNameState(state: any) {
    this.nameState$.next(state);
  }

  getNameState(): Observable<any> {
    return this.nameState$.asObservable();
  }

  setDateInfo(date: string) {
    this.dateInfo$.next(date);
  }

  getDateInfo(): Observable<string> {
    return this.dateInfo$.asObservable();
  }

  setDeleteConfig(config: DeleteConfig) {
    this.deleteConfig$.next(config);
  }

  getDeleteConfig(): Observable<DeleteConfig> {
    return this.deleteConfig$.asObservable();
  }

  setDeleteResponse(response: any) {
    this.deleteResponse$.next(response);
  }

  getDeleteResponse(): Observable<any> {
    return this.deleteResponse$.asObservable();
  }
}
