import { ZTNARecentSearchStore, ztnaRecentSearchStore } from './ztna-recent-search.store';
import { ZTNARecentSearch } from './ztna-recent-search.model';

export class ZTNARecentSearchService {
  constructor(private recentSearchStore: ZTNARecentSearchStore) {}

  updateRecentSearchStore(data: ZTNARecentSearch) {
    this.recentSearchStore.add(data, { prepend: true });
  }

  updateRecentSearchStoreById(id: string, data: ZTNARecentSearch) {
    this.recentSearchStore.update(id, data);
  }
  removeFilter(id: number) {
    this.recentSearchStore.remove(id);
  }
  resetStore() {
    this.recentSearchStore.reset();
  }
}

export const ztnaRecentSearchService = new ZTNARecentSearchService(ztnaRecentSearchStore);
