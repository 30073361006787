<div id="waf_rule_category__container">
  <div [formGroup]="newFormInst">
    <mat-checkbox color="primary" formControlName="isDefaultListChecked">
      <span class="rule-set_header"
        >Basic rule-set ({{ selectedDefaultListItem }} of
        {{ defaultListArray.length }})</span
      >
    </mat-checkbox>
    <div class="ml-4">
      <mat-form-field>
        <mat-label>View List</mat-label>
        <mat-select
          panelClass="mat-select-panel"
          formControlName="default"
          disableOptionCentering
          multiple
          disableRipple
        >
          <mat-select-trigger>
            <span class="selected__txt">
              {{ selectedDefaultListItem }}
              WAF Ruleset(s) selected
            </span>
          </mat-select-trigger>
          <mat-option
            #allDefaultSelected
            (click)="toggleAllDefaultSelection()"
            [value]="0"
          >
            <span>Select All</span>
          </mat-option>
          <mat-option
            *ngFor="let item of defaultListArray"
            [value]="item"
            (click)="selectAllDefaultItems()"
          >
            <span>
              {{ item }}
            </span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-checkbox color="primary" formControlName="isModSecListChecked">
      <span class="rule-set_header">
        OWASP Modsecurity Core Ruleset v3.2 ({{ selectedModSecListItem }} of
        {{ modSecListArray.length }})
      </span>
    </mat-checkbox>
    <div class="ml-4" [formGroup]="newFormInst">
      <mat-form-field>
        <mat-label>View List</mat-label>
        <mat-select
          panelClass="mat-select-panel"
          formControlName="modSec"
          disableOptionCentering
          multiple
          disableRipple
        >
          <mat-select-trigger>
            <span class="selected__txt">
              {{ selectedModSecListItem }}
              WAF Ruleset(s) selected
            </span>
          </mat-select-trigger>
          <mat-option
            #allModSecSelected
            (click)="toggleAllModSecSelection()"
            [value]="0"
          >
            <span class="view_list_option">Select All</span>
          </mat-option>
          <mat-option
            *ngFor="let item of modSecListArray"
            [value]="item"
            (click)="selectAllModSecItems()"
          >
            <span class="view_list_option">{{ item }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <!-- <button (click)="getData()">get</button> -->
</div>
