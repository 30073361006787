import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceService } from '@prosimoio/services';
import { CONSTANTS } from 'environments/environment';
import { CommonModelSerializer } from '../http/service/common.model.serializer';
import { EMPTY, Observable } from 'rxjs';

import {
  NetEgressControl,
  NetEgressControlSearchFilter,
  NetEgressResponse,
} from '@app/common/models';
import { expand, reduce, take } from 'rxjs/operators';
import { PAGE_SIZE } from '../http/util/constants';

@Injectable({
  providedIn: 'root',
})
export class NetEgressControlService extends ResourceService<any> {
  constructor(protected httpClient: HttpClient) {
    super(
      httpClient,
      CONSTANTS.DASHBOARD_HOST,
      '',
      new CommonModelSerializer()
    );
  }

  getEgressControls(
    searchTerm: string = ''
  ): Observable<Array<NetEgressControl>> {
    let pageStart = 0;
    this.path = CONSTANTS.SECURITY.EGRESS.SEARCH;
    return this.create(
      this.getEgressControlPayload(searchTerm, pageStart)
    ).pipe(
      expand((response: NetEgressResponse) => {
        pageStart += PAGE_SIZE;
        if (pageStart < response?.totalCount) {
          this.path = CONSTANTS.SECURITY.EGRESS.SEARCH;
          return this.create(
            this.getEgressControlPayload(searchTerm, pageStart)
          );
        } else {
          return EMPTY;
        }
      }),
      reduce((acc: Array<NetEgressControl>, val: NetEgressResponse) => {
        if (val?.records) {
          return acc.concat(val.records);
        }
      }, [] as Array<NetEgressControl>)
    );
  }

  getEgressControlsByPage(
    page: number,
    searchTerm: string
  ): Observable<NetEgressResponse> {
    this.path = CONSTANTS.SECURITY.EGRESS.SEARCH;
    return this.create(
      this.getEgressControlPayload(searchTerm, page * PAGE_SIZE)
    ).pipe(take(1));
  }

  getEgressControlPayload(
    searchTerm: string,
    pageStart: number
  ): NetEgressControlSearchFilter {
    return {
      page: {
        size: PAGE_SIZE,
        start: pageStart,
      },
      // sort: [
      //   {
      //     field: 'type',
      //     asc: true,
      //   },
      // ],
      value: searchTerm,
    } as NetEgressControlSearchFilter;
  }
}
