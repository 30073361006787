import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LightboxDynaCompConfig } from './lightbox.interface';

@Injectable({
  providedIn: 'root',
})
export class LightboxService {
  private lightboxComponent$ = new Subject<boolean>();
  private lightboxState$ = new Subject<boolean>();
  private isAddLightBox$ = new Subject<boolean>();
  private template$ = new Subject<string>();
  private dynaCompConfig$ = new Subject<LightboxDynaCompConfig>();

  constructor() {}

  setLightboxComponentInstance(lightboxComponent: any) {
    this.lightboxComponent$.next(lightboxComponent);
  }

  getLightboxComponentInstance(): Observable<any> {
    return this.lightboxComponent$;
  }

  // To display the lightbox
  setLightboxState(lightboxState: boolean) {
    this.lightboxState$.next(lightboxState);
  }

  getLightboxState(): Observable<boolean> {
    return this.lightboxState$;
  }

  // To add lightbox in template
  setAddLightboxInTemplateState(isAddLightBox: boolean) {
    this.isAddLightBox$.next(isAddLightBox);
  }

  getAddLightboxInTemplateState(): Observable<boolean> {
    return this.isAddLightBox$;
  }

  setLightboxTemplate(lightboxTemplate: string) {
    this.template$.next(lightboxTemplate);
  }

  getLightboxTemplate() {
    return this.template$;
  }

  setDynaCompConfig(dynaCompConfig: LightboxDynaCompConfig) {
    this.dynaCompConfig$.next(dynaCompConfig);
  }

  getDynaCompConfig(): Observable<LightboxDynaCompConfig> {
    return this.dynaCompConfig$;
  }
}
