import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ConfettiService } from './confetti.service';
// import * as party from 'party-js';
@Component({
  selector: 'app-confetti',
  templateUrl: './confetti.component.html',
  styleUrls: ['./confetti.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ConfettiComponent implements OnInit {
  subscriptions = [];
  confettiState = false;
  componentRef: ComponentRef<any>;
  constructor(
    private confettiService: ConfettiService,
    private cdr: ChangeDetectorRef
  ) {
    this.subscriptions.push(
      this.confettiService.getConfettiState().subscribe((data) => {
        this.confettiState = data;
        if (this.confettiState) {
          this.confettiFalling();
        }
        this.cdr.markForCheck();
        if (!data) {
          this.componentRef?.destroy();
        }
      })
    );
  }

  ngOnInit(): void {
    // this.confettiFalling();
  }

  //party.js way
  //   confettiFalling() {
  //     party.confetti(document.getElementById('confetti-container'), {
  //       count: 10000, // Number of confetti particles
  //       size: 2, // Size of each particle
  //       zIndex: 1, // Z-index of the confetti container
  //       colors: ['#f00', '#0f0', '#00f', '#ff0', '#f0f', '#0ff'], // Array of confetti colors
  //       gravity: 0.5, // Gravity factor (higher value = faster falling)
  //       scalar: 1.2, // Scaling factor (higher value = larger confetti)
  //     } as any);
  //   }
  // without party.js way
  confettiFalling() {
    const box = document.getElementById('box') as HTMLElement;
    const colors = [
      'red',
      'green',
      'blue',
      'yellow',
      'purple',
      'orange',
      'pink',
    ];

    for (let i = 0; i < 1000; i++) {
      // Create 1000 DIV elements for confetti
      const div = document.createElement('div');
      div.classList.add('confetti');
      box.appendChild(div);
    }

    const confetti = document.querySelectorAll('.confetti');

    for (let i = 0; i < confetti.length; i++) {
      const size = Math.random() * 0.01 * i;

      (confetti[i] as HTMLElement).style.width = 5 + size + 'px';
      (confetti[i] as HTMLElement).style.height = 16 + size + 'px';
      (confetti[i] as HTMLElement).style.left =
        Math.random() * innerWidth + 'px';
      const background = colors[Math.floor(Math.random() * colors.length)];
      (confetti[i] as HTMLElement).style.backgroundColor = background;
      (box.children[i] as HTMLElement).style.transform =
        'rotate(' + size * i + 'deg)';
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription?.unsubscribe());
  }
}
