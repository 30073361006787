<div class="mx-4 mt-2" [formGroup]="policyForm">
  <p class="page__hint">
    If no policy is selected then access will be implicitly denied
  </p>
  <mat-radio-group
    formControlName="policyOption"
    (change)="onChangePolicyOption($event?.value)"
  >
    <span *ngFor="let option of COPY_POLICY_OPTIONS">
      <mat-radio-button
        class="{{ option?.class }}"
        [value]="option?.value"
        color="primary"
      >
        {{ option?.displayName }}
      </mat-radio-button>
    </span>
  </mat-radio-group>
  <ng-container
    *ngIf="policyOption?.value === COPY_POLICY_OPTION_KEYS.SPECIFIC_POLICY"
  >
    <ng-container *ngIf="(policyLoader$ | async)?.status">
      <div
        class="mt-3"
        [ngClass]="
          (policyLoader$ | async)?.type === COPY_LOADER_CONFIG_TYPES.ERROR
            ? 'status-failed-clr'
            : 'clr-grey7'
        "
      >
        {{ (policyLoader$ | async)?.message }}
      </div>
    </ng-container>
    <ng-container *ngIf="!(policyLoader$ | async)?.status">
      <div
        *ngIf="policyOption?.value === COPY_POLICY_OPTION_KEYS.SPECIFIC_POLICY"
        class="mt-3"
      >
        <div class="d-flex align-items-baseline mt-2">
          <mat-label class="d-flex form__label">Available Policies </mat-label>
          <div class="ml-2">
            <pcomp-mat-error-info-tooltip
              [errorMessageConfig]="{
                isShowTooltip: false,
                errorCondition:
                  policyIDs?.hasError('required') && policyIDs?.touched,
                errorMessage: COPY_ERROR_MESSAGE?.REQUIRED
              }"
              [uiThemeMode]="uiThemeMode"
            ></pcomp-mat-error-info-tooltip>
          </div>
        </div>
        <div class="mb-2 w-50">
          <div
            class="mt-2"
            [ngClass]="{
              'invalid-range':
                policyIDs?.hasError('required') && policyIDs?.touched
            }"
          >
            <pcomp-mat-select-search
              *ngIf="selectSearchConfigForPolicies?.optionList?.length"
              [parentForm]="policyForm"
              [config]="selectSearchConfigForPolicies"
              [formControlNameInput]="policyIDs"
              controlName="policyIDs"
              [customizedSelectTriggerTextConfig]="
                customizedSelectTriggerTextConfig
              "
            >
            </pcomp-mat-select-search>
          </div>
        </div>
        <div class="mt-3">
          <pcomp-minimized-list
            [data]="policyIDs?.value"
            [removeCancelIcon]="
              FORM_FIELDS_TO_DISABLE_VALUES.includes(
                FORM_FIELDS_TO_DISABLE_KEYS.policy
              )
            "
            [labelkey]="'displayName'"
            minVisibleCount="3"
            (elementRemoved)="removePolicy($event)"
          ></pcomp-minimized-list>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
