import { Page, Sort, TimeISO } from '@prosimoio/services';
import { SelectObj } from './policy';
import { IDNameNs } from './namespace';
import { IDName } from './models';
import { NetworkGroupNs } from './network';

export type NetEgressControl = {
  id: string;
  name: string;
  action: string;
  matches: NetEgressControlMatches;
  namespaces: Array<IDName>;
  networks: Array<IDNameNs>;
  networkGroups: Array<NetworkGroupNs>;
  status: string;
  createdTime: TimeISO;
  updatedTime: TimeISO;
};

export type NetEgressControlMatches = {
  fqdn: Array<NetEgressControlRule>;
};

export type NetEgressControlRule = {
  property: string;
  operation: string;
  values: SelectObj;
};

export type NetEgressResponse = {
  records: Array<NetEgressControl>;
  totalCount: number;
};

export type NetEgressControlSearchFilter = {
  value?: string;
  page?: Page;
  sort?: Array<Sort>;
};
