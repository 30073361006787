import { HttpParams } from '@angular/common/http';

export class QueryOptions {
  private queryParams: HttpParams;

  constructor(queryParams: any) {
    this.queryParams = queryParams;
  }

  getQueryString() {
    return this.queryParams;
  }
}
