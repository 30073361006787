import { Pipe, PipeTransform } from '@angular/core';
import { AppUtil } from '@app/common/util/app-util';

@Pipe({
  name: 'isN2N',
})
export class IsN2nPipe implements PipeTransform {
  transform(log: any): boolean {
    return AppUtil.isLogN2N(log);
  }
}
