import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  Optional,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppUtil } from '@app/common/util/app-util';
import {
  HTTP_RESPONSE_CONTENT_TYPES,
  IMAGE_PATHS,
} from '@app/common/util/constants';
import { PDashLocalStoreUtil } from '@app/state/web-pdash.util';
import { OVERVIEW_TABLE_MESSAGE } from '@app/v2/configure/policy/policy.constants';
import { IOverviewCOntainerConfig } from '@prosimoio/components';

@Component({
  selector: 'app-overlapping-cidr',
  templateUrl: './overlapping-cidr.component.html',
  styleUrls: ['./overlapping-cidr.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverlappingCidrComponent implements OnInit {
  OVERVIEW_TABLE_MESSAGE = OVERVIEW_TABLE_MESSAGE;
  overviewContainerConfig: IOverviewCOntainerConfig;
  themeShade = 'light-theme-light';
  uiThemeMode: string =
    PDashLocalStoreUtil?.getUIThemeMode()?.toLocaleLowerCase();
  searchText: string;
  isDataAvailable: boolean = false;

  constructor(
    private appUtil: AppUtil,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() public dialogref: MatDialogRef<OverlappingCidrComponent>
  ) {
    this.addOverviewContainerConfig();
  }

  ngOnInit(): void {}

  addOverviewContainerConfig(): void {
    this.overviewContainerConfig = {
      title: 'Overlapping Networks',
      status: null,
      downloadImgIcon: IMAGE_PATHS.MANAGEMENT.DOWNLOADS_SELECTED,
      infoIconText:
        'The table shows all the VPCs with overlaps. Least specific subnet is shown first.',
    };
    this.themeShade = `${this.uiThemeMode}-light`;
  }

  onDownload(event: boolean): void {
    if (event) {
      this.appUtil.downloadData(
        JSON.stringify(this.data, null, ' '),
        HTTP_RESPONSE_CONTENT_TYPES.JSON
      );
    }
  }

  closeDialog(): void {
    this.dialogref.close();
  }
}
