import { AccountActivationModel } from './account-activation.model';

export class AccountActivationModelSerializer {
  /**
   * @description : customized response data
   * @param json - response from the server
   */

  fromJson(json: any): AccountActivationModel {
    return new AccountActivationModel('');
  }

  /**
   * @description : payload to request for a new token
   * @param AccountActivationModel
   */
  toJson(accountActivationModel: AccountActivationModel): any {
    return {
      token: accountActivationModel.token,
    };
  }

  toJsonArray(accountActivationModels: AccountActivationModel[]): any {
    return [];
  }
}
