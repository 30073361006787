import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { PlaygroundRecentSearch } from './playground-recent-search.model';

export interface PlaygroundRecentSearchState
  extends EntityState<PlaygroundRecentSearch> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'playground-recent-search', resettable: true })
export class PlaygroundRecentSearchStore extends EntityStore<
  PlaygroundRecentSearchState,
  PlaygroundRecentSearch
> {
  constructor() {
    super();
  }
}

export const playgroundRecentSearchStore = new PlaygroundRecentSearchStore();
