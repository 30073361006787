import { Network, ModelResponseData, PrefixInfo } from '@app/common/models';
import {
  NetworkAutoOnboardTabs,
  NetworkOnboardTabs,
  NetworkStatus,
  autoOnboardOverviewTabs,
  autoOnboardSteps,
} from './network.constants';
import { APP_SETUP_STATUS, CLOUD_TYPES } from '@app/common/util/constants';
import { ErrorObject } from './network.model';
import { TabConfig } from '@prosimoio/components';
import {
  NetworkAutoOnboardStep,
  NetworkOnboardStep,
} from '@app/common/components/drawer/orchestrator/orchestrator.model';

export class NetworkUtil {
  static getNetworkOnboardTabsFromCloudType(
    cloudType: string
  ): Array<TabConfig> {
    if (!cloudType) {
      return NetworkOnboardTabs;
    }

    if (cloudType.toUpperCase() === CLOUD_TYPES.PRIVATE) {
      return NetworkOnboardTabs.filter(
        (tab) => tab.code !== NetworkOnboardStep.VPC
      );
    }

    if (
      ![CLOUD_TYPES.AWS, CLOUD_TYPES.AZURE, CLOUD_TYPES.GCP].includes(cloudType)
    ) {
      return NetworkOnboardTabs;
    }

    return NetworkOnboardTabs.map((tab) => {
      return tab.code === NetworkOnboardStep.VPC &&
        cloudType === CLOUD_TYPES.AZURE
        ? ({
            ...tab,
            tabName: 'VNet Settings',
          } as TabConfig)
        : tab;
    });
  }

  static getNetworkOnboardTabsFromNetwork(
    network: Network,
    overviewTabs: boolean = false
  ): Array<TabConfig> {
    if (!network) {
      return NetworkOnboardTabs;
    }

    if (network?.autoOnboard && network?.privateCloud?.ruleBasedOnboarding) {
      if (overviewTabs) {
        return autoOnboardOverviewTabs;
      } else {
        return NetworkAutoOnboardTabs;
      }
    } else if (network?.autoOnboard) {
      if (overviewTabs) {
        return autoOnboardOverviewTabs.filter(
          (tab) => tab.code !== autoOnboardSteps.RULE
        );
      }
      return NetworkAutoOnboardTabs.filter(
        (tab) => tab.code !== NetworkAutoOnboardStep.RULE
      );
    }

    if (network.privateCloud) {
      return NetworkOnboardTabs.filter(
        (tab) => tab.code !== NetworkOnboardStep.VPC
      );
    }

    if (!network.publicCloud) {
      return NetworkOnboardTabs;
    }

    return NetworkOnboardTabs.map((tab) => {
      return tab.code === NetworkOnboardStep.VPC &&
        network.publicCloud.cloud === CLOUD_TYPES.AZURE
        ? ({
            ...tab,
            tabName: 'VNet Settings',
          } as TabConfig)
        : tab;
    });
  }

  static getNetworkState(network: Network): NetworkStatus {
    if (network.id === '') {
      return NetworkStatus.NEW;
    }
    if (
      [APP_SETUP_STATUS.CONFIGURING, APP_SETUP_STATUS.CONFIGURED].includes(
        network.status
      )
    ) {
      return NetworkStatus.SAVED;
    }
    if (
      [APP_SETUP_STATUS.DEPLOYING, APP_SETUP_STATUS.DECOMMISSIONING].includes(
        network.status
      )
    ) {
      return NetworkStatus['IN-PROGRESS'];
    }
    return NetworkStatus.DEPLOYED;
  }

  static getNetworkCreatePayload(network: Network): Network {
    return {
      name: network.name,
      exportable: network.exportable,
      namespaceID: network.namespaceID,
      publicCloud: !network.publicCloud
        ? null
        : {
            id: null,
            cloud: network.publicCloud.cloud,
            cloudKeyID: network.publicCloud.cloudKeyID,
            cloudRegion: network.publicCloud.cloudRegion,
            cloudType: network.publicCloud.cloudType,
            connectionOption: network.publicCloud.connectionOption,
            cloudNetworks: network.publicCloud.cloudNetworks.map((pc) => {
              return {
                subnets: pc.subnets.map((s) => {
                  let retVal = s;
                  if (!s.virtualSubnet?.length) {
                    delete retVal.virtualSubnet;
                  }
                  return retVal;
                }),
                ...pc,
              };
            }),
          },
      privateCloud: network.privateCloud,
      ownTransitCloud: network.ownTransitCloud,
      security: network.security,
      autoOnboard: network.autoOnboard,
      id: network.id,
    };
  }

  static getInvalidField(field, obj) {
    for (var k in obj) {
      if (typeof obj[k] == 'object' && obj[k] !== null) {
        const bb = this.getInvalidField(k, obj[k]);
        if (bb?.field?.length) {
          return {
            field: (isNaN(field) ? field + '.' : '') + bb.field,
            obj: bb.obj,
            index: bb.index ? bb.index : isNaN(field) ? null : field,
          };
        }
      } else {
        if (obj.valid === false) {
          return { field, obj, index: isNaN(field) ? null : field };
        } else {
          return null;
        }
      }
    }
  }

  static getErrorField(network: ModelResponseData<Network>): ErrorObject {
    // TODO: Needs testing
    // const resp = NetworkUtil.getInvalidField('', network);
    // return {
    //   field: resp.field,
    //   message: resp.obj.errorMessage,
    //   index: resp.index,
    // };
    if (network.name && !network.name.valid) {
      return {
        field: 'name',
        message: network.name.errorMessage,
      };
    }
    if (network.exportable && !network.exportable.valid) {
      return {
        field: 'exportable',
        message: network.exportable.errorMessage,
      };
    }
    if (network.namespaceID && !network.namespaceID.valid) {
      return {
        field: 'namespaceID',
        message: network.namespaceID.errorMessage,
      };
    }
    if (network.publicCloud?.cloud && !network.publicCloud.cloud.valid) {
      return {
        field: 'publicCloud.cloud',
        message: network.publicCloud.cloud.errorMessage,
      };
    }
    if (
      network.publicCloud?.cloudKeyID &&
      !network.publicCloud.cloudKeyID.valid
    ) {
      return {
        field: 'publicCloud.cloudKeyID',
        message: network.publicCloud.cloudKeyID.errorMessage,
      };
    }
    if (
      network.publicCloud?.cloudRegion &&
      !network.publicCloud.cloudRegion.valid
    ) {
      return {
        field: 'publicCloud.cloudRegion',
        message: network.publicCloud.cloudRegion.errorMessage,
      };
    }
    if (
      network.publicCloud?.cloudType &&
      !network.publicCloud.cloudType.valid
    ) {
      return {
        field: 'publicCloud.cloudType',
        message: network.publicCloud.cloudType.errorMessage,
      };
    }
    for (
      let i = 0;
      i < (network.publicCloud?.cloudNetworks || []).length;
      i++
    ) {
      let pc = network.publicCloud?.cloudNetworks[i];
      if (pc.cloudNetworkID && !pc.cloudNetworkID.valid) {
        return {
          field: 'publicCloud.cloudNetworks.cloudNetworkID',
          index: i,
          message: pc.cloudNetworkID.errorMessage,
        };
      }
      if (pc.connectivityType && !pc.connectivityType.valid) {
        return {
          field: 'publicCloud.cloudNetworks.connectivityType',
          index: i,
          message: pc.connectivityType.errorMessage,
        };
      }
      if (pc.connectorPlacement && !pc.connectorPlacement.valid) {
        return {
          field: 'publicCloud.cloudNetworks.connectorPlacement',
          index: i,
          message: pc.connectorPlacement.errorMessage,
        };
      }
      if (pc.hubID && !pc.hubID.valid) {
        return {
          field: 'publicCloud.cloudNetworks.hubID',
          index: i,
          message: pc.hubID.errorMessage,
        };
      }
      if (
        pc.connectorSettings?.bandwidthRange?.max &&
        !pc.connectorSettings.bandwidthRange.max.valid
      ) {
        return {
          field:
            'publicCloud.cloudNetworks.connectorSettings.bandwidthRange.max',
          index: i,
          message: pc.connectorSettings.bandwidthRange.max.errorMessage,
        };
      }
      if (
        pc.connectorSettings?.bandwidthRange?.min &&
        !pc.connectorSettings.bandwidthRange.min.valid
      ) {
        return {
          field:
            'publicCloud.cloudNetworks.connectorSettings.bandwidthRange.min',
          index: i,
          message: pc.connectorSettings.bandwidthRange.min.errorMessage,
        };
      }
      for (let subnet of pc.connectorSettings?.subnets || []) {
        if (subnet && !subnet.valid) {
          return {
            field: 'publicCloud.cloudNetworks.connectorSettings.subnets',
            index: i,
            message: subnet.errorMessage,
          };
        }
      }
      if (pc.serviceSubnets?.mode && !pc.serviceSubnets.mode.valid) {
        return {
          field: 'publicCloud.cloudNetworks.serviceSubnets.mode',
          index: i,
          message: pc.serviceSubnets.mode.errorMessage,
        };
      }
      if (pc.serviceSubnets?.svcID && !pc.serviceSubnets.svcID.valid) {
        return {
          field: 'publicCloud.cloudNetworks.serviceSubnets.svcID',
          index: i,
          message: pc.serviceSubnets.svcID.errorMessage,
        };
      }
      if (pc.serviceSubnets?.svcName && !pc.serviceSubnets.svcName.valid) {
        return {
          field: 'publicCloud.cloudNetworks.serviceSubnets.svcName',
          index: i,
          message: pc.serviceSubnets.svcName.errorMessage,
        };
      }
      for (let subnet of pc.serviceSubnets?.subnets || []) {
        for (let cidr of subnet?.ipAddrCidrs || []) {
          if (cidr && !cidr.valid) {
            return {
              field:
                'publicCloud.cloudNetworks.serviceSubnets.subnets.ipAddrCidrs',
              index: i,
              message: cidr.errorMessage,
            };
          }
        }
        if (subnet?.type && !subnet.type.valid) {
          return {
            field: 'publicCloud.cloudNetworks.serviceSubnets.subnets.type',
            index: i,
            message: subnet.type.errorMessage,
          };
        }
      }
      for (let subnet of pc?.subnets || []) {
        if (subnet?.subnet && !subnet.subnet.valid) {
          return {
            field: 'publicCloud.cloudNetworks.subnet',
            index: i,
            message: subnet.subnet.errorMessage,
          };
        }
        if (subnet?.virtualSubnet && !subnet.virtualSubnet.valid) {
          return {
            field: 'publicCloud.cloudNetworks.virtualSubnet',
            index: i,
            message: subnet.virtualSubnet.errorMessage,
          };
        }
      }
    }
    if (network.privateCloud?.cloud && !network.privateCloud.cloud.valid) {
      return {
        field: 'privateCloud.cloud',
        message: network.privateCloud.cloud.errorMessage,
      };
    }
    if (
      network.privateCloud?.cloudRegion &&
      !network.privateCloud.cloudRegion.valid
    ) {
      return {
        field: 'privateCloud.cloudRegion',
        message: network.privateCloud.cloudRegion.errorMessage,
      };
    }
    if (
      network.privateCloud?.cloudType &&
      !network.privateCloud.cloudType.valid
    ) {
      return {
        field: 'privateCloud.cloudType',
        message: network.privateCloud.cloudType.errorMessage,
      };
    }
    if (
      network.privateCloud?.privateCloudID &&
      !network.privateCloud.privateCloudID.valid
    ) {
      return {
        field: 'privateCloud.privateCloudID',
        message: network.privateCloud.privateCloudID.errorMessage,
      };
    }
    if (
      network.privateCloud?.subnets &&
      network.privateCloud.subnets.some((subnet) => !subnet.valid)
    ) {
      return {
        field: 'privateCloud.subnets',
        message: network.privateCloud.subnets[0].errorMessage,
      };
    }
    if (
      network.privateCloud?.ruleBasedOnboarding &&
      !network.privateCloud?.ruleBasedOnboarding.valid
    ) {
      return {
        field: 'privateCloud.ruleBasedOnboarding',
        message: network.privateCloud.ruleBasedOnboarding.errorMessage,
      };
    }
    if (network.privateCloud?.autoOnboardRules?.rules) {
      for (
        let i = 0;
        i < network.privateCloud.autoOnboardRules.rules?.length;
        i++
      ) {
        const rule = network.privateCloud.autoOnboardRules.rules[i];
        if (!rule.action.valid) {
          return {
            field: 'privateCloud.autoOnboardRules.rules.action',
            message: rule.action.errorMessage,
            index: i,
          };
        }
        if (
          rule.prefixes?.matchCondition &&
          !rule.prefixes?.matchCondition.valid
        ) {
          return {
            field:
              'privateCloud.autoOnboardRules.rules.prefixes.matchCondition',
            message: rule.prefixes.matchCondition.errorMessage,
            index: i,
          };
        }
        const invalidGroup = rule.prefixes.groups.find(
          (group) => !group.id.valid
        );
        if (invalidGroup) {
          return {
            field: 'privateCloud.autoOnboardRules.rules.prefixes.groups.id',
            message: invalidGroup.id.errorMessage,
            index: i,
          };
        }
        for (let prefix of rule.prefixes.values) {
          if (!prefix.prefix.valid) {
            return {
              field:
                'privateCloud.autoOnboardRules.rules.prefixes.values.prefix',
              message: prefix.prefix.errorMessage,
              index: i,
            };
          }
          if (!prefix.min.valid) {
            return {
              field: 'privateCloud.autoOnboardRules.rules.prefixes.values.min',
              message: prefix.min.errorMessage,
              index: i,
            };
          }
          if (!prefix.max.valid) {
            return {
              field: 'privateCloud.autoOnboardRules.rules.prefixes.values.max',
              message: prefix.max.errorMessage,
              index: i,
            };
          }
        }
        if (
          rule.bgpCommunities?.matchCondition &&
          !rule.bgpCommunities?.matchCondition.valid
        ) {
          return {
            field:
              'privateCloud.autoOnboardRules.rules.bgpCommunities.matchCondition',
            message: rule.bgpCommunities.matchCondition.errorMessage,
            index: i,
          };
        }
        const invalidCommGroup = rule.bgpCommunities.communityGroups.find(
          (group) => !group.id.valid
        );
        if (invalidCommGroup) {
          return {
            field:
              'privateCloud.autoOnboardRules.rules.bgpCommunities.communityGroups.id',
            message: invalidGroup.id.errorMessage,
            index: i,
          };
        }
        const invalidCommunity = rule.bgpCommunities.communities.find(
          (community) => !community.valid
        );
        if (invalidCommGroup) {
          return {
            field:
              'privateCloud.autoOnboardRules.rules.bgpCommunities.communities',
            message: invalidCommunity.errorMessage,
            index: i,
          };
        }
        if (
          rule.bgpNeighbours?.matchCondition &&
          !rule.bgpNeighbours?.matchCondition.valid
        ) {
          return {
            field:
              'privateCloud.autoOnboardRules.rules.bgpNeighbours.matchCondition',
            message: rule.bgpNeighbours.matchCondition.errorMessage,
            index: i,
          };
        }
        const invalidNeighbor = rule.bgpNeighbours.values.find(
          (neighbor) => !neighbor.valid
        );
        if (invalidNeighbor) {
          return {
            field: 'privateCloud.autoOnboardRules.rules.bgpNeighbours.values',
            message: invalidCommunity.errorMessage,
            index: i,
          };
        }
      }
    }
    for (let policy of network.security?.policies) {
      if (!policy.id.valid) {
        return {
          field: 'security.policies.id',
          message: policy.id.errorMessage,
        };
      }
    }
  }

  static comparePrefix(prefix1: PrefixInfo, prefix2: PrefixInfo): boolean {
    if (!prefix1 && !prefix2) {
      return true;
    }

    return (
      prefix1.prefix === prefix2.prefix &&
      prefix1.min === prefix2.min &&
      prefix2.max === prefix2.max
    );
  }
}
