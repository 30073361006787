<div class="d-flex flex-row mb-2">
  <div
    [ngClass]="{
      'invalid-range':
        prefixForm.controls.prefix?.errors && prefixForm.controls.prefix.touched
    }"
    class="d-flex flex-column pr-2 mr-1 w-50"
  >
    <div class="d-flex flex-row">
      <mat-label class="mb-2 form__label">Prefix</mat-label>
      <pcomp-mat-error-info-tooltip
        class="ml-2"
        [errorMessageConfig]="{
          isShowTooltip: false,
          errorCondition:
            prefixForm.controls.prefix?.errors &&
            prefixForm.controls.prefix.touched,
          errorMessage: prefixForm.controls.prefix.hasError('required')
            ? 'IP Prefix is required'
            : 'Invalid format'
        }"
        [uiThemeMode]="uiThemeMode"
      ></pcomp-mat-error-info-tooltip>
    </div>
    <mat-form-field
      appearance="outline"
      class="mat-form-field-wrapper-remove-padding"
    >
      <input
        matInput
        placeholder="Enter IP Prefix"
        [formControl]="prefixForm.controls.prefix"
        autocomplete="off"
      />
    </mat-form-field>
  </div>
  <div
    [ngClass]="{
      'invalid-range':
        prefixForm.controls.min?.errors && prefixForm.controls.min.touched
    }"
    class="d-flex flex-column pr-2 mr-1 w-25"
  >
    <div class="d-flex flex-row">
      <mat-label class="mb-2 form__label">Min mask</mat-label>
      <pcomp-mat-error-info-tooltip
        [errorMessageConfig]="{
          isShowTooltip: true,
          errorCondition:
            prefixForm.controls.min?.errors && prefixForm.controls.min.touched,
          tooltipText: prefixForm.controls.min.hasError('required')
            ? 'Min Mask is required'
            : prefixForm.controls.min.getError('mask') ||
              'Min mask should be greater than or equal to IP prefix mask'
        }"
        [uiThemeMode]="uiThemeMode"
      ></pcomp-mat-error-info-tooltip>
    </div>
    <mat-form-field
      appearance="outline"
      class="mat-form-field-wrapper-remove-padding"
    >
      <input
        matInput
        type="number"
        placeholder="Enter Min Mask"
        [formControl]="prefixForm.controls.min"
        autocomplete="off"
      />
    </mat-form-field>
  </div>
  <div
    [ngClass]="{
      'invalid-range':
        prefixForm.controls.max?.errors && prefixForm.controls.max.touched
    }"
    class="d-flex flex-column w-25"
  >
    <div class="d-flex flex-row">
      <mat-label class="mb-2 form__label">Max mask</mat-label>
      <pcomp-mat-error-info-tooltip
        [errorMessageConfig]="{
          isShowTooltip: true,
          errorCondition:
            prefixForm.controls.max?.errors && prefixForm.controls.max?.touched,
          tooltipText: prefixForm.controls.max.hasError('required')
            ? 'Max Mask is required'
            : prefixForm.controls.max.getError('mask') ||
              'Max Mask must be less than or equal to 32'
        }"
        [uiThemeMode]="uiThemeMode"
      ></pcomp-mat-error-info-tooltip>
    </div>
    <mat-form-field
      appearance="outline"
      class="mat-form-field-wrapper-remove-padding"
    >
      <input
        matInput
        type="number"
        placeholder="Enter Max Mask"
        [formControl]="prefixForm.controls.max"
        autocomplete="off"
      />
    </mat-form-field>
  </div>
</div>
