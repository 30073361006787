import { AdminStore, adminStore } from './admin.store';

export class AdminService {
  constructor(private adminStore: AdminStore) {}

  updateAccountStatus(accountStatus: string) {
    this.adminStore.update((state) => ({
      accountStatus,
    }));
  }
}

export const adminService = new AdminService(adminStore);
