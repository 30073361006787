import { OPERATORS_DISPLAY_NAMES } from './search-filter.constants';

export interface SearchFilterModel {
  key: string;
  name: string;
  type: string;
  readOnly?: boolean;
  selected?: Array<string>;
  configs?: any;
  isColumnFilter?: boolean;
  isExactFilter?: boolean;
  hasSecondary?: boolean;
  selectedKey?: string;
}

export const FILTER_OPERATORS = {
  CONTAINS: '=',
  LESS_THAN: '<',
  GREATER_THAN: '>',
  RANGE: 'range',
};
export const ADVANCE_SEARCH_FILTER_OPERATORS = [
  {
    displayName: OPERATORS_DISPLAY_NAMES.CONTAINS,
    value: FILTER_OPERATORS.CONTAINS,
  },
  {
    displayName: OPERATORS_DISPLAY_NAMES.LESS_THAN,
    value: FILTER_OPERATORS.LESS_THAN,
  },
  {
    displayName: OPERATORS_DISPLAY_NAMES.GREATER_THAN,
    value: FILTER_OPERATORS.GREATER_THAN,
  },
  {
    displayName: OPERATORS_DISPLAY_NAMES.RANGE,
    value: FILTER_OPERATORS.RANGE,
  },
];

export const ADVANCE_SEARCH_REGEX = {
  NUMBER_RANGE: /^(\d+(\.?\d{1,})?)*\-(\d+(\.?\d{1,})?)+$/,
  ONLY_NUMBERS: /^(\d+(\.?\d{1,})?)+$/,
  MULTIPLE_WORDS: /[^\,]*[^\,]$/,
};
