<div id="sub-menu-item__container">
  <ul
    [ngClass]="
      tabsWithBottomBotder
        ? 'tabs-with-bottom-border d-flex align-items-center justify-content-between'
        : ''
    "
  >
    <li
      class="clr-grey7 p-3 mx-4 my-3 d-inline-block cursor-pointer"
      [ngClass]="{ 'bdr-btm-primary1 selected': subMenuConfig.isSelected }"
      *ngFor="let subMenuConfig of configs"
      (click)="setSelectedSubMenuItem(subMenuConfig.code)"
    >
      <ng-container *ngIf="!subMenuConfig?.tooltip; else tooltipBLock">{{
        subMenuConfig.name
      }}</ng-container>
      <ng-template #tooltipBLock>
        <pcomp-tooltip-lite>
          <div content>{{ subMenuConfig.name }}</div>
          <div tooltip>{{ subMenuConfig?.tooltip }}</div>
        </pcomp-tooltip-lite>
      </ng-template>
      <img
        *ngIf="subMenuConfig?.isBeta"
        class="pl-1 pb-3"
        [src]="BETA_ICON"
        alt="Beta icon"
        alt=""
      />
    </li>
  </ul>
</div>
