<app-form [showLogo]="false">
  <div id="accountActivationForm" class="activation__container">
    <app-status></app-status>
    <div
      class="activation-form__container"
      *ngIf="activationFormDisplayStatus$ | async"
    >
      <div class="w-25 mx-auto">
        <app-alert></app-alert>
      </div>
      <form
        [formGroup]="requestTokenForm"
        class="activation__form"
        (ngSubmit)="requestNewValidationToken()"
      >
        <div class="input-group mb-3">
          <mat-form-field class="full-width">
            <input
              matInput
              placeholder="Email address"
              formControlName="username"
              autocomplete="off"
              required
            />
            <mat-error *ngIf="username?.errors?.required">
              Email is required.
            </mat-error>
            <mat-error *ngIf="username?.errors?.pattern">
              Invalid Email.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="input-group mb-3">
          <button
            type="submit"
            class="btn btn-light"
            color="primary"
            [disabled]="!requestTokenForm.valid"
            mat-raised-button
          >
            Resend
          </button>
        </div>
      </form>
    </div>
  </div>
</app-form>
