import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { COLORS, IMAGE_PATHS } from '@app/common/util/constants';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileComponent implements OnInit {
  @Input() UserName: string; // Represents firstname and lastname
  @Input() roles;
  @Input() team: string;
  COLOR = COLORS;
  USER_PROFILE_URL = IMAGE_PATHS.NAVIGATION.USER_PROFILE;
  constructor() {}

  ngOnInit() {}
}
