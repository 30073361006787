import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-capsule-renderer',
  templateUrl: './capsule-renderer.component.html',
  styleUrls: ['./capsule-renderer.component.scss'],
})
export class CapsuleRendererComponent implements OnInit {
  @Input() data: any;
  constructor() {}

  ngOnInit(): void {}
}
