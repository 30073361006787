import { CarouselComponent } from './carousel/carousel.component';
import { SwitchModule } from './../common/components/switch/switch.module';
import { LegendModule } from './legend/legend.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '@app/app-material.module';
import { Routes, RouterModule } from '@angular/router';
import { L2headerComponent } from './l2header/l2header.component';
import { AlertComponent } from './alert/alert.component';
import { HeaderComponent } from './header/header.component';
import { PasswordMeterComponent } from './password-meter/password-meter.component';
import { PasswordRuleComponent } from './password-rule/password-rule.component';
import { StepperComponent } from './stepper/stepper.component';
import { LightboxComponent } from './lightbox/lightbox.component';
import { TabComponent } from './tab/tab.component';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { LoaderComponent } from './loader/loader.component';
import { NoDataComponent } from './no-data/no-data.component';
import { NestedDropdownMenuComponent } from './nested-dropdown-menu/nested-dropdown-menu.component';
import { MenuItemComponent } from './nested-dropdown-menu/menu-item/menu-item.component';
import { ReactiveFormsModule } from '@angular/forms';
import { GeoMapComponent } from './geo-map/geo-map.component';
import { StatusDialogComponent } from './status-dialog/status-dialog.component';
import { SafePipeModule, UIThemePipeModule } from '@prosimoio/pipes';
import { NotificationComponent } from './notification/notification.component';
import { CheckboxGroupComponent } from './checkbox-group/checkbox-group.component';
import { NumberOperatorComponent } from './number-operator/number-operator.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { BannerComponent } from './banner/banner.component';
import { MCNSearchHistoryFilterComponent } from './search-history-filter/mcn-search-history-filter/mcn-search-history-filter.component';
import { ZTNASearchHistoryFilterComponent } from './search-history-filter/ztna-search-history-filter/ztna-search-history-filter.component';
import { HelpComponent } from './help/help.component';
import { GridComponent } from './grid/grid.component';
import { FeedbackFormComponent } from './feedback-form/feedback-form.component';
import { ReleaseNotesComponent } from './release-notes/release-notes.component';
import { MainHeaderComponent, TooltipLiteModule } from '@prosimoio/components';
import { DynamicComponentHostDirective } from './lightbox/lightbox.directive';
import { RuleListReadOnlyComponent } from './rule-list-read-only/rule-list-read-only.component';
import { ResizableModule } from '@prosimoio/directives';
import { WarningDialogModule } from './warning-dialog/warning-dialog.module';
import { ConfettiComponent } from './confetti/confetti.component';
import { OverlappingCidrModule } from './overlapping-cidr/overlapping-cidr.module';
const routes: Routes = [];
const commonComponents = [
  HeaderComponent,
  L2headerComponent,
  AlertComponent,
  PasswordMeterComponent,
  PasswordRuleComponent,
  StepperComponent,
  LightboxComponent,
  TabComponent,
  NavigationBarComponent,
  LoaderComponent,
  NestedDropdownMenuComponent,
  MenuItemComponent,
  NoDataComponent,
  CarouselComponent,
  GeoMapComponent,
  StatusDialogComponent,
  NotificationComponent,
  CheckboxGroupComponent,
  MCNSearchHistoryFilterComponent,
  ZTNASearchHistoryFilterComponent,
  NumberOperatorComponent,
  TimePickerComponent,
  BannerComponent,
  HelpComponent,
  GridComponent,
  FeedbackFormComponent,
  ReleaseNotesComponent,
  DynamicComponentHostDirective,
  RuleListReadOnlyComponent,
  ConfettiComponent,
];

@NgModule({
  declarations: [...commonComponents],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    AppMaterialModule,
    LegendModule,
    ReactiveFormsModule,
    SwitchModule,
    SafePipeModule,
    ResizableModule,
    TooltipLiteModule,
    WarningDialogModule,
    OverlappingCidrModule,
    UIThemePipeModule,
    MainHeaderComponent,
  ],
  exports: [...commonComponents, LegendModule],
})
export class CommonComponentsModule {}
