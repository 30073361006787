<ng-template
  #timeLeft
  let-firstDigit="firstDigit"
  let-secondDigit="secondDigit"
  let-type="type"
>
  <div class="access_denied">
    <h4 class="m-0">
      <span class="days"> {{ firstDigit }}{{ secondDigit }}</span>
      <span>
        {{ firstDigit === 0 && secondDigit <= 1 ? 'Day' : 'Days' }} left. Paid
        plans offer higher usage limits, additional settings, and much more.
        <a
          class="link"
          class="cursor-pointer blue-link text-decoration-none"
          href="mailto: support@prosimo.io"
          >Contact us</a
        >
        to extend.
      </span>
    </h4>
  </div>
</ng-template>
<ng-container *ngIf="configs$ | async as configs">
  <ng-container *ngIf="!hideBanners">
    <div class="bg-dark-shade banner-parent" *ngFor="let config of configs">
      <div
        id="banner__container"
        class="row no-gutters justify-content-start align-items-start p-3 banner banner-gradient"
        [ngClass]="{
          'closed-banner': hideBannerSet.has(config.type) || hideBanners,
          'opened-banner': !hideBannerSet.has(config.type) && !hideBanners,
          'trial-banner': config.type === BANNER_TYPE.trial
        }"
        *ngIf="
          config?.type !== BANNER_TYPE?.trial ||
          (config?.type === BANNER_TYPE?.trial &&
            (config?.data?.firstDigit > 0 || config?.data?.secondDigit > 0))
        "
      >
        <!-- banner type -->
        <ng-container [ngSwitch]="config?.type">
          <!-- Maintenance -->
          <ng-container *ngSwitchCase="BANNER_TYPE?.maintenance">
            <div
              class="banner-content col d-flex flex-column justify-conten-around h-100"
            >
              <div class="banner-content-header flex-grow-0">
                {{ config?.header }}
              </div>
              <div
                class="banner-content-description flex-grow-1 d-flex flex-wrap justify-content-center align-items-center"
              >
                {{ config?.data?.message }}
                from
                <span class="bold-txt px-1">
                  {{ config?.data?.from | date : 'short' }}
                  {{ config?.data?.timezone }}</span
                >
                to
                <span class="bold-txt px-1">
                  {{ config?.data?.to | date : 'short' }}
                  {{ config?.data?.timezone }}</span
                >
              </div>
            </div>
          </ng-container>
          <!-- Access Denied -->
          <ng-container *ngSwitchCase="BANNER_TYPE?.accessDenied">
            <div class="col">
              <div class="access_denied">
                <h4 class="m-0">Unauthorized Access</h4>
                <p class="pr-3">
                  Access is denied due to a role-based access control (RBAC)
                  authorization failure. You do not have permission to view the
                  page contents using the credentials that you have
                  provided.Contact your organization's administrator for Prosimo
                  to assign your account into appropriate roles or admin groups.
                </p>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="BANNER_TYPE?.trial">
            <div
              class="d-flex flex-column align-items-center col trial__banner"
              *ngIf="config?.data?.accountType === BANNER_TYPE?.trial"
            >
              <div class="trial__banner-header">
                You are viewing <span class="txt-lg">Prosimo</span> sandbox
              </div>
              <div class="my-1 trial__banner-content">
                Limited set of screens are shown in read-only mode for the
                sandbox. To experience the
                <span class="bold">Prosimo </span> platform entirely, please
                sign up for trial by clicking the
                <span class="bold txt-blue">Onboard</span> button.
              </div>
              <div class="d-flex justify-content-center">
                <ng-container
                  [ngTemplateOutlet]="timeLeft"
                  [ngTemplateOutletContext]="{
                    firstDigit: config?.data?.firstDigit,
                    secondDigit: config?.data?.secondDigit,
                    type: 'sandbox mode'
                  }"
                ></ng-container>
                <div
                  class="ml-5 trial__banner-onboard-btn"
                  (click)="onClickOnboardProsimo()"
                >
                  Onboard Prosimo
                </div>
              </div>
            </div>
            <div
              class="d-flex flex-column col trial__banner align-self-center"
              *ngIf="config?.data?.accountType !== BANNER_TYPE?.trial"
            >
              <ng-container
                [ngTemplateOutlet]="timeLeft"
                [ngTemplateOutletContext]="{
                  firstDigit: config?.data?.firstDigit,
                  secondDigit: config?.data?.secondDigit,
                  type: 'free trial'
                }"
              ></ng-container>
            </div>
          </ng-container>
        </ng-container>
        <!-- close -->
        <div>
          <div class="col-0 d-flex align-items-center" *ngIf="config">
            <mat-icon
              class="banner-close material-icons position-relative"
              (click)="onClose($event, config)"
              >close</mat-icon
            >
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
