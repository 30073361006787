import { UserStore, userStore } from './user.store';
import { UserState } from './user.store';
import { Observable, Subject } from 'rxjs';

export class UserService {
  constructor(private userStore: UserStore) {}
  private userState$ = new Subject<any>();
  updateUser(userState: UserState, isSetUserStateAsObservable = false) {
    let {
      token,
      tokenName,
      tokenExpiry,
      generalToken,
      generalTokenName,
      generalTokenExpiry,
      ...userStateWithoutTokenInfo
    } = userState;
    this.userStore.update(userStateWithoutTokenInfo);
    if (isSetUserStateAsObservable) {
      this.setUserStateAsObservable(userStateWithoutTokenInfo);
    }
  }

  setUserStateAsObservable(userState: UserState) {
    this.userState$.next(userState);
  }

  getUserStateAsObservable(): Observable<any> {
    return this.userState$.asObservable();
  }

  updateProsimoAppStatus(prosimoAppStatus: any) {
    this.userStore.update((state) => ({
      prosimoAppStatus,
    }));
  }

  updateCompanyName(companyName: string) {
    this.userStore.update((state) => ({
      companyName,
    }));
  }

  updateAccountType(accType: string) {
    this.userStore.update((state) => ({
      accType,
    }));
  }

  updateIconPath(iconPath: string) {
    this.userStore.update((state) => ({
      iconPath,
    }));
  }

  updateQueryParam(loginQueryParam: string) {
    this.userStore.update((state) => ({
      loginQueryParam,
    }));
  }

  updateLoginURL(loginURL: string) {
    this.userStore.update((state) => ({
      loginURL,
    }));
  }

  updateLicenseStatus(licenseStatus: string) {
    this.userStore.update((state) => ({
      licenseStatus,
    }));
  }
}

export const userService = new UserService(userStore);
