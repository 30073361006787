import { LegendComponent } from './legend.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [LegendComponent],
  imports: [CommonModule],
  exports: [LegendComponent],
})
export class LegendModule {}
