<div
  id="filter-action-form__container"
  class="shadow-sm mb-5 rounded filter__card bdr-grey3 bg-light-shade"
  [ngStyle]="{ left: leftMargin }"
>
  <div class="filter__avatar" *ngIf="logo">
    <div>
      <img [attr.src]="logo" class="bg-grey13" />
    </div>
  </div>
  <div class="filter__header position-relative border-bottom-grey-thin">
    <div>
      <h2>
        {{ title }}
        <span
          class="pl-2 material-icons info-icon"
          *ngIf="infoTooltip"
          #tooltip="matTooltip"
          [matTooltip]="infoTooltip"
          matTooltipPosition="right"
          >info_outline</span
        >
      </h2>

      <h6>{{ hint }}</h6>
    </div>
    <div class="position-absolute close__sign grey cursor-pointer">
      <span matTooltip="Close..." (click)="onCancel()">&times;</span>
    </div>
  </div>
  <div class="filter__contents lightbox__contents bg-dark-shade">
    <ng-content></ng-content>
  </div>
  <div class="action__controls bg-dark-shade border-top-grey-thin">
    <div class="my-auto mx-auto">
      <button mat-button color="primary" class="clr-grey4" (click)="onCancel()">
        Cancel
      </button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="disableDoneButton || (loader$ | async)"
        (click)="onDone()"
      >
        {{ doneAlias ? doneAlias : "Done" }}
      </button>
    </div>
  </div>
  <ng-container *ngIf="errorMessage$ | async">
    <div class="d-flex align-item-center errorMessage">
      <div class="col message">
        {{ errorMessage$ | async }}
      </div>
      <div
        class="d-flex errorMsg--close"
        (click)="dismissErrorMessage()"
        matTooltip="Close..."
      >
        <i class="material-icons">close</i>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!(errorMessage$ | async) && (loader$ | async)">
    <button class="btn btn-primary" type="button" disabled>
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      Saving...
    </button>
  </ng-container>
</div>
