import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: any, noOfCharsToDisplay: any): any {
    let trail = '...';
    let result;
    if (value) {
      result =
        value.length > noOfCharsToDisplay
          ? value.substring(0, noOfCharsToDisplay) + trail
          : value;
    }
    return result;
  }
}
