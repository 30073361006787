import { CLOUD_TYPES, IMAGE_PATHS } from '@app/common/util/constants';
import { ILightboxBtnConfig } from '@app/dashboard/diagnose/diagnostics/lightbox-form-template/lightbox-model';
import {
  LauncherAction,
  LauncherConfig,
} from '../shared/components/launcher/launcher.model';
import { Tab } from './visual-onboard.model';
export enum FilterTypes {
  EDGE_STATUS = 'EDGE_STATUS',
}

export enum EDGE_STATUS {
  NOT_DEPLOYED = 'NOT_DEPLOYED',
  DEPLOYING = 'DEPLOYING',
  DECOMMISSIONING = 'DECOMMISSIONING',
  DEPLOYED = 'DEPLOYED',
  CONFIGURED = 'CONFIGURED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'In Progress',
}

export const EDGE_FILTER_TEXT = {
  DEPLOYED: 'Edges Deployed (Completed)',
  DEPLOYING: 'Edges Deploying (In Progress)',
  NOT_DEPLOYED: 'Edges not deployed (Region Available)',
  FAILED: 'Edges or Transit Failure',
};

export const CSP_ICONS = {
  AWS: IMAGE_PATHS.CLOUD.CSP.AWS_DARK,
  AZURE: IMAGE_PATHS.CLOUD.CSP.AZURE_DARK,
  GCP: IMAGE_PATHS.CLOUD.CSP.GCP_DARK,
};

export const CSPGW_ICONS: Record<string, string> = {
  AWS: IMAGE_PATHS.VISUAL_ONBOARD.TGW,
  AZURE: IMAGE_PATHS.VISUAL_ONBOARD.VHUB,
};

export const VIRTUAL_PRIVATE_CLOUD_ICONS: Record<string, string> = {
  AWS: IMAGE_PATHS.APP_ONBOARD.DISCOVERY.SUBNET_DARK,
  AZURE: IMAGE_PATHS.DASHBOARD.DIAGNOSE.INFRASTRUCTURE.AZURE.VIRTUAL_NETWORKS,
};

export const SearchBoxPlaceholders: { [id: string]: string } = {
  INFRASTRUCTURE: 'Search for region, cloud and edge name',
};

export enum DrawerResponseContext {
  EDGE_CREATE = 'edge-create',
  EDGE_UPDATE = 'edge-update',
  TGW_CREATE = 'tgw-create',
  VHUB_CREATE = 'vhub-create',
  VPC_ATTACH = 'vpc-attach',
  VNET_ATTACH = 'vnet-attach',
  VPC_UPDATE = 'vpc-update',
  VNET_UPDATE = 'vnet-update',
}

export enum TransitBuilderContext {
  VPC_DELETE = 'vpc-delete',
  VNET_DELETE = 'vnet-delete',
  TGW_DELETE = 'tgw-delete',
  VHUB_DELETE = 'vhub-delete',
  EDGE_ATTACH = 'edge-attach',
  EDGE_DETACH = 'edge-detach',
  ALL_DETACH = 'all-detach',
  DRIFT_ACK_IGNORE = 'ignore',
  DRIFT_ACK_RESTORE = 'restore',
}

export const LauncherConfigs: Array<LauncherConfig> = [
  // {
  //   buttons: [
  //     {
  //       iconURL: IMAGE_PATHS.COMMON.PLUS,
  //       title: 'Zoom In',
  //       tooltip: 'Zoom In',
  //       action: LauncherAction['ZOOM-IN'],
  //       validCSPs: [CLOUD_TYPES.AWS, CLOUD_TYPES.AZURE, CLOUD_TYPES.GCP],
  //       largeIcon: false,
  //     },
  //     {
  //       iconURL: IMAGE_PATHS.COMMON.MINUS,
  //       title: 'Zoom Out',
  //       tooltip: 'Zoom Out',
  //       action: LauncherAction['ZOOM-OUT'],
  //       validCSPs: [CLOUD_TYPES.AWS, CLOUD_TYPES.AZURE, CLOUD_TYPES.GCP],
  //       largeIcon: false,
  //     },
  //   ],
  // },
  {
    buttons: [
      {
        iconURL: IMAGE_PATHS.MANAGEMENT.GROUPINGS,
        title: 'View',
        tooltip: 'Menu',
        action: LauncherAction.MENU,
        validCSPs: [CLOUD_TYPES.AWS, CLOUD_TYPES.AZURE, CLOUD_TYPES.GCP],
        largeIcon: false,
      },
      {
        iconURL: IMAGE_PATHS.DASHBOARD.DIAGNOSE.CLOUD_TRACER.REFRESH,
        title: 'Reset',
        tooltip: 'Reset Configuration',
        action: LauncherAction.RESET,
        validCSPs: [CLOUD_TYPES.AWS, CLOUD_TYPES.AZURE, CLOUD_TYPES.GCP],
        largeIcon: false,
      },
      {
        iconURL: CSPGW_ICONS.AWS,
        title: 'TGW',
        tooltip: 'Add new TGW',
        action: DrawerResponseContext.TGW_CREATE,
        validCSPs: [CLOUD_TYPES.AWS],
        largeIcon: false,
      },
      {
        iconURL: CSPGW_ICONS.AZURE,
        title: 'VHUB',
        tooltip: 'Add new VHUB',
        action: DrawerResponseContext.VHUB_CREATE,
        validCSPs: [CLOUD_TYPES.AZURE],
        largeIcon: false,
      },
      {
        title: 'VPCs',
        action: DrawerResponseContext.VPC_ATTACH,
        tooltip: 'Add VPCs',
        iconURL: VIRTUAL_PRIVATE_CLOUD_ICONS.AWS,
        validCSPs: [CLOUD_TYPES.AWS, CLOUD_TYPES.GCP],
        largeIcon: false,
      },
      {
        title: 'VNets',
        action: DrawerResponseContext.VNET_ATTACH,
        tooltip: 'Add VNets',
        iconURL: VIRTUAL_PRIVATE_CLOUD_ICONS.AZURE,
        validCSPs: [CLOUD_TYPES.AZURE],
        largeIcon: true,
      },
    ],
  },
  // VPC: {
  //   buttons: [
  //     {
  //       title: 'Managed',
  //       component: DrawerResponseContext.VPC_ATTACH,
  //       tooltip: 'Add VPCs',
  //       iconURL: VIRTUAL_PRIVATE_CLOUD_ICONS.AWS,
  //       validCSPs: [CLOUD_TYPES.AWS, CLOUD_TYPES.GCP],
  //     },
  //     // {
  //     //   title: 'Unmanaged',
  //     //   iconURL: IMAGE_PATHS.APP_ONBOARD.DISCOVERY.SUBNET_DARK,
  //     // },
  //   ],
  // },
  // VNET: {
  //   buttons: [
  //     {
  //       title: 'Managed',
  //       component: DrawerResponseContext.VNET_ATTACH,
  //       tooltip: 'Add VNets',
  //       iconURL: VIRTUAL_PRIVATE_CLOUD_ICONS.AZURE,
  //       validCSPs: [CLOUD_TYPES.AZURE],
  //     },
  //   ],
  // },
];

export const TGW_TABS: Array<Tab> = [
  {
    label: 'Select TGW',
    value: 'selectTGW',
  },
  {
    label: 'Create New TGW',
    value: 'createTGW',
  },
];

export const TGW_TABS_KEYS = {
  SELECT_TGW: 'selectTGW',
  CREATE_TGW: 'createTGW',
};

export const DrawerBtnType: { [key: string]: ILightboxBtnConfig } = {
  ADDVPC: {
    code: 'ADDVPC',
    name: 'Add VPCs',
    disabled: false,
  },
  ADDVNET: {
    code: 'ADDVNET',
    name: 'Add VNets',
    disabled: false,
  },
  ADDTGW: {
    code: 'ADDTGW',
    name: 'Create TGW',
    disabled: true,
  },
  ADDVHUB: {
    code: 'ADDVHUB',
    name: 'Create VHUB',
    disabled: true,
  },
  CREATE: {
    code: 'CREATE',
    name: 'Create',
    disabled: false,
  },
  UPDATE: {
    code: 'UPDATE',
    name: 'Update',
    disabled: true,
  },
  UPDATEVPC: {
    code: 'UPDATEVPC',
    name: 'Update VPCs',
    disabled: false,
  },
  UPDATEVNET: {
    code: 'UPDATEVNET',
    name: 'Update VNets',
    disabled: false,
  },
};

export const DrawerBtnByContext: {
  [context in DrawerResponseContext]: ILightboxBtnConfig;
} = {
  [DrawerResponseContext.EDGE_CREATE]: DrawerBtnType.CREATE,
  [DrawerResponseContext.EDGE_UPDATE]: DrawerBtnType.UPDATE,
  [DrawerResponseContext.TGW_CREATE]: DrawerBtnType.ADDTGW,
  [DrawerResponseContext.VHUB_CREATE]: DrawerBtnType.ADDVHUB,
  [DrawerResponseContext.VNET_ATTACH]: DrawerBtnType.ADDVNET,
  [DrawerResponseContext.VPC_ATTACH]: DrawerBtnType.ADDVPC,
  [DrawerResponseContext.VNET_UPDATE]: DrawerBtnType.UPDATEVNET,
  [DrawerResponseContext.VPC_UPDATE]: DrawerBtnType.UPDATEVPC,
};

export enum DeploymentActionType {
  ADD = 'ADD',
  DEL = 'DEL',
  MOD = 'MOD',
  EXIST = '',
  RESTORE = 'RESTORE',
  IGNORE = 'IGNORE',
}

export enum SummaryRowActions {
  EDIT = 'edit',
  DELETE = 'delete',
}

export const VIRTUAL_PRIVATE_CLOUD_NAME_PLURAL = {
  AWS: 'vpcs',
  AZURE: 'vnets',
  GCP: 'vpcs',
};

export const CSP_GATEWAY_NAME_PLURAL = {
  AWS: 'tgws',
  AZURE: 'vhubs',
};

export const virtualCloudPluralMapping = {
  vpc: {
    '=1': 'VPC',
    other: 'VPCs',
  },
  vnet: {
    '=1': 'VNet',
    other: 'VNets',
  },
};

export const gatewayPluralMapping = {
  tgw: {
    '=1': 'TGW',
    other: 'TGWs',
  },
  vhub: {
    '=1': 'VHUB',
    other: 'VHUBs',
  },
};

export interface EdgeAttachment {
  id?: string;
  connType?: string;
  pappFqdn?: string;
  attachType?: string;
  weight?: number;
}

export const DATA_FETCH_STATUS_ERROR_MSG =
  'Failed to retrieve cloud asset refresh status';

export const TABS_DETAILS = {
  FIRST_TAB_MARGIN: 'mat-tab-label-ml-16',
  TAB_MARGIN: 'mat-tab-label-ml-32',
};

export const MAP_ERROR_MESSAGES = { DRIFT_DETECTED: 'DRIFT DETECTED' };
