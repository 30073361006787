<div
  id="overlay"
  [ngClass]="{
    'position-fixed vh-100': true,
    'd-none': !(lightboxState$ | async),
    'd-inline-block': (lightboxState$ | async)
  }"
  (click)="onOverlayAction()"
>
  <div id="overlay__content" class="vh-100">
    <div (click)="$event.stopPropagation()">
      <ng-container>
        <ng-template dynaCompHost></ng-template>
      </ng-container>
      <ng-container>
        <ng-content></ng-content>
      </ng-container>
    </div>
  </div>
</div>
