import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
  EventEmitter,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, of } from 'rxjs';
import {
  startWith,
  map,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs/operators';
import { AutoComplete } from './auto-complete.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { REGEX } from '@app/common/util/constants';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoCompleteComponent implements OnInit, OnChanges {
  @Input() uiThemeMode: string;
  @Input() isSelectAllOption: boolean = false;
  @Input() config: AutoComplete;
  @Input() hideSelection = false;
  @Input() emitAfterInit = false;
  @Input() displayCheckbox = false;
  @Input() readOnly = false;
  @Input() removable = true;
  @Input() showPanelOnInit = false;
  @Input() displayOptionTooltip = false;
  @Input() showAddButton = false; // to show/hide add button to add seelcted/typed value
  @Input() validators; // validators array to pass validators to apply on input control
  @Input() multiSelect = true; // to select multiplr/single vaue from selected/typed value
  @Input() emitOnBlur = false; // to emit selected/typed value on blur event
  @Input() isSimpleSelect = false;
  @Input() preventFreeText = false;
  @Input() errorText = '';
  @Input() parentMetaInfo;
  @Input() suffixValueObj = null;
  @Output() changeSelection = new EventEmitter<any[]>();
  @Output() changeSearchStr = new EventEmitter<string>();
  visible = true;
  selectable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  inputCtrl = new UntypedFormControl('');
  selectedOptionsMap: Map<string, any>;
  selectedOptions: Array<any>;
  filteredOptions$: Observable<any[]>;
  options: Array<any>;
  targetKey: string;
  uniqueKey: string;
  miscType: any;
  ctx: any;
  isAllSelected: boolean = false;
  tooltipThemeClass: string = '';
  @ViewChild('autoInput', { static: false })
  autoInput: ElementRef<HTMLInputElement>;
  @ViewChild('trigger', { read: MatAutocompleteTrigger })
  inputAutoComplete: MatAutocompleteTrigger;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

  constructor(private cdr: ChangeDetectorRef) {
    // this.filteredOptions$ = this.inputCtrl.valueChanges.pipe(
    //   debounceTime(500),
    //   distinctUntilChanged(),
    //   map((filterStr: string | null) => filterStr ? this._filter(filterStr) : this.options.slice())
    // );
    this.ctx = this;
  }

  ngOnInit() {
    this.tooltipThemeClass =
      'cdk-component-container--' + this.uiThemeMode + '-dark';
  }

  ngOnChanges(changes: SimpleChanges) {
    const keys = Object.keys(changes);
    if (
      keys.length === 1 &&
      changes.errorText &&
      !changes.errorText?.firstChange &&
      !this.isSimpleSelect
    ) {
      // do nothing for error text changes
    } else {
      // render the component and add the certain behavior based on the input values
      this.initData(changes);
    }
  }

  /**
   * Create selected options map to prevent duplicate entry and add the rendering logic based on input values
   * @param event
   */
  initData(changes: any) {
    this.inputCtrl.setValidators(this.validators);
    this.createSelectedOptionsMap();
    this.selectedOptions = this.config?.selected?.slice();
    this.options = this.config?.options.slice();
    this.targetKey = this.config?.targetKey;
    this.uniqueKey = this.config?.uniqueKey;
    this.miscType = this.config?.miscType;
    this.checkForSelectAll();
    if (this.emitAfterInit) {
      this.emitChanges();
    }

    if (
      this.showPanelOnInit &&
      !changes.config?.firstChange &&
      (!this.matAutocomplete.isOpen || this.config?.querySearch)
    ) {
      //this.autoInput.nativeElement.focus();
      this.filteredOptions$ = of(this.options);
      setTimeout(() => {
        this.autoInput?.nativeElement?.focus();
        this.inputAutoComplete?.openPanel();
      }, 0);
    }

    if (this.config?.clearSearchValue) {
      this.inputCtrl?.patchValue('');
    }

    if (this.isSimpleSelect) {
      const [selectedOption] = this.selectedOptions;
      this.inputCtrl.patchValue(this.getDisplayValue(selectedOption));
    }

    // listen the input value changes
    this.filteredOptions$ = this.inputCtrl.valueChanges.pipe(
      debounceTime(500),
      startWith(null),
      distinctUntilChanged(),
      map((filterStr: string | null) =>
        filterStr ? this._filter(filterStr) : this.options?.slice()
      )
    );
  }

  /**
   * Create selected options map to prevent duplicate entry
   */
  createSelectedOptionsMap() {
    this.selectedOptionsMap = new Map<string, any>();
    const uniqueKey = this.config?.uniqueKey;
    this.config?.selected?.forEach((option) => {
      this.selectedOptionsMap.set(
        uniqueKey && option[uniqueKey] ? option[uniqueKey] : option,
        option
      );
    });
  }

  /**
   * Add the input value to selected option after separatorKeyCode
   * @param event
   */
  add(event: MatChipInputEvent): void {
    // Add option only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen && this.selectedOptions) {
      let value = event.value.trim();
      // Add option to selected list
      if (value && !this.isSelected(value) && this.config?.allowEntry) {
        const { inputType = '', isCustomFormatRequired = false } = this
          .miscType || { inputType: '', isCustomFormatRequired: false };
        if (
          inputType === this.suffixValueObj?.inputType &&
          isCustomFormatRequired
        ) {
          if (REGEX.IP_ADDRESS_PATTERN.test(value)) {
            value = `${value}/${this.suffixValueObj.value}`;
          }
        }
        if (!this.preventFreeText) {
          this.selectedOptions.push(value);
          this.selectedOptionsMap.set(value, value);
          this.emitChanges();
        }
      }
      this.resetInput(event);
    }
  }

  /**
   * Reset inputCtrl  and matchip input
   * @param event
   */
  resetInput(event: MatChipInputEvent) {
    const input = event.input;
    if (input) {
      input.value = '';
    }
    this.inputCtrl.setValue(null);
  }

  /**
   * Remove option from the selected option
   * @param option
   * @param pos
   */
  remove(option: any, pos?: number): void {
    const optionKey = this.getOptionKey(option) || option;
    if (pos === 0 || pos > 0) {
      this.selectedOptions.splice(pos, 1);
    } else {
      this.selectedOptions = this.selectedOptions.filter(
        (item) => item[this.uniqueKey] !== optionKey
      );
    }
    this.selectedOptionsMap.delete(optionKey);
    this.emitChanges();
    this.resetInputValueAndFocus();
  }

  /**
   * if there is no add button and values needs to selected on selection from dropdown option
   * Select option if does not exit in the selected array
   * @param event
   */
  selected(event: MatAutocompleteSelectedEvent): void {
    const option = event.option.value;
    // if there is no add button and value needs to selected on selection from dropdown option
    if (option) {
      if (this.isSimpleSelect) {
        this.onSimpleSelect(option);
      } else {
        if (this.isEmitValueOnSelection()) {
          if (this.isSelected(option) && this.displayCheckbox) {
            this.remove(option);
          } else {
            this.selectedOptions.push(option);
            this.selectedOptions = [...new Set(this.selectedOptions)];
            if (this.parentMetaInfo) {
              this.selectedOptions = this.selectedOptions?.map((option) => {
                option['parentMetaInfo'] = this.parentMetaInfo;
                return option;
              });
            }
            this.selectedOptionsMap.set(this.getOptionKey(option), option);
            this.resetInputValueAndFocus();
            this.emitChanges();
          }
        } else {
          // if there is add button then patch value to input on selection of option
          this.autoInput.nativeElement.value = option;
          this.inputCtrl.setValue(option);
        }
      }
    }
  }

  /**
   * Add or remove Selected Options from list
   * @param event
   */
  multiSelectOptionChanged(event: MatCheckboxChange): void {
    const option = event.source.value;
    if (this.isEmitValueOnSelection()) {
      if (this.isSelected(option)) {
        this.remove(option);
      } else {
        this.selectedOptions.push(option);
        this.selectedOptionsMap.set(this.getOptionKey(option), option);
        this.resetInputValueAndFocus();
        this.emitChanges();
      }
    } else {
      // if there is add button then patch value to input on selection of option
      this.autoInput.nativeElement.value = option;
      this.inputCtrl.setValue(option);
    }
    this.checkForSelectAll();
  }

  /**
   * Toggle select 'All' checkbox to select unselect all the apps.
   */
  toggleSelectAll(): void {
    this.isAllSelected = !this.isAllSelected;
    if (this.isAllSelected) {
      for (let option of this.options) {
        const isExist = this.selectedOptions?.find((x) => x.id === option.id);
        if (!isExist) {
          this.selectedOptions?.push(option);
          this.selectedOptionsMap.set(this.getOptionKey(option), option);
        }
      }
    } else {
      this.selectedOptions = [];
      this.selectedOptionsMap.clear();
    }
    this.emitChanges();
    this.resetInputValueAndFocus();
  }

  /**
   * Setting 'isAllSelected' flag based on the length of 'selectedOptions' and 'options' list.
   */
  checkForSelectAll(): void {
    if (this.isSelectAllOption) {
      this.isAllSelected =
        this.selectedOptions?.length === this.options?.length ? true : false;
    }
  }

  /**
   * reset the input value and unfocused the cursor
   */
  resetInputValueAndFocus() {
    if (this.autoInput) {
      this.autoInput.nativeElement.value = '';
      this.autoInput.nativeElement.blur();
    }
    this.inputCtrl.setValue(null);
  }

  /**
   * to decide if value needs to be emitted on selection of option or not
   */
  isEmitValueOnSelection() {
    return !this.showAddButton && !this.emitOnBlur;
  }

  /**
   * add option on blur of the input
   */
  addOptionOnBlur() {
    if (!this.showAddButton && this.config?.allowEntry) {
      this.addOption();
    }
  }

  /**
   * to add selected or typed option on click of add button event
   */
  addOption() {
    const newVal = this.inputCtrl.value;
    if (this.preventFreeText) {
      return;
    }
    if (this.inputCtrl.valid) {
      if (!newVal) {
        this.inputCtrl.markAsTouched();
        return;
      }
      if (newVal) {
        // if multiselect is allowed then push values to array and emit selected values array
        if (this.multiSelect) {
          if (!this.isSelected(newVal)) {
            this.selectedOptions.push(newVal);
            this.selectedOptionsMap.set(this.getOptionKey(newVal), newVal);
            this.resetInputValueAndFocus();
            this.emitChanges();
          } else {
            this.inputCtrl.setErrors({ duplicate: true });
          }
        } else {
          // if multiselect is not allowed then emit single value
          this.changeSelection.emit(newVal);
        }
      }
    }
  }

  onSimpleSelect(option: any) {
    this.selectedOptions = [option];
    this.selectedOptionsMap = new Map([[this.getOptionKey(option), option]]);
    this.emitChanges();
  }

  /**
   * Return true if the option is selected
   * @param option
   */
  isSelected(option: any) {
    const optionKey = this.getOptionKey(option);
    return this.selectedOptionsMap.has(optionKey);
  }

  getOptionKey(option) {
    return this.uniqueKey && option[this.uniqueKey]
      ? option[this.uniqueKey]
      : option;
  }

  getDisplayValue(option) {
    return this.targetKey ? option[this.targetKey] || '' : option;
  }

  /**
   * Filter the auto-complete dropdown list
   * @param value
   */
  private _filter(value: string): any[] {
    if (this?.config?.querySearch) {
      this.changeSearchStr.emit(value);
      this.autoInput?.nativeElement.blur();
    } else {
      if (typeof value === 'string') {
        const filterValue = value.toLowerCase();
        return this.options?.filter((option) => {
          const optionValue = this.getDisplayValue(option);
          return optionValue.toLowerCase().includes(filterValue);
        });
      }
    }
  }

  /**
   * Open the auto complete dialog on focus
   * @param event
   */
  onFocus(event) {
    this.inputCtrl.patchValue('');
  }

  onSimpleSelectBlur(event: any) {
    if (!this.matAutocomplete.isOpen) {
      const value = this.inputCtrl?.value?.trim();
      if (value != null && this.config.allowEntry) {
        this.selectedOptions = [value];
        this.selectedOptionsMap = new Map([[this.getOptionKey(value), value]]);
        this.emitChanges();
      }
    }
  }

  /**
   * Emit changes
   * @param data
   */
  emitChanges() {
    this.changeSelection.emit(this.selectedOptions.slice());
    if (!this.isSimpleSelect) {
      this.errorText = this.errorText ? this.errorText : '';
      this.cdr.markForCheck();
    }
  }
}
