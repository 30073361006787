import {
  DIAGNOSE_MENU_OPTIONS,
  IMAGE_PATHS,
} from './../../common/util/constants';
import { INSIGHTS_MENU_OPTIONS } from '@app/common/util/constants';
import { CONSTANTS } from 'environments/environment';

export const LEGEND_ID = {
  PROTECT: 'protect',
  OPTIMIZE: 'optimize',
};

export const MAIN_DASHBOARD_TILES = {
  HTTP_REQUESTS: 'troubleshoot',
  SESSIONS: 'mcn-devices',
  VIOLATIONS: 'protect',
  TRANSIT_HEALTH: 'transitHealth',
  USAGE: 'savedData',
  APP_HEALTH: 'ux',
  SUMMARY: 'devices',
};

export const MAIN_DASHBOARD_ROUTES = {
  apps: {
    url: (viewType) => `/dashboard/apps`,
    displayBanner: false,
    customizePayload: false,
  },
  cloud: {
    url: (viewType) => CONSTANTS.MANAGEMENT.UI_PATH_CLOUD,
    displayBanner: false,
    customizePayload: false,
  },
  edge: {
    url: (viewType) => CONSTANTS.MANAGEMENT.UI_PATH_EDGE,
    displayBanner: false,
    customizePayload: false,
  },
  guide: {
    url: (viewType) => `/dashboard/guide`,
    displayBanner: false,
    customizePayload: false,
  },
  connectorGroups: {
    url: (viewType) => `${CONSTANTS.MANAGEMENT.UI_PATH_CONNECTOR_GROUPS}`,
    displayBanner: false,
    customizePayload: false,
  },
  users: {
    url: (viewType) => CONSTANTS.INSIGHTS.UI_PATH_SUMMARY(viewType),
    type: INSIGHTS_MENU_OPTIONS.INSIGHTS.ZTNA,
    tab: INSIGHTS_MENU_OPTIONS.SUMMARY,
    subMenu: 'DEVICE',
    customizePayload: false,
  },
  ['mcn-apps']: {
    url: (viewType) => CONSTANTS.INSIGHTS.UI_PATH_SESSION(viewType),
    type: INSIGHTS_MENU_OPTIONS.INSIGHTS.MCN,
    tab: INSIGHTS_MENU_OPTIONS.SESSION,
    subMenu: 'DEVICE',
    customizePayload: false,
  },
  devices: {
    url: (viewType) => CONSTANTS.INSIGHTS.UI_PATH_SUMMARY(viewType),
    type: INSIGHTS_MENU_OPTIONS.INSIGHTS.ZTNA,
    tab: INSIGHTS_MENU_OPTIONS.SUMMARY,
    title: 'Sessions',
    subMenu: 'DEVICE',
    customizePayload: true,
    displayBanner: true,
  },
  ['mcn-devices']: {
    url: (viewType) => CONSTANTS.INSIGHTS.UI_PATH_SESSION(viewType),
    type: INSIGHTS_MENU_OPTIONS.INSIGHTS.MCN,
    tab: INSIGHTS_MENU_OPTIONS.SESSION,
    title: 'Sessions',
    subMenu: 'DEVICE',
    customizePayload: true,
    displayBanner: true,
  },
  'devices-no-banner': {
    url: (viewType) => CONSTANTS.INSIGHTS.UI_PATH_SUMMARY(viewType),
    type: INSIGHTS_MENU_OPTIONS.INSIGHTS.ZTNA,
    tab: INSIGHTS_MENU_OPTIONS.SUMMARY,
    title: 'Summary',
    subMenu: 'DEVICE',
    customizePayload: true,
    displayBanner: false,
  },
  'mcn-devices-no-banner': {
    url: (viewType) => CONSTANTS.INSIGHTS.UI_PATH_SESSION(viewType),
    type: INSIGHTS_MENU_OPTIONS.INSIGHTS.MCN,
    tab: INSIGHTS_MENU_OPTIONS.SESSION,
    title: 'Sessions',
    subMenu: 'DEVICE',
    customizePayload: true,
    displayBanner: false,
  },
  networks: {
    url: (viewType) => CONSTANTS.INSIGHTS.UI_PATH_SUMMARY(viewType),
    type: INSIGHTS_MENU_OPTIONS.INSIGHTS.ZTNA,
    tab: INSIGHTS_MENU_OPTIONS.SUMMARY,
    subMenu: 'SERVICE_PROVIDER',
    title: 'Sessions',
    customizePayload: false,
    displayBanner: false,
  },
  protect: {
    url: (viewType) => CONSTANTS.INSIGHTS.UI_PATH_EVENTS(viewType),
    type: INSIGHTS_MENU_OPTIONS.INSIGHTS.ZTNA,
    tab: INSIGHTS_MENU_OPTIONS.EVENTS,
    subMenu: 'APP_EVENTS_AGGREGATE',
    title: 'Violations',
    customizePayload: true,
    displayBanner: true,
  },
  sankey: {
    url: (viewType) => CONSTANTS.INSIGHTS.UI_PATH_SUMMARY(viewType),
    type: INSIGHTS_MENU_OPTIONS.INSIGHTS.ZTNA,
    tab: INSIGHTS_MENU_OPTIONS.SUMMARY,
    subMenu: 'DEVICE',
    displayBanner: false,
    customizePayload: false,
  },
  risk: {
    url: (viewType) => CONSTANTS.INSIGHTS.UI_PATH_USER_RISK(viewType),
    type: INSIGHTS_MENU_OPTIONS.INSIGHTS.ZTNA,
    tab: INSIGHTS_MENU_OPTIONS.USER_RISK,
    subMenu: 'USER_RISK_ROOT_CAUSES',
    customizePayload: true,
  },
  ux: {
    url: (viewType) => CONSTANTS.INSIGHTS.UI_PATH_APP_EXPERIENCE(viewType),
    type: INSIGHTS_MENU_OPTIONS.INSIGHTS.ZTNA,
    tab: INSIGHTS_MENU_OPTIONS.APP_EXPERIENCE,
    title: 'App Health',
    customizePayload: true,
  },
  accessed: {
    url: (viewType) => CONSTANTS.INSIGHTS.UI_PATH_APPS_ACCESSED(viewType),
    type: INSIGHTS_MENU_OPTIONS.INSIGHTS.ZTNA,
    tab: INSIGHTS_MENU_OPTIONS.APPS_ACCESSED,
    customizePayload: false,
  },
  dataUsage: {
    url: (viewType) => CONSTANTS.INSIGHTS.UI_PATH_PERFORMANCE(viewType),
    type: INSIGHTS_MENU_OPTIONS.INSIGHTS.ZTNA,
    tab: INSIGHTS_MENU_OPTIONS.APPS_ACCESSED,
    subMenu: INSIGHTS_MENU_OPTIONS.BANDWIDTH_USAGE,
    customizePayload: false,
  },
  performance: {
    url: (viewType) => CONSTANTS.INSIGHTS.UI_PATH_PERFORMANCE(viewType),
    type: INSIGHTS_MENU_OPTIONS.INSIGHTS.ZTNA,
    tab: INSIGHTS_MENU_OPTIONS.PERFORMANCE,
    subMenu: 'LATENCY',
    displayBanner: false,
    customizePayload: false,
  },
  savedData: {
    url: (viewType) => CONSTANTS.INSIGHTS.UI_PATH_PERFORMANCE(viewType),
    type: INSIGHTS_MENU_OPTIONS.INSIGHTS.ZTNA,
    tab: INSIGHTS_MENU_OPTIONS.PERFORMANCE,
    subMenu: 'DATA_USAGE',
    displayBanner: false,
    customizePayload: false,
  },
  geo: {
    url: (viewType) => CONSTANTS.INSIGHTS.UI_PATH_SUMMARY(viewType),
    type: INSIGHTS_MENU_OPTIONS.INSIGHTS.ZTNA,
    tab: INSIGHTS_MENU_OPTIONS.SUMMARY,
    subMenu: 'COUNTRY',
    customizePayload: false,
  },
  ['mcn-geo']: {
    url: (viewType) => CONSTANTS.INSIGHTS.UI_PATH_SESSION(viewType),
    type: INSIGHTS_MENU_OPTIONS.INSIGHTS.MCN,
    tab: INSIGHTS_MENU_OPTIONS.SESSION,
    subMenu: 'COUNTRY',
    customizePayload: false,
  },
  events: {
    url: (viewType) => CONSTANTS.INSIGHTS.UI_PATH_EVENTS(viewType),
    type: INSIGHTS_MENU_OPTIONS.INSIGHTS.ZTNA,
    tab: INSIGHTS_MENU_OPTIONS.EVENTS,
    subMenu: 'SEVERITY',
    customizePayload: true,
  },
  cloudTransit: {
    url: (viewType) => `${CONSTANTS.CLOUD_TRANSIT.UI_PATH}`,
    title: 'Cloud Transit',
    displayBanner: false,
    customizePayload: true,
  },
  transitHealth: {
    url: (viewType) => `${CONSTANTS.CLOUD_TRANSIT.UI_PATH}`,
    title: 'Transit Health',
    displayBanner: false,
    customizePayload: true,
  },
  troubleshoot: {
    url: (viewType) => `${CONSTANTS.INSIGHTS.UI_PATH_TROUBLESHOOT(viewType)}`,
    type: DIAGNOSE_MENU_OPTIONS.DIAGNOSE,
    tab: DIAGNOSE_MENU_OPTIONS.TROUBLESHOOTING,
    title: 'HTTP Requests',
    displayBanner: true,
    customizePayload: false,
  },
};

export const TIME_RANGES = [
  {
    key: 'DAY',
    name: 'Day',
  },
  {
    key: 'WEEK',
    name: 'Week',
  },
  {
    key: 'MONTH',
    name: 'Month',
  },
];

export const APP_SUMMARY = [
  {
    name: 'clouds',
    displayName: 'Clouds',
    count: null,
    redirectTo: MAIN_DASHBOARD_ROUTES.cloud,
    imgURL: IMAGE_PATHS.DASHBOARD.MAIN.CLOUD,
  },
  {
    name: 'regions',
    displayName: 'Regions',
    count: null,
    redirectTo: MAIN_DASHBOARD_ROUTES.edge,
    imgURL: IMAGE_PATHS.DASHBOARD.MAIN.REGIONS,
  },
  {
    name: 'edges',
    displayName: 'Edges',
    count: null,
    redirectTo: MAIN_DASHBOARD_ROUTES.edge,
    imgURL: IMAGE_PATHS.DASHBOARD.MAIN.EDGES,
  },
  {
    name: 'connectorGroups',
    displayName: 'Connector Groups',
    count: null,
    redirectTo: MAIN_DASHBOARD_ROUTES.connectorGroups,
    imgURL: IMAGE_PATHS.DASHBOARD.MAIN.CONNECTOR_GROUPS,
  },
];

export const SOURCE_NETWORKS = [
  {
    items: [
      {
        key: 'sourceNetworks',
        name: 'Source Networks',
      },
    ],
    redirectTo: 'mcn-devices-no-banner',
    imgURL: IMAGE_PATHS.DASHBOARD.MAIN.NETWORK,
  },
  {
    items: [
      {
        key: 'targetNetworks',
        name: 'Target Networks',
      },
    ],
    redirectTo: 'mcn-devices-no-banner',
    imgURL: IMAGE_PATHS.DASHBOARD.MAIN.NETWORK,
  },
  {
    items: [
      {
        key: 'app',
        name: 'Apps',
      },
    ],
    redirectTo: 'mcn-apps',
    imgURL: IMAGE_PATHS.DASHBOARD.MAIN.APP,
  },
  {
    items: [
      {
        key: 'domain',
        name: 'Domains',
      },
    ],
    redirectTo: 'mcn-geo',
    imgURL: IMAGE_PATHS.DASHBOARD.MAIN.DOMAIN,
  },
];

export const DATA_SUMMARY = [
  {
    items: [
      {
        key: 'users',
        name: 'Users',
      },
    ],
    redirectTo: 'users',
    imgURL: IMAGE_PATHS.DASHBOARD.MAIN.CLIENT,
  },
  {
    items: [
      {
        key: 'app',
        name: 'Apps',
      },
    ],
    redirectTo: 'users',
    imgURL: IMAGE_PATHS.DASHBOARD.MAIN.APP,
  },
  {
    items: [
      {
        key: 'domain',
        name: 'Domains',
      },
    ],
    redirectTo: 'geo',
    imgURL: IMAGE_PATHS.DASHBOARD.MAIN.DOMAIN,
  },
  {
    items: [
      {
        key: 'targetNetworks',
        name: 'Target Networks',
      },
    ],
    redirectTo: 'devices-no-banner',
    imgURL: IMAGE_PATHS.DASHBOARD.MAIN.NETWORK,
  },
  {
    items: [
      {
        key: 'serviceProvider',
        name: 'Service Providers',
      },
    ],
    redirectTo: 'networks',
    imgURL: IMAGE_PATHS.DASHBOARD.MAIN.ISP,
  },
];

export const TIME_RANGE_NUMBER = {
  DAY: 1,
  WEEK: 7,
  MONTH: 30,
};

export const INSIGHTS_SUMMARY_POLICY = {
  ALLOWED: 'Allowed',
  BLOCKED: 'Blocked',
};
