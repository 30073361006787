import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { TimeRange } from '../../shared/calendar.component';
import {
  DiagnoseSharedFilterState,
  diagnoseSharedFilterStore,
  DiagnoseSharedFilterStore,
  AccessibilityDetails,
} from './diagnose-filter.store';
import { TeamUser } from '@app/dashboard/shared/filter-list/client-list/client-list.model';
import { OnboardedApp } from '@app/dashboard/shared/filter-list/app-list/apps-list.model';
import { DiagnosticTestDetails } from '.';

export class DiagnoseSharedFilterQuery extends Query<DiagnoseSharedFilterState> {
  selectedTeamUsers$ = this.select((state) => state.selectedTeamUsers);
  selectedOnboardedApps$ = this.select((state) => state.selectedOnboardedApps);
  selectedNetworks$ = this.select((state) => state.selectedNetworks);
  timeRange$ = this.select((state) => state.timeRange);
  activeTab$ = this.select((state) => state.activeTab);
  diagnoseTimeRange$ = this.select((state) => state.diagnoseTimeRange);
  accessibilityDetails$ = this.select((state) => state.accessibilityDetails);
  diagnosticTestDetails$ = this.select((state) => state.diagnosticTestDetails);

  constructor(protected store: DiagnoseSharedFilterStore) {
    super(store);
  }

  get selectedTeamUsers(): Observable<Array<TeamUser>> {
    return this.selectedTeamUsers$;
  }

  get selectedOnboardedApps(): Observable<Array<OnboardedApp>> {
    return this.selectedOnboardedApps$;
  }

  get selectedNetworks(): Observable<Array<string>> {
    return this.selectedNetworks$;
  }

  get timeRange(): Observable<TimeRange> {
    return this.timeRange$;
  }

  get diagnoseTimeRange(): Observable<TimeRange> {
    return this.diagnoseTimeRange$;
  }

  get activeTab(): Observable<string> {
    return this.activeTab$;
  }

  get AccessibilityDetails(): Observable<AccessibilityDetails> {
    return this.accessibilityDetails$;
  }

  get DiagnosticTestDetails(): Observable<DiagnosticTestDetails> {
    return this.diagnosticTestDetails$;
  }
}

export const diagnoseSharedFilterQuery = new DiagnoseSharedFilterQuery(
  diagnoseSharedFilterStore
);
