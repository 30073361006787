import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ViewChild,
  Output,
  EventEmitter,
  ComponentRef,
} from '@angular/core';
import { LightboxService } from './lightbox.service';
import { Observable, Subject, of } from 'rxjs';
import { DynamicComponentHostDirective } from './lightbox.directive';
import {
  LightboxContentType,
  LightboxDynaCompConfig,
} from './lightbox.interface';
import { NavConfirmationService } from '@app/common/util/nav-confirmation.service';

@Component({
  selector: 'app-lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LightboxComponent implements OnInit, OnDestroy {
  @ViewChild(DynamicComponentHostDirective, { static: true })
  dynaCompHost!: DynamicComponentHostDirective;

  lightboxState$ = new Subject<boolean>();
  lightboxTemplate$: Observable<string>;
  dynaTemplate: string;
  subscriptions = [];
  componentRef: ComponentRef<any>;
  @Output() emitComponentRef = new EventEmitter();
  constructor(
    private lightboxService: LightboxService,
    private navConfirmationService: NavConfirmationService
  ) {
    this.subscriptions.push(
      this.lightboxService.getLightboxState().subscribe((data) => {
        this.lightboxState$.next(data);
        if (!data) {
          this.componentRef?.destroy();
        }
      }),
      this.lightboxService.getLightboxTemplate().subscribe((data) => {
        this.lightboxTemplate$ = of(data);
      }),
      this.lightboxService
        .getDynaCompConfig()
        .subscribe((data: LightboxDynaCompConfig) => {
          const contentType = data?.type || '';
          switch (contentType) {
            case LightboxContentType.COMPONENT:
              this.renderDynamicComponent(data);
              break;
            case LightboxContentType.TEMPLATE:
              this.dynaTemplate = data?.template;
              break;
          }
        })
    );
  }

  ngOnInit() {}

  /**
   *
   * @param data
   * @returns
   */
  // TODO: Add logic to destroy dyancamic component
  renderDynamicComponent(data: LightboxDynaCompConfig) {
    if (!this.dynaCompHost) {
      return;
    }

    const viewContainerRef = this.dynaCompHost.viewContainerRef;
    viewContainerRef.clear();
    // if(this.componentRef) {
    //   this.componentRef.hostView.destroy();
    // }
    this.componentRef = viewContainerRef.createComponent<any>(
      data?.component
    ) as any;
    this.componentRef.instance.data = data?.data;
    this.componentRef.instance.cdr.markForCheck();
    this.lightboxService.setLightboxComponentInstance(
      this.componentRef.instance
    );
  }

  onOverlayAction(): void {
    this.navConfirmationService.setLightboxOverlayAction(true);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription?.unsubscribe());
  }
}
