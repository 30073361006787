import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  APP_LOCALE,
  GETTING_STARTED_STEPS,
  HELP_ARTICLE_ID,
  IMAGE_PATHS,
} from '@app/common/util/constants';
import { GettingStartedService } from './getting-started.service';
import { ACTION_BUTTONS } from './getting-started.constant';
import { AppUtil } from '@app/common/util/app-util';
import { Router } from '@angular/router';
import { CONSTANTS } from 'environments/environment';
@Component({
  selector: 'app-getting-started',
  templateUrl: './getting-started.component.html',
  styleUrls: ['./getting-started.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({ transform: 'translateX(0)', opacity: 1, display: 'block' })
      ),
      state(
        'out',
        style({ transform: 'translateX(-100%)', opacity: 1, display: 'none' })
      ),
      transition('in <=> out', animate('800ms ease-in-out')), // Adjust the duration here (600ms)
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GettingStartedComponent implements OnInit {
  lbData: any;
  stepName: string;
  PROSIMO_LOGO = IMAGE_PATHS.NAVIGATION.PROSIMO;
  userFirstName = '';
  clickOnLetsGetStarted = false;
  COPY_GETTING_STARTED_STEPS = GETTING_STARTED_STEPS;
  quickStartGuideURL = CONSTANTS.HELP.PATH_TO_ARTICLE(
    APP_LOCALE.EN_US,
    HELP_ARTICLE_ID.QUICK_START
  );
  isStepOneDone = false;
  DAY0_CHECK_IMAGE = IMAGE_PATHS.VISUAL_ONBOARD.REGION_COMPLETE;
  @Input() set data(data) {
    const {
      lbData = {},
      stepName = '',
      userFirstName = '',
      isStepOneDone = false,
    } = data;
    this.lbData = lbData;
    this.stepName = stepName;
    this.userFirstName = userFirstName;
    this.isStepOneDone = isStepOneDone;
  }
  constructor(
    private gettingStartedService: GettingStartedService,
    private router: Router,
    private appUtil: AppUtil,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  letsGetStarted() {
    this.clickOnLetsGetStarted = !this.clickOnLetsGetStarted;
  }

  letsOnboard() {
    this.gettingStartedService.setSelectedActionButton(
      ACTION_BUTTONS.LETS_ONBOARD
    );
    this.appUtil.setLightboxDisplayState(false);
  }

  openQuickStartGuide() {
    this.appUtil.openHelpURL(this.quickStartGuideURL);
  }

  discoverTransit() {
    this.gettingStartedService.setSelectedActionButton(
      ACTION_BUTTONS.DISCOVER_TRANSIT
    );
    this.appUtil.setLightboxDisplayState(false);
    this.router.navigate([CONSTANTS.VISUAL_ONBOARDING.UI_INFRASTRUCTURE_PATH]);
  }

  onboardMoreAccounts() {
    this.appUtil.setLightboxDisplayState(false);
    this.router.navigate([CONSTANTS.MANAGEMENT.UI_PATH_CLOUD]);
    this.gettingStartedService.setSelectedActionButton(
      ACTION_BUTTONS.LETS_ONBOARD
    );
  }
}
