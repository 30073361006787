import { ManagementStore, managementStore } from './management.store';

export class ManagementService {
  constructor(private managementStore: ManagementStore) {}

  updateActiveTab(activeTab: string) {
    this.managementStore.update((state) => ({
      activeTab,
    }));
  }

  updateActiveSubMenu(activeSubMenu: string) {
    this.managementStore.update((state) => ({
      activeSubMenu,
    }));
  }

  updatePreviewImageUrl(previewImageUrl: string) {
    this.managementStore.update((state) => ({
      previewImageUrl,
    }));
  }

  // Update Management Landing Page Radio Button selection.
  updateActiveFilter(activeFilter: string) {
    this.managementStore.update((state) => ({
      activeFilter,
    }));
  }

}

export const managementService = new ManagementService(managementStore);
