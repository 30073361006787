import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TicketSupportModel } from './feedback-form.model';

@Injectable({
  providedIn: 'root',
})
export class FeedbackFormService {
  private feedbackFormConfig$ = new Subject<TicketSupportModel>();

  constructor() {}

  setFeedbackFormConfig(feedbackFormModel: TicketSupportModel) {
    this.feedbackFormConfig$.next(feedbackFormModel);
  }

  getFeedbackFormConfig(): Observable<TicketSupportModel> {
    return this.feedbackFormConfig$;
  }
}
