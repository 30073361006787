import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataFetchFormService {
  private actionButton$: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );
  constructor() {}

  setSelectedActionButtonResponse(response: any) {
    this.actionButton$.next(response);
  }

  getSelectedActionButtonResponse(): Observable<any> {
    return this.actionButton$.asObservable();
  }
}
