import { Store, StoreConfig } from '@datorama/akita';
import {
  TeamUser,
  TeamUserMasterSelectConfig,
  UserGroup,
} from '@app/dashboard/shared/filter-list/client-list/client-list.model';
import {
  OnboardedApp,
  AppGroup,
  OnboardedAppMasterSelectConfig,
} from '@app/dashboard/shared/filter-list/app-list/apps-list.model';
import { TimeRange } from '../../shared/calendar.component';
import {
  Network,
  NetworkGroup,
  NetworkMasterSelectConfig,
} from '@app/dashboard/shared/filter-list/network-list/network-list.model';

export enum TimeRangeType {
  PAST_ONE_DAY = 'PAST_ONE_DAY',
  PAST_ONE_WEEK = 'PAST_ONE_WEEK',
  PAST_ONE_MONTH = 'PAST_ONE_MONTH',
  CUSTOM = 'CUSTOM',
}

export interface ZTNAInsightsSharedFilterState {
  selectedTeamUsers?: Array<TeamUser>;
  selectedUserGroups?: Array<UserGroup>;
  selectedOnboardedApps?: Array<OnboardedApp>;
  selectedAppGroups?: Array<AppGroup>;
  selectedNetworks?: Array<Network>;
  selectedNetworkGroups?: Array<NetworkGroup>;
  timeRange?: TimeRange;
  selectedTimeRangeType?: TimeRangeType;
  insightTimeRange?: TimeRange;
  mainDashboardTimeRange?: TimeRange;
  activeTab?: string;
  activeSubMenu?: string;
  levelTwoActiveSubMenu?: string;
  source?: string;
  isDataNew?: boolean;
  totalUsers: number;
  totalApps: number;
  totalUserGroups?: number;
  totalAppGroups?: number;
  totalNetworks?: number;
  totalNetworkGroups?: number;
}

export function createInitialState(): ZTNAInsightsSharedFilterState {
  return {
    selectedTeamUsers: [TeamUserMasterSelectConfig],
    selectedUserGroups: [],
    selectedOnboardedApps: [OnboardedAppMasterSelectConfig],
    selectedAppGroups: [],
    selectedNetworks: [NetworkMasterSelectConfig],
    selectedNetworkGroups: [],
    timeRange: null,
    selectedTimeRangeType: TimeRangeType.PAST_ONE_WEEK,
    insightTimeRange: null,
    mainDashboardTimeRange: null,
    activeTab: '',
    levelTwoActiveSubMenu: '',
    activeSubMenu: '',
    source: 'FILTER_HISTORY',
    isDataNew: true,
    totalUsers: null,
    totalApps: null,
    totalUserGroups: null,
    totalNetworks: null,
    totalNetworkGroups: null,
  };
}

@StoreConfig({ name: 'ztna-insights-filters', resettable: true })
export class ZTNAInsightsSharedFilterStore extends Store<ZTNAInsightsSharedFilterState> {
  constructor() {
    super(createInitialState());
  }
}

export const ztnaInsightsSharedFilterStore =
  new ZTNAInsightsSharedFilterStore();
