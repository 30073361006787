import {
  Namespace,
  Network,
  NetworkPrivateCloud,
  PrefixInfo,
  PrivateCloudRule,
  RuleAction,
  RuleMatchCondition,
} from '@app/common/models';
import { ICloudController } from './cloud-controller';
import {
  NetworkDefinition,
  PCWithSubnets,
} from '../../components/drawer/definition/definition.model';
import { NetworkVPC, VPCSettings } from '../../components/drawer/vpc/vpc.model';
import { ArrayUtil } from '@prosimoio/services';
import { NetworkPolicy } from '../../components/drawer/policy/policy.model';
import {
  NetworkOnboardPrivateWorkflow,
} from '../../../network.constants';
import { ModelFormGroup } from '@app/common/util/form-util';
import { OrchestratorService } from '@app/common/components/drawer/orchestrator/orchestrator.service';
import {
  OrchestratorStep,
  OrchestratorWorkflow,
} from '@app/common/components/drawer/orchestrator/orchestrator.model';
import { NetworkUtil } from '../../../network.util';
import { NetworkAutoDefinition } from '../../components/drawer/auto-definition/auto-definition.model';
import {
  NetworkRule,
  Rule,
} from '../../components/drawer/auto-rule/auto-rule.model';
import { getWellKnownCommunityValue } from '@app/common/util/bgp-util';
import { ChangeSetList } from '../../../network.model';

export class PrivateCloudController implements ICloudController {
  constructor(private orchestratorService: OrchestratorService) {}

  getCloudType(network: Network): string {
    return network.privateCloud?.cloudType || '';
  }

  getCSP(network: Network) {
    return network.privateCloud?.cloud || '';
  }

  getCloudKeyID(network: Network): string {
    return network.privateCloud?.privateCloudID || '';
  }

  getCloudRegion(network: Network) {
    return network.privateCloud?.cloudRegion || '';
  }

  getCloudNetworks(network: Network): Array<PCWithSubnets> {
    return [
      // {
      //   subnets: network.privateCloud?.subnets,
      //   cloudNetworkID: network.privateCloud.privateCloudID,
      // },
    ];
  }

  getSubnets(network: NetworkDefinition) {
    return network.privateCloud?.subnets || [];
  }

  getCloudNetworksForm(network: NetworkDefinition): Array<PCWithSubnets> {
    return [
      // {
      //   subnets: network.cloudNetworks?.[0].subnets || [],
      //   cloudNetworkID: network.cloudNetworks?.[0].cloudNetworkID || '',
      // },
    ];
  }

  validateDefinition(network: NetworkDefinition): boolean {
    return (
      network.privateCloud.privateCloudID.length > 0 &&
      network.privateCloud.subnets.length > 0
    );
  }

  validateAutoDefinition(networkForm: NetworkAutoDefinition): boolean {
    return networkForm.cloudKeyID.length > 0;
  }

  detectDefinationFormChanges(
    network: Network,
    networkForm: NetworkDefinition
  ) {
    return (
      networkForm.name !== network.name ||
      networkForm.cloudType !== network.privateCloud?.cloudType ||
      networkForm.cloudKeyID !== network.privateCloud?.privateCloudID ||
      networkForm.cloudRegion !== network.privateCloud?.cloudRegion ||
      networkForm.csp !== network.privateCloud?.cloud ||
      networkForm.exportable !== network.exportable ||
      networkForm.namespace !== network.namespaceID ||
      !ArrayUtil.compareArrays(
        networkForm.privateCloud.subnets,
        network.privateCloud.subnets,
        ''
      )
    );
  }

  detectAutoDefinitionFormChanges(
    network: Network,
    networkForm: NetworkAutoDefinition
  ): boolean {
    return (
      networkForm.name != network.name ||
      networkForm.cloudType != network.privateCloud?.cloudType ||
      networkForm.cloudKeyID !== network.privateCloud?.privateCloudID ||
      networkForm.exportable !== network.exportable ||
      networkForm.namespace !== network.namespaceID ||
      networkForm.privateCloud.ruleBasedOnboarding !==
        network.privateCloud?.ruleBasedOnboarding
    );
  }

  updateDefinition(
    network: Network,
    networkForm: NetworkDefinition,
    namespaces: Array<Namespace>
  ) {
    const namespace = namespaces?.find(
      (namespace) => namespace?.id === networkForm.namespace
    );
    network.name = networkForm.name;
    if (!network.privateCloud) {
      network.privateCloud = {} as NetworkPrivateCloud;
      delete network.publicCloud;
      delete network.ownTransitCloud;
    }
    network.namespaceID = networkForm.namespace;
    network.namespaceName = namespace?.name;
    network.privateCloud.cloud = networkForm.csp;
    network.privateCloud.privateCloudID =
      networkForm.privateCloud.privateCloudID;
    network.privateCloud.cloudType = networkForm.cloudType;
    network.privateCloud.cloudRegion = networkForm.cloudRegion;
    network.privateCloud.subnets = networkForm.privateCloud.subnets;
    network.exportable = networkForm.exportable;
  }

  updateAutoDefinition(
    network: Network,
    networkForm: NetworkAutoDefinition
  ): Network {
    network.name = networkForm.name;
    if (!network.privateCloud) {
      network.privateCloud = {} as NetworkPrivateCloud;
      delete network.publicCloud;
      delete network.ownTransitCloud;
    }
    network.namespaceID = networkForm.namespace;
    network.privateCloud.cloud = 'PRIVATE';
    network.privateCloud.cloudType = networkForm.cloudType;
    network.privateCloud.privateCloudID = networkForm.cloudKeyID;
    if (
      networkForm.privateCloud.ruleBasedOnboarding &&
      network.privateCloud.ruleBasedOnboarding
    ) {
      network.privateCloud.autoOnboardRules = {
        rules: network.privateCloud.autoOnboardRules?.rules || [],
      };
    } else if (
      networkForm.privateCloud.ruleBasedOnboarding &&
      !network.privateCloud.ruleBasedOnboarding
    ) {
      network.privateCloud.autoOnboardRules = {
        rules: [],
      };
    } else {
      delete network.privateCloud.autoOnboardRules;
    }
    network.privateCloud.ruleBasedOnboarding =
      networkForm.privateCloud.ruleBasedOnboarding;
    network.exportable = networkForm.exportable;
    network.autoOnboard = true;
    return network;
  }

  detectAutoOnboardRulesFormChanges(
    network: Network,
    rulesForm: Rule[],
    prefixes: Map<string, Array<PrefixInfo>>,
    bgpCommunities: Map<string, Array<string>>,
    bgpNeighbors: Map<string, Array<string>>
  ): number[] {
    const modifiedRules: Array<number> = rulesForm?.reduce((p, v, i) => {
      const rule = network.privateCloud?.autoOnboardRules?.rules?.[i];
      if (!rule) {
        p.push(i);
        return p;
      }

      const rulePrefixes = prefixes.get(v.id);
      const ruleCommunities = bgpCommunities.get(v.id);
      const ruleNeighbors = bgpNeighbors.get(v.id);
      if (
        rule.action !== v.action ||
        (rule.prefixes?.matchCondition || RuleMatchCondition.ANY) !==
          v.prefixes?.matchCondition ||
        (rule.bgpCommunities?.matchCondition || RuleMatchCondition.ANY) !==
          v.bgpCommunities?.matchCondition ||
        (rule.bgpNeighbours?.matchCondition || RuleMatchCondition.ANY) !==
          v.bgpNeighbours?.matchCondition ||
        (rulePrefixes?.length || 0) !== (rule.prefixes?.values?.length || 0) ||
        !rulePrefixes?.every((prefix) =>
          rule.prefixes.values.some((exist) =>
            NetworkUtil.comparePrefix(prefix, exist)
          )
        ) ||
        !ArrayUtil.compareArrays(
          rule.prefixes?.groups?.map((group) => group.id) || [],
          v.prefixes?.groups || [],
          ''
        ) ||
        !ArrayUtil.compareArrays(
          (ruleCommunities || []).concat(
            v.bgpCommunities?.wellKnownCommunities || []
          ),
          rule.bgpCommunities?.communities || [],
          ''
        ) ||
        !ArrayUtil.compareArrays(
          rule.bgpCommunities?.communityGroups?.map((group) => group.id) || [],
          v.bgpCommunities?.communityGroups || [],
          ''
        ) ||
        !ArrayUtil.compareArrays(
          ruleNeighbors || [],
          rule.bgpNeighbours?.values || [],
          ''
        )
      ) {
        p.push(i);
      }

      return p;
    }, [] as Array<number>);
    return modifiedRules;
  }

  validateAutoOnboardRules(
    network: Network,
    rulesForm: Rule[],
    prefixes: Map<string, PrefixInfo[]>,
    bgpCommunities: Map<string, string[]>,
    bgpNeighbors: Map<string, string[]>
  ): Array<number> {
    if (!rulesForm?.length) {
      return [];
    }

    return rulesForm
      .map((rule, i) => {
        let valid = true;

        if (![RuleAction.ACCEPT, RuleAction.REJECT].includes(rule.action)) {
          valid = false;
        }

        if (
          ![RuleMatchCondition.ANY, RuleMatchCondition.NONE].includes(
            <RuleMatchCondition>rule.prefixes.matchCondition
          )
        ) {
          valid = false;
        }

        if (
          ![
            RuleMatchCondition.ANY,
            RuleMatchCondition.ALL,
            RuleMatchCondition.NONE,
          ].includes(<RuleMatchCondition>rule.bgpCommunities.matchCondition)
        ) {
          valid = false;
        }

        if (
          ![RuleMatchCondition.ANY, RuleMatchCondition.NONE].includes(
            <RuleMatchCondition>rule.bgpNeighbours.matchCondition
          )
        ) {
          valid = false;
        }

        return valid ? i : -1;
      })
      .filter((i) => i >= 0);
  }

  updateAutoOnboardRules(
    network: Network,
    rulesForm: NetworkRule,
    prefixes: Map<string, PrefixInfo[]>,
    bgpCommunities: Map<string, string[]>,
    bgpNeighbors: Map<string, string[]>
  ): void {
    network.name = rulesForm.name;
    if (!network.privateCloud.autoOnboardRules) {
      network.privateCloud.autoOnboardRules = { rules: [] };
    }

    network.privateCloud.autoOnboardRules.rules =
      rulesForm.rules.map<PrivateCloudRule>((rule) => {
        const ruleSet = {
          action: rule.action,
          prefixes:
            rule.prefixes.groups?.length || prefixes.get(rule.id)?.length
              ? {
                  matchCondition: rule.prefixes.matchCondition,
                  groups: rule.prefixes.groups.map((group) => ({ id: group })),
                  values: prefixes.get(rule.id),
                }
              : null,
          bgpCommunities:
            rule.bgpCommunities.communityGroups?.length ||
            bgpCommunities.get(rule.id)?.length ||
            rule.bgpCommunities?.wellKnownCommunities?.length
              ? {
                  matchCondition: rule.bgpCommunities.matchCondition,
                  communityGroups: rule.bgpCommunities.communityGroups.map(
                    (group) => ({ id: group })
                  ),
                  communities: (bgpCommunities.get(rule.id) || []).concat(
                    rule.bgpCommunities?.wellKnownCommunities || []
                  ),
                }
              : null,
          bgpNeighbours: bgpNeighbors.get(rule.id)?.length
            ? {
                matchCondition: rule.bgpNeighbours.matchCondition,
                values: bgpNeighbors.get(rule.id),
              }
            : null,
        };
        if (!ruleSet.prefixes) {
          delete ruleSet.prefixes;
        } else {
          if (!ruleSet.prefixes.groups?.length) {
            delete ruleSet?.prefixes?.groups;
          }
          if (!ruleSet.prefixes.values?.length) {
            delete ruleSet?.prefixes?.values;
          }
        }
        if (!ruleSet.bgpCommunities) {
          delete ruleSet.bgpCommunities;
        } else {
          if (!ruleSet.bgpCommunities.communityGroups?.length) {
            delete ruleSet?.bgpCommunities?.communityGroups;
          }
          if (!ruleSet.bgpCommunities.communities?.length) {
            delete ruleSet?.bgpCommunities?.communities;
          }
        }
        if (!ruleSet.bgpNeighbours) {
          delete ruleSet.bgpNeighbours;
        } else {
          if (!ruleSet.bgpNeighbours.values?.length) {
            delete ruleSet?.bgpNeighbours?.values;
          }
        }
        return ruleSet;
      });
  }

  getRulesChangeSet(
    existingNetwork: Network,
    rulesForm: NetworkRule,
    prefixes: Map<string, PrefixInfo[]>,
    bgpCommunities: Map<string, string[]>,
    bgpNeighbors: Map<string, Array<string>>,
    ipPrefixesLists: Map<string, string>,
    bgpGroups: Map<string, string>
  ): Array<ChangeSetList> {
    const changeSet: Array<ChangeSetList> = [];
    const maxLength = Math.max(
      existingNetwork?.privateCloud?.autoOnboardRules?.rules?.length || 0,
      rulesForm?.rules?.length || 0
    );
    for (let i = 0; i < maxLength; i++) {
      const ruleChangeSet: ChangeSetList = [];
      if (
        existingNetwork?.privateCloud?.autoOnboardRules?.rules?.[i]?.action !==
        rulesForm.rules[i].action
      ) {
        ruleChangeSet.push({
          key: 'Action',
          values: [
            {
              value: rulesForm.rules[i].action,
              action: !existingNetwork?.privateCloud?.autoOnboardRules?.rules?.[
                i
              ]?.action
                ? 'ADD'
                : 'MOD',
            },
          ],
        });
      }

      const prefixSet = {
        DEL: [],
        ADD: [],
      };
      const newPrefixes = prefixes.get(rulesForm.rules[i].id) || [];
      prefixSet.DEL =
        existingNetwork?.privateCloud?.autoOnboardRules?.rules?.[
          i
        ].prefixes?.values?.filter(
          (prefix) =>
            !newPrefixes.some((p) => NetworkUtil.comparePrefix(prefix, p))
        ) || [];
      prefixSet.ADD =
        newPrefixes.filter(
          (prefix) =>
            !existingNetwork?.privateCloud?.autoOnboardRules?.rules?.[
              i
            ].prefixes?.values?.some((p) =>
              NetworkUtil.comparePrefix(prefix, p)
            )
        ) || [];

      if (prefixSet.ADD.length || prefixSet.DEL.length) {
        ruleChangeSet.push({
          key: 'Prefix',
          values: prefixSet.ADD.map((prefix) => ({
            value: `${prefix.prefix} min ${prefix.min} max ${prefix.max}`,
            action: 'ADD',
          })).concat(
            prefixSet.DEL.map((prefix) => ({
              value: `${prefix.prefix} min ${prefix.min} max ${prefix.max}`,
              action: 'DEL',
            }))
          ),
        });
      }

      const prefixListSet = {
        DEL: [],
        ADD: [],
      };
      prefixListSet.DEL =
        existingNetwork?.privateCloud?.autoOnboardRules?.rules?.[
          i
        ]?.prefixes?.groups
          ?.filter(
            (group) =>
              !rulesForm.rules?.[i]?.prefixes?.groups.some(
                (g) => g === group.id
              )
          )
          ?.map((group) => group.name) || [];
      prefixListSet.ADD =
        rulesForm.rules?.[i]?.prefixes?.groups.filter(
          (group) =>
            !existingNetwork?.privateCloud?.autoOnboardRules?.rules?.[
              i
            ]?.prefixes?.groups?.some((g) => g.id === group)
        ) || [];

      if (prefixListSet.ADD.length || prefixListSet.DEL.length) {
        ruleChangeSet.push({
          key: 'Prefix List',
          values: prefixListSet.ADD.map((group) => ({
            value: ipPrefixesLists.get(group) || '',
            action: 'ADD',
          })).concat(
            prefixListSet.DEL.map((group) => ({
              value: group,
              action: 'DEL',
            }))
          ),
        });
      }

      if (
        existingNetwork?.privateCloud?.autoOnboardRules?.rules?.[i]?.prefixes
          .matchCondition !== rulesForm?.rules?.[i]?.prefixes.matchCondition &&
        (existingNetwork?.privateCloud?.autoOnboardRules?.rules?.[i]?.prefixes
          ?.groups?.length ||
          existingNetwork?.privateCloud?.autoOnboardRules?.rules?.[i]?.prefixes
            ?.values?.length ||
          prefixListSet.ADD.length ||
          prefixListSet.DEL.length ||
          prefixSet.ADD.length ||
          prefixSet.DEL.length)
      ) {
        ruleChangeSet.push({
          key: 'Prefix : Match Condition',
          values: [
            {
              value: rulesForm.rules[i].prefixes.matchCondition,
              action: !existingNetwork?.privateCloud?.autoOnboardRules?.rules?.[
                i
              ]?.prefixes?.matchCondition
                ? 'ADD'
                : 'MOD',
            },
          ],
        });
      }

      const bgpSet = {
        DEL: [],
        ADD: [],
      };
      const newCommunities = (
        bgpCommunities.get(rulesForm.rules[i].id) || []
      ).concat(rulesForm?.rules?.[i]?.bgpCommunities?.wellKnownCommunities);
      bgpSet.DEL =
        existingNetwork?.privateCloud?.autoOnboardRules?.rules?.[
          i
        ].bgpCommunities?.communities?.filter(
          (community) => !newCommunities.some((comm) => comm === community)
        ) || [];
      bgpSet.ADD =
        newCommunities.filter(
          (community) =>
            !existingNetwork?.privateCloud?.autoOnboardRules?.rules?.[
              i
            ].bgpCommunities?.communities?.some((comm) => comm === community)
        ) || [];

      if (bgpSet.ADD.length || bgpSet.DEL.length) {
        ruleChangeSet.push({
          key: 'BGP Community',
          values: bgpSet.ADD.map((community) => ({
            value: getWellKnownCommunityValue(community, false),
            action: 'ADD',
          })).concat(
            bgpSet.DEL.map((community) => ({
              value: getWellKnownCommunityValue(community, false),
              action: 'DEL',
            }))
          ),
        });
      }

      const bgpGroupSet = {
        DEL: [],
        ADD: [],
      };
      bgpGroupSet.DEL =
        existingNetwork?.privateCloud?.autoOnboardRules?.rules?.[
          i
        ]?.bgpCommunities?.communityGroups
          ?.filter(
            (group) =>
              !rulesForm.rules?.[i]?.bgpCommunities?.communityGroups.some(
                (g) => g === group.id
              )
          )
          ?.map((group) => group.name) || [];
      bgpGroupSet.ADD =
        rulesForm.rules?.[i]?.bgpCommunities?.communityGroups.filter(
          (group) =>
            !existingNetwork?.privateCloud?.autoOnboardRules?.rules?.[
              i
            ]?.bgpCommunities?.communityGroups?.some((g) => g.id === group)
        ) || [];

      if (bgpGroupSet.ADD.length || bgpGroupSet.DEL.length) {
        ruleChangeSet.push({
          key: 'BGP Community Group',
          values: bgpGroupSet.ADD.map((group) => ({
            value: bgpGroups.get(group) || '',
            action: 'ADD',
          })).concat(
            bgpGroupSet.DEL.map((group) => ({
              value: group,
              action: 'DEL',
            }))
          ),
        });
      }

      if (
        existingNetwork?.privateCloud?.autoOnboardRules?.rules?.[i]
          ?.bgpCommunities?.matchCondition !==
          rulesForm?.rules?.[i]?.bgpCommunities.matchCondition &&
        (existingNetwork?.privateCloud?.autoOnboardRules?.rules?.[i]
          ?.bgpCommunities?.communityGroups?.length ||
          existingNetwork?.privateCloud?.autoOnboardRules?.rules?.[i]
            ?.bgpCommunities?.communities?.length ||
          bgpGroupSet.ADD.length ||
          bgpGroupSet.DEL.length ||
          bgpSet.ADD.length ||
          bgpSet.DEL.length)
      ) {
        ruleChangeSet.push({
          key: 'BGP Community : Match Condition',
          values: [
            {
              value: rulesForm.rules[i].bgpCommunities.matchCondition,
              action: !existingNetwork?.privateCloud?.autoOnboardRules?.rules?.[
                i
              ]?.bgpCommunities?.matchCondition
                ? 'ADD'
                : 'MOD',
            },
          ],
        });
      }

      const bgpNeighborSet = {
        DEL: [],
        ADD: [],
      };
      const newNeighbors = bgpNeighbors.get(rulesForm.rules[i].id) || [];
      bgpNeighborSet.DEL =
        existingNetwork?.privateCloud?.autoOnboardRules?.rules?.[
          i
        ].bgpNeighbours?.values?.filter(
          (neighbor) => !newNeighbors.some((n) => n === neighbor)
        ) || [];
      bgpNeighborSet.ADD =
        newNeighbors.filter(
          (neighbor) =>
            !existingNetwork?.privateCloud?.autoOnboardRules?.rules?.[
              i
            ].bgpNeighbours?.values?.some((n) => n === neighbor)
        ) || [];

      if (bgpNeighborSet.ADD.length || bgpNeighborSet.DEL.length) {
        ruleChangeSet.push({
          key: 'BGP Neighbor',
          values: bgpNeighborSet.ADD.map((neighbor) => ({
            value: neighbor,
            action: 'ADD',
          })).concat(
            bgpNeighborSet.DEL.map((neighbor) => ({
              value: neighbor,
              action: 'DEL',
            }))
          ),
        });
      }

      if (
        existingNetwork?.privateCloud?.autoOnboardRules?.rules?.[i]
          ?.bgpNeighbours?.matchCondition !==
          rulesForm?.rules?.[i]?.bgpNeighbours.matchCondition &&
        (existingNetwork?.privateCloud?.autoOnboardRules?.rules?.[i]
          ?.bgpNeighbours?.values?.length ||
          existingNetwork?.privateCloud?.autoOnboardRules?.rules?.[i]
            ?.bgpNeighbours?.values?.length)
      ) {
        ruleChangeSet.push({
          key: 'BGP Neighbor : Match Condition',
          values: [
            {
              value: rulesForm.rules[i].bgpNeighbours?.matchCondition,
              action: !existingNetwork?.privateCloud?.autoOnboardRules?.rules?.[
                i
              ]?.bgpNeighbours?.matchCondition
                ? 'ADD'
                : 'MOD',
            },
          ],
        });
      }

      changeSet.push(ruleChangeSet);
    }
    return changeSet;
  }

  detectVPCFormChanges(
    network: Network,
    vpcForm: Array<VPCSettings>
  ): Array<number> {
    return [];
  }

  validateVPCs(network: Network, vpcSettings: VPCSettings[]): Array<number> {
    return [];
  }

  detectPolicyFormChanges(network: Network, networkForm: NetworkPolicy) {
    return !ArrayUtil.compareArrays(
      network.security.policies?.map((policy) => policy.id) || [],
      networkForm.policies,
      ''
    );
  }

  validatePolicy(
    networkForm: NetworkPolicy,
    defaultPolicies: Map<string, string>
  ) {
    if (networkForm.policies.length === 0) {
      return true;
    }
    // TODO: This needs to be added back when Policy section has restriction on DENY-ALL-x and other policies co-existing on the same entity.

    // if (
    //   [POLICY_TYPE.DENY_ALL_NETWROKS, POLICY_TYPE.DENY_ALL_USERS].some(
    //     (policy) => networkForm.policies.includes(defaultPolicies.get(policy))
    //   ) &&
    //   networkForm.policies.length > 1
    // ) {
    //   return false;
    // }
    return true;
  }

  updatePolicy(network: Network, networkForm: NetworkPolicy) {
    network.name = networkForm.name;
    network.security.policies = networkForm.policies.map((policy) => ({
      id: policy,
    }));
  }

  updateVPCs: (network: Network, networkForm: NetworkVPC) => void;

  addSubnetToDefinitionForm(
    subnet: string,
    networkForm: ModelFormGroup<NetworkDefinition>
  ) {
    networkForm.controls.privateCloud.controls.subnets.patchValue([
      subnet,
      ...networkForm.controls.privateCloud.controls.subnets.value,
    ]);
  }

  removeSubnetToDefinitionForm(
    subnet: string,
    networkForm: ModelFormGroup<NetworkDefinition>
  ) {
    networkForm.controls.privateCloud.controls.subnets.patchValue(
      networkForm.controls.privateCloud.controls.subnets.value.filter(
        (selSubnet) => selSubnet !== subnet
      )
    );
  }

  updateOrchestratorWorkflow(cloudType: string) {
    this.orchestratorService.setOrchWorkflow({
      tabs: NetworkUtil.getNetworkOnboardTabsFromCloudType(cloudType),
      workflowPayload: null,
      workflow:
        NetworkOnboardPrivateWorkflow as OrchestratorWorkflow<OrchestratorStep>,
    });
  }
}
