import { PrivateLink } from '../private-link-list/private-link-list.model';

export interface Network {
  id: string;
  name: string;
}
export interface NetworkGroup {
  id: string;
  name: string;
}

export interface NetworkFilters {
  networks?: Array<Network>;
  privateLinks?: Array<PrivateLink>;
  networkGroups?: Array<NetworkGroup>;
  selectedNetworks?: Array<Network>;
  selectedNetworkGroups?: Array<NetworkGroup>;
  selectedPrivateLinks?: Array<PrivateLink>;
  type?: string;
  viewMode?: string;
}

export const NetworkMasterSelectConfig: Network = {
  id: 'all',
  name: 'all',
};

export const NetworkGroupMasterSelectConfig: NetworkGroup = {
  id: 'all',
  name: 'all',
};
