import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AppUtil } from '@app/common/util/app-util';
import { LightboxBtnType } from '@app/dashboard/diagnose/diagnostics/lightbox-form-template/lightbox-model';
import { NetworkOnboardService } from '@app/v2/onboard/network/network.service';
import { Subject, zip } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { OrchestratorService } from '@app/common/components/drawer/orchestrator/orchestrator.service';
import {
  CloudCredential,
  CloudNetwork,
  CloudRegion,
  Cluster,
  Namespace,
  Network,
  NetworkOnboardErrorResponseData,
  PrivateCloud,
  SubnetInfo,
} from '@app/common/models';
import {
  IOrchestratorComponent,
  NetworkOnboardStep,
} from '@app/common/components/drawer/orchestrator/orchestrator.model';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModelFormArray, ModelFormGroup } from '@app/common/util/form-util';
import { NetworkDefinition, PCWithSubnets } from './definition.model';
import { PDashLocalStoreUtil } from '@app/state/web-pdash.util';
import {
  APP_SETUP_STATUS,
  CLOUD_KEY_TYPES,
  DIALOG_TOKEN,
  IMAGE_PATHS,
  LAST_UPDATED_TEXT,
  NETWORK_HOSTED_TYPE,
  NOTIFICATION_MESSAGES,
  REGEX,
} from '@app/common/util/constants';
import {
  ICloudController,
  PrivateCloudController,
  PublicCloudController,
  OwnCloudController,
} from '@app/v2/onboard/network/shared/utils/controllers';
import {
  CustomizedSelectTriggerTextConfig,
  DeleteDialogComponent,
  ListItemNode,
  LoaderService,
  SelectSearchConfig,
  ToastService,
} from '@prosimoio/components';
import { ArrayUtil, ObjectUtil } from '@prosimoio/services';
import { OnboardTemplateService } from '@app/common/components/drawer/templates/onboard-template/onboard-template.service';
import {
  VPCBulkResponse,
  VPCListItem,
} from '@app/v2/onboard/visual-onboard/visual-onboard.model';
import { NetworkUtil } from '../../../../network.util';
import {
  RegionValidator,
  VirtualSubnetValidator,
} from '../../../utils/error-handler.util';
import {
  CSP_PC,
  DeleteAction,
  NetworkDeleteConfig,
  NetworkStatus,
} from '@app/v2/onboard/network/network.constants';
import { VIRTUAL_PRIVATE_CLOUD_NAME_PLURAL } from '@app/v2/onboard/visual-onboard/visual-onboard.constants';
import { MultiSelectConfig } from '@prosimoio/components/lib/v2/multi-level-select/multi-level-select.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { NavConfirmationService } from '@app/common/util/nav-confirmation.service';
import { CLOUD_TYPES } from '@app/common/util/constants';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-definition',
  templateUrl: './definition.component.html',
  styleUrls: ['./definition.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefinitionComponent
  implements IOrchestratorComponent, OnInit, OnDestroy
{
  stopSubs$: Subject<void>;
  definitionForm: ModelFormGroup<NetworkDefinition>;
  loading = {
    namespaces: true,
    clouds: true,
    regions: true,
    vpcs: true,
  };
  step = NetworkOnboardStep.DEFINE;
  networkStatus: NetworkStatus;
  cloudController: ICloudController;
  selectSearchRegion: SelectSearchConfig;
  isStepValid = false;
  customizedSelectTriggerTextConfig = {
    isShow: true,
    all: 'All Subnets',
    plural: 'Subnets',
  } as CustomizedSelectTriggerTextConfig;
  styleConfig = {
    listHeight: '140px',
    dropdownWidth: '236px',
    panelWidth: '400px',
  };
  vpcDropdownDetails: Array<ListItemNode> = [];
  dropdownPlaceholder: string = 'Select Subnets';
  isUnmanaged: boolean = false;
  selectedCloud: string = '';
  multiSelectConfig: MultiSelectConfig;
  subnetControl: FormControl<string>;
  selectedSubnetListData: Array<string> = [];
  useVirtualSubnets: boolean = false;
  allSubnets: Array<{ cloudNetworkID: string } & SubnetInfo> = [];
  virtualSubnets: ModelFormArray<SubnetInfo> = new FormArray([], {});
  cloudAccountControl: FormControl<string>;
  defaultHint =
    'Networks integrate subnets within a VPC/VNET into the Prosimo fabric, routing traffic through the fabric.';
  hint = '';
  isEditable: boolean = true;
  selectNamespaceConfig: SelectSearchConfig = {
    optionList: [],
    placeholder: 'Select Namespace',
    uiThemeMode: PDashLocalStoreUtil.getUIThemeMode().toLowerCase() + '-theme',
    showSearch: true,
    isMultiSelection: false,
    isServerSideSearch: false,
    setKeyNameOnControl: true,
    keyName: 'id',
    addOption: false,
    panelWidthClass: 'select-width-none',
    displayName: 'name',
  };
  selectRegionConfig: SelectSearchConfig = {
    optionList: [],
    placeholder: 'Select Region',
    uiThemeMode: PDashLocalStoreUtil.getUIThemeMode().toLowerCase() + '-theme',
    showSearch: true,
    isMultiSelection: false,
    isServerSideSearch: false,
    setKeyNameOnControl: true,
    keyName: 'regionName',
    addOption: false,
    panelWidthClass: 'select-width-none',
    displayName: 'regionName',
    searchHint: 'Available regions with edge deployed.',
  };

  networkBackup: Network;
  namespaces: Array<Namespace> = [];
  publicClouds: Array<CloudCredential> = [];
  privateClouds: Array<PrivateCloud> = [];
  regions: Array<CloudRegion> = [];
  cloudRegionsMap: Map<string, Array<CloudRegion>>;
  unManagedVPCMap: Map<string, Array<string>>;
  edges: Array<Cluster>;
  virtualSubnetValidator = VirtualSubnetValidator();

  uiThemeMode: string = PDashLocalStoreUtil.getUIThemeMode().toLowerCase();
  tooltipThemeClass: string;

  COPY_NETWORK_HOSTED_TYPE = NETWORK_HOSTED_TYPE;
  errorIcon = IMAGE_PATHS.VISUAL_ONBOARD.ERROR;
  binUrl = IMAGE_PATHS.CONFIGURE.BIN;
  COPY_CSP_PC = CSP_PC;

  @Input() data: Network;
  @Input() errorPayload: any;

  constructor(
    private networkOnboardService: NetworkOnboardService,
    private orchestratorService: OrchestratorService,
    private onboardTemplateService: OnboardTemplateService,
    private loaderService: LoaderService,
    private navConfirmationService: NavConfirmationService,
    private appUtil: AppUtil,
    private toastService: ToastService,
    private elem: ElementRef,
    private cdr: ChangeDetectorRef,
    private titlecasePipe: TitleCasePipe
  ) {
    this.stopSubs$ = new Subject();
    this.cloudRegionsMap = new Map();
    this.unManagedVPCMap = new Map();
    this.edges = [];
  }

  ngOnInit(): void {
    this.networkBackup = ObjectUtil.deepClone(this.data);
    this.networkStatus = NetworkUtil.getNetworkState(this.data);

    this.toastService.setUITheme(this.uiThemeMode + '-theme');
    this.initForm();
    if (
      this.errorPayload &&
      this.errorPayload.errorStep === NetworkOnboardStep.DEFINE
    ) {
      this.hint = this.errorPayload.errorMessage;
    } else {
      this.hint = this.defaultHint;
    }

    this.onboardTemplateService.setNameState({
      disabled: ![NetworkStatus.NEW].includes(this.networkStatus),
    });

    PDashLocalStoreUtil.getUIThemeModeAsObservable()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (uiThemeMode) => {
          this.uiThemeMode = uiThemeMode.toLowerCase();
          this.toastService.setUITheme(this.uiThemeMode + '-theme');
          this.selectNamespaceConfig = {
            ...this.selectNamespaceConfig,
            uiThemeMode: this.uiThemeMode + '-theme',
          };
          this.selectRegionConfig = {
            ...this.selectRegionConfig,
            uiThemeMode: this.uiThemeMode + '-theme',
          };
          this.tooltipThemeClass =
            'cdk-component-container--' + this.uiThemeMode + '-theme-dark';
        },
      });
    this.appUtil.updateDrawerButtonConfig({
      mainBtn: { ...LightboxBtnType.NEXT, disabled: true },
      cancelBtn: LightboxBtnType.CANCEL,
    });
    this.onboardTemplateService
      .getNameInput()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (name) => {
          this.definitionForm.controls.name.patchValue(name);
        },
      });
    this.appUtil
      .getDrawerResponse()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (response) => {
          if (response.code === LightboxBtnType.NEXT.code) {
            this.updateNetwork();
          } else {
            if (this.detectFormChanges()) {
              this.navConfirmationService.navConfirmationPopup(null);
              return;
            }
            this.appUtil.setDrawerState(false);
            this.appUtil.setDynamicDrawerResponse({
              context: NetworkOnboardStep.DEFINE,
              action: response.code,
              payload: null,
            });
          }
        },
      });

    this.orchestratorService
      .getTabChangeRequest()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (step: NetworkOnboardStep) => {
          const formData = <NetworkDefinition>this.definitionForm.getRawValue();

          this.cloudController.updateDefinition(
            this.data,
            formData,
            this.namespaces
          );
          this.orchestratorService.setGotoStop(step);
        },
      });

    this.setDrawerFooter();

    this.fetchStaticData();
    this.fetchCloudRegions();

    this.cloudController.updateOrchestratorWorkflow(this.selectedCloud);
  }

  setDrawerFooter() {
    this.onboardTemplateService
      .getDeleteResponse()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (response) => {
          if (response.action === DeleteAction.DELETE) {
            this.deleteNetwork();
          } else if (response.action === DeleteAction.OFFBOARD) {
            this.offboardNetwork(response.confirmation);
          }
        },
      });

    if (this.data.createdTime) {
      this.onboardTemplateService.setDateInfo(
        `${LAST_UPDATED_TEXT}${this.appUtil.transformDateTime(
          this.data?.updatedTime
        )}`
      );
    }
    if (
      this.networkStatus !== NetworkStatus.NEW &&
      this.networkStatus !== NetworkStatus['IN-PROGRESS']
    ) {
      this.onboardTemplateService.setDeleteConfig({
        disabled: false,
        message: NetworkDeleteConfig[this.networkStatus].btn,
        tooltip: NetworkDeleteConfig[this.networkStatus].btn,
        panelConfig: {
          btnColorBlack: false,
          deleteMsg: NetworkDeleteConfig[this.networkStatus].panelMessage,
          deleteName: this.data.name,
          deleteButtonName:
            NetworkDeleteConfig[this.networkStatus].panelBtnName,
          action: NetworkDeleteConfig[this.networkStatus].action,
          confirmation: NetworkDeleteConfig[this.networkStatus].confirmation,
        },
      });
    }
  }

  initForm() {
    const cloudType = this.getCloudType();
    this.setCloudController(cloudType);
    if (this.networkStatus !== NetworkStatus.NEW) {
      const vpcs = this.cloudController.getCloudNetworks(this.data);

      this.definitionForm = new FormGroup({
        name: new FormControl(this.data.name, [Validators.required]),
        namespace: new FormControl(this.data.namespaceID),
        exportable: new FormControl(this.data.exportable),
        cloudType: new FormControl(cloudType),
        csp: new FormControl(this.cloudController.getCSP(this.data)),
        cloudKeyID: new FormControl(
          this.cloudController.getCloudKeyID(this.data)
        ),
        cloudRegion: new FormControl(
          this.cloudController.getCloudRegion(this.data)
        ),
        isUnmanaged: new FormControl<boolean>(false),
      });

      this.subnetControl = new FormControl('', [
        Validators.required,
        Validators.pattern(REGEX.CIDR_PATTERN_STRICT),
      ]);

      let selectedVPCs = 0,
        selectedSubnets = 0;

      selectedVPCs = vpcs.length;
      vpcs.forEach((vpc) => {
        selectedSubnets += vpc.subnets.length;
      });
      const csp = this.cloudController.getCSP(this.data);
      this.dropdownPlaceholder =
        `${selectedVPCs}` +
        (selectedVPCs > 1 ? ` ${CSP_PC[csp]}` + 's' : ` ${CSP_PC[csp]}`) +
        ` ${selectedSubnets}` +
        (selectedSubnets > 1 ? ' Subnets' : ' Subnet');
      this.cloudAccountControl = new FormControl('');
      this.setCloudController(this.definitionForm.controls.cloudType.value);
      this.setCloudType(cloudType, true);
    } else {
      const vpcs = this.cloudController.getCloudNetworks(this.data);
      const csp = this.cloudController.getCSP(this.data);
      this.definitionForm = new FormGroup({
        name: new FormControl(this.data.name || '', [Validators.required]),
        namespace: new FormControl(this.data.namespaceID || ''),
        exportable: new FormControl<boolean>(this.data.exportable, [
          Validators.required,
        ]),
        cloudType: new FormControl(cloudType),
        csp: new FormControl(csp),
        cloudKeyID: new FormControl(
          this.cloudController.getCloudKeyID(this.data)
        ),
        cloudRegion: new FormControl(
          this.cloudController.getCloudRegion(this.data)
        ),
        isUnmanaged: new FormControl<boolean>(false),
      });
      this.subnetControl = new FormControl('', [
        Validators.required,
        Validators.pattern(REGEX.CIDR_PATTERN_STRICT),
      ]);
      let selectedVPCs = 0,
        selectedSubnets = 0;

      selectedVPCs = vpcs.length;
      vpcs.forEach((vpc) => {
        selectedSubnets += vpc.subnets.length;
      });
      this.cloudAccountControl = new FormControl('');
      this.dropdownPlaceholder = `${selectedVPCs} ${
        selectedVPCs > 1 ? ` ${CSP_PC[csp]}` + 's' : ` ${CSP_PC[csp]}`
      }, ${selectedSubnets} Subnets`;
      this.setCloudController(this.definitionForm.controls.cloudType.value);
      this.setCloudType(cloudType, true);
    }
    // this.setFormErrors(); // TODO: set form errors
    this.setEditable();
    if (cloudType === NETWORK_HOSTED_TYPE.PRIVATE) {
      this.updateSubnetSelectionList();
    }
    this.selectedCloud = this.definitionForm.controls.csp.value;
    this.definitionForm.controls.cloudType.valueChanges
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (cloudType: string) => {
          this.setCloudController(cloudType);
          this.setCloudType(cloudType, false);
          this.cdr.markForCheck();
          this.definitionForm.controls.cloudKeyID.reset('', {
            emitEvent: false,
          });
          this.definitionForm.controls.cloudRegion.reset('');
          this.subnetControl.reset('');
          this.fetchClouds();
        },
      });
    this.definitionForm.controls.cloudKeyID.valueChanges
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (cloudKeyID) => {
          if (!cloudKeyID) {
            return;
          }

          this.definitionForm.controls.cloudRegion.reset('', {
            emitEvent: false,
          });
          this.subnetControl?.reset('');
          if (
            this.definitionForm.controls.cloudType.value ===
            NETWORK_HOSTED_TYPE.PUBLIC
          ) {
            this.definitionForm.controls.publicCloud.controls.cloudNetworks.clear();
            const cloud = this.publicClouds.find(
              (cloud) => cloud.id === cloudKeyID
            );
            this.isUnmanaged = cloud?.keyType === CLOUD_KEY_TYPES.UNMANAGED;
            this.definitionForm.controls.csp.patchValue(
              cloud?.cloudType || '',
              { emitEvent: false }
            );
            this.selectedCloud = cloud.cloudType;
            this.definitionForm.controls.isUnmanaged.patchValue(
              this.isUnmanaged,
              { emitEvent: false }
            );

            if (this.isUnmanaged) {
              this.fetchUnmanagedVPCandRegions();
            } else {
              this.fetchCloudRegions();
            }
          } else if (
            this.definitionForm.controls.cloudType.value ===
            NETWORK_HOSTED_TYPE.PRIVATE
          ) {
            this.definitionForm.controls.csp.patchValue('PRIVATE', {
              emitEvent: false,
            });
            this.selectedCloud = NETWORK_HOSTED_TYPE.PRIVATE;
          }
          this.cloudController.updateOrchestratorWorkflow(this.selectedCloud);
        },
      });
    this.cloudAccountControl.valueChanges
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (data) => {
          if (!data || data.length === 0) {
            return;
          }
          this.definitionForm.controls.cloudKeyID.setValue(
            this.publicClouds.find((cloud) => cloud.name === data).id
          );
        },
      });
    this.definitionForm.controls.cloudRegion.valueChanges
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (value) => {
          if (
            this.definitionForm.controls.cloudType.value ===
            NETWORK_HOSTED_TYPE.PUBLIC
          ) {
            this.definitionForm.controls.publicCloud.controls.cloudNetworks.clear();
          }
          this.subnetControl?.reset('');
          this.dropdownPlaceholder = 'Select Subnets';
          if (this.isUnmanaged) {
            const unManagedVPCs = this.unManagedVPCMap.get(
              `${this.definitionForm.controls.csp.value}.${value}`
            );
            this.setSelectSearchRegionConfigForUnmanaged(unManagedVPCs);
            return;
          }

          this.fetchSubnets();
        },
      });
    this.virtualSubnets.valueChanges.pipe(takeUntil(this.stopSubs$)).subscribe({
      next: (subnets) => {
        const cloudNetworks =
          this.definitionForm.getRawValue().publicCloud.cloudNetworks;
        this.allSubnets.forEach((sub) => {
          const selSub = subnets.find((s) => s.subnet === sub.subnet);
          if (!selSub) {
            sub.virtualSubnet = '';
          } else {
            if (sub.virtualSubnet === selSub.virtualSubnet) {
              return;
            }
            sub.virtualSubnet = selSub.virtualSubnet;
          }
          const index = cloudNetworks.findIndex(
            (pc) => pc.cloudNetworkID === sub.cloudNetworkID
          );
          this.definitionForm.controls.publicCloud.controls.cloudNetworks
            .at(index)
            .controls.subnets.at(
              cloudNetworks[index].subnets.findIndex(
                (s) => s.subnet === sub.subnet
              )
            )
            .patchValue({
              virtualSubnet: sub.virtualSubnet,
            });
        });
        this.virtualSubnets.statusChanges
          .pipe(takeUntil(this.stopSubs$))
          .subscribe({
            next: () => {
              this.validateStep();
            },
          });
        this.cdr.markForCheck();
      },
    });
    this.definitionForm.valueChanges.pipe(takeUntil(this.stopSubs$)).subscribe({
      next: (value) => {
        this.validateStep();
      },
    });
    this.cdr.markForCheck();
  }

  setEditable() {
    if (
      this.networkStatus === NetworkStatus.DEPLOYED ||
      this.networkStatus === NetworkStatus['IN-PROGRESS']
    ) {
      this.isEditable = false;
      this.definitionForm.disable();
      this.virtualSubnets.disable();
      if (this.data.status === APP_SETUP_STATUS.DEPLOYED) {
        this.definitionForm.controls.namespace.enable();
        this.definitionForm.controls.exportable.enable();
        this.definitionForm.controls.publicCloud?.controls.cloudNetworks?.controls.forEach(
          (control) => {
            control.controls.subnets.enable();
            control.controls.cloudNetworkID.enable();
          }
        );
      }
    }
  }

  fetchStaticData() {
    this.loaderService.setLoaderStatus(true);
    this.networkOnboardService.getNamespaces().subscribe({
      next: (namespaces) => {
        this.loaderService.setLoaderStatus(false);
        this.namespaces = namespaces;
        this.selectNamespaceConfig.optionList = namespaces;
        this.loading.namespaces = false;
        if (
          this.networkStatus === NetworkStatus.NEW &&
          !this.definitionForm.controls.namespace.value?.length
        ) {
          this.definitionForm.controls.namespace.patchValue(
            this.getDefaultNamespaceId(),
            { emitEvent: false }
          );
        }
        this.cdr.markForCheck();
      },
      error: (error) => {
        this.loading.namespaces = false;
        this.cdr.markForCheck();
      },
    });
    this.networkOnboardService.getEdges().subscribe({
      next: (edges) => {
        this.edges = (edges || []).filter(
          (edge) => edge.status === APP_SETUP_STATUS.DEPLOYED
        );
        this.definitionForm.controls.cloudRegion.addValidators(
          RegionValidator(this.edges)
        );
      },
    });
    this.fetchClouds();
  }

  setSelectSearchRegionConfig(vpcs: Array<VPCBulkResponse>, vpcsByRegion) {
    const vpcMap = new Map<string, Array<string>>(
      vpcsByRegion?.map((vpc) => [
        vpc.network,
        vpc.subnets
          .filter((subnet) => !subnet.is_used)
          .map((subnet) => subnet.cidr),
      ]) || []
    );
    this.vpcDropdownDetails = [];
    vpcs.forEach((vpcAcc) => {
      const vpcsList = this.getVPCList(
        vpcAcc.account?.[VIRTUAL_PRIVATE_CLOUD_NAME_PLURAL[this.selectedCloud]],
        vpcMap
      );
      this.vpcDropdownDetails.push({
        item: `${vpcAcc.account.accountName} |
          ${vpcsList.length} ${CSP_PC[this.definitionForm.controls.csp.value]}s,
          ${vpcsList.reduce((p, v, i) => p + v.children.length, 0)} Subnets`,
        key: vpcAcc?.account?.accountID,
        children: vpcsList,
        isSelected:
          vpcsList.filter((pc) => pc.isSelected)?.length ===
          vpcAcc.account?.[
            VIRTUAL_PRIVATE_CLOUD_NAME_PLURAL[this.selectedCloud]
          ]?.length,
        hidden: false,
      });
    });
    this.loading.vpcs = false;
    this.cdr.detectChanges();
  }

  setSelectSearchRegionConfigForUnmanaged(vpcs: Array<string>) {
    if (
      this.definitionForm.controls.cloudType.value !==
      NETWORK_HOSTED_TYPE.PUBLIC
    )
      return;
    this.vpcDropdownDetails = [];
    const currentVPCs =
      this.definitionForm.controls.publicCloud.controls.cloudNetworks.value.map(
        (pc) => pc.cloudNetworkID
      ) || [];
    const match = ArrayUtil.findCommonValues(vpcs || [], currentVPCs);
    const account = this.publicClouds.find((cloud) => {
      return cloud.id === this.definitionForm.controls.cloudKeyID.value;
    });
    this.vpcDropdownDetails = [
      {
        item: `${account.name} |
          ${vpcs.length} VPCs`,
        key: this.definitionForm.controls.cloudKeyID.value,
        children: vpcs.map((vpc) => {
          return {
            children: [],
            item: vpc,
            key: vpc,
            isSelected: currentVPCs.includes(vpc),
          };
        }),
        isSelected: match.length === vpcs?.length,
        hidden: false,
      },
    ];
    this.loading.vpcs = false;
    this.cdr.detectChanges();
  }

  getVPCList(
    vpcs: Array<VPCListItem>,
    vpcMap: Map<string, Array<string>>
  ): Array<ListItemNode> {
    if (
      this.definitionForm.controls.cloudType.value !==
      NETWORK_HOSTED_TYPE.PUBLIC
    ) {
      return;
    }
    const vpcList: Array<ListItemNode> = [];
    vpcs.forEach((vpc) => {
      const match =
        this.definitionForm.controls.publicCloud.controls.cloudNetworks.value.find(
          (pc) => pc.cloudNetworkID === vpc.id
        );
      const isVPCDisabled = this.isVPCDisabled(vpcMap, vpc);
      vpcList.push({
        key: vpc.id,
        item: vpc.name || vpc.name,
        isDisabled: isVPCDisabled,
        tooltip: isVPCDisabled
          ? 'This vpc is already onboarded as part of another network'
          : '',
        children: vpc.subnets.map((subnet) => {
          const isSubnetDisabled = this.isSubnetDisabled(
            vpcMap,
            vpc?.id || '',
            subnet
          );
          return {
            item: subnet,
            key: `${vpc.id}::${subnet}`,
            children: [],
            isDisabled: isSubnetDisabled,
            tooltip: isSubnetDisabled
              ? 'This subnet is already onboarded as part of another network'
              : '',
            isSelected:
              match?.subnets?.some((s) => s.subnet === subnet) || false,
          } as ListItemNode;
        }),
        isSelected:
          match?.subnets?.every((s) => vpc.subnets.includes(s.subnet)) || false,
      });
    });
    return vpcList;
  }

  isVPCDisabled(vpcMap: Map<string, Array<string>>, vpc: VPCListItem): boolean {
    return (
      !vpcMap.has(vpc.id) &&
      vpc.subnets.some((subnet) =>
        this.isSubnetDisabled(vpcMap, vpc?.id || '', subnet)
      )
    );
  }

  isSubnetDisabled(
    vpcMap: Map<string, Array<string>>,
    vpc: string,
    subnet: string
  ): boolean {
    return (
      (!vpcMap.has(vpc) || !vpcMap.get(vpc).includes(subnet)) &&
      !this.definitionForm.value.publicCloud.cloudNetworks.some(
        (privateCloud) =>
          privateCloud.cloudNetworkID === vpc &&
          privateCloud.subnets.some((selSubnet) => selSubnet.subnet === subnet)
      )
    );
  }

  selectVPC(val: Array<ListItemNode>) {
    if (
      this.definitionForm.controls.cloudType.value !==
      NETWORK_HOSTED_TYPE.PUBLIC
    ) {
      return;
    }
    this.definitionForm.controls.publicCloud.controls.cloudNetworks.clear({
      emitEvent: false,
    });
    this.definitionForm.controls.publicCloud.controls.cloudNetworks =
      new FormArray<ModelFormGroup<PCWithSubnets>>(
        this.getPCFromTree(val).map<ModelFormGroup<PCWithSubnets>>((pc) => {
          return new FormGroup({
            cloudNetworkID: new FormControl(pc.cloudNetworkID),
            subnets: new FormArray(
              pc.subnets.map((subs) => {
                const virtualSubnet = subs?.subnet
                  ? this.virtualSubnets.value.find(
                      (v) => v.subnet === subs.subnet
                    )
                  : null;
                return new FormGroup({
                  subnet: new FormControl(subs.subnet),
                  virtualSubnet: new FormControl(
                    subs.virtualSubnet || virtualSubnet?.virtualSubnet
                  ),
                });
              })
            ),
          });
        })
      );
    this.definitionForm.updateValueAndValidity();
    this.updateSelection(val);
    this.validateStep();
  }

  getPCFromTree(val: Array<ListItemNode>): Array<PCWithSubnets> {
    const vpcs = val[0].children.reduce((p, vpc) => {
      if (
        vpc.children.some((subnet) => subnet.isSelected) ||
        (vpc.isSelected && this.isUnmanaged)
      ) {
        p.push({
          cloudNetworkID: vpc.key,
          subnets: vpc.children
            .filter((subnet) => subnet.isSelected)
            .map((s) => {
              return {
                subnet: s.item,
              };
            }) as Array<SubnetInfo>,
        } as PCWithSubnets);
      }
      return p;
    }, [] as Array<PCWithSubnets>);
    return vpcs;
  }

  updateSelection(treeData: Array<ListItemNode>) {
    let selectedVPCs = 0;
    let selectedSubnets = 0;
    treeData.forEach((account) => {
      account.children.forEach((vpc) => {
        if (
          vpc.isSelected ||
          vpc.children.some((subnet) => subnet.isSelected)
        ) {
          selectedVPCs += 1;
          selectedSubnets += vpc.children.filter(
            (subnet) => subnet.isSelected
          ).length;
        }
      });
    });
    this.dropdownPlaceholder = this.isUnmanaged
      ? `${selectedVPCs} ${CSP_PC[this.definitionForm.controls.csp.value]}s`
      : `${selectedVPCs} ${
          CSP_PC[this.definitionForm.controls.csp.value]
        }s, ${selectedSubnets} Subnets`;
    this.setAllSubnets();
  }

  fetchClouds() {
    if (
      this.definitionForm.controls.cloudType.value ===
        NETWORK_HOSTED_TYPE.PUBLIC &&
      !this.publicClouds.length
    ) {
      this.loading.clouds = true;
      this.cdr.markForCheck();
      this.networkOnboardService.getPublicClouds().subscribe({
        next: (clouds) => {
          this.publicClouds = clouds;
          this.loading.clouds = false;

          if (
            this.publicClouds.find(
              (cloud) =>
                cloud.id === this.definitionForm.controls.cloudKeyID.value
            )?.keyType === CLOUD_KEY_TYPES.UNMANAGED
          ) {
            this.definitionForm.controls.isUnmanaged.patchValue(true, {
              emitEvent: false,
            });
            this.isUnmanaged = true;
          }
          this.setCloudDropdown();

          this.fetchSubnets();

          this.cdr.markForCheck();
        },
        error: (error) => {
          this.loading.clouds = false;
          this.cdr.markForCheck();
        },
      });
      return;
    }

    if (
      this.definitionForm.controls.cloudType.value ===
        NETWORK_HOSTED_TYPE.PRIVATE &&
      !this.privateClouds.length
    ) {
      this.loading.clouds = true;
      this.cdr.markForCheck();
      this.networkOnboardService.getPrivateClouds().subscribe({
        next: (clouds) => {
          this.privateClouds = clouds;
          this.loading.clouds = false;
          this.cdr.markForCheck();
        },
        error: (error) => {
          this.loading.clouds = false;
          this.cdr.markForCheck();
        },
      });
      return;
    }
  }

  fetchUnmanagedVPCandRegions() {
    if (
      this.definitionForm.controls.cloudType.value !==
      NETWORK_HOSTED_TYPE.PUBLIC
    ) {
      return;
    }

    if (!this.definitionForm.controls.cloudKeyID.value) {
      return;
    }

    if (
      this.cloudRegionsMap.has(this.definitionForm.controls.cloudKeyID.value)
    ) {
      this.regions = this.cloudRegionsMap.get(
        this.definitionForm.controls.cloudKeyID.value
      );
      this.selectRegionConfig.optionList = this.regions;
      this.cdr.markForCheck();
      return;
    }

    this.loading.regions = true;
    this.cdr.markForCheck();

    this.networkOnboardService.getCloudNetworksForUnmanaged().subscribe({
      next: (data) => {
        this.regions = [];
        if (data) {
          data.forEach((cloud) => {
            if (cloud.cloud !== this.definitionForm.controls.csp.value) {
              return;
            }
            this.regions = cloud.regions.map((region) => {
              this.unManagedVPCMap.set(
                `${cloud.cloud}.${region.region}`,
                region[VIRTUAL_PRIVATE_CLOUD_NAME_PLURAL[this.selectedCloud]]
              );
              return {
                regionName: region.region,
                appCount: 0,
                locationID: '',
              };
            });

            this.selectRegionConfig.optionList = this.regions;
          });
        }

        this.cloudRegionsMap.set(
          this.definitionForm.controls.cloudKeyID.value,
          this.regions || []
        );

        this.loading.regions = false;
        this.cdr.markForCheck();
      },
      error: (error) => {
        this.regions = [];

        this.selectRegionConfig.optionList = this.regions;
        this.loading.regions = false;
        this.cdr.markForCheck();
      },
    });
  }

  fetchCloudRegions() {
    if (
      this.definitionForm.controls.cloudType.value !==
      NETWORK_HOSTED_TYPE.PUBLIC
    ) {
      return;
    }

    if (!this.definitionForm.controls.cloudKeyID.value) {
      return;
    }

    if (
      this.cloudRegionsMap.has(this.definitionForm.controls.cloudKeyID.value)
    ) {
      this.regions = this.cloudRegionsMap.get(
        this.definitionForm.controls.cloudKeyID.value
      );
      this.selectRegionConfig.optionList = this.regions;
      this.cdr.markForCheck();
      return;
    }

    this.loading.regions = true;
    this.cdr.markForCheck();

    this.networkOnboardService
      .getCloudRegions(this.definitionForm.controls.cloudKeyID.value)
      .subscribe({
        next: (regions) => {
          this.regions = this.appUtil.sort(
            regions.filter((region) => {
              return (
                this.edges.some(
                  (edge) => edge.cloudRegion === region.regionName
                ) ||
                this.definitionForm.controls.cloudRegion.value ===
                  region.regionName
              );
            }) || [],
            'regionName'
          );

          this.selectRegionConfig.optionList = this.regions;
          this.cloudRegionsMap.set(
            this.definitionForm.controls.cloudKeyID.value,
            this.regions || []
          );
          this.loading.regions = false;
          this.cdr.markForCheck();
        },
        error: (error) => {
          this.regions = [];

          this.selectRegionConfig.optionList = this.regions;
          this.loading.regions = false;
          this.cdr.markForCheck();
        },
      });
  }

  fetchSubnets() {
    if (!this.definitionForm.controls.cloudRegion.value) {
      return;
    }

    this.loading.vpcs = true;
    zip(
      this.networkOnboardService.getVPCsByRegion(
        this.definitionForm.controls.cloudKeyID.value,
        this.definitionForm.controls.cloudRegion.value
      ),
      this.networkOnboardService.getVPCs({
        cloudType: this.definitionForm.controls.csp.value || '',
        cloudRegion: this.definitionForm.controls.cloudRegion.value || '',
        accountIDs: [
          this.publicClouds.find(
            (cloud) =>
              cloud.id === this.definitionForm.controls.cloudKeyID.value
          ).accountID,
        ],
      })
    ).subscribe({
      next: ([vpcsByRegion, vpcs]) => {
        this.setSelectSearchRegionConfig(vpcs, vpcsByRegion);
      },
    });
  }

  getDefaultNamespaceId() {
    const defaultNamespace = this.namespaces?.find(
      (namespace) => namespace?.name === 'default'
    );
    return defaultNamespace.id ? defaultNamespace.id : '';
  }

  setCloudController(cloudType: string): void {
    if (cloudType === NETWORK_HOSTED_TYPE.PUBLIC) {
      this.cloudController = new PublicCloudController(
        this.orchestratorService
      );
    } else if (cloudType === NETWORK_HOSTED_TYPE.PRIVATE) {
      this.cloudController = new PrivateCloudController(
        this.orchestratorService
      );
    } else if (cloudType === NETWORK_HOSTED_TYPE.OWN) {
      this.cloudController = new OwnCloudController(this.orchestratorService);
    }
  }

  getCloudType(): string {
    if (this.data.publicCloud?.cloudType) {
      return this.data.publicCloud?.cloudType;
    } else if (this.data.privateCloud?.cloudType) {
      return this.data.privateCloud?.cloudType;
    } else if (this.data.ownTransitCloud?.cloudType) {
      return this.data.ownTransitCloud?.cloudType;
    } else return NETWORK_HOSTED_TYPE.PUBLIC;
  }

  setCloudType(cloudType: string, isInit: boolean) {
    if (cloudType !== NETWORK_HOSTED_TYPE.PUBLIC) {
      if (!isInit) {
        this.definitionForm.controls.exportable.patchValue(false, {
          emitEvent: false,
        });
      }
      this.multiSelectConfig = null;
      this.isUnmanaged = false;
    } else {
      if (!isInit) {
        this.definitionForm.controls.exportable.patchValue(true, {
          emitEvent: false,
        });
      }

      this.setCloudDropdown();
    }

    switch (cloudType) {
      case NETWORK_HOSTED_TYPE.PUBLIC:
        this.definitionForm.removeControl('privateCloud', { emitEvent: false });
        this.definitionForm.removeControl('ownTransitCloud', {
          emitEvent: false,
        });
        this.definitionForm.addControl(
          'publicCloud',
          new FormGroup({
            cloudNetworks: new FormArray<ModelFormGroup<PCWithSubnets>>(
              this.cloudController
                .getCloudNetworks(this.data)
                .map<ModelFormGroup<PCWithSubnets>>((pc) => {
                  return new FormGroup({
                    cloudNetworkID: new FormControl(pc.cloudNetworkID),
                    subnets: new FormArray(
                      pc.subnets.map((subs) => {
                        return new FormGroup({
                          subnet: new FormControl(subs.subnet),
                          virtualSubnet: new FormControl(subs.virtualSubnet),
                        });
                      })
                    ),
                  });
                })
            ),
          })
        );
        this.setAllSubnets();
        break;
      case NETWORK_HOSTED_TYPE.PRIVATE:
        this.definitionForm.removeControl('publicCloud', { emitEvent: false });
        this.definitionForm.removeControl('ownTransitCloud', {
          emitEvent: false,
        });
        this.definitionForm.addControl(
          'privateCloud',
          new FormGroup({
            privateCloudID: new FormControl(
              this.data.privateCloud?.privateCloudID || ''
            ),
            subnets: new FormControl(this.data.privateCloud?.subnets || []),
          })
        );
        this.selectedSubnetListData = this.cloudController.getSubnets(
          this.definitionForm.getRawValue()
        );
        break;
      case NETWORK_HOSTED_TYPE.OWN:
        this.definitionForm.removeControl('publicCloud', { emitEvent: false });
        this.definitionForm.removeControl('privateCloud', { emitEvent: false });
        this.definitionForm.addControl(
          'ownTransitCloud',
          new FormGroup({
            subnets: new FormControl(this.data.ownTransitCloud?.subnets || []),
          })
        );
        break;
    }
    this.cloudController.updateOrchestratorWorkflow(cloudType);
  }

  setCloudDropdown() {
    this.cloudAccountControl.setValue(
      this.publicClouds?.find(
        (cloud) => cloud.id === this.definitionForm.controls.cloudKeyID.value
      )?.name || '',
      {
        emitEvent: false,
      }
    );
    this.multiSelectConfig = {
      displayName: 'Cloud Account',
      formControl: this.cloudAccountControl,
      uiThemeMode: this.uiThemeMode + '-theme',
      controlPlaceholder: 'Account',
      searchPlaceholder: 'Search Account',
      tooltipThemeClass: this.tooltipThemeClass,
      listEmptyMessage: 'No data available',
      showSearch: true,
      isDisabled: !this.isEditable,
      errorMessage: 'Invalid',
      optionKey: 'name',
      selectedOption: this.definitionForm.controls.cloudKeyID.value
        ? {
            name: this.cloudAccountControl.value || '',
            cloudKeyID: this.definitionForm.controls.cloudKeyID.value,
            imageURL:
              IMAGE_PATHS.CLOUD.CSP[
                `${this.definitionForm.controls.csp.value}_DARK`
              ],
          }
        : null,
      optionList: Object.values(
        this.publicClouds.reduce((p, v) => {
          let csp = p[v.cloudType];
          if (!csp) {
            csp = {
              name:
                v.cloudType.toUpperCase() === CLOUD_TYPES.AZURE
                  ? this.titlecasePipe.transform(v.cloudType)
                  : v.cloudType,
              imageURL: IMAGE_PATHS.CLOUD.CSP[`${v.cloudType}_DARK`],
              options: [],
            };
          }

          csp.options.push({
            name: v.name,
            imageURL: IMAGE_PATHS.CLOUD.CSP[`${v.cloudType}_DARK`],
            cloudKeyID: v.id,
          });
          p[v.cloudType] = csp;
          return p;
        }, {})
      ),
    };
  }

  onAction(data) {
    // console.log(data);
  }

  addSubnet(value) {
    this.subnetControl.markAsTouched();
    if (!this.subnetControl.valid) {
      return;
    }

    this.cloudController.addSubnetToDefinitionForm(
      this.subnetControl.value,
      this.definitionForm
    );
    this.selectedSubnetListData = this.cloudController.getSubnets(
      this.definitionForm.getRawValue()
    );
    this.subnetControl.reset();
  }

  updateSubnetSelectionList() {
    this.selectedSubnetListData = this.cloudController.getSubnets(
      this.definitionForm.getRawValue()
    );
  }

  removeSubnetFromList(subnet: string) {
    if (!subnet) {
      return;
    }

    this.cloudController.removeSubnetToDefinitionForm(
      subnet,
      this.definitionForm
    );
    this.selectedSubnetListData = this.cloudController.getSubnets(
      this.definitionForm.getRawValue()
    );
  }

  setUseVirtualSubnets(state: MatCheckboxChange) {
    this.useVirtualSubnets = state.checked;
    if (this.useVirtualSubnets) {
      this.addVirtualSubnet();
    } else {
      this.virtualSubnets.clear();
    }
  }

  setAllSubnets() {
    this.allSubnets = (<CloudNetwork[]>(
      this.definitionForm.getRawValue().publicCloud.cloudNetworks
    )).reduce((p, pc) => {
      return p.concat(
        pc.subnets.map((subnet) => {
          return {
            cloudNetworkID: pc.cloudNetworkID,
            ...subnet,
          };
        })
      );
    }, [] as Array<{ cloudNetworkID: string } & SubnetInfo>);
    this.setVirtualSubnets();
    this.cdr.markForCheck();
  }

  setVirtualSubnets() {
    this.virtualSubnets.clear({ emitEvent: false });
    this.allSubnets
      .filter((subnet) => subnet.virtualSubnet)
      .forEach((subnet) => {
        this.virtualSubnets.push(
          new FormGroup(
            {
              subnet: new FormControl({
                value: subnet.subnet,
                disabled: this.disableVirtualSubnet(subnet),
              }),
              virtualSubnet: new FormControl(
                {
                  value: subnet.virtualSubnet,
                  disabled: this.disableVirtualSubnet(subnet),
                },
                {
                  updateOn: 'change',
                  validators: [
                    Validators.required,
                    Validators.pattern(REGEX.CIDR_PATTERN_STRICT),
                  ],
                }
              ),
            },
            {
              asyncValidators: [this.virtualSubnetValidator(this.data)],
            }
          ),
          {
            emitEvent: false,
          }
        );
      });
    this.virtualSubnets.updateValueAndValidity();
    if (this.virtualSubnets.length > 0) {
      this.useVirtualSubnets = true;
    }
    this.cdr.markForCheck();
  }

  /**
   * Checks if a virtual subnet FormControl should be enabled.
   * In Update flow, only new subnets can be updated.
   * @param subnet Selected Subnet
   * @returns Boolean indicating if the conrol should be disabled
   */
  disableVirtualSubnet(subnet: SubnetInfo): boolean {
    return (
      (this.networkStatus === NetworkStatus.DEPLOYED ||
        this.networkStatus === NetworkStatus['IN-PROGRESS']) &&
      !!subnet.virtualSubnet
    );
  }

  addVirtualSubnet() {
    this.virtualSubnets.push(
      new FormGroup(
        {
          subnet: new FormControl(''),
          virtualSubnet: new FormControl('', {
            updateOn: 'change',
            validators: [
              Validators.required,
              Validators.pattern(REGEX.CIDR_PATTERN_STRICT),
            ],
          }),
        },
        {
          asyncValidators: [this.virtualSubnetValidator(this.data)],
        }
      )
    );
    this.cdr.markForCheck();
  }

  removeVirtualSubnet(index: number) {
    this.virtualSubnets.removeAt(index);
    this.cdr.markForCheck();
  }

  setFormErrors() {
    if (!this.errorPayload) {
      return;
    }
    let err: NetworkOnboardErrorResponseData = this.errorPayload.errorObject;
    setTimeout(() => {
      if (err.name.errorMessage) {
        this.definitionForm.controls.name.setErrors({
          validation: true,
        });
        this.onboardTemplateService.setNameState({
          error: err.name.errorMessage,
        });
      }
      if (err.exportable.errorMessage) {
        this.definitionForm.controls.exportable.setErrors({
          validation: true,
        });
      }
      if (err.namespaceID.errorMessage) {
        this.definitionForm.controls.namespace.setErrors({
          validation: true,
        });
      }
      if (err.publicCloud.cloudKeyID.errorMessage) {
        this.definitionForm.controls.cloudKeyID.setErrors({
          validation: true,
        });
      }
      if (err.publicCloud.cloudRegion.errorMessage) {
        this.definitionForm.controls.cloudRegion.setErrors({
          validation: true,
        });
      }
      if (err.publicCloud.cloudType.errorMessage) {
        this.definitionForm.controls.cloudType.setErrors({
          validation: true,
        });
      }
      err.publicCloud.cloudNetworks.forEach((pc, i) => {
        if (
          pc.cloudNetworkID.errorMessage ||
          pc.subnets.some((subnet) => subnet.subnet.errorMessage)
        ) {
          this.definitionForm.controls.publicCloud.controls.cloudNetworks
            .at(i)
            .setErrors({
              validation: true,
            });
        }
        pc.subnets.forEach((subnet, i) => {
          if (subnet.virtualSubnet.errorMessage) {
            this.virtualSubnets.at(i).controls.virtualSubnet.setErrors({
              validation: true,
            });
          }
        });
      });
      this.cdr.markForCheck();
    });
  }

  validateStep() {
    if (this.networkStatus !== NetworkStatus.NEW && !this.detectFormChanges()) {
      this.isStepValid = true;
    } else if (!this.definitionForm.controls.name.value) {
      this.isStepValid = false;
    } else if (!this.definitionForm.valid || this.virtualSubnets.invalid) {
      this.isStepValid = false;
    } else {
      const formData = <NetworkDefinition>this.definitionForm.getRawValue();
      this.isStepValid = this.cloudController.validateDefinition(formData);
    }
    if (this.isStepValid) {
      this.appUtil.updateDrawerButtonConfig({
        mainBtn: { ...LightboxBtnType.NEXT, disabled: false },
        cancelBtn: LightboxBtnType.CANCEL,
      });
    } else {
      this.appUtil.updateDrawerButtonConfig({
        mainBtn: { ...LightboxBtnType.NEXT, disabled: true },
        cancelBtn: LightboxBtnType.CANCEL,
      });
    }
    this.cdr.markForCheck();
  }

  detectFormChanges(): boolean {
    const formData = <NetworkDefinition>this.definitionForm.getRawValue();
    const changes = this.cloudController.detectDefinationFormChanges(
      this.data,
      formData
    );

    this.navConfirmationService.updateNavData({
      check: changes,
      elem: null,
    });

    return changes;
  }

  updateNetwork() {
    this.validateStep();
    if (!this.isStepValid) {
      return;
    }

    if (!this.detectFormChanges()) {
      this.markStepComplete();
      return;
    }

    const formData = <NetworkDefinition>this.definitionForm.getRawValue();

    this.cloudController.updateDefinition(this.data, formData, this.namespaces);
    this.markStepComplete();
  }

  deleteNetwork() {
    if (!this.data.id) {
      return;
    }

    this.loaderService.setLoaderStatus(true);
    this.networkOnboardService.deleteNetwork(this.data.id).subscribe({
      next: (resp) => {
        this.loaderService.setLoaderStatus(false);
        this.toastService.success(
          NOTIFICATION_MESSAGES.DELETE.SUCCESS(this.data.name)
        );
        this.appUtil.setDynamicDrawerResponse({
          action: 'delete',
          context: NetworkOnboardStep.DEFINE,
          payload: null,
        });
        this.appUtil.setDrawerState(false);
      },
      error: (error) => {
        this.loaderService.setLoaderStatus(false);
        this.showErrorMessage(
          {
            errorMsg:
              error?.error?.message ||
              NOTIFICATION_MESSAGES.DELETE.FAIL(this.data.name),
          },
          () => {
            this.appUtil.setDrawerState(false);
          }
        );
      },
    });
  }

  offboardNetwork(forced: boolean) {
    if (!this.data.id) {
      return;
    }
    if (this.networkStatus !== NetworkStatus.DEPLOYED) {
      return;
    }

    this.loaderService.setLoaderStatus(true);
    this.networkOnboardService.offboardNetwork(this.data.id, forced).subscribe({
      next: (resp) => {
        this.loaderService.setLoaderStatus(false);
        this.toastService.success(
          NOTIFICATION_MESSAGES.OFFBOARD.SUCCESS(this.data.name)
        );
        this.appUtil.setDynamicDrawerResponse({
          action: 'offboard',
          context: NetworkOnboardStep.DEFINE,
          payload: null,
        });
        this.appUtil.setDrawerState(false);
      },
      error: (error) => {
        this.loaderService.setLoaderStatus(false);
        this.showErrorMessage(
          {
            errorMsg:
              error?.error?.message ||
              NOTIFICATION_MESSAGES.OFFBOARD.FAIL(this.data.name),
          },
          () => {
            this.appUtil.setDrawerState(false);
          }
        );
      },
    });
  }

  showErrorMessage(popupConfig, action: (...args: any[]) => void) {
    const dialogData = {
      ...popupConfig,
      isInnerHTML: true,
    };

    const injector = this.appUtil?.createInjector(
      DIALOG_TOKEN.INJECTOR_TOKEN_DATA.DIALOG_DATA_TOKEN.NAME,
      dialogData
    );

    const componentRef = this.appUtil?.initializeDeleteOverlay(
      DeleteDialogComponent,
      injector,
      this.uiThemeMode + '-theme',
      '340px'
    );
    (componentRef.instance as DeleteDialogComponent)?.closeDialogEvent
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          action(response);
        },
        error: (err) => {
          console.warn(err);
        },
        complete: () => {
          this.appUtil?.destroyOverlay();
        },
      });
  }

  markStepComplete() {
    this.orchestratorService.setStepComplete(NetworkOnboardStep.DEFINE);
  }

  ngOnDestroy(): void {
    this.stopSubs$.next();
    this.stopSubs$.complete();
    this.navConfirmationService.updateNavData(null);
  }
}
