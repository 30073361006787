<div class="select-taget__container d-flex" *ngIf="data?.length > 0">
  <div class="w-100 d-flex mb-2 align-items-baseline">
    <div class="list_label w-25 p-2">Network name and namespace</div>
    <div class="w-75 d-flex align-items-center">
      <div class="list_label w-15 p-2">Subnets</div>
      <div class="w-85 d-flex align-items-center">
        <div class="list_label w-40 p-2">Overlapping Networks</div>
        <div class="list_label w-35 p-2">VPCs</div>
        <div class="list_label w-25 p-2">Subnets</div>
      </div>
    </div>
  </div>
</div>
<div
  class="select-taget__container w-100 overflow-auto"
  *ngIf="data?.length > 0"
>
  <div
    class="apps w-100 d-flex bdr-grey2 mb-2 align-items-center"
    *ngFor="let overlapCIDRParent of data; let last = last"
    [class.border-bottom]="!last"
  >
    <div class="w-25 p-2">
      <span
        matTooltip="{{ overlapCIDRParent?.networkName }} ({{
          overlapCIDRParent?.namespaceName
        }})"
        [matTooltipClass]="
          'cdk-component-container--' + uiThemeMode + '-theme-dark'
        "
        matTooltipPosition="after"
        >{{ overlapCIDRParent?.networkName }} ({{
          overlapCIDRParent?.namespaceName
        }})
      </span>
    </div>
    <div class="w-75">
      <div
        class="d-flex align-items-center w-100"
        *ngFor="let subnetConflict of overlapCIDRParent?.subnetConflicts"
      >
        <div class="w-15 p-2">
          {{ subnetConflict?.subnet }}
        </div>
        <div class="w-85 bdr-blue8-op2 bg-blue8-op5 list_container mb-2">
          <div
            class="d-flex align-items-center w-100"
            *ngFor="let overlap of subnetConflict?.overlaps"
          >
            <div class="w-40 items tooltip-content text-truncate p-2">
              <span
                matTooltip="{{ overlap?.networkName }}"
                [matTooltipClass]="
                  'cdk-component-container--' + uiThemeMode + '-theme-dark'
                "
                matTooltipPosition="after"
                >{{ overlap?.networkName }}</span
              >
            </div>
            <div class="w-35 p-2 text-truncate">
              <span
                matTooltip="{{ overlap?.vpc }}"
                [matTooltipClass]="
                  'cdk-component-container--' + uiThemeMode + '-theme-dark'
                "
                matTooltipPosition="after"
              >
                {{ overlap?.vpc | pcName : overlap?.cloudType }}
              </span>
            </div>
            <div class="w-25 p-2 text-truncate">
              {{ overlap?.subnet }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-flex flex-fill align-items-center justify-content-center w-100">
  <pcomp-no-data-available
    [isDataAvailable]="data?.length"
    class="w-100"
    [noDataMessage]="OVERVIEW_TABLE_MESSAGE"
  ></pcomp-no-data-available>
</div>
