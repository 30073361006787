import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  Network,
  PrefixInfo,
  PrivateCloudRule,
  RuleAction,
  RuleMatchCondition,
} from '@app/common/models';
import {
  DeleteAction,
  NetworkDeleteConfig,
  NetworkStatus,
} from '@app/v2/onboard/network/network.constants';
import {
  IOrchestratorComponent,
  NetworkAutoOnboardStep,
} from '@app/common/components/drawer/orchestrator/orchestrator.model';
import { OrchestratorService } from '@app/common/components/drawer/orchestrator/orchestrator.service';
import { Subject, zip } from 'rxjs';
import {
  ICloudController,
  OwnCloudController,
  PrivateCloudController,
  PublicCloudController,
} from '../../../utils/controllers';
import { PDashLocalStoreUtil } from '@app/state/web-pdash.util';
import {
  IMAGE_PATHS,
  LAST_UPDATED_TEXT,
  NETWORK_HOSTED_TYPE,
  REGEX,
} from '@app/common/util/constants';
import { ModelFormGroup } from '@app/common/util/form-util';

import { MatchConditionMap, NetworkRule, Rule } from './auto-rule.model';
import { networkQuery } from '@app/v2/onboard/network/state';
import { NetworkUtil } from '@app/v2/onboard/network/network.util';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppUtil } from '@app/common/util/app-util';
import { LightboxBtnType } from '@app/dashboard/diagnose/diagnostics/lightbox-form-template/lightbox-model';
import { takeUntil } from 'rxjs/operators';
import {
  ConditionsPanelService,
  RuleListPaneConfig,
  SelectSearchConfig,
  ToastService,
} from '@prosimoio/components';
import { OnboardTemplateService } from '@app/common/components/drawer/templates/onboard-template/onboard-template.service';
import { ArrayUtil } from '@prosimoio/services';
import { NetworkOnboardService } from '@app/v2/onboard/network/network.service';
import { BGPCommunityValidator } from '@app/management/groupings/bgp-community/bgp-community-form/bgp-community.utils';
import { ConditionReorder } from '@prosimoio/components/lib/v2/conditions-panel/conditions-panel.model';
import { WellKnownCommunities } from '@app/common/models/bgp';
import { getWellKnownCommunityValue } from '@app/common/util/bgp-util';
import { ChangeSetList } from '@app/v2/onboard/network/network.model';
import { NavConfirmationService } from '@app/common/util/nav-confirmation.service';

@Component({
  selector: 'app-auto-rule',
  templateUrl: './auto-rule.component.html',
  styleUrls: ['./auto-rule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoRuleComponent
  implements IOrchestratorComponent, OnInit, OnDestroy
{
  stopSubs$: Subject<void>;
  step = NetworkAutoOnboardStep.RULE;
  ruleForm: ModelFormGroup<NetworkRule>;
  propertyControl: FormControl<string>;
  selectedRuleIndex: number = 0;
  selectedRule: ModelFormGroup<Rule>;
  networkBackup: Network;
  networkStatus: NetworkStatus;
  isStepValid = false;
  cloudController: ICloudController;
  loading = {
    prefixList: true,
  };
  isEditable: boolean = true;
  defaultHint = 'Configure rules to onboard routes';
  hint = '';
  prefixMap: Map<string, Array<PrefixInfo>>;
  bgpCommunityMap: Map<string, Array<string>>;
  bgpNeighborMap: Map<string, Array<string>>;
  isToggleSwitchChecked: boolean = false;
  selectPrefixListConfig: SelectSearchConfig = {
    optionList: [],
    placeholder: 'Select Prefix List',
    uiThemeMode: PDashLocalStoreUtil.getUIThemeMode().toLowerCase() + '-theme',
    showSearch: true,
    isMultiSelection: true,
    isServerSideSearch: false,
    setKeyNameOnControl: true,
    keyName: 'id',
    addOption: false,
    panelWidthClass: 'select-width-none',
    displayName: 'name',
  };
  selectBgpGroupConfig: SelectSearchConfig = {
    optionList: [],
    placeholder: 'Select BGP Community List',
    uiThemeMode: PDashLocalStoreUtil.getUIThemeMode().toLowerCase() + '-theme',
    showSearch: true,
    isMultiSelection: true,
    isServerSideSearch: false,
    setKeyNameOnControl: true,
    keyName: 'id',
    addOption: false,
    panelWidthClass: 'select-width-none',
    displayName: 'name',
  };
  rulePane: RuleListPaneConfig = null;
  changeSet: Array<ChangeSetList>;

  ipPrefixesLists: Map<string, string>;
  bgpGroups: Map<string, string>;

  uiThemeMode: string = PDashLocalStoreUtil.getUIThemeMode().toLowerCase();
  tooltipThemeClass: string;

  MatchConditionMap = MatchConditionMap;
  RuleMatchCondition = RuleMatchCondition;
  RuleAction = RuleAction;
  WellKnownCommunities = WellKnownCommunities;
  reorderIcon = IMAGE_PATHS.COMMON.REORDER;
  errorIcon = IMAGE_PATHS.VISUAL_ONBOARD.ERROR;
  binUrl = IMAGE_PATHS.CONFIGURE.BIN;

  @Input() data: Network;
  @Input() errorPayload: any;

  constructor(
    private networkOnboardService: NetworkOnboardService,
    private orchestratorService: OrchestratorService,
    private onboardTemplateService: OnboardTemplateService,
    private conditionsPanelService: ConditionsPanelService,
    private navConfirmationService: NavConfirmationService,
    private toastService: ToastService,
    private appUtil: AppUtil,
    private cdr: ChangeDetectorRef
  ) {
    this.stopSubs$ = new Subject();
    this.prefixMap = new Map();
    this.bgpCommunityMap = new Map();
    this.bgpNeighborMap = new Map();
    this.ipPrefixesLists = new Map();
    this.bgpGroups = new Map();
  }

  ngOnInit(): void {
    // TODO: Update condition when public cloud support is added
    if (
      !this.data.privateCloud ||
      !this.data.privateCloud.ruleBasedOnboarding
    ) {
      return;
    }
    this.networkBackup = networkQuery.getActiveNetwork();
    this.networkStatus = NetworkUtil.getNetworkState(this.data);
    this.initForm();
    if (
      this.errorPayload &&
      this.errorPayload.errorStep === NetworkAutoOnboardStep.RULE
    ) {
      this.hint = this.errorPayload.errorMessage;
      this.selectedRuleIndex = this.errorPayload.index;
    } else {
      this.hint = this.defaultHint;
    }
    this.appUtil.updateDrawerButtonConfig({
      mainBtn: { ...LightboxBtnType.NEXT, disabled: true },
      cancelBtn: LightboxBtnType.CANCEL,
    });
    PDashLocalStoreUtil.getUIThemeModeAsObservable()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (uiThemeMode) => {
          this.uiThemeMode = uiThemeMode.toLowerCase();
          this.toastService.setUITheme(this.uiThemeMode + '-theme');
          this.selectPrefixListConfig.uiThemeMode =
            uiThemeMode.toLowerCase() + '-theme';
          this.selectBgpGroupConfig.uiThemeMode =
            uiThemeMode.toLowerCase() + '-theme';
          this.tooltipThemeClass =
            'cdk-component-container--' + this.uiThemeMode + '-theme-dark';
          this.cdr.markForCheck();
        },
      });
    this.onboardTemplateService
      .getNameInput()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (name) => {
          this.ruleForm.controls.name.patchValue(name);
        },
      });
    this.appUtil
      .getDrawerResponse()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (response) => {
          if (response.code === LightboxBtnType.NEXT.code) {
            this.updateNetwork();
          } else {
            if (this.detectFormChanges().length > 0) {
              this.navConfirmationService.navConfirmationPopup(null);
              return;
            }
            this.appUtil.setDrawerState(false);
            this.appUtil.setDynamicDrawerResponse({
              context: NetworkAutoOnboardStep.RULE,
              action: response.code,
              payload: null,
            });
          }
        },
      });
    this.appUtil
      .getBackdropEvent()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (data) => {
          if (this.detectFormChanges().length > 0) {
            this.navConfirmationService.navConfirmationPopup(null);
            return;
          }
        },
      });
    this.appUtil.setConditionHeading('Priority Order');
    this.setConditionPanelConfig(null);
    this.appUtil
      .getConditionClickEvent()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (val) => {
          this.selectedRuleIndex =
            this.ruleForm.controls.rules.controls.findIndex(
              (rule) => rule.value.id === val.label
            );
          this.selectedRule = this.ruleForm.controls.rules.at(
            this.selectedRuleIndex
          );
          // this.rulePane = {
          //   ruleTitle: `Prefix`,
          //   showRuleListIcon: true,
          //   displayedValueBetweenRules: 'AND',
          //   hideColumnGap: true,
          //   isRuleListEditable: true,
          //   ruleList: [
          //     this.prefixMap.get(this.selectedRule.value.id).map((prefix) => {
          //       return {
          //         rule: 'matches',
          //         ruleValue: {
          //           label: `${prefix.prefix}
          //            min ${prefix.min}
          //            max ${prefix.max}`,
          //           status: 'active',
          //         },
          //       };
          //     }),
          //   ],
          //   showAndOperationBetweenRules: true,
          //   showBoldRules: true,
          //   showBoldValue: true,
          //   showCommonRule: true,
          //   showDeleteIcon: true,
          //   showEditIcon: true,
          //   showOROperator: true,
          // };
        },
      });
    this.conditionsPanelService
      .getAddCondition()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: () => {
          this.ruleForm.controls.rules.push(this.getDefaultRuleFormGroup());
          this.setConditionPanelConfig(null);
          this.cdr.markForCheck();
        },
      });
    this.conditionsPanelService
      .getReorderConditions()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (data: ConditionReorder) => {
          const temp = this.ruleForm.controls.rules
            .at(data.previousIndex)
            .getRawValue();
          this.ruleForm.controls.rules
            .at(data.previousIndex)
            .setValue(
              this.ruleForm.controls.rules.at(data.currentIndex).getRawValue()
            );
          this.ruleForm.controls.rules.at(data.currentIndex).setValue(temp);
          this.cdr.markForCheck();
        },
      });

    this.orchestratorService
      .getTabChangeRequest()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (step: NetworkAutoOnboardStep) => {
          const formData = <NetworkRule>this.ruleForm.getRawValue();
          this.cloudController.updateAutoOnboardRules(
            this.data,
            formData,
            this.prefixMap,
            this.bgpCommunityMap,
            this.bgpNeighborMap
          );
          this.orchestratorService.setGotoStop(step);
        },
      });

    this.setDrawerFooter();
    this.fetchStaticData();
    this.validateStep();
  }

  setDrawerFooter() {
    this.onboardTemplateService
      .getDeleteResponse()
      .pipe(takeUntil(this.stopSubs$))
      .subscribe({
        next: (response) => {
          if (response.action === DeleteAction.DELETE) {
            // this.deleteNetwork();
          } else if (response.action === DeleteAction.OFFBOARD) {
            // this.offboardNetwork(response.confirmation);
          }
        },
      });

    if (this.data.createdTime) {
      this.onboardTemplateService.setDateInfo(
        `${LAST_UPDATED_TEXT}${this.appUtil.transformDateTime(
          this.data?.updatedTime
        )}`
      );
    }
    if (
      this.networkStatus !== NetworkStatus.NEW &&
      this.networkStatus !== NetworkStatus['IN-PROGRESS']
    ) {
      this.onboardTemplateService.setDeleteConfig({
        disabled: false,
        message: NetworkDeleteConfig[this.networkStatus].btn,
        tooltip: NetworkDeleteConfig[this.networkStatus].btn,
        panelConfig: {
          btnColorBlack: false,
          deleteMsg: NetworkDeleteConfig[this.networkStatus].panelMessage,
          deleteName: this.data.name,
          deleteButtonName:
            NetworkDeleteConfig[this.networkStatus].panelBtnName,
          action: NetworkDeleteConfig[this.networkStatus].action,
          confirmation: NetworkDeleteConfig[this.networkStatus].confirmation,
        },
      });
    }
  }

  fetchStaticData() {
    this.loading.prefixList = true;
    zip(
      this.networkOnboardService.getAllIPPrefixGroupings(),
      this.networkOnboardService.getAllBgpCommunityGroupings()
    ).subscribe({
      next: ([ipPrefixList, bgpGroups]) => {
        this.loading.prefixList = false;
        this.selectPrefixListConfig.optionList = ipPrefixList;
        this.selectBgpGroupConfig.optionList = bgpGroups;
        this.ipPrefixesLists = new Map(
          ipPrefixList.map((list) => [list.id, list.name])
        );
        this.bgpGroups = new Map(bgpGroups.map((list) => [list.id, list.name]));
        this.cdr.markForCheck();
      },
    });
  }

  setConditionPanelConfig(valid: Set<number>) {
    this.appUtil.setConditionConfig(
      // TODO: Handle public cloud when support is added
      this.ruleForm.controls.rules.controls?.length > 0
        ? this.ruleForm.controls.rules.controls.map((rule, i) => {
            return {
              displayLabel: `Rule ${i + 1}`,
              label: rule.controls.id.value,
              isActive: i === this.selectedRuleIndex,
              isValid: valid?.has(i) || false,
            };
          })
        : []
    );
  }

  initForm() {
    const cloudType = this.getCloudType();
    this.setCloudController(cloudType);

    this.ruleForm = new FormGroup({
      name: new FormControl(this.data.name || '', [Validators.required]),
      rules: new FormArray(
        this.getRulesFormArray(this.data.privateCloud.autoOnboardRules.rules)
      ),
    });
    this.propertyControl = new FormControl('prefix');
    this.selectedRule = this.ruleForm.controls.rules.at(this.selectedRuleIndex);
    this.ruleForm.valueChanges.pipe(takeUntil(this.stopSubs$)).subscribe({
      next: (val) => {
        this.updateChangeSet();
        this.validateStep();
      },
    });
  }

  getRulesFormArray(
    rules: Array<PrivateCloudRule>
  ): Array<ModelFormGroup<Rule>> {
    if (!rules || rules.length === 0) {
      return [this.getDefaultRuleFormGroup()];
    } else {
      return rules.map<ModelFormGroup<Rule>>((rule, i) => {
        const id = crypto.randomUUID().toString();
        this.prefixMap.set(
          id,
          ArrayUtil.deepClone(rule.prefixes?.values || [])
        );
        this.bgpCommunityMap.set(
          id,
          ArrayUtil.deepClone(
            (rule.bgpCommunities?.communities || []).filter(
              (community) => !getWellKnownCommunityValue(community, true)
            )
          )
        );
        this.bgpNeighborMap.set(
          id,
          ArrayUtil.deepClone(rule.bgpNeighbours?.values || [])
        );
        return new FormGroup({
          id: new FormControl(id),
          action: new FormControl(rule.action || RuleAction.ACCEPT),
          prefixes: new FormGroup({
            matchCondition: new FormControl(
              rule.prefixes?.matchCondition || RuleMatchCondition.ANY
            ),
            groups: new FormControl(
              rule.prefixes?.groups?.map<string>((group) => group.id) || []
            ),
            values: new FormGroup({
              max: new FormControl(null, [
                Validators.required,
                Validators.max(32),
              ]),
              min: new FormControl(null, [Validators.required]),
              prefix: new FormControl('', [
                Validators.required,
                Validators.pattern(REGEX.CIDR_PATTERN_STRICT),
              ]),
            }),
          }),
          bgpCommunities: new FormGroup({
            matchCondition: new FormControl(
              rule.bgpCommunities?.matchCondition || RuleMatchCondition.ANY
            ),
            wellKnownCommunities: new FormControl(
              this.getWellKnownCommunities(rule.bgpCommunities?.communities) ||
                []
            ),
            communities: new FormControl('', [
              Validators.required,
              BGPCommunityValidator,
            ]),
            communityGroups: new FormControl(
              rule.bgpCommunities?.communityGroups?.map<string>(
                (group) => group.id
              ) || []
            ),
          }),
          bgpNeighbours: new FormGroup({
            matchCondition: new FormControl(
              rule.bgpNeighbours?.matchCondition || RuleMatchCondition.ANY
            ),
            values: new FormControl('', [
              Validators.required,
              Validators.pattern(REGEX.IP_ADDRESS_PATTERN),
            ]),
          }),
        });
      });
    }
  }

  getDefaultRuleFormGroup(): ModelFormGroup<Rule> {
    return new FormGroup({
      id: new FormControl(crypto.randomUUID().toString()),
      action: new FormControl(<RuleAction>RuleAction.ACCEPT),
      prefixes: new FormGroup({
        matchCondition: new FormControl(
          <RuleMatchCondition>RuleMatchCondition.ANY
        ),
        groups: new FormControl([]),
        values: new FormGroup({
          max: new FormControl(null, [Validators.required, Validators.max(32)]),
          min: new FormControl(null, [Validators.required]),
          prefix: new FormControl('', [
            Validators.required,
            Validators.pattern(REGEX.CIDR_PATTERN_STRICT),
          ]),
        }),
      }),
      bgpCommunities: new FormGroup({
        matchCondition: new FormControl(
          <RuleMatchCondition>RuleMatchCondition.ANY
        ),
        communities: new FormControl('', [
          Validators.required,
          BGPCommunityValidator,
        ]),
        wellKnownCommunities: new FormControl(
          this.getWellKnownCommunities([]) || []
        ),
        communityGroups: new FormControl([]),
      }),
      bgpNeighbours: new FormGroup({
        matchCondition: new FormControl(
          <RuleMatchCondition>RuleMatchCondition.ANY
        ),
        values: new FormControl('', [
          Validators.required,
          Validators.pattern(REGEX.IP_ADDRESS_PATTERN),
        ]),
      }),
    });
  }

  getWellKnownCommunities(communities: Array<string>): Array<string> {
    if (!communities || !communities.length) {
      return [];
    }

    return (
      communities.filter((community) => !!WellKnownCommunities[community]) || []
    );
  }

  addPrefix() {
    let prefixes = this.prefixMap.get(this.selectedRule.value.id);
    if (!prefixes || prefixes.length === 0) {
      prefixes = [
        <PrefixInfo>this.selectedRule.controls.prefixes.controls.values.value,
      ];
    } else {
      prefixes.push(
        <PrefixInfo>this.selectedRule.controls.prefixes.controls.values.value
      );
    }

    this.prefixMap.set(this.selectedRule.value.id, prefixes);
    this.selectedRule.controls.prefixes.controls.values.reset(
      { max: null, min: null, prefix: '' },
      { emitEvent: false }
    );
    this.updateChangeSet();
    this.validateStep();
  }

  addBGPCommunity() {
    if (
      this.selectedRule.controls.bgpCommunities.controls.communities.invalid
    ) {
      return;
    }

    const community: string =
      this.selectedRule.controls.bgpCommunities.controls.communities.getRawValue();

    const wellKnownCommunity = getWellKnownCommunityValue(community, true);
    if (wellKnownCommunity) {
      this.selectedRule.controls.bgpCommunities.controls.wellKnownCommunities.setValue(
        Array.from(
          new Set(
            this.selectedRule.controls.bgpCommunities.controls.wellKnownCommunities
              .getRawValue()
              .concat([wellKnownCommunity])
          )
        )
      );
      this.selectedRule.controls.bgpCommunities.controls.communities.reset('', {
        emitEvent: false,
      });
      this.updateChangeSet();
      this.validateStep();
      return;
    }

    let communities = this.bgpCommunityMap.get(this.selectedRule.value.id);

    if (!communities || communities.length === 0) {
      communities = [community];
    } else if (communities?.some((v) => community === v)) {
      this.selectedRule.controls.bgpCommunities.controls.communities.reset('', {
        emitEvent: false,
      });
      return;
    } else {
      communities.push(community);
    }
    this.bgpCommunityMap.set(this.selectedRule.value.id, communities);
    this.selectedRule.controls.bgpCommunities.controls.communities.reset('', {
      emitEvent: false,
    });
    this.updateChangeSet();
    this.validateStep();
    this.cdr.markForCheck();
  }

  removeBGPCommunity(community: string) {
    // this.communities = this.communities.filter((v) => community !== v);
    // this.validatePage();
    // this.cdr.markForCheck();
  }

  addBGPNeighbor() {
    if (this.selectedRule.controls.bgpNeighbours.controls.values.invalid) {
      return;
    }

    const neighbor: string =
      this.selectedRule.controls.bgpNeighbours.controls.values.getRawValue();

    let neighbors = this.bgpNeighborMap.get(this.selectedRule.value.id);

    if (!neighbors || neighbors.length === 0) {
      neighbors = [neighbor];
    } else if (neighbors?.some((v) => neighbor === v)) {
      this.selectedRule.controls.bgpNeighbours.controls.values.reset();
      return;
    } else {
      neighbors.push(neighbor);
    }
    this.bgpNeighborMap.set(this.selectedRule.value.id, neighbors);
    this.selectedRule.controls.bgpNeighbours.controls.values.reset();
    this.updateChangeSet();
    this.validateStep();
    this.cdr.markForCheck();
  }

  getCloudType(): string {
    if (this.data.publicCloud?.cloudType) {
      return this.data.publicCloud?.cloudType;
    } else if (this.data.privateCloud?.cloudType) {
      return this.data.privateCloud?.cloudType;
    } else if (this.data.ownTransitCloud?.cloudType) {
      return this.data.ownTransitCloud?.cloudType;
    } else return NETWORK_HOSTED_TYPE.PRIVATE;
  }

  setCloudController(cloudType: string): void {
    if (cloudType === NETWORK_HOSTED_TYPE.PUBLIC) {
      this.cloudController = new PublicCloudController(
        this.orchestratorService
      );
    } else if (cloudType === NETWORK_HOSTED_TYPE.PRIVATE) {
      this.cloudController = new PrivateCloudController(
        this.orchestratorService
      );
    } else if (cloudType === NETWORK_HOSTED_TYPE.OWN) {
      this.cloudController = new OwnCloudController(this.orchestratorService);
    }
  }

  onViewChangesClick(event) {
    this.isToggleSwitchChecked = event;
  }

  deleteCondition(condition, value) {
    const ruleID = this.selectedRule.value.id;
    switch (condition) {
      case 'prefix':
        let prefixes = this.prefixMap.get(ruleID);
        prefixes = prefixes.filter(
          (prefix) => !NetworkUtil.comparePrefix(prefix, value)
        );
        this.prefixMap.set(ruleID, prefixes);
        break;
      case 'prefixList':
        this.selectedRule.controls.prefixes.controls.groups.setValue(
          this.selectedRule.value.prefixes.groups.filter(
            (group) => group !== value
          ) || []
        );
        break;
      case 'bgpCommunity':
        const wellKnownCommunity = getWellKnownCommunityValue(value, true);
        if (wellKnownCommunity) {
          this.selectedRule.controls.bgpCommunities.controls.wellKnownCommunities.setValue(
            this.selectedRule.value.bgpCommunities.wellKnownCommunities.filter(
              (community) => community !== wellKnownCommunity
            )
          );
          break;
        }
        let bgpCommunities = this.bgpCommunityMap.get(ruleID);
        bgpCommunities = bgpCommunities.filter(
          (bgpCommunity) => bgpCommunity !== value
        );
        this.bgpCommunityMap.set(ruleID, bgpCommunities);
        break;
      case 'bgpGroup':
        this.selectedRule.controls.bgpCommunities.controls.communityGroups.setValue(
          this.selectedRule.value.bgpCommunities.communityGroups.filter(
            (group) => group !== value
          ) || []
        );
        break;
      case 'bgpNeighbor':
        let bgpNeighbors = this.bgpNeighborMap.get(ruleID);
        bgpNeighbors = bgpNeighbors.filter(
          (bgpNeighbor) => bgpNeighbor !== value
        );
        this.bgpNeighborMap.set(ruleID, bgpNeighbors);
        break;
    }
    this.updateChangeSet();
  }

  updateChangeSet() {
    this.changeSet = this.cloudController.getRulesChangeSet(
      this.networkBackup,
      this.ruleForm.getRawValue(),
      this.prefixMap,
      this.bgpCommunityMap,
      this.bgpNeighborMap,
      this.ipPrefixesLists,
      this.bgpGroups
    );
  }

  deleteRule(index: number) {
    if (index < 0 || index >= this.ruleForm.controls.rules.length) {
      return;
    }

    this.ruleForm.controls.rules.removeAt(index);

    if (this.selectedRuleIndex >= this.ruleForm.controls.rules.length) {
      this.selectedRuleIndex = this.ruleForm.controls.rules.length - 1;
    }

    if (this.ruleForm.controls.rules.length === 0) {
      this.selectedRuleIndex = 0;
      this.ruleForm.controls.rules.push(this.getDefaultRuleFormGroup());

      this.selectedRule = this.ruleForm.controls.rules.at(
        this.selectedRuleIndex
      );
    }

    this.setConditionPanelConfig(null);
    this.selectedRule = this.ruleForm.controls.rules.at(this.selectedRuleIndex);
    this.cdr.markForCheck();
  }

  validateStep() {
    const modifiedRules = this.detectFormChanges();
    let validSteps = [];
    const formData = <NetworkRule>this.ruleForm.getRawValue();
    validSteps = this.cloudController.validateAutoOnboardRules(
      this.data,
      formData.rules,
      this.prefixMap,
      this.bgpCommunityMap,
      this.bgpNeighborMap
    );
    if (this.networkStatus !== NetworkStatus.NEW && !modifiedRules.length) {
      this.isStepValid = true;
    } else if (!this.ruleForm.controls.name.value) {
      this.isStepValid = false;
    } else {
      this.isStepValid = validSteps.length === formData.rules.length;
    }
    if (this.isStepValid) {
      this.appUtil.updateDrawerButtonConfig({
        mainBtn: { ...LightboxBtnType.NEXT, disabled: false },
        cancelBtn: LightboxBtnType.CANCEL,
      });
    } else {
      this.appUtil.updateDrawerButtonConfig({
        mainBtn: { ...LightboxBtnType.NEXT, disabled: true },
        cancelBtn: LightboxBtnType.CANCEL,
      });
    }
    if (this.errorPayload) {
      validSteps = validSteps?.filter(
        (step) => step !== this.errorPayload.index
      );
    }
    this.setConditionPanelConfig(new Set(validSteps));
    this.cdr.markForCheck();
  }

  detectFormChanges(): Array<number> {
    const formData = <NetworkRule>this.ruleForm.getRawValue();
    const changes = this.cloudController.detectAutoOnboardRulesFormChanges(
      this.data,
      formData.rules,
      this.prefixMap,
      this.bgpCommunityMap,
      this.bgpNeighborMap
    );
    this.navConfirmationService.updateNavData({
      check: changes.length > 0,
      elem: null,
    });
    return changes;
  }

  updateNetwork() {
    this.validateStep();
    if (!this.isStepValid) {
      return;
    }

    if (!this.detectFormChanges()) {
      this.markStepComplete();
      return;
    }

    const formData = <NetworkRule>this.ruleForm.getRawValue();
    this.cloudController.updateAutoOnboardRules(
      this.data,
      formData,
      this.prefixMap,
      this.bgpCommunityMap,
      this.bgpNeighborMap
    );
    this.markStepComplete();
  }

  markStepComplete() {
    this.orchestratorService.setStepComplete(NetworkAutoOnboardStep.RULE);
  }

  ngOnDestroy(): void {
    this.stopSubs$.next();
    this.stopSubs$.complete();
    this.navConfirmationService.updateNavData(null);
  }
}
