export interface TicketSupportModel {
  topics: Array<any>;
  displayForm?: boolean;
  iconURL?: string;
  requestStatus?: string;
  responseMsg?: string;
}

export interface TicketSubmitModel {
  type: string;
  subject: string;
  tags: Array<any>;
  comment: any;
}

export const SubmitTypes = [
  { displayName: 'Submit a question', value: 'question' },
  { displayName: 'Provide feedback', value: 'feedback' },
];

export enum RequestStatus {
  SUCCESS = 'success',
  ERROR = 'failed',
}
