import { Page, Sort, TimeISO } from '@prosimoio/services';

export type SharedService = {
  id: string;
  name: string;
  deployed: boolean;
  status: string;
  type: string;
  region: SharedServiceRegion;
  serviceInsert: ServiceInsertionSummary;
  createdTime: TimeISO;
  updatedTime: TimeISO;
  progress: number;
};

export type SharedServiceRegion = {
  id: string;
  serviceID: string;
  status: string;
  cloudType: string;
  cloudKeyID: string;
  cloudRegion: string;
  cloudZones: Array<string>;
  accountID: string;
  gwLoadBalancerID: string;
  resourceGroup: string;
  routeTable: Array<string>;
  resourceReqStatus: string;
  countSrc: number;
  countTgt: number;
  countTgtApp: number;
};

export type ServiceInsertionSummary = {
  id: string;
  name: string;
  type: string;
  regionId: string;
  source: FirewallPolSource;
  target: FirewallPolTarget;
  namespaceNID: number;
  namespaceID: string;
};

export type FirewallPolSource = {
  networks: Array<FirewallNetworkInfo>;
};

export type FirewallPolTarget = {
  networks: Array<FirewallNetworkInfo>;
  apps: Array<FirewallNetworkInfo>;
};

export type FirewallNetworkInfo = {
  id: string;
  name: string;
  subnet: string;
  cidrs: Array<string>;
  status: string;
  ifDelete: boolean;
};

export type SharedServiceEndpointSubnets = {
  sharedServiceName: string;
  serviceEpName: string;
  sharedSvcCloudKeyID: string;
  region: string;
  vpcID: string;
  cloudKeyID: string;
  subnets: Array<string>;
};

export type SharedServiceFilter = {
  value: string;
  type: string;
  statusFilter: Array<string>;
  page: Page;
  sort: Array<Sort>;
  deployed: boolean;
};

export type SharedServiceResponse = {
  totalCount: number;
  records: Array<SharedService>;
};
