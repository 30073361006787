import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  APP_SETUP_STATUS,
  APP_TYPES,
  SUBSCRIPTION_TYPES,
  URL_PATHS,
  USER_TYPES,
} from '@app/common/util/constants';
import { PDashLocalStoreUtil } from '@app/state/web-pdash.util';
import { userQuery, userService, UserState } from './state';
import { CONSTANTS } from 'environments/environment';
import { ObjectUtil } from '@prosimoio/services';
import { Observable, zip } from 'rxjs';
import {
  UserBehaviorInsightsService,
  UserBehaviorInsightsUserInfo,
} from '@prosimoio/services';
import { take } from 'rxjs/operators';
import { pDashService } from '@app/state/web-pdash.service';
import { Day0 } from '@app/state/web-pdash.store';
import { ClusterService } from '@app/common/services/cluster.service';
import { AccountService } from '@app/common/services/account.service';

@Injectable({
  providedIn: 'root',
})
export class LoginUtil {
  constructor(
    private router: Router,
    private accountService: AccountService,
    private clusterService: ClusterService,
    private ubiService: UserBehaviorInsightsService,
    @Inject(DOCUMENT) private document
  ) {}

  isProsimoAdmin() {
    const user = this.getUserState();
    return user?.type?.toLowerCase() === USER_TYPES.PROSIMO_ADMIN
      ? true
      : false;
  }

  isProsimoSupportTeam(user: any) {
    //const user = this.getUserState();
    const teamName = user?.team?.toLowerCase().substring(0, 15);
    return teamName === USER_TYPES.SUPPORT_ADMIN ? true : false;
  }

  isSupportAdmin() {
    const user = this.getUserState();
    return user?.type?.toLowerCase() === USER_TYPES.MSP_ADMIN &&
      this.isProsimoSupportTeam(user)
      ? true
      : false;
  }

  isMSPAdmin() {
    const user = this.getUserState();
    return user?.type?.toLowerCase() === USER_TYPES.MSP_ADMIN &&
      !this.isProsimoSupportTeam(user)
      ? true
      : false;
  }

  isTrialAdmin() {
    const user = this.getUserState();
    return user?.type?.toLowerCase() === USER_TYPES.SANDBOX ? true : false;
  }

  isAccountExpired(): boolean {
    const currentTime = new Date().getTime();
    const expiredTime = this.getTeamAccountExpiryTime();
    const delta = expiredTime - currentTime;
    return delta > 0 ? false : true;
  }

  getUserState(): UserState {
    return userQuery.getValue() || null;
  }

  getTeamAccountExpiryTime() {
    return userQuery.getValue().accountExpiry || null;
  }

  getUserID(): string {
    return userQuery.getValue().userId || null;
  }

  getUserName() {
    return userQuery.getValue().username || '';
  }

  getUserAuthenticationFactors(): Array<any> {
    return userQuery.getValue().factors || [];
  }

  getUserStatus() {
    return userQuery.getValue().status || '';
  }

  getUserStatusAsObservable(): Observable<string> {
    return userQuery.loggedInStatus;
  }

  getUserTokenName() {
    return userQuery.getValue().tokenName || '';
  }

  getUserGeneralTokenName() {
    return userQuery.getValue().generalTokenName || '';
  }

  getUserAccountType() {
    return userQuery.getValue().type || '';
  }

  getTeamMetaInfoFromUserState(): Observable<any> {
    return userQuery.select(['companyName', 'iconPath', 'type', 'loginURL']);
  }

  /**
   * get the current product view type - will be either MCN or ZTNA
   * @returns
   */
  getProductViewType(): string {
    return PDashLocalStoreUtil.getProductViewType();
  }

  /**
   * subscription type can be [ZTNA] or [MCN] or [ZTNA, MCN]
   * @returns
   */
  getSubscriptionType(): Array<string> {
    return userQuery.getValue()?.subscriptionType || [];
  }

  /**
   * Method to check if subscription type is MCN
   * @returns boolean
   */
  isMCNOnly(): boolean {
    const productViewType = this.getProductViewType();
    return (
      productViewType &&
      productViewType?.toLowerCase() === SUBSCRIPTION_TYPES.MCN
    );
  }

  /**
   * Method to check if subscription tpye is ZTNA
   * @returns boolean
   */
  isZTNAOnly(): boolean {
    const productViewType = this.getProductViewType();
    return (
      productViewType &&
      productViewType?.toLowerCase() === SUBSCRIPTION_TYPES.ZTNA
    );
  }

  getUserStateAsObservable(): Observable<any> {
    return userService.getUserStateAsObservable();
  }

  updateUserState(state: UserState, isSetUserStateAsObservable = false) {
    if (state) {
      if (state.token) {
        // set prosimo-token
        this.setCookie(
          `${state.tokenName} = ${state.token}`,
          state.tokenExpiry
        );
      }
      if (state.generalToken) {
        // set wildcard token
        const domain = this.getGeneralDomain();
        this.setCookie(
          `${state.generalTokenName} = ${state.generalToken}`,
          state.generalTokenExpiry,
          `.${domain}`
        );
      }
    }
    this.setUserBehaviorInsightsUserData(state);
    userService.updateUser(state, isSetUserStateAsObservable);
    this.setProductViewType(state);
  }

  /**
   * Sets the product view type
   * @param state
   */
  setProductViewType(state: UserState) {
    let viewType = '';
    const currentProductViewType = PDashLocalStoreUtil.getProductViewType();
    if (
      state &&
      state?.subscriptionType &&
      state?.subscriptionType?.length > 1
    ) {
      viewType = currentProductViewType
        ? currentProductViewType
        : state?.subscriptionType[0];
    } else {
      viewType = state?.subscriptionType[0];
      console.warn('unknown subscription type', state?.subscriptionType);
    }
    if (viewType) {
      PDashLocalStoreUtil.updateProductViewType(viewType);
    }
  }

  updateLoginQueryParam(queryParam: string) {
    userService.updateQueryParam(queryParam);
  }

  updateDayZeroStatus(status: string) {
    userService.updateProsimoAppStatus(status);
  }

  updateUserLicenseStatus(status) {
    userService.updateLicenseStatus(status);
  }

  /**
   * Return general domain
   */
  getGeneralDomain() {
    const host = location.hostname;
    const domainArr = host && host.split('.');
    const isTeamHost = domainArr && domainArr.length === 4 ? true : false;
    const domainStr = isTeamHost
      ? domainArr.slice(1).join('.')
      : domainArr.join('.');
    return domainStr;
  }

  startRouting(queryParams: string = null) {
    if (queryParams) {
      const { queryParams: qp } = ObjectUtil.decodeObject(queryParams);
      location.href = `${location.protocol}//${location.host}/${CONSTANTS.HELP.PATH_TO_HELP_CENTER}${qp}`;
      return;
    }
    if (this.isProsimoAdmin()) {
      this.router.navigateByUrl(URL_PATHS.ADMIN.DASHBOARD); // admin dashboard
    } else if (this.isSupportAdmin()) {
      window.open(`${CONSTANTS.DASHBOARD_HOST}/msp/support`, '_self');
    } else if (this.isMSPAdmin()) {
      this.router.navigateByUrl(URL_PATHS.MSP.DASHBOARD); // msp dashboard
    } else {
      if (this.isTrialAdmin()) {
        this.router.navigateByUrl(URL_PATHS.DASHBOARD); // main dashboard
      } else {
        this.routeBasedOnCondition();
      }
    }
  }

  routeBasedOnCondition() {
    const notAllowedSet = new Set<string>([
      APP_SETUP_STATUS.UNAVAILABLE,
      // APP_SETUP_STATUS.CONFIGURED,
      APP_SETUP_STATUS.CONFIGURING,
    ]);
    const userState: UserState = this.getUserState();
    const dataRequest$ = zip(
      this.accountService.getCloudsAndIDPsConfig(),
      this.clusterService.getEdges()
    );
    dataRequest$?.pipe(take(1)).subscribe(
      ([configData, edges]) => {
        const isEdgePresent = edges?.length != 0;
        const dayZeroStatus = this.dayZeroConfigStatus(configData);
        if (dayZeroStatus) {
          this.updateDayZeroStatus(dayZeroStatus);
        }
        // Get the user role to redirect either to admin or super-admin dashboard.
        // redirect user to dashboard page. TODO: Add condition to route to dashboard or admin/dashboard
        // enterprise customer dashboard
        if (notAllowedSet.has(dayZeroStatus || userState.prosimoAppStatus)) {
          this.router.navigate([CONSTANTS.MANAGEMENT.UI_PATH_CLOUD], {
            state: { appType: APP_TYPES.PROSIMO },
          });
          this.updateDay0Details({
            isStepOneDone: false,
            isStepTwoDone: false,
          });
        } else if (!isEdgePresent) {
          // based on day 0 step route user to appropriate route
          this.updateDay0Details({
            isStepOneDone: true,
            isStepTwoDone: isEdgePresent,
          });
          this.router.navigateByUrl(
            CONSTANTS.VISUAL_ONBOARDING.UI_INFRASTRUCTURE_PATH
          );
        } else {
          this.router.navigateByUrl(PDashLocalStoreUtil.getRequestedURL());
        }
      },
      (error) => {
        console.warn('failed to get cloud, idp and edges info', error);
        this.router.navigateByUrl(URL_PATHS.PAGE_NOT_FOUND);
      }
    );
  }

  updateDay0Details(day0Details: Day0) {
    pDashService.updateDay0Details(day0Details);
  }

  /**
   * Return the prosimo app status based on the configured clouds. If the primary cloud is configured route to url which is store in local store, else route to day 0 onboarding
   * @param param0
   * @returns
   */
  dayZeroConfigStatus({ clouds = [], idps = [] }) {
    const infraClouds = clouds?.filter((cloud) => cloud.isDefault);
    if (infraClouds?.length) {
      return APP_SETUP_STATUS.CONFIGURED;
    }
    return APP_SETUP_STATUS.CONFIGURING;
  }

  setCookie(
    value: string,
    expiry: number,
    domain: string = `${location.hostname}`
  ) {
    if (!value) {
      return;
    }
    const cookieValue = this.getCookieValue(
      `${value}; domain = ${domain}; expires = ${this.getExpiredTime(
        expiry
      )}; path=\/`
    );
    document.cookie = cookieValue;
  }

  getExpiredTime(expiry: number) {
    if (expiry) {
      return new Date(expiry).toUTCString();
    } else {
      // expiredTime = current time + (hours = 12 * minutes = 60 * second = 60 * milliseconds = 1000)
      const expiredTime = new Date().getTime() + 12 * 60 * 60 * 1000;
      return new Date(expiredTime).toUTCString();
    }
  }

  /**
   * Return extra security value if the environment is not dev
   * @param value base cookie value which should has name, value, domain, and path
   */
  getCookieValue(value: string) {
    if (!value) {
      return;
    }
    return `${value}; secure; sameSite=Strict;`;
  }

  deleteCookie(value: string, domain: string = `${location.hostname}`) {
    this.document.cookie = `${value}; expires=${new Date(
      'Thu, 01 Jan 1970 00:00:01 GMT'
    )}; domain = ${domain};`;
  }

  updateAccountType(type: string) {
    userService.updateAccountType(type);
  }

  updateCompanyName(companyName: string) {
    userService.updateCompanyName(companyName);
  }

  updateIconPath(iconPath: string) {
    userService.updateIconPath(iconPath);
  }

  updateLoginURL(loginURL: string) {
    userService.updateLoginURL(loginURL);
  }

  setUserBehaviorInsightsUserData(userState: UserState) {
    this.ubiService.identify(userState?.username || userState?.userId);
    this.ubiService.addUserProperties({
      username: userState?.username,
      team: userState?.team,
      type: userState?.type,
      pAppstatus: userState?.prosimoAppStatus,
      licStatus: userState?.licenseStatus,
    } as UserBehaviorInsightsUserInfo);
  }

  /**
   * Updating all subscription types to lowercase
   * @param subscriptionTypes
   * @returns
   */
  getTransformedSubscriptionType(
    subscriptionTypes: Array<string>
  ): Array<string> {
    const subscriptionTypesCopy = [...subscriptionTypes];
    if (subscriptionTypesCopy && subscriptionTypesCopy?.length) {
      return subscriptionTypesCopy.map((subscriptionType) =>
        subscriptionType?.toLowerCase()
      );
    }
    return [];
  }
}
