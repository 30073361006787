import { Legend, LegendData } from './../legend/legend.model';
import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { TooltipData } from '../tooltip/tooltip.component';

export interface SelectedRange {
  from: any;
  to: any;
}

export interface IResizeIdentity {
  size: Array<number>;
  identity: string;
}
@Injectable({
  providedIn: 'root',
})
export class ChartService {
  private brushExtent$ = new Subject<any>();
  private selectedRange$ = new Subject<SelectedRange>();
  private tooltipData$ = new Subject<TooltipData>();
  private selectedDataPoint$ = new Subject<any>();
  private legends$ = new Subject<Legend>();
  private selectedLegend$ = new Subject<LegendData>();
  private svgDimension$ = new Subject<IResizeIdentity>();
  private selectedPathsData$ = new Subject<any>();
  public isPrintViewMode$ = new BehaviorSubject<boolean>(false);

  constructor() {}

  setPrintViewMode(state: boolean) {
    this.isPrintViewMode$.next(state);
  }

  getPrintViewMode() {
    this.isPrintViewMode$.asObservable();
  }

  setBrushExtent(selectedBrushExtent: any) {
    this.brushExtent$.next(selectedBrushExtent);
  }

  getBrushExtent(): Observable<any> {
    return this.brushExtent$.asObservable();
  }

  setSelectedRange(selectedRange: SelectedRange) {
    this.selectedRange$.next(selectedRange);
  }

  getSelectedRange(): Observable<SelectedRange> {
    return this.selectedRange$.asObservable();
  }

  setTooltipData(tooltipData: any) {
    this.tooltipData$.next(tooltipData);
  }

  getTooltipData(): Observable<TooltipData> {
    return this.tooltipData$.asObservable();
  }

  setSelectedDataPoint(selectedDataPoint: any) {
    this.selectedDataPoint$.next(selectedDataPoint);
  }

  getSelectedDataPoint(): Observable<any> {
    return this.selectedDataPoint$.asObservable();
  }

  setLegends(legend: Legend) {
    this.legends$.next(legend);
  }

  getLegends() {
    return this.legends$.asObservable();
  }

  setSelectedLegend(legendData: LegendData) {
    this.selectedLegend$.next(legendData);
  }

  getSelectedLegend(): Observable<LegendData> {
    return this.selectedLegend$.asObservable();
  }

  setSvgDimension(width: number, height: number, identity: string = '') {
    this.svgDimension$.next({
      size: [width, height],
      identity: identity,
    });
  }

  getSvgDimension() {
    return this.svgDimension$.asObservable();
  }

  setSelectedPathsData(inSelectedPathData: any) {
    this.selectedPathsData$.next(inSelectedPathData);
  }

  getSelectedPathsData(): Observable<any> {
    return this.selectedPathsData$;
  }
}
