import { NetworkCreateAction } from '@app/common/models';

export type NetworkPolicy = {
  name: string;
  policies: Array<string>;
};

export const ActionMessage: { [action in NetworkCreateAction]: string } = {
  [NetworkCreateAction.VALIDATE]: 'validated',
  [NetworkCreateAction.SAVE]: 'updated',
  [NetworkCreateAction.DEPLOY]: 'onboard started',
  [NetworkCreateAction.REDEPLOY]: 'update started',
};
