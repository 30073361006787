import { DashboardMenuConfig } from './dashboard-menu.config';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DashboardMenuService {
  private selectedMenuItem$ = new Subject<DashboardMenuConfig>();

  constructor() {}

  setSelectedMenuItem(selectedMenuItemConfig: DashboardMenuConfig) {
    this.selectedMenuItem$.next(selectedMenuItemConfig);
  }

  getSelectedMenuItem(): Observable<DashboardMenuConfig> {
    return this.selectedMenuItem$.asObservable();
  }
}
