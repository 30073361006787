export const SEARCH_FILTER = {
  TYPES: {
    AUTOCOMPLETE: 'AUTOCOMPLETE',
    RANGE: 'RANGE',
    CHECKBOX_GROUP: 'CHECKBOX_GROUP',
    SELECT_PILL: 'SELECT_PILL',
    SELECT_DROPDOWN: 'SELECT_DROPDOWN',
  },
};

export const OPERATORS_DISPLAY_NAMES = {
  EQUALS: 'Equals',
  CONTAINS: 'Contains',
  LESS_THAN: 'Less Than',
  GREATER_THAN: 'Greater Than',
  RANGE: 'Range',
  OPERATOR: 'Operator',
};
