import { LegendData } from '@app/component-common/legend/legend.model';
import {
  AppsDashboardSharedStore,
  appsDashboardSharedStore,
  IActiveTabDetails,
} from './apps-dashboard.store';

export class AppsDashboardStoreService {
  constructor(private appsDashboardStore: AppsDashboardSharedStore) {}

  updateCurrentNav(activeNavigation: any) {
    this.appsDashboardStore.update((state) => ({
      activeNavigation,
    }));
  }

  updateCurrentTabDetails(activeTabDetails: IActiveTabDetails) {
    this.appsDashboardStore.update((state) => ({ activeTabDetails }));
  }


  updateCurrentMCNTabDetails(mcnActiveTabDetails: IActiveTabDetails) {
    this.appsDashboardStore.update((state) => ({ mcnActiveTabDetails }));
  }

  updateActiveSearchTerm(activeSearchTerm: string) {
    this.appsDashboardStore.update((state) => ({ activeSearchTerm }));
  }

  updateActiveLegends(activelegends: Array<LegendData>) {
    this.appsDashboardStore.update((state) => ({ activelegends }));
  }
}

export const appsDashboardStoreService = new AppsDashboardStoreService(
  appsDashboardSharedStore
);
