import { Injectable } from '@angular/core';
import { CommonService } from '@prosimoio/services';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { CONSTANTS } from 'environments/environment';
import {
  Application,
  ApplicationQuery,
  ApplicationSearchFilter,
} from '../models';
import { ONBOARD_TYPE } from '../util/constants';
import { PAGE_SIZE_V2 } from '../http/util/constants';

@Injectable({
  providedIn: 'root',
})
export class AppService extends CommonService {
  constructor(protected httpClient: HttpClient) {
    super(httpClient, CONSTANTS.DASHBOARD_HOST);
  }

  getAllApps(): Observable<Array<Application>> {
    this.path = CONSTANTS.V2.ONBOARD.APP.BASE_PATH;
    return this.read('');
  }

  /**
   * Get all applications by pagination
   * @param status App status to filter response. Ex.: `CONFIGURED`, `DEPLOYING`, `DEPLOYED`, etc
   * @param searchTerm Search text to filter the data
   * @returns Observable for an array of all `Applications`
   */
  getApplications(
    status: Array<string>,
    searchTerm: string = ''
  ): Observable<Array<Application>> {
    let page = 0;
    return this.createData(
      CONSTANTS.APP_ONBOARD.GET_ONBOARDED_APPS,
      this.appLoadPayload(status, searchTerm, page)
    );
  }

  getAppsByPage(
    page: number,
    searchTerm: string
  ): Observable<ApplicationQuery> {
    this.path = CONSTANTS.APP_ONBOARD.GET_ONBOARDED_APPS;
    return this.create(
      this.appLoadPayload([], searchTerm, page * PAGE_SIZE_V2)
    ).pipe(take(1));
  }

  private appLoadPayload(
    status: Array<string>,
    searchTerm: string,
    page: number
  ): ApplicationSearchFilter {
    return {
      page: {
        size: PAGE_SIZE_V2,
        start: page,
      },
      value: searchTerm,
      statusFilter: status,
      onboardType: [ONBOARD_TYPE.BEHIND_FABRIC, ONBOARD_TYPE.BOTH],
    } as ApplicationSearchFilter;
  }
}
