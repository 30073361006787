export class CommonModelSerializer {
  fromJson(data: any) {
    return data.data ? data.data : data;
  }

  toJson(data: any): any {
    return JSON.stringify(data);
  }

  toJsonArray(data: any): any {
    return data;
  }
}

export class FileUploadModelSerializer {
  fromJson(data: any) {
    return data.data ? data.data : data;
  }

  toJson(payload: any): any {
    const certificate = payload.file_certificate;
    const privateKey = payload.file_private_key;
    const formData = new FormData();
    if (certificate) {
      formData.append('certificate', certificate, certificate.name);
    }
    if (privateKey) {
      formData.append('privateKey', privateKey, privateKey.name);
    }
    return formData;
  }

  toJsonArray(data: any): any {
    return data;
  }
}
