import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { BadgeConfig } from './badge.model';
import { STATUS_CONSTANTS } from '@prosimoio/components';
@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent implements OnInit {
  _badgeConfig: BadgeConfig;
  get badgeConfig(): BadgeConfig {
    return this._badgeConfig;
  }
  @Input() set badgeConfig(config: BadgeConfig) {
    this._badgeConfig = config;
  }

  activeCSS: string[] = [
    STATUS_CONSTANTS.VALID,
    STATUS_CONSTANTS.SUCCESS,
    STATUS_CONSTANTS.ACTIVE,
    STATUS_CONSTANTS.ENABLED,
    STATUS_CONSTANTS.DEPLOYED,
    STATUS_CONSTANTS.APPROVED,
  ];
  errorCSS: string[] = [
    STATUS_CONSTANTS.ERROR,
    STATUS_CONSTANTS.EXPIRED,
    STATUS_CONSTANTS.FAILED,
    STATUS_CONSTANTS.REJECTED,
  ];
  notUsedCSS: string[] = [
    STATUS_CONSTANTS.EXPIRE_SOON,
    STATUS_CONSTANTS.INACTIVE,
    STATUS_CONSTANTS.DISABLED,
    STATUS_CONSTANTS.INVALID,
    STATUS_CONSTANTS.IN_PROGRESS,
    STATUS_CONSTANTS.PENDING,
    STATUS_CONSTANTS.DEPLOYING,
    STATUS_CONSTANTS.DECOMMISSIONING,
  ];
  configuredCSS: string[] = [STATUS_CONSTANTS.CONFIGURED];
  incompleteCSS: string[] = [STATUS_CONSTANTS.INCOMPLETE];
  constructor() {}

  ngOnInit(): void {}
}
