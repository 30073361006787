import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { AppUtil } from '@app/common/util/app-util';
import { COLORS, FILTER_TYPES } from '@app/common/util/constants';
import { ALL } from '@app/dashboard/dashboard.constants';
import { MCNInsightsSharedFilterState } from '@app/dashboard/state/mcn-insights-filters';

@Component({
  selector: 'app-mcn-search-history-filter',
  templateUrl: './mcn-search-history-filter.component.html',
  styleUrls: ['./../search-history-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MCNSearchHistoryFilterComponent implements OnInit {
  @Input() recentFilterIcons;
  @Output() deleteFilter = new EventEmitter();
  @Output() applyFilter = new EventEmitter();
  totalSources = 0;
  totalTargets = 0;
  filterDataConfig: any;
  @Input() set filterData(data) {
    this.totalSources = 0;
    this.totalTargets = 0;
    this.filterDataConfig = data;
    this.aggregateSourceAndTargetFilters(data);
  }
  filterDisplayIcons = {};
  COLOR = COLORS;
  FILTER_TYPE = FILTER_TYPES;
  selectedIndex: number;
  COPY_ALL = ALL;
  constructor(private appUtil: AppUtil) {}

  ngOnInit() {}

  aggregateSourceAndTargetFilters(filterData) {
    const {
      selectedSourceNetworks,
      selectedSourceNetworkGroups,
      selectedSourcePrivateLinks,
      selectedSourceRegions,
      selectedTargetNetworks,
      selectedTargetNetworkGroups,
      selectedOnboardedApps,
      selectedTargetRegions,
    } = filterData;
    const mcnInsightsFilterState: MCNInsightsSharedFilterState =
      this.appUtil.getMCNDashboardFilters();
    const {
      totalSourceNetworks = 0,
      totalSourceNetworkGroups = 0,
      totalSourcePrivateLinks = 0,
      totalSourceRegions = 0,
      totalTargetNetworks = 0,
      totalTargetRegions = 0,
      totalTargetNetworkGroups = 0,
      totalOnboardedApps = 0,
    } = mcnInsightsFilterState;

    if (
      this.appUtil.isMasterSelectAllRecords(
        selectedSourceNetworks,
        FILTER_TYPES.SRC_NETWORKS
      )
    ) {
      this.totalSources += totalSourceNetworks;
    } else {
      this.totalSources += selectedSourceNetworks.length;
    }
    if (
      this.appUtil.isMasterSelectAllRecords(
        selectedSourceNetworkGroups,
        FILTER_TYPES.SRC_NETWORK_GROUPS
      )
    ) {
      this.totalSources += totalSourceNetworkGroups;
    } else {
      this.totalSources += selectedSourceNetworkGroups.length;
    }
    if (
      this.appUtil.isMasterSelectAllRecords(
        selectedSourcePrivateLinks,
        FILTER_TYPES.SRC_PRIVATE_LINKS
      )
    ) {
      this.totalSources += totalSourcePrivateLinks;
    } else {
      this.totalSources += selectedSourcePrivateLinks.length;
    }
    if (
      this.appUtil.isMasterSelectAllRecords(
        selectedSourceRegions,
        FILTER_TYPES.SRC_REGIONS
      )
    ) {
      this.totalSources += totalSourceRegions;
    } else {
      this.totalSources += selectedSourceRegions.length;
    }

    if (
      this.appUtil.isMasterSelectAllRecords(
        selectedTargetNetworks,
        FILTER_TYPES.TGT_NETWORKS
      )
    ) {
      this.totalTargets += totalTargetNetworks;
    } else {
      this.totalTargets += selectedTargetNetworks.length;
    }
    if (
      this.appUtil.isMasterSelectAllRecords(
        selectedTargetNetworkGroups,
        FILTER_TYPES.TGT_NETWORK_GROUPS
      )
    ) {
      this.totalTargets += totalTargetNetworkGroups;
    } else {
      this.totalTargets += selectedTargetNetworkGroups.length;
    }
    if (
      this.appUtil.isMasterSelectAllRecords(
        selectedOnboardedApps,
        FILTER_TYPES.APP
      )
    ) {
      this.totalTargets += totalOnboardedApps;
    } else {
      this.totalTargets += selectedOnboardedApps.length;
    }
    if (
      this.appUtil.isMasterSelectAllRecords(
        selectedTargetRegions,
        FILTER_TYPES.TGT_REGIONS
      )
    ) {
      this.totalTargets += totalTargetRegions;
    } else {
      this.totalTargets += selectedTargetRegions.length;
    }
  }
  onSearchHistoryFilterDelete(value: string) {
    this.deleteFilter.emit(value);
  }

  onSearchHistoryFilterApply(filterData: any) {
    this.applyFilter.emit(filterData);
  }
}
