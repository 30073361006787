export interface StepState {
  isComplete: boolean;
  activeStateColor: string;
  inActiveStateColor: string;
}
export class StepperConfig {
  info: string; // details about the step . used in the tooltip input in UI
  label: string; // name for the step
  isComplete: boolean;
  isCurrent: boolean;
  isDisabled?: boolean;
  showStepConnector: boolean;
  order: number;
  bgGraphicPath: string;
  name: string;
  route: string;
  isHide: boolean;

  constructor() {
    this.info = '';
    this.label = '';
    this.isComplete = false;
    this.isDisabled = false;
    this.showStepConnector = false;
    this.order = 0;
    this.bgGraphicPath = '';
    this.isCurrent = false;
    this.isComplete = false;
    this.name = '';
    this.route = '';
    this.isHide = false;
  }

  init(): StepperConfig {
    return {
      info: '',
      label: '',
      isComplete: false,
      showStepConnector: false,
      order: 0,
      bgGraphicPath: '',
      isCurrent: false,
      name: '',
      route: '',
      isHide: false,
    } as StepperConfig;
  }
}
