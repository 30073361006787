import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceService } from '@prosimoio/services';
import { CONSTANTS } from 'environments/environment';
import { CommonModelSerializer } from '../http/service/common.model.serializer';
import { EMPTY, Observable } from 'rxjs';
import {
  Namespace,
  NamespaceResponse,
  NetworkSearchFilter,
} from '@app/common/models';
import { PAGE_SIZE_V2 } from '../http/util/constants';
import { expand, reduce, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NamespaceService extends ResourceService<any> {
  constructor(protected httpClient: HttpClient) {
    super(
      httpClient,
      CONSTANTS.DASHBOARD_HOST,
      '',
      new CommonModelSerializer()
    );
  }

  getNamespaces(
    status: Array<string>,
    searchTerm: string = ''
  ): Observable<Array<Namespace>> {
    let pageStart = 0;
    this.path = CONSTANTS.NETWORK_ONBOARD.GET_NAMESPACES_LIST;
    return this.create(
      this.getNamespacePayload(status, searchTerm, pageStart)
    ).pipe(
      expand((response: NamespaceResponse) => {
        pageStart += PAGE_SIZE_V2;
        if (pageStart < response.totalCount) {
          this.path = CONSTANTS.NETWORK_ONBOARD.GET_NAMESPACES_LIST;
          return this.create(
            this.getNamespacePayload(status, searchTerm, pageStart)
          );
        } else {
          return EMPTY;
        }
      }),
      reduce((acc: Array<Namespace>, val: NamespaceResponse) => {
        if (val?.namespaces) {
          return acc.concat(val.namespaces);
        }
      }, [] as Array<Namespace>),
      take(1)
    );
  }

  getNamespacePayload(
    status: Array<string>,
    searchTerm: string,
    pageStart: number
  ): NetworkSearchFilter {
    return {
      page: {
        size: PAGE_SIZE_V2,
        start: pageStart,
      },
      value: searchTerm,
      statusFilter: status,
    } as NetworkSearchFilter;
  }
}
