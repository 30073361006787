import {
  UI_PATHS_TO_SKIP,
  UI_THEME_MODES,
} from './../common/util/constants';
import { PageConfig } from '@app/common/util/constants';
import { pDashQuery } from './web-pdash.query';
import { pDashService } from './web-pdash.service';
import { Observable } from 'rxjs';
import { pDashStore } from './web-pdash.store';
import { ObjectUtil } from '@prosimoio/services';
import { CONSTANTS } from 'environments/environment';

export class PDashLocalStoreUtil {
  static localStoreRef: any;

  static setLocalStoreInst(localStoreInst: any) {
    PDashLocalStoreUtil.localStoreRef = localStoreInst;
  }

  static getLocalStoreInst() {
    return PDashLocalStoreUtil.localStoreRef;
  }

  static getPDashLocalStoreConfig(): PageConfig {
    return pDashQuery.getValue().pageInfo;
  }

  static getPageInfo(): Observable<any> {
    return pDashQuery.select();
  }

  static getHelpConfig(): Observable<any> {
    return pDashStore.getValue().helpConfig;
  }

  static getRegionConfig(): any {
    return pDashStore.getValue().regionConfig;
  }

  static getPageConfig(pageName: string): PageConfig {
    if (!pageName) {
      return {};
    }
    let pageConfig = {} as any;
    const helpConfig = pDashStore.getValue().helpConfig;
    if (helpConfig) {
      pageConfig = ObjectUtil.extractKeyValueFromNestedObject(
        helpConfig,
        pageName
      );
      const parentKey = pageConfig?.module?.parent?.key;
      const parentConfig = ObjectUtil.extractKeyValueFromNestedObject(
        helpConfig,
        parentKey
      );
      if (parentConfig?.ROOT) {
        const parentRootConfig = parentConfig['ROOT'];
        pageConfig = ObjectUtil.deepClone(pageConfig);
        Object.assign(pageConfig, { rootConfig: parentRootConfig });
      }
    }
    return pageConfig ? pageConfig : {};
  }

  static updatePageConfig(pageConfig: PageConfig) {
    pDashService.updatePageInfo(pageConfig);
  }

  static updateHelpConfig(pageConfig: any) {
    pDashService.updateHelpConfig(pageConfig);
  }

  static updateRegionConfig(regionConfig: any) {
    pDashService.updateRegionConfig(regionConfig);
  }

  static updateRequestedURL(inRequestedURL: string) {
    if (
      [CONSTANTS.UI_PATHS.LOGIN, `${CONSTANTS.UI_PATHS.LOGIN}/`].includes(
        inRequestedURL
      )
    ) {
      return;
    }
    const requestedURLPaths = inRequestedURL
      ? inRequestedURL.split('/')
      : location.pathname.split('/');
    const matchedPath = requestedURLPaths.find((path) =>
      UI_PATHS_TO_SKIP.REQUESTED_URL_LOCAL_STORE.includes(path)
    );
    pDashService.updateRequestedUrl(
      inRequestedURL === matchedPath
        ? CONSTANTS.UI_PATHS.MAIN_DASHBOARD
        : inRequestedURL
    );
  }

  static updateProductViewType(productViewType: string) {
    pDashService.updateProductViewType(productViewType);
  }

  static updateUIThemeMode(uiThemeMode: UI_THEME_MODES) {
    pDashService.updateUIThemeMode(uiThemeMode);
  }

  static getRequestedURL(): string {
    return pDashStore.getValue().requestedUrl;
  }

  static updateIsInteractiveTourActive(isTourActive: boolean) {
    pDashService.updateIsInteractiveGuideOn(isTourActive);
  }

  static getIsInteractiveTourActive(): boolean {
    return pDashStore.getValue()?.isInteractiveGuideOn;
  }

  static updateIsPDFPrintView(isPDFPrintView: boolean) {
    pDashService.updateIsPDFPrintView(isPDFPrintView);
  }

  static getIsPDFPrintView(): boolean {
    return pDashStore.getValue()?.isPDFPrintView;
  }

  static getProductViewType(): string {
    return pDashStore.getValue()?.productViewType;
  }

  static getUIThemeMode(): UI_THEME_MODES {
    return pDashStore.getValue().uiThemeMode;
  }

  static getUIThemeClass(uiThemeMode: string): string {
    return `mat-panel__bg--${uiThemeMode.toLowerCase()}-theme-light`;
  }

  static getProductViewTypeAsObservable(): Observable<string> {
    return pDashQuery.productViewType;
  }

  static getUIThemeModeAsObservable(): Observable<UI_THEME_MODES> {
    return pDashQuery.uiThemeMode;
  }
}
