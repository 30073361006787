import { Pipe, PipeTransform } from '@angular/core';
import { DashboardMenuConfig } from '../dashboard-menu/dashboard-menu.config';

@Pipe({
  name: 'selectedSubMenu',
})
export class DashboardSubMenuPipe implements PipeTransform {
  transform(
    menuConfigs: Array<DashboardMenuConfig>,
    index: number = 0
  ): unknown {
    return menuConfigs.map((config, i) => ({
      ...config,
      isSelected: i === index,
    }));
  }
}
