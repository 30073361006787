import { QueryEntity } from '@datorama/akita';
import { PlaygroundRecentSearch } from './playground-recent-search.model';
import {
  PlaygroundRecentSearchState,
  PlaygroundRecentSearchStore,
  playgroundRecentSearchStore,
} from './playground-recent-search.store';

export class PlaygroundRecentSearchQuery extends QueryEntity<
  PlaygroundRecentSearchState,
  PlaygroundRecentSearch
> {
  constructor(protected store: PlaygroundRecentSearchStore) {
    super(store);
  }
}

export const playgroundRecentSearchQuery = new PlaygroundRecentSearchQuery(
  playgroundRecentSearchStore
);
