<div class="d-flex" *ngIf="!isSimpleSelect">
  <div
    id="autocomplete__container"
    [ngClass]="{ 'read-only': readOnly, 'w-100': true }"
  >
    <mat-form-field class="example-chip-list autocomplete-form-field">
      <!-- <div class="form__field-label">{{config?.label}}</div> -->
      <mat-chip-list #chipList aria-label="options selection">
        <mat-chip
          *ngFor="let option of selectedOptions; index as i"
          [matTooltip]="option[targetKey] || option"
          [ngClass]="{ 'autocomplete-pill': true, 'd-none': hideSelection }"
          [selectable]="selectable"
          [removable]="removable"
          (removed)="remove(option, i)"
        >
          <div class="d-flex">
            <i
              matChipRemove
              *ngIf="removable"
              class="material-icons autocomplete-pill-close"
            >
              close
            </i>
          </div>
          {{ option[targetKey] || option }}
        </mat-chip>
        <input
          #autoInput
          #trigger="matAutocompleteTrigger"
          class="autocomplete-input"
          [placeholder]="config?.label"
          [formControl]="inputCtrl"
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur && config?.allowEntry"
          (matChipInputTokenEnd)="add($event)"
          (blur)="addOptionOnBlur()"
          (focus)="onFocus($event)"
        />
      </mat-chip-list>
      <mat-hint>
        <span class="hint-error" *ngIf="inputCtrl?.errors?.duplicate">
          This value is already present
        </span>
        <span
          class="hint-error"
          *ngIf="inputCtrl?.errors?.pattern && !inputCtrl?.errors?.minlength"
        >
          Invalid value
        </span>
        <span class="hint-error" *ngIf="inputCtrl?.errors?.minlength">
          name must be 3 or more characters
        </span>
        <span class="hint-error" *ngIf="inputCtrl?.errors?.maxlength">
          name must be not more than 255 characters
        </span>
        <span
          *ngIf="errorText"
          class="hint-error"
          [innerHTML]="errorText | safe : 'html'"
        ></span>
      </mat-hint>
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="selected($event)"
        class="mat-autocomplete--{{ uiThemeMode }}-light range--{{
          uiThemeMode
        }}-light"
      >
        <ng-container *ngIf="displayCheckbox">
          <mat-option
            *ngIf="isSelectAllOption"
            class="cursor-auto ellipsis"
            (click)="toggleSelectAll()"
          >
            <mat-checkbox
              color="primary"
              [checked]="isAllSelected"
              [value]="isAllSelected"
              (change)="toggleSelectAll()"
              (click)="$event.stopPropagation()"
              >All</mat-checkbox
            >
          </mat-option>
          <mat-option
            *ngFor="let option of filteredOptions$ | async"
            [value]="option"
            matTooltip="{{ option[targetKey] || option }}"
            [matTooltipClass]="tooltipThemeClass"
          >
            <mat-checkbox
              color="primary"
              [checked]="selectedOptionsMap.has(option[uniqueKey])"
              [disabled]="option?.isDisabled"
              [value]="option"
              (change)="multiSelectOptionChanged($event)"
              (click)="$event.stopPropagation()"
            >
              {{ option[targetKey] || option }}
            </mat-checkbox>
          </mat-option>
        </ng-container>
        <ng-container *ngIf="!displayCheckbox">
          <mat-option
            class="ellipsis"
            *ngFor="let option of filteredOptions$ | async"
            [value]="option"
            [matTooltipClass]="tooltipThemeClass"
            matTooltip="{{
              displayOptionTooltip ? option[targetKey] || option : ''
            }}"
          >
            {{ option[targetKey] || option }}
          </mat-option>
        </ng-container>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div *ngIf="showAddButton" (click)="addOption()">
    <mat-icon color="primary" class="material-icons cursor-pointer px-4">
      add_circle
    </mat-icon>
  </div>
</div>
<div
  *ngIf="isSimpleSelect"
  id="autocomplete__container"
  [ngClass]="{ 'read-only': readOnly }"
>
  <mat-form-field class="autocomplete-form-field">
    <input
      #autoInput
      #trigger="matAutocompleteTrigger"
      class="autocomplete-input"
      [placeholder]="config?.label"
      [formControl]="inputCtrl"
      matInput
      autocomplete="off"
      [matAutocomplete]="auto"
      (blur)="onSimpleSelectBlur($event)"
    />

    <mat-hint *ngIf="errorText">
      <span class="hint-error" [innerHTML]="errorText | safe : 'html'"></span>
    </mat-hint>

    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="selected($event)"
      class="mat-autocomplete--{{ uiThemeMode }}-light range--{{
        uiThemeMode
      }}-light"
    >
      <ng-container *ngIf="displayCheckbox">
        <mat-option
          *ngFor="let option of filteredOptions$ | async"
          [value]="option"
          matTooltip="{{
            displayOptionTooltip ? option[targetKey] || option : ''
          }}"
        >
          <div class="d-flex justify-content-start align-items-center">
            <i
              class="material-icons option-checkbox"
              [ngStyle]="{
                'font-size': '22px',
                'vertical-align': 'middle',
                color: '#4985e3'
              }"
            >
              {{
                selectedOptionsMap.has(option[uniqueKey])
                  ? "check_box"
                  : "check_box_outline_blank"
              }}
            </i>
            <div
              class="option-txt pl-2"
              [ngStyle]="{
                'font-size': '12px',
                color: '#404245'
              }"
            >
              {{ option[targetKey] || option }}
            </div>
          </div>
        </mat-option>
      </ng-container>
      <ng-container *ngIf="!displayCheckbox">
        <mat-option
          *ngFor="let option of filteredOptions$ | async"
          [value]="option"
          matTooltip="{{
            displayOptionTooltip ? option[targetKey] || option : ''
          }}"
        >
          {{ option[targetKey] || option }}
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
</div>
