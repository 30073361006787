import { STEP_TYPES } from './../../common/util/constants';
import { StepperConfig } from './stepper.config';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StepperService {
  private stepperConfig$ = new BehaviorSubject<Array<StepperConfig>>([
    new StepperConfig().init(),
  ]);
  private currentStep$ = new BehaviorSubject<StepperConfig>(
    new StepperConfig().init()
  );

  constructor() {}

  setStepperConfig(stepperConfig: Array<StepperConfig>) {
    this.stepperConfig$.next(stepperConfig);
  }

  getStepperConfig(): Observable<Array<StepperConfig>> {
    return this.stepperConfig$.asObservable();
  }

  getStepperConfigObj() {
    return this.stepperConfig$.getValue();
  }

  setCurrentStep(stepperConfig: StepperConfig) {
    const currentStep = Object.assign({}, stepperConfig);
    currentStep.isCurrent = true;
    this.currentStep$.next(currentStep);
  }

  getCurrentStep(): Observable<StepperConfig> {
    return this.currentStep$.asObservable();
  }

  setNextStep(stepType: string, stepName: string = '') {
    const currentStep = Object.assign({}, this.currentStep$.value);
    const stepperConfigs = this.stepperConfig$.value;
    let nextStep;
    if (stepName) {
      if (
        stepType &&
        stepType === STEP_TYPES.NEXT &&
        currentStep.order !== stepperConfigs.length &&
        stepName
      ) {
        nextStep = this.stepperConfig$.value.find((step) => {
          return step.name === stepName;
        });
      }
    } else {
      if (stepType && stepType === STEP_TYPES.PREV && currentStep.order !== 1) {
        nextStep = this.stepperConfig$.value.find((step) => {
          return step.order === currentStep.order - 1;
        });
      }

      if (
        stepType &&
        stepType === STEP_TYPES.NEXT &&
        currentStep.order !== stepperConfigs.length
      ) {
        nextStep = this.stepperConfig$.value.find((step) => {
          const { order, isDisabled } = step;
          return order > currentStep?.order && !isDisabled;
        });
      }
    }

    if (nextStep) {
      nextStep.isCurrent = true;
      this.setCurrentStep(nextStep);
    }
  }

  resetCurrentStep() {
    this.currentStep$ = new BehaviorSubject<StepperConfig>(
      new StepperConfig().init()
    );
  }
}
