import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CONSTANTS } from 'environments/environment';
import { ResourceService } from '@app/common/http/util/resource.service';
import { PasswordModelSerializer } from './password.model.serializer';
import { PasswordModel } from './password.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PasswordService extends ResourceService<PasswordModel> {
  constructor(protected httpClient: HttpClient) {
    super(
      httpClient,
      CONSTANTS.DASHBOARD_HOST,
      CONSTANTS.SIGN_UP,
      new PasswordModelSerializer()
    );
  }

  updatePassword(passwordDetails: PasswordModel): Observable<PasswordModel> {
    this.path = CONSTANTS.SIGN_UP;
    return this.update(passwordDetails);
  }

  resetPassword(
    passwordDetails: PasswordModel,
    token: string
  ): Observable<PasswordModel> {
    /**
     *
     * Server side end-point expectes only the token in the URL path. Resetting id to prevent attaching it in the URL path.
     */
    passwordDetails.id = '';
    this.path = `${CONSTANTS.PASSWORD_RESET}/${token}`;
    return this.update(passwordDetails);
  }

  verifyEmailForPasswordReset(username) {
    this.path = CONSTANTS.PASSWORD.RESET;
    return this.create(username);
  }

  verifyToken(token: string) {
    this.path = CONSTANTS.PASSWORD_VERIFICATION(token);
    return this.read('');
  }

  getTeamNames(userDetails: PasswordModel): Observable<PasswordModel> {
    this.path = CONSTANTS.PASSWORD.POST;
    return this.create(userDetails);
  }
  getDashboardURLSuffix(userID: string): Observable<PasswordModel> {
    this.path = CONSTANTS.PASSWORD.GET_DASHBOARD_URL_SUFFIX(userID);
    return this.read('');
  }
}
