<div
  class="profile-section d-flex flex-column align-items-center justify-content-center px-3"
>
  <p class="section__title">YOUR PROFILE</p>
  <div>
    <img class="profile__pic" [src]="USER_PROFILE_URL" alt="user-profile" />
  </div>
  <div class="heading">
    <span>{{ UserName }}</span>
  </div>
  <span class="heading">Role</span>
  <div class="role">
    <div *ngFor="let role of roles" class="role__pill">
      <app-pill
        [value]="role.name"
        [borderColor]="COLOR.GREY2"
        [fontSize]="'10px'"
        [textColor]="COLOR.GREY2"
      >
      </app-pill>
    </div>
  </div>
  <span class="heading">Team</span>
  <div class="role">
    <app-pill
      [value]="team"
      [borderColor]="COLOR.GREY2"
      [fontSize]="'10px'"
      [textColor]="COLOR.GREY2"
    >
    </app-pill>
  </div>
</div>
