<div class="d-flex flex-column checkbox-group-container">
  <div
    class="d-flex justify-content-start align-items-center py-1 checkbox-wrapper"
    *ngFor="let config of checkboxesConfig; index as i"
    (click)="toggleSelection(config, i)"
  >
    <i class="material-icons checkbox">
      {{
        selectionSet?.has(config.value)
          ? "check_box"
          : "check_box_outline_blank"
      }}</i
    >
    <span class="checkbox-label pl-2">
      {{ config.displayName }}
    </span>
  </div>
</div>
