<div
  class="filter-card_container bg-light-shade bdr-grey3"
  [ngClass]="{
    selected: filterDataConfig?.isSelected
  }"
>
  <div class="d-flex row">
    <div
      class="filter-content col-12"
      (click)="onSearchHistoryFilterApply(filterDataConfig)"
    >
      <div class="ml-3">
        <div class="d-flex flex-wrap mt-2 align-items-center">
          <div class="d-flex">
            <div class="text-count">{{ totalSources }}</div>
            <div class="text-label ml-1">Sources</div>
          </div>
          <div class="d-flex ml-2">
            <div class="text-count">{{ totalTargets }}</div>
            <div class="text-label ml-1">Targets</div>
          </div>
        </div>
        <div class="d-flex align-items-center mt-2 mb-2">
          <div class="d-flex">
            <div class="text-label">
              <div class="d-flex">
                <div class="timerange-header d-flex justify-content-between">
                  <div><b>From</b></div>
                  <div><b>:</b></div>
                </div>
                <div class="ml-2">
                  {{
                    filterDataConfig?.timeRange?.from
                      | date : "MM/dd/yy hh:mm:ss a"
                  }}
                </div>
              </div>
              <div class="mt-1 d-flex">
                <div class="timerange-header d-flex justify-content-between">
                  <div><b>To</b></div>
                  <div><b>:</b></div>
                </div>
                <div class="ml-2">
                  {{
                    filterDataConfig?.timeRange?.to
                      | date : "MM/dd/yy hh:mm:ss a"
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="position-absolute btn-delete">
      <div
        class="d-flex mt-1 w-100 h-100 justify-content-center align-items-center"
        (click)="onSearchHistoryFilterDelete(filterDataConfig?.id)"
      >
        <span
          class="btn-delete__icon"
          matTooltip="Remove filter from search history"
          >&times;</span
        >
      </div>
    </div>
  </div>
</div>
