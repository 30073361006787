<div id="app-time-filter">
  <div
    class="filters-header time-selector-header px-4 py-2"
    *ngIf="showPanelHeader"
  >
    Time
  </div>
  <!-- date time filter -->
  <div class="date-time-filter px-4 py-3">
    <div class="mb-3">
      <app-time-picker
        [label]="timePickerLabel"
        [hour]="selectedTime?.hour"
        [minute]="selectedTime?.minutes"
        [seconds]="selectedTime?.seconds"
        (selectionChange)="onChangeTime($event)"
      ></app-time-picker>
    </div>

    <ng-container *ngIf="includeAdditionalDateFilters">
      <div
        *ngFor="let customDate of additionalTimeFilters; index as i"
        class="col-0 py-2 radio-wrapper d-flex align-items-center"
        (click)="onCustomDateChangeDate(customDate)"
      >
        <i class="material-icons radio-btn" *ngIf="customDate?.value !== -1">
          {{
            selectedCustomDate === customDate?.value
              ? "radio_button_checked"
              : "radio_button_unchecked"
          }}
        </i>
        <span
          [ngClass]="{
            'px-2 radio-label': true,
            'font-italic': customDate?.value === -1
          }"
        >
          <pcomp-tooltip-lite>
            <span content>
              {{ customDate?.displaName }}
            </span>
            <span
              tooltip
              class="px-2"
              [innerHTML]="
                getTimeTooltip
                  | funcCaller
                    : customDate?.value
                    : datePipe
                    : dateFormats.FORMAT_DAY_MON_DATE_YR_TIME
                  | safe: 'html'
              "
            >
            </span>
          </pcomp-tooltip-lite>
        </span>
      </div>
    </ng-container>

    <div
      *ngFor="let date of dates; index as i"
      class="col-0 py-2 radio-wrapper d-flex align-items-center"
      (click)="onChangeDate(date)"
    >
      <i class="material-icons radio-btn" *ngIf="date !== -1">
        {{
          (selectedDate | date: dateFormats?.FORMAT_DAY_MON_DATE_YR) ===
          (date | date: dateFormats?.FORMAT_DAY_MON_DATE_YR)
            ? "radio_button_checked"
            : "radio_button_unchecked"
        }}
      </i>
      <span
        [ngClass]="{
          'px-2 radio-label': true,
          'font-italic': date === -1
        }"
      >
        <pcomp-tooltip-lite>
          <span content>
            {{
              date === -1
                ? getCurrentDateTime()
                : (date | date: dateFormats?.FORMAT_DAY_MON_DATE_YR)
            }}
          </span>
          <span
            tooltip
            class="px-2"
            [innerHTML]="
              getTimeTooltip
                | funcCaller
                  : date
                  : datePipe
                  : dateFormats.FORMAT_DAY_MON_DATE_YR_TIME
                | safe: 'html'
            "
          >
          </span>
        </pcomp-tooltip-lite>
      </span>
    </div>
  </div>
</div>
