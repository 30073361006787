import { map, distinctUntilChanged } from 'rxjs/operators';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import { TabService } from './tab.service';
import { Observable } from 'rxjs';

export interface TabConfig {
  order: number;
  label: string;
  isActive: boolean;
  key: string;
  isShowIcon?: boolean,
  selectedIconPath?: string;
  deselectedIconPath?: string;
}

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent implements OnInit, OnDestroy {
  configs$: Observable<Array<TabConfig>>;
  subscriptions = [];

  constructor(private tabService: TabService) {
    this.configs$ = this.tabService.getTabConfigs().pipe(
      distinctUntilChanged(),
      map((configs) => configs)
    );
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }

  setSelectedTab(selectedTabConfig: TabConfig) {
    this.tabService.setSelectedTab(selectedTabConfig);
  }
}
