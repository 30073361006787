import { Status } from './../common/components/status/status.model';
import { CONSTANTS } from './../../environments/environment';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { AccountActivationService } from './account-activation.service';
import { ReCaptchaV3Service } from 'ngx-captcha';
import {
  Validators,
  UntypedFormGroup,
  UntypedFormBuilder,
} from '@angular/forms';
import {
  ALERT_TYPES,
  IMAGE_PATHS,
  MESSAGES,
  EMAIL_ACTIVATION_STATUS,
  REGEX,
} from '@app/common/util/constants';
import { StatusService } from '@app/common/components/status/status.service';
import { AppUtil } from '@app/common/util/app-util';
import { AlertModel } from '@app/component-common/alert/alert.model';

@Component({
  selector: 'app-account-activation',
  templateUrl: './account-activation.component.html',
  styleUrls: ['./account-activation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountActivationComponent implements OnInit, OnDestroy {
  activationToken: any;
  email: string;
  activationFormDisplayStatus$ = new BehaviorSubject<boolean>(false);
  requestTokenForm: UntypedFormGroup;

  public captchaConfig = {
    siteKey: CONSTANTS.CAPTCHA_SITE_KEY,
    size: 'normal',
    lang: 'en',
    theme: 'light',
    type: 'image',
    useGlobalDomain: 'false',
  };
  reCaptchaToken = '';

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private accountActivationService: AccountActivationService,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private statusService: StatusService,
    private appUtil: AppUtil
  ) {}

  ngOnInit() {
    this.setActivationStatus('INIT');

    this.route.params.pipe(take(1)).subscribe((params) => {
      this.activationToken = params && params.token ? params.token : '';
      this.email = params && params.email ? params.email : '';
    });

    this.reCaptchaV3Service.execute(
      this.captchaConfig.siteKey,
      'prosimo',
      (token) => {
        this.reCaptchaToken = token;
        this.activateAccount();
      }
    );

    this.initRequestTokenForm();
  }

  activateAccount() {
    this.accountActivationService
      .activateAccount(this.activationToken, this.reCaptchaToken)
      .subscribe(
        (data) => {
          this.setActivationStatus(EMAIL_ACTIVATION_STATUS.THANK_YOU);
        },
        ({ error }) => {
          if (
            error &&
            error.statusCode === EMAIL_ACTIVATION_STATUS.DONE_ALREADY.CODE
          ) {
            this.setActivationStatus(EMAIL_ACTIVATION_STATUS.DONE_ALREADY.NAME);
          } else if (
            error &&
            error.statusCode === EMAIL_ACTIVATION_STATUS.EXPIRED.CODE
          ) {
            this.setActivationStatus(EMAIL_ACTIVATION_STATUS.EXPIRED.NAME);
          } else {
            this.setActivationStatus(EMAIL_ACTIVATION_STATUS.ERROR);
          }
        }
      );
  }

  setActivationStatus(activationStatus: string): void {
    switch (activationStatus.toUpperCase()) {
      case 'INIT':
        return this.statusService.setStatus({
          img: {
            src: IMAGE_PATHS.ICON.MAIL_ENVELOPE,
            style: {
              width: MESSAGES.EMAL_VERIFICATION.INIT.STYLE.WIDTH,
              height: MESSAGES.EMAL_VERIFICATION.INIT.STYLE.HEIGHT,
            },
          },
          statusMsg1: MESSAGES.EMAL_VERIFICATION.INIT.MESSAGE1,
          statusMsg2: MESSAGES.EMAL_VERIFICATION.INIT.MESSAGE2,
        } as Status);
      case 'THANK_YOU':
        return this.statusService.setStatus({
          img: {
            src: IMAGE_PATHS.ICON.THANK_YOU,
            style: {
              width: MESSAGES.EMAL_VERIFICATION.THANK_YOU.STYLE.WIDTH,
              height: MESSAGES.EMAL_VERIFICATION.THANK_YOU.STYLE.HEIGHT,
            },
          },
          statusMsg1: MESSAGES.EMAL_VERIFICATION.THANK_YOU.MESSAGE1,
          statusMsg2: MESSAGES.EMAL_VERIFICATION.THANK_YOU.MESSAGE2,
        } as Status);
      case 'EXPIRED':
        this.activationFormDisplayStatus$.next(true);
        return this.statusService.setStatus({
          img: {
            src: IMAGE_PATHS.ICON.VERIFICATION_FAILURE,
            style: {
              width: MESSAGES.EMAL_VERIFICATION.EXPIRED.STYLE.WIDTH,
              height: MESSAGES.EMAL_VERIFICATION.EXPIRED.STYLE.WIDTH,
            },
          },
          statusMsg1: MESSAGES.EMAL_VERIFICATION.EXPIRED.MESSAGE1,
          statusMsg2: MESSAGES.EMAL_VERIFICATION.EXPIRED.MESSAGE2,
        } as Status);
      case 'DONE_ALREADY':
        return this.statusService.setStatus({
          img: {
            src: IMAGE_PATHS.ICON.MAIL_ENVELOPE,
            style: {
              width: MESSAGES.EMAL_VERIFICATION.DONE_ALREADY.STYLE.WIDTH,
              height: MESSAGES.EMAL_VERIFICATION.DONE_ALREADY.STYLE.HEIGHT,
            },
          },
          statusMsg1: MESSAGES.EMAL_VERIFICATION.DONE_ALREADY.MESSAGE1,
          statusMsg2: '',
        } as Status);
      case 'ERROR':
      default:
        return this.statusService.setStatus({
          img: {
            src: IMAGE_PATHS.ICON.ERROR,
            style: {
              width: MESSAGES.EMAL_VERIFICATION.ERROR.STYLE.WIDTH,
              height: MESSAGES.EMAL_VERIFICATION.ERROR.STYLE.HEIGHT,
            },
          },
          statusMsg1: MESSAGES.EMAL_VERIFICATION.ERROR.MESSAGE1,
          statusMsg2: MESSAGES.ERROR,
        } as Status);
    }
  }

  requestNewValidationToken() {
    this.accountActivationService
      .requestNewValidationToken({ token: this.activationToken })
      .subscribe(
        (data) => {
          this.requestNewValidationTokenSuccessMsg();
        },
        (error) => {
          this.requestNewValidationTokenSuccessMsg();
        }
      );
  }

  initRequestTokenForm() {
    this.requestTokenForm = this.fb.group({
      username: [
        window.atob(this.email),
        [Validators.required, Validators.pattern(REGEX.EMAIL)],
      ],
    });
  }

  requestNewValidationTokenSuccessMsg() {
    this.appUtil.showAlert({
      type: ALERT_TYPES.SUCCESS,
      message1: 'Please check your inbox to activate your account.',
      message2: '',
      show: true,
    } as AlertModel);
  }

  get username() {
    return this.requestTokenForm.get('username');
  }

  ngOnDestroy() {
    this.appUtil.hideAlert();
  }
}
