import { StepperConfig } from '@app/component-common/stepper/stepper.config';
import { ManagementMenuItem, Tab } from '@app/management/management.model';
import { CONSTANTS } from 'environments/environment';
import { L2HeaderModel } from './../../component-common/l2header/l2header.model';
import { DIALOG_DATA_TOKEN } from '@prosimoio/components';
import { WARNING_DIALOG_DATA_TOKEN } from '@app/component-common/warning-dialog/warning-dialog.model';

/**
 * Regular Expression
 */
export const REGEX = {
  WHITESPACE: /\s/g,
  NAME: /^[a-zA-Z][a-zA-Z0-9_-].*$/,
  CLOUD_NAME: /^[a-zA-Z0-9-]*$/,
  IP_ADDRESS_PATTERN:
    /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/,
  IP_ADDRESS_IN_NAMESPACE:
    /^((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))$|^((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))+\s+in+\s.*$/,
  PORT: /^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/,
  PRIVATE_IP_ADDRESS_PATTERN:
    /(^192\.168\.([0-1]?[0-9]?[0-9]|[2][0-4][0-9]|[2][5][0-5])\.([0-1]?[0-9]?[0-9]|[2][0-4][0-9]|[2][5][0-5])$)|(^172\.([1][6-9]|[2][0-9]|[3][0-1])\.([0-1]?[0-9]?[0-9]|[2][0-4][0-9]|[2][5][0-5])\.([0-1]?[0-9]?[0-9]|[2][0-4][0-9]|[2][5][0-5])$)|(^10\.([0-1]?[0-9]?[0-9]|[2][0-4][0-9]|[2][5][0-5])\.([0-1]?[0-9]?[0-9]|[2][0-4][0-9]|[2][5][0-5])\.([0-1]?[0-9]?[0-9]|[2][0-4][0-9]|[2][5][0-5])$)/,
  CIDR_PATTERN: /^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([1-9]|[1-2][0-9]|3[0-2]))?$/,
  CIDR_PATTERN_STRICT:
    /^((25[0-5]|2[0-4][0-9]|1?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|1?[0-9][0-9]?)\/([1-9]|[1-2][0-9]|3[0-2])$/,
  SUMMARY_ROUTE_CIDR_PATTERN_STRICT:
    /^((25[0-5]|2[0-4][0-9]|1?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|1?[0-9][0-9]?)\/([0-9]|[1-2][0-9]|3[0-2])$/,
  CIDR_PATTERN_MIN_MASK_24:
    /^((25[0-5]|2[0-4][0-9]|1?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|1?[0-9][0-9]?)\/([1-9]|[1][0-9]|[2][0-4])?$/,
  // Azure Address Spaces only allow subnet prefixes upto /24
  CIDR_PATTERN_STRICT_AZURE:
    /^(((?:25[012345]|2[0-4]\d|1?\d\d?)\.){3}(?:25[012345]|2[0-4]\d|1?\d\d?))(?:\/([1-9]|1[0-9]|2[0-4]))(?![.\d])$/,
  NETWORK_WITH_SINGLE_IPV4_ADDRESS: /^([0-9]{1,3}\.){3}[0-9]{1,3}(\/3[2])?$/,
  SPECIAL_CHARACTER_REPLACER: /[^A-Z0-9]/gi,
  DOMAIN_NAME:
    /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/,
  DOMAIN_NAME_PERMITTED_CHARS: /[^A-Za-z0-9-.]/,
  DOMAIN_NAME_v2:
    /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:.[a-zA-Z]{2,})+$/,

  WILD_CARD_DOMAIN_NAME:
    /^(\*\.)(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/,
  URL: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
  EMAIL:
    /^(?=.{1,446}$)(?=.{1,64}@)[-!#$%&'*+\/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,254}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/,
  TEAM_NAME: /^[a-z0-9][a-z0-9-]{1,33}[a-z0-9]$/,
  CNAME:
    /^(([a-zA-Z0-9_]|[a-zA-Z0-9_][a-zA-Z0-9_\-]*[a-zA-Z0-9_])\.)*([A-Za-z0-9_]|[A-Za-z0-9_][A-Za-z0-9_\-]*[A-Za-z0-9_](\.?))$/,
  URI_PATH: /^[^\s"<>#%{}|^~`\[\]]*$/, // to avoid unsafe characters like " < > # % { } | \ ^ ~ [ ] `
  HTML: /^[^<>]*$/,
  CONTENT_TYPE: /^[-\w.]+\/[-\w.]+$/, // to check content type in url-rewrite
  HEADER: /^[^:]*$/, // to check header in url-rewrite
  WORD_MATCH: (word) => new RegExp(`^((?!${word}).)*$`), // to check if word is present in given string,
  DISCOVERY_REPORT_NAME: /^[a-zA-Z][a-zA-Z0-9_-]{2,63}/,
  BASE64: /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/,
  ALL_INTEGERS: /^[0-9]{5,35}$/,
  CURL_PATTERN:
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)?(([a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}){1}|((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])){1})(:[0-9]{1,5})?(\/.*)?$/,
  // Regex for IP Address and Hostname in Diagnose Screens - cURL and NetCat
  IP_ADDRESS_HOSTNAME:
    /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$|^(([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,})$|^(([0-9A-Fa-f]{1,4}:){7}[0-9A-Fa-f]{1,4})$|^([a-zA-Z0-9-]+\.[a-zA-Z]{2,})$|^((www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,})$/,
  PROCESS_NAME: {
    WINDOWS: /^[^<>{*?"|}]*$/, // to avoid { }  * ? " < > |
    MAC: /^[^“*:?‘{}\[\]]*$/, // to avoid { } : * ? ” < > |
  },
  AGENT_IP_ADDRESS:
    /(^([1-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.([1-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-4])(\/3[2])?$)/,
  // Regex for the Time entered in the 'Match Conditions' tab in the Create New/Edit Access Policy form.
  TIME: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]-(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
  // Regex for the Time entered in the 'Match Conditions' tab in the Create New/Edit Access Policy form when operation selected is 'Between'.
  TIME_BETWEEN:
    /^(((0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]-(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9])?(,?))*$/,
  IP_ADDRESS_WITH_COMMA:
    /^(?:(?:((((((?:25[012345]|2[0-4]\d|1?\d\d?)\.){3}(?:25[012345]|2[0-4]\d|1?\d\d?))(?:\/([1-9]|[1-2][0-9]|(3[0-2])))?(?![.\d]))|(0\.0\.0\.0\/0))(,?)(\s?))*)|(?:any))$/i,
  PORT_NUMBER_WITH_COMMA:
    /^(?:(?:(([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])(,?)(\s?))*)|(?:any))$/i,
  CIDR_WITH_COMMA:
    /^((((?:25[012345]|2[0-4]\d|1?\d\d?)\.){3}(?:25[012345]|2[0-4]\d|1?\d\d?))(?:\/([1-9]|[1-2][0-9]|3[0-2]))(?![.\d])(,?)(\s?))*$/,
  TGW_NAME: /^[\w\s\d.:/=+-@]{3,256}$/,
  VHUB_NAME: /^[a-zA-Z0-9][a-zA-Z0-9.\-_]{1,78}[a-zA-Z0-9_]$/,
  ALPHANUMERIC: /^[a-zA-Z0-9]+$/,
  PASSWORD: /^[a-zA-Z0-9\W_]+$/,
  ONE_UPPER_CASE: /^(?=(?:.*[A-Z]){1,})/,
  ONE_LOWER_CASE: /^(?=(?:.*[a-z]){1,})/,
  ONE_DIGIT: /^(?=(?:.*\d){1,})/,
  SPECIAL_CHARACTER: /^[\W_]/,
};

export const COLORS = {
  GREEN: '#008124',
  ORANGE: '#E55900',
  ORANGE2: '#ed772e',
  ORANGE3: '#ffb34b',
  ORANGE4: '#ef8234',
  RED: '#C24242',
  RED2: '#C9214A',
  BLUE5: '#0073C6',
  BLUE6: '#2758B7',
  BLUE: '#497DE3',
  LIGHT_BLUE: '#C7D3F1',
  GREY1: '#3F4B5E',
  GREY2: '#72808E',
  GREY3: '#D0D8E4',
  GREY4: '#E2E8F0',
  GREEN1: '#027D49',
  GREEN2: '#4DB07B',
  GREEN3: '#149B3A',
  GREEN4: '#2fb4aa',
  GREEN5: '#44d347',
  GREEN6: '#0EA36D',
  YELLOW: '#EEC430',
  PURPLE: '#1DAEFF',
  TEAL: '#3DC1D3',
  PINK: '#D88787',
  PINK2: '#BD4F77',
  BLUISH_PURPLE: '#4940A0',
  BEIGE: '#E3CEC1',
  WHITE: '#ffffff',
  PURPLE2: '#290082',
  ERR_RED: '#E25959',
  NO_DATA_GRAY: '#D0D8E4',
  PURPLE3: '#6A4293',
  DARK_PINK: '#c60060',
  DARK_BLUE: '#001D82',
  DARK_PURPLE: '#3A0760',
  CHART_BLUE: '#2A79D9',
  CHART_PINK: '#ED05BA',
  CHART_GREEN: '#039C55',
  CHART_ORANGE: '#FF5722',
  CHART_YELLOW: '#FEDA49',
  CHART_PURPLE: '#702BFE',
  RADIAL_TREE_RED: '#FF3366',
  RADIAL_TREE_STROKE_RED: 'rgba(255, 51, 102)',
  RADIAL_TREE_YELLOW: '#FBBC06',
  RADIAL_TREE_STROKE_YELLOW: 'rgba(251, 188, 6)',
  RADIAL_TREE_GREEN: '#05A34A',
  RADIAL_TREE_STROKE_GREEN: 'rgba(5, 163, 74)',
  RADIAL_TREE_GREY: '#F4F6F9',
  RADIAL_TREE_STROKE_GREY: '#A5B4C6',
  CHART_V2_BLUE: '#00A1C6',
  CHART_V2_GREEN: '#02962C',
  CHART_V2_PURPLE: '#A72593',
};

export const PROSIMO_TOKEN_LABEL = 'Prosimo-Token';

export const PROSIMO_API_TOKEN_LABEL = 'Prosimo-ApiToken';

// V2 Icons path.
export const V2_IMAGES_FOLDER_NAME = `${CONSTANTS.DASHBOARD_HOST}/images/v2`;

export const IMAGES_FOLDER_NAME = `${CONSTANTS.DASHBOARD_HOST}/images`;

export const FONT_FAMILY = 'Roboto';

export enum UI_THEME_MODES {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
}

export const SUBSCRIPTION_TYPES = {
  MCN: 'mcn',
  ZTNA: 'ztna',
};

export const VIEW_TYPES = {
  PLAYGROUND: 'playground',
};

export const PRODUCT_VIEW_TYPE = {
  [SUBSCRIPTION_TYPES.MCN]: 'ProTransit',
  [SUBSCRIPTION_TYPES.ZTNA]: 'ProAccess',
};

export const APP_TYPE_LABELS = {
  TARGET_APP: (isSingular = true) => (isSingular ? `Target` : `Targets`),
  SOURCE_APP: (isSingular = true) => (isSingular ? `Source` : `Sources`),
};

export const HTTP_RESPONSE_STATUS_CODES = {
  IN_PROGRESS: 'E101020',
  FAILED: 'E101012',
};

export const INDEX_PAGE_ELEMENT_CLASSES = {
  TITLE: 'page-title',
  FAVICON: 'favicon',
};

export const TEAM_ICONS = {
  FAVICON: 'favicon.ico',
  ABSTRACTMARK: 'abstractmark.svg',
  WORDMARK: 'wordmark.svg',
};

export const PROSIMO_TAG_LINE =
  'Simplify Enterprise Cloud with autonomous multi-cloud networking';

/**
 * User Types
 */

export const USER_TYPES = {
  PROSIMO_ADMIN: 'prosimo',
  MSP_ADMIN: 'msp',
  SANDBOX: 'trial', // sandbox user
  TEAM_USER: 'customer', // normal team user,
  SUPPORT_ADMIN: 'prosimo-support', // support team name
};

export const ACCOUNT_TYPES = {
  TENANT: 'tenant',
};

/**
 * App Type
 */

export const APP_TYPES = {
  PROSIMO: 'prosimo',
  APP: 'app',
};

/**
 * Alert types - pairs with the alert types from bootstrap
 */
export const ALERT_TYPES = {
  SUCCESS: 'success',
  DANGER: 'danger',
  WARNING: 'warning',
  INFO: 'info',
  ERROR: 'error',
  STATUS: 'primary',
};

/**
 * Dialog types
 */
export const DIALOG_ACTION_TYPES = {
  SUCCESS: 'success',
  CONFIRM: 'confirm',
  ERROR: 'error',
  INFO: 'info',
};

export const LOG_CONDITIONAL_FILTERS_VIEW_TYPES = {
  troubleshootingDetailsView: {
    includeKeys: ['sessionID'],
    removeKeys: ['timeRange'],
  },
  troubleshootingTransactionIdBasedView: {
    includeKeys: ['logID'],
  },
};

/**
 * User status after login
 */
export const USER_STATUSES = {
  MFA_REQUIRED: 'mfa_required',
  SUCCESS: 'success',
};

/**
 * Dialog Action Button Types
 */
export const DIALOG_BUTTON_TYPES = {
  YES: 'Yes',
  CANCEL: 'Cancel',
  SAVE: 'Save',
  VIEW: 'View',
  CLOSE: 'Close',
  DONE: 'Done',
  CONFIRM: 'Confirm',
};

export const CONFIRM_ACTIONS = {
  YES: 'yes',
  CANCEL: 'cancel',
};
/**
 * waf rulelset types
 */
export const RULESET_TYPES = {
  OWASP: 'owasp-crs-v32',
  BASIC: 'basic',
};
/**
 * Lightbox types
 */
export const LIGHTBOX_TYPES = {
  FORM: 'form',
  DIALOG: 'dialog',
};

/**
 * Cloud  types
 */
export const CLOUD_TYPES = {
  AWS: 'AWS',
  AZURE: 'AZURE',
  GCP: 'GCP',
  PRIVATE: 'PRIVATE',
};

/**
 * Cloud  key types
 */
export const CLOUD_KEY_TYPES = {
  UNMANAGED: 'UNMANAGED',
};

export const APP_HOSTED_TYPE = {
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC',
};

export const NETWORK_HOSTED_TYPE = {
  PRIVATE: 'private',
  PUBLIC: 'public',
  OWN: 'own-transit',
};

/**
 * IDP  types
 */
export const IDP_TYPES = {
  OKTA: 'okta',
  AZURE: 'azure_ad',
  ONE_LOGIN: 'one_login',
  PING: 'ping',
  PING_FEDERATE: 'ping_federate',
  GOOGLE: 'google',
  OTHER: 'other',
};
/**
 * DNS  types
 */
export const DNS_TYPES = {
  MANUAL: 'manual',
  AWS: 'aws_route53',
  PRIVATE_ZONE: 'private_hosted_zone',
};
/**
 * Domain  types
 */

export const DOMAIN_TYPES = {
  PROSIMO: 'prosimo',
  CUSTOM: 'custom',
};

/**
 * Certificate  types
 */
export const CERT_TYPES = {
  DOMAIN: 'domain',
  CA: 'ca',
  CLIENT: 'client',
  INTERNAL: 'internal',
};
/**
 * Optimization  types
 */
export const OPTIMIZATION_TYPES = {
  PERFORMANCE_ENHANCED: {
    NAME: 'Performance Enhanced',
    VALUE: 'PerformanceEnhanced',
  },
  COST_SAVINGS: {
    NAME: 'Cost Savings',
    VALUE: 'CostSaving',
  },
  FAST_LANE_MODE: {
    NAME: 'Fast Lane',
    VALUE: 'FastLane',
  },
};

/**
 * Error Page Methods
 */
export const METHODS = {
  CUSTOM: 'custom',
  DEFAULT: 'default',
  PASS_THROUGH: 'pass-through',
};

/**
 * Cloud form mode types
 */
export const CLOUD_MODES = {
  NEW_CLOUD_CONFIG: 'newCloudConfig',
  EDIT_CLOUD_CONFIG: 'editCloudConfig',
};

export const LOCATION_OPTIONS = {
  COUNTRY: 'COUNTRY',
  STATE: 'STATE',
  CITY: 'CITY',
};

/**
 * IDP form auth types
 */
export const IDP_AUTH_TYPES = {
  OIDC: 'oidc',
  SAML: 'saml',
  OTHER: 'other',
};

export const IDP_API_CREDS_VALUES = {
  YES: 'yes',
  NO: 'no',
};

export const YES_NO_ACTIONS = {
  YES: 'YES',
  NO: 'NO',
};

/**
 * URL rewrite config type
 */
export const URL_REWRITE_CONFIG_TYPES = {
  CUSTOM: 'custom',
  DEFAULT: 'default',
};

export const EMAIL_PATTERN = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/;

export const IDP_RADIO_AUTH_TYPES = [
  {
    name: 'OIDC',
    value: 'oidc',
  },
  {
    name: 'SAML',
    value: 'saml',
  },
];

/**
 * Insights Data Source
 */
export const INSIGHTS_DATA_SOURCE = {
  SEARCH_FILTER: 'SEARCH_FILTER',
  SEARCH_HISTORY: 'SEARCH_HISTORY',
  SUB_MENU_NAV_FETCH: 'SUB_MENU_NAV_FETCH',
  SUB_MENU_NAV_TOGGLE: 'SUB_MENU_NAV_TOGGLE',
};

/**
 * pAgent access types
 */
export const ACCESS_TYPES = {
  AGENT: 'agent',
  AGENTLESS: 'agentless',
  AGENT_CONSOLIDATED: 'consolidate-agent',
  STREAM: 'stream',
  AGENT_AND_STREAM: 'agentAndStream',
  ANY: 'any',
  CLIENT_APPLICATION_GROUPS: 'clientApplicationGroup',
};

/**
 * agent based access types
 */
export const APP_ADDRESS_TYPES = {
  FQDN: 'fqdn',
  IP_ADDRESS: 'ip_address',
};

/**
 * File Upload Header
 */

export const FILE_UPLOAD_HEADER_CONSTANT = {
  'prosimo-file-upload': 'upload',
};

/**
 * Cache Rule Path Pattern / Device Posture Profile Type
 */
export const RISK_PROFILE_STATUS = {
  NEW: 'new',
  EXISTING: 'existing',
  DELETE: 'delete',
};

/**
 * Switch Component Options
 */
export const SWITCH_OPTIONS = {
  OFF: 'Off',
  ON: 'On',
  YES: 'Yes',
  NO: 'No',
};

export const CONNECTIVITY_OPTIONS_KEYS = {
  VPC_PEERING: 'vpc-peering',
  VNET_PEERING: 'vnet-peering',
  VWAN_HUB: 'vwan-hub',
  TRANSIT_GATEWAY: 'transit-gateway',
  PUBLIC: 'public',
};

export const VIRTUAL_PRIVATE_CLOUD_NAME = {
  AWS: 'VPC',
  AZURE: 'VNET',
  GCP: 'VPC',
};

export const CSP_GATEWAY_NAME = {
  AWS: 'TGW',
  AZURE: 'VHUB',
};

/**
 * URL paths - referred by router redirect function
 */
export const URL_PATHS = {
  ADMIN: {
    DASHBOARD: '/admin/account/requests',
    TEAMS: '/admin/teams',
  },
  MSP: {
    DASHBOARD: '/sp/dashboard',
    MANAGEMENT: '/sp/management',
    RELEASE_NOTES: '/sp/releases',
    TASKS: '/sp/activities',
    USER_PROFILE: '/sp/user-profile',
  },
  LOGIN: '/login',
  ACCOUNT_EXPIRED: '/account/expired',
  PASSWORD_ERROR: '/password/error',
  DASHBOARD: '/dashboard',
  DASHBOARD_APPS: '/dashboard/apps/onboarded',
  DASHBOARD_APPS_DISCOVERED: '/dashboard/apps/discovered',
  DASHBOARD_NETWORKS: '/dashboard/networks/onboarded',
  DASHBOARD_PRIVATE_LINK_SOURCES: '/dashboard/private-link-sources',
  DASHBOARD_NETWORKS_DISCOVERED: '/dashboard/networks/discovered',
  DASHBOARD_INSIGHTS: '/dashboard/insights',
  COST: '/cost',
  DASHBOARD_POLICY: '/dashboard/policy',
  DASHBOARD_TASKS: '/v2/activities',
  DASHBOARD_ALERTS: '/v2/alert-notification',
  DASHBOARD_USER_PROFILE: '/v2/user-profile',
  DASHBOARD_CLOUD_TRANSIT: '/dashboard/cloud-transit',
  DASHBOARD_RELEASE_NOTES: '/releases',
  APP_ONBOARD_DEFAULT_STEP: (appType) => `/app-onboard/${appType}/settings`,
  APP_ONBOARD_LAUNCH_STEP: (appType) => `/app-onboard/${appType}/onboard`,
  PROSIMO_APP_ONBOARD_DEFAULT_STEP: (appType) =>
    `/app-onboard/${appType}/cloud`,
  PAGE_NOT_FOUND: 'page-not-found',
  API_DOCS: {
    TEAM: 'apidocs/team/index.html',
    MSP: 'apidocs/msp/index.html',
  },
  NETWORK_ONBOARD: {
    CLOUD_CONFIG: '/network-onboard/cloud-config',
    SECIRUTY: '/network-onboard/security',
    LAUNCH: '/network-onboard/onboard',
  },
  SHARED_SERVICE: {
    CONFIG: '/shared-service/onboard',
    SHARED_SERVICES_DASHBOARD: '/dashboard/sharedServices',
  },
  PRIVATE_LINK_SOURCE_ONBOARD: {
    CLOUD: '/private-link-source-onboard/cloud',
    LAUNCH: '/private-link-source-onboard/onboard',
  },
  HOME: `dashboard/main`,
  INSIGHTS: `v2/insights/main`,
  ONBOARD: `v2/onboard`,
  POLICY: `v2/configure`,
  DIAGNOSE: `v2/diagnose/main`,
  MANAGEMENT: `management`,
  ENG_LABS: `dashboard/playground/cluster`,
  TASKS: `v2/activities`,
  ALERT: `v2/alert-notification`,
  API_DOCUMENTATION: `apidocs/team/index.html`,
  DOCUMENTATION: `api/support/help/product`,
  VIDEOS: `dashboard/agent/download`,
  MY_PROFILE: `v2/user-profile`,
  MY_PROFILE_PASSWORD: `dashboard/user-profile/password`,
};

export const FILTER_TYPES = {
  APP: 'APP',
  USER: 'USER',
  APP_GROUP: 'APP_GROUP',
  USER_GROUP: 'USER_GROUP',
  CALENDAR: 'CALENDAR',
  SRC_NETWORKS: 'SOURCE_NETWORKS',
  SRC_PRIVATE_LINKS: 'SOURCE_PRIVATE_LINKS',
  SRC_REGIONS: 'SOURCE_REGIONS',
  SRC_NETWORK_GROUPS: 'SOURCE_NETWORK_GROUPS',
  TGT_NETWORKS: 'TARGET_NETWORKS',
  TGT_REGIONS: 'TARGET_REGIONS',
  TGT_NETWORK_GROUPS: 'TARGET_NETWORK_GROUPS',
  NETWORK: 'NETWORK',
  REGION: 'REGION',
  PRIVATE_LINK: 'PRIVATE_LINK',
  RESTRICTIONS: 'RESTRICTIONS',
};

// Toggle button
export const FILTER_DATA_VIEW_MODE = {
  ALL: 'All',
  SELECTED: 'Selected',
};

/**
 * App setup status - pairs with the status used by server side
 *
 */
export const APP_SETUP_STATUS = {
  UNAVAILABLE: 'UNAVAILABLE',
  CONFIGURING: 'CONFIGURING',
  CONFIGURED: 'CONFIGURED',
  DEPLOYING: 'DEPLOYING',
  ONBOARDING: 'ONBOARDING',
  DEPLOYED: 'DEPLOYED',
  ONBOARDED: 'ONBOARDED',
  FAILED: 'FAILED',
  INPROGRESS: 'IN-PROGRESS',
  DISCOVERED: 'DISCOVERED',
  DECOMMISSIONING: 'DECOMMISSIONING',
  OFFBOARDING: 'OFFBOARDING',
  ACTIVE: 'ACTIVE',
};

export const CONFIG_TABLE_STATUS = {
  INDEXING: 'INDEXING',
};

/**
 * Roles header config
 */
export const ROLES_HEADER_CONFIG = [
  {
    name: '',
    rowSize: '1',
  },
  {
    name: 'Roles',
    rowSize: '5',
  },
];

/**
 * Tenant Roles header config
 */
export const TENANT_ROLES_HEADER_CONFIG = [
  {
    name: '',
    rowSize: '1',
  },
  {
    name: 'Roles',
    rowSize: '3',
  },
  {
    name: 'Tenants',
    rowSize: '2',
  },
];

export const API_TOKEN_HEADER_CONFIG = [
  {
    name: '',
    rowSize: '1',
  },
  {
    name: 'Status',
    rowSize: '1',
  },
  {
    name: 'Name',
    rowSize: '2',
  },
  {
    name: 'Role',
    rowSize: '2',
  },
  {
    name: 'API Token',
    rowSize: '1',
  },
  {
    name: 'Last Used',
    rowSize: '2',
  },
  {
    name: 'Updated By',
    rowSize: '1',
  },
  {
    name: 'Date Updated',
    rowSize: '2',
  },
];

/**
 * User group header config
 */
export const USER_GROUP_HEADER_CONFIG = [
  {
    name: '',
    rowSize: '1',
  },
  {
    name: 'User Groups',
    rowSize: '3',
  },
  {
    name: 'Group Type',
    rowSize: '2',
  },
  {
    name: 'Emails',
    rowSize: '6',
  },
];

/**
 * App group header config
 */
export const APP_GROUP_HEADER_CONFIG = [
  {
    name: '',
    rowSize: '1',
  },
  {
    name: 'App Groups',
    rowSize: '3',
  },
  {
    name: 'Apps',
    rowSize: '8',
  },
];

/**
 * Network group header config
 */
export const NETWORK_GROUP_HEADER_CONFIG = [
  {
    name: '',
    rowSize: '1',
  },
  {
    name: 'Network Groups',
    rowSize: '3',
  },
  {
    name: 'Networks',
    rowSize: '8',
  },
];

/**
 * IDP Management header config
 */
export const IDP_HEADER_CONFIG = [
  {
    name: '',
    rowSize: '1',
  },
  {
    name: 'Status',
    rowSize: '1',
  },
  {
    name: 'IDP Service',
    rowSize: '2',
  },
  {
    name: 'Authorization Type',
    rowSize: '3',
  },
  {
    name: 'Type',
    rowSize: '2',
  },
  {
    name: 'User Domain',
    rowSize: '3',
  },
];

/**
 * IDP Management header config
 */
export const USER_MANAGEMENT_HEADER_CONFIG = [
  {
    name: '',
    rowSize: '1',
  },
  {
    name: 'Status',
    rowSize: '1',
  },
  {
    name: 'Name',
    rowSize: '2',
  },
  {
    name: 'Contacts',
    rowSize: '2',
  },
  {
    name: 'Admin Groups',
    rowSize: '3',
  },
  {
    name: 'Roles',
    rowSize: '3',
  },
];

export const TOKEN_ROW_ACTIONS = {
  SELECT_TOKEN: 'onSelectToken',
  CLICK_ACTIONS: 'onClickActions',
  DEACTIVATE: 'onDeactivate',
  ACTIVATE: 'onActivate',
  CONFIRM_DELETE: 'confirmDelete',
  REGENERATE: 'onRegenerate',
};

export const GETTING_STARTED_STEPS = {
  FIRST_STEP: 'FIRST_STEP',
  SECOND_STEP: 'SECOND_STEP',
  THIRD_STEP: 'THIRD_STEP',
};

/**
 * Image paths
 */
export const IMAGE_PATHS = {
  FAVICON: `${IMAGES_FOLDER_NAME}/favicon.ico`,
  LOGO: {
    GREY_BG: `${IMAGES_FOLDER_NAME}/logo.svg`,
    WHITE_BG: `${IMAGES_FOLDER_NAME}/icons/sign-up/login_logo.svg`,
    NEW: `${IMAGES_FOLDER_NAME}/logo/prosimo-new.svg`,
    TRANSPARENT_BG: `${IMAGES_FOLDER_NAME}/logo/logo-transparent-bg.svg`,
    INACTIVE_EDGE: `${IMAGES_FOLDER_NAME}/logo/inactive-edge.svg`,
    MAIN: `${IMAGES_FOLDER_NAME}/logo/prosimo-main.svg`,
    MAIN_WHITE: `${IMAGES_FOLDER_NAME}/logo/prosimo-main-white.svg`,
  },
  CLOUD: {
    CSP: {
      AWS: `${IMAGES_FOLDER_NAME}/icons/clouds/aws.svg`,
      AWS_DARK: `${IMAGES_FOLDER_NAME}/icons/clouds/aws_dark.svg`,
      AZURE: `${IMAGES_FOLDER_NAME}/icons/clouds/azure.svg`,
      AZURE_DARK: `${IMAGES_FOLDER_NAME}/icons/clouds/azure_dark.svg`,
      GCP: `${IMAGES_FOLDER_NAME}/icons/clouds/gcp.svg`,
      GCP_DARK: `${IMAGES_FOLDER_NAME}/icons/clouds/gcp_dark.svg`,
      TGW: `${IMAGES_FOLDER_NAME}/icons/clouds/tgw.svg`,
      VPC: `${IMAGES_FOLDER_NAME}/icons/clouds/vpc.svg`,
      VHUB: `${IMAGES_FOLDER_NAME}/icons/clouds/vhub.svg`,
      VNET: `${IMAGES_FOLDER_NAME}/icons/clouds/vnet.svg`,
      PEERING: `${IMAGES_FOLDER_NAME}/icons/clouds/peering.svg`,
    },
  },
  ICON: {
    SPACE_SHIP: `${IMAGES_FOLDER_NAME}/icons/sign-up/space_ship.svg`,
    ROCKET_OFF: `${IMAGES_FOLDER_NAME}/icons/error-pages/rocket_404.png`,
    ARROW_PAGE_NOT_FOUND: `${IMAGES_FOLDER_NAME}/icons/error-pages/404_page_arrow.svg`,
    THANK_YOU: `${IMAGES_FOLDER_NAME}/icons/sign-up/thank_you.svg`,
    REQUEST_SENT: `${IMAGES_FOLDER_NAME}/icons/sign-up/request_sent.svg`,
    MAIL_ENVELOPE: `${IMAGES_FOLDER_NAME}/icons/sign-up/verify_email.svg`,
    VERIFICATION_FAILURE: `${IMAGES_FOLDER_NAME}/icons/sign-up/signup_expired.svg`,
    GEO_MAP_BG: `${IMAGES_FOLDER_NAME}/icons/sign-up/full-map-background.svg`,
    HUMAN_FACE: `${IMAGES_FOLDER_NAME}/icons/sign-up/account_active.svg`,
    ERROR: `${IMAGES_FOLDER_NAME}/icons/sign-up/error.svg`,
    AWS: `${IMAGES_FOLDER_NAME}/icons/third-party/aws.svg`,
    AWS_ROUTE53: `${IMAGES_FOLDER_NAME}/icons/third-party/aws_route53.svg`,
    OKTA: `${IMAGES_FOLDER_NAME}/icons/third-party/okta.svg`,
    AZURE_AD: `${IMAGES_FOLDER_NAME}/icons/third-party/azure.svg`,
    ONE_LOGIN: `${IMAGES_FOLDER_NAME}/icons/third-party/onelogin.svg`,
    GOOGLE: `${IMAGES_FOLDER_NAME}/icons/third-party/google.svg`,
    PING: `${IMAGES_FOLDER_NAME}/icons/third-party/pingone.svg`,
    PING_FEDERATE: `${IMAGES_FOLDER_NAME}/icons/third-party/ping_federate.svg`,
    AZURE: `${IMAGES_FOLDER_NAME}/icons/third-party/azure.svg`,
    GCP: `${IMAGES_FOLDER_NAME}/icons/third-party/gcp.svg`,
    EMPTY_IMAGE: `${IMAGES_FOLDER_NAME}/icons/common/empty_image.svg`,
    STANDARD_GEO_MAP_PIN: `${IMAGES_FOLDER_NAME}/icons/common/marker-pin.svg`,
    EXISTING_PIN: `${IMAGES_FOLDER_NAME}/icons/guide/icon_existing.svg`,
    SPIN_UP_PIN: `${IMAGES_FOLDER_NAME}/icons/guide/icon_launch.svg`,
    SPIN_DOWN_PIN: `${IMAGES_FOLDER_NAME}/icons/guide/icon_spindown.svg`,
    REMOVABLE_EXISTING_PIN: `${IMAGES_FOLDER_NAME}/icons/guide/icon_existing_removable.svg`,
    REMOVABLE_SPIN_UP_PIN: `${IMAGES_FOLDER_NAME}/icons/guide/icon_launch_removable.svg`,
    REMOVABLE_SPIN_DOWN_PIN: `${IMAGES_FOLDER_NAME}/icons/guide/icon_spindown_removable.svg`,
    ADD_EDGE_PIN: `${IMAGES_FOLDER_NAME}/icons/guide/icon_add_region.svg`,
    PROSIMO_P: `${IMAGES_FOLDER_NAME}/icons/guide/icon_p.svg`,
    PROSIMO_RED: `${IMAGES_FOLDER_NAME}/icons/common/icon_red_prosimo.svg`,
    ERROR_OUTLINE: `${IMAGES_FOLDER_NAME}/icons/common/error_outline.svg`,
    GCP_CLOUD_LOGO_ONLY: `${IMAGES_FOLDER_NAME}/icons/third-party/gcp_img_only.svg`,
    AZURE_CLOUD_LOGO_ONLY: `${IMAGES_FOLDER_NAME}/icons/third-party/azure_img_only.svg`,
    REFRESH: `${IMAGES_FOLDER_NAME}/icons/common/rotate.svg`,
    AGENT_GREY: `${IMAGES_FOLDER_NAME}/icons/common/icon_agent_grey.svg`,
    SERVICENOW: `${IMAGES_FOLDER_NAME}/icons/third-party/servicenow.svg`,
    SPLUNK: `${IMAGES_FOLDER_NAME}/icons/third-party/splunk.svg`,
  },
  BG_GRAPHICS: {
    CLOUD: `${IMAGES_FOLDER_NAME}/icons/app-onboard/cloud_graphic.svg`,
    IDP: `${IMAGES_FOLDER_NAME}/icons/app-onboard/idp_graphic.svg`,
    SPACESHIP: `${IMAGES_FOLDER_NAME}/icons/app-onboard/spaceship_graphic.svg`,
    DNS: `${IMAGES_FOLDER_NAME}/icons/app-onboard/dns_graphic.svg`,
    POLICY: `${IMAGES_FOLDER_NAME}/icons/app-onboard/policy_graphic.svg`,
    SSL: `${IMAGES_FOLDER_NAME}/icons/app-onboard/ssl_graphic.svg`,
    OPTIMIZATION: `${IMAGES_FOLDER_NAME}/icons/app-onboard/optimization_graphic.svg`,
    SETTINGS: `${IMAGES_FOLDER_NAME}/icons/app-onboard/settings_graphic.svg`,
    MAINTENANCE: `${IMAGES_FOLDER_NAME}/icons/common/bg_under_maintenance.svg`,
    MAINTENANCE_TOP: `${IMAGES_FOLDER_NAME}/icons/common/maintenance_clouds.svg`,
    MAINTENANCE_BOTTOM: `${IMAGES_FOLDER_NAME}/icons/common/maintenance_crane_and_rocket.svg`,
    ACCOUNT_EXPIRED: `${IMAGES_FOLDER_NAME}/icons/trial/expire_backdrop.svg`,
  },
  COMMON: {
    CHECK_MARK: `${IMAGES_FOLDER_NAME}/icons/common/checkmark.svg`,
    PROSIMO: `${IMAGES_FOLDER_NAME}/icons/common/01_P.svg`,
    USER: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/users.svg`,
    USER_OUTLINE: `${IMAGES_FOLDER_NAME}/icons/common/user_blank.svg`,
    APPS: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/tgt_filter_circle.svg`,
    APPS_BACKUP: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/apps-backup.svg`,
    EDGE_PRIMARY: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/edge-primary.svg`,
    EDGE_SECONDARY: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/edge-secondary.svg`,
    CALENDAR: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/calendar_filter_circle.svg`,
    CONFIGURE: `${IMAGES_FOLDER_NAME}/icons/common/configure.svg`,
    ROCKET_ORANGE: `${IMAGES_FOLDER_NAME}/icons/common/rocket-orange.svg`,
    ROCKET_LIGHT_THEME: `${IMAGES_FOLDER_NAME}/icons/common/rocket-light-theme.svg`,
    ROCKET_DARK_THEME: `${IMAGES_FOLDER_NAME}/icons/common/rocket-dark-theme.svg`,
    ACCESS: `${IMAGES_FOLDER_NAME}/icons/common/Access.svg`,
    ACCESS_SELECTED: `${IMAGES_FOLDER_NAME}/icons/common/Access_Selected.svg`,
    AUTH: `${IMAGES_FOLDER_NAME}/icons/common/Auth.svg`,
    AUTH_SELECTED: `${IMAGES_FOLDER_NAME}/icons/common/Auth_Selected.svg`,
    GREY_CLOUD: `${IMAGES_FOLDER_NAME}/icons/common/card_cloud_icon.svg`,
    AZURE_IDP: `${IMAGES_FOLDER_NAME}/icons/common/icon_azure_idp.svg`,
    WARNING: `${IMAGES_FOLDER_NAME}/icons/common/warning.svg`,
    DELETE: `${IMAGES_FOLDER_NAME}/icons/common/icon_erase.svg`,
    CALCULATOR_CIRCLE: `${IMAGES_FOLDER_NAME}/icons/common/icon_pricing_cal.svg`,
    CALCULATOR: `${IMAGES_FOLDER_NAME}/icons/diagnostics/infrastructure/icon_pricing_cal.svg`,
    INFRA_COST_CALCULATOR: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/infra_cost_calculator.svg`,
    HELP_ADMIN: `${IMAGES_FOLDER_NAME}/icons/common/icon_help_admin.svg`,
    ARROW_BLUE: `${IMAGES_FOLDER_NAME}/icons/common/icon_arrow.svg`,
    PIN_GREY: `${IMAGES_FOLDER_NAME}/icons/common/icon_pin_right.svg`,
    INFO: `${IMAGES_FOLDER_NAME}/icons/common/icon_info.svg`,
    BETA_ICON: `${IMAGES_FOLDER_NAME}/icons/common/beta_logo.svg`,
    BETA_SELECTED: `${IMAGES_FOLDER_NAME}/icons/common/icon-beta-selected.svg`,
    BETA_DESELECTED: `${IMAGES_FOLDER_NAME}/icons/common/icon-beta-deselected.svg`,
    USER_DISTRIBUTION: `${IMAGES_FOLDER_NAME}/icons/common/icon_user_distribution.svg`,
    PRIVATE_NEUTRAL: `${IMAGES_FOLDER_NAME}/icons/diagnostics/icon_private.svg`,
    SELECTED_PDF: `${IMAGES_FOLDER_NAME}/icons/common/selected_pdf.svg`,
    PDF: `${IMAGES_FOLDER_NAME}/icons/common/pdf.svg`,
    CSP_AWS: `${IMAGES_FOLDER_NAME}/icons/common/csp/aws_csp_icon.svg`,
    CSP_AZURE: `${IMAGES_FOLDER_NAME}/icons/common/csp/azure_csp_icon.svg`,
    CSP_GOOGLE: `${IMAGES_FOLDER_NAME}/icons/common/csp/gcp_csp_icon.svg`,
    CSP_PRIVATE: `${IMAGES_FOLDER_NAME}/icons/diagnostics/icon_private.svg`,
    FILE_GREY: `${IMAGES_FOLDER_NAME}/icons/common/icon_grey_file.svg`,
    FILE_BLUE: `${IMAGES_FOLDER_NAME}/icons/common/icon_blue_file.svg`,
    COPY: `${IMAGES_FOLDER_NAME}/icons/common/copy.svg`,
    FILTER_ICON: `${IMAGES_FOLDER_NAME}/icons/common/filter.svg`,
    RESET_MAP: `${IMAGES_FOLDER_NAME}/icons/common/reset_map.svg`,
    ALERT_ICON: `${IMAGES_FOLDER_NAME}/icons/common/alert_icon.svg`,
    LOG_FILTER_ICON: `${IMAGES_FOLDER_NAME}/icons/common/log-filter.svg`,
    COLUMN_FILTER: `${IMAGES_FOLDER_NAME}/icons/common/column-filter.svg`,
    REORDER: `${IMAGES_FOLDER_NAME}/icons/common/reorder.svg`,
    PLUS: `${IMAGES_FOLDER_NAME}/icons/common/plus.svg`,
    MINUS: `${IMAGES_FOLDER_NAME}/icons/common/minus.svg`,
    CLOSE: `${IMAGES_FOLDER_NAME}/icons/common/close.svg`,
    UNDO: `${IMAGES_FOLDER_NAME}/icons/common/undo.svg`,
  },
  MSP: {
    DASHBOARD: {
      ADDRESS_BOOK: `${IMAGES_FOLDER_NAME}/icons/msp/dashboard/icon_address_book.svg`,
      APP: `${IMAGES_FOLDER_NAME}/icons/msp/dashboard/icon_app.svg`,
      RED_APP: `${IMAGES_FOLDER_NAME}/icons/msp/dashboard/icon_red_app.svg`,
      EDGE: `${IMAGES_FOLDER_NAME}/icons/msp/dashboard/icon_edge.svg`,
      RED_EDGE: `${IMAGES_FOLDER_NAME}/icons/msp/dashboard/icon_red_edge.svg`,
      TICKET: `${IMAGES_FOLDER_NAME}/icons/msp/dashboard/icon_tickets.svg`,
      RED_TICKET: `${IMAGES_FOLDER_NAME}/icons/msp/dashboard/icon_red_tickets.svg`,
      USER: `${IMAGES_FOLDER_NAME}/icons/msp/dashboard/icon_users.svg`,
      RED_USER: `${IMAGES_FOLDER_NAME}/icons/msp/dashboard/icon_red_users.svg`,
      ALERT: `${IMAGES_FOLDER_NAME}/icons/msp/dashboard/icon_notifications.svg`,
      RED_ALERT: `${IMAGES_FOLDER_NAME}/icons/msp/dashboard/icon_red_notifications.svg`,
    },
  },
  COST_ESTIMATION: {
    USER: `${IMAGES_FOLDER_NAME}/icons/cost-estimation/icon_users.svg`,
    APP_USAGE: `${IMAGES_FOLDER_NAME}/icons/cost-estimation/icon_apps.svg`,
    REGION: `${IMAGES_FOLDER_NAME}/icons/cost-estimation/icon_web.svg`,
    EDGE: `${IMAGES_FOLDER_NAME}/icons/cost-estimation/icon_edge.svg`,
    AWS: `${IMAGES_FOLDER_NAME}/icons/cost-estimation/icon_aws.svg`,
    GCP: `${IMAGES_FOLDER_NAME}/icons/cost-estimation/icon_gcp.svg`,
    AZURE: `${IMAGES_FOLDER_NAME}/icons/cost-estimation/icon_azure.svg`,
  },
  NAVIGATION: {
    PROSIMO: `${IMAGES_FOLDER_NAME}/icons/common/Icon_prosimo_logo.svg`,
    SELECTED_PROSIMO: `${IMAGES_FOLDER_NAME}/icons/common/Icon_white_prosimo_logo.svg`,
    USERS: `${IMAGES_FOLDER_NAME}/icons/app-navigation/user-dashboard-unselected.svg`,
    APP: `${IMAGES_FOLDER_NAME}/icons/app-navigation/app-dashboard-unselected.svg`,
    ON_BOARDING: `${IMAGES_FOLDER_NAME}/icons/app-navigation/on-boarding-unselected.svg`,
    POLICY: `${IMAGES_FOLDER_NAME}/icons/app-navigation/policy-unselected.svg`,
    OPTIMIZATION: `${IMAGES_FOLDER_NAME}/icons/app-navigation/optimization-unselected.svg`,
    MANAGEMENT: `${IMAGES_FOLDER_NAME}/icons/app-navigation/management-unselected.svg`,
    PLAYGROUND: `${IMAGES_FOLDER_NAME}/icons/app-navigation/labs-unselected.svg`,
    SELECTED_PLAYGROUND: `${IMAGES_FOLDER_NAME}/icons/app-navigation/labs-selected.svg`,
    TROUBLESHOOT: `${IMAGES_FOLDER_NAME}/icons/app-navigation/troubleshoot-unselected.svg`,
    DISCOVERY: `${IMAGES_FOLDER_NAME}/icons/app-navigation/discovery-unselected.svg`,
    SETTINGS: `${IMAGES_FOLDER_NAME}/icons/app-navigation/settings-unselected.svg`,
    PIN: `${IMAGES_FOLDER_NAME}/icons/common/pin_unselected.svg`,
    GUIDE: `${IMAGES_FOLDER_NAME}/icons/app-navigation/guide-unselected.svg`,
    SELECTED_DISCOVERY: `${IMAGES_FOLDER_NAME}/icons/app-navigation/discovery-selected.svg`,
    SELECTED_TROUBLESHOOT: `${IMAGES_FOLDER_NAME}/icons/app-navigation/troubleshoot-selected.svg`,
    SELECTED_SETTINGS: `${IMAGES_FOLDER_NAME}/icons/app-navigation/settings-selected.svg`,
    SELECTED_PIN: `${IMAGES_FOLDER_NAME}/icons/common/pin_selected.svg`,
    SELECTED_USERS: `${IMAGES_FOLDER_NAME}/icons/app-navigation/user-dashboard-selected.svg`,
    SELECTED_APP: `${IMAGES_FOLDER_NAME}/icons/app-navigation/app-dashboard-selected.svg`,
    SELECTED_ON_BOARDING: `${IMAGES_FOLDER_NAME}/icons/app-navigation/on-boarding-selected.svg`,
    SELECTED_POLICY: `${IMAGES_FOLDER_NAME}/icons/app-navigation/policy-selected.svg`,
    SELECTED_MANAGEMENT: `${IMAGES_FOLDER_NAME}/icons/app-navigation/management-selected.svg`,
    SECURITY: `${IMAGES_FOLDER_NAME}/icons/app-navigation/security-unselected.svg`,
    SELECTED_SECURITY: `${IMAGES_FOLDER_NAME}/icons/app-navigation/security-selected.svg`,
    TASKS: `${IMAGES_FOLDER_NAME}/icons/app-navigation/deselected-task.svg`,
    SELECTED_TASKS: `${IMAGES_FOLDER_NAME}/icons/app-navigation/selected-task.svg`,
    ALERTS: `${IMAGES_FOLDER_NAME}/icons/app-navigation/deselected-alert.svg`,
    ALERTS_NEW: `${IMAGES_FOLDER_NAME}/icons/app-navigation/alert-new.svg`,
    SELECTED_ALERTS: `${IMAGES_FOLDER_NAME}/icons/app-navigation/selected-alert.svg`,
    USER_PROFILE: `${IMAGES_FOLDER_NAME}/icons/app-navigation/deselected-user-profile.svg`,
    SELECTED_USER_PROFILE: `${IMAGES_FOLDER_NAME}/icons/app-navigation/selected-user-profile.svg`,
    SELECTED_OPTIMIZATION: `${IMAGES_FOLDER_NAME}/icons/app-navigation/optimization-selected.svg`,
    SELECTED_GUIDE: `${IMAGES_FOLDER_NAME}/icons/app-navigation/guide-selected.svg`,
    HELP_SELECTED: `${IMAGES_FOLDER_NAME}/icons/app-navigation/selected-help.svg`,
    HELP_UNSELECTED: `${IMAGES_FOLDER_NAME}/icons/app-navigation/deselected-help.svg`,
    AGENT_SELECTED: `${IMAGES_FOLDER_NAME}/icons/app-navigation/agent-selected.svg`,
    AGENT_DESELECTED: `${IMAGES_FOLDER_NAME}/icons/app-navigation/agent-deselected.svg`,
    CLOUD_TRANSIT_SELECTED: `${IMAGES_FOLDER_NAME}/icons/app-navigation/cloud-transit-selected.svg`,
    CLOUD_TRANSIT_DESELECTED: `${IMAGES_FOLDER_NAME}/icons/app-navigation/cloud-transit-deselected.svg`,
    SELECTED_PRODUCT_TOUR: `${IMAGES_FOLDER_NAME}/icons/app-navigation/selected_tour_icon.svg`,
    DESELECTED_PRODUCT_TOUR: `${IMAGES_FOLDER_NAME}/icons/app-navigation/deselected_tour_icon.svg`,
    HOME_DESELECTED: `${IMAGES_FOLDER_NAME}/icons/app-navigation/home_deselected.svg`,
    HOME_SELECTED: `${IMAGES_FOLDER_NAME}/icons/app-navigation/home_selected.svg`,
    SEARCH: `${IMAGES_FOLDER_NAME}/icons/app-navigation/search.svg`,
    TASK: `${IMAGES_FOLDER_NAME}/icons/app-navigation/task.svg`,
    NOTIFICATION: `${IMAGES_FOLDER_NAME}/icons/app-navigation/notification.svg`,
    HELP: `${IMAGES_FOLDER_NAME}/icons/app-navigation/help.svg`,
    USER: `${IMAGES_FOLDER_NAME}/icons/app-navigation/user.svg`,
    ANALYTICS: `${IMAGES_FOLDER_NAME}/icons/app-navigation/analytics.svg`,
    COST: `${IMAGES_FOLDER_NAME}/icons/app-navigation/cost.svg`,
    TRANSIT: `${IMAGES_FOLDER_NAME}/icons/app-navigation/transit.svg`,
    NEBULA: `${IMAGES_FOLDER_NAME}/icons/app-navigation/nebula.svg`,
    THEME: `${IMAGES_FOLDER_NAME}/icons/app-navigation/theme.svg`,
    LOGOUT: `${IMAGES_FOLDER_NAME}/icons/app-navigation/logout.svg`,
    MENU: `${IMAGES_FOLDER_NAME}/icons/common/menu.svg`,
    MENU_CLOSE: `${IMAGES_FOLDER_NAME}/icons/common/menu-close.svg`,
  },
  MANAGEMENT: {
    ADMIN: `${V2_IMAGES_FOLDER_NAME}/icons/management/admins.svg`,
    ADMIN_SELECTED: `${V2_IMAGES_FOLDER_NAME}/icons/management/admins.svg`,
    CLOUD: `${V2_IMAGES_FOLDER_NAME}/icons/management/clouds.svg`,
    CLOUD_SELECTED: `${V2_IMAGES_FOLDER_NAME}/icons/management/clouds.svg`,
    IDP: `${V2_IMAGES_FOLDER_NAME}/icons/management/idp.svg`,
    IDP_SELECTED: `${V2_IMAGES_FOLDER_NAME}/icons/management/idp.svg`,
    EDGE: `${V2_IMAGES_FOLDER_NAME}/icons/management/edges.svg`,
    EDGE_SELECTED: `${V2_IMAGES_FOLDER_NAME}/icons/management/edges.svg`,
    CONNECTOR_GROUPS: `${V2_IMAGES_FOLDER_NAME}/icons/management/connector-groups.svg`,
    CONNECTOR_GROUPS_SELECTED: `${V2_IMAGES_FOLDER_NAME}/icons/management/connector-groups.svg`,
    GROUPINGS: `${V2_IMAGES_FOLDER_NAME}/icons/management/groupings.svg`,
    GROUPINGS_SELECTED: `${V2_IMAGES_FOLDER_NAME}/icons/management/groupings.svg`,
    CERTIFICATES: `${V2_IMAGES_FOLDER_NAME}/icons/management/certificates.svg`,
    CERTIFICATES_SELECTED: `${V2_IMAGES_FOLDER_NAME}/icons/management/certificates.svg`,
    PAPP_BACKGROUND: `${IMAGES_FOLDER_NAME}/icons/common/Management_Papp_background.svg`,
    IP_ADDRESSES: `${V2_IMAGES_FOLDER_NAME}/icons/management/ip-addresses.svg`,
    IP_ADDRESSES_SELECTED: `${V2_IMAGES_FOLDER_NAME}/icons/management/ip-addresses.svg`,
    ALERTS: `${V2_IMAGES_FOLDER_NAME}/icons/management/alerts.svg`,
    ALERTS_SELECTED: `${V2_IMAGES_FOLDER_NAME}/icons/management/alerts.svg`,
    LOGS: `${V2_IMAGES_FOLDER_NAME}/icons/management/agent-integration.svg`,
    LOGS_SELECTED: `${V2_IMAGES_FOLDER_NAME}/icons/management/agent-integration.svg`,
    API_TOKENS: `${V2_IMAGES_FOLDER_NAME}/icons/management/api-tokens.svg`,
    API_TOKENS_SELECTED: `${V2_IMAGES_FOLDER_NAME}/icons/management/api-tokens.svg`,
    ERROR_PAGE: `${V2_IMAGES_FOLDER_NAME}/icons/management/error-pages.svg`,
    ERROR_PAGE_SELECTED: `${V2_IMAGES_FOLDER_NAME}/icons/management/error-pages.svg`,
    PERMISSIONS: `${IMAGES_FOLDER_NAME}/icons/management/deselected-permission.svg`,
    PERMISSIONS_SELECTED: `${IMAGES_FOLDER_NAME}/icons/management/selected-permission.svg`,
    EDR_INTEGRATION: `${V2_IMAGES_FOLDER_NAME}/icons/management/edr.svg`,
    EDR_INTEGRATION_SELECTED: `${V2_IMAGES_FOLDER_NAME}/icons/management/edr.svg`,
    DOWNLOADS: `${V2_IMAGES_FOLDER_NAME}/icons/management/downloads.svg`,
    DOWNLOADS_SELECTED: `${V2_IMAGES_FOLDER_NAME}/icons/management/downloads.svg`,
    DATA_FETCH: `${V2_IMAGES_FOLDER_NAME}/icons/management/data-fetch.svg`,
    SUMMARY_ROUTES: `${V2_IMAGES_FOLDER_NAME}/icons/management/summary-routes.svg`,
    SUMMARY_ROUTES_SELECTED: `${V2_IMAGES_FOLDER_NAME}/icons/management/summary-routes.svg`,
    INFRA_TAGS: `${V2_IMAGES_FOLDER_NAME}/icons/management/infra-tag.svg`,
    INFRA_TAGS_SELECTED: `${V2_IMAGES_FOLDER_NAME}/icons/management/infra-tag.svg`,
  },
  CONFIGURE: {
    ACCESS_POLICY_LOGO: `${V2_IMAGES_FOLDER_NAME}/icons/configure/access-policy.svg`,
    WAF_POLICY_LOGO: `${V2_IMAGES_FOLDER_NAME}/icons/configure/waf-policy.svg`,
    IP_REPUTATION_LOGO: `${V2_IMAGES_FOLDER_NAME}/icons/configure/ip-reputation.svg`,
    DYNAMIC_RISK_LOGO: `${V2_IMAGES_FOLDER_NAME}/icons/configure/dynamic-risk.svg`,
    DEVICE_POSTURE_LOGO: `${V2_IMAGES_FOLDER_NAME}/icons/configure/device-posture.svg`,
    ENDPOINT_INTEGRATION_LOGO: `${V2_IMAGES_FOLDER_NAME}/icons/configure/endpoint-integration.svg`,
    CONNECTIVITY_LOGO: `${V2_IMAGES_FOLDER_NAME}/icons/configure/connectivity.svg`,
    SEGMENTATION_LOGO: `${V2_IMAGES_FOLDER_NAME}/icons/configure/segmentation.svg`,
    OPTIMIZE_LOGO: `${V2_IMAGES_FOLDER_NAME}/icons/configure/optimize.svg`,
    PENCIL: `${V2_IMAGES_FOLDER_NAME}/icons/configure/pencil.svg`,
    BIN: `${V2_IMAGES_FOLDER_NAME}/icons/configure/bin.svg`,
    WARNING: `${V2_IMAGES_FOLDER_NAME}/icons/configure/warning.svg`,
  },
  TRANSIT_360: {
    CHEVRON_DOWN: `${IMAGES_FOLDER_NAME}/icons/common/chevron_down.svg`,
  },
  ERROR_PAGE: {
    LOGO_DYNAMIC_ERROR_PAGE_BACKGROUND: `${IMAGES_FOLDER_NAME}/icons/error-pages/dynamic_error_page_background.svg`,
  },
  AGENT: {
    WINDOWS: `${IMAGES_FOLDER_NAME}/icons/agent/os_windows.svg`,
    MAC: `${IMAGES_FOLDER_NAME}/icons/agent/os_macos.svg`,
  },
  DISCOVERY: {
    STORAGE_TYPES: {
      AWS_S3: `${IMAGES_FOLDER_NAME}/icons/discovery/storage-types/aws_s3.svg`,
      AZURE: `${IMAGES_FOLDER_NAME}/icons/discovery/storage-types/azure.svg`,
      GCP: `${IMAGES_FOLDER_NAME}/icons/discovery/storage-types/gcp.svg`,
    },
  },
  DASHBOARD: {
    MAIN: {
      CLOUD: `${IMAGES_FOLDER_NAME}/icons/main-dashboard/icon_cloud.svg`,
      REGIONS: `${IMAGES_FOLDER_NAME}/icons/main-dashboard/icon_regions.svg`,
      EDGES: `${IMAGES_FOLDER_NAME}/icons/main-dashboard/icon_edge.svg`,
      APP: `${IMAGES_FOLDER_NAME}/icons/main-dashboard/apps_icon_grey.svg`,
      NETWORK: `${IMAGES_FOLDER_NAME}/icons/main-dashboard/networks_icon_grey.svg`,
      DOMAIN: `${IMAGES_FOLDER_NAME}/icons/main-dashboard/domains_icon_grey.svg`,
      CLIENT: `${IMAGES_FOLDER_NAME}/icons/main-dashboard/clients_icon_grey.svg`,
      GEO_CLIENT: `${IMAGES_FOLDER_NAME}/icons/main-dashboard/user_icon_grey_circle.svg`,
      GEO_CLOUD: `${IMAGES_FOLDER_NAME}/icons/main-dashboard/cloud_map_icon_grey_circle.svg`,
      RECOMMENDATIONS_EDGE: `${IMAGES_FOLDER_NAME}/icons/main-dashboard/edge_recommendation_icon.svg`,
      CONNECTOR_GROUPS: `${IMAGES_FOLDER_NAME}/icons/main-dashboard/icon_connector_groups.svg`,
      ISP: `${IMAGES_FOLDER_NAME}/icons/main-dashboard/isp_icon.svg`,
    },
    GUIDE: {
      SINGLE_CLOUD: `${IMAGES_FOLDER_NAME}/icons/guide/single_cloud_selected.svg`,
      MULTI_CLOUD: `${IMAGES_FOLDER_NAME}/icons/guide/multi_cloud_selected.svg`,
      LEGEND_EXISTING: `${IMAGES_FOLDER_NAME}/icons/guide/existing.svg`,
      LEGEND_SPIN_UP: `${IMAGES_FOLDER_NAME}/icons/guide/launch.svg`,
      LEGEND_SPIN_DOWN: `${IMAGES_FOLDER_NAME}/icons/guide/spin_down.svg`,
      AWS_EXISTING: `${IMAGES_FOLDER_NAME}/icons/guide/aws_existing.svg`,
      AWS_EXISTING_CLOSE: `${IMAGES_FOLDER_NAME}/icons/guide/aws_existing_close.svg`,
      AWS_LAUNCH: `${IMAGES_FOLDER_NAME}/icons/guide/aws_launch.svg`,
      AWS_LAUNCH_CLOSE: `${IMAGES_FOLDER_NAME}/icons/guide/aws_launch_close.svg`,
      AWS_SPIN_DOWN: `${IMAGES_FOLDER_NAME}/icons/guide/aws_spin_down.svg`,
      AWS_SPIN_DOWN_CLOSE: `${IMAGES_FOLDER_NAME}/icons/guide/aws_spin_down_close.svg`,
      AZURE_EXISTING: `${IMAGES_FOLDER_NAME}/icons/guide/azure_existing.svg`,
      AZURE_EXISTING_CLOSE: `${IMAGES_FOLDER_NAME}/icons/guide/azure_existing_close.svg`,
      AZURE_LAUNCH: `${IMAGES_FOLDER_NAME}/icons/guide/azure_launch.svg`,
      AZURE_LAUNCH_CLOSE: `${IMAGES_FOLDER_NAME}/icons/guide/azure_launch_close.svg`,
      AZURE_SPIN_DOWN: `${IMAGES_FOLDER_NAME}/icons/guide/azure_spin_down.svg`,
      AZURE_SPIN_DOWN_CLOSE: `${IMAGES_FOLDER_NAME}/icons/guide/azure_spin_down_close.svg`,
      GCP_EXISTING: `${IMAGES_FOLDER_NAME}/icons/guide/gcp_existing.svg`,
      GCP_EXISTING_CLOSE: `${IMAGES_FOLDER_NAME}/icons/guide/gcp_existing_close.svg`,
      GCP_LAUNCH: `${IMAGES_FOLDER_NAME}/icons/guide/gcp_launch.svg`,
      GCP_LAUNCH_CLOSE: `${IMAGES_FOLDER_NAME}/icons/guide/gcp_launch_close.svg`,
      GCP_SPIN_DOWN: `${IMAGES_FOLDER_NAME}/icons/guide/gcp_spin_down.svg`,
      GCP_SPIN_DOWN_CLOSE: `${IMAGES_FOLDER_NAME}/icons/guide/gcp_spin_down_close.svg`,
    },
    SHARED: {
      CLIENTS: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/src_filter_circle.svg`,
      USERS: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/users.svg`,
      USER_GROUPS: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/user-group.svg`,
      APPS: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/apps.svg`,
      APP_GROUPS: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/app-group.svg`,
      CLIENT_APPS: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/client-apps.svg`,
      CALENDAR: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/calendar.svg`,
      APP_GROUP_SEARCH_HISTORY: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/app-group-filter-history.svg`,
      SEARCH_HISTORY_FILTER_AXI_APPS: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/search-history-axi-apps.svg`,
      SEARCH_HISTORY_FILTER_AXI_APP_GROUPS: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/search-history-axi-app-groups.svg`,
      SEARCH_HISTORY_FILTER_USERS: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/search-history-users.svg`,
      SEARCH_HISTORY_FILTER_USER_GROUPS: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/search-history-user-groups.svg`,
      SEARCH_HISTORY_FILTER_CLIENT_APPS: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/search-history-client-apps.svg`,
    },
    INSIGHTS: {
      TRENDING: `${IMAGES_FOLDER_NAME}/icons/common/trending-stat-line.svg`,
      ACTIVE: `${IMAGES_FOLDER_NAME}/icons/common/active-stat-line.svg`,
      SESSION_CLOCK: `${IMAGES_FOLDER_NAME}/icons/common/session-stat-clock.svg`,
      MAP: `${IMAGES_FOLDER_NAME}/icons/common/countries-stat-map.svg`,
      FILTER: {
        USER: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/filter_user.svg`,
        USER_GROUP: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/user_group_filter_history.svg`,
        APPS: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/filter_apps.svg`,
        APP_GROUP: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/app_group.svg`,
        CALENDAR: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/filter_calendar.svg`,
        SELECTED_APPS_FILTER_FORM: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/selected-apps-form.svg`,
        SELECTED_APP_GROUPS_FILTER_FORM: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/selected-app-groups-form.svg`,
        SELECTED_USERS_FILTER_FORM: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/selected-users-form.svg`,
        SELECTED_USER_GROUPS_FILTER_FORM: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/selected-user-groups-form.svg`,
        SELECTED_CLIENT_APPS_FILTER_FORM: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/selected-client-apps-form.svg`,
        DESELECTED_APPS_FILTER_FORM: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/deselected-apps-form.svg`,
        DESELECTED_APP_GROUPS_FILTER_FORM: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/deselected-app-groups-form.svg`,
        DESELECTED_USERS_FILTER_FORM: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/deselected-users-form.svg`,
        DESELECTED_USER_GROUPS_FILTER_FORM: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/deselected-user-groups-form.svg`,
        DESELECTED_CLIENT_APPS_FILTER_FORM: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/deselected-client-apps-form.svg`,
      },
      MCN: {
        SRC_FILTER: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/src_filter_circle.svg`,
        TGT_FILTER: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/tgt_filter_circle.svg`,
        CALENDAR_FILTER: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/calendar_filter_circle.svg`,
        SRC_NETWORK_PANEL: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/src_filter_circle.svg`,
        SRC_NETWORK: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/src-network/src_circle.svg`,
        SRC_REGION: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/src-region/src_circle.svg`,
        SRC_PRIVATE_LINK: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/src-private-link/src_circle.svg`,
        SRC_NETWORK_GROUP: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/src-network-group/src_group_circle.svg`,
        SRC_NETWORK_FILTER_TAB_SELECTED: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/src-network/src_text_orange.svg`,
        SRC_NETWORK_FILTER_TAB_UNSELECTED: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/src-network/src_text_gray.svg`,
        SRC_PRIVATE_LINK_TAB_SELECTED: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/src-private-link/src_text_orange.svg`,
        SRC_PRIVATE_LINK_TAB_UNSELECTED: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/src-private-link/src_text_gray.svg`,
        SRC_NETWORK_GROUP_FILTER_TAB_SELECTED: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/src-network-group/src_group_text_orange.svg`,
        SRC_NETWORK_GROUP_FILTER_TAB_UNSELECTED: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/src-network-group/src_group_text_gray.svg`,
        TGT_NETWORK_PANEL: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/tgt_filter_circle.svg`,
        TGT_NETWORK: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/tgt-network/tgt_circle.svg`,
        TGT_REGION: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/tgt-region/tgt_circle.svg`,
        TGT_NETWORK_GROUP: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/tgt-network-group/tgt_group_circle.svg`,
        TGT_NETWORK_FILTER_TAB_SELECTED: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/tgt-network/tgt_text_orange.svg`,
        TGT_NETWORK_FILTER_TAB_UNSELECTED: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/tgt-network/tgt_text_gray.svg`,
        TGT_NETWORK_GROUP_FILTER_TAB_SELECTED: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/tgt-network-group/tgt_group_text_orange.svg`,
        TGT_NETWORK_GROUP_FILTER_TAB_UNSELECTED: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/tgt-network-group/tgt_group_text_gray.svg`,
        SRC_NETWORK_RECENT_SEARCH: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/src-network/src_text.svg`,
        SRC_PRIVATE_LINK_RECENT_SEARCH: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/src-private-link/src_text_orange.svg`,
        SRC_NETWORK_GROUP_RECENT_SEARCH: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/src-network-group/src_group_text.svg`,
        SRC_REGION_RECENT_SEARCH: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/src-network/src_text.svg`,
        TGT_NETWORK_GROUP_RECENT_SEARCH: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/tgt-network-group/tgt_group_text.svg`,
        TGT_NETWORK_RECENT_SEARCH: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/tgt-network/tgt_text.svg`,
        TGT_REGION_RECENT_SEARCH: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/tgt-network/tgt_text.svg`,
        NETWORKS_SELECTED: `${IMAGES_FOLDER_NAME}/icons/mcn-insights/network_selected.svg`,
      },
    },
    DIAGNOSE: {
      SELECTED_AWS_UP: `${IMAGES_FOLDER_NAME}/icons/diagnostics/selected_icon_green_aws.svg`,
      SELECTED_GCP_UP: `${IMAGES_FOLDER_NAME}/icons/diagnostics/selected_icon_green_gcp.svg`,
      SELECTED_AZURE_UP: `${IMAGES_FOLDER_NAME}/icons/diagnostics/selected_icon_green_azure.svg`,
      SELECTED_AWS_DOWN: `${IMAGES_FOLDER_NAME}/icons/diagnostics/selected_icon_red_aws.svg`,
      SELECTED_GCP_DOWN: `${IMAGES_FOLDER_NAME}/icons/diagnostics/selected_icon_red_gcp.svg`,
      SELECTED_AZURE_DOWN: `${IMAGES_FOLDER_NAME}/icons/diagnostics/selected_icon_red_azure.svg`,
      AWS_UP: `${IMAGES_FOLDER_NAME}/icons/diagnostics/icon_green_aws.svg`,
      AWS_DOWN: `${IMAGES_FOLDER_NAME}/icons/diagnostics/icon_red_aws.svg`,
      GCP_UP: `${IMAGES_FOLDER_NAME}/icons/diagnostics/icon_green_gcp.svg`,
      GCP_DOWN: `${IMAGES_FOLDER_NAME}/icons/diagnostics/icon_red_gcp.svg`,
      AZURE_UP: `${IMAGES_FOLDER_NAME}/icons/diagnostics/icon_green_azure.svg`,
      AZURE_DOWN: `${IMAGES_FOLDER_NAME}/icons/diagnostics/icon_red_azure.svg`,
      APP_DOWN: `${IMAGES_FOLDER_NAME}/icons/diagnostics/icon_app_red.svg`,
      APP_UP: `${IMAGES_FOLDER_NAME}/icons/diagnostics/icon_app_green.svg`,
      APP_UNREACHABLE: `${IMAGES_FOLDER_NAME}/icons/diagnostics/icon_app_gray.svg`,
      EDGE_DOWN: `${IMAGES_FOLDER_NAME}/icons/diagnostics/icon_edge_red.svg`,
      EDGE_UP: `${IMAGES_FOLDER_NAME}/icons/diagnostics/icon_edge_green.svg`,
      EDGE_BLACK: `${IMAGES_FOLDER_NAME}/icons/diagnostics/icon_edge_black.svg`,
      SELECTED_AWS_PARTIAL: `${IMAGES_FOLDER_NAME}/icons/diagnostics/selected_icon_amber_aws.svg`,
      SELECTED_GCP_PARTIAL: `${IMAGES_FOLDER_NAME}/icons/diagnostics/selected_icon_amber_gcp.svg`,
      SELECTED_AZURE_PARTIAL: `${IMAGES_FOLDER_NAME}/icons/diagnostics/selected_icon_amber_azure.svg`,
      AWS_PARTIAL: `${IMAGES_FOLDER_NAME}/icons/diagnostics/icon_amber_aws.svg`,
      GCP_PARTIAL: `${IMAGES_FOLDER_NAME}/icons/diagnostics/icon_amber_gcp.svg`,
      AZURE_PARTIAL: `${IMAGES_FOLDER_NAME}/icons/diagnostics/icon_amber_azure.svg`,
      PRIVATE_UP: `${IMAGES_FOLDER_NAME}/icons/diagnostics/icon_green_private.svg`,
      PRIVATE_DOWN: `${IMAGES_FOLDER_NAME}/icons/diagnostics/icon_red_private.svg`,
      PRIVATE_PARTIAL: `${IMAGES_FOLDER_NAME}/icons/diagnostics/icon_amber_private.svg`,
      PRIVATE_NEUTRAL: `${IMAGES_FOLDER_NAME}/icons/diagnostics/icon_private.svg`,
      SELECTED_PRIVATE_DOWN: `${IMAGES_FOLDER_NAME}/icons/diagnostics/selected_icon_red_private.svg`,
      SELECTED_PRIVATE_PARTIAL: `${IMAGES_FOLDER_NAME}/icons/diagnostics/selected_icon_amber_private.svg`,
      SELECTED_PRIVATE_UP: `${IMAGES_FOLDER_NAME}/icons/diagnostics/selected_icon_green_private.svg`,
      CLOUD_TRACER: {
        ARROW_LEFT: `${IMAGES_FOLDER_NAME}/icons/diagnostics/cloud-tracer/arrow_left.svg`,
        ARROW_RIGHT: `${IMAGES_FOLDER_NAME}/icons/diagnostics/cloud-tracer/arrow_right.svg`,
        CONNECTOR: `${IMAGES_FOLDER_NAME}/icons/diagnostics/cloud-tracer/connector.svg`,
        DESKTOP: `${IMAGES_FOLDER_NAME}/icons/diagnostics/cloud-tracer/desktop.svg`,
        EDGE: `${IMAGES_FOLDER_NAME}/icons/diagnostics/cloud-tracer/edge.svg`,
        EXPORT: `${IMAGES_FOLDER_NAME}/icons/diagnostics/cloud-tracer/export.svg`,
        FIREWALL: `${IMAGES_FOLDER_NAME}/icons/diagnostics/cloud-tracer/firewall.svg`,
        INTERNET: `${IMAGES_FOLDER_NAME}/icons/diagnostics/cloud-tracer/internet.svg`,
        LOADER_NEW: `${IMAGES_FOLDER_NAME}/icons/diagnostics/cloud-tracer/loader_new.gif`,
        NETWORK: `${IMAGES_FOLDER_NAME}/icons/diagnostics/cloud-tracer/network.svg`,
        REFRESH: `${IMAGES_FOLDER_NAME}/icons/diagnostics/cloud-tracer/refresh.svg`,
        RIGHT: `${IMAGES_FOLDER_NAME}/icons/diagnostics/cloud-tracer/right.svg`,
        TGW: `${IMAGES_FOLDER_NAME}/icons/diagnostics/cloud-tracer/tgw.svg`,
        WARNING: `${IMAGES_FOLDER_NAME}/icons/diagnostics/cloud-tracer/warning.svg`,
        CHECK: `${IMAGES_FOLDER_NAME}/icons/diagnostics/cloud-tracer/check.svg`,
        SUBNET: `${IMAGES_FOLDER_NAME}/icons/diagnostics/cloud-tracer/subnet.svg`,
        PEERING: `${IMAGES_FOLDER_NAME}/icons/diagnostics/cloud-tracer/peering.svg`,
        CLOSE: `${IMAGES_FOLDER_NAME}/icons/diagnostics/cloud-tracer/close.svg`,
        REVERSE: `${IMAGES_FOLDER_NAME}/icons/diagnostics/cloud-tracer/reverse.svg`,
        ARROW_LEFT_BIG: `${IMAGES_FOLDER_NAME}/icons/diagnostics/cloud-tracer/arrow_left_big.svg`,
        ARROW_RIGHT_BIG: `${IMAGES_FOLDER_NAME}/icons/diagnostics/cloud-tracer/arrow_right_big.svg`,
        CHEVRON_RIGHT: `${IMAGES_FOLDER_NAME}/icons/diagnostics/cloud-tracer/chevron-right.svg`,
        SHARED_SERVICE: `${IMAGES_FOLDER_NAME}/icons/diagnostics/cloud-tracer/shared_service.svg`,
        UNKNOWN: `${IMAGES_FOLDER_NAME}/icons/diagnostics/cloud-tracer/unknown.svg`,
      },

      INFRASTRUCTURE: {
        AWS: {
          EKS: `${IMAGES_FOLDER_NAME}/icons/diagnostics/infrastructure/aws/icon_aws_eks.svg`,
          GATEWAY: `${IMAGES_FOLDER_NAME}/icons/diagnostics/infrastructure/aws/icon_aws_nat_gateway.svg`,
          GATEWAY_DATA: `${IMAGES_FOLDER_NAME}/icons/diagnostics/infrastructure/aws/icon_aws_nat_gateway_data.svg`,
          NLB: `${IMAGES_FOLDER_NAME}/icons/diagnostics/infrastructure/aws/icon_aws_nlb.svg`,
          NLB_DATA: `${IMAGES_FOLDER_NAME}/icons/diagnostics/infrastructure/aws/icon_aws_nlb_data.svg`,
          VPC: `${IMAGES_FOLDER_NAME}/icons/diagnostics/infrastructure/aws/icon_aws_vpc.svg`,
          IP: `${IMAGES_FOLDER_NAME}/icons/diagnostics/infrastructure/aws/icon_aws_elastic_ip.svg`,
          WORKER_NODES: `${IMAGES_FOLDER_NAME}/icons/diagnostics/infrastructure/aws/icon_aws_worker_nodes.svg`,
          VOLUMES: `${IMAGES_FOLDER_NAME}/icons/diagnostics/infrastructure/aws/icon_aws_ebs.svg`,
        },
        AZURE: {
          PUBLIC_IP: `${IMAGES_FOLDER_NAME}/icons/diagnostics/infrastructure/azure/icon_azure_public_ip.svg`,
          KUBERNETES: `${IMAGES_FOLDER_NAME}/icons/diagnostics/infrastructure/azure/icon_azure_kubernetes_services.svg`,
          LOAD_BALANCER: `${IMAGES_FOLDER_NAME}/icons/diagnostics/infrastructure/azure/icon_azure_load_balancers.svg`,
          LOAD_BALANCER_DATA: `${IMAGES_FOLDER_NAME}/icons/diagnostics/infrastructure/azure/icon_azure_data_processing.svg`,
          VIRTUAL_NETWORKS: `${IMAGES_FOLDER_NAME}/icons/diagnostics/infrastructure/azure/icon_azure_virtual_networks.svg`,
          VM_SCALE: `${IMAGES_FOLDER_NAME}/icons/diagnostics/infrastructure/azure/icon_azure_vm_scale.svg`,
          DISKS: `${IMAGES_FOLDER_NAME}/icons/diagnostics/infrastructure/azure/icon_azure_disk.svg`,
          VWAN_HUB: `${IMAGES_FOLDER_NAME}/icons/diagnostics/infrastructure/azure/icon_azure_vhub_v2.svg`,
        },
        GCP: {
          CLOUD_EXTERNAL_IP: `${IMAGES_FOLDER_NAME}/icons/diagnostics/infrastructure/gcp/icon_gcp_cloud_external_ip.svg`,
          CLOUD_LOAD_BALANCING: `${IMAGES_FOLDER_NAME}/icons/diagnostics/infrastructure/gcp/icon_gcp_cloud_load_balancing.svg`,
          CLOUD_LOAD_BALANCING_DATA: `${IMAGES_FOLDER_NAME}/icons/diagnostics/infrastructure/gcp/icon_gcp_data_processing.svg`,
          COMPUTE_ENGINE: `${IMAGES_FOLDER_NAME}/icons/diagnostics/infrastructure/gcp/icon_gcp_compute_engine.svg`,
          KUBERNETES: `${IMAGES_FOLDER_NAME}/icons/diagnostics/infrastructure/gcp/icon_gcp_kubernetes.svg`,
          VIRTUAL_PRIVATE_CLOUD: `${IMAGES_FOLDER_NAME}/icons/diagnostics/infrastructure/gcp/icon_gcp_virtual_private_cloud.svg`,
          DISKS: `${IMAGES_FOLDER_NAME}/icons/diagnostics/infrastructure/gcp/icon_gcp_persistent_disk.svg`,
        },
        PRIVATE: {
          VM: `${IMAGES_FOLDER_NAME}/icons/diagnostics/infrastructure/private/icon_vm.svg`,
          BOLT: `${IMAGES_FOLDER_NAME}/icons/diagnostics/infrastructure/private/icon_bolt.svg`,
        },
        DATA_DOWNSTREAM: `${IMAGES_FOLDER_NAME}/icons/diagnostics/infrastructure/icon_data_downstream.svg`,
        DATA_UPSTREAM: `${IMAGES_FOLDER_NAME}/icons/diagnostics/infrastructure/icon_data_upstream.svg`,
      },
      HEALTH_STATUS: {
        EDGE: `${IMAGES_FOLDER_NAME}/icons/diagnostics/accessibility/icon_edge.svg`,
        SELECTED_EDGE: `${IMAGES_FOLDER_NAME}/icons/diagnostics/accessibility/icon_orange_edge.svg`,
        APP: `${IMAGES_FOLDER_NAME}/icons/diagnostics/accessibility/icon_app.svg`,
        SELECTED_APP: `${IMAGES_FOLDER_NAME}/icons/diagnostics/accessibility/icon_orange_app.svg`,
        SEGMENT: `${IMAGES_FOLDER_NAME}/icons/diagnostics/accessibility/icon_segment.svg`,
        SELECTED_SEGMENT: `${IMAGES_FOLDER_NAME}/icons/diagnostics/accessibility/icon_orange_segment.svg`,
      },
    },
    POLICY: {
      USERS: `${IMAGES_FOLDER_NAME}/icons/policy/icon_user.svg`,
      SELECTED_USERS: `${IMAGES_FOLDER_NAME}/icons/policy/icon_green_user.svg`,
      IDP: `${IMAGES_FOLDER_NAME}/icons/policy/icon_idp.svg`,
      SELECTED_IDP: `${IMAGES_FOLDER_NAME}/icons/policy/icon_green_idp.svg`,
      DEVICES: `${IMAGES_FOLDER_NAME}/icons/policy/icon_devices.svg`,
      SELECTED_DEVICES: `${IMAGES_FOLDER_NAME}/icons/policy/icon_green_devices.svg`,
      NETWORKACL: `${IMAGES_FOLDER_NAME}/icons/policy/icon_networks.svg`,
      SELECTED_NETWORKACL: `${IMAGES_FOLDER_NAME}/icons/policy/icon_green_networks.svg`,
      TIME: `${IMAGES_FOLDER_NAME}/icons/policy/icon_time.svg`,
      SELECTED_TIME: `${IMAGES_FOLDER_NAME}/icons/policy/icon_green_time.svg`,
      URL: `${IMAGES_FOLDER_NAME}/icons/policy/icon_url.svg`,
      SELECTED_URL: `${IMAGES_FOLDER_NAME}/icons/policy/icon_green_url.svg`,
      ADVANCED: `${IMAGES_FOLDER_NAME}/icons/policy/icon_advances.svg`,
      SELECTED_ADVANCED: `${IMAGES_FOLDER_NAME}/icons/policy/icon_green_advances.svg`,
      APPLICATIONS: `${IMAGES_FOLDER_NAME}/icons/policy/icon_applications.svg`,
      SELECTED_APPLICATIONS: `${IMAGES_FOLDER_NAME}/icons/policy/icon_green_applications.svg`,
      DEVICEPOSTUREPROFILES: `${IMAGES_FOLDER_NAME}/icons/policy/icon_device_posture.svg`,
      SELECTED_DEVICEPOSTUREPROFILES: `${IMAGES_FOLDER_NAME}/icons/policy/icon_green_device_posture.svg`,
      DUPLICATE: `${IMAGES_FOLDER_NAME}/icons/policy/icon_duplicate.svg`,
      READ_ONLY: `${IMAGES_FOLDER_NAME}/icons/policy/icon_eye.svg`,
      NETWORKS: `${IMAGES_FOLDER_NAME}/icons/policy/icon_location.svg`,
      SELECTED_NETWORKS: `${IMAGES_FOLDER_NAME}/icons/policy/icon_green_location.svg`,
    },
  },
  APP_ONBOARD: {
    SSL: {
      CERT_ON: `${IMAGES_FOLDER_NAME}/icons/app-onboard/ssl_cert/ssl_cert_filled.svg`,
      CERT_OFF: `${IMAGES_FOLDER_NAME}/icons/app-onboard/ssl_cert/ssl_cert_empty.svg`,
    },
    POLICY: {
      'IMPLICIT-DENY': `${IMAGES_FOLDER_NAME}/icons/app-onboard/policy/denyApp-onboard.svg`,
      'ALLOW-ALL': `${IMAGES_FOLDER_NAME}/icons/app-onboard/policy/allowApp-onboard.svg`,
      'DENY-ALL': `${IMAGES_FOLDER_NAME}/icons/app-onboard/policy/denyApp-onboard.svg`,
      'ALLOW-ALL-USERS': `${IMAGES_FOLDER_NAME}/icons/app-onboard/policy/allowApp-onboard.svg`,
      'DENY-ALL-USERS': `${IMAGES_FOLDER_NAME}/icons/app-onboard/policy/denyApp-onboard.svg`,
      customize: `${IMAGES_FOLDER_NAME}/icons/app-onboard/policy/customApp-onboard.svg`,
      SECURITY_ON: `${IMAGES_FOLDER_NAME}/icons/app-onboard/policy/security_filled.svg`,
      SECURITY_OFF: `${IMAGES_FOLDER_NAME}/icons/app-onboard/policy/security_empty.svg`,
      'DEFAULT-CONNECT-POLICY': `${IMAGES_FOLDER_NAME}/icons/app-onboard/policy/default-connect-policy.svg`,
      'DEFAULT-MCN-POLICY': `${IMAGES_FOLDER_NAME}/icons/app-onboard/policy/default-connect-policy.svg`,
    },
    CLOUD: {
      END_POINT: `${IMAGES_FOLDER_NAME}/icons/app-onboard/icon_end_point.svg`,
    },
    APP_TO_APP: {
      VPC: `${IMAGES_FOLDER_NAME}/icons/app-onboard/capp/icon_vpc.svg`,
      CLOUD: `${IMAGES_FOLDER_NAME}/icons/app-onboard/capp/icon_cloud.svg`,
      ENDPOINT: `${IMAGES_FOLDER_NAME}/icons/app-onboard/capp/icon_endpoint.svg`,
      REGION: `${IMAGES_FOLDER_NAME}/icons/app-onboard/capp/icon_region.svg`,
      ADD_CLOUD: `${IMAGES_FOLDER_NAME}/icons/app-onboard/capp/icon_new_cloud.svg`,
      APPS: `${IMAGES_FOLDER_NAME}/icons/user-dashboard/deselected-app-groups-form.svg`,
    },
    ONBOARDING: `${IMAGES_FOLDER_NAME}/icons/app-onboard/icon_onboarding.svg`,
    CONFIGURING: `${IMAGES_FOLDER_NAME}/icons/app-onboard/icon_configuring.svg`,
    DISCOVERED: `${IMAGES_FOLDER_NAME}/icons/app-onboard/icon_discovered.svg`,
    ONBOARDED: `${IMAGES_FOLDER_NAME}/icons/app-onboard/icon_onboarded.svg`,
    OFFBOARDED: `${IMAGES_FOLDER_NAME}/icons/app-onboard/icon_offboarded.svg`,
    FAILED: `${IMAGES_FOLDER_NAME}/icons/app-onboard/icon_failed.svg`,
    UNAPPLIED_CONFIGURATION: `${IMAGES_FOLDER_NAME}/icons/app-onboard/unapplied_app_config.svg`,
    APP_SWITCH: {
      APP_SELECTED: `${IMAGES_FOLDER_NAME}/icons/app-onboard/selected_apps_icon.svg`,
      APP_DESELECTED: `${IMAGES_FOLDER_NAME}/icons/app-onboard/deselected_apps_icon.svg`,
      GROUP_SELECTED: `${IMAGES_FOLDER_NAME}/icons/app-onboard/selected_group_apps.svg`,
      GROUP_DESELECTED: `${IMAGES_FOLDER_NAME}/icons/app-onboard/deselected_group_apps.svg`,
    },
    DISCOVERY: {
      APP_BLUE: `${IMAGES_FOLDER_NAME}/icons/app-onboard/app-discovery/apps_icon_blue.svg`,
      APP_GRAY: `${IMAGES_FOLDER_NAME}/icons/app-onboard/app-discovery/apps_icon_light_gray.svg`,
      APP_DARK: `${IMAGES_FOLDER_NAME}/icons/app-onboard/app-discovery/dark_apps_icon.svg`,
      CLOUD: `${IMAGES_FOLDER_NAME}/icons/app-onboard/app-discovery/csp_icon_dark_gray.svg`,
      REGION: `${IMAGES_FOLDER_NAME}/icons/app-onboard/app-discovery/region_icon_dark_gray.svg`,
      SPACESHIP: `${IMAGES_FOLDER_NAME}/icons/app-onboard/app-discovery/spaceship_icon_dark_gray.svg`,
      SUBNET_BLUE: `${IMAGES_FOLDER_NAME}/icons/app-onboard/app-discovery/subnets_icon_blue.svg`,
      SUBNET_DARK: `${IMAGES_FOLDER_NAME}/icons/app-onboard/app-discovery/subnets_icon_dark_gray.svg`,
      SUBNET_GRAY: `${IMAGES_FOLDER_NAME}/icons/app-onboard/app-discovery/subnets_icon_light_gray.svg`,
      TABLE_DARK: `${IMAGES_FOLDER_NAME}/icons/app-onboard/app-discovery/table_icon_dark_gray.svg`,
      TABLE_GRAY: `${IMAGES_FOLDER_NAME}/icons/app-onboard/app-discovery/table_icon_light_gray.svg`,
      TREE_DARK: `${IMAGES_FOLDER_NAME}/icons/app-onboard/app-discovery/tree_icon_dark_gray.svg`,
      TREE_GRAY: `${IMAGES_FOLDER_NAME}/icons/app-onboard/app-discovery/tree_icon_light_gray.svg`,
      VPC_DARK: `${IMAGES_FOLDER_NAME}/icons/app-onboard/app-discovery/vpc_icon_dark_gray.svg`,
      AWS_CSP: `${IMAGES_FOLDER_NAME}/icons/app-onboard/app-discovery/aws_csp_icon.svg`,
      AZURE_CSP: `${IMAGES_FOLDER_NAME}/icons/app-onboard/app-discovery/azure_csp_icon.svg`,
      GCP_CSP: `${IMAGES_FOLDER_NAME}/icons/app-onboard/app-discovery/gcp_csp_icon.svg`,
      BLUE_CHECK_MARK: `${IMAGES_FOLDER_NAME}/icons/app-onboard/app-discovery/blue_check_mark.svg`,
    },
  },
  APP_NETWORK_DASHBOARD: {
    LEGENDS: {
      ONBOARDING: `${IMAGES_FOLDER_NAME}/icons/app-network-dashboard/icon_onboarding.svg`,
      DECOMMISSIONING: `${IMAGES_FOLDER_NAME}/icons/app-network-dashboard/icon_onboarding.svg`,
      CONFIGURING: `${IMAGES_FOLDER_NAME}/icons/app-network-dashboard/icon_configuring.svg`,
      ONBOARDED: `${IMAGES_FOLDER_NAME}/icons/app-network-dashboard/icon_onboarded.svg`,
      OFFBOARDED: `${IMAGES_FOLDER_NAME}/icons/app-network-dashboard/icon_offboarded.svg`,
      FAILED: `${IMAGES_FOLDER_NAME}/icons/app-network-dashboard/icon_failed.svg`,
      UNAPPLIED_CONFIGURATION: `${IMAGES_FOLDER_NAME}/icons/app-network-dashboard/icon_unapplied.svg`,
      SELECTED_ONBOARDING: `${IMAGES_FOLDER_NAME}/icons/app-network-dashboard/icon_selected_onboarding.svg`,
      SELECTED_DECOMMISSIONING: `${IMAGES_FOLDER_NAME}/icons/app-network-dashboard/icon_selected_onboarding.svg`,
      SELECTED_CONFIGURING: `${IMAGES_FOLDER_NAME}/icons/app-network-dashboard/icon_selected_configuring.svg`,
      SELECTED_ONBOARDED: `${IMAGES_FOLDER_NAME}/icons/app-network-dashboard/icon_selected_onboarded.svg`,
      SELECTED_OFFBOARDED: `${IMAGES_FOLDER_NAME}/icons/app-network-dashboard/icon_selected_offboarded.svg`,
      SELECTED_FAILED: `${IMAGES_FOLDER_NAME}/icons/app-network-dashboard/icon_selected_failed.svg`,
      SELECTED_UNAPPLIED_CONFIGURATION: `${IMAGES_FOLDER_NAME}/icons/app-network-dashboard/icon_selected_unapplied.svg`,
    },
  },
  SHARED_SERVICE: {
    FIREWALL: `${IMAGES_FOLDER_NAME}/icons/common/firewall.svg`,
    PALO_ALTO_FIREWALL: `${IMAGES_FOLDER_NAME}/icons/third-party/firewall/palo-alto.svg`,
    CHECKPOINT_FIREWALL: `${IMAGES_FOLDER_NAME}/icons/third-party/firewall/checkpoint.svg`,
    EDIT_SELECTED: `${IMAGES_FOLDER_NAME}/icons/common/shared-service/edit_selected.svg`,
    PENDING_SELECTED: `${IMAGES_FOLDER_NAME}/icons/common/shared-service/approval_pending_selected.svg`,
  },
  SECURITY: {
    WAF: {
      WAF_MAPPING: `${IMAGES_FOLDER_NAME}/icons/common/waf_mapping.svg`,
    },
  },
  RELEASES: {
    PROSIMO: `${IMAGES_FOLDER_NAME}/icons/common/icon_prosimo_release_notes.svg`,
    BOLT: `${IMAGES_FOLDER_NAME}/icons/release-notes/bolt.png`,
    BUG: `${IMAGES_FOLDER_NAME}/icons/release-notes/bug.png`,
    NEW: `${IMAGES_FOLDER_NAME}/icons/release-notes/new.png`,
  },
  CLOUD_TRANSIT: {
    ACTIVE_EDGE: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/active_edges.svg`,
    FAILED_EDGE: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/fail_edges.svg`,
    PARTIAL_FAILED_EDGE: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/partial_failure-edges.svg`,
    HEALTHY_SEGMENT: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/healthy_segments.svg`,
    PROBLEMATIC_SEGMENT: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/problematic_segments.svg`,
    SWITCH_VIEW: {
      CL_EDGE_SELECTED: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/selected_cloud.svg`,
      CL_EDGE_DESELECTED: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/deselected_cloud.svg`,
      CL_SEGMENT_SELECTED: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/selected_segments.svg`,
      CL_SEGMENT_DESELECTED: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/deselected_segments.svg`,
    },
    SOURCE: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/source_icon.svg`,
    USERS_CLOUD_TRANSIT: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/icon_users_cloud_transit.svg`,
    APPS_CLOUD_TRANSIT: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/icon_apps_cloud_transit.svg`,
    FILTER_ICON_CLOUD: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/filter_icon_cloud_transit_cloud.svg`,
    FILTER_ICON_EDGE: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/filter_icon_cloud_transit_edge.svg`,
    FQDN_ICON: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/fqdn.svg`,
    SEGMENT_GREEN_ICON: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/segment_green.svg`,
    SEGMENT_RED_ICON: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/segment_red.svg`,
    FIRST_MILE_ICON: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/first_mile.svg`,
    DOUBLE_ARROW_RED_ICON: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/double_arrow_red.svg`,
    DOUBLE_ARROW_GREEN_ICON: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/double_arrow_green.svg`,
    FIRST_MILE_BLACK_ICON: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/first_mile_black.svg`,
    APP_PRIMARY_ICON: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/app_primary.svg`,
    SEGMENT_BLACK_ICON: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/segment.svg`,
    SELECTED_USER_ICON: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/selected_user.svg`,
    SELECTED_EDGE_ICON: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/selected_edge.svg`,
    DESELECTED_USER_ICON: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/deselected_user.svg`,
    DESELECTED_EDGE_ICON: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/deselected_edge.svg`,
    EDGE_FILTER_ICON: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/edge_filter.svg`,
    APP_BACKUP: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/app_backup.svg`,
    CALENDAR_DESELECTED: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/calendar_icon.svg`,
    CALENDAR_SELECTED: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/blue_calendar_icon.svg`,
    FILTER_DESELECTED: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/filter_icon.svg`,
    FILTER_SELECTED: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/blue_filter_icon.svg`,
    ONBOARDED_APP: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/app_icon.svg`,
    USERS: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/users_icon.svg`,
    CALCULATOR: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/calculator_icon.svg`,
    ONBOARDED_NETWORK: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/network_icon.svg`,
    PRIVATE_LINK: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/private_link_icon.svg`,
    SELECTED_NETWORK: `${IMAGES_FOLDER_NAME}/icons/cloud-transit/selected_network_icon.svg`,
  },
  VISUAL_ONBOARD: {
    DC: `${IMAGES_FOLDER_NAME}/icons/app-onboard/visual-onboard/dc.svg`,
    TGW: `${IMAGES_FOLDER_NAME}/icons/app-onboard/visual-onboard/tgw.svg`,
    PEERING: `${IMAGES_FOLDER_NAME}/icons/app-onboard/visual-onboard/peering.svg`,
    DXGW: `${IMAGES_FOLDER_NAME}/icons/app-onboard/visual-onboard/dxgw.svg`,
    EDGE: `${IMAGES_FOLDER_NAME}/icons/app-onboard/visual-onboard/edge_marker.svg`,
    EDGE_DISABLED: `${IMAGES_FOLDER_NAME}/icons/app-onboard/visual-onboard/cloud_marker.svg`,
    EDGE_ERROR: `${IMAGES_FOLDER_NAME}/icons/app-onboard/visual-onboard/error_edge_marker.svg`,
    REGION_COMPLETE: `${IMAGES_FOLDER_NAME}/icons/app-onboard/visual-onboard/complete.svg`,
    REGION_INCOMPLETE: `${IMAGES_FOLDER_NAME}/icons/app-onboard/visual-onboard/incomplete.svg`,
    DATACENTER: `${IMAGES_FOLDER_NAME}/icons/app-onboard/visual-onboard/datacenter.svg`,
    VHUB: `${IMAGES_FOLDER_NAME}/icons/diagnostics/infrastructure/azure/icon_azure_vhub_v2.svg`,
    EXPORT: `${IMAGES_FOLDER_NAME}/icons/app-onboard/visual-onboard/export.svg`,
    ERROR: `${IMAGES_FOLDER_NAME}/icons/app-onboard/visual-onboard/error.svg`,
    TRANSIT_NAV_ICON: `${IMAGES_FOLDER_NAME}/icons/app-onboard/visual-onboard/transit.svg`,
  },
  COST_DASHBOARD: {
    COST_BAR_VIEW: `${IMAGES_FOLDER_NAME}/icons/cost-dashboard/icon_bar_view.svg`,
    COST_LINE_VIEW: `${IMAGES_FOLDER_NAME}/icons/cost-dashboard/icon_line_view.svg`,
    COST_DASHBOARD_SELECTED: `${IMAGES_FOLDER_NAME}/icons/cost-dashboard/icon_cost_dollar_selected.svg`,
    COST_DASHBOARD_DESELECTED: `${IMAGES_FOLDER_NAME}/icons/cost-dashboard/icon_cost_dollar_deselected.svg`,
  },
  ENTITIES: {
    APPLICATION: `${IMAGES_FOLDER_NAME}/v2/icons/entities/application.svg`,
    NETWORK: `${IMAGES_FOLDER_NAME}/v2/icons/entities/network.svg`,
    SHARED_SERVICE: `${IMAGES_FOLDER_NAME}/v2/icons/entities/shared_service.svg`,
    PRIVATE_LINK: `${IMAGES_FOLDER_NAME}/v2/icons/entities/private_link.svg`,
  },
  PROFILE: {
    THEME_LIGHT: `${IMAGES_FOLDER_NAME}/profile/theme-light.svg`,
    THEME_DARK: `${IMAGES_FOLDER_NAME}/profile/theme-dark.svg`,
  },
};

export const MIME_TYPES = {
  APPLICATION_JSON: 'application/json',
};

/**
 * Stepper component configurations
 */

export const STEPPER = {
  APP_ONBOARDING_CONFIGS: [
    {
      info: 'App settings',
      label: 'Settings',
      isComplete: false,
      isDisabled: false,
      showStepConnector: true,
      order: 1,
      bgGraphicPath: IMAGE_PATHS.BG_GRAPHICS.SETTINGS,
      isCurrent: false,
      name: 'SETTINGS',
      route: 'SETTINGS',
    } as StepperConfig,
    {
      info: `Cloud configurations for Target setup`,
      label: `Cloud`,
      isComplete: false,
      isDisabled: false,
      showStepConnector: true,
      order: 2,
      bgGraphicPath: IMAGE_PATHS.BG_GRAPHICS.CLOUD,
      isCurrent: false,
      name: 'CLOUD_AXI',
      route: 'CLOUD/AXI',
    } as StepperConfig,
    {
      info: `Cloud configurations for ${APP_TYPE_LABELS.SOURCE_APP()} setup`,
      label: `Cloud config for ${APP_TYPE_LABELS.SOURCE_APP()}`,
      isComplete: false,
      isDisabled: false,
      showStepConnector: true,
      order: 3,
      bgGraphicPath: IMAGE_PATHS.BG_GRAPHICS.CLOUD,
      isCurrent: false,
      name: 'CLOUD_CLI',
      route: 'CLOUD/CLI',
    } as StepperConfig,
    {
      info: 'DNS settings',
      label: 'DNS',
      isComplete: false,
      isDisabled: false,
      showStepConnector: true,
      order: 4,
      bgGraphicPath: IMAGE_PATHS.BG_GRAPHICS.DNS,
      isCurrent: false,
      name: 'DNS',
      route: 'DNS',
    } as StepperConfig,
    {
      info: 'SSL configuration',
      label: 'Certificates',
      isComplete: false,
      isDisabled: false,
      showStepConnector: true,
      order: 5,
      bgGraphicPath: IMAGE_PATHS.BG_GRAPHICS.SSL,
      isCurrent: false,
      name: 'SSL',
      route: 'SSL',
    } as StepperConfig,
    {
      info: 'Optimization',
      label: 'Optimization',
      isComplete: false,
      isDisabled: false,
      showStepConnector: true,
      order: 6,
      bgGraphicPath: IMAGE_PATHS.BG_GRAPHICS.OPTIMIZATION,
      isCurrent: false,
      name: 'OPTIMIZATION',
      route: 'OPTIMIZATION',
    } as StepperConfig,
    {
      info: 'Security details',
      label: 'Security',
      isComplete: false,
      isDisabled: false,
      showStepConnector: true,
      order: 7,
      bgGraphicPath: IMAGE_PATHS.BG_GRAPHICS.POLICY,
      isCurrent: false,
      name: 'SECURITY',
      route: 'SECURITY',
    } as StepperConfig,
    {
      info: `Attach ${APP_TYPE_LABELS.TARGET_APP(false)}`,
      label: `Attach ${APP_TYPE_LABELS.TARGET_APP(false)}`,
      isComplete: false,
      isDisabled: false,
      showStepConnector: true,
      order: 8,
      bgGraphicPath: IMAGE_PATHS.BG_GRAPHICS.POLICY,
      isCurrent: false,
      name: 'APPS',
      route: 'APPS',
    } as StepperConfig,
    {
      info: 'Onboard app',
      label: 'Onboard',
      isComplete: false,
      isDisabled: false,
      showStepConnector: false,
      order: 9,
      bgGraphicPath: IMAGE_PATHS.BG_GRAPHICS.SPACESHIP,
      isCurrent: false,
      name: 'ONBOARD',
      route: 'ONBOARD',
    } as StepperConfig,
  ],
  PROSIMO_APP_ONBOARDING_CONFIGS: [
    {
      info: 'cloud setup',
      label: 'Cloud',
      isComplete: true,
      isDisabled: false,
      isCurrent: true,
      showStepConnector: true,
      order: 1,
      bgGraphicPath: IMAGE_PATHS.BG_GRAPHICS.CLOUD,
      name: 'CLOUD',
      route: 'CLOUD',
    } as StepperConfig,
    {
      info: 'IDP setup',
      label: 'IDP',
      isComplete: false,
      isDisabled: false,
      showStepConnector: true,
      order: 2,
      bgGraphicPath: IMAGE_PATHS.BG_GRAPHICS.IDP,
      isCurrent: false,
      name: 'IDP',
      route: 'IDP',
    } as StepperConfig,
    {
      info: 'Onboard prosimo app',
      label: 'Onboard',
      isComplete: false,
      isDisabled: false,
      showStepConnector: false,
      order: 3,
      bgGraphicPath: IMAGE_PATHS.BG_GRAPHICS.SPACESHIP,
      isCurrent: false,
      name: 'ONBOARD',
      route: 'ONBOARD',
    } as StepperConfig,
  ],
  POLICY_CREATION_CONFIGS: [
    {
      info: 'match conditions',
      label: 'Match Conditions',
      isComplete: false,
      showStepConnector: true,
      order: 1,
      bgGraphicPath: IMAGE_PATHS.BG_GRAPHICS.POLICY,
      isCurrent: false,
      name: 'matches',
    } as StepperConfig,
    {
      info: 'set action',
      label: 'Set Action',
      isComplete: true,
      isCurrent: true,
      showStepConnector: true,
      order: 2,
      bgGraphicPath: IMAGE_PATHS.BG_GRAPHICS.POLICY,
      name: 'actions',
    } as StepperConfig,
    {
      info: 'apply policies',
      label: 'Apply Policies',
      isComplete: true,
      showStepConnector: true,
      order: 3,
      bgGraphicPath: IMAGE_PATHS.BG_GRAPHICS.POLICY,
      isCurrent: false,
      name: 'apps',
    } as StepperConfig,
    {
      info: 'verify and save',
      label: 'Verify and Save',
      isComplete: false,
      showStepConnector: false,
      order: 4,
      bgGraphicPath: IMAGE_PATHS.BG_GRAPHICS.POLICY,
      isCurrent: false,
      name: 'summary',
    } as StepperConfig,
  ],
  POLICY_APPS_ONLY_CONFIG: [
    {
      info: 'apply policies',
      label: 'Apply Policies',
      isComplete: true,
      showStepConnector: false,
      order: 1,
      bgGraphicPath: IMAGE_PATHS.BG_GRAPHICS.POLICY,
      isCurrent: false,
      name: 'apps',
    } as StepperConfig,
  ],
  NETWORK_ONBOARDING_CONFIGS: [
    {
      info: `Cloud configurations for network setup`,
      label: `Cloud`,
      isComplete: false,
      isDisabled: false,
      showStepConnector: true,
      order: 1,
      bgGraphicPath: IMAGE_PATHS.BG_GRAPHICS.CLOUD,
      isCurrent: false,
      name: 'CLOUD_CONFIG',
      route: 'CLOUD-CONFIG',
    } as StepperConfig,
    {
      info: 'Security details',
      label: 'Security',
      isComplete: false,
      isDisabled: false,
      showStepConnector: true,
      order: 2,
      bgGraphicPath: IMAGE_PATHS.BG_GRAPHICS.POLICY,
      isCurrent: false,
      name: 'SECURITY',
      route: 'SECURITY',
    } as StepperConfig,
    {
      info: 'Launch network',
      label: 'Launch',
      isComplete: false,
      isDisabled: false,
      showStepConnector: false,
      order: 3,
      bgGraphicPath: IMAGE_PATHS.BG_GRAPHICS.SPACESHIP,
      isCurrent: false,
      name: 'ONBOARD',
      route: 'ONBOARD',
    } as StepperConfig,
  ],
  PRIVATE_LINK_SOURCE_ONBOARD_CONFIG: [
    {
      info: `Cloud configurations for Private Link Source setup`,
      label: `Cloud`,
      isComplete: false,
      isDisabled: false,
      showStepConnector: true,
      order: 1,
      bgGraphicPath: IMAGE_PATHS.BG_GRAPHICS.CLOUD,
      isCurrent: false,
      name: 'CLOUD',
      route: 'CLOUD',
    } as StepperConfig,
    {
      info: 'Launch Private Link Source',
      label: 'Launch',
      isComplete: false,
      isDisabled: false,
      showStepConnector: false,
      order: 3,
      bgGraphicPath: IMAGE_PATHS.BG_GRAPHICS.SPACESHIP,
      isCurrent: false,
      name: 'ONBOARD',
      route: 'ONBOARD',
    } as StepperConfig,
  ],
};

export const CLOUD_TYPE_IMAGE_URL_MAP = new Map([
  ['AWS', IMAGE_PATHS.CLOUD.CSP.AWS_DARK],
  ['AZURE', IMAGE_PATHS.CLOUD.CSP.AZURE_DARK],
  ['GCP', IMAGE_PATHS.CLOUD.CSP.GCP_DARK],
  ['PRIVATE', IMAGE_PATHS.COMMON.CSP_PRIVATE],
]);

export const STEP_TYPES = {
  PREV: 'PREV',
  NEXT: 'NEXT',
  SAVE: 'SAVE',
  ONBOARD: 'ONBOARD',
};

export const APP_INTERACTION_TYPES_KEY = {
  APP_TO_APP: 'appToApp',
  USER_TO_APP: 'userToApp',
};

export const ONBOARD_TYPE = {
  BEHIND_FABRIC: 'behind_fabric',
  ACCESSING_FABRIC: 'accessing_fabric',
  BOTH: 'behind_accessing_fabric',
  WEB: 'web',
  PRIVATE_LINK_SOURCE: 'private_link_source',
};

export const IDP_LIGHTBOX_VIEW_TYPE = {
  SYNC_FROM_IDP: 'SYNC_FROM_IDP',
  SELECT_APPS: 'SELECT_APPS',
};

/**
 * Management Menu & Sub-Menu configurations
 */

export const MANAGEMENT = {
  MANAGEMENT_TEXT: 'Management',
  PAGE_SIZE: 50,
};

/**
 * Management Create New Item Mode
 */
export const CREATE_ITEM_MODES = {
  LIGHTBOX: 'LIGHTBOX',
  INLINE: 'INLINE',
};

export const MANAGEMENT_MENU_OPTIONS = {
  ADMIN: 'ADMIN',
  CLOUD: 'CLOUD',
  IDP: 'IDP',
  EDGE: 'EDGE',
  CONNECTOR_GROUPS: 'CONNECTOR-GROUPS',
  GROUPINGS: 'GROUPINGS',
  CERTIFICATES: 'CERTIFICATES',
  ALERT: 'ALERT',
  LOG_RECEIVER: 'LOG-RECEIVER',
  API_TOKENS: 'API-TOKENS',
  ERROR_PAGES: 'ERROR-PAGES',
  EDR_INTEGRATION: 'EDR-INTEGRATION',
  DOWNLOADS: 'DOWNLOADS',
  AGENT_INTEGRATION: 'AGENT-INTEGRATION',
  DATA_FETCH: 'DATA-FETCH',
  SUMMARY_ROUTES: 'SUMMARY_ROUTES',
  INFRA_TAGS: 'INFRA_TAGS',
};

export const MSP_MANAGEMENT_MENU_OPTIONS = {
  ADMINS: 'ADMINS',
  PERMISSIONS: 'PERMISSIONS',
  API_TOKENS: 'API-TOKENS',
};

export const USER_MANAGEMENT_TAB_KEYS = {
  USERS: 'users',
  USER_GROUPS: 'usergroups',
  ROLES: 'roles',
  MSP_ACCESS: 'mspaccess',
  SSO_ACCESS: 'ssoaccess',
  NETWORK_GROUPS: 'NETWORK',
};

export const USER_MANAGEMENT_TABS = [
  {
    name: 'Admins',
    isSelected: true,
    newBtnLabel: 'Create New Admin',
    key: USER_MANAGEMENT_TAB_KEYS.USERS,
    code: 'ADMINS',
    order: 1,
    routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_ADMIN,
  } as Tab,
  {
    name: 'Admin Groups',
    isSelected: false,
    newBtnLabel: 'Create New Admin Group',
    key: USER_MANAGEMENT_TAB_KEYS.USER_GROUPS,
    code: 'ADMIN_GROUPS',
    order: 2,
    routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_ADMIN,
  } as Tab,
  {
    name: 'Roles',
    isSelected: false,
    newBtnLabel: 'Create New Roles',
    key: USER_MANAGEMENT_TAB_KEYS.ROLES,
    code: 'ROLES',
    order: 3,
    routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_ADMIN,
  } as Tab,
  {
    name: 'MSP Access',
    isSelected: false,
    newBtnLabel: 'Create New MSP',
    key: USER_MANAGEMENT_TAB_KEYS.MSP_ACCESS,
    code: 'MSP_ACCESS',
    order: 4,
    routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_ADMIN,
  } as Tab,
  {
    name: 'SSO Access',
    isSelected: false,
    newBtnLabel: 'Create New SSO',
    key: USER_MANAGEMENT_TAB_KEYS.SSO_ACCESS,
    code: 'SSO_ACCESS',
    order: 5,
    routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_ADMIN,
  } as Tab,
];

export const GROUPINGS_TABS = [
  {
    name: 'User Groups',
    isSelected: true,
    newBtnLabel: 'Create New User Group',
    key: 'USER',
    code: 'USER',
    order: 1,
    routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_GROUPINGS,
  } as Tab,
  {
    name: 'App Groups',
    isSelected: false,
    newBtnLabel: `Create New ${APP_TYPE_LABELS.TARGET_APP()} Group`,
    key: 'APP',
    code: 'APP',
    order: 2,
    routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_GROUPINGS,
  } as Tab,
  {
    name: 'Network Groups',
    isSelected: false,
    newBtnLabel: 'Create New Network Group',
    key: USER_MANAGEMENT_TAB_KEYS.NETWORK_GROUPS,
    code: 'NETWORK',
    order: 3,
    routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_NETWORK_GROUPS,
  } as Tab,
  {
    name: 'Device Groups',
    isSelected: false,
    newBtnLabel: 'Create New Device Group',
    order: 4,
    key: 'DEVICE',
    code: 'DEVICE',
    routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_GROUPINGS,
  } as Tab,
  {
    name: 'Time Groups',
    isSelected: false,
    newBtnLabel: 'Create New Time Group',
    order: 5,
    key: 'TIME',
    code: 'TIME',
    routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_GROUPINGS,
  } as Tab,
  {
    name: 'IP Prefixes',
    isSelected: false,
    newBtnLabel: 'Create New IP Prefix Group',
    key: 'IP_RANGE_PREFIX',
    code: 'IP_RANGE_PREFIX',
    order: 6,
    routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_GROUPINGS,
  } as Tab,
  {
    name: 'BGP Communities',
    isSelected: false,
    newBtnLabel: 'Create New BGP Community Group',
    key: 'BGP_COMMUNITY',
    code: 'BGP_COMMUNITY',
    order: 7,
    routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_GROUPINGS,
  } as Tab,
  {
    name: 'Geo Groups',
    isSelected: false,
    newBtnLabel: 'Create New Geo Group',
    key: 'GEO',
    code: 'GEO',
    order: 8,
    routeUrl: CONSTANTS.INSIGHTS.UI_PATH_APPS_ACCESSED(SUBSCRIPTION_TYPES.ZTNA),
  } as Tab,
];

export const ALERT_TAB_TYPES = {
  ALERTS: 'ALERTS',
  NOTIFICATION_CHANNELS: 'NOTIFICATION_CHANNELS',
};

export const ALERT_TABS = [
  {
    name: 'Alerts',
    isSelected: true,
    newBtnLabel: '',
    key: ALERT_TAB_TYPES.ALERTS,
    code: ALERT_TAB_TYPES.ALERTS,
    order: 1,
    routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_ALERT,
  } as Tab,
  {
    name: 'Notification Channels',
    isSelected: false,
    newBtnLabel: 'Add New Notification Channel',
    key: ALERT_TAB_TYPES.NOTIFICATION_CHANNELS,
    code: ALERT_TAB_TYPES.NOTIFICATION_CHANNELS,
    order: 2,
    routeUrl: CONSTANTS.MANAGEMENT.UI_PATH_ALERT,
  } as Tab,
];

export const MSP_MANAGEMENT_TABS = [
  {
    name: 'Admins',
    isSelected: true,
    code: MSP_MANAGEMENT_MENU_OPTIONS.ADMINS,
    order: 1,
    imgUrl: IMAGE_PATHS.MANAGEMENT.ADMIN,
    selectedImgUrl: IMAGE_PATHS.MANAGEMENT.ADMIN_SELECTED,
    routeUrl: 'sp/management/admins',
    contents: ['admins', 'admingroups'],
  } as ManagementMenuItem,
  {
    name: 'Permissions',
    isSelected: false,
    code: MSP_MANAGEMENT_MENU_OPTIONS.PERMISSIONS,
    order: 2,
    imgUrl: IMAGE_PATHS.MANAGEMENT.PERMISSIONS,
    selectedImgUrl: IMAGE_PATHS.MANAGEMENT.PERMISSIONS_SELECTED,
    routeUrl: 'sp/management/permissions',
    contents: ['msp', 'team'],
  } as ManagementMenuItem,
  {
    name: 'API Tokens',
    isSelected: false,
    code: MSP_MANAGEMENT_MENU_OPTIONS.API_TOKENS,
    order: 3,
    imgUrl: IMAGE_PATHS.MANAGEMENT.API_TOKENS,
    selectedImgUrl: IMAGE_PATHS.MANAGEMENT.API_TOKENS_SELECTED,
    routeUrl: 'sp/management/api-tokens',
    contents: ['API tokens'],
  } as ManagementMenuItem,
];

/** User dashboard menu options */
export const USER_DASHBOARD_MENU_OPTIONS = {
  USER_RISK_SCORE: 'USER_RISK_SCORE',
  APPS_ACCESSED: 'USER_APPS_ACCESSED',
  USER_EXPERIENCE_SCORE: 'USER_EXPERIENCE_SCORE',
  SUMMARY: 'USER_SUMMARY',
  EVENTS: 'USER_EVENTS',
};

export const APP_DASHBOARD_MENU_OPTIONS = {
  APP_EXPERIENCE: 'APP_EXPERIENCE',
  APP_PERFORMANCE: 'APP_PERFORMANCE',
  APP_HITS: 'APP_HITS',
  APP_NETWORK_ACCESS: 'APP_NETWORK_ACCESS',
  APP_EVENTS: 'APP_EVENTS',
};

export const INSIGHTS_MENU_OPTIONS = {
  SUMMARY: 'SUMMARY',
  SESSION: 'SESSIONS',
  PERFORMANCE: 'PERFORMANCE',
  APPS_ACCESSED: 'APPS_ACCESSED',
  APP_EXPERIENCE: 'APP_EXPERIENCE',
  BANDWIDTH_USAGE: 'BANDWIDTH_USAGE',
  USER_RISK: 'USER_RISK',
  HEATMAP: 'HEATMAP',
  EVENTS: 'EVENTS',
  WAF: 'WAF',
  INSIGHTS: {
    ZTNA: SUBSCRIPTION_TYPES.ZTNA,
    MCN: SUBSCRIPTION_TYPES.MCN,
  },
  NETWORKS_ACCESSED: 'NETWORKS_ACCESSED',
  LOGS: 'LOGS',
};

export const DIAGNOSE_MENU_OPTIONS = {
  HEALTH_STATUS: 'HEALTH_STATUS',
  INFRASTRUCTURE: 'INFRASTRUCTURE',
  TROUBLESHOOTING: 'TROUBLESHOOTING',
  DEBUGGING: 'DEBUGGING',
  DIAGNOSE: 'Diagnose',
};

export const CLOUD_TRANSIT_MENU_OPTIONS = {
  CLOUD_TRANSIT: 'CLOUD_TRANSIT',
  TRANSIT: 'EDGE_TRANSIT',
  ZTNA: 'ZTNA',
  MCN: 'MCN',
  TARGET: 'TARGET',
};

/** HTTP Response content types */
export const HTTP_RESPONSE_CONTENT_TYPES = {
  JSON: 'application/json',
  TEXT: 'text/plain',
};

export const MESSAGES = {
  NO_DATA_ALERTS: 'There are no alerts available',
  NO_DATA_CHANNELS: 'There are no channels available',
  NO_DATA_ADAVANCED_SETTING_URL_REWRITE: 'There are no rule sets available',
  NO_DATA_INSIGHTS:
    "There doesn't seem to be any data available. Please adjust the filters and try again.",
  NO_DATA_MANAGEMENT:
    "There doesn't seem to be any data available. Please add an entry by clicking on the create new button above.",
  NO_DATA_RECOMMENDATION:
    "There doesn't seem to be any recommendation available.",
  NO_DATA:
    "There doesn't seem to be any data available. Please adjust the filters and try again.",
  NO_CACHE_DATA:
    "There doesn't seem to be any cache activity data available. Please adjust the filters and try again.",
  ERROR: 'We are unable to complete your request. Please try again. ',
  SUCCESS: 'Successful ',
  EMAL_VERIFICATION: {
    INIT: {
      MESSAGE1: 'Account activation in-progress!',
      MESSAGE2: 'Please wait...',
      STYLE: {
        WIDTH: '80px',
        HEIGHT: '90px',
      },
    },
    THANK_YOU: {
      MESSAGE1: 'Thank you!',
      MESSAGE2:
        'Your account is now pending approval, please check your email for an approval status within 24 hours',
      STYLE: {
        WIDTH: '60px',
        HEIGHT: '60px',
      },
    },
    EXPIRED: {
      MESSAGE1: 'Your verification has expired!',
      MESSAGE2: "But don't worry , we'll send you another one.",
      STYLE: {
        WIDTH: '60px',
        HEIGHT: '60px',
      },
    },
    DONE_ALREADY: {
      MESSAGE1: 'Your email was verified already',
      STYLE: {
        WIDTH: '60px',
        HEIGHT: '60px',
      },
    },
    ERROR: {
      MESSAGE1: 'Sorry',
      STYLE: {
        WIDTH: '60px',
        HEIGHT: '60px',
      },
    },
  },

  TYPES: {
    ERROR: 'ERROR',
    INFO: 'INFO',
  },
  WAF: {
    CONFLICT_NOTE:
      'Some domains have been mapped to other rules. If selected, the current mapping will be removed and mapped to new rule.',
  },
  INSIGHTS: {
    NO_LATENCY_DISTRIBUTION_STAT: 'Learning mode - not enough data',
    NO_REQUEST_DISTRIBUTION_STAT:
      'The initial score for every new user access or new location is set at 80',
  },
  NO_DATA_AVAILABLE: (type: string) => `No ${type.toLowerCase()} available`,
  RECOMMENDATIONS_TOOLTIP: `
            Recommendations for fabric edge locations are generated to further optimize performance based on user access
            locations, application behavior pattern, and admin settings for applications during onboarding.
            They are updated every day, as new data gets generated`,
  NO_APPS_DATA: `There doesn't seem to be any app available`,
  NO_NETWORKS_DATA: `There doesn't seem to be any app available`,
  NO_GROUPS_DATA: `There doesn't seem to be any app group available`,
  NO_NETWORK_GROUPS_DATA: `There doesn't seem to be any network group available`,
  NO_HTTP_HEADER_REWRITE_DATA: `There doesn't seem to be any http header rewrite available`,
  NO_SVC_DATA: `There doesn't seem to be any shared service available`,
  APP_DISCOVERY: {
    NO_VPC_FOUND: (type: string) =>
      `There doesn't seem to be any ${type} available for search term.`,
    NO_SUBNET_FOUND: `There doesn't seem to be any subnets available for search term.`,
  },
};

export const EMAIL_ACTIVATION_STATUS = {
  INIT: 'INIT',
  THANK_YOU: 'THANK_YOU',
  DONE_ALREADY: {
    NAME: 'DONE_ALREADY',
    CODE: 'E101003',
  },
  EXPIRED: {
    NAME: 'EXPIRED',
    CODE: 'E101008',
  },
  ERROR: 'ERROR',
};

export const PASSWORD_STATUS = {
  RESET: 'RESET',
  CREATE: 'CREATE',
  ERROR: 'ERROR',
  FORGOT: 'FORGOT',
};
export const CHART_DEFAULT_DIMENSIONS = {
  WIDTH: 1200,
  HEIGHT: 230,
  TIME_SLIDER_X_AXIS_CONTAINER_HEIGHT: 60,
  SANKEY_HEIGHT: 300,
  LEFT: 100,
  RIGHT: 50,
  TOP: 50,
  BOTTOM: 50,
  Y_AXIS: {
    HEIGHT: 150,
  },
};

export const CHART_PRINT_VIEW_DIMENSIONS = {
  WIDTH: 960,
};

export const NUMERIC_ROUND_OFF = {
  INTEGER: '1.0-0',
  TWO_DIGITS: '2.0-0',
  ONE_DECIMALS: '1.1-1',
  TWO_DECIMALS: '1.2-2',
  THREE_DECIMALS: '1.3-3',
  FOUR_DECIMALS: '1.4-4',
};

export const PROSIMO_ADMIN = 'prosimo';

export const DOWNLOAD_TYPES = {
  SAVE: 'save',
  VIEW: 'view',
  EXE: 'exe',
  MSI: 'msi',
  ZIP: 'zip',
};

export const MENU_NAMES = {
  PROSIMO: 'Prosimo',
  MANAGEMENT: 'Management',
  INSIGHTS: 'Insights',
  ONBOARD: 'Onboard',
  APPLICATIONS: 'Applications',
  NETWORK: 'Networks',
  SHARED_SERVICES: 'Shared Services',
  PRIVATE_LINK_SOURCE: 'Private Link Sources',
  VISUAL: 'Transit',
  APP_ONBOARDING: 'App Onboarding',
  POLICY_LIBRARY: 'Policy Library',
  WAF_LIBRARY: 'WAF Policies',
  IP_REPUTATION: 'IP Reputation',
  DYNAMIC_RISK: 'Dynamic Risk',
  USER_SETTINGS: 'User Settings',
  DEVICE_POSTURE: 'Device Posture',
  OPTIMIZE: 'Optimize',
  CACHE_RULES: 'Cache Rules',
  GUIDE: 'Guide',
  AGENT: 'Agent',
  DIAGNOSE: 'Diagnose',
  DISCOVERY: 'Discovery',
  CLOUD_TRANSIT: 'Cloud Explorer',
  TRANSIT_360: 'Transit 360',
  ZTNA: 'ProAccess',
  MCN: 'ProTransit',
  CONFIGURE: 'Policy',
  HEALTH_STATUS: 'Health Status',
  DIAGNOSTIC_TOOLS: 'Diagnostic Tools',
  SHARED_SERVICE: 'Shared Services',
  PLAYGROUND: 'Eng Labs',
  COST: 'Cost 360',
  ONBOARD_V2: 'Onboard',
};

export const HEADER_CONFIGS = {
  MSP_DASHBOARD: {
    title: 'Managed Service Provider Portal for',
    description:
      'Add tenants and centrally monitor key health metrics across tenants',
    displayHeader: true,
    displayActionBtn: false,
    isMSPAdmin: true,
  } as L2HeaderModel,
  MSP_MANAGEMENT: {
    title: 'Management',
    description: 'Manage your assets here',
    displayHeader: true,
    displayActionBtn: false,
    isMSPAdmin: true,
  } as L2HeaderModel,
  MSP_TASKS: {
    title: 'Activities',
    description:
      "Track your team member's dashboard activities along with their status",
    displayHeader: true,
    displayActionBtn: false,
    selectedIcon: 'tasks',
    isMSPAdmin: true,
  } as L2HeaderModel,
  MSP_USER_PROFILE: {
    title: 'My Profile',
    description: 'Personal Settings',
    displayHeader: true,
    displayActionBtn: false,
    selectedIcon: 'user-profile',
    isMSPAdmin: true,
  } as L2HeaderModel,
  PROSIMO_ADMIN: {
    title: 'Prosimo Admin',
    description: '',
    displayHeader: true,
    displayActionBtn: false,
    isProsimoAdmin: true,
  } as L2HeaderModel,
  APPS: {
    title: 'App Onboarding',
    description: 'Select an App below to onboard',
    displayHeader: true,
    displayActionBtn: false,
  } as L2HeaderModel,
  NETWORKS: {
    title: 'Network Onboarding',
    description: 'Select a Network below to onboard',
    displayHeader: true,
    displayActionBtn: false,
  } as L2HeaderModel,
  SHARED_SERVICES: {
    title: 'Shared Services',
    description: 'Select a Shared Service below to onboard',
    displayHeader: true,
    displayActionBtn: false,
  } as L2HeaderModel,
  PRIVATE_LINK_SOURCE: {
    title: 'Private Link Source',
    description: 'Onboard and manage Private Link Sources',
    displayHeader: true,
    displayActionBtn: false,
  } as L2HeaderModel,
  ONBOARD_CONFIGURE: {
    title: 'Onboard',
    description:
      'Onboard applications, networks, private link sources and transit',
    displayHeader: true,
    displayActionBtn: false,
    isBetaRelease: true,
  } as L2HeaderModel,
  ADVANCED_SETTING: {
    title: 'Advanced Settings',
    description: 'Configure advanced proxy settings.',
    displayHeader: true,
    displayActionBtn: false,
    routingLink: '/dashboard/apps',
    routingLinkLabel: '< App Onboarding',
  } as L2HeaderModel,
  NETWORK_ADVANCED_SETTING: {
    title: 'Advanced Settings',
    description: 'Configure advanced connectivity options for networks',
    displayHeader: true,
    displayActionBtn: false,
    routingLink: 'dashboard/networks/onboarded',
    routingLinkLabel: '< Network Onboarding',
  } as L2HeaderModel,
  INSIGHTS_MAIN: {
    title: 'Insights',
    description: '',
    displayHeader: true,
    displayActionBtn: false,
  } as L2HeaderModel,
  INSIGHTS: {
    NETWORKS: {
      title: 'Insights',
      description: 'Insight into network interaction and security',
      displayHeader: true,
      displayActionBtn: false,
    } as L2HeaderModel,
    APPS: {
      title: 'Insights',
      description: 'Insight into user interaction and security',
      displayHeader: true,
      displayActionBtn: false,
    } as L2HeaderModel,
    COST: {
      title: 'Cost 360',
      description: 'Cost breakdown and chargeback',
      displayHeader: true,
      displayActionBtn: false,
      // betaImageURL: IMAGE_PATHS.COMMON.BETA_ICON,
    } as L2HeaderModel,
  },
  MANAGEMENT: {
    title: 'Management',
    description: 'Manage your assets',
    displayHeader: true,
    displayActionBtn: false,
  } as L2HeaderModel,
  NAMESPACE: {
    title: 'Connectivity Policies',
    description: 'Manage your namespaces',
    displayHeader: true,
    displayActionBtn: false,
  } as L2HeaderModel,
  PRIVATELINK: {
    title: 'Connectivity Policies',
    description: 'Manage your private links',
    displayHeader: true,
    displayActionBtn: false,
  } as L2HeaderModel,
  SERVICE_INSERTION: {
    title: 'Connectivity Policies',
    description: 'Manage service insertion policies',
    displayHeader: true,
    displayActionBtn: false,
  } as L2HeaderModel,
  CONFIGURE: {
    title: 'Policy Library',
    description: 'Manage your Policies',
    displayHeader: true,
    displayActionBtn: false,
  } as L2HeaderModel,

  POLICY_LIBRARY: {
    title: 'Policy Library',
    description: 'Create Policies to apply to apps and networks',
    displayHeader: true,
    displayActionBtn: false,
  } as L2HeaderModel,
  POLICY_FORM: {
    title: 'Security Policies',
    description:
      'Configure actions, properties, and apps/networks to create a policy',
    displayHeader: true,
    displayActionBtn: false,
  } as L2HeaderModel,
  DIAGNOSE: {
    title: 'Diagnose',
    description: 'Diagnose and troubleshoot your issues here',
    displayHeader: true,
    displayActionBtn: false,
  } as L2HeaderModel,
  TASKS: {
    title: '',
    description: '',
    displayHeader: true,
    displayActionBtn: false,
    selectedIcon: 'tasks',
  } as L2HeaderModel,
  ALERTS: {
    title: '',
    description: '',
    displayHeader: true,
    displayActionBtn: false,
    selectedIcon: 'alerts',
  } as L2HeaderModel,
  USER_PROFILE: {
    title: 'My Profile',
    description: 'Personal Settings',
    displayHeader: true,
    displayActionBtn: false,
    selectedIcon: 'user-profile',
  } as L2HeaderModel,
  SECURITY: {
    WAF_RULES: {
      title: 'Security Policies',
      description: 'Set up your WAF Policies and apply them to your apps',
      displayHeader: true,
      displayActionBtn: false,
    } as L2HeaderModel,

    IP_REPUTATION: {
      title: 'Security Policies',
      description: 'Set up your IP Reputation and apply them to your apps',
      displayHeader: true,
      displayActionBtn: false,
    } as L2HeaderModel,

    INTERNET_EGRESS: {
      title: 'Security Policies',
      description: 'Set up your Interet Egress and apply them to your apps',
      displayHeader: true,
      displayActionBtn: false,
    } as L2HeaderModel,

    DYNAMIC_RISK: {
      title: 'Security Policies',
      description:
        'Set up your Dynamic Risk Settings and apply them to your apps',
      displayHeader: true,
      displayActionBtn: false,
    } as L2HeaderModel,

    USER_SETTINGS: {
      title: 'User Settings',
      description:
        'Remediate users locked out due to policy settings and manage exclude list',
      displayHeader: true,
      displayActionBtn: false,
    } as L2HeaderModel,

    DEVICE_POSTURE_PROFILE: {
      title: 'Security Policies',
      description: 'Set up your device posture profiles',
      displayHeader: true,
      displayActionBtn: false,
    } as L2HeaderModel,

    END_POINT_INTEGRATION: {
      title: 'Security Policies',
      description:
        'Set up posture profiles for end point security solutions from integration partners',
      displayHeader: true,
      displayActionBtn: false,
    } as L2HeaderModel,
  },
  OPTIMIZE: {
    CACHE_RULES: {
      title: 'Optimize Policies',
      description: 'Set up your Cache Rules and apply them to your apps',
      displayHeader: true,
      displayActionBtn: false,
    } as L2HeaderModel,
  },
  GUIDE: {
    title: 'Guide',
    description: 'See how to improve your Application experience',
    displayHeader: true,
    displayActionBtn: false,
  } as L2HeaderModel,
  AGENT: {
    title: 'Agent',
    description: 'Manage agent here',
    displayHeader: true,
    displayActionBtn: false,
  } as L2HeaderModel,
  DISCOVERY: {
    title: 'Discovery Report',
    description: 'Upload Logs for Discovery Report',
    displayHeader: true,
    displayActionBtn: false,
  } as L2HeaderModel,
  CLOUD_TRANSIT: {
    title: 'Transit 360',
    description: '360 degree view of your transit activities',
    displayHeader: true,
    displayActionBtn: false,
  } as L2HeaderModel,
  APP_ONBOARDING: {
    title: 'App Onboarding',
    description: 'Onboard Apps',
    displayHeader: true,
    displayActionBtn: false,
  } as L2HeaderModel,
  NETWORK_ONBOARDING: {
    title: 'Network Onboarding',
    description: 'Onboard Networks',
    displayHeader: true,
    displayActionBtn: false,
  } as L2HeaderModel,
  VISUAL_ONBOARDING: {
    INFRASTRUCTURE: {
      title: 'Transit',
      description: 'Select an Edge',
      displayHeader: true,
      displayActionBtn: false,
    } as L2HeaderModel,
    REGION_CONFIG: {
      title: 'Transit',
      description: 'Configure Connections',
      displayHeader: true,
      displayActionBtn: false,
    } as L2HeaderModel,
    DEPLOYMENTS: {
      title: 'Transit',
      description: 'Review Deployments',
      displayHeader: true,
      displayActionBtn: false,
    } as L2HeaderModel,
  },
  DASHBOARDV2: {
    title: 'Dashboard 2.0',
    description: 'Dashboard v2.0',
    displayHeader: true,
  } as L2HeaderModel,
  SHARED_SERVICE_ONBOARDING: {
    title: 'Shared Service Onboarding',
    description: 'Onboarding Shared Service',
    displayHeader: true,
    displayActionBtn: false,
  } as L2HeaderModel,
  PLAYGROUND: {
    title: 'Eng Labs',
    description:
      'Explore different patterns in your network based on data exchanged',
    displayHeader: true,
  } as L2HeaderModel,
  PROFILE: {
    title: 'Profile Settings',
    description: '',
    displayHeader: true,
  } as L2HeaderModel,
};

export const PageLayoutType = {
  ACCOUNT_EXPIRED: {
    type: 'account-expired',
    banner: false,
    navigation: true,
    navigationReadOnly: true,
    header: null,
  },
  LOGIN: {
    type: 'login',
    banner: false,
    navigation: false,
    header: null,
  },
  MFA: {
    type: 'mfa',
    banner: false,
    navigation: false,
    header: null,
  },
  MSP_ADMIN: {
    type: 'mspAdmin',
    banner: true,
    navigation: true,
    header: 'MSP_ADMIN',
  },
  PROSIMO_ADMIN: {
    type: 'prosimoAdmin',
    banner: true,
    navigation: true,
    header: 'PROSIMO_ADMIN',
  },
  APP_ONBOARDING: {
    type: 'app-onboard',
    banner: true,
    navigation: true,
    header: 'APP_ONBOARDING',
  },

  ADVANCED_SETTING: {
    type: 'advanced-setting',
    banner: true,
    navigation: true,
    header: 'ADVANCED_SETTING',
  },
  NETWORK_ADVANCED_SETTING: {
    type: 'advanced-setting',
    banner: true,
    navigation: true,
    header: 'NETWORK_ADVANCED_SETTING',
  },
  DASHBOARD: {
    type: 'dashboard',
    banner: true,
    navigation: true,
    header: null,
  },
  DISCOVERY: {
    type: 'discovery',
    banner: true,
    navigation: true,
    header: 'DISCOVERY',
  },
  DASHBOARDV2: {
    type: 'dashboardv2',
    banner: true,
    navigation: true,
    header: null,
  },
};

export const PageTypes = {
  LOGIN: 'login',
  PROSIMO_ADMIN: 'prosimoAdmin',
  MSP_ADMIN: 'mspAdmin',
  APP_ONBOARDING: 'app-onboard',
  DASHBOARD: 'dashboard',
  SIGNUP: 'signup',
  SIGNIN: 'signin',
  PASSWORD: 'password',
  PAGE_NOT_FOUND: 'pageNotFound',
};

export const SIMPLE_PAGES = [
  PageTypes.LOGIN,
  PageTypes.SIGNUP,
  PageTypes.SIGNIN,
  PageTypes.PASSWORD,
  PageTypes.PAGE_NOT_FOUND,
];

export interface LocalStoreState {
  name: string;
  reset: boolean;
  delete: boolean;
}

export const LOCAL_STORE = {
  name: 'pdash-store',
};

export const LOCAL_STORE_STATE_CONFIGS = [
  {
    name: 'admin',
    reset: false,
    delete: false,
  } as LocalStoreState,
  {
    name: 'app-onboard',
    reset: false,
    delete: false,
  } as LocalStoreState,
  {
    name: 'app',
    reset: false,
    delete: false,
  } as LocalStoreState,
  {
    name: 'policy-form-definition',
    reset: false,
    delete: false,
  } as LocalStoreState,
  {
    name: 'log-meta-data',
    reset: false,
    delete: true,
  } as LocalStoreState,
  {
    name: 'diagnose',
    reset: false,
    delete: false,
  } as LocalStoreState,
  {
    name: 'insights',
    reset: true,
    delete: false,
  } as LocalStoreState,
  {
    name: 'log-search-filter',
    reset: false,
    delete: false,
  } as LocalStoreState,
  {
    name: 'management',
    reset: false,
    delete: false,
  } as LocalStoreState,
  {
    name: 'user',
    reset: false,
    delete: false,
  } as LocalStoreState,
  {
    name: 'web-pdash',
    reset: false,
    delete: false,
  } as LocalStoreState,
];

export const DATA_MULTIPLES = {
  BASE_2: 1024,
  BASE_10: 1000,
};

export interface PageConfig {
  rootConfig?: RootConfig;
  name?: string;
  module?: PageConfigModule;
  helpId?: string;
  desc?: string;
  title?: string;
  interactiveGuides?: Array<InteractiveGuide>;
}

export interface SelectionObjectWithArray {
  pageIndex: number;
  selections: Array<Object>;
}

export interface SelectionObjectWithSet {
  pageIndex: number;
  selections: Set<string>;
}
export interface PageConfigModule {
  parent: string;
  child: string;
}

export interface RootConfig {
  helpId: {
    mcn: string;
    ztna: string;
  };
}
export interface InteractiveGuide {
  key: string;
  desc: string;
}

export const PAGE_CONFIG_PAGE_NAMES = {
  MAIN_DASHBOARD: 'MAIN_DASHBOARD',
  SUMMARY: 'SUMMARY',
  SESSION: 'SESSIONS',
  PERFORMANCE: 'PERFORMANCE',
  APPS_ACCESSED: 'APPS_ACCESSED',
  APP_EXPERIENCE: 'APP_EXPERIENCE',
  USER_RISK: 'USER_RISK',
  HEAT_MAP: 'HEAT_MAP',
  WAF: 'WAF',
  EVENTS: 'EVENTS',
  DAY_ZERO_CLOUD: 'DAY_ZERO_CLOUD',
  DAY_ONE_CLOUD: 'DAY_ONE_CLOUD',
  DAY_ZERO_IDP: 'DAY_ZERO_IDP',
  LAUNCH: 'LAUNCH',
  SETTINGS: 'SETTINGS',
  DNS: 'DNS',
  SSL_CERT: 'SSL_CERT',
  OPTIMIZATION: 'OPTIMIZATION',
  SECURITY: 'SECURITY',
  ONBOARD: 'ONBOARD',
  APPS_DASHBOARD: 'DASHBOARD',
  APPS_DASHBOARD_DISCOVERED: 'DISCOVERED',
  ADVANCED_SETTING: 'ADVANCED_SETTING',
  NETWORK_ADVANCED_SETTING: 'NETWORK_ADVANCED_SETTING',
  POLICY_HOME: 'POLICY_HOME',
  POLICY_LIBRARY: 'POLICY_LIBRARY',
  WAF_POLICY: 'WAF_POLICY',
  IP_REPUTATION: 'IP_REPUTATION',
  DYNAMIC_RISK: 'DYNAMIC_RISK',
  USER_SETTINGS: 'USER_SETTINGS',
  DEVICE_POSTURE_PROFILE: 'DEVICE_POSTURE_PROFILE',
  CACHE_RULE: 'CACHE_RULE',
  GUIDE: 'GUIDE',
  AGENT_OVERVIEW: 'AGENT_OVERVIEW',
  AGENT_DEVICES: 'AGENT_DEVICES',
  AGENT_SETTINGS: 'AGENT_SETTINGS',
  AGENT_DOWNLOAD: 'AGENT_DOWNLOAD',
  ADMINS: 'ADMINS',
  ADMIN_GROUPS: 'ADMIN_GROUPS',
  ROLES: 'ROLES',
  MSP_ACCESS: 'MSP_ACCESS',
  SSO_ACCESS: 'SSO_ACCESS',
  MANAGEMENT_HOME: 'MANAGEMENT_HOME',
  EDGE_MANAGEMENT: 'EDGE_MANAGEMENT',
  USER_MANAGEMENT: 'USER_MANAGEMENT',
  APP_MANAGEMENT: 'APP_MANAGEMENT',
  DEVICE_MANAGEMENT: 'DEVICE_MANAGEMENT',
  TIME_MANAGEMENT: 'TIME_MANAGEMENT',
  IP_RANGE_MANAGEMENT: 'IP_RANGE_MANAGEMENT',
  IP_PREFIX_MANAGEMENT: 'IP_PREFIX_MANAGEMENT',
  GEO_MANAGEMENT: 'GEO_MANAGEMENT',
  CLOUD_MANAGEMENT: 'CLOUD_MANAGEMENT',
  IDP_MANAGEMENT: 'IDP_MANAGEMENT',
  IP_ADDRESSES: 'IP_ADDRESSES',
  CERTIFICATES_MANAGEMENT: 'CERTIFICATES_MANAGEMENT',
  ALERTS_MANAGEMENT: 'ALERTS_MANAGEMENT',
  NOTIFICATION_CHANNEL: 'NOTIFICATION_CHANNEL',
  ALERTS: 'ALERTS',
  LOG_RECEIVER: 'LOG_RECEIVER',
  DATA_FETCH: 'DATA_FETCH',
  SUMMARY_ROUTES: 'SUMMARY_ROUTES',
  API_TOKENS: 'API_TOKENS',
  ERROR_PAGES: 'ERROR_PAGES',
  INFRASTRUCTURE: 'INFRASTRUCTURE',
  HEALTH_STATUS: 'HEALTH_STATUS',
  TROUBLESHOOTING: 'TROUBLESHOOTING',
  LOGS: 'LOGS',
  DIAGNOSTICS: 'DIAGNOSTICS',
  TASKS: 'TASKS',
  PROFILE: 'PROFILE',
  PASSWORD: 'PASSWORD',
  NOTES: 'NOTES',
  DISCOVERY: 'DISCOVERY',
  MSP_DASHBOARD: 'MSP_DASHBOARD',
  MSP_ADMINS: 'MSP_ADMINS',
  MSP_ADMIN_GROUPS: 'MSP_ADMIN_GROUPS',
  MSP_ROLES: 'MSP_ROLES',
  TENANT_ROLES: 'TENANT_ROLES',
  MSP_CLOUD_MANAGEMENT: 'MSP_CLOUD_MANAGEMENT',
  MSP_API_TOKENS: 'MSP_API_TOKENS',
  MSP_TASKS: 'MSP_TASKS',
  MSP_USER_PROFILE: 'MSP_USER_PROFILE',
  MSP_USER_PASSWORD: 'MSP_USER_PASSWORD',
  CLOUD_TRANSIT: 'CLOUD_TRANSIT',
  CLOUD_TRANSIT_TRANSIT_VIEW: 'CLOUD_TRANSIT_TRANSIT_VIEW',
  CLOUD_TRANSIT_ZTNA: 'CLOUD_TRANSIT_ZTNA',
  CLOUD_TRANSIT_MCN: 'CLOUD_TRANSIT_MCN',
  EDR_INTEGRATION: 'EDR_INTEGRATION',
  VISUAL_ONBOARDING: 'VISUAL_ONBOARDING',
  DOWNLOAD_AGENT: 'DOWNLOAD_AGENT',
  DOWNLOAD_CONNECTORS: 'DOWNLOAD_CONNECTORS',
  DOWNLOAD_TERRAFORM: 'DOWNLOAD_TERRAFORM',
  ROUTE_TABLE_CONNECTOR: 'ROUTE_TABLE_CONNECTOR',
  ROUTE_TABLE_EDGE: 'ROUTE_TABLE_EDGE',
  ROUTE_TABLE_VPC: 'ROUTE_TABLE_VPC',
  ROUTE_TABLE_SUBNET: 'ROUTE_TABLE_SUBNET',
  ROUTE_TABLE_TGW: 'ROUTE_TABLE_TGW',
  COST: 'COST',
  NETWORKS: 'NETWORKS',
  SHARED_SERVICES: 'SHARED_SERVICES',
  PRIVATE_LINK_SOURCE: 'PRIVATE_LINK_SOURCE',
  FIREWALL_CC_LIST: 'FIREWALL_CC_LIST',
  INSIGHTS: 'INSIGHTS',
};

export const APP_LOCALE = {
  EN_US: 'en-us',
};
/** PANEL DISPLAY STATUS */
export enum PANEL_DISPLAY_STATUS {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
}

export const UI_PATHS_TO_SKIP = {
  TEAM: [
    'password',
    'activation',
    'page-not-found',
    'forgot',
    'reset',
    'releases',
    'expired',
    'authentication',
  ],
  NON_TEAM: [
    'password',
    'activation',
    'page-not-found',
    'forgot',
    'reset',
    'signup',
    'authentication',
  ],
  REQUESTED_URL_LOCAL_STORE: [
    'signup',
    'password',
    'activation',
    'page-not-found',
    'authentication',
  ],
};

/** RECOMMENDATIONS */
export const IMPROVEMENT_LABELS = {
  PERFORMANCE_CHANGE: 'Performance Change',
  COST_IMPACT: 'Cost Impact',
};

export const LOG_FETCH_STATUS = {
  STARTED: 'STARTED',
  COMPLETED: 'COMPOLETED',
  ERROR: 'ERROR',
  NO_DATA: 'NO_DATA',
};

export const TABULAR_VIEW_HEADER_WIDTH = {
  W_100: '100%',
  W_99: '99%',
};
export const CONFIRM_ACTIONS_TYPE = {
  DELETE: 'DELETE',
  UNDO: 'UNDO',
};

export const AWS_BYOIP_LABEL = 'Enable BYOIP for AWS Edges';

export const LOCATION_TYPE = {
  COUNTRY: 'country',
  STATE: 'state',
  CITY: 'city',
};

export const PROTOCOLS = {
  TCP: 'tcp',
  UDP: 'udp',
  DNS: 'dns',
  HTTP: 'http',
  HTTPS: 'https',
  ICMP: 'icmp',
  ANY: 'any',
};
export const LEGEND_STATUS = {
  ON: 'on',
  OFF: 'off',
};

export const ONBOARD_CONTAINERS = {
  APPS_CONTAINER: 'APPS_CONTAINER',
  NETWORKS_CONTAINER: 'NETWORKS_CONTAINER',
  PRIVATE_LINK_SOURCE_CONTAINER: 'PRIVATE_LINK_SOURCE_CONTAINER',
};

export const NO_DATA_AVAILABLE_INFO = {
  data: {
    data: [
      {
        key: 'N/A',
        value: 100,
        label: 'N/A',
        'N/A': {
          '': 'No HTTP requests were made',
        },
      },
    ],
    label: 'N/A',
  },
  COLOR_CODE: COLORS.NO_DATA_GRAY,
};

export const PDF_PRINT = {
  PROGRESS: 'PDF generation in progress, monitor the status in task page',
  PRINT_FAILED: 'failed to print the the report',
  GENERATION_FAILED: 'Failed to generate the PDF report',
  QUERY_FAILED: 'query for the PDF failed',
};
export const ACCESS_BASED_POLICY_ACTIONS = {
  DENY_ALL: 'Deny All Users',
  ALLOW_ALL: 'Allow All Users',
  DEFAULT_MCN_POLICY: 'Allow All Networks',
  DENY_ALL_NETWORKS: 'Deny All Networks',
};

export const CLOUD_TRACER_GRID_INFO = {
  HISTORY: {
    HEADERS: [
      'status',
      'date',
      'user',
      'sourceIpValue',
      'sourcePortValue',
      'targetIpValue',
      'targetPortValue',
      'protocol',
      'actions',
    ],
    KEYS: [
      { key: 'status', value: 'Status' },
      { key: 'date', value: 'Date' },
      { key: 'user', value: 'User' },
      { key: 'sourceIpValue', value: 'Source IP' },
      { key: 'sourcePortValue', value: 'Source Ports' },
      { key: 'targetIpValue', value: 'Target IP' },
      { key: 'targetPortValue', value: 'Target Ports' },
      { key: 'protocol', value: 'Protocol' },
      { key: 'actions', value: 'Actions' },
    ],
  },
  ROUTE_TABLE: {
    HEADERS: ['isError', 'dstCIDR', 'nextHopType', 'nextHopID'],
    KEYS: [
      { key: 'isError', value: '' },
      { key: 'dstCIDR', value: 'CIDR' },
      { key: 'nextHopType', value: 'Target Type' },
      { key: 'nextHopID', value: 'Target ID' },
    ],
  },
  POLICY: {
    HEADERS: [
      'isError',
      'ruleType',
      'cidr',
      'ruleOrder',
      'portRange',
      'proto',
      'ruleAction',
    ],
    KEYS: [
      { key: 'isError', value: '' },
      { key: 'ruleType', value: 'Type' },
      { key: 'cidr', value: 'CIDR' },
      { key: 'ruleOrder', value: 'Rule Order' },
      { key: 'proto', value: 'Protocol' },
      { key: 'portRange', value: 'Port Range' },
      { key: 'ruleAction', value: 'Action' },
    ],
  },
};
export const STATUS_CONSTANTS = {
  VALID: 'Valid',
  EXPIRED: 'Expired',
  EXPIRE_SOON: 'Expire Soon',
  SUCCESS: 'Success',
  ERROR: 'Error',
};

export const MAT_ICON_NAMES = {
  INFO: 'info',
  CODE_ICON: 'code icon',
  DELETE_ICON: 'delete icon',
  DOWNLOAD: 'file_download',
  EDIT: 'mode_edit',
};

export const MAT_ICON_CLASS_NAME = {
  MATERIAL_ICONS_OUTLINED: 'material-icons-outlined',
};

export const MAT_ICON_COLOR = {
  PRIMARY: 'primary',
};

export const POLICY_TYPE_ACCESS_NAMES = {
  'ALLOW-ALL': 'ALLOW-ALL-USERS',
  'DENY-ALL': 'DENY-ALL-USERS',
  'DEFAULT-MCN-POLICY': 'ALLOW-ALL-NETWORKS',
  'DENY-ALL-NETWORKS': 'DENY-ALL-NETWORKS',
  'ALLOW-ALL-USERS': 'ALLOW-ALL',
  'DENY-ALL-USERS': 'DENY-ALL',
  'ALLOW-ALL-NETWOKS': 'DEFAULT-MCN-POLICY',
  CUSTOMIZE: 'customize',
};

export const POLICY_ALLOW_ACTIONS = {
  ALLOW: 'allow',
  MFA: 'mfa',
  SKIP_WAF: 'skipWAF',
  BYPASS: 'bypass',
  ALERT: 'alert',
};

export const POLICY_NAME_VALIDATION_REGEX = /^[a-zA-Z][a-zA-Z0-9_-]{2,63}$/;

export enum LogDetailsStatusFields {
  count = 'requestCount',
  successCount = 'successCount',
  redirectCount = 'redirectCount',
  errorCount = 'errorCount',
  status = 'status',
}

export const NOTIFICATION_MESSAGES = {
  DELETE: {
    FAIL: (context) => `Unable to delete ${context}! Please try again.`,
    SUCCESS: (context) => `${context} deleted successfully.`,
    CONFIRM: (context) => `Are you sure you want to delete  ${context}?`,
  },
  DOWNLOAD: {
    FAIL: `Unable to download certificate! Please try again.`,
    CONFIRM: `Select from following options`,
  },
  UPDATE: {
    FAIL: (context) => `Unable to update ${context}! Please try again.`,
    SUCCESS: (context) => `${context} updated successfully.`,
  },
  CREATE: {
    SUCCESS: (context) => `${context} created successfully.`,
    FAIL: (context) => `Unable to create ${context}!Please try again.`,
  },
  CONFLICT: {
    FAIL: `Resolve the conflicts and please try again.`,
  },
  OFFBOARD: {
    CONFIRM: (context) => `Are you sure you want to offboard ${context}?`,
    FAIL: (context) => `Unable to offboard ${context}! Please try again.`,
    SUCCESS: (context) => `${context} offboarding task started!`,
  },
  ONBOARD: {
    FAIL: (context) => `Unable to onboard ${context}! Please try again.`,
    SUCCESS: (context) => `${context} onboarding task started!`,
  },
  UNDO: {
    UPDATE: `Are you sure you want to discard the unsaved changes?`,
  },
};
export const MAT_OPTION_CLASSNAME = 'mat-option-text';

export const IMPLICIT_DENY_POLICY_WARNING =
  'If no policy is selected then access will be implicitly denied';

export const DIALOG_TOKEN = {
  INJECTOR_TOKEN_DATA: {
    DIALOG_DATA_TOKEN: {
      NAME: 'DIALOG_DATA_TOKEN',
      INJECTOR: DIALOG_DATA_TOKEN,
    },
    WARNING_DIALOG_DATA_TOKEN: {
      NAME: 'WARNING_DIALOG_DATA_TOKEN',
      INJECTOR: WARNING_DIALOG_DATA_TOKEN,
    },
  },
};

export const DEFAULT_NAMESPACE = 'default';

export const EDGE_VALIDATION = (errorMsg) => ({
  errorTitle: 'Edge requirements validation failed',
  errorMsg,
  isInnerHTML: true,
});

export const CONNECTOR_GROUP_VALIDATION = (errorMsg) => ({
  errorTitle: 'Connector Group requirements validation failed',
  errorMsg,
  isInnerHTML: true,
});

export const ALL_LOWERCASE = 'abcdefghijklmnopqrstuvwxyz';
export const ALL_UPPERCASE = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
export const PWD_SPECIAL_CHAR = '!@#$%^&*./';
export const ALL_NUMERIC = '0123456789';
export const IP_RANGE_TOOLTIP = `Enter a valid RFC 1918 private address CIDR block for edge deployment`;
export const EGRESS_NAT_BANDWIDTH_OPTIONS = [
  {
    displayName: '1 Gbps',
    value: 1,
  },
  {
    displayName: '2 Gbps',
    value: 2,
  },
  {
    displayName: '3 Gbps',
    value: 3,
  },
  {
    displayName: '4 Gbps',
    value: 4,
  },
  {
    displayName: '5 Gbps',
    value: 5,
  },
];

export const ELASTIC_IP_OPTIONS = [
  {
    displayName: 'Auto',
    class: 'p-0',
    value: 'auto',
  },
  {
    displayName: 'Manual',
    class: 'ml-2',
    value: 'manual',
  },
];

export const ELASTIC_IP_OPTION_KEYS = {
  AUTO: 'auto',
  MANUAL: 'manual',
};

export interface LoaderConfig {
  status: boolean;
  message: string;
  type: string;
}

export const DEFAULT_LOADER_CONFIG: LoaderConfig = {
  status: false,
  message: '',
  type: '',
};

export const LOADER_CONFIG_TYPES = {
  LOADER: 'LOADER',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export const LOADER_MESSAGES = {
  LOADING: 'Loading...',
};

export const HELP_ARTICLE_ID = {
  QUICK_START: '24071605356813',
  VIDEO_TUTORIAL: '24074200084621',
  EDGE_DEPLOYMENT: '16275653428877',
  ALERT_NOTIFICATIONS: '20909282686221',
  INFRA_VPC: '12338143704973',
};

export enum DeleteAction {
  DELETE = 'delete',
  OFFBOARD = 'offboard',
  DECOMMISSION = 'decommission',
}

export const EGRESS_NAT_SUPPORTED_CLOUD = [CLOUD_TYPES.AWS, CLOUD_TYPES.AZURE];

export const VIRTUAL_NETWORKS_LABELS = {
  VPC: 'VPC',
  VNET: 'VNet',
};

export const LAST_UPDATED_TEXT = 'Last Updated: ';

export const SYNC_FROM_IDP = 'SYNC_FROM_IDP';

export const POLICY_CONFLICT_MESSAGE =
  'Some policies cannot be attached because of conflicting match conditions';

export const SERVICE_INSERTION_ENDPOINT_TYPES = {
  AUTO: 'auto',
  MANUAL: 'manual',
  NONE: 'none',
};

export const CONNECTOR_TYPE_VALUE = {
  INFRA: 'infra',
  APP: 'app',
  NONE: 'none',
};

export const AppOnboardingTypesLegendNames = {
  AXI_APP: `${APP_TYPE_LABELS.TARGET_APP()}`,
  CLIENT_APP: `${APP_TYPE_LABELS.SOURCE_APP()}`,
  BOTH: 'Both',
  UNAPPLIED_CONFIGURATION: 'Unapplied Configuration',
};

export const APP_ONBOARDING_LEGENDS = [
  {
    name: AppOnboardingTypesLegendNames.UNAPPLIED_CONFIGURATION,
    status: LEGEND_STATUS.OFF,
    searchTerm: {
      key: 'statusFilter',
      value: `${APP_SETUP_STATUS.CONFIGURED},${APP_SETUP_STATUS.CONFIGURING}`,
      other: { deployed: true },
    },
    customStyles: {
      classes: {
        selected: 'lt-folded-corner-active',
        active: 'lt-folded-corner-active',
        inactive: '',
      },
      styles: {
        selected: '',
        active: ``,
        inactive: `height: 12px;
                      width: 12px;
                      border-radius: unset !important;
                      content: url('${IMAGE_PATHS.APP_ONBOARD.UNAPPLIED_CONFIGURATION}');`,
      },
    },
    color: {
      border: COLORS.TEAL,
      fill: COLORS.TEAL,
    },
  },
];

export const APPS_DASHBORD_TAB_VALUES = {
  ONNBOARDED: 'ONNBOARDED',
  DISCOVERED: 'DISCOVERED',
  DECOMMISSIONED: 'DECOMMISSIONED',
};

export const APP_DASHBOARD_URLS = {
  ONBOARDED: URL_PATHS.DASHBOARD_APPS,
  DISCOVERED: URL_PATHS.DASHBOARD_APPS_DISCOVERED,
};

export const NETWORK_DASHBOARD_NAV = {
  [APPS_DASHBORD_TAB_VALUES.ONNBOARDED]: URL_PATHS.DASHBOARD_NETWORKS,
  [APPS_DASHBORD_TAB_VALUES.DISCOVERED]:
    URL_PATHS.DASHBOARD_NETWORKS_DISCOVERED,
};
