import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CommonModelSerializer, ResourceService } from '@prosimoio/services';

import { ConfigDefaults } from '@app/common/models';
import { CONSTANTS } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends ResourceService<any> {
  constructor(protected httpClient: HttpClient) {
    super(
      httpClient,
      CONSTANTS.DASHBOARD_HOST,
      '',
      new CommonModelSerializer()
    );
  }

  getCloudsAndIDPsConfig(): Observable<ConfigDefaults> {
    this.path = CONSTANTS.PROSIMO_APP_ONBOARD.CONFIG_DEFAULTS;
    return this.read('');
  }
}
