<div id="legendContainer">
  <ul class="row d-flex justify-content-center align-items-center">
    <li
      class="d-flex justify-content-center align-items-center mx-4"
      (click)="setSelectedLegend(legend)"
      *ngFor="let legend of legends"
    >
      <div
        class="d-flex justify-content-center align-items-center circle-container"
      >
        <div
          *ngIf="
            legend?.status === LEGEND_STATUS.ON;
            then selectedLegendBlock;
            else unSelectedLegendBlock
          "
        ></div>
        <ng-template #selectedLegendBlock>
          <div
            [ngClass]="{
              'outer-circle d-flex justify-content-center align-items-center': true
            }"
            [ngStyle]="{
              'border-color': legend?.color?.border,
              'box-shadow': '0px 0px 5px 1px ' + legend?.color?.border
            }"
          >
            <span
              [ngClass]="{ 'position-absolute legend': true }"
              [ngStyle]="{
                'background-color': legend?.color?.border,
                'z-index': 99
              }"
            >
            </span>
          </div>
        </ng-template>
        <ng-template #unSelectedLegendBlock>
          <span
            class="d-block"
            [ngClass]="{ 'position-absolute legend': true }"
            [ngStyle]="{
              'background-color': legend?.color?.border,
              border: '1px solid' + legend?.color?.border
            }"
          ></span>
        </ng-template>
      </div>
      <span> = </span>
      <span>{{ legend.name }}</span>
    </li>
  </ul>
</div>
