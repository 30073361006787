import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { COLORS, FILTER_TYPES } from '@app/common/util/constants';
import { AppUtil } from '@app/common/util/app-util';
import { DatePipe } from '@angular/common';
import { ALL } from '@app/dashboard/dashboard.constants';
import { ZTNAInsightsSharedFilterState } from '@app/dashboard/state/ztna-insights-filters';

@Component({
  selector: 'app-ztna-search-history-filter',
  templateUrl: './ztna-search-history-filter.component.html',
  styleUrls: ['./../search-history-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZTNASearchHistoryFilterComponent implements OnInit {
  @Input() recentFilterIcons;
  @Output() deleteFilter = new EventEmitter();
  @Output() applyFilter = new EventEmitter();
  totalSources = 0;
  totalTargets = 0;
  filterDataConfig: any;
  @Input() set filterData(data) {
    this.totalSources = 0;
    this.totalTargets = 0;
    this.filterDataConfig = data;
    this.aggregateSourceAndTargetFilters(data);
  }
  filterDisplayIcons = {};
  COLOR = COLORS;
  FILTER_TYPE = FILTER_TYPES;
  selectedIndex: number;
  COPY_ALL = ALL;
  constructor(private appUtil: AppUtil, private datePipe: DatePipe) {}

  ngOnInit() {}

  aggregateSourceAndTargetFilters(filterData) {
    const {
      selectedTeamUsers,
      selectedUserGroups,
      selectedOnboardedApps,
      selectedAppGroups,
      selectedNetworks,
      selectedNetworkGroups,
    } = filterData;
    const ztnaInsightsFilterState: ZTNAInsightsSharedFilterState =
      this.appUtil.getInsightsDashboardFilters();
    const {
      totalUsers = 0,
      totalUserGroups = 0,
      totalApps = 0,
      totalAppGroups = 0,
      totalNetworks = 0,
      totalNetworkGroups = 0,
    } = ztnaInsightsFilterState;

    if (
      this.appUtil.isMasterSelectAllRecords(
        selectedTeamUsers,
        FILTER_TYPES.USER
      )
    ) {
      this.totalSources += totalUsers;
    } else {
      this.totalSources += selectedTeamUsers.length;
    }
    if (
      this.appUtil.isMasterSelectAllRecords(
        selectedUserGroups,
        FILTER_TYPES.USER_GROUP
      )
    ) {
      this.totalSources += totalUserGroups;
    } else {
      this.totalSources += selectedUserGroups.length;
    }
    if (
      this.appUtil.isMasterSelectAllRecords(
        selectedOnboardedApps,
        FILTER_TYPES.SRC_PRIVATE_LINKS
      )
    ) {
      this.totalTargets += totalApps;
    } else {
      this.totalTargets += selectedOnboardedApps.length;
    }
    if (
      this.appUtil.isMasterSelectAllRecords(
        selectedAppGroups,
        FILTER_TYPES.APP_GROUP
      )
    ) {
      this.totalTargets += totalAppGroups;
    } else {
      this.totalTargets += selectedAppGroups.length;
    }

    if (
      this.appUtil.isMasterSelectAllRecords(
        selectedNetworks,
        FILTER_TYPES.TGT_NETWORKS
      )
    ) {
      this.totalTargets += totalNetworks;
    } else {
      this.totalTargets += selectedNetworks.length;
    }
    if (
      this.appUtil.isMasterSelectAllRecords(
        selectedNetworkGroups,
        FILTER_TYPES.TGT_NETWORK_GROUPS
      )
    ) {
      this.totalTargets += totalNetworkGroups;
    } else {
      this.totalTargets += selectedNetworkGroups.length;
    }
  }
  onSearchHistoryFilterDelete(value: string) {
    this.deleteFilter.emit(value);
  }

  onSearchHistoryFilterApply(filterData: any) {
    this.applyFilter.emit(filterData);
  }
}
