import { Store, StoreConfig } from '@datorama/akita';

export interface SecurityState {
  activeTab: string;
}

export function createInitialState(): SecurityState {
  return {
    activeTab: '',
  };
}

@StoreConfig({ name: 'security' })
export class SecurityStore extends Store<SecurityState> {
  constructor() {
    super(createInitialState());
  }
}

export const securityStore = new SecurityStore();
