
export interface PrivateLink {
  id?: string;
  name: string;
}

export interface PrivateLinkFilters {
  privateLinks?: Array<PrivateLink>;
  selectedPrivateLinks?: Array<PrivateLink>;
  type?: string;
  viewMode?: string;
}

export const PrivateLinkMasterSelectConfig: PrivateLink = {
  id: 'all',
  name: 'all'
}

