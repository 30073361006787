import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { ALL } from '@app/dashboard/dashboard.constants';
import {
  ColumnDef,
  GridViewConfig,
  MasterSelectConfig,
  SortInfo,
} from './grid.model';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridComponent implements OnInit {
  dataSource: any;
  selection: any;
  private viewConfig: GridViewConfig;
  displayedColumns: string[];
  sortKey: string;
  columnDef: Array<ColumnDef>;
  rowPaddingBottom = 0;
  _masterSelectConfig: MasterSelectConfig = { isShow: false };
  @Input() set masterSelectConfig(masterSelectConfig: MasterSelectConfig) {
    this._masterSelectConfig = masterSelectConfig;
  }
  get masterSelectConfig() {
    return this._masterSelectConfig;
  }
  @Input() set gridViewConfig(gridViewConfig: GridViewConfig) {
    this.viewConfig = gridViewConfig;
    this.rowPaddingBottom = gridViewConfig?.rowPaddingBottom;
    this.setGridConfig();
  }
  @Output()
  selectAllConfig = new EventEmitter(); // Emits is row selected or not and the selected row's value
  @Output() selectedRowInfo = new EventEmitter(); // Emits is row selected or not and the selected row's value
  @Output() sortInfo = new EventEmitter<SortInfo>(); // Emits sort information - column key name and the sort order

  constructor() {}

  ngOnInit() {}

  setGridConfig() {
    this.dataSource = this.viewConfig.dataSource;
    this.selection = this.viewConfig.selection;
    this.displayedColumns = this.viewConfig.displayedColumns;
    this.sortKey = this.viewConfig.sortKey;
    this.columnDef = this.viewConfig.columnDef;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.selectedRowInfo.emit({
        isSelected: false,
        values: this.dataSource.data,
      });
      this._masterSelectConfig.isShow = false;
    } else {
      this.dataSource.data.forEach((row) => this.selection.select(row));
      this.selectedRowInfo.emit({
        isSelected: true,
        values: this.dataSource.data,
      });
      this._masterSelectConfig.isShow = true;
    }
  }

  updateSelection(row: any) {
    this.selection.toggle(row);

    if (this.isAllSelected()) {
      this.masterSelectConfig.isShow = true;
    } else {
      this.masterSelectConfig.isShow = false;
    }
    if (this.masterSelectConfig?.isSelected) {
      this.selectedRowInfo.emit({
        isSelected: true,
        values: this.selection.selected,
      });
    } else {
      this.selectedRowInfo.emit({
        isSelected: this.selection.isSelected(row),
        values: [row],
      });
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.username
    }`;
  }

  sortRows(sort: MatSort, sortKey: string) {
    if (sort.direction) {
      this.sortInfo.emit({
        key: sortKey,
        order: sort.direction.toUpperCase(),
      });
    }
  }

  masterSelectAllRecords() {
    this._masterSelectConfig.isSelected = !this._masterSelectConfig.isSelected;
    this.selectAllConfig.emit(this._masterSelectConfig);
    if (this._masterSelectConfig.isSelected) {
      this.selectedRowInfo.emit({
        isSelected: true,
        values: ALL,
      });
    } else {
      this.selection.clear();
      this.selectedRowInfo.emit({
        isSelected: false,
        values: this.dataSource.data,
      });
    }
  }
}
