import { DrawerResponseContext } from '@app/v2/onboard/visual-onboard/visual-onboard.constants';

export interface LauncherConfig {
  buttons: Array<LauncherBtnConfig>;
}

export interface LauncherBtnConfig {
  title?: string;
  tooltip?: string;
  iconURL?: string;
  largeIcon?: boolean;
  action?: LauncherAction | DrawerResponseContext;
  validCSPs: Array<string>;
}

export enum LauncherAction {
  'ZOOM-IN' = 'ZOOM-IN',
  'ZOOM-OUT' = 'ZOOM-OUT',
  MENU = 'MENU',
  RESET = 'RESET',
}
