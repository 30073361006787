import { Query } from '@datorama/akita';
import { PDashStore, PDashState, pDashStore, Day0 } from './web-pdash.store';
import { Observable } from 'rxjs';
import { PageConfig, UI_THEME_MODES } from '@app/common/util/constants';

export class PDashQuery extends Query<PDashState> {
  pageInfo$ = this.select((state) => state.pageInfo);
  helpConfig$ = this.select((state) => state.helpConfig);
  regionConfig$ = this.select((state) => state.regionConfig);
  requestedUrl$ = this.select((state) => state.requestedUrl);
  productViewType$ = this.select((state) => state?.productViewType);
  uiThemeMode$ = this.select((state) => state.uiThemeMode);
  day0Details$ = this.select((state) => state.day0);

  constructor(protected store: PDashStore) {
    super(store);
  }

  get pageInfo(): Observable<PageConfig> {
    return this.pageInfo$;
  }

  get helpConfig(): Observable<any> {
    return this.helpConfig$;
  }

  get regionConfig(): Observable<any> {
    return this.regionConfig$;
  }

  get requestedUrl(): Observable<string> {
    return this.requestedUrl$;
  }

  get productViewType(): Observable<string> {
    return this.productViewType$;
  }

  get uiThemeMode(): Observable<UI_THEME_MODES> {
    return this.uiThemeMode$;
  }

  get day0Details(): Observable<Day0> {
    return this.day0Details$;
  }
}

export const pDashQuery = new PDashQuery(pDashStore);
