import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusPillComponent } from './status-pill.component';

@NgModule({
  declarations: [StatusPillComponent],
  imports: [CommonModule],
  exports: [StatusPillComponent],
})
export class StatusPillModule {}
