import { Store, StoreConfig } from '@datorama/akita';
import { TimeRange } from '@prosimoio/services';
import {
  Network,
  NetworkMasterSelectConfig,
} from '@app/dashboard/shared/filter-list/network-list/network-list.model';
import { OnboardedApp } from '@app/dashboard/shared/filter-list/client-list/client-list.model';
import { Region } from '@app/dashboard/shared/filter-list/region-list/region-list.model';
import { OnboardedAppMasterSelectConfig } from '@app/dashboard/shared/filter-list/app-list/apps-list.model';
import {
  PrivateLink,
  PrivateLinkMasterSelectConfig,
} from '@app/dashboard/shared/filter-list/private-link-list/private-link-list.model';
import { Restrictions } from '@app/common/components/filter/playground-filter/playground-filter.model';

export enum TimeRangeType {
  PAST_ONE_DAY = 'PAST_ONE_DAY',
  PAST_ONE_WEEK = 'PAST_ONE_WEEK',
  PAST_ONE_MONTH = 'PAST_ONE_MONTH',
  CUSTOM = 'CUSTOM',
}
export interface PlaygroundFilterState {
  selectedNetworks?: Array<Network>;
  selectedPrivateLinks?: Array<PrivateLink>;
  selectedRegions?: Array<Region>;
  selectedOnboardedApps?: Array<OnboardedApp>;
  selectedTimeRangeType?: TimeRangeType;
  timeRange?: TimeRange;
  activeTab?: string;
  activeSubMenu?: string;
  levelTwoActiveSubMenu?: string;
  totalNetworks?: number;
  totalPrivateLinks?: number;
  totalRegions?: number;
  totalOnboardedApps?: number;
  restrictions: Restrictions;
  source?: string;
}

export function createInitialState(): PlaygroundFilterState {
  return {
    selectedNetworks: [NetworkMasterSelectConfig],
    selectedPrivateLinks: [PrivateLinkMasterSelectConfig],
    selectedRegions: [],
    selectedOnboardedApps: [OnboardedAppMasterSelectConfig],
    selectedTimeRangeType: TimeRangeType.PAST_ONE_WEEK,
    timeRange: { from: 0, to: 0, interval: '' },
    activeTab: '',
    levelTwoActiveSubMenu: '',
    activeSubMenu: '',
    source: 'FILTER_HISTORY',
    totalNetworks: 0,
    totalPrivateLinks: 0,
    totalRegions: 0,
    totalOnboardedApps: 0,
    restrictions: {
      rCSP: true,
      rContinent: false,
      rCountry: false,
      rRegion: false
    }
  };
}

@StoreConfig({ name: 'playground-filters', resettable: true })
export class PlaygroundFilterStore extends Store<PlaygroundFilterState> {
  constructor() {
    super(createInitialState());
  }
}

export const playgroundFilterStore = new PlaygroundFilterStore();
