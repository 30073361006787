import { IMAGE_PATHS, MENU_NAMES, URL_PATHS } from '@app/common/util/constants';
import { ENV_CONSTANTS } from 'environments/env.constants';
import { CONSTANTS } from 'environments/environment';

export interface NavMenuItem {
  imgUrl: string;
  selectedImgUrl: string;
  name: string;
  isSelected: boolean;
  routeUrls: Array<string>;
  childRouteUrls?: Array<string>;
  subMenu?: Array<any>;
  parentName?: string;
  isMain?: boolean;
  isDisableOnTrial?: boolean;
  isDisable?: boolean;
  isHidden?: boolean;
}

export interface UserSettings {
  firstName: string;
  lastName: string;
}

export interface NavMenuLogo {
  imgUrl: string;
  name: string;
}

export const MENU_NAMES_TO_HIDE_IN_PROD_ENV = [];

export const MSP_MENU_ITEMS = [
  {
    imgUrl: IMAGE_PATHS.NAVIGATION.PROSIMO,
    selectedImgUrl: IMAGE_PATHS.NAVIGATION.SELECTED_PROSIMO,
    name: MENU_NAMES.PROSIMO,
    isSelected: false,
    isMain: true,
    routeUrls: ['/sp/dashboard'],
    isDisable: false,
    isHidden: false,
    fullPath: URL_PATHS.MSP.DASHBOARD,
  },
  {
    imgUrl: IMAGE_PATHS.NAVIGATION.MANAGEMENT,
    selectedImgUrl: IMAGE_PATHS.NAVIGATION.SELECTED_MANAGEMENT,
    name: MENU_NAMES.MANAGEMENT,
    isSelected: false,
    routeUrls: ['/sp/management'],
    isDisable: false,
    isHidden: false,
    fullPath: URL_PATHS.MSP.MANAGEMENT,
  },
];

export const DEFAULT_MENU_ITEMS = [
  {
    imgUrl: IMAGE_PATHS.NAVIGATION.PROSIMO,
    selectedImgUrl: IMAGE_PATHS.NAVIGATION.SELECTED_PROSIMO,
    name: 'Home',
    isSelected: false,
    isMain: true,
    routeUrls: ['/dashboard/main'],
    isDisable: false,
    isHidden: false,
    fullPath: URL_PATHS.HOME,
  },
  {
    imgUrl: IMAGE_PATHS.NAVIGATION.APP,
    selectedImgUrl: IMAGE_PATHS.NAVIGATION.SELECTED_APP,
    name: MENU_NAMES.INSIGHTS,
    isSelected: false,
    routeUrls: [
      'v2/insights',
      'v2/transit-360/infra',
      'v2/transit-360/target',
      'cost/overview',
      'dashboard/insights',
      'cost/chargeback',
      'cost/data-transfer',
      'cost/shared-service',
    ],
    isDisable: false,
    isHidden: false,
    fullPath: URL_PATHS.INSIGHTS,
  },
  {
    imgUrl: IMAGE_PATHS.NAVIGATION.ON_BOARDING,
    selectedImgUrl: IMAGE_PATHS.NAVIGATION.SELECTED_ON_BOARDING,
    name: MENU_NAMES.ONBOARD_V2,
    isSelected: false,
    routeUrls: [CONSTANTS.V2.ONBOARD.UI_BASE_PATH, '/dashboard/apps'],
    isDisable: false,
    isHidden: false,
    fullPath: URL_PATHS.ONBOARD,
  },

  {
    imgUrl: IMAGE_PATHS.NAVIGATION.SECURITY,
    selectedImgUrl: IMAGE_PATHS.NAVIGATION.SELECTED_SECURITY,
    name: MENU_NAMES.CONFIGURE,
    isSelected: false,
    routeUrls: [URL_PATHS.POLICY],
    isDisable: false,
    isHidden: false,
    fullPath: URL_PATHS.POLICY,
  },

  {
    imgUrl: IMAGE_PATHS.NAVIGATION.MANAGEMENT,
    selectedImgUrl: IMAGE_PATHS.NAVIGATION.SELECTED_MANAGEMENT,
    name: MENU_NAMES.MANAGEMENT,
    isSelected: false,
    routeUrls: ENV_CONSTANTS.MANAGEMENT.MANAGEMENT_ROUTE_URL,
    isDisable: false,
    isHidden: false,
    isBetaRelease: false,
    fullPath: URL_PATHS.MANAGEMENT,
  },
  {
    imgUrl: IMAGE_PATHS.NAVIGATION.TROUBLESHOOT,
    selectedImgUrl: IMAGE_PATHS.NAVIGATION.SELECTED_TROUBLESHOOT,
    name: MENU_NAMES.DIAGNOSE,
    isSelected: false,
    routeUrls: [`v2/diagnose`],
    isDisable: false,
    isHidden: false,
    isBetaRelease: false,
    fullPath: URL_PATHS.DIAGNOSE,
  },
  {
    imgUrl: IMAGE_PATHS.NAVIGATION.PLAYGROUND,
    selectedImgUrl: IMAGE_PATHS.NAVIGATION.SELECTED_PLAYGROUND,
    name: MENU_NAMES.PLAYGROUND,
    isSelected: false,
    routeUrls: [CONSTANTS.PLAYGROUND.UI_PATH.UI_BASE_PATH],
    isDisable: false,
    isHidden: false,
    isBetaRelease: false,
    fullPath: URL_PATHS.ENG_LABS,
  },
];

export const DEFAULT_ACTIONS_ITEMS = [
  {
    name: 'TASKS',
    isSelected: false,
    routeUrls: [URL_PATHS.TASKS],
    fullPath: URL_PATHS.TASKS,
  },
  {
    name: 'ALERTS',
    isSelected: false,
    routeUrls: [URL_PATHS.ALERT],
    fullPath: URL_PATHS.ALERT,
  },
  {
    name: 'HELP',
    isSelected: false,
    routeUrls: [''],
    fullPath: '',
  },
  {
    name: 'USER_PROFILE',
    isSelected: false,
    routeUrls: [URL_PATHS.MY_PROFILE],
    fullPath: 'dashboard/user-profile',
  },
];

export const MSP_DEFAULT_MENU_ITEM = {
  imgUrl: IMAGE_PATHS.NAVIGATION.PROSIMO,
  selectedImgUrl: IMAGE_PATHS.NAVIGATION.SELECTED_PROSIMO,
  name: MENU_NAMES.PROSIMO,
  isSelected: false,
  routeUrls: ['/sp/dashboard'],
  isDisable: false,
  isHidden: false,
};

export const DEFAULT_MENU_ITEM = {
  imgUrl: IMAGE_PATHS.NAVIGATION.APP,
  selectedImgUrl: IMAGE_PATHS.NAVIGATION.SELECTED_APP,
  name: 'App Dashboard',
  isSelected: false,
  routeUrls: ['/dashboard/apps'],
  isDisable: false,
  isHidden: false,
};

// Provide menu names which needs to be disabled based on product type
export const MENU_NAMES_TO_BE_REMOVED_FOR_MCN = [
  MENU_NAMES.DISCOVERY,
  MENU_NAMES.DEVICE_POSTURE,
  MENU_NAMES.DYNAMIC_RISK,
  MENU_NAMES.USER_SETTINGS,
  MENU_NAMES.GUIDE,
  MENU_NAMES.AGENT,
  MENU_NAMES.IP_REPUTATION,
];

export const MENU_NAMES_TO_BE_REMOVED_FOR_MCN_IF_NO_PRIVATE_LINK = [
  MENU_NAMES.DISCOVERY,
  MENU_NAMES.DEVICE_POSTURE,
  MENU_NAMES.DYNAMIC_RISK,
  MENU_NAMES.USER_SETTINGS,
  MENU_NAMES.GUIDE,
  MENU_NAMES.AGENT,
  MENU_NAMES.PRIVATE_LINK_SOURCE,
];

export const MENU_NAMES_TO_BE_REMOVED_FOR_ZTNA = [
  MENU_NAMES.PRIVATE_LINK_SOURCE,
  MENU_NAMES.SHARED_SERVICE,
  MENU_NAMES.PLAYGROUND,
  MENU_NAMES.COST,
];

export const PLAYGROUND_TEAM_NAMES = ['', 'fullstacktransit'];

export class NavigationBarModel {
  logo: NavMenuLogo;
  menuItems: Array<NavMenuItem>;
  actionItems: Array<any>;
  selectedMenuItem: NavMenuItem;
  userSettings: UserSettings;
  prosimoAdmin: boolean;
  isMSP: boolean;

  constructor(pAdmin: boolean = false, isMsp: boolean = false) {
    this.logo = { imgUrl: IMAGE_PATHS.LOGO.MAIN, name: 'prosimo' };
    this.prosimoAdmin = pAdmin;
    (this.isMSP = isMsp),
      (this.menuItems = this.getMenuItems()),
      (this.actionItems = this.getActionItems());
  }

  getMenuItems(): Array<NavMenuItem> {
    return this.isMSP ? MSP_MENU_ITEMS : DEFAULT_MENU_ITEMS;
  }

  getActionItems(): Array<any> {
    return DEFAULT_ACTIONS_ITEMS;
  }

  getDefaultMenuItem(): NavMenuItem {
    return this.isMSP ? MSP_DEFAULT_MENU_ITEM : DEFAULT_MENU_ITEM;
  }
}
