export const ENV_CONSTANTS = {
  DASHBOARD_HOST: '',
  DASHBOARD_API_TOKEN: '',
  DASHBOARD_ROOT: `api`,
  ENABLE_DYNAMIC_HOST: false,
  VALIDATE_ACCESS: `api/account/team/access`,
  AUTH_PATH: 'api/account/login',
  GET_SSO_AUTH_PATH: `api/account/team/auth/info`,
  GET_USER_INFO: `api/account/auth/info`,
  TEAM_META_INFO: `api/team/metainfo`,
  AUTH_CODE: (userID) => `api/account/login/${userID}/send`,
  AUTH_CODE_VERIFY: (userID) => `api/account/login/${userID}/verify`,
  EXTERNAL_REPORT_DASHBOARD_API: {
    VALIDATE_TOKEN: `validate-prosimo-token`,
  },
  MFA: {
    UI_PATH_LOGIN: '/login',
    UI_PATH_AUTHENTICATION: '/authentication',
  },
  SIGNIN: {
    TEAM_URLS_REQUEST: `api/account/team/domain/request`,
    TEAM_NAME_VALIDATION: `api/account/team/name/verification`,
  },
  SIGN_UP: 'api/account/signup',
  ACCOUNT_ACTIVATION: 'api/account/activation',
  RESEND_VERIFICATION_EMAIL: 'api/account/verification',
  MAINTENANCE_INFO: 'api/account/maintenance',
  ACCOUNT_EXTENSION: 'api/account/team/access/extension/request',
  UPDATE_TRIAL_ACCOUNT: 'api/account/trial/onboard',
  PAGE_CONFIG: 'api/support/help/articles/config',
  PASSWORD: {
    RESET: 'api/account/password/reset',
    POST: `api/account/team/name`,
    GET_DASHBOARD_URL_SUFFIX: (accountID) =>
      `api/account/${accountID}/dashboard/url/suffix`,
    VALIDATE_TOKEN: `api/account/token/validation`,
  },
  PASSWORD_VERIFICATION: (token) =>
    `api/account/password/verification/${token}`,
  PASSWORD_RESET: 'api/account/password/reset',
  PASSWORD_RESET_BULK: 'api/account/password/bulk/reset',
  ADMIN_ACCOUNT_REQUEST: 'api/admin/account',
  ADMIN_ACCOUNT_REQUEST_APPROVAL: 'api/admin/account/approval',
  ADMIN_TEAMS: 'api/admin/team/search',
  ADMIN_TEAM_EXTENSION: (teamID) => `api/admin/team/${teamID}/expiry/extension`,
  ADMIN_TEAM_LICENSE_STATUS: (teamID) =>
    `api/admin/team/${teamID}/license/status`,
  CAPTCHA_SITE_KEY: '6LdOE6AUAAAAAIQ89UIYl91OSWARosCv6nRuE5i8',
  PROSIMO_APP_ONBOARD: {
    CLOUD: {
      POST: (appId) => `api/prosimo/app/${appId}/cloud`,
      CONFIG: (appType, appId) => `api/${appType}/app/${appId}/cloud`,
    },
    IDP: (appId) => `api/prosimo/app/${appId}/idp`,
    SUMMARY: (appId) => `api/prosimo/app/${appId}/summary`,
    LAUNCH: (appId) => `api/prosimo/app/deployment/${appId}`,
    CLOUD_REGIONS: 'api/cloud/region',
    GCP_CLOUD_REGIONS: 'api/cloud/region/file',
    GCP: {
      POST: (appId) => `api/prosimo/app/${appId}/file/cloud`,
    },
    SETTINGS: {
      POST: 'api/prosimo/app/settings',
    },
    STATUS: 'api/prosimo/app/status',
    CONFIG_DEFAULTS: 'api/account/team/config/defaults',
  },
  APP_ONBOARD: {
    GET_DEFAULT: (id) => `api/app/onboard/${id}/appopt`,
    POLICY: {
      GET: 'api/policy/group',
      POST: (appId) => `api/app/onboard/${appId}/policy`,
      PUT: (appId) => `api/app/onboard/${appId}/waf`,
    },
    SETTINGS: {
      POST: 'api/app/onboard/settings',
      PUT: (appId) => `api/app/onboard/${appId}/settings`,
      GET: (appId) => `api/app/onboard/${appId}/settings`,
      PROSIMO_DOMAIN: `api/app/onboard/prosimodomain`,
      AUTO_GENERATE_SERVICE_IP: `api/app/onboard/synthetic/ip/generate`,
      VALIDATE_SERVICE_IP: `api/app/onboard/synthetic/ip/validate`,
    },
    DNS: {
      GET_CLOUDS: `api/cloud/creds`,
      UPDATE_DNS: (appId) => `api/app/onboard/${appId}/dns`,
      GET_ADVANCED_STATUS: (appId) => `api/app/onboard/${appId}/advanced/dns`,
      GET_REGIONS: `api/app/onboard/advanced/dns/regions`,
    },
    SSL: {
      CERTIFICATES: `api/cert`,
      DOMAIN_CERTIFICATE: `api/cert/domain`,
      INTERNAL_CERTIFICATE: `api/cert/internal`,
      CA_CERTIFICATE: `api/cert/ca`,
      CLIENT_CERTIFICATE: `api/cert/client`,
      URL_CERT_MAPPING: (appId) => `api/app/onboard/${appId}/cert`,
      GET: (appId) => `api/app/onboard/${appId}/cert`,
      PUT: (appId) => `api/app/onboard/${appId}/cert`,
    },
    GCP: {
      POST: (appId) => `api/app/onboard/${appId}/file/cloud`,
    },
    IDP: {
      GET: (appId) => `api/app/onboard/${appId}/idp`,
    },
    APPS: {
      BEHIND_FABRIC: (id) => `api/app/onboard/${id}/capp`,
      ACCESSING_FABRIC: (id) => `api/capp/${id}/app`,
    },
    CLOUD: {
      GET: (appId) => `api/app/onboard/${appId}/cloud`,
      UPDATE_DISCOVERY: (appId) => `api/app/onboard/${appId}/cloud`,
      CONFIG: (appType, appId) => `api/${appType}/onboard/${appId}/cloud`,
      ENDPOINTS_BY_REGIONS: (appId) =>
        `api/app/onboard/${appId}/endpoint/discovered`,
      ENDPOINTS_BY_REGIONS_WITH_NO_APP_ID: `api/app/onboard/endpoint/discovered`,
    },
    SUMMARY: (appId) => `api/app/onboard/${appId}/summary`,
    LAUNCH: (appId) => `api/app/deployment/${appId}`,
    GET_ONBOARDED_APPS: `api/app/onboard/search`,
    ADVANCED_SETTING: {
      URL_REWRITE: `api/app/onboard/advanced/rewrite`,
      CREATE_URL_REWRITE: (id) => `api/app/onboard/advanced/rewrite/${id}`,
      DELETE_URL_REWRITE: `api/app/onboard/advanced/rewrite/delete`,
      IMPORT_URL_REWRITE: (id) =>
        `api/app/onboard/advanced/rewrite/import/${id}`,
      RESET_URL_REWRITE: `api/app/onboard/advanced/rewrite/reset`,
      HTTP_HEADER_REWRITE: {
        TEAM_HEADER_MANIPULATION_PATH: `api/app/onboard/advanced/team/header/manipulation`,
        DEFAULT_PATH: `api/app/onboard/advanced/header/manipulation`,
        HEADERS_PATH: `api/app/onboard/advanced/header`,
        VARIABLES_PATH: `api/app/onboard/advanced/header/values`,
        TEAM_INFO_PATH: `api/app/onboard/advanced/team`,
      },
      APP_TRANSIT_SETTINGS: `api/app/onboard/advanced/settings/app-transit`,
      UI_PATH: (context: 'app' | 'network' = 'app') =>
        `/${context}-onboard/advanced-setting`,
      UI_PATH_URL_REWRITE: (context: 'app' | 'network' = 'app') =>
        `/${context}-onboard/advanced-setting/url-rewrite`,
      UI_PATH_HTTP_REWRITE: (context: 'app' | 'network' = 'app') =>
        `/${context}-onboard/advanced-setting/http-rewrite`,
      UI_PATH_APP_TRANSIT_SETTINGS: (context: 'app' | 'network' = 'app') =>
        `/${context}-onboard/advanced-setting/app-transit-settings`,
    },
    AWS_EXTERNAL_ID: `api/cloud/creds/external-id`,
    AWS_BYOIP: `api/cloud/advanced-settings/aws/customer-ip-edge-egress`,
  },
  APPS_SEARCH: `api/app/onboard/search`,
  APPS_STATUS_COUNT: `api/app/onboard/status/count`,
  APP_OFFBOARD: `api/app/deployment`,
  APP_STATUS_CHECK: `api/app/onboard/status/check`,
  APP_DELETE: `api/app/onboard`,
  APP_SHARED_SERVICE: 'api/shared-svc/search',
  SERVICE_INSERTION: {
    SEARCH: 'api/svc-insert/search',
    SAVE: 'api/svc-insert',
    UPDATE: 'api/svc-insert',
    DELETE: 'api/svc-insert',
  },
  CLOUD: {
    CONFIGURED_LIST: 'api/cloud/creds',
    REGIONS: {
      GET: (cloudID) => `api/cloud/${cloudID}/region`,
    },
    STORAGE: {
      S3: `api/cloud/service/amazon/s3/discovery`,
    },
    SERVICES: {
      GET: 'api/cloud/support/service',
    },
    IPPOOL: `api/ippool`,
    RESERVED_IP_RANGES: (csp) => `api/ippool/reservedips?cloud=${csp}`,
    VALIDATE_SUBNET: `api/ippool/validate`,
    UPDATE_SUBNET_CLUSTER: (id) => `api/ippool/cluster/${id}`,
  },
  IDP: {
    SUPPORTED_LIST: 'api/idp/supported',
    CONFIGURED_LIST: 'api/idp',
    ALL_USERS_IN_TEAM: 'api/idp/user/search',
    USER_GROUPS: 'api/idp/user/groups',
    IMPORT_USERS: 'api/idp/user/import',
  },
  CONFIGURE: {
    UI_V2_PATH: `/v2/configure`,
  },
  POLICY: {
    SEARCH: ``,
    FORM_DEFINITION_AGENT: `api/policy/file/definition/agent`,
    FORM_DEFINITION_AGENT_LESS: `api/policy/file/definition/agentless`,
    FORM_DEFINITION_ACCESS: `api/policy/file/definition/access`,
    FORM_DEFINITION_TRANSIT: `api/policy/file/definition/transit`,
    POLICY_SEARCH: `api/policy/search`,
    LIST: `api/policy`,
    APPS: `api/app/onboard`,
    POST: `api/policy`,
    PUT: `api/policy`,
    BATCH_UPDATE: `api/policy/batch/app`,
    DELETE: `api/policy`,
    TARGET_APP_AND_SOURCE_APPS_RELATION: 'api/app/onboard/target/source',
    UNIQUE_NAME: (policyName) => `api/policy/availability/name/${policyName}`,
    // TODO : Remove this constant.
    UI_PATH_CREATE_POLICY: `dashboard/policy/create`,
    UI_CREATE_POLICY_PATH: `/v2/configure/policy/access-policy`,
    UI_PATH_POLICY_LIBRARY: `dashboard/policy/library`,
    UI_PATH_EDIT_POLICY: `dashboard/policy/edit`,
    UI_PATH_EGRESS_POLICY: '/v2/configure/security/internet-egress',
  },
  SECURITY: {
    WAF_RULE: `api/waf`,
    FILTER_WAF_RULE: `api/waf/filter`,
    WAF_DOMAIN: `api/app/domain`,
    UPDATE_WAF_DOMAIN: (wafId) => `api/waf/${wafId}/domain`,
    WAF_RULE_GROUP_LIST: `api/waf/ruleset`,
    IP_REPUTATION: `api/ip-reputation`,
    UI_PATH_WAF_ALLOWLIST: (isAllowlist) =>
      `dashboard/security/waf-rules/${isAllowlist}`,
    UI_PATH_IP_ALLOWLIST: `dashboard/security/ip-reputation/allowlist/`,
    UI_PATH_ENDPOINT_INTEGRATION: '/v2/configure/security/endpoint-integration',
    DYNAMIC_RISK: {
      THRESHOLD_DEFINITION: `api/dynamicrisk/definition`,
      DEFAULT_PATH: `api/dynamicrisk`,
      COUNTRY: `api/geo/country`,
      LOCATION: `api/geo/location/search`,
      REGION: (countryCode) => `api/geo/country/${countryCode}/region`,
      CITY_IF_REGION: (countryCode, regionCode) =>
        `api/geo/country/${countryCode}/region/${regionCode}/city`,
      CITY_IF_NO_REGION: (countryCode) => `api/geo/country/${countryCode}/city`,
      LOCATION_ALLOWLIST: `api/geo/allowlist`,
      COUNT: `api/config/count/dynamic-risk`,
    },
    EGRESS: {
      CREATE: `api/net-egress-control`,
      UPDATE: (id) => `api/net-egress-control/${id}`,
      SEARCH: `api/net-egress-control/search`,
      DEFINITION: `api/net-egress-control/file/definition`,
      NETWORKS: `api/net-egress-control/network/search`,
    },
    USER_SETTINGS: {
      USER_SEARCH: `api/usersettings/lockoutuser/search`,
      UNLOCK_USER: `api/usersettings/lockoutuser/unlock`,
      ALLOW_USER_SEARCH: `api/usersettings/excludeduser/search`,
      UPDATE_ALLOW_USER: `api/usersettings/excludeduser`,
      CHECK_DUPLICATE_USER: `api/usersettings/excludeduser/check`,
      REVOKE_USERS: `api/user/revoke`,
    },
    DEVICE_POSTURE: {
      DEVICE_POSTURE_PROFILE: {
        SETTINGS: `api/device-posture/settings`,
        PROFILES: `api/device-posture`,
        RISK_LEVEL: (levelType) => `api/device-posture/risk-level/${levelType}`,
        METADATA: `api/device-posture/metadata`,
      },
      EDR_PROFILE: {
        EDR: `api/edr`,
        PROFILE: `api/edr-profile`,
        UPDATE_EDR: (vendorName) => `api/edr-profile/vendor/${vendorName}`,
        GET_VENDORS: `api/edr/supported`,
      },
    },
  },
  OPTIMIZE: {
    CACHE_RULE: `api/cacherule`,
    CACHE_DOMAIN: `api/app/domain/cache`,
    PURGE_CACHE: (id) => `api/cacherule/purge/${id}`,
    CACHE_DEFAULT: `api/cacherule/default`,
    CACHE_STATIC_CONTENT_MIME_TYPE: `api/cacherule/mime/types`,
    DELETE_MULTIPLE_CACHE: `api/cacherule/remove`,
  },
  PRIVATE_LINK_POLICY: {
    SEARCH: 'api/private-link-source/policy/search',
    CREATE: 'api/private-link-source/policy',
    UPDATE: (id) => `api/private-link-source/policy/${id}`,
    DELETE: (id) => `api/private-link-source/policy/${id}`,
    GET_HOSTEDZONES: 'api/private-link-source/discovery/hostedzones',
  },
  MANAGEMENT: {
    GET_GROUPINGS_VIEW: `api/groupings/search`,
    USER_SUMMARY: `api/usermgmt/summary`,
    GET_APPS_STATUS: `api/app/onboard/status/check`,
    GET_USER_STATUS: `api/idp/user/check`,
    GET_USER_GROUP_STATUS: `api/groupings/usergroup/check`,
    GET_APP_GROUP_STATUS: `api/groupings/appgroup/check`,
    GET_NETWORK_STATUS: `api/network/onboard/status/check`,
    IP_PREFIX_GROUPING: {
      BASE_PATH: 'api/groupings/ip/range',
      SEARCH: 'api/groupings/ip/range/search',
    },
    BGP_COMMUNITY_GROUPING: {
      BASE_PATH: 'api/groupings/bgp',
      SEARCH: 'api/groupings/bgp/search',
    },
    UI_V2_PATH: `management`,
    UI_PATH_ADMIN: `management/admin`,
    UI_PATH_NETWORK_GROUPS: `dashboard/management/network-groups`,
    UI_PATH_CLOUD: `management/cloud`,
    UI_PATH_IDP: `management/idp`,
    UI_PATH_EDGE: `management/edge`,
    UI_PATH_CONNECTOR_GROUPS: `management/connector-groups`,
    UI_PATH_GROUPINGS: `management/groupings`,
    UI_PATH_IP_ADDRESSES: `management/ip-addresses`,
    UI_PATH_CERTIFICATES: `management/certificates`,
    UI_PATH_ALERT: `management/alert`,
    UI_PATH_AGENT_INTEGRATION: `management/agent-integration`,
    UI_PATH_LOGS: `management/log-receiver`,
    UI_PATH_API_TOKENS: `management/api-tokens`,
    UI_PATH_ERROR_PAGES: `management/error-pages`,
    UI_PATH_EDR_INTEGRATION: `management/edr-integration`,
    UI_PATH_DATA_FETCH: `management/data-fetch`,
    UI_PATH_DOWNLOADS: `management/downloads`,
    UI_PATH_PROSIMO_INFRA_TAGS: `management/infra-tags`,
    UI_PATH_ERROR_PAGE_PREVIEW: 'error-pages/preview',
    UI_PATH_SUMMARY_ROUTES: `management/summary-routes`,
    UI_PATH_FIREWALL_CONTROL_CENTER: `management/firewall-control-center`,
    BREADCRUMB_CONFIG: [{ label: 'Management', link: '/management' }],
    MANAGEMENT_ROUTE_URL: ['/management'],
  },
  ROLES_MANAGEMENT: {
    ACTION_DEFINITION: `api/role/action/definition`,
    DEFAULT_PATH: `api/role`,
  },
  POLICY_SECURITY: {
    UI_V2_PATH: '/v2/configure',
  },
  CLOUD_MANAGEMENT: {
    GET_CLOUD: `api/cloud/creds?usage=t`,
    PRIVATE_CLOUD: `api/cloud/private`,
    PRIVATE_CLOUD_STATUS_CHECK: `api/cloud/private/status`,
    POST_CLOUD: `api/cloud/creds`,
    UPDATE_CLOUD: (id) => `api/cloud/creds/${id}`,
    DELETE_CLOUD: (id) => `api/cloud/creds/${id}`,
    GCP_POST_CLOUD: `api/cloud/creds/file`,
    GCP_UPDATE_CLOUD: (id) => `api/cloud/creds/${id}/file`,
    AUTO_UPDATE_CLOUD: (id) => `api/cloud/creds/${id}/auto-update`,
    CONNECTIVITY_OPTION: `api/prosimo/app/connectivity/options`,
    PUBLIC_CONNECTIVITY_OPTION: `api/prosimo/app/connectivity/gateway/options`,
    AWS_TGW_ATTACHMENTS: `api/cloud/private/attachpoint/details`,
    CLOUD_COUNT: `api/config/count/cloud`,
    SET_CONNECTIVITY: `api/prosimo/app/edge-type`,
    GET_CONNECTIVITY: `api/prosimo/app/edge-type`,
    CLOUD_BULK_ONBOARD: `api/cloud/creds/bulk`,
    GET_CONNECTIVITY_CONFIG: `api/edge/connectivity/config`,
  },
  IDP_MANAGEMENT: {
    GET_IDP: `api/idp`,
    GET_SAML_METADATA_PATH: (id) => `api/idp/${id}/saml/metadata`,
    POST_IDP: `api/idp`,
    GOOGLE_IDP: `api/idp/creds/file`,
    DELETE_IDP: (id) => `api/idp/${id}`,
    UPDATE_IDP: `api/idp`,
    IMPORT_STATUS: `api/idp/import/progress`,
    IMPORT_PARAMETERS: `api/idp/import/parameters`,
  },
  CERTIFICATES_MANAGEMENT: {
    GET_CERTIFICATES: `api/cert`,
    GET_CERTIFICATE: (id) => `api/cert/${id}`,
    POST_CERTIFICATE: `api/cert`,
    DELETE_CERTIFICATE: (id) => `api/cert/${id}`,
    UPDATE_DOMAIN_CERTIFICATE: (id) => `api/cert/domain/${id}`,
    UPDATE_CA_CERTIFICATE: (id) => `api/cert/ca/${id}`,
    UPDATE_CLIENT_CERTIFICATE: (id) => `api/cert/client/${id}`,
    DOWNLOAD_CERTIFICATE: (id) => `api/cert/download/${id}`,
  },
  ALERT: {
    SEARCH: `api/mgmt/alert/search`,
    DEACTIVATE: (id) => `api/mgmt/alert/${id}/deactivate`,
    ACTIVATE: (id) => `api/mgmt/alert/${id}/activate`,
    UPDATE: (id) => `api/mgmt/alert/${id}/notifications`,
    NOTIFICATION_CHANNEL: {
      DEFAULT: `api/mgmt/alert/channel`,
      SUPPORTED_CHANNELS: `api/mgmt/alert/channel/support`,
      TEST_CONFIG: (channelType) =>
        `api/mgmt/alert/channel/${channelType}/test`,
    },
    COUNT: `api/config/count/alert`,
  },
  USERS_MANAGEMENT: {
    DEFAULT_PATH: `api/user`,
    UPDATE: (id) => `api/user/${id}`,
    REVOKE: `api/account/revoke`,
    BULK_DELETE: `api/user/bulk/delete`,
    BULK_REVOKE: `api/account/bulk/revoke`,
    SIGN_OUT: (id) => `api/user/${id}/sessions`,
    BULK_SIGN_OUT: 'api/user/bulk/sessions',
  },
  USERGROUPS_MANAGEMENT: {
    DEFAULT_PATH: `api/usergroup`,
    IMPORT_IDP: `api/groupings/usergroup/import`,
    GROUP_NAMES: `api/groupings/usergroup/names`,
    IMPORT_STATUS: `api/feature/task`,
    UPDATE: (id) => `api/usergroup/${id}`,
  },
  MSP_ACCESS_MANAGEMENT: {
    DEFAULT_PATH: `api/mspmgmt`,
    UPDATE_STATUS: (id) => `api/mspmgmt/${id}/status`,
  },
  SSO_ACCESS_MANAGEMENT: {
    DEFAULT_PATH: `api/user/auth/idp`,
    UPDATE_PATH: (id) => `api/user/auth/idp/${id}`,
  },
  LOG_RECEIVER_MANAGEMENT: `api/logexporter`,
  API_TOKEN: {
    DEFAULT_PATH: `api/token`,
    DEACTIVATE: (id) => `api/token/${id}/deactivate`,
    ACTIVATE: (id) => `api/token/${id}/activate`,
    REGENERATE: (id) => `api/token/${id}/regenerate`,
    SETTINGS: `api/token/settings`,
  },
  ERROR_PAGE_MANAGEMENT: {
    GET_UPDATED_DATA: `api/errorpage`,
    GET_DEFAULT_DATA: `api/errorpage/default`,
  },
  EDGE_MANAGEMENT: {
    EDGES: {
      DEFAULT_PATH: `api/prosimo/app`,
      RECEIVE: `api/prosimo/app/deployment`,
      LAUNCH: (id) => `api/prosimo/app/deployment/${id}`,
      RECOMMENDATION: `api/prosimo/app/recomm`,
      SUMMARY: `api/prosimo/app/summary`,
      VM_DETAILS: `api/prosimo/app/vm`,
      DELETE_VM: (id) => `api/prosimo/app/${id}/vm`,
      GENERATE_TOKEN: (id) => `api/prosimo/app/${id}/token`,
      DOWNLOAD: (fileType) => `api/prosimo/app/${fileType}/download`,
      PEERING_OPTIONS: (id) => `api/prosimo/app/connectivity/${id}`,
      CONNECTIVITY_OPTION: (id) => `api/prosimo/app/connectivity/options/${id}`,
      DELETE_CONNECTIVITY_OPTION: (id) =>
        `api/prosimo/app/connectivity/options/remove/${id}`,
      NODE_SCALING_OPTIONS: `api/prosimo/app/node-size/settings/definition`,
      EDGE_EDIT: (id) => `api/prosimo/app/${id}`,
      UPDATE_EDGE_IP_RANGE: (id) => `api/subnet/cluster/${id}`,
      VALIDATE_EDGE: `api/prosimo/app/validation`,
      DECOMMISSION_EGRESS_NAT: (id) => `api/prosimo/app/${id}/egress-nat-svc`,
    },
    EDGES_CONNECTOR_GROUP: {
      DEFAULT_PATH: `api/edge/connector-group`,
      BULK_DELETE: `api/edge/connector-group/delete`,
      DOWNLOAD: (id) => `api/edge/connector-group/${id}/download`,
    },
    CONNECTIVITY_OPTION: `api/prosimo/app/connectivity/options`,
  },
  DOWNLOADS_MANAGEMENT: {
    DEFAULT: `api/downloads/file/info`,
    DOWNLOADS: (filename) => `api/terraform/file/download/${filename}`,
  },
  CONNECTOR_GROUP: {
    UPDATE: (id) => `api/edge/connector-group/${id}`,
    DOWNLOAD: (id) => `api/edge/connector-group/${id}/download`,
    PUBLIC: {
      CREATE: `api/edge/connector-group/public`,
      SEARCH: `api/edge/connector-group/public/search`,
      BULK_DELETE: `api/edge/connector-group/delete/public`,
      NETWORK_DETAIL: (id) => `api/edge/connector-group/${id}/subnets`,
    },
    PRIVATE: {
      CREATE: `api/edge/connector-group`,
      CREATE_CONNECTORS: `api/edge/connector-group/connectors`,
      CREATE_CONNECTORS_GROUP: `api/edge/connector-group`,
      CREATE_CONNECTORS_DOWNLOAD: `api/edge/connector-group/connectors`,
      SEARCH: `api/edge/connector-group/private/search`,
      BULK_DELETE: `api/edge/connector-group/delete`,
      DELETE: (cgId: string) =>
        `api/edge/connector-group/private/${cgId}/connector-settings`,
      EDGE_CIDR: (privateCloudID: string) =>
        `api/cloud/private/${privateCloudID}/edge/subnets`,
    },
    COUNT: `api/config/count/connector-group`,
  },
  CONNECTOR: {
    CONNECTOR_OPTIONS: `api/edge/connector/settings/definition`,
    WORKLOAD_VPC_SCALING: (id: string) =>
      `api/edge/connector-group/connector/deployment/${id}`,
    VALIDATE_REQUIREMENT: (id: string) =>
      `api/edge/connector-group/public/validation/${id}`,
    INFRA_VPC_SCALING: (id: string) =>
      `api/prosimo/app/connector/deployment/${id}`,
  },
  GROUPINGS: {
    GET: (groupingsType) => `api/groupings/type/${groupingsType}`,
    DEFAULT: `api/groupings`,
    IDP_USERS: `api/idp/users`,
    COUNTRIES: `api/groupings/file/geo/country`,
    SEARCH_GEO: `api/geo/search`,
    TIMEZONES: `api/groupings/file/time/timezone`,
    SUMMARY: `api/groupings/summary`,
  },
  INFRA_TAGS: {
    DEFAULT: `api/cloud-resource-tag`,
  },
  DATA_FETCH: {
    FETCH: `api/datafetch/list`,
    REGIONS: `api/cloud/creds/regions`,
    ADD_MANUAL_FETCH: `api/datafetch`,
    SETTINGS: `api/datafetch/settings`,
    COUNT: `api/config/count/data-fetch`,
  },
  SUMMARY_ROUTE: {
    DEFAULT: `api/route-entry`,
    ENABLE: (id) => `api/route-entry/${id}/enable`,
    DISABLE: (id) => `api/route-entry/${id}/disable`,
    SYNC: (id) => `api/route-entry/${id}/sync`,
    SEARCH: `api/route-entry/search`,
    REGIONS: `api/route-entry/region`,
    IMPACTED_NETWORK: (id) => `api/route-entry/${id}/impacted-network`,
    NETWORK: {
      DEFAULT: `api/route-entry/network`,
      ENABLE: (id) => `api/route-entry/network/${id}/enable`,
      DISABLE: (id) => `api/route-entry/network/${id}/disable`,
      SYNC: (id) => `api/route-entry/network/${id}/sync`,
      SEARCH: `api/route-entry/network/search`,
    },
    COUNT: `api/config/count/summary-routes`,
    SETTINGS: `api/route-entry/network/settings`,
  },
  FIREWALL_CONTROL_CENTER: {
    DEFAULT: `api/route-entry`,
    SEARCH: `api/route-entry/search`,
    COUNT: `api/fcc/count`,
    KEY_PAIR_BY_CLOUD_ID: (cloudAccID, region) =>
      `api/fcc/firewall/${cloudAccID}/keypair?region=${region}`,
    INSTANCE_SIZE: (firewallType) =>
      `api/fcc/firewall/vmspec?firewallType=${firewallType}`,
    INSTANCE_VERSION: (firewallType) =>
      `api/fcc/firewall/version?firewallType=${firewallType}`,
    TEMPLATE_NAME: (firewallType) =>
      `api/fcc/firewall/tstacks?firewallType=${firewallType}`,
    DEVICE_GROUP: (firewallType) =>
      `api/fcc/firewall/dgroups?firewallType=${firewallType}`,
    DOWNLOAD_KEY_PAIR: (firewallID) => `api/fcc/firewall/${firewallID}/pvtkey`,
    VALIDATE_API_KEY: `api/fcc/manager/validate`,
    FIREWALL_MANAGER: {
      CREATE: `api/fcc/manager`,
      SEARCH: `api/fcc/manager/search`,
    },
    MANAGED_FIREWALL: {
      CREATE: `api/fcc/firewall`,
      SEARCH: `api/fcc/firewall/search`,
      DEPLOY: (id) => `api/fcc/firewall/${id}/deploy`,
      DECOMMISSION: (id) => `api/fcc/firewall/${id}/decommission`,
      DEVICE_GROUPS: (firewallType) =>
        `api/fcc/firewall/dgroups?firewallType=${firewallType}`,
      TEMPLATE_NAMES: (firewallType) =>
        `api/fcc/firewall/tstacks?firewallType=${firewallType}`,
    },
  },
  AGENT: {
    DASHBOARD: `api/agent/dashboard`,
    DEVICES: `api/agent/devices`,
    DEVICES_SEARCH: `api/agent/devices/search`,
    SETTINGS: `api/agent/settings`,
    DOWNLOAD: `api/agent/download`,
    DEVICE_POSTURE_RISK_DISTRIBUTION: `api/agent/device-posture`,
  },
  GUIDE: {
    DEPLOY: `api/prosimo/app/guide`,
    DEPLOY_STATUS: `api/feature/task`,
    CUSTOM_GUIDE: `api/guide/edge`,
    UI_PATH_GUIDE: `dashboard/guide`,
    ONBOARDED_APP: `api/prosimo/app/guide/onboardedapp`,
    CUSTOM_GUIDE_LATENCY: `api/prosimo/app/customrecomm`,
  },
  INSIGHTS: {
    GET_APP_STAT: 'api/dashboard/insights/appusage',
    GET_APP_EXPERIENCE_STAT: 'api/dashboard/insights/experience',
    GET_EVENT_LOGS: 'api/dashboard/insights/eventlog',
    GET_ACCESS_LOGS: 'api/dashboard/insights/accesslog',
    GET_SUMMARY_LOGS: 'api/dashboard/insights/summary/log',
    GET_USER_RISK_LOG: 'api/dashboard/insights/risk/log',
    GET_USER_EXPERIENCE_LOG: 'api/dashboard/insights/experience/log',
    GET_HITS_STAT: 'api/dashboard/insights/hits',
    GET_NETWORK_ACCESS_STAT: 'api/dashboard/insights/networkaccess', // remove . country_stats under summary
    GET_EVENTS_SEVERITY_STAT: 'api/dashboard/insights/events',
    GET_EVENTS_TYPE_STAT: 'api/dashboard/insights/events',
    GET_EVENTS_AGGREGATE_STAT: 'api/dashboard/insights/events',
    USER_RISK_SCORE_STAT: 'api/dashboard/insights/risk',
    USER_EXPERIENCE_STAT: 'api/dashboard/insights/experience',
    PERFORMANCE_STAT: 'api/dashboard/insights/perf',
    WAF_STAT: 'api/dashboard/insights/waf',
    SUMMARY_STAT: 'api/dashboard/insights/summary',
    UI_PATH_SUMMARY: (type) => `dashboard/insights/${type}/summary`,
    UI_PATH_SESSION: (type) => `dashboard/insights/${type}/sessions`,
    UI_PATH_PERFORMANCE: (type) => `dashboard/insights/${type}/performance`,
    UI_PATH_APPS_ACCESSED: (type) => `dashboard/insights/${type}/apps-accessed`,
    UI_PATH_NETWORKS_ACCESSED: (type) =>
      `dashboard/insights/${type}/networks-accessed`,
    UI_PATH_APP_EXPERIENCE: (type) =>
      `dashboard/insights/${type}/app-experience`,
    UI_PATH_USER_RISK: (type) => `dashboard/insights/${type}/user-risk`,
    UI_PATH_HEATMAP: (type) => `dashboard/insights/${type}/heatmap`,
    UI_PATH_EVENTS: (type) => `dashboard/insights/${type}/events`,
    UI_PATH_TROUBLESHOOT: (type) => `dashboard/insights/${type}/logs`,
    UI_PATH_EVENTS_SEVERITY: '/severity',
    UI_PATH_EVENTS_TYPE: '/type',
    UI_PATH_EVENTS_AGGREGATE: '/aggregate',
    UI_PATH_WAF: (type) => `dashboard/insights/${type}/waf`,
    UI_PATH_SUMMARY_HEADERS: 'api/dashboard/insights/summary/header',
  },
  DIAGNOSE: {
    GET_TROUBLESHOOT_LOG: 'api/dashboard/diagnostics/troubleshoot',
    GET_ACCESSIBILITY_LOG: 'api/dashboard/diagnostics/accessibility/health',
    GET_CLOUD_GEO: 'api/dashboard/diagnostics/map/cloud',
    GET_COUNTRIES_GEO: 'api/dashboard/diagnostics/map/geo',
    GET_EDGE_GENERAL_INFO: `api/dashboard/diagnostics/edge/infra`,
    GET_CALCULATOR_INFO: `api/dashboard/diagnostics/calculator`,
    RUN_DEBUG_TEST: 'api/diagnose/debugging',
    GET_TEST_STATUS: (id) => `api/diagnose/debugging/${id}`,
    GET_APP_DOMAINS: 'api/app/edge',
    UI_PATH_HEALTH_STATUS: 'dashboard/diagnose/health-status',
    UI_PATH_INFRASTRUCTURE: 'dashboard/diagnose/infrastructure',
    UI_PATH_TROUBLESHOOTING: 'dashboard/diagnose/troubleshooting',
    UI_PATH_DIAGNOSTICS: 'dashboard/diagnose/diagnostics',
    UI_PATH_TO_DIAGNOSTICS: (encodedObj) =>
      `dashboard/diagnose/diagnostics/${encodedObj}`,
    UI_PATH_CLOUD_TRACER: 'v2/diagnose/cloud-tracer',
    UI_PATH_TO_CLOUD_TRACER: (encodedObj) =>
      `v2/diagnose/cloud-tracer/${encodedObj}`,
    UI_PATH_TO_CLOUD_TRACER_RUN_ID: (view, id) =>
      `v2/diagnose/cloud-tracer/${view}/run-id/${id}`,
    UI_PATH_COST_ESTIMATION: 'cost-estimation',
    UI_PATH_TO_INSIGHTS: (encodedObj) =>
      `dashboard/insights/mcn/summary/${encodedObj}`,
    UI_PATH_TO_INSIGHTS_SESSION: (encodedObj) =>
      `dashboard/insights/mcn/sessions/${encodedObj}`,
    PDF_VIEW: (domain) => `${domain}/dashboard/diagnose/diagnostics/pdf-view`,
    GET_CLOUD_TRACER: 'api/diagnose/cloudtrace',
    GET_NAMESPACES: (type) =>
      `api/namespace/subnet/network/conflicts?type=${type}`,
    GET_CLOUD_TRACER_HISTORY: (from: number, pageSize: number) =>
      `api/diagnose/cloudtrace/history?from=${from}&size=${pageSize}`,
    REFRESH_CONFIG: 'api/datafetch',
    REFRESH_CONFIG_STATUS: 'api/datafetch/list',
    REFRESH_LAST_UPDATED: 'api/datafetch/settings',
    GET_CLOUD_TRACER_BY_ID: (id) => `api/diagnose/cloudtrace/${id}`,
    GET_CLOUD_TRACER_VIEW: (id) => `api/diagnose/cloudtrace/view/${id}`,
    GET_ROUTE_TABLE_SUMMARY: 'api/diagnose/routetable/count',
    GET_EDGE_ROUTE_TABLE: 'api/diagnose/routetable/edge',
    GET_CONNECTOR_GROUP_ROUTE_TABLE: 'api/diagnose/routetable/connectorgroup',
    GET_VPC_ROUTE_TABLE: 'api/diagnose/routetable/vpc',
    GET_SUBNET_ROUTE_TABLE: 'api/diagnose/routetable/subnet',
    GET_TGW_ROUTE_TABLE: 'api/diagnose/routetable/tgw',
    GET_VWAN_HUB_ROUTE_TABLE: 'api/diagnose/routetable/vhub',
    GET_ROUTE_TABLE_DETAILS_BY_ID: (type) =>
      `api/diagnose/routetable/${type}/routes`,
    GET_SERVICES: 'api/diagnose/services',
    GET_INSTANCES: 'api/diagnose/instances',
    GET_COMMANDS: 'api/diagnose/commands',
    GET_VPC: 'api/network/onboard/search/vpc',
    GET_CONNECTOR_INSTANCES: 'api/network/onboard/search/connectors',
    GET_CONTAINERS: 'api/diagnose/container',
  },
  CLIENT_APPLICATION_GROUP: {
    UPDATE_CLOUD: (id) => `api/capp/${id}/cloud`,
    GET_DETAIL_INFO: (id) => `api/capp/${id}`,
    GET_CLOUD_REGION_VPC: `api/cloud/vpc/discovery`,
    ROUTE_TABLE: `api/cloud/network/route-table/discovery`,
    GET_CLOUD_REGION_VPC_UNMANAGED: `api/cloud/vpc/unmanaged`,
    GET_VPC_ENDPOINTS: `api/cloud/endpoint/discovery`,
    GET_VPC_SUBNETS: `api/cloud/subnet/discovery`,
    GET_REGION_TRANSIT_HUBS: `api/cloud/transit-hub/discovery`,
    GET_VPC_TRANSIT_HUBS: `api/cloud/attached-transit-hub/discovery`,
    DEPLOY_APP_GROUP: `api/capp/deployment`,
    CONNECTOR_DETAILS: (id) => `api/capp/${id}/connector/details`,
    GET_CLOUD_REGION_EDGE_VPC: 'api/cloud/edge-vpc/discovery',
    GET_REGION_VPC: `api/network/transit/setup/cloud-network/search`,
  },

  MAIN_DASHBOARD: {
    GET_APP_SUMMARY: `api/app/onboard/summary/count`,
    GET_DYNAMIC_DATA: `api/dashboard/main/all`,
    GET_CSP_LAT_LON: `api/dashboard/diagnostics/map/cloud`,
  },
  TASKS: {
    AUDIT: `api/audit`,
    TASK_DETAILS: (id) => `api/task/${id}`,
    TASK_SEARCH: `api/task/search`,
    META_DATA: `api/task/data/meta`,
    AUDIT_BY_ID: (id) => `api/audit/${id}`,
    UI_PATH_AUDIT: `v2/activities`,
  },
  ALERTS: {
    SEARCH: `api/alert/search`,
    META_DATA: `api/alert/metadata`,
    ACK_ALERT: `api/alert/ack/bulk`,
  },
  USER_PROFILE: {
    MAIN: '/v2/user-profile',
    GET_USER_PROFILE_INFO: `api/user/profile`,
    UPDATE_USER_PASSWORD: `api/user/profile/password`,
    UPDATE_USER_PROFILE: `api/user/profile/info`,
    LOGOUT_USER: `api/account/logout`,
    UI_PATH_PROFILE: `dashboard/user-profile/profile`,
    UI_PATH_PASSWORD: `dashboard/user-profile/password`,
  },
  HELP: {
    GET_ARTICLE_BY_ID: (locale, articleID) =>
      `api/support/help/${locale}/articles/${articleID}`,
    GET_ARTICLES_BY_SEARCH_TERM: `api/support/help/articles/search`,
    POST_TICKET: `api/support/ticket`,
    PATH_TO_HELP_CENTER: 'api/support/help/product',
    HOME_PAGE: (locale) => `https://help.prosimo.io/hc/${locale}`,
    PATH_TO_ARTICLE: (locale, articleID) =>
      `https://help.prosimo.io/hc/${locale}/articles/${articleID}`,
    HELP_AND_LEARN: `api/support/help/product`,
  },
  MSP: {
    SEARCH_TENANTS: `api/msp/team/search`,
    GET_TENANTS: `api/msp/team`,
    GET_TENANTS_SUMMARY: `api/msp/team/summary`,
    ADD_TENANT: `api/msp/team`,
    DELETE_TENANT: `api/msp/team`,
    MANAGEMENT: {
      ADMINS_SUMMARY: `api/msp/admin/summary`,
      PERMISSIONS_SUMMARY: `api/msp/permission/summary`,
      ADMINS: {
        DEFAULT: `api/msp/admin`,
        UPDATE: (id) => `api/msp/admin/${id}`,
      },
      ADMIN_GROUPS: {
        DEFAULT: `api/msp/admingroup`,
        SEARCH: `api/msp/admingroup/search`,
      },
      SSO_ACCESS_MANAGEMENT: {
        DEFAULT_PATH: `api/msp/admin/auth/idp`,
        UPDATE_PATH: (id) => `api/user/auth/idp/${id}`,
      },
      MSP_ROLES: {
        DEFINITION: `api/msp/role/action/definition`,
        DEFAULT: `api/msp/role`,
      },
      TENANTS_ROLES: {
        DEFINITION: `api/msp/teamrole/action/definition`,
        DEFAULT: `api/msp/teamrole`,
      },
      MSP_API_TOKEN: {
        DEFAULT_PATH: `api/msp/token`,
        DEACTIVATE: (id) => `api/msp/token/${id}/deactivate`,
        ACTIVATE: (id) => `api/msp/token/${id}/activate`,
        REGENERATE: (id) => `api/msp/token/${id}/regenerate`,
        SETTINGS: `api/msp/token/settings`,
      },
      UI_PATH_ADMINS: `msp/management/admins`,
      UI_PATH_PERMISSIONS: `msp/management/permissions`,
      UI_PATH_API_TOKENS: `msp/management/api-tokens`,
    },
  },
  DISCOVERY: {
    GET_ALL_DISCOVERY_FILTERS: `api/log-discovery`,
    SAVE_DISCOVERY_CREDENTIALS: `api/log-discovery`,
    GET_DISCOVERY_REPORT_DETAILS_BY_ID: (id) => `api/log-discovery/${id}`,
    DELETE_DISCOVERY_REPORT_DETAILS_BY_ID: (id) => `api/log-discovery/${id}`,
    REPORTS: `api/log-discovery/plot`,
  },
  UI_PATHS: {
    SIGNIN: {
      ROOT: '/signin',
      FORGOT_TEAM: '/signin/forgot/teamname',
    },
    LOGIN: '/login',
    MAIN_DASHBOARD: '/dashboard/main',
    DISCOVERY: `/discovery`,
    SP_LOGIN: `/spl`,
  },
  GET_LOG_META_DATA: 'api/dashboard/insights/meta',
  GET_APPS: 'api/app/onboard/discover',
  IMAGES_BUCKET_NAME: 'prosimo-images',
  LOGO_BUCKET_NAME: 'logo',
  GOOGLE_STORAGE_URL: 'https://storage.googleapis.com',
  CLOUD_TRANSIT: {
    TOPOLGY_TRANSIT: `api/dashboard/topology/transit`,
    UI_PATH: 'v2/transit-360/infra',
    UI_APP_VIEW: 'dashboard/cloud-transit/app',
    UI_ZTNA_VIEW: 'dashboard/cloud-transit/ztna',
    UI_MCN_VIEW: 'dashboard/cloud-transit/mcn',
    UI_TARGET_VIEW: 'dashboard/cloud-transit/target',
  },
  VISUAL_ONBOARDING: {
    UI_BASE_PATH: '/v2/onboard/visual-onboard',
    UI_INFRASTRUCTURE_PATH: '/v2/onboard/visual-onboard/infrastructure',
    UI_REGION_CONFIG_PATH: '/v2/onboard/visual-onboard/connections',
    UI_DEPLOYMENTS_PATH: '/v2/onboard/visual-onboard/summary',
    DEPLOYMENT: {
      BASE_PATH: 'api/network/transit/setup',
      SEARCH: 'api/network/transit/setup/search',
      BULK_DELETE: 'api/network/transit/setup/bulk-delete',
      STATUS: 'api/network/transit/setup/status',
      SUMMARY: 'api/network/transit/setup/summary',
      DEPLOY: 'api/network/transit/deployment',
    },
    EDGE_STATUS: 'api/network/transit/setup/edge',
    VWAN: 'api/network/transit/setup/vwan/search',
    ISSUE_FIXED: (id: string) => `api/network/transit/setup/issue-fixed/${id}`,
    PDF_VIEW: (domain) => `${domain}/v2/onboard/visual-onboard/summary/pdf`,
  },
  GENERATE_REPORT: `api/report`,
  REPORT_STATUS: (id) => `api/report/${id}/status`,
  DOWNLOAD_REPORT: (id) => `api/report/download/${id}`,
  EDR_INTEGRATION: {
    GET_EDR: `api/edr`,
    CREATE_EDR: `api/edr`,
    UPDATE_EDR: (id) => `api/edr/${id}`,
    DELETE_EDR: (id) => `api/edr/${id}`,
    GET_VENDORS: `api/edr/supported`,
  },
  DISCOVERED_APPS: {
    NETWORK_DISCOVERY: `api/network/discovery`,
    REGIONS_BY_CSP_ID: (csp_id) =>
      `api/network/discovery/cloud/${csp_id}/regions`,
    VPCS_BY_REGION_ID: (csp_id, region_id) =>
      `api/network/discovery/cloud/${csp_id}/regions/${region_id}/vpc`,
    SUBNETS_BY_VPC_ID: (csp_id, region_id, vpc_id) =>
      `api/network/discovery/cloud/${csp_id}/regions/${region_id}/vpc/${vpc_id}/subnets`,
    ENDPOINTS_BY_SUBNET_ID: (csp_id, region_id, vpc_id, subnet_id) =>
      `api/network/discovery/cloud/${csp_id}/regions/${region_id}/vpc/${vpc_id}/subnets/${subnet_id}/endpoints`,
    REFRESH_DISCOVERY: `api/network/discovery/refresh`,
    REFRESH_DISCOVERY_STATUS: `api/network/discovery/pending`,
  },
  PRIVATE_LINK_SOURCE_ONBOARD: {
    DISCOVER_REGION: `api/private-link-source/discovery/regions`,
    DISCOVER_NETWORK: `api/private-link-source/discovery/networks`,
    DISCOVER_SUBNET: `api/private-link-source/discovery/subnets`,
    CREATE: `api/private-link-source`,
    PATH: (id) => `api/private-link-source/${id}`,
    ADVANCED_SETTING: {
      DEFAULT: `api/private-link-source/configure`,
    },
    DISCOVER_VPC_TREE: 'api/private-link-source/discovery/vpc',
  },
  PRIVATE_LINK_SOURCE_SEARCH: `api/private-link-source/search`,
  PRIVATE_LINK_SOURCE_DELETE: (id) => `api/private-link-source/${id}`,
  NETWORK_ONBOARD: {
    CREATE: `api/network/onboard`,
    NETWORK: `api/network/onboard`,
    DELETE: `api/network/onboard`,
    NETWORK_STATUS_COUNT: `api/network/onboard/status/count`,
    OFFBOARD: `api/network/deployment`,
    CONNECTOR_CONFIG: `api/network/onboard/connector/settings/search`,
    VPC_IMPACT: (placement: string, entityId) =>
      `api/network/onboard/impact/${placement}/${entityId}`,
    REBOARD: {
      POST: (networkId) => `api/network/deployment/${networkId}`,
    },
    CLOUD_CONFIG: {
      PUT: (networkId) => `api/network/onboard/${networkId}/cloud`,
    },
    SECURITY: {
      PUT: (networkId) => `api/network/onboard/${networkId}/policy`,
    },
    ONBOARD: {
      PUT: (networkId) => `api/network/deployment/${networkId}`,
    },
    UPDATE_NAMESPACES: 'api/namespace/team/advanced/settings',
    GET_NAMESPACES_CONFIG: 'api/namespace/team/advanced/settings',
    ADD_NAMESPACE: 'api/namespace',
    GET_NAMESPACES_LIST: 'api/namespace/search',
    DELETE_NAMESPACE: `api/namespace`,
    ASSIGN_NETWORK_TO_NAMESPACE: (id) => `api/namespace/assign/${id}`,
    OVERLAPPED_NAMESPACES: (id) => `api/namespace/export/details/${id}`,
    EDIT_NAMESPACE: (id) => `api/namespace/name/${id}`,
    EXPORT_NETWORK: (id) => `api/namespace/export/${id}`,
    WITHDRAW_NETWORK: (id) => `api/namespace/withdraw/${id}`,
    GET_OVERLAPPING_CIDR: `api/namespace/subnet/conflicts`,
    GET_NETWORK_CONFLICTS: (id) => `api/network/onboard/${id}/conflict`,
    GET_NETWORK_CONFLICTS_BY_PAYLOAD: `api/network/onboard/conflicts`,
    GET_VIRTUAL_SUBNETS: 'api/network/onboard/virtual/subnets',
  },
  NETWORK_SEARCH: `api/network/onboard/search`,
  TARGET_NETWORK_SEARCH: `api/network/onboard/target/search`,
  NETWORK_DELETE: `api/network/onboard`,
  NETWORK_OFFBOARD: `api/network/deployment`,
  NETWORK_STATUS_CHECK: `api/network/onboard/status/check`,
  NAMESPACE_MEMBER_OVERVIEW: (id) => `api/namespace/members/overview/${id}`,
  NAMESPACE_EXPORT_OVERVIEW: (id) => `api/namespace/export/overview/${id}`,
  NAMESPACE_IMPORT_OVERVIEW: (id) => `api/namespace/import/overview/${id}`,
  NAMESPACE_NETWORKS: (id) => `api/namespace/network/${id}`,
  NETWORK_WITH_ASSIGNED_NAMESPACE: `api/namespace/assign/networks`,
  NAMESPACE_TO_WITHDRAW: (id) => `api/namespace/network/withdraw/${id}`,
  DEFAULT_NAMESPACE: `api/namespace/name/default`,
  SVC_NAMESPACE_NETWORKS: (id) => `api/namespace/${id}/svcinst/network`,
  SVC_NAMESPACE_NETWORKS_POLICY: (id) => `api/namespace/${id}/network`,
  GET_NAMESPACE_TO_EXPORTS: (id) => `api/namespace/export/network/${id}`,
  GET_NETWORKS_BY_NAMESPACES: 'api/namespace/network/search',

  SHARED_SERVICES: {
    SEARCH: `api/shared-svc/search`,
    SHARED_SERVICE: `api/shared-svc`,
    STATUS_CHECK: `api/shared-svc/status/check`,
    STATUS_COUNT: `api/shared-svc/status/count`,
    OFFBOARD_SVC: `api/shared-svc/deployment`,
    SVC_ENDPOINT: `api/shared-svc/endpointsvc`,
    CLOUD_ZONES: `api/cloud/zones`,
    SAVE: (sharedSvcId) => `api/shared-svc/${sharedSvcId}`,
    ONBOARD: (sharedSvcId) => `api/shared-svc/deployment/${sharedSvcId}`,
    REBOARD: (sharedSvcId) => `api/shared-svc/deployment/${sharedSvcId}`,
    OFFBOARD: (sharedSvcId) => `api/shared-svc/deployment/${sharedSvcId}`,
    CLOUD_NETWORK_SUBNETS: 'api/cloud/endpoint/network-subnets',
    RESOURCE_GROUPS: `api/shared-svc/resource-group`,
    RESOURCE_GROUP_LB: (resourceGroupName) =>
      `api/shared-svc/resource-group/${resourceGroupName}/loadbalancer`,
    ROUTE_TABLE_IDS: (resourceGroupName: string) =>
      `api/shared-svc/resource-group/${resourceGroupName}/routetable`,
    LB_AND_NAMESPACES: 'api/svc-insert/network/loadbalancer',
    VNETS: (resource_group_name) =>
      `api/shared-svc/resource-group/${resource_group_name}/vnet`,
    SUBNET_VALIDATE: `api/network/onboard/subnets/validate`,
  },
  PLAYGROUND: {
    UI_PATH: {
      UI_BASE_PATH: '/dashboard/playground',
      UI_PATH_CLUSTER: `/dashboard/playground/cluster`,
      UI_PATH_GRAPH: '/dashboard/playground/graph',
      UI_PATH_RECOMMENDATIONS: '/dashboard/playground/recommendations',
    },
    API: {
      BASE_PATH: 'api/dashboard/playground',
      EXTERNAL_REPORTS: `api/eng-labs/dashboard`,
    },
  },
  COST_DASHBOARD: {
    HOME: '/cost',
    SEARCH_FILTER_VALUES: 'api/dashboard/cost/filter/values',
    OVERVIEW: 'api/dashboard/cost/overview',
    CHARGEBACK: 'api/dashboard/cost/chargeback',
    DATA_TRANSFER: 'api/dashboard/cost/datatransfer',
    CHARGEBACK_SERVICE: 'api/dashboard/cost/chargeback/services',
    CHARGEBACK_DATA_TRANSFER: 'api/dashboard/cost/chargeback/datatransfer',
    CHARGEBACK_TRENDLINE: 'api/dashboard/cost/chargeback/trend',
    SHARED_SERVICE: 'api/dashboard/cost/services',
  },
  ALLOWED_ENV_FOR_USER_BEHAVIOR_ANALYTICS: ['PROD'],
  V2: {
    ONBOARD: {
      UI_BASE_PATH: 'v2/onboard',
      APP: {
        UI_PATH: {
          UI_BASE_PATH: 'v2/onboard/app',
          UI_ONBOARDED_PATH: 'v2/onboard/app/onboarded',
          UI_DISCOVERED_PATH: 'v2/onboard/app/discovered',
        },
        BASE_PATH: 'api/app/onboard',
        CREATE: (action: string) => `api/app/deployment?action=${action}`,
        COUNT: 'api/app/onboard/count',
      },
      NETWORKS: {
        UI_PATH: {
          UI_BASE_PATH: 'v2/onboard/networks',
          UI_ONBOARDED_PATH: 'v2/onboard/networks/onboarded',
          UI_DISCOVERED_PATH: 'v2/onboard/networks/discovered',
        },
        CREATE: (action: string) =>
          `api/network/onboard/deploy?action=${action}`,
        SUMMARY_ROUTE_MATCH: `api/network/onboard/routing/subnets`,
        SEARCH_FILTER_VALUES: `api/network/onboard/search/values`,
        SPECIFIC_SEARCH: `api/network/onboard/specific/search`,
      },
      PRIVATE_LINK_SOURCES: {
        UI_PATH: {
          UI_BASE_PATH: 'v2/onboard/private-link-sources',
        },
      },
      SHARED_SERVICES: {
        UI_PATH: {
          UI_BASE_PATH: 'v2/onboard/shared-services',
        },
      },
      TRANSIT: {
        UI_PATH: {
          UI_BASE_PATH: 'v2/onboard/transit',
          UI_INFRA_PATH: 'v2/onboard/transit/infra',
          UI_TARGET_PATH: 'v2/onboard/transit/target',
        },
      },
    },
  },
};
