import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { CheckboxGroupItemModel } from './checkbox-group.model';

@Component({
  selector: 'app-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss'],
})
export class CheckboxGroupComponent implements OnInit, OnChanges {
  @Input('groupConfig') set checkboxGroupConfig(
    config: Array<CheckboxGroupItemModel>
  ) {
    this.checkboxesConfig = config;
    this.selectionSet = new Set();
    config.forEach((item) => {
      if (item.selected) {
        this.selectionSet.add(item.value);
      }
    });
  }
  @Output() changeSelection = new EventEmitter();
  checkboxesConfig: Array<CheckboxGroupItemModel>;
  selectionSet: Set<string>;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {}

  toggleSelection(config: CheckboxGroupItemModel, pos: number) {
    const { value } = config;
    if (this.selectionSet.has(value)) {
      this.selectionSet.delete(value);
      config.selected = false;
    } else {
      this.selectionSet.add(value);
      config.selected = true;
    }
    this.changeSelection.emit(Array.from(this.selectionSet));
  }
}
