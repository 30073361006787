import { NotificationService } from '@app/component-common/notification/notification.service';
import { AppUtil } from '@app/common/util/app-util';
import { BannerType, BannerModel, TRIAL_ACCOUNT_MSG } from './banner.model';
import { ReplaySubject } from 'rxjs';
import { BannerService } from './banner.service';
import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import {
  DIALOG_ACTION_TYPES,
  DIALOG_BUTTON_TYPES,
} from '@app/common/util/constants';
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerComponent implements OnInit, OnDestroy {
  BANNER_TYPE = BannerType;

  configs$ = new ReplaySubject<Array<BannerModel>>();
  hideBannerSet = new Set();
  hideBanners = false;
  subscriptions = [];
  constructor(
    private bannerService: BannerService,
    private cdr: ChangeDetectorRef,
    private appUtil: AppUtil,
    private notificationService: NotificationService
  ) {
    const configSub = this.bannerService
      .getBannerConfig()
      .subscribe((config) => {
        this.configs$.next(config);
      });

    const displaySub = this.bannerService
      .getBannerDisplayStatus()
      .subscribe((status: boolean) => {
        this.hideBanners = status;
        this.cdr.markForCheck();
      });

    const confirmSub = this.notificationService
      .getSelectedBannerAction()
      .subscribe(
        // listen action from confirm dialog
        (action) => this.onConfirm(action)
      );

    const cancelSub = this.notificationService.getSelectedAction().subscribe(
      // listen action from error dialog
      (action) => this.onConfirm(action)
    );

    this.subscriptions.push(configSub, displaySub, confirmSub, cancelSub);
  }

  ngOnInit() {}

  onClose(event: any, config: BannerModel) {
    //this.hideBannerSet.add(config.type);
    this.bannerService.removeBannerConfig(config);
  }

  onClickOnboardProsimo() {
    this.displayConfirmMessage(TRIAL_ACCOUNT_MSG.CONFIRM);
  }

  displayConfirmMessage(msg: string) {
    this.appUtil.setConfirmMessage(msg, [
      {
        name: DIALOG_BUTTON_TYPES.CANCEL,
        state: 'inActive',
        isBanner: true,
      },
      {
        name: DIALOG_BUTTON_TYPES.CONFIRM,
        state: 'Active',
        isBanner: true,
      },
    ]);
  }

  displayLoadingMessage() {
    this.appUtil.setNotificationMessage(
      TRIAL_ACCOUNT_MSG.LOADING,
      DIALOG_ACTION_TYPES.CONFIRM
    );
    this.appUtil.setNotificationLoaderStatus(true);
    this.cdr.markForCheck();
  }

  onConfirm(selectedBtn: any) {
    const { name, isBanner } = selectedBtn;
    if (name === DIALOG_BUTTON_TYPES.CONFIRM && isBanner) {
      this.displayLoadingMessage();
      const sub = this.bannerService.changeTrialAccountToNormal().subscribe(
        (data) => {
          // if success, route user back to login page
          this.appUtil.hideMessageDialog();
          this.appUtil.logout();
        },
        (error) => {
          console.warn('Failed to create the normal account', error);
          const { message = 'Failed to create dashboard account' } =
            error.error || {};
          this.appUtil.setNotificationMessage(
            message,
            DIALOG_ACTION_TYPES.ERROR
          );
        }
      );
      this.subscriptions.push(sub);
    } else {
      this.appUtil.hideMessageDialog();
    }
  }

  routeTo(path: string) {
    if (path) {
      this.appUtil.routeTo(path);
    }
  }

  ngOnDestroy(): void {
    this.appUtil.hideMessageDialog();
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }
}
