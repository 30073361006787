import { QueryEntity } from '@datorama/akita';
import { MCNRecentSearch } from './mcn-recent-search.model';
import {
  MCNRecentSearchState,
  MCNRecentSearchStore,
  mcnRecentSearchStore,
} from './mcn-recent-search.store';

export class MCNRecentSearchQuery extends QueryEntity<
  MCNRecentSearchState,
  MCNRecentSearch
> {
  constructor(protected store: MCNRecentSearchStore) {
    super(store);
  }
}

export const mcnRecentSearchQuery = new MCNRecentSearchQuery(mcnRecentSearchStore);
