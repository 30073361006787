import { Store, StoreConfig } from '@datorama/akita';
import { TimeRange } from '../../shared/calendar.component';

export interface LogSearchFilterState {
  logSearchTimeRange?: TimeRange;
}

export function createInitialState(): LogSearchFilterState {
  return {
    logSearchTimeRange: null,
  };
}

@StoreConfig({ name: 'log-search-filter', resettable: true })
export class LogSearchFilterStore extends Store<LogSearchFilterState> {
  constructor() {
    super(createInitialState());
  }
}

export const logSearchFilterStore = new LogSearchFilterStore();
