import {
  INSIGHTS_MENU_OPTIONS,
  SUBSCRIPTION_TYPES,
} from '@app/common/util/constants';
import { Resource } from '@app/common/http/util/resource';
import { ResponseModel } from '@app/common/util/models/response.model';
import { TimeRange } from '../shared/calendar.component';
import { Page } from '@app/common/components/paginator/paginator.model';
import { DashboardMenuConfig } from '@app/common/components/dashboard-menu/dashboard-menu.config';
import { CONSTANTS } from 'environments/environment';
import {
  OnboardedApp,
  AppGroup,
} from '../shared/filter-list/app-list/apps-list.model';
import {
  TeamUser,
  UserGroup,
} from '../shared/filter-list/client-list/client-list.model';
import { SunburstChartNode } from '@prosimoio/components';
import { TRAFFIC_ENDPOINT_TYPE } from './insights.constants';

export interface Sort {
  field: string;
  asc: boolean;
}

export interface InsightsStatPayload {
  metric?: string;
  usernames?: Array<string>;
  userGroups?: Array<string>;
  apps?: Array<string>;
  appGroups?: Array<string>;
  timeRange: TimeRange;
  filter?: string;
  page?: Page;
  sort?: Sort;
  logID?: Array<string>;
  status?: Array<string>;
  blocked?: Array<string>;
  ranges?: Array<string>;
  columnFilter?: Array<any>;
  exactFilters?: Array<any>;
  tags?: Array<any>;
  sourceNetworks?: Array<string>;
  sourcePrivateLinks?: Array<string>;
  sourceRegions?: Array<string>;
  targetRegions?: Array<string>;
  targetNetworks?: Array<string>;
  accessTypes?: Array<string>;
  sourceNetworkGroups?: Array<string>;
  targetNetworkGroups?: Array<string>;
}

export class InsightsModel extends Resource {
  payload?: InsightsStatPayload;
  response?: ResponseModel;
}

export const APPS_INSIGHTS_MENU_CONFIGS = [
  {
    name: 'Summary',
    path: 'summary',
    isSelected: true,
    isDisabled: false,
    code: INSIGHTS_MENU_OPTIONS.SUMMARY,
    fullPath: CONSTANTS.INSIGHTS.UI_PATH_SUMMARY(SUBSCRIPTION_TYPES.ZTNA),
    order: 1,
  } as DashboardMenuConfig,
  {
    name: 'Performance',
    path: 'performance',
    isSelected: false,
    isDisabled: false,
    code: INSIGHTS_MENU_OPTIONS.PERFORMANCE,
    fullPath: CONSTANTS.INSIGHTS.UI_PATH_PERFORMANCE(SUBSCRIPTION_TYPES.ZTNA),
    order: 2,
  } as DashboardMenuConfig,
  {
    name: 'Apps Accessed',
    path: 'apps-accessed',
    isSelected: false,
    isDisabled: false,
    code: INSIGHTS_MENU_OPTIONS.APPS_ACCESSED,
    fullPath: CONSTANTS.INSIGHTS.UI_PATH_APPS_ACCESSED(SUBSCRIPTION_TYPES.ZTNA),
    order: 3,
  } as DashboardMenuConfig,
  {
    name: 'App Experience',
    path: 'app-experience',
    isSelected: false,
    isDisabled: false,
    code: INSIGHTS_MENU_OPTIONS.APP_EXPERIENCE,
    fullPath: CONSTANTS.INSIGHTS.UI_PATH_APP_EXPERIENCE(
      SUBSCRIPTION_TYPES.ZTNA
    ),
    order: 4,
  } as DashboardMenuConfig,
  {
    name: 'User Risk',
    path: 'user-risk',
    isSelected: false,
    isDisabled: false,
    code: INSIGHTS_MENU_OPTIONS.USER_RISK,
    fullPath: CONSTANTS.INSIGHTS.UI_PATH_USER_RISK(SUBSCRIPTION_TYPES.ZTNA),
    order: 5,
  } as DashboardMenuConfig,
  {
    name: 'Heat Map',
    path: 'heatmap',
    isSelected: false,
    isDisabled: false,
    code: INSIGHTS_MENU_OPTIONS.HEATMAP,
    fullPath: CONSTANTS.INSIGHTS.UI_PATH_HEATMAP(SUBSCRIPTION_TYPES.ZTNA),
    order: 5,
  } as DashboardMenuConfig,
  {
    name: 'WAF',
    path: 'waf',
    isSelected: false,
    isDisabled: false,
    code: INSIGHTS_MENU_OPTIONS.WAF,
    fullPath: CONSTANTS.INSIGHTS.UI_PATH_WAF(SUBSCRIPTION_TYPES.ZTNA),
    order: 6,
  } as DashboardMenuConfig,
  {
    name: 'Events',
    path: 'events',
    isSelected: false,
    isDisabled: false,
    code: INSIGHTS_MENU_OPTIONS.EVENTS,
    fullPath: CONSTANTS.INSIGHTS.UI_PATH_EVENTS(SUBSCRIPTION_TYPES.ZTNA),
    order: 7,
  } as DashboardMenuConfig,
  {
    name: 'Logs',
    path: 'logs',
    isSelected: false,
    isDisabled: false,
    code: INSIGHTS_MENU_OPTIONS.LOGS,
    fullPath: CONSTANTS.INSIGHTS.UI_PATH_TROUBLESHOOT(SUBSCRIPTION_TYPES.ZTNA),
    order: 8,
  } as DashboardMenuConfig,
];

export const NETWORKS_INSIGHTS_MENU_CONFIGS = [
  {
    name: 'Summary',
    path: 'summary',
    isSelected: true,
    isDisabled: false,
    code: INSIGHTS_MENU_OPTIONS.SUMMARY,
    fullPath: CONSTANTS.INSIGHTS.UI_PATH_SUMMARY(SUBSCRIPTION_TYPES.MCN),
    order: 1,
  } as DashboardMenuConfig,
  {
    name: 'Performance',
    path: 'performance',
    isSelected: false,
    isDisabled: false,
    code: INSIGHTS_MENU_OPTIONS.PERFORMANCE,
    fullPath: CONSTANTS.INSIGHTS.UI_PATH_PERFORMANCE(SUBSCRIPTION_TYPES.MCN),
    order: 2,
  } as DashboardMenuConfig,
  {
    name: 'Sessions',
    path: 'sessions',
    isSelected: false,
    isDisabled: false,
    code: INSIGHTS_MENU_OPTIONS.SESSION,
    fullPath: CONSTANTS.INSIGHTS.UI_PATH_SESSION(SUBSCRIPTION_TYPES.MCN),
    order: 3,
  } as DashboardMenuConfig,
  {
    name: 'Events',
    path: 'events',
    isSelected: false,
    isDisabled: false,
    code: INSIGHTS_MENU_OPTIONS.EVENTS,
    fullPath: CONSTANTS.INSIGHTS.UI_PATH_EVENTS(SUBSCRIPTION_TYPES.MCN),
    order: 4,
  } as DashboardMenuConfig,
  {
    name: 'Logs',
    path: 'logs',
    isSelected: false,
    isDisabled: false,
    code: INSIGHTS_MENU_OPTIONS.LOGS,
    fullPath: CONSTANTS.INSIGHTS.UI_PATH_TROUBLESHOOT(SUBSCRIPTION_TYPES.MCN),
    order: 5,
  } as DashboardMenuConfig,
];

export interface FilterConfigs {
  apps: Array<OnboardedApp>;
  appGroups: Array<AppGroup>;
  selectedApps?: Array<OnboardedApp>;
  selectedAppGroups?: Array<AppGroup>;
  users: Array<TeamUser>;
  userGroups: Array<UserGroup>;
  selectedUsers?: Array<TeamUser>;
  selectedUserGroups?: Array<UserGroup>;
  totalUsers?: number;
  totalOnboardedApps?: number;
  totalAppGroups?: number;
  totalUserGroups?: number;
  totalNetworks?: number;
  totalNetworkGroups?: number;
}

export interface IMcnSessionHeadersInfo {
  dataRcvd?: number;
  dataSent?: number;
  uniqueSourceCount?: number;
  uniqueSourceIPCount?: number;
  uniqueSourceRegionCount?: number;
  uniqueTargetCount?: number;
  uniqueTargetIPCount?: number;
  uniqueTargetRegionCount?: number;
  totalRecords?: number | string;
  uniqueSessionCount?: number;
  totalDataSent?: number | string;
  totalDataRcvd?: number | string;
}
export const DEFAULT_MCN_SESSION_HEADERS_INFO: IMcnSessionHeadersInfo = {
  dataRcvd: 0,
  dataSent: 0,
  uniqueSourceCount: 0,
  uniqueSourceIPCount: 0,
  uniqueSourceRegionCount: 0,
  uniqueTargetCount: 0,
  uniqueTargetIPCount: 0,
  uniqueTargetRegionCount: 0,
  totalRecords: 0,
  totalDataSent: 0,
  totalDataRcvd: 0,
};

export const PROTOCOLS_LIST = [
  { label: 'HTTP', value: 'http' },
  { label: 'HTTPS', value: 'https' },
  { label: 'SSH', value: 'ssh' },
  { label: 'TCP', value: 'tcp' },
  { label: 'UDP', value: 'udp' },
  { label: 'DNS', value: 'dns' },
  { label: 'RDP', value: 'rdp' },
  { label: 'VNC', value: 'vnc' },
  { label: 'ICMP', value: 'icmp' },
];

export interface SunburstChartType {
  name: string;
  valueField: string;
  valueName: string;
  level1: TRAFFIC_ENDPOINT_TYPE;
  level1Name?: string;
  level2: TRAFFIC_ENDPOINT_TYPE;
  level2Name?: string;
  showType: boolean;
  tooltip: string;
}

export interface MCNSunburstChart {
  chartData: SunburstChartNode;
  tableData: SunburstChartNode;
  tooltipTemplate: (value: any) => string;
  totalData: string;
}
