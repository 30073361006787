<div id="singleSelectBox__container" *ngIf="configData">
  <ng-container *ngIf="!hideAutoComplete && configData?.options?.length > 0">
    <div *ngIf="isShowHeader" class="singleSelectBox__header">{{ title }}</div>
    <div class="row no-gutters justify-content-start">
      <div
        class="col-sm-12 col-md-6 dropDown__container"
        [ngClass]="{ 'd-none': readOnly }"
      >
        <app-auto-complete
          [isSelectAllOption]="isSelectAllOption"
          [config]="configData"
          [displayCheckbox]="true"
          [displayOptionTooltip]="displayOptionTooltip"
          (changeSelection)="updateSelection($event)"
          [hideSelection]="true"
        >
        </app-auto-complete>
      </div>
    </div>
    <div class="singleSelectBox__header">
      {{ selectedList?.length || 0 }} selected
    </div>
    <div class="row no-gutters justify-content-start">
      <app-pill
        class="mx-2 my-2"
        *ngFor="let item of selectedList; index as i"
        [value]="item.name"
        [closeable]="!readOnly"
        (close)="removeSelectedItem(item, i)"
        [pillWidth]="pillWidth"
      ></app-pill>
    </div>
  </ng-container>
</div>
