import { AppUtil } from '@app/common/util/app-util';
import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { AutoComplete } from '../auto-complete/auto-complete.model';

@Component({
  selector: 'app-single-select-box',
  templateUrl: './single-select-box.component.html',
  styleUrls: ['./single-select-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleSelectBoxComponent implements OnInit, OnChanges {
  configData: AutoComplete;
  selectionMessage: string;
  pillWidth: string = '150px';
  @Input() isShowHeader = true;
  @Input() isSelectAllOption = false;
  @Input() readOnly = false;
  @Input() set config(data: AutoComplete) {
    this.configData = data;
    this.selectedList = this.configData?.selected?.slice();
  }
  @Input() hideAutoComplete = false;
  @Input() title = '';
  @Input() displayOptionTooltip = false;
  @Output() valuesChange = new EventEmitter();

  @Output() removedItem = new EventEmitter();

  selectedList: Array<any>;

  constructor(private appUtil: AppUtil) {}

  ngOnInit() {
    this.selectionMessage = this.getSelectionMessage();
  }

  ngOnChanges() {
    if (this.configData) {
      this.selectedList = this.configData?.selected?.slice();
    }
  }

  /**
   * to get selection message based on title and selected list length
   * @returns selectionMessage
   */
  getSelectionMessage(): string {
    let message = '';
    const count = this.selectedList?.length || 0;
    const title = this.title;
    if (title) {
      switch (count) {
        case 0:
          message = `No ${title} is selected`;
          break;
        case 1:
          message = `${count} ${title} is selected`;
          break;
        default:
          message = `${count} ${title}s are selected`;
      }
    } else {
      message = `${count} selected`;
    }
    return message;
  }

  updateSelection(data: Array<any>) {
    // TODO: add logic to handle user input string
    this.selectedList = data.slice();
    this.selectionMessage = this.getSelectionMessage();
    this.emitChanges();
  }

  removeSelectedItem(item: any, pos: number) {
    this.selectedList.splice(pos, 1);
    const newConfig = this.appUtil.clone(this.configData);
    newConfig.selected = this.selectedList.slice();
    this.configData = newConfig;
    this.selectionMessage = this.getSelectionMessage();
    this.removedItem.emit(item);
  }

  emitChanges() {
    this.valuesChange.emit(this.selectedList.slice());
  }
}
