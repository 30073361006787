import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceService } from '@prosimoio/services';
import { CONSTANTS } from 'environments/environment';
import { CommonModelSerializer } from '../http/service/common.model.serializer';
import { EMPTY, Observable } from 'rxjs';
import {
  SharedService,
  SharedServiceEndpointSubnets,
  SharedServiceFilter,
  SharedServiceResponse,
} from '../models';
import { expand, reduce, take } from 'rxjs/operators';
import { PAGE_SIZE_V2 } from '../http/util/constants';

@Injectable({
  providedIn: 'root',
})
export class SharedServiceService extends ResourceService<any> {
  constructor(protected httpClient: HttpClient) {
    super(
      httpClient,
      CONSTANTS.DASHBOARD_HOST,
      '',
      new CommonModelSerializer()
    );
  }

  getSharedServices(
    status: Array<string>,
    searchTerm: string = ''
  ): Observable<Array<SharedService>> {
    let pageStart = 0;
    this.path = CONSTANTS.SHARED_SERVICES.SEARCH;
    return this.create(
      this.getSharedServicePayload(status, searchTerm, pageStart)
    ).pipe(
      expand((response: SharedServiceResponse) => {
        pageStart += PAGE_SIZE_V2;
        if (pageStart < response.totalCount) {
          this.path = CONSTANTS.SHARED_SERVICES.SEARCH;
          return this.create(
            this.getSharedServicePayload(status, searchTerm, pageStart)
          );
        } else {
          return EMPTY;
        }
      }),
      reduce((acc: Array<SharedService>, val: SharedServiceResponse) => {
        if (val?.records) {
          return acc.concat(val.records);
        }
      }, [] as Array<SharedService>),
      take(1)
    );
  }

  getNetworksByPage(
    page: number,
    searchTerm: string
  ): Observable<SharedServiceResponse> {
    this.path = CONSTANTS.SHARED_SERVICES.SEARCH;
    return this.create(
      this.getSharedServicePayload([], searchTerm, page * PAGE_SIZE_V2)
    ).pipe(take(1));
  }

  getSharedServicePayload(
    status: Array<string>,
    searchTerm: string,
    pageStart: number
  ): SharedServiceFilter {
    return {
      page: {
        size: PAGE_SIZE_V2,
        start: pageStart,
      },
      value: searchTerm,
      statusFilter: status,
    } as SharedServiceFilter;
  }

  getSharedServiceEndpointSubnets(
    payload: SharedServiceEndpointSubnets
  ): Observable<SharedServiceEndpointSubnets> {
    this.path = CONSTANTS.SHARED_SERVICES.CLOUD_NETWORK_SUBNETS;
    return this.create(payload).pipe(take(1));
  }
}
