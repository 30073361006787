<div class="d-flex flex-column mx-4 my-2">
  <p class="clr-grey5 page__hint">
    <img
      class="error-icon"
      *ngIf="errorPayload?.errorStep === step"
      [src]="errorIcon"
    />
    {{ hint }}
  </p>
  <mat-accordion [formGroup]="policyForm" [multi]="true" class="bordered-panel">
    <mat-expansion-panel
      [expanded]="true"
      [hideToggle]="true"
      class="form-panel"
    >
      <mat-expansion-panel-header class="form-panel__header pe-none arrow">
        <mat-panel-title>Access Policies (Ingress)</mat-panel-title>
      </mat-expansion-panel-header>

      <mat-radio-group
        class="mb-2"
        [formControl]="policyState"
        (change)="changePolicyState()"
      >
        <mat-radio-button
          color="primary"
          [value]="PolicyControlState.NONE"
          class="ml-n1 pl-0"
        >
          No Policy (Implicit Deny)
        </mat-radio-button>
        <mat-radio-button
          color="primary"
          [value]="PolicyControlState.CUSTOM"
          class="pl-2"
        >
          Select Specific Policy
        </mat-radio-button>
      </mat-radio-group>
      <ng-container *ngIf="policyState.value === PolicyControlState.CUSTOM">
        <mat-label class="d-flex mb-2 form__label">
          Available Policies
        </mat-label>
        <div class="w-50">
          <pcomp-mat-select-search
            *ngIf="selectSearchConfigForAccessPolicies?.optionList?.length"
            [parentForm]="policyForm"
            [config]="selectSearchConfigForAccessPolicies"
            [formControlNameInput]="policiesControl"
            controlName="policiesControl"
            (emitSelections)="selectPolicy($event?.value)"
            [customizedSelectTriggerTextConfig]="
              customizedSelectTriggerTextConfig
            "
          >
          </pcomp-mat-select-search>
        </div>

        <div class="mb-3">
          <mat-error
            *ngIf="
              loading.policies.status &&
              loading.policies.type === COPY_LOADER_CONFIG_TYPES.ERROR
            "
          >
            {{ loading.policies?.message }}
          </mat-error>
          <div
            *ngIf="
              loading.policies.status &&
              loading.policies.type !== COPY_LOADER_CONFIG_TYPES.ERROR
            "
          >
            {{ loading.policies?.message }}
          </div>
        </div>

        <pcomp-minimized-list
          *ngIf="selectedPoliciesListData?.length"
          class="d-block my-3"
          [tableData]="selectedPoliciesListData"
          [columnData]="POLICY_COLUMN_DATA"
          [isTable]="true"
          [removeCancelIcon]="false"
          minVisibleCount="3"
          [colSpanValue]="1"
          [columnWidth]="columnWidth"
          (elementRemoved)="deletePolicy($event)"
        ></pcomp-minimized-list>
      </ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</div>
