export const ZTNA_ROWS = [
  {
    left: 'USER',
    leftHeader: 'Sources',
    right: 'APPS',
    rightHeader: 'Targets',
  },
  {
    left: 'USER_GROUP',
    right: 'TARGET_NETWORK',
  },
  {
    left: '',
    right: 'APP_GROUP',
  },
  {
    left: 'TIME_RANGE',
    leftHeader: 'Time Range',
  },
];

export const MCN_ROWS = [
  {
    left: 'SOURCE_NETWORK',
    leftHeader: 'Sources',
    right: 'TARGET_NETWORK',
    rightHeader: 'Targets',
  },
  {
    left: 'SOURCE_NETWORK_GROUP',
    right: 'TARGET_NETWORK_GROUP',
  },
  {
    left: 'PRIVATE_LINK',
    right: 'APPS',
  },
  {
    left: 'SOURCE_REGION',
    leftHeader: 'Regions',
    right: 'SOURCE_REGION',
    rightHeader: 'Regions',
  },
  {
    left: 'TIME_RANGE',
    leftHeader: 'Time Range',
  },
];

export const FILTERS_INFO = {
  USER: {
    selectedKey: 'selectedTeamUsers',
    totalKey: 'totalUsers',
    targetKey: 'email',
    selectedAllText: 'Users',
  },
  USER_GROUP: {
    selectedKey: 'selectedUserGroups',
    totalKey: 'totalUserGroups',
    targetKey: 'name',
    selectedAllText: 'User Groups',
  },
  APPS: {
    selectedKey: 'selectedOnboardedApps',
    totalKey: 'totalApps',
    targetKey: 'appName',
    selectedAllText: `Apps`,
  },
  APP_GROUP: {
    selectedKey: 'selectedAppGroups',
    totalKey: 'totalAppGroups',
    targetKey: 'name',
    selectedAllText: `App Groups`,
  },
  TIME_RANGE: {
    selectedKey: 'timeRange',
    isTimeRange: true,
  },
  SOURCE_REGION: {
    selectedKey: 'selectedSourceRegions',
    totalKey: 'totalSourceRegions',
    targetKey: 'name',
    selectedAllText: `Regions`,
  },
  SOURCE_NETWORK: {
    selectedKey: 'selectedSourceNetworks',
    totalKey: 'totalSourceNetworks',
    targetKey: 'name',
    selectedAllText: `Networks`,
  },
  SOURCE_NETWORK_GROUP: {
    selectedKey: 'selectedSourceNetworkGroups',
    totalKey: 'totalSourceNetworkGroups',
    targetKey: 'name',
    selectedAllText: `Network Groups`,
  },
  TARGET_REGION: {
    selectedKey: 'selectedTargetRegions',
    totalKey: 'totalTargetRegions',
    targetKey: 'name',
    selectedAllText: `Regions`,
  },
  TARGET_NETWORK: {
    selectedKey: 'selectedTargetNetworks',
    totalKey: 'totalTargetNetworks',
    targetKey: 'name',
    selectedAllText: `Networks`,
  },
  TARGET_NETWORK_GROUP: {
    selectedKey: 'selectedTargetNetworkGroups',
    totalKey: 'totalTargetNetworkGroups',
    targetKey: 'name',
    selectedAllText: `Network Groups`,
  },
  PRIVATE_LINK: {
    selectedKey: 'selectedSourcePrivateLinks',
    totalKey: 'totalSourcePrivateLinks',
    targetKey: 'name',
    selectedAllText: `Private Links`,
  },
};
