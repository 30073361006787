import { AppUtil } from '@app/common/util/app-util';

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SignupGuard implements CanActivate {
  constructor(private appUtil: AppUtil) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (this.appUtil.getHost().split('.').length === 4) {
      this.appUtil.routeTo();
      return false;
    } else {
      return true;
    }
  }
}
