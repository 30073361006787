import {
  ZTNAInsightsSharedFilterStore,
  ztnaInsightsSharedFilterStore,
  TimeRangeType,
} from './ztna-insights-filter.store';
import { TimeRange } from '../../shared/calendar.component';
import {
  TeamUser,
  UserGroup,
} from '@app/dashboard/shared/filter-list/client-list/client-list.model';
import {
  OnboardedApp,
  AppGroup,
} from '@app/dashboard/shared/filter-list/app-list/apps-list.model';
import { Network, NetworkGroup } from '@app/dashboard/shared/filter-list/network-list/network-list.model';

export class ZTNAInsightsStoreFilterService {
  constructor(private insightsFilterStore: ZTNAInsightsSharedFilterStore) {}

  updateSelectedTeamUsers(selectedTeamUsers: Array<TeamUser>) {
    this.insightsFilterStore.update((state) => ({
      selectedTeamUsers,
    }));
  }

  updateSelectedUserGroups(selectedUserGroups: Array<UserGroup>) {
    this.insightsFilterStore.update((state) => ({
      selectedUserGroups,
    }));
  }

  updateSelectedOnboardedApps(selectedOnboardedApps: Array<OnboardedApp>) {
    this.insightsFilterStore.update((state) => ({
      selectedOnboardedApps,
    }));
  }

  updateSelectedAppGroups(selectedAppGroups: Array<AppGroup>) {
    this.insightsFilterStore.update((state) => ({
      selectedAppGroups,
    }));
  }

  updateSelectedNetworks(selectedNetworks: Array<Network>) {
    this.insightsFilterStore.update((state) => ({
      selectedNetworks,
    }));
  }
  updateSelectedNetworkGroups(selectedNetworkGroups: Array<NetworkGroup>) {
    this.insightsFilterStore.update((state) => ({
      selectedNetworkGroups,
    }));
  }

  updateDateRange(timeRange: TimeRange) {
    this.insightsFilterStore.update((state) => ({
      timeRange,
    }));
  }

  updateInsightDateRange(insightTimeRange: TimeRange) {
    this.insightsFilterStore.update((state) => ({
      insightTimeRange,
    }));
  }

  updateMainDashboardDateRange(mainDashboardTimeRange: TimeRange) {
    this.insightsFilterStore.update((state) => ({
      mainDashboardTimeRange,
    }));
  }

  updateActiveTab(activeTab: string) {
    this.insightsFilterStore.update((state) => ({
      activeTab,
    }));
  }

  updateActiveSubMenu(activeSubMenu: string) {
    this.insightsFilterStore.update((state) => ({
      activeSubMenu,
    }));
  }

  updateLevelTwoActiveSubMenu(levelTwoActiveSubMenu: string) {
    this.insightsFilterStore.update((state) => ({
      levelTwoActiveSubMenu,
    }));
  }

  updateSource(source: string) {
    this.insightsFilterStore.update((state) => ({
      source,
    }));
  }

  updateSelectedTimeRangeType(selectedTimeRangeType: TimeRangeType) {
    this.insightsFilterStore.update((state) => ({
      selectedTimeRangeType,
    }));
  }
}

export const ztnaInsightsStoreFilterService = new ZTNAInsightsStoreFilterService(
  ztnaInsightsSharedFilterStore
);
