<ul id="password-rules">
  <li>Password should have atleast</li>
  <li>
    <span *ngIf="(passwordRules$ | async)?.digit">&#10004;</span
    ><span *ngIf="!(passwordRules$ | async)?.digit">&#215;</span>One digit
  </li>
  <li>
    <span *ngIf="(passwordRules$ | async)?.lower">&#10004;</span
    ><span *ngIf="!(passwordRules$ | async)?.lower">&#215;</span>One lowercase
    letter
  </li>
  <li>
    <span *ngIf="(passwordRules$ | async)?.upper">&#10004;</span
    ><span *ngIf="!(passwordRules$ | async)?.upper">&#215;</span>One uppercase
    letter
  </li>
</ul>
