import { Query } from '@datorama/akita';
import { NetworkState, NetworkStore, networkStore } from './network.store';
import { Network } from '@app/common/models';

export class NetworkQuery extends Query<NetworkState> {
  constructor(protected store: NetworkStore) {
    super(store);
  }

  getActiveNetwork(): Network {
    return this.store.getValue().activeNetwork;
  }
}

export const networkQuery = new NetworkQuery(networkStore);
