import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberValidator',
})
export class NumberValidatorPipe implements PipeTransform {
  transform(value: any, displayZero: boolean): any {
    if (displayZero && value !== '-' && (value === 0 || value)) {
      return value;
    }
    return '-';
  }
}
