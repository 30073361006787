<div id="time-picker__container" class="d-flex flex-column align-items-start">
  <div class="col px-3 time-picker-label">
    {{ label }}
  </div>

  <div
    class="col-0 px-3 input-wrapper d-flex justify-content-start align-items-center"
  >
    <input
      class="border-top-0 border-left-0 border-right-0 mx-1 my-2 picker hour-picker"
      type="number"
      min="0"
      max="23"
      placeholder="hh"
      [value]="_hour"
      (change)="onSelect($event, 'hour')"
    />
    <div>:</div>
    <input
      class="border-top-0 border-left-0 border-right-0 mx-1 my-2 picker hour-picker"
      type="number"
      min="0"
      max="59"
      placeholder="mm"
      [value]="_minute"
      (change)="onSelect($event, 'minute')"
    />
    <div>:</div>
    <input
      class="border-top-0 border-left-0 border-right-0 mx-1 my-2 picker hour-picker"
      type="number"
      min="0"
      max="59"
      placeholder="ss"
      [value]="_seconds"
      (change)="onSelect($event, 'seconds')"
    />
  </div>
</div>
