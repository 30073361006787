import { InjectionToken } from '@angular/core';

export const WARNING_DIALOG_DATA_TOKEN = new InjectionToken<WarningDialogData>(
  'warning-dialog-portal-data'
);

export interface WarningDialogData {
  message: string;
  buttonTitle?: string;
  cancelButtonTitle?: string;
  isShowCancelButton?: boolean;
  dialogPosition?: {
    top: string;
    right: string;
  };
}
