<mat-menu class="menu-item" #itemMenu="matMenu" [overlapTrigger]="false">
  <div *ngFor="let item of contextMenuValueData">
    <!-- Handle branch node menu items -->
    <div *ngIf="item?.options && item?.options?.length > 0 && !item?.disabled">
      <div
        mat-menu-item
        [matMenuTriggerFor]="menu.itemMenu"
        [disabled]="item?.readOnly ? true : false"
      >
        <div class="d-flex h-100 align-items-center">
          <div class="col-5" *ngIf="item?.imageURL">
            <img
              class="{{ item?.className }}"
              [attr.src]="item.imageURL"
              alt=""
            />
          </div>
          <div
            class="d-flex align-items-center justify-content-between"
            [ngClass]="{ 'col-7': item?.imageURL }"
          >
            <span class="menu-item-text">{{ item.name }}</span>
          </div>
        </div>
      </div>
      <app-menu-item
        #menu
        [contextMenuValues]="item.options"
        (valueChange)="selectedMenuOption($event)"
      ></app-menu-item>
    </div>

    <!-- Handle disabled item -->
    <div *ngIf="item?.disabled">
      <div mat-menu-item>
        <div class="d-flex h-100 align-items-center">
          <div class="col-5">
            <img class="{{ item?.className }}" [attr.src]="item.imageURL" alt="" />
          </div>
          <div class="col-7 d-flex align-items-center justify-content-between" [matMenuTriggerFor]="infoMenu"
            #menuTrigger="matMenuTrigger" (mouseenter)="menuTrigger.openMenu()">
            <span class="menu-item-text">{{ item.name }}</span>
            <mat-menu #infoMenu="matMenu" xPosition="after">
              <div class="px-3 info-text">{{ item?.msg }}</div>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>

    <!-- Handle leaf node menu items -->
    <span *ngIf="!item?.options || item?.options?.length === 0">
      <span
        (click)="selectedMenuOption(item)"
        mat-menu-item
        [disabled]="
          (item?.disabled ? true : false) || (item?.readOnly ? true : false)
        "
      >
        <span class="menu-item-text">{{ item.name }}</span>
      </span>
    </span>
  </div>
</mat-menu>
