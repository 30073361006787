import { COLORS, FONT_FAMILY } from '@app/common/util/constants';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ChartService } from '@app/component-common/charts/common/services/chart.service';
import { TooltipData } from '@app/component-common/charts/common/tooltip/tooltip.component';
import { AppUtil } from '@app/common/util/app-util';
@Component({
  selector: 'app-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PillComponent implements OnInit {
  @Input() value: string;
  @Input() closeable = false;
  @Input() closePositionAfter = false;
  @Input() borderColor = COLORS.BLUE;
  @Input() textColor = COLORS.BLUE;
  @Input() fontSize = '12px';
  @Input() backgroundColor;
  @Input() showCustomTooltipData = null;
  @Input() isRemoveOnClose = true;
  @Input() pillWidth = null;
  @Output() close = new EventEmitter();
  tooltipObject = {};
  closed = false;
  constructor(private chartService: ChartService, private appUtil: AppUtil) {}

  ngOnInit() {}

  onClose() {
    this.closed = this.isRemoveOnClose;
    this.close.emit(this.value);
  }

  showTooltip(event) {
    const { left = 0 } = this.appUtil.getTooltipParentContainerDims();
    const coordinates = {
      x: event.clientX - left,
      y: window.innerHeight - event.clientY + 28,
      clientWidth: window.innerWidth,
      clientHeight: event.pageY,
    };
    const template = this.createTemplate();
    this.chartService.setTooltipData({
      template,
      coordinates,
      containerElemContext: event.relatedTarget,
      show: this.showCustomTooltipData ? true : false,
      showPointer: false,
    } as TooltipData);
  }

  hideTooltip() {
    const coordinates = {};
    const template = '';
    this.chartService.setTooltipData({
      template,
      coordinates,
      containerElemContext: null,
      show: false,
      header: {},
    } as TooltipData);
  }

  createTemplate(): string {
    if (this.showCustomTooltipData && this.showCustomTooltipData.data) {
      this.getCustomTooltipDataFromObject(
        this.showCustomTooltipData.data,
        this.showCustomTooltipData.displayKeys
      );
      let template = `<div style="padding: 0px 10px 10px 10px; width: 200px; word-break: break-all; font-family: ${FONT_FAMILY}; font-size: 0.75rem">`;
      Object.entries(this.tooltipObject).forEach(([key, value]) => {
        if (value !== '?') {
          key = key.charAt(0).toUpperCase() + key.slice(1);
          template = template + `<div class="mt-2" > ${key} : ${value} </div>`;
        }
      });
      return template;
    }
  }

  // get key value data from nested object by providing keys
  getCustomTooltipDataFromObject(dataObject: any, displayKeys: Array<string>) {
    Object.entries(dataObject).forEach(([key, value]) => {
      if (displayKeys.includes(key)) {
        this.tooltipObject[key] = dataObject[key];
      } else if (typeof dataObject[key] === 'object') {
        this.getCustomTooltipDataFromObject(dataObject[key], displayKeys);
      }
    });
  }
  get getTooltip() {
    return this.showCustomTooltipData ? '' : this.value;
  }
}
