import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ZTNARecentSearch } from './ztna-recent-search.model';
import { Injectable } from '@angular/core';

export interface ZTNARecentSearchState extends EntityState<ZTNARecentSearch> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'ztna-recent-search', resettable: true })
export class ZTNARecentSearchStore extends EntityStore<
  ZTNARecentSearchState,
  ZTNARecentSearch
> {
  constructor() {
    super();
  }
}

export const ztnaRecentSearchStore = new ZTNARecentSearchStore();
