import { Application } from '@app/common/models';
import {
  OnboardEntity,
  OnboardEntityType,
} from '@app/common/components/drawer/templates/onboard-template/onboard-template.model';

export type AppOnboardPayload = OnboardEntity<OnboardEntityType.APP> &
  Application;

export interface GenSSLCertModel {
  ca: string;
  url: string;
  termsOfServiceAgreed: boolean;
}

export interface SSLModel {
  id?: string;
  certificateName?: string;
  privateKeyName?: string;
  file_certificate: File;
  file_private_key?: File;
}
export interface ConnectivityTypeModel {
  displayName: string;
  value: string;
  isDefault: boolean;
  isDisabled: boolean;
}
export interface EndpointAttachpointData {
  endPoints: Array<any>;
  attachPoints: Array<any>;
  formLabels: any;
}

export interface LoaderConfig {
  status: boolean;
  message: string;
  type: string;
}

export class EndPointModel {
  displayName: string;
  appNetworkID: string;
  attachPoint: string;
  attachPointID: string;
  attachPointIP: string;
  appIP: string;
  appFqdn?: string;
  attachPointDisplayName: string;
  networkSubnet?: string;

  constructor() {
    this.displayName = '';
    this.appNetworkID = '';
    this.attachPoint = '';
    this.attachPointIP = '';
    this.appIP = '';
    this.attachPointDisplayName = '';
  }
}

export enum AppCreateAction {
  VALIDATE = 'validate',
  SAVE = 'save',
  DEPLOY = 'deploy',
  REDEPLOY = 'redeploy',
}

export interface OverviewData {
  isShowStatus?: boolean;
  selectedRow: Application;
  cloudIDAccountMap: Map<string, any>;
  namespaceIDMap: Map<string, any>;
  cacheRuleIDMap: Map<string, any>;
  certIDMap: Map<string, any>;
  policyIDMap: Map<string, any>;
}
