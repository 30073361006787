import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SearchService } from './search.service';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { IButtonActionDef, IButtonDropdownDef } from '@prosimoio/components';
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit, OnDestroy {
  @Input() roundedCorners = 'ALL';
  @Input() searchTermLength = 2;
  @Input() set resetSearchTerm(resetSearchTerm: boolean) {
    if (resetSearchTerm) {
      this.filterValue.patchValue('');
    }
  }
  @Input() searchTypeSelectionDropdown?: IButtonDropdownDef;
  @Input() placeholder: string = '';
  @Input() set activeSearchTerm(value: string) {
    this.filterValue.patchValue(value);
  }
  @Input() filterableColumnMap;
  @Output() onSearchSelectionChange? = new EventEmitter<IButtonActionDef>();
  @Output() searchTerm = new EventEmitter();
  @Output() selectedFilterMenuColumn = new EventEmitter();
  filterValue = new UntypedFormControl('');
  subscriptions = [];
  selectedSearchType: string;
  selectAllFilterColumns = true; // select all filter columns
  filteredColumnsSet: Set<string>; // filtered columns set
  displayNameMap: Map<string, string>; // lookup for displayName for columns
  previousValue: string = '';
  constructor(
    private searchService: SearchService,
    private cdr: ChangeDetectorRef
  ) {
    this.subscriptions.push(
      // emit the value changes
      this.filterValue.valueChanges
        .pipe(
          distinctUntilChanged(),
          debounceTime(200),
          filter((value: string) => this.isSearchTermValid(value))
        )
        .subscribe((value) => {
          this.setAndEmitSearchTerm(value);
        }),
      // reset the input value
      this.searchService.getResetFiltering().subscribe((reset) => {
        if (reset) {
          this.filterValue.patchValue('');
        }
      })
    );
    this.filteredColumnsSet = new Set();
  }

  ngOnInit(): void {}

  /**
   * sets the rounded corners to the search input based on the input parameter
   */

  getSearchContainerStyles() {
    const searchInputStyles = 'd-flex justify-content-start align-items-center';
    switch (this.roundedCorners.toUpperCase()) {
      case 'ALL':
        return `all ${searchInputStyles}`;
      case 'LEFT':
        return `left ${searchInputStyles}`;
    }
  }

  setAndEmitSearchTerm(value: string) {
    this.searchService.setFilteringString(value);
    this.searchTerm.emit(value);
  }

  onSearchTermPaste(event) {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const searchValue = clipboardData.getData('text');
    this.searchTerm.emit(searchValue?.trim());
  }

  /**
   * Emit selected action details
   * @param actionItem
   */
  onSelectionChange = (actionItem: IButtonActionDef) =>
    this.onSearchSelectionChange.emit(actionItem);

  /**
   * Toggle the value of selectAllFilterColumns
   * @param event
   */
  toggleAllColumnsFilter(event: any) {
    this.selectAllFilterColumns = !this.selectAllFilterColumns;
    if (this.selectAllFilterColumns) {
      this.filteredColumnsSet.clear();
    }
    this.selectedFilterMenuColumn.emit(this.filteredColumnsSet);
  }

  /**
   * Toggle filtered columns from the set
   * @param event
   * @param column
   */
  toggleColumnsFilter(event: any, column: string) {
    event.stopPropagation();
    const filteredColumns = this.filteredColumnsSet;
    this.selectAllFilterColumns = false;
    if (filteredColumns.has(column)) {
      filteredColumns.delete(column);
    } else {
      filteredColumns.add(column);
    }
    if (
      filteredColumns?.size === this.filterableColumnMap.size ||
      !filteredColumns?.size
    ) {
      this.selectAllFilterColumns = true;
    }
    this.selectedFilterMenuColumn.emit(this.filteredColumnsSet);
    this.cdr.markForCheck();
  }

  /**
   * Validate search string is valid or not
   * @param value
   * @returns
   */
  isSearchTermValid(value: string): boolean {
    let searchTerm = value;
    if (value?.match(/^[\s]/)) {
      // Check if there are any black spaces at starting of search text and trim if found
      searchTerm = value?.trim();
    }

    if (searchTerm?.match(/\s{2,}/)) {
      // Verify if there are more than two spaces in the search text and do not fire emit for black spaces
      searchTerm = `${searchTerm?.trim()} `;
    }

    if (searchTerm?.length > 0 || searchTerm === '') {
      if (this.previousValue !== searchTerm) {
        // Compare previous value with current/searchTerm and fire if value is different
        this.previousValue = searchTerm;
        return true;
      }
    }
    return false;
  }

  ngOnDestroy() {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }
}
