import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  notificationConfig$ = new Subject<any>();
  selectedAction$ = new Subject<any>();
  selectedBannerAction$ = new Subject<any>(); // to listen action from banner
  showLoader$ = new Subject<any>();
  constructor() {}

  setMessageConfig(config: any) {
    this.notificationConfig$.next(config);
  }

  setSelectedAction(selectedAction: any) {
    this.selectedAction$.next(selectedAction);
  }

  setSelectedBannerAction(selectedAction: any) {
    this.selectedBannerAction$.next(selectedAction);
  }

  setLoderStatus(showLoader: boolean) {
    this.showLoader$.next(showLoader);
  }

  getMessageConfig() {
    return this.notificationConfig$.asObservable();
  }

  getSelectedAction() {
    return this.selectedAction$.asObservable();
  }

  getSelectedBannerAction() {
    return this.selectedBannerAction$.asObservable();
  }

  getLoaderStatus() {
    return this.showLoader$.asObservable();
  }
}
