import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ObjectUtil } from '@prosimoio/services';
import { AppUtil } from '@app/common/util/app-util';
import { IMAGE_PATHS } from '@app/common/util/constants';

@Component({
  selector: 'app-error-page-preview',
  templateUrl: './error-page-preview.component.html',
  styleUrls: ['./error-page-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorPagePreviewComponent implements OnInit {
  errorPageContent = null;
  previewImageUrl: any;
  backgroundImageURL =
    IMAGE_PATHS.ERROR_PAGE.LOGO_DYNAMIC_ERROR_PAGE_BACKGROUND;
  constructor(
    private cdr: ChangeDetectorRef,
    private appUtil: AppUtil,
    private route: ActivatedRoute
  ) {
    const encodedErrorPageContent =
      this.route.snapshot.paramMap.get('errorPageContent');
    this.errorPageContent = ObjectUtil.decodeObject(encodedErrorPageContent);
    this.previewImageUrl = this.appUtil.getManagementPreviewImageUrl();
    // reset previewIMageUrl from local store
    this.appUtil.updatePreviewImageUrl('', 'Management');
  }

  ngOnInit(): void {}
}
