import { FILTERS_INFO, MCN_ROWS, ZTNA_ROWS } from './print-filter-header.model';
import { PrintFilterHeaderService } from './print-filter-header.service';
import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { SUBSCRIPTION_TYPES } from '@app/common/util/constants';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ObjectUtil } from '@prosimoio/services';

@Component({
  selector: 'app-print-filter-header',
  templateUrl: './print-filter-header.component.html',
  styleUrls: ['./print-filter-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintFilterHeaderComponent implements OnInit, OnDestroy {
  ROWS;
  FILTERS_INFO = FILTERS_INFO;
  subscriptions = [];
  filterData$ = new BehaviorSubject<any>(null);

  _viewType: string;
  @Input() set viewType(viewType: string) {
    this._viewType = viewType;
    this.ROWS = this.viewType === SUBSCRIPTION_TYPES.MCN ? MCN_ROWS : ZTNA_ROWS;
  }
  get viewType(): string {
    return this._viewType;
  }

  constructor(private printFilterHeaderService: PrintFilterHeaderService) {
    this.subscriptions.push(
      this.printFilterHeaderService
        .getFilterData()
        .pipe(
          distinctUntilChanged((curr, prev) => {
            return ObjectUtil.compare(curr, prev);
          }),
          debounceTime(100)
        )
        .subscribe(
          (data) => {
            this.filterData$.next(data);
          },
          (error) => {
            console.log('failed to get filter values', error);
          }
        )
    );
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }
}
