<form
  [formGroup]="restrictions"
  class="d-flex flex-column flex-fill justify-content-center"
>
  <div class="d-flex flex-column restictions overflow-hidden">
    <mat-checkbox color="primary" [formControl]="restrictions.controls.rCSP">
      <pcomp-tooltip-lite>
        <span content>Cloud</span>
        <span tooltip
          >Restrict recommended region to the same <b>Cloud</b> as current
          region</span
        >
      </pcomp-tooltip-lite>
    </mat-checkbox>
    <!-- <mat-checkbox color="primary" [formControl]="restrictions.controls.rRegion">
            <pcomp-tooltip-lite>
                <span content>Cloud Region</span>
                <span tooltip>Restrict recommended region to the same <b>region</b> as current region</span>
            </pcomp-tooltip-lite>
        </mat-checkbox> -->
    <mat-checkbox
      color="primary"
      [formControl]="restrictions.controls.rContinent"
    >
      <pcomp-tooltip-lite>
        <span content>Continent</span>
        <span tooltip
          >Restrict recommended region to the same <b>continent</b> as current
          region</span
        >
      </pcomp-tooltip-lite>
    </mat-checkbox>
    <mat-checkbox
      color="primary"
      [formControl]="restrictions.controls.rCountry"
    >
      <pcomp-tooltip-lite>
        <span content>Country</span>
        <span tooltip
          >Restrict recommended region to the same <b>country</b> as current
          region</span
        >
      </pcomp-tooltip-lite>
    </mat-checkbox>
  </div>
</form>
