import { PasswordModel } from './password.model';
import { ResponseModel } from '@app/common/util/models/response.model';
import { throwError } from 'rxjs';

export class PasswordModelSerializer {
  /**
   * @description : customized response data
   * @param json - response from the server
   */

  fromJson(json: any): PasswordModel {
    try {
      const passwordUpdateResponse = new PasswordModel();
      passwordUpdateResponse.teamURL = json.data.teamURL; // return team url
      passwordUpdateResponse.response = new ResponseModel(
        json.statusCode,
        json.message,
        json.data
      );
      return passwordUpdateResponse;
    } catch (error) {
      throwError(error);
    }
  }

  /**
   * @description : payload to add/update password
   * @param PasswordModel
   */
  toJson(passwordModel: PasswordModel): any {
    return {
      username: passwordModel.username,
      userID: passwordModel.userID,
      password: passwordModel.password,
      team: passwordModel.team,
      dashboardUrl: passwordModel.dashboardUrl,
    };
  }

  toJsonArray(passwordModel: PasswordModel[]): any {
    return [];
  }
}
